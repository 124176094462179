import React, { Component } from 'react';
import { Timeline, Tweet } from 'react-twitter-widgets';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
import YouTube from 'react-youtube';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

const mobileMatch = window.matchMedia('(max-width: 510px)');
class SocialEmbed extends Component {
	constructor(props) {
		super(props);
		this.state = { isLoaded: null };

		// logger.log('[SocialEmbed] - constructor %o', this);
	}

	componentDidMount() {
		// logger.log('[SocialEmbed] - mounted %o', this);
		if(this.props?.data?.platform == 'instagram') {
			this._loadInstagram();
		}
	}

	_loadInstagram = () => {
		if (!window.instgrm && !this.state.isLoaded) {
			logger.log('[SocialEmbed] Loading instagram script');
			const s = document.createElement('script');
			s.async = s.defer = true;
			s.src = `https://platform.instagram.com/en_US/embeds.js`;
			s.id = 'react-instagram-embed-script';
			s.onload = this._onLoad;
			const body = document.body || null;
			if (body) {
				body.appendChild(s);
			}
		} else {
			logger.log('[SocialEmbed] Loading instagram script');
			this._onLoad();
		}
	}

	_onLoad = () => {
		this.setState(
			{
				isLoaded: true,
			},
			() => {
				if (window.instgrm) {
					window.instgrm.Embeds.process();
					logger.log('[SocialEmbed] Loaded instagram');
				}
			}
		);
	}

	render() {
		/**
		 * check type and render based off of that
		 * i.e.:
		 *  Facebook post, Instagram post, Twitter timeline, Twitter tweet (singular)
		 **/
		// logger.log('[SocialEmbed] render:%o', this);

		let { data } = this.props

		if (data.visible === true) {
			switch (data.platform) {
				case 'timeline':
					return data?.screenName ? (
						<div className="timeline">
							<ErrorBoundary message={'Unable to render timeline component.'}>
								<Timeline
									dataSource={{
										sourceType: 'profile',
										screenName: data.screenName,
									}}
								/>
							</ErrorBoundary>
						</div>
					) : null;
				//discuss with david
				// return <Timeline dataSource={{sourceType: 'list', id: data.listId}} />
				// return <Timeline dataSource={{sourceType: 'collection', screenName: data.collectionId}} />

				case 'facebook':
					// logger.log('[SocialEmbed] Facebook info: %o', data.postURL);
					return data?.postURL ? (
						<ErrorBoundary message={'Unable to render Facebook post.'}>
							<FacebookProvider appId="471867639491882">
								<EmbeddedPost href={data.postURL} width="500" />
							</FacebookProvider>
						</ErrorBoundary>
					) : null;

				case 'twitter':
					// let tweetWidth = mobileMatch.matches ? { width: 'auto' } : { width: 'auto' };
					// logger.log('[SocialEmbed] Twitter info: %o', data.tweetId);
					return data?.tweetId ? (
						<ErrorBoundary message={'Unable to render Tweet.'}>
							<Tweet 
								key={data.tweetId} 
								tweetId={data.tweetId} 
								renderError={(_err) => <p>Could not load tweet</p>}
							/>
						</ErrorBoundary>
					) : null;

				case 'instagram':
					// logger.log('[SocialEmbed] Instagram info: %o', data.instagramId);
					return this.state.isLoaded && data?.instagramId ? (
						<ErrorBoundary message={'Unable to render Instagram embed.'}>
							<blockquote
								key={data.instagramId}
								className="instagram-media"
								data-instgrm-captioned
								data-instgrm-permalink={data.instagramId}
								style={{
									maxWidth: '540px',
									minWidth: '326px',
									width: '99.375%',
									width: '-webkit-calc(100% - 2px)',
									width: 'calc(100% - 2px)',
								}}></blockquote>
						</ErrorBoundary>
					) : null;

				case 'youtube':
					// return `Youtube`
					return data?.youTubeVideoId ? (
						<ErrorBoundary message={'Unable to render Youtube embed.'}>
							<YouTube videoId={data.youTubeVideoId} containerClassName="youtube-video" />
						</ErrorBoundary>
					) : null;

				default:
					return null;
			}
		} else {
			// return <div className="rich-text social"></div>;
			return null;
		}
	}
}

export default SocialEmbed;
