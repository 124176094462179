/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { fetch } from 'appdir/components/general/Util';
import Video from 'appdir/components/content/Video';
import Image from 'appdir/components/cms/Image';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Image
 * -----------------------------------------------------------------------------
 */
const MediaWrapper = props => {
	logger.log('[Util MediaWrapper] - props:%o', props);

	const [mediaItems, setMediaItems] = useState(null);
	const [displayCount, setDisplayCount] = useState(3);
	const [maxDisplayCount, setMaxDisplayCount] = useState(3);
	const [showloadMore, setShowLoadMore] = useState(false);

	useEffect(() => {
		fetch(props?.data?.feed)
			.then(data => {
				logger.log('[Util MediaWrapper] - fetch :%o', data?.content);
				setMediaItems(data?.content);
				setMaxDisplayCount(data?.totalRows);
				if (data.totalRows > 3) {
					setShowLoadMore(true);
				}
			})
			.catch(error => {
				logger.error('[MediaWrapper] unable to load feed');
			});
	}, []);

	const renderImageItem = item => {
		let data = {
			images: {
				image: {
					imageId: item?.cmsId,
					url: item?.images[0]?.large || item?.images[0]?.medium,
					caption: item?.images[0]?.credit,
				},
			},
		};

		return <Image key={`image-${item?.cmsId}`} data={data} />;
	};

	const renderVideoItem = data => {
		data = {
			reference: props.id,
			type: 'Video',
			video: {
				autoplay: 'false',
				autoplayPolicy: 'mutedinline',
				category: data?.subType,
				displayDate: `${data?.displayDate}`,
				id: data?.cmsId,
				playsinline: 'true',
				poster: data?.images[0]?.xlarge,
				shareUrl: data?.shareUrl,
				startSeconds: '0',
				streams: {
					stream: {
						cdn: 'Akamai',
						url: data?.media?.m3u8,
						mimeType: data?.media?.m3u8.includes('m3u8') ? 'application/x-mpegURL' : 'video/mp4',
						streamType: 'vod',
					},
					title: data?.title,
				},
				tracks: {
					track:
						get(data, 'media.tracks', false) && get(data, 'media.tracks[0].file', false)
							? {
									type: 'captions',
									// type: 'text/vtt',
									srclang: 'en',
									file: data.media.tracks[0].file,
									label: 'English1',
							  }
							: {},
				},
			},
		};

		return (
			<div className="video">
				<div className="media-16x9">
					<div className="media-wrapper">
						<Video key={`video-${data?.cmsId}`} data={data} />
					</div>
				</div>
			</div>
		);
	};

	/**
	 * when user clicks load more
	 * let display count to max number
	 * of items available
	 **/
	const loadMore = () => {
		setDisplayCount(maxDisplayCount);
		setShowLoadMore(false);
	};

	if (mediaItems) {
		return (
			<div className="media-wrapper-container">
				{mediaItems.map((media, i) => {
					if (i < displayCount) {
						if (media.type == 'video') {
							return renderVideoItem(media);
						} else if (media.type == 'photo') {
							return renderImageItem(media);
						}
					}
				})}
				{showloadMore ? (
					<div className="load-more-wrapper">
						<div className="load-more">
							<span
								onClick={e => {
									loadMore();
								}}>
								Load More
							</span>
						</div>
					</div>
				) : null}
			</div>
		);
	} else {
		return null;
	}
};

export default MediaWrapper;
