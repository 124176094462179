/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import cn from 'classnames';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ButtonLinkText
 *
 * For accessibility, this is created to handle onClick action without
 * the usual EventsLink button style
 *
 * For usual text link, use the WimLink component
 * -----------------------------------------------------------------------------
 *
 * @param state
 * @param state.style {Object} CSS styles to apply to the component.
 * @param className - available preset classNames --> disabled, bold, center
 *
 * @return {*}
 * @constructor
 */
const ButtonLinkText = ({
	onClick,
	className,
	style,
	children,
	disabled,
	ariaHidden,
	type,
	name,
	to,
	external,
	bypassAppLink,
	target,
}) => {
	let classes = cn({
		'button-text-link': true,
		disabled: disabled,
	});

	let keyPress = e => {
		if (e.key === 'Enter') {
			onClick();
		}
	};

	if (onClick) {
		return (
			<div className={`${classes} ${className}`} disabled={disabled}>
				<button
					style={style}
					onClick={() => onClick()}
					onKeyPress={e => keyPress(e)}
					tabIndex={ariaHidden ? -1 : '0'}
					name={name}
					type={type ? type : 'button'}
					role={ariaHidden ? 'none' : 'button'}>
					{children}
				</button>
			</div>
		);
	} else if (to) {
		return (
			<div className={`${classes} ${className}`} disabled={disabled} tabIndex={ariaHidden ? -1 : 0}>
				<EventsLink to={to} external={external} target={target} bypassAppLink={bypassAppLink}>
					<div className={classes} style={style}>
						<div className="content">{children}</div>
						<div className="border"></div>
					</div>
				</EventsLink>
			</div>
		);
	} else {
		return (
			<div className={`${classes} ${className}`} tabIndex={ariaHidden ? -1 : 0} disabled={disabled}>
				<div style={style}>{children}</div>
			</div>
		);
	}
};

export default ButtonLinkText;
