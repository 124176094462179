import React from 'react';
import ButtonGeneral from 'components/cms/ButtonGeneral';

/**
 * ---------------------------------------
 * ButtonGroup - Module with buttons for content.
 * ---------------------------------------
 */

const ButtonGroup = props => {
	// logger.log('[ButtonGroup] render props:%o, ', props);
	let buttonData = props.data;
	let measureData = props.measureData;

	// const getMeasureData = which => {
	// 	let metricsData = {
	// 		...measureData,
	// 		which: which,
	// 	};
	// 	return metricsData;
	// };

	return (
		<div className="button-group">
			{buttonData.map((item, i) => {
				return (
					<ButtonGeneral
						key={`${measureData.id}_bg${i}`}
						data={{
							item,
							style: 'uso-button uppercase blue',
							title: item.text,
							link: item.link,
							measureData: {
								...measureData,
								buttonText: item.text,
								link: item.link,
							},
						}}
					/>
				);
			})}
		</div>
	);
};

export default ButtonGroup;
