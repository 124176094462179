/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Template from 'components/Template';
import TemplateGeneric from 'components/TemplateGeneric';

import StubBox from 'appdir/components/common-ui/StubBox';
import InsightsIdxContent from './InsightsIdxContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: InsightsIndex
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['InsightsIndex'],
		stubs: state['Config'].stubPages,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class InsightsIndex extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openModal: false,
		};
		logger.log('[InsightsIndex] constructor - this%o', this);
	}

	componentDidMount() {}

	render() {
		let header_attributes = {
			headerType: 'scores',
			title: 'Match Insights',
			shortTitle: 'Match Insights',
			metaTitle: 'Match Insights',
			metaDescription: 'Match Insights',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'http://www.ibm.com/sports/usopen',
				title: 'Presented by',
				name: 'IBM',
				imgClass: '',
				imgSrc: '/assets/images/logos/ibm_logo_black.png',
				tagline: 'Presented by',
			},
			page_header: 'Match Insights',
			title: 'Match Insights',
		};

		logger.log('[InsightsIndex] render - window.webviewPlatform:%o', window.webviewPlatform);
		logger.log('[InsightsIndex] render - this:%o', this);

		// logger.log('[InsightsIndex] render - selectedDay:%o', selectedDay);
		if (this.props.stubs && this.props.stubs.insightsindex.stub === 'stub') {
			//TODO put this back
			// if (1 != 1) {
			// logger.log('[InsightsIndex] render - stub');
			if (!window.webviewPlatform) {
				return (
					<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
						<div className="content-main">
							<StubBox
								attributes={{
									title: this.props.stubs.insightsindex.title,
									message: this.props.stubs.insightsindex.text,
								}}
							/>
						</div>
					</Template>
				);
			} else {
				return (
					<TemplateGeneric className="webview">
						<div className="content-main">
							<StubBox
								attributes={{
									title: this.props.stubs.insightsindex.title,
									message: this.props.stubs.insightsindex.text,
								}}
							/>
						</div>
					</TemplateGeneric>
				);
			}
		} else {
			if (!window.webviewPlatform) {
				return (
					<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
						<InsightsIdxContent />
					</Template>
				);
			} else {
				return (
					<TemplateGeneric className="webview">
						<InsightsIdxContent />
					</TemplateGeneric>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InsightsIndex);
