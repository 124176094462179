import React from "react";

const PlayerProfessionalInfo = ({ data }) => {
  const { turned_pro = "" } = data.playerObj;

  const {
    num_played = "",
    events_entered = "",
    best_singles = "",
    best_doubles = ""
  } = data.playerObj.event;

  const {
    singles_titles = "",
    doubles_titles = "",
    matches_won = "",
    matches_lost = "",
    prize_money = ""
  } = data.playerObj.results.career;

  const { high_singles = "", high_doubles = "" } = data.playerObj.rank;

  log("player", data);

  return (
    <span>
      {!num_played &&
      !events_entered &&
      !best_singles &&
      !best_doubles ? null : (
        <div
          className="two-col inline player-professional-info"
          style={{ padding: "10px 0px" }}
        >
          <div className="professional-info-item text">
            <div className="professional-info">
              {num_played ? (
                <div>
                  <span className="professional-info-field">
                    Championships Played:
                  </span>{" "}
                  <span className="professional-info-value">{num_played}</span>
                </div>
              ) : null}
              {events_entered ? (
                <div>
                  <span className="professional-info-field">
                    Events Entered:
                  </span>{" "}
                  <span className="professional-info-value">
                    {events_entered}
                  </span>
                </div>
              ) : null}
              {best_singles ? (
                <div>
                  <span className="professional-info-field">Best Singles:</span>{" "}
                  <span className="professional-info-value">
                    {best_singles}
                  </span>
                </div>
              ) : null}
              {best_doubles ? (
                <div>
                  <span className="professional-info-field">Best Doubles:</span>{" "}
                  <span className="professional-info-value">
                    {best_doubles}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="professional-info-item image">
            <img src="/assets/images/player-profile/trophy.png" />
          </div>
        </div>
      )}
      {!turned_pro &&
      !matches_won &&
      !matches_lost &&
      !high_singles &&
      !high_doubles &&
      !prize_money ? null : (
        <div
          className="two-col inline player-professional-info"
          style={{ padding: "10px 0px" }}
        >
          <div className="professional-info-item text">
            <div className="professional-info">
              {turned_pro ? (
                <div>
                  <span className="professional-info-field">
                    Years Turned Pro:
                  </span>{" "}
                  <span className="professional-info-value">{turned_pro}</span>
                </div>
              ) : null}
              {matches_won ? (
                <div>
                  <span className="professional-info-field">
                    Career Matches Won:
                  </span>{" "}
                  <span className="professional-info-value">{matches_won}</span>
                </div>
              ) : null}
              {matches_lost ? (
                <div>
                  <span className="professional-info-field">
                    Career Matches Lost:
                  </span>{" "}
                  <span className="professional-info-value">
                    {matches_lost}
                  </span>
                </div>
              ) : null}
              {high_singles ? (
                <div>
                  <span className="professional-info-field">
                    High Rank for Singles:
                  </span>{" "}
                  <span className="professional-info-value">
                    {high_singles}
                  </span>
                </div>
              ) : null}
              {high_doubles ? (
                <div>
                  <span className="professional-info-field">
                    High Rank for Doubles:
                  </span>{" "}
                  <span className="professional-info-value">
                    {high_doubles}
                  </span>
                </div>
              ) : null}
              {prize_money ? (
                <div>
                  <span className="professional-info-field">
                    Career Prize Money:
                  </span>{" "}
                  <span className="professional-info-value">{prize_money}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="professional-info-item image">
            <img src="/assets/images/player-profile/tennis-racquet.png" />
          </div>
        </div>
      )}
    </span>
  );
};

export default PlayerProfessionalInfo;
