import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import MatchBox from 'appdir/components/_scoring/MatchBox';

class LazyMatchBox extends Component {
	constructor(props) {
		super(props);
		this.state = { isVisible: false };
		this.onChange = this.onChange.bind(this);
		//logger.log('[LazyMatchBox] constructor:%o', this);
	}

	onChange(isVisible) {
		//logger.log('[LazyMatchBox] onChange:%o', isVisible);
		if (isVisible && !this.state.isVisible) {
			this.setState({ isVisible });
		}
	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	if (!this.props.attributes) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	render() {
		let { data } = this.props.attributes;
		return (
			<VisibilitySensor
				onChange={isVisible => this.onChange(isVisible)}
				partialVisibility={true}
				offset={{ top: 0 }}
				scrollCheck={true}>
				{({ isVisible }) => (
					<div
						className={`lazy-container ${data.team1.idA}${data.team1.idB ? ' ' + data.team1.idB : ''} ${
							data.team2.idA
						}${data.team2.idB ? ' ' + data.team2.idB : ''}`}>
						{(isVisible || this.state.isVisible) && <MatchBox {...this.props} />}
					</div>
				)}
			</VisibilitySensor>
		);
	}
}

export default LazyMatchBox;
