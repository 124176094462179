/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
// import Template from 'components/Template';
import EventsRedirect from 'appdir/components/general/EventsRedirect';

/** MI Specific components */
import MatchInsightsContent from './MatchInsightsContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsight
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	unmount: () => dispatch(deps.actions.MatchInsights.unmount()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});

class MatchInsights_wim extends Component {
	constructor(props) {
		super(props);
		// logger.log('[MatchInsights] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	render() {
		logger.log('[MatchInsights] render - props:%o, this.state:%o', this.props, this.state);
		let header_propsData = {
			headerType: 'scores',
			title: 'Match Insights',
			metaTitle: 'Match Insights',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/matchinsights/index.html',
					title: 'Match Insights',
				},
			],
			title: 'Match Insights',
		};

		return (
			<EventsRedirect to={'/en_US/matchinsights/index.html'} />
			// <Template
			// 	header={header_propsData}
			// 	subHeader={this.props.windowSize !== 'mobile' ? subheader_attributes : null}
			// 	className="scorespage"
			// 	id="match-insights">
			// 	<section className="wrapper scorespage">
			// 		{/* <div className="match-insights-background" /> */}
			// 		<MatchInsightsContent matchId={this.props.match.params.matchId} isWebview={false} />
			// 	</section>
			// </Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsights_wim);
