import React, { Component } from 'react';

class TabPanel extends Component {
	render() {
		return (
			<div>
				<div className="tabPanel">
					{this.props.tabs.map(tab => {
						const active = tab === this.props.selectedTab ? 'active' : '';
						return (
							<div className={'panel-item ' + active} key={tab} data-text={tab}>
								<div className={'panel-link ' + active} onClick={() => this.props.setSelectedTab(tab)}>
									{tab}
								</div>
							</div>
						);
					})}
				</div>
				{this.props.children}
			</div>
		);
	}
}

export default TabPanel;
