/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import ReactEcp from 'react-ecp';
import NextUp from './NextUp';
import MeasurementUtils from 'appdir/lib/analytics';
import { getScreenType } from 'components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: AdTag
 *
 * To use this component, you need to create a config in config_web.json
 *
 * Config looks like this (just add a new one under mip):  Description of everything is in the constructor
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LiveVideoPlayer'],
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveVideoPlayer.mount()),
	unmount: () => dispatch(deps.actions.LiveVideoPlayer.unmount()),
	currentlyPlaying: (id, player) => dispatch(deps.actions.LiveVideoPlayer.currentlyPlaying(id, player)),
});

class LiveVideoPlayer extends Component {
	constructor(props) {
		super(props);
		// this.state = Object.assign({}, this.props);
		this.started = false;
	}
	componentDidMount() {
		this.props.mount();
		logger.log('[LiveVideoPlayer] componentDidMount');
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	onStart() {
		logger.log('[LiveVideoPlayer] onStart this.props.playing:%o, this.player:%o', this.props.playing, this.player);

		if (this.props.attributes.onPlay) {
			this.props.attributes.onPlay();
		}

		this.props.playing.map(item => {
			/*logger.log(
        "[LiveVideoPlayer] item:%o, paused:%o",
        item.player,
        item.player.amp.paused
      )*/
			if (item.id != this.props.attributes.id && item.player.amp.paused === false) {
				item.player.pause();
			}

			if (!this.started && this.player && this.player.props.id == 'herovideo') {
				this.started = true;
				MeasurementUtils.dispatchMeasurementCall('videoplayer', {
					action: 'start',
					metricsData: {
						...this.props.attributes.videoData,
						origin: this.props.attributes.id,
					},
				});
			} else if (this.player && this.player.props.id !== 'herovideo') {
				MeasurementUtils.dispatchMeasurementCall('videoplayer', {
					action: 'start',
					metricsData: {
						...this.props.attributes.videoData,
						origin: this.props.attributes.id,
					},
				});
			}
		});
	}

	onPlay() {
		logger.log('[LiveVideoPlayer] onPlay');
		if (this.props.attributes.onPlay) {
			this.props.attributes.onPlay();
		}

		this.props.playing.map(item => {
			if (item.id != this.props.attributes.id && item.player.amp.paused === false) {
				item.player.pause();
			}
		});

		// MeasurementUtils.dispatchMeasurementCall("videoplayer", {
		//   action:'play',
		//   metricsData: this.props.attributes.videoData
		// });
	}

	onPause() {
		logger.log('[App] onPause');
		if (this.props.attributes.onPause) {
			this.props.attributes.onPause();
		}
	}

	onUserAction(data) {
		logger.log('[App] onUserAction - data:%o', data);
		MeasurementUtils.dispatchMeasurementCall('videoplayer', {
			action: data.action,
			metricsData: {
				...this.props.attributes.videoData,
				origin: this.props.attributes.id,
			},
		});
	}

	onFullscreenChange(isFullscreen) {
		logger.log('[App] onFullscreenChange - isFullscreen:%o', isFullscreen);
		if (this.props.onFullscreenChange) {
			this.props.onFullscreenChange(isFullscreen, this.props.data);
		}
	}

	onReady(amp) {
		logger.log('[LiveVideoPlayer] onReady amp:%o', amp);
		this.player = amp;
		this.props.currentlyPlaying(this.props.attributes.id, amp);
	}

	onTimeUpdate(data) {
		logger.log('[VideoPlayer] onTimeUpdate - data:%o', data);
	}

	onReady(amp) {
		logger.log('[VideoPlayer] onReady amp:%o', amp);
	}

	nextUpUserEnable(on) {
		logger.log(`${this.name} nextUpUserEnable - on:%o`, on);
		this.props.attributes.toggleAutoPlay(on);
	}

	render() {
		logger.log('[LiveVideoPlayer] render this:%o', this);
		let colors = {
			highlightColor: '#447fff',
			toolbarBackground: 'rgba(73, 77, 95, .8)',
			progressBackground: '#D8D8D8',
		};
		return this.props.playerConfig && this.props.attributes.videoData ? (
			<span>
				<div>
					<ReactEcp
						id={this.props.attributes.id}
						ampBasePath="/libs/"
						playerConfig={{
							...this.props.playerConfig,
							analytics: {
								...this.props.playerConfig.analytics,
								convivaData: {
									...this.props.playerConfig.analytics.convivaData,
									custom: {
										...this.props.playerConfig.analytics.convivaData.custom,
										screenType: getScreenType(),
										pageURL: this.props.Router.pathname + this.props.Router.search,
									},
								},
							},
						}}
						videoConfig={this.props.attributes.videoData}
						forcePseudoFullScreen={false}
						isPseudoFullscreen={false}
						onReady={amp => this.onReady(amp)}
						onUserAction={data => this.onUserAction(data)}
						onFullscreenChange={data => this.onFullscreenChange(data)}
						onStart={() => this.onStart()}
						onPlay={() => this.onPlay()}
						onPause={() => this.onPause()}
						onComplete={() => this.onComplete()}
						onTimeUpdate={data => this.onTimeUpdate(data)}
						colors={colors}
						reset={this.props.attributes.reset}
					/>
				</div>
				{this.props.attributes.showNext ? (
					<NextUp
						content={this.props.attributes.nextVid}
						seconds={5}
						auto={this.props.attributes.shouldAutoplay}
						onUserEnable={on => this.nextUpUserEnable(on)}
						playNext={this.props.attributes.playNextFn}
					/>
				) : null}
			</span>
		) : null;
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveVideoPlayer);
