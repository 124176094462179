import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	switch (action.type) {
		case deps.actionTypes.SPONSORLANDINGPAGE_MOUNT:
			newState = {
				...state,
				...action.data,
			};

			return newState;

		case deps.actionTypes.SPONSORLANDINGPAGE_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};

			return newState;

		case deps.actionTypes.SPONSORLANDINGPAGE_LOAD:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.SPONSORLANDINGPAGE_FILTER:
			filters = {
				...state.filters,
				...action.data,
			};

			logger.log('[SponsorLanding] SPONSORLANDINGPAGE_FILTER - state:%o', state);

			newState = {
				...state,
				filters: filters,
			};

			logger.log('[SponsorLanding] SPONSORLANDINGPAGE_FILTER - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
