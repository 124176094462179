import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import deps from 'dependencies';
import Template from 'components/Template';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import StubBox from 'appdir/components/common-ui/StubBox';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import { Carousel } from 'react-responsive-carousel';
import ReactHtmlParser from 'html-react-parser';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import OQModal from '../OpenQuestions/OQModal';
import { fetchAll } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {
		...state['OpenQuestionsDetail'],
		stubs: state['Config'].stubPages,
		OQDataFeeds: state['Config'].open_questions,
		EventsWindow: state['WindowSize'].EventsWindow,
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.OpenQuestionsDetail.mount()),
});

class OpenQuestionsDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			percent: 0,
			section: 'form', //form, loading, or results
			carouselMode: 'pro',
			showModal: false,
			loaded: false,
			viewedIndex: 0,
		};
		this.listener;

		this.percent = 0;

		this.showProgress = this.showProgress.bind(this);
		this.fetchDetails = this.fetchDetails.bind(this);
		this.formSubmit = this.formSubmit.bind(this);
		this.genSource = this.genSource.bind(this);
		this.response = '';

		this.mapVideoData = this.mapVideoData.bind(this);
		this.onCarouselChange = this.onCarouselChange.bind(this);

		logger.log('[OpenQuestions] constructor - props:%o', props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('mount')) {
			this.props.mount();
		}
		this.listener = window.addEventListener('keydown', e => this.toggleModal(e));
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.listener);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			get(this.props, 'Router.params.question', false) &&
			!isEmpty(this.props.OQDataFeeds) &&
			(get(this.props, 'Router.params.question', '') != get(prevProps, 'Router.params.question', '') ||
				!isEqual(this.props.OQDataFeeds, prevProps.OQDataFeeds))
		) {
			this.fetchDetails();
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: `Open Questions Detail:${get(this.props, 'Router.params.question', '')}`,
			});
			if (
				this.props.Router.search.indexOf('results=true') != -1 ||
				this.props.OQDataFeeds.post_tournament == true
			) {
				this.setState({ section: 'results' });
				setTimeout(() => {
					this.showProgress();
				}, 2500);
			}
		}

		if (prevState.carouselMode != this.state.carouselMode) {
			this.setState({ viewedIndex: 0 });
		}
	}

	mapVideoData(data) {
		logger.log('[OpenQuestions] mapVideoData:%o', data);
		if (data && !isEmpty(data)) {
			let vastCategory = 'default';
			if (data.subType == 'highlights') {
				vastCategory = 'highlights';
			} else if (data.subType == 'press_conferences') {
				vastCategory = 'press_conferences';
			}
			return {
				id: data.cmsId,
				title: data.title,
				poster: data.images[0].xlarge,
				shareUrl: data.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: data.media.m3u8,
						mimeType: data.media.m3u8.includes('m3u8') ? 'application/x-mpegURL' : 'video/mp4',
						streamType: 'vod',
					},
				],
				tracks:
					get(data, 'media.tracks', false) && get(data, 'media.tracks[0].file', false)
						? [
								{
									kind: 'captions',
									type: 'text/vtt',
									srclang: 'en',
									src: data.media.tracks[0].file,
								},
						  ]
						: [],
				startSeconds: 0,
				autoplay: true, //autoPlay ? autoPlay : false,
				autoplayPolicy: 'mutedinline',
				autoplayPoster: true,
				playsinline: true,
				displayDate: data.displayDate,
				ads: [],
				category: data.subType,
			};
		}
	}

	fetchDetails() {
		let question = this.props.Router.params.question;
		//fetch country players
		logger.log(
			'[OpenQuestionsDetail] fetchDetails - this.props: %o',
			get(this.props, 'match.params.question', false)
		);
		fetchAll([
			this.props.OQDataFeeds.question_list,
			this.props.OQDataFeeds.question_details.replace('::question::', question),
			this.props.OQDataFeeds.procon_counts,
			this.props.OQDataFeeds.videos,
		])
			.then(data => {
				logger.log('[OpenQuestionsDetail] narration data:%o', data);
				let questionFromUrl = this.props.Router.params.question;
				let questionDetail = filter(data[0].openquestions, function(o) {
					const searchRegExp = /\s/g;
					const replaceWith = '-';
					let questionTopic = o.topic.toLowerCase().replace(searchRegExp, replaceWith);
					return questionTopic == questionFromUrl;
				});
				let procon = data[2][questionDetail[0].topic];
				// let proCount = procon.overalltopicprocount;
				// let totalCount = proCount + procon.overalltopicconcount;

				// let day2 = new Date('2020-09-1');
				// let today = new Date();
				// if (today >= day2) {
				// 	//after day 1
				// 	logger.log('[OpenQuestionsDetail] date is after specified date');
				let proCount = procon.overalltopiccrowdprocount;
				let totalCount = proCount + procon.overalltopiccrowdconcount;
				// }

				this.percent = Math.round((proCount / totalCount) * 100);

				let videoData = filter(data[3].open_questions, function(o) {
					return o.title == questionDetail[0].topic;
				})[0];

				let vidData = this.mapVideoData(videoData);
				logger.log('[OpenQuestionsDetail] vidData:%o', vidData);

				this.setState(prevState => {
					return { ...prevState, OQData: data, questionDetail, vidData, loaded: true };
				});
			})
			.catch(e => {
				this.setState({
					loadingError: true,
				});
			});
	}

	showProgress() {
		let percent = this.percent;
		this.setState({ percent });
	}

	formSubmit() {
		//validate
		if (get(this.response, 'length', 0) < 20) {
			this.setState({ error: true });
		} else {
			//submit the form
			let questionDetail = this.state.questionDetail[0];
			let data = JSON.stringify({
				human_text: questionDetail.human_text,
				subject: questionDetail.claim,
				topic: questionDetail.topic,
				text: this.response,
			});
			let url = this.props.OQDataFeeds.form_submit;
			axios.post(url, data, { headers: { 'Content-Type': 'application/json' } });

			MeasurementUtils.dispatchMeasurementCall('Open Questions:Submit', {
				data,
			});

			this.setState({ error: false, section: 'loading' });
			setTimeout(() => {
				this.setState({ section: 'results' });
				let element = document.getElementById('question_header');
				if (element) {
					element.scrollIntoView({ block: 'start' });
				}
			}, 2000);
			setTimeout(() => {
				this.showProgress();
			}, 2500);
		}
	}

	genSource(item) {
		let url;
		let isUrl = false;
		if (item.indexOf('http') != -1) {
			isUrl = true;
			url = new URL(item);
		}
		return (
			<Fragment>
				{isUrl ? (
					<a href={item} target="_new" className="carousel_source">
						{url.hostname}
					</a>
				) : (
					<span className="carousel_source nolink">{item}</span>
				)}
			</Fragment>
		);
	}

	onPlay() {}

	proConSwitch(carouselMode) {
		let agree = carouselMode == 'pro' ? 'agree' : 'disagree';
		MeasurementUtils.dispatchMeasurementCall('Open Questions:<agree/disagree>', {
			agree,
		});
		this.setState({ carouselMode, viewedIndex: 0 });
	}

	toggleModal(e) {
		let showModal = this.state.showModal == true ? false : true;
		if (e.key == 'Escape') {
			showModal = false;
		} else if (e.key) {
			return;
		}
		MeasurementUtils.dispatchMeasurementCall('Open Questions:Information', {
			showModal,
		});
		this.setState({ showModal });
	}

	onCarouselChange(e) {
		this.setState({ viewedIndex: e });
	}

	render() {
		logger.log('[OpenQuestionsDetail] render - this.state: %o', this.state);
		logger.log('[OpenQuestionsDetail] render - this.props: %o', this.props);

		let header_attributes = {
			headerType: 'visit',
			title: 'Open Questions',
			metaTitle: 'Open Questions ',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		const indicatorStyles = {
			cursor: 'pointer',
			color: '#418FDE',
			display: 'inline-block',
			margin: '0 8px',
		};

		let video = this.state.vidData;

		let carouselItems = get(this.state, 'OQData[1].' + this.state.carouselMode, []).slice(0, 5);
		let sourcelist = get(this.state, 'OQData[1].narration[0].doc.url_links', []);
		let postTournament = get(this.props, 'OQDataFeeds.post_tournament', false);

		//Stub check
		if (this.props.stubs && this.props.stubs.open_questions.stub === 'stub') {
			return (
				<Template header={header_attributes} subHeader={false} hideAd={true}>
					<section id="open_questions" className="wrapper openquestions">
						<h1 className="header" style={{ marginBottom: '0px' }}>
							Open Questions
						</h1>
						<StubBox
							attributes={{
								title: header_attributes.title,
								message: this.props.stubs.open_questions.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.props.stubs && this.state.loaded) {
			return (
				<Template header={header_attributes} subHeader={false} hideAd={true}>
					<OQModal showModal={this.state.showModal} toggleModal={e => this.toggleModal(e)} />
					<div className="openquestions general_content">
						<div className="open_questions_header">
							<img
								src="/assets/images/matchInsights/Watson_product_logo_color_RGB.png"
								alt="Open Questions with Watson"
							/>
							<div className="header_text">
								<span className="first">Open Questions </span>
								<span className="second">with Watson</span>
							</div>
							<span className="info" onClick={e => this.toggleModal(e)}>
								<i className="icon-info-icon" />
							</span>
						</div>
						<div className="go_back">
							<Link to="/en_US/open_questions/index.html">
								<i className="icon-arrow-left" />
								<span>See All Open Questions</span>
							</Link>
						</div>
						<div className="video_container">
							{video && (
								<VideoPlayer
									attributes={{
										videoData: video,
										onComplete: null,
										onPlay: this.onPlay,
										showNext: false,
										nextVid: null,
										id: `oqVideo`,
										// playNextFn: false,
										reset: false,
									}}
								/>
							)}
						</div>
						<div className="question_title" id="question_header">
							{get(this.state, 'questionDetail[0].human_text', '')}
							{/* {postTournament && <span class="question_ended">This Open Question has ended</span>} */}
						</div>
						<div className="question_content">
							{/* Results */}
							<div className={`question_results ${this.state.section == 'results' ? `shown` : `hidden`}`}>
								<div className="results_title">
									<img
										src="/assets/images/matchInsights/Watson_product_logo_color_RGB.png"
										alt="Open Questions with Watson"
									/>
									Watson Debate Summary
								</div>
								{!postTournament && (
									<div className="closeX">
										<button
											className="close-results"
											onClick={() => this.setState({ section: 'form', percent: 0 })}>
											<i className="icon-close" />
										</button>
									</div>
								)}
								<div className="results_percent">
									<div className="progress_bar">
										<div className="bar_overflow">
											<div
												className="bar"
												style={{ transform: `rotate(${this.state.percent * 1.8}deg)` }}
											/>
											<div
												className={`progress_bar_labels ${
													this.state.percent == 0 ? `` : `shown`
												}`}>
												<div className="percent_agree">
													<span className="percent_value">{this.state.percent}</span>
													<span className="percent_label">{`Agree`}</span>
												</div>
												<div className="vl" />
												<div className="percent_disagree">
													<span className="percent_value">{100 - this.state.percent}</span>
													<span className="percent_label">{`Disagree`}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="results_summary">
									{/* {ReactHtmlParser(get(this.state, 'OQData[1].narration[0].doc.free_text', ``))} */}
									{ReactHtmlParser(
										get(this.state, 'OQData[1].narration[0].doc.free_text', ``).replace(
											/\n/g,
											'<br />'
										)
									)}
									<p>
										<span className="sourcelist">
											The Watson Debate Summary analyzed content from the following sources:
										</span>
										<br />
										{sourcelist.map((item, i) => {
											let url;
											let isUrl = false;
											if (item.indexOf('http') != -1) {
												isUrl = true;
												url = new URL(item);
											}
											return (
												<Fragment key={`sourcelist-${i}`}>
													{isUrl ? (
														<a href={item} target="_new">
															{url.hostname}
														</a>
													) : (
														<span>{item}</span>
													)}
													{i != sourcelist.length - 1 ? `, ` : ``}
												</Fragment>
											);
										})}
									</p>
								</div>
							</div>

							{/* Loading */}
							<div className={`loading_results ${this.state.section == 'loading' ? `shown` : `hidden`}`}>
								<div className="watson_animated" />
								<div className="spinner_label">Results are Processing...</div>
							</div>

							{/* Form */}
							<div className={`user_feedback ${this.state.section == 'form' ? `shown` : `hidden`}`}>
								<div className="agree_disagree">
									<div className={`agree ${this.state.carouselMode == 'pro' ? `selected` : ``}`}>
										<button onClick={e => this.proConSwitch('pro')}>See Pros</button>
									</div>
									<div className={`disagree ${this.state.carouselMode == 'con' ? `selected` : ``}`}>
										<button onClick={e => this.proConSwitch('con')}>See Cons</button>
									</div>
								</div>
								<div className="open_question_carousel">
									{!isEmpty(carouselItems) && (
										<Fragment>
											{get(this.props, 'EventsWindow.windowWidth', 1024) > 1024 ? (
												<Carousel
													showThumbs={false}
													useKeyboardArrows={true}
													showStatus={false}
													swipeable
													emulateTouch
													infiniteLoop
													showIndicators={true}
													draggable={true}
													centerSlidePercentage={90}
													onChange={e => this.onCarouselChange(e)}
													selectedItem={this.state.viewedIndex}
													renderIndicator={(onClickHandler, isSelected, index, label) => {
														if (isSelected) {
															return (
																<li
																	style={{ ...indicatorStyles, fontWeight: 'bold' }}
																	aria-label={`Selected: ${label} ${index + 1}`}
																	title={`Selected: ${label} ${index + 1}`}>
																	{index + 1}
																</li>
															);
														}
														return (
															<li
																style={indicatorStyles}
																onClick={onClickHandler}
																onKeyDown={onClickHandler}
																value={index}
																key={index}
																role="button"
																tabIndex={0}
																title={`${label} ${index + 1}`}
																aria-label={`${label} ${index + 1}`}>
																{index + 1}
															</li>
														);
													}}>
													{carouselItems.map((item, i) => (
														<div key={`carousel-${i}`} className="slide-item">
															<span className="carousel_text">
																<span className="bigquote">"</span>
																{item.doc.text}"
															</span>
															{this.genSource(item.doc.url_link)}
														</div>
													))}
												</Carousel>
											) : (
												<Carousel
													showThumbs={false}
													useKeyboardArrows={true}
													showStatus={false}
													swipeable
													emulateTouch
													infiniteLoop
													showIndicators={true}
													draggable={true}
													centerSlidePercentage={90}
													onChange={e => this.onCarouselChange(e)}
													selectedItem={this.state.viewedIndex}>
													{carouselItems.map((item, i) => (
														<div key={`carousel-${i}`} className="slide-item">
															<span className="carousel_text">"{item.doc.text}"</span>
															{this.genSource(item.doc.url_link)}
														</div>
													))}
												</Carousel>
											)}
										</Fragment>
									)}
								</div>

								{get(this.props, 'OQDataFeeds.form_submit', false) && (
									<div className="open_question_submit_form">
										<div className="open_question_prompt">
											Contribute to the discussion below and view the collective results
										</div>
										<div className="open_question_form">
											<div className="form_area">
												{this.state.error && (
													<span className="errorMessage">
														Please enter at least 20 characters.
													</span>
												)}
												<textarea
													onKeyUp={e => {
														this.response = e.target.value;
													}}
													className={`response ${this.state.error ? `error` : ``}`}
												/>
											</div>
											<span className="terms_of_use">
												{`By clicking SUBMIT you are agreeing to these`}{' '}
												<Link to="/en_US/open_questions/terms.html" target="_new">
													Terms of Use
												</Link>
											</span>
											<button
												className="open_question_submit"
												value="submit"
												onClick={this.formSubmit}>
												Submit
											</button>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</Template>
			);
		} else {
			return (
				<Template header={header_attributes} subHeader={false} hideAd={true}>
					<LoadingIndicator />
				</Template>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(OpenQuestionsDetail);
