import React from 'react';
import { checkWonStatus } from '../MatchBoxUtils';

/**
 * -----------------------------------------------------------------------------
 * Class Component: TeamResults
 * @param {*} scoreIndes --- team var
 * @param {*} sumscores ---- bool
 * @param {*} schedule -------- bool
 * @param {*} draws ----- bool
 * @param {*} latestClass --------
 * @param {*} scores ------- score data object
 * @param {*} emptySets ------- empty set data object
 * @returns game and set scores for a team
 * -----------------------------------------------------------------------------
 */

const TeamResults = props => {
	let { data } = props;
	// logger.log('[Matchbox TeamResults] data:%o,', data);

	return (
		<>
			{data.sumscores === true ? null : data.schedule === true || data.draws === true ? (
				<div className="pts"></div>
			) : (
				<div className={`pts${data.latestClass}`}>{data.scores.gameScore[parseInt(data.scoreIndex)]}</div>
			)}

			{data.scores
				? data.scores.sets.map((setData, i) => {
						let setno = parseInt(i, 10) + 1;
						let wonclass = checkWonStatus(data.attributes, data.scoreIndex, setno);

						return (
							<div key={i} className={`set set${setno} ${wonclass}`}>
								<span className="scores">
									{Array.isArray(setData)
										? setData[parseInt(data.scoreIndex)].scoreDisplay
											? setData[parseInt(data.scoreIndex)].scoreDisplay
											: ''
										: setData.team1.scoreDisplay !== 'null'
										? setData.team1.scoreDisplay
										: ''}
									<span className="tiebreak">
										{Array.isArray(setData)
											? setData[parseInt(data.scoreIndex)].tiebreakDisplay
												? setData[parseInt(data.scoreIndex)].tiebreakDisplay
												: ''
											: setData.team1.tiebreakDisplay !== 'null'
											? setData.team1.tiebreakDisplay
											: ''}
									</span>
								</span>
							</div>
						);
				  })
				: null}

			{data.emptySets.map((set, i) => {
				return (
					<div key={i} className={`set set${set}`}>
						<span className="scores">
							<span className="tiebreak" />
						</span>
					</div>
				);
			})}
		</>
	);
};

export default React.memo(TeamResults);
