/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerBox for Match Insights
 * -----------------------------------------------------------------------------
 */
const PlayerBox = props => {
	logger.log('[PlayerBox] - props:%o', props);

	const viewPlayerBio = pid => {
		logger.log('[PlayerBox] - viewPlayerBio:%o', pid);
		props.onPlayerBioClick(pid);
	};

	const getPlayerFlag = nation => {
		let flagPath;

		flagPath = props.flagPath.replace('<code>', nation);

		return flagPath;
	};

	let imgSrc = props.imgPath.replace('<playerid>', props.data.idA);

	return (
		<div className={`mi-player-data mi-section player${props.teamnum}`} key={`player${props.teamnum}-data`}>
			<div className="mi-player-image">
				<img src={imgSrc} alt={props.data.displayNameA} />
			</div>
			<div className="mi-player-name-country">
				<div className="mi-player-name">
					<div className="first-name">{props.data.firstNameA}</div>
					<div className="last-name">
						<div>{props.data.lastNameA}</div>
						{props.data.seed ? <div className="seed">[{props.data.seed}]</div> : null}
					</div>
				</div>
				<div className="mi-player-flag-country">
					<div className="mi-player-flag">
						{props.data.nationA ? (
							<img src={getPlayerFlag(props.data.nationA)} alt={props.data.nationA} />
						) : null}
					</div>
					<div className="mi-player-country">
						{props.data.nationStringA ? props.data.nationStringA : null}
					</div>
				</div>
				{props.windowSize !== 'mobile' ? (
					<a
						href={`/en_US/players/overview/${props.data.idA}.html`}
						className="mi-player-bio-link"
						onClick={() => {
							viewPlayerBio(props.data.idA);
						}}>
						View Player Bio &gt;
					</a>
				) : // <div
				// 	className="mi-player-bio-link"
				// 	onClick={() => {
				// 		viewPlayerBio(props.data.idA);
				// 	}}>
				// 	View Player Bio &gt;
				// </div>
				null}
			</div>
		</div>
	);
};

export default PlayerBox;
