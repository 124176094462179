/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import deps from 'dependencies';
import { connect } from 'react-redux';
import get from 'lodash/get';
import VisibilitySensor from 'react-visibility-sensor';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['Video'],
		anyclipConfig: state?.['Config']?.videoPlayer?.anyclip ?? {},
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Video.mount()),
	currentlyPlaying: (playlist, timeline, outofport) => dispatch(deps.actions.Video.currentlyPlaying(playlist, timeline, outofport)),
	closeFromVideo: () => dispatch(deps.actions.VideoPlayerLanding.closeFromVideo())
});
/**
 * -----------------------------------------------------------------------------
 * Functional Component: Video
 * -----------------------------------------------------------------------------
 */
class Video extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoData: null,
			displayThumbnail: false,
			inlineLoaded: false
		};

		if(!this.script) {
			this.script = document.createElement('script');
			this.script.async = true;
			this.script.src = props.anyclipConfig.anyclipScript;
			this.script.setAttribute('pubname', props.anyclipConfig.pubname);
			// this.script.setAttribute('data-tm-videoguids', '[]');
			// this.script.setAttribute('data-tm-videoguids', '["ashe16934516102275337"]');
			this.script.setAttribute('widgetname', props.anyclipConfig.mainPlayerId);
		}

		// <script type="application/ld+json" name="ac-lre-schema">
		// {
		// 	"@context":"https://schema.org",
		// 	"@type":"VideoObject",
		// 	"name":"Diaz Acosta vs. Gaston Highlights | Round 1, Set 2",
		// 	"description":"Facundo Diaz Acosta vs Hugo Gaston in a round 1 match at the 2024 US Open.",
		// 	"duration":"PT42.347S",
		// 	"thumbnailUrl":"https://stream.geniusplus.ai/MjSAlZEBCn68at4T34B-/1724790184837_480x270_thumbnail.jpg",
		// 	"uploadDate":"2024-08-27T20:22:16.702Z",
		// 	"interactionStatistic":{
		// 		"@type": "InteractionCounter",
		// 		"interactionType": { "@type": "http://schema.org/WatchAction" },
		// 		"userInteractionCount": 0
		// 	}
		// }
		// </script>

		this.inlineWidget = null;
		this.inlineReady = false;
		this.inlineSubscribed = false;

		this.isAshe = document.location.href.includes('ashe.') ? true : false;
		this.isLocalhost = document.location.href.includes('localhost') || document.location.href.includes('dev.usopen.org') ? true : false;

		logger.log('[Video] constructor this:%o', this);
	}

	componentWillUnmount() {
		// logger.log('[Video] componentWillUnmount %o', this);
		['__uspapi', '__cmp', '__tcfapi'].forEach(function(e) {
			if (window[e] !== null) {
				logger.log('[VideoLanding] componentWillUnmount %o exists, deleting', e);
				delete window[e];
			}
		});
		this.setState(
			{
				inlineLoaded: false
			},
			() => {
				this.inlineWidget = null;
				this.inlineReady = false;
				this.inlineSubscribed = false;
			}
		);
	}

	componentDidMount() {
		// logger.log('[Video] componentDidMount %o', this);
		this.props.mount();

		if(this.props?.data?.style !== 'inline') {
			this.showVideo();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[Video] componentDidUpdate prevProps:%o prevState:%o, this:%o', prevProps, prevState, this);
		/**
		 * once player has loaded or if video data changes, 
		 * subscribe to playerOutOfPort message to set playlist
		 * and current time in redux state to be used
		 * by the AnyclipFloat component
		 **/
		let _this = this;
		if(
			this.state?.inlineLoaded === true && prevState?.inlineLoaded === false &&
			this.inlineWidget && 
			!this.inlineSubscribed
		) {			
			this.inlineWidget.subscribe(() => {
				// logger.log('[Video] componentDidUpdate subscribing to outOfPort message for:%o', this.props.data.video.id);

				this.inlineSubscribed = true;
	
				let savedPlaylist = [this.inlineWidget.getCurrentPlaylistItem()]; // playlist must be an array
				let savedTimeline = this.inlineWidget.player.currentTime() * 1000;
	
				logger.log('[Video] componentDidUpdate savedPlaylist:%o, savedTimeline:%o', savedPlaylist, savedTimeline);
	
				this.props.currentlyPlaying(savedPlaylist, savedTimeline, true);
	
			}, 'playerOutOfPort');

			let playerName = this.props?.data?.style === 'inline' ? 'Inline Player' : 'Overlay Player';

			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'adImpression', playerName);
			}, 'adImpression');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'adSkipped', playerName);
			}, 'adSkipped');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'adComplete', playerName);
			}, 'adComplete');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerClipImpression', playerName, 'Play');
			}, 'playerClipImpression');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerFirstQuartile', playerName, 'Play:25%');
			}, 'playerFirstQuartile');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerMidpoint', playerName, 'Play:50%');
			}, 'playerMidpoint');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerThirdQuartile', playerName, 'Play:75%');
			}, 'playerThirdQuartile');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerClipComplete', playerName, 'Play:Complete');
				if(this.props?.data?.closeOnComplete === true) {
					this.props.closeFromVideo();
				}
			}, 'playerClipComplete');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerFullscreen', playerName);
			}, 'playerFullscreen');
			this.inlineWidget.subscribe(e => {
				let mute_status = e?.isMute == 0 ? 'Unmuted' : 'Muted';
				this.measureEvent(e, 'playerMute', `Play:${mute_status}`);
			}, 'playerMute');
			this.inlineWidget.subscribe(e => {
				let pause_status = e?.isPaused === true ? 'Pause' : 'Resume';
				this.measureEvent(e, 'playerPause', `Play:${pause_status}`);
			}, 'playerPause');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'ShareScreenOpen', playerName);
			}, 'ShareScreenOpen');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerTheaterModeShare', playerName);
			}, 'playerTheaterModeShare');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'ShareVideoClicked', playerName);
			}, 'ShareVideoClicked');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxDisplayed', playerName);
			}, 'LuxDisplayed');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxHidden', playerName);
			}, 'LuxHidden');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxUserOpen', playerName);
			}, 'LuxUserOpen');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxUserClose', playerName);
			}, 'LuxUserClose');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxLabelDisplay', playerName);
			}, 'LuxLabelDisplay');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxLabelHide', playerName);
			}, 'LuxLabelHide');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxClickVideo', playerName);
			}, 'LuxClickVideo');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxButtonClick', playerName);
			}, 'LuxButtonClick');
		}
	}

	measureEvent = (e, player_event, player, alt_event) => {
		// logger.log('[Video] measureEvent - e:%o, player_event:%o', e, player_event);
		// logger.log('[Video] measureEvent - this.inlineWidget:%o', this.inlineWidget);

		// logger.log(
		// 	'[Video] measureEvent - data:%o',
		// 	this.inlineWidget?.getTMplaylist()[this.inlineWidget?.playlistCurrentIndex()]
		// );

		let title;
		let category = null;
		let matchId = '0000';
		let matchFound = null;
		let labels;

		title = this.inlineWidget?.getTMplaylist()[this.inlineWidget?.playlistCurrentIndex()]?.title || null;
		labels = this.inlineWidget?.getCurrentPlaylistItem()?.label || [];

		// logger.log('[Video] measureEvent - labels:%o', labels);

		if (labels.length > 0) {
			category = labels.find((label,i) => {
				return label.name == 'category';
			}).value || null;

			// logger.log('[Video] measureEvent - category:%o, categoryFound:%',category, categoryFound);

			if (category == 'cognitive match highlights') {
				matchFound = filter(labels, (label, i) => {
					return label.name == 'matchid';
				});

				/** isolate match id value from string */
				if (matchFound) {
					matchId = matchFound[0].value.slice(matchFound[0].value.indexOf('_') + 1);
				}
			}

			// logger.log('[Video] measureEvent - categoryFound:%o, matchId:%o', categoryFound, matchId);
		}

		if(window.webview) {
			let contextData = [];
			if(player_event == 'playerClipImpression' && category == 'cognitive match highlights') {
				contextData = [
					{ content_vod: title },
					{ vod_category: category },
					{ vod_matchid: matchId },
					{ ai_commentary: 'aiAA:true|aiCC:true' },
					{ player_event: player_event }
				];
			} else {
				contextData = [
					{ content_vod: title },
					{ vod_category: category },
					{ vod_matchid: matchId },
					{ player_event: player_event }
				];
			}

			if(player_event == 'playerClipImpression'){
				contextData.push({tab: this.props.tab});
			}
	
			let metricsVal = ['metrics', `Video${alt_event ? `:${alt_event}` : ''}`];
	
			logger.log('[Video] measureEvent - contextData:%o', contextData);
	
			measureInApp(metricsVal, contextData);
		} else {
			let metricsData = {};
			if(player_event == 'playerClipImpression'){
				metricsData = 
				{
					playerEventData: e ? e : null,
					title: title,
					category: category,
					matchId: matchId,
					tab: this.props.tab
				}
			}else{
				metricsData = 
				{
					playerEventData: e ? e : null,
					title: title,
					category: category,
					matchId: matchId,
				}
			}
			
			MeasurementUtils.dispatchMeasurementCall(player, {
				action: player_event,
				metricsData: metricsData
			});
		}
	};

	togglePlay() {
		//toggle the video player to play/pause
	}

	mapVideoData(data, autoPlay) {
		logger.log('[Video] the video data %o', data);
		if (!this.props.config) {
			return {
				id: data.id,
				title: data.title,
				poster: data.poster,
				shareUrl: data.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: data.streams.stream.url,
						mimeType: this.getMimeType(data.streams.stream.url),
						streamType: 'vod',
					},
				],
				tracks: get(data, 'media.tracks[0].file', false)
					? [
							{
								kind: 'captions',
								type: 'text/vtt',
								srclang: 'en',
								src: data.media.tracks[0].file,
							},
					  ]
					: [],
				startSeconds: 0,
				autoplay: autoPlay ? autoPlay : false,
				autoplayPolicy: 'mutedinline',
				playsinline: true,
				displayDate: data.displayDate,
			};
		} else {
			let videoConfigTemplate = {
				...this.props.config.videoConfigTemplate,
			};
			videoConfigTemplate['id'] = data.id;
			videoConfigTemplate['autoplay'] = autoPlay ? autoPlay : false;
			videoConfigTemplate['title'] = data.title;
			videoConfigTemplate['poster'] = data.poster;
			videoConfigTemplate['shareUrl'] = data.shareUrl.startsWith('http') ? data.shareUrl : '';
			videoConfigTemplate['streams'][0]['mimeType'] = data.streams.stream
				? this.getMimeType(data.streams.stream.url)
				: this.getMimeType(data.streams[0].stream.url);
			videoConfigTemplate['streams'][0]['streamType'] = data.streams.stream
				? this.getStreamType(data.streams.stream.url)
				: this.getStreamType(data.streams[0].stream.url);
			videoConfigTemplate['streams'][0]['url'] = data.streams.stream
				? data.streams.stream.url
				: data.streams[0].stream.url;
			videoConfigTemplate['tracks'][0]['kind'] = 'captions';
			videoConfigTemplate['tracks'][0]['type'] = 'text/vtt';
			videoConfigTemplate['tracks'][0]['srclang'] = 'en';
			if (!get(data, 'media.tracks[0].file', false) && !get(data, 'tracks.track.file', false)) {
				videoConfigTemplate['tracks'] = null;
			} else {
				videoConfigTemplate['tracks'][0]['src'] = data.tracks.track.file;
			}
			// videoConfigTemplate["tracks"][0]["src"] = "";
			videoConfigTemplate['displayDate'] = data.displayDate;
			// logger.log(videoConfigTemplate, "The video config template");
			return videoConfigTemplate;
		}
	}

	getMimeType(url) {
		let extensionArry = url.split('.');
		let vidExtension = extensionArry[extensionArry.length - 1];
		if (vidExtension.indexOf('mp4') !== -1) {
			return 'video/mp4';
		} else {
			return 'application/x-mpegURL';
		}
	}

	getStreamType(url) {
		let extensionArry = url.split('.');
		let vidExtension = extensionArry[extensionArry.length - 1];
		if (vidExtension.indexOf('mp4') !== -1) {
			return 'vod';
		} else {
			return 'vod';
		}
	}

	vidComplete = () => {
		// logger.log('[Video] completed playing video');
	};

	showVideo = () => {
		if ((((this.props?.data?.style === 'inline' || this.props?.data?.style !== 'inline') && this.props?.config) || 
				(this.props?.data?.style !== 'inline' &&!this.props?.config)) && 
				this.state.videoData == null
		) {
			// let videoData = this.mapVideoData(this.props.data.video, true);
			let videoData = this.props?.data?.style === 'inline' ? 
				this.mapVideoData(this.props?.data?.video, true) : (this.props?.data?.video?.id || this.props?.attributes?.videoData?.id || this.props?.data?.id);
			
			if (!window.webview) {
				this.inlineSubscribed = false;
				
				this.setState(prevState => {
					return {
						...prevState,
						videoData,
					};
				}, () => {
					// logger.log('[Video] showVideo this:%o', this);
					let prefix = this.isAshe || this.isLocalhost ? 'ashe' : '';
					let videoArray = new Array(`${prefix}${this.props?.data?.id || this.props?.data?.video?.id || this.props?.attributes?.videoData?.id}`);
					// logger.log('[Video] showVideo videoArray:%o', videoArray);

					let string_videoArray = JSON.stringify(videoArray);
					// logger.log('[Video] showVideo string_videoArray:%o', string_videoArray);

					this.script.setAttribute('data-tm-videoguids', string_videoArray);

					if (this.props?.data?.style !== 'inline') {
						this.script.setAttribute('data-ap', 'on');
					}

					logger.log('[Video] showVideo this.script:%o', this.script);
					let videoContainer = document.getElementById(`anyclip-container-${this.props?.data?.video?.id || this.props?.attributes?.videoData?.id || this.props?.data?.id}`);
					videoContainer.appendChild(this.script);

					window.addEventListener('message', event => {
						if (
							event?.data?.length > 0 &&
							event?.data?.indexOf('lre:playerReady://') === 0 &&
							!this.state?.inlineLoaded &&
							!this.inlineWidget
						) {
							logger.log('[Video] showVideo playerReady event fired:%o', event);
						
							let widget_data = JSON.parse(event.data.slice('lre:playerReady://'.length));
							// this.inlineWidget = window.anyclip.getWidget(this.props?.anyclipConfig?.mainPlayerId);
							this.inlineWidget = window.anyclip.getWidget(null, widget_data['sessionId']);
							if(this.inlineWidget) {
								this.inlineReady = true;
							}
			
							this.setState({
								inlineLoaded: true,
							});
						}
					});

					// this.setCurrentVideoSubscription();
				});
			} else {
				logger.log('[VideoModal] sending link to apps');
				if (window.webviewPlatform == 'ios') {
					window.webkit.messageHandlers.video.postMessage(videoData?.id || this.props?.data?.video?.id || this.props?.attributes?.videoData?.id);
				} else {
					//logger.log('http://usopen/video?id=' + videoData.id)
					location.href = 'http://usopen/video?id=' + ( videoData?.id || this.props?.data?.video?.id || this.props?.attributes?.videoData?.id);
				}
			}
		}
	};

	onChange = (isVisible) => {
		// logger.log('[Video] onChange isVisible:%o, this.inlineWidget:%o', isVisible, this.inlineWidget);
	}

	render() {
		logger.log('[Video] render this:%o', this);
		let share_link = {};
		if (
			this.props?.data?.video &&
			this.props?.data?.video?.shareUrl &&
			this.props?.data?.video?.shareUrl.startsWith('http')
		) {
			share_link['share-link'] = this.props?.data?.video?.shareUrl;
		}

		if (
			this.props?.attributes?.videoData?.video &&
			this.props?.attributes?.videoData?.shareUrl &&
			this.props?.attributes?.videoData?.shareUrl.startsWith('http')
		) {
			share_link['share-link'] = this.props?.attributes?.videoData?.shareUrl;
		}

		if (
			this.props?.data &&
			this.props?.data?.shareUrl &&
			this.props?.data?.shareUrl.startsWith('http')
		) {
			share_link['share-link'] = this.props?.attributes?.videoData?.shareUrl;
		}

		let videoStyle={
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: '0',
			left: '0'
		}

		if(this.props?.data?.style !== 'inline') {
			return (
				<div className="inlineVideo" id={`_${this.props?.data?.video?.id || this.props?.attributes?.videoData?.id || this.props?.data?.id}_inline`} {...share_link}>
					<div id={`anyclip-container-${this.props?.data?.video?.id || this.props?.attributes?.videoData?.id|| this.props?.data?.id}`} style={videoStyle}></div>
				</div>
			)
		} else {
			return this.props.config ? (
				<VisibilitySensor key={`vs-${this.props.data.video.id}`} onChange={this.onChange} partialVisibility={true}>
					{({ isVisible }) => (
						<div className="inlineVideo" id={`_${this.props?.data?.video?.id || this.props?.attributes?.videoData?.id || this.props?.data?.id}_inline`} {...share_link}>
							<Helmet>
								<script type="application/ld+json" name="ac-lre-schema">
									{`{
										"@context":"https://schema.org",
										"@type":"VideoObject",
										"name": "${this.props?.data?.video?.title || this.props?.data?.title || ''}", 
										"description": "${this.props?.data?.video?.description || this.props?.data?.description || ''}",
										"duration": "${this.props?.data?.video?.duration || this.props?.data?.duration || ''}",
										"thumbnailUrl": "${this.props?.data?.video?.poster || this.props?.data?.poster || ''}",
										"uploadDate": "${this.props?.data?.video?.uploadDate || this.props?.data?.uploadDate || this.props?.data?.video?.displayDate || this.props?.data?.displayDate}",
										"interactionStatistic":{
											"@type": "InteractionCounter",
											"interactionType": { "@type": "http://schema.org/WatchAction" },
											"userInteractionCount": 0
										}
									}`}
								</script>
							</Helmet>

							{this.state.videoData !== null ? (
								<div id={`anyclip-container-${this.props?.data?.video?.id || this.props?.attributes?.videoData?.id || this.props?.data?.id}`} style={videoStyle}></div>
							) : (
								<a
									onClick={() => {
										this.showVideo();
									}}
									className="playVideo">
									<img
										src={this.props?.data?.video?.poster || this.props?.attributes?.videoData?.poster || this.props?.data?.poster}
										alt={`play video ${this.props?.data?.video?.title || this.props?.attributes?.videoData?.title || this.props?.data?.title}`}
									/>
									<div className="playBtn" />
									
								</a>
							)}
						</div>
					)}
				</VisibilitySensor>
			) : null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);