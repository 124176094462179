/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Time from 'appdir/components/common-ui/Time';

/**
 * -----------------------------------------------------------------------------
 * React Component: Section Header for Match Insights
 *
 *  props = {
 *             title,
 *             onInfoClickFn, -- callback method to open a modal
 *             infoIcon, ------ boolean show/hide (i)
 *             lastUpdate, ----- time
 *           }
 * -----------------------------------------------------------------------------
 */
const MISectionHeader = props => {
	let klass = 'mi-section-title ';
	klass += props?.onTitleClickFn ? 'close ' : '';

	return (
		<div className={`mi-section-header ${props?.style ? props.style : ''}`} ref={props?.refProp}>
			{props.title && !props.hideTitle ? (
				<h4 className={klass} onClick={props?.onTitleClickFn ? () => props.onTitleClickFn() : null}>
					{props.title}
				</h4>
			) : null}

			<div className={`mi-last-updated ${props.lastUpdatePos ? props.lastUpdatePos : ''}`}>
				{props.lastUpdate && props.lastUpdate != '' ? (
					<>
						Last Update: <Time epoch_ms={props.lastUpdate} format="ddd hh:mm A" options="upper" />
						{/* Updated: <Time epoch_ms={props.lastUpdate} format="ddd HH:mm z" options="upper" /> */}
					</>
				) : null}
			</div>
		</div>
	);
};

export default MISectionHeader;
