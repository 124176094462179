import React from 'react';

const SearchBox = props => {
	return (
		<div className="searchInputContainer">
			<input
				type="text"
				className="player-search-box"
				value={props.value}
				onChange={props.onEnter}
				onKeyPress={props.onEnter}
				onClick={props.reset}
				placeholder={props.placeholder}
			/>
			<div className="searchIcon" onClick={() => props.handleClick(props.value)}>
				<i className="icon-search" />
			</div>
		</div>
	);
};

export default SearchBox;
