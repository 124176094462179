import LiveShow from './index';
import deps from 'dependencies';

export default [
	{
		path:
			'/:lang(en_US)/video/:channel(us_open_now_live_show|us_open_live_stream|tennis-plays-for-peace|us-open-finals-matchcast).html',
		exact: true,
		component: LiveShow,
		load: params => deps.actions.LiveShow.mount(params),
	},
];
