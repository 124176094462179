import React, { Component } from 'react';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import { connect } from 'react-redux';
import MeasurementUtils from 'appdir/lib/analytics';
import AdTag from 'appdir/components/general/AdTag';

const mapStateToProps = (state, props) => {
	return {
		...state['Byline'],
		EventsWindow: state['WindowSize'].EventsWindow,
		adConfigData: state['Config']?.adConfig,
		...props,
	};
};
class Byline extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shareX: 0,
			shareY: 0,
		};
		//logger.log('[ByLine] constructor: state:%o',this.state);
		this.appShareRef = React.createRef();
	}

	componentDidMount() {
		logger.log('[ByLine] componentDidMount');
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[ByLine] componentDidUpdate: %o',  shareIcon);
		if (this.appShareRef && this.appShareRef.current) {
			const shareIcon = this.appShareRef.current.getBoundingClientRect();

			if (this.state.shareX !== shareIcon.x) {
				this.setState({
					shareX: shareIcon.x,
					shareY: shareIcon.y,
				});
			}
		}
	}

	clicked(which, title) {
		MeasurementUtils.dispatchMeasurementCall('Social Buttons', {
			button: which,
			title,
		});
	}

	render() {
		logger.log('[ByLine] render - this:%o ', this);
		let iosShareUrl = `javascript:window.webkit.messageHandlers.share.postMessage({x: ${this.state.shareX}, y: ${this.state.shareY}});`;
		let androidShareUrl = `http://usopen/share?url=${this.props?.share?.url}`;
		return this.state ? (
			<section className="articleInfo social-share-div">
				<div className="info-row">
					{/* <div className="info">
						<div className="info-cell">
							{this.props?.attributes?.length > 0 && <span className="author">{this.props?.attributes?.credit} /</span>}
							<span className="date">
								<Time epoch_ms={this.props?.attributes?.metaDate} format="dddd, MMMM DD, YYYY" />
							</span>
						</div>
					</div> */}

					{window.webview ? (
						<>
							{window.webviewPlatform == 'ios' && this?.props?.adConfigData ? (
								//logger.log('[ByLine] render - adconfigdata:%o ', this?.props?.adConfigData?.['article_2']),

								<>
								<div className="socialShare">
									<a
										href={window.webviewPlatform == 'ios' ? iosShareUrl : androidShareUrl}
										external={true}>
										<div className="app">
											<i
												ref={this.appShareRef}
												className={
													window.webviewPlatform == 'ios' ? 'icon-share2' : 'icon-share'
												}
											/>
										</div>
									</a>
								</div>
								{	this?.props?.EventsWindow?.numericWindowSize < 3 ?
									<AdTag
										adConfig={this?.props?.adConfigData?.['article_2']} //using the 920 ads
										dfpNetworkId={this?.props?.adConfigData?.dfpNetworkId}
									/>
									: null
								}
								
								</>
							) : null}
						</>
					) : (
						<div className="socialShare">
							<FacebookShareButton url={this.props?.share?.url} quote={this.props?.share?.title}>
								<i
									className="icon-facebook"
									title="Facebook"
									onClick={() => this.clicked('Facebook', this.props?.share?.title)}
								/>
							</FacebookShareButton>
							<TwitterShareButton url={this.props?.share?.url} title={this.props?.share?.title}>
								<i
									className="icon-twitter"
									title="Twitter"
									onClick={() => this.clicked('Twitter', this.props?.share?.title)}
								/>
							</TwitterShareButton>
							<EmailShareButton url={this.props?.share?.url} title={this.props?.share?.title}>
								<i
									className="icon-email"
									title="Email"
									onClick={() => this.clicked('Email', this.props?.share?.title)}
								/>
							</EmailShareButton>
						</div>
					)}
				</div>
			</section>
		) : null;
	}
}

export default connect(mapStateToProps)(Byline);
