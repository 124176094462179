/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';

import Slider from 'rc-slider';

import ErrorBoundary from 'components/general/ErrorBoundary';
import EventsButton from 'appdir/components/common-ui/EventsButton';
//  import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MISectionHeader from 'appdir/components/pages/MatchInsights/elements/MISectionHeader';
import { fetch } from 'appdir/components/general/Util';
import { getTeamsLikelihoodWinData } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import { doMeasurement } from 'appdir/components/general/Util';
import { getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: HaveYourSay for Match Insights
 *
 * [Testing Backdoor to enable voting]
 * pass a query string ?override=true to set the voting to open for testing if voting is closed
 *
 * Voting Rules ----------
 *
 * [Slider Scale]
 * - Scale is 0 - 100
 * - The “perc” value is a number from 0 to 100.
 * - If the value is 0, the slider is all the way to the left,
 *   this indicates that the user thinks there is a 100% chance of player 1 winning.
 * - If the value is 100, it is all the way to the right,
 *   this indicates that the user thinks there is a 100% chance of player 2 winning.
 * - Five different text to display depending on the voting value
 *   'Equally likely', 'slightly more likely', 'likely','highly likely', 'extremely likely'
 *
 * [Voting Open]
 * - A user can vote onece per a match
 * - The Submit button is disabled if the prediction value is 50
 *
 * [Once Voted]
 * - Display results with (You) handle that's placed at the value the user has voted on the slider
 * - Display The Fans Predict, Watson Predict tetxt under Your Predict
 *
 * [Voting Closed]
 * - When match's status is changed to status: "Players Warming Up",
 *   no more voting, automatically display results with Fan Avg & Watson bubbles
 * - If the user has already voted, display (You) handle in the slider
 * - If the user didn't vote for the match, hide the handle in the slider
 * - Disable the slider
 *
 * [Post Match]
 *  - SlamTracker ONLY - if the fan's predicted winner is the match winner, display a special content
 *
 * Slider Design Rules ----------
 *
 * [Voting Open]
 * - The border under the player names is just a simple solid line
 * - Place the Watson bubble with a gray border at the position Watson predicts
 * - The normal handle (|||) without opac outer border placed in the middle of the slider (50% position)
 * - Default slider color is gray
 * - When the slider is moved away from the default (50%) position, fill the slider
 *   with the player specific color (green or purple) till where it's selected
 * - The handle adds opac border around the (|||) icon. The border color is the player specific
 *
 * [Voted]
 * - The handle is changed to (You) with a solid smaller border that's specific to the player color
 * - Watson bubble border stays black (this color is fixed regardless of the player)
 * - Place Fan Avg bubble at the fan average value with the border color specific to the Fan voted player
 * - Add ^ to the border under the player names to indicate which player is selected
 *   as the slider moves to each player's side
 * - The disabled slide color is the same as default slider color
 * - You Predict text color
 *   If the user's value is towards team1, display in green, otherwise purple
 * - The Fans Predict text color
 *   If the vote results from cloud is towards team1, display in green otherwise in purple
 * - Watson Predict text color
 *   Always black (to avoid confusion, make this black and not associating with team color)
 *
 * Intro text -----------
 * [Voting Open]
 * "Use the slider to pick which player you think is more likely to win."
 *
 * [Voted]
 * "Results are in!"
 *
 * [Voting Closed]
 * "The voting is closed. Final results are in!"
 *
 * Result Feed ----
 * not voted feed looks like this
 * {
 *      "rows": []
 *  }
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['HaveYourSay'],
		sharedDataConfig: state['Config'].sharedDataConfig,
		configOtherData: state['Config'].otherData,
		windowSize: state['Controller'].windowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: param => dispatch(deps.actions.HaveYourSay.mount(param)),
	sendVote: data => dispatch(deps.actions.HaveYourSay.sendVote(data)),
	unmount: () => dispatch(deps.actions.HaveYourSay.unmount()),
});

class HaveYourSay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			voting: 'open',
			sharePopup: 'hide',
			collapse: true,
			value: 50,
			voteResults2: {
				/** for testing */
				key: 1101,
				value: [61.99999999999999],
			},
		};

		this.firstLoad = true;
		this.loadingRef = React.createRef();
		this.defaultValue = 50;
		this.team1_color = '#418FDE';
		this.team2_color = '#FFD400';
		this.marks = [0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100]; // slider mark for each value
		this.sliderRef = React.createRef();
		// logger.log('[MatchInsights] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentDidMount() {
		/** ?override=true ignores the match status and always set vote to open */
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));

		this.props.mount();
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		let currentMatch = this.props?.matchStatsData;
		let currentMatchStatus = currentMatch?.status;
		let prevMatchStatus = prevProps?.matchStatsData?.status;
		let firstStatusInStatsData = 'players arrive on court';
		let preMatchStatus = 'A'; // future match statusCode
		let isVotingClosed =
			currentMatch?.statusCode !== preMatchStatus ||
			(!currentMatchStatus && currentMatchStatus === undefined && currentMatchStatus === null);

		if (this.props?.status == 'loaded' && this.firstLoad) {
			if (this.props?.voted?.hasOwnProperty(this.matchId) && !this.disableFanPrediction) {
				/** already voted in past, get the results and display */
				this.firstLoad = false;
				this.pullVoteResults();

				/** reset the value to be previously votetd value */
				this.setState({
					value: this.props.voted[this.matchId],
				});
			} else if (isVotingClosed && !this.disableFanPrediction) {
				this.firstLoad = false;

				/** close the vote */
				this.setState(
					{
						voting: 'close',
					},
					() => {
						this.pullVoteResults();
					}
				);
			}
		}

		/** status is changed and players are waming up - time to close the voting
		 *  When the status is in "Players Warming Up" - close the voting
		 */
		if (
			prevMatchStatus &&
			currentMatchStatus === prevMatchStatus &&
			currentMatch?.statusCode !== currentMatch?.statusCode &&
			!this.disableFanPrediction
		) {
			/** the match is about to start, close the voting */
			this.setState({
				voting: 'close',
			});
		}
	}

	getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator type={'white'} />
			</div>
		);
	};

	submitVote = () => {
		/** show loading indicator while everyone is working hard to post user vote */
		if (this.loadingRef.current) {
			this.loadingRef.current.classList.add('show');
		}

		let haveYourSaySubmitVotePath = this.props?.configOtherData?.matchInsights?.haveYourSaySubmitVote;

		let request = axios({
			method: 'post',
			url: haveYourSaySubmitVotePath,
			data: {
				match_id: parseInt(this.matchId),
				perc: parseInt(this.state.value),
			},
			headers: { 'Content-type': 'application/json' },
		})
			.then(res => {
				/** get the vote results */
				this.pullVoteResults(true);

				// logger.log('[HaveYourSay] sendVote success fetching data: %o', res);
			})
			.catch(error => {
				logger.error('[HaveYourSay] sendVote error fetching vote data: %o', error);

				this.setState({
					status: 'error',
				});

				if (this.loadingRef.current) {
					this.loadingRef.current.classList.remove('show');
				}
			});

		//IBM Match Insights:[Match ID]:Fan Prediction:[Player Name]
		let team1PlayerName = `${this.teams[0]['firstNameA']}  ${this.teams[0]['lastNameA']}`;
		let team2PlayerName = `${this.teams[1]['firstNameA']}  ${this.teams[1]['lastNameA']}`;
		let playerName = this.youSelectedClass == 'team1' ? team1PlayerName : team2PlayerName;

		let metricsArgs = [{ matchId: this.matchId }, { playerName: playerName }];
		let contextData;

		if (window.webviewPlatform) {
			metricsArgs = [];
			contextData = [{ fanPrediction: `${this.matchId}:${playerName}` }];
		}

		doMeasurement('IBM Match Insights', 'Fan Prediction', metricsArgs, contextData);
	};

	pullVoteResults = (userVoted = false) => {
		let haveYourSayResultsPath = this.props?.configOtherData?.matchInsights?.haveYourSayResults.replace(
			'<matchId>',
			this.matchId
		);

		fetch(haveYourSayResultsPath)
			.then(result => {
				// logger.log('[HaveYourSay] componentDidUpdate result:%o', result);

				if (this.loadingRef.current) {
					this.loadingRef.current.classList.remove('show');
				}

				/** only when the Submit button is clicked, store matchId
				 *  and voted value in Redux
				 */
				if (userVoted) {
					this.props.sendVote({
						voted: { [this.matchId]: this.state.value },
					});
				}

				this.setState({
					voteResults: result.rows[0],
				});
			})
			.catch(error => {
				logger.error('[HaveYourSay] componentDidUpdate error:%o', error);

				this.setState({
					status: 'error',
				});

				if (this.loadingRef.current) {
					this.loadingRef.current.classList.remove('show');
				}
			});
	};

	onShareClick = () => {
		this.setState({
			sharePopup: 'hide',
		});
	};

	toggleShare = () => {
		if (window.webview) {
			let url = window.location.href;

			/** clean up the URL so it sharers the correct www URL and not webview URL */
			if (url.indexOf('/webview') > -1) {
				url = url.replace('/webview', '');
			}

			window.webviewPlatform == 'ios'
				? window.webkit.messageHandlers.share.postMessage(url)
				: window.JSInterface.share(url);

			// doMeasurement('Match Insights', 'toggleShare', [this.state.sharePopup == 'hide' ? 'show' : 'hide']);
		} else {
			doMeasurement('IBM Match Insights', this.matchId, [
				'Have Your Say',
				'toggleShare',
				this.state.sharePopup == 'hide' ? 'show' : 'hide',
			]);

			this.setState({
				sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
			});
		}
	};

	handleClick = () => {
		if (this.showButton) {
			this.setState(
				{
					collapse: !this.state.collapse,
				},
				() => {
					if (!this.state.collapse) {
						/** if the Have Your Say button is clicked to expand,
						 *  scroll the header element to the view so user doesn't have to scroll
						 */
						this.sliderRef.current.scrollIntoView();
					}
					doMeasurement('IBM Match Insights', 'Have Your Say', [this.state.collapse ? 'Close' : 'Open']);
				}
			);
		}
	};

	handleChange = value => {
		this.setState({
			value: value,
		});
	};

	clearError = () => {
		this.setState({
			status: null,
		});
	};

	getPredictionMessage = (value, player = null) => {
		let degree = '';

		if (!player) {
			/** when voted, get the player name from this.teams */
			player =
				this.state.value < this.defaultValue ? (
					<span>{this.teams[0]['displayNameA']}</span>
				) : this.state.value > this.defaultValue ? (
					<span>{this.teams[1]['displayNameA']}</span>
				) : (
					'Each player'
				);
		} else {
			player = <span>{player}</span>;
		}

		if (value == this.defaultValue) {
			degree = 'Equally likely';
		} else if (value == this.marks[0] || value < this.marks[1] || value > this.marks[7] || value == this.marks[8]) {
			degree = 'extremely likely';
		} else if (value < this.marks[2] || value > this.marks[6]) {
			degree = 'highly likely';
		} else if (value < this.marks[3] || value > this.marks[5]) {
			degree = 'likely';
		} else if (value < this.marks[4] || value > this.marks[4]) {
			degree = 'slightly more likely';
		}

		return (
			<>
				{player} is <span>{degree}</span> to win the match
			</>
		);
	};

	/** Customize the selected value's color */
	getTrackStyle = value => {
		let style = {};

		let backgroundColor = 'transparent';
		let startPos = { left: '50%' };
		let width = 0;

		if (value === this.defaultValue) {
			width = 0;
		} else if (value < this.defaultValue) {
			backgroundColor = this.team1_color;
			startPos = { left: `${value}%` };
			width = this.defaultValue - value + '%';
		} else if (value > this.defaultValue) {
			backgroundColor = this.team2_color;
			startPos = { left: '50%' };
			width = value - this.defaultValue + '%';
		}

		style = {
			backgroundColor: backgroundColor,
			height: 10,
			...startPos,
			width,
		};

		return style;
	};

	getBubblePosClass = (type, val) => {
		let bubbleClass = 'bug ';
		bubbleClass += this.userVoted || this.votingClosed ? 'voted ' : '';

		/** type options = 'watson' || 'fan' */
		if (type) {
			bubbleClass += `${type} `;

			/** change the border color based on the fan favored player */
			if (type == 'fan') {
				bubbleClass += this.fanSelectedClass;
			}
		}

		if (val) {
			/** for team1 winner, adjust the number so it is positioned properly in the left half of slider */
			let adjustedVal = this.preMatchWinnerTeam == 'team1' ? 100 - val : val;

			/** the number is selected to keep
			 * the bubble slightly stays within the
			 * Have Your Say container */
			if (adjustedVal < 2) {
				bubbleClass += 'left ';
			} else if (adjustedVal > 98) {
				bubbleClass += 'right ';
			}
		}

		return bubbleClass;
	};

	getPreMatchWinnerData = () => {
		let likelyhoodWinData = getTeamsLikelihoodWinData(this.props.powerIndexMatchupData, this.teams);
		this.prematchWinnerPos = null;
		this.prermatchWinnerNum = null;

		if (likelyhoodWinData.filter(d => d['likelihoodWinNum']).length > 0) {
			/** make sure we are looking at the same player by checking scoring player ID and clouddata player ID */
			let team1Data = likelyhoodWinData.filter(d => d['player_id'] === this.teams[0]['idA']);
			let team2Data = likelyhoodWinData.filter(d => d['player_id'] === this.teams[1]['idA']);

			this.preMatchWinnerTeam = team1Data[0]['likelihoodWin'] ? 'team1' : 'team2';

			/** if Team1 is likelihood to win, subtruct the number from 100
			 *  so it's positioned properly within team1's graph real estate (left half)
			 */
			this.prematchWinnerPos = team1Data[0]['likelihoodWin']
				? 100 - team1Data[0]['likelihoodWinNum'] + '%'
				: team2Data[0]['likelihoodWinNum'] + '%';

			this.prermatchWinnerNum = team1Data[0]['likelihoodWin']
				? team1Data[0]['likelihoodWinNum']
				: team2Data[0]['likelihoodWinNum'];

			this.prematchWinnerName = team1Data[0]['likelihoodWin']
				? this.teams[0]['displayNameA']
				: this.teams[1]['displayNameA'];
		}
	};

	setClassNames = () => {
		this.wrapperClass = 'have-your-say ';
		this.wrapperClass += this.state.collapse && this.showButton ? 'noborder' : '';

		this.youSelectedClass =
			this.state.value < this.defaultValue ? 'team1' : this.state.value > this.defaultValue ? 'team2' : '';

		this.fanSelectedClass =
			this.fanVoteResult < this.defaultValue ? 'team1' : this.fanVoteResult > this.defaultValue ? 'team2' : '';

		this.sliderClass = 'user-slider ';
		this.sliderClass +=
			this.state.value === this.defaultValue
				? 'default '
				: this.state.value < this.defaultValue
				? 'team1 '
				: 'team2 ';
		/** has to be either one of them for css class set up
		 *  The difference b/w this.userVoted and this.votingClosed is
		 *   - votingClosed -- user hasn't voted for this match but voting is closed, hide the handle when displaying the result
		 *   - userVoted ----- user has voted and has user's selected value to display with results
		 */

		if (this.userVoted) {
			this.sliderClass += 'voted ';
		} else if (this.votingClosed) {
			this.sliderClass += 'voting-closed ';
		}
	};

	getMainContent = () => {
		let introMessage = 'Use the slider to pick which player you think is more likely to win.';

		if (this.votingClosed) {
			introMessage = 'The voting is closed. Final results are in!';
		} else if (this.userVoted) {
			introMessage = 'Results are in!';
		}

		return (
			<>
				<p className="intro">{introMessage}</p>
				<div className={this.sliderClass}>
					{this.prematchWinnerPos && this.prermatchWinnerNum ? (
						<div
							className={this.getBubblePosClass('watson', this.prermatchWinnerNum)}
							style={{ left: this.prematchWinnerPos }}>
							Watson
						</div>
					) : null}

					{/** post vote  || post voting closed */
					(this.userVoted || this.votingClosed) && !isNaN(this.fanSelectedNum) ? (
						<div
							className={this.getBubblePosClass('fan', this.fanVoteResult)}
							style={{ left: this.fanSelectedNum + '%' }}>
							Fan Avg
						</div>
					) : null}

					<Slider
						className=""
						value={this.state.value}
						onChange={this.handleChange}
						max={100}
						marks={{
							0: 0,
							12.5: 12.5,
							25: 25,
							37.5: 37.5,
							50: 50,
							62.5: 62.5,
							75: 75,
							87.5: 87.5,
							100: 100,
						}}
						disabled={this.userVoted || this.votingClosed ? true : false}
						// dots={true}
						// step={12}
						trackStyle={this.getTrackStyle(this.state.value)}
					/>

					<div className={`players ${this.youSelectedClass}`}>
						<div className="col team1">{this.teams[0]['displayNameA']}</div>
						<div className="col v">vs</div>
						<div className="col team2">{this.teams[1]['displayNameA']}</div>
					</div>
				</div>

				{this.state.voting == 'open' || (this.votingClosed && this.userVoted) || this.parsedQs?.override ? (
					<>
						<h5 className={this.youSelectedClass}>You Predict</h5>
						<p className={`prediction ${this.userVoted || this.votingClosed ? 'voted' : ''}`}>
							{this.getPredictionMessage(this.state.value)}
						</p>
					</>
				) : null}

				{/** post vote  || post voting closed */
				(this.userVoted || this.votingClosed) && this.fanSelectedNum ? (
					<>
						<h5 className={this.fanSelectedClass}>The Fans Predict</h5>
						<p className="prediction voted">
							{this.getPredictionMessage(this.fanSelectedNum, this.fanSelectedWinnerName)}
						</p>
					</>
				) : null}

				{/** post vote  || post voting closed */
				(this.userVoted || this.votingClosed) && this.prermatchWinnerNum ? (
					<>
						<h5 className="watson">Watson Predicts</h5>
						<p className="prediction">
							{this.getPredictionMessage(this.prermatchWinnerNum, this.prematchWinnerName)}
						</p>
					</>
				) : null}

				{/** post vote  || post voting closed */
				this.userVoted || this.votingClosed ? //     <div className={`byline--share-wrapper`}> //     Share Your Prediction //     <i className="icon-share" /> // > //     }} //         this.toggleShare(); //     onClick={() => { // <EventsButton className={`border center rounded-corner share`} // [Future Update] -- Share the fan vote
				//         <ShareMenu
				//             type="popup"
				//             view={this.state.sharePopup}
				//             orient="top"
				//             onClick={this.onShareClick}
				//             share={window.location.href}
				//         />
				//     </div>
				// </EventsButton>
				null : (
					<EventsButton
						className={`border center rounded-corner`}
						onClick={() => {
							this.submitVote();
						}}
						disabled={this.state.value === this.defaultValue ? true : false}>
						Submit Prediction
					</EventsButton>
				)}
			</>
		);
	};

	getPostVotedSpecialDisplay = () => {
		/** if team 1, add 50 to the voted value as
		 * the voting scale for team 1 is b/w 0 to 50 where 0 is extremely likely
		 * */
		let fanWinnerNum =
			this.fanVoteResultWinnerTeam == 'team1'
				? Math.round(this.fanVoteResult + this.defaultValue)
				: Math.round(this.fanVoteResult);
		let opponentFanVoteResult = Math.round(100 - fanWinnerNum);
		return (
			<div className="mi-post-match-prediction-wrapper">
				<div className="mi-post-match-prediction-content">
					<h3>US Open Fans Predicted the winner!</h3>

					<div className="player-info">
						<div className="image-name-box">
							<img
								className="player-image"
								src={`${this.props?.imgPath?.replace('<playerid>', this.teams[0]['idA'])}`}
							/>
							<span>{this.teams[0]['displayNameA']}</span>
						</div>
						<div className="vote-info">
							<h4>Here are the fan prediction results.</h4>
							<div className="numbers">
								<span>{this.matchWinnerTeam == 'team1' ? fanWinnerNum : opponentFanVoteResult} %</span>
								<span>{this.matchWinnerTeam == 'team2' ? fanWinnerNum : opponentFanVoteResult} %</span>
							</div>
						</div>

						<div className="image-name-box">
							<img
								className="player-image"
								src={`${this.props?.imgPath?.replace('<playerid>', this.teams[1]['idA'])}`}
							/>
							<span>{this.teams[1]['displayNameA']}</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	render() {
		this.disablePostFanvotingSTMessage = this.props?.configOtherData?.matchInsights?.disablePostFanvotingSTMessage;
		this.disableFanPrediction = this.props.configOtherData?.matchInsights?.disableFanPrediction;
		this.isSlamtracker = this.props?.type == 'slamtracker';

		this.showButton =
			this.props?.windowSize == 'mobile' || this.props?.windowSize == 'tablet' || this.isSlamtracker
				? true
				: false;

		this.matchId = this.props.matchId;
		this.teams = this.props.teams;

		this.matchWinner = this.teams[0]['won']
			? this.teams[0]['displayNameA']
			: this.teams[1]['won']
			? this.teams[1]['displayNameA']
			: null;
		this.matchWinnerTeam = this.teams[0]['won'] ? 'team1' : this.teams[1]['won'] ? 'team2' : null;

		this.isThereResult = this.state?.voteResults?.value?.length > 0;
		this.fanVoteResult = this.state?.voteResults?.value[0]; // average fan vote result
		this.userVoted = this.props?.voted?.hasOwnProperty(this.matchId) && this.isThereResult; // check if the user has already voted this match
		this.votingClosed = this.state.voting === 'close'; // when Players started warming up, close the vote

		/** backdoor for displaying the pre vote state even after players are warming up for testing */
		if (
			(this.parsedQs?.override && this.votingClosed) ||
			(this.props?.configOtherData?.matchInsights?.forceOpenFanVoting && this.votingClosed)
		) {
			this.votingClosed = false;
		}

		this.fanSelectedNum = Math.round(this.state?.voteResults?.value[0]);
		this.fanSelectedWinnerName = '';
		this.fanVoteResultWinnerTeam;

		if (this.isThereResult) {
			let isTeam1FanWinner = this.state.voteResults.value[0] < this.defaultValue;
			this.fanVoteResultWinnerTeam = isTeam1FanWinner ? 'team1' : 'team2';
			this.fanSelectedWinnerName = isTeam1FanWinner
				? this.teams[0]['displayNameA']
				: this.teams[1]['displayNameA'];
		}

		this.getPreMatchWinnerData();
		this.setClassNames();

		// logger.log('[HaveYourSay] this:%o', this);

		if (this.disableFanPrediction) {
			return null;
		} else if (this.props.status == 'loaded') {
			return (
				<ErrorBoundary message="">
					{!this.disablePostFanvotingSTMessage &&
					this.isSlamtracker &&
					this.isThereResult &&
					this.matchWinnerTeam == this.fanVoteResultWinnerTeam ? (
						/** SlamTracker Only, show Fan predicted the winner
						 * special content if the match winner is fan's average predicted winner
						 */
						this.getPostVotedSpecialDisplay()
					) : (
						<div className={this.wrapperClass}>
							{(this.state.collapse && this.showButton) ||
							(this.state.collapse && this.showButton && this.isSlamtracker) ? (
								<EventsButton
									className={`center rounded-corner header-button`}
									onClick={this.handleClick}>
									Have Your Say
									<span>{!this.votingClosed ? 'Make your prediction' : 'See Results'}</span>
								</EventsButton>
							) : (
								<>
									<MISectionHeader
										title="Have Your Say"
										lastUpdatePos="right"
										onTitleClickFn={this.showButton ? this.handleClick : null}
										refProp={this.sliderRef}
									/>
									<div
										className={`mi-inner-body ${
											this.userVoted || this.votingClosed ? 'voted' : ''
										}`}>
										{this.state?.status === 'error' ? (
											<div className="error-message">
												<p>Something went wrong while processing your prediction.</p>
												<p>
													<a onClick={this.clearError}>Please try again</a>
												</p>
											</div>
										) : (
											this.getMainContent()
										)}
									</div>
									{this.getLoadingIndicator()}
								</>
							)}
						</div>
					)}
				</ErrorBoundary>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HaveYourSay);
