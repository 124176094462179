import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { getAppLink } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class EventsRedirect extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.link = {
			url: '',
			external: false,
			query: this.props.query ? this.props.query : '',
		};

		if (this.props.to) {
			if (window.webview) {
				this.link = getAppLink(this.props.to, this.props.title ? this.props.title : '');

				if (!this.link.external) {
					document.location.href = this.link.url;
				}
			} else {
				this.link = {
					url: this.props.to,
					external: false,
					query: this.props.query ? this.props.query : '',
				};
			}
			// if (this.state.query) {
			//   this.link.query = this.state.query
			// }
		}

		//logger.log("[EventsRedirect] constructor - link:%o", this.link);
	}

	componentDidMount() {
		//logger.log('[EventsRedirect] componentDidMount - state:%o props:%o', this.state, this.props);
	}

	render() {
		//logger.log('[EventsRedirect] render - state:%o', this.state);
		// logger.log('[EventsRedirect] render - this.link.url:%o, this.link.query:%o', this.link.url, this.link.query);

		// logger.log('[Router] updateRoute - router:%o pathname:%o', Router.pathname, location.pathname);
		// if (Router.pathname && Router.pathname.indexOf('/webview') == 0 && location.pathname.indexOf('/webview') != 0) {
		//  	location.pathname = '/webview'.concat(location.pathname);
		// }

		return !this.link.external ? (
			<Redirect push to={`${this.link.url}${this.link.query ? `?${this.link.query}` : ``}`} />
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsRedirect);
