/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Template from 'components/Template';
import { connect } from 'react-redux';
import MeasurementUtils from 'appdir/lib/analytics';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import Helmet from 'react-helmet';
import filter from 'lodash/filter';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoLanding
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['VideoLanding'],
		windowSize: state['Controller'].windowSize,
		anyclipFolder: state['Config']?.videoPlayer?.anyclip?.anyclipFolder || null,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class VideoLanding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inline_player_loaded: false,
			watch_player_loaded: false
		};

		this.inline_player = null;
		this.watch_player = null;
		this.watch_player_prefix = "MAIN==";
		this.inline_player_subscribed = false;
		this.watch_player_subscribed = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Videos',
		});
	}

	componentDidMount() {
		window.addEventListener('message', event => {
			if (
				event?.data?.length > 0 &&
				event?.data?.indexOf('lre:playerReady://') === 0 &&
				(!this.state.inline_player_loaded || !this.state.watch_player_loaded)
			) {
				// logger.log('[VideoLanding] componentDidMount event:%o', event.data);

				let widget_data = JSON.parse(event.data.slice('lre:playerReady://'.length));

				if(widget_data?.['customWidgetId'].startsWith(this.watch_player_prefix)) {
					this.watch_player = window.anyclip.getWidget(null, widget_data['sessionId']);

					this.setState({
						watch_player_loaded: true,
					})
				} else {				
					this.inline_player = window.anyclip.getWidget(null, widget_data['sessionId']);
					this.setState({
						inline_player_loaded: true,
					})
				}
			}
		});

		window.addEventListener('hashchange', event => {
            logger.log('[VideoLanding] hash changed to:%o', window.location.hash);
        });
	}

	componentWillUnmount() {
		logger.log('[VideoLanding] componentWillUnmount');

		window.dispatchEvent(new Event('ChangeWatchRouterDispatch'));

		['__uspapi', '__cmp', '__tcfapi'].forEach(function(e) {
			if (window[e] !== null) {
				logger.log('[VideoLanding] componentWillUnmount %o exists, deleting', e);
				delete window[e];
			}
		});
		this.setState(
			{
				inline_player_loaded: false,
                watch_player_loaded: false
			},
			() => {
				this.inline_player = null;
				this.watch_player = null;
			}
		);
	}

	componentDidUpdate() {
		// logger.log('[VideoLanding] componentDidUpdate this:%o', this);

		/** Anyclip events */
		if (this.inline_player && this.inline_player?.subscribe && !this.inline_player_subscribed) {
			// logger.log('[VideoLanding] AnyClip - this.inline_player:%o', this.inline_player);
			this.inline_player_subscribed = true;

			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'adImpression', 'Inline Player');
			}, 'adImpression');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'adSkipped', 'Inline Player');
			}, 'adSkipped');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'adComplete', 'Inline Player');
			}, 'adComplete');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerClipImpression', 'Inline Player');
			}, 'playerClipImpression');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerFirstQuartile', 'Inline Player');
			}, 'playerFirstQuartile');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerMidpoint', 'Inline Player');
			}, 'playerMidpoint');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerThirdQuartile', 'Inline Player');
			}, 'playerThirdQuartile');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerClipComplete', 'Inline Player');
			}, 'playerClipComplete');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerFullscreen', 'Inline Player');
			}, 'playerFullscreen');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerMute', 'Inline Player');
			}, 'playerMute');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerPause', 'Inline Player');
			}, 'playerPause');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'ShareScreenOpen', 'Inline Player');
			}, 'ShareScreenOpen');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerTheaterModeShare', 'Inline Player');
			}, 'playerTheaterModeShare');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'ShareVideoClicked', 'Inline Player');
			}, 'ShareVideoClicked');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxDisplayed', 'Inline Player');
			}, 'LuxDisplayed');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxHidden', 'Inline Player');
			}, 'LuxHidden');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxUserOpen', 'Inline Player');
			}, 'LuxUserOpen');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxUserClose', 'Inline Player');
			}, 'LuxUserClose');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxLabelDisplay', 'Inline Player');
			}, 'LuxLabelDisplay');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxLabelHide', 'Inline Player');
			}, 'LuxLabelHide');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxClickVideo', 'Inline Player');
			}, 'LuxClickVideo');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxButtonClick', 'Inline Player');
			}, 'LuxButtonClick');
		}

		if(this.watch_player && this.watch_player?.subscribe && !this.watch_player_subscribed) {
			this.watch_player_subscribed = true;
				
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'adImpression', 'Watch Player');
			}, 'adImpression');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'adSkipped', 'Watch Player');
			}, 'adSkipped');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'adComplete', 'Watch Player');
			}, 'adComplete');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerClipImpression', 'Watch Player');
			}, 'playerClipImpression');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerFirstQuartile', 'Watch Player');
			}, 'playerFirstQuartile');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerMidpoint', 'Watch Player');
			}, 'playerMidpoint');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerThirdQuartile', 'Watch Player');
			}, 'playerThirdQuartile');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerClipComplete', 'Watch Player');
			}, 'playerClipComplete');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerFullscreen', 'Watch Player');
			}, 'playerFullscreen');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerMute', 'Watch Player');
			}, 'playerMute');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerPause', 'Watch Player');
			}, 'playerPause');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'ShareScreenOpen', 'Watch Player');
			}, 'ShareScreenOpen');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerTheaterModeShare', 'Watch Player');
			}, 'playerTheaterModeShare');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'ShareVideoClicked', 'Watch Player');
			}, 'ShareVideoClicked');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxDisplayed', 'Watch Player');
			}, 'LuxDisplayed');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxHidden', 'Watch Player');
			}, 'LuxHidden');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxUserOpen', 'Watch Player');
			}, 'LuxUserOpen');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxUserClose', 'Watch Player');
			}, 'LuxUserClose');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxLabelDisplay', 'Watch Player');
			}, 'LuxLabelDisplay');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxLabelHide', 'Watch Player');
			}, 'LuxLabelHide');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxClickVideo', 'Watch Player');
			}, 'LuxClickVideo');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxButtonClick', 'Watch Player');
			}, 'LuxButtonClick');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'w_viewAll', 'Watch Player');
			}, 'w_viewAll');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'chipClicked', 'Watch Player');
			}, 'chipClicked');
		}
	}

	measureEvent = (e, player_event, player) => {
		// logger.log('[VideoLanding] measureEvent - e:%o, player_event:%o', e, player_event);
		// logger.log('[VideoLanding] measureEvent - player:%o', player);

		// logger.log(
		// 	'[VideoLanding] measureEvent - data:%o',
		// 	this.inline_player?.getTMplaylist()[this.inline_player?.playlistCurrentIndex()]
		// );

		let title;
		let category = null;
		let ccType = null;
		let matchId = '0000';
		let matchFound = null;
		let labels;

		if(player == 'Inline Player') {
			title = this.inline_player?.getTMplaylist()[this.inline_player?.playlistCurrentIndex()]?.title || null;
			labels = this.inline_player?.getCurrentPlaylistItem()?.label || [];
		} else {
			title = this.watch_player?.getTMplaylist()[this.watch_player?.playlistCurrentIndex()]?.title || null;
			labels = this.watch_player?.getCurrentPlaylistItem()?.label || [];
		}

		// logger.log('[VideoLanding] measureEvent - labels:%o', labels);

		if (labels.length > 0) {
			category = labels.find((label,i) => {
				return label.name == 'category';
			})?.value || null;

			ccType = labels.find((label,i) => {
				return label.name == 'ccType' || label.name == 'cc Type';
			})?.value || null;

			// logger.log('[VideoLanding] measureEvent - category:%o, ccType:%o',category, ccType);

			if (category == 'cognitive match highlights') {
				matchFound = filter(labels, (label, i) => {
					return label.name == 'matchid';
				});

				/** isolate match id value from string */
				if (matchFound) {
					matchId = matchFound[0].value.slice(matchFound[0].value.indexOf('_') + 1);
				}
			}

			// logger.log('[VideoLanding] measureEvent - categoryFound:%o, matchId:%o', categoryFound, matchId);
		}

		MeasurementUtils.dispatchMeasurementCall(player, {
			action: player_event,
			metricsData: {
				playerEventData: e ? e : null,
				title: title,
				category: category,
				ccType: ccType,
				matchId: matchId,
			},
		});
	};

	render() {
		// logger.log('[VideoLanding] render state:%o props: %o', this.state, this.props);
		let header_propsData = {};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: [],
			title: 'Videos',
			page_header: 'Videos',
		};

		header_propsData = {
			headerType: 'video',
			shortTitle: '',
			scrollElem: '',
			metaDescription: '',
			metaDate: '',
			scroll: false,
			titleElem: '.news--header',
			imageSrc: null,
			videoSrc: null,
			title: 'US Open Highlights & Features',
			metaTitle: 'US Open Highlights & Features',
		};

		if (this.props?.anyclipFolder) {
			window.ac_vh_params = {
				containerId: 'anyclip-container',
				folderName: this.props.anyclipFolder,
			};

			return (
				<Template
					header={header_propsData}
					subHeader={subheader_attributes}
					adType={'landingHero'}
					adTypeRefresh={'landingHero'}>
					<Helmet>
						<meta property="og:title" content="@USOpen" />
						<meta property="og:description" content="@USOpen" />
						<meta property="fb:app_id" content="471867639491882" />
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:site" content="@USOpen" />
						<meta name="twitter:creator" content="@USOpen" />
						<script src="https://watch-app.geniusplus.ai/main.js"></script>
					</Helmet>
					<section className="page-content videolanding">
						<PageSubMenu mode="Watch" selected="Videos" />
						<div id="anyclip-container"></div>
					</section>
				</Template>
			);
		} else {
			return null;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoLanding);
