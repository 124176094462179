import React, { useState, useEffect } from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';
import cn from 'classnames';

import { getIconClass } from 'appdir/components/general/Util';
import { fetch } from 'appdir/components/general/Util';

/**
 * ---------------------------------------
 * Functional Component: LiveBlog - generic content box
 *
 * Option to support bullets, icons, and no bullets lists
 *
 * Props
 *  @param {Object} EventsWindow
 *  @param {Boolean} showHeader ------- when the component is set to place in the center,
 *                                      show the header row in case to show button link
 *                                      above mobile size
 *                                      this also makes the list to display in two columns
 *  @param {Object} <cmsdata> --------- content
 *
 * ---------------------------------------
 */

const LiveBlog = props => {
	// logger.log('[LiveBlogLeft] props:%o', props);
	let { EventsWindow } = props.data;

	let measureData = Object.assign({}, props.data);
	// logger.log('[LiveBlogLeft] measureData:%o', measureData);
	// delete measureData.data?.content;

	let mobileSize = EventsWindow?.numericWindowSize < 4;
	let measureNum = !mobileSize ? 2 : 1;

	const getClassNames = () => {
		return cn({
			bullets: true,
			'no-bullets': !props.data?.bullets,
		});
	};

	const { data } = props;
	const [liveBlogItems, setliveBlogItems] = useState([]);
	let useIcons = data.icons ? data.icons : false;

	useEffect(() => {
		async function getLiveBlogData() {
			try {
				const result = await fetch(data?.content?.feed);
				setliveBlogItems(result?.items);
			} catch (err) {
				logger.log(err);
			}
		}
		if (data?.content?.feed) {
			getLiveBlogData();
		}
	}, []);

	return liveBlogItems?.length > 0 ? (
		<div className={`homeLiveBlog ${data?.desktopPosition}`}>
			<div className="content">
				<ul className={getClassNames()}>
					{liveBlogItems.map((item, i) => {
						let listIcon = '';

						if (item?.icon && item?.icon !== '') {
							listIcon = 'icon-check';
						}

						return (
							<li key={`${item.id} ${i}`} className={listIcon !== '' && useIcons ? 'icon-indent' : ''}>
								<EventsLink
									to={item.link}
									measureData={{
										...item,
										...measureData,
										itemType: item.type,
										itemTitle: item.title,
										actionType: 'homePageClick',
										which: `stories_col${measureNum}_item${i}`,
									}}>
									{item?.icon && item?.icon !== '' && useIcons ? (
										<i className={getIconClass(item.icon)} />
									) : null}
									{ReactHtmlParser(item.title)}
								</EventsLink>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	) : null;
};

export default LiveBlog;
