/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deps from 'dependencies';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
// import { getInterfaceLink } from 'appdir/components/general/Util/index.js';
import { getAppLink } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * Component: PlayerProfileButton
 * -----------------------------------------------------------------------------
 */
const PlayerProfileButton = props => {
    const dispatch = useDispatch();
	const windowSize = useSelector((state) => state?.['Controller']?.windowSize ?? {});

	const gotoProfile = path => {
		// props.measureIndex(`${props.currentTab}:Player Profile Button`, '', []);
		logger.log('[PlayerProfileButton] windowSize:%o', windowSize);
		logger.log('[PlayerProfileButton] path:%o', path);
        if (window.webview) {
			// let interfaceLink = getInterfaceLink('liveMatches', {id: props.matchId});
			// interfaceLink?.onclick();
			let link = getAppLink(path, '');
			location.href = link.url;
		} else if (path) {
			if (windowSize == 'mobile') {
				dispatch(deps.actions.Controller.setSTPanelStatus({ open: false }));
			}
            dispatch(deps.actions.MainNav.navigate({path: path}));
		}
    }

    return (
		<div className="player-profile-button-container">
			<ButtonLinkText
				className="player-profile-button"
				onClick={() => {
					gotoProfile(`/en_US/players/overview/${props.playerId}.html`);
				}}>
				Full Profile
			</ButtonLinkText>
		</div>
	);
}

export default PlayerProfileButton;
