import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				currentDayPath: Config.scoringData.schedule,
				daysPath: Config.scoringData.scheduleDays,
				pdfPath: Config.scoringData.schedulePdf,
				status: 'loaded',
				wpiRankings: Config.sharedDataConfig.powerIndex.path,
				courtList: Config.scoringConfig.courtNames
			};
			logger.log('[OnCourtSchedule] actions - mount: data%o', data);
			dispatch({
				type: deps.actionTypes.ONCOURT_SCHEDULE_MOUNT,
				data: data,
			});
		});
	},
	unmount: () => dispatch => {
		let data = {
			status: null,
		};
		dispatch({
			type: deps.actionTypes.ONCOURT_SCHEDULE_UNMOUNT,
			data: data,
		});
	},
};
