import React from 'react';
import BasicDisplay from './BasicDisplay';
import ButtonGroup from './ButtonGroup';

/**
 * ---------------------------------------
 * SingleFeature - module is one piece of content. the module will have the ability to have a title.
 * the content will have a title, an image, and short description.
 * ---------------------------------------
 */

const SingleFeature = props => {
	// logger.log('[SingleFeature] render props:%o, ', props);
	let data = props.data.content.items;
	let buttonData = props.data.content.buttons;
	let measureData = {
		...props.data,
		type: props.data.type,
		itemId: props.data.id,
		itemType: props.data.type,
		actionType: 'homePageClick',
	};
	delete measureData.content;

	if (data.length > 0) {
		return (
			<div className="single-feature">
				{data.map((item, i) => {
					return (
						<div className="story" key={`feature${i}`}>
							<BasicDisplay
								data={item}
								display="story"
								measureData={{ ...measureData, which: `feature${i}_link`, itemTitle: item.title }}
							/>
						</div>
					);
				})}
				{buttonData.length > 0 ? (
					<ButtonGroup
						data={buttonData}
						measureData={{
							...measureData,
							which: 'button',
							actionType: 'homepage_button',
							moduleType: props.data.type,
							title: props.data.title,
						}}
					/>
				) : null}
			</div>
		);
	} else {
		return null;
	}
};

export default SingleFeature;
