/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import get from 'lodash/get';
/**
 * -----------------------------------------------------------------------------
 * React Component: VideoHero
 * -----------------------------------------------------------------------------
 */

class VideoHero extends Component {
	constructor(props) {
		super(props);
		this.nextVidIndex = 0;
		this.onPlay = this.onPlay.bind(this);
		this.mapVideoData = this.mapVideoData.bind(this);
		this.playNext = this.playNext.bind(this);
		this.playVideo = this.playVideo.bind(this);
		this.alreadyStartedCountdown = false;
		this.initHeroVideo = this.initHeroVideo.bind(this);

		logger.log('[VideoHero] constructor props:%o', this.props);
		this.state = {
			mainThumb: this.props.data ? this.props.data.image_large : null,
			currVideo: this.props.data ? this.mapVideoData(this.props.data.video, true, 0) : null,
			vidPlaylist: get(this.props, 'data.related_content', false)
				? this.props.data.related_content.map((d, i) => {
						return this.mapVideoData(d, true, i);
				  })
				: null,
			nextVid:
				get(this.props, 'data.related_content.length', 0) > 0
					? this.mapVideoData(this.props.data.related_content[this.nextVidIndex], true, this.nextVidIndex)
					: null,
			startPlaying: false,
			showNext: false,
			shouldAutoplay: true,
		};
	}

	// mapVideoData(data) {
	//   let videoData = data.video ? data.video : data;
	//   if (!this.state.data.videoConfig) {
	//     return {
	//       id: videoData.id,
	//       title: videoData.title,
	//       poster: videoData.picture,
	//       shareUrl: "",
	//       streams: [
	//         {
	//           cdn: "Akamai",
	//           url: videoData.highlink,
	//           mimeType: "application/x-mpegURL",
	//           streamType: "vod"
	//         }
	//       ],
	//       tracks:
	//         get(data, "media.tracks", false) &&
	//         get(data, "media.tracks[0].file", false)
	//           ? [
	//               {
	//                 kind: "captions",
	//                 type: "text/vtt",
	//                 srclang: "en",
	//                 src: data.media.tracks[0].file
	//               }
	//             ]
	//           : [],
	//       startSeconds: 0,
	//       autoplay: false,
	//       autoplayPolicy: "mutedinline",
	//       playsinline: false,
	//       displayDate: videoData.date
	//     };
	//   } else {
	//     let videoConfigTemplate = {
	//       ...this.state.data.videoConfig.videoConfigTemplate
	//     };
	//     videoConfigTemplate["id"] = data.cmsId;
	//     videoConfigTemplate["title"] = data.title;
	//     videoConfigTemplate["poster"] = data.images[0].medium;
	//     videoConfigTemplate["shareUrl"] = data.shareUrl;
	//     videoConfigTemplate["streams"][0]["mimeType"] = this.getMimeType(data);
	//     videoConfigTemplate["streams"][0]["streamType"] = "vod";
	//     videoConfigTemplate["streams"][0]["url"] = data.media.m3u8;
	//     if (!get(data, "media.tracks[0].file", false)) {
	//       videoConfigTemplate["tracks"] = null;
	//     } else {
	//       videoConfigTemplate["tracks"][0]["src"] = data.tracks.track.file;
	//     }
	//     videoConfigTemplate["displayDate"] = data.displayDate;
	//     // console.log(videoConfigTemplate, "The video config template");
	//     return videoConfigTemplate;
	//   }
	// }

	initHeroVideo() {
		logger.log('[VideoHero] initHeroVideo');

		this.props.onPause();

		this.nextVidIndex = 1;
		this.setState({
			...this.props,
			mainThumb: this.props.data ? this.props.data.image_large : null,
			currVideo: this.props.data ? this.mapVideoData(this.props.data.video, true, 0) : null,
			vidPlaylist: get(this.props, 'data.related_content', false)
				? this.props.data.related_content.map((d, i) => {
						return this.mapVideoData(d, true, i);
				  })
				: null,
			nextVid:
				get(this.props, 'data.related_content.length', 0) > 0
					? this.mapVideoData(this.props.data.related_content[this.nextVidIndex], true, this.nextVidIndex)
					: null,
			startPlaying: false,
			showNext: false,
			shouldAutoplay: true,
		});
	}

	componentDidMount() {
		logger.log('[VideoHero] - componentDidMount this:%o', this);
		// if (this.state.hasOwnProperty('mount')) {
		// 	this.state.mount();
		// }
		//this.initHeroVideo();
	}

	getMimeType(url) {
		let extensionArry = url.split('.');
		let vidExtension = extensionArry[extensionArry.length - 1];
		if (vidExtension.indexOf('mp4') !== -1) {
			return 'video/mp4';
		} else {
			return 'application/x-mpegURL';
		}
	}

	updateHero() {
		let currVid = this.state.nextVid;
		this.nextVidIndex++;
		let nextVid =
			this.nextVidIndex < this.state.vidPlaylist.length ? this.state.vidPlaylist[this.nextVidIndex] : null;
		logger.log('[VideoHero] - updateHero currVid:%o', currVid);
		logger.log('[VideoHero] - updateHero nextVid:%o', nextVid);

		if (nextVid) {
			this.setState(prevState => {
				return {
					...prevState,
					currVideo: currVid,
					nextVid,
					showNext: false,
				};
			});
		} else {
			this.setState({
				nextVid,
				currVideo: currVid,
				// showNext:false
			});
			// this.nextVidIndex = 1;
			// this.setState({
			//   ...this.props,
			//   mainThumb: this.props.data ? this.props.data.image_large : null,
			//   currVideo: this.props.data
			//     ? this.mapVideoData(this.props.data.video, true)
			//     : null,
			//   vidPlaylist: this.props.data
			//     ? this.props.data.related_content.map((d, i) => {
			//         return this.mapVideoData(d, true);
			//       })
			//     : null,
			//   nextVid: this.props.data && this.props.data.related_content.length > 0
			//     ? this.mapVideoData(
			//         this.props.data.related_content[this.nextVidIndex],
			//         true
			//       )
			//     : null,
			//   startPlaying: false,
			//   showNext: false,
			//   shouldAutoplay: true
			// });
		}
		//logger.log("[VideoHero] - updateHero state:%o", this.state);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[BackToTop] - componentDidUpdate state:%o', this.state);
		if (this.state.showNext && !this.alreadyStartedCountdown && this.state.shouldAutoplay) {
			let counter = 5;
			this.alreadyStartedCountdown = true;
			this.countDownIntervall = setInterval(() => {
				document.getElementById('nextCounter').innerText = counter;
				counter--;
				if (counter === -1) {
					clearInterval(this.countDownIntervall);
					counter = 5;
					this.updateHero();
					this.alreadyStartedCountdown = false;
				}
			}, 1000);

			// if (counter == 0) {
			//   clearInterval(this.countDownIntervall);
			//   // this.countDown = 5;
			//   this.updateHero();
			// }
		}

		// if (prevState.currVideo !== this.state.currVideo && !this.state.currVideo) {
		//   this.initHeroVideo();
		// }
	}

	mapVideoData(data, autoPlay, index) {
		let videoData = data.video ? data.video : data;
		return {
			id: videoData.id,
			index,
			title: videoData.title,
			poster: videoData.picture,
			shareUrl: '',
			streams: [
				{
					cdn: 'Akamai',
					url: videoData.highlink,
					mimeType: this.getMimeType(videoData.highlink),
					streamType: 'vod',
				},
			],
			tracks: [
				{
					kind: 'captions',
					type: 'text/vtt',
					srclang: 'en',
					src: '',
				},
			],
			startSeconds: 0,
			autoplay: autoPlay ? autoPlay : false,
			autoplayPolicy: 'mutedinline',
			//playsinline: false,
			playsinline: true,
			displayDate: videoData.date,
			category: videoData.category,
		};
	}

	playNext() {
		// if (this.state.nextVid) {
		// 	this.setState(prevState => {
		// 		return {
		// 			...prevState,
		// 			showNext: true,
		// 		};
		// 	});
		// }
	}

	playVideo() {
		logger.log('[VideoHero] playVideo');

		if (!this.state.startPlaying) {
			this.setState(prevState => {
				return {
					...prevState,
					startPlaying: true,
				};
			});
		}
	}

	onPlay() {
		logger.log('[VideoHero] onPlay');

		// this.setState(prevState => {
		//   return {
		//     ...prevState
		//     //showNext: false
		//   };
		// });
	}

	render() {
		logger.log('[VideoHero] render this:%o', this);
		return this.state.currVideo ? (
			this.state.startPlaying ? (
				<div>
					<VideoPlayer
						attributes={{
							videoData: this.state.currVideo,
							onComplete: this.playNext,
							onPlay: this.props.onPlay,
							onPause: this.props.onPause,
							nextVid: this.state.nextVid,
							id: `herovideo`,
							playNextFn: this.updateHero,
							reset: false,
							showPanelIcon: true,
						}}
					/>
					<i className="icon-close videoClose" onClick={() => this.initHeroVideo()} />
				</div>
			) : (
				<div
					onClick={() => {
						this.playVideo();
					}}>
					<div className="playBtn">&nbsp;</div>
					<img src={this.state.mainThumb} title={this.state.currVideo.title} />
				</div>
			)
		) : null;
	}
}

export default VideoHero;
