import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const data = {
				playerImagePath: Config.otherData.playerProfileImagePath,
				flagImagePathLarge: Config.otherData.flagImagePathLarge,
				flagImagePathSmall: Config.otherData.flagImagePathSmall,
				stLive: Config.slamtracker.stLive,
				useAnimation: Config.scoring.useAnimation,
			};
			dispatch({
				type: deps.actionTypes.MATCHBOX_MOUNTED,
				data: data,
			});
		});
	},
};
