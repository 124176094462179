import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		let archive = false;
		let year = 'current';
		logger.log('[Photos] action.mount - params: %o', params);

		if (params.year && params.year != 'index') {
			archive = true;
			year = params.year;
		}

		//logger.log('[Photos] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				path: Config.cmsData.index,
				photosbyCount: Config.relatedContent.photosbyCount,
				photosbyId: Config.relatedContent.photosbyId,
				photobyId: Config.relatedContent.photobyId,
				photosbyPlayer: Config.relatedContent.photosbyPlayer,
				photoPlayersPath: Config.cmsData.photoPlayers,
				archive,
				year,
				newsFilterPath: Config.cmsData.newsFilter,
				domain: Config.relatedContent.domain,
				adConfig: Config.adConfig,
				adMode: Config.landingPages.adMode,
				sponsor: Config.sponsors.photos,
			};
			logger.log('[Photos] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.PHOTOSINDEX_LOAD, data: data });
		});
	},
};
