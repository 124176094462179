/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Translate from '../../Translate';
import op from 'object-path';

import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: STToggle
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...props,
		statMatch: op.get(state.ScoreManager, 'statMatch'),
		uom: state['Controller'].scoring['uom'],
	};
};

const mapDispatchToProps = dispatch => ({
	updateUom: data => dispatch(deps.actions.Controller.updateUom(data)),
});

class STToggle extends Component {
	constructor(props) {
		super(props);
		// logger.log('[STToggle] constructor - this:%o ', this);
	}

	changeMeasurement(which) {
		this.props.updateUom({ uom: which });
		this.measureToggle('Unit Toggle', [which, 'en_US']);
	}

	measureToggle(measureAction, measure_args) {
		if (window.location.href.indexOf('suite') == -1) {
			let matchStatus;
			let measureArray;

			if (this.props.statMatch.status == 'Complete') {
				matchStatus = 'Complete';
			} else {
				matchStatus = 'Live';
			}

			if (typeof webview === 'undefined') {
				measureArray = ['Slamtracker', matchStatus, 'Toggle'].concat(measureAction).concat(measure_args);

				MeasurementUtils.dispatchMeasurementCall(measureAction, {
					data: measureArray,
				});
			} else {
				measureArray = ['Metrics', 'Slamtracker', matchStatus].concat(measureAction).concat(measure_args);

				measureInApp(measureArray);
			}
		}
	}

	render() {
		// logger.log('[STToggle] render - this:%o ', this);

		if (this.props.uom) {
			return (
				<div className="unit-toggle">
					<a
						className={`unit-metric ${this.props.uom == 'metric' ? 'selected' : ''}`}
						onClick={() => this.changeMeasurement('metric')}>
						<i className="icon-radio-button" />
						<i className="icon-radio-selected" />
						<Translate originalText="metric" />
					</a>
					<a
						className={`unit-imperial ${this.props.uom == 'imperial' ? 'selected' : ''}`}
						onClick={() => this.changeMeasurement('imperial')}>
						<i className="icon-radio-button" />
						<i className="icon-radio-selected" />
						<Translate originalText="imperial" />
					</a>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(STToggle);
