import React, { Component } from 'react';

class CountDownTimer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			days: null,
			hours: '00',
			minutes: '00',
			seconds: '00',
			timeUp: false,
		};
	}

	componentWillUnmount() {
		if (this.countdownTimer) {
			clearInterval(this.countdownTimer);
		}
	}

	componentDidMount() {
		this.setCountdownTimer();

		if (!this.countdownTimer) {
			this.countdownTimer = setInterval(() => {
				this.setCountdownTimer();
			}, 60000);
		}
	}

	setCountdownTimer = () => {
		let eventDate = +new Date(this.props.date);
		let difference = eventDate - +new Date();

		if (difference < 1) {
			logger.log('[CountDownTimer] difference:%o', difference);
			let days = Math.floor(difference / (1000 * 60 * 60 * 24)) * -1;

			this.setState({ timeUp: true, days });
		} else {
			let days = Math.floor(difference / (1000 * 60 * 60 * 24));
			let hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
			let minutes = Math.floor((difference / (1000 * 60)) % 60);
			let seconds = Math.floor((difference / 1000) % 60);

			this.setState({
				hours: hours > 9 ? hours : `0${hours}`,
				minutes: minutes > 9 ? minutes : `0${minutes}`,
				seconds: seconds > 9 ? seconds : `0${seconds}`,
				days,
			});
		}
	};

	render() {
		logger.log('[CountDownTimer] this:%o', this);
		const { days, hours, minutes, seconds, timeUp } = this.state;
		const dayString = days > 1 ? 'days' : 'day';
		if (this.state.days) {
			return timeUp ? (
				<h4 className="countdown-timer">
					Today is day {days} of <span>Fan Week</span>
				</h4>
			) : (
				//<p>{ `${days} ${dayString} ${hours} hours ${minutes}  minutes ${seconds} seconds` }</p>
				<h4 className="countdown-timer">
					{days} {dayString} left until <span>Fan Week</span>
				</h4>
			);
		} else {
			return null;
		}
	}
}
export default CountDownTimer;
