import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { getCleanLink, getAppLink } from 'appdir/components/general/Util';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import MeasurementUtils from 'appdir/lib/analytics';
import isEmpty from 'lodash/isEmpty';

export default class EventsLink extends Component {
	constructor(props) {
		//logger.log('[EventsLink] constructor - props:%o', props);

		super(props);

		let link = {
			url: '',
			external: false,
		};

		if (props.to) {
			if (window.webview) {
				link = getAppLink(
					props.to,
					props.title ? props.title : '',
					props.bypassAppLink ? props.bypassAppLink : false
				);
			} else if (!props.external) {
				link = getCleanLink(props.to);
			} else {
				link = {
					url: props.to,
					external: true,
				};
			}
		}

		this.state = {
			...props,
			link: link,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[EventsLink] getDerivedStateFromProps - name:%o next:%o', nextProps.name, nextProps);

		let link = {
			url: '',
			external: false,
		};

		if (nextProps.to) {
			if (window.webview) {
				link = getAppLink(
					nextProps.to,
					nextProps.title ? nextProps.title : '',
					nextProps.bypassAppLink ? nextProps.bypassAppLink : false
				);
			} else if (!nextProps.external) {
				link = getCleanLink(nextProps.to);
			} else {
				link = {
					url: nextProps.to,
					external: true,
				};
			}
		}

		let newState = {
			...prevState,
			...nextProps,
			link: link,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[EventsLink] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	openWindow(url) {
		window.open(url, '', this.state.options);
	}

	onClick(args) {
		logger.log('[EventsLink] onClick - args:%o', args);
		if (args.actionType) {
			MeasurementUtils.dispatchMeasurementCall(args.actionType, { args: args });
		} else {
			MeasurementUtils.dispatchMeasurementCall('eventsLink', { args: args });
		}
	}

	handleWebviewLink = link => {
		if (link) {
			location.href = link;
		}
	};

	handleChatLink = link => {
		logger.log('Chat Info: %o', link);
		let chatLink = link.split(':');
		let chatId = chatLink.length > 2 ? chatLink[2] : '21751';
		try {
			SatisfiApp.Global.chatPopupOpen();
		} catch (e) {
			window.open(
				'https://chat.satis.fi/?pageID=' + chatId,
				'USOchat',
				'resizable=no,status=no,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=no,dependent=no,width=375,height=406'
			);
		}
	}

	/**
	 * RLL combined 2 function - onClick (to handle measurement) and
	 * handleWebviewLink (to handle webview links - iOS no longer likes
	 * the href links)
	 * @param {*} measureArgs - metrics arguments or null
	 * @param {*} link - url for webview link or null
	 */
	handleMeasurementAndWebviewLink = (measureArgs, link) => {
		if (measureArgs) {
			if (measureArgs.actionType) {
				MeasurementUtils.dispatchMeasurementCall(measureArgs.actionType, { args: measureArgs });
			} else {
				MeasurementUtils.dispatchMeasurementCall('eventsLink', { args: measureArgs });
			}
		}

		if (link) {
			location.href = link;
		}
	};

	render() {
		// logger.log('[EventsLink] render - state:%o', this.state);
		// logger.log('[EventsLink] render - rend:%o', ( this.state.link && this.state.link.url && this.state.children));
		if (this.state.link && this.state.link.url && this.state.children) {
			if (this.state.popup) {
				let openStyle = {
					cursor: 'pointer',
				};

				return (
					<span style={openStyle} onClick={() => this.openWindow(this.state.link.url)}>
						{this.state.children}
					</span>
				);
			} else if (this.state.link.url.includes('satisfi:chat') ) {
				return(
					<a className={this.state.style ? this.state.style : ''} href="#" onClick={e => {
						e.preventDefault();
						this.handleChatLink(this.state.link.url);
						return false;
					}}>
						{this.state.children}
					</a>
				)			
			} else if (window.webview && !this.state.link.external) {
				return (
					<a href={this.state.link.url} className={this.state.style ? this.state.style : ''} target="_self">
						{this.state.children}
					</a>
				);
			} else if (this.state.link.external) {
				let className = this.state.style ? this.state.style : '';
				if (this.state.link.url.indexOf('.pdf') > -1) {
					className = '' ? (className = 'download') : className + ' download';
				} else {
					className = '' ? (className = 'external') : className + ' external';
				}

				if (this.props.measureData && window.webview && this.props.webviewCare) {
					return (
						<ButtonLinkText
							onClick={() =>
								this.handleMeasurementAndWebviewLink(this.props.measureData, this.state.link.url)
							}
							className={this.state.style ? this.state.style : ''}>
							{this.state.children}
						</ButtonLinkText>
					);
				} else if (this.props.measureData) {
					return (
						<a
							href={this.state.link.url}
							className={className}
							onClick={() => this.onClick(this.props.measureData)}
							target="_blank">
							{this.state.children}
						</a>
					);
				} else {
					if (window.webview && this.props.webviewCare) {
						/** iOS stops getting a message through normal <a href="{message}">
						 *  use onClick to force sending message
						 *
						 *  use <button> since href="javascript:" is not going to be supported by future React
						 *
						 *  props.webviewCare --- flag true if it requires special treatment
						 */
						return (
							<ButtonLinkText
								onClick={() => this.handleWebviewLink(this.state.link.url)}
								className={this.state.style ? this.state.style : ''}>
								{this.state.children}
							</ButtonLinkText>
						);
					} else {
						return (
							<a
								href={this.state.link.url}
								className={className}
								target="_blank"
								onClick={() => (this.props?.onClick ? this.props.onClick : null)}>
								{this.state.children}
							</a>
						);
					}
				}
			} else if (this.props.stateData) {
				logger.log('[EventsLink] render - stateData:%o', this.props.stateData);

				let _search =
					this?.state?.link?.url?.indexOf('?') !== -1
						? this?.state?.link?.url?.substring(this?.state?.link?.url?.indexOf('?'))
						: '';
				let _pathname = _search !== '' ? this?.state?.link?.url?.replace(_search, '') : this?.state?.link?.url;

				//logger.log('[EventsLink] render stateData - search:%o, pathname:%o', _search, _pathname);

				let _stateData = {};

				if (this.props.stateData) {
					_stateData = { ...this.props.stateData };
				}

				return (
					<Link
						to={{
							pathname: _pathname,
							search: _search,
							state: _stateData,
						}}
						smooth="true"
						// call onclick to set the origin that is used for metrics.
						onClick={this.props.stateData?.origin ? () => this.onClick(this.props.stateData) : null}
						className={this.state.style ? this.state.style : ''}>
						{this.state.children}
					</Link>
				);
			} else if (this.props.measureData) {
				return (
					<Link
						to={this.state.link.url}
						smooth
						className={this.state.style ? this.state.style : ''}
						onClick={() => this.onClick(this.props.measureData)}>
						{this.state.children}
					</Link>
				);
			} else {
				return (
					<Link
						to={this.state.link.url}
						smooth
						className={this.state.style ? this.state.style : ''}
						onClick={this.props.onClick}
						// data-text={this.props.text}
					>
						{this.state.children}
					</Link>
				);
			}
		} else if (this.state.children) {
			return <span>{this.state.children}</span>;
		} else {
			return null;
		}
	}
}
