/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Header from 'appdir/components/general/Header';
import Footer from 'appdir/components/general/Footer';
import PageHeader from 'appdir/components/general/PageHeader';
import NewsTile from 'appdir/components/content/NewsTile';
import AdTag from 'components/general/AdTag';
import SubHeader from 'appdir/components/general/SubHeader';
import MobileNav from 'appdir/components/general/MobileNav';
import MeasurementUtils from 'appdir/lib/analytics';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import EventsRedirect from 'appdir/components/general/EventsRedirect';

const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: NewsIndex
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['NewsIndex'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.NewsIndex.mount()),
	filter: settings => dispatch(deps.actions.NewsIndex.filter(settings)),
});

class GalleryLandingPage extends Component {
	constructor(props) {
		super(props);

		logger.log('[GalleryLanding] constructor - props:%o ', props);

		this.state = {
			loaded: false,
			hero: null,
			showAll: true,
			years: [],
			defaultDay: null,
			defaultDayId: null,
			defaultMonth: null,
			defaultYear: null,
			selectedYear: '',
			selectedMonth: '',
			selectedDay: '',
			selectedDayId: '',
			newsIndex: null,
			tournIndex: null,
			tournIdIndex: null,
			filter: null,
			urlParams: null,
			relatedContent: null,
			yearsAvailable: [],
			tournDatesAvailable: [],
			showingCells: 12,
		};

		this.loadedJson = ''; //keep track of last loaded json url
		this.filterType = '';

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Photos',
		});
	}

	componentDidMount() {
		logger.log('[GalleryLanding] componentDidMount - state:%o', this.state);
		// if (this.props.location.search != "") {
		//   this.getFilter(this.props.location.search);
		// }
	}

	componentWillUnmount() {
		this.props.filter({
			type: '',
			open: '',
		});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[GalleryLanding] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let url = ''; // redirect url
		let data = prevState.data; // json data
		// logger.log(
		// 	'[GalleryLanding] getDerivedStateFromProps - next props status:%o this state:%o next props:%o',
		// 	nextProps.status,
		// 	prevState,
		// 	nextProps
		// );

		let newState = {
			...prevState,
			nav: url,
			data: data,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[GalleryLanding] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[GalleryLanding] componentDidUpdate - this: %o', this);
		const showAll = this.state.showAll;
		const year = this.state.selectedYear;
		const month = this.state.selectedMonth;
		const day = this.state.selectedDay;
		const filter = JSON.stringify(this.state.urlParams);
		const newsIndex = this.state.newsIndex;
		const tournIndex = this.state.tournIndex;

		if (this.state.hero == null) {
			this.getHero();
		}

		if (!isEqual(prevProps, this.props)) {
			this.getFilter(this.props.location.search);
		}

		if (showAll) {
			if (newsIndex == null) {
				this.getNewsIndex();
			} else if (
				prevState.selectedMonth != month ||
				prevState.selectedYear != year ||
				prevState.showAll != showAll
			) {
				this.constructUrl();
				this.getRelatedContent();
			}
		} else {
			if (tournIndex == null) {
				this.getTournIndex();
			} else if (prevState.selectedDay != day || prevState.showAll != showAll) {
				this.constructUrl();
				this.getRelatedContent();
			}
		}
	}

	getFilter(search) {
		logger.log('[GalleryLanding] getFilter - this: %o', this);
		search = search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);
		//parsedQs = {year: "2018", month: "11", day: "", mode: "all", filter: ""}
		if (JSON.stringify(this.state.urlParams) != JSON.stringify(parsedQs)) {
			this.setState({
				urlParams: parsedQs,
			});
		}
	}

	parseFilter() {
		// parsedQs = {year: "2018", month: "11", day: "", mode: "all", filter: ""}
		const filter = this.state.urlParams;
		if (filter != null && filter.mode != null) {
			let showAll = this.state.showAll;
			let selectedYear = '';
			let selectedMonth = '';
			let selectedDayId = '';
			let selectedDay = '';

			logger.log('[GalleryLanding] Parse Filter: %o', filter);

			if (filter.year != undefined) {
				selectedYear = filter.year;
			}
			if (filter.month != undefined) {
				selectedMonth = filter.month;
			}
			if (filter.day != undefined) {
				selectedDayId = filter.day;
			}
			if (filter.mode != undefined) {
				showAll = filter.mode == 'all' ? true : false;
			} else {
				showAll = true;
			}

			this.setState({
				relatedContent: null,
				showAll: showAll,
				defaultYear: selectedYear,
				defaultMonth: selectedMonth,
				defaultDayId: selectedDayId,
			});
		}
	}

	constructUrl() {
		let urlString = '?year=<y>&month=<m>&day=<d>&mode=<mode>&filter=';

		if (this.state.showAll) {
			urlString = urlString.replace('<y>', () => {
				return this.state.selectedYear == '' ? this.state.defaultYear : this.state.selectedYear;
			});
			urlString = urlString.replace('<m>', () => {
				return this.state.selectedMonth == '' ? this.state.defaultMonth : this.state.selectedMonth;
			});
			urlString = urlString.replace('<d>', () => {
				return '';
			});
			urlString = urlString.replace('<mode>', () => {
				return 'all';
			});
		} else {
			urlString = urlString.replace('<y>', () => {
				return '';
			});
			urlString = urlString.replace('<m>', () => {
				return '';
			});
			urlString = urlString.replace('<d>', () => {
				return this.state.selectedDayId == '' ? this.state.defaultDayId : this.state.selectedDayId;
			});
			urlString = urlString.replace('<mode>', () => {
				return 'tourn';
			});
		}
		logger.log('[GalleryLanding] constructUrl: %o', urlString);

		// until url is fixed, this is breaking refresh
		// this.props.history.push({
		//   pathname: "/en_US/news/index.html",
		//   search: urlString
		// });
	}

	getNewsIndex() {
		logger.log('[GalleryLanding] getNewsIndex: %o', this);

		if (this.props.path != null) {
			let apiDataPath = this.props.path.replace('<section>', () => {
				return 'photos_index';
			});

			fetch(apiDataPath).then(result => {
				let dates = result.content.dates;
				let newsIndexDates = {};
				let firstObject = null;
				let years = {};
				let defaultYear = this.state?.urlParams?.year;
				let defaultMonth = this.state?.urlParams?.month;

				firstObject = dates[0];

				dates.forEach(function(element) {
					newsIndexDates[element.key] = {
						file: element.file,
					};
					years[element.year] = '';
				});

				if (
					(defaultYear == null && defaultMonth == null) ||
					newsIndexDates[defaultYear + defaultMonth] == null
				) {
					defaultYear = dates[0].year;
					defaultMonth = dates[0].month;
				}

				let yearsArray = Object.keys(years)
					.sort()
					.reverse();

				if (this.state.selectedMonth == '' || this.state.selectedYear == '') {
					this.setState(
						{
							newsIndex: newsIndexDates,
							firstNewsObject: firstObject,
							yearsAvailable: yearsArray,
							defaultYear: defaultYear,
							defaultMonth: defaultMonth,
							selectedYear: defaultYear,
							selectedMonth: defaultMonth,
						},
						() => {
							logger.log('[GalleryLanding] after getNewsIndex: %o', this.state);
						}
					);
				} else {
					this.setState(
						{
							newsIndex: newsIndexDates,
							firstNewsObject: firstObject,
							yearsAvailable: yearsArray,
							defaultYear: defaultYear,
							defaultMonth: defaultMonth,
						},
						() => {
							logger.log('[GalleryLanding] after getNewsIndex: %o', this.state);
						}
					);
				}
			});
		}
	}

	getTournIndex() {
		logger.log('[GalleryLanding] getTournIndex: %o', this);

		let apiDataPath = this.props.path.replace('<section>', () => {
			return 'photos_tourn_index';
		});

		fetch(apiDataPath).then(result => {
			const dates = result.content.dates;
			let tournIndexDates = {};
			let tournIdIndex = {};
			let firstObject = null;
			let newsFilterKey = null;
			let tournDates = [];
			let defaultDay = this.state.defaultDay != null ? this.state.defaultDay : null;
			let defaultDayId = this.state.urlParams.day != null ? this.state.urlParams.day : null;

			firstObject = dates[0];
			newsFilterKey = dates[0].display;

			dates.forEach(function(element) {
				tournIndexDates[element.display] = {
					file: element.file,
					key: element.key,
				};
				tournIdIndex[element.key] = {
					file: element.file,
					display: element.display,
				};
				tournDates.push({
					display: element.display,
					dayid: element.dayid,
					key: element.key,
				});
			});

			if (defaultDayId == null) {
				defaultDay = newsFilterKey;
				defaultDayId = dates[0].key;
			} else if (tournIdIndex[defaultDayId] != null) {
				defaultDay = tournIdIndex[defaultDayId].display;
			} else {
				defaultDay = newsFilterKey;
				defaultDayId = dates[0].key;
			}

			let tournArray = tournDates.sort((a, b) => {
				return a.dayid - b.dayid;
			});

			logger.log('[GalleryLanding] getTournIndex - tournIndexDates: %o', tournIndexDates);

			if (this.state.selectedDay == '' || this.state.selectedDayId == '') {
				this.setState(
					{
						tournIndex: tournIndexDates,
						tournIdIndex: tournIdIndex,
						firstTournObject: firstObject,
						tournDatesAvailable: tournArray,
						defaultDayId: defaultDayId,
						defaultDay: defaultDay,
						selectedDay: defaultDay,
						selectedDayId: defaultDayId,
					},
					() => {
						logger.log('[GalleryLanding] after getTournIndex: %o', this.state);
					}
				);
			} else {
				this.setState(
					{
						tournIndex: tournIndexDates,
						tournIdIndex: tournIdIndex,
						firstTournObject: firstObject,
						tournDatesAvailable: tournArray,
						defaultDayId: defaultDayId,
						defaultDay: defaultDay,
					},
					() => {
						logger.log('[GalleryLanding] after getTournIndex: %o', this.state);
					}
				);
			}
		});
	}

	getRelatedContent() {
		logger.log('[GalleryLanding] getRelatedContent: %o', this);

		let filePath = '';
		const newsIndex = this.state.newsIndex;
		const tournIndex = this.state.tournIndex;
		const showAll = this.state.showAll;
		let month = this.state.selectedMonth == '' ? this.state.defaultMonth : this.state.selectedMonth;
		let year = this.state.selectedYear == '' ? this.state.defaultYear : this.state.selectedYear;
		let day = this.state.selectedDay == '' ? this.state.defaultDay : this.state.selectedDay;

		// if(this.state.firstNewsObject != null) {
		//     filePath = this.state.firstNewsObject.file;
		// } else {
		//     filePath = this.state.newsIndexDates[this.state.newsFilterKey].file;
		// }
		if (showAll) {
			let key = String(year + month);
			if (newsIndex[key] != null) {
				filePath = newsIndex[key].file;
			} else {
				logger.log("[GalleryLanding] File path doesn't exist for key: %o", key);
				this.setEmptyRelatedContent();
			}
		} else {
			if (tournIndex[day] != null) {
				filePath = tournIndex[day].file;
			} else {
				logger.log("[GalleryLanding] File path doesn't exist for key: %o", day);
				this.setEmptyRelatedContent();
			}
		}

		if (filePath != '') {
			let path = this.props.newsFilterPath.replace('<url>', () => {
				return filePath;
			});

			fetch(path).then(result => {
				logger.log('[GalleryLanding] getRelatedContent - result:%o', result.content);
				const adObject = {
					type: 'ad',
				};

				if (result.content.length > 0) {
					if (result.content.length < 3) {
						result.content[result.content.length] = adObject;
					} else {
						result.content.splice(2, 0, adObject);
					}
					// The following are the options for ad placement:
					//  yearround
					//  tournament

					if (this.props.adMode == 'tournament' && result.content.length > 7) {
						for (var i = 7; i < result.content.length; i++) {
							if ((i - 2) % 5 == 0) {
								result.content.splice(i, 0, adObject);
							}
						}
					}
				}

				this.setState({
					relatedContent: result,
					showingCells: 12,
				});
			});
		}
	}

	setEmptyRelatedContent() {
		this.setState({
			relatedContent: {
				content: [],
			},
		});
	}

	getHero() {
		const path = this.props.heroPath;
		logger.log('[GalleryLanding] getHero - path: %o', path);
		fetch(path).then(result => {
			logger.log('[GalleryLanding] getHero - result: %o', result);
			this.setState({
				hero: result.hero,
			});
		});
	}

	checkError() {
		//check if need to display error
		let error = '';
		if (this.count == 0) {
			error = 'show';
		}
		return <div className={`errorMessage ${error}`}>There is no content for your selection.</div>;
	}

	render() {
		logger.log('[GalleryLanding] render - this:%o', this);

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: this.props.sponsor,
			title: 'Photos',
		};
		// let promo = this.state.promo;
		let header_propsData = {};

		logger.log('[GalleryLanding] render - header:%o', header_propsData);

		if (this.state.nav && this.state.nav != this.props.location.pathname) {
			return <EventsRedirect push to={this.state.nav} />;
		} else if (this.state.relatedContent) {
			this.count = 0;

			return (
				<section className="wrapper">
					<Header attributes={header_propsData} />
					<MobileNav />
					<SubHeader attributes={subheader_attributes} />

					{this.renderHero()}
					{this.filter()}
					{this.renderContent()}
					<Footer />
				</section>
			);
		} else {
			return (
				<section className="wrapper">
					<Header attributes={header_propsData} />
					{this.renderHero()}
					{/* <ScrollHandler hasData={(false)} filter={this.state.filters.type} /> */}
					{this.filter()}
					<div className="content-main">
						<LoadingIndicator />
					</div>
					<Footer />
				</section>
			);
		}
	}

	renderContent() {
		if (this.state.relatedContent == null) {
			return <LoadingIndicator />;
		}
		if (this.state.relatedContent.content.length == 0) {
			return this.renderNoNews();
		} else {
			return this.renderNews();
		}
	}

	renderNews() {
		let content = this.state.relatedContent.content;
		const numberOfCells = this.state.showingCells;

		return (
			<div className="content-main">
				<div className="news-tile">
					<div className="newsChips">
						<div className="chip_content">
							{content.map((item, index) => {
								if (index < numberOfCells) {
									if (item.type == 'gallery') {
										let newsObject = {};
										const imagePrefix = this.props.newsImagePath.replace('<url>', () => {
											return '';
										});
										newsObject['col-style'] = 'three-col';
										newsObject.contentId = item.contentId;
										newsObject.date = item.displayDate;
										newsObject.images = item.images[0];
										newsObject.shortTitle = item.shortTitle;
										newsObject.title = item.title;
										newsObject.prefix = imagePrefix;
										newsObject.type = item.type;
										newsObject.author = 'By ' + item.metadata.credit;
										newsObject.url = item.url;
										newsObject.onClick = e => {
											this.photosTileClicked(e);
										};

										return <NewsTile attributes={newsObject} key={item.contentId} />;
									} else {
										return (
											<div id="schedule-ad-3" className="news-ad">
												<AdTag
													adConfig={this.props.adConfig.landingpage}
													dfpNetworkId={this.props.adConfig.dfpNetworkId}
												/>
											</div>
										);
									}
								} else {
									return null;
								}
							})}
							{this.renderLoadMore()}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderNoNews() {
		return (
			<div className="news-tile">
				<div className="no-news">
					<p>No galleries were found for the current selection.</p>
				</div>
			</div>
		);
	}

	renderLoadMore() {
		if (this.state.relatedContent.content.length > this.state.showingCells) {
			return (
				<button
					onClick={e => {
						this.loadMorePressed(e);
					}}
					className="label">
					Load More
				</button>
			);
		} else {
			return null;
		}
	}

	loadMorePressed(e) {
		MeasurementUtils.dispatchMeasurementCall('Load More');
		this.setState({
			showingCells: this.state.showingCells + 12,
		});
	}

	renderHero() {
		if (this.state.hero) {
			const imagePath = this.props.newsImagePath.replace('<url>', () => {
				return this.state.hero.photo.desktop;
			});

			const header_propsData = {
				headerType: 'generic',
				title: !this.state.hero.title ? '---' : this.state.hero.title,
				shortTitle: !this.state.hero.type ? '---' : this.state.hero.type,
				titleElem: '.landing--header',
				metaTitle: !this.state.hero.type ? '' : this.state.hero.type,
				metaDescription: '',
				imageSrc: imagePath,
				metaDate: '',
				metaPlayers: '',
				link: this.state.hero.url,
			};

			return <PageHeader attributes={header_propsData} />;
		} else {
			return null;
		}
	}

	convertNewsTile(item) {
		let newsObject = {};
		newsObject['col-style'] = 'three-col';
		newsObject.contentId = item.contentId;
		newsObject.date = item.displayDate;
		newsObject.images = item.images;
		newsObject.shortTitle = item.shortTitle;
		newsObject.title = item.title;
		newsObject.type = item.type;
		newsObject.url = item.url;

		return <NewsTile attributes={item} />;
	}

	filter() {
		const showAll = this.state.showAll;
		return (
			<section className="filters">
				<div className="selection">
					<div className="selection">
						<div
							className={`photos all ${showAll ? 'active' : ''}`}
							onClick={e => {
								this.radioButtonClick('All');
							}}>
							<div className="radio" />
							All Photos
						</div>
						<div
							className={`photos tourny ${!showAll ? 'active' : ''}`}
							onClick={e => {
								this.radioButtonClick('Tourn');
							}}>
							<div className="radio select" />
							Tournament
						</div>
					</div>

					{this.renderSubFilter(showAll)}
				</div>
			</section>
		);
	}

	renderSubFilter(isShowingAll) {
		if (isShowingAll) {
			return (
				<div>
					{this.renderYears()}
					<div className="filter-dropdown">
						<select
							name="months"
							id="filterMonths"
							key="filterMonths"
							onChange={e => {
								this.getSelectMonth(e);
							}}
							value={this.state.selectedMonth}>
							<option key="01" value="01">
								January
							</option>
							<option key="02" value="02">
								February
							</option>
							<option key="03" value="03">
								March
							</option>
							<option key="04" value="04">
								April
							</option>
							<option key="05" value="05">
								May
							</option>
							<option key="06" value="06">
								June
							</option>
							<option key="07" value="07">
								July
							</option>
							<option key="08" value="08">
								August
							</option>
							<option key="09" value="09">
								September
							</option>
							<option key="10" value="10">
								October
							</option>
							<option key="11" value="11">
								November
							</option>
							<option key="12" value="12">
								December
							</option>
						</select>
					</div>
				</div>
			);
		} else {
			return this.renderTournDates();
		}
	}

	renderTournDates() {
		const content = this.state.tournDatesAvailable;
		return (
			<div className="filter-dropdown">
				<select
					name="tournDates"
					id="filtertournDates"
					key="filtertournDates"
					onChange={e => {
						this.getSelectedTournDay(e);
					}}>
					{content.map((item, index) => {
						return (
							<option
								key={`${item.display}-${index}`}
								value={item.display}
								selected={this.state.selectedDay != item.display ? false : true}>
								{item.display}
							</option>
						);
					})}
				</select>
			</div>
		);
	}

	renderYears() {
		const content = this.state.yearsAvailable;

		return (
			<div className="filter-dropdown">
				<select
					name="years"
					id="yearListXs"
					key="filterYears"
					onChange={e => {
						this.getSelectYear(e);
					}}
					value={this.state.selectedYear}>
					{content.map((item, index) => {
						return (
							<option key={`${item}-${index}`} value={item}>
								{item}
							</option>
						);
					})}
				</select>
			</div>
		);
	}

	getSelectMonth = e => {
		MeasurementUtils.dispatchMeasurementCall('GalleryLanding Select Month', {
			selection: e.target.value,
		});

		this.setState({
			selectedMonth: e.target.value,
			relatedContent: null,
		});
	};

	getSelectYear = e => {
		MeasurementUtils.dispatchMeasurementCall('GalleryLanding Select Year', {
			selection: e.target.value,
		});
		this.setState({
			selectedYear: e.target.value,
			relatedContent: null,
		});
	};

	getSelectedTournDay = e => {
		const value = e.target.value;
		const key = this.state.tournIndex[value].key;
		logger.log('[GalleryLanding] - getSelectedTournDay value: %o ', value);

		MeasurementUtils.dispatchMeasurementCall('GalleryLanding Select Day', {
			selection: value,
		});
		this.setState({
			selectedDay: e.target.value,
			selectedDayId: key,
			relatedContent: null,
		});
	};

	radioButtonClick(selection) {
		logger.log('[GalleryLanding] - radio button: ');
		let checked = selection === 'All' ? true : false;
		MeasurementUtils.dispatchMeasurementCall('GalleryLanding Select', {
			selection: selection,
		});
		this.setState({
			showAll: checked,
			relatedContent: null,
		});
		logger.log(checked);
		// console.dir(e);
	}

	photosTileClicked(url) {
		logger.log('[GalleryLanding] photosTileClicked - state:%o', this.state);

		this.setState({
			nav: url,
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryLandingPage);
