/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Loading Indicator
 * -----------------------------------------------------------------------------
 */
const LoadingIndicator = (props) => {
	let classes = 'loading-indicator';
	if (props.type) {
		classes = classes.concat(' ' + props.type);
	}

	return (
		<div className={classes}>
			<div>
				<div className="circle-one" />
				<div className="circle-two" />
				<div className="circle-three" />
			</div>
		</div>
	);
};

export default LoadingIndicator;
