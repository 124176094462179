/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Button from 'appdir/components/common-ui/Button';
import { getLikelihoodWinner, getLikelyhoodWinData } from '../MatchInsightsUtils';
import LikelihoodWinBanner from 'appdir/components/pages/MatchInsights/elements/LikelihoodWinBanner';
import PowerIndexChart from 'appdir/components/pages/MatchInsights/elements/PowerIndexChart';
import PlayerBox from './PlayerBox';
import MISectionHeader from './MISectionHeader';

/**
 * -----------------------------------------------------------------------------
 * React Component: PowerRanking for Match Insights
 * -----------------------------------------------------------------------------
 */
const PowerRanking = props => {
	// logger.log('[PowerRanking] - props:%o', props);

	let { teams, infoIcon, showInfoTip, powerIndexMatchupData, matchData } = props;
	let direction = getLikelihoodWinner(powerIndexMatchupData) == teams[0]['idA'] ? 'left' : 'right';

	const renderEventInfo = () => {
		return matchData ? (
			<div className="card-head">
				<div className="eventName">{`${matchData.eventName} ${String.fromCharCode(8211)} ${
					matchData.roundName
				}`}</div>
				<div className="courtName">{matchData.shortCourtName}</div>
			</div>
		) : null;
	};

	return (
		// <div className="mi-section mi-power-ranking-wrapper">
		<div className={`mi-section notitle no-border-bottom`} id={`${props?.type ? props.type + '-style' : ''}`}>
			<MISectionHeader
				// title="IBM Power Index"
				title=""
				infoIcon={infoIcon}
				onInfoClickFn={props.onInfoClickFn}
				lastUpdate={
					powerIndexMatchupData['publication_date_milliseconds']
						? powerIndexMatchupData['publication_date_milliseconds']
						: ''
				}
				style="power-index"
			/>

			{/** only mobile size to display the event info above L2W button
			 *  embedded MatchInsightsCard doesn't display this
			 */}
			<div className="below-desktop">
				{renderEventInfo()}
				<LikelihoodWinBanner direction={direction} />
			</div>

			<div className="above-desktop">
				<div className="mi-pr-player-info-wrapper">
					<div className="mi-pr-player-info player1">
						{direction === 'left' ? (
							<LikelihoodWinBanner direction={props?.type == 'news' ? direction : 'center'} />
						) : null}
					</div>
					<div className="v"></div>
					<div className="mi-pr-player-info player2">
						{direction === 'right' ? (
							<LikelihoodWinBanner direction={props?.type == 'news' ? direction : 'center'} />
						) : null}
					</div>
				</div>
			</div>

			<div className="embedded-wrapper">
				<div className="mi-pr-player-info-wrapper">
					{teams.map((team, i) => {
						return (
							<React.Fragment key={`playerKey${i + 1}-box`}>
								<PlayerBox
									data={team}
									likelihoodWinData={getLikelyhoodWinData(powerIndexMatchupData, team.idA)}
									teamnum={i + 1}
									imgPath={props.imgPath}
									flagPath={props.flagPath}
									key={`player${i + 1}-box`}
									type={props.type}
									isWebview={props.isWebview}
									isPanel={props.isPanel ? props.isPanel : false}
								/>
								{i === 0 ? (
									<div className="v" key={`playerbox-v${i}`}>
										{/* <div className="above-tablet">
											{ renderEventInfo()}
										</div> */}
										<PowerIndexChart
											teams={teams}
											matchId={matchData.match_id}
											powerRankingData={powerIndexMatchupData}
											type={props?.type}
										/>
									</div>
								) : null}
							</React.Fragment>
						);
					})}

					
				</div>
				{/* {props?.showWhyButton ? (
				<div className="where-to-watch">
					<div className="button"
						to={`/en_US/matchinsights/${matchData.match_id}.html`}
					>
						<i className="icon-blogIcon-video"></i>
						Where To Watch
					</div>
				</div>
				) : null} */}
			</div>
		</div>
	);
};

export default PowerRanking;
