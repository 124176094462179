/**
 * Generated by createManifest.js
 * DO NOT directly edit this file !!!!!!
 */

module.exports = {
    get: () => {
        return {
            allActions: {
                Controller: require('components/Controller/actions').default,
                ScoreManager: require('components/ScoreManager/actions')
                    .default,
                MatchBox: require('components/_scoring/MatchBox/actions')
                    .default,
                SummaryScoreboard: require('components/_scoring/SummaryScoreboard/actions')
                    .default,
                MatchInsightsCard: require('components/cms/MatchInsightsCard/actions')
                    .default,
                DrawAnalysis: require('components/common-ui/DrawAnalysis/actions')
                    .default,
                DrawAnalysisHelp: require('components/common-ui/DrawAnalysisHelp/actions')
                    .default,
                HaveYourSay: require('components/common-ui/HaveYourSay/actions')
                    .default,
                MatchInsightsModal: require('components/common-ui/MatchInsightsModal/actions')
                    .default,
                PageSubMenu: require('components/common-ui/PageSubMenu/actions')
                    .default,
                SmartSearch: require('components/common-ui/SmartSearch/actions')
                    .default,
                LiveVideoPlayer: require('components/content/LiveVideoPlayer/actions')
                    .default,
                Video: require('components/content/Video/actions').default,
                VideoPlayer: require('components/content/VideoPlayer/actions')
                    .default,
                ActiveData: require('components/data/ActiveData/actions')
                    .default,
                CommonData: require('components/data/CommonData/actions')
                    .default,
                Config: require('components/data/Config/actions').default,
                Tournament: require('components/data/Tournament/actions')
                    .default,
                AdUnitLazy: require('components/general/AdUnitLazy/actions')
                    .default,
                AgeGate: require('components/general/AgeGate/actions').default,
                Analytics: require('components/general/Analytics/actions')
                    .default,
                BreakingAlert: require('components/general/BreakingAlert/actions')
                    .default,
                FanWeekSchedule: require('components/general/FanWeekSchedule/actions')
                    .default,
                Footer: require('components/general/Footer/actions').default,
                Header: require('components/general/Header/actions').default,
                HomeHero: require('components/general/HomeHero/actions')
                    .default,
                LiveBar: require('components/general/LiveBar/actions').default,
                MainNav: require('components/general/MainNav/actions').default,
                MobileNav: require('components/general/MobileNav/actions')
                    .default,
                ModalOverlay: require('components/general/ModalOverlay/actions')
                    .default,
                OnCourtSchedule: require('components/general/OnCourtSchedule/actions')
                    .default,
                PageHeader: require('components/general/PageHeader/actions')
                    .default,
                PlayerFilters: require('components/general/PlayerFilters/actions')
                    .default,
                PlayerSeed: require('components/general/PlayerSeed/actions')
                    .default,
                ScrollHandler: require('components/general/ScrollHandler/actions')
                    .default,
                TicketsModule: require('components/general/TicketsModule/actions')
                    .default,
                VideoWrapper: require('components/general/VideoWrapper/actions')
                    .default,
                WindowSize: require('components/general/WindowSize/actions')
                    .default,
                ContentPage: require('components/pages/ContentPage/actions')
                    .default,
                CountryPlayersPage: require('components/pages/CountryPlayersPage/actions')
                    .default,
                DrawsPage: require('components/pages/DrawsPage/actions')
                    .default,
                EdgeConnectTest: require('components/pages/EdgeConnectTest/actions')
                    .default,
                FanWeek: require('components/pages/FanWeek/actions').default,
                GalleryLanding: require('components/pages/GalleryLanding/actions')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/actions')
                    .default,
                GeneralContentPage: require('components/pages/GeneralContentPage/actions')
                    .default,
                GroundsMap: require('components/pages/GroundsMap/actions')
                    .default,
                Home: require('components/pages/Home/actions').default,
                HomePreview: require('components/pages/HomePreview/actions')
                    .default,
                InsightsIndex: require('components/pages/InsightsIndex/actions')
                    .default,
                Interviews: require('components/pages/Interviews/actions')
                    .default,
                LiveAtUSOpen: require('components/pages/LiveAtUSOpen/actions')
                    .default,
                LivePractice: require('components/pages/LivePractice/actions')
                    .default,
                LiveScores: require('components/pages/LiveScores/actions')
                    .default,
                LiveShow: require('components/pages/LiveShow/actions').default,
                LiveVideo: require('components/pages/LiveVideo/actions')
                    .default,
                MatchInsights: require('components/pages/MatchInsights/actions')
                    .default,
                MediaLanding: require('components/pages/MediaLanding/actions')
                    .default,
                NewsIndex: require('components/pages/NewsIndex/actions')
                    .default,
                OpenQuestions: require('components/pages/OpenQuestions/actions')
                    .default,
                OpenQuestionsDetail: require('components/pages/OpenQuestionsDetail/actions')
                    .default,
                PastChamps: require('components/pages/PastChamps/actions')
                    .default,
                Photos: require('components/pages/Photos/actions').default,
                PlayerPage: require('components/pages/PlayerPage/actions')
                    .default,
                PlayerSearch: require('components/pages/PlayerSearch/actions')
                    .default,
                PlayerStatsPage: require('components/pages/PlayerStatsPage/actions')
                    .default,
                PlayersIndex: require('components/pages/PlayersIndex/actions')
                    .default,
                PowerIndexLeaderboard: require('components/pages/PowerIndexLeaderboard/actions')
                    .default,
                PracticeSchedulePage: require('components/pages/PracticeSchedulePage/actions')
                    .default,
                RadioLanding: require('components/pages/RadioLanding/actions')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/actions')
                    .default,
                Schedule: require('components/pages/Schedule/actions').default,
                SchedulePage: require('components/pages/SchedulePage/actions')
                    .default,
                SearchPage: require('components/pages/SearchPage/actions')
                    .default,
                SeedsListPage: require('components/pages/SeedsListPage/actions')
                    .default,
                SiteMap: require('components/pages/SiteMap/actions').default,
                SlamTrackerLanding: require('components/pages/SlamTrackerLanding/actions')
                    .default,
                Slamtracker: require('components/pages/Slamtracker/actions')
                    .default,
                SponsorLanding: require('components/pages/SponsorLanding/actions')
                    .default,
                TVSchedule: require('components/pages/TVSchedule/actions')
                    .default,
                VideoLanding: require('components/pages/VideoLanding/actions')
                    .default,
                VideoPlayerLanding: require('components/pages/VideoPlayerLanding/actions')
                    .default,
                WinFactorsFinder: require('components/pages/WinFactorsFinder/actions')
                    .default,
                ContentPageWebview: require('components/webviews/ContentPageWebview/actions')
                    .default,
                EventScheduleWebview: require('components/webviews/EventScheduleWebview/actions')
                    .default,
                FanWeekWebview: require('components/webviews/FanWeekWebview/actions')
                    .default,
                GeneralContentPageWebview: require('components/webviews/GeneralContentPageWebview/actions')
                    .default,
                GroundsMapMatchBox: require('components/webviews/GroundsMapMatchBox/actions')
                    .default,
                LiveAtUSOpenWebview: require('components/webviews/LiveAtUSOpenWebview/actions')
                    .default,
                OpenQuestionsDetailWebview: require('components/webviews/OpenQuestionsDetailWebview/actions')
                    .default,
                OpenQuestionsWebview: require('components/webviews/OpenQuestionsWebview/actions')
                    .default,
                PracticeScheduleWebview: require('components/webviews/PracticeScheduleWebview/actions')
                    .default,
                RadioPlayer: require('components/webviews/RadioPlayer/actions')
                    .default,
                ScheduleWebview: require('components/webviews/ScheduleWebview/actions')
                    .default,
                SlamtrackerDemo: require('components/webviews/SlamtrackerDemo/actions')
                    .default,
                SlamtrackerWebview: require('components/webviews/SlamtrackerWebview/actions')
                    .default,
                Plugable: require('dotix/components/Plugable/actions').default,
                Router: require('dotix/components/Router/actions').default,
            },
            allActionTypes: {
                Controller: require('components/Controller/actionTypes')
                    .default,
                ScoreManager: require('components/ScoreManager/actionTypes')
                    .default,
                MatchBox: require('components/_scoring/MatchBox/actionTypes')
                    .default,
                SummaryScoreboard: require('components/_scoring/SummaryScoreboard/actionTypes')
                    .default,
                MatchInsightsCard: require('components/cms/MatchInsightsCard/actionTypes')
                    .default,
                DrawAnalysis: require('components/common-ui/DrawAnalysis/actionTypes')
                    .default,
                DrawAnalysisHelp: require('components/common-ui/DrawAnalysisHelp/actionTypes')
                    .default,
                HaveYourSay: require('components/common-ui/HaveYourSay/actionTypes')
                    .default,
                MatchInsightsModal: require('components/common-ui/MatchInsightsModal/actionTypes')
                    .default,
                PageSubMenu: require('components/common-ui/PageSubMenu/actionTypes')
                    .default,
                SmartSearch: require('components/common-ui/SmartSearch/actionTypes')
                    .default,
                LiveVideoPlayer: require('components/content/LiveVideoPlayer/actionTypes')
                    .default,
                Video: require('components/content/Video/actionTypes').default,
                VideoPlayer: require('components/content/VideoPlayer/actionTypes')
                    .default,
                ActiveData: require('components/data/ActiveData/actionTypes')
                    .default,
                CommonData: require('components/data/CommonData/actionTypes')
                    .default,
                Config: require('components/data/Config/actionTypes').default,
                Tournament: require('components/data/Tournament/actionTypes')
                    .default,
                AdUnitLazy: require('components/general/AdUnitLazy/actionTypes')
                    .default,
                AgeGate: require('components/general/AgeGate/actionTypes')
                    .default,
                BreakingAlert: require('components/general/BreakingAlert/actionTypes')
                    .default,
                FanWeekSchedule: require('components/general/FanWeekSchedule/actionTypes')
                    .default,
                Footer: require('components/general/Footer/actionTypes')
                    .default,
                Header: require('components/general/Header/actionTypes')
                    .default,
                HomeHero: require('components/general/HomeHero/actionTypes')
                    .default,
                LiveBar: require('components/general/LiveBar/actionTypes')
                    .default,
                MainNav: require('components/general/MainNav/actionTypes')
                    .default,
                MobileNav: require('components/general/MobileNav/actionTypes')
                    .default,
                ModalOverlay: require('components/general/ModalOverlay/actionTypes')
                    .default,
                OnCourtSchedule: require('components/general/OnCourtSchedule/actionTypes')
                    .default,
                PageHeader: require('components/general/PageHeader/actionTypes')
                    .default,
                PlayerFilters: require('components/general/PlayerFilters/actionTypes')
                    .default,
                PlayerSeed: require('components/general/PlayerSeed/actionTypes')
                    .default,
                ScrollHandler: require('components/general/ScrollHandler/actionTypes')
                    .default,
                TicketsModule: require('components/general/TicketsModule/actionTypes')
                    .default,
                VideoWrapper: require('components/general/VideoWrapper/actionTypes')
                    .default,
                WindowSize: require('components/general/WindowSize/actionTypes')
                    .default,
                ContentPage: require('components/pages/ContentPage/actionTypes')
                    .default,
                CountryPlayersPage: require('components/pages/CountryPlayersPage/actionTypes')
                    .default,
                DrawsPage: require('components/pages/DrawsPage/actionTypes')
                    .default,
                EdgeConnectTest: require('components/pages/EdgeConnectTest/actionTypes')
                    .default,
                FanWeek: require('components/pages/FanWeek/actionTypes')
                    .default,
                GalleryLanding: require('components/pages/GalleryLanding/actionTypes')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/actionTypes')
                    .default,
                GeneralContentPage: require('components/pages/GeneralContentPage/actionTypes')
                    .default,
                GroundsMap: require('components/pages/GroundsMap/actionTypes')
                    .default,
                Home: require('components/pages/Home/actionTypes').default,
                HomePreview: require('components/pages/HomePreview/actionTypes')
                    .default,
                InsightsIndex: require('components/pages/InsightsIndex/actionTypes')
                    .default,
                Interviews: require('components/pages/Interviews/actionTypes')
                    .default,
                LiveAtUSOpen: require('components/pages/LiveAtUSOpen/actionTypes')
                    .default,
                LivePractice: require('components/pages/LivePractice/actionTypes')
                    .default,
                LiveScores: require('components/pages/LiveScores/actionTypes')
                    .default,
                LiveShow: require('components/pages/LiveShow/actionTypes')
                    .default,
                LiveVideo: require('components/pages/LiveVideo/actionTypes')
                    .default,
                MatchInsights: require('components/pages/MatchInsights/actionTypes')
                    .default,
                MediaLanding: require('components/pages/MediaLanding/actionTypes')
                    .default,
                NewsIndex: require('components/pages/NewsIndex/actionTypes')
                    .default,
                OpenQuestions: require('components/pages/OpenQuestions/actionTypes')
                    .default,
                OpenQuestionsDetail: require('components/pages/OpenQuestionsDetail/actionTypes')
                    .default,
                PastChamps: require('components/pages/PastChamps/actionTypes')
                    .default,
                Photos: require('components/pages/Photos/actionTypes').default,
                PlayerPage: require('components/pages/PlayerPage/actionTypes')
                    .default,
                PlayerSearch: require('components/pages/PlayerSearch/actionTypes')
                    .default,
                PlayerStatsPage: require('components/pages/PlayerStatsPage/actionTypes')
                    .default,
                PlayersIndex: require('components/pages/PlayersIndex/actionTypes')
                    .default,
                PowerIndexLeaderboard: require('components/pages/PowerIndexLeaderboard/actionTypes')
                    .default,
                PracticeSchedulePage: require('components/pages/PracticeSchedulePage/actionTypes')
                    .default,
                RadioLanding: require('components/pages/RadioLanding/actionTypes')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/actionTypes')
                    .default,
                Schedule: require('components/pages/Schedule/actionTypes')
                    .default,
                SchedulePage: require('components/pages/SchedulePage/actionTypes')
                    .default,
                SearchPage: require('components/pages/SearchPage/actionTypes')
                    .default,
                SeedsListPage: require('components/pages/SeedsListPage/actionTypes')
                    .default,
                SiteMap: require('components/pages/SiteMap/actionTypes')
                    .default,
                SlamTrackerLanding: require('components/pages/SlamTrackerLanding/actionTypes')
                    .default,
                Slamtracker: require('components/pages/Slamtracker/actionTypes')
                    .default,
                SponsorLanding: require('components/pages/SponsorLanding/actionTypes')
                    .default,
                TVSchedule: require('components/pages/TVSchedule/actionTypes')
                    .default,
                VideoLanding: require('components/pages/VideoLanding/actionTypes')
                    .default,
                VideoPlayerLanding: require('components/pages/VideoPlayerLanding/actionTypes')
                    .default,
                WinFactorsFinder: require('components/pages/WinFactorsFinder/actionTypes')
                    .default,
                ContentPageWebview: require('components/webviews/ContentPageWebview/actionTypes')
                    .default,
                EventScheduleWebview: require('components/webviews/EventScheduleWebview/actionTypes')
                    .default,
                FanWeekWebview: require('components/webviews/FanWeekWebview/actionTypes')
                    .default,
                GeneralContentPageWebview: require('components/webviews/GeneralContentPageWebview/actionTypes')
                    .default,
                GroundsMapMatchBox: require('components/webviews/GroundsMapMatchBox/actionTypes')
                    .default,
                LiveAtUSOpenWebview: require('components/webviews/LiveAtUSOpenWebview/actionTypes')
                    .default,
                OpenQuestionsDetailWebview: require('components/webviews/OpenQuestionsDetailWebview/actionTypes')
                    .default,
                OpenQuestionsWebview: require('components/webviews/OpenQuestionsWebview/actionTypes')
                    .default,
                PracticeScheduleWebview: require('components/webviews/PracticeScheduleWebview/actionTypes')
                    .default,
                RadioPlayer: require('components/webviews/RadioPlayer/actionTypes')
                    .default,
                ScheduleWebview: require('components/webviews/ScheduleWebview/actionTypes')
                    .default,
                SlamtrackerDemo: require('components/webviews/SlamtrackerDemo/actionTypes')
                    .default,
                SlamtrackerWebview: require('components/webviews/SlamtrackerWebview/actionTypes')
                    .default,
                Plugable: require('dotix/components/Plugable/actionTypes')
                    .default,
                Router: require('dotix/components/Router/actionTypes').default,
            },
            allReducers: {
                Controller: require('components/Controller/reducers').default,
                ScoreManager: require('components/ScoreManager/reducers')
                    .default,
                MatchBox: require('components/_scoring/MatchBox/reducers')
                    .default,
                SummaryScoreboard: require('components/_scoring/SummaryScoreboard/reducers')
                    .default,
                MatchInsightsCard: require('components/cms/MatchInsightsCard/reducers')
                    .default,
                DrawAnalysis: require('components/common-ui/DrawAnalysis/reducers')
                    .default,
                DrawAnalysisHelp: require('components/common-ui/DrawAnalysisHelp/reducers')
                    .default,
                HaveYourSay: require('components/common-ui/HaveYourSay/reducers')
                    .default,
                MatchInsightsModal: require('components/common-ui/MatchInsightsModal/reducers')
                    .default,
                PageSubMenu: require('components/common-ui/PageSubMenu/reducers')
                    .default,
                SmartSearch: require('components/common-ui/SmartSearch/reducers')
                    .default,
                LiveVideoPlayer: require('components/content/LiveVideoPlayer/reducers')
                    .default,
                Video: require('components/content/Video/reducers').default,
                VideoPlayer: require('components/content/VideoPlayer/reducers')
                    .default,
                ActiveData: require('components/data/ActiveData/reducers')
                    .default,
                CommonData: require('components/data/CommonData/reducers')
                    .default,
                Config: require('components/data/Config/reducers').default,
                Tournament: require('components/data/Tournament/reducers')
                    .default,
                AdUnitLazy: require('components/general/AdUnitLazy/reducers')
                    .default,
                AgeGate: require('components/general/AgeGate/reducers').default,
                BreakingAlert: require('components/general/BreakingAlert/reducers')
                    .default,
                FanWeekSchedule: require('components/general/FanWeekSchedule/reducers')
                    .default,
                Footer: require('components/general/Footer/reducers').default,
                Header: require('components/general/Header/reducers').default,
                HomeHero: require('components/general/HomeHero/reducers')
                    .default,
                LiveBar: require('components/general/LiveBar/reducers').default,
                MainNav: require('components/general/MainNav/reducers').default,
                MobileNav: require('components/general/MobileNav/reducers')
                    .default,
                ModalOverlay: require('components/general/ModalOverlay/reducers')
                    .default,
                OnCourtSchedule: require('components/general/OnCourtSchedule/reducers')
                    .default,
                PageHeader: require('components/general/PageHeader/reducers')
                    .default,
                PlayerFilters: require('components/general/PlayerFilters/reducers')
                    .default,
                PlayerSeed: require('components/general/PlayerSeed/reducers')
                    .default,
                ScrollHandler: require('components/general/ScrollHandler/reducers')
                    .default,
                TicketsModule: require('components/general/TicketsModule/reducers')
                    .default,
                VideoWrapper: require('components/general/VideoWrapper/reducers')
                    .default,
                WindowSize: require('components/general/WindowSize/reducers')
                    .default,
                ContentPage: require('components/pages/ContentPage/reducers')
                    .default,
                CountryPlayersPage: require('components/pages/CountryPlayersPage/reducers')
                    .default,
                DrawsPage: require('components/pages/DrawsPage/reducers')
                    .default,
                EdgeConnectTest: require('components/pages/EdgeConnectTest/reducers')
                    .default,
                FanWeek: require('components/pages/FanWeek/reducers').default,
                GalleryLanding: require('components/pages/GalleryLanding/reducers')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/reducers')
                    .default,
                GeneralContentPage: require('components/pages/GeneralContentPage/reducers')
                    .default,
                GroundsMap: require('components/pages/GroundsMap/reducers')
                    .default,
                Home: require('components/pages/Home/reducers').default,
                HomePreview: require('components/pages/HomePreview/reducers')
                    .default,
                InsightsIndex: require('components/pages/InsightsIndex/reducers')
                    .default,
                Interviews: require('components/pages/Interviews/reducers')
                    .default,
                LiveAtUSOpen: require('components/pages/LiveAtUSOpen/reducers')
                    .default,
                LivePractice: require('components/pages/LivePractice/reducers')
                    .default,
                LiveScores: require('components/pages/LiveScores/reducers')
                    .default,
                LiveShow: require('components/pages/LiveShow/reducers').default,
                LiveVideo: require('components/pages/LiveVideo/reducers')
                    .default,
                MatchInsights: require('components/pages/MatchInsights/reducers')
                    .default,
                MediaLanding: require('components/pages/MediaLanding/reducers')
                    .default,
                NewsIndex: require('components/pages/NewsIndex/reducers')
                    .default,
                OpenQuestions: require('components/pages/OpenQuestions/reducers')
                    .default,
                OpenQuestionsDetail: require('components/pages/OpenQuestionsDetail/reducers')
                    .default,
                PastChamps: require('components/pages/PastChamps/reducers')
                    .default,
                Photos: require('components/pages/Photos/reducers').default,
                PlayerPage: require('components/pages/PlayerPage/reducers')
                    .default,
                PlayerSearch: require('components/pages/PlayerSearch/reducers')
                    .default,
                PlayerStatsPage: require('components/pages/PlayerStatsPage/reducers')
                    .default,
                PlayersIndex: require('components/pages/PlayersIndex/reducers')
                    .default,
                PowerIndexLeaderboard: require('components/pages/PowerIndexLeaderboard/reducers')
                    .default,
                PracticeSchedulePage: require('components/pages/PracticeSchedulePage/reducers')
                    .default,
                RadioLanding: require('components/pages/RadioLanding/reducers')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/reducers')
                    .default,
                Schedule: require('components/pages/Schedule/reducers').default,
                SchedulePage: require('components/pages/SchedulePage/reducers')
                    .default,
                SearchPage: require('components/pages/SearchPage/reducers')
                    .default,
                SeedsListPage: require('components/pages/SeedsListPage/reducers')
                    .default,
                SiteMap: require('components/pages/SiteMap/reducers').default,
                SlamTrackerLanding: require('components/pages/SlamTrackerLanding/reducers')
                    .default,
                Slamtracker: require('components/pages/Slamtracker/reducers')
                    .default,
                SponsorLanding: require('components/pages/SponsorLanding/reducers')
                    .default,
                TVSchedule: require('components/pages/TVSchedule/reducers')
                    .default,
                VideoLanding: require('components/pages/VideoLanding/reducers')
                    .default,
                VideoPlayerLanding: require('components/pages/VideoPlayerLanding/reducers')
                    .default,
                WinFactorsFinder: require('components/pages/WinFactorsFinder/reducers')
                    .default,
                ContentPageWebview: require('components/webviews/ContentPageWebview/reducers')
                    .default,
                EventScheduleWebview: require('components/webviews/EventScheduleWebview/reducers')
                    .default,
                FanWeekWebview: require('components/webviews/FanWeekWebview/reducers')
                    .default,
                GeneralContentPageWebview: require('components/webviews/GeneralContentPageWebview/reducers')
                    .default,
                GroundsMapMatchBox: require('components/webviews/GroundsMapMatchBox/reducers')
                    .default,
                LiveAtUSOpenWebview: require('components/webviews/LiveAtUSOpenWebview/reducers')
                    .default,
                OpenQuestionsDetailWebview: require('components/webviews/OpenQuestionsDetailWebview/reducers')
                    .default,
                OpenQuestionsWebview: require('components/webviews/OpenQuestionsWebview/reducers')
                    .default,
                PracticeScheduleWebview: require('components/webviews/PracticeScheduleWebview/reducers')
                    .default,
                RadioPlayer: require('components/webviews/RadioPlayer/reducers')
                    .default,
                ScheduleWebview: require('components/webviews/ScheduleWebview/reducers')
                    .default,
                SlamtrackerDemo: require('components/webviews/SlamtrackerDemo/reducers')
                    .default,
                SlamtrackerWebview: require('components/webviews/SlamtrackerWebview/reducers')
                    .default,
                Plugable: require('dotix/components/Plugable/reducers').default,
                Router: require('dotix/components/Router/reducers').default,
            },
            allInitialStates: {
                Controller: require('components/Controller/state').default,
                ScoreManager: require('components/ScoreManager/state').default,
                SummaryScoreboard: require('components/_scoring/SummaryScoreboard/state')
                    .default,
                DrawAnalysis: require('components/common-ui/DrawAnalysis/state')
                    .default,
                DrawAnalysisHelp: require('components/common-ui/DrawAnalysisHelp/state')
                    .default,
                HaveYourSay: require('components/common-ui/HaveYourSay/state')
                    .default,
                MatchInsightsModal: require('components/common-ui/MatchInsightsModal/state')
                    .default,
                SmartSearch: require('components/common-ui/SmartSearch/state')
                    .default,
                LiveVideoPlayer: require('components/content/LiveVideoPlayer/state')
                    .default,
                NewsTile: require('components/content/NewsTile/state').default,
                VideoPlayer: require('components/content/VideoPlayer/state')
                    .default,
                ActiveData: require('components/data/ActiveData/state').default,
                CommonData: require('components/data/CommonData/state').default,
                Config: require('components/data/Config/state').default,
                Tournament: require('components/data/Tournament/state').default,
                AgeGate: require('components/general/AgeGate/state').default,
                FanWeekSchedule: require('components/general/FanWeekSchedule/state')
                    .default,
                Header: require('components/general/Header/state').default,
                MainNav: require('components/general/MainNav/state').default,
                MobileNav: require('components/general/MobileNav/state')
                    .default,
                ModalOverlay: require('components/general/ModalOverlay/state')
                    .default,
                OnCourtSchedule: require('components/general/OnCourtSchedule/state')
                    .default,
                PageHeader: require('components/general/PageHeader/state')
                    .default,
                PlayerSeed: require('components/general/PlayerSeed/state')
                    .default,
                ScrollHandler: require('components/general/ScrollHandler/state')
                    .default,
                TicketsModule: require('components/general/TicketsModule/state')
                    .default,
                VideoWrapper: require('components/general/VideoWrapper/state')
                    .default,
                ContentPage: require('components/pages/ContentPage/state')
                    .default,
                CountryPlayersPage: require('components/pages/CountryPlayersPage/state')
                    .default,
                DrawsPage: require('components/pages/DrawsPage/state').default,
                EdgeConnectTest: require('components/pages/EdgeConnectTest/state')
                    .default,
                GeneralContentPage: require('components/pages/GeneralContentPage/state')
                    .default,
                Home: require('components/pages/Home/state').default,
                LivePractice: require('components/pages/LivePractice/state')
                    .default,
                LiveScores: require('components/pages/LiveScores/state')
                    .default,
                LiveShow: require('components/pages/LiveShow/state').default,
                LiveVideo: require('components/pages/LiveVideo/state').default,
                MatchInsights: require('components/pages/MatchInsights/state')
                    .default,
                OpenQuestions: require('components/pages/OpenQuestions/state')
                    .default,
                PastChamps: require('components/pages/PastChamps/state')
                    .default,
                PlayerPage: require('components/pages/PlayerPage/state')
                    .default,
                PlayerSearch: require('components/pages/PlayerSearch/state')
                    .default,
                PlayerStatsPage: require('components/pages/PlayerStatsPage/state')
                    .default,
                PowerIndexLeaderboard: require('components/pages/PowerIndexLeaderboard/state')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/state')
                    .default,
                SchedulePage: require('components/pages/SchedulePage/state')
                    .default,
                SearchPage: require('components/pages/SearchPage/state')
                    .default,
                SeedsListPage: require('components/pages/SeedsListPage/state')
                    .default,
                SiteMap: require('components/pages/SiteMap/state').default,
                Slamtracker: require('components/pages/Slamtracker/state')
                    .default,
                SponsorLanding: require('components/pages/SponsorLanding/state')
                    .default,
                TVSchedule: require('components/pages/TVSchedule/state')
                    .default,
                VideoLanding: require('components/pages/VideoLanding/state')
                    .default,
                ContentPageWebview: require('components/webviews/ContentPageWebview/state')
                    .default,
                GeneralContentPageWebview: require('components/webviews/GeneralContentPageWebview/state')
                    .default,
                RadioPlayer: require('components/webviews/RadioPlayer/state')
                    .default,
                ScheduleWebview: require('components/webviews/ScheduleWebview/state')
                    .default,
                SlamtrackerDemo: require('components/webviews/SlamtrackerDemo/state')
                    .default,
                SlamtrackerWebview: require('components/webviews/SlamtrackerWebview/state')
                    .default,
                Plugable: require('dotix/components/Plugable/state').default,
            },
            allRoutes: {
                SummaryScoreboard: require('components/_scoring/SummaryScoreboard/route')
                    .default,
                Config: require('components/data/Config/route').default,
                Dining: require('components/general/Dining/route').default,
                Accessibility: require('components/pages/Accessibility/route')
                    .default,
                AdsLanding: require('components/pages/AdsLanding/route')
                    .default,
                AnyclipTest: require('components/pages/AnyclipTest/route')
                    .default,
                AppLanding: require('components/pages/AppLanding/route')
                    .default,
                ContentPage: require('components/pages/ContentPage/route')
                    .default,
                CountryPlayersPage: require('components/pages/CountryPlayersPage/route')
                    .default,
                DrawsPage: require('components/pages/DrawsPage/route').default,
                EdgeConnectTest: require('components/pages/EdgeConnectTest/route')
                    .default,
                FanWeek: require('components/pages/FanWeek/route').default,
                FeedbackThankYou: require('components/pages/FeedbackThankYou/route')
                    .default,
                GalleryLanding: require('components/pages/GalleryLanding/route')
                    .default,
                GalleryPage: require('components/pages/GalleryPage/route')
                    .default,
                GeneralContentPage: require('components/pages/GeneralContentPage/route')
                    .default,
                GroundsMap: require('components/pages/GroundsMap/route')
                    .default,
                Home: require('components/pages/Home/route').default,
                HomePreview: require('components/pages/HomePreview/route')
                    .default,
                InternationalSchedule: require('components/pages/InternationalSchedule/route')
                    .default,
                Interviews: require('components/pages/Interviews/route')
                    .default,
                LiveAtUSOpen: require('components/pages/LiveAtUSOpen/route')
                    .default,
                LivePractice: require('components/pages/LivePractice/route')
                    .default,
                LiveScores: require('components/pages/LiveScores/route')
                    .default,
                LiveShow: require('components/pages/LiveShow/route').default,
                LiveVideo: require('components/pages/LiveVideo/route').default,
                MediaLanding: require('components/pages/MediaLanding/route')
                    .default,
                NetworkTest: require('components/pages/NetworkTest/route')
                    .default,
                NewsIndex: require('components/pages/NewsIndex/route').default,
                OpenQuestions: require('components/pages/OpenQuestions/route')
                    .default,
                OpenQuestionsDetail: require('components/pages/OpenQuestionsDetail/route')
                    .default,
                OpenQuestionsTerms: require('components/pages/OpenQuestionsTerms/route')
                    .default,
                PastChamps: require('components/pages/PastChamps/route')
                    .default,
                Photos: require('components/pages/Photos/route').default,
                PlayerPage: require('components/pages/PlayerPage/route')
                    .default,
                PlayerSearch: require('components/pages/PlayerSearch/route')
                    .default,
                PlayerStatsPage: require('components/pages/PlayerStatsPage/route')
                    .default,
                PlayersIndex: require('components/pages/PlayersIndex/route')
                    .default,
                PracticeSchedulePage: require('components/pages/PracticeSchedulePage/route')
                    .default,
                RadioLanding: require('components/pages/RadioLanding/route')
                    .default,
                ResultsPage: require('components/pages/ResultsPage/route')
                    .default,
                Schedule: require('components/pages/Schedule/route').default,
                SchedulePage: require('components/pages/SchedulePage/route')
                    .default,
                SearchPage: require('components/pages/SearchPage/route')
                    .default,
                SeedsListPage: require('components/pages/SeedsListPage/route')
                    .default,
                SiteMap: require('components/pages/SiteMap/route').default,
                SlamTrackerLanding: require('components/pages/SlamTrackerLanding/route')
                    .default,
                Slamtracker: require('components/pages/Slamtracker/route')
                    .default,
                SponsorLanding: require('components/pages/SponsorLanding/route')
                    .default,
                TVSchedule: require('components/pages/TVSchedule/route')
                    .default,
                TestPage: require('components/pages/TestPage/route').default,
                VideoLanding: require('components/pages/VideoLanding/route')
                    .default,
                VideoPlayerLanding: require('components/pages/VideoPlayerLanding/route')
                    .default,
                WinFactorsFinder: require('components/pages/WinFactorsFinder/route')
                    .default,
                ContentPageWebview: require('components/webviews/ContentPageWebview/route')
                    .default,
                DiningWebview: require('components/webviews/DiningWebview/route')
                    .default,
                DrawsWebview: require('components/webviews/DrawsWebview/route')
                    .default,
                EventScheduleWebview: require('components/webviews/EventScheduleWebview/route')
                    .default,
                FanWeekWebview: require('components/webviews/FanWeekWebview/route')
                    .default,
                GeneralContentPageWebview: require('components/webviews/GeneralContentPageWebview/route')
                    .default,
                GroundsMapMatchBox: require('components/webviews/GroundsMapMatchBox/route')
                    .default,
                LiveAtUSOpenWebview: require('components/webviews/LiveAtUSOpenWebview/route')
                    .default,
                OpenQuestionsDetailWebview: require('components/webviews/OpenQuestionsDetailWebview/route')
                    .default,
                OpenQuestionsWebview: require('components/webviews/OpenQuestionsWebview/route')
                    .default,
                PracticeScheduleWebview: require('components/webviews/PracticeScheduleWebview/route')
                    .default,
                RadioPlayer: require('components/webviews/RadioPlayer/route')
                    .default,
                ScheduleWebview: require('components/webviews/ScheduleWebview/route')
                    .default,
                SlamtrackerDemo: require('components/webviews/SlamtrackerDemo/route')
                    .default,
                SlamtrackerWebview: require('components/webviews/SlamtrackerWebview/route')
                    .default,
                WatchWebview: require('components/webviews/WatchWebview/route')
                    .default,
                WatsonxInfoOverlayWebview: require('components/webviews/WatsonxInfoOverlayWebview/route')
                    .default,
            },
            allServices: {
                CommonData: require('components/data/CommonData/services')
                    .default,
                Config: require('components/data/Config/services').default,
                Tournament: require('components/data/Tournament/services')
                    .default,
                PageHeader: require('components/general/PageHeader/services')
                    .default,
                PlayerFilters: require('components/general/PlayerFilters/services')
                    .default,
                PlayerSeed: require('components/general/PlayerSeed/services')
                    .default,
                TicketsModule: require('components/general/TicketsModule/services')
                    .default,
                VideoWrapper: require('components/general/VideoWrapper/services')
                    .default,
                EdgeConnectTest: require('components/pages/EdgeConnectTest/services')
                    .default,
                LivePractice: require('components/pages/LivePractice/services')
                    .default,
                LiveScores: require('components/pages/LiveScores/services')
                    .default,
                LiveShow: require('components/pages/LiveShow/services').default,
                LiveVideo: require('components/pages/LiveVideo/services')
                    .default,
                OpenQuestions: require('components/pages/OpenQuestions/services')
                    .default,
                PlayerPage: require('components/pages/PlayerPage/services')
                    .default,
                RadioLanding: require('components/pages/RadioLanding/services')
                    .default,
                SchedulePage: require('components/pages/SchedulePage/services')
                    .default,
                SearchPage: require('components/pages/SearchPage/services')
                    .default,
                SlamTrackerLanding: require('components/pages/SlamTrackerLanding/services')
                    .default,
                GroundsMapMatchBox: require('components/webviews/GroundsMapMatchBox/services')
                    .default,
                OpenQuestionsDetailWebview: require('components/webviews/OpenQuestionsDetailWebview/services')
                    .default,
                OpenQuestionsWebview: require('components/webviews/OpenQuestionsWebview/services')
                    .default,
                SlamtrackerDemo: require('components/webviews/SlamtrackerDemo/services')
                    .default,
                SlamtrackerWebview: require('components/webviews/SlamtrackerWebview/services')
                    .default,
            },
            allMiddleware: {
                redux: require('dotix/redux/middleware').default,
            },
            allEnhancers: {
                redux: require('dotix/redux/enhancer').default,
            },
        };
    },
    contexts: {
        components:
            typeof window !== 'undefined' &&
            require.context('components', true, /.jsx?$/),
        dotix:
            typeof window !== 'undefined' &&
            require.context('dotix/components', true, /.jsx?$/),
    },
    listContexts: () => {
        return;
    },
    list: () => {
        return {
            allActions: {
                type: 'actions',
                imports: [
                    'components/Controller/actions',
                    'components/ScoreManager/actions',
                    'components/_scoring/MatchBox/actions',
                    'components/_scoring/SummaryScoreboard/actions',
                    'components/cms/MatchInsightsCard/actions',
                    'components/common-ui/DrawAnalysis/actions',
                    'components/common-ui/DrawAnalysisHelp/actions',
                    'components/common-ui/HaveYourSay/actions',
                    'components/common-ui/MatchInsightsModal/actions',
                    'components/common-ui/PageSubMenu/actions',
                    'components/common-ui/SmartSearch/actions',
                    'components/content/LiveVideoPlayer/actions',
                    'components/content/Video/actions',
                    'components/content/VideoPlayer/actions',
                    'components/data/ActiveData/actions',
                    'components/data/CommonData/actions',
                    'components/data/Config/actions',
                    'components/data/Tournament/actions',
                    'components/general/AdUnitLazy/actions',
                    'components/general/AgeGate/actions',
                    'components/general/Analytics/actions',
                    'components/general/BreakingAlert/actions',
                    'components/general/FanWeekSchedule/actions',
                    'components/general/Footer/actions',
                    'components/general/Header/actions',
                    'components/general/HomeHero/actions',
                    'components/general/LiveBar/actions',
                    'components/general/MainNav/actions',
                    'components/general/MobileNav/actions',
                    'components/general/ModalOverlay/actions',
                    'components/general/OnCourtSchedule/actions',
                    'components/general/PageHeader/actions',
                    'components/general/PlayerFilters/actions',
                    'components/general/PlayerSeed/actions',
                    'components/general/ScrollHandler/actions',
                    'components/general/TicketsModule/actions',
                    'components/general/VideoWrapper/actions',
                    'components/general/WindowSize/actions',
                    'components/pages/ContentPage/actions',
                    'components/pages/CountryPlayersPage/actions',
                    'components/pages/DrawsPage/actions',
                    'components/pages/EdgeConnectTest/actions',
                    'components/pages/FanWeek/actions',
                    'components/pages/GalleryLanding/actions',
                    'components/pages/GalleryPage/actions',
                    'components/pages/GeneralContentPage/actions',
                    'components/pages/GroundsMap/actions',
                    'components/pages/Home/actions',
                    'components/pages/HomePreview/actions',
                    'components/pages/InsightsIndex/actions',
                    'components/pages/Interviews/actions',
                    'components/pages/LiveAtUSOpen/actions',
                    'components/pages/LivePractice/actions',
                    'components/pages/LiveScores/actions',
                    'components/pages/LiveShow/actions',
                    'components/pages/LiveVideo/actions',
                    'components/pages/MatchInsights/actions',
                    'components/pages/MediaLanding/actions',
                    'components/pages/NewsIndex/actions',
                    'components/pages/OpenQuestions/actions',
                    'components/pages/OpenQuestionsDetail/actions',
                    'components/pages/PastChamps/actions',
                    'components/pages/Photos/actions',
                    'components/pages/PlayerPage/actions',
                    'components/pages/PlayerSearch/actions',
                    'components/pages/PlayerStatsPage/actions',
                    'components/pages/PlayersIndex/actions',
                    'components/pages/PowerIndexLeaderboard/actions',
                    'components/pages/PracticeSchedulePage/actions',
                    'components/pages/RadioLanding/actions',
                    'components/pages/ResultsPage/actions',
                    'components/pages/Schedule/actions',
                    'components/pages/SchedulePage/actions',
                    'components/pages/SearchPage/actions',
                    'components/pages/SeedsListPage/actions',
                    'components/pages/SiteMap/actions',
                    'components/pages/SlamTrackerLanding/actions',
                    'components/pages/Slamtracker/actions',
                    'components/pages/SponsorLanding/actions',
                    'components/pages/TVSchedule/actions',
                    'components/pages/VideoLanding/actions',
                    'components/pages/VideoPlayerLanding/actions',
                    'components/pages/WinFactorsFinder/actions',
                    'components/webviews/ContentPageWebview/actions',
                    'components/webviews/EventScheduleWebview/actions',
                    'components/webviews/FanWeekWebview/actions',
                    'components/webviews/GeneralContentPageWebview/actions',
                    'components/webviews/GroundsMapMatchBox/actions',
                    'components/webviews/LiveAtUSOpenWebview/actions',
                    'components/webviews/OpenQuestionsDetailWebview/actions',
                    'components/webviews/OpenQuestionsWebview/actions',
                    'components/webviews/PracticeScheduleWebview/actions',
                    'components/webviews/RadioPlayer/actions',
                    'components/webviews/ScheduleWebview/actions',
                    'components/webviews/SlamtrackerDemo/actions',
                    'components/webviews/SlamtrackerWebview/actions',
                    'dotix/components/Plugable/actions',
                    'dotix/components/Router/actions',
                ],
            },
            allActionTypes: {
                type: 'actionTypes',
                imports: [
                    'components/Controller/actionTypes',
                    'components/ScoreManager/actionTypes',
                    'components/_scoring/MatchBox/actionTypes',
                    'components/_scoring/SummaryScoreboard/actionTypes',
                    'components/cms/MatchInsightsCard/actionTypes',
                    'components/common-ui/DrawAnalysis/actionTypes',
                    'components/common-ui/DrawAnalysisHelp/actionTypes',
                    'components/common-ui/HaveYourSay/actionTypes',
                    'components/common-ui/MatchInsightsModal/actionTypes',
                    'components/common-ui/PageSubMenu/actionTypes',
                    'components/common-ui/SmartSearch/actionTypes',
                    'components/content/LiveVideoPlayer/actionTypes',
                    'components/content/Video/actionTypes',
                    'components/content/VideoPlayer/actionTypes',
                    'components/data/ActiveData/actionTypes',
                    'components/data/CommonData/actionTypes',
                    'components/data/Config/actionTypes',
                    'components/data/Tournament/actionTypes',
                    'components/general/AdUnitLazy/actionTypes',
                    'components/general/AgeGate/actionTypes',
                    'components/general/BreakingAlert/actionTypes',
                    'components/general/FanWeekSchedule/actionTypes',
                    'components/general/Footer/actionTypes',
                    'components/general/Header/actionTypes',
                    'components/general/HomeHero/actionTypes',
                    'components/general/LiveBar/actionTypes',
                    'components/general/MainNav/actionTypes',
                    'components/general/MobileNav/actionTypes',
                    'components/general/ModalOverlay/actionTypes',
                    'components/general/OnCourtSchedule/actionTypes',
                    'components/general/PageHeader/actionTypes',
                    'components/general/PlayerFilters/actionTypes',
                    'components/general/PlayerSeed/actionTypes',
                    'components/general/ScrollHandler/actionTypes',
                    'components/general/TicketsModule/actionTypes',
                    'components/general/VideoWrapper/actionTypes',
                    'components/general/WindowSize/actionTypes',
                    'components/pages/ContentPage/actionTypes',
                    'components/pages/CountryPlayersPage/actionTypes',
                    'components/pages/DrawsPage/actionTypes',
                    'components/pages/EdgeConnectTest/actionTypes',
                    'components/pages/FanWeek/actionTypes',
                    'components/pages/GalleryLanding/actionTypes',
                    'components/pages/GalleryPage/actionTypes',
                    'components/pages/GeneralContentPage/actionTypes',
                    'components/pages/GroundsMap/actionTypes',
                    'components/pages/Home/actionTypes',
                    'components/pages/HomePreview/actionTypes',
                    'components/pages/InsightsIndex/actionTypes',
                    'components/pages/Interviews/actionTypes',
                    'components/pages/LiveAtUSOpen/actionTypes',
                    'components/pages/LivePractice/actionTypes',
                    'components/pages/LiveScores/actionTypes',
                    'components/pages/LiveShow/actionTypes',
                    'components/pages/LiveVideo/actionTypes',
                    'components/pages/MatchInsights/actionTypes',
                    'components/pages/MediaLanding/actionTypes',
                    'components/pages/NewsIndex/actionTypes',
                    'components/pages/OpenQuestions/actionTypes',
                    'components/pages/OpenQuestionsDetail/actionTypes',
                    'components/pages/PastChamps/actionTypes',
                    'components/pages/Photos/actionTypes',
                    'components/pages/PlayerPage/actionTypes',
                    'components/pages/PlayerSearch/actionTypes',
                    'components/pages/PlayerStatsPage/actionTypes',
                    'components/pages/PlayersIndex/actionTypes',
                    'components/pages/PowerIndexLeaderboard/actionTypes',
                    'components/pages/PracticeSchedulePage/actionTypes',
                    'components/pages/RadioLanding/actionTypes',
                    'components/pages/ResultsPage/actionTypes',
                    'components/pages/Schedule/actionTypes',
                    'components/pages/SchedulePage/actionTypes',
                    'components/pages/SearchPage/actionTypes',
                    'components/pages/SeedsListPage/actionTypes',
                    'components/pages/SiteMap/actionTypes',
                    'components/pages/SlamTrackerLanding/actionTypes',
                    'components/pages/Slamtracker/actionTypes',
                    'components/pages/SponsorLanding/actionTypes',
                    'components/pages/TVSchedule/actionTypes',
                    'components/pages/VideoLanding/actionTypes',
                    'components/pages/VideoPlayerLanding/actionTypes',
                    'components/pages/WinFactorsFinder/actionTypes',
                    'components/webviews/ContentPageWebview/actionTypes',
                    'components/webviews/EventScheduleWebview/actionTypes',
                    'components/webviews/FanWeekWebview/actionTypes',
                    'components/webviews/GeneralContentPageWebview/actionTypes',
                    'components/webviews/GroundsMapMatchBox/actionTypes',
                    'components/webviews/LiveAtUSOpenWebview/actionTypes',
                    'components/webviews/OpenQuestionsDetailWebview/actionTypes',
                    'components/webviews/OpenQuestionsWebview/actionTypes',
                    'components/webviews/PracticeScheduleWebview/actionTypes',
                    'components/webviews/RadioPlayer/actionTypes',
                    'components/webviews/ScheduleWebview/actionTypes',
                    'components/webviews/SlamtrackerDemo/actionTypes',
                    'components/webviews/SlamtrackerWebview/actionTypes',
                    'dotix/components/Plugable/actionTypes',
                    'dotix/components/Router/actionTypes',
                ],
            },
            allReducers: {
                type: 'reducers',
                imports: [
                    'components/Controller/reducers',
                    'components/ScoreManager/reducers',
                    'components/_scoring/MatchBox/reducers',
                    'components/_scoring/SummaryScoreboard/reducers',
                    'components/cms/MatchInsightsCard/reducers',
                    'components/common-ui/DrawAnalysis/reducers',
                    'components/common-ui/DrawAnalysisHelp/reducers',
                    'components/common-ui/HaveYourSay/reducers',
                    'components/common-ui/MatchInsightsModal/reducers',
                    'components/common-ui/PageSubMenu/reducers',
                    'components/common-ui/SmartSearch/reducers',
                    'components/content/LiveVideoPlayer/reducers',
                    'components/content/Video/reducers',
                    'components/content/VideoPlayer/reducers',
                    'components/data/ActiveData/reducers',
                    'components/data/CommonData/reducers',
                    'components/data/Config/reducers',
                    'components/data/Tournament/reducers',
                    'components/general/AdUnitLazy/reducers',
                    'components/general/AgeGate/reducers',
                    'components/general/BreakingAlert/reducers',
                    'components/general/FanWeekSchedule/reducers',
                    'components/general/Footer/reducers',
                    'components/general/Header/reducers',
                    'components/general/HomeHero/reducers',
                    'components/general/LiveBar/reducers',
                    'components/general/MainNav/reducers',
                    'components/general/MobileNav/reducers',
                    'components/general/ModalOverlay/reducers',
                    'components/general/OnCourtSchedule/reducers',
                    'components/general/PageHeader/reducers',
                    'components/general/PlayerFilters/reducers',
                    'components/general/PlayerSeed/reducers',
                    'components/general/ScrollHandler/reducers',
                    'components/general/TicketsModule/reducers',
                    'components/general/VideoWrapper/reducers',
                    'components/general/WindowSize/reducers',
                    'components/pages/ContentPage/reducers',
                    'components/pages/CountryPlayersPage/reducers',
                    'components/pages/DrawsPage/reducers',
                    'components/pages/EdgeConnectTest/reducers',
                    'components/pages/FanWeek/reducers',
                    'components/pages/GalleryLanding/reducers',
                    'components/pages/GalleryPage/reducers',
                    'components/pages/GeneralContentPage/reducers',
                    'components/pages/GroundsMap/reducers',
                    'components/pages/Home/reducers',
                    'components/pages/HomePreview/reducers',
                    'components/pages/InsightsIndex/reducers',
                    'components/pages/Interviews/reducers',
                    'components/pages/LiveAtUSOpen/reducers',
                    'components/pages/LivePractice/reducers',
                    'components/pages/LiveScores/reducers',
                    'components/pages/LiveShow/reducers',
                    'components/pages/LiveVideo/reducers',
                    'components/pages/MatchInsights/reducers',
                    'components/pages/MediaLanding/reducers',
                    'components/pages/NewsIndex/reducers',
                    'components/pages/OpenQuestions/reducers',
                    'components/pages/OpenQuestionsDetail/reducers',
                    'components/pages/PastChamps/reducers',
                    'components/pages/Photos/reducers',
                    'components/pages/PlayerPage/reducers',
                    'components/pages/PlayerSearch/reducers',
                    'components/pages/PlayerStatsPage/reducers',
                    'components/pages/PlayersIndex/reducers',
                    'components/pages/PowerIndexLeaderboard/reducers',
                    'components/pages/PracticeSchedulePage/reducers',
                    'components/pages/RadioLanding/reducers',
                    'components/pages/ResultsPage/reducers',
                    'components/pages/Schedule/reducers',
                    'components/pages/SchedulePage/reducers',
                    'components/pages/SearchPage/reducers',
                    'components/pages/SeedsListPage/reducers',
                    'components/pages/SiteMap/reducers',
                    'components/pages/SlamTrackerLanding/reducers',
                    'components/pages/Slamtracker/reducers',
                    'components/pages/SponsorLanding/reducers',
                    'components/pages/TVSchedule/reducers',
                    'components/pages/VideoLanding/reducers',
                    'components/pages/VideoPlayerLanding/reducers',
                    'components/pages/WinFactorsFinder/reducers',
                    'components/webviews/ContentPageWebview/reducers',
                    'components/webviews/EventScheduleWebview/reducers',
                    'components/webviews/FanWeekWebview/reducers',
                    'components/webviews/GeneralContentPageWebview/reducers',
                    'components/webviews/GroundsMapMatchBox/reducers',
                    'components/webviews/LiveAtUSOpenWebview/reducers',
                    'components/webviews/OpenQuestionsDetailWebview/reducers',
                    'components/webviews/OpenQuestionsWebview/reducers',
                    'components/webviews/PracticeScheduleWebview/reducers',
                    'components/webviews/RadioPlayer/reducers',
                    'components/webviews/ScheduleWebview/reducers',
                    'components/webviews/SlamtrackerDemo/reducers',
                    'components/webviews/SlamtrackerWebview/reducers',
                    'dotix/components/Plugable/reducers',
                    'dotix/components/Router/reducers',
                ],
            },
            allInitialStates: {
                type: 'state',
                imports: [
                    'components/Controller/state',
                    'components/ScoreManager/state',
                    'components/_scoring/SummaryScoreboard/state',
                    'components/common-ui/DrawAnalysis/state',
                    'components/common-ui/DrawAnalysisHelp/state',
                    'components/common-ui/HaveYourSay/state',
                    'components/common-ui/MatchInsightsModal/state',
                    'components/common-ui/SmartSearch/state',
                    'components/content/LiveVideoPlayer/state',
                    'components/content/NewsTile/state',
                    'components/content/VideoPlayer/state',
                    'components/data/ActiveData/state',
                    'components/data/CommonData/state',
                    'components/data/Config/state',
                    'components/data/Tournament/state',
                    'components/general/AgeGate/state',
                    'components/general/FanWeekSchedule/state',
                    'components/general/Header/state',
                    'components/general/MainNav/state',
                    'components/general/MobileNav/state',
                    'components/general/ModalOverlay/state',
                    'components/general/OnCourtSchedule/state',
                    'components/general/PageHeader/state',
                    'components/general/PlayerSeed/state',
                    'components/general/ScrollHandler/state',
                    'components/general/TicketsModule/state',
                    'components/general/VideoWrapper/state',
                    'components/pages/ContentPage/state',
                    'components/pages/CountryPlayersPage/state',
                    'components/pages/DrawsPage/state',
                    'components/pages/EdgeConnectTest/state',
                    'components/pages/GeneralContentPage/state',
                    'components/pages/Home/state',
                    'components/pages/LivePractice/state',
                    'components/pages/LiveScores/state',
                    'components/pages/LiveShow/state',
                    'components/pages/LiveVideo/state',
                    'components/pages/MatchInsights/state',
                    'components/pages/OpenQuestions/state',
                    'components/pages/PastChamps/state',
                    'components/pages/PlayerPage/state',
                    'components/pages/PlayerSearch/state',
                    'components/pages/PlayerStatsPage/state',
                    'components/pages/PowerIndexLeaderboard/state',
                    'components/pages/ResultsPage/state',
                    'components/pages/SchedulePage/state',
                    'components/pages/SearchPage/state',
                    'components/pages/SeedsListPage/state',
                    'components/pages/SiteMap/state',
                    'components/pages/Slamtracker/state',
                    'components/pages/SponsorLanding/state',
                    'components/pages/TVSchedule/state',
                    'components/pages/VideoLanding/state',
                    'components/webviews/ContentPageWebview/state',
                    'components/webviews/GeneralContentPageWebview/state',
                    'components/webviews/RadioPlayer/state',
                    'components/webviews/ScheduleWebview/state',
                    'components/webviews/SlamtrackerDemo/state',
                    'components/webviews/SlamtrackerWebview/state',
                    'dotix/components/Plugable/state',
                ],
            },
            allRoutes: {
                type: 'route',
                imports: [
                    'components/_scoring/SummaryScoreboard/route',
                    'components/data/Config/route',
                    'components/general/Dining/route',
                    'components/pages/Accessibility/route',
                    'components/pages/AdsLanding/route',
                    'components/pages/AnyclipTest/route',
                    'components/pages/AppLanding/route',
                    'components/pages/ContentPage/route',
                    'components/pages/CountryPlayersPage/route',
                    'components/pages/DrawsPage/route',
                    'components/pages/EdgeConnectTest/route',
                    'components/pages/FanWeek/route',
                    'components/pages/FeedbackThankYou/route',
                    'components/pages/GalleryLanding/route',
                    'components/pages/GalleryPage/route',
                    'components/pages/GeneralContentPage/route',
                    'components/pages/GroundsMap/route',
                    'components/pages/Home/route',
                    'components/pages/HomePreview/route',
                    'components/pages/InternationalSchedule/route',
                    'components/pages/Interviews/route',
                    'components/pages/LiveAtUSOpen/route',
                    'components/pages/LivePractice/route',
                    'components/pages/LiveScores/route',
                    'components/pages/LiveShow/route',
                    'components/pages/LiveVideo/route',
                    'components/pages/MediaLanding/route',
                    'components/pages/NetworkTest/route',
                    'components/pages/NewsIndex/route',
                    'components/pages/OpenQuestions/route',
                    'components/pages/OpenQuestionsDetail/route',
                    'components/pages/OpenQuestionsTerms/route',
                    'components/pages/PastChamps/route',
                    'components/pages/Photos/route',
                    'components/pages/PlayerPage/route',
                    'components/pages/PlayerSearch/route',
                    'components/pages/PlayerStatsPage/route',
                    'components/pages/PlayersIndex/route',
                    'components/pages/PracticeSchedulePage/route',
                    'components/pages/RadioLanding/route',
                    'components/pages/ResultsPage/route',
                    'components/pages/Schedule/route',
                    'components/pages/SchedulePage/route',
                    'components/pages/SearchPage/route',
                    'components/pages/SeedsListPage/route',
                    'components/pages/SiteMap/route',
                    'components/pages/SlamTrackerLanding/route',
                    'components/pages/Slamtracker/route',
                    'components/pages/SponsorLanding/route',
                    'components/pages/TVSchedule/route',
                    'components/pages/TestPage/route',
                    'components/pages/VideoLanding/route',
                    'components/pages/VideoPlayerLanding/route',
                    'components/pages/WinFactorsFinder/route',
                    'components/webviews/ContentPageWebview/route',
                    'components/webviews/DiningWebview/route',
                    'components/webviews/DrawsWebview/route',
                    'components/webviews/EventScheduleWebview/route',
                    'components/webviews/FanWeekWebview/route',
                    'components/webviews/GeneralContentPageWebview/route',
                    'components/webviews/GroundsMapMatchBox/route',
                    'components/webviews/LiveAtUSOpenWebview/route',
                    'components/webviews/OpenQuestionsDetailWebview/route',
                    'components/webviews/OpenQuestionsWebview/route',
                    'components/webviews/PracticeScheduleWebview/route',
                    'components/webviews/RadioPlayer/route',
                    'components/webviews/ScheduleWebview/route',
                    'components/webviews/SlamtrackerDemo/route',
                    'components/webviews/SlamtrackerWebview/route',
                    'components/webviews/WatchWebview/route',
                    'components/webviews/WatsonxInfoOverlayWebview/route',
                ],
            },
            allServices: {
                type: 'services',
                imports: [
                    'components/data/CommonData/services',
                    'components/data/Config/services',
                    'components/data/Tournament/services',
                    'components/general/PageHeader/services',
                    'components/general/PlayerFilters/services',
                    'components/general/PlayerSeed/services',
                    'components/general/TicketsModule/services',
                    'components/general/VideoWrapper/services',
                    'components/pages/EdgeConnectTest/services',
                    'components/pages/LivePractice/services',
                    'components/pages/LiveScores/services',
                    'components/pages/LiveShow/services',
                    'components/pages/LiveVideo/services',
                    'components/pages/OpenQuestions/services',
                    'components/pages/PlayerPage/services',
                    'components/pages/RadioLanding/services',
                    'components/pages/SchedulePage/services',
                    'components/pages/SearchPage/services',
                    'components/pages/SlamTrackerLanding/services',
                    'components/webviews/GroundsMapMatchBox/services',
                    'components/webviews/OpenQuestionsDetailWebview/services',
                    'components/webviews/OpenQuestionsWebview/services',
                    'components/webviews/SlamtrackerDemo/services',
                    'components/webviews/SlamtrackerWebview/services',
                ],
            },
            allMiddleware: {
                type: 'middleware',
                imports: ['dotix/redux/middleware'],
            },
            allEnhancers: {
                type: 'enhancer',
                imports: ['dotix/redux/enhancer'],
            },
        };
    },
};
