import React, { PureComponent } from 'react';

/**
 * -----------------------------------------------------------------------------
 * Class Component: TeamNameFlag
 * @param {*} flagPath ---- flag image path from Config
 * @param {*} name -------- teamData.displayNameA || teamData.displayNameB
 * @param {*} country ----- !Array.isArray(teamData.nationA) ? teamData.nationA : teamData.nationA[0] || nationB
 * @param {*} seed -------- teamData.seed
 * @param {*} isTbd ------- bool
 * @returns Flag and Player Name for non AI Draws (AI Draws = MS || WS && enabled && bracket view)
 * -----------------------------------------------------------------------------
 */

export default class TeamNameFlag extends PureComponent {
	render() {
		// logger.log('[MatchBox TeamNameFlag] props:%o', this.props);

		return (
			<>
				{this.props.isTbd ? null : (
					<div className="flag">
						<img
							alt={this.props.country}
							src={this.props?.flagPath?.replace('<code>', this.props.country)}
						/>
					</div>
				)}
				<div className="name">{this.props.isTbd ? 'T.B.D' : this.props.name}</div>
				<div
					className={`seed ${
						this.props.seed && !this.props.isTbd ? '' : 'hide'
					}`}>{`[${this.props.seed}]`}</div>
			</>
		);
	}
}
