/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: PageSponsor
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const PageSponsor = props => {
	//logger.log('[PageSponsor] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	logger.log('[PageSponsor] - attr:%o', attributes);

	if (attributes) {
		return (
			<section className="pageSponsor">
				<span>{attributes.tagline}</span>
				<div className="sponsorInfo">
					<EventsLink to={attributes.link}>
						<img src={attributes.imgSrc} border="0" alt={attributes.title} />
					</EventsLink>
					<div className="sponsorLine" />
				</div>
			</section>
		);
	} else {
		return null;
	}
};

export default PageSponsor;
