import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[SearchPage] action.mount - data:%o', data);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			data.solrSearch = Config.solrSearch;
			data.atozPath = Config.cmsData.index.replace('<section>', 'atoz');
			data.faqPath = Config.cmsData.faq;
			dispatch({ type: deps.actionTypes.SEARCHPAGE_MOUNT, data: data });
		});
	},
	showSearch: flag => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.SEARCHPAGE_SHOWSEARCH, data: flag });
	},
	unmount: () => (dispatch, getState, store) => {
		let data = {};
		data.solrSearch = '';
		data.atozPath = '';
		dispatch({ type: deps.actionTypes.SEARCHPAGE_UNMOUNT, data: data });
	},
};
