import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	switch (action.type) {
		case deps.actionTypes.LIVEPRACTICE_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[LivePractice] LIVEPRACTICE_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.LIVEPRACTICE_SELECT:
			newState = {
				...state,
				selected: action.data,
			};
			//logger.log('[LivePractice] LIVEPRACTICE_SELECT - newState:%o', newState);
			return newState;

		case deps.actionTypes.LIVEPRACTICE_FILTER:
			// logger.log('[LivePractice] LIVEPRACTICE_FILTER - data:%o', action.data);

			let filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};

			// logger.log('[LivePractice] LIVEPRACTICE_FILTER - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
