/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { Redirect, withRouter } from 'react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import MenuItems from './MenuItems';
/**
 * -----------------------------------------------------------------------------
 * React Component: MainNav
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MainNav'],
		windowSizeVal: state['Controller'].windowSizeVal,
		menuItems: state['Controller'].menuItems,
		bgImage: state['Controller'].bgImage,
		mobile_bgImage: state['Controller'].mobile_bgImage,
		notfoundImage: state['Config']?.otherData?.notfoundImage,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MainNav.mount()),
	unmount: data => dispatch(deps.actions.MainNav.unmount(data)),
	update: data => dispatch(deps.actions.MainNav.update(data)),
});

class MainNav extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.fetchedMenu = false;
		this.bgLoaded = false;

		// logger.log('[MainNav] constructor this:%o', this);
	}

	componentDidMount() {
		this.props.mount();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[MainNav] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let nav = '';
		let search = '';
		let panel = false;

		if (nextProps.newPath && nextProps.newPath != prevState.location?.pathname + prevState.location?.search) {
			// nav = nextProps.newPath;
			// search = nextProps.newSearch;
			// panel = nextProps.panelClick;
		}

		let newState = {
			...prevState,
			...nextProps,
			// nav: nav,
			// search: search,
			// panel: panel,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[MainNav] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentWillUnmount() {
		// logger.log('[MainNav] componentWillUnmount');
		this.props.update({ hideAd: false });
		this.props.unmount({
			newPath: null,
			nav: null,
		});
	}

	componentDidUpdate(prevState) {
		// logger.log('[MainNav] componentDidUpdate state:%o',this.state);
		const stubElem = document.getElementById('stub-page');
		const panelStubElem = document.getElementById('panel-stub-page');
		const notFoundElem = document.getElementById('notfound-page');

		// logger.log('[MainNav] componentDidUpdate 1 stubElem:%o, panelStubElem:%o, notFoundElem:%o',stubElem, panelStubElem, notFoundElem);

		if (!this.bgLoaded && this.props.status == 'ready' && (stubElem || notFoundElem)) {
			// logger.log('[MainNav] componentDidUpdate 2 stubElem:%o, panelStubElem:%o, notFoundElem:%o',stubElem, panelStubElem, notFoundElem);

			this.bgLoaded = true;
			this.setState({
				stubClass: stubElem || panelStubElem ? 'stub' : 'notfound',
				stubStyle: stubElem || panelStubElem ? `` : `background-image: url(${this.props?.notfoundImage}`,
			});
			this.props.update({ hideAd: true });
		} else {
			this.props.update({ hideAd: false });
		}
	}

	render() {
		// logger.log('[MainNav] render - this:%o', this);
		const elem = document.getElementById('root');
		const stubElem = document.getElementById('stub-page');
		const panelElem = document.getElementsByClassName('st-content-main');
		const panelStubElem = document.getElementById('panel-stub-page');
		const notFoundElem = document.getElementById('notfound-page');

		// logger.log('[MainNav] render - elem:%o, stubElem:%o, panelElem:%o, panelStubElem:%o, notFoundElem:%o', elem, stubElem, panelElem, panelStubElem, notFoundElem);

		// const elem = document.querySelector('.uso-site');
		if (this.props.status == 'ready' && panelElem && panelStubElem) {
			panelElem[0].classList.add(this.state.stubClass);
			panelElem[0].setAttribute('style', this.state.stubStyle);
		}

		// stubs won't have a background image.  just a color.  defined in stub-box.scss
		if (this.props.status == 'ready' && elem && stubElem) {
			elem.setAttribute('class', this.state.stubClass);
			elem.setAttribute('style', this.state.stubStyle);
		}
		// not found page uses a different bg image.  defined in config_web
		else if (this.props.status == 'ready' && elem && notFoundElem) {
			elem.setAttribute('class', this.state.stubClass);
			elem.setAttribute('style', this.state.stubStyle);
		}
		// site bg is defined in backgroundImage.json
		else if (this.props.status == 'ready' && elem) {
			//check for window size then change to the mobile background image
			if (this.props.windowSizeVal < 2) {
				elem.setAttribute('style', `background-image: url(${this.props.mobile_bgImage})`);
			} else {
				elem.setAttribute('style', `background-image: url(${this.props.bgImage})`);
			}
		}

		if (
			(this.props.newPath && this.props.newPath !== this.props.location.pathname) ||
			(this.props.newSearch && this.props.newSearch !== this.props.location.search)
		) {
			return (
				<Redirect
					push
					to={{
						pathname: this.props.newPath,
						search: this.props.newSearch,
						state: { panel: this.props.panelClick },
					}}
				/>
			);
		}

		if (this.props.menuItems) {
			return (
				<div data-dna="global-navigation" id="header-links" className="d-none d-llg-flex">
					{/* <div className="li-date d-xl-inline-block">AUG 28 – SEPT 10, 2023</div> */}

					{/* adding location as a prop to force menuitems to re-render when url changes */}
					<MenuItems
						data={get(this.props, 'menuItems.menu.item', [])}
						attributes="main"
						location={window.location.href}
					/>
					{/* <MenuLiveIndicators /> */}
				</div>
			);
		} else {
			return <div />;
		}
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNav));
