/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';
import Helmet from 'react-helmet';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

/* page elements */
import TemplateGeneric from 'components/TemplateGeneric';

/**
 * -----------------------------------------------------------------------------
 * React Component: WatchWebview
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['VideoLanding'],
		anyclipAppFolder: state['Config']?.videoPlayer?.anyclip?.anyclipAppFolder || null,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});
class WatchWebview extends Component {
	constructor(props) {
        super(props);

		this.state = {
			inline_player_loaded: false,
			main_widget_loaded: false
		};

		this.inline_player = null;
		this.watch_player = null;
		this.watch_player_prefix = "MAIN==";
		this.inline_player_subscribed = false;
		this.watch_player_subscribed = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Videos',
		});
	}

	componentDidMount() {
		window.addEventListener('message', event => {
			if (
				event?.data?.length > 0 &&
				event?.data?.indexOf('lre:playerReady://') === 0 &&
				(!this.state.inline_player_loaded || !this.state.watch_player_loaded)
			) {
				// logger.log('[WatchWebview] componentDidMount event:%o', event.data);

				let widget_data = JSON.parse(event.data.slice('lre:playerReady://'.length));

				if(widget_data['customWidgetId'].startsWith(this.watch_player_prefix)) {
					this.watch_player = window.anyclip.getWidget(null, widget_data['sessionId']);

					this.setState({
						watch_player_loaded: true,
					})
				} else {				
					this.inline_player = window.anyclip.getWidget(null, widget_data['sessionId']);
					this.setState({
						inline_player_loaded: true,
					})
				}
			}
		});

        window.addEventListener('hashchange', (event) => {
            logger.log('[WatchWebview] hash changed to:%o', window.location.hash);
        });
	}

	componentWillUnmount() {
		logger.log('[WatchWebview] componentWillUnmount');

		window.dispatchEvent(new Event('ChangeWatchRouterDispatch'));

		['__uspapi', '__cmp', '__tcfapi'].forEach(function(e) {
			if (window[e] !== null) {
				logger.log('[WatchWebview] componentWillUnmount %o exists, deleting', e);
				delete window[e];
			}
		});
		this.setState(
			{
				anyclip_player_loaded: false,
                main_widget_loaded: false
			},
			() => {
				this.anyclip_player = null;
                this.main_widget = null;
			}
		);
	}

	componentDidUpdate() {
		// logger.log('[WatchWebview] componentDidUpdate this:%o', this);

		/** Anyclip events */
		if (this.inline_player && this.inline_player?.subscribe && !this.inline_player_subscribed) {
			// logger.log('[WatchWebview] AnyClip - this.inline_player:%o', this.inline_player);
			this.inline_player_subscribed = true;

			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'adImpression', 'Inline Player', 'Play:adImpression');
			}, 'adImpression');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'adSkipped', 'Inline Player', 'Play:adSkipped');
			}, 'adSkipped');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'adComplete', 'Inline Player', 'Play:adComplete');
			}, 'adComplete');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerClipImpression', 'Inline Player', 'Play');
			}, 'playerClipImpression');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerFirstQuartile', 'Inline Player', 'Play:25%');
			}, 'playerFirstQuartile');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerMidpoint', 'Inline Player', 'Play:50%');
			}, 'playerMidpoint');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerThirdQuartile', 'Inline Player', 'Play:75%');
			}, 'playerThirdQuartile');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerClipComplete', 'Inline Player', 'Play:Complete');
			}, 'playerClipComplete');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerFullscreen', 'Inline Player');
			}, 'playerFullscreen');
			this.inline_player.subscribe(e => {
				let mute_status = e?.isMute == 0 ? 'Unmuted' : 'Muted';
				this.measureEvent(e, 'playerMute', 'Inline Player', `Play:${mute_status}`);
			}, 'playerMute');
			this.inline_player.subscribe(e => {
				let pause_status = e?.isPaused === true ? 'Pause' : 'Resume';
				this.measureEvent(e, 'playerPause', 'Inline Player', `Play:${pause_status}`);
			}, 'playerPause');
			this.inline_player.subscribe(e => {
				// let cc_status = e?.isPaused === true ? 'Pause' : 'Resume';
				// this.measureEvent(e, 'CloseCaptionsChangedOnClick', 'Inline Player', `Play:${cc_status}`);
				this.measureEvent(e, 'CloseCaptionsChangedOnClick', 'Inline Player');
			}, 'CloseCaptionsChangedOnClick');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'ShareScreenOpen', 'Inline Player');
			}, 'ShareScreenOpen');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'playerTheaterModeShare', 'Inline Player');
			}, 'playerTheaterModeShare');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'ShareVideoClicked', 'Inline Player');
			}, 'ShareVideoClicked');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxDisplayed', 'Inline Player');
			}, 'LuxDisplayed');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxHidden', 'Inline Player');
			}, 'LuxHidden');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxUserOpen', 'Inline Player');
			}, 'LuxUserOpen');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxUserClose', 'Inline Player');
			}, 'LuxUserClose');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxLabelDisplay', 'Inline Player');
			}, 'LuxLabelDisplay');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxLabelHide', 'Inline Player');
			}, 'LuxLabelHide');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxClickVideo', 'Inline Player');
			}, 'LuxClickVideo');
			this.inline_player.subscribe(e => {
				this.measureEvent(e, 'LuxButtonClick', 'Inline Player');
			}, 'LuxButtonClick');
		}

		if(this.watch_player && this.watch_player?.subscribe && !this.watch_player_subscribed) {
			this.watch_player_subscribed = true;
				
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'adImpression', 'Watch Player', 'Play:adImpression');
			}, 'adImpression');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'adSkipped', 'Watch Player', 'Play:adSkipped');
			}, 'adSkipped');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'adComplete', 'Watch Player', 'Play:adComplete');
			}, 'adComplete');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerClipImpression', 'Watch Player', 'Play');
			}, 'playerClipImpression');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerFirstQuartile', 'Watch Player', 'Play:25%');
			}, 'playerFirstQuartile');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerMidpoint', 'Watch Player', 'Play:50%');
			}, 'playerMidpoint');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerThirdQuartile', 'Watch Player', 'Play:75%');
			}, 'playerThirdQuartile');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerClipComplete', 'Watch Player', 'Play:Complete');
			}, 'playerClipComplete');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerFullscreen', 'Watch Player');
			}, 'playerFullscreen');
			this.watch_player.subscribe(e => {
				let mute_status = e?.isMute == 0 ? 'Unmuted' : 'Muted';
				this.measureEvent(e, 'playerMute', 'Watch Player', `Play:${mute_status}`);
			}, 'playerMute');
			this.watch_player.subscribe(e => {
				let pause_status = e?.isPaused === true ? 'Pause' : 'Resume';
				this.measureEvent(e, 'playerPause', 'Watch Player', `Play:${pause_status}`);
			}, 'playerPause');
			this.watch_player.subscribe(e => {
				// let cc_status = e?.isPaused === true ? 'Pause' : 'Resume';
				// this.measureEvent(e, 'CloseCaptionsChangedOnClick', 'Watch Player', `Play:${cc_status}`);
				this.measureEvent(e, 'CloseCaptionsChangedOnClick', 'Watch Player');
			}, 'CloseCaptionsChangedOnClick');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'ShareScreenOpen', 'Watch Player');
			}, 'ShareScreenOpen');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'playerTheaterModeShare', 'Watch Player');
			}, 'playerTheaterModeShare');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'ShareVideoClicked', 'Watch Player');
			}, 'ShareVideoClicked');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxDisplayed', 'Watch Player');
			}, 'LuxDisplayed');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxHidden', 'Watch Player');
			}, 'LuxHidden');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxUserOpen', 'Watch Player');
			}, 'LuxUserOpen');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxUserClose', 'Watch Player');
			}, 'LuxUserClose');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxLabelDisplay', 'Watch Player');
			}, 'LuxLabelDisplay');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxLabelHide', 'Watch Player');
			}, 'LuxLabelHide');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxClickVideo', 'Watch Player');
			}, 'LuxClickVideo');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'LuxButtonClick', 'Watch Player');
			}, 'LuxButtonClick');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'w_viewAll', 'Watch Player');
			}, 'w_viewAll');
			this.watch_player.subscribe(e => {
				this.measureEvent(e, 'chipClicked', 'Watch Player');
			}, 'chipClicked');
		}
	}

    measureEvent = (e, player_event, player, alt_event) => {
		// logger.log('[WatchWebview] measureEvent - e:%o, player:%o, player_event:%o, alt_event:%o', e, player, player_event, alt_event);

		// logger.log(
		// 	'[WatchWebview] measureEvent - data:%o',
		// 	this.anyclip_player?.getTMplaylist()[this.anyclip_player?.playlistCurrentIndex()]
		// );
		let title = this.main_widget?.getTMplaylist()[this.main_widget?.playlistCurrentIndex()]?.title || null;
		let category = null;
		let ccType = null;
		let matchId = '0000';
		let matchFound = null;
		let labels;

		if(player == 'Inline Player') {
			title = this.inline_player?.getTMplaylist()[this.inline_player?.playlistCurrentIndex()]?.title || null;
			labels = this.inline_player?.getCurrentPlaylistItem()?.label || [];
		} else {
			title = this.watch_player?.getTMplaylist()[this.watch_player?.playlistCurrentIndex()]?.title || null;
			labels = this.watch_player?.getCurrentPlaylistItem()?.label || [];
		}

		let contextData = [];
		let metricsVal = [];

		if (labels.length > 0) {
			category = labels.find((label,i) => {
				return label.name == 'category';
			})?.value || null;

			ccType = labels.find((label,i) => {
				return label.name == 'ccType' || label.name == 'cc Type';
			})?.value || null;

			// logger.log('[WatchWebview] measureEvent - categoryFound:%',categoryFound);

			if (category == 'cognitive match highlights') {
				matchFound = filter(labels, (label, i) => {
					return label.name == 'matchid';
				});

				/** isolate match id value from string */
				if (matchFound) {
					matchId = matchFound[0].value.slice(matchFound[0].value.indexOf('_') + 1);
				}
			}

			// logger.log('[WatchWebview] measureEvent - categoryFound:%o, matchId:%o', categoryFound, matchId);
		}

		let noContextEvents = ['playerMute', 'playerPause', 'playerFullscreen', 'adImpression', 'adSkipped', 'adComplete'];

		if(player_event == 'playerClipImpression' && (category == 'cognitive match highlights' || ccType == 'cognitive')) {
			contextData = [
				{ content_vod: title },
				{ vod_category: category },
				{ vod_matchid: matchId },
				{ ai_commentary: 'aiAA:true|aiCC:true' },
				{ player: player},
				{ player_event: player_event }
			];
		} else {
			if(includes(noContextEvents, player_event)) {
				contextData = [
					{ player: player},
					{ player_event: player_event }
				];
			} else {
				contextData = [
					{ content_vod: title },
					{ vod_category: category },
					{ vod_matchid: matchId },
					{ player: player},
					{ player_event: player_event }
				];
			} 
		}

		metricsVal = ['metrics', `Video${alt_event ? `:${alt_event}` : ''}`];

		// logger.log('[WatchWebview] measureEvent - contextData:%o', contextData);

		measureInApp(metricsVal, contextData);
	};

	render() {
		// logger.log('[WatchWebview] render - this:%o', this);

        if (this.props?.anyclipAppFolder) {
			window.ac_vh_params = {
				containerId: 'anyclip-container',
				folderName: this.props.anyclipAppFolder,
				usOpenShareUrl: 'https://www.usopen.org/en_US/video/index.html',
			};

			return (
				<TemplateGeneric className="webview">
					<Helmet>
						<meta property="og:title" content="@USOpen" />
						<meta property="og:description" content="@USOpen" />
						<meta property="fb:app_id" content="471867639491882" />
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:site" content="@USOpen" />
						<meta name="twitter:creator" content="@USOpen" />
						<script src="https://watch-app.geniusplus.ai/main.js"></script>
					</Helmet>
                    <section id="video" className="wrapper videolanding webview">
						<div id="anyclip-container"></div>
					</section>
				</TemplateGeneric>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchWebview);