import React from 'react';
import PlayerImage from 'appdir/components/common-ui/PlayerImage';

import DeterminingFactors from './DeterminingFactors';
import RankOverTime from './RankOverTime';
import PowerRanking from 'appdir/components/common-ui/PowerRanking/PowerRanking';
import PlayerStatus from './PlayerStatus';
import DrawAnalysis from 'appdir/components/common-ui/DrawAnalysis';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import { values } from 'appdir/main';
import { generateStatusText, getSinglesMatch } from '../Util';
import op from 'object-path';

const LeaderboardData = (props = {}) => {
	const { data = {} } = props;
	const playerDrawAnalysisData = data?.playerDrawAnalysisData;
	// logger.log('[LeaderboardData] data:%o', data);

	const displayMatchDetails = getSinglesMatch(props.data.playerStatusData);
	const statusText = generateStatusText(displayMatchDetails);
	const matchId = op.get(displayMatchDetails, 'match_details.match_id');
	const hasInsight =
		matchId &&
		matchId !== '' &&
		op.get(data, 'matchInsightsMatches.matches') &&
		data.matchInsightsMatches.matches.indexOf(matchId) !== -1;

	// logger.log(
	// 	'[LeaderboardData] matchId:%o, displayMatchDetails:%o, statusText:%o, hasInsight:%o',
	// 	matchId,
	// 	displayMatchDetails,
	// 	statusText,
	// 	hasInsight
	// );

	return (
		<div className="powerrank-data-container">
			<div className="sect player-image">
				<PlayerImage attributes={data.playerImageData} />
				<div className="player-info">
					<PowerRanking data={{ value: data.powerrank }}></PowerRanking>
					{/* NextMatch:  768px tablet text only and no button, & desktop */}
					<div className="nextmatch-container">
						{matchId ? (
							<PlayerStatus
								preMatchInsight={hasInsight}
								statusText={{ ...statusText }}
								matchId={matchId}
							/>
						) : null}
					</div>
				</div>
				{/* NextMatch: 768px width just for the button, large desktop, show status text and the button */}
				<div className="nextmatch-container desktop-style">
					{matchId ? (
						<PlayerStatus preMatchInsight={hasInsight} statusText={{ ...statusText }} matchId={matchId} />
					) : null}
				</div>
			</div>
			<div className="sect draw-analysis">
				{/** above mobile display (set in CSS) */
				playerDrawAnalysisData && props?.enabled?.drawAnalysis && (
					<ErrorBoundary message="">
						<DrawAnalysis
							attributes={{
								style: ' mobile',
								playerDrawAnalysisData,
								playerStatusData: props.data.playerStatusData,
								metricsPage: values.powerIndexTitle,
								view: 'powerindex',
								// callbackFn: playerId => openPathToTheFinal(playerId),
							}}
						/>
					</ErrorBoundary>
				)}
				{!props?.enabled?.drawAnalysis ||
					(!playerDrawAnalysisData && props?.enabled?.drawAnalysis && (
						<p>{values.aiDrawTitle} is not available at this time. </p>
					))}
			</div>
			<div className="sect key-factors">
				{data.determiningFactors ? (
					<DeterminingFactors
						data={{ determiningFactors: data.determiningFactors, powerrank: data.powerrank }}
					/>
				) : null}
			</div>
			<div className="sect rank-overtime">
				{/** mismatched rank over time data often cause issues
				 *    not to expand the row on Safari and iOS app
				 *   during the off season.
				 *   data.disablePowerIndexTrending is in config not to render this
				 *   so we can test and develop
				 */
				data.rankOverTime && !data.disablePowerIndexTrending ? <RankOverTime data={data.rankOverTime} /> : null}

				{data.disablePowerIndexTrending && <p className="msg">Rank Over Time is not available at this time.</p>}
			</div>

			{/* NextMatch: mobile only */}
			<div className="nextmatch-container mobile-show">
				{matchId ? (
					<PlayerStatus preMatchInsight={hasInsight} statusText={{ ...statusText }} matchId={matchId} />
				) : null}
			</div>
		</div>
	);
};

export default LeaderboardData;
