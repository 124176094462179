/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Template from 'components/Template';
import AtoZNav from 'appdir/components/common-ui/AtoZNav';
import PlayerList from './PlayersList';
import PlayerFilters from 'appdir/components/general/PlayerFilters';
import PlayerSeed from 'appdir/components/general/PlayerSeed';
import ScrollHandler from 'appdir/components/general/ScrollHandler';
import classNames from 'classnames';
import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import FavoritesModule from 'appdir/components/general/FavoritesModule';
import HomeContentWrapper from 'appdir/components/cms/HomeContent/HomeContentWrapper';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayersIndex
 * -----------------------------------------------------------------------------
 */

const queryString = require('querystring-browser');

const mapStateToProps = (state, props) => ({
	...state['PlayersIndex'],
	PlayersIndex: state.PlayersIndex,
	FeaturedPlayers: state.Config.featuredPlayers,
	windowSize: state['Controller'].windowSize,
	windowSizeVal: state['Controller'].windowSizeVal,
	stubs: state['Config'].stubPages,
	favorites: state['Controller'].favorites,
	excludedPlayers: state['Config']?.otherData?.excludedPlayers,
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayersIndex.mount()),
	unmount: data => dispatch(deps.actions.PlayersIndex.unmount(data)),
	filter: data => dispatch(deps.actions.PlayersIndex.filter(data)),
});

const letterList = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];

class PlayersIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			showGender: 'F',
			eventGender: '',
			value: '',
			selectedTab: 'Women',
			selectedEvent: 'AE', //All Events
		};

		this.loadedJson = '';
		this.scrollToList = createRef();
		this.eventName = '';
		this.mensEvents = ['MS', 'MD', 'CS', 'CD', 'BS', 'BD', 'JS', 'JD', 'MQ', 'BQ'];
		// logger.log('[PlayersIndex] constructor - props:%o', this.props);
	}

	componentWillUnmount() {
		this.props.unmount({
			players: null,
		});
	}

	componentDidMount() {
		MeasurementUtils.dispatchMeasurementCall('playersPage', {});
	}

	componentDidUpdate(prevProps, prevState) {
		//analytics for tracking letter navigation change
		// logger.log('[PlayersIndex] componentDidUpdate - state:%o', this.state);

		if (this.state.selectedEvent && this.state.selectedEvent !== prevState.selectedEvent) {
			let event_seed_results = this.getEventSeedingData();
			this.setState(prevState => {
				return {
					...prevState,
					event_seed_results,
					eventGender: this.genderOfEvent,
				};
			});
		}
		let currHashLetter = this.state.location.hash;

		if (currHashLetter !== prevState.location.hash) {
			let letter = currHashLetter.replace('#', '');
		}

		if (!this.loadedJson && this.props.PlayersIndex.profilePath) {
			this.loadedJson = this.props.PlayersIndex.profilePath;
			fetch(this.props.PlayersIndex.profilePath)
				.then(result => {
					//logger.log('[PlayerIndex] componentDidUpdate PlayersIndex:%o', result);
					this.setState({
						players: result.players,
					});
				})
				.catch(error => {
					//logger.log('[RolexScoreboard] componentDidUpdate error:%o',error);
					this.setState({
						status: 'error',
						errorType: error,
					});
				});

			fetch(this.props.PlayersIndex.updatedSeedsList)
				.then(result => {
					//logger.log('[PlayerIndex] componentDidUpdate seedPlayers:%o', result);
					this.setState({
						seed_results: result.seed_results,
						event_seed_results: result.seed_results,
					});
				})
				.catch(error => {
					//logger.log('[RolexScoreboard] componentDidUpdate error:%o',error);
					this.setState({
						status: 'error',
						errorType: error,
					});
				});
		}
	}

	handleChange = evt => {
		this.setState({
			value: evt.target.value,
		});
	};

	handleKeyPress = evt => {
		if (evt.key == 'Enter') {
			// logger.log('[PlayersIndex] handleKeyPress - value:%o', this.state.value);
			if (evt.target.value.trim().length == 0) {
				this.setState({
					value: 'Enter a last name',
				});
			} else {
				this.loadResults();
			}
		}
	};

	handleClick = value => {
		if (value.trim().length == 0) {
			this.setState({
				value: 'Enter a last name',
			});
		} else {
			this.setState({
				value: value,
			});

			this.loadResults();
		}
	};

	clearValue = evt => {
		this.setState({
			value: '',
		});
	};

	loadResults = v => {
		logger.log('load results');
		this.setState(prevState => ({
			loadResults: true,
			value: v ? v : prevState.value,
		}));
	};

	getNavSettings() {
		let navSettings;

		//pass in the top fixed position of the nav when the e scrolls
		navSettings = { navTop: 106 };

		return navSettings;
	}

	setSelectedTab = tab => {
		this.setState({ selectedTab: tab });
	};

	handleSelectedEvent = value => {
		logger.log('[PlayersIndex] handleSelectedEvent - val:%o', value);
		let showGender = 'F';
		if (this.mensEvents.includes(value)) {
			showGender = 'M';
		}

		this.setState({
			//selectedEvent: val.target.innerText
			selectedEvent: value,
			showGender,
		});
		this.scrollToListHandler();
	};

	handleSelectedChange = val => {
		// logger.log('[PlayersIndex] handleSelectedChange - val:%o', val);

		this.setState({
			selectedEvent: val,
		});
	};

	scrollToListHandler = () => {
		if (this.scrollToList.current !== null) {
			this.scrollToList.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'nearest',
			});
		}
	};

	renderPlayerList(letterList) {
		let playersByEvent = this.renderSeedingList();
		if (playersByEvent?.length > 0) {
			const playerLists = letterList.map((letter, index) => {
				return (
					<div key={letter + index} className="letter-section">
						<div ref={letter} key={letter} id={letter} className="player-section">
							<a name={letter} />
							<div
								className={`column ${
									this.state?.selectedEvent !== 'XD' &&
									this.state?.selectedEvent !== 'AE' &&
									this.state.eventGender == 'M'
										? 'hideCol'
										: ''
								}`}>
								<div className="letter-header">{letter}</div>
								<PlayerList players={playersByEvent} letter={letter} gender="F" />
							</div>
							<div className="separator"></div>
							<div
								className={`column ${
									this.state?.selectedEvent !== 'XD' &&
									this.state?.selectedEvent !== 'AE' &&
									this.state.eventGender == 'F'
										? 'hideCol'
										: ''
								}`}>
								<div className="letter-header">{letter}</div>
								<PlayerList players={playersByEvent} letter={letter} gender="M" />
							</div>
						</div>
					</div>
				);
			});
			return playerLists;
		} else {
			return <div className="no-players">There are no players for the event you have selected.</div>;
		}
	}

	renderMobileList(letterList, gender) {
		let playersByEvent = this.renderSeedingList();
		if (playersByEvent?.length > 0) {
			const playerMobileLists = letterList.map((letter, index) => {
				return (
					<div key={letter + index} className="letter-section">
						<div className="column-header">
							<div className="letter-header">{letter}</div>
						</div>
						<div ref={gender + letter} key={gender + letter} id={letter} className="player-section">
							<a name={gender + letter} />
							<div className="column">
								<PlayerList players={playersByEvent} letter={letter} gender={gender} />
							</div>
						</div>
					</div>
				);
			});
			return playerMobileLists;
		} else {
			return <div className="no-players">There are no players for the event you have selected.</div>;
		}
	}

	renderSeedingList() {
		let selectedEvent = this.state?.selectedEvent;
		let playersOfEvent = [];
		let gender;

		let playerData = this.state?.players;
		playerData?.map(event => {
			if (selectedEvent === 'AE') {
				playersOfEvent.push(event);
				return playersOfEvent;
			} else {
				event?.events_entered?.map(e => {
					if (selectedEvent === e.event_id) {
						playersOfEvent.push(event);
						return playersOfEvent;
					}
				});
			}
		});

		playersOfEvent.map(player => {
			gender = player.gender;
		});

		this.genderOfEvent = gender;
		return playersOfEvent;
	}

	getEventSeedingData() {
		let selectedEvent = this.state.selectedEvent;
		let updatedSeedsList = this.state?.seed_results;
		let updatedSeedingData = [];
		let gender;

		updatedSeedsList?.map((event, i) => {
			if (selectedEvent === 'AE') {
				updatedSeedingData.push(event);
				this.eventName = event.eventName;
				return updatedSeedingData;
			}
			if (selectedEvent === event.event_id) {
				updatedSeedingData.push(event);
				this.eventName = event.eventName;
				return updatedSeedingData;
			}
		});

		let playerList = [];
		updatedSeedingData?.map(player => {
			player?.seeds?.map(p => {
				playerList.push(p['tour-id-a']);
				playerList.push(p['tour-id-b']);
			});
		});

		updatedSeedingData.map(player => {
			gender = player.gender;
		});

		this.genderOfevent = gender;
		// logger.log('[PlayersIndex] getEventSeedingData - updatedSeedingData:%o', updatedSeedingData);
		return updatedSeedingData;
	}

	showList = genderList => {
		// logger.log('show section: genderList', genderList);
		this.setState({
			showGender: genderList,
		});
	};

	render() {
		logger.log('[PlayersIndex] render - this:%o', this);
		let breakpoint = this.props.windowSizeVal;
		if (this.state.loadResults) {
			let url = '/en_US/players/search.html?term=' + this.state.value;

			return (
				<div>
					<EventsRedirect to={url} />
				</div>
			);
		}

		let header_attributes = {
			headerType: 'Players',
			metaTitle: 'US Open Players | Official Site of the 2023 US Open Tennis Championships - A USTA Event',
			metaDescription:
				'Discover which top tennis players from around the world are competing at the US Open. Watch highlights, access statistics, read news and follow their progress.',
			metaDate: '',
			metaPlayers: '',
			scroll: false,
			canonicalLink: 'https://www.usopen.org/en_US/players/index.html'
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'https://www.emirates.com/us/english/',
				title: 'Presented by',
				name: 'Emirates Airline',
				imgClass: '',
				imgSrc: '/assets/images/logos/emirates_logo_red.png',
				tagline: 'Presented by',
			},
			title: 'Players',
			page_header: 'Players',
		};

		let playerData = [];
		let flagImagePathLarge = '';
		let playerProfileImagePath = '';
		let activeMClass = '';
		let activeFClass = '';
		let selectM = '';
		let selectF = '';

		if (this.state.showGender === 'M') {
			activeMClass = classNames('mobileView current');
			activeFClass = classNames('mobileView hide');
			selectM = classNames('header-title show');
			selectF = classNames('header-title');
		} else {
			activeFClass = classNames('mobileView current');
			activeMClass = classNames('mobileView hide');
			selectM = classNames('header-title');
			selectF = classNames('header-title show');
		}

		if (this.props.PlayersIndex && Object.keys(this.props.PlayersIndex).length > 0) {
			playerProfileImagePath = this.props.PlayersIndex.playerProfileImagePath;
			flagImagePathLarge = this.props.PlayersIndex.flagImagePathLarge;
		}
		if (this.props.stubs && this.props.stubs.players.stub === 'stub') {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section id="players" className="wrapper playerIndex">
						<StubBox
							attributes={{
								title: this.props.stubs.players.title,
								message: this.props.stubs.players.text,
								basePicPath: this.state.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.state.players) {
			if (this.state.players.length > 0) {
				return (
					<Template header={header_attributes} subHeader={subheader_attributes}>
						<section className="wrapper playerIndex">
							<ScrollHandler hasData={true} filter={'none'} />
							<a name="top" />
							<div className="content-filters">
								<PlayerFilters />
							</div>
							<PageSubMenu
								mode="Players"
								selected={this.state.selectedEvent}
								selectEventHandler={this.handleSelectedEvent}
								selectedEvent={this.state.selectedEvent}
								scrollToListHandler={this.scrollToListHandler}
							/>
							<div className="content-title">Players</div>

							{breakpoint >= 2 ? (
								<div id="playersList" ref={this.scrollToList}>
									<AtoZNav
										settings={this.getNavSettings()}
										data=""
										mode="players-by-name"
										onLoadResults={this.loadResults}
									/>
									<HomeContentWrapper data={{ borderTop: 'yellow', suppressHeader: true }}>
										<FavoritesModule mode="playersindex" playersData={this.state.players} />
									</HomeContentWrapper>
									{this.eventName?.includes('Doubles') ? null : (
										<PlayerSeed
											players={this.state.players}
											seed_results={this.state.event_seed_results}
											settings={this.getNavSettings()}
											selectedEvent={this.state.selectedEvent}
											eventName={this.eventName}
										/>
									)}

									<div className="playerContainer">
										<div className="column-header">
											<div className="header-type-title">Women</div>
											<div className="separator"></div>
											<div className="header-type-title">Men</div>
										</div>
										{this.renderPlayerList(letterList)}
									</div>
								</div>
							) : (
								<div id="mobileList">
									<AtoZNav
										settings={this.getNavSettings()}
										data={this.state.showGender}
										mode="players-by-name-mobile"
										onLoadResults={this.loadResults}
									/>
									<HomeContentWrapper data={{ borderTop: 'yellow', suppressHeader: true }}>
										<FavoritesModule mode="playersindex" playersData={this.state.players} />
									</HomeContentWrapper>
									{this.eventName?.includes('Doubles') ? null : (
										<PlayerSeed
											players={this.state.players}
											seed_results={this.state.event_seed_results}
											settings={this.getNavSettings()}
											selectedEvent={this.state.selectedEvent}
											gender={this.state.showGender}
											eventName={this.eventName}
										/>
									)}

									<div className="mobile-column-header">
										<div className={selectF} onClick={() => this.showList('F')}>
											Women
										</div>
										<div className={selectM} onClick={() => this.showList('M')}>
											Men
										</div>
									</div>

									{this.state.selectedEvent == 'AE' ? (
										<>
											{this.state.showGender == 'M' && (
												<div className={activeMClass}>
													{this.renderMobileList(letterList, 'M')}
												</div>
											)}
											{this.state.showGender == 'F' && (
												<div className={activeFClass}>
													{this.renderMobileList(letterList, 'F')}
												</div>
											)}
										</>
									) : (
										<>
											{this.state.eventGender ? (
												<>
													{this.state.eventGender == 'M' && (
														<div className={activeMClass}>
															{this.renderMobileList(letterList, 'M')}
														</div>
													)}
													{this.state.eventGender == 'F' && (
														<div className={activeFClass}>
															{this.renderMobileList(letterList, 'F')}
														</div>
													)}
													{this.state.showGender !== this.state.eventGender && (
														<div className="no-players">
															There are no players for the event you have selected.
														</div>
													)}
												</>
											) : (
												<>
													<div>
														{this.renderMobileList(letterList, this.state.eventGender)}
													</div>
												</>
											)}
										</>
									)}
								</div>
							)}
						</section>
					</Template>
				);
			}
		} else {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper playerIndex">
						<ScrollHandler hasData={false} filter={'none'} />

						<LoadingIndicator />
					</section>
				</Template>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayersIndex);
