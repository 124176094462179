/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, useEffect } from 'react';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import { Scrollchor } from 'react-scrollchor';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: AtoZNav
 * -----------------------------------------------------------------------------
 */

const letterList = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];
const mobileMatch = window.matchMedia('(max-width: 767px)').matches;
const tabletMatch = window.matchMedia('(max-width: 1023px)').matches;
const desktopMatch = window.matchMedia('(min-width: 1024px)').matches;
class AtoZNav extends Component {
	//logger.log('[AtoZNav] - props:%o', props);

	//let attributes = props['attributes'][props.id];
	//logger.log('[AtoZNav] - attr:%o', attributes);

	static getDropdownData() {
		let options = [];

		options.push({
			name: 'A-Z',
			value: 'A-Z',
			query: 'select',
			selected: true,
		});

		letterList.map((letter, index) => options.push({ name: letter, value: `#${letter}`, query: `#${letter}` }));

		//logger.log('[AtoZNav] getDropdownData - options:%o', options);
		let menuData = { options };
		return menuData;
	}

	constructor(props) {
		logger.log('[AtoZNav] constructor - props:%o', props);
		super(props);
		this.state = {
			...props,
			value: '',
			hideDiv: true,
		};
		const ref = React.createRef();
	}

	componentDidMount() {
		//logger.log('[AtoZNav] componentDidMount - state:%o', this.state);
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}

		this.getAnimationSettings();
	}

	clearValue = evt => {
		this.setState({
			value: '',
		});
	};

	handleChange = event => {
		// you have to handle change this way because it asynchronous.
		// So, this will set the state with a callback to then set the location
		let letterHash = event?.target?.value;

		/** Android has to use a custom dropdown as app
		 *  overrides the native dropdown with not readable style
		 *  maybe Android team can make it work next year
		 */
		if (window.webview && window.webviewPlatform === 'android') {
			letterHash = event;
		}

		this.setState({ value: letterHash });
		let temp = letterHash.charAt(1);
		let hashLink = document.getElementById(`hash${temp}`);
		// logger.log('[AtoZnav] refs:%o', this.refs);
		// this.refs[hashLink].simulateClick();
		if (hashLink) {
			hashLink.click();
		}
	};

	handleSearchChange = evt => {
		// console.log('handlesearchchange evt', evt.target.value);

		this.setState({
			value: evt.target.value,
		});
	};

	handleKeyPress = evt => {
		if (evt.key == 'Enter') {
			logger.log('[PlayerFilters] handleKeyPress - value:%o', this.state.value);
			if (evt.target.value.trim().length == 0) {
				this.setState({
					value: 'Enter a last name',
				});
			} else {
				this.props.onLoadResults(this.state.value);
				MeasurementUtils.dispatchMeasurementCall('Players Search', {
					value: this.state.value,
				});
			}
		}
	};

	onOpen = which => {};

	getAnimationSettings() {
		let animationOffset;
		let animationDuration = 600;
		let navTop = 0;

		let atozRect = document.querySelector('.content-filters').getBoundingClientRect();
		// logger.log('[AtoZNav] getAnimationSettings - atozRect:%o', atozRect);
		if (this.state.settings && this.state.settings.navTop) {
			navTop = this.state.settings.navTop;
		} else if (this.state.data && this.state.data.navTop) {
			navTop = Number.parseInt(this.state.data.navTop);
		}
		animationOffset = (atozRect.height + navTop) * -1;
		// logger.log('[AtoZNav] getAnimationSettings - animationOffset:%o', animationOffset);

		this.setState({
			offset: animationOffset,
		});
	}

	handleClick = value => {
		// console.log('[AtoZNav] handleclick value:%o', value);

		if (value.trim().length == 0) {
			document.getElementById('inputPlayerSearch').focus();

			this.setState(prevState => ({
				// value: 'Enter a last name',
				hideDiv: !prevState.hideDiv,
			}));
		} else {
			this.setState(prevState => ({
				value: value,
				hideDiv: !prevState.hideDiv,
			}));

			this.props.onLoadResults(this.state.value);
		}
	};

	onClick(letter) {
		MeasurementUtils.dispatchMeasurementCall('Players Alphabet', {
			value: letter,
		});
	}

	render() {
		logger.log('[AtoZNav] render - this:%o', this);
		//logger.log('[AtoZNav] constructor - duration:%o', this.state.settings.duration);
		let alphaList = this.state.data.alphaList;

		return (
			<div id="atoznav">
				<div className="dropdown_nav filter-dropdown">
					{/** Android app overwrite the browser native dropdown styles
					 *    makes it not readable - white text on white background,
					 *    using a custom build dropdown to avoid it
					 */
					window.webview && window.webviewPlatform == 'android' ? (
						<SelectMenu
							name="filter"
							attributes={AtoZNav.getDropdownData()}
							selected={this.state.value}
							onSelected={this.handleChange}
							onOpen={this.onOpen}
						/>
					) : (
						<div className="az-dropdown-container">
							<select onChange={this.handleChange} className="az-select">
								{AtoZNav.getDropdownData().options.map(option => (
									<option key={option.name} value={option.value}>
										{option.name}
									</option>
								))}
							</select>
						</div>
					)}

					{this.props.mode === 'players-by-name-mobile' ? (
						<div className={`searchContainer-c  ${!this.state.hideDiv ? 'showInput' : ''}`}>
							<div className="searchInputContainer">
								<div className="searchIcon" onClick={() => this.handleClick(this.state.value)}>
									<div className="ar-icon">
										<div>
											<img src={'/assets/images/header/search-icon.svg'} />
										</div>
									</div>
								</div>
								<input
									id="inputPlayerSearch"
									hidden={this.state.hideDiv}
									type="text"
									className="player-search"
									value={this.state.value}
									onChange={this.handleSearchChange}
									onClick={this.clearValue}
									onKeyPress={this.handleKeyPress}
									placeholder="Search for player"
								/>
								<i
									className={`icon-close ${this.state.hideDiv ? '' : 'show'}`}
									onClick={() => this.handleClick(this.state.value)}></i>
							</div>
						</div>
					) : null}
				</div>

				<div className="text_nav">
					<ul className="azAlphaInner">
						{letterList.map((letter, index) => (
							<li
								key={letter}
								className={alphaList && !(letter.toLowerCase() in alphaList) ? 'disabled' : null}
								onClick={() => this.onClick(letter)}>
								<Scrollchor
									data-text={letter}
									id={`hash${letter}`}
									// ref={`hash${letter}`}
									to={alphaList && !(letter.toLowerCase() in alphaList) ? '' : letter}
									animate={{ offset: this.state.offset }}>
									{letter}
								</Scrollchor>
							</li>
						))}
					</ul>
					{this.props.mode === 'players-by-name' ? (
						<div className={`searchContainer-c ${!this.state.hideDiv ? 'showInput' : ''}`}>
							<div className="searchInputContainer">
								<div className="searchIcon" onClick={() => this.handleClick(this.state.value)}>
									<div className="ar-icon">
										<div>
											<img src={'/assets/images/header/search-icon.svg'} />
										</div>
									</div>
								</div>
								<input
									id="inputPlayerSearch"
									hidden={this.state.hideDiv}
									type="text"
									className="player-search"
									value={this.state.value}
									onChange={this.handleSearchChange}
									onClick={this.clearValue}
									onKeyPress={this.handleKeyPress}
									placeholder="Search for player"
								/>
								<i
									className={`icon-close ${this.state.hideDiv ? '' : 'show'}`}
									onClick={() => this.handleClick(this.state.value)}></i>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default AtoZNav;
