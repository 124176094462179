import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';
import get from 'lodash/get';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import { Carousel } from 'react-responsive-carousel';

const mapStateToProps = (state, props) => {
	return {
		...state['OpenQuestionsDetail'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.OpenQuestionsDetail.mount()),
});

class OpenQuestionsDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			percent: 0,
			section: 'form', //form, loading, or results
		};

		this.showProgress = this.showProgress.bind(this);
		this.fetchDetails = this.fetchDetails.bind(this);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Open Questions',
		});
		logger.log('[OpenQuestions] constructor - props:%o', props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('mount')) {
			this.props.mount();
		}
		this.fetchDetails();
	}

	fetchDetails() {
		//fetch country players
		logger.log(
			'[OpenQuestionsDetail] fetchDetails - this.props: %o',
			get(this.props, 'match.params.question', false)
		);
		/*deps.services.OpenQuestionsDetail.fetch(
			""
			// this.state.path.countryPlayer.replace(
			// 	"<country_code>",
			// 	this.state.countryPlayers
			// )
		)
		.then(data => {
			this.setState(prevState => {
				return { ...prevState, OCData: data };
			});
			if (!this.loaded) {
				// pageView(ANALYTICS_PROPS.playerByCountry, this.state.countryPlayers);
				this.loaded = true;
			}
		})
		.catch(e => {
			this.setState({
			loadingError: true
			});
		});*/
	}

	showProgress() {
		let percent = this.state.percent > 0 ? 0 : 66;
		this.setState({ percent });
	}

	render() {
		logger.log('[OpenQuestionsDetail] render - this.state: %o', this.state);
		logger.log('[OpenQuestionsDetail] render - this.props: %o', this.props);

		let header_attributes = {
			headerType: 'visit',
			title: 'Open Questions',
			metaTitle: 'Open Questions ',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		if (1 == 1) {
			//replace this with check for data
			return (
				<Fragment>
					<div className="openquestions general_content">
						<div className="open_questions_watson_loader">
							<div className="open_questions_watson_loader_inner">
								<div className="watson_logo">{`<watson spinner here>`}</div>
							</div>
						</div>
						<div className="open_questions_header">
							<span>Open Questions with Watson</span>
						</div>
						<div className="go_back">
							<Link to="/en_US/open_questions/index.html">
								<i className="icon-arrow-left" />
								<span>See All Open Questions</span>
							</Link>
						</div>
						<div className="video_container"></div>
						<div className="question_title">Courtside coaching should be allowed</div>
						<div className="question_content">
							<div className={`question_results ${this.state.section == 'results' ? `shown` : `hidden`}`}>
								<div className="results_title">Watson Debate Summary</div>
								<div className="closeX">
									<i className="icon-close" />
								</div>
								<div className="results_percent">
									<div className="progress_bar" onClick={this.showProgress}>
										<div className="bar_overflow">
											<div
												className="bar"
												style={{ transform: `rotate(${this.state.percent * 1.8}deg)` }}
											/>
											<div
												className={`progress_bar_labels ${
													this.state.percent == 0 ? `` : `shown`
												}`}>
												<div className="percent_agree">
													<span className="percent_value">{this.state.percent}</span>
													<span className="percent_label">{`Agree`}</span>
												</div>
												<div className="vl" />
												<div className="percent_disagree">
													<span className="percent_value">{100 - this.state.percent}</span>
													<span className="percent_label">{`Disagree`}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="results_summary">
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer volutpat mattis
										massa interdum tempor. Vestibulum tempor nunc a quam auctor, in feugiat eros
										rutrum. Nulla vel mi velit. Morbi eu ante quis ex finibus accumsan ut id erat.
										Pellentesque et tortor elit. Donec eget finibus elit. Maecenas vitae lectus
										massa. Suspendisse elit lectus, egestas semper lacinia nec, finibus venenatis
										velit.
									</p>

									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer volutpat mattis
										massa interdum tempor. Vestibulum tempor nunc a quam auctor, in feugiat eros
										rutrum. Nulla vel mi velit. Morbi eu ante quis ex finibus accumsan ut id erat.
										Pellentesque et tortor elit. Donec eget finibus elit. Maecenas vitae lectus
										massa. Suspendisse elit lectus, egestas semper lacinia nec, finibus venenatis
										velit.
									</p>

									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer volutpat mattis
										massa interdum tempor. Vestibulum tempor nunc a quam auctor, in feugiat eros
										rutrum. Nulla vel mi velit. Morbi eu ante quis ex finibus accumsan ut id erat.
										Pellentesque et tortor elit. Donec eget finibus elit. Maecenas vitae lectus
										massa. Suspendisse elit lectus, egestas semper lacinia nec, finibus venenatis
										velit.
									</p>
								</div>
							</div>

							<div className={`loading_results ${this.state.section == 'loading' ? `shown` : `hidden`}`}>
								<div className="watson_animated" />
								<div className="spinner_label">Results are Processing...</div>
							</div>

							<div className={`user_feedback ${this.state.section == 'form' ? `shown` : `hidden`}`}>
								<div className="agree_disagree">
									<div className="agree selected">Agree</div>
									<div className="disagree">Disagree</div>
								</div>
								<div className="open_question_carousel">
									<Carousel
										showThumbs={false}
										useKeyboardArrows={true}
										showStatus={false}
										swipeable
										emulateTouch
										infiniteLoop
										showIndicators={true}
										centerMode
										draggable={true}
										centerSlidePercentage={90}>
										<div key="carousel-1" className="slide-item">
											“Coaching is a vital component of any sporting performance. Yet banning it
											almost makes it look as if it had to be hidden, as if it was shameful.”
										</div>
										<div key="carousel-2" className="slide-item">
											Blerp
										</div>
										<div key="carousel-3" className="slide-item">
											Blorp
										</div>
										{/* {images.map((item, i) => (
											<div key={i}>
												<img src={item.url} alt={item.title || item.caption} />
											</div>
										))} */}
									</Carousel>
								</div>
								<div className="open_question_submit">
									<div className="open_question_prompt">Contribute to the discussion below</div>
									<div className="open_question_form">
										<textarea className="response" />
										<span className="terms_of_use">
											{`By Clicking SUBMIT you are agreeing to these`}{' '}
											<Link to="#">Terms of Use</Link>
										</span>
										<button className="open_question_submit" value="submit">
											Submit
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<LoadingIndicator />
				</Fragment>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(OpenQuestionsDetail);
