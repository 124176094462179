/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: PullQuote
 * -----------------------------------------------------------------------------
 */

class PullQuote extends Component {
	constructor(props) {
		super(props);
		//logger.log('[PullQuote] - props:%o', props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	render() {
		// logger.log('[PullQuote] - this:%o', this);

		return (
			<div className={`${this.state.data.style} block-quote`}>
				{/* <i className="icon-quote"></i> */}
				{this.state.data.text}
				{/* <div className="quote-text"></div> */}
				{/* <div className="quote-author">{this.state.data.author}</div> */}
			</div>
		);
	}
}

export default PullQuote;
