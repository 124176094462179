import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let config = {
				relatedContentPath: Config.relatedContent.video,
				videosByCategory: Config.relatedContent.videosByCategory,
				menusAPI: Config.landingPages.video.filter,
				highlightsGeoCheck: Config.otherData.highlightsGeoCheck,
				hero: Config.landingPages.hero.video,
				relatedContent: Config.relatedContent.video,
				relatedContentCategory: Config.relatedContent.videoCategory,
				relatedContentCount: Config.landingPages.video.count,
				relatedContentNext: Config.relatedContent.nextPrevious,
				domain: Config.relatedContent.domain,
				adConfig: Config.adConfig,
				videoConfig: Config.videoPlayer,
				sponsors: Config.sponsors,
				topStories: Config.landingPages.video.topStories,
				adMode: Config.landingPages.adMode,
			};
			dispatch({ type: deps.actionTypes.VIDEOLANDING_MOUNT, data: config });
		});
	},
};
