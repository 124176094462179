/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import deps from 'dependencies';
import { withRouter } from 'react-router';
// import { fetch } from 'appdir/components/general/Util';
import MenuItems from 'appdir/components/general/MainNav/MenuItems';
import SubNav from './SubNav';
// import MenuLiveIndicators from 'appdir/components/general/Header/MenuLiveIndicators';
import LiveBar from 'appdir/components/general/LiveBar';

/**
 * -----------------------------------------------------------------------------
 * React Component: MobileNav
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MobileNav'],
		menu_open: state['Header'].menu_open,
		menuItems: state['Controller'].menuItems,
		windowSize: state['Controller'].windowSize,
		live_open: state['Header'].live_open,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MobileNav.mount()),
	unmount: data => dispatch(deps.actions.MobileNav.unmount(data)),
	hoverMobile: (isHover, menuName) => dispatch(deps.actions.MainNav.hoverMobile(isHover, menuName)),
	toggleMenu: data => dispatch(deps.actions.Header.toggleMenu(data)),
	toggleLiveMenu: data => dispatch(deps.actions.Header.toggleLiveMenu(data)),
});

class MobileNav extends Component {
	constructor(props) {
		super(props);

		this.state = {
			topOffset: '0px',
		};
		this.fetchedMenu = false;
		this.prevOffset = 0;
		this.mobileNavElem = React.createRef();
		// logger.log('[MobileNav] constructor this:%o',this);
	}

	componentDidMount() {
		// logger.log('[MobileNav] componentDidMount - this:%o', this);
		// this.mobileNavElem = document.querySelector('#uso-mobileNav');
		this.headerElem = document.querySelector('#uso-header');
		this.bodyElem = document.querySelector('body');
	}

	componentDidUpdate(prevState, prevProps) {
		// logger.log('[MobileNav] componentDidUpdate prevState:%o', prevState);
		// logger.log('[MobileNav] componentDidUpdate this:%o', this);

		/**
		 * when menu is closed, scroll the page to the previous position
		 **/

		if (prevProps.menu_open === true && this.props.menu_open === false) {
			window.scroll({
				top: this.prevOffset * -1,
				behavior: 'auto',
			});
		}
	}

	onUnHover(subMenuName, e) {
		// logger.log('[SubNav] onHover submenu:%o', subMenuName);
		this.props.hoverMobile(false, '');
		//if (moreClass !== '') {
		//this.props.hoverMobile(false, subMenuName);
		//}
	}

	render() {
		// logger.log('[MobileNav] render - this:%o', this);
		// logger.log('[MobileNav] render - this.mobileNavElem.current:%o', this.mobileNavElem.current);
		let menuClass = '';
		let bodyOffset = 0;
		let navStyle = {};
		let navHeight = 0;
		let searchClass = '';

		if (window.pageYOffset > 0) {
			bodyOffset = window.pageYOffset * -1;
		}

		// logger.log('[MobileNav] render - bodyOffset:%o', bodyOffset);

		if (this.props.menuItems) {
			if (this.props.menu_open) {
				// logger.log('this.props.menu_open', this);
				menuClass = 'menu-opened';

				/**
				 * when menu is open, add class to body which sets position to fixed,
				 * set top position to be current offset so page doesn't go back to top,
				 * set prevOffset to store value for use later.
				 **/
				if (this.bodyElem) {
					this.bodyElem.classList.add('modal-on-noscroll');
					this.bodyElem.style.top = bodyOffset + 'px';
				}

				/**
				 * when menu is open, need to dynamically set the height of the menu to allow
				 * for overflow if needed
				 */
				if (this.mobileNavElem && this.mobileNavElem.current) {
					let navLocation = this.mobileNavElem.current.getBoundingClientRect();

					// logger.log('[MobileNav] render - window.innerHeight:%o', window.innerHeight);
					// logger.log('[MobileNav] render - navLocation:%o', navLocation);

					navHeight = window.innerHeight - navLocation.top;

					// logger.log('[MobileNav] render - navHeight:%o', navHeight);

					navStyle = {
						height: navHeight + 'px',
						// overflow: 'scroll',
						paddingBottom: '86px',
					};
				}
				this.prevOffset = bodyOffset;

				if (this.headerElem && this.headerElem.classList.contains('sticky')) {
					menuClass += ' sticky';
					searchClass += 'sticky';
					/**
					 * when menu is open and fixed, need to dynamically set the height of the menu \
					 * to allow for overflow if needed - this height will be a different calculation
					 * than the one set above
					 */

					if (this.mobileNavElem && this.mobileNavElem.current) {
						navHeight = window.innerHeight - 56;

						navStyle = {
							height: navHeight + 'px',
							// overflow: 'scroll',
							paddingBottom: '120px',
						};
					}
				}
			} else {
				menuClass = '';
				searchClass = '';
				/**
				 * when menu is closed, remove class from body,
				 * set top position to be 0, if mobile nav has 'sticky'
				 * class, remove it.
				 **/
				if (this.bodyElem) {
					this.bodyElem.classList.remove('modal-on-noscroll');
					this.bodyElem.style.top = 0 + 'px';
				}

				if (this.mobileNavElem && this.mobileNavElem.current) {
					if (this.mobileNavElem.current.classList.contains('sticky')) {
						this.mobileNavElem.current.classList.remove('sticky');
					}
				}
			}

			return (
				<noindex>
					<div data-dna="global-mobile-navigation" className="global-mobile-navigation">
						{this.props.menu_open && (
							<div className={`search-box ${searchClass}`}>
								<form id="mainSearch" action="/en_US/search/results.html" method="get">
									<a href="/en_US/search/index.html" className="clear-decorate">
										<div className="ar-icon">
											<div className="icon">
												<i className="icon-search" />
											</div>
										</div>
									</a>
									<input type="text" id="search-input" name="query" placeholder="" />
								</form>
							</div>
						)}
						<div
							id="uso-mobileNav"
							className={`menu-container d-llg-none ${menuClass}`}
							role="navigation"
							ref={this.mobileNavElem}
							style={navStyle}>
							{/* <div
								onClick={e => {
									this.navClicked(e);
								}}> */}
							{/* <LiveBar /> */}
							{/* </div> */}
							<MenuItems data={get(this.props, 'menuItems.menu.item', [])} attributes="mobile" />
							{/* <MenuLiveIndicators /> */}
						</div>
						<SubNav data={get(this.props, 'menuItems.menu.item', [])} mainOpen={menuClass} />
					</div>
				</noindex>
			);
		} else {
			return <div />;
		}
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileNav));
