/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import JSXParser from 'react-jsx-parser';
import ModalOverlay from 'appdir/components/general/ModalOverlay';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import Button from 'appdir/components/common-ui/Button';
import EventsLink from 'appdir/components/general/EventsLink';
import isEmpty from 'lodash/isEmpty';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * RLL - some of this may change based on how this works for everyone and once
 * 	measurement gets sorted out
 * React Component: Share
 *
 * props:
 * url - specific url to share if not the current page
 * section - section for measurement
 * title - title for measurement
 * -----------------------------------------------------------------------------
 */

const TicketsInfoModal = props => {
	let { modalParams } = useSelector(state => state['ModalOverlay']);
	let { stadium } = modalParams;
	let session = stadium?.session?.[modalParams?.sessionIndex];
	// session['theme']['title'] = "theme title";
	// session['theme']['description'] = "<p>description</p>";

	logger.log('[TicketsInfoModal] props - props:%o', props);
	logger.log('[TicketsInfoModal] props - modalParams:%o', modalParams);

	const dispatch = useDispatch();

	const onClose = () => {
		document.body.classList.remove('modal-on-noscroll-nofixed');
		MeasurementUtils.dispatchMeasurementCall('closeModal', {
			session,
			modalType: 'TICKETS_INFO',
		});
		dispatch(deps.actions.ModalOverlay.hideOverlayModal());
	};

	/* on load (componenDidMount) */
	useEffect(() => {
		if (!document.body.classList.contains('modal-on-noscroll-nofixed')) {
			document.body.classList.add('modal-on-noscroll-nofixed');
		} else {
			document.body.classList.remove('modal-on-noscroll-nofixed');
		}
	}, []);

	return (
		<ModalOverlay onClose={onClose} containerClass="individual-tickets-modal" modalClass="tickets-info-modal">
			{stadium && session && (
				<div className="modal-content-wrapper">
					<div className="container">
						<div className="event">{session?.event}</div>
						{session?.number && session?.number !== '' && (
							<div className="session">Session {session?.number}</div>
						)}
						{session?.title && session?.title !== '' && <div className="title">{session?.title}</div>}
						{session?.date && session?.date !== '' && (
							<div className="date">
								{moment(session.date)?.format('dddd, MMMM DD')} - {session.time}
							</div>
						)}

						{!isEmpty(session?.theme) && (
							<div className="theme-wrapper">
								<div className="title">{session?.theme?.title}</div>
								<div className="description">
									<JSXParser jsx={session?.theme?.description} />
								</div>
							</div>
						)}
					</div>
					{session?.description && (
						<div className="description-wrapper">
							<div className="section-title">What's Included</div>
							<div className="container">
								<JSXParser jsx={session?.description} />
							</div>
						</div>
					)}
					<div className="container">
						{session?.link && session?.link !== '' && (
							<div className="modal-btn-wrapper">
								<EventsLink to={session?.link}>
									<Button className="session-btn">{session?.buttonText}</Button>
								</EventsLink>
							</div>
						)}
					</div>
				</div>
			)}
		</ModalOverlay>
	);
};
export default TicketsInfoModal;
