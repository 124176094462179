import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		logger.log('[LiveAtUSOpenWebview] actions.mount');

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let day = 'current';

			let data = {
				status: 'load',
				schedulePath: Config.scoringData.schedule,
				adConfig: Config.adConfig,
				liveBlogPath: Config.otherData.liveBlog,
				daysPath: Config.scoringData.scheduleDays,
				tournDay: day,
			};

			logger.log('[LiveAtUSOpenWebview] action.mount - alldata: %o', data);

			dispatch({ type: deps.actionTypes.LIVEATUSOPEN_WEBVIEW_MOUNT, data: data });
		});
	},

	unmount: () => dispatch => {
		let data = {
			status: null,
		};
		dispatch({
			type: deps.actionTypes.LIVEATUSOPEN_WEBVIEW_UNMOUNT,
			data: data,
		});
	},
	setDay: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEATUSOPEN_WEBVIEW_SETDAY, data: data });
	},
	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEATUSOPEN_WEBVIEW_FILTER, data: data });
	},
};
