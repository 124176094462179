import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MAINNAV_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.MAINNAV_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[MainNav] reducer MAINNAV_UNMOUNT newState:%o', newState);
			return newState;

		case deps.actionTypes.MAINNAV_TOGGLEMOBILE:
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[MainNav] reducer MAINNAV_TOGGLEMOBILE newState:%o', newState);
			return newState;

		case deps.actionTypes.MAINNAV_HOVERMOBILE:
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[MainNav] reducer MAINNAV_HOVERMOBILE newState:%o', newState);
			return newState;

		case deps.actionTypes.MAINNAV_UPDATE:
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[MainNav] reducer MAINNAV_UPDATE newState:%o', newState);
			return newState;

		//RLL 8/20/2022
		case deps.actionTypes.MAINNAV_NAVIGATE:
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[MainNav] reducer MAINNAV_NAVIGATE newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
