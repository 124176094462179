import ContentPageWebview from './index';
import deps from 'dependencies';

export default [
	{
		path: '/webview/:lang(en_US)/news/:category(interviews)/:date/:id.html',
		exact: true,
		component: ContentPageWebview,
		onRouteChange: () => {},
		load: params => deps.actions.ContentPageWebview.mount(params),
	},
	{
		path: '/webview/:lang(en_US)/news/:category(articles)/:date?/:id.html',
		exact: true,
		component: ContentPageWebview,
		onRouteChange: () => {},
		load: params => deps.actions.ContentPageWebview.mount(params),
	},
];
