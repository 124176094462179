/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * React Component: NextUp
 * -----------------------------------------------------------------------------
 */
class NextUp extends Component {
	constructor(props) {
		super(props);

		this.countDown = this.countDown.bind(this);

		if (!this.timer && this.props.content && this.props.auto) {
			this.timer = setInterval(this.countDown, 1000);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[NextUp] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		if (!nextProps.content) {
			this.stopCountdown();
		}

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[NextUp] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate() {
		if (!this.timer && this.props.content && this.props.auto) {
			this.timer = setInterval(this.countDown, 1000);
		}
	}

	countDown() {
		// Remove one second, set state so a re-render happens.
		let seconds = this.props.seconds - 1;
		this.setState({
			seconds: seconds,
		});

		// Check if we're at zero.
		if (seconds == 0) {
			this.nextUpPlay(true);
		}
	}

	nextUpPlay(auto) {
		this.stopCountdown();
		this.props.playNext(this.props.content);
	}

	enableToggle() {
		let enabled = !this.props.auto;

		if (!enabled) {
			this.stopCountdown();
		} else {
			this.timer = setInterval(this.countDown, 1000);
		}

		if (this.props.onUserEnable) {
			this.props.onUserEnable(enabled);
		}

		this.setState({
			auto: enabled,
		});
	}

	stopCountdown() {
		clearInterval(this.timer);
		this.timer = null;
	}

	render() {
		logger.log('[NextUp] render - this:%o', this);

		if (this.props.content) {
			return (
				<div id="overlayNextUp" className="overlayNextUp">
					<div className="nextup" style={{ display: 'block' }}>
						<div className="next_banner">
							<div className="photo">
								<img src={this.props.content !== null ? this.props.content.poster : ''} />
							</div>
							<div
								onClick={() => {
									this.props.playNext(this.props.content);
								}}
								className="next">
								<img
									src="/assets/images/video/btn_next_play.png"
									style={{
										width: '25px',
										height: '28px',
										position: 'absolute',
										left: '0',
										right: '0',
										bottom: '0',
										top: '0',
										margin: 'auto',
										display: 'block',
										flexShrink: '0',
									}}
								/>
							</div>
							<div className="countdown">
								<div className="label" style={{ display: this.props.auto ? 'block' : 'none' }}>
									Starts in...
								</div>
								<div
									id="nextCounter"
									className="count"
									style={{
										display: this.props.auto ? 'block' : 'none',
									}}>
									{this.props.seconds}
								</div>
								<div className="allow">
									<a
										onClick={() => {
											this.enableToggle();
										}}
										style={{ color: '#000000' }}>
										{this.props.auto ? 'Disable' : 'Enable'} autoplay
									</a>
								</div>
							</div>
							<div className="next-content">
								<div className="prompt">Up Next...</div>
								<div className="title">
									{this.props.content !== null ? this.props.content.title : ''}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}
export default NextUp;
