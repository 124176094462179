import React from "react";
import EventsLink from "appdir/components/general/EventsLink";
const Sponsor = props => {
  return (
    <EventsLink to={props.attributes.link}>
      <img
        src={props.attributes.imagePath}
        alt={props.attributes.alt}
        title={props.attributes.title}
        border="0"
      />
    </EventsLink>
  );
};
export default Sponsor;
