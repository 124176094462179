/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import op from 'object-path';

import { getStatusConfig } from 'appdir/components/pages/Slamtracker/Utilities';
import deps from 'dependencies';
import { useDispatch } from 'react-redux';
import { values } from 'appdir/main';
import { getAppLink } from 'appdir/components/general/Util';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Sticky Header
 * -----------------------------------------------------------------------------
 */

const StickyHeader = props => {

  {/* Pre, live, post */}
  const matchStatus = getStatusConfig(props?.statMatch?.statusCode)['status'];
  const ScoreManager = useSelector((state) => op.get(state['ScoreManager']));

  // logger.log('[Slamtracker StickyHeader] StickyHeader - props:%o', props.isHeaderFixed );


  const dispatch = useDispatch();
  let { data } = props;

  const mainProfilePath = '/en_US/players/overview/';

  const navigate = path => {
		if (values.webview) {
			let link = getAppLink(path, '');
			location.href = link.url;
		} else if (path) {
			if (data.windowSize == 'mobile') {
				dispatch(deps.actions.Controller.setSTPanelStatus({ open: false }));
			}
			dispatch(deps.actions.MainNav.navigate({ path: path }));
		}
  };

  const renderCrtIcon = (match, team) => {
		// logger.log('[Slamtracker Header] team:%o, match:%o',team, match);
		let html;
		if (team == 'team1') {
			if (!match.team1.won &&
				!match.team2.won &&
				match.statusCode === 'A'
			) {
				html = (
					<div className="live-score-container">
						<div className="live-score">
							<span>
								{props.statMatch?.scores?.gameScore[0]}
							</span>
						</div>
						<div className="crtIcon currentServer">
							{(match.server == 'A' || match.server == 'B') ? <i className="icon-ballSolid" /> : <div className='emptyIconContainer'></div>  }
						</div>
					</div>
				);
			} else {
				html = null;
			}
		}

		if (team == 'team2') {
			if (!match.team1.won &&
				!match.team2.won &&
				match.statusCode === 'A'
			) {
				html = (
					<div className="live-score-container">
						<div className="live-score">
							<span>
								{props.statMatch?.scores?.gameScore[1]}
							</span>
						</div>
						<div className="crtIcon currentServer">
							{(match.server == 'C' || match.server == 'D') ? <i className="icon-ballSolid" /> : <div className='emptyIconContainer'></div> }
						</div>
					</div>
				);
			} else {
				html = null;
			}
		}

		return html;
	}
  
  const renderPlayerImages = () => {
		let doubles = false;
		let p1A_img, p1B_img, p2A_img, p2B_img;

		p1A_img = props.playerImageURL.replace('<playerid>', props.statMatch.team1.idA);
		p2A_img = props.playerImageURL.replace('<playerid>', props.statMatch.team2.idA);

		if (props.statMatch.team1.displayNameB || props.statMatch.team2.displayNameB) {
			doubles = true;
			p1B_img = props.playerImageURL.replace('<playerid>', props.statMatch.team1.idB);
			p2B_img = props.playerImageURL.replace('<playerid>', props.statMatch.team2.idB);
		}

		return (
			<div className={`team-container ${doubles ? 'doubles' : ''}`}>
				<div className="team1">
					<div className="team-info">
						<div className="img-container">
							<div className="image">
								<img src={p1A_img} alt={props.statMatch.team1.displayNameA} />
								<br />
								{doubles && props.statMatch.team1.displayNameB ? (
									<img src={p1B_img} alt={props.statMatch.team1.displayNameB} />
								) : (
									''
								)}
								{props.statMatch.team1.won ? (
									<div className="match-winner">
										<i className="icon-check" />
									</div>
								) : null}
							</div>
							<div className="team-score">
								{props.statMatch.team1 ? (
									<div className="icon-container">{renderCrtIcon(props.statMatch, 'team1')}</div>
								) : null}
							</div>
						</div>
						<div className="name-container">
							<div className="name">
								<div className="member-one">
									<div
										role="link"
										className="link-to-player"
										onClick={() => navigate(`${mainProfilePath}${props.statMatch.team1.idA}.html`)}>
										{props.statMatch.team1.displayNameA}
									</div>
									{props.statMatch.team1.seed && (
										<div className="seed">{`[${props.statMatch.team1.seed}]`}</div>
									)}
								</div>
								{doubles && props.statMatch.team1.displayNameB ? (
									<div className="member-two">
										<div
											role="link"
											className="link-to-player"
											onClick={() =>
												navigate(`${mainProfilePath}${props.statMatch.team1.idB}.html`)
											}>
											{props.statMatch.team1.displayNameB}
										</div>
										{props.statMatch.team1.seed && (
											<div className="seed">{`[${props.statMatch.team1.seed}]`}</div>
										)}
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
				<div className="match-data">
					{matchStatus == 'live' && <span className="live-indicator"></span>}
					<span className="match-status"> {props.statMatch.status}</span>
				</div>
				<div className="team2">
					<div className="team-info">
						<div className="img-container">
							<div className="team-score">
								{props.statMatch.team2 ? (
									<div className="icon-container">{renderCrtIcon(props.statMatch, 'team2')}</div>
								) : null}
								{props.statMatch.team2.won ? (
									<div className="match-winner">
										<i className="icon-check" />
									</div>
								) : null}
							</div>
							<div className="image">
								<img src={p2A_img} alt={props.statMatch.team2.displayNameA} />
								<br />
								{doubles && props.statMatch.team2.displayNameB ? (
									<img src={p2B_img} alt={props.statMatch.team2.displayNameB} />
								) : (
									''
								)}
							</div>
						</div>
						<div className="name-container">
							<div className="name">
								<div className="member-one">
									<div
										role="link"
										className="link-to-player"
										onClick={() => navigate(`${mainProfilePath}${props.statMatch.team2.idA}.html`)}>
										{props.statMatch.team2.displayNameA}
									</div>
									{props.statMatch.team2.seed && (
										<div className="seed">{`[${props.statMatch.team2.seed}]`}</div>
									)}
								</div>
								{doubles && props.statMatch.team2.displayNameB ? (
									<div className="member-two">
										<div
											role="link"
											className="link-to-player"
											onClick={() =>
												navigate(`${mainProfilePath}${props.statMatch.team2.idB}.html`)
											}>
											{props.statMatch.team2.displayNameB}
										</div>
										{props.statMatch.team2.seed && (
											<div className="seed">{`[${props.statMatch.team2.seed}]`}</div>
										)}
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
  };

  return (
    <div className='sticky-header-wrapper'>
      <div className='sticky-header-container'>
        {renderPlayerImages()}
      </div>
    </div>
  );

}

export default StickyHeader;