/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from "react-redux";
import deps from "dependencies";
import AgeGate from "./AgeGate";

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: AgeGate
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
  return Object.assign({}, state["AgeGate"], props);
};

const mapDispatchToProps = dispatch => ({
  mount: () => dispatch(deps.actions.AgeGate.mount()),
  ungate: () => dispatch(deps.actions.AgeGate.ungate())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgeGate);
