import deps from 'dependencies';

export default {
	mount: ({ player }) => (dispatch, getState, store) => {
		// log("[PlayerPage] mount - player:%o", player);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const pathObj = {
				playerInfo: Config.scoringData.playersDetail, //playerDetailData /<id>.json
				playerDataPath: Config.scoringData.players, //playerScoringData / players.json
				playerMatches: Config.scoringData.playerCompletedMatches,
				playerImagePath: Config.otherData.playerImagePath,
				flagImagePathLarge: Config.otherData.flagImagePathLarge,
				flagImagePathSmall: Config.otherData.flagImagePathSmall,
				playerProfileImagePath: Config.otherData.playerImagePath,
				relatedContentPath: Config.relatedContent,
				playerMatchStats: Config.scoringData.matchStatistics,
				showHistory: Config.otherData.showPlayerHistory,
			};

			dispatch({
				type: deps.actionTypes.PLAYERPAGE_LOADING,
				data: pathObj,
			});
		});
	},
};
