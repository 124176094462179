import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.VIDEOPLAYER_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.VIDEOPLAYER_UNMOUNT:
			newState = {
				...state,
				...action.data,
				playing: [],
			};
			return newState;

		case deps.actionTypes.VIDEOPLAYER_CURRENTLY_PLAYING:
			logger.log('[VideoPlayer] VIDEOPLAYER_CURRENTLY_PLAYING %o', action);
			newState = {
				...state,
				playing: [...state.playing, action.data],
			};
			return newState;

		case deps.actionTypes.VIDEOPLAYER_AUTOPLAY:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.VIDEOPLAYER_UPDATE:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		default:
			return state;
	}
};
