/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import op from 'object-path';
import EventsRedirect from './general/EventsRedirect';
import WindowSize from './general/WindowSize';
import ModalOverlayContainer from './general/ModalOverlay/ModalOverlayContainer';
import isEmpty from 'lodash/isEmpty';
import { DFPSlotsProvider, DFPManager } from 'react-dfp';

/**
 * -----------------------------------------------------------------------------
 * React Component: TemplateGeneric
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['TemplateGeneric'],
		globalLink: state['Controller'].globalLink,
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	//mount: () => dispatch(actions.TemplateHome.mount()),
});

class TemplateGeneric extends Component {
	constructor(props) {
		super(props);
		this.handleEmptyAd = this.handleEmptyAd.bind(this);
		this.adStatus = {};
		this.adReload_timeout = {};
		logger.log('[TemplateGeneric] constructor this:%o',this);
	}

	componentDidMount() {
		logger.log('[TemplateGeneric] componentDidMount');

		this.setState({
			templateLoaded: true
		}, () => {
			DFPManager.getGoogletag().then((googletag) => {
				logger.log('[TemplateGeneric AdTag] componentDidMount googletag:%o',googletag);
				
				googletag.cmd.push(() => {
					googletag.pubads().addEventListener('slotRenderEnded', event => this.handleEmptyAd(event));
				})
				
			})
			.catch((error) => {
				logger.log('[TemplateGeneric AdTag] componentDidMount googletag getSlots error:%o', error);
			})
		})
		
	}

	handleEmptyAd(event) {
		let slotId = event?.slot?.getSlotElementId();
		try {
			if (event.isEmpty == true && slotId) {
				logger.log('[TemplateGeneric AdTag] handleEmptyAd event.isEmpty:%o, slotId:%o, adStatus:%o',event.isEmpty, slotId, this?.adStatus);
	
				// if (this?.adStatus?.[slotId] == undefined) {
				// 	if (this.adReload_timeout[slotId]) {
				// 		clearTimeout(this.adReload_timeout[slotId]);
				// 	}
				// 	//this.containsAd = !event.isEmpty;  
				// 	this.adStatus[slotId] = 'loading';
				// 	logger.log('[TemplateGeneric AdTag] handleEmptyAd slot reload slotId:%o, startTimer:%o', slotId, this?.adStatus?.[slotId]);
	
				// 	this.adReload_timeout[slotId] = setTimeout(() => {
				// 		DFPManager.refresh(slotId); // i don't think we should be forcing a refresh
				// 		//DFPManager.reload();
				// 		this.adStatus[slotId] = 'loaded';
				// 		logger.log('[TemplateGeneric AdTag] handleEmptyAd slot reload slotId:%o, endTimer:%o', slotId, this?.adStatus?.[slotId]);
				// 	}, 5000);
				// }
			}
		} catch (e) {
			logger.log('[TemplateGeneric AdTag] handleEmptyAd e:%o', e);

		}
	}

	componentWillUnmount() {
		logger.log('[TemplateGeneric] componentWillUnmount');

		DFPManager.getGoogletag().then((googletag) => {
			logger.log('[TemplateGeneric AdTag] componentWillUnmount googletag:%o',googletag);

			googletag.cmd.push(() => {
				logger.log('[TemplateGeneric AdTag] componentWillUnmount slots:%o',googletag.pubads().getSlots());
				googletag.pubads().removeEventListener('slotRenderEnded', event => this.handleEmptyAd(event));
			})
		})
		.catch((error) => {
			logger.log('[TemplateGeneric AdTag] componentWillUnmount googletag getSlots error:%o', error);

		})

	}

	render() {
		let { children } = this.props;
		// logger.log("[TemplateGeneric] globalLink - %o", this.props.globalLink);
		let redirectLink = op.get(this.props.globalLink, 'link');
		let redirectQuery = op.get(this.props.globalLink, 'query');
		if (redirectLink) {
			// logger.log(
			//   "[TemplateGeneric] has globalLink - %o",
			//   this.props.globalLink
			// );
			return <EventsRedirect to={redirectLink} query={redirectQuery} />;
		} else {
			return (
				<Fragment>
					<WindowSize />
					<DFPSlotsProvider
						dfpNetworkId={"5681"}
						collapseEmptyDivs={true}
						singleRequest={true}
						lazyLoad={{
							fetchMarginPercent: 100,
							renderMarginPercent: 25,
							mobileScaling: 0
						}}
					>
					{children}
					</DFPSlotsProvider>
					<ModalOverlayContainer title={this.props.title} description={this.props.description} />
				</Fragment>
			);
		}
	}
}

TemplateGeneric.defaultProps = {
	header: {},
	mounted: false,
	navbarFixed: false,
	headerMarginBottom: 0,
	bodyClass: 'uso-site',
	title: 'Official Site of the 2024 US Open Tennis Championships - A USTA Event',
	description:
		'The official site of the 2024 US Open Tennis Championships.  Live tennis scores, watch every match live stream, listen to live radio, and follow the action behind every match.',
	keywords: 'US Open',
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateGeneric);
