import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[SeedsListPage] action.mount - ensureConfig');

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[SeedsListPage] action.mount - dispatch - param:%o', params);
			logger.log('Config: ', Config);

			let data = {
				seedsPath: Config.scoringData.seedsList,
				basePicPath: Config.otherData.basePicPath,
				profilePath: Config.scoringData.players,
				updatedSeedsPath: Config.scoringData.updatedSeedsList,
			};

			dispatch({ type: deps.actionTypes.SEEDSLISTPAGE_LOAD, data: data });
		});
	},
	unmount: data => (dispatch, getState, store) => {
		logger.log('[MainNav] actions.unmount data:%o', data);
		dispatch({ type: deps.actionTypes.SEEDSLISTPAGE_UNMOUNT, data: data });
	},
};
