import React, { useEffect, useState } from 'react';

/**
 * Toggle Switch Component
 *
 * @param {*} props.attributes
 * @returns
 */

const ToggleButton = props => {
	const { label, toggled, id, onClickCallback } = props?.attributes;

	const [isToggled, toggle] = useState(toggled);

	// logger.log('[ToggleButton]  isToggled:%o, toggled:%o, area:%o', isToggled, toggled, props.attributes?.area);

	/** toggled outside of the component, update state to match the props */
	useEffect(() => {
		toggle(toggled);
	}, [toggled]);

	const callback = () => {
		toggle(!isToggled);
		onClickCallback(!isToggled);
	};

	return (
		<div className="toggle-button">
			<label htmlFor={id}>
				<input
					type="checkbox"
					id={id}
					checked={isToggled}
					tabIndex={0}
					aria-checked={isToggled}
					aria-label={label}
					onChange={callback}
					onKeyPress={() => (event.key === 'Enter' ? callback : null)}
				/>
				<span />
				<strong>{label}</strong>
			</label>
		</div>
	);
};

export default ToggleButton;
