/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Template from 'components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import JSXParser from 'react-jsx-parser';
import StubBox from 'appdir/components/common-ui/StubBox';
import RelatedContentChips from 'appdir/components/cms/RelatedContentChips';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import get from 'lodash/get';
import { isIOS } from 'react-device-detect';
import LiveVideoSubnav from '../LiveVideo/LiveVideoSubnav';
import AdTag from 'appdir/components/general/AdTag';

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveShow
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LiveShow'],
		...props,
		stubs: state['Config'].stubPages,
		adConfig: state['Config']['adConfig'],
		liveVideo: state['ActiveData'].video,
		windowSize: state['PageHeader'].windowSize,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveShow.mount()),
	unmount: () => dispatch(deps.actions.LiveShow.unmount()),
	select: selected => dispatch(deps.actions.LiveShow.select(selected)),
});

class LiveShow extends Component {
	constructor(props) {
		super(props);

		logger.log('[LiveShow] constructoLiveShow:%o', props);

		this.skip = 0;
		let currVideo = null;
		this.state = {
			currVideo,
		};
	}

	componentDidMount() {
		logger.log('[LiveShow] componentDidMount - this:%o', this);
	}

	componentWillUnmount() {
		//logger.log('[LiveShow] componentWillUnmount');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[LiveShow] componentDidUpdate - this:%o', this);
	}

	onVideoEvent = event => {
		logger.info('[LiveShow] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				directVideo: '',
			});
		}
	};

	onNavClick = selected => {
		if (selected != this.props.selected) {
			//logger.info('[LiveShow] onNavClick - selected:%o', selected);
			this.props.select(selected);
		}
	};

	renderLiveShowHero() {
		// first lets get the video info and dertimine if its live
	}

	render() {
		logger.log('[LiveShow] render - this:%o', this);

		let title = this.props?.liveVideo?.wimchan ? this.props?.liveVideo?.wimchan?.name : 'US Open Live Stream';
		let header_propsData = {
			headerType: 'live-streaming',
			title: title,
			shortTitle: title,
			scroll: false,
			metaTitle: title + ' | Videos',
			metaDescription: 'US Open Live Stream from the US Open Tennis Championships',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/video/index.html',
					title: 'Watch',
				},
			],
			title: 'US Open Finals Matchcast',
		};

		// if have selected video and geos are set
		if (this.props.stubs && this.props.stubs.liveshow && this.props.stubs.liveshow.stub == 'stub') {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<StubBox
						attributes={{
							title: header_propsData.title,
							message: this.props.stubs.liveshow.text,
							basePicPath: this.props.basePicPath,
						}}
					/>
				</Template>
			);
		} else if (this.props.liveVideo && this.props.liveVideo.wimchan) {
			let vidData = this.props.liveVideo.wimchan;
			let currVideoData = {
				id: vidData.code,
				title: vidData.name,
				poster: get(vidData, 'images.slate', ''),
				shareUrl: 'https://www.usopen.org/en_US/video/us-open-finals-matchcast.html',
				streams: [
					{
						cdn: 'Akamai',
						url: vidData.media.desktop[0].url,
						mimeType: 'application/x-mpegURL',
						streamType: 'live',
					},
				],
				tracks: [
					{
						kind: 'captions',
						type: vidData.media.desktop[0].captionsType,
						srclang: 'en',
						src: '',
					},
				],
				startSeconds: 0,
				autoplay: isIOS ? false : true,
				autoplayPolicy: 'mutedinline',
				playsinline: false,
				displayDate: '',
				ads: [],
			};

			logger.log('[LiveShow] render - currVideoData:%o', currVideoData);
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="page-content live_show">
						<LiveVideoSubnav history={this.props.history} selected={`usofinals`} />
						<section className="heroSection landing">
							{currVideoData && vidData.live ? (
								<div className="heroVideo">
									<VideoPlayer
										attributes={{
											videoData: currVideoData,
											shouldAutoplay: true,
											id: 'live_show',
											reset: false,
											showPanelIcon: true,
										}}
									/>
								</div>
							) : (
								<div className="live-video">
									<div className="live-video-slate">
										<div className="slate-container">
											<div className="slate-image">
												<img src={vidData.images.slate} alt={`${vidData.name} Off Air`} />
											</div>
											<div className="slate-message">
												{/* <h1>{vidData.name}</h1> */}
												{/* <h2>Off Air</h2> */}
											</div>
										</div>
									</div>
								</div>
							)}
						</section>
						{/* <div
							style={{
								width: '100%',
								textAlign: 'center',
								margin: '20px auto',
							}}>
							<div id="Chip1">
								<AdTag
									adConfig={this.props.adConfig.live_video}
									dfpNetworkId={this.props.adConfig.dfpNetworkId}
								/>
							</div>
						</div> */}
						{this.props.stubs &&
						this.props.stubs.liveshowchips &&
						this.props.stubs.liveshowchips.stub != 'stub' ? (
							<React.Fragment>
								<div className="live_show_desc">
									<JSXParser jsx={this.props.stubs.liveshowchips.text} />
								</div>
								{this.props.usonowApiPath ? (
									<section className="chips_content">
										<h2>{this.props.stubs.liveshowchips.title}</h2>
										<RelatedContentChips
											chipsId={'live_show_chips'}
											showAds={false}
											skip={this.skip}
											apiPath={this.props.usonowApiPath}
										/>
									</section>
								) : null}
							</React.Fragment>
						) : null}
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveShow);
LiveShow;
