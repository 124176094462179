/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from "react";

/**
 * -----------------------------------------------------------------------------
 * React Component: AgeGate
 * -----------------------------------------------------------------------------
 */

class AgeGate extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, this.props);
    this.generateDays = this.generateDays.bind(this);
    this.generateYears = this.generateYears.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (this.state.hasOwnProperty("mount")) {
      this.state.mount(this);
    }
  }

  generateDays() {
    let days = [];
    let i = 1;
    days.push(
      <option value="0" key="day">
        Day
      </option>
    );
    for (i; i <= 31; i++) {
      days.push(
        <option value={i} key={`day${i}`}>
          {i}
        </option>
      );
    }
    return days;
  }

  generateYears() {
    let years = [];
    let i = 2016;
    years.push(
      <option value="2017" key="year">
        Year
      </option>
    );
    for (i; i >= 1901; i--) {
      years.push(
        <option value={i} key={`year${i}`}>
          {i}
        </option>
      );
    }
    return years;
  }

  formUpdate(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  submit() {
    let underAge = true;
    let today = new Date();
    let birthday = new Date(
      this.state.year,
      this.state.month,
      this.state.day,
      "00",
      "00",
      "00"
    );
    let age = today.getFullYear() - birthday.getFullYear();
    let m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    underAge = age >= 21 ? false : true;

    logger.log("[AgeGate] age:%o, birthday:%o", age, birthday.toString());
    this.setState({ gateError: underAge });
    if (!underAge) {
      this.props.ungate();
    }
  }

  render() {
    logger.log("[AgeGate] render state:%o", this.state);
    return (
      <section id="age-gate-container">
        <div className="age-gate">
          <img src="/assets/images/logos/grey_goose_age_gate.png" />
          <h2>You must be 21 or over to continue into this site.</h2>
          <p>
            Please enter your date of birth and then press "Go" to enter the
            site.
          </p>
          {this.state.gateError && (
            <p>
              Sorry, you must be at least 21 years old in order to continue.
            </p>
          )}
          <div id="grey_goose_agegate">
            <div id="age-gate">
              <select
                id="month"
                name="month"
                aria-label="Month"
                value={this.state.month}
                onChange={this.formUpdate}
              >
                <option value="0">Month</option>
                <option value="0">January</option>
                <option value="1">February</option>
                <option value="2">March</option>
                <option value="3">April</option>
                <option value="4">May</option>
                <option value="5">June</option>
                <option value="6">July</option>
                <option value="7">August</option>
                <option value="8">September</option>
                <option value="9">October</option>
                <option value="10">November</option>
                <option value="11">December</option>
              </select>
              <select
                id="day"
                name="day"
                aria-label="Day"
                value={this.state.day}
                onChange={this.formUpdate}
              >
                {this.generateDays()}
              </select>
              <select
                id="year"
                name="year"
                aria-label="Year"
                value={this.state.year}
                onChange={this.formUpdate}
              >
                {this.generateYears()}
              </select>
            </div>
            {this.state.day && this.state.month && this.state.year && (
              <input
                id="verify"
                name="verify"
                value="Go"
                className="submit"
                type="submit"
                onClick={this.submit}
              />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default AgeGate;
