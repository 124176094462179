/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PageHeader from 'appdir/components/general/PageHeader';
import NewsTile from 'appdir/components/content/NewsTile';
import AdTag from 'components/general/AdTag';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: NewsIndex
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['NewsIndex'],
		landingPages: state['Config'].landingPages,
		tournamentYear: state['Config']?.scoring?.tournamentYear,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.NewsIndex.mount()),
	filter: settings => dispatch(deps.actions.NewsIndex.filter(settings)),
});

class NewsIndex extends Component {
	constructor(props) {
		super(props);

		logger.log('[NewsIndex] constructor - props:%o ', props);

		this.state = {
			loaded: false,
			hero: null,
			showAll: false,
			years: [],
			defaultDay: null,
			defaultDayId: null,
			defaultMonth: null,
			defaultYear: null,
			selectedYear: '',
			selectedMonth: '',
			selectedDay: '',
			selectedDayId: '',
			newsIndex: null,
			tournIndex: null,
			tournIdIndex: null,
			filter: null,
			urlParams: null,
			relatedContent: null,
			yearsAvailable: [],
			tournDatesAvailable: [],
			showingCells: 12,
		};

		this.loadedJson = ''; //keep track of last loaded json url
		this.filterType = '';
		this.loadMoreCount = 1;
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'News',
		});
	}

	componentDidMount() {
		logger.log('[NewsIndex] componentDidMount - this:%o', this);
		// if (this.state.location.search != "") {
		//   this.getFilter(this.state.location.search);
		// }
	}

	componentWillUnmount() {
		this.props.filter({
			type: '',
			open: '',
		});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[NewsIndex] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let url = ''; // redirect url
		let data = prevState.data; // json data
		// logger.log(
		// 	'[NewsIndex] getDerivedStateFromProps - next props status:%o this state:%o next props:%o',
		// 	nextProps.status,
		// 	prevState,
		// 	nextProps
		// );

		let newState = {
			...prevState,
			nav: url,
			data: data,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[NewsIndex] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[NewsIndex] componentDidUpdate - state: %o', this.state);
		const showAll = this.state.showAll;
		const year = this.state.selectedYear;
		const month = this.state.selectedMonth;
		const day = this.state.selectedDay;
		const filter = JSON.stringify(this.state.urlParams);
		const newsIndex = this.state.newsIndex;
		const tournIndex = this.state.tournIndex;

		if (!isEqual(prevProps, this.props)) {
			this.getFilter(this.props.location.search);
		}

		// There's no hero for 2022
		// if (this.state.hero == null) {
		// 	this.getHero();
		// }
		if (this.props.path) {
			if (showAll) {
				if (newsIndex == null) {
					this.getNewsIndex();
				} else if (
					prevState.selectedMonth != month ||
					prevState.selectedYear != year ||
					prevState.showAll != showAll
				) {
					this.constructUrl();
					this.getRelatedContent();
				}
			} else {
				if (tournIndex == null) {
					this.getTournIndex();
				} else if (prevState.selectedDay != day || prevState.showAll != showAll) {
					this.constructUrl();
					this.getRelatedContent();
				}
			}
		}
	}

	getFilter(search) {
		logger.log('[NewsIndex] getFilter - this: %o', this);
		search = search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);
		//parsedQs = {year: "2018", month: "11", day: "", mode: "all", filter: ""}
		if (JSON.stringify(this.state.urlParams) != JSON.stringify(parsedQs)) {
			this.setState({
				urlParams: parsedQs,
			});
		}
	}

	parseFilter() {
		// parsedQs = {year: "2018", month: "11", day: "", mode: "all", filter: ""}
		const filter = this.state.urlParams;
		if (filter != null && filter.mode != null) {
			let showAll = this.state.showAll;
			let selectedYear = '';
			let selectedMonth = '';
			let selectedDayId = '';
			let selectedDay = '';

			logger.log('[NewsIndex] Parse Filter: %o', filter);

			if (filter.year != undefined) {
				selectedYear = filter.year;
			}
			if (filter.month != undefined) {
				selectedMonth = filter.month;
			}
			if (filter.day != undefined) {
				selectedDayId = filter.day;
			}
			if (filter.mode != undefined) {
				showAll = filter.mode == 'all' ? true : false;
			} else {
				showAll = true;
			}

			this.setState({
				relatedContent: null,
				showAll: showAll,
				defaultYear: selectedYear,
				defaultMonth: selectedMonth,
				defaultDayId: selectedDayId,
			});
		}
	}

	constructUrl() {
		let urlString = '?year=<y>&month=<m>&day=<d>&mode=<mode>&filter=';

		if (this.state.showAll) {
			urlString = urlString.replace('<y>', () => {
				return this.state.selectedYear == '' ? this.state.defaultYear : this.state.selectedYear;
			});
			urlString = urlString.replace('<m>', () => {
				return this.state.selectedMonth == '' ? this.state.defaultMonth : this.state.selectedMonth;
			});
			urlString = urlString.replace('<d>', () => {
				return '';
			});
			urlString = urlString.replace('<mode>', () => {
				return 'all';
			});
		} else {
			urlString = urlString.replace('<y>', () => {
				return '';
			});
			urlString = urlString.replace('<m>', () => {
				return '';
			});
			urlString = urlString.replace('<d>', () => {
				return this.state.selectedDayId == '' ? this.state.defaultDayId : this.state.selectedDayId;
			});
			urlString = urlString.replace('<mode>', () => {
				return 'tourn';
			});
		}
		logger.log('[NewsIndex] constructUrl: %o', urlString);

		// until url is fixed, this is breaking refresh
		// this.props.history.push({
		//   pathname: "/en_US/news/index.html",
		//   search: urlString
		// });
	}

	// constructFilter() {
	//     let filter = {};

	//     if(this.state.showAll) {
	//         filter.month = this.state.selectedMonth;
	//         filter.year = this.state.selectedYear;
	//         filter.mode = 'all';
	//     } else {
	//         filter.mode = 'tourn';
	//         filter.day = this.state.selectedDayId;
	//     }

	//     filter.filter = "";

	//     logger.log('[NewsIndex] constructFilter: %o', filter);

	//     this.setState({
	//         urlParams: filter
	//     })
	// }

	getNewsIndex() {
		logger.log('[NewsIndex] getNewsIndex: %o', this);

		if (this.props.path != null) {
			let apiDataPath = this.props.path.replace('<section>', () => {
				return 'news_index';
			});

			fetch(apiDataPath).then(result => {
				let dates = result.content.dates;
				let newsIndexDates = {};
				let firstObject = null;
				let years = {};
				let defaultYear = this.state.urlParams?.year;
				let defaultMonth = this.state.urlParams?.month;

				firstObject = dates[0];

				dates.forEach(function(element) {
					newsIndexDates[element.key] = {
						file: element.file,
					};
					years[element.year] = '';
				});

				if (
					(defaultYear == null && defaultMonth == null) ||
					newsIndexDates[defaultYear + defaultMonth] == null
				) {
					defaultYear = dates[0].year;
					defaultMonth = dates[0].month;
				}

				let yearsArray = Object.keys(years)
					.sort()
					.reverse();

				if (this.state.selectedMonth == '' || this.state.selectedYear == '') {
					this.setState(
						{
							newsIndex: newsIndexDates,
							firstNewsObject: firstObject,
							yearsAvailable: yearsArray,
							defaultYear: defaultYear,
							defaultMonth: defaultMonth,
							selectedYear: defaultYear,
							selectedMonth: defaultMonth,
						},
						() => {
							logger.log('[NewsIndex] after getNewsIndex: %o', this.state);
						}
					);
				} else {
					this.setState(
						{
							newsIndex: newsIndexDates,
							firstNewsObject: firstObject,
							yearsAvailable: yearsArray,
							defaultYear: defaultYear,
							defaultMonth: defaultMonth,
						},
						() => {
							logger.log('[NewsIndex] after getNewsIndex: %o', this.state);
						}
					);
				}
			});
		}
	}

	getTournIndex() {
		logger.log('[NewsIndex] getTournIndex: %o', this);

		let apiDataPath = this.props.path.replace('<section>', () => {
			return 'news_tourn_index';
		});

		fetch(apiDataPath).then(result => {
			const dates = result.content.dates;
			logger.log('[NewsIndex] this:%o', this);
			let tournIndexDates = {};
			let tournIdIndex = {};
			let firstObject = null;
			let newsFilterKey = null;
			let tournDates = [];
			let defaultDay = this.state.defaultDay != null ? this.state.defaultDay : null;
			let defaultDayId = this.state.urlParams?.day != null ? this.state.urlParams?.day : null;

			firstObject = dates[0];
			newsFilterKey = dates[0].display;

			dates.forEach(function(element) {
				tournIndexDates[element.display] = {
					file: element.file,
					key: element.key,
				};
				tournIdIndex[element.key] = {
					file: element.file,
					display: element.display,
				};
				tournDates.push({
					display: element.display,
					dayid: element.dayid,
					key: element.key,
				});
			});

			if (tournIdIndex[defaultDayId] != null) {
				defaultDay = tournIdIndex[defaultDayId].display;
			} else {
				let defaultDayObject = find(dates, function(o) {
					return o.chunk > 0;
				});
				logger.log('[NewsIndex] defaultDayObject:%o', defaultDayObject);
				defaultDay = defaultDayObject.display; //newsFilterKey;
				defaultDayId = defaultDayObject.key; //dates[0].key;
			}

			let tournArray = tournDates.sort((a, b) => {
				return a.dayid - b.dayid;
			});

			logger.log('[NewsIndex] getTournIndex - tournIndexDates: %o', tournIndexDates);

			if (this.state.selectedDay == '' || this.state.selectedDayId == '') {
				this.setState(
					{
						tournIndex: tournIndexDates,
						tournIdIndex: tournIdIndex,
						firstTournObject: firstObject,
						tournDatesAvailable: tournArray,
						defaultDayId: defaultDayId,
						defaultDay: defaultDay,
						selectedDay: defaultDay,
						selectedDayId: defaultDayId,
					},
					() => {
						logger.log('[NewsIndex] after getTournIndex: %o', this.state);
					}
				);
			} else {
				this.setState(
					{
						tournIndex: tournIndexDates,
						tournIdIndex: tournIdIndex,
						firstTournObject: firstObject,
						tournDatesAvailable: tournArray,
						defaultDayId: defaultDayId,
						defaultDay: defaultDay,
					},
					() => {
						logger.log('[NewsIndex] after getTournIndex: %o', this.state);
					}
				);
			}
		});
	}

	getAdObject() {
		const slotId = `ad-${Math.floor(Math.random() * 10000000)}`;

		//`/${this.props.adConfig.dfpNetworkId}/${this.props.adConfig.landingpage.adUnit}`} size={this.props.adConfig.landingpage?.sizes[2]}
		return {
			type:'ad',
			slotId
		}
	}

	getRelatedContent() {
		logger.log('[NewsIndex] getRelatedContent: %o', this.state);

		let filePath = '';
		const newsIndex = this.state.newsIndex;
		const tournIndex = this.state.tournIndex;
		const showAll = this.state.showAll;
		let month = this.state.selectedMonth == '' ? this.state.defaultMonth : this.state.selectedMonth;
		let year = this.state.selectedYear == '' ? this.state.defaultYear : this.state.selectedYear;
		let day = this.state.selectedDay == '' ? this.state.defaultDay : this.state.selectedDay;

		// if(this.state.firstNewsObject != null) {
		//     filePath = this.state.firstNewsObject.file;
		// } else {
		//     filePath = this.state.newsIndexDates[this.state.newsFilterKey].file;
		// }
		if (showAll) {
			let key = String(year + month);
			if (newsIndex[key] != null) {
				filePath = newsIndex[key].file;
			} else {
				logger.log("[NewsIndex] File path doesn't exist for key: %o", key);
				this.setEmptyRelatedContent();
			}
		} else {
			if (tournIndex[day] != null) {
				filePath = tournIndex[day].file;
			} else {
				logger.log("[NewsIndex] File path doesn't exist for key: %o", day);
				this.setEmptyRelatedContent();
			}
		}

		if (filePath != '') {
			// let path = this.props.newsFilterPath.replace('<url>', () => {
			// 	return filePath;
			// });

			let path = filePath;

			fetch(path).then(result => {
				logger.log('[NewsIndex] getRelatedContent - result:%o', result.content);

				if (result.content.length > 0) {
					// place ad in 3rd spot if 3 or more items, otherwise place in 2nd spot
					if (result.content.length < 3) {
						result.content[result.content.length] = this.getAdObject();
					} else {
						result.content.splice(2, 0, this.getAdObject());
					}
					// The following are the options for ad placement:
					//  yearround
					//  tournament

					// place ad at position 7 and then every 5th after
					if (this.props.adMode == 'tournament' && result.content.length > 7) {
						for (var i = 7; i < result.content.length; i++) {
							if ((i - 2) % 5 == 0) {
								result.content.splice(i, 0, this.getAdObject());
							}
						}
					}
				}

				this.setState({
					relatedContent: result,
					showingCells: 12,
				});
			});
		}
	}

	setEmptyRelatedContent() {
		this.setState({
			relatedContent: {
				content: [],
			},
		});
	}

	getHero() {
		const path = this.props.heroPath;
		logger.log('[NewsIndex] getHero - path: %o', path);
		if (path) {
			fetch(path).then(result => {
				logger.log('[NewsIndex] getHero - result: %o', result);
				this.setState({
					hero: result.hero,
				});
			});
		}
	}

	checkError() {
		//check if need to display error
		let error = '';
		if (this.count == 0) {
			error = 'show';
		}
		return <div className={`errorMessage ${error}`}>There is no content for your selection.</div>;
	}

	renderContent() {
		if (this.state.relatedContent == null) {
			return <LoadingIndicator />;
		}
		if (this.state.relatedContent.content.length == 0) {
			return this.renderNoNews();
		} else {
			return this.renderNews();
		}
	}

	renderNews() {
		let content = this.state.relatedContent.content;
		const numberOfCells = this.state.showingCells;

		return (
			<div className="news-tile">
				<div className="newsChips">
					<div className="chip_content">
						{content.map((item, index) => {
							if (index < numberOfCells) {
								if (item.type === 'news') {
									let newsObject = {};
									const imagePrefix = this.props.newsImagePath.replace('<url>', () => {
										return '';
									});
									newsObject['col-style'] = 'three-col';
									newsObject.contentId = item.contentId;
									newsObject.date = item.displayDate;
									newsObject.images = item.images[0];
									newsObject.shortTitle = item.shortTitle;
									newsObject.title = item.title;
									newsObject.type = item.type;
									newsObject.subType = item.subType;
									newsObject.prefix = imagePrefix;
									newsObject.metadata = item.metadata;
									if (item.metadata.credit !== '') {
										newsObject.author = 'By ' + item.metadata.credit;
									} else {
										newsObject.author = '';
									}
									newsObject.url = item.url;
									newsObject.onClick = e => {
										this.newsTileClicked(e, index);
									};

									return (
										<NewsTile attributes={newsObject} key={item.contentId} categoryFilter={true} />
									);
								} else {
									return (
										<div key={index} id={`ad${index}`} className="news-ad">
											<AdTag
												id={`adtag${index}`}
												adConfig={this.props.adConfig.landingpage}
												dfpNetworkId={this.props.adConfig.dfpNetworkId}
											/>
										</div>
									);
								}
							} else {
								return (item.type === 'ad' ? <div id={item.slotId} style={{display:"none"}} /> : null)
							}
						})}
						
					</div>
					{this.renderLoadMore()}
				</div>
			</div>
		);
	}

	renderNoNews() {
		return (
			<div className="news-tile">
				<div className="no-news">
					<p>No news was found for the current selection.</p>
				</div>
			</div>
		);
	}

	renderLoadMore() {
		if (this.state.relatedContent.content.length > this.state.showingCells) {
			return (
				<button
					onClick={e => {
						this.loadMorePressed(e);
					}}
					className="load-more-button">
					Load More
				</button>
			);
		} else {
			return null;
		}
	}

	loadMorePressed(e) {
		MeasurementUtils.dispatchMeasurementCall('LoadMore', {
			count: this.loadMoreCount,
		});
		this.loadMoreCount++;
		this.setState({
			showingCells: this.state.showingCells + 12,
		});
	}

	renderHero() {
		if (this.state.hero) {
			const imagePath = this.props.newsImagePath.replace('<url>', () => {
				return this.state.hero.photo.desktop;
			});

			const header_attributes = {
				headerType: 'generic',
				title: !this.state.hero.title ? '---' : this.state.hero.title,
				shortTitle: !this.state.hero.type ? '---' : this.state.hero.type,
				titleElem: '.landing--header',
				metaTitle: !this.state.hero.type ? '' : this.state.hero.type,
				metaDescription: '',
				imageSrc: imagePath,
				metaDate: '',
				metaPlayers: '',
				link: this.state.hero.url,
				canonicalLink: document.location.href.split('?')[0],
			};

			return <PageHeader attributes={header_attributes} />;
		} else {
			return null;
		}
	}

	convertNewsTile(item) {
		let newsObject = {};
		newsObject['col-style'] = 'three-col';
		newsObject.contentId = item.contentId;
		newsObject.date = item.displayDate;
		newsObject.images = item.images;
		newsObject.shortTitle = item.shortTitle;
		newsObject.title = item.title;
		newsObject.type = item.type;
		newsObject.url = item.url;
		newsObject.onClick = this.newsTileClicked;

		return <NewsTile attributes={item} />;
	}

	filter() {
		const showAll = this.state.showAll;
		return (
			<>
				<section className="filters">
					<div className="selection">
						{this?.props?.landingPages?.news?.hideTournFilter ? null : (
							<div
								className={`photos tourny ${!showAll ? 'active' : ''}`}
								onClick={e => {
									this.radioButtonClick('Tourn');
								}}>
								<div className="radio select" />
								<span>{this.props.tournamentYear}</span> Tournament
							</div>
						)}
						{this?.props?.landingPages?.news?.hideYearAndMonths ? (
							<section className="filters">
								<div className="selection">&nbsp;</div>
							</section>
						) : (
							<div
								className={`photos all ${showAll ? 'active' : ''}`}
								onClick={e => {
									this.radioButtonClick('All');
								}}>
								<div className="radio" />
								<span>By Year and</span> Month
							</div>
						)}
					</div>
				</section>

				{showAll && !this?.props?.landingPages?.news?.hideYearAndMonths ? (
					<section className="filters third">{this.renderSubFilter(showAll)}</section>
				) : null}
			</>
		);
	}

	minutely() {
		return <div className="minute-tv" data-embed-name="news"></div>;
	}

	renderSubFilter(isShowingAll) {
		if (isShowingAll) {
			return (
				<div className="news-dropdown-wrapper">
					{this.renderYears()}
					<div className="filter-dropdown-months">
						<select
							name="months"
							id="filterMonths"
							key="filterMonths"
							onChange={e => {
								this.getSelectMonth(e);
							}}
							value={this.state.selectedMonth}>
							<option key="01" value="01">
								January
							</option>
							<option key="02" value="02">
								February
							</option>
							<option key="03" value="03">
								March
							</option>
							<option key="04" value="04">
								April
							</option>
							<option key="05" value="05">
								May
							</option>
							<option key="06" value="06">
								June
							</option>
							<option key="07" value="07">
								July
							</option>
							<option key="08" value="08">
								August
							</option>
							<option key="09" value="09">
								September
							</option>
							<option key="10" value="10">
								October
							</option>
							<option key="11" value="11">
								November
							</option>
							<option key="12" value="12">
								December
							</option>
						</select>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	renderTournDates() {
		const content = this.state.tournDatesAvailable;
		return (
			<div className="filter-dropdown">
				<select
					name="tournDates"
					id="filtertournDates"
					key="filtertournDates"
					onChange={e => {
						this.getSelectedTournDay(e);
					}}
					value={this.state.selectedDay}>
					{content.map((item, index) => {
						return (
							<option key={`${item.display}-${index}`} value={item.display}>
								{item.display}
							</option>
						);
					})}
				</select>
			</div>
		);
	}

	renderYears() {
		const content = this.state.yearsAvailable;

		return (
			<div className="filter-dropdown-years">
				<select
					name="years"
					id="yearListXs"
					key="filterYears"
					onChange={e => {
						this.getSelectYear(e);
					}}
					value={this.state.selectedYear}>
					{content.map((item, index) => {
						return (
							<option key={`${item}-${index}`} value={item}>
								{item}
							</option>
						);
					})}
				</select>
			</div>
		);
	}

	getSelectMonth = e => {
		logger.log('[NewsIndex] - getSelectMonth: ');
		MeasurementUtils.dispatchMeasurementCall('SelectMonth', {
			selection: e.target.value,
		});
		this.setState({
			selectedMonth: e.target.value,
			relatedContent: null,
		});
	};

	getSelectYear = e => {
		logger.log('[NewsIndex] - getSelectYear: ');
		MeasurementUtils.dispatchMeasurementCall('SelectYear', {
			selection: e.target.value,
		});
		this.setState({
			selectedYear: e.target.value,
			relatedContent: null,
		});
	};

	getSelectedTournDay = e => {
		const value = e.target.value;
		const key = this.state.tournIndex[value].key;
		logger.log('[NewsIndex] - getSelectedTournDay value: %o ', value);
		MeasurementUtils.dispatchMeasurementCall('SelectDay', { selection: value });
		this.setState({
			selectedDay: e.target.value,
			selectedDayId: key,
			relatedContent: null,
		});
	};

	radioButtonClick(selection) {
		let checked = selection === 'All' ? true : false;
		MeasurementUtils.dispatchMeasurementCall('SelectCategory', {
			selection: selection,
		});
		this.setState({
			showAll: checked,
			relatedContent: null,
		});
		logger.log(checked);
		// console.dir(e);
		logger.log('[NewsIndex] - radio button: ');
	}

	newsTileClicked(url, index) {
		logger.log('[NewsIndex] newsTileClicked - state:%o', this.state);
		MeasurementUtils.dispatchMeasurementCall('TileClicked', {
			index,
			url,
		});
		this.setState({
			nav: url,
		});
	}

	render() {
		//logger.log('[NewsIndex] render - this:%o', this);

		let header_attributes = {
			headerType: 'generic',
			title: 'News',
			shortTitle: 'News',
			titleElem: '.landing--header',
			metaTitle: 'News',
			metaDescription: 'Stay updated with the latest news from the US Open Tennis Tournament. Receive comprehensive coverage, including player interviews, expert analysis and more.',
			metaDate: '',
			metaPlayers: '',			
			canonicalLink: 'https://www.usopen.org/en_US/news/index.html',
		};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: this.props.sponsor,

			title: 'News',
			page_header: 'News',
		};
		// let promo = this.props.promo;
		// let header_attributes = {};

		//logger.log('[NewsIndex] render - header:%o', header_attributes);

		if (this.state.nav && this.state.nav != this.props.location.pathname) {
			return <EventsRedirect push to={this.state.nav} />;
		} else if (this.state.relatedContent) {
			this.count = 0;

			return (
					<Template
						header={header_attributes}
						subHeader={subheader_attributes}
						adType={'landingHero'}
						adTypeRefresh={'landingHero'}>
						<section className="wrapper newsindex">
							<PageSubMenu mode="News" selected="News" />
							{this.filter()}
							{this.minutely()}
							{this.renderContent()}
						</section>
					</Template>
			);
		} else {
			return (
				<Template
					header={header_attributes}
					subHeader={subheader_attributes}
					adType={'landingHero'}
					adTypeRefresh={'landingHero'}>
					<section className="wrapper">
						{this.renderHero()}
						{/* <ScrollHandler hasData={(false)} filter={this.state.filters.type} /> */}
						{this.filter()}
						{this.minutely()}
						<LoadingIndicator />
					</section>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsIndex);
