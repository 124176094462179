export default {
	mount: () => (dispatch, getState, store) => {
		logger.log('[RadioPlayer] - mount');
		// return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
		//   Config => {
		//     let activeDataPath = Config.otherData.activeData.path;
		//     let activeDataRefreshRate = Config.otherData.activeData.rateSec;

		//     return fetch(activeDataPath).then(data => {
		//       logger.log("[RadioPlayer] - mount - returnedData %o", data);

		//       let newData = {
		//         ...data,
		//         activeDataRefreshRate
		//       };
		//       dispatch({
		//         type: deps.actionTypes.RADIO_LOAD,
		//         data: newData
		//       });
		//     });
		//   }
		// );
	},
};
