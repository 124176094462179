import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import isEmpty from 'lodash/isEmpty';

const mapStateToProps = (state, props) => {
	return {
		viewedContent: state['Controller']?.content?.viewed,
		contentNewExpireSec: state['Controller']['contentNewExpireSec'],
		...props.data,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	updateViewedContent: dataArray => dispatch(actions.Controller.updateViewedContent(dataArray)),
});

class NewFlag extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			newContent: false,
		};
	}

	componentDidMount() {}

	detectNewTimestamp(dataTimestamp) {
		let dateNow = moment().valueOf();
		//logger.log('[NewFlag] detectNewTimestamp - dateNow:%o date:%o inList:%o', (currDate - dataTimestamp) * 1000, this.state.contentNewExpireSec);
		if ((dateNow - dataTimestamp) / 1000 <= this.state.contentNewExpireSec) {
			return true;
		}
		return false;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[NewFlag] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[NewFlag] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		//let newContent = this.checkState();
		//logger.log('[NewFlag] componentDidUpdate - dateNow:%o date:%o inList:%o', dateNow, this.state.displayDate, inViewedList);
		// if(prevState.newContent !== newContent) {
		//     this.setState(nextState => {
		//         return {...nextState, newContent};
		//     });
		// }
	}

	checkState() {
		//determine if id should be added to viewed content list
		//need id of content
		let contentId = this.state.contentId;
		let viewedContentIdArry = this.state?.viewedContent;
		let inViewedList = viewedContentIdArry?.filter(d => d.contentId == contentId).length;
		let newTimestamp = this.detectNewTimestamp(this.state.displayDate);
		let newContent;
		if (inViewedList > 0 || !newTimestamp) {
			newContent = false;
		} else {
			newContent = true;
		}

		return newContent;
	}

	render() {
		let newContent = this.checkState();
		//logger.log('[NewFlag] render - newContent:%o', newContent);

		return <span>{newContent ? <div className="new-tag">New</div> : null}</span>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewFlag);
