import React from 'react';

const RadioButtons = props => {
	const { data = {} } = props;
	const { list = [] } = data;

	return (
		<div className="radio-group">
			{list.map((listItem, i) => {
				return (
					<div key={i} className="radio-button" onClick={() => data.callback(listItem.value)}>
						<i
							className={`icon-radio-${
								data.selected == listItem.value ? 'selected' : 'button'
							} checkmark`}
						/>
						<span className="button-label">{listItem.label}</span>
					</div>
				);
			})}
		</div>
	);
};
export default RadioButtons;
