/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
// import Chartist from 'chartist';
// import ChartistGraph from 'react-chartist';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerChart for Match Insights
 * -----------------------------------------------------------------------------
 */
class PlayerChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			render: false,
		};
		logger.log('[PlayerChart] - props:%o', this.props);

		this.donut_data = {
			series: [
				props.likelihoodWinData['likelihoodWinNum'],
				parseInt(100 - props.likelihoodWinData['likelihoodWinNum']),
			],
			label: `<div class="mi-pr-chart-center"><span class="chart-value">${
				props.likelihoodWinData['likelihoodWinNum']
			}</span>${' '}<sup>%</sup></div>`,
			// series: [53]//test data
		};
		this.donut_options = {
			donut: true,
			donutWidth: 8,
			startAngle: 210,
			total: 120,
			showLabel: false,
			resize: true,
			chartPadding: 0,
			// plugins: [
			// 	Chartist.plugins.fillDonut({
			// 		items: [
			// 			{
			// 				content: this.donut_data.label,
			// 			},
			// 		],
			// 	}),
			// ],
		};

		this.donut_data = {
			series: [
				props.likelihoodWinData['likelihoodWinNum'],
				parseInt(100 - props.likelihoodWinData['likelihoodWinNum']),
			],
			// label: '<div className="mi-pr-chart-center"><span className="num">{likelihoodWinData[\'likelihoodWinNum\']}</span>{\' \'}<span className="unit">%</span></div>'
			// series: [53]//test data
		};
	}

	componentDidMount() {
		setTimeout(
			function() {
				this.setState({ render: true });
			}.bind(this),
			500
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps) {
			let chartEl = document.querySelector('.mi-pr-chart');
			if (chartEl && op.get(this.props, 'type', null) == 'slamtracker') {
				// force redraw the chart when tabs are switched back
				window.dispatchEvent(new Event('resize'));
			}
		}
	}

	render() {
		logger.log('[PlayerChart] - render - props:%o', this.props);

		let { teamnum } = this.props;
		return (
			<div className={`mi-pr-player-chart player${teamnum}`}>
				<div className="mi-pr-chart">
					{/* {this.state.render ? (
						<ChartistGraph data={this.donut_data} options={this.donut_options} type={'Pie'} />
					) : null} */}
				</div>
			</div>
		);
	}
}

export default PlayerChart;
