/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import ReactEcp from 'react-ecp';
import NextUp from './NextUp';
import MeasurementUtils from 'appdir/lib/analytics';
import { getScreenType } from 'components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: AdTag
 *
 * To use this component, you need to create a config in config_web.json
 *
 * Config looks like this (just add a new one under mip):  Description of everything is in the constructor
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['VideoPlayer'],
		LiveVideo: state['LiveVideo'],
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.VideoPlayer.mount()),
	unmount: () => dispatch(deps.actions.VideoPlayer.unmount()),
	currentlyPlaying: (id, player) => dispatch(deps.actions.VideoPlayer.currentlyPlaying(id, player)),
	openPip: data => dispatch(deps.actions.LiveVideo.openPip(data)),
	closePip: () => dispatch(deps.actions.LiveVideo.closePip()),
	updateAudioPreference: data => dispatch(deps.actions.VideoPlayer.update(data)),
});

class VideoPlayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			nextVid: null,
			showNext: false,
		};
		this.measure_50 = false;
		this.measure_95 = false;
		this.started = false;
		this.playerConfig;
		this.secondsPlayed;
		this.playNextFn = this.playNextFn.bind(this);
		this.pipOpener = this.pipOpener.bind(this);
	}
	componentDidMount() {
		this.props.mount();
		logger.log('[VideoPlayer] componentDidMount');
	}

	componentWillUnmount() {
		if (this.player?.lastFullscreenAction !== 'fullscreen_open') {
			this.props.unmount();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props?.attributes?.id != prevProps?.attributes?.id) {
			this.started = false;
			this.setState({ nextVid: null, reset: null });
		}

		if (prevProps.isVisible === true && this.props.isVisible === false && this.player?.amp?.paused === false) {
			this.pipOpener();
		}
	}

	additionalMeasurement() {
		logger.log(`${this.name} additionalMeasurement - amp.audioTracks:%o`, this?.player?.amp);

		let playerTracks = this?.player?.amp?.audioTracks?.list?.map(track => {
			logger.log(`${this.name} additionalMeasurement - track:%o`, track);

			return track.data;
		});
		logger.log(`${this.name} additionalMeasurement - playerTracks:%o`, playerTracks);
		let captions = localStorage?.getItem('ecp') ? JSON.parse(localStorage?.getItem('ecp'))?.captions : false;
		let additionalMeasurement = {
			preferences: {
				captions,
				...(playerTracks.length > 0 && this?.props?.aiAudio && { aiAudio: this?.props?.aiAudio }),
			},
			...(playerTracks.length > 0 && { availableTracks: playerTracks }),
		};

		additionalMeasurement['preferences']['captions'] = captions;

		logger.log(`${this.name} additionalMeasurement - additionalMeasurement:%o`, additionalMeasurement);

		return additionalMeasurement;
	}

	onStart() {
		logger.log('[VideoPlayer] onStart this.props.playing:%o, this.player:%o', this.props.playing, this.player);

		if (this.props.attributes.onPlay) {
			this.props.attributes.onPlay();
		}

		this.measure_50 = false;
		this.measure_95 = false;

		this.props.playing.map(item => {
			/*logger.log(
        "[VideoPlayer] item:%o, paused:%o",
        item.player,
        item.player.amp.paused
      )*/
			if (item?.id !== this.props?.attributes?.id && item?.player?.amp?.paused === false) {
				item.player.pause();
			}

			if (!this.started && this.player && this.player.props.id == 'herovideo') {
				this.started = true;
				MeasurementUtils.dispatchMeasurementCall('videoplayer', {
					action: 'start',
					metricsData: {
						...this.props.attributes.videoData,
						origin: this?.props?.attributes?.id,
						...this.additionalMeasurement(),
					},
				});
			} else if (!this.started && this.player && this.player.props.id !== 'herovideo') {
				this.started = true;
				MeasurementUtils.dispatchMeasurementCall('videoplayer', {
					action: 'start',
					metricsData: {
						...this.props.attributes.videoData,
						origin: this?.props?.attributes?.id,
						...this.additionalMeasurement(),
					},
				});
			}
		});
	}

	onPlay() {
		logger.log('[VideoPlayer] onPlay');
		if (this.props.attributes.onPlay) {
			this.props.attributes.onPlay();
		}

		this.props.playing.map(item => {
			if (item?.id != this?.props?.attributes?.id && item?.player?.amp?.paused === false) {
				item?.player?.pause();
			}
		});

		// MeasurementUtils.dispatchMeasurementCall("videoplayer", {
		//   action:'play',
		//   metricsData: this.props.attributes.videoData
		// });
	}

	onPause() {
		logger.log('[App] onPause');
		if (this.props.attributes.onPause) {
			this.props.attributes.onPause();
		}
	}

	onComplete() {
		if (this?.props?.attributes?.onComplete) {
			this?.props?.attributes?.onComplete();
		}
		MeasurementUtils.dispatchMeasurementCall('videoplayer', {
			action: 'complete',
			metricsData: {
				...this.props.attributes.videoData,
				origin: this?.props?.attributes?.id,
				...this.additionalMeasurement(),
			},
		});

		if (this.props.attributes?.noAutoNext !== false) {
			logger.log('[VideoPlayer] showNext');
			this.setState({ showNext: true });
		}

		this.started = true;
		logger.log('[App] onComplete');
	}

	pipOpener() {
		//opening the pip window here
		let videoConfig = {
			...this.props.attributes.videoData,
			startSeconds: this.secondsPlayed,
		};
		let pipData = { playerConfig: this.playerConfig, videoConfig, id: this?.props?.attributes?.id };
		this.player.pause();
		this.props.openPip(pipData);
	}

	onUserAction(data) {
		logger.log('[VideoPlayer] onUserAction - data:%o', data);

		let actionData = {
			//...this.state.videoData,
			...this.props.attributes.videoData,
			...this.additionalMeasurement(),
		};

		// MeasurementUtils.dispatchMeasurementCall('videoplayer', {
		// 	action: data.action,
		// 	metricsData: {
		// 		...this.props.attributes.videoData,
		// 		origin: this.props.attributes.id,
		// 	},
		// });

		if (data.action == 'panel_open') {
			this.pipOpener();
			//opening the pip window here
			// let videoConfig = {
			// 	...this.props.attributes.videoData,
			// 	startSeconds: this.secondsPlayed,
			// };
			// let pipData = { playerConfig: this.playerConfig, videoConfig, id: this.props.attributes.id };
			// this.player.pause();
			// this.props.openPip(pipData);
		}

		if (data.action == 'panel_close') {
			this.props.closePip();
		}

		if (data.action == 'altAudio_open') {
			let selectedAudio = this?.player?.amp?.tracks?.data?.audioTracks?.list?.find(track => {
				return track.enabled === true;
			})?.data;

			logger.log('[VideoPlayer] onUserAction - selectedAudio:%o', selectedAudio);
			this.props.updateAudioPreference({
				aiAudio: { id: parseInt(selectedAudio.id, 10), label: selectedAudio.label },
			});
		}

		if (data.action == 'altAudio_close') {
			let selectedAudio = this?.player?.amp?.tracks?.data?.audioTracks?.list?.find(track => {
				return track.enabled === true;
			})?.data;

			logger.log('[VideoPlayer] onUserAction - selectedAudio:%o', selectedAudio);

			try {
				if (this?.props?.aiAudio?.label !== selectedAudio.label) {
					actionData.currentAudio = selectedAudio.label;
					actionData['preferences']['aiAudio'] = {
						id: parseInt(selectedAudio.id, 10),
						label: selectedAudio.label,
					};
				}
			} catch (e) {
				logger.error('[VideoPlayer] onUserAction - measurement selectAudio failed e:%o', e);
			}
			this.props.updateAudioPreference({
				aiAudio: { id: parseInt(selectedAudio.id, 10), label: selectedAudio.label },
			});
		}

		MeasurementUtils.dispatchMeasurementCall('videoplayer', {
			action: data.action,
			metricsData: {
				...actionData,
				origin: this?.props?.attributes?.id,
			},
		});
	}

	onLoadedMetadata(data) {
		logger.log(`${this.name} - onLoadedMetadata`, data);
		// why is this there?  because we need to wait for the tracks to actually download.  it is dumb!
		const audioInterval = setInterval(() => {
			if (data?.audioTracks?.length > 0 && this?.player?.amp?.appState?.playState == 'playing') {
				logger.log(`${this.name} -  onLoadedMetadata playState:%o`, this?.player?.amp?.appState?.playState);

				// data is loaded, so we can stop the interval
				clearInterval(audioInterval);

				let availableTracks = data?.audioTracks.map(track => {
					logger.log(`${this.name} -  onLoadedMetadata interval audioTracks.map track.data:%o`, track.data);
					return track.data;
				});

				logger.log(`${this.name} -  onLoadedMetadata interval availableTracks:%o`, availableTracks);

				let selectedAudio = availableTracks.find(track => {
					return track.enabled === true && track.language !== '';
				});

				let defaultAudio = { id: selectedAudio.id, label: selectedAudio.label };

				let audioTrackData = this?.props?.aiAudio;

				logger.log(
					`${this.name} -  onLoadedMetadata interval selectedAudio:%o, userPrefAudio:%o`,
					selectedAudio,
					audioTrackData
				);

				let actionData = {
					...this.props.attributes.videoData,
					...this.additionalMeasurement(),
					origin: this?.props?.attributes?.id,
				};
				if (selectedAudio) {
					if (audioTrackData?.id >= 0) {
						// let's just make sure we are comparing apples to apples, so doing a parseInt here.
						if (parseInt(audioTrackData.id, 10) !== parseInt(selectedAudio.id, 10)) {
							logger.log(
								`${this.name} -  onLoadedMetadata interval switch to audioPref:%o`,
								audioTrackData
							);

							// parseInt because switch audio is looking for the index of the track
							this?.player?.switchAudio(parseInt(audioTrackData?.id, 10));

							logger.log(`${this.name} -  onLoadedMetadata interval highlight rewind`);
							this.player.seek(0);

							actionData.preferences['aiAudio'] = this?.props?.aiAudio;
							MeasurementUtils.dispatchMeasurementCall(`videoPlayer`, {
								action: 'multipleTracks',
								metricsData: {
									...actionData,
									availableTracks: data?.audioTracks.map(tracks => {
										return tracks.data;
									}),
								},
							});
						} else {
							this.state.updateAudioPreference({ userPreferences: { aiAudio: defaultAudio } });
							actionData.preferences['aiAudio'] = defaultAudio;

							MeasurementUtils.dispatchMeasurementCall(`videoPlayer`, {
								action: 'multipleTracks',
								metricsData: {
									...actionData,
									availableTracks: data?.audioTracks.map(tracks => {
										return tracks.data;
									}),
								},
							});
						}
					} else {
						logger.log(
							`${this.name} -  onLoadedMetadata interval set a default for users audioPref:%o`,
							defaultAudio
						);
						actionData.preferences['aiAudio'] = defaultAudio;

						MeasurementUtils.dispatchMeasurementCall(`videoPlayer`, {
							action: 'multipleTracks',
							metricsData: {
								...actionData,
								availableTracks: data?.audioTracks.map(tracks => {
									return tracks.data;
								}),
							},
						});
						this.state.updateAudioPreference({ aiAudio: defaultAudio });
					}
				}
			}
		}, 100);
	}

	onFullscreenChange(isFullscreen) {
		logger.log('[App] onFullscreenChange - isFullscreen:%o', isFullscreen);
		if (this.props.onFullscreenChange) {
			this.props.onFullscreenChange(isFullscreen, this.props.data);
		}
	}

	onTimeUpdate(data) {
		// logger.log('[VideoPlayer] onTimeUpdate - data:%o', data);
		this.secondsPlayed = data.time;
		let percentage = (data.time / data.duration) * 100;
		//logger.log('[VideoPlayer] onTimeUpdate - percentage:%o', percentage);

		// if (this.props.onTimeUpdate) {
		// 	this.props.onTimeUpdate(data, this.props.data);
		// }
		if (percentage >= 95 && !this.measure_95) {
			this.measure_95 = true;
			MeasurementUtils.dispatchMeasurementCall('videoplayer', {
				action: 'percentage_90',
				metricsData: {
					...this.props.attributes.videoData,
					origin: this?.props?.attributes?.id,
					...this.additionalMeasurement(),
				},
			});
		} else if (percentage >= 50 && !this.measure_50) {
			this.measure_50 = true;
			MeasurementUtils.dispatchMeasurementCall('videoplayer', {
				action: 'percentage_50',
				metricsData: {
					...this.props.attributes.videoData,
					origin: this?.props?.attributes?.id,
					...this.additionalMeasurement(),
				},
			});
		}
	}

	onReady(amp) {
		logger.log('[VideoPlayer] onReady amp:%o', amp);
		this.player = amp;
		this.props.currentlyPlaying(this?.props?.attributes?.id, amp);
	}

	nextUpUserEnable(on) {
		logger.log(`${this.name} nextUpUserEnable - on:%o`, on);
		// this.props.attributes.toggleAutoPlay(on);
	}

	playNextFn(data) {
		logger.log(`[VideoPlayer] playNextFn data:%o`, data);
		this.setState({ nextVid: data, reset: false, showNext: false });
	}

	setPlayerConfig() {
		this.playerConfig = {};
		if (this.props.playerConfig && this.props.attributes.videoData) {
			this.playerConfig = {
				...this.props.playerConfig,
				ui: {
					...this.props.playerConfig.ui,
					toolbar: {
						buttons: {
							...this.props.playerConfig.ui.toolbar.buttons,
							right: {
								...this.props.playerConfig.ui.toolbar.buttons.right,
								panel: {
									display: this.props.attributes.showPanelIcon,
									position: 'right',
								},
							},
						},
					},
				},
				analytics: {
					...this.props.playerConfig.analytics,
					convivaData: {
						...this.props.playerConfig.analytics.convivaData,
						custom: {
							...this.props.playerConfig.analytics.convivaData.custom,
							screenType: getScreenType(),
							pageURL: this.state.Router.pathname + this.state.Router.search,
						},
					},
				},
			};
		}
	}

	render() {
		logger.log('[VideoPlayer] render this:%o', this);
		let colors = {
			highlightColor: '#447fff',
			toolbarBackground: 'rgba(73, 77, 95, .8)',
			progressBackground: '#D8D8D8',
		};

		// set the player config
		this.setPlayerConfig();

		return this?.props?.playerConfig && this?.props?.attributes?.videoData && this?.props?.attributes?.id ? (
			<span>
				<>
					<div>
						<ReactEcp
							id={this.state.nextVid?.id || this?.props?.attributes?.id}
							ampBasePath="/libs/"
							playerConfig={this.playerConfig}
							videoConfig={this.state.nextVid || this.props.attributes.videoData}
							forcePseudoFullScreen={false}
							isPseudoFullscreen={false}
							onReady={amp => this.onReady(amp)}
							onUserAction={data => this.onUserAction(data)}
							onFullscreenChange={data => this.onFullscreenChange(data)}
							onStart={() => this.onStart()}
							onPlay={() => this.onPlay()}
							onPause={() => this.onPause()}
							onComplete={() => this.onComplete()}
							onTimeUpdate={data => this.onTimeUpdate(data)}
							colors={colors}
							onLoadedMetadata={data => this.onLoadedMetadata(data)}
							// reset={this.state.reset || this.props.attributes.reset}
							reset={false}
						/>
					</div>
					{this.state.showNext ? (
						<NextUp
							vidId={this.state?.nextVid?.id || this?.props?.attributes?.id}
							// content={this.props.attributes.nextVid}
							seconds={5}
							// auto={this.props.attributes.shouldAutoplay}
							onUserEnable={on => this.nextUpUserEnable(on)}
							playNext={this.playNextFn}
						/>
					) : null}
				</>
			</span>
		) : null;
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
