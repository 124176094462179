/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'html-react-parser';

import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import {
	matchIsFutureState,
	matchIsInProgress,
	matchIsLiveState,
	matchIsCompleteState,
	matchIsLiveNotInProgress,
} from 'appdir/components/pages/SchedulePage/ScheduleUtils';
import { determinePlayerHighlightClass } from 'appdir/components/pages/SchedulePage/ScheduleUtils';
import { isDoublesMatch } from 'appdir/components/pages/Slamtracker/Utilities';

import PlayerImage from './PlayerImage';

/**
 * -----------------------------------------------------------------------------
 * Utility funcions
 * -----------------------------------------------------------------------------
 */

// for www player profile, disable the player's name link if already on the players profile
// keep the opponent's or team member (doubles) link active
export const checkSuppressProfileLink = (attributes, pid) => {
	let { suppressProfileLink } = attributes;
	let playerProfileId =
		attributes.playerProfileId !== '' || attributes.playerProfileId !== undefined ? attributes.playerProfileId : '';

	if (!suppressProfileLink && playerProfileId !== '') {
		if (playerProfileId === pid) {
			suppressProfileLink = true;
		}
	}
	return suppressProfileLink;
};

export const checkWonStatus = (attributes, team, index) => {
	let scoreData = attributes.data;
	let wonclass = scoreData.scores.setsWon[index] === parseInt(team) + 1 ? 'won' : '';

	return wonclass;
};

/**
 *
 * @param {*} flagImagePathSmall ---- flag image path from Config
 * @param {*} playerName ------------ teamData.displayNameA || teamData.displayNameB
 * @param {*} countryCode ----------- !Array.isArray(teamData.nationA) ? teamData.nationA : teamData.nationA[0] || nationB
 * @param {*} seed ------------------ teamData.seed
 * @param {*} is_tbd ---------------- bool
 * @returns Flag and Player Name
 */
export const getFlagAndPlayerName = (flagImagePathSmall, playerName, countryCode, seed, is_tbd = null) => {
	return (
		<>
			{is_tbd ? null : (
				<div className="flag">
					<img alt={countryCode} src={flagImagePathSmall.replace('<code>', countryCode)} />
				</div>
			)}
			<div className="name">{is_tbd ? 'T.B.D' : playerName}</div>
			<div className={`seed ${seed && !is_tbd ? '' : 'hide'}`}>{`[${seed}]`}</div>
		</>
	);
};

export const getServeSpeed = (data, uom) => {
	let textUnit = uom == 'metric' ? ' KMH' : ' MPH';
	let serveSpeedArray = data ? data.split(',') : [null, null];
	let serveSpeed = uom == 'metric' ? serveSpeedArray[0] : serveSpeedArray[1];
	return serveSpeed ? 'Serve Speed: ' + serveSpeed + textUnit : null;
};

export const renderPlayerImage = (which, attributes, config) => {
	let scoreData = attributes.data;
	let isDoubles = scoreData ? isDoublesMatch(scoreData) : false;
	let teamData = which == 'one' ? scoreData.team1 : scoreData.team2;

	return (
		<div className={`team-img-container team-${which}${isDoubles ? 'doubles' : ''}`}>
			<PlayerImage
				attributes={{
					'player-image': config.playerImagePath.replace('<playerid>', teamData.idA),
					style: teamData.idB ? 'xsmall' : 'small',
				}}
			/>
			{teamData.idB ? (
				<PlayerImage
					attributes={{
						'player-image': config.playerImagePath.replace('<playerid>', teamData.idB),
						style: 'xsmall',
					}}
				/>
			) : null}
		</div>
	);
};

export const renderTeam = (sumscores, props, state, which, linkCallback) => {
	// logger.log(
	// 	'[MatchBox] renderTeam - sumscores:%o, props:%o, state:%o, which:%o, linkCallback:%o',
	// 	sumscores,
	// 	props,
	// 	state,
	// 	which,
	// 	linkCallback
	// );
	if (props.attributes.isSidepanel === true) {
	}
	let scoreData = props.attributes.data;
	let isDoubles = scoreData.eventName ? isDoublesMatch(scoreData.eventName) : false;
	let boxStyle = props.attributes.style ? props.attributes.style : '';
	let schedule = props.attributes?.mode == 'schedule' ? true : false;
	let draws = props.attributes?.mode == 'draws' ? true : false;

	let teamData;

	if (which == 'one') {
		if (props.attributes?.matchType == 'upcoming') {
			teamData = scoreData.team1[0];
		} else {
			teamData = scoreData.team1;
		}
	} else if (which == 'two') {
		if (props.attributes?.matchType == 'upcoming') {
			teamData = scoreData.team2[0];
		} else {
			teamData = scoreData.team2;
		}
	}

	let scoreIndex = which == 'one' ? '0' : '1';
	let suppressProfileLink_a = checkSuppressProfileLink(props.attributes, teamData.idA);
	let suppressProfileLink_b = checkSuppressProfileLink(props.attributes, teamData.idB);

	// logger.log('[MatchBox] renderTeam - suppressProfileLink_a:%o, suppressProfileLink_b:%o', suppressProfileLink_a, suppressProfileLink_b);

	let iconClasses = '';
	let winnerClass = '';
	let latestClass = '';
	let emptySets = [];

	let is_tbd = props.attributes?.matchType == 'upcoming' && teamData.length > 1 ? true : false;

	if (props.attributes.isSidepanel === true) {
		// logger.log('[MatchBox] renderTeam - matchIsFutureState', matchIsFutureState(scoreData));
	}

	scoreData.team1.won = scoreData.team1.won && scoreData.team1.won !== 'false' ? true : false;
	scoreData.team2.won = scoreData.team2.won && scoreData.team2.won !== 'false' ? true : false;

	if (matchIsFutureState(scoreData)) {
		iconClasses = 'crticon';
	} else {
		if (which == 'one') {
			iconClasses = classNames(
				'crticon',
				(scoreData.server == 'A' || scoreData.server == 'B') &&
					!scoreData.team1.won &&
					!scoreData.team2.won &&
					matchIsLiveState(scoreData) &&
					!schedule &&
					!draws
					? 'currentServer'
					: '',
				scoreData.team1.won ? 'winner' : ''
			);

			if (matchIsCompleteState(scoreData)) {
				winnerClass = classNames(scoreData.team1.won ? 'winner' : 'loser');
			}
			latestClass = state.lastScore_t1 && matchIsLiveState(scoreData) ? ' latest' : '';
		} else {
			iconClasses = classNames(
				'crticon',
				(scoreData.server == 'C' || scoreData.server == 'D') &&
					!scoreData.team1.won &&
					!scoreData.team2.won &&
					matchIsLiveState(scoreData) &&
					!schedule &&
					!draws
					? 'currentServer'
					: '',
				scoreData.team2.won ? 'winner' : ''
			);

			if (matchIsCompleteState(scoreData)) {
				winnerClass = classNames(scoreData.team2.won ? 'winner' : 'loser');
			}
			latestClass = state.lastScore_t2 && matchIsLiveState(scoreData) ? ' latest' : '';
		}
	}

	for (var i = 0; i < 5 - scoreData?.scores?.sets?.length && scoreData?.scores?.sets?.length < 5; i++) {
		emptySets[i] = 5 - i;
	}

	//stats level only set after match complete, so won't allow active pre/during match
	let active = scoreData.team1.idA && scoreData.team2.idA;
	//let slamtrackerActive = (active && scoreData.statsLevel !== null && scoreData.statsLevel !== 'N');
	let slamtrackerActive = active && scoreData.courtId;

	emptySets.reverse();

	return (
		<div
			className={`team-info team-${which} ${isDoubles ? 'doubles' : 'singles'}
            ${winnerClass}
            ${determinePlayerHighlightClass(
				teamData.idA,
				!window.webview ? props?.favorites : props?.appFavs,
				props.attributes?.searchedPlayer,
				props?.appShowFavs
			)}
            ${determinePlayerHighlightClass(
				teamData.idB,
				!window.webview ? props?.favorites : props?.appFavs,
				props.attributes?.searchedPlayer,
				props?.appShowFavs
			)}
        `}
			// onMouseEnter={
			//     props.attributes.mode == 'draws' ? () => props.onHovered(teamData.idA) : undefined
			// }
			// onMouseLeave={props.attributes.mode == 'draws' ? () => props.onHovered('') : undefined}
		>
			{/* {window.webview ? (
                <div className="team-name">
                    <div
                        onClick={
                            !suppressProfileLink && !is_tbd && teamData.idA
                                ? e => {
                                    linkCallback(e, `/en_US/players/overview/${teamData.idA}.html`);
                                  }
                                : null
                        }
                        className={`member-one`} >
                        {is_tbd ? null : (
                            <div className="flag">
                                <img
                                    alt={teamData.nationA}
                                    src={state.MatchBoxConfig.flagImagePathSmall.replace(
                                        '<code>',
                                        !Array.isArray(teamData.nationA) ? teamData.nationA : teamData.nationA[0]
                                    )}
                                />
                            </div>
                        )}
                        <div className="name">{is_tbd ? 'T.B.D' : teamData.displayNameA}</div>
                        <div className={`seed ${teamData.seed && !is_tbd ? '' : 'hide'}`}>{`[${teamData.seed}]`}</div>
                    </div>
                    <div
                        onClick={
                            !suppressProfileLink && !is_tbd && teamData.idB
                                ? e => {
                                    linkCallback(e, `/en_US/players/overview/${teamData.idB}.html`);
                                  }
                                : null
                        }
                        className={`member-two`}>
                        {is_tbd ? null : (
                            <div className="flag">
                                <img
                                    alt={teamData.nationB}
                                    src={state.MatchBoxConfig.flagImagePathSmall.replace(
                                        '<code>',
                                        !Array.isArray(teamData.nationB) ? teamData.nationB : teamData.nationB[0]
                                    )}
                                />
                            </div>
                        )}
                        <div className="name">{is_tbd ? 'T.B.D' : teamData.displayNameB}</div>
                        <div className={`seed ${teamData.seed && !is_tbd ? '' : 'hide'}`}>{`[${teamData.seed}]`}</div>
                    </div>
                </div>
            ) : ( */}
			<div className="team-name">
				<div
					onClick={
						!suppressProfileLink_a && !is_tbd && teamData.idA
							? e => {
									linkCallback(e, `/en_US/players/overview/${teamData.idA}.html`);
							  }
							: null
					}
					className={`member-one ${
						!window.webview &&
						state?.favorites?.show &&
						state?.favorites?.players.indexOf(teamData.idA) !== -1
							? 'favorite'
							: ''
					}`}>
					{getFlagAndPlayerName(
							state.MatchBoxConfig.flagImagePathSmall,
							teamData.displayNameA,
							!Array.isArray(teamData.nationA) ? teamData.nationA : teamData.nationA[0],
							teamData.seed,
							is_tbd
						)}
				</div>
				<div
					onClick={
						!suppressProfileLink_b && !is_tbd && teamData.idB
							? e => {
									linkCallback(e, `/en_US/players/overview/${teamData.idB}.html`);
							  }
							: null
					}
					className={`member-two ${
						!window.webview &&
						state?.favorites?.show &&
						state?.favorites?.players.indexOf(teamData.idB) !== -1
							? 'favorite'
							: ''
					}`}>
					{getFlagAndPlayerName(
						state.MatchBoxConfig.flagImagePathSmall,
						teamData.displayNameB,
						!Array.isArray(teamData.nationB) ? teamData.nationB : teamData.nationB[0],
						teamData.seed,
						is_tbd
					)}
				</div>
			</div>
			{/* )} */}
			{boxStyle != 'upcoming' ? (
				<div className="match-results">
					<div className="results-wrapper">
						<div className={iconClasses}>
							<i className="icon-check" />
							<i className="icon-ballSolid" />
						</div>
						{sumscores === true ? null : schedule === true || draws === true ? (
							<div className="pts"></div>
						) : (
							<div className={`pts${latestClass}`}>
								{scoreData.scores.gameScore[parseInt(scoreIndex)]}
							</div>
						)}
						{scoreData.scores
							? scoreData.scores.sets.map((setData, i) => {
									let setno = parseInt(i, 10) + 1;
									let wonclass = checkWonStatus(props.attributes, scoreIndex, setno);

									return (
										<div key={i} className={`set set${setno} ${wonclass}`}>
											<span className="scores">
												{Array.isArray(setData)
													? setData[parseInt(scoreIndex)].scoreDisplay
														? setData[parseInt(scoreIndex)].scoreDisplay
														: ''
													: setData.team1.scoreDisplay !== 'null'
													? setData.team1.scoreDisplay
													: ''}
												<span className="tiebreak">
													{Array.isArray(setData)
														? setData[parseInt(scoreIndex)].tiebreakDisplay
															? setData[parseInt(scoreIndex)].tiebreakDisplay
															: ''
														: setData.team1.tiebreakDisplay !== 'null'
														? setData.team1.tiebreakDisplay
														: ''}
												</span>
											</span>
										</div>
									);
							  })
							: null}
						{emptySets.map((set, i) => {
							return (
								<div key={i} className={`set set${set}`}>
									<span className="scores">
										<span className="tiebreak" />
									</span>
								</div>
							);
						})}
					</div>
				</div>
			) : null}
		</div>
	);
};
