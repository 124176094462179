/**
 HAVEYOURSAY Initial State
*/

export default {
	voted: {},
	// 7776000000 = 90 days in milliseconds
	persist: {
		voted: 7776000000,
	},
};
