import deps from "dependencies";

export default {
  mount: data => dispatch => {
    return dispatch({
      type: deps.actionTypes.PLAYERSEED_MOUNT,
      data
    });
  }
};
