import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	//logger.log("[CommonData] reducer - action: %o", action);

	switch (action.type) {
		case deps.actionTypes.COMMONDATA_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[CommonData] deps.actionTypes.COMMONDATA_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.COMMONDATA_UPDATE:
			// logger.log('[CommonData] reducer.COMMONDATA_UPDATE - action:%o:', action);

			if (action.key) {
				newState = {
					...state,
					[action.configId]: {
						...state[action.configId],
						[action.key]: {
							...state[action.configId][action.key],
							...action.data,
							status: 'loaded',
						},
					},
				};
				if (newState[action.configId][action.key].hasOwnProperty('error')) {
					delete newState[action.configId].error;
				}
			} else {
				if (action.configId == 'upcomingMatches') {
					let matchIdData = [];
					if (action.data.result.matches.length > 0) {
						Object.keys(action.data.result.matches).map((key, i) => {
							matchIdData.push(action.data.result.matches[key].match_id);
						});
					}

					newState = {
						...state,
						[action.configId]: {
							...state[action.configId],
							...action.data,
							status: 'loaded',
							upcomingMatchIds: matchIdData,
						},
					};
				} else {
					newState = {
						...state,
						[action.configId]: {
							...state[action.configId],
							...action.data,
							status: 'loaded',
						},
					};
				}
				if (newState[action.configId].hasOwnProperty('error')) {
					delete newState[action.configId].error;
				}
			}

			logger.log('[CommonData] reducer.COMMONDATA_UPDATE - newState:%o:', newState);
			return newState;

		case deps.actionTypes.COMMONDATA_LOADING:
			// logger.log('[CommonData] reducer.COMMONDATA_LOADING - action:%o', action);
			if (action.key) {
				newState = {
					...state,
					[action.configId]: {
						...state[action.configId],
						[action.key]: {
							...state[action.configId][action.key],
							...action.data,
							status: 'loading',
						},
					},
				};
				if (newState[action.configId][action.key].hasOwnProperty('error')) {
					delete newState[action.configId].error;
				}
			} else {
				newState = {
					...state,
					[action.configId]: {
						...state[action.configId],
						...action.data,
						status: 'loading',
					},
				};
				if (newState[action.configId].hasOwnProperty('error')) {
					delete newState[action.configId].error;
				}
			}
			logger.log('[CommonData] reducer.COMMONDATA_LOADING - newState:%o', newState);

			return newState;

		case deps.actionTypes.COMMONDATA_ERROR:
			// logger.log('[CommonData] reducer.COMMONDATA_ERROR - action:%o', action);

			if (action.key) {
				newState = {
					...state,
					[action.configId]: {
						...state[action.configId],
						[action.key]: {
							...state[action.configId][action.key],
							...action.data,
							status: 'error',
							error: action.error,
						},
					},
				};
				if (newState[action.configId][action.key].hasOwnProperty('error')) {
					delete newState[action.configId].error;
				}
			} else {
				newState = {
					...state,
					[action.configId]: {
						...state[action.configId],
						...action.data,
						status: 'error',
						error: action.error,
					},
				};
				if (newState[action.configId].hasOwnProperty('error')) {
					delete newState[action.configId].error;
				}
			}

			logger.log('[CommonData] reducer.COMMONDATA_ERROR, newState:%o', newState);

			return newState;

		case deps.actionTypes.COMMONDATA_EXPIRED:
			// logger.log('[CommonData] reducer.COMMONDATA_EXPIRED, action:%o', action);

			if (action.configId) {
				if (action.key) {
					newState = {
						...state,
						[action.configId]: {
							...state[action.configId],
							[action.key]: {
								status: 'expired',
								refreshSec: action.refreshSec,
							},
						},
					};
					if (newState[action.configId][action.key].hasOwnProperty('error')) {
						delete newState[action.configId].error;
					}
				} else {
					newState = {
						...state,
						[action.configId]: {
							status: 'expired',
							refreshSec: action.refreshSec,
						},
					};
					if (newState[action.configId].hasOwnProperty('error')) {
						delete newState[action.configId].error;
					}
				}
			} else {
				newState = {
					...state,
				};
			}
			logger.log('[CommonData] reducer.COMMONDATA_EXPIRED, newState:%o', newState);

			return newState;
		default:
			return state;
	}
};
