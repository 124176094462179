import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	//logger.log('[DrawAnalysisHelp] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.DRAWANALYSISHELP_MOUNT:
			// logger.log('[DrawAnalysisHelp] reducers - action:%o',action);

			newState = {
				...state,
				status: 'loaded',
				...action.data,
			};
			// logger.log('[DrawAnalysisHelp] reducers DRAWANALYSISHELP_MOUNT - newState:%o',newState);
			return newState;
			break;
		case deps.actionTypes.DRAWANALYSISHELP_UPDATE:
			logger.log('[DrawAnalysisHelp] reducers - DRAWANALYSISHELP_UPDATE action:%o', action);
			newState = {
				...state,
				...action.data,
			};
			logger.log('[DrawAnalysisHelp] deps.actionTypes.DRAWANALYSISHELP_UPDATE - newState:%o', newState);
			return newState;
			break;
		case deps.actionTypes.DRAWANALYSISHELP_UNMOUNT:
			logger.log('[DrawAnalysisHelp] reducers - DRAWANALYSISHELP_UNMOUNT action:%o', action);
			newState = {
				...state,
				...action.data,
				status: null,
				defaultTop: 0,
			};
			logger.log('[DrawAnalysisHelp] deps.actionTypes.DRAWANALYSISHELP_UNMOUNT - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};
