import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[SchedulePage] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				status: 'mount',
				basePicPath: Config.otherData.basePicPath,
				schedulePath: Config.scoringData.practice,
				flagImagePathSmall: Config.otherData.flagImagePathSmall,
			};
			// logger.log('[PracticeSchedulePage] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.PRACTICESCHEDULE_MOUNT, data: data });
		});
	},
};
