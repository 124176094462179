import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import isEqual from 'lodash/isEqual';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';

import { values } from 'appdir/main';
import {
	generateStatusText,
	getSinglesMatch,
	isWinnerStatus,
} from 'appdir/components/pages/PowerIndexLeaderboard/Util';
import { getNumberWithOrdinal } from 'appdir/components/general/Util';
import {
	getSinglesEventCodeByPlayerId,
	getSelectedMatchFromDrawsPath,
	getSelectedMatchFromDrawsPathByRoundNum,
	getIsLostFromDrawsPath,
	getFinalRoundStatusFromDrawsPath,
} from 'appdir/components/pages/DrawsPage/DrawsUtils';
import { doMeasurement } from 'appdir/components/general/Util';

import DrawAnalysisBar from 'appdir/components/common-ui/DrawAnalysis/DrawAnalysisBar';
import DrawAnalysisHelp from 'appdir/components/common-ui/DrawAnalysisHelp';
import BracketViewButton from 'appdir/components/common-ui/DrawAnalysis/BracketViewButton';
import EventsButton from 'appdir/components/common-ui/EventsButton';

/**
 * Return Selected Player's Draw Analyisis
 * Requires drawAnalysis data and player status data
 *
 * Placed on the Draws Bracket view and the Power Index page
 * @param {*} props.attributes
 * @returns
 */
const DrawAnalysis = props => {
	logger.log('[DrawAnalysis] props:%o', props);

	/** data */
	const drawEventsData = useSelector(state => state['Tournament']?.data?.drawEvents);
	const CommonData = useSelector(state => state.CommonData, isEqual);
	const SharedDataConfig = useSelector(state => state.Config.sharedDataConfig);
	let drawAnalysisRedux = useSelector(state => state['DrawAnalysis']);
	let drawAnalysisHelpRedux = useSelector(state => state['DrawAnalysisHelp']);

	// const pathToTheFinal = useSelector(state => state['PathToTheFinal']);
	const Router = useSelector(state => state['Router']);
	// const useBeginData = useSelector(state => state['Config']?.feature?.drawAnalysisBegin);

	let attributes = props?.attributes;
	let playerDrawAnalysisData = attributes?.playerDrawAnalysisData;
	let { playerStatusData = {} } = attributes;
	let drawPathData = attributes?.drawPathData;
	let playerId = playerDrawAnalysisData?.playerId;
	let eventId = playerId ? getSinglesEventCodeByPlayerId(playerId) : null;
	let page = Router?.pathname?.includes('/powerindex/') ? 'powerindex' : 'draws';

	let drawsURLName = eventId == 'MS' ? 'mens-singles' : eventId == 'WS' ? 'womens-singles' : 'womens-singles';
	let bracketViewLink = `/en_US/draws/${drawsURLName}.html?event=${eventId}&playerId=${playerId}&view=bracket`;

	let aiDrawData = attributes?.aiDrawData;

	let aiDrawRoundName = aiDrawData?.aiDrawRoundName; // from Draws
	let aiDrawRoundNum = aiDrawData?.aiDrawRoundNum; // from Draws

	let aiDrawFinalRoundStatus = aiDrawData?.aiDrawFinalRoundStatus;

	let isWinner, isFinalCompleted, isUnscheduledFinals;
	let isFinalTeam1Scheduled = aiDrawFinalRoundStatus?.isTeam1Scheduled;
	let isFinalTeam2Scheduled = aiDrawFinalRoundStatus?.isTeam2Scheduled;
	let isScheduledMatch =
		aiDrawData?.aiDrawSelectedMatch?.[0]?.length > 0
			? aiDrawData.aiDrawSelectedMatch[0][0]?.team1?.idA == aiDrawData?.aiDrawPid ||
			  aiDrawData.aiDrawSelectedMatch[0][0]?.team2?.idA == aiDrawData?.aiDrawPid
			: false;
	let isEventExist = drawEventsData?.data?.filter(event => event?.id == eventId)?.[0];

	let winnerTitle = `${values.tournamentYear} Winner`;

	/** if Final is completed, don't show the defaut text box on Full View
	 *   there will be a winner box displayed instead
	 */
	let showDefaultText = attributes?.type == 'bracket' && !aiDrawData?.aiDrawBg;

	/** on the bracket page, if state.drawAnalysisExpand is false, hide the bar and content */
	let hideContent = page == 'draws' && !aiDrawData?.toggleContent ? true : false;

	const dispatch = useDispatch();

	const checkExpired = key => {
		return dispatch(deps.actions.CommonData.checkExpired(key));
	};

	useEffect(() => {
		if (SharedDataConfig) {
			checkExpired(SharedDataConfig['innovationContent']).then(resp => {
				if (resp.status == 'expired') {
					// logger.log('[DrawAnalysis] CommonData InnovationContent - expired');
					dispatch(deps.actions.CommonData.update(SharedDataConfig['innovationContent']));
				}
			});
		}
	}, [SharedDataConfig, CommonData['innovationContent']]);

	useEffect(() => {
		// logger.log('[DrawAnalysis] drawEventsData');
		if (isEmpty(drawEventsData)) {
			dispatch(deps.actions.Tournament.getDrawsEvents());
		}

		/** set Draw Analysis init redux */
		if (!drawAnalysisRedux?.status) {
			dispatch(deps.actions.DrawAnalysis.mount());
		}
	}, []);

	/** json content template feed */
	const drawAnalysisContent = CommonData?.innovationContent?.result?.aiDrawAnalysisBox;

	logger.log(
		'[DrawAnalysis] drawAnalysisRedux:%o, drawAnalysisHelpRedux:%o',
		drawAnalysisRedux,
		drawAnalysisHelpRedux
	);

	/** Power Index ----- requires playerStatusData
	 *  Draws ----------- requires drawpathData
	 *  Draws/bracket -- drawPathData is optional. if no data, display default text
	 */
	if (attributes?.playerStatusData || attributes?.drawPathData || showDefaultText) {
		const displayMatchDetails = () => {
			try {
				return getSinglesMatch(playerStatusData);
			} catch (e) {
				return null;
			}
		};

		/** get the latest single match details based on playerStatus  */
		let matchStatusDetails = displayMatchDetails();

		const hasStatus = () => {
			try {
				return generateStatusText(matchStatusDetails);
			} catch (e) {
				return null;
			}
		};

		const statusText = hasStatus();

		// logger.log('[DrawAnalysis] matchDetails displayMatchDetails():%o', displayMatchDetails());

		/**
		 *
		 * @param {String} round
		 *  Convert LS or MS event's round number to round name
		 */
		const convertRoundNumber = round => {
			if (round && !isNaN(parseInt(round))) {
				switch (parseInt(round)) {
					case 1:
						return 'First Round';
					case 2:
						return 'Second Round';
					case 3:
						return 'Third Round';
					case 4:
						return 'Fourth Round';
					case 5:
						return 'Quarterfinal';
					case 6:
						return 'Semifinal';
					case 7:
						return 'Final';
					default:
						break;
				}
			}
		};

		/** set content vars and store necessary data */
		const setContentVars = () => {
			try {
				let content = '';
				let round, playerOpponent, playerName, isLost;

				/**
				 *  Power Index -------- passes playeStatus Data,
				 *  Draws Full View ---- passes drawPathData
				 *  Path to the Final -- passes drawPathData
				 *
				 *  get only necessary data and store it in var
				 */
				if (statusText) {
					round = statusText.round;
					playerOpponent = statusText.playerOpponent;
					isLost =
						statusText.matchStatus?.toLowerCase() == 'out' ||
						statusText.matchStatus?.toLowerCase() == 'lost';
					playerName = playerStatusData?.playerTvName;
					isWinner = isWinnerStatus(matchStatusDetails, playerId);
				} else if (props?.attributes?.drawPathData?.status == 'loaded') {
					let playerData = drawPathData?.player;

					let selectedMatchIdMatch = getSelectedMatchFromDrawsPath(
						drawPathData,
						props.attributes?.selectedMatchId
					);
					let lostStatus = getIsLostFromDrawsPath(drawPathData);
					isLost = lostStatus?.isLost;

					let finalOpponentName;

					/** check final round status based on drawPathData just for MS and LS
					 *  final status is needed to display Final column, check this
					 *  even though Config.enabled.drawAnalysis is false
					 */
					if (eventId == 'MS' || eventId == 'WS') {
						let finalStatus = getFinalRoundStatusFromDrawsPath(drawPathData);
						isWinner = finalStatus?.isWinner;
						isFinalCompleted = finalStatus?.isCompleted;
						finalOpponentName = finalStatus?.finalOpponentName;

						/** need to check if the final is scheduled from draws data for Full View
						 *  to display Power Index CTA or Path to Final
						 */
						isUnscheduledFinals =
							parseInt(aiDrawRoundNum) == 7 && (!isFinalTeam1Scheduled || !isFinalTeam2Scheduled);

						/**
						 *  Lost player
						 *  if player is out, get the opponentName and the round name
						 *  that the player lost against to display as a overall message
						 *
						 *  Winner
						 *  always display win against opponent name for the winner in round by round click
						 *
						 *  Round by Round still in the tournament
						 *  display the first Opponent name in the selected match's drawsPath
						 */
						round = isLost ? convertRoundNumber(lostStatus?.lostRoundNum) : aiDrawRoundName;
						isLost = isLost;
						playerOpponent = isLost
							? lostStatus?.lostAgainst
							: isWinner
							? finalOpponentName
							: selectedMatchIdMatch?.opponents?.[0]?.nameA;
						playerName = playerData?.displayNameA;
					}
				}

				let pronoun,
					name,
					roundDrawLabel,
					roundDrawRank,
					roundPlayerOpponent = '';

				if (playerDrawAnalysisData) {
					const { displayName, drawRank, drawLabel } = playerDrawAnalysisData; // this is overall draw analysis data - used for Round 1
					pronoun = eventId == 'MS' ? 'He' : 'She';
					name = displayName ? displayName : playerName;

					let matchedMatch = getSelectedMatchFromDrawsPathByRoundNum(drawPathData, aiDrawRoundNum);
					if (matchedMatch) {
						roundDrawLabel = matchedMatch?.label ? matchedMatch.label : '';
						roundDrawRank = matchedMatch?.drawScore ? matchedMatch.drawScore : '';
						roundPlayerOpponent = matchedMatch?.opponents?.[0]?.nameA
							? matchedMatch.opponents[0].nameA
							: '';
					}
				}

				let firstDrawLabel = playerDrawAnalysisData?.beginDrawLabel
					? playerDrawAnalysisData.beginDrawLabel
					: '';

				/** if the first round's draw label is neutral, in the Power Index second sentence,
				 *  we don't want to show Neutral. Change it to favourable.
				 */
				if (firstDrawLabel == 'neutral') {
					firstDrawLabel = 'favorable';
				}

				return {
					name,
					round,
					isLost,
					pronoun,
					roundDrawLabel,
					roundDrawRank,
					playerOpponent,
					roundPlayerOpponent,
					displayRank: playerDrawAnalysisData?.displayRank,
					overallDrawLabel: playerDrawAnalysisData?.drawLabel,
					firstDrawRank: playerDrawAnalysisData?.beginDrawRank,
					firstDrawLabel,
				};
			} catch (e) {
				logger.error('[DrawAnalysis] setContentVars error:%o', e);
				return null;
			}
		};

		const contentVars = setContentVars();

		const {
			round,
			// matchStatus,
			isLost,
			playerOpponent,
			pronoun,
			name,
			overallDrawLabel,
			roundDrawLabel,
			roundDrawRank,
			roundPlayerOpponent,
			firstDrawRank,
			firstDrawLabel,
			displayRank,
		} = contentVars;

		// const isLostPlayer = (matchStatus?.toLowerCase() == 'out' || matchStatus?.toLowerCase() == 'lost');

		// logger.log('[DrawAnalysis] contentVars:%o', contentVars);

		/** on the bracket view, display a button to go to Power Index page  */
		let showPowerIndexButton = page == 'draws' && !attributes?.hideButton && isUnscheduledFinals;

		/**
		 *
		 * @returns Power Index draw analysis content
		 *
		 *  display second sentence if overall draw rank data are used
		 */
		const getPowerIndexDrawAnalysisContent = newFirstDrawRank => {
			let content = '';
			if (overallDrawLabel == 'neutral') {
				content = drawAnalysisContent?.playerSelectedNeutralPowerIndex?.text
					?.replace('<playerName>', name)
					?.replace('<drawLabel>', overallDrawLabel);
			} else {
				content = drawAnalysisContent?.playerSelectedPowerIndex?.text
					?.replace('<playerName>', name)
					?.replace('<drawRank>', displayRank ? getNumberWithOrdinal(displayRank) : '')
					?.replace('<drawLabel>', overallDrawLabel);
			}

			/**
			 *  if overall data are used, show the second sentence
			 *
			 *  no longer need to check useBeginData - power index feed
			 *  is taking care of the value.
			 *  Just in case, commenting out the check for now
			 */
			// if(!useBeginData) {
			content += drawAnalysisContent?.playerSelectePowerIndex2ndSentence?.text
				?.replace('<S/he>', pronoun)
				?.replace('<firstRoundDrawRank>', newFirstDrawRank ? getNumberWithOrdinal(newFirstDrawRank) : '')
				?.replace('<firstRoundDrawLabel>', firstDrawLabel);

			// }

			return content;
		};

		/** get static content from feed  (innovation_static_content.json) and replace key values with data */
		const generateAnalysisContent = () => {
			let content = '';

			if (attributes?.type == 'bracket' && !aiDrawData?.aiDrawBg) {
				/** only for Full View - display default text when no player is selected */
				content = drawAnalysisContent?.onboarding?.text;

				return content;
			} else if (playerDrawAnalysisData) {
				let newFirstDrawRank = firstDrawRank;

				/** reverse the number only for firstDrawRank if difficult so if the draw size is 128,
				 *   and draw rank is 128, it will be the most difficult draw
				 *
				 *   displayRank is already calculated properly - no need to reverse
				 */
				if (firstDrawLabel == 'difficult' && newFirstDrawRank) {
					newFirstDrawRank = playerDrawAnalysisData?.drawTotal - firstDrawRank + 1;
				}

				/** for Power Index, there are three versions to display
				 *  Out and with draw rank text, and Winner
				 *
				 *  Out message is for all views - Power Index, Full View Draws, Draws
				 */
				if (isLost) {
					content = drawAnalysisContent?.lost?.text
						?.replace('<playerName>', name)
						?.replace('<round>', round)
						?.replace('<opponentName>', playerOpponent);
				} else if (
					isUnscheduledFinals ||
					(isFinalCompleted && isWinner) ||
					(page == 'powerindex' && isWinner)
				) {
					/** for Full View Draws Final Round - winner or unscheduled final
					 *  always display the final opponent who win against when round by round
					 *  is clicked
					 */
					if (isWinner) {
						content = drawAnalysisContent?.winner?.text
							?.replace('<playerName>', name)
							?.replace('<year>', values.tournamentYear)
							?.replace('<opponentName>', playerOpponent);
					} else {
						content = drawAnalysisContent?.finals?.text;
					}
				} else if (
					/** Power Index, for normal Draws, or Full View Draws Round 1
					 *  and Full View Draws for completed matches
					 */
					page == 'powerindex' ||
					parseInt(aiDrawRoundNum) == 1 ||
					attributes?.view !== 'aidraw' ||
					(attributes?.view == 'aidraw' && isScheduledMatch)
				) {
					/** draw rank text */
					if (overallDrawLabel == 'neutral') {
						page == 'powerindex'
							? (content = getPowerIndexDrawAnalysisContent(newFirstDrawRank))
							: (content = drawAnalysisContent?.playerSelectedNeutral?.text
									?.replace('<playerName>', name)
									?.replace('<drawRank>', displayRank ? getNumberWithOrdinal(displayRank) : '')
									?.replace('<drawLabel>', overallDrawLabel));
					} else {
						page == 'powerindex'
							? (content = getPowerIndexDrawAnalysisContent(newFirstDrawRank))
							: (content = drawAnalysisContent?.playerSelected?.text
									?.replace(
										'<currentRound>',
										convertRoundNumber(playerDrawAnalysisData?.currentRound)
									)
									?.replace('<playerName>', name)
									?.replace('<drawRank>', displayRank ? getNumberWithOrdinal(displayRank) : '')
									?.replace('<drawLabel>', overallDrawLabel));
					}
				} else if (parseInt(aiDrawRoundNum) > 1) {
					/** Full View Draws Round by Round content for over round 2 - future matches */
					content = drawAnalysisContent?.roundSelected?.text
						?.replace('<playerName>', name)
						?.replace('<drawLabel>', roundDrawLabel)
						?.replace('<round>', aiDrawRoundName)
						?.replace('<S/he>', pronoun)
						?.replace('<opponentName>', roundPlayerOpponent);
				}

				return ReactHtmlParser(content);
			} else if (!props.attributes?.aiDrawData?.aidrawBg) {
				/** only for Full View - display default text when no player is selected */
				content = drawAnalysisContent?.onboarding?.text;

				return content;
			}
		};

		const getTitle = () => {
			if (isUnscheduledFinals || (isFinalCompleted && isWinner) || (page == 'powerindex' && isWinner)) {
				if (isWinner) {
					return (
						<h4>
							{/* {values.aiDrawTitle} */}
							<span className="winner-title">{winnerTitle}</span>
						</h4>
					);
				} else {
					return null;
				}
			} else if (parseInt(aiDrawRoundNum) > 1 && !isScheduledMatch) {
				/** round by round Full View Draws */
				return (
					<>
						<h4>{drawAnalysisContent?.roundSelected?.title?.replace('<round>', aiDrawRoundName)}</h4>
						<div className={`draw-label ${roundDrawLabel}`}>{roundDrawLabel}</div>
					</>
				);
			} else if (!isLost) {
				/** if the player is lost, don't show the Draw Analysis label */
				return (
					<>
						<h4>{values.aiDrawTitle} </h4>
						<div className={`draw-label ${playerDrawAnalysisData?.drawLabel}`}>
							{playerDrawAnalysisData?.drawLabel}
						</div>
					</>
				);
			} else {
				return null;
			}
		};

		/** show/hide help content overlay
		 *  add no scroll class if it doesn't exist on open
		 *  remove no scroll class if it's opened on the Power Index page
		 *  don't remove the class on Path to the Finals screen (draws) as
		 *  it's on top of the overlay that also needs no scroll class
		 */
		const toggleHelp = val => {
			// let pathToFinalPlayerImageContainerEl = document?.querySelector('.player-wrapper .col');

			if (val == 'open') {
				/** fade-background element is only available in the Path to the Final component
				 *  When Help overlay is displaned from overlay Path to the Final,
				 *  need to be creative to move z-index and opacity on some elements
				 *  to make various fixed posiwioned elements work
				 */
				// 	document?.querySelector('.fade-background')?.classList.add('show');
				// 	document?.querySelector('.draws-path-wrapper')?.classList.add('lower-zindex');

				// 	if (pathToFinalPlayerImageContainerEl) {
				// 		pathToFinalPlayerImageContainerEl?.classList.add('lower-opacity');
				// 		document?.querySelector('.player-info')?.classList?.add('lower-opacity');
				// 	}

				if (!document.body.classList.contains('modal-on-noscroll')) {
					document.body.classList.add('modal-on-noscroll');
				}
			} else if (val == 'close') {
				// 	/** make usre to undo added class where necessary */
				// 	document?.querySelector('.fade-background')?.classList.remove('show');
				// 	document?.querySelector('.draws-path-wrapper')?.classList.remove('lower-zindex');

				// 	if (pathToFinalPlayerImageContainerEl) {
				// 		pathToFinalPlayerImageContainerEl?.classList.remove('lower-opacity');
				// 		document?.querySelector('.player-info')?.classList?.remove('lower-opacity');
				// 	}

				// 	/** need to check if the view is aidraw, this is different from page == "draw"
				// 	 *  normal draw don't want to remove the class since it's on top of Path to the Final overlay
				// 	 */
				// 	if ((page == 'powerindex' || attributes?.view == 'aidraw') && !pathToTheFinal?.display) {
				// 		document.body.classList.remove('modal-on-noscroll');
				// 		document?.querySelector('.footer-global')?.classList.add('hide');
				// 	}
				document.body.classList.remove('modal-on-noscroll');
			}

			let data = {
				help: val == 'open' ? true : false,
			};

			dispatch(deps.actions.DrawAnalysisHelp.update(data));

			let measureArgs = [val]; // needs in an array and string, not obj
			let contextData = [];
			if (window.webview) {
			}
			doMeasurement(attributes.metricsPage, 'Draw Analysis Helper', measureArgs, contextData);

			// if (pathToTheFinal?.defaultTop) {
			// 	window.scrollBy(0, pathToTheFinal.defaultTop);
			// }
		};

		const getSentenceClass = () => {
			let classes = '';

			if (isLost || showPowerIndexButton) {
				classes += 'extra-space ';
			}

			classes += hideContent ? 'hide ' : '';

			return classes;
		};

		let wrapperClass = 'draw-analysis-inner-wrapper ';
		wrapperClass += showDefaultText ? 'intro ' : '';

		let passedClass = attributes?.style ? attributes.style : '';

		return (
			<div className={`draw-analysis-wrapper ${passedClass}`}>
				{isWinner && attributes?.type !== 'bracket' && <h4 className="winner-title">{winnerTitle}</h4>}

				<div className={wrapperClass}>
					{/** hide the middle column when:
					 *    1. default text is showing
					 *    2. Lost players
					 *    3. Unscheduled final match box is clicked
					 */
					!showDefaultText && !isLost && !showPowerIndexButton && (
						<div className="silo">
							{getTitle()}
							{/**
							 *  don't show help icon when Finals static
							 *  content is displayed since there is no bar to explain
							 *
							 *  don't show for lost players
							 */
							// !isUnscheduledFinals && !isLost && (
							!isUnscheduledFinals && (
								<div className="help" onClick={() => toggleHelp('open')}>
									<i className="icon-help"></i>
								</div>
							)}

							{/** Not Finals message -------- display DrawAnalysis Bar
							 *   Schdeuld match ------------ don't show predicted bar marker - get overall draw rank marker
							 *   Lost player ----------------don't show the bar
							 *   Not hideContent ------------don't display the bar and the content on the bracket view
							 */
							!isUnscheduledFinals && !showDefaultText && !isLost && !hideContent && (
								<DrawAnalysisBar
									data={{
										playerDrawAnalysisData,
										roundByRoundData:
											parseInt(aiDrawRoundNum) > 1 && !isScheduledMatch
												? { roundDrawLabel }
												: null,
										isWinner,
										demo: showDefaultText ? true : false,
									}}
								/>
							)}
						</div>
					)}

					<p className={getSentenceClass()}>{generateAnalysisContent()}</p>

					{/**
					 *   For Unscheduled Final, show Power Index button */
					showPowerIndexButton && (
						<div className="power-index-button">
							<EventsButton
								aria-label={values.powerIndexTitle}
								to={'/en_US/powerindex/index.html'}
								title={values.powerIndexTitle}
								className={`compact`}
								name={values.powerIndexTitle}>
								{values.powerIndexTitle}
							</EventsButton>
						</div>
					)}

					{page == 'powerindex' && (
						<>
							{!isFinalCompleted && !isLost && (
								<p>See the round by round analysis by visiting the Draws Bracket View.</p>
							)}
							<BracketViewButton
								attributes={{
									bracketViewLink,
									playerId,
								}}
							/>
						</>
					)}

					{
						/**
						 *  Normal Path to the Final button
						 *  Hide it if the selected event's draws are not released
						 */
						// !attributes?.hideButton && !isUnscheduledFinals && isEventExist && (
						// 	<Button
						// 		aria-label="Path To The Final"
						// 		onClick={() => attributes?.callbackFn(playerDrawAnalysisData?.playerId)}
						// 		title={'Path To The Final'}
						// 		bypassAppLink={false}
						// 		className={`rounded-corner ${attributes?.styleClass}`}
						// 		name={`Path To The Final`}
						// 		style={{ width: '100%' }}>
						// 		Path to the Final
						// 	</Button>
						// )
					}
				</div>

				{/**
				 *    Add DrawsAnalysisHelp here just to display on PowerIndex page
				 *    The Bracket View has DrawsAnalysisHelp component that's placed on the DrawsLists
				 */
				page == 'powerindex' && (
					<DrawAnalysisHelp
						attributes={{
							page,
							playerId,
							bracketViewLink,
						}}
					/>
				)}
			</div>
		);
	} else {
		return null;
	}
};

export default DrawAnalysis;
