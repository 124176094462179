/**
 * get contentId from CMS XML feed and map main nav and second nav
 *
 */
export const cmsSubNavContentIdList = contentId => {
	logger.log('cmsSubNavContentIdList - contentId:%o', contentId);
	let mode = '';
	let selected = '';
	let typeSel = '';

	switch (contentId) {
		/** --- Visit : Plan Your Visit --- */
		case 'plan_your_visit':
		case 'transportation_directions':
		case 'prohibited_items':
		case 'official_travel_packages':
			mode = 'Visit';
			selected = 'Plan Your Visit';
			break;

		/** --- Visit : At the Open --- */
		/** Food and Beverage (Dining) page is a standalone page and it has its own pageSubNavMenu added */
		case 'on_site':
		case 'grounds_map':
		case 'guests_with_disabilities':
			// case "us_open_fan_week": // no need to show page sub menu on this page - in case they want to show, just commenting this out

			mode = 'Visit';
			selected = 'At the Open';
			break;

		/** --- Visit : At Home --- */
		case 'us_open_at_home':
			mode = 'Visit';
			selected = 'At Home';
			break;

		/** --- Visit : At Home --- */
		case 'atoz':
			mode = 'Visit';
			selected = 'A-Z Guide';
			break;

		/** --- News : Podcast --- */
		case 'us_open_podcast':
			mode = 'News';
			selected = 'Podcast';
			break;

		/** --- News : Podcast --- */
		case 'equal_play_podcast':
			mode = 'News';
			selected = 'Podcast';
			break;

		/** --- News : History --- */
		case 'history':
			mode = 'News';
			selected = 'History';
			break;

		//international tv schedule
		case 'tv_intl':
			mode = 'Watch';
			selected = 'Broadcast Partners';
			// typeSel = 'International';
			break;

		//How to watch
		case 'watch_listen_live_to_the_us_open':
			mode = 'Watch';
			selected = 'How to Watch';
			// typeSel = 'International';
			break;

		case 'fan-week':
			mode = 'Fan Week';
			selected = 'Fan Week';
			break;

		case 'schedule-of-events':
			mode = 'Fan Week';
			selected = 'Schedlule of Events';
			break;

		case 'arthur-ashe-kids-day':
		case 'arthur_ashe_kids_day':
			mode = 'Fan Week';
			selected = "Arthur Ashe Kids' Day";

		default:
			break;
	}

	return { mode, selected, typeSel };
};
