import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SLAMTRACKER_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.SLAMTRACKER_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.SLAMTRACKER_LOAD:
			newState = {
				...state,
			};
			return newState;

		case deps.actionTypes.SLAMTRACKER_CHANGEVIEW:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.SLAMTRACKERLIVE_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.SLAMTRACKER_MOMENTUM_TOGGLEPOINT:
			newState = {
				...state,
				expandedPoints: {
					...state.expandedPoints,
					...action.data,
				},
			};

			// logger.log(
			//   "[Slamtracker] reducer.SLAMTRACKER_MOMENTUM_TOGGLEPOINT newState:%o",
			//   newState
			// );
			return newState;

		case deps.actionTypes.SLAMTRACKER_UPDATE_MATCHHISTORY:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		// case deps.actionTypes.SLAMTRACKER_LOAD_SITUATIONAL_DATA:
		//   newState = {
		//     ...state,
		//     ...action.data
		//   };
		//   return newState;

		case deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_ZOOM:
			newState = {
				...state,
				filters: {
					open: '',
				},
				momentumPreferences: {
					...state.momentumPreferences,
					...action.data,
					disableScroll: true,
				},
			};
			// logger.log(
			//   "[Slamtracker] reducer.SLAMTRACKER_UPDATE_MOMENTUM_ZOOM newState:%o",
			//   newState
			// );
			return newState;

		case deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_SET:
			newState = {
				...state,
				momentumPreferences: {
					...state.momentumPreferences,
					...action.data,
				},
			};
			// logger.log('[Slamtracker] reducer.SLAMTRACKER_UPDATE_MOMENTUM_SET newState:%o', newState);
			return newState;

		case deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_GAME:
			newState = {
				...state,
				momentumPreferences: {
					...state.momentumPreferences,
					...action.data,
				},
			};
			// logger.log('[Slamtracker] reducer.SLAMTRACKER_UPDATE_MOMENTUM_GAME newState:%o', newState);
			return newState;

		case deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_SETTINGS:
			newState = {
				...state,
				momentumPreferences: {
					...state.momentumPreferences,
					settings: {
						...state.momentumPreferences.settings,
						...action.data.settings,
					},
				},
			};
			// logger.log(
			//   "[Slamtracker] reducer.SLAMTRACKER_UPDATE_MOMENTUM_SETTINGS newState:%o",
			//   newState
			// );
			return newState;

		case deps.actionTypes.SLAMTRACKER_ENABLE_MOMENTUM_SCROLL:
			newState = {
				...state,
				momentumPreferences: {
					...state.momentumPreferences,
					disableScroll: false,
				},
			};
			// logger.log(
			//   "[Slamtracker] reducer.SLAMTRACKER_ENABLE_MOMENTUM_SCROLL newState:%o",
			//   newState
			// );
			return newState;

		case deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_SHOW_SETTINGS:
			newState = {
				...state,
				filters: {
					open: '',
				},
				momentumPreferences: {
					...state.momentumPreferences,
					...action.data,
				},
			};
			// logger.log(
			//   "[Slamtracker] reducer.SLAMTRACKER_UPDATE_MOMENTUM_SHOW_SETTINGS newState:%o",
			//   newState
			// );
			return newState;

		case deps.actionTypes.SLAMTRACKER_FILTER:
			// logger.log("[Slamtracker] SLAMTRACKER_FILTER - data:%o", action.data);

			let filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};
			// logger.log("[Slamtracker] SLAMTRACKER_FILTER - newState:%o", newState);
			return newState;

		case deps.actionTypes.SLAMTRACKER_MOMENTUM_MOUNT:
			// logger.log('[Slamtracker] SLAMTRACKER_MOMENTUM_MOUNT - data:%o', action.data);

			newState = {
				...state,
				...action.data,
			};
			// logger.log('[Slamtracker] SLAMTRACKER_MOMENTUM_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.SLAMTRACKER_RESET:
			// logger.log(
			//   "[Slamtracker] reducer.SLAMTRACKER_RESET data:%o",
			//   action.data
			// );
			newState = {
				...state,
				slamtrackerStatsLayout: 'summary',
				filters: {
					open: '',
				},
				momentumLoaded: false,
				momentumPreferences: {
					zoomLevel: 'match',
					set: '1',
					game: '1',
					showSettings: false,
					settings: {
						All: false,
						Ace: true,
						Winner: true,
						BreakPointWon: true,
						DoubleFault: false,
						UnforcedError: false,
						Expand: false,
					},
				},
				expandedPoints: {},
				highlights: {},
			};

			// logger.log(
			//   "[Slamtracker] reducer.SLAMTRACKER_RESET newState:%o",
			//   newState
			// );
			return newState;

		case deps.actionTypes.SLAMTRACKER_UPDATE_HIGHLIGHTS:
			newState = {
				...state,
				...action.data,
			};

			// logger.log('[Slamtracker] reducer.SLAMTRACKER_UPDATE_HIGHLIGHTS newState:%o', newState);
			return newState;
		
		case deps.actionTypes.SLAMTRACKER_UPDATE_DATA:
			// logger.log('[Slamtracker] reducer.SLAMTRACKER_UPDATE_DATA action.data:%o', action.data);
			newState = {
				...state,
				...action.data
			}

			// logger.log('[Slamtracker] reducer.SLAMTRACKER_UPDATE_DATA newState:%o', newState);
		
			return newState;

		default:
			return state;
	}
};
