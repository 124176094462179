/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import PlayerImage from 'appdir/components/content/PlayerImage';
import EventsLink from 'appdir/components/general/EventsLink';
import { getAppLink } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerBox for Match Insights
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['PlayerBox'],
		windowSize: state?.['Controller']?.windowSize,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	setSTPanelStatus: data => dispatch(deps.actions.Controller.setSTPanelStatus(data)),
});
class PlayerBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			render: false,
		};
		// logger.log('[PlayerBox] - props:%o', props);

		this.imgSrc = props?.imgPath?.replace('<playerid>', props?.data?.idA);

		// this.updateFavs = this.updateFavs.bind(this);
	}

	getPlayerFlag(nation) {
		let flagPath;

		flagPath = this.props?.flagPath?.replace('<code>', nation);

		return flagPath;
	}

	onLinkClick = linkPath => {
		if(window.webview && this.props?.isPanel) {
			let link = getAppLink(linkPath, '');
			location.href = link.url;
		} else {
			if (this.props.windowSize == 'mobile') {
				this.props.setSTPanelStatus({ open: false });
			}
			this.props.navigate({ path: linkPath });
		}
	};

	render() {
		// logger.log('[PlayerBox] - render - this:%o', this);

		let { teamnum, data, likelihoodWinData } = this.props;
		return (
			<div className={`mi-pr-player-info player${teamnum}`}>
				<div className="mi-pr-player-container">
					{this.props.isPanel === true ? (
						<div className="player-box-link"
							onClick={() => {
								this.onLinkClick(`/en_US/players/overview/${data.idA}.html`);
							}}>
							<PlayerImage
								attributes={{
									'player-image': this.imgSrc,
									pid: data.idA,
								}}
							/>
						</div>
					) : (
						<EventsLink
							to={`/en_US/players/overview/${data.idA}.html`}
							webviewCare={window.webview === true ? true : false}>
							<PlayerImage
								attributes={{
									'player-image': this.imgSrc,
									pid: data.idA,
								}}
							/>
						</EventsLink>
					)}

					<div className="mi-pr-player-country-name">
						{this.props.isPanel === true ? (
							<div className="player-box-link"
								onClick={() => {
									this.onLinkClick(`/en_US/players/overview/${data.idA}.html`);
								}}>
								<div className="player-name">{data.displayNameA}</div>
							</div>
						) : (
							<EventsLink
								to={`/en_US/players/overview/${data.idA}.html`}
								webviewCare={window.webview === true ? true : false}>
								<div className="player-name">{data.displayNameA}</div>
							</EventsLink>
						)}

						<div className="mi-player-flag">
							{data.nationA ? <img src={this.getPlayerFlag(data.nationA)} alt={data.nationA} /> : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerBox);
