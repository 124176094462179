/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

/* page elements */
import InsightsIndex from 'appdir/components/pages/InsightsIndex';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsightsWebview
 * -----------------------------------------------------------------------------
 */

export default class MatchInsightsIndexWebview extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		logger.log('[MatchInsightsIndexWebview] render - this: %o', this);

		return <InsightsIndex />;
	}
}
