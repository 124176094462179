/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import TicketsModule from './TicketsModule';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: TicketsModule
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.TicketsModule,
	IndividTicketsDay: state['GeneralContentPage'].id,
	EventsWindow: state['WindowSize'].EventsWindow,
	fanweekInfo: state['Config']?.otherData?.fanweekInfo,
	...props,
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.TicketsModule.mount()),
	loadOverlayModal: (type, data) => dispatch(deps.actions.ModalOverlay.loadOverlayModal(type, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsModule);
