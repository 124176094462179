/**
 * RadioPlayer Initial State
 */
export default {
	// radio: {},

	// do not persist to local storage (default)
	// see https://www.npmjs.com/package/redux-local-persist
	persist: false,
};
