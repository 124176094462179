import deps from "dependencies";

export default {
  mount: () => (dispatch, getState, store) => {
    return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
      Config => {
        logger.log("[Hero] - actions mount");
        let data = {
          heroPath: Config.cmsData["mediaWall"],
          videoConfig: Config.videoPlayer,
          status: "loaded"
        };
        dispatch({ type: deps.actionTypes.HOMEHERO_MOUNT, data: data });
      }
    );
  }
};
