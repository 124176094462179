import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	//logger.log('[SchedulePage] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.SCHEDULEPAGE_LOAD:
			// logger.log('[SchedulePage] reducers SCHEDULEPAGE_LOAD - action:%o, state:%o', action, state);

			filters = {
				...state.filters,
				day: action.data.tournDay,
			};

			newState = {
				...state,
				...action.data,
				status: 'load',
				filters,
			};
			logger.log('[SchedulePage] reducers SCHEDULEPAGE_LOAD - newState:%o', newState);
			return newState;

		case deps.actionTypes.SCHEDULEPAGE_FILTER:
			// logger.log('[SchedulePage] SCHEDULEPAGE_FILTER - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};
			logger.log('[SchedulePage] SCHEDULEPAGEPAGE_FILTER - newState:%o', newState);
			return newState;

		case deps.actionTypes.SCHEDULEPAGE_SETDAY:
			// logger.log('[SchedulePage] SCHEDULEPAGE_SETDAY - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'load',
			};
			logger.log('[SchedulePage] SCHEDULEPAGE_SETDAY - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
