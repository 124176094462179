import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['PlayerStatsPage'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.PlayerStatsPage.filter(settings)),
});

class PlayerStatsSelect extends Component {
	constructor(props) {
		super(props);
	}

	setStats = e => {
		this.props.filter({
			playerStats: e.target.value,
			open: '',
		});

		MeasurementUtils.dispatchMeasurementCall('select', e.target.value);
	};

	onOpen = which => {
		this.props.filter({
			open: which,
		});
	};

	render() {
		let selectData = {
			options: [{ name: 'Stats', value: 'stats' }],
		};
		selectData.options = selectData.options.concat(this.props.selectData);
		return (
			<div className="scores-select-menu">
				<select onChange={this.setStats} className="scores-select-menu-select">
					{selectData.options.map(option => (
						<option key={option.name} value={option.name}>
							{option.name
								.charAt(0)
								.toUpperCase()
								.concat(option.name.slice(1))}
						</option>
					))}
				</select>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStatsSelect);
