import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.GENERALCONTENT_LOAD:
			newState = {
				...state,
				...action.data,
				data: '',
				prevNextData: '',
			};
			logger.log('[GeneralContentPage] GENERALCONTENT_LOAD - newState:%o', newState);
			return newState;

		case deps.actionTypes.GENERALCONTENT_UNMOUNT:
			newState = {
				...state,
				...action.data,
				data: '',
				prevNextData: '',
			};
			logger.log('[GeneralContentPage] GENERALCONTENT_UNMOUNT - newState:%o', newState);
			return newState;

		default:
			// logger.log('[GeneralContentPage] action.type - default:%o',state);
			return state;
	}
};
