/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import throttle from 'lodash/throttle';
/**
 * -----------------------------------------------------------------------------
 * React Component: BackToTop
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['BackToTop'],
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};
class BackToTop extends Component {
	constructor(props) {
		super(props);
		this.onScoll = this.onScroll.bind(this);
	}

	componentDidMount() {
		logger.log('[BackToTop] - componentDidMount state:%o', this.state);
		// if (this.state.hasOwnProperty('mount')) {
		// 	this.state.mount();
		// }
		window.addEventListener('scroll', throttle(this.onScroll.bind(this), 100, { trailing: true }), false);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScoll.bind(this), false);
	}

	onScroll() {
		//logger.log('[BackToTop] - onScroll: scrollY: %o', window.scrollY);
		if (this.state.EventsWindow && window.scrollY > this.state.EventsWindow.windowHeight) {
			this.setState({
				show: true,
			});
		} else {
			this.setState({
				show: false,
			});
		}
	}

	render() {
		//logger.log('[BackToTop] render state:%o', this.state);
		return this.state.show ? (
			<section className="page-content backToTopWrapper">
				<section className="backToTopContainer">
					<div className="item backToTop">
						<a href="#">
							<i className="icon-arrow-left" />
						</a>
					</div>
				</section>
			</section>
		) : null;
	}
}

export default connect(mapStateToProps)(BackToTop);
