import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EventsLink from 'appdir/components/general/EventsLink';

const mapStateToProps = (state, props) => {
	return {
		...state['BlueBar'],
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};
class BlueBar extends Component {
	constructor(props) {
		super();
		this.state = {
			selectedOption: '',
		};
	}

	componentDidMount() {
		this.currPathname = window.location.pathname;
	}

	componentDidUpdate() {
		const selectedItem = this.props?.menuData?.find(item => item.link.url === this.currPathname);
		if (selectedItem && !this.state.selectedOption) {
			this.setState({ selectedOption: selectedItem.link.url });
		}
	}

	handleDropdownClick = e => {
		logger.log('handledropdown', e.target.value);
		const selectedOption = e.target.value;
		if (String(selectedOption).includes('https://')) {
			// console.log('handledropdown reached', e.target.value);
			if (!window.open(e.target.value, '_blank')) {
				// if window.open fails, just open the map
				location.href = e.target.value;
			}
		} else {
			// console.log('handledropdown op2', e.target.value);
			this.setState({ selectedOption });
			const { menuData, history } = this.props;
			history.push(selectedOption);
		}
	};

	render() {
		let smallScreen = this.props.EventsWindow.windowWidth < 768;
		const displayMenu =
			this.props.menuData?.length > 0 && smallScreen ? (
				<div className="visit-select-dropdown-wrapper">
					<select
						onChange={this.handleDropdownClick}
						className="visit-select-menu"
						value={this.state?.selectedOption}>
						{this.props.menuData?.map(option => (
							<option key={option.title} value={option.link.url}>
								{option.title}
							</option>
						))}
					</select>
				</div>
			) : (
				<div className="lb-events-wrapper">
					{this.props.menuData?.map(item => {
						return (
							<div className="lb-item-wrapper">
								<EventsLink to={item.link.url} key={item.title}>
									<div
										data-text={item.title}
										className={`lb-event-name ${item.link.url === window.location.pathname &&
											'active'}`}>
										{item.title}
									</div>
								</EventsLink>
							</div>
						);
					})}
				</div>
			);
		return <div className="light-blue-bar-wrapper">{displayMenu}</div>;
	}
}

export default withRouter(connect(mapStateToProps, null)(BlueBar));
