/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import PlayerSeed from './PlayerSeed';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: PlayerSeed
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.PlayerSeed,
	favorites: state['Controller'].favorites,
	FeaturedPlayers: state.Config.featuredPlayers,
	OtherData: state.Config.otherData,
	windowSize: state['Controller'].windowSize,
	countriesLookUp: state['Controller'].countriesLookUp,
	...props,
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.PlayerSeed.mount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerSeed);
