/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from 'components/Template';
import { isMobile, isTablet } from 'react-device-detect';

/**
 * -----------------------------------------------------------------------------
 * React Component: RadioLanding
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['RadioLanding'],
		stubs: state['Config'].stubPages,
		sponsors: state['Config'].sponsors,
		radio: state['Config'].radio,
		liveIndicators: state['ActiveData']['liveIndicators'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.RadioLanding.mount()),
	unmount: data => dispatch(deps.actions.RadioLanding.unmount(data)),
});

class RadioLanding extends Component {
	constructor(props) {
		super(props);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'US Open Radio',
		});
		// logger.log('[RadioLanding] constructor - this: %o', this);
	}

	openRadio(stream, debug, promo) {
		let radioWindow = '';
		let radioLaunchPage = '';
		let baseUrl = '/webview/en_US/radio/radio.html';
		let lang = 'en';

		if (this.props?.liveIndicators?.radio) {
			if (isMobile || isTablet) {
				window.open(baseUrl, '_blank');
			} else {
				var d = new Date();
				var t = d.getTime();
				radioLaunchPage += baseUrl + '?ts=' + t;
				radioLaunchPage += debug && debug.indexOf('debug') > -1 ? '&db=true' : '&db=false';
				radioLaunchPage += '&ref=' + document.location.host + document.location.pathname;
				radioLaunchPage += '&lang=' + lang;
				if (radioWindow.closed || radioWindow == '') {
					//consoleWindow = window.open(consolelaunchPage,"consoleWindow","width=510,height=" + mcWindowHeight + ",top=50,left=50")
					radioWindow = window.open(radioLaunchPage, 'radioWindow', 'width=1024,height=708,top=50,left=50');
					if (radioWindow.opener == null) radioWindow.opener = self;
				}
				radioWindow.focus();
			}
		}
	}

	componentWillUnmount() {
		// logger.log('[RadioLanding] unmounted');
		this.props.unmount();
	}

	isStubbed() {
		return this.props?.stubs?.radio == 'stub' ? true : false;
	}

	getSponsor() {
		// logger.log("[RadioLanding] getSponsor - this:%o", this);
		let sponsor = null;
		if (this.props.sponsors && this.props.sponsors['radio']) {
			sponsor = this.props.sponsors['radio'];
		}
		logger.log('[RadioLanding] getSponsor - sponsor:%o', sponsor);
		return sponsor;
	}

	render() {
		logger.log('[RadioLanding] render - this:%o', this);

		let header_propsData = {
			headerType: 'radio',
			metaTitle: 'US Open Radio',
			metaDescription: 'Listen to free, play by play coverage of the 2020 US Open on US Open Radio',
			metaDate: '',
			metaPlayers: '',
			scroll: false,
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			title: 'US Open Radio',
			sponsor: this.getSponsor(),
		};

		if (!this.props.stubs) {
			return null;
		}

		if (this.isStubbed()) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<div className="content-main">
						<StubBox
							attributes={{
								title: header_propsData.title,
								message: this.props.stubs.competitors.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</div>
				</Template>
			);
		} else {
			//let propsData = this.getPageData();
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section id="radio" className="heroSection">
						<div className="heroImage">
							<div
								className="heroContainer"
								style={{
									backgroundImage: `url(${this.props.radio.landing.background})`,
									minHeight: '300px',
								}}>
								<div className="heroInfo">
									<div className="radioLanding">
										<h3>US Open Radio</h3>
										<h4>Presented by American Express</h4>
										{this.props?.liveIndicators?.radio ? (
											<div className="msg">
												<p>{this.props.radio.landing.description}</p>
												<div
													onClick={() => {
														this.openRadio(0);
													}}
													className={`uso-button blue`}>
													Launch Radio
												</div>
											</div>
										) : (
											<div className="msg">
												<p>{this.props.radio.landing['off-air']}</p>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RadioLanding);
