/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Template from 'components/Template';
import { connect } from 'react-redux';
import deps from 'dependencies';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import Video from 'appdir/components/content/Video';
import Helmet from 'react-helmet';
import Time from 'appdir/components/common-ui/Time';
import axios from 'axios';
import uniq from 'lodash/uniq';
import AdUnit from 'appdir/components/general/AdUnit';
import MeasurementUtils from 'appdir/lib/analytics';
import get from 'lodash/get';
import { isIOS } from 'react-device-detect';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
import { fetch, fetchAll, getAppVideoLink, getQuerystringHash } from 'appdir/components/general/Util';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import ModalOverlay from 'appdir/components/general/ModalOverlay';
import EventsRedirect from 'appdir/components/general/EventsRedirect';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoPlayerLanding
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['VideoPlayerLanding'],
		windowSize: state['Controller'].windowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadOverlayModal: (type, data) => dispatch(deps.actions.ModalOverlay.loadOverlayModal(type, data)),
	hideOverlayModal: () => dispatch(deps.actions.ModalOverlay.hideOverlayModal()),
});

class VideoPlayerLanding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			monthKey: null,
			dayKey: null,
			yearKey: null,
			categoryKey: null,
			filterAll: null,
			filterTourn: null,
			filterKeyAll: null,
			filterKeyTourn: null,
			filterCategory: null,
			type: null,
			fullVideoData: [],
			videoData: [],
			noData: false,
			nextVid: null,
			showNext: false,
			countDown: null,
			autoPlayText: 'Disable',
			shouldAutoplay: true,
			fullScreen: 'close',
			topVideos: null,
			showModal: false,
			modalFinished: false,
		};
		this.alreadyStartedCountdown = false;
		this.countDownIntervall = null;
		this.countDown = 5;
		this.fetchCurr = true;
		this.fetchRelated = true;
		this.fetchTopVideos = true;
		this.fetchFilters = true;

		this.geoCheck = false;
		this.hideHighlights = false;
		this.parsedVideoType = '';
		this.loadedParse = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Videos',
		});
	}

	componentDidMount() {
		this.parsedVideoType = getQuerystringHash();

		if (this.props.match.params.id && this.props.match.params.id != 'index') {
			this.setState({ playNow: isIOS ? false : true });
		}
	}

	showVideo = () => {
		this.setState({ playNow: true });
	};

	showMore = () => {
		if (this.state.fullVideoData.length > 0) {
			let fullDataSet = this.state.fullVideoData;
			let shownDataSet = this.state.videoData;
			let videoTotal = this.props.config.adMode == 'tournament' ? 12 : 15;
			let splicedDataSet = fullDataSet.splice(0, videoTotal);
			shownDataSet = shownDataSet.concat(splicedDataSet);

			MeasurementUtils.dispatchMeasurementCall('Load More');
			this.setState(prevState => {
				return {
					...prevState,
					videoData: shownDataSet,
					fullVideoData: fullDataSet,
				};
			});
		}
	};

	mapVideoData = (data, autoPlay) => {
		// logger.log('[VideoPlayerLanding] data:%o', data);
		if (data && !isEmpty(data)) {
			let vastCategory = 'default';
			if (data.subType == 'highlights') {
				vastCategory = 'highlights';
			} else if (data.subType == 'press_conferences') {
				vastCategory = 'press_conferences';
			}
			return {
				id: data.cmsId,
				title: data.title,
				description: data.description && data.description.length > 0 ? data.description : data.title,
				poster: data.images[0].xlarge,
				shareUrl: data.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: data.media.m3u8,
						mimeType: data.media.m3u8.includes('m3u8') ? 'application/x-mpegURL' : 'video/mp4',
						streamType: 'vod',
					},
				],
				tracks:
					get(data, 'media.tracks', false) && get(data, 'media.tracks[0].file', false)
						? [
								{
									kind: 'captions',
									type: 'text/vtt',
									srclang: 'en',
									src: data.media.tracks[0].file,
								},
						  ]
						: [],
				startSeconds: 0,
				autoplay: true, //autoPlay ? autoPlay : false,
				autoplayPolicy: 'mutedinline',
				playsinline: true,
				displayDate: data.displayDate,
				ads: [this.props.config.adConfig.vast[vastCategory]],
				category: data.subType,
			};
		}
	};

	getMimeType(data) {
		return 'application/x-mpegURL';
	}

	updateFilters = (all, tourn, category) => {
		let defaultType = 'all';
		let filterKey = all.content.dates[0].key;
		let currYear = all.content.dates[0].year;
		let subType = category.videoCategories[0].subType;
		this.parsedVideoType = '';
		this.setState(prevState => {
			return {
				...prevState,
				filterAll: all.content.dates,
				filterTourn: tourn.content.dates,
				filterCategory: category.videoCategories,
				type: defaultType,
				filterKey: filterKey,
				subType: subType,
				currYear: currYear,
			};
		});
	};

	setNext = videoURL => {
		return fetch(videoURL);
	};

	playNext = () => {
		if (this.state.nextVid !== null) {
			this.setState(prevState => {
				return {
					...prevState,
					showNext: true,
				};
			});
		}
	};

	componentDidUpdate(prevState) {
		let highlightsBlock;
		if (this.props.config && !this.geoCheck) {
			//get initial hero video for the video landing page
			fetch(this.props.config.highlightsGeoCheck)
				.then(result => {
					//logger.log('[VideoPlayerLanding] componentDidUpdate - fetchGeoJson:%o', result);
					this.hideHighlights = result.block;
					// logger.log('[VideoPlayerLanding] componentDidUpdate - geo: %o', this.hideHighlights);
					this.geoCheck = true;
					this.setState(prevState => {
						return {
							...prevState,
							showHighlights: this.hideHighlights,
						};
					});
					// logger.log('[VideoPlayerLanding] componentDidUpdate - state: %o', this.state);
				})
				.catch(e => {
					this.hideHighlights = false;
					this.geoCheck = true;
					this.setState(prevState => {
						return {
							...prevState,
							showHighlights: this.hideHighlights,
						};
					});
					// logger.log('[VideoPlayerLanding] componentDidUpdate - state: %o', this.state);
				});
		}
		// logger.log('[VideoPlayerLanding] componentDidUpdate - geoCheck: %o fetchCurr: %o', this.geoCheck, this.fetchCurr);
		if (this.geoCheck) {
			if (this.fetchCurr && this.props.config) {
				this.fetchCurr = false;

				//determine hero API url based on URL having video id in it or not
				let heroURL = this.props.match.params.id
					? this.props.config.relatedContent.replace('<videoId>', this.props.match.params.id)
					: null;
				// : this.props.config.hero;
				logger.log('[VideoPlayerLanding] heroUrl: %o', heroURL);
				//fetch hero video
				if (heroURL) {
					fetch(heroURL).then(data => {
						//fetch the up next video to play after hero video is done
						let currVideoData = this.props.match.params.id ? data : data.hero;
						let nextURL = this.props.config.relatedContentNext
							.replace('<contentId>', this.props.match.params.id ? data.cmsId : data.hero.cmsId)
							.replace('<count>', 1)
							.replace('<true|false>', 'false')
							.replace('<type>', 'video');
						// logger.log('[VideoPlayerLanding] componentDidUpdate - currVideoData: %o', currVideoData);
						nextURL = this.hideHighlights
							? nextURL + '&omitSubTypes=highlights&omitSubTypes=extended_highlights'
							: nextURL;
						// logger.log('[VideoPlayerLanding] componentDidUpdate - nextURL: %o', nextURL);
						this.setNext(nextURL)
							.then(nextVideoData => {
								this.setState(prevState => {
									return {
										...prevState,
										nextVid: this.mapVideoData(nextVideoData.next[0]),
										currVideoData: this.mapVideoData(currVideoData),
										showModal: this.props.match?.params?.id ? true : false,
									};
								});
							})
							.catch(err => {
								console.error('Related Video Fail', err);
								//if error fetching next video set hero video anyways and set next up video to null
								this.setState(prevState => {
									return {
										...prevState,
										nextVid: null,
										currVideoData: this.mapVideoData(currVideoData),
									};
								});
							});
					});
				}
			}

			//initial fetch for filter dropdowns
			if (
				!this.state.filterAll &&
				!this.state.filterTourn &&
				!this.state.filterCategory &&
				this.props.config &&
				this.fetchFilters
			) {
				this.fetchFilters = false;
				fetchAll([
					this.props.config.menusAPI.all,
					this.props.config.menusAPI.tourn,
					this.props.config.menusAPI.category,
				]).then(
					axios.spread((all, tourn, category) => {
						let defaultType = 'all';
						let filterKey = all.content.dates[0].key;
						let filterKeyTourn = tourn.content.dates[0].key;
						let subType = this.props.location.search.includes('usopennow=true')
							? 'us_open_now'
							: category.videoCategories[0].subType;
						let currYear = all.content.dates[0].year;
						let currMonth = all.content.dates[0].month;
						let filteredCategories = [];
						if (this.hideHighlights) {
							category.videoCategories.map(cat => {
								if (cat.subType != 'highlights' && cat.subType != 'extended_highlights') {
									// logger.log('[VideoPlayerLanding] componentDidUpdate category: %o', cat);
									filteredCategories.push(cat);
								}
							});
						} else {
							if (this.parsedVideoType !== '') {
								category.videoCategories.map(cat => {
									if (cat.subType.includes(this.parsedVideoType)) {
										subType = this.parsedVideoType.toLowerCase();
									}
								});
							}
							filteredCategories = category.videoCategories;
						}
						// logger.log('[VideoPlayerLanding] componentDidUpdate categories: %o, subType:%o', filteredCategories, subType);
						const allFilterData = this.mapFilter(all.content.dates);
						const tournFilterData = this.mapFilter(tourn.content.dates);
						this.setState(prevState => {
							return {
								...prevState,
								filterAll: allFilterData,
								filterTourn: tournFilterData,
								filterCategory: filteredCategories,
								type: defaultType,
								filterKeyAll: filterKey,
								filterKeyTourn: filterKeyTourn,
								subType: subType,
								currYear: currYear,
								currMonth: currMonth,
								currCategory: '',
							};
						});
					})
				);
			}

			//fetch related content video data
			if (
				this.props.config &&
				this.state.type &&
				(this.state.filterKeyTourn !== null || this.state.filterKeyAll !== null) &&
				this.fetchRelated
			) {
				//determine filter type dynamically based on filter settings for related content API call
				let apiURL = '';
				let subTypeQS = this.state.subType;
				if (this.state.subType == 'highlights') {
					subTypeQS = this.state.subType + '&subType=cognitive%20match%20highlights';
				}
				apiURL = this.props.config.videosByCategory.replace(
					'<subType>',
					this.state.subType ? `&subType=${subTypeQS}` : ``
				);
				apiURL = this.hideHighlights
					? apiURL + '&omitSubTypes=highlights&omitSubTypes=extended_highlights'
					: apiURL;
				// logger.log('[VideoPlayerLanding] apiUrl:%o, relatedContentPath:%o', apiURL, this.state.filterAll);
				//if (this.state.subType) {
				fetch(apiURL).then(data => {
					// logger.log('[VideoPlayerLanding] data returned from related content %o', data);
					this.fetchRelated = false;

					/**
           * If related content video comes back with a count over 12 splice results into seperate data structures.
             The two data structures will used to store the related content that will be displayed in the UI,
             the second data structure will store the rest of the fetched data to be displayed when invoked by 'LOAD MORE' button
           */
					if (data.content.length > 0) {
						let fullVideoData = data.content.map(this.mapVideoData);
						let videoTotal = this.props.config.adMode == 'tournament' ? 12 : 14;
						let partialVideoData = fullVideoData.splice(0, videoTotal);
						this.setState(prevState => {
							return {
								...prevState,
								videoData: partialVideoData,
								fullVideoData: fullVideoData,
								noData: false,
							};
						});
					} else {
						this.setState(prevState => {
							return {
								...prevState,
								noData: true,
							};
						});
					}
				});
			}
		}

		if(this.props?.closeFromVideo === true) {
			this.hideVideoModal();
		}
	}

	//helper function to transform array structure into a object pseudo hashmap for faster lookups based on keys
	mapFilter(data) {
		let filterObj = {};
		for (let i = 0; i < data.length; i++) {
			let currData = data[i];
			filterObj[currData.key] = currData;
		}
		return filterObj;
	}

	//helper function that updates the hero video with new video
	updateHero = (data, tileClicked = false) => {
		//logger.log("The hero data", data);
		// logger.log('[VideoPlayerLanding] updateHero data:%o', data);

		let newCurrVideo = { ...data, autoplay: true, tileClicked };

		let nextURL = this.props.config.relatedContentNext
			.replace('<contentId>', data.id)
			.replace('<count>', 1)
			.replace('<true|false>', 'false')
			.replace('<type>', 'video');
		nextURL = this.hideHighlights ? nextURL + '&omitSubTypes=highlights&omitSubTypes=extended_highlights' : nextURL;
		// logger.log('[VideoPlayerLanding] componentDidUpdate - nextURL: %o', nextURL);
		fetch(nextURL)
			.then(data => {
				this.setState(prevState => {
					return {
						...prevState,
						currVideoData: newCurrVideo,
						nextVid: this.mapVideoData(data.next[0]),
						showNext: false,
					};
				});
			})
			.catch(err => {
				console.error('Related Next Video error', err);
				this.setState(prevState => {
					return {
						...prevState,
						nextVid: null,
						currVideoData: newCurrVideo,
						showNext: false,
					};
				});
			});
		//scroll to the top of the page to display the updated video
		//window.scrollTo(0, 0);
	};

	//updating the year filter state object
	updateYear = event => {
		let currYear = event.target.value;
		this.fetchRelated = true;
		MeasurementUtils.dispatchMeasurementCall('Select Year', {
			selection: currYear,
		});

		this.setState(prevState => {
			return {
				...prevState,
				currYear: currYear,
				filterKeyAll: `${currYear}${this.state.currMonth}`,
				videoData: [],
			};
		});
	};

	//updating the month filter state object
	updateMonth = event => {
		let currMonth = event.target.value;
		this.fetchRelated = true;
		MeasurementUtils.dispatchMeasurementCall('Select Month', {
			selection: currMonth,
		});

		this.setState(prevState => {
			return {
				...prevState,
				currMonth: currMonth,
				filterKeyAll: `${this.state.currYear}${currMonth}`,
				videoData: [],
			};
		});
	};

	//changing the filter type (all or tournament)
	toggleFilterType = data => {
		this.fetchRelated = true;
		MeasurementUtils.dispatchMeasurementCall('Select', { selection: data });
		this.setState(prevState => {
			return {
				...prevState,
				type: data,
				videoData: [],
			};
		});
	};

	//update tournament day selection state filter object
	updateTourn = event => {
		let currFilterKey = event.target.value;
		this.fetchRelated = true;
		MeasurementUtils.dispatchMeasurementCall('Select Day', {
			selection: currFilterKey,
		});
		this.setState(prevState => {
			return {
				...prevState,
				filterKeyTourn: currFilterKey,
				videoData: [],
			};
		});
	};

	//update category filter state object
	updateCategoryType = event => {
		let categoryType = event.target.value;
		this.parsedVideoType = '';
		this.fetchRelated = true;

		MeasurementUtils.dispatchMeasurementCall('Select', {
			selection: categoryType,
		});
		this.setState(prevState => {
			return {
				...prevState,
				subType: categoryType,
				videoData: [],
			};
		});
	};

	//set autoplay of next video on/off for hero video landing section
	toggleAutoPlay = toggle => {
		// let shouldAutoplay = this.state.shouldAutoplay;
		this.setState(prevState => {
			return {
				...prevState,
				shouldAutoplay: toggle,
			};
		});
	};

	onFullscreenChange = (data, videoData) => {
		// logger.log('[VideoPlayerLanding] fullscreenChange - data:%o', data);
		if (data) {
			this.setState({ fullScreen: 'open' });
		} else {
			this.setState({ fullScreen: 'close' });
		}
	};

	renderAd(num) {
		return (
			<div key={`ad${num}`} id={`ad${num}`} className="news-ad">
				<AdUnit data={{ adType: 'landingpage' }} />
			</div>
		);
	}

	getTileIndex(cmsId) {
		if (this.state.videoData && this.state.videoData.length > 0) {
			// logger.log('[VideoPlayerLanding] mapVideoData this.state.videoData:%o', this.state.videoData);
			// logger.log('[VideoPlayerLanding] mapVideoData cmsId:%o', cmsId);
			return this.state.videoData.findIndex(video => video.id === cmsId);
		} else return -1;
	}

	renderTopVideos = () => {
		// logger.log('[VideoPlayerLanding] renderTopVideos topVideos:%o', this.state.topVideos);
		let topVideos = this.state.topVideos;
		let mainStory = topVideos['content']['items'][0];
		let storyList = topVideos['content']['items'].slice(1);
		return (
			<div id="uso-topStories">
				<section id="uso-topStories-content">
					<header>
						<h2>{topVideos['title']}</h2>
						{topVideos['content']['date'].hasOwnProperty('dateStr') ? (
							<time dateTime={topVideos['content']['date']['dateTime']}>
								{topVideos['content']['date']['dateStr']}
							</time>
						) : null}
						<div id="sponsor" />
					</header>
					<section className="uso-stories">
						<ul className="uso-mainStory">
							{
								<li
									onClick={() => {
										this.updateHero(this.mapVideoData(mainStory.video), true);
									}}>
									<span className="uso-top-video" share-link={mainStory['video']['shareUrl']}>
										<img src={mainStory['video']['images'][0].xlarge} alt={mainStory['title']} />
										<div className="playBtn">&nbsp;</div>
									</span>
									<div className="mainStoryContent">
										<h3>{mainStory['title']}</h3>
										<div className="desc">{ReactHtmlParser(mainStory['abstract'])}</div>
									</div>
								</li>
							}
						</ul>
						<ul className="uso-storyList">
							{storyList.map((item, i) => (
								<li
									key={i}
									onClick={() => {
										this.updateHero(this.mapVideoData(item.video), true);
									}}>
									<span className="uso-top-video story-video" share-link={item['video']['shareUrl']}>
										<img src={item['video']['images'][0].small} alt={item['title']} />
										<div className="playBtn">&nbsp;</div>
									</span>
									<h3>{item['title']}</h3>
								</li>
							))}
						</ul>
					</section>
				</section>
			</div>
		);
	};

	canBePlayed(category) {
		// logger.log('[VideoPlayerLanding] canBePlayed %o %o %o', this.hideHighlights, category);
		if (this.hideHighlights && (category === 'highlights' || category === 'extended_highlights')) {
			return false;
		} else {
			return true;
		}
	}

	renderVideoItem = () => {
		if (this.state?.currVideoData) {
			this.setState(prevState => {
				return {
					...prevState,
					showModal: false,
				};
			}, () => { 

				logger.log('[VideoPlayerLanding] renderVideoItem - this.state?.currVideoData:%o', this.state?.currVideoData);
				const videoData = { 
					video: {
						id: this.state?.currVideoData?.id,
						title: this.state?.currVideoData?.title,
						shareUrl: this.state?.currVideoData?.shareUrl,
						// poster: this.state?.currVideoData?.images[0]?.xlarge
						poster: this.state?.currVideoData?.poster,
						closeOnComplete: true
					},
					autoplay:'true'
				};

				if(!window.webview) { 
					this.props.loadOverlayModal('VIDEO_MODAL', videoData);
				} else {
					let videoLink = getAppVideoLink(this.state?.currVideoData?.id);
					videoLink?.onclick();
				}
			});
		}
	};

	hideVideoModal = () => {
		logger.log('[VideoModal][VideoPlayerLanding] - hideVideoModal');

		if (this.state.currVideoData) {
			this.setState(prevState => {
				return {
					...prevState,
					showModal: false,
					modalFinished: true,
				};
			}, () => {
				if(this.props?.closeFromVideo === true) {
					this.props.hideOverlayModal();
				}
			});
		}
	};

	render() {
		logger.log('[VideoPlayerLanding] render this: %o', this);
		let header_propsData = {};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: [],
			title: 'Videos',
			page_header: 'Videos',
		};
		let filterAllKeys = this.state.filterAll !== null ? Object.keys(this.state.filterAll) : [];
		let yearFilter =
			filterAllKeys.length > 0
				? uniq(
						filterAllKeys.map(d => {
							return this.state.filterAll[d].year;
						})
				  )
				: [];

		let filterTournKeys = this.state.filterTourn !== null ? Object.keys(this.state.filterTourn) : [];

		if (this.state.featuredHero) {
			header_propsData = {
				headerType: 'video',
				shortTitle: this.state.featuredHero.title,
				scrollElem: '.content-main',
				metaTitle: this.state.featuredHero.title,
				metaDescription: this.state.featuredHero.description,
				metaDate: this.state.contentPageArry[currArticleIndex].date,
				scroll: false,
				titleElem: '.news--header',
				imageSrc: this.state.featuredHero.images[0].xlarge,
				videoSrc: this.featuredHero,
				title: 'US Open Highlights & Features',
			};
		} else if (this.state.currVideoData) {
			header_propsData = {
				headerType: 'video',
				shortTitle: this.state.currVideoData.title,
				scrollElem: '',
				metaDescription: this.state.currVideoData.description,
				metaDate: this.state.currVideoData.displayDate,
				scroll: false,
				titleElem: '.news--header',
				imageSrc: this.state.currVideoData.poster,
				videoSrc: null,
				title: `${this.state.currVideoData.title} - US Open Highlights & Features`,
				metaTitle: `${this.state.currVideoData.title} - US Open Highlights & Features`,
			};
		} else {
			header_propsData = {
				headerType: 'video',
				shortTitle: '',
				scrollElem: '',
				metaDescription: '',
				metaDate: '',
				scroll: false,
				titleElem: '.news--header',
				imageSrc: null,
				videoSrc: null,
				title: 'US Open Highlights & Features',
				metaTitle: 'US Open Highlights & Features',
			};
		}
		if (this.props.config && this.props.config.sponsors) {
			subheader_attributes.sponsor = this.props.config.sponsors.video;
		}
		if (this.state.currVideoData) {
			let videoURL = this.state.currVideoData.shareUrl;
			let containsURL = videoURL.startsWith('http');
			this.showURL = containsURL
				? this.state.currVideoData.shareUrl
				: this.props.config.domain + this.state.currVideoData.shareUrl;
			// logger.log('[VideoPlayerLanding] VideoShareBar shareURL: %o', this.showURL);
		}
		let canPlay = false;
		if (this.state.currVideoData) {
			canPlay = this.canBePlayed(this.state.currVideoData.category);
			// logger.log('[VideoPlayerLanding] render - can be played: %o', canPlay);
		}

		let displaySubType = this.state.subType;
		if (this.parsedVideoType !== '') {
			displaySubType = this.parsedVideoType.toLowerCase();
		}

		return (
			<Template
				header={header_propsData}
				subHeader={subheader_attributes}
				adType={'landingHero'}
				adTypeRefresh={'landingHero'}>
				{this.state.currVideoData && (
					<Helmet>
						<meta property="og:title" content={this.state.currVideoData.title} />
						<meta property="og:description" content={this.state.currVideoData.description} />
						<meta property="fb:app_id" content="471867639491882" />
						<meta property="og:image" content={this.state.currVideoData.poster} />
						<meta
							property="og:url"
							content={
								this.state.currVideoData.shareUrl.startsWith('http')
									? this.state.currVideoData.shareUrl
									: this.props.config.domain + this.state.currVideoData.shareUrl
							}
						/>
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:site" content="@USOpen" />
						<meta name="twitter:creator" content="@USOpen" />
						<meta name="twitter:title" content={this.state.currVideoData.title} />
						<meta name="twitter:description" content={this.state.currVideoData.description} />
						<meta name="twitter:image" content={this.state.currVideoData.poster} />
						<meta
							name="twitter:url"
							content={
								this.state.currVideoData.shareUrl.startsWith('http')
									? this.state.currVideoData.shareUrl
									: this.props.config.domain + this.state.currVideoData.shareUrl
							}
						/>
					</Helmet>
				)}
				<section className="page-content VideoPlayerLanding">
					<PageSubMenu mode="Watch" selected="Latest Videos" />
					{this.state.modalFinished ? (
						<div>
							{/* redirect to video landing page */}
							<EventsRedirect to={'/en_US/video/index.html'} />
						</div>
					) : null}
				</section>
				{this.state.showModal && this.state?.currVideoData ? (
					<div className="news-tile">
						{this.renderVideoItem()}
					</div>
				) : null}
			</Template>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayerLanding);
