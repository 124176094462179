import React from 'react';
import Template from 'components/Template';
import EventsLink from 'components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';

const AndroidLanding = props => {
	MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
		pageTitle: `Android Landing Page`,
	});

	let header_attributes = {
		headerType: 'generic',
		title: 'US Open Apps - Android',
		metaTitle: 'US Open Apps - Android',
		metaDescription:
			'The Official Site of the 2024 US Open Tennis Championships, a USTA event. US Open Apps - Android',
		metaDate: '',
		metaPlayers: '',
	};

	let subheader_attributes = {
		breadcrumbs: [
			{
				link: '/index.html',
				title: 'home',
			},
			{
				link: '/en_US/interactive/mobile/index.html',
				title: 'US Open Apps',
			},
		],
		sponsor: {},
		title: 'Android App',
	};
	return (
		<Template header={header_attributes} subHeader={subheader_attributes}>
			<h1 className="header">Android App</h1>
			<section className="page-content">
				<div className="app_landing_content">
					<div className="app_landing_container">
						<div class="appDetails">
							<div class="divice">
								<img src="/assets/images/misc/android.png" alt="Android" />
							</div>
							<div class="desc">
								<p class="link">
									<EventsLink to="https://play.google.com/store/apps/details?id=com.ibm.events.android.usopen">
										Download the US Open Android App
									</EventsLink>
								</p>
								<p>
									The United States Tennis Association is pleased to offer the official app for the US
									Open Tennis Championships, designed and developed by IBM. Stay connected to all the
									action during the Open, August 19 to September 8, 2024.
								</p>
								<h3>New Features:</h3>
								<h3>Fan Cam</h3>
								<p>
									Cheer for your favorite players from the comfort of your own homes. Submit your
									cheers for a chance to be seen and heard!
								</p>
								<h3>Live at the US Open</h3>
								<p>
									Peek into the US Open with live commentary from around the grounds and covering your
									favorite matches. Live at the US Open will​ be your eyes and ears on the grounds of
									the US Open providing up-to-the-minute news, match highlights, photos, and more.
								</p>
								<h3>Key Features: </h3>
								<h3>Latest</h3>
								<p>
									The interactive home screen offers fans an immersive experience into the US Open
									with immediate access to live scores and latest news, photos, and videos.
								</p>
								<h3>Scores – Analytics & Insights by IBM</h3>
								<p>
									LIVE exclusive point-by-point scoring updates, completed match results, statistics,
									match insights and IBM Keys to the Match during the US Open.
								</p>
								<h3>Players</h3>
								<p>
									Follow and favorite all the Players throughout the 2020 US Open and receive curated
									score, stats, and results
								</p>
								<h3>Other Features</h3>
								<p>Match Highlights, Official Schedule of Play, Draws, Ask the Open</p>
								<div class="ftr">
									<EventsLink to="https://play.google.com/store/apps/details?id=com.ibm.events.android.usopen">
										<img
											src="/assets/images/misc/google-play-badge.png"
											alt="Get it on Google Play"
										/>
									</EventsLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Template>
	);
};

export default AndroidLanding;
