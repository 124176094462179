import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Time from 'appdir/components/common-ui/Time';

class SearchResults extends Component {
	constructor(props) {
		super(props);

		let docs = props.data.response.docs;

		if (docs) {
			docs = docs.filter(result => result.score > 0.0001);
			docs = docs.filter(result => result.url_str.indexOf('en_US/search/content') < 0);

			docs = docs.sort((a, b) => {
				let dateA = parseFloat(a['metatag.resultdate_str']);
				let dateB = parseFloat(b['metatag.resultdate_str']);
				let titleA = a['metatag.resulttitle_str'] || '';
				let titleB = b['metatag.resulttitle_str'] || '';
				let scoreA = a.score;
				let scoreB = b.score;

				// bring player profile to the top
				// if (isNaN(dateA) && titleA.indexOf('Player Profile') > -1) {
				// 	dateA = 9999999999999;
				// 	logger.log('[SearchResults] constructor - move profile to top: %o', titleA);
				// } else if (isNaN(dateA)) {
				// 	dateA = 0;
				// }

				if (scoreA < scoreB) {
					return 1;
				} else if (scoreA > scoreB) {
					return -1;
				} else {
					return 0;
				}
			});
		}

		logger.log('[SearchResults] constructor - props:%o', props);
		logger.log('[SearchResults] constructor - docs:%o', docs);

		this.state = {
			docs: docs,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	getResult(item, key) {
		let regex = /^(https?:\/\/).*usopen.org\//;
		let linkUrl = '';

		linkUrl = String(item['url_str']).replace(regex, '/');
		return (
			<li key={key}>
				<Link to={linkUrl}>
					{item['metatag.resulttitle_str'] ? (
						<div className="title">{item['metatag.resulttitle_str']}</div>
					) : (
						<div className="title">{item['title_str']}</div>
					)}
				</Link>
				<div className="summary">{item['metatag.description_str']}</div>
				{item['metatag.resultdate_str'] ? (
					<div className="search--result-item-date">
						{<Time epoch_ms={item['metatag.resultdate_str']} format="dddd, MMMM D, YYYY h:mm A" />}
					</div>
				) : null}
			</li>
		);
	}

	render() {
		logger.log('[SearchResults] render - state:%o', this.state);
		let count = 0;

		if (this.state.docs) {
			return (
				<div className="searchResultsContainer">
					<div className="search-results-count">{this.state.docs.length} Results</div>
					<hr />
					<ul>
						{this.state.docs.map((item, index) => {
							//logger.log('[SearchPage] render - item:%o', item);
							return this.getResult(item, count++);
						})}
					</ul>
				</div>
			);
		} else {
			return null;
		}
	}
}
export default SearchResults;
