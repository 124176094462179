/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import Time from 'appdir/components/common-ui/Time';

import Template from 'components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import DataTabs from 'appdir/components/common-ui/DataTabs';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import StubBox from 'appdir/components/common-ui/StubBox';
import LeaderboardRow from './elements/LeaderboardRow';
import LeaderboardData from './elements/LeaderboardData';
import EventsLink from 'appdir/components/general/EventsLink';
import { getQSParams } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import MeasurementUtils from 'appdir/lib/analytics';
import { doMeasurement } from 'appdir/components/general/Util';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { isPostMatch } from 'appdir/components/pages/DrawsPage/DrawsUtils';
import { values } from 'appdir/main';

/**
 * -----------------------------------------------------------------------------
 * React Component: PowerIndexLeaderboard
 * -----------------------------------------------------------------------------
 */

/** convert tab values to the event ID
 *  to access to Tournament data */

const tabLookUp = {
	atp: 'MS',
	wta: 'WS',
};
export default class PowerIndexLeaderboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hideDiv: true,
			expired: false,
			startIndex: 0,
			endIndex: 31,
			loadMore_disabled: false,
			primary_sort: 'wpi_rank',
			secondary_sort: null,
			expand_full: {},
			resetSearch: false,
			searchTerm: '',
			dataListType: 'current',
			defaultPlayer: window.location.search ? getQSParams(window.location.search, 'playerId') : false,
			selectedTab:
				(window.location.search && getQSParams(window.location.search, 'playerId').startsWith('atp')) ||
				(window.location.search &&
					getQuerystringValues(window.location.search.replace(/^\?/, '')).selectTab == 'atp')
					? 'atp'
					: 'wta',
		};
		this.increment = 32;
		this.loadMoreCounter = 0;
		this.pageViewMeasured = false;
		this.onEnter = this.onEnter.bind(this);
		this.resetSearch = this.resetSearch.bind(this);
		this.tabSelect = this.tabSelect.bind(this);
		this.sortData = this.sortData.bind(this);
		this.loadMoreData = this.loadMoreData.bind(this);
		this.expandRow = this.expandRow.bind(this);
		this.listSelect = this.listSelect.bind(this);
		this.defaultComplete = false;

		logger.log(
			'[PowerIndexLeaderboard] constructor - defaultPlayer: %o',
			getQSParams(window.location.search, 'playerId')
		);

		logger.log('[PowerIndexLeaderboard] constructor - state:%o, props: %o', this.state, this.props);
		// logger.log(
		// 	'[PowerIndexLeaderboard] constructor - appShowFavs:%o, appFavs: %o',
		// 	this.appShowFavs,
		// 	this.state.appFavs
		// );

		this.initLoad = true;
		this.dataLoaded = false;
	}

	componentDidMount() {}

	componentWillUnmount() {
		this.props.unmount();
		this.props.clearTournament();
	}

	componentDidUpdate(prevProps, prevState) {
		let isStub = this.props.stubs?.powerindex && this.props.stubs?.powerindex?.stub === 'stub';

		if (this.props.status == 'loaded' && this.props.currentDay && !this.pageViewMeasured) {
			this.pageViewMeasured = true;
			// first time in, do a pageview
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: values.powerIndexTitle,
			});
		}

		if (this.props.status == 'loaded' && !isStub && this.props.stubs) {
			// make sure stub data are available and it's not stubbed
			if (this.props.status == 'loaded' && this.initLoad && this.props.sharedDataConfig) {
				this.initLoad = false;
				this.props.getPowerIndex(tabLookUp[this.state.selectedTab]);
				this.props.getPlayerList();
				this.props.getPowerIndexTrends();

				this.props.checkExpired(this.props.sharedDataConfig?.innovationContent).then(resp => {
					if (resp.status == 'expired') {
						// logger.log('[DrawAnalysis] CommonData InnovationContent - expired');
						this.props.update(this.props.sharedDataConfig.innovationContent);
					}
				});

				/** if Draw Analysis is enabled, fetch draws data
				 *  to show or hide drawLabel
				 */
				if (this?.props?.enabled?.drawAnalysis) {
					this.props.getDraws(tabLookUp[this.state.selectedTab]);
				}
			}

			if (
				this.props?.players?.status == 'loaded' &&
				this.props?.powerIndex?.status == 'loaded' &&
				!this.state.playerList &&
				((this.props?.drawData?.status == 'loaded' && this.props?.enabled?.drawAnalysis) ||
					!this.props?.enabled?.drawAnalysis)
			) {
				// load players list for searchbox
				if (this.props?.players?.data?.length > 0 && this.props?.powerIndex?.data?.length > 0) {
					let playerList = [];
					this.dataLoaded = true;

					this.props.players.data.map(player => {
						playerList.push({
							firstName: player.first_name,
							lastName: player.last_name,
							shortName: player.tv_name,
							country: player.country,
							countryName: player.country_long,
							id: player.id,
						});
					});

					/** find each player's event status to display or hide draw analysis label in the row */
					if (this.props.enabled?.drawAnalysis) {
						this.getPlayerEventStatus(this.props.powerIndex.data);
					}

					/** store Power Index for atp or wta as Tournament data only holds one set of data */
					this.setState({
						playerList,
						powerIndexData: {
							[this.state.selectedTab]: this.props?.powerIndex.data,
						},
					});
				}
			}

			if (
				!this.state.sortedList &&
				this.state.powerIndexData?.[this.state.selectedTab] &&
				this.state.playerList
			) {
				logger.log('[PowerIndexLeaderboard] componentDidUpdate updateSortedList initial');
				let list = this.state.powerIndexData[this.state.selectedTab];

				this.updateSortedList(list, true);
			}

			/** sorting is changed */
			if (this.state.primary_sort !== prevState.primary_sort) {
				logger.log('[PowerIndexLeaderboard] componentDidUpdate primary_sort changed');
				this.updateSortedList(this.state.sortedList);
			}

			/** selected Tab is changed */
			if (prevState.selectedTab !== this.state.selectedTab) {
				if (this?.props?.enabled?.drawAnalysis) {
					this.props.getDraws(tabLookUp[this.state.selectedTab]);
				}

				if (this.state.powerIndexData?.[this.state.selectedTab]) {
					let list = this.state.powerIndexData?.[this.state.selectedTab];
					this.updateSortedList(list, true);
				} else {
					this.props.getPowerIndex(tabLookUp[this.state.selectedTab]);
				}
			}

			/** drawData and/or power index data are updated
			 * - check each player's event status for draw analysis display */
			if (
				this.props.enabled?.drawAnalysis &&
				this.props?.drawData?.status == 'loaded' &&
				(this.props?.drawData?.data?.eventId !== prevProps?.drawData?.data?.eventId ||
					this.state?.powerIndexData?.[this.state?.selectedTab] !==
						prevState?.powerIndexData?.[this.state?.selectedTab]) &&
				this.props?.drawData?.data?.eventId == tabLookUp[this.state.selectedTab] &&
				this.state?.powerIndexData?.[this.state?.selectedTab]
			) {
				this.getPlayerEventStatus();
			}

			/** store the selected event power index in state as Tournamet data only holds one event and overwrites it  */
			if (
				this.dataLoaded &&
				this.props?.powerIndex?.data !== prevProps?.powerIndex?.data &&
				!this.state.powerIndexData?.[this.state.selectedTab] &&
				this.props?.powerIndex?.data?.[0]?.playerid?.startsWith(this.state.selectedTab)
			) {
				let newState = {
					...this.state,
					powerIndexData: {
						...this.state.powerIndexData,
						[this.state.selectedTab]: this.props.powerIndex.data,
					},
				};

				logger.log('[PowerIndexLeaderboard] componentDidUpdate - newstate:%o, this: %o', newState, this);
				this.setState(
					{
						powerIndexData: {
							...this.state.powerIndexData,
							[this.state.selectedTab]: this.props.powerIndex.data,
						},
					},
					() => {
						let list = this.props.powerIndex.data;
						this.updateSortedList(list, true);
					}
				);
			}

			// logic if default player on query string
			if (this.isDataReady() && !this.defaultComplete && this.state.defaultPlayer) {
				this.defaultComplete = true;
				const defaultPlayerObj = this.state.playerList.find(player => {
					return player.id == this.state.defaultPlayer;
				});

				logger.log('[PowerIndexLeaderboard] componentDidUpdate defaultPlayer - playerObj:%o', defaultPlayerObj);

				if (defaultPlayerObj) {
					this.onEnter(null, `${defaultPlayerObj.firstName} ${defaultPlayerObj.lastName}`);
				}
			}
		}
	}

	resetSearch() {
		this.setState(
			{
				searchTerm: '',
				defaultPler: false,
			},
			() => {
				this.filterList();
				if (!window.webview) {
					this.props.history.replace('/en_US/powerindex/index.html');
				}
			}
		);
	}

	filterList() {
		let value = this.state.searchTerm;
		let filteredList =
			value !== ''
				? this.state.sortedList.filter((item, index) => {
						return item.fullName.toLowerCase().includes(value.toLowerCase());
				  })
				: [];

		// if user cleared out the search field, reset the list
		if (value !== '') {
			// set the sorted list to what was found in the filter
			this.setState(
				{
					sortedList: filteredList,
					loadMore_disabled: true,
				},
				() => {
					logger.log('[PowerIndexLeaderboard] filterList - filteredList:%o', filteredList);
					if (filteredList.length == 1) {
						this.expandRow(filteredList[0].playerid, true, 'full');
					}
				}
			);
		} else {
			// reset the list
			let list = this.state.powerIndexData?.[this.state.selectedTab];
			this.setState(
				{
					loadMore_disabled: false,
				},
				() => {
					this.updateSortedList(list, true);
				}
			);
		}
	}

	handleHideDiv = () => {
		this.setState(prevState => ({
			hideDiv: !prevState.hideDiv,
		}));

		doMeasurement(values.powerIndexTitle, 'playerSearch', [{ show: this.state.hideDiv }]);
	};

	/***
	 * onEnter:  callback for the search filter box.
	 */
	onEnter(evt, searchTerm) {
		logger.log('[PowerIndexLeaderboard] onEnter - evt:%o, searchTerm:%o', evt, searchTerm);

		let value = evt ? evt.target.value.trim() : searchTerm;

		this.setState(
			{
				searchTerm: value,
				defaultPlayer: null,
			},
			() => {
				this.filterList();
			}
		);
	}

	/**
	 * tabSelect:  callback for the ladies/gents tabs
	 */
	tabSelect(which) {
		if (this.state.selectedTab !== which) {
			this.setState({
				selectedTab: which,
				startIndex: 0,
				endIndex: 31,
				loadMore_disabled: false,
				primary_sort: 'wpi_rank',
				secondary_sort: null,
				expand_full: {},
				searchTerm: '',
			});
		}

		let eventType = which === 'wta' ? "Women's Singles" : "Men's Singles";
		// logger.log('[PowerIndexLeaderboard] tabSelect - which:%o, eventType:%o', which, eventType);
		doMeasurement(values.powerIndexTitle, [eventType]);
	}

	/**
	 *
	 *  order:
	 *  favorable to neutral to difficult
	 *
	 * 	in case some players may not have Draws Analysis data,
	 *  set the value larger than other label to push them down
	 *
	 *   vvv, www, and xxx <--- out players in order as favoroble, neutral and difficult
	 */
	getDrawAnalysisSortingOrder = (label, pstatus) => {
		switch (label) {
			case 'favorable':
				if (pstatus == 'out') {
					return 'vvv';
				} else {
					return 'aaa';
				}
			case 'neutral':
				if (pstatus == 'out') {
					return 'www';
				} else {
					return 'bbb';
				}
			case 'difficult':
				if (pstatus == 'out') {
					return 'xxx';
				} else {
					return 'ccc';
				}
			default:
				return 'zzz';
		}
	};

	/**
	 * updateSortedList:  does the actual sort on the power index list and also adds additional player information
	 * if it is the intial sort.
	 * @param {*} list
	 * @param {*} initial
	 */
	updateSortedList(list, initial = false) {
		let sortedList = Object.assign([], list);
		let newSortedList = [];

		if (!initial) {
			if (this.state.primary_sort && this.state.secondary_sort) {
				if (this.state?.primary_sort == 'drawAnalysis') {
					sortedList.sort((a, b) => {
						let num1, num2, pid1, pid2, level1, level2, p1status, p2status;

						pid1 = a?.playerid;
						pid2 = b?.playerid;

						num1 = a[this.state.primary_sort]?.['drawLabel'];
						num2 = b[this.state.primary_sort]?.['drawLabel'];

						/** player status to find if the player is in or out */
						p1status = this.state?.playerEventStatus?.[pid1];
						p2status = this.state?.playerEventStatus?.[pid2];

						/** order:
						 *  favorable to neutral to difficult
						 *  drawLevel smaller to larger within the same difficulty
						 *  if the player is out, push her/him down
						 */
						num1 = this.getDrawAnalysisSortingOrder(num1, p1status);
						num2 = this.getDrawAnalysisSortingOrder(num2, p2status);

						level1 = p1status === 'out' ? 500 : a[this.state.secondary_sort]?.['drawLevel'];
						level2 = p2status === 'out' ? 500 : b[this.state.secondary_sort]?.['drawLevel'];

						if (num1 === num2) {
							return level1 < level2 ? -1 : 1;
						} else {
							return num1 < num2 ? -1 : 1;
						}
					});
				} else {
					sortedList.sort((a, b) =>
						a[this.state.primary_sort] > b[this.state.primary_sort]
							? 1
							: a[this.state.primary_sort] === b[this.state.primary_sort]
							? a[this.state.secondary_sort] > b[this.state.secondary_sort]
								? 1
								: -1
							: -1
					);
				}
			} else if (this.state.primary_sort) {
				sortedList.sort((a, b) => {
					let val1 = a[this.state.primary_sort];
					let val2 = b[this.state.primary_sort];

					/** if the country name is not listed, push the row towards bottom */
					if (this.state?.primary_sort == 'countryName') {
						val1 = val1 == '' ? 'zzz' : val1;
						val2 = val2 == '' ? 'zzz' : val2;
					}

					return val1 > val2 ? 1 : -1;
				});
			}
		} else {
			// add player data to sortedList
			sortedList.map((pi_player, index) => {
				let playerData = this.state.playerList.find(player => {
					return player.id == pi_player.playerid;
				});

				//logger.log('[PowerIndexLeaderboard] componentDidUpdate playerData: %o, index:%o', playerData, index);

				if (playerData) {
					sortedList[index]['firstName'] = playerData.firstName;
					sortedList[index]['lastName'] = playerData.lastName;
					sortedList[index]['fullName'] = playerData.firstName + ' ' + playerData.lastName;
					sortedList[index]['shortName'] = playerData.shortName;
					sortedList[index]['countryName'] = playerData.countryName;
					sortedList[index]['country'] = playerData.country;
					//logger.log('[PowerIndexLeaderboard] componentDidUpdate player data added:%o', newList[index]);

					newSortedList.push(sortedList[index]);
				}
			});
		}
		this.setState(
			{
				sortedList: initial ? newSortedList : sortedList,
				playersAdded: true,
				searchTerm: '',
			},
			() => {
				logger.log(
					'[PowerIndexLeaderboard] updateSortedList - sortedList:%o, initial%o',
					this.state.sortedList,
					initial
				);
			}
		);
	}

	/**
	 * sortData:  callback when user clicks a column to sort
	 * @param {*} which
	 */
	sortData(which) {
		logger.log('[PowerIndexLeaderboard] sortData - which:%o', which);
		let primary_sort;
		let secondary_sort;

		switch (which) {
			case 'powerrank':
				primary_sort = 'wpi_rank';
				secondary_sort = null;
				break;
			case 'tourrank':
				primary_sort = 'tour_rank';
				secondary_sort = null;
				break;
			case 'playername':
				primary_sort = 'lastName';
				secondary_sort = 'firstName';
				break;
			case 'country':
				primary_sort = 'countryName';
				secondary_sort = null;
				break;
			case 'aidraw':
				primary_sort = 'drawAnalysis'; // target drawLabel in updateSortedList()
				secondary_sort = 'drawAnalysis'; // target drawLevel in updateSortedList
				break;
			default:
				primary_sort = 'wpi_rank';
				secondary_sort = null;
				break;
		}

		this.setState({
			primary_sort,
			secondary_sort,
		});
	}

	/**
	 * getRowData:  gets the data to be passed to the table row component
	 * @param {*} data
	 * @returns
	 */
	getRowData(data) {
		let player = this.state.playerList.find(p => {
			return p.id == data.playerid;
		});
		//logger.log('[PowerIndexLeaderboard] getRowData - data:%o, player:%o', data, player);

		return {
			type: 'row',
			tourrank: data.tour_rank,
			rank: data.wpi_rank,
			movement:
				this.state.dataListType == 'current'
					? data.wpi_rank_change
						? data.wpi_rank_change
						: 0
					: data.movement_event_to_date
					? data.movement_event_to_date
					: 0,
			flagPath: this.props.otherData.flagImagePathSmall.replace('<code>', op.get(player, 'country', '')),
			countryCode: op.get(data, 'country', ''),
			countryName: op.get(data, 'countryName', ''),
			shortName: op.get(data, 'shortName', ' - '),
			playerName: op.get(data, 'fullName', ' - '),
			playerId: data.playerid,
			drawAnalysis: data?.drawAnalysis,
			playerEventStatus: this.state?.playerEventStatus?.[data.playerid],
			enabled: this.props?.enabled?.drawAnalysis,
		};
	}

	/**
	 * getContentData:  gets the data to be passed to the table data component. (shown when user clicks a row)
	 * @param {*} piData
	 * @param {*} trendData
	 * @param {*} playerDrawAnalysisData
	 * @returns
	 */
	getContentData(piData, trendData, playerDrawAnalysisData) {
		//logger.log('[PowerIndexLeaderboard] getContentData - piData:%o', piData);
		const playerMatches = op.get(this.props, `playerMatchesData.${piData.playerid}.result.matches`, []);

		let determiningFactors = [
			{ type: 'single', title: 'Recent Performance', value: piData.performance_score },
			{ type: 'single', title: 'Media Volume', value: piData.volume_score },
			{ type: 'double', title: 'Sentiment', value: piData.sentiment },
		];

		let labels = trendData.trend.map(data => {
			return moment(data.date).format('M/D');
		});
		//logger.log('[PowerIndexLeaderboard] getContentData - labels:%o', labels);

		let wpi_series = trendData.trend.map(data => {
			return -data.wpi_rank;
		});
		let tour_series = trendData.trend.map(data => {
			return -data.tour_rank;
		});

		logger.log('[PowerIndexLeaderboard] getContentData - playerMatches:%o', playerMatches);

		const getWinIndicator = () => {
			if (playerMatches.length > 0) {
				const match = playerMatches[0];
				if (match[`team${match.winner}`].idA == trendData.bio.player_id) {
					return 'W';
				} else {
					return 'L';
				}
			}
		};
		const matchWon = index => {
			if (matchesPlayed.length > 0) {
				const match = matchesPlayed[index];
				if (match[`team${match.winner}`].idA == trendData.bio.player_id) {
					return true;
				} else {
					return false;
				}
			}
		};

		const matchesPlayed = playerMatches.filter((match, index) => {
			return match.eventCode == 'MS' || match.eventCode == 'WS' ? match.epoch : null;
		});

		let rankOverTime = {
			graphData: {
				labels,
				series: [
					{
						name: 'tour-series',
						className: 'ct-series-a tour-series',
						data: tour_series,
					},
					{
						name: 'powerrank-series',
						className: 'ct-series-b  powerrank-series',
						data: wpi_series,
					},
				],
			},
			matchesPlayed: matchesPlayed.map((match, index) => {
				return {
					matchDate: moment(match.epoch)
						.subtract(5, 'hours')
						.tz('America/New_York')
						.format('M/D'),
					won: matchWon(index),
				};
			}),
			lastMatchPlayed:
				playerMatches.length > 0
					? moment(playerMatches[0].epoch)
							.subtract(5, 'hours')
							.tz('America/New_York')
							.format('M/D')
					: '',
			winIndicator: getWinIndicator(),
			event: this.state.selectedTab,
		};

		logger.log('[PowerIndexLeaderboard] getContentData - contentData:%o', { determiningFactors, rankOverTime });

		return {
			determiningFactors,
			rankOverTime,
			powerrank: piData.wpi_rank,
			playerImageData: { 'player-image': this.props.playerImagePath.replace('<playerid>', piData.playerid) },
			playerStatusData: op.get(this.props, `playerStatusData.${piData.playerid}.result`, false),
			matchInsightsMatches: op.get(this.props, `matchInsightsMatchesData.result`, false),
			playerDrawAnalysisData,
			disablePowerIndexTrending: this.props?.otherData?.disablePowerIndexTrending,
		};
	}

	/**
	 * loadMoreData:  handler for the load more button
	 * @param {*} listLength
	 */
	loadMoreData(listLength) {
		const endIndex = op.get(this.state, 'endIndex');
		const newEnd = endIndex + this.increment < listLength ? endIndex + this.increment : listLength - 1;
		logger.log(
			'[PowerIndexLeaderboard] loadMoreData - listLength:%o, endIndex:%o, newEndIndex:%o',
			listLength,
			endIndex,
			newEnd
		);
		if (!this.state.loadMore_disabled) {
			this.loadMoreCounter++;

			this.setState({
				endIndex: newEnd,
				loadMore_disabled: newEnd == listLength - 1,
			});
			doMeasurement(values.powerIndexTitle, 'Load More', [this.loadMoreCounter]);
		}
	}

	/**
	 * expandRow:  callback for when user expands a row
	 * @param {*} playerId
	 * @param {*} toggle
	 * @param {*} type
	 */
	expandRow(playerId, toggle, type) {
		logger.log('[PowerIndexLeaderboard] expandRow - playerId:%o, toggle:%o, type:%o', playerId, toggle, type);

		const curState = Object.assign({}, this.state[`expand_${type}`]);

		this.setState(
			{
				//expand_fav: {},
				expand_full: {},
				[`expand_${type}`]: {
					//...curState,
					[playerId]: toggle,
				},
			},
			() => {
				// if toggle = true get player matches data
				if (toggle) {
					/** draw analysis data for the player */
					this.props.getDrawAnalysis(tabLookUp[this.state.selectedTab], playerId);

					this.props.getPlayerMatches(playerId);

					this.props.getPlayerStatus(playerId);

					/** get the available match insights */
					this.props.getMatchInsightsAvailable();

					/** save the scroll position so when the help overlay
					 *  is closed, the user stays at the same position
					 */
					let scrollTop =
						window.pageYOffset !== undefined
							? window.pageYOffset
							: (document.documentElement || document.body.parentNode || document.body).scrollTop;
					this.props.drawAnalysisHelpUpdate({ defaultTop: scrollTop });
				} else {
					/** clear position */
					this.props.drawAnalysisHelpUpdate({ defaultTop: 0 });
				}
			}
		);
	}

	/** if the power index data is passed, use it */
	getPlayerEventStatus = data => {
		let drawData = this.props?.drawData;
		let piData = data ? data : this.state?.powerIndexData?.[this.state?.selectedTab];
		let latestMatchStatus = {};
		/** if there are drawData for the selected event, filter the match data */
		if (
			drawData?.status == 'loaded' &&
			drawData?.data?.eventId == tabLookUp[this.state?.selectedTab] && // make sure drawsData is for the selected Tab's data
			piData?.length > 0
		) {
			piData.forEach((player, index) => {
				let piPlayer = player.playerid;
				let foundMatches = [];
				let curMatch = {};
				let playerEventStatus = 'in';

				foundMatches = drawData?.data?.matches.filter((match, i) => {
					return match.team1.idA == piPlayer || match.team2.idA == piPlayer;
				});

				if (foundMatches.length > 0) {
					curMatch = foundMatches[foundMatches.length - 1];

					if (curMatch.winner && curMatch.roundCode == 'F') {
						/** if the final round, find if the player won or out */
						let teamObj = `team${curMatch.winner}`;
						playerEventStatus = curMatch[teamObj]?.idA == piPlayer ? 'won' : 'out';
					} else if (isPostMatch(curMatch)) {
						/** the latest match is over - is the player In or out */
						let teamObj = `team${curMatch.winner}`;
						playerEventStatus = curMatch[teamObj]?.idA == piPlayer ? 'in' : 'out';
					}
				}

				latestMatchStatus[piPlayer] = playerEventStatus;
			});

			this.setState({
				playerEventStatus: latestMatchStatus,
			});
		}
		logger.log('[PowerIndexLeaderboard] - getPlayerEventStatus - latestMatchStatus:%o', latestMatchStatus);
	};

	/**
	 * isDataReady:  determines if the data is ready for rendering
	 * @returns
	 */
	isDataReady() {
		return (
			this.state.playerList &&
			this.state.playersAdded &&
			this.state.powerIndexData?.[this.state.selectedTab] &&
			(op.get(this.props, 'powerIndexTrendingData.result') ? true : false)
		);
	}

	/**
	 * renderTableList: formats the jsx for rendering the table list
	 * @param {*} dataList
	 * @param {*} expandType
	 * @returns
	 */
	renderTableList(dataList, expandType) {
		return dataList.map((row, index) => {
			let piData = this.getRowData(row);
			let selectedPlayerId = row.playerid;
			let trendData = this.props.powerIndexTrendingData?.result?.[selectedPlayerId];

			/** make sure drawAnalysisData in Tounament Data is for the selected player */
			let playerDrawAnalysisData =
				this.props?.drawAnalysisData?.data?.playerId == selectedPlayerId
					? this.props?.drawAnalysisData?.data
					: null;

			let expand = op.get(this.state, `expand_${expandType}.${row.playerid}`, false) ? true : false;
			let rowData = {
				...piData,
				expand,
				expandType,
				expandCallback: this.expandRow,
				countriesLookUp: this.props.countriesLookUp,
				powerIndexMovementArrows: this.props?.enabled?.powerIndexMovementArrows,
			};

			let enabled = this.props?.enabled;

			let contentData =
				enabled &&
				row &&
				expand &&
				op.get(this.props, `playerMatchesData.${row.playerid}.result.matches`, false) &&
				op.get(this.props, `playerStatusData.${row.playerid}.result.matches`, false)
					? this.getContentData(row, trendData, playerDrawAnalysisData)
					: false;

			return index <= this.state.endIndex ? (
				<LeaderboardRow key={row.playerid} data={rowData}>
					{contentData && expand ? (
						<ErrorBoundary message="Additional Power Index data is unavailable for this player">
							<LeaderboardData data={contentData} enabled={enabled} />
						</ErrorBoundary>
					) : null}
				</LeaderboardRow>
			) : null;
		});
	}

	/**
	 *  If no one has drawAnalysis label, hide the header text, AI Draw Analysis
	 * @param {*} dataList
	 * @returns
	 */
	hideAiDrawHeader = dataList => {
		let hideAidrawHdr = false;
		if (dataList?.length > 0) {
			let dataAry = [...dataList];
			let tmp = [];

			tmp = dataAry.filter(item => {
				return item?.drawAnalysis?.drawLabel !== '';
			});

			hideAidrawHdr = tmp.length == 0;
		}

		/** if everyone is done playing the event, hide the draw analysis header */
		if (this.state?.playerEventStatus && this?.props?.enabled?.drawAnalysis) {
			for (let key in this.state?.playerEventStatus) {
				if (this.state?.playerEventStatus[key] == 'won') {
					return (hideAidrawHdr = true);
				}
			}
		}

		return hideAidrawHdr;
	};

	listSelect(which) {
		logger.log('[PowerIndexLeaderboard] listSelect which:%o', which);
		this.setState({
			dataListType: which,
		});
		doMeasurement(values.powerIndexTitle, 'movementType', [which]);
	}

	/**
	 * renderPageContent:  renders the overall page structure.  calls other render type functions.
	 * @param {*} header_attributes
	 * @returns
	 */
	renderPageContent(header_attributes) {
		//logger.log('[PowerIndexLeaderboard] renderPageContent - this.state.appFavs:%o', this.state.appFavs);

		let headerDate = op.get(this.props, `tournament.data.powerIndex.publicationTimestamp`);
		let dataList = op.get(this.state, `sortedList`, []);

		let searchData =
			this.state.defaultPlayer && this.state.playerList
				? this.state.playerList.find(player => {
						return player.id == this.state.defaultPlayer;
				  })
				: null;

		let playerSearchList = this.state.playerList
			? this.state.playerList.filter(player => {
					return player.id.indexOf(this.state.selectedTab) !== -1;
			  })
			: [];

		if (this.props.stubs && this.props.stubs?.powerindex?.stub === 'stub') {
			return (
				<div id="powerindex-leaderboard" className="content-main">
					<StubBox attributes={{ message: this.props.stubs.powerindex.text }} />
				</div>
			);
		} else if (this.props.stubs) {
			// make sure stub props are available to avoid the flashing table header when it is actually Stub
			return (
				<div id="powerindex-leaderboard" className="content-main">
					{this.state.error ? (
						<StubBox attributes={{ message: `${values.powerIndexTitle} are unavailable at this time.` }} />
					) : this.props?.powerIndex?.status == 'loaded' ? (
						<ErrorBoundary
							message={`${values.powerIndexTitle} are unavailable at this time.`}
							showStub={true}>
							<section className="pi-header">
								<DataTabs
									data={{
										className: '',
										callback: this.tabSelect,
										tabsList: [
											{
												selected: this.state.selectedTab == 'wta',
												id: 'wta',
												label: "Women's Singles",
											},
											{
												selected: this.state.selectedTab == 'atp',
												id: 'atp',
												label: "Men's Singles",
											},
										],
									}}
								/>
								<DataTabs
									data={{
										callback: this.listSelect,
										className: 'secondary',
										tabsList: [
											{
												selected: this.state.dataListType == 'current',
												id: 'current',
												label: "Yesterday's Movement",
											},
											{
												selected: this.state.dataListType == 'event',
												id: 'event',
												label: 'Movement Event to Date',
											},
										],
									}}
								/>
							</section>
							<section>
								<div className="column-layout">
									<div className="helper-wrapper middle">
										<div className="helper-text">
											{/* <div className="update-date short">
												Updated:{' '}
												<Time epoch_ms={headerDate} format="MM/DD/yyyy" options="upper" />
											</div> */}
											<div className="update-date long">
												Updated:{' '}
												<Time
													epoch_ms={headerDate}
													format="MM/DD/yyyy HH:mm z"
													options="upper"
												/>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section>
								<div className="column-layout">
									<div className="leaderboard-table">
										<LeaderboardRow
											data={{
												type: 'header',
												sortCallback: this.sortData,
												selectedTab: this.state.selectedTab,
												hideAidrawHdr: this.hideAiDrawHeader(dataList),
												enabled: this?.props?.enabled?.drawAnalysis,
												playerEventStatus: this.state?.playerEventStatus
													? Object.values(this.state?.playerEventStatus)?.[0]
													: null,
											}}
										/>

										<LeaderboardRow data={{ type: 'subheader' }}>Full Leaderboard</LeaderboardRow>
										{this.isDataReady() ? this.renderTableList(dataList, 'full') : null}
									</div>
									<div
										className={`button solid ${this.state.loadMore_disabled ? 'disabled' : 'blue'}`}
										onClick={
											!this.state.loadMore_disabled
												? () => this.loadMoreData(dataList.length)
												: null
										}>
										Load More
									</div>
									{this.props?.commonData?.innovationContent?.result?.powerIndex && (
										<div className="helper-wrapper">
											<div className="helper-title">{values.powerIndexTitle}</div>
											<div className="helper-text">
												{
													this.props.commonData.innovationContent.result.powerIndex?.bottom
														?.text
												}
											</div>
											<div className="helper-action">
												<EventsLink
													to="https://www.ibm.com/sports/usopen/"
													external={true}
													style="solid blue">
													Learn more
												</EventsLink>
											</div>
										</div>
									)}
								</div>
							</section>
						</ErrorBoundary>
					) : (
						<LoadingIndicator />
					)}
				</div>
			);
		}
	}

	render() {
		//logger.log('[PowerIndexLeaderboard] render - window.webviewPlatform:%o', window.webviewPlatform);
		logger.log(
			'[PowerIndexLeaderboard] render - props:%o, this.state:%o, webviewPlatform:%o',
			this.props,
			this.state,
			window.webviewPlatform
		);

		let header_attributes = {
			headerType: 'scores',
			title: values.powerIndexTitle,
			metaTitle: values.powerIndexTitle,
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			helper: 'An AI-powered measurement of player momentum.',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'http://www.ibm.com/sports/usopen',
				title: 'Presented by',
				name: 'IBM',
				imgClass: '',
				imgSrc: '/assets/images/logos/ibm_logo_black.png',
				tagline: 'Presented by',
			},
			page_header: values.powerIndexTitle,
			title: values.powerIndexTitle,
		};
		if (this.props.status == 'loaded') {
			return (
				<>
					{!window.webviewPlatform ? (
						<Template
							id="powerindex"
							header={header_attributes}
							subHeader={subheader_attributes}
							hideAd={true}>
							{/* render stub */}
							{this.props?.stubs?.powerindex?.stub == 'stub' ? (
								<section className="wrapper">
									<StubBox
										attributes={{
											title: header_attributes.title,
											message: op.get(
												this.props,
												'stubs.powerindex.text',
												`${values.powerIndexTitle} coming soon.`
											),
											basePicPath: op.get(this.props, 'otherData.basePicPath', ''),
										}}
									/>
								</section>
							) : (
								<>
									{/* render content */}
									<h1 className="header">{values.powerIndexTitle}</h1>

									<section id="powerindex-leaderboard-wrapper" className="wrapper">
										{this.props?.commonData?.innovationContent?.result?.powerIndex && (
											<div className="helper-wrapper top">
												<div className="helper-text">
													{
														this.props?.commonData.innovationContent.result.powerIndex?.top
															?.text
													}
												</div>
											</div>
										)}
										{this.renderPageContent(header_attributes)}
									</section>
								</>
							)}
						</Template>
					) : (
						// render webview
						<>
							<div id="powerindex-leaderboard-wrapper">
								{this.props?.commonData?.innovationContent?.result?.powerIndex && (
									<div className="helper-wrapper top">
										<div className="helper-text">
											{this.props?.commonData.innovationContent.result.powerIndex?.top?.text}
										</div>
									</div>
								)}
								{this.renderPageContent(header_attributes)}
							</div>
						</>
					)}
				</>
			);
		} else {
			return (
				<>
					{!window.webviewPlatform ? (
						<Template
							id="powerindex"
							header={header_attributes}
							subHeader={subheader_attributes}
							hideAd={true}>
							<section className="wrapper">
								<h1 className="header">{values.powerIndexTitle}</h1>
								<LoadingIndicator />
							</section>
						</Template>
					) : (
						<section className="wrapper">
							<h1 className="header">{values.powerIndexTitle}</h1>
							<LoadingIndicator />
						</section>
					)}
				</>
			);
		}
	}
}
