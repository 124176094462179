import { adobePageview, adobeExternalLink, adobeMeasureApp } from 'appdir/lib/tracking';

export default {
	/**
	 * handle general page view calls
	 */
	a_pageView: (data, mod = '') => dispatch => {
		logger.log('[Analytics] deps.actions.pageView - data:%o mod:%o', data, mod);
		let copyData = { ...data };

		if (mod.length > 0) {
			copyData.page_name = copyData.page_name + ':' + mod;
		}

		adobePageview(copyData);
	},

	/**
	 * handle page view calls for news/archive index pages
	 */
	a_pageViewNews: data => dispatch => {
		logger.log('[Analytics] deps.actions.pageViewNews - data:%o', data);

		let props = {};
		let vals = {};
		let year = data.year != 'current' ? ':' + data.year : '';

		switch (data.type) {
			case 'all':
				vals = {
					page_section: 'News' + year,
					page_name: 'All',
				};
				adobePageview(vals, props);
				break;

			case 'news':
				vals = {
					page_section: 'News' + year,
					page_name: 'News',
				};
				adobePageview(vals, props);
				break;

			case 'gallery':
				vals = {
					page_section: 'News' + year,
					page_name: 'Photos',
				};
				adobePageview(vals, props);
				break;

			case 'video':
				vals = {
					page_section: 'News' + year,
					page_name: 'Videos',
				};
				adobePageview(vals, props);
				break;

			case 'press release':
				vals = {
					page_section: 'News' + year,
					page_name: 'Press Releases',
				};
				adobePageview(vals, props);
				break;

			case 'interview':
				vals = {
					page_section: 'News' + year,
					page_name: 'Interviews',
				};
				adobePageview(vals, props);
				break;

			case 'favorite':
				vals = {
					page_section: 'News' + year,
					page_name: 'Your Players',
				};
				adobePageview(vals, props);
				break;

			default:
				break;
		}
	},

	/**
	 * handle page view calls for content pages
	 */
	a_pageViewContent: data => dispatch => {
		logger.log('[Analytics] deps.actions.pageViewContent - data:%o', data);

		let props = {};
		let vals = {};

		// let pageNameVal = "";
		// let qstringVal = "";

		// qstringVal = document.getElementsByTagName("qstring");
		// if (pageNameVal.length > 0) {
		// var page_name = pageNameVal[0].getAttribute("content");

		// if (qstringVal.length > 0) {
		// 	for (var i = 0; i < qstringVal.length; i++) {
		// 	if (null === qsParse.get(qstringVal[i].getAttribute("content"))) {
		// 		page_name += ":" + qstringVal[i].getAttribute("default");
		// 	} else {
		// 		page_name += ":" + qsParse.get(qstringVal[i].getAttribute("content"));
		// 	}
		// 	}
		// }
		// }

		// set category data
		if (data.category) {
			switch (data.category) {
				case 'articles':
					vals = {
						page_section: 'News',
						page_name: 'Articles:Detail',
					};
					props = {
						prop4: 'Article',
						prop5: data.title,
					};
					adobePageview(vals, props);
					break;

				case 'about_wimbledon':
				case 'aboutwimbledon':
					vals = {
						page_section: 'About',
						page_name: 'About Wimbledon:' + data.title,
					};
					adobePageview(vals);
					break;

				case 'atoz':
					vals = {
						page_section: 'Visit',
						page_name: 'Visitors A to Z Guide:' + data.title,
					};
					adobePageview(vals);
					break;

				case 'tickets':
					vals = {
						page_section: 'Visit',
						page_name: 'Tickets:' + data.title,
					};
					adobePageview(vals);
					break;

				case 'jobs':
					vals = {
						page_section: 'About',
						page_name: 'Jobs:' + data.title,
					};
					adobePageview(vals);
					break;

				case 'visitandtickets':
				case 'visit_and_tickets':
					vals = {
						page_section: 'Visit',
						page_name: 'Visit & Tickets:' + data.title,
					};
					adobePageview(vals);
					break;

				case 'wimbledonfoundation':
				case 'foundation':
					vals = {
						page_section: 'Foundation',
						page_name: data.title,
					};
					adobePageview(vals);
					break;

				case 'champion':
					vals = {
						page_section: 'Draws Archive',
						page_name: 'Detail:' + data.title,
					};
					adobePageview(vals);
					break;

				default:
					break;
			}
		}

		// set landing data
		if (data.landing) {
			switch (data.landing) {
				case 'jobs':
				case 'media':
				case 'about_wimbledon':
					vals = {
						page_section: 'About',
						page_name: data.title,
					};
					adobePageview(vals);
					break;

				case 'your_visit':
				case 'tickets':
				case 'learning':
				case 'museum_and_tours':
				case 'foodanddrink':
				case 'visit_and_tickets':
					vals = {
						page_section: 'Visit',
						page_name: data.title,
					};
					adobePageview(vals);
					break;

				case 'inpursuitofgreatness':
					vals = {
						page_section: 'News',
						page_name: data.title,
					};
					adobePageview(vals);
					break;

				case 'fast_facts':
					vals = {
						page_section: 'Draws Archive',
						page_name: 'Draws Archive Fast Facts',
					};
					adobePageview(vals);
					break;

				case 'foundation':
					vals = {
						page_section: 'Foundation',
						page_name: 'Foundation',
					};
					adobePageview(vals);
					break;

				default:
					break;
			}
		}
	},

	a_openExternal: data => dispatch => {
		//data: lnk, newWindow, w, h
		if (data.lnk.indexOf('http://' == -1)) {
			switch (data.lnk) {
				case 'ibm':
					lnk = 'http://www.ibm.com/';
					break;
				case 'tix1':
					lnk =
						'https://oss.ticketmaster.com/html/outsider.htmI?CAMEFROM=&GOTO=https%3A%2F%2Foss.ticketmaster.com%2Fhtml%2Frequest.htmI%3Fl%3DEN%26team%3Dusopentennis%26STAGE%3D1%26PROC%3DBUY%26EventName%3D11DEPSIT';
					break;
				default:
					break;
			}
		}

		logger.log('[Analytics] deps.actions.openExternal - data:%o ', data);
		adobeExternalLink(data);
	},

	a_measureApp: () => dispatch => {
		logger.log('[Analytics] deps.actions.measureApp - args:%o ', arguments);
		adobeMeasureApp(arguments);
	},
};
