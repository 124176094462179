/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: InlineImage
 * -----------------------------------------------------------------------------
 */
const InlineImage = props => {
	logger.log('[InlineImage] - props:%o', props);

	let attributes = props.data;
	let type = 'image';
	let titleLine = '';

	//look for error
	if (!attributes) {
		return null;
	}

	if (attributes['images']['image'].length > 1) {
		type = 'multiple';
	}

	if (type == 'image') {
		logger.log('[InlineImage] attributes:%o', attributes);

		let image = attributes['images']['image'];
		//logger.log('[InlineImage] link:%o', attributes['link']);

		if (attributes['link']) {
			return (
				<div className={`photo-inline ${attributes['style']}`}>
					<EventsLink to={attributes['link']}>
						<img src={image['url']} alt={image['title']} />
					</EventsLink>
				</div>
			);
		} else {
			return (
				<div className={`photo-inline ${attributes['style']}`}>
					<img src={image['url']} alt={image['title']} />
				</div>
			);
		}
	} else if (type == 'multiple') {
		let images = attributes['images']['image'];
		return (
			<div>
				{images.map((item, i) => (
					<img src={item.url} alt={item.caption} />
				))}
			</div>
		);
	}
};

export default InlineImage;
