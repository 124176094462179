import ScheduleWebview from './index';
import deps from 'dependencies';

// tournDay will be a field in the params obj.
// route is day<dayNum>.html
export default {
	path: '/webview/:lang(en_US)/scores/schedule/schedule:tournDay.html',
	exact: true,
	component: ScheduleWebview,
	load: params => deps.actions.ScheduleWebview.mount(params),
};
