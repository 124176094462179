/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from 'components/Template';

/**
 * -----------------------------------------------------------------------------
 * React Component: PastChamps
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PastChamps'],
		stubs: state['Config'].stubPages,
		scoringVars: state['Config'].scoring,
		seedGender: state['PlayerFilters'].seedGender,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PastChamps.mount()),
	unmount: data => dispatch(deps.actions.PastChamps.unmount(data)),
	filterGender: gender => dispatch(deps.actions.PlayerFilters.filterGender(gender)),
});

class PastChamps extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedIndex: 0,
			sponsorList: null,
			loadedJson: false,
		};

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Past Champions',
		});
		// logger.log('[PastChamps] constructor - this: %o', this);
	}

	componentDidMount() {
		// logger.log('[PastChamps] componentDidMount');
		if (
			!this.state.sponsorList &&
			!this.state.loadedJson &&
			this.props.pastChampsPath &&
			this.props.scoringVars !== undefined
		) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		// logger.log('[PastChamps] unmounted');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PastChamps] componentDidUpdate - this:%o', this);

		// calculate new data path from path in state and current filters
		if (
			!this.state.loadedJson &&
			!this.isStubbed() &&
			this.props.pastChampsPath &&
			this.props.scoringVars !== undefined
		) {
			this.fetchData();
		}
	}

	isStubbed() {
		return this.props.stubs && this.props.stubs.competitors
			? this.props.stubs.competitors == 'stub'
				? true
				: false
			: false;
	}

	fetchData() {
		logger.log('[PastChamps] fetchData - this:%o', this);

		fetch(this.props.pastChampsPath)
			.then(data => {
				this.setState(prevState => {
					return { ...prevState, sponsorList: data };
				});
			})
			.catch(error => {
				logger.log('[PastChamps] fetchData error loading data: %o', error);
			});

		this.setState(prevState => {
			return { ...prevState, loadedJson: true };
		});
	}

	handleChange = e => {
		logger.log('[PastChamps] handleChange - event:%o', event);

		MeasurementUtils.dispatchMeasurementCall('SelectEvent', {
			filter: { selection: e.target.value },
		});

		this.props.history.push(`${e.target.value}`);
	};

	setSelected(index) {
		this.setState(prevState => {
			return { ...prevState, selectedIndex: index };
		});
	}

	getFootnotes(data) {
		return (
			<div>
				{data.map((item, i) => {
					return <p key={i}>{item}</p>;
				})}
			</div>
		);
	}

	getFactoids(data) {
		return (
			<ul>
				{data.map((item, i) => {
					return <li key={i}>{item}</li>;
				})}
			</ul>
		);
	}

	singlesMainTable(eventdata) {
		logger.log('[PastChamps] singlesMainTable - event: %o', eventdata);
		let singles = Object.assign({}, eventdata);
		return (
			<div className="playoff_table champsSinglesTable">
				<div className="header">
					<div className="playoff_reg_register">Year</div>
					<div className="playoff_mobileCol">
						<div className="playoff_reg_city">Champion</div>
						<div className="playoff_reg_city">Right/Left Handed</div>
						<div className="playoff_reg_city">Runner-Up</div>
						<div className="playoff_reg_venue">Score</div>
					</div>
				</div>
				<div className="header mobile">
					<div className="playoff_reg_register">Year</div>
					<div className="playoff_mobileCol">
						<div className="playoff_reg_date">Champion and Runner-Up</div>
					</div>
				</div>
				{singles.data
					.slice(0)
					.reverse()
					.map((item, i) => {
						return (
							<div className="pl-row" key={i}>
								<div className="playoff_reg_register">{item['year']}</div>
								<div className="playoff_mobileCol">
									<div className="playoff_reg_city">
										<span className="label">Champion:</span>
										{item['champion']}
									</div>
									<div className="playoff_reg_city">
										<span className="label">Rt/Lt Handed:</span>
										{item['hand']}
									</div>
									<div className="playoff_reg_city">
										<span className="label">Runner-Up:</span>
										{item['runnerup']}
									</div>
									<div className="playoff_reg_venue">
										<span className="label">Score:</span>
										{item['score']}
									</div>
								</div>
							</div>
						);
					})}
				<div>
					{singles.footnotes && singles.footnotes.length > 0 ? this.getFootnotes(singles.footnotes) : null}
					{singles.factoids && singles.factoids.length > 0 ? this.getFactoids(singles.factoids) : null}
				</div>
			</div>
		);
	}

	doublesTable(eventdata) {
		logger.log('[PastChamps] doublesTable - event: %o', eventdata);
		let doubles = Object.assign({}, eventdata);
		return (
			<div className="playoff_table champsDoublesTable">
				<div className="header">
					<div className="playoff_reg_register">Year</div>
					<div className="playoff_mobileCol">
						<div className="playoff_reg_city">
							{eventdata.seeds ? `(Seed) ` : null}
							Champion
						</div>
						<div className="playoff_reg_city">
							{eventdata.seeds ? `(Seed) ` : null}
							Runner-Up
						</div>
						<div className="playoff_reg_venue">Score</div>
					</div>
				</div>
				<div className="header mobile">
					<div className="playoff_reg_register">Year</div>
					<div className="playoff_mobileCol">
						<div className="playoff_reg_date">Champion and Runner-Up</div>
					</div>
				</div>

				{doubles.data
					.slice(0)
					.reverse()
					.map((item, i) => {
						return item['score'] ? (
							<div className="pl-row" key={i}>
								<div className="playoff_reg_register">{item['year']}</div>
								<div className="playoff_mobileCol">
									<div className="playoff_reg_city">
										<span className="label">Champion:</span> {item['champion'][0]}
										<br />
										{item['champion'][1]}
									</div>
									<div className="playoff_reg_city">
										<span className="label">Runner-Up:</span> {item['runnerup'][0]}
										<br />
										{item['runnerup'][1]}
									</div>
									<div className="playoff_reg_venue">
										<span className="label">Score:</span>
										{item['score']}
									</div>
								</div>
							</div>
						) : (
							<div className="pl-row" key={i}>
								<div className="playoff_reg_register">{item['year']}</div>
								<div className="playoff_mobileCol">
									<div className="messagetext">
										<strong>{item['champion']}</strong>
									</div>
								</div>
							</div>
						);
					})}
				<div>
					{doubles.footnotes && doubles.footnotes.length > 0 ? this.getFootnotes(doubles.footnotes) : null}
					{doubles.factoids && doubles.factoids.length > 0 ? this.getFactoids(doubles.factoids) : null}
				</div>
			</div>
		);
	}

	singlesTable(eventdata) {
		logger.log('[PastChamps] singlesTable - event: %o', eventdata);
		let singles = Object.assign({}, eventdata);
		return (
			<div className="playoff_table champsOtherSingles">
				<div className="header">
					<div className="playoff_reg_register">Year</div>
					<div className="playoff_mobileCol">
						<div className="playoff_reg_city">
							{eventdata.seeds ? `(Seed) ` : null}
							Champion
						</div>
						<div className="playoff_reg_city">
							{eventdata.seeds ? `(Seed) ` : null}
							Runner-Up
						</div>
						<div className="playoff_reg_venue">Score</div>
					</div>
				</div>
				<div className="header mobile">
					<div className="playoff_reg_register">Year</div>
					<div className="playoff_mobileCol">
						<div className="playoff_reg_date">Champion and Runner-Up</div>
					</div>
				</div>

				{singles.data
					.slice(0)
					.reverse()
					.map((item, i) => {
						return item['score'] ? (
							<div className="pl-row" key={i}>
								<div className="playoff_reg_register">{item['year']}</div>
								<div className="playoff_mobileCol">
									<div className="playoff_reg_city">
										<span className="label">Champion:</span>
										{item['champion']}
									</div>
									<div className="playoff_reg_city">
										<span className="label">Runner-Up:</span>
										{item['runnerup']}
									</div>
									<div className="playoff_reg_venue">
										<span className="label">Score:</span>
										{item['score']}
									</div>
								</div>
							</div>
						) : (
							<div className="pl-row" key={i}>
								<div className="playoff_reg_register">{item['year']}</div>
								<div className="playoff_mobileCol">
									<div className="messagetext">
										<strong>{item['champion']}</strong>
									</div>
								</div>
							</div>
						);
					})}
				<div>
					{singles.footnotes && singles.footnotes.length > 0 ? this.getFootnotes(singles.footnotes) : null}
					{singles.factoids && singles.factoids.length > 0 ? this.getFactoids(singles.factoids) : null}
				</div>
			</div>
		);
	}

	renderChampsTables(event) {
		let eventData = Object.assign({}, this.state.sponsorList[event]);
		logger.log('[PastChamps] renderChampsTables - event: %o : eventData: %o', event, eventData);
		switch (eventData.type) {
			case 'singles-main':
				return this.singlesMainTable(eventData);
			case 'doubles':
				return this.doublesTable(eventData);
			default:
				return this.singlesTable(eventData);
		}
	}

	renderJuniorsTables() {
		let jrBoysData = Object.assign({}, this.state.sponsorList['jrb']);
		let jrGirlsData = Object.assign({}, this.state.sponsorList['jrg']);
		return (
			<div>
				<h2 className="champs-sub-header">
					{jrBoysData.title} {jrBoysData.data[0].year} - {jrBoysData.data[jrBoysData.data.length - 1].year}
				</h2>
				{this.singlesTable(jrBoysData)}
				<h2 className="champs-sub-header">
					{jrGirlsData.title} {jrGirlsData.data[0].year} -{' '}
					{jrGirlsData.data[jrGirlsData.data.length - 1].year}
				</h2>
				{this.singlesTable(jrGirlsData)}
			</div>
		);
	}

	render() {
		logger.log('[PastChamps] render - this:%o', this);

		let header_propsData = {
			headerType: 'history',
			title: 'List of Past US Open Champions',
			metaTitle: 'List of Past US Open Champions',
			metaDescription:
				"Discover the US Open Tennis Championship winners. A list of men's, women's, doubles, juniors and wheelchair US Open champions",
			canonicalLink: this.props.event ? `https://www.usopen.org/en_US/visit/history/${this.props.event}champs.html` 
							: `https://www.usopen.org/en_US/visit/history/mschamps.html`,
		};	

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'Home',
				},
				{
					link: '/en_US/visit/history.html',
					title: 'History',
				},
			],
			title: 'List of Past US Open Champions',
		};

		if (!this.props.stubs) {
			return null;
		}

		if (this.isStubbed()) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<div className="content-main">
						<StubBox
							attributes={{
								title: header_propsData.title,
								message: this.props.stubs.competitors.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</div>
				</Template>
			);
		} else {
			if (this.state.loadedJson) {
				if (this.state.sponsorList) {
					let eventList = this.state.sponsorList;
					logger.log('[PastChamps] render - event:%o', this.props.event);
					let page_header =
						this.props.event === 'jr'
						? `Junior Championships`
						: `${eventList[this.props.event].title} ${eventList[this.props.event].data[0].year} - ${eventList[this.props.event].data[eventList[this.props.event].data.length - 1].year}`;
						subheader_attributes['page_header'] = page_header;					
					logger.log('[PastChamps] render - event:%o', this.props.event);
					return (
						<Template header={header_propsData} subHeader={subheader_attributes}>
							<h1 className="header">
								{this.props.event === 'jr'
									? `Junior Championships`
									: `${eventList[this.props.event].title} ${
											eventList[this.props.event].data[0].year
									  } - ${
											eventList[this.props.event].data[
												eventList[this.props.event].data.length - 1
											].year
									  }`}
							</h1>
							<section className="wrapper white-bg">
								<div className="general_content page">
									<div className="siteDetailContainer">
										<div className="siteDetail default">
											<p style={{ textAlign: 'center' }}>
												Dating back to 1881 until today check out the past champions of the US
												Open Tennis Championships. See scores and opponents in each year's US
												Open finals matchups. Learn about men's, women's, doubles, juniors and
												wheelchair US Open champions by clicking on the drop-down menu below.
											</p>
										</div>
									</div>
								</div>

								<section className="champs-select-menu">
									<select
										name="dropdown"
										size="1"
										id="select-anchor"
										className="champs-select-menu-dropdown"
										onChange={this.handleChange}>
										<option value="#">Select an Event</option>
										{this.props.pastChampionsEvents.map((item, i) => {
											return (
												<option key={i} value={`/en_US/visit/history/${item.event}champs.html`}>
													{item.title}
												</option>
											);
										})}
										<option value="/en_US/visit/grand_slam_alltime_champions.html">
											Grand Slam All-Time Champions
										</option>
									</select>
								</section>
								{this.props.event === 'jr'
									? this.renderJuniorsTables()
									: this.renderChampsTables(this.props.event)}
							</section>
						</Template>
					);
				} else {
					return (
						<Template header={header_propsData} subHeader={subheader_attributes}>
							<h1 className="header">List of Past US Open Champions</h1>
							<section className="wrapper white-bg">
								<div className="general_content page">
									<div className="siteDetailContainer">
										<div className="siteDetail default">
											<p style={{ textAlign: 'center' }}>
												Dating back to 1881 until today check out the past champions of the US
												Open Tennis Championships. See scores and opponents in each year's US
												Open finals matchups. Learn about men's, women's, doubles, juniors and
												wheelchair US Open champions by clicking on the drop-down menu below.
											</p>
										</div>
									</div>
								</div>

								<section className="champs-select-menu" />
								<div className="playoff_table">
									<div className="header">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="header mobile" />
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
										</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
									<div className="pl-row">
										<div className="playoff_reg_register">&nbsp;</div>
										<div className="playoff_mobileCol">&nbsp;</div>
									</div>
								</div>
							</section>
						</Template>
					);
				}
			} else {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes}>
						<h1 className="header">List of Past US Open Champions</h1>
						<div className="content-main">
							<LoadingIndicator />
						</div>
					</Template>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PastChamps);
