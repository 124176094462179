/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import op from 'object-path';
import Time from 'appdir/components/common-ui/Time';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import MIInfoIcon from './MIInfoIcon';
import JSXParser from 'react-jsx-parser';
import EventsLink from 'appdir/components/general/EventsLink';
import { doMeasurement } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: MIHeaderContents
 *
 * This is the components that shows text on top of the header image
 * on Match Insights page and IBM Power Index LB
 *
 * pass power-rankings as type in props for Power Index. default is MI
 * props.data.type == power-rankings
 *
 *  props = {
 *             data.type,
 *             data.headerTitle,
 *             data.headerSubTitle,
 *             data.headerDate,
 *           }
 *  type
 * -----------------------------------------------------------------------------
 */
const MIHeaderContents = props => {
	logger.log('[MIHeaderContents] props: %o', props);

	let { data } = props;
	let type = op.get(data, 'type', '');
	let subtitle = null;

	if (op.get(data, 'windowSize', false) == 'mobile') {
		subtitle = `${data.event} ${String.fromCharCode(8211)} ${data.round}<br/>${data.court}`;
		// <br/>`;
	} else {
		subtitle = `${data.event} ${String.fromCharCode(8211)} ${
			data.round
		} <span className="subtitle-divider">|</span> ${data.court}`;
		//  <span className="subtitle-divider">|</span> `;
	}

	const handleShare = e => {
		if (window.webviewPlatform) {
			let url = data.url;

			/** clean up the URL so it sharers the correct www URL and not webview URL */
			if (url.indexOf('/webview') > -1) {
				url = url.replace('/webview', '');
			}

			window.webviewPlatform == 'ios'
				? window.webkit.messageHandlers.share.postMessage(url)
				: window.JSInterface.share(url);
		}

		let socialOutlet = e?.target?.alt ? e.target.alt : '';
		doMeasurement('IBM Match Insights', `${socialOutlet} Share`, [`Match Insights ${data.matchId}`]);
	};

	return (
		<div className={`contents-in-header-wrapper ${type}`}>
			<div className="contents-in-header">
				{type == 'power-rankings' ? getPowerRankingsContents(data) : getMIContents(data)}

				<div className="contents-in-header-byline">
					<div className="contents-in-header-subtitle">
						{subtitle ? (
							<h4 className="sub-title">
								<JSXParser jsx={subtitle} />
								{/* <Time epoch_ms={data.headerDate} format="dddd, MMMM D, YYYY" /> */}
							</h4>
						) : null}
					</div>
					{window.webviewPlatform ? (
						<div className="socialShare">
							<span>
								<img
									src="https://www.usopen.org/images/nav/social_icons/facebook_gray.png"
									alt="Facebook"
									border="0"
									onClick={e => {
										handleShare(e);
									}}
								/>
							</span>
							<span>
								<img
									src="https://www.usopen.org/images/nav/social_icons/twitter_gray.png"
									alt="Twitter"
									border="0"
									onClick={e => {
										handleShare(e);
									}}
								/>
							</span>
						</div>
					) : (
						<div className="socialShare">
							<FacebookShareButton url={data.url} quote={data.headerTitle}>
								<img
									src="https://www.usopen.org/images/nav/social_icons/facebook_gray.png"
									alt="Facebook"
									border="0"
									onClick={e => {
										handleShare(e);
									}}
								/>
							</FacebookShareButton>
							<TwitterShareButton url={data.url} title={data.headerTitle}>
								<img
									src="https://www.usopen.org/images/nav/social_icons/twitter_gray.png"
									alt="Twitter"
									border="0"
									onClick={e => {
										handleShare(e);
									}}
								/>
							</TwitterShareButton>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const getMIContents = data => {
	return (
		<>
			{data.headerTitle ? (
				<div className="contents-in-header-title">
					<h1>
						{data.headerTitle}
						{data.infoIcon ? <MIInfoIcon onInfoClickFn={data.onInfoClickFn} /> : null}
					</h1>
					{data.cmsLink ? (
						<div className="display-cms-link">
							<EventsLink to={`/en_US/matchinsights/${data.matchId}.html`}>
								View full Match Insights
							</EventsLink>
						</div>
					) : null}
				</div>
			) : null}
		</>
	);
};

const getPowerRankingsContents = data => {
	return (
		<>
			{data.headerTitle ? (
				<div className="contents-in-header-title">
					<h1>{data.headerTitle}</h1>
				</div>
			) : null}
			{data.headerDate ? (
				<div className="contents-in-header-date">
					<h3>
						Updated <Time epoch_ms={data.headerDate} format="ddd D MMM YYYY HH:mm z" options="upper" />
					</h3>
				</div>
			) : null}
		</>
	);
};

export default MIHeaderContents;
