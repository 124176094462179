import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'components/general/Util';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from 'components/Template';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';

/**
 * -----------------------------------------------------------------------------
 * React Component: NotFound
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['TVSchedule'],
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.TVSchedule.mount()),
	unmount: data => dispatch(deps.actions.TVSchedule.unmount(data)),
});

class TVSchedule extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedIndex: 0,
			tvSchedule: null,
			loadedJson: false,
		};

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'TV Schedule',
		});
		// logger.log('[TVSchedule] constructor - state: %o', this.state);
	}

	componentWillUnmount() {
		// logger.log('[TVSchedule] unmounted');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[TVSchedule] componentDidUpdate - props:%o, prevProps:%o', this.props, prevProps);

		// calculate new data path from path in state and current filters
		if (prevProps.configLoaded !== this.props.configLoaded && this.props.configLoaded) {
			// logger.log(
			// 	'[TVSchedule] componentDidUpdate tvSchedule %o, loaded %o, path %o,',
			// 	this.state.tvSchedule,
			// 	this.state.loadedJson,
			// 	this.state.tvschedulePath
			// );
			if (!this.state.tvSchedule && !this.state.loadedJson && this.props.tvschedulePath) {
				this.fetchData();
			}
		}

		//   if (
		//     !this.state.loadedJson &&
		//     !this.isStubbed() &&
		//     this.state.tvschedulePath &&
		//     this.state.scoringVars !== undefined
		//   ) {
		//     this.fetchData();
		//   }
	}

	isStubbed() {
		return this.props.stubs && this.props.stubs.tv_us
			? this.props.stubs.tv_us.stub == 'stub'
				? true
				: false
			: false;
	}

	fetchData() {
		// logger.log('[TVSchedule] fetchData - state:%o', this.state);

		fetch(this.props.tvschedulePath)
			.then(data => {
				this.setState(prevState => {
					return { ...prevState, tvSchedule: data };
				});
			})
			.catch(error => {
				logger.log('[TVSchedule] fetchData error loading data: %o', error);
			});

		this.setState(prevState => {
			return { ...prevState, loadedJson: true };
		});
	}

	// createTableHeaders() {
	// 	if (this.props.filters.listView == 'seeds') {
	// 		return ['Seed', 'Name', '', 'Opponent', 'Round'];
	// 	} else {
	// 		return ['Rank', 'Name'];
	// 	}
	// }

	// formatTableData(data) {
	// 	if (this.props.filters.listView == 'seeds') {
	// 		let seedData = data.seeds;
	// 		return seedData.map((d, i) => {
	// 			return {
	// 				seed: d['rank'],
	// 				name:
	// 					d['tour-id'] != null ? (
	// 						<Link to={`/en_US/players/overview/${d['tour-id']}.html`}>{d['long-name']}</Link>
	// 					) : (
	// 						d['long-name']
	// 					),
	// 				text: d['result'],
	// 				opponent:
	// 					d['opp-tour-id'] != null ? (
	// 						<Link to={`/en_US/players/overview/${d['opp-tour-id']}.html`}>{d['opp-long-name']}</Link>
	// 					) : (
	// 						d['opp-long-name']
	// 					),
	// 				round: d['round'],
	// 			};
	// 		});
	// 	}
	// }

	// getPageData() {
	// 	let tableTitle;
	// 	let headerData;
	// 	let tableRowData;
	// 	let pageClass;

	// 	tableTitle = "Men's Seeds";
	// 	headerData = this.createTableHeaders();
	// 	tableRowData = this.formatTableData(this.state.tvSchedule);

	// 	return {
	// 		tableTitle: tableTitle,
	// 		headers: headerData,
	// 		data: tableRowData,
	// 		tableContainer: 'competitorList',
	// 		pageClass: pageClass,
	// 	};
	// }

	setSelected(index) {
		this.setState(prevState => {
			return { ...prevState, selectedIndex: index };
		});
	}

	render() {
		let header_attributes = {
			headerType: 'stub',
			minHeight: 190,
			title: 'US Open TV Schedule',
			metaTitle: 'US Open TV Schedule | Schedule',
			metaDescription: 'Live TV coverage information for the 2024 US Open Tennis Championships.',
			metaDate: '',
			metaPlayers: '',
			scroll: true,
			hideElems: ['.category-question-list'],
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/about/eventschedule.html',
					title: 'Schedule',
				},
			],
			sponsor: {
				link: 'http://www.rolex.com',
				title: 'Rolex',
				name: 'Rolex',
				imgClass: '',
				imgSrc: '/assets/images/logos/rolex_logo_sm.png',
				tagline: 'In partnership with',
			},
			title: 'TV Schedule',
			page_header: 'Domestic TV Schedule',
		};

		logger.log('[TVSchedule] render - this.state:%o, this.props:%o', this.state, this.props);

		// if (!this.props.stubs) {
		// 	return null;
		// }

		if (this.isStubbed()) {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<StubBox
						attributes={{
							title: header_attributes.title,
							message: this.props.stubs.tv_us.text,
							basePicPath: this.props.basePicPath,
						}}
					/>
				</Template>
			);
		} else {
			if (this.state.loadedJson) {
				if (this.state.tvSchedule) {
					let headerContent = [];
					let bodyContent = [];
					let footerContent = [];
					this.state.tvSchedule.schedule.map(section => {
						logger.log('[TVSchedule] render - section:%o', section);
						headerContent = section.head;
						bodyContent = section.body;
						footerContent = section.footer;
					});

					let legendInfo = headerContent[0];
					let colInfo = headerContent[1];
					// logger.log('[TVSchedule] render - bodyContent: %o', bodyContent);

					return (
						<Template header={header_attributes} subHeader={subheader_attributes}>
							<section className="wrapper event" id="tv-schedule">
								<PageSubMenu mode="Schedule" selected="TV Schedule" type="Domestic" />
								<div className="coverage-info">{ReactHtmlParser(legendInfo.legend[0].text)}</div>
								{bodyContent.map((body, b) => {
									return (
										<table className="table text-center" key={`table-${b}`}>
											<thead key={`thead${b}`}>
												<tr className="dayHeader">
													{/* <td className="row-spacer" /> */}
													<td scope="col" colSpan="4" className="header">
														{body.date}
													</td>
													{/* <td className="row-spacer" /> */}
												</tr>
											</thead>
											<tbody className="title">
												<tr>
													{/* <td className="row-spacer" /> */}
													{colInfo.columns.map((info, i) => {
														return (
															<th className={info.class} key={`h${i}`}>
																{ReactHtmlParser(info.text)}
															</th>
														);
													})}
													{/* <td className="row-spacer" /> */}
												</tr>
											</tbody>
											<tbody className="day">
												{body.channels.map((channel, n) => {
													let liveClass =
														channel.info[0].class == 'tv_live_ind' ? 'live' : '';
													return (
														<tr key={`${channel.network}${n}`} className={liveClass}>
															{/* <td className="row-spacer" /> */}
															<td className={channel.info[0].class}>
																{/* {ReactHtmlParser(channel.info[0].text)} */}
																<i
																	className={
																		channel.info[0].class == 'tv_live_ind'
																			? 'icon-check'
																			: ''
																	}
																/>
															</td>
															<td className={channel.info[1].class}>
																{ReactHtmlParser(channel.info[1].text)}
															</td>
															<td className={channel.info[2].class}>
																{ReactHtmlParser(channel.info[2].text)}
															</td>
															<td className={channel.info[3].class}>
																{ReactHtmlParser(channel.info[3].text)}
															</td>
															{/* <td className="row-spacer" /> */}
														</tr>
													);
												})}
											</tbody>
											<tfoot>
												<tr className="empty">
													<td colSpan="6">{body?.footer}&nbsp;</td>
												</tr>
											</tfoot>
										</table>
									);
								})}
							</section>
						</Template>
					);
				} else {
					return (
						<Template header={header_attributes} subHeader={subheader_attributes}>
							<section className="wrapper playerSeeds">
								<h1 className="header">Domestic TV Schedule</h1>
								<LoadingIndicator />
							</section>
						</Template>
					);
				}
			} else {
				return (
					<Template header={header_attributes} subHeader={subheader_attributes}>
						<section className="wrapper playerSeeds">
							<h1 className="header">Domestic TV Schedule</h1>
							<LoadingIndicator />
						</section>
					</Template>
				);
			}
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(TVSchedule);
