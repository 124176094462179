/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import ReactHtmlParser from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: YearByYear
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const YearByYear = props => {
	logger.log('[YearByYear] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	//logger.log('[YearByYear] - attr:%o', attributes);

	if (attributes) {
		return (
			<section className="topic-section" id={`_${attributes['title']}`}>
				<div className="topic-container">
					{attributes['images'].image ? (
						<div className="topic-media left">
							<div className="topic-media-image">
								<div>
									<img src={attributes['images'].image.url} alt={attributes['title']} />
								</div>
							</div>
						</div>
					) : null}
					<div className="topic-detail">
						<h3>
							<a className="offset-anchor" name={`year_${attributes['title']}`}>
								{attributes['title']}
							</a>
						</h3>
						{ReactHtmlParser(attributes['text'])}
					</div>
				</div>
			</section>
		);
	} else {
		return null;
	}
};

export default YearByYear;
