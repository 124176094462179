/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import PowerIndexLeaderboardWebview from './PowerIndexLeaderboardWebview';

/**
 * -----------------------------------------------------------------------------
 * React Component: HoleDetail
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PowerIndexLeaderboard'],
		...props,
	};
};

export default connect(mapStateToProps)(PowerIndexLeaderboardWebview);
