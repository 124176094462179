import GeneralContentPageWebview from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:lang(en_US)/about/:landing(tv_intl).html',
		order: 10,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path: '/webview/:lang(en_US)/info/:landing(privacy|terms|contact_us)/:id(index).html',
		order: 10,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path: '/webview/:lang(en_US)/about/:landing(sponsors)/:id.html',
		order: 10,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path: [
			'/webview/:lang(en_US)/:category(about)/:id(fanweek|fan_week)/index.html',
			'/webview/:lang(en_US)/:category(about)/:id(fanweek|fan_week)/',
		],
		order: 10,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path: [
			'/webview/:lang(en_US)/social/:landing(fanaccesspass)/:id(index).html',
			'/webview/:lang(en_US)/social/:landing(fanaccesspass)/',
		],
		order: 10,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path: [
			'/webview/:lang(en_US)/social/:category(fanaccesspass)/:id/index.html',
			'/webview/:lang(en_US)/social/:category(fanaccesspass)/:id.html',
			'/webview/:lang(en_US)/social/:category(fanaccesspass)/:id/',
		],
		order: 11,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path: '/webview/:lang(en_US)/:category(atoz)/:id(schedule|tv_schedules).html',
		order: 10,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path: '/webview/:lang(en_US)/:category(atoz)/:id(official_suppliers).html',
		order: 10,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path: 'webview/:lang(en_US)/:category(atoz)/:id(shopping).html',
		order: 10,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
	{
		path:
			'/webview/:lang(en_US)/:category(content|info|about|tickets|visit|event_guide|players_only|us-open-at-home)/:id.html',
		order: 11,
		exact: true,
		component: GeneralContentPageWebview,
		load: params => deps.actions.GeneralContentPageWebview.mount(params),
	},
];
