/**
 LiveVideo Initial State
*/

export default {
	selected: 'index', //values: 'wimchan', 'espn, 'bbc'
	videoPip: false,
	filters: {
		selected: '',
		title: 'SELECT A DAY',
		open: '',
	},
};
