/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ButtonLink
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const ButtonLink = props => {
	//logger.log('[ButtonLink] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	//logger.log('[ButtonLink] - attr:%o', attributes);

	if (attributes && attributes['title'] && attributes['url']) {
		return (
			<span>
				<EventsLink to={`${attributes['url']}`} style={attributes['style']}>
					{attributes['title']}
				</EventsLink>
			</span>
		);
	} else {
		return null;
	}
};

export default ButtonLink;
