import React, { Component } from 'react';

class TournamentDayDropdown extends Component {
	constructor(props) {
		super();
	}

	handleChange = event => {
		const selectedOption = event.target.value;
		this.props.onOptionSelected(selectedOption);
	};

	render() {
		let { options, pageMode } = this.props;
		let daySelectOptions = [];
		const tmpOptions = [...options];
		const nonQualOptions = tmpOptions.filter(option => {
			return !option.quals && !option.practice;
		});

		// match insights day selector does not display qual days
		daySelectOptions = pageMode === 'tourn' ? nonQualOptions : options;

		/**
		 * we need to assign the array to a new array object instead
		 * of manipulating the original array
		 **/
		let reversedOptions = pageMode === 'tourn' ? Object.assign([], daySelectOptions) : Object.assign([], options);
		reversedOptions.reverse();

		return (
			<div className="tournament-day-dropdown-wrapper">
				<select
					className="tournament-day-dropdown-selected"
					onChange={this.handleChange}
					value={this.props.selectedDay}>
					{reversedOptions.map(option =>
						option.displayDay &&
						option.released &&
						((pageMode == 'scores' && option.winnerCount > 0) || pageMode != 'scores') ? (
							<option key={option.order} value={option.tournDay}>
								{option.messageShort}
							</option>
						) : null
					)}
				</select>
			</div>
		);
	}
}

export default TournamentDayDropdown;
