import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { eventDef, getCorrectEventId } from './DrawsUtils';

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['DrawsPage'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	update: data => dispatch(deps.actions.DrawsPage.update(data)),
});

class DrawsNavigator extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		logger.log('[DrawsNavigator] constructor state:%o', this);
	}

	componentDidMount() {
		//logger.log('[DrawsNavigator] componentDidMount state:%o', this.props);
	}

	componentDidUpdate(prevState) {
		//logger.log('[DrawsNavigator] componentDidUpdate this.props:%o', this.props);
	}

	getBracketLabel(round) {
		//logger.log('[DrawsNavigator] function passed in value:%o', round);
		let labelArray = this.props.roundNames;

		for (var i = 0; i < labelArray.length; i++) {
			if (labelArray[i].roundCode == round) {
				return (
					<div key={i} className={`round${labelArray[i].roundCode}`}>
						<span className="long">{labelArray[i].roundNameShort}</span>
						<span className="short">{labelArray[i].roundNameShort}</span>
					</div>
				);
			}
		}
	}

	displayBracketColumn(type, index) {
		let round = index + 1;
		let bracketName = round.toString();
		//logger.log('[DrawsNavigator] displayBracketColumn type, round:%o', type, round);
		switch (type) {
			// 12 member top-bottom bracket
			case 'a':
				return (
					<div key={type} className="bracket-round s4l12">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'top', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'top' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'top', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'top' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'bottom', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'bottom' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'bottom', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'bottom' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				this.count++;
				break;
			// 10 member top-bottom bracket
			case 'b':
				return (
					<div key={type} className="bracket-round s2l10">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'top', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'top' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'bottom', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'bottom' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 10 member single bracket
			case 'c':
				return (
					<div key={type} className="bracket-round s1l10">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 8 member single bracket
			case 'd':
				return (
					<div key={type} className="bracket-round s1l8">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 4 member single bracket
			case 'e':
				return (
					<div key={type} className="bracket-round s1l4">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 2 member single bracket
			case 'f':
				return (
					<div key={type} className="bracket-round s1l2">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 1 member bracket
			case 'g':
				return (
					<div key={type} className="bracket-round s1l1">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
						</div>
					</div>
				);
				break;

			default:
				return null;

				break;
		}
	}

	onBracketSelect(index, round) {
		logger.log('[DrawsNavigator] onBracketSelect - values:%o', index, round);

		this.props.update({
			eventRound: {
				...this.props.eventRound,
				selectedIndex: index,
				selectedRound: round,
				clicked: true,
			},
		});
	}

	onArrowSelect(direction) {
		let newIndex = 0;
		let newRound = 0;
		let newBracket = '';
		let correctedEvent = getCorrectEventId(this.props.event, this.props.totalRounds);
		let brackets = eventDef[this.props.event].bracketDisp;
		let selectedIndex = this.props.eventRound.selectedIndex;
		let totalBracketsCount = brackets.length; // 7
		let currentRound = this.props.eventRound.selectedRound;
		let currentIndex = brackets.indexOf(selectedIndex); // 0

		if (direction == 0) {
			// Left arrow clicked

			if (currentIndex == 0) {
				newBracket = brackets[totalBracketsCount - 1];
				newRound = this.props.totalRounds;
			} else {
				newIndex = currentIndex - 1;
				newBracket = brackets[newIndex];
				if (selectedIndex == '1bottom' || selectedIndex == '2bottom') {
					newRound = currentRound;
				} else {
					newRound = currentRound - 1;
				}
			}
		} else {
			// Right arrow clicked
			if (currentIndex == totalBracketsCount - 1) {
				newBracket = brackets[0];
				newRound = 1;
			} else {
				newIndex = currentIndex + 1;
				newBracket = brackets[newIndex];
				if (selectedIndex == '1top' || selectedIndex == '2top') {
					newRound = currentRound;
				} else {
					newRound = currentRound + 1;
				}
			}
		}

		this.props.update({
			eventRound: {
				...this.props.eventRound,
				selectedIndex: newBracket,
				selectedRound: newRound,
				clicked: true,
			},
		});
	}

	render() {
		logger.log('[DrawsNavigator] render - this:%o, eventDef:%o', this, eventDef);
		if (this.props.eventRound) {
			let correctedEvent = getCorrectEventId(this.props.event, this.props.totalRounds);

			let labelDisp = eventDef[correctedEvent] ? eventDef[correctedEvent].roundNum : null;
			let bracketDisp = eventDef[correctedEvent] ? eventDef[correctedEvent].roundDisp : null;

			logger.log('[DrawsNavigator] render - values:%o', labelDisp, bracketDisp);

			if (labelDisp && bracketDisp) {
				return (
					<div data-dna="draws-nav-base" className="column-layout">
						<div className="draws-nav">
							<div className="draws-nav-column-titles">
								<div className="title-buffer" />
								{Object.keys(labelDisp).map((round, i) => {
									return this.getBracketLabel(round, i);
								})}
								<div className="title-buffer" />
							</div>
							<div className="draws-nav-chart">
								<i
									onClick={() => {
										this.onArrowSelect(0);
									}}
									onKeyPress={() => (event.key === 'Enter' ? this.onArrowSelect(0) : null)}
									tabIndex={0}
									className="icon-left-arrow"
								/>
								<div className="draws-nav-chart-bracket">
									<div className="bracket-buffer" />
									{bracketDisp.map((letter, i) => {
										return this.displayBracketColumn(letter, i);
									})}
									<div className="bracket-buffer" />
								</div>
								<i
									onClick={() => {
										this.onArrowSelect(1);
									}}
									onKeyPress={() => (event.key === 'Enter' ? this.onArrowSelect(1) : null)}
									tabIndex={0}
									className="icon-right-arrow"
								/>
							</div>
						</div>
					</div>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsNavigator);
