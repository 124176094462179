import config from "./tracking.config";
import op from "object-path";

const engines = {};
Object.keys(config).forEach(name => {
  engines[name] = config[name]["engine"];
});

const adobePageview = (data, props) => {
  let engine = engines.adobe;
  engine.adobePageview(data, props);
};

const adobeMeasureApp = args => {
  let engine = engines.adobe;
  engine.adobeMeasureApp(args);
};

const adobeMeasureAppProps = (args, props) => {
  let engine = engines.adobe;
  engine.adobeMeasureAppProps(args, props);
};

const adobeExternalLink = data => {
  let engine = engines.adobe;
  engine.adobeExternalLink(data);
};

const pageview = page => {
  logger.log("[TRACKING] PAGEVIEW ->", page);

  // Loop through all engines and if an engine
  // has the `pageview` property, execute it.
  Object.keys(engines).forEach(name => {
    let engine = engines[name];

    if (op.has(engine, "pageview")) {
      engine.pageview(page);
    }
  });
};

const trackEvent = e => {
  let { type } = e;
  let data = e.target.dataset || {};
  logger.log(`[TRACKING] trackEvent - e:%o type:%o`, e, type);
  if (!data.hasOwnProperty("metric")) {
    return;
  }

  let { category = e.type, action = e.type, metric = null } = data;

  if (metric === null) {
    return;
  }

  if (typeof metric === "string") {
    metric = JSON.parse(metric);
  }

  logger.log(`[TRACKING] EVENT: ${e.type} ->`, { category, action, metric });

  track({ type, category, action, metric, event: type });
};

const track = ({
  type,
  category,
  action,
  label,
  metric,
  event,
  engine = "all"
}) => {
  let engs = engines;

  if (engine !== "all") {
    engs = {};
    engs[engine] = engines[engine];
  }

  Object.keys(engs).forEach(name => {
    let eng = engs[name];
    if (typeof eng[type] === "function") {
      eng[type]({ type, category, label, action, metric, event });
    }
  });
};

const trackingMiddleware = ({
  exclude = []
} = {}) => store => next => action => {
  next(action);

  switch (action.type) {
    // Config engines when Router mounts
    case "ROUTER_MOUNT": {
      logger.log("[TRACKING] INIT ->", Object.keys(engines).join(", "));

      // Loop through all engines and if an engine
      // has the `initialize` property, execute it.
      Object.keys(engines).forEach(name => {
        let engine = engines[name];

        if (op.has(engine, "initialize")) {
          engine.initialize(config[name]["key"]);
        }
      });

      return;
    }

    // Execute pageview on route change
    case "UPDATE_ROUTE": {
      let { pathname } = action.location;

      // pathname in excluded list?
      if (exclude.indexOf(pathname) > -1) {
        return;
      }

      pageview(pathname);

      return;
    }

    // Execute ominiture events only

    case "PAGE_VIEW": {
      adobePageview(action.data);

      return;
    }
  }
};

export {
  pageview,
  adobePageview,
  adobeMeasureApp,
  adobeMeasureAppProps,
  adobeExternalLink,
  track,
  trackEvent,
  trackingMiddleware
};

// Global onclick register
//window.onclick = trackEvent;
