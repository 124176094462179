import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Hole
 * -----------------------------------------------------------------------------
 */

class Hole extends Component {
	constructor(props) {
		super(props);
		//logger.log('[Hole] - props:%o', props);

		// this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		if (this.props.hasOwnProperty('onMount')) {
			this.props.onMount(this);
		}
	}

	render() {
		//logger.log('[Hole] - state:%o', this.state.data);

		return <div>&nbsp;</div>;
	}
}

export default Hole;
