import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		logger.log('[TVSchedule] action.mount - ensureConfig store:%o', store);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				tvschedulePath: Config.otherData.tvSchedule,
				basePicPath: Config.otherData.basePicPath,
				configLoaded: true,
			};

			logger.log('[TVSchedule] action.mount - data:%o', data);

			dispatch({
				type: deps.actionTypes.TVSCHEDULEPAGE_LOAD,
				data: data,
			});
		});
	},
	unmount: data => (dispatch, getState, store) => {
		logger.log('[TVSchedule] actions.unmount data:%o', data);
		let data = {
			tvschedulePath: null,
			basePicPath: null,
			configLoaded: false,
		};
		dispatch({ type: deps.actionTypes.TVSCHEDULEPAGE_UNMOUNT, data: data });
	},
};
