import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
// import MIPlayerPR from 'appdir/components/pages/MatchInsights/MIPlayerPR';
import MatchInsightsContent from 'appdir/components/pages/MatchInsights/MatchInsightsContent';

const mapStateToProps = (state, props) => {
	return {
		...state['PowerRankingBlog'],
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchInsights.mount()),
});

class PowerRankingBlog extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.mount();
	}

	render() {
		logger.log('[PowerRankingBlog] render liveblog pr/ltw - state:%o, props:%o', this.state, this.props);

		if (this.props.likelihood) {
			//display Power Index with likelihood buttons
			return (
				<MatchInsightsContent
					matchId={this.props.matchId}
					isWebview={false}
					cmsLink={true}
					ignoreHelmet={true}
					ignorePageView={true}
					type={'blog'}
				/>
			);
		} 
		{/* MD - Innovation 2024 - Decommision Power Index */}
		{ /*
			else {
			//display Power Index only
			return <MIPlayerPR matchId={this.props.matchId} />;
			}
		*/}
		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerRankingBlog);
