/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from "react";
import EventsLink from "appdir/components/general/EventsLink";

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ContentLink
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const ContentLink = props => {
  //logger.log('[ContentLink] - props:%o', props);

  // let attributes = props['attributes'][props.id];
  let attributes = props.data;
  //logger.log('[ContentLink] - attr:%o', attributes);

  if (attributes && attributes.images) {
    let image = attributes["images"]["image"];

    return (
      <div className={`content-link ${attributes["style"]}`}>
        <EventsLink to={`${attributes["url"]}`}>
          <div className="content-link-image">
            <img src={image["url"]} alt={image["title"]} />
          </div>
          <div className="content-link-title">{attributes["text"]}</div>
        </EventsLink>
      </div>
    );
  } else {
    return null;
  }
};

export default ContentLink;
