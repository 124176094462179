
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import op from 'object-path';
import { getStatusConfig } from 'appdir/components/pages/Slamtracker/Utilities';
import ErrorBoundary from 'components/general/ErrorBoundary';

/* header elements */
import STScoreboard from './STScoreboard';
import STStickyHeader from './STStickyHeader'
import throttle from 'lodash/throttle';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Header
 * -----------------------------------------------------------------------------
 */

const STHeader = props => {

  // logger.log('[Slamtracker Header] props: %o', props);

  /**
    * -----------------------------
    * Props from SlamTrackerContent
    * -----------------------------
    * statMatch
    * liveMatchIds
    * match
    * matchid
    * matchlist
    * panel
    * matchtype
    * useToggle
    * showChallenges
    * isHeaderFixed
  */

  {/* Pre, live, post */}
  const matchStatus = getStatusConfig(props?.statMatch?.statusCode)['status'];
  // const ScoreManager = useSelector((state) => op.get(state['ScoreManager']));

  {/* is header fixed? */}
  const [sticky, setSticky] = useState(null);

  // logger.log('[Slamtracker Header] matchStatus - matchStatus:%o, statuscode:%o', matchStatus, props.statMatch.statusCode );

  const stPanelContent = document?.querySelector('.stpanel-content');
  const stContent = document?.querySelector('.slamtracker');

  const scrollContent = stPanelContent ? stPanelContent : stContent;
  // logger.log('[Slamtracker Header] sticky scrollContent:%o', scrollContent);

  useEffect(() => {
		if (!window.webview && stPanelContent) {
			// logger.log('[Slamtracker Header] sticky useEffect: %o', scrollContent);
			if (scrollContent) {
				scrollContent.addEventListener('scroll', throttle(stickyHeader, 100, { trailing: true }), false);
				return () => {
					scrollContent.addEventListener('scroll', throttle(stickyHeader, 100, { trailing: true }), false);
				};
			}
		}

		if (!window.webview && !stPanelContent && stContent) {
			// logger.log('[Slamtracker Header] sticky stContent:%o', stContent);
			let timeout;
			const panelContentMain = document.querySelector('.content-main');
      const headerHeight = (document?.querySelector('.slamtracker-header-content').scrollHeight);

			// let topAddOffSet = 60 + 56 + (165 / 2);

			window.onscroll = () => {
				if (timeout) {
					clearTimeout(timeout);
				}

				timeout = setTimeout(() => {
					if (window.scrollY > 0 && window.scrollY > headerHeight) {
						// logger.log(
						// 	'[Slamtracker Header] sticky window.scrollY:%o, topAddOffSet:%o',
						// 	window.scrollY,
						// 	topAddOffSet
						// );
						panelContentMain.classList.add('fixed');
						setSticky(true);
					} else {
						panelContentMain.classList.remove('fixed');
						setSticky(false);
					}
				}, 50);
			};
		}
  }, [sticky]);

  const stickyHeader = () => {
    const headerScrollContent = document?.querySelector('.slamtracker-content');
    const scrollContentTop = scrollContent ? scrollContent.scrollTop : '';
    const headerHeight = (document?.querySelector('.slamtracker-header-content').scrollHeight);
    const panelContentMain = document.querySelector('.content-main');

    // logger.log('[Slamtracker Header] sticky scrollContent:%o, headerHeight:%o', scrollContent.scrollHeight, headerHeight);

    let fixedHeader = scrollContentTop > 0 && scrollContentTop > headerHeight ? true : false;
    
    if(fixedHeader && panelContentMain) {
      panelContentMain.classList.add('fixed')
    } else {
      panelContentMain.classList.remove('fixed');
    }

    setSticky(fixedHeader);
  };

  // logger.log('[Slamtracker Header] sticky isSticky:%o', sticky);

  return (
		<>
			<div className="slamtracker-header-content">
				{!sticky ? (
					<STScoreboard
						match={props.match}
						matchid={props.matchid}
						matchlist={props.matchlist}
						panel={props.panel}
						matchtype={props.matchtype}
						useToggle={props.useToggle}
						showChallenges={props.showChallenges}
					/>
				) : (
					<STStickyHeader
						statMatch={props.statMatch}
						match={props.match}
						matchid={props.matchid}
						matchlist={props.matchlist}
						matchtype={props.matchtype}
						playerImageURL={props.playerImageURL}
						isHeaderFixed={sticky}
					/>
				)}
			</div>
		</>
  );
}

export default STHeader;