/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

import Schedule from 'appdir/components/pages/Schedule';

const EventScheduleWebview = () => {
	return <Schedule />;
};

export default EventScheduleWebview;
