import deps from "dependencies";

export default {
  mount: () => (dispatch, getState, store) => {
    deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
      Config => {
        let path = Config.otherData.individual_tickets;
        deps.services.TicketsModule.fetch(path).then(data => {
          dispatch({
            type: deps.actionTypes.TICKETSMODULE_MOUNT,
            data
          });
        });
      }
    );
  }
};
