import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.PRACTICESCHEDULE_MOUNT:
			// logger.log('[PracticeSchedulePage] reducers - action:%o', action);

			newState = {
				...state,
				...action.data,
			};

			// logger.log('[PracticeSchedulePage] reducers PRACTICESCHEDULE_MOUNT - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
