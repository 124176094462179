/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import ReactHtmlParser from 'html-react-parser';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Faq
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;
export default class Faq extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accordionState: {},
		};
	}

	toggleAccordion = uuid => {
		let accordionState = {};
		let attributes = this.props.data;
		accordionState[uuid] = {
			...uuid,
			expanded:
				!this.state?.accordionState?.hasOwnProperty(uuid[0]) || !this?.state?.accordionState[uuid[0]]?.expanded,
		};

		logger.log(
			'[Faq] toggleAccordion - accordionState:%o, doesExist:%o',
			accordionState,
			this.state?.hasOwnProperty(uuid[0])
		);

		this.setState({
			accordionState,
		});

		MeasurementUtils.dispatchMeasurementCall('toggleAccordion', {
			...accordionState[uuid],
			title: attributes['questions'].question[uuid].qu,
		});
	};

	render() {
		logger.log('[Faq] - this:%o', this);

		let attributes = this.props.data;
		if (attributes) {
			return (
				<section id={attributes['id']} className="faq-section">
					<div className="faq-wrapper">
						<h4>{attributes['title']}</h4>
						<Accordion
							className="faq-container"
							onChange={itemUuids => {
								this.toggleAccordion(itemUuids);
							}}>
							{attributes['questions'].question.map((item, i) => (
								<AccordionItem
									key={`item${i}`}
									uuid={i}
									dangerouslySetExpanded={this?.state?.accordionState[i]?.expanded}>
									<AccordionItemHeading
										className={`q ${this?.state?.accordionState[i]?.expanded ? 'expand' : ''}`}>
										<AccordionItemButton>
											<div className="ui-icon" role="presentation">
												<i
													className={`${
														this?.state?.accordionState[i]?.expanded
															? 'icon-up-arrow'
															: 'icon-down-arrow'
													}`}></i>
											</div>
											{attributes['questions'].question[i].qu}
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel className="a">
										{ReactHtmlParser(attributes['questions'].question[i].an)}
									</AccordionItemPanel>
								</AccordionItem>
							))}
						</Accordion>
					</div>
				</section>
			);
		} else {
			return null;
		}
	}
}
