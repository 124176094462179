import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import MatchInsightsContent from 'appdir/components/pages/MatchInsights/MatchInsightsContent';
import MatchInsightsCard from 'appdir/components/cms/MatchInsightsCard';

const mapStateToProps = (state, props) => {
	return {
		...state['PowerRankingLTW'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchInsights.mount()),
});

class PowerRankingLTW extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.mount();
	}

	render() {
		logger.log('[PowerRankingLTW] render for contentpage - props:%o', this.props);

		return (
			<div className="prContainer">
				<div className="cmsPowerRankingLTW">
					<MatchInsightsCard matchId={this.props.data.matchId} />
					{/* <MatchInsightsContent
						matchId={this.props.data.matchId}
						isWebview={false}
						cmsLink={true}
						ignoreHelmet={true}
						type={'blog'}
					/> */}
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerRankingLTW);
