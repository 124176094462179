// props:
// doesn't need the data - all the same everywhere
// callback function for click
// use props.eventsWindow to decide whether dropdown or bar
// ^^for window size
// mixins general breakpoint - scss/_mixins.scss
// also needs to switch to dropdown in mobile
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { restHeaders } from 'appdir/components/general/Util';
import TournamentDayBar from '../TournamentDayBar/TournamentDayBar';
import TournamentDayDropdown from '../TournamentDayDropdown/TournamentDayDropdown';

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

const mapStateToProps = (state, props) => {
	return {
		...state['TournamentDaySelector'],
		daysData: state['Config'].scoringData.scheduleDays,
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};

class TournamentDaySelector extends Component {
	constructor(props) {
		super();
	}

	componentDidMount() {
		fetch(this.props.daysData)
			.then(result => {
				this.setState({
					scheduleDays: result.eventDays,
				});
			})
			.catch(error => {
				this.setState({
					status: 'error',
					errorType: error,
				});
			});
	}

	render() {
		logger.log('[TournamentDaySelector] render - this: %o', this);
		if (this.state?.scheduleDays && this.props?.EventsWindow?.numericWindowSize) {
			let isMobile = this.props.EventsWindow.numericWindowSize < 3;

			const displayDayPicker = isMobile ? (
				<TournamentDayDropdown
					options={this.state.scheduleDays}
					onOptionSelected={this.props.handleOptionSelected}
					selectedDay={this.props.selectedDay}
					enableFuture={this.props?.enableFuture ? this.props?.enableFuture : false}
					pageMode={this.props?.pageMode}
				/>
			) : (
				<TournamentDayBar
					options={this.state.scheduleDays}
					onOptionSelected={this.props.handleOptionSelected}
					selectedDay={this.props.selectedDay}
					enableFuture={this.props?.enableFuture ? this.props?.enableFuture : false}
					pageMode={this.props?.pageMode}
				/>
			);
			return <div className="tournament-day-selector-wrapper">{displayDayPicker}</div>;
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, null)(TournamentDaySelector);
