/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsLink from 'components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveVideo
 *
 * add to page : <LiveVideo courtId="A" location="matchbox" />
 * params: courtId, location
 *
 * if location is not matchbox, you'll need to add a section in the return
 * method that will return the html you need.
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...props,
		geoblock: state['Controller'].geoblock,
		channels: state['Controller'].video.channels,
	};
};

class LiveVideo extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		// logger.log('[LiveVideo] constructor this:%o', this);
	}

	componentDidMount() {
		// logger.log('[LiveVideo] componentDidMount props:%o ', this.props);
	}

	checkCourtVideo(promo) {
		var videoUrl = '';

		if (!this.props.geoblock && this.props.channels) {
			for (var i = 0; i < this.props.channels.length; i++) {
				if (this.props.channels[i].courtId == this.props.courtId && this.props.channels[i].live) {
					if (!promo) {
						// We matched the court and it's live
						videoUrl = this.props.channels[i].launchUrl;
						// Stop looping as we found the court
						i = this.props.channels.length;
					} else {
						// videoUrl = "javascript:launchLC('" + this.props.courtId + "','','',''," + false + ",'" + promo + "')";
						videoUrl = `/en_US/video/live.html?court=${this.props.courtId}`;
					}
				}
			}
		}

		return videoUrl;
	}

	render() {
		// logger.log('[LiveVideo] render - this:%o', this);

		if (this.props.channels) {
			if (this.checkCourtVideo(this.props.courtId) != '' && !this.props.geoblock) {
				//  return html based on location
				if (this.props.location == 'matchbox') {
					var videoUrl = this.checkCourtVideo(this.props.courtId);

					if (this.props.panel === true) {
						return (
							<div
								className="live-video"
								onClick={e => {
									this.props.onClick(e, videoUrl);
								}}>
								<i className={this.props.icon} />
								{this.props.hideText ? '' : 'WATCH LIVE on ESPN'}
							</div>
						);
					} else {
						return (
							<EventsLink style="live-video blue-button" to={videoUrl}>
								<i className={this.props.icon} />
								{this.props.hideText ? '' : 'WATCH LIVE on ESPN'}
							</EventsLink>
						);
					}
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps)(LiveVideo);
