import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		// logger.log('[COUNTRYPLAYERSPAGE] action.mount - data:%o', data);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let paths = {
				countryPlayer: Config.scoringData.countryPlayers,
				countries: Config.scoringData.countries,
				flagImagePath: Config.otherData.flagImagePathLarge,
				basePicPath: Config.otherData.basePicPath,
				updatedSeedsList: Config.scoringData.updatedSeedsList,
			};

			dispatch({
				type: deps.actionTypes.COUNTRYPLAYERSPAGE_MOUNT,
				data: { ...paths },
			});
		});
	},
};
