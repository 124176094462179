import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.PLAYER_SEARCH:
			newState = {
				...state,
				...action.data,
				loadResults: true,
			};
			logger.log('[PlayerSearch] reducer - PLAYER_SEARCH: %o', newState);
			return newState;

		case deps.actionTypes.PLAYER_SEARCH_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[PlayerSearch] reducer - PLAYER_SEARCH_UNMOUNT: %o', newState);
			return newState;

		default:
			return state;
	}
};
