import deps from 'dependencies';

const initialModalState = {
	modalType: null,
};

export default (state = initialModalState, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SHOW_MODAL:
			logger.log('[ModalOverlay] SHOW_MODAL - action:%o', action);
			switch (action.data.modalType) {
				case 'SHARE_MODAL':
					newState = {
						...state,
						modalType: action.data.modalType,
						share_url: action.data.share_url ? action.data.share_url : null,
						share_section: action.data.share_section ? action.data.share_section : null,
						share_title: action.data.share_title ? action.data.share_title : null,
					};
					break;
				case 'MATCH_INSIGHTS':
					newState = {
						...state,
						modalType: action.data.modalType,
						matchId: action.data.matchId,
					};
					break;
				case 'TICKETS_INFO':
				case 'FAN_WEEK_SCHEDULE':
				case 'VIDEO_MODAL':
					newState = {
						...state,
						...action.data,
					};
					break;
				default:
					newState = {
						...state,
						modalType: action.data.modalType,
					};
					break;
			}
			logger.log('[ModalOverlay] SHOW_MODAL - newState:%o', newState);
			return newState;

		case deps.actionTypes.HIDE_MODAL:
			logger.log('[ModalOverlay] HIDE_MODAL - action:%o', action);
			return initialModalState;

		default:
			newState = {
				...state,
			};
			return newState;
	}
};
