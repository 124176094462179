import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SEARCHPAGE_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.SEARCHPAGE_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.SEARCHPAGE_SHOWSEARCH:
			newState = {
				...state,
				showSearch: action.data,
			};
			return newState;

		default:
			return state;
	}
};
