/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import MatchBox from 'appdir/components/_scoring/MatchBox';
import STToggle from 'appdir/components/pages/Slamtracker/elements/header/STToggle';
import STChallenges from 'appdir/components/pages/Slamtracker/elements/header/STChallenges';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: STScoreboard
 * -----------------------------------------------------------------------------
 */
const STScoreboard = props => {
	// logger.log('[STScoreboard] props:%o', props);

	let { match } = props;
	let { matchid } = props;
	let { matchlist } = props;
	let { panel } = props;
	let { matchtype } = props;
	let { suppressProfileLink } = props;
	let { useToggle } = props;
	let { showChallenges } = props;

	let liveVideo = true;

	let foundMatch = matchlist.find(function(match) {
		return match.match_id == matchid;
	});

	if (foundMatch && matchtype != 'upcoming') {
		liveVideo = true;
	} else {
		liveVideo = false;
	}

	if (!matchtype) {
		matchtype = '';
	}

	return (
		<section className="slamtracker-scoreboard">
			<div className="content">
				{((matchtype !== 'upcoming' && match.scores) || matchtype == 'upcoming') &&
				matchid == match.match_id ? (
					<MatchBox
						attributes={{
							data: match,
							style: matchtype && matchtype == 'upcoming' ? matchtype : 'live',
							mode: 'live',
							event: match.shortEventName,
							tableHeader: match.courtName,
							showLinks: false,
							usePlayerImages: panel ? false : true,
							isFeaturedMatch: true,
							liveVideo: liveVideo,
							useRadio: false,
							useLive: true,
							suppressProfileLink: suppressProfileLink,
							isSidepanel: panel ? panel : false,
						}}
						key={`slamtrackerScoreboard-${match.match_id}`}
					/>
				) : (
					<LoadingIndicator type="white" />
				)}
			</div>
			<div className="more">
				{showChallenges ? (
					<div className={`challenges team-one ${match.team1.idB != null ? 'doubles' : ''}`}>
						<STChallenges
							data={{
								available: match.team1.challengesAvailable,
								used: match.team1.challengesUsed,
							}}
						/>
					</div>
				) : null}

				{useToggle ? <STToggle /> : null}

				<div className="slamtracker-radio" />
				{showChallenges ? (
					<div className={`challenges team-two ${match.team2.idB != null ? 'doubles' : ''}`}>
						<STChallenges
							data={{
								available: match.team2.challengesAvailable,
								used: match.team2.challengesUsed,
							}}
						/>
					</div>
				) : null}
			</div>
		</section>
	);
};

export default STScoreboard;
