import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[SchedulePage] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[SchedulePage] action.mount - data: %o', data);

			let currentScheduleDay = getState()?.['ActiveData']?.['currentDay']?.['schedule'] || 'Current';

			let day = params.tournDay ? params.tournDay : currentScheduleDay;

			let data = {
				path: Config.scoringData.schedule,
				daysPath: Config.scoringData.scheduleDays,
				pdfPath: Config.scoringData.schedulePdf,
				tournDay: day, //params.tournDay,
				event: params.event ? params.event : 'all',
				basePicPath: Config.otherData.basePicPath,
				highlightsPath: Config.otherData.matchhighlights,
				completedMatches: Config.scoringData.completedMatches,
				liveScores: Config.scoringData.liveScore.path,
				futureSchedule: Config.otherData.futureSchedule,
				tournamentSchedule: Config.cmsData.tournamentSchedule,
			};
			logger.log('[SchedulePage] action.mount - data: %o', data);
			dispatch({ type: deps.actionTypes.SCHEDULEPAGE_LOAD, data: data });
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.SCHEDULEPAGE_FILTER, data: data });
	},
	setDay: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.SCHEDULEPAGE_SETDAY, data: data });
	},
};
