import { fetch, fetchAll } from 'appdir/components/general/Util';
import { getQuerystringValues } from 'appdir/components/general/Util';

export const loadPreMatchData = path => {
	return new Promise((resolve, reject) => {
		fetch(path)
			.then(response => {
				// logger.log('[Data] loadPreMatchData response:%o', response);
				resolve({ response });
			})
			.catch(error => {
				// logger.log('[Data] loadPreMatchData singles error:%o', error);
				reject(error);
			});
	});
};

export const loadCompletedMatchData = pathObj => {
	return new Promise((resolve, reject) => {
		fetchAll(pathObj)
			.then(response => {
				// logger.log('[Data] loadCompletedMatchData response:%o', response);
				resolve({ response });
			})
			.catch(error => {
				// logger.log('[Data] loadCompletedData singles error:%o', error);
				reject(error);
			});
	});
};

export const loadOverviewData = (data, url) => {
	return new Promise((resolve, reject) => {
		let overviewData = {};

		let pathObj = [url.replace('<id>', data.team1.idA), url.replace('<id>', data.team2.idA)];

		// logger.log('[SlamtrackerContent][Data] loadOverviewData fetch singles data - data:%o', data);

		fetchAll(pathObj)
			.then(pAdata => {
				// logger.log('[SlamtrackerContent][Data] loadOverviewData pAdata:%o', pAdata);
				if (data.team1.idB != null && data.team1.idB != '' && data.team2.idB != null && data.team2.idB != '') {
					let doublesPathObj = [url.replace('<id>', data.team1.idB), url.replace('<id>', data.team2.idB)];
					// logger.log('[SlamtrackerContent][Data] componentDidUpdate fetch doubles data');

					fetchAll(doublesPathObj)
						.then(pBdata => {
							// logger.log('[SlamtrackerContent][Data] loadOverviewData pBdata:%o', pBdata);
							overviewData = {
								...overviewData,
								p1ADetail: pAdata[0],
								p2ADetail: pAdata[1],
								p1BDetail: pBdata[0],
								p2BDetail: pBdata[1],
								doublesOverviewError: false,
								singlesOverviewError: false,
							};
							// logger.log('[SlamtrackerData] loadOverviewData resolve fetch doubles data');
							resolve({ overviewData });
						})
						.catch(error => {
							// logger.log('[SlamtrackerContent][Data] loadOverviewData doubles error:%o', error);
							overviewData = {
								...overviewData,
								p1ADetail: pAdata[0],
								p2ADetail: pAdata[1],
								p1BDetail: false,
								p2BDetail: false,
								doublesOverviewError: true,
							};
							reject({ overviewData });
						});
				} else {
					// logger.log('[Data] loadOverviewData resolve fetch singles data - overviewData:%o', overviewData);
					overviewData = {
						...overviewData,
						p1ADetail: pAdata[0],
						p2ADetail: pAdata[1],
						singlesOverviewError: false,
					};
					resolve({ overviewData });
				}
			})
			.catch(error => {
				// logger.log('[SlamtrackerContent][Data] loadOverviewData singles error:%o', error);
				overviewData = {
					...overviewData,
					p1ADetail: false,
					p2ADetail: false,
					singlesOverviewError: true,
				};
				reject({ overviewData });
			});
	});
};

export const loadInnovationsData = (innovationMatch, otherData) => {
	return new Promise((resolve, reject) => {
		let innovationsData = {};

		/** ?override=true ignores the match status and always set vote to open
		 *  ?test=true to use test data
		 */
		let parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		let isInnovationTest = parsedQs?.test;

		let powerIndexMatchupPath = otherData.innovations.powerIndexMatchup.replace('<matchId>', innovationMatch);

		/** set testing URLs  */
		if (isInnovationTest) {
			powerIndexMatchupPath = this.props.innovationTest.matchInsightscloudData.powerIndexMatchup.replace(
				'<matchId>',
				innovationMatch
			);
		}

		fetch(powerIndexMatchupPath)
			.then(pim_result => {
				// logger.log('[Data] loadInnovationsData - pim_result:%o', pim_result);

				innovationsData = {
					match_id: innovationMatch,
					powerIndexMatchup: pim_result,
				};

				resolve({ innovationsData });
			})
			.catch(error => {
				logger.log('[Data] loadInnovationsData - error loading powerIndexMatchup data:%o', error);
				innovationsData = null;

				reject({ innovationsData });
			})
	});
};
