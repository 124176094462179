/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import DayNav from 'appdir/components/common-ui/DayNav';

const mapStateToProps = (state, props) => {
	return {
		...state['LiveAtUSOpen'],
		...props,
		EventsWindow: state['WindowSize'].EventsWindow,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.LiveAtUSOpen.filter(settings)),
});

class DaySelect extends Component {
	constructor(props) {
		super(props);

		let dayNavOptions = props.days
			.filter(day => day.tournDay)
			.map(item => ({
				value: item.tournDay.toString(),
				type: item.quals ? 'quali' : 'tourn',
				textName: item.displayDay,
				dropdownName: item.messageShort,
				title: item.messageShort,
				sort: item.tournDay,
				disabled: !item.released ? true : false,
			}));

		this.state = {
			dayNavOptions: dayNavOptions,
			mode: props.mode,
			filters: props.filters,
		};

		logger.log('[DaySelect] constructor this:%o', this);
	}

	setDay = day => {
		let isDesktop = this.props.EventsWindow.windowSize === 'desktop';
		let dayVal = isDesktop ? day : day.target.value;
		this.props.filter({
			day: dayVal,
			open: '',
		});
	};
	onOpen = which => {
		logger.log('[DaySelect] onOpen:%o', which);
		this.props.filter({
			open: which,
		});
	};

	render() {
		logger.log('[DaySelect] render - this:%o', this);

		// let daysData = {
		// 	options: [
		// 		{
		// 			dropdownName: 'Select Day',
		// 			textName: '',
		// 			value: '',
		// 			type: '',
		// 			title: '',
		// 			disabled: true,
		// 		},
		// 	],
		// };
		const daysData = {
			options: [],
		};

		// add available days to base archive menu options
		this.state.dayNavOptions.forEach(item => {
			let revRelease;
			//disable future released schedule link
			if (this.props.default < item.sort) {
				revRelease = true;
			} else {
				revRelease = item.disabled;
			}

			daysData.options.push({
				textName: item.textName,
				dropdownName: item.dropdownName,
				value: item.value,
				type: item.type,
				title: item.title,
				sort: item.sort,
				disabled: revRelease,
			});
		});
		let reversedOptions = Object.assign([], daysData.options);
		reversedOptions.reverse();
		let isDesktop = this.props.EventsWindow.windowSize === 'desktop';

		const displayDayPicker = isDesktop ? (
			<DayNav
				name="days"
				attributes={daysData}
				selected={this.state.filters.day == 'current' ? this.props.default : this.state.filters.day}
				show={this.state.filters.open == 'days'}
				onSelected={this.setDay}
				onOpen={this.onOpen}
				mode={this.props.mode}
			/>
		) : (
			<div className="live-blog-menu-select-wrapper">
				<select onChange={this.setDay} className="live-blog-menu-select">
					{reversedOptions.map(option => (
						<option key={option.dropdownName} value={option.value}>
							{option.dropdownName}
						</option>
					))}
				</select>
				<span className="live-menu-arrow" />
			</div>
		);
		return <div className="daynav scores-select-menu dark">{displayDayPicker}</div>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DaySelect);
