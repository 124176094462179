import React, { PureComponent } from 'react';

/**
 * -----------------------------------------------------------------------------
 * Class Component: MatchDuration
 * @params
 *  - duration
 * -----------------------------------------------------------------------------
 */

export default class MatchDuration extends PureComponent {
	render() {
		// logger.log('[MatchBox MatchDuration] props:%o', this.props);

		return this.props.duration && this.props.duration !== '0:00' ? (
			<span className="duration robintest">Duration: {this.props.duration}</span>
		) : null;
	}
}
