/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useRef } from 'react';
import ReactHtmlParser from 'html-react-parser';
import isArray from 'lodash/isArray';
import Slider from 'react-slick';
import EventsLink from 'components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';

import { Carousel } from 'react-responsive-carousel';
// import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Calendar
 * -----------------------------------------------------------------------------
 */
const Calendar = props => {
	logger.log('[Util Calendar] - props:%o', props);

	const slideIndex = useRef(0);
	const { data } = props;
	let itemArray;

	/** if no data, no need to proceed, return null */
	if (!data) {
		return null;
	}

	if (!isArray(data?.calendarItems?.calendarItem)) {
		itemArray = new Array(data?.calendarItems?.calendarItem);
	} else {
		itemArray = data?.calendarItems?.calendarItem;
	}

	const renderCalendarItem = item => {
		let img_alt =
			item?.images?.image?.caption != '' ? item?.images?.image?.caption : item?.title ? item.title : null;

		return (
			<div className="calendar-item-wrapper" key={`item-${item.id}`}>
				<div className="calendar-item">
					<div className="image">
						<img src={item?.images?.image?.url} alt={img_alt} />
					</div>
					{/* <div className="content-container"> */}
					<div className="content info">
						{item?.title && <div className="title">{item?.title}</div>}

						{item?.description && <div className="description">{ReactHtmlParser(item?.description)}</div>}
						{item?.start_time && item?.end_time && (
							<span className="time">{`${item?.start_time} to ${item?.end_time} (ET)`}</span>
						)}
						{item?.location && <span className="location">{item?.location}</span>}
						{item?.location_map_link && (
							<div className="fanweek-map link-button">
								<EventsLink to={item.location_map_link}>
									<i className="icon-grounds-map"></i>
									Find It On the Map
								</EventsLink>
							</div>
						)}
						{item.button_text && item.link && (
							<div className="uso-primary-button">
								<EventsLink to={item.link}>{item.button_text}</EventsLink>
							</div>
						)}
					</div>
					{/* </div> */}
				</div>
			</div>
		);
	};

	const measureNextPrev = newIndex => {
		//logger.log('[SummaryScoreboard] measureNexPrev newIndex:%o',newIndex);
		let prevSlide = slideIndex.current;

		if (prevSlide < newIndex) {
			MeasurementUtils.dispatchMeasurementCall('eventSliderClick', {
				action: 'next',
				curSlide: newIndex,
				curSlideTitle: props.data.calendarItems.calendarItem[newIndex].title,
				curSlideId: props.data.calendarItems.calendarItem[newIndex].id,
				parent: props.data.parentId,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('eventSliderClick', {
				action: 'prev',
				curSlide: newIndex,
				curSlideTitle: props.data.calendarItems.calendarItem[newIndex].title,
				curSlideId: props.data.calendarItems.calendarItem[newIndex].id,
				parent: props.data.parentId,
			});
		}

		slideIndex.current = newIndex;
	};

	/**
	 * for the responsive sizing, the
	 * breakpoint represents the max
	 * size for those settings
	 */
	let calendarSliderSettings = {
		dots: true,
		arrows: true,
		infinite: false,
		className: 'fanweek-carousel',
		speed: 500,
		initialSlide: 0,
		swipeToSlide: true,
		variableWidth: true,
		slidesToShow: 2,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
		afterChange: newIndex => {
			measureNextPrev(newIndex);
		},
	};

	return (
		<section className="calendar-section">
			<Slider {...calendarSliderSettings}>
				{itemArray.map(item => {
					return renderCalendarItem(item);
				})}
			</Slider>
		</section>
	);
};

export default Calendar;
