/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';

export default class OQModal extends Component {
	render() {
		return (
			<Fragment>
				{this.props.showModal && (
					<div className="openquestions_modal">
						<div className="openquestions_modal_content">
							<div className="watson_animated" />
							<div className="modal_header">Open Questions with Watson</div>
							<div className="modal_text">
								Open Questions uses Watson Discovery to curate debates on hot tennis topics. It uses
								natural language processing to analyze millions of data sources around a debatable
								statement. It can assess the quality of pro and con arguments and generate a summary of
								the debate. Fans can then add their own opinions. To learn more,{' '}
								<a href="https://www.ibm.com/watson/natural-language-processing" target="_new">
									click here
								</a>
								.
							</div>
							<button className="open_question_submit" onClick={this.props.toggleModal}>
								Got It
							</button>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}
