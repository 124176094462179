import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

const mapStateToProps = (state, props) => {
	return {
		...state['RolexWatch'],
		Config: state['Config']['otherData'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SiteMap.mount()),
});

class RolexWatch extends Component {
	constructor(props) {
		super(props);
		logger.log('[RolexWatch] constructor');
	}

	render() {
		return get(this.props, 'Config.showRolexWatch', false) ? (
			<div className="rolexblock">
				<iframe
					src="https://static.rolex.com/clocks/2021/us_open_tennis_mobile_HTML_320x80/rolex.html"
					style={{
						width: '100%',
						height: '80px',
						border: 0,
						margin: 0,
						padding: 0,
						overflow: 'hidden',
						scroll: 'none',
					}}
					scrolling="NO"
					frameBorder="NO"
				/>
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RolexWatch);
