export const configWeb = {
  id: "/Config",
  type: "object",
  properties: {
    refreshSec: {
      type: "integer",
      minimum: 10
    },
    scoringData: {
      type: "object",
      properties: {
        liveScore: {
          $id: "/properties/liveScore",
          type: "object",
          additionalProperties: false,
          properties: {
            path: {
              $id: "/properties/liveScore/properties/path",
              type: "string"
            },
            rateSec: {
              $id: "/properties/liveScore/properties/rateSec",
              type: "integer",
              minimum: 5
            }
          },
          required: ["path", "rateSec"]
        },
        matchScore: {
          $id: "/properties/matchScore",
          type: "object",
          additionalProperties: false,
          properties: {
            path: {
              $id: "/properties/matchScore/properties/path",
              type: "string"
            },
            rateSec: {
              $id: "/properties/matchScore/properties/rateSec",
              type: "integer",
              minimum: 5
            }
          },
          required: ["path", "rateSec"]
        }
      }
    },
    stubPages: {
      type: "object",
      properties: {
        sidepanel: {
          type: "object",
          properties: {
            stub: {
              type: "string"
            },
            text: {
              type: "array"
            }
          }
        }
      }
    }
  },
  required: ["refreshSec"]
};

export const relatedContentVideo = {
  id: "/RelatedContent",
  type: "object",
  properties: {
    title: { type: "string" },
    contentId: { type: "string" },
    cmsId: { type: "string" },
    images: { type: "array" },
    url: { type: "string" },
    media: {
      type: "object",
      properties: {
        m3u8: { type: "string" },
        share: { type: "string" }
      },
      required: ["m3u8"]
    }
  },
  required: ["title", "cmsId", "images", "url", "media"]
};

export const searchResults = {
  id: "/Search",
  type: "object",
  properties: {
    responseHeader: { type: "object" },
    response: { type: "object" }
  },
  required: ["responseHeader", "response"]
};

export const playerFavorites = {
  id: "/PlayerFavorites",
  type: "object",
  properties: {
    id: { type: ["string", "null"] },
    first_name: { type: ["string", "null"] },
    last_name: { type: ["string", "null"] },
    events_entered: { type: ["string", "null"] },
    last_status: { type: ["string", "null"] },
    last_opp: { type: ["string", "null"] },
    last_court: { type: ["string", "null"] },
    last_event: { type: ["string", "null"] },
    last_round: { type: ["string", "null"] },
    last_match_id: { type: ["string", "null"] },
    next_status: { type: ["string", "null"] },
    next_opp: { type: ["string", "null"] },
    next_court: { type: ["string", "null"] },
    next_event: { type: ["string", "null"] },
    next_round: { type: ["string", "null"] },
    next_match_id: { type: ["string", "null"] }
  },
  required: [
    "id",
    "first_name",
    "last_name",
    "events_entered",
    "last_status",
    "last_opp",
    "last_court",
    "last_event",
    "last_round",
    "last_match_id",
    "next_status",
    "next_opp",
    "next_court",
    "next_event",
    "next_round",
    "next_match_id"
  ]
};
