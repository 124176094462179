import React from 'react';
import { useSelector } from 'react-redux';

import JSXParser from 'react-jsx-parser';
import Helmet from 'react-helmet';
import Header from 'appdir/components/general/Header';
import MobileNav from 'appdir/components/general/MobileNav';
import PageHeader from 'appdir/components/general/PageHeader';
import Byline from 'appdir/components/general/Byline';
import SubHeader from 'appdir/components/general/SubHeader';
import AdTag from 'appdir/components/general/AdTag';
import NewsletterBanner from 'appdir/components/general/NewsletterBanner';
import PageSponsor from 'appdir/components/cms/PageSponsor';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import VisibilitySensor from 'react-visibility-sensor';
import { increaseFontSize, decreaseFontSize } from './Utils';

const ArticleContent = props => {
	logger.log('[ArticleContent] props:%o', props);

	const adConfigData = useSelector(state => state['Config']?.adConfig);
	
	// const [inView] = React.useState(false);

	let { articleContent, currContent, headerData, subheaderData, articleIndex, adConfig, eventsWindow } = props.data;

	let adConf = 'article_1';
	let adConf2 = 'article_2';

	// logger.log('[ContentPage] currContent header_propsData:%o', header_propsData);
	return (
		<span
			id={`article-wrapper-${articleIndex}`}
			key={`articleContent-${articleIndex}-${articleContent.contentId}`}
			className="article-inner-wrapper">
			{articleIndex == 0 ? (
				<>
					<Header attributes={headerData} />
					<MobileNav />
					<SubHeader attributes={subheaderData} />
					<div className="topAd" key={`article-ad-${articleIndex}`}>
						<AdTag
							adConfig={adConfigData[adConf2]} //using the 920 ads
							dfpNetworkId={adConfigData.dfpNetworkId}
						/>
					</div>

					<PageHeader attributes={headerData} />

					<Helmet>
						<meta name="measureTitle" content="Detail" />

						<meta property="og:title" content={articleContent.title} />
						<meta property="og:description" content={articleContent.abstract} />
						<meta property="fb:app_id" content="471867639491882" />
						<meta property="og:locale" content="en_US" />
						<meta property="og:image" content={articleContent.header.image} />
						<meta property="og:url" content={articleContent.url.share} />
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:site" content="@USOpen" />
						<meta name="twitter:creator" content="@USOpen" />
						<meta name="twitter:title" content={articleContent.title} />
						<meta name="twitter:description" content={articleContent.abstract} />
						<meta name="twitter:image" content={articleContent.header.image} />
						<meta name="twitter:url" content={articleContent.url.share} />
						{articleContent.url.canonical ? (
							<link rel="canonical" href={articleContent.url.canonical} />
						) : (
							<link rel="canonical" href={articleContent.url.share} />
						)}
						{articleContent.url.amp ? <link rel="amphtml" href={articleContent.url.amp} /> : null}
					</Helmet>

					{articleContent.pageSponsor ? <PageSponsor data={articleContent.pageSponsor} /> : null}
				</>
			) : (
				<>
					<div className="next-article">
						<div className="centerMargins">
							<div className="breadcrumbs">
								<span>Next Story :&nbsp;</span>
								<span className="pagetitle">{articleContent.title}</span>
							</div>
						</div>
					</div>

					<PageHeader attributes={headerData} />

					{articleContent.url.amp ? <link rel="amphtml" href={articleContent.url.amp} /> : null}

					{articleContent.pageSponsor ? <PageSponsor data={articleContent.pageSponsor} /> : null}
				</>
			)}

			<VisibilitySensor
				onChange={isVisible => props.onArticleChange(isVisible, articleContent.url.canonical, articleContent)}
				partialVisibility={true}>
				{({ isVisible }) => {
					{
						Object.keys(currContent.attributes).map((att, i) => {
							if (currContent.attributes[att].type == 'SocialEmbed') {
								currContent.attributes[att].visible = isVisible;

								// logger.log('[ArticleContent][SocialEmbed] currentContent.attributes:%o', currContent.attributes);
							}
							if(currContent.attributes[att].type == 'Video') {
								currContent.attributes[att].style = 'inline';
							}
						});
					}

					return (
						<article id={`article${articleIndex}`} key={`article${articleIndex}`} className="newsArticle">
							{eventsWindow.windowSize == 'desktop' && eventsWindow.desktopSize !== 'smallDesktop' ? (
								<>
									<div className={'article-sticky-ad'}>
										<AdTag
											key={`ad${articleIndex}`}
											adConfig={adConfigData[adConf]}
											dfpNetworkId={adConfigData.dfpNetworkId}
										/>
									</div>
									<div className={'article-sticky-social'}>
										<Byline
											attributes={headerData}
											webview={false}
											share={{
												url: articleContent.url.share,
												title: articleContent.metaTitle,
											}}
										/>
									</div>
								</>
							) : null}

							<section className="uso-news">
								<div className="change-font-size">
									Text Size:
									<div className="font-decrease" onClick={decreaseFontSize}>
										-
									</div>
									<div className="font-increase" onClick={increaseFontSize}>
										+
									</div>
								</div>
								{eventsWindow.windowSize == 'mobile' ||
								eventsWindow.windowSize == 'tablet' ||
								eventsWindow.desktopSize == 'smallDesktop' ? (
									<Byline
										attributes={headerData}
										webview={false}
										share={{
											url: articleContent.url.share,
											title: articleContent.metaTitle,
										}}
									/>
								) : null}
								{eventsWindow.windowSize == 'tablet' ||
								(eventsWindow.windowSize == 'desktop' && eventsWindow.desktopSize == 'smallDesktop') ? (
									<div className="rich-text">
										<div className="articleAd">
											<AdTag
												key={`ad${articleIndex}`}
												adConfig={adConfigData[adConf]}
												dfpNetworkId={adConfigData.dfpNetworkId}
											/>
										</div>
									</div>
								) : null}
							</section>

							<ErrorBoundary message={'Article content is unavailable'}>
								<JSXParser
									key={`articles${articleIndex}`}
									bindings={currContent.attributes}
									components={currContent.components}
									jsx={currContent.jsx}
									renderInWrapper={false}
									showWarnings={true}
								/>
							</ErrorBoundary>

							{eventsWindow.windowSize !== 'tablet' && eventsWindow.windowSize !== 'desktop' ? (
								<div
									id={`article-ad-${articleIndex}`}
									className="article-ad-container"
									key={`articlead${articleIndex}`}>
									<AdTag
										key={`article-ad-${articleIndex}`}
										adConfig={adConfigData[adConf]} //using the 920 ads
										dfpNetworkId={adConfigData.dfpNetworkId}
									/>
								</div>
							) : null}
							<NewsletterBanner style="article-banner" />
						</article>
					);
				}}
			</VisibilitySensor>
		</span>
	);
};

export default ArticleContent;
