import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import op from 'object-path';
import TennisScoringData from 'tennis-scoring-react/src';
import deps from 'dependencies';
import { updatePointHistory } from './PointHistory';

const queryString = require('querystring-browser');

const mapStateToProps = (state, props) => {
	return {
		...state['ScoreManager'],
		configStatus: state['Config'].status,
		scoringConfig: state['Config'].scoring,
		scoringData: state['Config'].scoringData,
		scoringConnectionReady: op.get(state['Controller'], 'scoring.connectionReady', false),
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	setMatches: matchData => dispatch(deps.actions.ScoreManager.setMatches(matchData)),
	setCurrentMatch: matchData => dispatch(deps.actions.ScoreManager.setCurrentMatch(matchData)),
	setLiveStatMatch: matchData => dispatch(deps.actions.ScoreManager.setLiveStatMatch(matchData)),
	setStatMatchStats: statData => dispatch(deps.actions.ScoreManager.setStatMatchStats(statData)),
	setStatMatchHistory: historyData => dispatch(deps.actions.ScoreManager.setStatMatchHistory(historyData)),
	clearStatMatch: () => dispatch(deps.actions.ScoreManager.clearStatMatch()),
	mount: () => dispatch(deps.actions.ScoreManager.mount()),
	connectionReady: isReady => dispatch(deps.actions.Controller.scoresConnectionReady(isReady)),
	setScoringStatus: scoringStatus => dispatch(deps.actions.Controller.setScoringStatus(scoringStatus)),
});

class ScoreManager extends Component {
	constructor(props) {
		super(props);
		this.loaded = false;
		this.balanceOverride;

		let search = window.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);
		let mqttMode = get(parsedQs, 'mqtt', '');
		if (mqttMode == 'ec') {
			this.balanceOverride = 0;
		} else if (mqttMode == 'ms') {
			this.balanceOverride = 100;
		}

		logger.log('[ScoreManager] constructor - this:%o', this);
	}

	componentWillUnmount() {
		this.removeLocalStorage();
	}

	componentDidMount() {
		// logger.log('[ScoreManager] - componentDidMount');
		this.props.mount();

		this.removeLocalStorage();

		window.addEventListener('ixEventsScoring', e => {
			if (e.detail) {
				if (e.detail.type == 'addMatchData') {
					// logger.log('[ScoreManager] received event: %o', e.detail)
					if (this.props.statMatch.match_id != e.detail.matchId) {
						this.props.clearStatMatch();
					}
					this.props.setCurrentMatch(e.detail);
				}
			}
		});
	}

	removeLocalStorage() {
		if (window.localStorage) {
			window.localStorage.removeItem('ixScoringMatches');
		}
	}

	shouldComponentUpdate() {
		if (this.props.scoringConnectionReady === true) {
			return false;
		} else {
			logger.log(
				'[ScoreManager] shouldComponentUpdate - yes it should - scoringConnectionReady:%o',
				this.props.scoringConnectionReady
			);
			return true;
		}
	}

	componentDidUpdate() {
		if (this.props.scoringConnectionReady === true && !this.started) {
			this.started = true;
			this.props.setScoringStatus({ loaded: true });
		}
	}

	setConnectionStatus = isReady => {
		logger.log('[ScoreManager] setConnectionStatus - isReady:%o', isReady);
		// if (!this.state.connectionReady) {
		// 	this.setState({
		// 		connectionReady: isReady,
		// 	});
		// }

		if (!isReady) {
			this.props.setMatches(null);
		}
		this.props.connectionReady(isReady);
	};

	processScoreUpdate(scoreData) {
		// logger.log('[ScoreManager] - processScoreUpdate: this: %o', this);
		let tempMatch;
		let _this = this;

		if (this.props.currentMatch) {
			tempMatch = scoreData.find(function(match) {
				return match.match_id == _this.props.currentMatch.matchId;
			});
			if (typeof tempMatch !== 'undefined') {
				this.props.setLiveStatMatch(tempMatch);
			}
		}

		this.props.setMatches(scoreData);
	}

	processPointHistoryUpdate(newHistory) {
		/** only process new points if they have the same match id a the current statMatch */
		if (this.props?.statMatch && newHistory[0].MatchID == this.props?.statMatch?.match_id && this.props?.statMatch?.statusCode !== 'X' && this.props?.statMatch?.statusCode !== 'Y') {
			let updatedHistoryData = updatePointHistory(this.props.statMatch.pointHistory, newHistory);
			this.props.setStatMatchHistory(updatedHistoryData);
		}
	}

	render() {
		// logger.log('[ScoreManager] render - this:%o', this);

		return (
			<>
				{this.props?.configStatus == 'loaded' && this.props?.scoringConfig && this.props?.scoringData ? (
					<TennisScoringData
						balance={parseInt(this.props.scoringConfig.pubsubBalance, 10)}
						mqttBalance={parseInt(this.props.scoringConfig.messagesightBalance, 10)}
						messageSightHostname={this.props.scoringConfig.messageSiteHost}
						edgeConnectHostname={this.props.scoringConfig.edgeConnectHost}
						tokenCreator={this.props.scoringConfig.edgeConnectTokenGenerator}
						mqttPort={this.props.scoringConfig.messagesightSSLPort}
						mqttEvent={this.props.scoringConfig.messagePath}
						tournamentYear={this.props.scoringConfig.tournamentYear}
						androidMode={this.props.scoringConfig.android_mode}
						scoresPollingFile={this.props.scoringData.liveScore.path}
						statsFile={this.props.scoringData.matchStat.path}
						cFile={this.props.scoringData.matchHistory.path}
						uFile={this.props.scoringData.matchHistoryUpdate.path}
						pollingFrequency={this.props.scoringConfig.pollingFrequency}
						retryAttempts={this.props.scoringConfig.pubsubRetries}
						anonymous={true}
						connectionReady={isReady => {
							// this.props.connectionReady(isReady);
							this.setConnectionStatus(isReady);
						}}
						scoresUpdated={scoreData => {
							// logger.log('[ScoreManager] Scores Data Updated! %o', scoreData);
							this.processScoreUpdate(scoreData);
						}}
						statsUpdated={statData => {
							// logger.log('[ScoreManager] Stats Data Updated! %o', statData);
							this.props.setStatMatchStats(statData);
						}}
						historyUpdated={historyData => {
							// logger.log('[ScoreManager] Match History Data Updated! %o', historyData);
							let newHistory;

							if (this.props.statMatch.pointHistory.length > 0) {
								newHistory = this.props.statMatch.pointHistory.filter(point => {
									let isUpdatedPoint = false;

									for (var i = 0; i < historyData.length; i++) {
										if (point.PointNumber === historyData[i].PointNumber) {
											isUpdatedPoint = true;
										}
									}

									if (!isUpdatedPoint) {
										return point;
									} else {
										return null;
									}
								});

								newHistory = newHistory.concat(historyData);
							} else {
								newHistory = historyData;
							}

							this.processPointHistoryUpdate(newHistory);
						}}
					/>
				) : null}
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreManager);
