import React from 'react';
import filter from 'lodash/filter';
import findLastKey from 'lodash/findLast';
import op from 'object-path';
// import KeyTranslate from './Keys/KeyTranslate';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';

export const getNumFromString = str => {
	return str.replace(/\D/g, '');
};

export const isNumeric = data => {
	return !isNaN(parseFloat(data)) && isFinite(data);
};

export const getServeTeam = serveVal => {
	if (serveVal == '1' || serveVal == '3') {
		return '1';
	} else {
		return '2';
	}
};

export const arrayToObject = (array, keyField) =>
	array.reduce((obj, item) => {
		obj[item[keyField]] = item;
		return obj;
	}, {});

export const hasCurrentMatchEnded = (prevState, currentState) => {
	if (
		op.get(prevState, 'ScoreManager.statMatch.status', false) !== 'Completed' &&
		op.get(currentState, 'ScoreManager.statMatch.match_id', false) === currentState.matchId &&
		op.get(currentState, 'ScoreManager.statMatch.status', false) == 'Completed'
	) {
		return true;
	} else {
		return false;
	}
};

export const isNewMatch = (prevState, currentState) => {
	// logger.log('[Slamtracker util] isNewMatch prevState:%o, currentState:%o', prevState, currentState);

	if (
		prevState.matchId !== currentState.matchId &&
		op.get(currentState, 'ScoreManager.statMatch.match_id', false) !== currentState.matchId
	) {
		return true;
	} else {
		return false;
	}
};

export const getLastPointData = pointsBySet => {
	let lastSet = Object.keys(pointsBySet).reverse()[0];
	let lastGame = Object.keys(pointsBySet[lastSet]).reverse()[0];
	let numPoints = Object.keys(pointsBySet[lastSet][lastGame]).length;
	let lastPoint = findLastKey(pointsBySet[lastSet][lastGame]);
	// so this lastPoint object is the wrong data.
	return { numPoints, lastSet, lastGame, lastPoint };
};

export const getStatTotal = (statName, setNo, pointNumber, pointHistory) => {
	try {
		let objArray;

		if (setNo != 0) {
			objArray = filter(pointHistory, function(e) {
				return parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) && e.SetNo == setNo;
			});
		} else {
			objArray = filter(pointHistory, function(e) {
				return parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10);
			});
		}

		let t1val = 0;
		let t2val = 0;

		if (statName !== 'NetPoint') {
			//ok so i think net points is the only one where a 3 will be counted for team 1 and 2
			// all other stats a 3 is for team 1 player b and a 4 is for team 2 player b
			t1val = filter(objArray, function(e) {
				return e[statName] == '1' || e[statName] == '3';
			}).length;
			t2val = filter(objArray, function(e) {
				return e[statName] == '2' || e[statName] == '4';
			}).length;
		} else {
			t1val = filter(objArray, function(e) {
				return e[statName] == '1' || e[statName] == '3';
			}).length;
			t2val = filter(objArray, function(e) {
				return e[statName] == '2' || e[statName] == '3';
			}).length;
		}

		return new Array(t1val, t2val);
	} catch (e) {
		logger.log('getStatTotal ERROR - ' + e);
	}
};

export const getDoubleDonutData = (set, point, numerator, denominator, pointHistory) => {
	let num = getStatTotal(numerator, set, point, pointHistory);
	let den = getStatTotal(denominator, set, point, pointHistory);

	let p1pct = 0;
	let p2pct = 0;

	if (den[0] != 0) {
		p1pct = Math.round((parseInt(num[0], 10) / parseInt(den[0], 10)) * 100);
		if (isNaN(p1pct)) {
			p1pct = 0;
		}
	}

	if (den[1] != 0) {
		p2pct = Math.round((parseInt(num[1], 10) / parseInt(den[1], 10)) * 100);
		if (isNaN(p2pct)) {
			p2pct = 0;
		}
	}

	// create data object
	let dd_data = {
		p1: {
			series: [p1pct, 100 - p1pct],
			chart_value: p1pct,
			num: num[0],
			den: den[0],
		},
		p2: {
			series: [p2pct, 100 - p2pct],
			chart_value: p2pct,
			num: num[1],
			den: den[1],
		},
	};

	return dd_data;
};

export const stage_items = ['dst', 'ret', 'trk', 'fsp', 'fsw', 'npw', 'rpw', 'ssw', 'ace', 'dbf', 'win', 'asp', 'kik'];

/**
 * This function takes the stage item value and matches it against a regular expression to see if its
 * a situational stage item
 */
export const isStageSituational = text => {
	var isMatch = false;
	if (
		text.match(/dg[1-6]/g) ||
		text.match(/d[0-2][0-2]s/g) ||
		text.match(/[ud][1-3]b[1-2]/g) ||
		text.match(/mb[1-2]/g)
	) {
		isMatch = true;
	}
	return isMatch;
};

export const isStageKeys = text => {
	let isMatch = false;
	if (text.match(/p[1-2]k[1-3]/g)) {
		isMatch = true;
	}
	return isMatch;
};

/**
 * functions to render keys, putting them here so I can reuse them
 * for match insights overlay
 */
const getKeyClass = goal_reached => {
	let key_class = 'unmet';

	if (goal_reached == '1') {
		key_class = 'keymet';
	} else if (goal_reached == '0') {
		key_class = 'unmet';
	}

	return key_class;
};

export const renderKey = (team, key, keyValue, index, mi) => {
	// logger.log('[SlamtrackerKeys] renderKey - keyValue:%o ', keyValue);
	let isMatchInsights = false;

	if (mi && mi === true) {
		isMatchInsights = true;
	}

	if (index < 3) {
		return (
			<div className={`${team}_${key} key col-xs-12`} key={key}>
				<div className="summary">
					<div className="text">
						{/* <KeyTranslate keyId={keyValue.key_id} goal1={keyValue.goals[0]} goal2={keyValue.goals[1]} /> */}
					</div>
					{!isMatchInsights ? (
						<div className="boxes">
							<div
								className={`set s1 ${
									keyValue.goal_reached['set_1'] ? getKeyClass(keyValue.goal_reached['set_1']) : ''
								}`}
							/>
							<div
								className={`set s2 ${
									keyValue.goal_reached['set_2'] ? getKeyClass(keyValue.goal_reached['set_2']) : ''
								}`}
							/>
							<div
								className={`set s3 ${
									keyValue.goal_reached['set_3'] ? getKeyClass(keyValue.goal_reached['set_3']) : ''
								}`}
							/>
							<div
								className={`set s4 ${
									keyValue.goal_reached['set_4'] ? getKeyClass(keyValue.goal_reached['set_4']) : ''
								}`}
							/>
							<div
								className={`set s5 ${
									keyValue.goal_reached['set_5'] ? getKeyClass(keyValue.goal_reached['set_5']) : ''
								}`}
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	}
};

export const doMeasurement = (measureAction, measure_args, statMatch) => {
	if (window.location.href.indexOf('suite') == -1) {
		let matchStatus;
		let measureArray;

		if (statMatch.status == 'Complete') {
			matchStatus = 'Complete';
		} else {
			matchStatus = 'Live';
		}

		if (typeof webview === 'undefined') {
			measureArray = ['Slamtracker', statMatch.status, 'Match Stats'].concat(measureAction).concat(measure_args);

			MeasurementUtils.dispatchMeasurementCall(measureAction, {
				data: measureArray,
			});
		} else {
			measureArray = ['Metrics', 'Slamtracker', statMatch.status].concat(measureAction).concat(measure_args);

			// logger.log('[measurement] measureArray:%o', measureArray);

			//rll:  remove join for usta only
			//.join(':');
			measureInApp(measureArray);
		}
	}
};

export const formatRelatedContentURL = (statMatch, url) => {
	let rc_tags = '';

	// if (statMatch.team1.idA != null) {
	// 	rc_tags += `&tags=${statMatch.team1.idA}`;
	// }
	// if (statMatch.team1.idB != null) {
	// 	rc_tags += `&tags=${statMatch.team1.idB}`;
	// }
	// if (statMatch.team2.idA != null) {
	// 	rc_tags += `&tags=${statMatch.team2.idA}`;
	// }
	// if (statMatch.team2.idB != null) {
	// 	rc_tags += `&tags=${statMatch.team2.idB}`;
	// }

	let relatedContentURL = url.replace('<matchId>', statMatch.match_id);
	// relatedContentURL = relatedContentURL + rc_tags;

	// logger.log('[Utilities] formatRelatedContentURL - relatedContentURL: ',relatedContentURL);

	return relatedContentURL;
};

/**
 * this function determines if this is a tiebreak game
 */
export const isTiebreakGame = (statMatch, item, eventNames, st_event, tb_events) => {
	// let { statMatch } = this.state;

	var tiebreak = false;
	var set = parseInt(item.SetNo, 10);
	//var game = parseInt(item.GameNo,10) == 12?13:parseInt(item.GameNo);
	var game = parseInt(item.GameNo);
	var setWinner = item.SetWinner == '0' ? false : true;
	//logger.log('[STContent] this.props:%o, this.state:%o',this.props, this.state);
	const isQuals = () => {
		let quals = false;
		eventNames.map((event, i) => {
			if (event.quals == true && event.sb_code == statMatch.eventCode) {
				quals = true;
			}
		});
		//logger.log('[STContent] is quals');

		return quals;
	};

	//if (st_event == 'wim' && game >= 13 /*&&!setWinner*/) {
	if (st_event == 'wim' && game >= 13) {
		if (isQuals()) {
			//logger.log('[STContent] is quals: %o', item);
			// quals match
			if (st_event == 'wim' && statMatch.eventCode == 'S' && statMatch.roundCode == '3' && set != 5) {
				// qual mens round 3 but not set 5
				tiebreak = true;
			} else if (set != 3) {
				// all others not set 3
				tiebreak = true;
			}
		} else {
			// main draw
			//logger.log('[STContent] is maindraw: %o', item);
			if ((statMatch.eventCode == 'A' || statMatch.eventCode == 'C') && set != 5) {
				// mens singles and doubles and not the last set
				tiebreak = true;
			} else if (statMatch.eventCode != 'A' && statMatch.eventCode != 'C' && set != 3) {
				// all others not the last set
				tiebreak = true;
			}
		}
	}
	//logger.log('[STContent] isTiebreakGame SetNo:%o, GameNo:%o, PointNumber:%o, SetWinner:%o, tiebreakEvent:%o', item.SetNo, item.GameNo, item.PointNumber, item.SetWinner, this.props.superTiebreakEvents.indexOf(statMatch.eventCode));
	if (st_event == 'uso' && (game >= 13 || (item.SetNo == '3' && tb_events.indexOf(statMatch.eventCode) !== -1))) {
		tiebreak = true;
	}

	if (tiebreak) {
		//logger.log('[STContent] isTiebreakGame - isTiebreakGame game:%o', game);
	}
	return tiebreak;
};

/**
 * These status are only for match status data -
 * A is in progress
 * B is future
 * D is complete
 * E is retired
 * F is Walkover
 * G is default
 * H is heat delay (not sure this is ever used)
 * J is rain delay (not sure this is ever used)
 * K is suspended
 * X is players arrive on court
 * Y is players warming up
 **/

export const getMatchStatus = (matchid, upcoming, live) => {
	let status = null;

	logger.log('[Utilities] getMatchStatus - matchid:%o', matchid);
	logger.log('[Utilities] getMatchStatus - upcoming:%o', upcoming);
	logger.log('[Utilities] getMatchStatus - live:%o', live);

	/** search for match id in upcoming matches */
	let upcomingFound = upcoming.find(function(match) {
		return match.match_id == matchid;
	});

	logger.log('[Utilities] getMatchStatus - upcomingFound:%o', upcomingFound);

	/** match id was not found in upcoming matches */
	if (upcomingFound === undefined) {
		/** search for match id in live matches */
		let liveFound = live.find(function(match) {
			return match.match_id == matchid;
		});

		logger.log('[Utilities] getMatchStatus - liveFound:%o', liveFound);

		/** match was not found in live matches (or upcoming), must be completed */
		if (
			liveFound === undefined ||
			(liveFound !== undefined && (liveFound?.winner || liveFound?.status?.toLowerCase() == 'completed'))
		) {
			status = 'complete';
		} else {
			/** match was found in live matches */
			status = 'live';
		}
	} else {
		/** match was found in upcoming matches */
		status = 'prematch';
	}

	// logger.log('[STContent][Utilities] getMatchStatus - status:%o', status);

	return status;
};

export const isDoublesMatch = scoreData => {
	//logger.log('[Slamtracker Utilities] isDoublesMatch scoreData:%o, team1 type:%o, team2 type:%o', scoreData, typeof scoreData.team1, typeof scoreData.team2);
	// awt:  for some reason typeof array not working.  wierd.
	if (typeof scoreData.team1 === 'array' || scoreData?.team1?.[0]) {
		if (scoreData?.team1?.[0]?.idB || scoreData?.team2?.[0]?.idB) {
			return true;
		} else {
			return false;
		}
	} else {
		if (scoreData?.team1?.idB || scoreData?.team2?.idB) {
			return true;
		} else {
			return false;
		}
	}
	// if (eventName.toLowerCase().indexOf('doubles') > -1) {
	// 	return true;
	// } else {
	// 	return false;
	// }
};
