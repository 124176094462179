/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getTeamsLikelihoodWinData } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';

/**
 * -----------------------------------------------------------------------------
 * React Component:  Match Insight PowerIndexChart shows Likelyhood Win % and donuts chart with a winner check mark
 *
 *  props = {
 *             @param Array teams --------------- required, [team1, team2] match info from scoring system
 *			    @param String matchId ----------- optional, to pull PowerRankingMatchUp data
 *             @param Boolean pullData ---------- optional, flag it true to pull PowerRankingMatchUP data. requires to have matchId
 *             @param Object powerRankingData --- optional, if the data is passed, no need to pull PowerrankingMatchUp data
 *           }
 *
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PowerIndexChart'],
		configOtherData: state['Config'].otherData,
		windowSize: state['PageHeader'].windowSize,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({});

class PowerIndexChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			powerRankingData: null,
			status: null,
		};

		this.matchId = null;
		ChartJS.register(ArcElement, Tooltip, Legend);

		logger.log('[PowerIndexChart] constructor - this: %o', this);
	}

	componentDidMount() {
		this.setState({
			status: 'loaded',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		this.matchId = this.props?.matchId;

		/** if powerRankingsData is passed as props, don't fetch */
		if (
			this.props.pullData &&
			!this.state.powerRankingData &&
			this.matchId &&
			this.matchId !== undefined &&
			this.state.status == 'loaded'
		) {
			let powerIndexMatchupPath = this.props?.configOtherData.innovations?.powerIndexMatchup?.replace(
				'<matchId>',
				this.matchId
			);

			deps.services.MatchInsights.fetch(powerIndexMatchupPath)
				.then(result => {
					// logger.log('[PowerIndexChart] result:%o', result);
					this.setState({
						powerRankingData: result,
					});
				})
				.catch(error => {
					logger.error('[PowerIndexChart] componentDidUpdate error loading powerIndexMatchup data:%o', error);
					this.setState({
						powerRankingData: 'error',
					});
				});
		}
	}

	renderVsFallback() {
		let newDonutData = {
			datasets: [
				{
					label: false,
					data: [100],
					backgroundColor: ['#FFD400'],
					borderWidth: 0,
					cutout: '66%',
				},
			],
		};

		let pieOptions = {
			plugins: {
				tooltip: {
					enabled: false, // <-- this option disables tooltips
				},
				legend: {
					display: false,
				},
			},
			hover: {
				mode: null, // <--- disable hover highlight
			},
			animation: false,
		};

		return (
			<div className="PowerIndexChart">
				<hr className="vs team1" />
				<div className="donutChart">
					<Doughnut data={newDonutData} options={pieOptions} plugins={[]} width={'70px'} height={'70px'} />
				</div>
				<hr className="vs team2" />
			</div>
		);
	}

	render() {
		let { teams } = this.props;
		this.isSlamTracker = this.props?.type == 'slamtracker';

		/** support both passing the powerRankings data as props and pass matchID and fetch powerRankings data */
		if (
			(this.props.pullData && this.state.powerRankingData && this.state.powerRankingData !== 'error') ||
			this.props.powerRankingData
		) {
			/** select which PowerRankings match up data to use - pulled data or passed data */
			let powerRankingsDataSource = this.props.pullData
				? this.state.powerRankingData
				: this.props.powerRankingData;
			let likelyhoodWinData = getTeamsLikelihoodWinData(powerRankingsDataSource, teams);

			/** make sure players have the probablility number  */
			if (likelyhoodWinData.filter(d => d['likelihoodWinNum']).length > 0) {
				let team1Data = likelyhoodWinData.filter(d => d['player_id'] === teams[0]['idA']);
				let team2Data = likelyhoodWinData.filter(d => d['player_id'] === teams[1]['idA']);

				/** get which team won to display winner's check */
				let winnerTeam = '';

				teams.map((team, index) => {
					if (team?.won) {
						winnerTeam = `team${index + 1}`;
						return winnerTeam;
					}
				});

				let newDonutData = {
					datasets: [
						{
							label: false,
							data: [team2Data[0]['likelihoodWinNum'], team1Data[0]['likelihoodWinNum']],
							backgroundColor: ['#FFD400', '#418FDE'],
							borderWidth: 0,
							cutout: '66%',
						},
					],
				};

				let pieOptions = {
					plugins: {
						tooltip: {
							enabled: false, // <-- this option disables tooltips
						},
						legend: {
							display: false,
						},
					},
					hover: {
						mode: null, // <--- disable hover highlight
					},
					animation: false,
				};

				// logger.log('[PowerIndexChart] this:%o', this);

				return (
					<div className="PowerIndexChart">
						<div className="probability team1">
							{
								/** if Slamtracker and desktop size, display name above the number */
								<div className="slamtracker-only">
									<span className="player-name">{teams[0]['displayNameA']}</span>
								</div>
							}
							{team1Data[0]['likelihoodWinNum']}%
						</div>
						<div className="donutChart">
							<Doughnut
								data={newDonutData}
								options={pieOptions}
								plugins={[]}
								width={'70px'}
								height={'70px'}
							/>
						</div>
						<div className="probability team2">
							{
								/** if Slamtracker and desktop size, display name above the number */
								<div className="slamtracker-only">
									<span className="player-name">{teams[1]['displayNameA']}</span>
								</div>
							}
							{team2Data[0]['likelihoodWinNum']}%
						</div>
						{winnerTeam !== '' ? (
							<div className={`winnerCheck ${winnerTeam}`}>
								<i className="icon-check" />
							</div>
						) : null}
					</div>
				);
			} else {
				return this.renderVsFallback();
			}
		} else {
			return this.renderVsFallback();
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerIndexChart);
