import deps from 'dependencies';
import findIndex from 'lodash/findIndex';
import { fetch } from 'appdir/components/general/Util';

export default {
	update: data => (dispatch, getState, store) => {
		// logger.log('[Controller] actions - update:  data:%o', data);
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATE, data: data });
	},

	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let contentNewExpireSec = parseInt(Config.contentNewExpireSec);
			let miMatchesPath = Config.sharedDataConfig.matchInsightsMatches.path;
			let showMatchInsights = Config.otherData.showMatchInsights;

			/** before available match insights feed is out, don't fetch the non existing feed */
			let matchInsightsPull = Config.enabled?.matchInsightsPull;

			if (matchInsightsPull) {
				fetch([miMatchesPath])
					.then(mi_match_data => {
						dispatch({
							type: deps.actionTypes.CONTROLLER_MOUNT,
							data: {
								contentNewExpireSec,
								loaded: true,
								menus: Config.cmsData.mainMenu,
								miMatches:
									showMatchInsights === true && mi_match_data.matches ? mi_match_data.matches : [],
								siteBG: Config.cmsData.siteBackgroundImage,
							},
						});
					})
					.catch(error => {
						logger.log('[Controller] actions - error loading match insights match list:%o', error);

						dispatch({
							type: deps.actionTypes.CONTROLLER_MOUNT,
							data: {
								contentNewExpireSec,
								loaded: true,
								menus: Config.cmsData.mainMenu,
								miMatches: [],
								siteBG: Config.cmsData.siteBackgroundImage,
							},
						});
					});
			} else {
				dispatch({
					type: deps.actionTypes.CONTROLLER_MOUNT,
					data: {
						contentNewExpireSec,
						loaded: true,
						menus: Config.cmsData.mainMenu,
						miMatches: [],
						siteBG: Config.cmsData.siteBackgroundImage,
					},
				});
			}
		});
	},

	updateUom: data => dispatch => {
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATEUOM, data: data });
	},

	updateGeoBlock: data => dispatch => {
		dispatch({ type: deps.actionTypes.CONTROLLER_UPDATEGEOBLOCK, data: data });
	},

	updateVideoChannels: data => dispatch => {
		// logger.log('[updateVideoChannels] data:%o', data);
		dispatch({
			type: deps.actionTypes.CONTROLLER_UPDATEVIDEOCHANNELS,
			data: data,
		});
	},

	updateDate: data => (dispatch, getState, store) => {
		let controllerDate = data;
		dispatch({
			type: deps.actionTypes.CONTROLLER_UPDATEDATE,
			data: controllerDate,
		});
	},

	setPlatform: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.CONTROLLER_SETPLATFORM, data: data });
	},

	setScoringStatus: data => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.CONTROLLER_SET_SCORING_STATUS,
			data: data,
		});
	},

	updateViewedContent: (time, id) => (dispatch, getState, store) => {
		let contentNewExpire = getState().Controller['contentNewExpireSec'];
		let dateNow = Date.now() / 1000;
		let contentNew = false;

		if (dateNow - time / 1000 <= contentNewExpire) {
			contentNew = true;
		}
		//logger.log('[Controller] action.updateViewedContent - time:%o new:%o', time, contentNew);

		if (contentNew) {
			let contentArray = getState().Controller['content']['viewed'];

			//logger.log('[Controller] action.updateViewedContent - time:%o contentNewExpire:%o', time, contentNewExpire);

			contentArray.filter((d, i) => {
				return d.date >= dateNow - contentNewExpire;
			});
			let index = findIndex(contentArray, o => {
				return o.contentId == id;
			});
			if (index == -1) {
				contentArray.push({ contentId: id, date: time });
			}

			dispatch({
				type: deps.actionTypes.CONTROLLER_UPDATEVIEWEDCONTENT,
				data: { viewed: contentArray },
			});
		}
	},
	setWindowSize: data => (dispatch, getState, store) => {
		//logger.log('[Controller] action.setWindowSize -data.windowSize: %o', data);
		dispatch({ type: deps.actionTypes.CONTROLLER_SETWINDOWSIZE, data: data });
	},
	setSTWindowSize: data => (dispatch, getState, store) => {
		//logger.log('[Controller] action.setSTWindowSize -data.stWindowSize: %o', data);
		dispatch({ type: deps.actionTypes.CONTROLLER_SETSTWINDOWSIZE, data: data });
	},
	setMIWindowSize: data => (dispatch, getState, store) => {
		//logger.log('[Controller] action.setMIWindowSize -data.miWindowSize: %o', data);
		dispatch({ type: deps.actionTypes.CONTROLLER_SETMIWINDOWSIZE, data: data });
	},
	setMenuItems: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.CONTROLLER_SET_MENUITEMS, data: data });
	},
	setGlobalLink: data => (dispatch, getState, store) => {
		// data = {globalLink:someurl}
		dispatch({ type: deps.actionTypes.CONTROLLER_SET_GLOBALLINK, data });
	},
	scoresConnectionReady: data => dispatch => {
		dispatch({
			type: deps.actionTypes.CONTROLLER_SCORECONNECTION_READY,
			data: { connectionReady: data },
		});
	},
	setSTPanelStatus: data => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.CONTROLLER_SETSTPANEL_STATUS,
			data: data,
		});
	},
};
