import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	// logger.log("[LiveAtUSOpenWebview] reducer - action: %o", action);

	switch (action.type) {
		case deps.actionTypes.LIVEATUSOPEN_WEBVIEW_MOUNT:
			logger.log('[LiveAtUSOpenWebview] reducer.LIVEATUSOPEN_WEBVIEW_MOUNT - action:%o', action);
			filters = {
				...state.filters,
				day: action.data.tournDay,
			};

			newState = {
				...state,
				...action.data,
				filters,
			};
			return newState;

		case deps.actionTypes.LIVEATUSOPEN_WEBVIEW_UNMOUNT:
			// logger.log('[LiveAtUSOpenWebview] reducer.LIVEATUSOPEN_WEBVIEW_UNMOUNT');

			newState = {
				...state,
				...action.data,
			};

			return newState;

		case deps.actionTypes.LIVEATUSOPEN_WEBVIEW_FILTER:
			logger.log('[LiveAtUSOpen] LIVEATUSOPEN_WEBVIEW_FILTER - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};
			logger.log('[LiveAtUSOpen] LIVEATUSOPEN_WEBVIEW_FILTER - newState:%o', newState);
			return newState;

		case deps.actionTypes.LIVEATUSOPEN_WEBVIEW_SETDAY:
			logger.log('[LiveAtUSOpen] LIVEATUSOPEN_WEBVIEW_SETDAY - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'load',
			};
			logger.log('[LiveAtUSOpen] LIVEATUSOPEN_WEBVIEW_SETDAY - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
