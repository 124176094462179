import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[GalleryPage] mount - data:%o', data);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let galleryAPIPath = '';
			if (data.photoid) {
				galleryAPIPath = Config.relatedContent.singleImageGallery;
				// } else if (data.playerid) {
				//   galleryAPIPath = Config.relatedContent.playerRelatedGallery;
			} else {
				galleryAPIPath = Config.cmsData.gallery;
			}
			// dispatch({type: deps.actionTypes.SIDEPANEL_HIDEPANEL, data: false});
			dispatch({
				type: deps.actionTypes.GALLERYPAGE_MOUNT,
				data: galleryAPIPath,
			});
		});
	},
	unmount: () => (dispatch, getState, store) => {
		logger.log('[GalleryPage] unmounting the GalleryPage component');
		dispatch({ type: deps.actionTypes.GALLERYPAGE_UNMOUNT });
	},
};
