ffdebug = false; // turn console messages off
var mArgs;
var mUrl;
var cArgs;
var cURL;
/*******
 * This function should be used for measuring application clicks within the site.   Unlimited parameters may be passed.
 * parameters may be string or 1 single array of values.
 *
 * example of use:
 * 		measureApp('home page','personalization','edit');
 * 							OR
 * 		measureApp(['home page','personalization','edit']);
 *
 * formatted url will look like this:    http://domain/home_page_personalization_edit.html
 *
 *
 * @return
 */

function measureApp() {
  var id = "";
  var cm = "";
  mArgs = arguments;

  //console.log('measureApp - args:%o', mArgs);

  //alert(mArgs.length + " actual parameters");
  // If only 1 parameter was passed in, and that
  // parameter contains an array, treat that array
  // as the arguments array.
  fflog(
    "debug",
    "'globalMeasure measureApp:  parameter[0] type:  ',mArgs[0].constructor"
  );
  if (mArgs.length == 1 && mArgs[0].constructor == Array) {
    mArgs = mArgs[0];
  }

  fflog(
    "debug",
    "'globalMeasure measureApp:  interpreted parameters:  ' , mArgs.length"
  );

  if (mArgs[0] == "home page") {
    mArgs[0] = "Home";
  }
  id = "";
  for (i = 0; i < mArgs.length; i++) {
    if (mArgs[i].constructor !== Array) {
      fflog(
        "debug",
        "'globalMeasure measureApp:  mArgs[%d] type:  ', i, mArgs[i].constructor, '  value:  ', mArgs[i]"
      );
      if (i == 0) {
        id += mArgs[i];
      } else {
        id += ":" + mArgs[i];
        cm += ":" + mArgs[i];
      }
    } else {
      fflog(
        "error",
        "'globalMeasure measureApp:  parameter[%d] is an invalid type:  ', i, mArgs[i].constructor"
      );
      return;
    }
  }

  onClick = "";

  if (s.suppressEvent17 == undefined || !s.suppressEvent17) {
    s.events = "event17";
    s.linkTrackEvents = s.apl(s.linkTrackEvents, "event17", ",", 1);
  }
  s.linkTrackVars = s.apl(s.linkTrackVars, "events", ",", 1);

  //Account for Most Popular Photos and Most Popular Videos

  if (mArgs.length == 4) {
    if (mArgs[0] == "Video" && mArgs[3].indexOf("mp4") != -1) {
      s.prop33 = mArgs[3];
      s.linkTrackVars += "prop33";
    }
  }

  if (s.suppressEvent2 != "event17") {
    s.linkTrackVars += ",prop11,prop12,prop13";
  }

  s.tl(this, "o", id);

  // reset linkTrack* values so it's fresh for next call
  s.linkTrackVars = "";
  s.linkTrackEvents = "";

  //fflog('debug',"'globalMeasure:  ',id");
  fflog("debug", '"globalMeasure measureApp:  id value:  ' + id + '"');

  if (cm.indexOf("Message") == -1) {
    if (location.pathname.charAt(location.pathname.length - 1) == "/") {
      cm = location.pathname + "index.html" + cm;
    } else {
      cm = location.pathname + cm;
    }

    var cid = "www";

    if (cm.indexOf("mobile") != -1) {
      cid = "mdot";
    }

    if (typeof cmCreateConversionEventTag == "function") {
      cmCreateConversionEventTag(cm, 2, cid);
    }
  }
}

function measureScoring() {
  s.suppressEvent2 = "event58";
  measureApp.apply(this, arguments);
  s.suppressEvent2 = "no";
}

function measureAutoVideo() {
  s.suppressEvent17 = true;
  measureApp.apply(this, arguments);
  s.suppressEvent17 = false;
}

/*******
 * This function should be used for measuring clicks that take the user away from the site.   One parameter may be passed.
 * This function should ONLY be called from another function where measureApp will not work.
 *
 * example of use:
 * 		measureClick('http://www.facebook.com');
 *          OR
 * 		measureClick('/click/.....');
 *          OR
 * 		measureClick(url);
 *
 * @return
 */
function clickMeasure() {
  cArgs = arguments;

  //alert(cArgs.length + " actual parameters");
  // If only 1 parameter was passed in, and that
  // parameter contains an array, treat that array
  // as the arguments array.
  fflog("debug", "'globalMeasure:  parameter[0] type:  ',cArgs[0].constructor");
  if (cArgs.length == 1 && cArgs[0].constructor == Array) {
    cArgs = cArgs[0];
  }

  fflog("debug", "'globalMeasure:  interpreted parameters:  ' , cArgs.length");

  if (cArgs[0].indexOf("http://") == 0) {
    cUrl = cArgs;
  } else {
    cUrl = "http://" + siteURL + cArgs[0];
  }

  fflog("debug", "'globalMeasure:  ',cUrl");
}

/***
 * function: uptimeMeasure
 * measurement function to measure uptime. Used in conjunction with uptime.js
 */
function uptimeMeasure(info) {
  var page = "";
  var refPage = escape(document.location.href);
  var randomnumber = Math.floor(Math.random() * 9999999999);
  var ck = "";
  ck = getCookieValue("sauid");
  ck = ck ? ck : "";
  page = escape(info);
  clickMeasure(url);
}

/*******
 * function: nextPrevMeasure
 * measurement function for next and previous arrows in gallery
 * @param dir - values are "next" or "print", using "next" to measure both next and previous clicks
 */
var newsImg = new Image();

function nextPrevMeasure(dir) {
  var rannum = Math.random();
  var site = "www";
  if (location.hostname) {
    site = location.hostname + "";
  }
  var loc = document.location + "";
  loc = loc.substring(loc.indexOf("/en_US"), loc.indexOf(".html"));
  var pag = loc + "_" + dir + ".html";
  clickMeasure(url);
}

/***
 * function:  fflog
 * Just a quick function to allow for logging to firebug console.
 *
 * @param type - type of log msg  (error, info, debug, warn)
 * @param msg - message to log
 *
 * Note:  errors always get logged.
 *
 * @return
 */
function fflog(type, msg) {
  if (ffdebug || type == "error") {
    eval("console." + type + "(" + msg + ");");
  }
}
