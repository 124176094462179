import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import EventsLink from 'appdir/components/general/EventsLink';
import classNames from 'classnames';

import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['SubNav'],
		menu_hover: state['MainNav'].menu_hover,
		menu_name: state['MainNav'].menu_name,
		menu_open: state['Header'].menu_open,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	toggleMenu: isOpen => dispatch(deps.actions.Header.toggleMenu(isOpen)),
	hoverMobile: (isHover, menuName) => dispatch(deps.actions.MainNav.hoverMobile(isHover, menuName)),
});

class SubNav extends Component {
	constructor(props) {
		super(props);

		this.subRef = React.createRef();
		this.navClicked = this.navClicked.bind(this);
		// logger.log('[SubNav] constructor this:%o', this);
	}

	navClicked(e, selected, title) {
		// logger.log('[SubNav] navClicked selected:%o, title:%o, props:%o,', selected, title, this.props);

		MeasurementUtils.dispatchMeasurementCall(`SubSection`, {
			//subSection: selected,
			selected: title,
			webview: window.webview ? true : false,
		});

		this.props.toggleMenu(false);
		this.props.hoverMobile(false, e.target.id);
	}

	onUnHover(subMenuName, e) {
		// logger.log('[SubNav] onHover submenu:%o', subMenuName);
		this.props.hoverMobile(false, subMenuName);
		//if (moreClass !== '') {
		//this.state.hoverMobile(false, subMenuName);
		//}
	}

	onHover(subMenuName) {
		// logger.log('[SubNav] onHover submenu:%o, moreClass:%o', subMenuName);
		this.props.hoverMobile(true, subMenuName);
	}

	getParamChar(string) {
		if (string.indexOf('?') > -1) {
			return '&';
		} else {
			return '?';
		}
	}

	// add metrics promo to the link
	getLinkUrl(url, type) {
		return url + this.getParamChar(url) + 'promo=' + type;
	}

	render() {
		// logger.log('[SubNav] render - this:%o', this);
		let mainNavItems = this.props.data ? this.props.data : [];
		let showSubMenuClass = '';
		let _this = this;
		let offset = this.subRef?.current?.offsetTop;

		if (this.props.menu_hover && this.props.mainOpen.includes('menu-opened')) {
			if (this.props.mainOpen.includes('sticky')) {
				showSubMenuClass = 'shown sticky';
			} else {
				showSubMenuClass = 'shown';
			}
		} else {
			showSubMenuClass = '';
		}
		return (
			<div
				className={`submenu-container d-llg-none ${showSubMenuClass} `}
				ref={this.subRef}
				style={{ height: `calc(100vh - ${offset}px)` }}>
				{mainNavItems.map((item, index) => {
					if (item.sub_menu) {
						let subNavItems = item.sub_menu ? item.sub_menu : [];
						let subMenuName = item.title.replace(/\s/g, '');
						let subClasses = classNames('submenu', 'more', subMenuName, 'shown');

						//check for hover menu name
						if (_this.props.menu_name) {
							//if hover menu name exists and matches current array item then display
							if (_this.props.menu_name.subMenuName === subMenuName) {
								return (
									<ul
										key={subMenuName + index}
										data-here="here"
										className={subClasses}
										onClick={e => {
											this.navClicked(e);
										}}
										// onMouseOver={e => this.onHover({ subMenuName }, e)}
										// onMouseOut={e => this.onUnHover({ subMenuName }, e)}
									>
										{subNavItems.map((j, k) => {
											let linkUrl = this.getLinkUrl(j.link.url, 'subnav');
											let selectedClass = '';
											if (window.location.href.includes(linkUrl)) {
												selectedClass = 'selected';
											}
											return (
												<li
													className={`submenu-item ${selectedClass}`}
													key={j + k}
													onClick={e => {
														this.navClicked(e, subMenuName, j.title);
													}}>
													{j.link.type === '' ? (
														<a href={linkUrl} target={j.link.target}>
															{j.title}
														</a>
													) : (
														<EventsLink to={linkUrl}>{j.title}</EventsLink>
													)}
												</li>
											);
										})}
									</ul>
								);
							}
						}
					}
				})}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SubNav);
