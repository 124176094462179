/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import FanWeekHero from './FanWeekHero';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: FanWeek Hero
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state['FanWeekHero'],
	fanweekHeroData: state['Config'].otherData?.fanweek_hero,
	EventsWindow: state['WindowSize'].EventsWindow,
	...props,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FanWeekHero);
