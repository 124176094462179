/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

import EventInfo from './elements/EventInfo';
import EventInfoStatus from './elements/EventInfoStatus';
import EventCourt from './elements/EventCourt';
import MatchDuration from './elements/MatchDuration';
import TeamNameFlag from './elements/TeamNameFlag';
import TeamOneIcon from './elements/TeamOneIcon';
import TeamTwoIcon from './elements/TeamTwoIcon';
import TeamResults from './elements/TeamResults';

import EventsLink from 'appdir/components/general/EventsLink';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import LiveVideo from 'appdir/components/general/LiveVideo';
import { getAppLink } from 'appdir/components/general/Util';
import { getQSParams } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import {
	matchIsFutureState,
	matchIsLiveState,
	matchIsLiveNotInProgress,
	matchIsCompleteState,
	determinePlayerHighlightClass,
} from 'appdir/components/pages/SchedulePage/ScheduleUtils';
import { checkSuppressProfileLink, renderPlayerImage } from './MatchBoxUtils';
import { doMeasurement } from 'appdir/components/general/Util';
import { isDoublesMatch } from 'appdir/components/pages/Slamtracker/Utilities';

import isEqual from 'lodash/isEqual';
import { detailedDiff } from 'deep-object-diff';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ScoreTable
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MatchBox'],
		uom: state['Controller'].scoring.uom,
		favorites: state['Controller'].favorites,
		appFavs: window.location.search ? getQSParams(window.location.search, 'filterPlayers').split(',') : [],
		appShowFavs: window.location.search ? getQSParams(window.location.search, 'showFavs') === 'true' : false,
		liveIndicators: state['ActiveData'].liveIndicators,
		showSTPanel: op.get(state, 'Config.scoring.showSTPanel', false),
		stPanelWindowSize: op.get(state, 'Config.scoring.stPanelWindowSize', 0),
		miMatches: state['Controller'].miMatches,
		windowSizeVal: state['Controller'].windowSizeVal,
		enabled: state['Config']?.enabled,
		drawData: state['Tournament']?.data?.draw,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	mount: () => dispatch(deps.actions.MatchBox.mount()),
	setSTPanelStatus: data => dispatch(deps.actions.Controller.setSTPanelStatus(data)),
});

class MatchBox extends Component {
	constructor(props) {
		// logger.log("[MatchBox] constructor");
		super(props);
		this.state = {
			lastScore_t1: false,
			lastScore_t2: false,
			startAnimation: false,
		};

		this.actualUpdate = false;
		this.serveSpeedUpdate = false;

		// logger.log('[MatchBox] constructor - this:%o', this);
	}

	componentDidMount() {
		this.props.mount();
	}

	componentWillUnmount() {
		clearTimeout(this.t1_timer);
		clearTimeout(this.t2_timer);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[MatchBox] componentDidUpdate - this:%o', this);

		if (!this.props.MatchBoxConfig) {
			this.props.mount();
		}
		const curAttributes = this.props.attributes;

		// logger.log('[MatchBox] componentDidUpdate - curAttributes:%o', curAttributes);

		// logger.log('[MatchBox] componentDidUpdate match:%o team1 curScore:%o this.t1:%o', curAttributes.data.match_id, curAttributes.data.scores.gameScore[0], this.t1_gameScore );
		// logger.log('[MatchBox] componentDidUpdate match:%o team2 curScore:%o this.t2:%o', curAttributes.data.match_id, curAttributes.data.scores.gameScore[1], this.t2_gameScore );

		if (curAttributes.style !== 'upcoming' && curAttributes.mode !== 'schedule') {
			if (!this.t1_gameScore) {
				this.t1_gameScore = curAttributes.data?.scores?.gameScore[0];
			}
			if (!this.t2_gameScore) {
				this.t2_gameScore = curAttributes.data?.scores?.gameScore[1];
			}
			if (!this.serveSpeed) {
				this.serveSpeed = curAttributes?.last_serve_speed;
			}

			if (curAttributes.data?.scores?.gameScore[0] != this.t1_gameScore && !this.state.lastScore_t1) {
				//logger.log('[MatchBox] componentDidUpdate match:%o team1 score changed:%o to %o', curAttributes.data.match_id, this.t1_gameScore, curAttributes.data.scores.gameScore[0] );
				this.setState({
					lastScore_t1: true,
					lastScore_t2: false,
				});
				if (curAttributes.data.scores.gameScore[0]) {
					this.t1_timer = setTimeout(() => this.setState({ lastScore_t1: false }), 5000);
				}

				this.t1_gameScore = curAttributes.data.scores.gameScore[0];
			}

			if (curAttributes.data?.scores?.gameScore[1] != this.t2_gameScore && !this.state.lastScore_t2) {
				//logger.log('[MatchBox] componentDidUpdate match:%o team2 score changed:%o to %o', curAttributes.data.match_id, this.t2_gameScore,curAttributes.data.scores.gameScore[1] );
				this.setState({
					lastScore_t2: true,
					lastScore_t1: false,
				});
				if (curAttributes.data.scores.gameScore[1]) {
					this.t2_timer = setTimeout(() => this.setState({ lastScore_t2: false }), 5000);
				}
				this.t2_gameScore = curAttributes.data.scores.gameScore[1];
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		this.actualUpdate = false;
		this.serveSpeedUpdate = false;
		let propDifferences;

		if (!isEqual(nextProps, this.props)) {
			propDifferences = detailedDiff(nextProps, this.props);

			Object.keys(propDifferences).map((diffType, index) => {
				if (Object.keys(propDifferences[diffType]).length > 0) {
					/** if props were added or deleted, definitely update */
					if (diffType == 'added' || diffType == 'deleted') {
						// if (this.props.attributes.data.match_id == '1224') {
						// 	logger.log('[MatchBox] shouldComponentUpdate - setting this.actualUpdate to true because props have been added or deleted');
						// }
						this.actualUpdate = true;
					} else {
						/** if props were updated and if they are only the functions
						 * from mapDispatchToProps, then don't update
						 * **/
						Object.keys(propDifferences[diffType]).map((key, index) => {
							let keyType = typeof propDifferences[diffType][key];

							if (key == 'attributes') {
								if (this.props.attributes.data.match_id == '1224') {
									logger.log(
										'[MatchBox] shouldComponentUpdate - attributes has updated: attributes:%o',
										propDifferences[diffType][key]?.data?.last_serve_speed
									);
								}
								this.serveSpeedUpdate = true;
							}

							if (
								keyType !== 'function' ||
								(keyType === 'function' &&
									key !== 'navigate' &&
									key !== 'mount' &&
									key !== 'setSTPanelStatus')
							) {
								// if (this.props.attributes.data.match_id == '1224') {
								// 	logger.log('[MatchBox] shouldComponentUpdate - setting this.actualUpdate to true: key:%o, keyType:%o', key, keyType);
								// }
								this.actualUpdate = true;
							}
						});
					}
				}
			});
		}

		/** detect when player's bio link is set to open - re-render to redirect  */
		if (!isEqual(nextState, this.state)) {
			if (this.state.nav !== nextState.nav && nextState.nav) {
				this.actualUpdate = true;
			}
		}

		if (this.actualUpdate === true) {
			return true;
		} else {
			return false;
		}
	}

	onLinkClick = (e, path, pid) => {
		logger.log('[MatchBox] onLinkClick - this.state.attributes:%o, e:%o, path:%o',this.state.attributes, e.target, path);
		e.preventDefault();

		if (typeof webview !== 'undefined') {
			let link = getAppLink(path, '');
			location.href = link.url;
		} else if (path) {
			if (this.props.attributes.isSidepanel) {
				this.props.navigate({ path: path, panelClick: true });
			} else {
				this.setState({
					nav: path,
				});
			}
		}
	};

	openSTPanel = (matchid, status) => {
		logger.log('[Matchbox] openSTPanel - this:%o', this);
		this.props.setSTPanelStatus({ open: true, matchid: matchid, matchstatus: status });
	};

	openWebviewPlayer(path) {
		let link = getAppLink(path, '');
		location.href = link.url;
	}

	/**
	 * 
	 * @param {obj} data 
	 * @returns obj 
	 * 
	 * match.statusCode
	 * 	
		Live Scores/Draws/Schedule:
		A = In Progress
		B = Future
		D = Complete
		E = Retired
		F = Walkover
		G = Default
		K = Suspended
		P = Postponed
		X = Arrive on Court / In Progress (Schedule)
		Y = Warming Up / In Progress (Schedule)
	 */
	getCTA = match => {
		// let useMIButton = matchHasInsights(this.props.miMatches, match.match_id) && !matchIsCompleteState(match);
		let cta = {
			url: null,
			text: null,
			status: null,
		};
		if (match) {
			let slamTrackerUrl = `/en_US/scores/stats/${match?.match_id}.html`;
			// let insightsUrl = `/en_US/matchInsights/${match?.match_id}.html`;
			if (matchIsFutureState(match)) {
				cta.url = slamTrackerUrl;
				cta.text = 'Match Preview';
				cta.status = 'upcoming';
			} else if (matchIsCompleteState(match)) {
				cta.url = slamTrackerUrl;
				cta.text = 'Match Recap';
				cta.status = 'complete';
			} else if (matchIsLiveState(match)) {
				cta.url = slamTrackerUrl;
				cta.text = 'Follow Live';
				cta.status = 'live';
			}
		}
		// logger.log('[MatchBox] getCTA:%o, showLinks:%o, showSTPanel: %o', cta, this.state.attributes.showLinks, this.props.showSTPanel);
		return cta;
	};

	openRadio(stream, debug, promo) {
		let radioWindow = '';
		let radioLaunchPage = '';
		let baseUrl = '/webview/en_US/radio/radio.html';
		let lang = 'en';

		let appProtocol = 'ustaopen';
		if (location.hostname.indexOf('-cdt') > -1) {
			appProtocol = 'ustaopen-cdt';
		} else if (location.hostname.indexOf('ashe.') > -1) {
			appProtocol = 'ustaopen-pre';
		}

		if (this.props.liveIndicators && this.props.liveIndicators.radio) {
			if (window.webview) {
				if (window.webviewPlatform !== 'ios') {
					window.open(baseUrl, '_blank');
				} else {
					document.location.href = `${appProtocol}:///radio`;
				}
			} else {
				var d = new Date();
				var t = d.getTime();
				radioLaunchPage += baseUrl + '?ts=' + t;
				radioLaunchPage += debug && debug.indexOf('debug') > -1 ? '&db=true' : '&db=false';
				radioLaunchPage += '&ref=' + document.location.host + document.location.pathname;
				radioLaunchPage += '&lang=' + lang;
				if (radioWindow.closed || radioWindow == '') {
					//consoleWindow = window.open(consolelaunchPage,"consoleWindow","width=510,height=" + mcWindowHeight + ",top=50,left=50")
					radioWindow = window.open(radioLaunchPage, 'radioWindow', 'width=1024,height=708,top=50,left=50');
					if (radioWindow.opener == null) radioWindow.opener = self;
				}
				radioWindow.focus();
			}
		}
	}

	getId(matchId) {
		let randomNumber = Math.floor(Math.random() * 1000 + 1);
		let randomMatchKey = String(matchId) + String(randomNumber);

		return randomMatchKey;
	}

	getTeamData(which) {
		// logger.log('[MatchBox] renderTeam - this:%o', this);
		let { attributes } = this.props;
		let scoreData = attributes.data;
		let schedule = attributes?.mode == 'schedule' ? true : false;
		let draws = attributes?.mode == 'draws' ? true : false;
		let teamInfo;

		if (which == 'one') {
			if (attributes?.matchType == 'upcoming') {
				teamInfo = scoreData.team1[0];
			} else {
				teamInfo = scoreData.team1;
			}
		} else if (which == 'two') {
			if (attributes?.matchType == 'upcoming') {
				teamInfo = scoreData.team2[0];
			} else {
				teamInfo = scoreData.team2;
			}
		}
		let scoreIndex = which == 'one' ? '0' : '1';
		let suppressProfileLink_a = checkSuppressProfileLink(attributes, teamInfo.idA);
		let suppressProfileLink_b = checkSuppressProfileLink(attributes, teamInfo.idB);

		// logger.log('[MatchBox] renderTeam - suppressProfileLink_a:%o, suppressProfileLink_b:%o', suppressProfileLink_a, suppressProfileLink_b);

		let is_tbd = this.props.attributes?.matchType == 'upcoming' && teamInfo.length > 1 ? true : false;

		let iconClasses = '';
		let animationClass = false;
		let winnerClass = '';
		let latestClass = '';
		let emptySets = [];

		let useAnimation =
			this.props.MatchBoxConfig?.useAnimation === true && this.props.attributes.mode == 'live' ? true : false;

		if (matchIsFutureState(scoreData) || matchIsLiveNotInProgress(scoreData)) {
			iconClasses = 'crticon';
		} else {
			if (which == 'one') {
				iconClasses = classNames(
					'crticon',
					(scoreData.server == 'A' || scoreData.server == 'B') &&
						!scoreData.team1.won &&
						!scoreData.team2.won &&
						matchIsLiveState(scoreData) &&
						!schedule &&
						!draws
						? 'currentServer'
						: '',
					scoreData.team1.won ? 'winner' : ''
				);

				if (
					useAnimation &&
					iconClasses.indexOf('currentServer') > -1 &&
					this?.serveSpeedUpdate === true &&
					scoreData.last_serve_speed
				) {
					// iconClasses = classNames(iconClasses, animationClass);
					animationClass = true;
				}

				if (matchIsCompleteState(scoreData)) {
					winnerClass = classNames(scoreData.team1.won ? 'winner' : 'loser');
				}
				latestClass = this.state.lastScore_t1 && matchIsLiveState(scoreData) ? ' latest' : '';
			} else {
				iconClasses = classNames(
					'crticon',
					(scoreData.server == 'C' || scoreData.server == 'D') &&
						!scoreData.team1.won &&
						!scoreData.team2.won &&
						matchIsLiveState(scoreData) &&
						!schedule &&
						!draws
						? 'currentServer'
						: '',
					scoreData.team2.won ? 'winner' : ''
				);

				if (
					useAnimation &&
					iconClasses.indexOf('currentServer') > -1 &&
					this?.serveSpeedUpdate === true &&
					scoreData.last_serve_speed
				) {
					// iconClasses = classNames(iconClasses, animationClass);
					animationClass = true;
				}

				if (matchIsCompleteState(scoreData)) {
					winnerClass = classNames(scoreData.team2.won ? 'winner' : 'loser');
				}
				latestClass = this.state.lastScore_t2 && matchIsLiveState(scoreData) ? ' latest' : '';
			}

			for (var i = 0; i < 5 - scoreData?.scores?.sets?.length && scoreData?.scores?.sets?.length < 5; i++) {
				emptySets[i] = 5 - i;
			}
		}

		emptySets.reverse();

		let dataObj = {
			team: which,
			teamInfo: teamInfo,
			scoreIndex: scoreIndex,
			suppressProfileLink_a: suppressProfileLink_a,
			suppressProfileLink_b: suppressProfileLink_b,
			iconClasses: iconClasses,
			isTbd: is_tbd,
			iconClasses: iconClasses,
			winnerClass: winnerClass,
			latestClass: latestClass,
			emptySets: emptySets,
			animationClass: animationClass,
			useAnimation: useAnimation,
		};

		return dataObj;
	}

	render() {
		this.scoreData = this.props.attributes.data;
		let boxStyle = this.props.attributes.style ? this.props.attributes.style : '';
		let boxMode = this.props.attributes.mode || '';
		let { MatchBoxConfig } = this.props;
		let isDoubles = this.scoreData ? isDoublesMatch(this.scoreData) : false;
		let schedule = this.props.attributes?.mode == 'schedule' ? true : false;
		let draws = this.props.attributes?.mode == 'draws' ? true : false;
		let showLinks = this.props.attributes.showLinks;
		let useLive = this.props.attributes.useLive ? this.props.attributes.useLive : false;
		let useRadio = this.props.attributes.useRadio ? this.props.attributes.useRadio : false;
		let { usePlayerImages } = this.props.attributes;
		let { uom } = this.props;
		let liveVideo = this.props.attributes.liveVideo ? this.props.attributes.liveVideo : false;

		//stats level only set after match complete, so won't allow active pre/during match
		let active = this.scoreData.team1.idA && this.scoreData.team2.idA;
		//let slamtrackerActive = (active && scoreData.statsLevel !== null && scoreData.statsLevel !== 'N');
		let slamtrackerActive = active && this.scoreData.courtId && MatchBoxConfig?.stLive;
		// ^^ pretty sure court is needed because it must be a scheduled match

		/** get CTA link and text */
		let cta = this.getCTA(this.scoreData);

		let keyValue = this.scoreData.match_id;

		if (this.state?.lastScore_t1 === true || this.state?.lastScore_t2 === true) {
			keyValue = this.getId(this.scoreData.match_id);
		}

		let team1Data = this.getTeamData('one');
		let team2Data = this.getTeamData('two');

		// logger.log('[MatchBox] render - this:%o, match_id:%o', this, this.scoreData.match_id);
		// if (this.scoreData.match_id == '1224') {
		// 	logger.log('[MatchBox] render - this:%o', this);
		// }

		if (this.state.nav) {
			return <EventsRedirect to={this.state.nav} />;
		}

		let useSTPanelLinks =
			this.props.showSTPanel && !window.webview && this.props.windowSizeVal >= this.props.stPanelWindowSize
				? true
				: false;

    /** Allow slamtracker to be shown in mobile*/
    // let useSTPanelLinks =
		// 	this.props.showSTPanel && !window.webview
		// 		? true
		// 		: false;

		if (boxStyle == 'upcoming') {
			/**
			 * RLL added in 2022 to account for upcoming state of slamtracker
			 * panel - might be able to use this on slamtracker pages at a later
			 * time
			 **/

			// logger.log('[MatchBox] render - this:%o', this);

			let { usePlayerImages } = this.props.attributes;
			let { uom } = this.props;

			return MatchBoxConfig ? (
				<div className={`match-box-container${usePlayerImages ? ' usePlayerImages' : ''} ${boxMode}`}>
					{usePlayerImages
						? renderPlayerImage('one', this.props.attributes, this.props.MatchBoxConfig)
						: null}
					<div
						className={`match-box ${boxStyle}`}
						data-match={this.scoreData.match_id}
						data-event={this.scoreData.eventCode}
						data-court={this.scoreData.courtId}>
						<div className={`match-status ${boxStyle}`}>
							<div className="event-info">
								<EventInfo
									key={`match-${this.scoreData.match_id}-EventInfo`}
									matchId={this.scoreData.match_id}
									event={this.props.attributes?.event || null}
									isFeaturedMatch={this.props?.isFeaturedMatch || false}
									round={this.props.attributes?.round || null}
									roundName={this.props.attributes?.data?.roundName || null}
									roundNameShort={this.props.attributes?.data?.roundNameShort || null}
									comments={this.props.scoreData?.comment || null}
									style={boxStyle}
									eventCode={this.props.scoreData?.eventCode || null}
									mode={boxMode}
								/>
								<EventInfoStatus
									key={`match-${this.scoreData.match_id}-EventInfoStatus`}
									data={{
										attributes: this.props.attributes,
										uom: uom,
										sumscores: false,
									}}
								/>
							</div>
							<div className="match-box-border">
								<div className="event-court">
									<EventCourt
										key={`match-${this.scoreData.match_id}-EventCourt`}
										matchId={this.scoreData.match_id}
										isFinal={this.props?.attributes?.isFinal || false}
										sumscores={false}
										eventName={this.props?.attributes?.data?.eventName || ''}
										courtName={this.props?.attributes?.data?.courtName || ''}
									/>
									<MatchDuration
										key={`match-${this.scoreData.match_id}-MatchDuration`}
										duration={this.scoreData.duration}
									/>
								</div>

								<div className="prematch-container">
									<div className="prematch-teams">
										{/* team one starts */}
										<div
											className={`team-info team-${team1Data.team} ${
												isDoubles ? 'doubles' : 'singles'
											}
												${team1Data.winnerClass}
												${determinePlayerHighlightClass(
													team1Data.teamInfo.idA,
													!window.webview ? this.props?.favorites : this.props?.appFavs,
													this.props.attributes?.searchedPlayer,
													this.props?.appShowFavs
												)}
												${determinePlayerHighlightClass(
													team1Data.teamInfo.idB,
													!window.webview ? this.props?.favorites : this.props?.appFavs,
													this.props.attributes?.searchedPlayer,
													this.props?.appShowFavs
												)}
											`}
											>
											<div className="team-name">
												<div
													onClick={
														!team1Data.suppressProfileLink_a &&
														!team1Data.isTbd &&
														team1Data.teamInfo.idA
															? e => {
																	this.onLinkClick(
																		e,
																		`/en_US/players/overview/${team1Data.teamInfo.idA}.html`
																	);
															  }
															: null
													}
													className={`member-one ${
														!window.webview &&
														this.props?.favorites?.show &&
														this.props?.favorites?.players.indexOf(
															team1Data.teamInfo.idA
														) !== -1
															? 'favorite'
															: ''
													}`}>
													<TeamNameFlag
														key={`${
															this.props.attributes.isFeaturedMatch ? 'st-' : ''
														}match-${this.scoreData}-TeamNameFlag-team-${
															team1Data.team
														}-player1`}
														matchId={this.scoreData.match_id}
														flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
														name={team1Data.teamInfo.displayNameA}
														country={
															!Array.isArray(team1Data.teamInfo.nationA)
																? team1Data.teamInfo.nationA
																: team1Data.teamInfo.nationA[0]
														}
														seed={team1Data.teamInfo.seed}
														isTbd={team1Data.isTbd}
													/>
												</div>
												{isDoubles ? (
													<div
														onClick={
															!team1Data.suppressProfileLink_b &&
															!team1Data.isTbd &&
															team1Data.teamInfo.idB
																? e => {
																		this.onLinkClick(
																			e,
																			`/en_US/players/overview/${team1Data.teamInfo.idB}.html`
																		);
																  }
																: null
														}
														className={`member-two ${
															!window.webview &&
															this.props?.favorites?.show &&
															this.props?.favorites?.players.indexOf(
																team1Data.teamInfo.idB
															) !== -1
																? 'favorite'
																: ''
														}`}>
														<TeamNameFlag
															key={`${
																this.props.attributes.isFeaturedMatch ? 'st-' : ''
															}match-${this.scoreData.match_id}-TeamNameFlag-team-${
																team1Data.team
															}-player2`}
															matchId={this.scoreData.match_id}
															flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
															name={team1Data.teamInfo.displayNameB}
															country={
																!Array.isArray(team1Data.teamInfo.nationB)
																	? team1Data.teamInfo.nationB
																	: team1Data.teamInfo.nationB[0]
															}
															seed={team1Data.teamInfo.seed}
															isTbd={team1Data.isTbd}
														/>
													</div>
												) : null}
											</div>
										</div>
										{/* team one ends */}

										{/* team two starts */}
										<div
											className={`team-info team-${team2Data.team} ${
												isDoubles ? 'doubles' : 'singles'
											}
												${team2Data.winnerClass}
												${determinePlayerHighlightClass(
													team2Data.teamInfo.idA,
													!window.webview ? this.props?.favorites : this.props?.appFavs,
													this.props.attributes?.searchedPlayer,
													this.props?.appShowFavs
												)}
												${determinePlayerHighlightClass(
													team2Data.teamInfo.idB,
													!window.webview ? this.props?.favorites : this.props?.appFavs,
													this.props.attributes?.searchedPlayer,
													this.props?.appShowFavs
												)}
											`}
											>
											<div className="team-name">
												<div
													onClick={
														!team2Data.suppressProfileLink_a &&
														!team2Data.isTbd &&
														team2Data.teamInfo.idA
															? e => {
																	this.onLinkClick(
																		e,
																		`/en_US/players/overview/${team2Data.teamInfo.idA}.html`
																	);
															  }
															: null
													}
													className={`member-one ${
														!window.webview &&
														this.props?.favorites?.show &&
														this.props?.favorites?.players.indexOf(
															team2Data.teamInfo.idA
														) !== -1
															? 'favorite'
															: ''
													}`}>
													<TeamNameFlag
														key={`${
															this.props.attributes.isFeaturedMatch ? 'st-' : ''
														}match-${this.scoreData.match_id}-TeamNameFlag-team-${
															team2Data.team
														}-player1`}
														matchId={this.scoreData.match_id}
														flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
														name={team2Data.teamInfo.displayNameA}
														country={
															!Array.isArray(team2Data.teamInfo.nationA)
																? team2Data.teamInfo.nationA
																: team2Data.teamInfo.nationA[0]
														}
														seed={team2Data.teamInfo.seed}
														isTbd={team2Data.isTbd}
													/>
												</div>
												{isDoubles ? (
													<div
														onClick={
															!team2Data.suppressProfileLink_b &&
															!team2Data.isTbd &&
															team2Data.teamInfo.idB
																? e => {
																		this.onLinkClick(
																			e,
																			`/en_US/players/overview/${team2Data.teamInfo.idB}.html`
																		);
																  }
																: null
														}
														className={`member-two ${
															!window.webview &&
															this.props?.favorites?.show &&
															this.props?.favorites?.players.indexOf(
																team2Data.teamInfo.idB
															) !== -1
																? 'favorite'
																: ''
														}`}>
														<TeamNameFlag
															key={`${
																this.props.attributes.isFeaturedMatch ? 'st-' : ''
															}match-${this.scoreData.match_id}-TeamNameFlag-team-${
																team2Data.team
															}-player2`}
															matchId={this.scoreData.match_id}
															flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
															name={team2Data.teamInfo.displayNameB}
															country={
																!Array.isArray(team2Data.teamInfo.nationB)
																	? team2Data.teamInfo.nationB
																	: team2Data.teamInfo.nationB[0]
															}
															seed={team2Data.teamInfo.seed}
															isTbd={team2Data.isTbd}
														/>
													</div>
												) : null}
											</div>
										</div>
										{/* team two ends */}
									</div>
									<div className="prematch-button side">
										<EventsLink
											// to="https://www.ticketmaster.com/usopentennis"
                      to="https://www.usopen.org/en_US/tickets/individual_tickets.html"
											external={true}
											style="blue-button">
											Find Tickets
										</EventsLink>
									</div>
								</div>
							</div>
							<div className="prematch-button bottom">
								<EventsLink
									// to="https://www.ticketmaster.com/usopentennis"
                  to="https://www.usopen.org/en_US/tickets/individual_tickets.html"
									external={true}
									style="blue-button">
									Find Tickets
								</EventsLink>
							</div>
						</div>
					</div>
					{usePlayerImages
						? renderPlayerImage('two', this.props.attributes, this.props.MatchBoxConfig)
						: null}
				</div>
			) : null;
		} else if (this.scoreData.scores.sets) {
			/**
			 * RLL 8/23/2022
			 *
			 * Matchbox button configuration
			 *
			 * stLive: variable to let us know slamtracker and slamtracker pages are active
			 * useAnimation: boolean to determine whether or not to add animation class to score tables
			 * useSTPanelLinks:
			 * 	- checks showSTPanel (should we show the slamtracker panel) value
			 * 	- checks window.webview (for us open 2023 panel is not used on webviews) value
			 *  - compares windowSizeVal with stPanelWindowSize (the minimum window size to show the slamtracker panel)
			 */

			// logger.log('[MatchBox] render - team1Data:%o', team1Data);

			return MatchBoxConfig ? (
				<div
					className={`${
						usePlayerImages ? 'match-box-container usePlayerImages' : 'match-box-container'
					} ${boxMode}`}
					key={keyValue}>
					{usePlayerImages
						? renderPlayerImage('one', this.props.attributes, this.props.MatchBoxConfig)
						: null}
					<div
						className={`match-box ${cta.status}`}
						data-match={this.scoreData.match_id}
						data-event={this.scoreData.eventCode}
						data-court={this.scoreData.courtId}>
						<div className={`match-status ${cta.status}`}>
							<div className="event-info">
								<EventInfo
									key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
										this.scoreData.match_id
									}-EventInfo`}
									matchId={this.scoreData.match_id}
									event={this.props.attributes?.event || null}
									isFeaturedMatch={this.props?.isFeaturedMatch || false}
									round={this.props.attributes?.round || null}
									roundName={this.props.attributes?.data?.roundName || null}
									roundNameShort={this.props.attributes?.data?.roundNameShort || null}
									comments={this.props.scoreData?.comment || null}
									style={boxStyle}
									eventCode={this.props.scoreData?.eventCode || null}
									mode={boxMode}
								/>
								<EventInfoStatus
									key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
										this.scoreData.match_id
									}-EventInfoStatus`}
									data={{
										attributes: this.props.attributes,
										uom: uom,
										sumscores: false,
									}}
								/>
							</div>
							<div className="match-box-border">
								<div className="event-court">
									<EventCourt
										key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
											this.scoreData.match_id
										}-EventCourt`}
										matchId={this.scoreData.match_id}
										isFinal={this.props?.attributes?.isFinal || false}
										sumscores={false}
										eventName={this.props?.attributes?.data?.eventName || ''}
										courtName={this.props?.attributes?.data?.courtName || ''}
									/>
									<MatchDuration
										key={`match-${this.scoreData.match_id}-MatchDuration`}
										matchId={this.scoreData.match_id}
										duration={this.scoreData.duration}
									/>
								</div>

								{/* team one starts */}
								<div
									className={`team-info team-${team1Data.team} ${isDoubles ? 'doubles' : 'singles'}
										${team1Data.winnerClass}
										${determinePlayerHighlightClass(
											team1Data.teamInfo.idA,
											!window.webview ? this.props?.favorites : this.props?.appFavs,
											this.props.attributes?.searchedPlayer,
											this.props?.appShowFavs
										)}
										${determinePlayerHighlightClass(
											team1Data.teamInfo.idB,
											!window.webview ? this.props?.favorites : this.props?.appFavs,
											this.props.attributes?.searchedPlayer,
											this.props?.appShowFavs
										)}
									`}
									>
									<div className="team-name">
										<div
											onClick={
												!team1Data.suppressProfileLink_a &&
												!team1Data.isTbd &&
												team1Data.teamInfo.idA
													? e => {
															this.onLinkClick(
																e,
																`/en_US/players/overview/${team1Data.teamInfo.idA}.html`
															);
													  }
													: null
											}
											className={`member-one ${
												!window.webview &&
												this.props?.favorites?.show &&
												this.props?.favorites?.players.indexOf(team1Data.teamInfo.idA) !== -1
													? 'favorite'
													: ''
											}`}>
											<TeamNameFlag
												key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
													this.scoreData
												}-TeamNameFlag-team-${team1Data.team}-player1`}
												matchId={this.scoreData.match_id}
												flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
												name={team1Data.teamInfo.displayNameA}
												country={
													!Array.isArray(team1Data.teamInfo.nationA)
														? team1Data.teamInfo.nationA
														: team1Data.teamInfo.nationA[0]
												}
												seed={team1Data.teamInfo.seed}
												isTbd={team1Data.isTbd}
											/>
										</div>
										{isDoubles ? (
											<div
												onClick={
													!team1Data.suppressProfileLink_b &&
													!team1Data.isTbd &&
													team1Data.teamInfo.idB
														? e => {
																this.onLinkClick(
																	e,
																	`/en_US/players/overview/${team1Data.teamInfo.idB}.html`
																);
														  }
														: null
												}
												className={`member-two ${
													!window.webview &&
													this.props?.favorites?.show &&
													this.props?.favorites?.players.indexOf(team1Data.teamInfo.idB) !==
														-1
														? 'favorite'
														: ''
												}`}>
												<TeamNameFlag
													key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
														this.scoreData.match_id
													}-TeamNameFlag-team-${team1Data.team}-player2`}
													matchId={this.scoreData.match_id}
													flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
													name={team1Data.teamInfo.displayNameB}
													country={
														!Array.isArray(team1Data.teamInfo.nationB)
															? team1Data.teamInfo.nationB
															: team1Data.teamInfo.nationB[0]
													}
													seed={team1Data.teamInfo.seed}
													isTbd={team1Data.isTbd}
												/>
											</div>
										) : null}
									</div>

									{boxStyle != 'upcoming' ? (
										<div className="match-results">
											<div className="results-wrapper">
												<TeamOneIcon
													key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
														this.scoreData.match_id
													}-TeamOneIcon-team-${team1Data.team}`}
													matchId={this.scoreData.match_id}
													iconClasses={team1Data.iconClasses}
													useAnimation={team1Data.useAnimation}
													serveSpeed={this.scoreData.last_serve_speed}
													team={team1Data.team}
												/>
												<TeamResults
													key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
														this.scoreData.match_id
													}-TeamResults-team-${team1Data.team}`}
													matchId={this.scoreData.match_id}
													data={{
														attributes: this.props.attributes,
														scoreIndex: team1Data.scoreIndex,
														sumscores: false,
														schedule: schedule,
														draws: draws,
														latestClass: team1Data.latestClass,
														scores: this.scoreData.scores,
														emptySets: team1Data.emptySets,
													}}
												/>
											</div>
										</div>
									) : null}
								</div>
								{/* team one ends */}

								{/* team two starts */}
								<div
									className={`team-info team-${team2Data.team} ${isDoubles ? 'doubles' : 'singles'}
										${team2Data.winnerClass}
										${determinePlayerHighlightClass(
											team2Data.teamInfo.idA,
											!window.webview ? this.props?.favorites : this.props?.appFavs,
											this.props.attributes?.searchedPlayer,
											this.props?.appShowFavs
										)}
										${determinePlayerHighlightClass(
											team2Data.teamInfo.idB,
											!window.webview ? this.props?.favorites : this.props?.appFavs,
											this.props.attributes?.searchedPlayer,
											this.props?.appShowFavs
										)}
									`}
									>
									<div className="team-name">
										<div
											onClick={
												!team2Data.suppressProfileLink_a &&
												!team2Data.isTbd &&
												team2Data.teamInfo.idA
													? e => {
															this.onLinkClick(
																e,
																`/en_US/players/overview/${team2Data.teamInfo.idA}.html`
															);
													  }
													: null
											}
											className={`member-one ${
												!window.webview &&
												this.props?.favorites?.show &&
												this.props?.favorites?.players.indexOf(team2Data.teamInfo.idA) !== -1
													? 'favorite'
													: ''
											}`}>
											<TeamNameFlag
												key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
													this.scoreData.match_id
												}-TeamNameFlag-team-${team2Data.team}-player1`}
												matchId={this.scoreData.match_id}
												flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
												name={team2Data.teamInfo.displayNameA}
												country={
													!Array.isArray(team2Data.teamInfo.nationA)
														? team2Data.teamInfo.nationA
														: team2Data.teamInfo.nationA[0]
												}
												seed={team2Data.teamInfo.seed}
												isTbd={team2Data.isTbd}
											/>
										</div>
										{isDoubles ? (
											<div
												onClick={
													!team2Data.suppressProfileLink_b &&
													!team2Data.isTbd &&
													team2Data.teamInfo.idB
														? e => {
																this.onLinkClick(
																	e,
																	`/en_US/players/overview/${team2Data.teamInfo.idB}.html`
																);
														  }
														: null
												}
												className={`member-two ${
													!window.webview &&
													this.props?.favorites?.show &&
													this.props?.favorites?.players.indexOf(team2Data.teamInfo.idB) !==
														-1
														? 'favorite'
														: ''
												}`}>
												<TeamNameFlag
													key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
														this.scoreData.match_id
													}-TeamNameFlag-team-${team2Data.team}-player2`}
													matchId={this.scoreData.match_id}
													flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
													name={team2Data.teamInfo.displayNameB}
													country={
														!Array.isArray(team2Data.teamInfo.nationB)
															? team2Data.teamInfo.nationB
															: team2Data.teamInfo.nationB[0]
													}
													seed={team2Data.teamInfo.seed}
													isTbd={team2Data.isTbd}
												/>
											</div>
										) : null}
									</div>
									{boxStyle != 'upcoming' ? (
										<div className="match-results">
											<div className="results-wrapper">
												<TeamTwoIcon
													key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
														this.scoreData.match_id
													}-TeamTwoIcon-team-${team2Data.team}`}
													matchId={this.scoreData.match_id}
													iconClasses={team2Data.iconClasses}
													useAnimation={team2Data.useAnimation}
													serveSpeed={this.scoreData.last_serve_speed}
													team={team2Data.team}
												/>
												<TeamResults
													key={`${this.props.attributes.isFeaturedMatch ? 'st-' : ''}match-${
														this.scoreData.match_id
													}-TeamResults-team-${team2Data.team}`}
													matchId={this.scoreData.match_id}
													data={{
														attributes: this.props.attributes,
														scoreIndex: team2Data.scoreIndex,
														sumscores: false,
														schedule: schedule,
														draws: draws,
														latestClass: team2Data.latestClass,
														scores: this.scoreData.scores,
														emptySets: team2Data.emptySets,
													}}
												/>
											</div>
										</div>
									) : null}
								</div>
								{/* team two ends */}
							</div>
						</div>

						{slamtrackerActive && !MatchBoxConfig.stLive && cta?.url && cta?.text ? (
							<div className="match-box-buttons">
								<div className={`slamtracker-button blue-button ${cta.status}`}>
									<div className="slamtracker-button_text">
										{cta.text}
										<div className="arrow-icon">
											<i className="icon-arrow-right" />
											<i className="icon-arrow-right arrow" />
										</div>
									</div>
								</div>
							</div>
						) : null}
						{showLinks && slamtrackerActive && MatchBoxConfig.stLive && useSTPanelLinks && cta?.status ? (
							<div className="match-box-buttons">
								<div
									className={`slamtracker-button blue-button ${cta.status}`}
									onClick={e => {
										this.openSTPanel(this.scoreData.match_id, cta.status);
									}}>
									<div className="slamtracker-button_text">
										{cta.text}
										<div className="arrow-icon">
											<i className="icon-arrow-right" />
											<i className="icon-arrow-right arrow" />
										</div>
									</div>
								</div>
							</div>
						) : null}
						{showLinks &&
						slamtrackerActive &&
						this.props.MatchBoxConfig.stLive &&
						!useSTPanelLinks &&
						cta?.url &&
						cta?.text ? (
							<div className="match-box-buttons">
								<div
									className={`slamtracker-button blue-button ${cta.status}`}
									onClick={e => {
										this.onLinkClick(e, cta?.url);
									}}>
									<div className="slamtracker-button_text">
										{cta.text}
										<div className="arrow-icon">
											<i className="icon-arrow-right" />
											<i className="icon-arrow-right arrow" />
										</div>
									</div>
								</div>
							</div>
						) : null}
						{useLive &&
						(this.props?.liveIndicators?.video === true || this.props?.liveIndicators?.radio === true) ? (
							<div className="live-buttons">
								{liveVideo &&
								this.props?.liveIndicators?.video === true &&
								typeof webview === 'undefined' ? (
									<ErrorBoundary message="Live Video is not available for this match">
										<LiveVideo
											courtId={this.scoreData.courtId}
											location="matchbox"
											icon={
												this.props.liveIndicators && this.props.liveIndicators.video
													? 'icon-pagenation-dot-selected'
													: 'icon-pagenation-dot'
											}
											panel={this.props.attributes.isSidepanel}
											onClick={this.onLinkClick}
										/>
									</ErrorBoundary>
								) : null}
								{useRadio && this.props?.liveIndicators?.radio === true ? (
									<div
										className={`live-radio ${
											this.props.attributes.isSidepanel !== true ? 'blue-button' : ''
										} ${
											this.props.liveIndicators && this.props.liveIndicators.radio
												? ''
												: 'disabled'
										}`}
										onClick={() => {
											this.openRadio(0);
										}}>
										<i
											className={
												this.props.liveIndicators && this.props.liveIndicators.radio
													? 'icon-pagenation-dot-selected'
													: 'icon-pagenation-dot'
											}></i>
										LISTEN
									</div>
								) : null}
							</div>
						) : null}
					</div>
					{usePlayerImages
						? renderPlayerImage('two', this.props.attributes, this.props.MatchBoxConfig)
						: null}
				</div>
			) : null;
		} else {
			logger.log('[MatchBox] no match data - this:%o', this);
			// return <div>No Match Data</div>;

			return (
				<div className="match-box error">
					<div className="match-status">
						<div className="event-info"></div>
						<div className="match-box-border">
							<div className="event-court"></div>
							<div className="team-info team-one singles">
								<div className="team-name">
									<div className="member-one"></div>
									<div className="member-two"></div>
								</div>
							</div>
							<div className="team-info team-two singles">
								<div className="team-name">
									<div className="member-one"></div>
									<div className="member-two"></div>
								</div>
							</div>
							<div className="prematch-button bottom"></div>
						</div>
						<div className="error-message">No Match Data</div>
					</div>
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchBox);
