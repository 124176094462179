import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default (state = {}, action) => {
	let newState;
	let filters;
	switch (action.type) {
		case deps.actionTypes.PLAYERSTATSPAGE_LOAD:
			newState = {
				...state,
				...action.data,
				updated: moment().toISOString(),
			};
			// logger.log('[PlayerStatsPage] PLAYERSTATSPAGE_LOAD - newState:% o', newState);
			return newState;

		case deps.actionTypes.PLAYERSTATSPAGE_FILTER:
			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
			};
			//logger.log('[PlayerStatsPage] PLAYERSTATSPAGE_FILTER - newState:%o', newState);
			return newState;

		case deps.actionTypes.PLAYERSTATSPAGE_SETGENDER:
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[PlayerStatsPage] PLAYERSTATSPAGE_SETGENDER - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
