/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { fetch, getQuerystringValues } from 'appdir/components/general/Util';
import { hasInsights } from 'components/common-ui/PowerRanking/Utils';

import MISectionHeader from './MISectionHeader';
import ErrorBoundary from 'components/general/ErrorBoundary';

/**
 * -----------------------------------------------------------------------------
 * React Component: WinFactors for Match Insights
 *
 *  props = {
 *             matchId
 *             hideTitle --- boolean
 *           }
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['WinFactors'],
		configOtherData: state['Config'].otherData,
		windowSize: state['Controller'].miWindowSize,
		innovationTest: state['Config'].innovationTest,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class WinFactors extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null,
		};

		this.firstLoad = true;
		this.winFactorsLoaded = false;
		this._isMounted = false;
		// logger.log('[MatchInsights] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentDidMount() {
		this.matchId = this.props?.matchId;
		this._isMounted = true;

		/** ?test=true to fetch feeds from config.innovationTest obj
		 */
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		this.isInnovationTest = this.parsedQs?.test;

		if (this._isMounted) {
			this.setState({
				status: 'loaded',
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		/** fetch the available matches feed */
		if (this.firstLoad && !this.state.winFactorsMatches && this.state.status == 'loaded') {
			let winFactorsMatchesPath = this.props?.configOtherData?.matchInsights?.winFactorsMatches;

			if (this.isInnovationTest) {
				winFactorsMatchesPath = this.props?.innovationTest?.matchInsightscloudData?.winFactorsMatches;
			}

			this.firstLoad = false;

			fetch(winFactorsMatchesPath)
				.then(result => {
					// logger.log('[WinFactors] componentDidUpdate WinfactorsMatches result:%o', result);

					this.setState({
						winFactorsMatches: result?.matches?.length > 0 ? result.matches : [],
					});
				})
				.catch(error => {
					logger.error('[WinFactors] componentDidUpdate error:%o', error);

					this.setState({
						winFactorsMatches: 'error',
					});
				});
		}

		/** [TODO] Update the matchID */
		// this.matchId = '1101'; // for testing

		if (
			this.state.winFactorsMatches &&
			this.state.winFactorsMatches !== 'error' &&
			this.state?.winFactorsMatches?.length > 0 &&
			!this.winFactorsLoaded &&
			hasInsights(this.state.winFactorsMatches, this.matchId)
		) {
			/** update the path to use dynamic matchId */
			let winFactorsPath = this.props?.configOtherData?.matchInsights?.winFactors.replace(
				'<matchId>',
				this.matchId
			);

			if (this.isInnovationTest) {
				winFactorsPath = this.props?.innovationTest?.matchInsightscloudData?.winFactors.replace(
					'<matchId>',
					this.matchId
				);
			}

			this.winFactorsLoaded = true;

			fetch(winFactorsPath)
				.then(result => {
					// logger.log('[WinFactors] componentDidUpdate WinFactors result:%o', result);

					this.setState({
						winFactorsData: result,
					});
				})
				.catch(error => {
					logger.error('[WinFactors] componentDidUpdate error:%o', error);

					this.setState({
						winFactorsData: 'error',
					});
				});
		} else if (!this.winFactorsLoaded && this.state.winFactorsMatches && this.state.winFactorsMatches !== 'error') {
			this.winFactorsLoaded = true;

			this.setState({
				winFactorsData: {},
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		/** fetch the available matches feed */
		if (this.firstLoad && !this.state.winFactorsMatches && this.state.status == 'loaded') {
			let winFactorsMatchesPath = this.props?.configOtherData?.matchInsights?.winFactorsMatches;

			if (this.isInnovationTest) {
				winFactorsMatchesPath = this.props?.innovationTest?.matchInsightscloudData?.winFactorsMatches;
			}

			this.firstLoad = false;

			fetch(winFactorsMatchesPath)
				.then(result => {
					// logger.log('[WinFactors] componentDidUpdate WinfactorsMatches result:%o', result);

					this.setState({
						winFactorsMatches: result?.matches?.length > 0 ? result.matches : [],
					});
				})
				.catch(error => {
					logger.error('[WinFactors] componentDidUpdate error:%o', error);

					this.setState({
						winFactorsMatches: 'error',
					});
				});
		}

		/** [TODO] Update the matchID */
		// this.matchId = '1101'; // for testing

		if (
			this.state.winFactorsMatches &&
			this.state.winFactorsMatches !== 'error' &&
			this.state?.winFactorsMatches?.length > 0 &&
			!this.winFactorsLoaded &&
			hasInsights(this.state.winFactorsMatches, this.matchId)
		) {
			/** update the path to use dynamic matchId */
			let winFactorsPath = this.props?.configOtherData?.matchInsights?.winFactors.replace(
				'<matchId>',
				this.matchId
			);

			if (this.isInnovationTest) {
				winFactorsPath = this.props?.innovationTest?.matchInsightscloudData?.winFactors.replace(
					'<matchId>',
					this.matchId
				);
			}

			this.winFactorsLoaded = true;

			fetch(winFactorsPath)
				.then(result => {
					// logger.log('[WinFactors] componentDidUpdate WinFactors result:%o', result);

					this.setState({
						winFactorsData: result,
					});
				})
				.catch(error => {
					logger.error('[WinFactors] componentDidUpdate error:%o', error);

					this.setState({
						winFactorsData: 'error',
					});
				});
		} else if (!this.winFactorsLoaded && this.state.winFactorsMatches && this.state.winFactorsMatches !== 'error') {
			this.winFactorsLoaded = true;

			this.setState({
				winFactorsData: {},
			});
		}
	}

	getIcon = key => {
		let iconClass = '';

		switch (key.toLowerCase()) {
			case 'overall_domain_power_index':
				iconClass = 'likes';
				break;
			case 'age_years':
			case 'ageyears':
				iconClass = 'arrows-up';
				break;
			case 'ratio_games_won':
				iconClass = 'checkmark';
				break;
			case 'net_sets_won':
				iconClass = 'head-to-head';
				break;
			case 'rank':
			case 'wta_rank':
			case 'atp_rank':
				iconClass = 'rank';
				break;
			case 'match_quality_wins':
				iconClass = 'ball';
				break;
			case 'scaled_watson_power_index':
				iconClass = 'arrow-out';
				break;
			case 'surface_domain_index':
				iconClass = 'court';
				break;
			default:
				break;
		}

		return iconClass;
	};

	getPriority = key => {
		let priority = 100;

		switch (key.toLowerCase()) {
			case 'match_quality_wins':
				priority = 0;
				break;
			case 'scaled_watson_power_index':
				priority = 1;
				break;
			case 'net_sets_won':
				priority = 2;
				break;
			case 'surface_domain_index':
				priority = 3;
				break;
			case 'age_years':
			case 'ageyears':
				priority = 4;
				break;
			case 'ratio_games_won':
				priority = 5;
				break;
			case 'overall_domain_power_index':
				priority = 6;
				break;
			case 'rank':
			case 'wta_rank':
			case 'atp_rank':
				priority = 7;
				break;
			default:
				break;
		}

		return priority;
	};

	render() {
		let klass = 'mi-win-factors ';

		let lastUpdate = this.state.winFactorsData?.last_update ? this.state.winFactorsData.last_update : '';

		this.sortedWinFactors = [];
		/** sort Win Factors data vased on factors */
		if (this.state?.winFactorsData?.sentences) {
			Object.keys(this.state?.winFactorsData?.sentences).map(keyName => {
				this.sortedWinFactors[this.getPriority(keyName)] = {
					[keyName]: this.state.winFactorsData.sentences[keyName],
				};
			});
		}

		// logger.log('[WinFactors] this:%o', this);

		return (
			<ErrorBoundary message="Win Factors are not currently available for this match" klass="mi-section">
				<div className={klass}>
					<MISectionHeader
						title="Win Factors"
						lastUpdate={lastUpdate}
						lastUpdatePos="right"
						hideTitle={this.props.hideTitle ? this.props.hideTitle : false}
						style={this.props?.headerClass ? this.props.headerClass : null}
					/>

					{(this.state.status == 'loaded' && isEmpty(this.state?.winFactorsData)) ||
					isEmpty(this.state?.winFactorsData?.sentences) ? (
						<div className="mi-inner-body">
							<div className="row">There is no data for this match</div>
						</div>
					) : this.state.status == 'loaded' ? (
						<div className="mi-inner-body">
							{this.sortedWinFactors.length > 0
								? this.sortedWinFactors.map((factor, index) => {
										/** keyName -- Object.keys(factor)[0] */
										return (
											<div className="row" key={`winFactors ${factor} ${index}`}>
												<div
													className={`col icon ${this.getIcon(
														Object.keys(factor)[0]
													)}`}></div>
												<div className="col copy">{factor[Object.keys(factor)[0]]}</div>
											</div>
										);
								  })
								: null}
						</div>
					) : null}
				</div>
			</ErrorBoundary>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WinFactors);
