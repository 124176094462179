import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		logger.log('[Schedule] actions.mount - ');

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				status: 'load',
				pageHeader: Config.tournamentSchedule,
				schedulePath: Config.cmsData.tournamentSchedule,
				adConfig: Config.adConfig,
			};

			logger.log('[Schedule] action.mount - alldata: %o', data);

			dispatch({ type: deps.actionTypes.SCHEDULE_LOAD, data: data });
		});
	},
};
