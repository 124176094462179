/**
 DrawAnalysisHelp Initial State
*/
/**
 *  help ------ show (true) /hide (false) Help overlay
 *  viewed ---- hide (true) / show (false) onboarding help overlay
 */
export default {
	help: false,
	viewed: false,
	// 2592000000 = 30 days in milliseconds
	persist: {
		viewed: 2592000000,
	},
};
