import deps from "dependencies";

export default {
    mount: () => (dispatch, getState, store) => {
        return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
            Config => {
                dispatch({
                    type: deps.actionTypes.VIDEO_MOUNT,
                    data: Config.videoPlayer
                });
            }
        );
    },
    currentlyPlaying: (playlist, timeline, outofport) => (dispatch, getState, store) => {
        dispatch({
            type: deps.actionTypes.VIDEO_CURRENTLY_PLAYING,
            data: { playlist, timeline, outofport },
        });
    },
    setVideoLocation: (outofport) => (dispatch, getState, store) => {
        dispatch({
            type: deps.actionTypes.VIDEO_LOCATION,
            data: outofport,
        });
    }
};
