/**
 Video Player Initial State
*/

export default {
	playing: [],
	auto: true,
	aiAudio: {},
	persist: {
		aiAudio: true,
	},
};
