import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { doMeasurement } from 'appdir/components/general/Util';
import HelpModal from 'appdir/components/common-ui/HelpModal';

const IBMwatsonx = props => {

    let styleClasses = props?.styleClasses ?? '';
    let onclickClass = props?.onclickClass ? 'onclick' : '';

	const configText = useSelector(state => state['Config']?.otherData?.innovations?.branding);

    const [openModal, setState] = useState(false);

    /** clean up on unmount */
	useEffect(() => {
		return function cleanup() {
			document.body.classList.remove('modal-on-noscroll-nofixed');
		};
	}, []);

    useEffect(() => {
		/** when HelpModal is set to display, add a class to the body to prevent
		 *  the main content to scroll
		 *  lower header-global z-index as it is higher
		 */

		if (openModal) {
			document.body.classList.add('modal-on-noscroll-nofixed');
		} else {
			document.body.classList.remove('modal-on-noscroll-nofixed');
		}
	}, [openModal]);

    /**
     * www ----- send modal open/close metrics
     * webview - send modal open/close metrics
     *           send message to app so app calls 
     *           watsonxInfoOverlayWebview
     */
    const infoClick = () => {
        let pageTitle = '';
        let measureAction = 'IBMwatsonx'
        let measureArgs = [{modal: openModal ? 'Close' : 'Open'}]; // needs in an array and string, not obj
		let contextData = [];
		if (window.webview) {
            pageTitle = 'IBMwatsonx';
            measureAction = 'modal';
            measureArgs = ['open'];
		}
		doMeasurement(pageTitle, measureAction, measureArgs, contextData);

        if(!window.webview) {
            setState(openModal ? false : true);

        } else {
            /** for app modal (?) icon
             *  app opens another modal webview 
             *  to show help overlay
             * */

            if (window.webviewPlatform == 'ios' && window.webkit) {
                window.webkit.messageHandlers.info_modal.postMessage('open');
            } else if (window.JSInterface) {
                const appData = {
                    type: "info_modal",
                    data: {
                    action: 'open'
                    }
                };

                let stringData = JSON.stringify(appData);

                window.JSInterface.webviewMessage(stringData);
            }
        }
    }
    
    let modalBodyText = configText?.helpText;
    let marketingLink = configText?.marketingLink ?? {};
    logger.log('[IBMwatsonx] configText:%o', configText);

    return (
        <div className={`watsonx-branding ${styleClasses}`}>
            {configText?.subText} <span className={`watsonx-logo ${onclickClass}`} onClick={() => {props?.onclickClass && infoClick()}}>IBM<sup>&reg;</sup> watsonx&trade;</span>
            {
                !props?.data?.hideInfoIcon && (
                    <div className="question">
                        <i 
                            className={`icon-help ${onclickClass}`}
                            onClick={() => {props?.onclickClass && infoClick()}}
                        />
                    </div>
                )
            }
            { 
                openModal && modalBodyText && !window.webview && (
                        <HelpModal 
                            body={modalBodyText}
                            marketingLink={marketingLink}
                            closeModal={() => {props?.onclickClass && infoClick()}} 
                        />
                    )
            }
        </div>
    )

}

export default IBMwatsonx;