import SchedulePage from './index';
import deps from 'dependencies';

// tournDay will be a field in the params obj.
// route is day<dayNum>.html
export default {
	//path:"/:lang(en_US)/scores/schedule/(index|schedule):tournDay(|\\d|\\d\\d).html",
	path: ['/:lang(en_US)/scores/schedule/index.html', '/:lang(en_US)/scores/schedule/schedule:tournDay.html'],
	exact: true,
	component: SchedulePage,
	load: params => deps.actions.SchedulePage.mount(params),
};
