import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;
	let eventRound;

	switch (action.type) {
		case deps.actionTypes.DRAWSPAGE_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[DrawsPage] reducers DRAWSPAGE_MOUNT - newState:%o', newState);

			return newState;

		case deps.actionTypes.DRAWSPAGE_UNMOUNT:
			// let filters = {
			// 	event: '',
			// 	open: ''
			// }
			filters = {
				...state.filters,
			};

			eventRound = {
				...state.eventRound,
				pageReturn: true,
				selectedIndex: '',
				selectedRound: 0,
			};

			newState = {
				...state,
				drawsPath: action.data.drawsPath,
				filters: filters,
				eventRound: eventRound,
				mountStatus: null,
			};

			logger.log('[DrawsPage] DRAWSPAGE_UNMOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWSPAGE_FILTER:
			//logger.log('[DrawsPage] RESULTSPAGE_FILTER - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};
			logger.log('[DrawsPage] DRAWSPAGE_FILTER - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWS_SET_ROUND:
			//logger.log('[DrawsPage] RESULTSPAGE_FILTER - data:%o', action.data

			eventRound = {
				...state.eventRound,
				...action.data,
			};

			newState = {
				...state,
				eventRound: eventRound,
				//view: action.data.view ? action.data.view : 'round',
			};
			logger.log('[DrawsPage] DRAWSPAGE_FILTER - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWSPAGE_UPDATE:
			logger.log('[DrawsPage] DRAWSPAGE_UPDATE - data:%o', action.data);

			eventRound = {
				...state.eventRound,
				...action.data.eventRound,
			};
			filters = {
				...state.filters,
				...action.data.filters,
			};

			newState = {
				...state,
				eventRound: eventRound,
				filters: filters,
				view: action.data.view ? action.data.view : state.view,
				//view: action.data.view ? action.data.view : state.view,
			};
			logger.log('[DrawsPage] DRAWSPAGE_UPDATE - newState:%o', newState);
			return newState;

		case deps.actionTypes.DRAWS_RENDER:
			newState = {
				...state,
				timestamp: action.data,
			};

			return newState;

		case deps.actionTypes.DRAWS_CHANGE_VIEW:
			newState = {
				...state,
				...action.data,
			};
			logger.log('[DrawsPage] DRAWS_CHANGE_VIEW - newState:%o', newState);

			return newState;

		default:
			return state;
	}
};
