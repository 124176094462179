/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import throttle from 'lodash/throttle';

/**
 * -----------------------------------------------------------------------------
 * React Component: Hero
 * -----------------------------------------------------------------------------
 */

const portrait = window.matchMedia('(orientation: portrait)');
const xxsmall = window.matchMedia('(max-width: 374px');
const xsmall = window.matchMedia('(max-width: 639px)');
const small = window.matchMedia('(max-width: 767px)');
const smalllg = window.matchMedia('(max-width: 1023px)');
const medium = window.matchMedia('(max-width: 1279px)');
const large = window.matchMedia('(max-width: 1439px)');
const xlarge = window.matchMedia('(max-width: 1599px)');
const xxlarge = window.matchMedia('(min-width: 1600px)');

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.WindowSize.mount()),
	setWindowSize: data => dispatch(deps.actions.WindowSize.setWindowSize(data)),
});

const mapStateToProps = (state, props) => {
	return {
		...state['WindowSize'],
		...props,
	};
};

class WindowSize extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		logger.log('[WindowSize] - componentDidMount');
		if (this.props.hasOwnProperty('mount')) {
			this.props.mount(this);
		}
		window.addEventListener('resize', throttle(this.onResize.bind(this), 100, { trailing: true }), false);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize.bind(this), false);
	}

	componentDidUpdate(prevState) {
		//logger.log('[WindowSize] - componentDidUpdate this:%o',this);
		this.checkWindowSizes();
	}
	onResize() {
		//logger.log('[WindowSize] - onResize');
		this.checkWindowSizes();
	}

	checkWindowSizes() {
		//logger.log('[WindowSize] - checkWindowSizes state:%o',this.state);
		let size = '';
		let desktopSize = '';
		let tabletSize = '';
		let numericWindowSize = 0;
		let breakpoint = '';
		let orientation = portrait.matches ? 'portrait' : 'landscape';

		if (xxsmall.matches) {
			size = 'smallMobile';
			numericWindowSize = 0;
			breakpoint = 'xsmall';
		} else if (xsmall.matches) {
			size = 'mobile';
			numericWindowSize = 1;
			breakpoint = 'xsmall';
		} else if (small.matches) {
			size = 'tablet';
			tabletSize = 'smallTablet';
			numericWindowSize = 2;
			breakpoint = 'small';
		} else if (smalllg.matches) {
			size = 'tablet';
			tabletSize = 'mediumTablet';
			numericWindowSize = 3;
			breakpoint = 'smalllg';
		} else if (medium.matches) {
			size = 'desktop';
			desktopSize = 'smallDesktop';
			numericWindowSize = 4;
			breakpoint = 'medium';
		} else if (large.matches) {
			size = 'desktop';
			desktopSize = 'mediumDesktop';
			numericWindowSize = 5;
			breakpoint = 'large';
		} else if (xlarge.matches) {
			size = 'desktop';
			desktopSize = 'largeDesktop';
			numericWindowSize = 6;
			breakpoint = 'xlarge';
		} else if (xxlarge.matches) {
			size = 'desktop';
			desktopSize = 'xlargeDesktop';
			numericWindowSize = 7;
			breakpoint = 'xxlarge';
		}

		if (
			size !== this.lastWindowSize ||
			desktopSize !== this.lastDesktopSize ||
			orientation != this.lastOrientation ||
			tabletSize !== this.lastTabletSize
		) {
			this.lastWindowSize = size;
			this.lastDesktopSize = desktopSize;
			this.lastTabletSize = tabletSize;
			this.lastOrientation = orientation;
			this.props.setWindowSize({
				EventsWindow: {
					windowSize: size,
					desktopSize: desktopSize,
					tabletSize: tabletSize,
					numericWindowSize: numericWindowSize,
					breakpoint: breakpoint,
					orientation: orientation,
					windowWidth: window.innerWidth,
					windowHeight: window.innerHeight,
				},
			});
		}
	}

	render() {
		//logger.log('[WindowSize] render state:%o', this.state);
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowSize);

WindowSize.defaultProps = {};
