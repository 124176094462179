/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: ScheduleBuyTickets
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ScheduleWebview'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class ScheduleBuyTickets extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let data = this.props.attributes.data;
		let hasFooterComments = false;
		let fcData = [];

		logger.log(
			'[ScheduleBuyTickets] render this:%o, hasFooterComments:%o, fcData:%o, data: %o',
			this,
			hasFooterComments,
			fcData,
			data
		);

		if (data.footerComment !== null) {
			hasFooterComments = true;
			fcData = data.footerComment?.split('|');
		}

		//round info
		return (
			<>
				<React.Fragment>
					<div className="column-layout">
						<div className="schedule-comments">
							The match schedules are released the day prior to play, but you can check for available
							tickets to the events below.
							<EventsLink
								to="https://www.ticketmaster.com/usopentennis"
								external={true}
								style="blue-button">
								See Tickets
							</EventsLink>
						</div>
					</div>
					{data.rounds.map((info, d) => {
						return (
							<div className="column-layout">
								<div className="schedule-court">
									<div className="courtName">
										{info.roundName} - {info.session}
									</div>
									<div className="schedule-content">
										<ul>
											{info.matches.map(type => {
												return <li>{type}</li>;
											})}
										</ul>
									</div>
								</div>
							</div>
						);
					})}

					{hasFooterComments === true ? (
						<div className="column-layout">
							<div className="footer-comments">
								{fcData.map((comment, i) => {
									return (
										<div className="footer-comment-item" key={`comment-${i}`}>
											{comment}
										</div>
									);
								})}
							</div>
						</div>
					) : null}
				</React.Fragment>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleBuyTickets);
