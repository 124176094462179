/**
 Slamtracker Initial State
*/

export default {
  slamtrackerUnit: "metric",
  slamtrackerStatsLayout: "summary",
  filters: {
    open: ""
  }
};
