import NewsIndex from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/:lang(en_US)/news/index.html', '/:lang(en_US)/news/'],
		exact: true,
		component: NewsIndex,
		load: params => deps.actions.NewsIndex.mount(params),
	},
];
