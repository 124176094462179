/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: CalendarEvent
 * -----------------------------------------------------------------------------
 */
const CalendarEvent = props => {
	logger.log('[Util CalendarEvent] - props:%o', props);

	const { data } = props;
	const { calendarItem } = data?.calendarItems || null;

	if (!calendarItem) {
		return null;
	}

	return (
		<section className="calendar-event-section">
			<div className={`calendar-event-header ${data?.style}`}>
				{calendarItem?.title && <div className="title">{calendarItem?.title}</div>}
				<div className="date-time">
					{calendarItem?.dates?.date?.date && <span className="date">{calendarItem?.dates?.date?.date}</span>}
					{calendarItem?.dates?.date?.start_time && (
						<span className="time"> - {calendarItem?.dates?.date?.start_time}</span>
					)}
				</div>
			</div>
			<div className="calendar-event-details">
				{/* location, more info, description */}
				{calendarItem?.location && <div className="location">{calendarItem?.location}</div>}
				<div className="more-info">
					{calendarItem?.ticket_link && (
						<div className="calendar-event-tickets info-item">
							<EventsLink to={calendarItem.ticket_link} style="calendar-event-btn tix">
								{calendarItem?.ticket_text}
							</EventsLink>
						</div>
					)}
					{calendarItem?.free_event === 'true' && (
						<div className="calendar-event-free info-item">
							<i className="icon-thin-check"></i>
							<span className="info-item-text">Free Event</span>
						</div>
					)}
					{calendarItem?.location_map_link && (
						<div className="calendar-event-map info-item">
							<EventsLink to={calendarItem.location_map_link}>
								<i className="icon-grounds-map"></i>
								<span>Find It On the Map</span>
							</EventsLink>
						</div>
					)}
				</div>
				{calendarItem?.description && (
					<div className="description">{ReactHtmlParser(calendarItem?.description)}</div>
				)}
			</div>
		</section>
	);
};

export default CalendarEvent;
