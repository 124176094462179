/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CountDownTimer from 'components/general/FanWeekSchedule/CountDownTimer';
import EventsLink from 'components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * React Component: FanWeekHero
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state['FanWeekHero'],
	fanweekHeroData: state['Config'].otherData?.fanweek_hero,
	EventsWindow: state['WindowSize'].EventsWindow,
	...props,
});

const mapDispatchToProps = dispatch => ({});

class FanWeekHero extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		logger.log('[FanWeekHero] this:%o', this);

		let { fanweekHeroData } = this.props;

		if (fanweekHeroData) {
			return (
				<div className="fanweek-hero">
					<h2>{ReactHtmlParser(fanweekHeroData.title)}</h2>
					<h3>{fanweekHeroData.subTitle}</h3>
					<div>{ReactHtmlParser(fanweekHeroData.text)}</div>
					<CountDownTimer date={fanweekHeroData?.targetDate} />

					{fanweekHeroData?.button?.text !== '' &&
					fanweekHeroData?.button?.url &&
					fanweekHeroData?.button?.url !== '' ? (
						<EventsLink
							to={fanweekHeroData?.button?.url}
							external={fanweekHeroData?.button?.external}
							style="fanweek">
							{fanweekHeroData?.button?.text}
						</EventsLink>
					) : null}
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FanWeekHero);
