/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import PowerIndexLeaderboard from './PowerIndexLeaderboard';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: PowerIndexLeaderboard
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PowerIndexLeaderboard'],
		sharedDataConfig: state['Config'].sharedDataConfig,
		otherData: state['Config'].otherData,
		scoringData: state['Config'].scoringData,
		playerImagePath: op.get(state['Config'], 'otherData.playerProfileImagePath'),
		favorites: state['Controller'].favorites,
		powerIndex: state['Tournament']?.data?.powerIndex,
		players: state['Tournament']?.data?.players,
		drawAnalysisData: state['Tournament']?.data?.drawAnalysis,
		enabled: state['Config']?.enabled,

		countriesLookUp: state['Controller'].countriesLookUp,
		commonData: state['CommonData'],
		powerIndexMatchupData: state['CommonData']['powerIndexMatchup'],
		powerIndexTrendingData: state['CommonData']['powerIndexTrending'],
		playerStatusData: state['CommonData']['playerStatus'],
		playerMatchesData: state['CommonData']['playerMatches'],
		matchInsightsMatchesData: state['CommonData']['matchInsightsMatches'],
		windowSize: state['PageHeader'].windowSize,
		currentDay: state['ActiveData']['currentDay'],
		stubs: state['Config'].stubPages,
		Router: state['Router'],
		drawData: op.get(state['Tournament'], 'data.draw', null),
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	unmount: () => dispatch(deps.actions.PowerIndexLeaderboard.unmount()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
	getDraws: event => dispatch(deps.actions.Tournament.getDraws({ event: event })),
	getPlayerList: () => dispatch(deps.actions.Tournament.getPlayerList()),
	getPowerIndex: eventId => dispatch(deps.actions.Tournament.getPowerIndex({ eventId: eventId })),
	getPowerIndexTrends: () => dispatch(deps.actions.Tournament.getPowerIndexTrends()),
	getDrawAnalysis: (event, playerId) =>
		dispatch(deps.actions.Tournament.getDrawAnalysis({ eventId: event, playerId: playerId })),
	getMatchInsightsAvailable: () => dispatch(deps.actions.Tournament.getMatchInsightsAvailable()),
	getPowerIndexMatchup: match => dispatch(deps.actions.Tournament.getPowerIndexMatchup({ matchId: match })),
	getPlayerStatus: playerId => dispatch(deps.actions.Tournament.getPlayerStatus({ playerId: playerId })),
	getPlayerMatches: playerId => dispatch(deps.actions.Tournament.getPlayerMatches({ playerId: playerId })),
	clearTournament: () => dispatch(deps.actions.Tournament.clearAll()),
	drawAnalysisHelpUpdate: data => dispatch(deps.actions.DrawAnalysisHelp.update(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PowerIndexLeaderboard);
