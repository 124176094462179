import { connect } from 'react-redux';
import RadioPlayer from './RadioPlayer';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: RadioPlayer
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.RadioPlayer,
	playerConfig: state['Config']?.playerConfig?.radio,
	radio: state['ActiveData']['radio'],
	otherDataRadio: state['Config']?.otherData?.radio,
	stubs: state['Config'].stubPages,
	...props,
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.RadioPlayer.mount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RadioPlayer);
