import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				liveBarPath: Config.cmsData.liveBarData,
				liveBarShow: Config.otherData.showLiveBar,
			};

			dispatch({ type: deps.actionTypes.LIVEBAR_MOUNT, data: data });
		});
	},
};
