import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				pageHeaders: Config.pageHeaders,
				ibmLink: Config.ibmLink,
				alertPath: Config.otherData.activeData.path,
				newsletter: Config.components.newsletter,
			};

			dispatch({ type: deps.actionTypes.HEADER_MOUNT, data: data });
		});
	},
	toggleMenu: isOpen => dispatch => {
		dispatch({
			type: deps.actionTypes.HEADER_TOGGLEMENU,
			data: { menu_open: isOpen },
		});
	},
	toggleLiveMenu: isOpen => dispatch => {
		dispatch({
			type: deps.actionTypes.HEADER_TOGGLELIVEMENU,
			data: { live_open: isOpen },
		});
	},
};
