import React, { Component } from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

export default class SocialChannels extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};
		this.measureData = {
			...props.attributes.data,
			type: `${props.attributes.data.type}${props.attributes.data.desktopPosition}`,
			itemType: `${props.attributes.data.type}${props.attributes.data.desktopPosition}`,
			itemTitle: props.attributes.data.title,
			itemId: props.attributes.data.id,
			actionType: 'homePageClick',
		};
		delete this.measureData.content;
		// logger.log('[SocialChannels] constructor - measureData: %o', this.measureData);
	}

	componentDidMount() {
		// logger.log("[SocialChannels] componentDidMount");
	}

	render() {
		// logger.log('[SocialChannels] render - props:%o, state:%o', this.props, this.state);

		return (
			<div className="social-media">
				<div className="header">
					<div className="title">Follow The US Open</div>
				</div>
				<div className="content">
					<div className="item">
						<EventsLink
							to="https://www.facebook.com/usopentennis"
							measureData={{
								...this.measureData,
								which: 'facebook',
							}}>
							<img src="/assets/images/nav/social_icons/facebook_gray.png" alt="facebook" border="0" />
						</EventsLink>
					</div>
					<div className="item">
						<EventsLink
							to="https://www.twitter.com/usopen"
							measureData={{
								...this.measureData,
								which: 'twitter',
							}}>
							<img src="/assets/images/nav/social_icons/twitter_gray.png" alt="twitter" border="0" />
						</EventsLink>
					</div>
					<div className="item">
						<EventsLink
							to="https://www.instagram.com/usopen"
							measureData={{
								...this.measureData,
								which: 'instagram',
							}}>
							<img src="/assets/images/nav/social_icons/instagram_gray.png" alt="instagram" border="0" />
						</EventsLink>
					</div>
					<div className="item">
						<EventsLink
							to="https://www.youtube.com/user/usopen"
							measureData={{
								...this.measureData,
								which: 'youtube',
							}}>
							<img src="/assets/images/nav/social_icons/youtube_gray.png" alt="youtube" border="0" />
						</EventsLink>
					</div>
					<div className="item">
						<EventsLink
							to="https://www.tiktok.com/@usopen"
							measureData={{
								...this.measureData,
								which: 'tiktok',
							}}>
							<img src="/assets/images/nav/social_icons/tiktok_gray.png" alt="tiktok" border="0" />
						</EventsLink>
					</div>
				</div>
			</div>
		);
	}
}
