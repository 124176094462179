import React from 'react';
import BasicDisplay from './BasicDisplay';
import EventsLink from 'appdir/components/general/EventsLink';
import ButtonGroup from './ButtonGroup';

/**
 * ---------------------------------------
 * KeyPoints -module with a title that contains one large lead content piece (news, photo, video) with a title and a short description under the
 * photo. bulleted list of content underneath. these can link to anywhere on the site
 * ---------------------------------------
 */

const KeyPoints = props => {
	logger.log('[KeyPoints] render props:%o, ', props);
	let leadData = [];
	let bulletsData = [];
	let buttonData = props.data.content.buttons;

	props.data.content.items.map(item => {
		if (item.style == 'lead') {
			leadData.push(item);
		} else {
			bulletsData.push(item);
		}
	});
	let measureData = {
		...props.data,
		type: props.data.type,
		itemId: props.data.id,
		itemType: props.data.type,
	};
	delete measureData.content;

	let columnCount = Math.round(bulletsData.length / 2);
	let col1 = bulletsData.slice(0, columnCount);
	let col2 = bulletsData.slice(columnCount, bulletsData.length);
	// logger.log('[KeyPoints] render col1:%o, col2:%o, columnCount:%o, bulletsData.length', col1, col2, columnCount, bulletsData.length);

	let bulletsClass = props?.data?.bullets ? '' : 'no-bullets';

	return (
		<div className="keypoints">
			<div className="lead">
				<BasicDisplay
					data={leadData[0]}
					measureData={{
						...measureData,
						which: 'lead',
						itemTitle: leadData[0].title,
						actionType: 'homePageClick',
					}}
				/>
			</div>
			<div className="bulletList">
				<div className="col1">
					<ul className={bulletsClass}>
						{col1.map((item, i) => {
							return (
								<li key={`col1${i}`}>
									<EventsLink
										to={item.link}
										measureData={{
											...measureData,
											which: `col1_${i}_link`,
											actionType: 'homePageClick',
											itemTitle: item.title,
										}}>
										{item.title}
									</EventsLink>
								</li>
							);
						})}
					</ul>
				</div>
				<div className="col2">
					<ul className={bulletsClass}>
						{col2.map((item, i) => {
							return (
								<li key={`col2${i}`}>
									<EventsLink
										to={item.link}
										measureData={{
											...measureData,
											which: `col2_${i}_link`,
											actionType: 'homePageClick',
											itemTitle: item.title,
										}}>
										{item.title}
									</EventsLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			{buttonData.length > 0 ? (
				<ButtonGroup
					data={buttonData}
					measureData={{
						...measureData,
						which: 'button',
						actionType: 'homepage_button',
						moduleType: props.data.type,
						title: props.data.title,
					}}
				/>
			) : null}
		</div>
	);
};

export default KeyPoints;
