/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import Slider from 'react-slick';
import { fetch } from 'appdir/components/general/Util';
import ReactHtmlParser from 'html-react-parser';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import SumscoresMatchBox from 'appdir/components/_scoring/MatchBox/sumscores';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import Favorites from 'components/common-ui/Favorites';
import { matchIsCompleteState } from 'appdir/components/pages/SchedulePage/ScheduleUtils';
import HomeContentWrapper from 'appdir/components/cms/HomeContent/HomeContentWrapper';
import EventsLink from 'appdir/components/general/EventsLink';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: SummaryScoreboard
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['SummaryScoreboard'],
		ScoreManager: state?.['ScoreManager'],
		controllerScoring: state?.['Controller']?.scoring,
		controllerLoaded: state?.['Controller']?.loaded,
		showSumscores: state?.['Config']?.scoring?.showSumscores ?? false,
		windowSize: state?.['Controller']?.windowSize,
		stWindowSize: state?.['Controller']?.stWindowSize,
		windowSizeVal: state?.['Controller']?.windowSizeVal,
		currentDay: state?.['ActiveData']?.currentDay,
		liveIndicators: state?.['ActiveData']?.liveIndicators,
		activeDataStatus: state?.['ActiveData']?.status,
		Router: state?.['Router'],
		miMatches: state?.['Controller']?.miMatches,
		stubs: state?.['Config']?.stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SummaryScoreboard.mount()),
	unmount: () => dispatch(deps.actions.SummaryScoreboard.unmount()),
	setScoringStatus: data => dispatch(deps.actions.Controller.setScoringStatus(data)),
	setGlobalLink: data => dispatch(deps.actions.Controller.setGlobalLink(data)),
});

class SummaryScoreboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tournState: 'hide',
			completedData: {
				matches: [],
			},
			scheduleData: {
				courts: [],
			},
			finalData: {
				matches: [],
			},
			curSlide: 0
		};

		this.init = true;
		this.schedule_init = true;
		this.completed_init = true;
		this.final_init = true;
		this.sumscoresRef = React.createRef();

		window.addEventListener('resize', throttle(this.onSumscoresResize.bind(this), 100, { trailing: true }), false);
		this.measureData = {
			...props?.data,
			type: `${props?.data?.type}${props?.data?.desktopPosition}`,
			itemTitle: props?.data?.title,
			itemId: props?.data?.id,
			itemType: `${props?.data?.type}${props?.data?.desktopPosition}`,
			actionType: 'homePageClick',
		};
		delete this.measureData.content;

		logger.log('[SummaryScoreboard] constructor this:%o', this);
	}

	onSumscoresResize() {
		this.setSumscoreWidth();
	}

	isSlamtrackerMobile() {
		let { controllerScoring } = this.props;
		let { stWindowSize } = this.props;

		if (stWindowSize && controllerScoring && controllerScoring.slamtracker && stWindowSize == 'mobile') {
			return true;
		} else {
			return false;
		}
	}

	matchHasInsights(match_id) {
		if (this.props.miMatches.indexOf(match_id) > -1) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 *
	 * @param {string} type
	 * @param {obj} data
	 * @returns obj
	 */
	getCTA = (data, type = 'scheduled') => {
		let cta = {
			url: null,
			text: null,
			status: null,
		};
		// logger.log('[SummaryScoreboard] getCTA data:%o', data);

		if (type == 'scheduled' && data) {
			let status = data?.status;
			let statsLevel = data?.statsLevel;

			let slamTrackerUrl = `/en_US/scores/stats/${data?.match?.match_id}.html`;
			let insightsUrl = `/en_US/matchInsights/${data?.match?.match_id}.html`;

			if (status !== 'upcoming' && statsLevel !== 'N') {
				if (matchIsCompleteState(data.match)) {
					cta.url = slamTrackerUrl;
					cta.text = 'Match Recap';
					cta.status = 'complete';
				} else {
					cta.url = slamTrackerUrl;
					cta.text = 'Follow Live';
					cta.status = 'live';
				}
			} else if (status === 'upcoming' && statsLevel !== 'N' && this.matchHasInsights(data?.match?.match_id)) {
				cta.url = slamTrackerUrl;
				// cta.text = 'Match Insights with Watson';
				cta.text = 'Match Preview'; // always show Match Preview whether there is Insights or not
				cta.status = 'prematch';
			} else {
				cta.url = slamTrackerUrl;
				cta.text = 'Match Preview';
				cta.status = 'prematch';
			}
		} else if (type == 'final' && data) {
			let slamTrackerUrl = `/en_US/scores/stats/${data?.match?.match_id || data?.match_id}.html`;
			let insightsUrl = `/en_US/matchInsights/${data?.match?.match_id || data?.match_id}.html`;

			if (data.statsLevel !== 'N') {
				cta.url = slamTrackerUrl;
				cta.text = 'Match Recap';
				cta.status = 'complete';
			}
		}

		return cta;
	};

	componentDidMount() {
		// logger.log('[SummaryScoreboard] componentDidMount state:%o', this.state);
		if (!this.props?.status || this.props?.status != 'loaded') {
			this.props.mount();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		let isStub = this.props?.stubs?.summaryscoreboard?.stub === 'stub';

		if (
			this.props?.activeDataStatus == 'loaded' &&
			this.props?.controllerLoaded &&
			this.props?.status == 'loaded' &&
			this.init &&
			this.props?.showSumscores &&
			!isStub
		) {
			//let tournState = this.getTournState();
			// logger.log('[SummaryScoreboard componentDidUpdate init');
			this.init = false;
			this.setTournState();
			this.props.setScoringStatus({ mip: true });
		}

		if (
			!isStub &&
			!this.init &&
			this.props?.activeDataStatus == 'loaded' &&
			this.props?.controllerLoaded &&
			this.props?.status == 'loaded' &&
			(prevProps?.currentDay?.tournament !== this.props?.currentDay?.tournament ||
				prevProps?.currentDay?.completed !== this.props?.currentDay?.completed ||
				prevProps?.currentDay?.schedule !== this.props?.currentDay?.schedule ||
				prevProps?.liveIndicators?.scores !== this.props?.liveIndicators?.scores)
		) {
			// logger.log('[SummaryScoreboard componentDidUpdate currentDay or liveIndicators changed');
			this.setTournState();
		}

		if (this.state?.tournState !== prevState?.tournState && !isStub) {
			// logger.log('[SummaryScoreboard componentDidUpdate tournState changed tournState:%o', this.state.tournState);
			if (this.state.tournState == 'preLive') {
				this.getScheduledMatches();
				//this.getCompletedMatches();
				this.setSumscoreWidth();
			} else if (this.state.tournState == 'live') {
				this.getScheduledMatches();
				this.getCompletedMatches();
				this.setSumscoreWidth();
			} else if (this.state.tournState == 'post') {
				this.getFinalMatches();
				this.setSumscoreWidth();
			} else if (this.state.tournState == 'hide') {
			}
		}

		if (prevState.curSlide < this.state.curSlide) {
			//measureApp('sumscores','next')
		}
		if (prevState.curSlide > this.state.curSlide) {
			//measureApp('sumscores','prev');
		}
	}

	setSumscoreWidth() {
		let { controllerScoring } = this.props;
		let { stWindowSize } = this.props;

		if (
			stWindowSize &&
			controllerScoring &&
			(!controllerScoring?.slamtracker || (controllerScoring?.slamtracker && stWindowSize !== 'mobile'))
		) {
			let clientHeight = this?.sumscoresRef?.clientHeight;
			let clientWidth = this?.sumscoresRef?.current?.clientWidth;

			if (!this.state?.sumscoresWidth || clientWidth !== this.state?.sumscoresWidth) {
				this.setState({ sumscoresWidth: clientWidth });
			}
		}
	}

	combineSessionData(scheduleData) {
		let newData = scheduleData;
		let courts = [];
		for (var i = 0; i < scheduleData.courts.length; i++) {
			let court = scheduleData.courts[i];
			let matches = court.matches;
			court.matches = [];
			if (court.session == 1) {
				for (var j = 0; j < matches.length; j++) {
					if (matches[j].team1.length > 0 && matches[j].team2.length > 0) {
						court.matches.push(matches[j]);
					}
				}
				courts.push(court);
			} else if (scheduleData.courts[i].session == 2) {
				//logger.log('[SummaryScoreboard] combineSessionData: need to append data, session1 matches: %o', courts[courts.length-1].matches);
				let newCourt = false;
				for (var j = 0; j < matches.length; j++) {
					if (matches[j].team1.length > 0 && matches[j].team2.length > 0) {
						if (courts.length > 0 && courts[courts.length - 1].courtId == court.courtId) {
							courts[courts.length - 1].matches.push(matches[j]);
						} else {
							court.matches.push(matches[j]);
							newCourt = true;
						}
					}
				}
				if (newCourt) {
					courts.push(court);
				}
			}
		}
		newData.courts = courts;
		// logger.log('[SummaryScoreboard] combineSessionData: newData:%o', newData);
		return newData;
	}

	getScheduledMatches() {
		if (this.schedule_init && this.props?.paths && this.props?.currentDay?.tournament) {
			if (this.props?.paths?.schedule) {
				let dataPath = this.props.paths.schedule.replace(
					'<day>',
					this.props.currentDay?.tournament == '0' && this.props.currentDay?.schedule == '1'
						? this.props.currentDay?.schedule
						: this.props.currentDay?.tournament
				);
				this.schedule_init = false;

				fetch(dataPath)
					.then(result => {
						// logger.log('[SummaryScoreboard] getScheduledMatches scheduleData results:%o', result);
						let scheduleData = this.combineSessionData(result);
						this.setState({
							scheduleData: scheduleData,
							schedule_loaded: true,
						});

						if (this.schedule_timeout) {
							clearTimeout(this.schedule_timeout);
						}

						this.schedule_timeout = setTimeout(() => {
							this.schedule_init = true;
							this.getScheduledMatches();
						}, 30000);
					})
					.catch(error => {
						// logger.error('[SummaryScoreboard] getScheduledMatches error:%o',error);
						this.setState({
							hasError: true,
							errorType: error,
						});
					});
			}
		}
	}

	getCompletedMatches() {
		if (
			this.completed_init &&
			this.props?.paths &&
			this.props?.currentDay?.completed !== 0
		) {
			if (this.props?.paths?.completed) {
				let dataPath = this.props.paths.completed.replace('<day>', this.props.currentDay.completed);
				this.completed_init = false;
				
				fetch(dataPath)
					.then(result => {
						// logger.log('[SummaryScoreboard] getCompletedMatches completed result:%o',result);
						this.setState({
							completedData: result,
							completed_loaded: true,
						});

						if (this.completed_timeout) {
							clearTimeout(this.completed_timeout);
						}

						this.completed_timeout = setTimeout(() => {
							this.completed_init = true;
							this.getCompletedMatches();
						}, 30000);
					})
					.catch(error => {
						// logger.error('[SummaryScoreboard] getCompletedMatches error:%o',error);
						this.setState({
							hasError: true,
							errorType: error,
						});
					});
			} else {
				if (this.completed_timeout) {
					clearTimeout(this.completed_timeout);
				}

				this.completed_timeout = setTimeout(() => {
					this.completed_init = true;
				}, 30000);
			}
		}
	}

	getFinalMatches() {
		try {
			if (this.final_init && this.props.paths) {
				if (this.props.paths.final) {
					let dataPath = this.props.paths.final;
					this.final_init = false;
					fetch(dataPath)
						.then(result => {
							// logger.log('[SummaryScoreboard] getFinalMatches finals result:%o',result);
							this.setState({
								finalData: result,
								final_loaded: true,
							});
						})
						.catch(error => {
							this.setState({
								hasError: true,
								errorType: error,
							});
						});
				}
			}
		} catch (error) {
			// logger.error('[SummaryScoreboard] getFinalMatches error:%o',error);
			this.setState({
				hasError: true,
				errorType: error,
			});
		}
	}

	getMatchData(lastMatch, nextMatch, courtName) {
		let match;
		let status;
		let { liveMatches } = this.props?.ScoreManager;
		let { completedData } = this.state;

		// logger.log('[SummaryScoreboard] getMatchData - lastMatch:%o, nextMatch:%o, courtName:%o',lastMatch, nextMatch, courtName);

		if (!isEmpty(lastMatch) && liveMatches) {
			let foundMatch = liveMatches.find(function(match) {
				return match.match_id == lastMatch.match_id;
			});

			if (typeof foundMatch !== 'undefined') {
				match = foundMatch;
				status = 'live';
				//logger.log('[SummaryScoreboard] getMatchData - match:%o, status:%o',match,status);
			} else if (!isEmpty(nextMatch)) {
				match = nextMatch;
				status = 'upcoming';
				//logger.log('[SummaryScoreboard] getMatchData - match:%o, status:%o',match,status);
			} else {
				let temp = completedData.matches.filter(match => match.match_id == lastMatch.match_id);
				if (temp.length > 0) {
					match = temp[0];
				} else {
					match = false;
				}
				status = 'completed';

				//logger.log('[SummaryScoreboard] getMatchData - match:%o, status:%o',match,status);
			}
		} else if (!isEmpty(nextMatch)) {
			match = nextMatch;
			status = 'upcoming';
			//logger.log('[SummaryScoreboard] getMatchData - match:%o, status:%o',match,status);
		} else {
			// logger.error(
			//   "[SummaryScoreboard] getMatchData - lastMatch:%o, nextMatch:%o, courtName:%o",
			//   lastMatch,
			//   nextMatch,
			//   courtName
			// );
		}
		//logger.log('[SummaryScoreboard] getMatchData - returning match:%o, courtName:%o, status:%o',match, match.courtName, status);
		return { match: match, status: status };
	}

	renderScheduledCourts() {
		let { scheduleData } = this.state;
		// logger.log('[SummaryScoreboard] renderScheduledCourts scheduleData:%o',scheduleData);
		try {
			return scheduleData.courts.map((court, index) => {
				let startedMatches = court.matches.filter(
					match => match.statusCode !== 'B' && match.statusCode !== 'F'
				);
				let notStartedMatches = court.matches.filter(match => match.statusCode === 'B');

				// logger.log('[SummaryScoreboard] renderScheduledCourts - court:%o, startedMatches:%o',court.courtName, startedMatches);
				// logger.log('[SummaryScoreboard] renderScheduledCourts - court:%o, notStartedMatches:%o',court.courtName, notStartedMatches);
				const getLastMatch = () => {
					let match = startedMatches[startedMatches.length - 1];
					for (var i = 0; i < startedMatches.length; i++) {
						if (startedMatches[i].statusCode == 'A' || startedMatches[i].statusCode == 'K') {
							match = startedMatches[i];
							break;
						}
					}
					return match;
				};
				//let lastMatch = startedMatches.length > 0 ? startedMatches[startedMatches.length - 1] : {};
				let lastMatch = getLastMatch();
				let nextMatch = notStartedMatches.length > 0 ? notStartedMatches[0] : {};

				let displayMatch = this.getMatchData(lastMatch, nextMatch, court.courtName);
				let cta = displayMatch?.match ? this.getCTA(displayMatch, 'scheduled') : null;

				// logger.log('[SummaryScoreboard] renderScheduledCourts - displayMatch:%o, courtName:%o, status:%o', displayMatch.match, displayMatch.match.courtName, displayMatch.status);

				return displayMatch.match ? (
					<div className="match-container" key={court + index} ref={court.courtId}>
						{
							<SumscoresMatchBox
								attributes={{
									data: displayMatch.match,
									style: 'live',
									event: displayMatch.match.shortEventName,
									tableHeader: displayMatch.match.courtName,
									showLinks: true,
									liveVideo: false,
									matchType: displayMatch.status,
									cta,
								}}
							/>
						}
					</div>
				) : null;
			});
		} catch (error) {
			logger.error('[SummaryScoreboard] renderScheduledCourts error:%o', error);
			return <GenericError message="The scoreboard is unavailable at this time." />;
		}
	}

	renderFinalMatches() {
		//logger.log('[SummaryScoreboard] renderFinalMatches');
		let { finalData } = this.state;
		try {
			return finalData.matches.map(match => {
				//logger.log('[SummaryScoreboard] renderFinalMatches match:%o',match);
				return (
					<div className="match-container" key={match.match_id} ref={match.match_id}>
						{
							<SumscoresMatchBox
								attributes={{
									data: match,
									style: 'live',
									event: match.shortEventName,
									tableHeader: match.courtName,
									showLinks: true,
									liveVideo: false,
									matchType: 'completed',
									isFinal: true,
									cta: this.getCTA(match, 'final'),
								}}
							/>
						}
						{/* {match.statsLevel !== 'N' ? (
							<div
								onClick={() => this.openSlamtracker(`/en_US/scores/stats/${match.match_id}.html`)}
								id={match.match_id}
								className={`hover-container ${
									this.state.showSlamtracker && this.state.showSlamtracker == match.match_id
										? 'show'
										: 'hide'
								}`}
								ref={match.match_id}>
								<div className="inner-container">
									<div className="line-1">Match Details by</div>
									<div className="line-2">IBM SlamTracker</div>
								</div>
							</div>
						) : null} */}
					</div>
				);
			});
		} catch (error) {
			logger.error('[SummaryScoreboard] renderFinalMatches error:%o', error);
			<GenericError message="The scoreboard is unavailable at this time." />;
		}
	}

	componentWillUnmount() {
		// sending mip:true tells controller that we are turning mip page off
		//this.props.setScoringStatus({ mip: false });
		clearTimeout(this.schedule_timeout);
		clearTimeout(this.completed_timeout);
		window.removeEventListener('resize', this.onSumscoresResize.bind(this), false);
		//this.state.unmount();
	}

	measureNextPrev = (newIndex) => {
		//logger.log('[SummaryScoreboard] measureNexPrev newIndex:%o',newIndex);
		let prevSlide = this.state.curSlide;

		if (prevSlide < newIndex) {
			MeasurementUtils.dispatchMeasurementCall(this.measureData.type, {
				action: 'next',
				curSlide: newIndex,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall(this.measureData.type, {
				action: 'prev',
				curSlide: newIndex,
			});
		}

		this.setState({
			curSlide: newIndex,
		});
	}

	setTournState() {
		let tournState = this.state?.tournState;
		if (!this.props?.showSumscores) {
			tournState = 'hide';
		} else if (!this.props?.liveIndicators?.scores && this.props?.currentDay) {
			if (
				this.props?.currentDay?.tournament == '0' &&
				this.props?.currentDay?.schedule == '1' &&
				this.props?.currentDay?.completed == '0'
			) {
				tournState = 'preLive';
			} else if (
				this.props?.currentDay?.tournament == '0' &&
				this.props?.currentDay?.schedule == '0' &&
				this.props?.currentDay?.completed == '0'
			) {
				tournState = 'hide';
			} else if (this.props?.currentDay?.tournament == 'post') {
				tournState = 'post';
			} else if (
				this.props?.currentDay?.tournament !== 'post' &&
				parseInt(this.props?.currentDay?.tournament, 10) > 0
			) {
				tournState = 'live';
			} else {
				tournState = 'hide';
			}
		} else {
			if (this.props?.currentDay?.tournament !== 'post' && parseInt(this.props?.currentDay?.tournament, 10) > 0) {
				tournState = 'live';
			} else if (this.props?.currentDay?.tournament == 'post') {
				tournState = 'post';
			} else {
				tournState = 'hide';
			}
		}
		logger.log('[SummaryScoreboard] setTournState tournState:%o', tournState);
		this.setState({
			tournState,
		});
	}

	renderContents = () => {
		let settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: Math.trunc(this.state?.sumscoresWidth / 274),
			slidesToScroll: 1,
			autoplay: false,
			variableWidth: true,
			className: 'sumscoresSlide',
			afterChange: newIndex => {
				this.measureNextPrev(newIndex);
			},
		};

		if (this.props?.windowSizeVal > 3) {
			return (
				<>
					{this.props?.currentDay.tournament !== 'post' ? this.renderScheduledCourts() : null}

					{this.props.liveIndicators && this.props.currentDay?.tournament == 'post'
						? this.renderFinalMatches()
						: null}
				</>
			);
		} else {
			return (
				<Slider {...settings}>
					{this.props.liveIndicators && this.props.currentDay?.tournament !== 'post'
						? this.renderScheduledCourts()
						: null}

					{this.props.liveIndicators && this.props.currentDay?.tournament == 'post'
						? this.renderFinalMatches()
						: null}
				</Slider>
			);
		}
	};

	render() {
		// logger.log('[SummaryScoreboard] render - this:%o', this);
		let { scheduleData } = this.state;
		let { completedData } = this.state;

		// iframe style for rolex clock
		let iframeStyle = {};
		iframeStyle['width'] = '200px';
		iframeStyle['height'] = '70px';
		iframeStyle['border'] = '0';
		iframeStyle['margin'] = '0px auto';
		iframeStyle['padding'] = '0';
		iframeStyle['overflow'] = 'hidden';
		iframeStyle['scroll'] = 'none';

		/** stub state */
		if (this.props?.stubs && this.props?.stubs?.summaryscoreboard?.stub === 'stub') {
			return (
				<div id="summary-scoreboard-wrapper">
					<ErrorBoundary message="The scoreboard is unavailable at this time.">
						<HomeContentWrapper data={{ ...this.props.data }}>
							<div className="title-bar">
								<h3>Live Scores</h3>
							</div>
							<div id="summary-scoreboard" className="stub">
								{ReactHtmlParser(this.props.stubs?.summaryscoreboard?.text)}
							</div>
						</HomeContentWrapper>
					</ErrorBoundary>
				</div>
			);
		} else if (
			(!this.props?.controllerScoring?.slamtracker ||
				(this.props?.controllerScoring?.slamtracker && this.props?.stWindowSize !== 'mobile')) &&
			this.state?.tournState !== 'hide' &&
			this.props?.activeDataStatus == 'loaded' &&
			this.props?.stubs
		) {
			return (
				<div id="summary-scoreboard-wrapper">
					<ErrorBoundary message="The scoreboard is unavailable at this time.">
						<HomeContentWrapper data={{ ...this.props.data }}>
							<div className="title-bar">
								<h3>Live Scores</h3>
								<div className="col">
									<Favorites sumScoreBoard={true} upperCase={true} style="outline" />
								</div>
								<div className="col right link">
									<EventsLink
										to={`/en_US/scores/index.html?promo=homesumscores`}
										measureData={{
											...this.measureData,
											which: `${this.measureData.type}_link`,
										}}>
										View All &gt;
									</EventsLink>
								</div>
							</div>
							{!this.state?.hasError ? (
								/** data state */
								<div id="summary-scoreboard" ref={this.sumscoresRef}>
									{(this.state?.tournState == 'preLive' && scheduleData) ||
									(this.state?.tournState == 'live' && scheduleData && completedData) ||
									(this.state?.tournState == 'post' && this.state?.finalData) ? (
										this.renderContents()
									) : (
										<div style={{ height: '112px', position: 'relative' }}>
											<LoadingIndicator>Loading</LoadingIndicator>
										</div>
									)}
								</div>
							) : (
								/** error state */
								<div id="summary-scoreboard" className="stub error">
									{ReactHtmlParser(this.props.stubs?.summaryscoreboard?.text)}
								</div>
							)}
						</HomeContentWrapper>
					</ErrorBoundary>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryScoreboard);
