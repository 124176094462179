/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import axios from 'axios';

import DayNav from 'appdir/components/common-ui/DayNav';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import { doMeasurement } from 'appdir/components/general/Util';
import { getQuerystringValues } from 'appdir/components/general/Util';

import EventsLink from 'appdir/components/general/EventsLink';
import MatchCard from 'appdir/components/pages/InsightsIndex/MatchCard';

import StubBox from 'appdir/components/common-ui/StubBox';
import TournamentDaySelector from 'appdir/components/common-ui/TournamentDaySelector';

/**
 * -----------------------------------------------------------------------------
 * React Component: InsightsIdxContent
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['InsightsIndex'],
		innovationTest: state['Config'].innovationTest,
		scoringPaths: state['Config'].scoringData,
		sharedDataConfig: state['Config'].sharedDataConfig,
		otherData: state['Config'].otherData,
		favorites: state['Controller'].favorites,
		stubs: state['Config'].stubPages,
		scoringConfig: state['Config'].scoringConfig,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class InsightsIdxContent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// ...props,
			hideDiv: true,
			matchType: 'all',
			day: '7',
			openModal: false,
			filterValue: '',
			nocards: false,
		};
		this.courtSort = [];
		this.searchData = [];

		this.dataLoaded = false;
	}

	handleChange = evt => {
		this.setState({
			filterValue: evt.target.value.trim(),
		});
	};

	handleKeyPress = evt => {
		// if (evt.key == 'Enter') {
		logger.log('[InsightsIdxContent] handleKeyPress - this.state.value:%o', this.state.value);
		logger.log('[InsightsIdxContent] handleKeyPress - this.searchData:%o', this.searchData);
		let value = this.state.value.trim();
		this.setState({ filterValue: value });
		return;

		// if (evt.target.value.trim().length > 0) {
		// 	let filteredSearchData = filter(this.searchData, o => {
		// 		return o.lastName.toLowerCase().includes(value.toLowerCase());
		// 	});
		// 	// logger.log("[InsightsIdxContent] handleKeyPress - filteredSearchData:%o", filteredSearchData);
		// 	let playerId = get(filteredSearchData, '0.id', false);
		// 	if (playerId) {
		// 		let el = document.getElementById(playerId);
		// 		if (el !== null) {
		// 			let coord = el.getBoundingClientRect();
		// 			let offset = 106; //minus the height of the header
		// 			window.scroll({
		// 				top: coord.top - offset,
		// 				behavior: 'smooth',
		// 			});
		// 		}
		// 	}
		// }
		// }
	};

	handleClick = value => {
		this.setState({ filterValue: value });
		return;

		// let filteredSearchData = filter(this.searchData, o => {
		// 	return o.lastName.toLowerCase().includes(value.toLowerCase());
		// });
		// logger.log('[InsightsIdxContent] handleClick - filteredSearchData:%o', filteredSearchData);
		// let playerId = get(filteredSearchData, '0.id', false);
		// if (playerId) {
		// 	let el = document.getElementById(playerId);
		// 	if (el !== null) {
		// 		let coord = el.getBoundingClientRect();
		// 		let offset = 106; //minus the height of the header
		// 		window.scroll({
		// 			top: coord.top - offset,
		// 			behavior: 'smooth',
		// 		});
		// 	}
		// }
	};

	getDraws = () => {
		let request1, request2, request3, request4;

		if (this.isInnovationTest) {
			request1 = axios.get(this.props.innovationTest.drawDetail.replace('<eventId>', 'MS'));
			request2 = axios.get(this.props.innovationTest?.drawDetail.replace('<eventId>', 'WS'));
			request3 = axios.get(this.props.innovationTest?.matchInsightsMatches.path);
			request4 = axios.get(this.props.innovationTest?.scheduleDays);
		} else {
			request1 = axios.get(this.props.scoringPaths.drawDetail.replace('<eventId>', 'MS'));
			request2 = axios.get(this.props.scoringPaths.drawDetail.replace('<eventId>', 'WS'));
			request3 = axios.get(this.props.sharedDataConfig.matchInsightsMatches.path);
			request4 = axios.get(this.props.scoringPaths.scheduleDays);
		}

		axios.all([request1, request2, request3, request4]).then(
			axios.spread((res1, res2, res3, res4) => {
				logger.log(
					'[InsightsIdxContent] getDraws - res1:%o, res2:%o, res3:%o, res4:%o',
					res1,
					res2,
					res3,
					res4
				);

				let ms = res1.data.matches;
				let ws = res2.data.matches;
				let eventDays = filter(res4.data.eventDays, o => {
					return o.tournDay >= 6;
				});

				// let eventDays = res4.data.eventDays;
				let insights_matches = res3.data.matches;
				ms = reject(ms, v => !includes(insights_matches, v.match_id));
				ws = reject(ws, v => !includes(insights_matches, v.match_id));
				let currentDay = filter(eventDays, o => {
					return o.currentDay == true;
				});
				logger.log('[InsightsIdxContent] getDraws - eventDays:%o', eventDays);
				this.setState({ ms, ws, eventDays, day: get(currentDay, '0.tournDay', '7').toString() });
			})
		);
	};

	componentDidMount() {
		/** ?override=true ignores the match status and always set vote to open in the MI details page
		 *  add the same param to the link to the details page
		 */
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		this.isInnovationTest = this.parsedQs?.test;
		if (document.querySelector('.header')) {
			const el = document.querySelector('.header');
			el.innerHTML = 'Match Insights, built with watsonx';
		}

		/**
		 * due to bypassAppLink, ios is no longer firing the pageView metrics call
		 * that's navigated to through bypassAppLink={true} b/w Insights Index and Insights Details
		 * Detect the previous page path and if it's from MI Index page, send it with messageHandlers to iOS
		 */
		// const insightsRegex = /\/en_US\/matchinsights\/(\d*)\.html/;
		// let insightsMatch = document.referrer.match(insightsRegex);

		// if (window.webviewPlatform == 'ios' && insightsMatch) {
		// 	let metricsVal = `IBM Match Insights Index`;

		// 	// logger.log('[MatchInsights] componentDidMount - metricsVal:%o', metricsVal);
		// 	window.webkit.messageHandlers.metrics.postMessage(metricsVal);
		// } else {
		// 	MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
		// 		pageTitle: 'Match Insights Index',
		// 	});
		// }

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Match Insights Index',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.scoringPaths.drawDetail && isEmpty(this.state.ms) && !this.dataLoaded) {
			logger.log('[InsightsIdxContent] componentDidUpdate - time to fetch');
			this.getDraws();

			if (this.props.scoringConfig?.courtNames) {
				this.dataLoaded = true;

				this.props.scoringConfig.courtNames.map(i => {
					this.courtSort[i.long] = i.sb_code;
				});
			}
		}

		let curMatchType = this.state?.matchType;
		let prevMatchType = prevState?.matchType;
		let curDay = this.state?.day;
		let prevDay = prevState?.day;
		if (curMatchType !== prevMatchType || curDay !== prevDay) {
			this.setState(
				{
					nocards: false,
				},
				() => {
					setTimeout(() => {
						this.checkContents();
					}, 2000);
				}
			);
		}
	}

	setMatchType = matchType => {
		// MeasurementUtils.dispatchMeasurementCall('setEvent', { event: matchType });

		let metricsVal = '';

		switch (matchType?.toLowerCase()) {
			case 'ms':
				metricsVal = "Men's Matches";
				break;

			case 'ws':
				metricsVal = "Women's Matches";
				break;

			default:
				metricsVal = 'All Events';
				break;
		}

		// metricsVal = matchType?.toLowerCase() == 'ms' ? "Men's Matches" : "Women's Matches";
		doMeasurement('Match Insights Index', metricsVal);

		this.setState({ matchType, filterValue: '' });
	};

	setDay = day => {
		logger.log('[InsightsIdxContent] setDay:%o', day);
		// MeasurementUtils.dispatchMeasurementCall('setDay', { day });

		doMeasurement('Match Insights Index', 'Day Picker', [day]);

		this.setState({ day, open: '', filterValue: '' });
	};

	onOpen = which => {
		//MeasurementUtils.dispatchMeasurementCall('setDay', { day, event: this.state.matchType});

		this.setState({
			open: which,
		});
	};

	handleHideDiv = () => {
		this.setState(prevState => ({
			hideDiv: !prevState.hideDiv,
		}));

		doMeasurement('Match Insights Index', 'playerSearch', [{ show: this.state.hideDiv }]);
	};

	/***
	 * onEnter:  callback for the search filter box.
	 */
	onEnter = value => {
		logger.log('[SearchPage] onEnter - value:%o', value);
		let searchedPlayer = value;
		this.setState({ searchedPlayer });
		//get element page offset
		let el = document.getElementById(searchedPlayer.id);
		if (el !== null) {
			let coord = el.getBoundingClientRect();
			let offset = 106; //minus the height of the header
			window.scroll({
				top: coord.top - offset,
				behavior: 'smooth',
			});
		}
	};

	infoClick = () => {
		let { openModal } = this.state;
		openModal = openModal ? false : true;

		// MeasurementUtils.dispatchMeasurementCall('infoClick', { open: openModal });

		doMeasurement('Match Insights Index', 'openinfoClickModal', ['open', 'header']);

		this.setState({ openModal });
	};

	/** if for some reasons even though cards data exists but all the MatchCard returned null,
	 * display no match text
	 */
	checkContents = () => {
		let match_card_els = document.getElementsByClassName('match-card');
		let no_matches_els = document.getElementsByClassName('no-matches');

		if (match_card_els?.length == 0 && no_matches_els?.length == 0) {
			this.setState({
				nocards: true,
			});
		} else {
			this.setState({
				nocards: false,
			});
		}
	};

	/**
	 * renderPageContent:  renders the overall page structure.  calls other render type functions.
	 * @returns
	 */
	renderPageContent() {
		let { ms, matchType, eventDays, openModal } = this.state;
		let selectedDay = filter(eventDays, o => {
			return o.tournDay == this.state.day;
		});
		logger.log('[InsightsIndex] renderPageContent - selectedDay:%o', selectedDay);
		if ((this.props.stubs && this.props.stubs.insightsindex.stub === 'stub') || this.state?.ms == 'error') {
			// logger.log('[InsightsIndex] render - stub');
			let message =
				this.state?.ms == 'error'
					? 'Match Insights are not available at this time.'
					: this.props.stubs.insightsindex.text;
			return (
				<div className="content-main">
					<StubBox attributes={{ message: message }} />
				</div>
			);
		} else if (!isEmpty(ms) && !isEmpty(eventDays)) {
			let dayNavOptions = eventDays.map(item => ({
				value: item.tournDay.toString(),
				type: item.quals ? 'quali' : 'tourn',
				textName: item.displayDay,
				dropdownName: item.message,
				title: item.message,
				sort: item.tournDay,
				tournEvents: item.events || '',
				disabled: !item.released ? true : false,
			}));

			let daysData = {
				options: [{ dropdownName: 'Select Day', textName: '', value: '', type: '', title: '', disabled: true }],
			};

			// add available days to base archive menu options
			dayNavOptions.forEach(item => {
				daysData.options.push({
					textName: item.textName,
					dropdownName: item.dropdownName,
					value: item.value,
					type: item.type,
					title: item.title,
					sort: item.sort,
					disabled: item.disabled,
				});
			});

			let intDay = parseInt(this.state.day, 10);
			let cards;

			if (matchType !== 'all') {
				cards = filter(this.state[matchType], o => {
					return o.eventDay == intDay;
				});
			} else {
				let allCards = this.state.ws.concat(this.state.ms);
				cards = filter(allCards, o => {
					return o.eventDay == intDay;
				});
			}

			let oppMatchType = matchType == 'ms' ? 'ws' : 'ms';
			let oppCards = filter(this.state[oppMatchType], o => {
				return o.eventDay == intDay;
			});

			let lowerCaseFilter = this.state.filterValue.toLowerCase();
			if (lowerCaseFilter != '') {
				cards = filter(cards, o => {
					return (
						o.team1.lastNameA.toLowerCase().includes(lowerCaseFilter) ||
						o.team2.lastNameA.toLowerCase().includes(lowerCaseFilter)
					);
				});
			}

			/** add a primary sorting to push completed matches down and put upcoming matches on top */
			cards = cards.map(i => {
				return {
					...i,
					sort: this.courtSort[i.courtName],
					sortPrimary: i?.status.toLowerCase() == 'completed' ? 1 : -1,
				};
			});
			cards = sortBy(cards, ['sortPrimary', 'sort']);
			logger.log('[InsightsIndex] cards:%o', cards);
			let searchData = [];
			cards.forEach(i => {
				searchData.push({ firstName: i.team1.firstNameA, lastName: i.team1.lastNameA, id: i.team1.idA });
				searchData.push({ firstName: i.team2.firstNameA, lastName: i.team2.lastNameA, id: i.team2.idA });
			});

			let modalHeaderText = 'Match Insights with Watson';
			let modalBodyText =
				' Match Insights with IBM Watson are AI-generated previews that help fans get up to speed on every singles match at Wimbledon. Combining the power of hybrid cloud and AI, Match Insights analyse data from thousands of sources -- from recent statistics to expert commentary -- producing projections of each player’s Likelihood to Win, an explanation of their Win Factors, and a measurement of player momentum called the IBM Power Index. Match Insights also include relevant quotes from the media and a natural language summary of key performance metrics.';

			return (
				<div className="content-main insights-index">
					<div className="idx-header column-layout">
						{/* {openModal && <MIModal header={modalHeaderText} body={modalBodyText} closeModal={this.infoClick} />}  */}
						{/* <div className="idx-header-inner">
							<div className="headtext">
								Match Insights with Watson
								<div className="info">
									<MIInfoIcon onInfoClickFn={this.infoClick} />
								</div>
							</div>
							<hr className="spacerLine" />
							<div className="subtext">
								Select a match below to view the AI-generated preview for every singles match
							</div>
						</div> */}
					</div>

					<div className="page-submenu-wrapper insights-mode">
						<div className="page-submenu">
							<div
								data-text="All Events"
								className={`item ${matchType == 'all' ? `selected` : ``}`}
								onClick={() => this.setMatchType('all')}
								onKeyDown={e => {
									if (e.key == ' ' || e.code == 'Space') {
										this.setMatchType('all');
									}
								}}
								tabIndex={0}>
								All Events
							</div>
							<div
								data-text="Women's Singles"
								className={`item ${matchType == 'ws' ? `selected` : ``}`}
								onClick={() => this.setMatchType('ws')}
								onKeyDown={e => {
									if (e.key == ' ' || e.code == 'Space') {
										this.setMatchType('ws');
									}
								}}
								tabIndex={0}>
								Women's Singles
							</div>
							<div
								data-text="Men's Singles"
								className={`item ${matchType == 'ms' ? `selected` : ``}`}
								onClick={() => this.setMatchType('ms')}
								onKeyDown={e => {
									if (e.key == ' ' || e.code == 'Space') {
										this.setMatchType('ms');
									}
								}}
								tabIndex={0}>
								Men's Singles
							</div>
						</div>

						<div className="filters">
							<div className="player-search">
								<div className="searchInputContainer">
									<input
										hidden={this.state.hideDiv}
										type="text"
										className="player-search"
										value={this.state.filterValue}
										onChange={this.handleChange}
										onKeyPress={this.handleChange}
										// onKeyPress={this.handleKeyPress}
										// onClick={this.clearValue}
										placeholder="Search by Last Name"
									/>
									<div className="searchIcon">
										<div className="ar-icon" onClick={this.handleHideDiv}>
											<div>
												<img src="/assets/images/header/search-icon.svg" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="daynav-wrapper">
							<div className="daynav scores-select-menu dark">
								{window.webview && window.webviewPlatform == 'android' ? (
									<DayNav
										name="days"
										attributes={daysData}
										// selected={this.state.filters.day}
										// show={this.state.filters.open == 'days'}
										selected={this.state.day}
										show={this.state.open == 'days'}
										onSelected={this.setDay}
										onOpen={this.onOpen}
										mode={`tourn`}
									/>
								) : (
									<TournamentDaySelector
										name="days"
										handleOptionSelected={this.setDay}
										pageMode="tourn"
										selectedDay={this.state.day}
										enableFuture={false}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="column-layout insights-cards-container">
						<div className="insights-cards column-layout">
							{this.isInnovationTest && this.props.innovationTest?.availableMatches?.length > 0 && (
								<div className="column-layout">
									<h2>Test Link(s) for Webviews</h2>
									{this.props.innovationTest?.availableMatches?.map((match, index) => {
										return (
											<p>
												<EventsLink
													to={`/en_US/scores/stats/${match}.html${
														this.parsedQs?.override ? '?override=true' : ''
													}${this.parsedQs?.test ? '?test=true' : ''}`}
													bypassAppLink={true}
													key={`InnovationTest${match}${index}`}>
													<div className="card-cta">
														<h3>Test Link to Match Insights - Match Id: {match}</h3>
													</div>
												</EventsLink>
											</p>
										);
									})}
								</div>
							)}

							{cards.length > 0 ? (
								<>
									{cards.map(i => {
										return (
											<MatchCard
												match={i}
												total={cards.length}
												searchedPlayer={this.state.searchedPlayer}
												key={`-match-card-${i.match_id}`}
											/>
										);
									})}
								</>
							) : (
								<div className="no-matches">
									{this.state.filterValue !== ''
										? 'Player Not Found'
										: matchType == 'all'
										? 'Match Insights are not available for this day.'
										: `${
												matchType == 'ms' ? "MEN'S" : "WOMEN'S'"
										  } MATCH INSIGHTS ARE NOT AVAILABLE FOR THIS DAY. ${
												oppCards.length > 0
													? `CHECK OUT THE ${
															matchType == 'ms' ? "WOMEN'S'" : "MEN'S"
													  } MATCH INSIGHTS FOR DAY ${get(selectedDay, '0.displayDay', '')}.`
													: ``
										  }`}
								</div>
							)}

							{this.state.nocards ? (
								<div className="no-matches-error">Match Insights are not available for this day.</div>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			);
		}
	}

	render() {
		logger.log('[InsightsIdxContent] render - this:%o,  webviewPlatform:%o', this, window.webviewPlatform);
		// let isInnovationTest = this.props?.innovationTest?.enableTest;

		if (this.props.status == 'loaded') {
			let { ms, matchType, eventDays, openModal } = this.state;
			let selectedDay = filter(eventDays, o => {
				return o.tournDay == this.state.day;
			});
			logger.log('[InsightsIdxContent] render - selectedDay:%o', selectedDay);

			return <>{this.renderPageContent()}</>;
		} else {
			return (
				<Fragment>
					<div className="content-main">
						<LoadingIndicator type="white" />
					</div>
				</Fragment>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InsightsIdxContent);
