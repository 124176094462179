import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsLink from 'appdir/components/general/EventsLink';

const mapStateToProps = (state, props) => {
	return {
		...state['NewsletterSignUp'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class NewsletterSignUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailValue: '',
			placeHolder: 'Enter Email Address',
			errorState: false,
		};

		this.alertMsg = {
			id: '1',
			type: 'emailSubmit',
			text: '',
		};

		this.measureData = Object.assign({}, props.attributes.data);
		delete this.measureData.content;

		this.loaded = false;

		logger.log('[NewsletterSignUp] constructor - this:%o', this);
	}

	render() {
		// logger.log('[NewsletterSignUp] render - this:%o', this, this);

		return (
			<div className="newsletter-signup">
				<div className="header">
					<div className="title">
						US Open <span>Insider Newsletter</span>
					</div>
				</div>
				<div className="content">
					<p>Don't miss your chance to receive USTA and US Open News, Section News, Shop News and more.</p>
					<div className="uso-primary-button">
						<EventsLink to={'https://t.email.usopen.org/lp/ustaProAcq'}>Sign up</EventsLink>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterSignUp);
