/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import isArray from 'lodash/isArray';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Image
 * -----------------------------------------------------------------------------
 */
const Image = props => {
	logger.log('[Util Image] - props:%o', props);

	const { data } = props;
	let imageArray;

	/** if no data, no need to proceed, return null */
	if (!data) {
		return null;
	}

	const { images } = data;

	/** if images are not in an array, put them in one */
	if (!isArray(images.image)) {
		imageArray = new Array(images.image);
	} else {
		imageArray = images.image;
	}

	const getTitleLine = image => {
		if (image?.caption && image?.credit) {
			return (
				<div>
					<div className="caption">{image?.caption}</div>
					<div className="credit">
						Photo by <span className="author">{image?.credit}</span>
					</div>
				</div>
			);
		} else if (image?.caption) {
			return <div className="caption">{image?.caption}</div>;
		} else if (image?.credit) {
			return (
				<div className="credit">
					Photo by <span className="author">{image?.credit}</span>
				</div>
			);
		} else {
			return null;
		}
	};

	const renderImage = image => {
		if (data?.link !== '#') {
			return (
				<div className={`photo ${data?.style}`} key={image.imageId}>
					<EventsLink to={data?.link}>
						<img src={image?.url} alt={image?.caption} />
						<div className="photo-info">{getTitleLine(image)}</div>
					</EventsLink>
				</div>
			);
		} else {
			return (
				<div className={`photo ${data?.style}`} key={image.imageId}>
					<img src={image?.url} alt={image?.caption} />
					{getTitleLine(image)}
				</div>
			);
		}
	};

	// logger.log("[Util Image] - imageArray:%o", imageArray);

	/** loop through imageArray and write out html for each image */
	return imageArray.map(image => {
		return renderImage(image);
	});
};

export default Image;
