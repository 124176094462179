/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import FavoriteStar from 'appdir/components/common-ui/FavoriteStar';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerSeed
 * -----------------------------------------------------------------------------
 */

class PlayerSeed extends Component {
	constructor(props) {
		super(props);
		logger.log('[PlayerSeed] constructor - state:%o', props);
	}

	componentDidMount() {
		if (this.props.hasOwnProperty('mount')) {
			this.props.mount(this);
		}
	}

	componentDidUpdate(prevProps) {
		// logger.log('[PlayerSeed] componentDidUpdate - this:%o', this);

		if (prevProps.selectedEvent !== this.props.selectedEvent) {
			// this.formatSeedsList();
			// this.formatSeedDoublesList();
		}
	}

	generateName(player, tourId) {
		// logger.log("[PlayerSeed] playerInfo:%o", find(this.props.players, ['id', player['tour-id']]))
		let playerInfo;
		if (tourId) {
			playerInfo = find(this.props.players, ['id', tourId]);
			if (playerInfo == undefined) {
				return null;
			} else {
				return (
					<Link to={`/en_US/players/overview/${tourId}.html`}>
						<span className="firstName">{playerInfo?.first_name}</span>
						<span className="lastName">{playerInfo?.last_name}</span>
					</Link>
				);
			}
		} else {
			playerInfo = find(this.props.players, ['id', player['tour-id-a']]);
			if (playerInfo == undefined) {
				return null;
			} else {
				return (
					<Link to={`/en_US/players/overview/${player['tour-id-a']}.html`}>
						<span className="firstName">{playerInfo?.first_name}</span>
						<span className="lastName">{playerInfo?.last_name}</span>
					</Link>
				);
			}
		}
	}

	generateOppName = (player, id) => {
		//logger.log("[PlayerSeed] playerInfo:%o", find(this.props.players, ['id', player['opp-tour-id']]))
		if (this.props.showPlayerSeeds) {
			let playerInfo;
			if (id) {
				playerInfo = find(this.props.players, ['id', id]);
				if (playerInfo == undefined) {
					return null;
				} else {
					return (
						<Link to={`/en_US/players/overview/${id}.html`}>
							<span className="firstName">{playerInfo?.first_name}</span>
							<span className="lastName">{playerInfo?.last_name}</span>
						</Link>
					);
				}
			} else {
				playerInfo = find(this.props.players, ['id', player['tour-id-a']]);
				if (playerInfo == undefined) {
					return null;
				} else {
					return (
						<Link to={`/en_US/players/overview/${player['opp-tour-id-a']}.html`}>
							<span className="firstName">{playerInfo?.first_name}</span>
							<span className="lastName">{playerInfo?.last_name}</span>
						</Link>
					);
				}
			}
		} else {
			let playerInfo = find(this.props.players, ['id', player['opp-tour-id']]);
			if (playerInfo == undefined) {
				return null;
			} else {
				return (
					<Link to={`/en_US/players/overview/${player['opp-tour-id']}.html`}>
						<span className="firstName">{playerInfo?.first_name}</span>
						<span className="lastName">{playerInfo?.last_name}</span>
					</Link>
				);
			}
		}
	};

	generateCountry = (player, tourId) => {
		let playerInfo;
		let countryCode = '';
		let countryName = '';
		let countryIndex;

		if (tourId) {
			playerInfo = find(this.props.players, ['id', tourId]);
			countryIndex = this.props?.countriesLookUp?.findIndex((item, index) => item.code === player['country-b']);

			if (countryIndex) {
				countryCode = this.props.countriesLookUp[countryIndex].code;
				countryName = this.props.countriesLookUp[countryIndex].display;
			}

			if (playerInfo == undefined) {
				return null;
			} else {
				return (
					<div className="country">
						<div className="flag">
							<img
								src={`//www.usopen.org/images/flags/${playerInfo.country}_h.gif`}
								alt={`${countryName} flag`}
							/>
						</div>
						<span className={`countryName ${countryName.length > 12 ? 'longCtyName' : ''}`}>
							{countryName}
						</span>
					</div>
				);
			}
		} else {
			playerInfo = find(this.props.players, ['id', player['tour-id-a']]);
			countryIndex = this.props?.countriesLookUp?.findIndex((item, index) => item.code === player['country-a']);

			if (playerInfo == undefined) {
				return null;
			} else {
				if (countryIndex) {
					countryCode = this.props.countriesLookUp[countryIndex].code;
					countryName = this.props.countriesLookUp[countryIndex].display;
				}
				return (
					<div className="country">
						<div className="flag">
							<img
								src={`//www.usopen.org/images/flags/${playerInfo.country}_h.gif`}
								alt={`${countryName} flag`}
							/>
						</div>
						<span className={`countryName ${countryName.length > 12 ? 'longCtyName' : ''}`}>
							{countryName}
						</span>
					</div>
				);
			}
		}
	};

	renderPlayerBoxes = gender => {
		// logger.log('[PlayerSeed] players:%o, gender:%o', this.props.seed_results, gender);
		// let playerList = this.props.seed_results[gender == 'M' ? 0 : 1]?.seeds?.slice(0, 10);
		let playerList;
		if (this.props?.seed_results.length > 1) {
			if (this.props?.eventName?.includes('Doubles')) {
				playerList = this.formatSeedDoublesList()[gender == 'F' ? 0 : 1]?.seeds?.slice(0, 32);
			} else {
				playerList = this.props?.seed_results[gender == 'F' ? 0 : 1]?.seeds?.slice(0, 32);
			}
		} else {
			playerList = this.props?.seed_results[0].seeds?.slice(0, 32);
		}
		// logger.log('[PlayerSeed] playerList:%o', playerList);
		return playerList?.map((player, index) => (
			<div className="player-box" key={`player-box${index}`}>
				{this.props?.favorites?.players.length > 0 ? (
					<>
						<div className="playerPic">
							<img
								src={`//images.usopen.org/ix-events-usta-players/${player['tour-id-a']}.jpg`}
								alt={`${player['long-name-a']}player_photo`}
							/>
						</div>
						<div className="playerInfo">
							<div className="playerName">{this.generateName(player)}</div>
							{this.generateCountry(player)}
							<div className="withFavs">
								<FavoriteStar
									className="favorite"
									id={player['tour-id-a']}
									playerName={player['short-name-a']}
									type="players"
									unFavColor="blue"
									favColor="yellow"
									alwaysShow={true}
								/>
								<div className="number">
									<span>{index + 1}</span>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="number">
							<span>{index + 1}</span>
						</div>
						<div className="playerPic">
							<img
								src={`//images.usopen.org/ix-events-usta-players/${player['tour-id-a']}.jpg`}
								alt={`${player['long-name-a']}player_photo`}
							/>
						</div>
						<div className="playerInfo">
							<div className="playerName">{this.generateName(player)}</div>
							{this.generateCountry(player)}
							<FavoriteStar
								className="favorite"
								id={player['tour-id-a']}
								playerName={player['short-name-a']}
								type="players"
								unFavColor="blue"
								favColor="yellow"
								alwaysShow={true}
							/>
						</div>
					</>
				)}
			</div>
		));
	};

	renderSeededBoxes(gender) {
		logger.log(
			'[PlayerSeed] renderSeededBoxes - event:%o, players:%o, gender:%o',
			this.props.selectedEvent,
			this.props.seed_results,
			gender
		);
		// let playerList = this.props?.seed_results[gender == 'F' ? 0 : 0]?.seeds?.slice(0, 32);

		let playerList;
		if (this.props?.seed_results.length > 1) {
			if (this.props?.eventName?.includes('Doubles')) {
				playerList = this.formatSeedDoublesList()[gender == 'F' ? 0 : 1]?.seeds?.slice(0, 32);
			} else {
				playerList = this.props?.seed_results[gender == 'F' ? 0 : 1]?.seeds?.slice(0, 32);
			}
		} else {
			playerList = this.props?.seed_results[0].seeds?.slice(0, 32);
		}
		//playerList = this.props?.seed_results[gender == 'M' ? 0 : 1]?.seeds?.slice(0, 32);

		let round = [];
		return playerList?.map((player, index) => (
			<div id="playerBox" key={`player-box${index}`}>
				<div
					className={`player-box ${
						this.props?.favorites?.players?.includes(player['tour-id-a']) ? 'highlightClass' : ''
					}`}>
					<div id="player-info">
						<div className="number">
							<span>{index + 1}</span>
						</div>
						<div className="playerPic">
							<img
								src={`//images.usopen.org/ix-events-usta-players/${player['tour-id-a']}.jpg`}
								alt={`${player['long-name']}player_photo`}
							/>
						</div>
						<div className="playerInfo">
							<div className="playerName">{this.generateName(player)}</div>
							<div className="playerCountry">{this.generateCountry(player)}</div>
							<div className="withFavs">
								<FavoriteStar
									className="favorite"
									id={player['tour-id-a']}
									playerName={player['short-name-a']}
									type="players"
									unFavColor="blue"
									favColor="yellow"
									alwaysShow={true}
								/>
							</div>
						</div>
					</div>
					<div id="oppnt-Info">
						<div id="result">
							<div id="outcome">
								{player['result'] === 'def.'
									? player['result'].replace(/def./gi, 'defeated:')
									: player['result']}
								{player['result'] === 'vs.' || player['result'] === 'def.' ? '' : ':'}
							</div>
							<div className="playerName">
								<span>
									{player['opp-long-name-a'] === 'Qualifier' ||
									player['opp-short-name-a'] === 'Qualifier'
										? 'Qualifier'
										: this.generateOppName(player, player['opp-tour-id-a'])}
								</span>
								{this.props.selectedEvent == 'WD' ||
								this.props.selectedEvent == 'MD' ||
								this.props.selectedEvent == 'XD' ? (
									<span className="opp-name">
										{this.generateOppName(player, player['opp-tour-id-b'])}
									</span>
								) : null}
							</div>
						</div>

						<div id="round">
							in:
							<div id="roundNum">
								{player['round'].includes('RND')
									? player['round'].replace(/RND/gi, 'Round')
									: player['round'] === 'QF'
									? player['round'].replace(/QF/gi, 'Quarterfinal')
									: player['round'] === 'SF'
									? player['round'].replace(/SF/gi, 'Semifinal')
									: player['round'] === 'FINAL'
									? player['round'].replace(/FINAL/gi, 'Final')
									: player['round']}
							</div>
						</div>
					</div>
				</div>
				{this.props?.eventName?.includes('Doubles') ? (
					<div
						className={`player-box ${
							this.props?.favorites?.players?.includes(player['tour-id-b']) ? 'highlightClass' : ''
						}`}>
						<div id="player-info">
							<div className="number">
								<span></span>
							</div>
							<div className="playerPic team-member-two">
								<img
									src={`//images.usopen.org/ix-events-usta-players/${player['tour-id-b']}.jpg`}
									alt={`${player['long-name']}player_photo`}
								/>
							</div>
							<div className="playerInfo">
								<div className="playerName">{this.generateName(player, player['tour-id-b'])}</div>
								<div className="playerCountry">{this.generateCountry(player, player['tour-id-b'])}</div>
								<div className="withFavs">
									<FavoriteStar
										className="favorite"
										id={player['tour-id-b']}
										playerName={player['short-name-b']}
										type="players"
										unFavColor="blue"
										favColor="yellow"
										alwaysShow={true}
									/>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
		));
	}

	formatSeedDoublesList() {
		let team = [];
		let teamMemberOne = [];
		let teamMemberTwo = [];
		let event = this.props.selectedEvent;
		let data = this.props.seed_results;

		data?.map(event => {
			event?.seeds.map(player => {
				teamMemberOne.push({
					eventName: event['eventName'],
					event_id: event['event_id'],
					seeds: {
						country_a: player['country-a'],
						long_name_a: player['long-name-a'],
						opp_country_a: player['opp-country-a'],
						opp_long_name_a: player['opp-long-name-a'],
						opp_rank: player['opp-rank'],
						opp_short_name_a: player['opp-short-name-a'],
						opp_tour_id_a: player['opp-tour-id-a'],
						rank: player['rank'],
						results: player['results'],
						round: player['round'],
						short_name_a: player['short-name-a'],
						tour_id_a: player['tour-id-a'],
					},
				});
				teamMemberTwo.push({
					eventName: event['eventName'],
					event_id: event['event_id'],
					seeds: {
						country_b: player['country-b'],
						long_name_b: player['long-name-b'],
						opp_country_b: player['opp-country-b'],
						opp_long_name_b: player['opp-long-name-b'],
						opp_rank: player['opp-rank'],
						opp_short_name_b: player['opp-short-name-b'],
						opp_tour_id_b: player['opp-tour-id-b'],
						rank: player['rank'],
						results: player['results'],
						round: player['round'],
						short_name_b: player['short-name-b'],
						tour_id_b: player['tour-id-b'],
					},
				});
			});
		});
		team.push(teamMemberOne);
		team.push(teamMemberTwo);

		// logger.log('[PlayerSeed] formatSeedDoublesList: ', team);

		return team;
	}

	areThereSeededPlayers = () => {
		let areTherePlayers = false;

		if (this.props?.seed_results?.length > 0) {
			for (let i = 0; i < this.props.seed_results?.length; i++) {
				areTherePlayers = this.props.seed_results[i].seeds?.length > 0;
				if (areTherePlayers) {
					areTherePlayers = true;
					break; //exit loop if one seed is available
				}
			}
		}

		return areTherePlayers;
	};

	render() {
		let settings = {
			dots: false,
			infinite: false,
			speed: 1000,
			slidesToShow: 6,
			slidesToScroll: 6,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 1279,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
						infinite: true,
					},
				},
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					},
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 460,
					settings: {
						slidesToShow: 1.4,
						slidesToScroll: 1,
					},
				},
			],
		};

		let breakpoint = this.props.windowSize;

		logger.log('[PlayerSeed] render - this:%o', this);

		//logger.log("[PlayerSeed] breakpoint:%o", breakpoint);
		/** config switch **/
		//logger.log('[PlayerSeed] OtherData:%o', this.props.OtherData);
		// logger.log('[PlayerSeed] props:%o', this.props);

		/** make sure at least one gender has seeded players data */
		let areTherePlayers = this.areThereSeededPlayers();

		if (!get(this.props, 'OtherData.showSeeds', false) || !this.props.seed_results || !areTherePlayers) {
			return null;
		}
		if (this.props.showPlayerSeeds == true) {
			return (
				<Fragment>
					<div className="player-seed">
						<div
							className={`womensContainer ${
								(this.props?.gender == 'M' &&
									this.props.selectedEvent !== 'AE' &&
									this.props.selectedEvent !== 'XD') ||
								this.props.selectedEvent == 'US' ||
								this.props.selectedEvent == 'UD' ||
								this.props?.eventName?.includes("Men's")
									? 'hideCol'
									: ''
							}`}>
							<div className="women">
								{breakpoint == 'mobile' || breakpoint == 'landscape' ? (
									<>{this.props.gender == 'F' && <>{this.renderSeededBoxes('F')}</>}</>
								) : (
									<>{this.renderSeededBoxes('F')}</>
								)}
							</div>
						</div>
						<div className="separator"></div>
						<div
							className={`mensContainer ${
								(this.props?.gender == 'F' &&
									this.props?.selectedEvent !== 'AE' &&
									this.props.selectedEvent !== 'XD') ||
								this.props?.eventName?.includes("Women's")
									? 'hideCol'
									: ''
							}`}>
							<div className="men">
								{breakpoint == 'mobile' || breakpoint == 'landscape' ? (
									<>{this.props.gender == 'M' && <>{this.renderSeededBoxes('M')}</>}</>
								) : (
									<>{this.renderSeededBoxes('M')}</>
								)}
							</div>
						</div>
					</div>
				</Fragment>
			);
		} else {
			if (this.props.selectedEvent == 'AE') {
				return (
					<Fragment>
						<div className="player-seed">
							<div className="seedTitle">
								{`${
									breakpoint != 'mobile' || this.props.gender == 'F' ? `WOMEN'S` : `MEN'S`
								} TOP SEEDS`}
							</div>
							<div className="womensContainer">
								<div className="womensContainer">
									<div className="women">
										<Slider {...settings}>
											{!this.props.gender || this.props.gender == 'F'
												? this.renderPlayerBoxes('F')
												: this.renderPlayerBoxes(this.props.gender)}
										</Slider>
									</div>
								</div>
							</div>
							{breakpoint != 'mobile' && (
								<div className="mensContainer">
									<div className="seedTitle">MEN'S TOP SEEDS</div>
									<div className="men">
										{breakpoint == 'mobile' ? (
											<Slider {...settings}>{this.renderPlayerBoxes(this.props.gender)}</Slider>
										) : (
											<Slider {...settings}>{this.renderPlayerBoxes('M')}</Slider>
										)}
									</div>
								</div>
							)}
						</div>
					</Fragment>
				);
			} else {
				return (
					<Fragment>
						{this.props.players.length > 0 && (
							<div className="player-seed">
								<div className="mensContainer">
									<div className="seedTitle">{this.props?.eventName} TOP SEEDS</div>
									<div className="men">
										{breakpoint == 'mobile' ? (
											<Slider {...settings}>{this.renderPlayerBoxes(this.props.gender)}</Slider>
										) : (
											<Slider {...settings}>{this.renderPlayerBoxes(this.props.gender)}</Slider>
										)}
									</div>
								</div>
							</div>
						)}
					</Fragment>
				);
			}
		}
	}
}

PlayerSeed.defaultProps = {};
export default PlayerSeed;
