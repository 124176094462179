/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import deps from 'dependencies';
import AdTag from 'appdir/components/general/AdTag';
import VisibilitySensor from 'react-visibility-sensor';

/**
 * -----------------------------------------------------------------------------
 * React Component: AdUnitLazy 
 * 
 * To use this component, you need to create a config in config_web.json
 * 
 * Config looks like this (just add a new one under mip):  Description of everything is in the constructor
 * 
 * "adConfig" : {
        "mip": {
            "dfpNetworkId":"5681",
            "sizeMapping":[ 
                {"viewport": [1024, 0], "sizes":[[970, 90]]},
                {"viewport": [768, 0], "sizes":[[728, 90]]},
                {"viewport": [0, 0], "sizes":[[320, 50]] }
            ],
            "shouldRefresh" : true,
            "sizes" : [[970,90],[728,90],[320,50]],
            "isResponsive" : true,
            "adUnit" : "2016_Desktop_ROS/leaderboard",
            "refreshRate" : 30000
        }
        "yourConfig": {
            ...
        }
    }
 * 
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['AdUnitLazy'],
		windowSize: state['Controller'].windowSize,
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.AdUnitLazy.mount()),
});

class AdUnitLazy extends Component {
	constructor(props) {
		super(props);
		this.state = { isVisible: false };
		this.onChange = this.onChange.bind(this);
	}
	componentDidMount() {
		// logger.log('[AdUnitLazy] adConfig:%o', this.props.adConfig);
		if (!this.props.adConfig) {
			this.props.mount();
		}
	}

	onChange(isVisible) {
		// logger.log('[AdUnitLazy] onChange:%o', isVisible);
		if (isVisible && !this.state.isVisible) {
			this.setState({ isVisible });
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!this.props.data || !this.props.data.adType || !this.props.adConfig) {
			return true;
		} else {
			return false;
		}
	}

	render() {
		// logger.log('[AdUnitLazy] render this:%o', this);

		let randomKey = Math.floor(Math.random() * 10000000);
		if (this.props.data && this.props.data.adType && this.props.adConfig) {
			logger.log('[AdUnitLazy] rendering ad...');
		}

		let isHomepage = false;
		let pathName = get(this.props, 'Router.pathname', false);
		if (pathName == '/' || pathName == '/index.html') {
			isHomepage = true;
		}

		// const adType = this.state.adType;
		//visibility sensor doesn't pick up unless its child element has height > 0
		return this.props.data && this.props.data.adType && this.props.adConfig ? (
			<Fragment>
				{!isHomepage ? (
					<AdTag
						key={'lazy-ad-' + randomKey}
						id={'lazy-ad-' + randomKey}
						adConfig={this.props.adConfig[this.props.data.adType]}
						dfpNetworkId={this.props.adConfig.dfpNetworkId}
					/>
				) : (
					<VisibilitySensor onChange={isVisible => this.onChange(isVisible)} partialVisibility={true}>
						{({ isVisible }) => (
							<div style={{ minHeight: '15px' }}>
								{/* <div>I am {isVisible ? 'visible' : 'invisible'}</div> */}
								<span
									style={{
										color: 'rgb(0 0 0 / 0%)',
										height: '1px',
										display: 'block',
										overflow: 'hidden',
									}}>
									.
								</span>
								{(isVisible || this.state.isVisible) && (
									<AdTag
										key={'lazy-ad-' + randomKey}
										id={'lazy-ad-' + randomKey}
										adConfig={this.props.adConfig[this.props.data.adType]}
										dfpNetworkId={this.props.adConfig.dfpNetworkId}
									/>
								)}
							</div>
						)}
					</VisibilitySensor>
				)}
			</Fragment>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdUnitLazy);
