/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useEffect, useState } from 'react';
import RelatedContent from 'appdir/components/cms/RelatedContent';
import { formatRelatedContentURL } from 'appdir/components/pages/Slamtracker/Utilities';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Slamtracker Related Content
 * -----------------------------------------------------------------------------
 */

const STRelatedContent = props => {
  // logger.log('[STRelatedContent] props: %o', props);
  const [showRelated, setShowRelated] = useState(null);

  let rc_url = formatRelatedContentURL(props?.statMatch, props?.relatedData?.matchRelated);
  logger.log('[STRelatedContent] showRelated:%o', showRelated);
  return (
    <div className={`relatedContentContainer ${showRelated ? '' : 'hide'}`}>
      <div className='title'>Related Content</div>
      <div className='related-content'>
        <RelatedContent 
          data={{type: 'match' }}
          url={rc_url}
          location={props.location}
          isPanel={true}
          filterData={props.filterData}
          showRelated={shouldShow => setShowRelated(shouldShow)}
        />
      </div>
    </div>
  );
}

export default STRelatedContent;