/**
 Controller Initial State
*/

export default {
	favorites: {
		show: false,
		players: [],
	},
	platform: '',
	sidepanel: {
		userOpen: true,
	},
	scoring: {
		view: 'page',
		uom: 'imperial',
		mip: false,
		sidepanel: false,
		slamtracker: false,
		started: false,
		schedule: false,
		draws: false,
	},
	stpanel: {
		matchid: null,
		matchstatus: null,
		open: false,
		panelToST: false,
	},
	content: {
		viewed: [], //list of content ids
	},
	privacy: {
		viewed: false,
	},
	video: {
		main: {
			id: '',
			status: '',
			volume: '',
		},
		sidepanel: {
			id: '',
			status: '',
			volume: '',
		},
		channels: null,
	},
	loaded: false,
	geoblock: false,
	// 7776000000 = 90days in milliseconds
	persist: {
		favorites: 7776000000,
		'scoring.uom': 7776000000,
	},
};
