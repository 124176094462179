import MediaLanding from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/:lang(en_US)/news/latest/index.html', '/:lang(en_US)/news/latest.html'],
		exact: true,
		component: MediaLanding,
		load: params => deps.actions.NewsIndex.mount(params),
	},
];
