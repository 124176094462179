import deps from "dependencies";

export default {
  mount: params => (dispatch, getState, store) => {
    logger.log("[GroundsMapMatchBox] action.mount - params:%o", params);

    return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
      Config => {
        // logger.log('[GroundsMapMatchBox] action.mount - Config: %o', Config);

        let data = {
          path: Config.scoringData.matchScore.path,
          status: "match",
          court: params.courtId
        };
        logger.log("[GroundsMapMatchBox] action.mount - data: %o", data);
        dispatch({
          type: deps.actionTypes.GROUNDSMAPMATCHBOX_MOUNT,
          data: data
        });
      }
    );
  },
  unmount: () => (dispatch, getState, store) => {
    let data = {
      status: null,
      path: null
    };
    dispatch({ type: deps.actionTypes.GROUNDSMAPMATCHBOX_UNMOUNT, data: data });
  }
};
