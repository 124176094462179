import React from 'react';
import EventsLink from 'components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';
import Time from 'appdir/components/common-ui/Time';
/**
 * ---------------------------------------
 * DoubleStack - Module with 2,4,6 pieces of content. the module will have the ability to have a title.
 * the content within the module has an image and a title
 * ---------------------------------------
 */

const LatestMediaTile = props => {
	logger.log('[LatestMediaTile] render props:%o, ', props);
	let data = props.data;
	logger.log('[BasicDisplay] ' + data.shareUrl);
	// if (data) {
	// 	let captionClass = data.style == 'lead' ? 'leadTitle' : '';}
	return (
		<div className="story">
			{/* <BasicDisplay data={item} /> */}
			{data.type == 'video' || data.type == 'photo' || data.type == 'news' ? (
				<div className="displayWrapper">
					<EventsLink to={data.url}>
						<div className="photoContainer">
							<div className="photoImg image-wrapper">
								<img src={data.images[0].small} title={data.title} />
							</div>
							{data.type === 'video' ? <div className="playBtn">&nbsp;</div> : null}
						</div>
						<div className="captionContainer">
							<div className="caption">{ReactHtmlParser(data.title)}</div>
							{data.type === 'news' ? (
								<div className="credit">By {data.metadata.credit}</div>
							) : (
								<div className="credit"></div>
							)}
							<div className="date">{<Time epoch_ms={data.displayDate} format="dddd, MMMM D" />}</div>

							{/* {item.style == 'lead' || this.props.display == 'story' ? (
								<div className="description">{ReactHtmlParser(item.description)}</div>
							) : null} */}
						</div>
						<div className="content-type">
							<div>{ReactHtmlParser(data.type)}</div>
							{data.type == 'news' ? (
								<i className="icon-blogIcon-news" />
							) : data.type == 'video' ? (
								<i className="icon-blogIcon-video" />
							) : data.type == 'photo' ? (
								<i className="icon-video-camera" />
							) : null}
						</div>
					</EventsLink>
				</div>
			) : null}
		</div>
	);
};

export default LatestMediaTile;
