import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from 'components/Template';

const mapStateToProps = (state, props) => {
	return {
		...state['FeedbackThankYou'],
		...props,
	};
};

class FeedbackThankYou extends Component {
	constructor(props) {
		super(props);
		logger.log('[FeedbackThankYou] constructor - props:%o', props);
	}

	render() {
		logger.log('[Shop] render - this: %o', this);
		return (
			<Template>
				<section className="page-content">Thank you for your feedback.</section>
			</Template>
		);
	}
}
export default connect(mapStateToProps)(FeedbackThankYou);
