import PhotosPage from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/:lang(en_US)/photospage/:photo.html', '/:lang(en_US)/photospage/'],
		exact: true,
		component: PhotosPage,
		load: params => deps.actions.Photos.mount(params),
	},
];
