import deps from 'dependencies';

const queryString = require('querystring-browser');

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[ResultsPage] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store, getState).then(Config => {
			logger.log('[ResultsPage] action.mount - params: %o,getState:%o', params, getState().Router);
			let search = getState().Router.search.replace(/^\?/, '');
			let parsedQs = queryString.parse(search);

			let data = {
				path: Config.scoringData.completedMatches,
				tourn_events: Config.scoringConfig.eventNames,
				daysPath: Config.scoringData.completedMatchDays,
				tournDay: params.tournDay,
				event: parsedQs.event ? parsedQs.event : 'AE',
				basePicPath: Config.otherData.basePicPath,
			};
			logger.log('[ResultsPage] action.mount - data: %o', data);
			dispatch({ type: deps.actionTypes.RESULTSPAGE_LOAD, data: data });
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.RESULTSPAGE_FILTER, data: data });
	},

	setDay: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.RESULTSPAGE_SETDAY, data: data });
	},
};
