import React from 'react';
import Template from 'components/Template';
import StubBox from 'appdir/components/common-ui/StubBox';
import Helmet from 'react-helmet';
/**
 * -----------------------------------------------------------------------------
 * React Component: NotFound
 * -----------------------------------------------------------------------------
 */

const NotFound = props => {
	return (
		<Template hideAd={true}>
			<Helmet>
				<meta name="prerender-status-code" content="404" />
				<meta name="robots" content="noindex" />
			</Helmet>
			<section id="notfound-page" className="wrapper">
				<StubBox
					attributes={{
						title: 'Let!',
						message: '<p>This page does not exist.<br /><a href="/index.html">Try another serve?</a></p>',
						id: 'notfound',
					}}
				/>
			</section>
		</Template>
	);
};
export default NotFound;
