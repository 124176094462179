/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: RelatedLinks
 * -----------------------------------------------------------------------------
 */
const RelatedLinks = props => {
	logger.log('[RelatedLinks] - props:%o', props);

	let attributes = props.data;
	let imagesData = attributes?.images?.image;
	logger.log('[RelatedLinks] - imagesData:%o', imagesData);
	return window.webview ? (
		<div className="related-container">
			<div className="related-photo">
				{!isEmpty(imagesData) && imagesData?.url ? (
					<EventsLink to={`${attributes.url._}?promo=related`}>
						<img src={imagesData?.url} alt={attributes.title} />
					</EventsLink>
				) : null}
			</div>
			<div className="related-caption">
				<EventsLink to={`${attributes.url._}?promo=related`}>
					<p>
						<span id="_mce_caret" data-mce-bogus="true">
							<strong>READ MORE:&nbsp;</strong>
						</span>
						{attributes.title}
					</p>
				</EventsLink>
			</div>
		</div>
	) : (
		<div className="related-container">
			<div className="related-photo">
				{!isEmpty(imagesData) && imagesData?.url ? (
					<a href={`${attributes.url._}?promo=related`}>
						<img src={imagesData?.url} alt={attributes.title} />
					</a>
				) : null}
			</div>
			<div className="related-caption">
				<a href={`${attributes.url._}?promo=related`}>
					{' '}
					<p>
						<span id="_mce_caret" data-mce-bogus="true">
							<strong>READ MORE:&nbsp;</strong>
						</span>
						{attributes.title}
					</p>
				</a>
			</div>
		</div>
	);
};
export default RelatedLinks;
