/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Time from 'appdir/components/common-ui/Time';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: InTheMedia for Match Insights
 * -----------------------------------------------------------------------------
 */
const InTheMedia = props => {
	// logger.log('[InTheMedia] props:%o', props);

	return (
		<div className="media-item" key={`player${props.teamnum}-item-${props.count}`}>
			<div className="media-item-text">{`"${props.data.doc.insight.factoid.text}"`}</div>
			<div className="media-item-info">
				<span className="media-item-year">
					<Time epoch_ms={props.data.doc.article.publication_date_ms} format="YYYY" />
				</span>
				<span className="media-item-host">{` - ${props.data.doc.article.host}`}</span>
				<span className="media-item-link">
					<EventsLink to={props.data.doc.article.url} external={true}>
						<i className="icon-link-out"></i>
					</EventsLink>
				</span>
			</div>
		</div>
	);
};

export default InTheMedia;
