/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { values } from 'appdir/main';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * React Component: Config
 * -----------------------------------------------------------------------------
 */

export default class Config extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);

		logger.log('[Config] constructor - props:%o', this.props);
	}

	componentDidMount() {
		//logger.log('[Config] componentDidMount');
		//this.props.update();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[Config] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[Config] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate(nextProps) {
		//logger.log('[Config] componentDidUpdate - state:%o values:%o', this.state, values);

		if (this.state.auth) {
			values.config = {
				auth: this.state.auth,
			};
		}

		// logger.log(
		//   "[Config] componentDidUpdate - state:%o values:%o",
		//   this.state,
		//   values
		// );

		if (this.state.status === 'expired') {
			this.props.update();
		}

		if (op.get(this.state, 'staticData', false)) {
			//logger.log('[Config] componentDidUpdate - staticData:%o', this.state.staticData.length);
			for (let item in this.state.staticData) {
				let updated = op.get(this.state.staticData[item], 'updated', 0);
				let loadDiff = moment().diff(moment(updated), 'seconds', true);
				//expired if diff greater then refresh and
				//  has valid refresh time with data loaded
				let data = this.state[item] ? true : false;
				let expired = loadDiff >= this.state.staticData[item].refresh;
				if (expired && data && this.state.staticData[item].refresh < 0) {
					expired = false;
				}
				//logger.log('[Config] componentDidUpdate - staticData- item:%o data:%o expired:%o', item, this.state.staticData[item], expired);
				//logger.log('[Config] componentDidUpdate - staticData- item:%o data:%o expired:%o', item, this.state.staticData[item], expired);

				// if expired and not still loading, then load again
				//   (if there was an error loading, state remains loading and does not load again)
				if (expired && op.get(this.state.staticData[item], 'status', '') != 'loading') {
					this.props.updateStatic(item);
				}
			}
		}

		// if (this.state.staticLoaded) {
		// 	deps.services.Config.fetchStatic(result.staticData.menu).then((result) => {
		// 		logger.log('[Config] load static - result:%o', result);
		// 	});
		// }
	}

	render() {
		//logger.log('[Config] render');
		return null;
	}
}
