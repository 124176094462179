/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetch } from 'appdir/components/general/Util';
import NewsTile from 'appdir/components/content/NewsTile';
import TabPanel from 'appdir/components/general/Tabs/TabPanel';
import Tab from 'appdir/components/general/Tabs/Tab';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import AdTag from 'appdir/components/general/AdTag';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		tournamentYear: state['Config']?.scoring?.tournamentYear,
		adConfig: state['Config']?.adConfig,
		domain: state['Config']?.relatedContent?.domain,
		videoConfig: state['Config']?.videoPlayer,
		adMode: state['Config']?.landingPages?.adMode,
		newsImagePath: state['Config']?.cmsData?.newsImage,
		path: state['Config']?.cmsData.index,
		...props,
	};
};
/**
 * -----------------------------------------------------------------------------
 * Functional Component: RelatedContent
 * -----------------------------------------------------------------------------
 */
class RelatedContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.data,
			playerPage: props?.location == 'playerPage' ? true : false,
			showItems: 7,
			selectedTab: 'All',
			hasRelatedContentData: true,
		};
		this._isMounted = false;
    	this.showOrHideCallback = this.showOrHideCallback.bind(this);
	}

	componentDidMount() {
		// logger.log('[RelatedContent] componentDidMount - state:%o', this.state);
		this._isMounted = true;

		if (this.props.url) {
			let path = this.props.url;
			// logger.log('[RelatedContent] componentDidMount - url:%o', path);

			//path = 'http://ix-events-related-content.mybluemix.net/rest/v2/wim_2018/en/tag?tags=atpf324&count=4';

			fetch(path).then(data => {
				// const adObject = {
				// 	type: 'ad',
				// };
				if (data.content.length > 0) {
					// if (data.content.length < 3) {
					// 	data.content[data.content.length] = adObject;
					// } else {
					// 	data.content.splice(2, 0, adObject);
					// }
					// // The following are the options for ad placement:
					// //  yearround
					// //  tournament

					// if (this.props.adMode == 'tournament' && data.content.length > 7) {
					// 	for (var i = 7; i < data.content.length; i++) {
					// 		if ((i - 2) % 5 == 0) {
					// 			data.content.splice(i, 0, adObject);
					// 		}
					// 	}
					// }
          			this.showOrHideCallback(data);
				}

				if (this._isMounted) {
					this.setState({
						relatedContentData: data,
						showItems: this.props.adMode == 'tournament' ? 7 : 8,
						status: 'loaded',
						hasRelatedContentData: data.content.length > 0
					});
				}
			});
		}

		//already loaded related content from player profiles
		if (this.state.relatedContentData) {
			if (this._isMounted) {
				this.setState({
					status: 'loaded',
					style: 'four-col',
				});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}
  
  showOrHideCallback(data){
	logger.log('[RelatedContent] showOrHideCallback - data:%o', data);
		if (this.props.hasOwnProperty('showRelated') && data?.content?.length > 0) {	
			this.props.showRelated(true);
		}else if(data?.content?.length == 0){
			this.props.showRelated(false);
		}
	}

	handleShowMore = () => {
		this.setState({
			showItems:
				this.state.showItems >= this.state.relatedContentData.content
					? this.state.showItems
					: this.props.adMode == 'tournament' 
					? this.state.showItems + 5
					: this.state.showItems + 6
		});
	};

	setSelectedTab = tab => {
		MeasurementUtils.dispatchMeasurementCall('Player Profile Tab', {
			selectedTab: tab,
		});
		let showItemsReset = this.props.adMode == 'tournament' ? 7 : 8;
		if (this.props.url) {
			let path = tab === 'News' ? this.props.url + '&type=news'
				: tab === 'Videos' ?  this.props.url + '&type=video'
				: tab === 'Photos' ? this.props.url + '&type=photo'
				: this.props.url
			// logger.log('[RelatedContent] setSelectedTab - url:%o', path);

			fetch(path).then(data => {			
				this.setState({ selectedTab: tab, relatedContentData: data, showItems: showItemsReset, });
			});
		} else {
			this.setState({ selectedTab: tab, showItems: showItemsReset, });
		}
	};

	formatNewsTileData(relatedContentArry, col) {
		//let max = 4;
		//relatedContentArry = relatedContentArry.slice(relatedContentArry.length - max - 1, relatedContentArry.length - 1);

		return relatedContentArry.map((d, i) => {
			return {
				img: d.images[0].medium,
				type: d.type,
				subType: d.subType,
				typeText: d.type,
				// date: (this.state.includeDates == 'true') ? d.displayDate : '', //why is this line like this --removed amb 062618
				date: d.displayDate,
				title: d.shortTitle ? d.shortTitle : d.title,
				style: col ? col : 'two-col',
				description: d.description ? d.description : '',
				cmsId: d.cmsId,
				url: d.url,
			};
			// logger.log('[RelatedContent] - formatNewsTileData:%o', d);
		});
	}

	formatNewsTileDataPlayer(relatedContentArry, col) {
		//let max = 4;
		//relatedContentArry = relatedContentArry.slice(relatedContentArry.length - max - 1, relatedContentArry.length - 1);

		return relatedContentArry.map((d, i) => {
			return {
				img:
					d.images[0].medium.charAt(0) == '/'
						? 'http://www.usopen.org/' + d.images[0].medium
						: d.images[0].medium,
				type: d.type,
				subType: d.subType,
				typeText: d.type,
				date: d.displayDate,
				title: d.title,
				style: col ? col : 'two-col',
				description: d.description ? d.description : '',
				cmsId: d.cmsId,
				url: d.url,
			};
		});
	}

	renderAd(num) {
		return (
			<div key={`ad${num}`} id={`ad${num}`} className="chip ad">
				<AdTag
					key={`adtag${num}`}
					adConfig={this?.props?.adConfig?.[`landingpage`]}
					dfpNetworkId={this?.props?.adConfig.dfpNetworkId}
				/>
			</div>
		);
	}

	mapVideoData(data, autoPlay) {
		// logger.log('[MediaLanding] data:%o', data);
		if (data && !isEmpty(data)) {
			let vastCategory = 'default';
			// if (data?.subType == 'highlights') {
			// 	vastCategory = 'highlights';
			// } else if (data?.subType == 'features') {
			// 	vastCategory = 'features';
			// }
			return {
				id: data.cmsId,
				title: data.title,
				description:
					data.description && data.description.length > 0
						? data.description
						: 'View the latest US Open Highlights and Features.',
				poster: data.images[0].xlarge,
				shareUrl: data.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: data.media.m3u8,
						mimeType: data.media.m3u8.includes('m3u8') ? 'application/x-mpegURL' : 'video/mp4',
						streamType: 'vod',
					},
				],
				tracks:
					get(data, 'media.tracks', false) && get(data, 'media.tracks[0].file', false)
						? [
								{
									kind: 'captions',
									type: 'text/vtt',
									srclang: 'en',
									src: data.media.tracks[0].file,
								},
						  ]
						: [],
				startSeconds: 0,
				autoplay: true, //autoPlay ? autoPlay : false,
				autoplayPolicy: 'mutedinline',
				playsinline: true,
				displayDate: data.displayDate,
				ads: [this.props?.adConfig?.vast[vastCategory]],
				category: data.subType,
			};
		}
	}

	formatTileData(contentData) {
		let item = contentData;
		let mediaObject = {};
		// logger.log('[RelatedContent] renderAllContent - item: %o', item);

		const imagePrefix = this.props.domain;
		mediaObject['col-style'] = 'three-col';
		mediaObject.cmsId = item.cmsId ? item.cmsId : '';
		mediaObject.contentId = item.contentId ? item.contentId : '';
		mediaObject.date = item.displayDate ? item.displayDate : '';
		mediaObject.images = item.images && item.images.length > 0 ? item.images[0] : '';
		mediaObject.shortTitle = item.shortTitle ? item.shortTitle : '';
		mediaObject.title = item.title ? item.title : '';
		mediaObject.type = item.type ? item.type : '';
		mediaObject.prefix = imagePrefix ? imagePrefix : '';
		mediaObject.metadata = item.metadata ? item.metadata : '';
		mediaObject.subType = item.subType ? item.subType : '';
		mediaObject.description = item.description ? item.description : '';

		if (item?.metadata?.credit !== '') {
			let author = op.get(item, 'images.0.credit', false);
			mediaObject.author = author ? 'By ' + author : '';
		} else {
			mediaObject.author = '';
		}
		mediaObject.url = item.url;

		{
			// logger.log('[RelatedContent] formatTileData - mediaObject: %o', mediaObject);
		}

		return mediaObject;
	}

	renderNewsContent() {
		let relatedContent = this.state?.relatedContentData?.content;
		let relatedNewsContent = [];
		if (relatedContent.length > 0) {
			relatedNewsContent = relatedContent.filter(item => item.type == 'news');
		}
		logger.log('[RelatedContent] renderNewsContent - relatedContent: %o', relatedContent);
		
		return relatedNewsContent.length > 0 ? (
			<>
				<div className="newsChips related-cnt">
					<div className="chip_content">
						{relatedNewsContent.slice(0, this.state?.showItems).map((d, i) => {
							return (
								<React.Fragment key={i}>
									{i == 2 ||
									(this.props?.adMode == 'tournament' && (i == 6 || (i > 6 && (i - 2) % 4 == 0)))
										? this.renderAd(i)
										: null}
									<NewsTile
										id={`${d.type}Tile-${d.index}`}
										key={`${d.contentId}-${i}`}
										attributes={this.formatTileData(d)}
										isPanel={this.props?.isPanel ? this.props?.isPanel : false}
									/>
								</React.Fragment>
							);
						})}
					</div>
				</div>
				{relatedNewsContent?.length > this.state?.showItems ? (
					<div className="showMoreContainer">
						<div className="showMoreBtn" onClick={this.handleShowMore}>
							MORE
						</div>
					</div>
				) : null}
			</>
		) : (
			<div className="news-tile">
				<div className="no-news">
					<p>There are no news articles for this player at this time</p>
				</div>
			</div>
		);
	}

	renderVideoContent() {
		let relatedContent = this.state?.relatedContentData?.content;
		let relatedVideoContent = [];
		if (relatedContent.length > 0) {
			relatedVideoContent = relatedContent.filter(item => item.type == 'video');
		}

		return relatedVideoContent.length > 0 ? (
			<>
				<div className="newsChips related-cnt">
					<div className="chip_content">
						{relatedVideoContent.slice(0, this.state?.showItems).map((d, i) => {
							return (
								<React.Fragment key={i}>
									{i == 2 ||
									(this.props?.adMode == 'tournament' && (i == 6 || (i > 6 && (i - 2) % 4 == 0)))
										? this.renderAd(i)
										: null}
									<NewsTile
										data={this.mapVideoData(d)}
										id={`${d.type}Tile-${d.index}`}
										key={`${d.contentId}-${i}`}
										attributes={this.formatTileData(d)}
										isPanel={this.props?.isPanel ? this.props?.isPanel : false}
									/>
								</React.Fragment>
							);
						})}
					</div>
				</div>
				{relatedVideoContent?.length > this.state?.showItems ? (
					<div className="showMoreContainer">
						<div className="showMoreBtn" onClick={this.handleShowMore}>
							MORE
						</div>
					</div>
				) : null}
			</>
		) : (
			<div className="news-tile">
				<div className="no-news">
					<p>It looks like there are no videos right now. Stay tuned for updates.</p>
				</div>
			</div>
		);
	}

	renderPhotoContent() {
		let relatedContent = this.state?.relatedContentData?.content;
		let relatedPhotoContent = [];
		if (relatedContent.length > 0) {
			relatedPhotoContent = relatedContent.filter(item => item.type == 'photo');
		}

		return relatedPhotoContent.length > 0 ? (
			<>
				<div className="newsChips related-cnt">
					<div className="chip_content">
						{relatedPhotoContent.slice(0, this.state?.showItems).map((d, i) => {
							return (
								<React.Fragment key={i}>
									{i == 2 ||
									(this.props?.adMode == 'tournament' && (i == 6 || (i > 6 && (i - 2) % 4 == 0)))
										? this.renderAd(i)
										: null}
									<NewsTile
										id={`${d.type}Tile-${d.index}`}
										key={`${d.contentId}-${i}`}
										attributes={this.formatTileData(d)}
										playerPage={this.state.playerPage}
										isPanel={this.props?.isPanel ? this.props?.isPanel : false}
									/>
								</React.Fragment>
							);
						})}
					</div>
				</div>
				{relatedPhotoContent.length > this.state.showItems ? (
					<div className="showMoreContainer">
						<div className="showMoreBtn" onClick={this.handleShowMore}>
							MORE
						</div>
					</div>
				) : null}
			</>
		) : (
			<div className="news-tile">
				<div className="no-news">
					<p>It looks like there are no photos right now. Stay tuned for updates.</p>
				</div>
			</div>
		);
	}

	renderSingleContent() {
		let relatedContent = this.state?.relatedContentData?.content;
		return relatedContent?.length > 0 ? (
			<div className="newsChips related-cnt">
				<div className="chip_content">
					{relatedContent.slice(0, 1).map((d, i) => {
						return (
							<NewsTile
								hideFooter={this.state.showLatestContent == true && this.state.showAll == false}
								data={d.type == 'video' ? this.mapVideoData(d) : ''}
								id={`${d.type}Tile-${d.index}`}
								key={`${d.contentId}-${i}`}
								attributes={this.formatTileData(d)}
								isPanel={this.props?.isPanel ? this.props?.isPanel : false}
							/>
						);
					})}
				</div>
			</div>
		) : null;
	}

	renderAllContent() {
		let relatedContent = this.state?.relatedContentData?.content;
		return relatedContent?.length > 0 ? (
			<>
				<div className="newsChips related-cnt">
					<div className="chip_content">
						{relatedContent.slice(0, this.state?.showItems).map((d, i) => {
							return (
								<React.Fragment key={i}>
									{i == 2 ||
									(this.props?.adMode == 'tournament' && (i == 6 || (i > 6 && (i - 2) % 4 == 0)))
										? this.renderAd(i)
										: null}
									<NewsTile
										data={d.type == 'video' ? this.mapVideoData(d) : ''}
										id={`${d.type}Tile-${d.index}`}
										key={`${d.contentId}-${i}`}
										attributes={this.formatTileData(d)}
										isPanel={this.props?.isPanel ? this.props?.isPanel : false}
										playerPage={d.type === 'photo' ? true : false}
									/>
								</React.Fragment>
							);
						})}
					</div>
				</div>
				{relatedContent?.length > this.state?.showItems ? (
					<div className="showMoreContainer">
						<div className="showMoreBtn" onClick={this.handleShowMore}>
							MORE
						</div>
					</div>
				) : null}
			</>
		) : null;
	}

	render() {
		// logger.log('[RelatedContent] render - this:%o', this);

		return this.state.status == 'loaded' && this.state.hasRelatedContentData ? (
			<>
				{this.state.showLatestContent == true && this.state.showAll == false ? (
					<>{this.renderSingleContent()}</>
				) : op.get(this.props, 'location', false) == 'slamtracker' ? (
					<>
						<div className="news-tile">{this.renderAllContent()}</div>
					</>
				) : (
					<>
						<div className="contentNavContainer">
							<div className="contentTitle">
								<p>RELATED NEWS </p>
							</div>
							<div className="relatedContent-nav">
								<div className="tabs">
									<TabPanel
										tabs={['All', 'News', 'Videos', 'Photos']}
										selectedTab={this.state.selectedTab}
										setSelectedTab={this.setSelectedTab}></TabPanel>
								</div>
							</div>
						</div>
						<Tab isSelected={this.state.selectedTab === 'All'}>
							<div className="news-tile">{this.renderAllContent()}</div>
						</Tab>
						<Tab isSelected={this.state.selectedTab === 'News'}>
							<div className="news-tile">{this.renderNewsContent()}</div>
						</Tab>
						<Tab isSelected={this.state.selectedTab === 'Videos'}>
							<div className="news-tile">{this.renderVideoContent()}</div>
						</Tab>
						<Tab isSelected={this.state.selectedTab === 'Photos'}>
							<div className="news-tile">{this.renderPhotoContent()}</div>
						</Tab>
					</>
				)}
			</>
		) : null;
	}
}

export default connect(mapStateToProps)(RelatedContent);
