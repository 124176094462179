/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import DayNav from 'appdir/components/common-ui/DayNav';
import MeasurementUtils from 'appdir/lib/analytics';
import TournamentDaySelector from 'appdir/components/common-ui/TournamentDaySelector';

/**
 * -----------------------------------------------------------------------------
 * React Component: SchedulePage
 * this is the completed matches page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return Object.assign({}, state['SchedulePage'], props);
	//return Object.assign({}, state['SchedulePage'], props);
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.SchedulePage.filter(settings)),
});

class DaySelect extends Component {
	constructor(props) {
		super(props);

		let dayNavOptions = props.days
			.filter(day => day.tournDay)
			.map(item => ({
				value: item.tournDay.toString(),
				type: item.quals ? 'quali' : 'tourn',
				textName: item.displayDay,
				dropdownName: item.messageShort,
				title: item.messageShort,
				sort: item.tournDay,
				disabled: !item.released ? true : false,
			}));

		this.state = {
			...props,
			dayNavOptions: dayNavOptions,
			mode: props.mode,
			filters: props.filters,
		};

		this.setDay = this.setDay.bind(this);
		this.onOpen = this.onOpen.bind(this);

		logger.log('[DaySelect] constructor props:%o state:%o', props, this.state);
	}

	componentWillReceiveProps(nextProps) {
		//logger.log('[DaySelect] componentWillReceiveProps - next:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});

		//logger.log('[DaySelect] componentWillReceiveProps - state:%o', this.state);
	}

	setDay(day) {
		logger.log('[DaySelect] setDay:%o', day);
		MeasurementUtils.dispatchMeasurementCall('pageView', { day, pageTitle: 'Schedule of Play' });
		this.props.filter({
			day: day,
			open: '',
			showAllDays: this.state?.showAllDays,
		});
	}
	onOpen(which) {
		logger.log('[DaySelect] onOpen:%o', which);
		this.props.filter({
			open: which,
		});
	}

	render() {
		logger.log('[DaySelect] render - state:%o', this.state);

		let daysData = {
			options: [
				{
					dropdownName: 'Select Day',
					textName: '',
					value: '',
					type: '',
					title: '',
					disabled: true,
				},
			],
		};

		// add available days to base archive menu options
		this.state.dayNavOptions.forEach(item => {
			daysData.options.push({
				textName: item.textName,
				dropdownName: item.dropdownName,
				value: item.value,
				type: item.type,
				title: item.title,
				sort: item.sort,
				disabled: item.disabled,
			});
		});

		return (
			<div className="daynav scores-select-menu">
				<TournamentDaySelector
					handleOptionSelected={this.setDay}
					selectedDay={this.props.filters.day}
					enableFuture={this.props?.enableFuture ? this.props?.enableFuture : false} //show all future days - #1551
				/>
				{/* <DayNav
					name="days"
					attributes={daysData}
					selected={this.state.filters.day == 'Current' ? this.props.default : this.state.filters.day}
					show={this.state.filters.open == 'days'}
					onSelected={this.setDay}
					onOpen={this.onOpen}
					mode={this.state.mode}
				/> */}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DaySelect);
