import { getQuerystringValues } from 'appdir/components/general/Util';

/** Find out which player is the prematch winner from powerRankingsMatchup */
export const getLikelihoodWinner = powerRankingData => {
	let tmp = [];
	let preMatchWinner;
	let counter = 0;
	for (let key in powerRankingData) {
		if (powerRankingData[key]) {
			tmp.push({
				player_id: key,
				win_prob_prematch: powerRankingData[key]['win_prob_prematch'],
			});
			counter++;
		}
	}

	if (tmp[0]?.win_prob_prematch && tmp[1]?.win_prob_prematch && tmp[0]?.player_id && tmp[1]?.player_id) {
		preMatchWinner =
			tmp[0]['win_prob_prematch'] > tmp[1]['win_prob_prematch'] ? tmp[0]['player_id'] : tmp[1]['player_id'];
	}

	// logger.log('[[getLikelihoodWinner] tmp:%o, preMatchWinner:%o', tmp, preMatchWinner);

	return preMatchWinner;
};

/** get likelyhood win probability percentage and which player is the prematch winner */
export const getLikelyhoodWinData = (powerRankingData, pid) => {
	let prematchWinner = getLikelihoodWinner(powerRankingData);
	let winProbNum = null;
	if (powerRankingData.hasOwnProperty(pid)) {
		winProbNum = Math.round(powerRankingData[pid]['win_prob_prematch'] * 100);
	}
	let likelihoodFlag = prematchWinner == pid ? true : false;

	return {
		likelihoodWin: likelihoodFlag,
		likelihoodWinNum: winProbNum,
		player_id: pid,
	};
};

/** get likelyhoodWinData for both teams at the same time */
export const getTeamsLikelihoodWinData = (powerRankingData, teams) => {
	let teamsLikelihoodWin = [];
	if (teams.length > 0) {
		teams.map(team => {
			teamsLikelihoodWin.push(getLikelyhoodWinData(powerRankingData, team['idA']));
		});
	}

	//  logger.log('[getTeamsLikelihoodWinData] teamsLikelihoodWin:%o', teamsLikelihoodWin);

	return teamsLikelihoodWin;
};

export const getProjectedWinnerText = (powerRankingData, teams) => {
	logger.log('[getProjectedWinnerText] teams:%o', teams);
	let likelyWinner = getLikelihoodWinner(powerRankingData);
	let likelyWinnerSentence =
		"Based on the Watson analysis, <span class='name'><playerName></span> is projected to win.";

	if (likelyWinner == teams[0].idA) {
		likelyWinnerSentence = likelyWinnerSentence.replace('<playerName>', teams[0].displayNameA);
	} else {
		likelyWinnerSentence = likelyWinnerSentence.replace('<playerName>', teams[1].displayNameA);
	}

	return likelyWinnerSentence;
};

/** get Upset Alerts
 * - Matches identified where the IBM Power Index favors the underdog,
 *  as defined by the tour ranks, to win
 */
export const checkUpsetAlert = (powerRankingData, p1id, p2id) => {
	let isUpset =
		parseInt(powerRankingData[p1id]['upset_alert']) != 0 || parseInt(powerRankingData[p2id]['upset_alert']) != 0;

	//logger.log('[checkUpsetAlert] isUpset:%o', isUpset);
	return isUpset;
};

export const getQSParams = (search, which) => {
	search = search.replace(/^\?/, '');
	let parsedQs = getQuerystringValues(search);
	let value = '';

	switch (which) {
		case 'filterPlayers':
			if (parsedQs.filterPlayers) {
				value = parsedQs.filterPlayers;
			}
			break;
		case 'showFavs':
			if (parsedQs.showFavs) {
				value = parsedQs.showFavs;
			}
			break;
		case 'playerId':
			if (parsedQs.playerId) {
				value = parsedQs.playerId;
			}
			break;
		case 'searchedPlayer':
			if (parsedQs.searchedPlayer) {
				value = parsedQs.searchedPlayer;
			}
			break;
		default:
			break;
	}

	return value;
};
