/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
// import VideoPlayer from "appdir/components/general/VideoPlayer";

//require('script!datadir/en_GB/web/ecp/js/eventsCore-all.js');

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoWrapper
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['VideoPlayer'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.VideoPlayer.mount()),
});

class VideoWrapper extends Component {
	constructor(props) {
		super(props);

		this.name = 'VideoWrapper';
		this.playInline = true;
	}

	componentDidMount() {
		this.props.mount();
	}

	/**
	 * handle clicks from news tile
	 */
	onClick() {
		logger.log('[VideoWrapper] onClick - state:%o', this.state);

		if (this.playInline) {
			//this.props.play(this.props.attributes.contentId);
			this.setState({
				videoId: this.props.attributes.contentId,
			});
		} else {
			this.setState({
				nav: this.props.attributes.url,
			});
		}
	}

	onVideoEvent = event => {
		logger.info('[VideoWrapper] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				videoId: '',
			});
		}
	};

	render() {
		logger.log(`${this.name} render - title:% state:%o`, this.props.attributes.title, this.state);

		let thumbImg = this.props.attributes.thumb;

		if (this.state.nav) {
			return <EventsRedirect to={this.state.nav} />;
		} else if (this.props.type == 'header' && this.state.videoId) {
			return (
				<div className="video-wrapper-header">
					<div className="video-wrapper">
						<img className="video-thumb" src={thumbImg} />
						{/* <VideoPlayer
              attributes={this.props.attributes}
              onEvent={this.onVideoEvent}
            /> */}
					</div>
				</div>
			);
		} else if (this.state.videoId) {
			return (
				<div className="video-wrapper">
					<img className="video-thumb" src={thumbImg} />
					{/* <VideoPlayer
            attributes={this.props.attributes}
            onEvent={this.onVideoEvent}
          /> */}
				</div>
			);
		} else if (this.props.type == 'header') {
			return (
				<span
					className="news--header-videoLinkButton"
					onClick={() => {
						this.onClick();
					}}>
					Play Full Video
					<i className="icon-video" />
				</span>
			);
		} else {
			return (
				<div
					className="video-wrapper"
					onClick={() => {
						this.onClick();
					}}>
					<img className="video-thumb" src={thumbImg} />
					<i className="icon-video" />
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoWrapper);
