/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import ReactHtmlParser from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: StubMessage
 * -----------------------------------------------------------------------------
 */
const StubBox = props => {
	let attributes = props.attributes;
	logger.log('[StubBox] render attributes:%o', props.attributes);

	const elem = document.getElementById('root');

	if (window.webview) {
		elem.setAttribute('class', 'stub');
	}
	return attributes.message ? (
		<div id={`${attributes.id ? attributes.id : 'stub-page'}`}>
			<div className="column-layout">
				<section className="uso-content">
					{attributes.subTitle || attributes.subTitle == '' ? <h2>{attributes.subTitle}</h2> : null}
					<h1>{attributes.title}</h1>
					{Array.isArray(attributes.message) ? (
						<p>{ReactHtmlParser(attributes.message[0])}</p>
					) : (
						<p>{ReactHtmlParser(attributes.message)}</p>
					)}
				</section>
				{Array.isArray(attributes.message) && attributes.message.length > 1 ? (
					<section className="uso-content">
						{attributes.message.slice(1).map((text, i) => {
							return (
								<p key={i} className="text">
									{ReactHtmlParser(text)}
								</p>
							);
						})}
					</section>
				) : null}
			</div>
		</div>
	) : null;
};

export default StubBox;
