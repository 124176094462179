import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import MeasurementUtils from 'appdir/lib/analytics';

class Carousel extends Component {
	constructor(props) {
		super(props);
		this._imageGallery = null;
		this.state = { ...this.props.attributes, currIndex: 0 };
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[Carousel] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let new_nextProps = nextProps.attributes ? nextProps.attributes : nextProps;

		let newState = {
			...prevState,
			...new_nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[Carousel] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	slideTo(index) {
		MeasurementUtils.dispatchMeasurementCall('galleryNavigate', {
			newIndex: index,
		});
		this._imageGallery.slideToIndex(index);
	}

	openGallery() {
		logger.log('[Carousel] - openGallery to full size');
	}

	onSlide() {
		if (this._imageGallery) {
			let currIndex = this._imageGallery.getCurrentIndex();
			MeasurementUtils.dispatchMeasurementCall('galleryNavigate', {
				currSlide: currIndex,
			});
			this.setState(prevState => {
				return { ...prevState, currIndex: currIndex };
			});
			if (this.state.onSlide) {
				this.state.onSlide(currIndex);
			}
		}
	}

	toggleSlideNav(slideDirection) {
		let currIndex = this._imageGallery.getCurrentIndex();
		let newIndex = currIndex;
		if (slideDirection == 'next' || slideDirection == 'right') {
			newIndex = currIndex + 1 <= this.state.items.length - 1 && this.state.items.length > 0 ? currIndex + 1 : 0;
		} else {
			newIndex = currIndex - 1 > -1 && this.state.items.length > 0 ? currIndex - 1 : this.state.items.length - 1;
		}
		this.slideTo(newIndex);
	}

	pause() {
		this._imageGallery.pause();
	}

	play() {
		this._imageGallery.play();
	}

	render() {
		logger.log('[Carousel] state - %o', this.state);
		let imagesBullets = this.state.items.map((d, i) => i);
		return (
			<div className={`carousel-wrapper`}>
				<div className="nav-wrapper">
					{this.state.customNav ? (
						this.state.customNavHTML ? (
							this.state.customNavHTML.leftNav('left', this.toggleSlideNav.bind(this))
						) : (
							<div
								className="custom-slide prev-slide"
								onClick={() => {
									this.toggleSlideNav('prev');
								}}
							/>
						)
					) : null}

					{this.state.customBullets
						? imagesBullets.map((d, i) => {
								return (
									<div
										key={i}
										className={`nav-dot ${i == this.state.currIndex ? 'selected' : ''}`}
										onClick={() => {
											this.slideTo(i);
										}}>
										●
									</div>
								);
						  })
						: null}

					{this.state.customNav ? (
						this.state.customNavHTML ? (
							this.state.customNavHTML.rightNav('right', this.toggleSlideNav.bind(this))
						) : (
							<div
								className="custom-slide next-slide"
								onClick={() => {
									this.toggleSlideNav('next');
								}}
							/>
						)
					) : null}
				</div>
				<ImageGallery
					ref={i => (this._imageGallery = i)}
					items={this.state.items}
					infinite={this.state.infinite || false}
					showThumbnails={this.state.showThumbnails}
					showFullscreenButton={this.state.showFullscreenButton}
					showPlayButton={this.state.showPlayButton}
					showBullets={this.state.showBullets}
					onImageLoad={this.state.onImageLoad ? this.state.onImageLoad.bind(this) : null}
					showNav={this.state.showNav}
					autoPlay={this.state.autoPlay || false}
					onSlide={this.onSlide.bind(this)}
					slideInterval={this.state.slideInterval || 5000}
					onClick={() => {
						this.openGallery();
					}}
				/>
			</div>
		);
	}
}

export default Carousel;
