/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { measureInApp } from 'appdir/components/general/Analytics';
import { doMeasurement } from 'appdir/components/general/Util';

/* page elements */
import HelpModal from 'appdir/components/common-ui/HelpModal';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: watsonsx info Overlay for Webview
 * -----------------------------------------------------------------------------
 */

const WatsonxInfoOverlayWebview = props => {
	/** static content from Config */
	const configText = useSelector(state => state['Config']?.otherData?.innovations?.branding);

	let modalBodyText = configText?.helpText;
    let marketingLink = configText?.marketingLink ?? {};

	/** put a class for Android to place a transparent blue background
	 *  iOS can place its own blue background natively
	 */
	let overlayClass = window.webviewPlatform !== 'ios' ? 'android' : '';

	const close = () => {
		if(window.webviewPlatform == "ios" && window.webkit) {
			measureInApp(['Metrics', 'IBMwatsonx', 'modal', 'close']);
		}

		/** send close message to apps to close the overlay webview */
		if (window.webviewPlatform == 'ios' && window.webkit) {
			window.webkit.messageHandlers.close_info_modal.postMessage('close');

		} else if (window.JSInterface) {
			const appData = {
				type: "close_info_modal",
				data: {
				action: 'close'
				}
			};

			let stringData = JSON.stringify(appData);

			window.JSInterface.webviewMessage(stringData);
		}

		if(window.webviewPlatform !== "ios") {
			/** android needs to be sent after the close_info_modal to make both messages work */
			measureInApp(['Metrics', 'IBMwatsonx', 'modal', 'close']);
		}
    }
	logger.log('[watsonxInfoOverlayWebview] render - configText: %o', configText);
	if(modalBodyText) {
		return (
			<section className={`wrapper info-overlay  ${overlayClass}`}>
			<HelpModal
				styleClasses={`webview ${overlayClass}`}
				body={modalBodyText}
				marketingLink={marketingLink}
				closeModal={() => close()} 
			/>
			</section>)
	} else {
		return (
			<section className={`wrapper info-overlay  ${overlayClass}`}>
				<LoadingIndicator />
			</section>
		);
	}
}
		

export default WatsonxInfoOverlayWebview;
