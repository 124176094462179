/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import ModalOverlay from 'appdir/components/general/ModalOverlay';
import MatchInsightsContent from './MatchInsightsContent';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsightsModal
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsightsModal'],
		...state['ModalOverlay'],
		Router: state['Router'],
		...props,
	};
};

class MatchInsightsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		// logger.log('[MatchInsightsModal] constructor - props:%o', this.props);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[MatchInsightsModal] getDerivedStateFromProps - nextProps:%o', nextProps);

		let newState = {
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	onClose() {
		if (this.state.Router.pathname.indexOf('matchinsights') > -1) {
			location.href = '/en_US/index.html';
		}
		this.props.hideOverlayModal();
	}

	render() {
		logger.log('[MatchInsightsModal] render - state:%o', this.state);

		document.body.classList.add('modal-open');

		if (op.get(this.state, 'matchId', false)) {
			return (
				<ModalOverlay onClose={this.onClose} modalClass="overlay-match-insights">
					<MatchInsightsContent matchId={this.state.matchId} />
				</ModalOverlay>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps)(MatchInsightsModal);
