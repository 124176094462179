import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.OPEN_QUESTIONS_MOUNT:
			newState = {
				...state,
				status: 'loaded',
				...action.data,
			};
			logger.log('[OpenQuestions] reducer.OPEN_QUESTIONS_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.OPEN_QUESTIONS_UNMOUNT:
			newState = {
				...state,
				status: 'unloaded',
				...action.data,
			};
			logger.log('[OpenQuestions] reducer.OPEN_QUESTIONS_UNMOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.OPEN_QUESTIONS_ERROR:
			logger.log('[OpenQuestions] reducer.OPEN_QUESTIONS_ERROR - action:%o', action);
			newState = Object.assign({}, state, {
				status: 'error',
				error: action.error,
			});
			return newState;

		case deps.actionTypes.OPEN_QUESTIONS_LOAD:
			logger.log('[OpenQuestions] reducer.OPEN_QUESTIONS_LOAD');

			newState = {
				...state,
				status: 'loaded',
				...action.data,
			};
			//logger.log('[OpenQuestions] reducer.OPEN_QUESTIONS_LOAD - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
