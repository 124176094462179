import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SLAMTRACKERLANDING_MOUT:
			logger.log('[SlamTrackerLanding] reducers - action:%o', action);

			newState = {
				...state,
				...action.data,
			};

			logger.log('[SlamTrackerLanding] reducers SLAMTRACKERLANDING_MOUT - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
