import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Home Content components
 * -----------------------------------------------------------------------------
 */

class HomeContent extends Component {
	constructor(props) {
		super(props);
		// this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		if (this.props.hasOwnProperty('onMount')) {
			this.props.onMount(this);
		}
	}

	render() {
		logger.log('[HomeContent] - this:%o', this);
		let { data } = this.props;
		let idKey = this.props.idKey;

		if (data) {
			switch (data.type) {
				// case 'chip':
				// 	return <BasicChip data={data.content} />;
				// 	break;

				// case 'HomeHeroLatest':
				// 	return <HomeHeroStories key={idKey} data={data} />;
				// 	break;

				// case '1Column':
				// 	//multipe modes - number of arrangments
				// 	return (
				// 		<ColBlocks
				// 			attributes={{
				// 				data: data,
				// 				key: idKey,
				// 				type: '1Col',
				// 				multiMode: 4,
				// 			}}
				// 		/>
				// 	);
				// 	break;
				// case '3Column':
				// 	return (
				// 		<ColBlocks
				// 			attributes={{
				// 				data: data,
				// 				key: idKey,
				// 				type: '3Col',
				// 			}}
				// 		/>
				// 	);
				// 	break;
				// case '4Column':
				// 	return (
				// 		<ColBlocks
				// 			attributes={{
				// 				data: data,
				// 				key: idKey,
				// 				type: '4Col',
				// 			}}
				// 		/>
				// 	);
				// 	break;
				// case '3ColumnCategories':
				// 	return <CategoryCols key={idKey} data={data} />;
				// 	break;

				case 'NativeContent':
					return (
						<CheckerBoard
							attributes={{
								data: data,
								key: idKey,
								multiMode: 1,
							}}
						/>
					);
					break;

				default:
					logger.log('[HomeContent] - Data item not a Home Content Component');
					return null;
			}
		} else {
			return null;
		}
	}
}

export default HomeContent;
