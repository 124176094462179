import uniqBy from 'lodash/uniqBy';

// Event config for displaying the Draws Navigator
export const eventDef = {
	MS: {
		//roundNum: ['1', '2', '3', '4', 'Q', 'S', 'F'],
		roundNum: { '1': 1, '2': 1, '3': 1, '4': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['a', 'b', 'c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5', '6', '7'],
		//bracketDisp: ['1top', '1bottom', '2top', '2bottom', '3', '4', '5', '6', '7'],
	},
	WS: {
		roundNum: { '1': 1, '2': 1, '3': 1, '4': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['a', 'b', 'c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5', '6', '7'],
	},
	MD: {
		roundNum: { '1': 1, '2': 1, '3': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['b', 'c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5', '6'],
	},
	WD: {
		roundNum: { '1': 1, '2': 1, '3': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['b', 'c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5', '6'],
	},
	XD: {
		roundNum: { '1': 1, '2': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5'],
	},
	BS: {
		roundNum: { '1': 1, '2': 1, '3': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['b', 'c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5', '6'],
	},
	GS: {
		roundNum: { '1': 1, '2': 1, '3': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['b', 'c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5', '6'],
	},
	BD: {
		roundNum: { '1': 1, '2': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5'],
	},
	GD: {
		roundNum: { '1': 1, '2': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['c', 'd', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4', '5'],
	},
	MQ: {
		roundNum: { '1': 1, '2': 1, '3': 1 },
		roundDisp: ['a', 'b', 'c'],
		bracketDisp: ['1', '2', '3'],
	},
	WQ: {
		roundNum: { '1': 1, '2': 1, '3': 1 },
		roundDisp: ['a', 'b', 'c'],
		bracketDisp: ['1', '2', '3'],
	},
	BQ: {
		roundNum: { '1': 1, '2': 1 },
		roundDisp: ['d', 'e'],
		bracketDisp: ['1', '2'],
	},
	GQ: {
		roundNum: { '1': 1, '2': 1 },
		roundDisp: ['d', 'e'],
		bracketDisp: ['1', '2'],
	},
	CS: {
		roundNum: { '1': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['d', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4'],
	},
	DS: {
		roundNum: { '1': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['d', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4'],
	},
	CD: {
		roundNum: { Q: 1, S: 1, F: 1 },
		roundDisp: ['e', 'f', 'g'],
		bracketDisp: ['1', '2', '3'],
	},
	DD: {
		roundNum: { Q: 1, S: 1, F: 1 },
		roundDisp: ['e', 'f', 'g'],
		bracketDisp: ['1', '2', '3'],
	},
	US: {
		roundNum: { '1': 1, Q: 1, S: 1, F: 1 },
		roundDisp: ['d', 'e', 'f', 'g'],
		bracketDisp: ['1', '2', '3', '4'],
	},
	UD: {
		roundNum: { Q: 1, S: 1, F: 1 },
		roundDisp: ['e', 'f', 'g'],
		bracketDisp: ['1', '2', '3'],
	},
	JS: {
		roundNum: { Q: 1, S: 1, F: 1 },
		roundDisp: ['e', 'f', 'g'],
		bracketDisp: ['1', '2', '3'],
	},
	KS: {
		roundNum: { Q: 1, S: 1, F: 1 },
		roundDisp: ['e', 'f', 'g'],
		bracketDisp: ['1', '2', '3'],
	},
	JD: {
		roundNum: { S: 1, F: 1 },
		roundDisp: ['f', 'g'],
		bracketDisp: ['1', '2'],
	},
	KD: {
		roundNum: { S: 1, F: 1 },
		roundDisp: ['f', 'g'],
		bracketDisp: ['1', '2'],
	},
	CM: {
		roundNum: { Q: 1, S: 1, F: 1 },
		roundDisp: ['e', 'f', 'g'],
		bracketDisp: ['1', '2', '3'],
	},
	CW: {
		roundNum: { Q: 1, S: 1, F: 1 },
		roundDisp: ['e', 'f', 'g'],
		bracketDisp: ['1', '2', '3'],
	},
};

export const getEventName = (event, tourn_events) => {
	if (tourn_events) {
		let nameArray = tourn_events;
		let title = '';

		for (var i = 0; i < nameArray.length; i++) {
			if (nameArray[i].code == event) {
				title = nameArray[i].long;
			}
		}
		return title;
	} else {
		return false;
	}
};

export const getPlayersData = (data, event, rounds) => {
	//logger.log('[DrawsUtils] getPlayersData - data: %o event:%o rounds:%o', data, event, rounds);

	let playersArray = [];
	let playersSet = {};
	let playersPlaying = [];

	try {
		let correctedEvent = getCorrectEventId(event, rounds);
		let eventData = eventDef[correctedEvent];
		let roundsCount = formulateMatchStats(data.matches, eventData);
		//logger.log('[DrawsUtils] getPlayersData - roundsCount: %o', roundsCount);

		data.matches.map((match, i) => {
			let roundName = match.roundCode;
			// logger.log(
			// 	'[DrawsUtils] getPlayersData - match:%o, roundsCount:%o, roundName: %o',
			// 	match,
			// 	roundsCount,
			// 	roundName
			// );

			let player1A = {
				firstName: match.team1.firstNameA,
				lastName: match.team1.lastNameA,
				id: match.team1.idA,
			};
			let player1B = {
				firstName: match.team1.firstNameB,
				lastName: match.team1.lastNameB,
				id: match.team1.idB,
			};
			let player2A = {
				firstName: match.team2.firstNameA,
				lastName: match.team2.lastNameA,
				id: match.team2.idA,
			};
			let player2B = {
				firstName: match.team2.firstNameB,
				lastName: match.team2.lastNameB,
				id: match.team2.idB,
			};

			let roundCount = roundsCount[roundName].count;
			let roundOffset = roundsCount[roundName].offset;

			// logger.log(
			// 	'[DrawsUtils] getPlayersData - roundCount: %o roundOffset:%o i:%o: (i - roundOffset):%o',
			// 	roundCount,
			// 	roundOffset,
			// 	i,
			// 	i - roundOffset
			// );

			let drawSection = determineDrawSection(roundName, i - roundOffset, roundCount, correctedEvent);
			let rndNum = parseInt(match.match_id.slice(-3, -2));

			playersSet[player1A.id] = { index: drawSection, rndNum };
			playersSet[player1B.id] = { index: drawSection, rndNum };
			playersSet[player2A.id] = { index: drawSection, rndNum };
			playersSet[player2B.id] = { index: drawSection, rndNum };

			playersArray.push(player1A, player1B, player2A, player2B);
			playersPlaying = playersPlaying.concat(playersArray.filter(d => d.firstName !== null));
		});
		playersPlaying = uniqBy([...playersPlaying], 'id');
	} catch (e) {
		logger.error('[DrawsUtils] usually happens when event draw data is bad. getPlayersData - e: %o', e);
	}
	return { playersPlaying, playersSet };
};

export const getSponsorConfig = (event, data) => {
	//logger.log('[DrawsUtils] Util getSponsorConfig - event:%o, data:%o', event, data);

	if (event && data) {
		let sponsor = data?.default;

		Object.keys(data).map((key, index) => {
			if (key !== 'default' && data[key]?.eventList?.indexOf(event) !== -1) {
				sponsor = data[key];
			}
		});
		return sponsor;
	}
	return {};
};

export const getEventCode = (route = '', eventNames = []) => {
	//logger.log('[DrawsUtils] Util getEventCode - route:%o, eventNames:%o', route, eventNames);

	let eventCode = 'MS';

	eventNames.map(item => {
		if (item.route == route) {
			eventCode = item.code;
		}
	});

	//logger.log('[DrawsUtils] Util getEventCode - eventCode:%o', eventCode);

	return eventCode;
};

export const getRoute = (eventCode = '', eventNames = []) => {
	// logger.log('[DrawsUtils] Util getEventCode - route:%o, eventNames:%o', eventCode, eventNames);

	let eventRoute = 'mens-singles';

	eventNames.map(item => {
		logger.log('EventNames %o, item: %o', eventNames, item);
		if (item.code == eventCode && item.route) {
			eventRoute = item.route;
		}
	});

	logger.log('[DrawsUtils] Util getEventCode - eventRoute:%o', eventRoute);

	return `/en_US/draws/${eventRoute}.html`;
};

const formulateMatchStats = (matches, eventKey) => {
	// let eventLength = Object.keys(eventKey.roundNum).length;
	let roundCount = {};
	let eventKeys = Object.keys(eventKey.roundNum);
	//create object map with round and its count of matches
	for (let i = 0; i < eventKeys.length; i++) {
		let currentRound = eventKeys[i];
		let matchCount = matches.filter(d => d.roundCode === currentRound).length;
		let offset = matches.findIndex(el => el.roundCode === currentRound);
		roundCount[currentRound] = { count: matchCount, offset };
	}

	return roundCount;
};

const determineDrawSection = (roundName, i, roundCount, filterEvent) => {
	// let index = i + 1;
	let index = i;
	let event = eventDef[filterEvent];
	//logger.log('[DrawsUtils] determineDrawSection event:%o, roundName:%o', event, roundName);

	let sections = event['roundNum'][roundName];
	//logger.log('[DrawsUtils] determineDrawSection sections:%o', sections);

	let section = null;
	section = getIndex(event, sections, index, roundCount, sections, roundName, filterEvent);

	return section;
};

const getIndex = (event, num, index, matchLength, totalSections, roundName, filterEvent) => {
	let divisible = (num - 1) / totalSections;
	let percentage = index / matchLength;

	if (percentage >= divisible || num - 1 == 0) {
		let roundNum = eventDef[filterEvent]['roundNum'];

		// logger.log(
		// 	'[DrawsUtils] getIndex roundName:%o, num:%o, roundName:%o, roundNum:%o',
		// 	roundName,
		// 	num,
		// 	roundName,
		// 	roundNum
		// );

		let bracketIndex = getBracketNameIndex(roundName, num, roundName, roundNum);
		// logger.log('[DrawsUtils] getIndex bracketIndex:%o', bracketIndex);

		let section = event['bracketDisp'][bracketIndex - 1];
		return section;
	}

	return getIndex(event, num - 1, index, matchLength, totalSections, roundName, filterEvent);
};

const getBracketNameIndex = (originalRoundName, calcSection, roundName, roundNum) => {
	//determine the round number
	let roundKeys = Object.keys(roundNum);
	//logger.log('[DrawsUtils] getBracketNameIndex roundKeys:%o, calcSection:%o', roundKeys, calcSection);

	let originalRoundIndex = roundKeys.findIndex(ele => ele === originalRoundName);

	//logger.log('[DrawsUtils] getBracketNameIndex originalRoundIndex:%o', originalRoundIndex);

	if (originalRoundIndex === 0) {
		return calcSection;
	}

	let index = roundKeys.findIndex(ele => ele === roundName);

	if (index === 0) {
		return calcSection;
	}
	let updatedRoundName = roundKeys[index - 1];
	let count = roundNum[updatedRoundName];
	let secondupdatedRoundName = roundKeys[index - 1];

	return count + getBracketNameIndex(originalRoundName, calcSection, secondupdatedRoundName, roundNum);
};

export const getCorrectEventId = (event, rounds) => {
	let eventId = event;

	if (event == 'MX' || event == 'SQ' || event == 'DQ') {
		eventId = `${event}_${rounds}`;
	}

	return eventId;
};

export const determineDrawsHighlightClass = (team, pid, scoreData) => {
	let higlight_condition =
		pid !== '' &&
		(pid === scoreData[`${team}`].idA || pid === scoreData[`${team}`].idB) &&
		(scoreData[`${team}`].idA !== '' ||
			scoreData[`${team}`].idB !== '' ||
			scoreData[`${team}`].idA !== null ||
			scoreData[`${team}`].idB !== null);

	return higlight_condition ? 'highlight' : '';
};

export const getCurrentRound = matches => {
	let match = matches.find((match, index) => {
		return !['D', 'E', 'F', 'G'].includes(match.statusCode);
	});

	logger.log('[DrawsUtils] getCurrentRound match:%o', match);

	if (match) {
		logger.log('[DrawsUtils] getCurrentRound match:%o', match);

		return parseInt(match.match_id.split('')[match.match_id.length - 3], 10);
	} else {
		let lastMatch = matches[matches.length - 1];
		logger.log('[DrawsUtils] getCurrentRound lastMatch:%o', lastMatch);

		return parseInt(lastMatch.match_id.split('')[lastMatch.match_id.length - 3], 10);
	}
};

/** MS or WS  */
export const getSinglesEventCodeByPlayerId = playerId => {
	return playerId?.startsWith('atp') ? 'MS' : playerId.startsWith('wta') ? 'WS' : null;
};

/** check if slamtracker button is active */
export const isSlamTrackerActive = matchData => {
	//stats level only set after match complete, so won't allow active pre/during match
	let active =
		(matchData?.team1?.idA && matchData?.team2?.idA) || (matchData?.team1?.[0]?.idA && matchData?.team2?.[0]?.idA);
	let slamtrackerActive = active && matchData?.courtId;
	return slamtrackerActive;
};

export const isPostMatch = matchData => {
	const postMatchCode = ['D', 'E', 'F'];
	const postMatch = postMatchCode.includes(matchData?.statusCode);
	return postMatch;
};

/** based on draws statusCode 
 * 
 * Draws, Live Scores:
	A = In Progress
	B = Future
	D = Complete
	E = Retired
	F = Walkover
	G = Default
	K = Suspended
	X = Arrive on Court
	Y = Warming Up
*/
export const isPreMatch = matchData => {
	const preMatchCode = ['B', 'X', 'Y'];
	const preMatch = preMatchCode.includes(matchData?.statusCode);
	return preMatch;
};

/** return lost or not
 *  who played against last and what round was it
 */
export const getIsLostFromDrawsPath = drawPathData => {
	const match = drawPathData?.data.filter(obj => {
		return obj.result === 'lost';
	});

	let isLost = match?.length > 0 ? true : false;
	let lostRoundNum = isLost ? match?.[0].round : null;
	let lostAgainst = isLost ? match?.[0].opponents?.[0]?.nameA : '';

	return {
		isLost,
		lostRoundNum,
		lostAgainst,
	};
};

/** return the draw path object for the given match id */
export const getSelectedMatchFromDrawsPath = (drawPathData, matchId) => {
	let selectedMatch;

	if (drawPathData && drawPathData?.status == 'loaded' && matchId) {
		let index;

		/** check if the matchId exist in the draw path data */
		if (Array.isArray(drawPathData?.data)) {
			index = drawPathData?.data?.findIndex(obj => {
				return obj.match === matchId;
			});
		}

		/** the same match id found */
		if (index > -1) {
			selectedMatch = drawPathData?.data[index];
		}
	}

	return selectedMatch;
};

/** return the draw path object for the given roundNum */
export const getSelectedMatchFromDrawsPathByRoundNum = (drawPathData, roundNum) => {
	let selectedMatch;

	if (drawPathData && drawPathData?.status == 'loaded' && roundNum) {
		/** check if the matchId exist in the draw path data */

		let index;

		/** make sure there are data - if no data it's {} if there are data, it's in [] */
		if (Array.isArray(drawPathData?.data)) {
			index = drawPathData?.data?.findIndex(obj => {
				return obj.round === roundNum.toString();
			});
		}

		/** the same match id found */
		if (index > -1) {
			selectedMatch = drawPathData?.data[index];
		}
	}

	return selectedMatch;
};

/** return the final round's status, opponent from drawsPath data
 *  drawPath doesn't include the data  to check if the Final is scheduled
 */
export const getFinalRoundStatusFromDrawsPath = drawPathData => {
	let finalMatch, isWinner, isCompleted, finalOpponentName;

	if (drawPathData && drawPathData?.status == 'loaded') {
		if (Array.isArray(drawPathData?.data)) {
			finalMatch = drawPathData?.data?.filter(match => match.round == '7');

			if (finalMatch?.length > 0) {
				isWinner = finalMatch[0]?.result == 'won';
				isCompleted = isWinner || finalMatch[0]?.result == 'lost';
				finalOpponentName = finalMatch[0]?.opponents?.[0]?.nameA;
			}
		}
	}
	return { isWinner, isCompleted, finalOpponentName };
};

/**
 *  isWinnerIsCompleted() calls getFinalRoundDetail()
 */
export const getFinalRoundDetail = (finalMatch, pid = null) => {
	let isWinner, isCompleted, isTeam1Scheduled, isTeam2Scheduled, winnerTeam, opponentName, opponentTeam;
	if (finalMatch) {
		winnerTeam = finalMatch?.team1?.won ? 'team1' : finalMatch?.team2?.won ? 'team2' : null;
		if (winnerTeam) {
			isWinner = pid ? finalMatch?.[winnerTeam]?.idA == pid : false;
			opponentTeam = winnerTeam == 'team1' ? 'team2' : 'team1';
		}
		isCompleted = finalMatch?.status == 'Completed';
		isTeam1Scheduled = finalMatch?.team1?.idA;
		isTeam2Scheduled = finalMatch?.team2?.idA;

		/** the runner up's player name */
		opponentName = finalMatch?.[opponentTeam]?.displayNameA;
	}

	return { isWinner, isCompleted, opponentTeam, isTeam1Scheduled, isTeam2Scheduled, opponentTeam };
};

/**
 *
 * @param {*} drawsData - obj (drawsLists/index) or array (draw data)
 * @param {*} pid  ------ optional
 * @returns
 */
export const isWinnerIsCompleted = (drawsData, pid = null) => {
	let finalRound,
		finalMatch,
		isWinner,
		isCompleted,
		isTeam1Scheduled,
		isTeam2Scheduled,
		winnerTeam,
		opponentName,
		opponentTeam;

	/** used on Draws List - drawData is column based obj
	 *  and not the raw data from the draw feed */
	if (!Array.isArray(drawsData)) {
		finalRound = drawsData[Object.keys(drawsData)[Object.keys(drawsData).length - 1]];
		finalMatch = finalRound?.title == 'Final' ? finalRound?.matches?.[0] : null;
	} else if (drawsData) {
		/** used draws feed data - used on Path to the Final */
		finalRound = drawsData?.[drawsData?.length - 1];
		finalMatch = drawsData?.[drawsData?.length - 1]?.roundCode == 'F' ? finalRound : null;
	}

	if (finalMatch) {
		let finalMatchDetail = getFinalRoundDetail(finalMatch, pid);
		isWinner = finalMatchDetail?.isWinner;
		isCompleted = finalMatchDetail?.isCompleted;
		opponentTeam = finalMatchDetail?.opponentTeam;
		isTeam1Scheduled = finalMatchDetail?.isTeam1Scheduled;
		isTeam2Scheduled = finalMatchDetail?.isTeam2Scheduled;
		opponentTeam = finalMatchDetail?.opponentTeam;
	}

	return { isWinner, isCompleted, isTeam1Scheduled, isTeam2Scheduled, opponentName };
};
