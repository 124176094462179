/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';
import HeadtoHeadTournamentStats from 'appdir/components/pages/Slamtracker/elements/preview/HeadtoHeadTournamentStats';
import HeadtoHeadYTDStats from 'appdir/components/pages/Slamtracker/elements/preview/HeadtoHeadYTDStats';


/**
 * -----------------------------------------------------------------------------
 * Functional Component: YTDStats
 * -----------------------------------------------------------------------------
 */
const HeadtoHeadStats = props => {

    const statMatch = useSelector((state) => state?.['ScoreManager']?.statMatch ?? {});

    // logger.log('[HeadtoHeadStats] statMatch:%o,', statMatch);

    return (
        <div className="stats">
            <div className="stats-content-row header">
                <div className="t1">{ statMatch?.team1?.displayNameA }</div>
                <div className="t2">{ statMatch?.team2?.displayNameA }</div>
            </div>

            <HeadtoHeadTournamentStats />
            <HeadtoHeadYTDStats />
        </div>
    )
};

export default HeadtoHeadStats;