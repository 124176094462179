/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from 'components/Template';
import EventsLink from 'components/general/EventsLink';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: SeedsListPage
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SlamTrackerLanding'],
		stubs: state['Config'].stubPages,
		slamtrackerUrl: op.get(state.Config, 'scoringData.matchStatsURL'),
		ScoreManager: state.ScoreManager,
		liveIndicators: state['ActiveData']['liveIndicators'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PracticeSchedulePage.mount()),
	//unmount:(data) => dispatch(actions.PracticeSchedulePage.unmount(data))
});

class SlamTrackerLanding extends Component {
	constructor(props) {
		super(props);

		this.loadedJson = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'SlamtrackerLanding',
		});
	}

	componentDidMount() {
		//logger.log('[PracticeSchedulePage] componentDidMount');
	}

	isStubbed() {
		return this.props.stubs && this.props.stubs.slamtracker
			? this.props.stubs.slamtracker.stub === 'stub'
				? true
				: false
			: false;
	}

	renderContent() {
		return (
			<div id="content">
				<section className="uso-content">
					<div>
						<div className="rich-text">
							{/* <h3 style={{ textAlign: 'center' }}>&nbsp;</h3> */}
							{/* <h3 style={{ textAlign: 'center' }}>Follow ALL matches, ALL Courts Live</h3> */}
							<p>&nbsp;</p>
							<p style={{ textAlign: 'center' }}>
								IBM SlamTracker is the premier scoring application for the US Open. It provides
								real-time scores, statistics, and in-depth, point-by-point analyses of every match.
							</p>
						</div>
					</div>
				</section>
			</div>
		);
	}

	renderButton() {
		let scoresOn = op.get(this.props, 'liveIndicators.scores', false);
		let liveMatches = op.get(this.props, 'ScoreManager.liveMatches', []);

		if (scoresOn && liveMatches && liveMatches.length > 0) {
			return (
				<div className="uso-interactive">
					<EventsLink
						to={`/en_US/scores/stats/${liveMatches[0].match_id}.html?promo=landing`}
						style="uso-button blue">
						Launch a Match
					</EventsLink>
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		//logger.log("[SlamTrackerLanding] render - this:%o", this);

		let header_propsData = {
			headerType: 'scores',
			title: 'IBM SlamTracker',
			metaTitle: 'IBM SlamTracker ',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'http://www.ibm.com/sports/usopen',
				title: 'Presented by',
				name: 'IBM',
				imgClass: '',
				imgSrc: '/assets/images/logos/ibm_logo_black.png',
				tagline: 'Presented by',
			},
			page_header: 'IBM SlamTracker',
			title: 'IBM SlamTracker',
		};

		let status = op.get(this.props, 'status');

		//logger.log("[SlamTrackerLanding] render - stubber:%o", this.isStubbed());
		return (
			<Template header={header_propsData} subHeader={subheader_attributes}>
				{status == 'loaded' ? (
					this.isStubbed() ? (
						<section className="wrapper slamtracker">
							<StubBox
								attributes={{
									title: header_propsData.title,
									message: this.props.stubs.slamtracker.text,
									basePicPath: this.props.basePicPath,
								}}
							/>
						</section>
					) : (
						<section className="wrapper slamtracker">
							<h1 className="header">IBM SlamTracker</h1>
							{this.renderContent()}
							{this.renderButton()}
						</section>
					)
				) : (
					<section className="wrapper slamtracker">
						<h1 className="header">IBM SlamTracker</h1>

						<LoadingIndicator />
					</section>
				)}
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlamTrackerLanding);
