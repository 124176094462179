/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: SectionDivider
 * -----------------------------------------------------------------------------
 */
class SectionDivider extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('onMount')) {
			this.state.onMount(this);
		}
	}

	render() {
		//logger.log('[SectionDivider] render - state:%o', this.state);
		return (
			<div className={`${this.state.data.style}`}>
				<div className="secDividerContent">
					<hr />
				</div>
			</div>
		);
	}
}

export default SectionDivider;
