import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { values } from 'appdir/main';
import {
	getDrawPath,
	getPlayerData,
	getDrawAnalysisLabel,
	getDrawAnalysisLevel,
	getDrawAnalysisLevel2,
	getDrawAnalysisDisplayRank,
} from 'appdir/components/data/Tournament/Util';
import axios from 'axios';

/**
Tournament Actions

These actions inititate loading tournament related data.  
Some items load into The Tournament store.
Others which are defined in the config 'sharedDataConfig' load in the CommonData store

These can be used by dispatching the action to load the data needed, 
  mapping Tournament or CommonData state as needed
  and receiving lifecycle updates to with the redux store changes

Data that can be loaded:
  Tournament store
    Results 
	  - includes complated matches and completed match days
    Schedule
	  - includes schedule with live scores
	Draws
	  - includes draws and sraws event list
  CommonData
    Match Insights Available
	Match Insights Matchup
	Power Index
	Power Index Trends
	Power Index Matchup
	Player Status
	Player Matches
	Player Lookup	

*/

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				paths: {
					completedDays: Config.scoringData.completedMatchDays,
					completedMatches: Config.scoringData.completedMatches,
					schedule: Config.scoringData.schedule,
					scheduleDays: Config.scoringData.scheduleDays,
					draws: Config.scoringData.draws,
					drawsEvent: Config.scoringData.drawDetail,
					players: Config.scoringData.players,
					playersDetail: Config.scoringData.playersDetail,
				},
				shared: {
					drawAnalysis: Config.sharedDataConfig.drawAnalysis,
					playerListData: Config.sharedDataConfig.playerListData,
				},
			};
			logger.log('[Tournament] deps.actions.mount - data:%o', data);
			dispatch({ type: deps.actionTypes.TOURNAMENT_MOUNT, data });
			//dispatch(deps.actions.Tournament.getResults({ day: 1 }));
		});
	},

	/**
	 * get player detail file
	 * @param {*} params
	 * @returns
	 */
	getPlayerDetail: params => (dispatch, getState) => {
		// logger.log('[Tournament]  deps.actions.getPlayerDetail - params:%o state:%o', params, getState().Tournament);
		let paths = getState().Tournament.paths;
		let idArray = [...params.playerIds];
		let files = idArray.map(id => {
			return { url: paths.playersDetail.replace('<id>', id) };
		});
		loadMultiple('playerDetail', files, dispatch);
	},

	/**
	 * get player detail file
	 * @param {*} params
	 * @returns
	 */
	getPlayerList: params => (dispatch, getState) => {
		let paths = getState().Tournament.paths;
		let files = [{ key: `players`, url: paths.players }];
		loadData(files, dispatch, true);
	},

	/**
	 * get results file
	 * @param {*} params
	 * @returns
	 */
	getResults: params => (dispatch, getState) => {
		//logger.log('[Tournament]  deps.actions.getResults - params:%o state:%o', params, getState().Tournament);
		let paths = getState().Tournament.paths;
		let files = [
			{ key: 'resultDays', url: paths.completedDays },
			{ key: `results_${params.day}`, url: paths.completedMatches.replace('<day>', params.day) },
		];
		loadData(files, dispatch, true);
	},

	/**
	 * Gets schedule data
	 * @param {*} params
	 * @returns
	 */
	getSchedule: (params, scores = false) => (dispatch, getState) => {
		//logger.log('[Tournament] deps.actions.getSchedule ');
		let paths = getState().Tournament.paths;
		let files = [
			{ key: 'scheduleDays', url: paths.scheduleDays },
			{ key: `schedule_${params.day}`, url: paths.schedule.replace('<day>', params.day) },
		];

		loadData(files, dispatch, true);
	},

	/**
	 * Gets schedule data
	 *   also initiates scoring data
	 *   combines scores with schedule data
	 *   continues scoring until different data request or stop action
	 * @param {*} params
	 * @returns
	 */
	// getScheduleScores: params => (dispatch, getState) => {
	// 	//logger.log('[Tournament] deps.actions.getSchedule ');
	// 	let paths = getState().Tournament.paths;
	// 	dispatch(deps.actions.ScoreManager.setScoreManagerStatus({ mip: true }));
	// 	let files = [
	// 		{ key: 'scheduleDays', url: paths.scheduleDays },
	// 		{ key: `scheduleScores_${params.day}`, url: paths.schedule.replace('<day>', params.day) },
	// 	];

	// 	loadData(files, dispatch, true);
	// },

	/**
	 * get draws data
	 * @param {*} params
	 * @returns
	 */
	getDrawsEvents: params => (dispatch, getState) => {
		let paths = getState().Tournament.paths;
		dispatch({ type: deps.actionTypes.TOURNAMENT_LOADING, key: `drawEvents`, status: 'loading' });
		getData(paths?.draws)
			.then(result => {
				dispatch(updateObject(`drawEvents`, result, 'loaded', null));
			})
			.catch(err => {
				//logger.error('[Tournament]  deps.actions.getDraws - error:%o', err);
				dispatch(updateObject(`drawEvents`, [], 'error', err));
			});
	},

	/**
	 * get draws data
	 * @param {*} params
	 * @returns
	 */
	getDraws: params => (dispatch, getState) => {
		let paths = getState().Tournament.paths;

		dispatch({ type: deps.actionTypes.TOURNAMENT_LOADING, key: `draws_${params.event}`, status: 'loading' });
		getData(paths.drawsEvent.replace('<eventId>', params.event))
			.then(result => {
				result.eventId = params.event;
				//logger.log('[Tournament]  deps.actions.getDraws - data:%o', result);
				dispatch(updateObject(`draws_${params.event}`, result, 'loaded', null));
			})
			.catch(err => {
				//logger.error('[Tournament]  deps.actions.getDraws - error:%o', err);
				dispatch(updateObject(`draws_${params.event}`, [], 'error', err));
			});
	},

	/**
	 * get draws path data
	 *  if MS or WS then need addtl score files
	 *  requires draw be loaded first before try to get draw path
	 * @param {*} params
	 * @returns
	 */
	getDrawPath: params => (dispatch, getState) => {
		let player;
		const paths = getState().Tournament.paths;
		values.drawBounds = getState().Config?.otherData?.drawBounds;
		let drawPathData, playerData;

		//receive player lists and process draw path data
		const processData = players => {
			//send loading
			dispatch({ type: deps.actionTypes.TOURNAMENT_LOADING, key: `drawPath`, status: 'loading' });

			let eventId = getState().Tournament?.data?.draw?.data?.eventId;

			//if draw analysis enabled and supported event
			// get draw analysis data
			// process data and dispatch with player list
			if ((eventId == 'MS' || eventId == 'WS') && getState().Config?.enabled?.drawAnalysis) {
				getData(getState().Tournament.shared.drawAnalysis.path.replace('<event>', eventId))
					.then(result => {
						player = getPlayerAnalysis(params.playerId, result.data);
						drawPathData = getDrawPath(getState().Tournament?.data?.draw?.data, params.playerId, player);
						playerData = getPlayerData(getState().Tournament?.data?.draw?.data, params.playerId, player);
						//logger.log('[Tournament]  deps.actions.getDrawPath - params:%o drawPathData:%o',params, drawPathData);
						dispatch(
							updateObject(
								'drawPath',
								{ data: drawPathData, player: playerData, players: players },
								'loaded'
							)
						);
					})
					.catch(err => {
						logger.error('[Tournament]  deps.actions.getDrawPath - drawAnalysis error:%o', err);
						drawPathData = getDrawPath(getState().Tournament?.data?.draw?.data, params.playerId);
						playerData = getPlayerData(getState().Tournament?.data?.draw?.data, params.playerId);
						dispatch(
							updateObject(
								'drawPath',
								{ data: drawPathData, player: playerData, players: players },
								'loaded'
							)
						);
					});
			} else {
				drawPathData = getDrawPath(getState().Tournament?.data?.draw?.data, params.playerId);
				playerData = getPlayerData(getState().Tournament?.data?.draw?.data, params.playerId);
				dispatch(updateObject('drawPath', { data: drawPathData, player: playerData }, 'loaded'));
			}
		};

		//get eventId from draw data
		if (getState().Tournament?.data?.draw?.data?.eventId) {
			//check for loaded players and load first if not loaded
			if (!isLoaded(getState(), 'players')?.data) {
				getData(paths.players)
					.then(result => {
						processData(result.players);
					})
					.catch(err => {
						logger.error('[Tournament]  deps.actions.getDrawPath - error:%o', err);
						dispatch(updateObject('drawPath', { data: [] }, 'error'));
					});
			} else {
				processData(getState().Tournament.data.players.data);
			}
		} else {
			logger.warn('[Tournament]  deps.actions.getDrawPath - no draw data');
			dispatch(updateObject('drawPath', { data: drawPathData, playerId: params.playerId }, 'error'));
		}
	},

	/**
	 * get draw analysis data
	 * @param {*} params (requires en event code and a playerId)
	 * @returns
	 */
	getDrawAnalysis: params => (dispatch, getState, store) => {
		const paths = getState().Tournament.paths;
		const processData = players => {
			dispatch({ type: deps.actionTypes.TOURNAMENT_LOADING, key: `drawAnalysis`, status: 'loading' });
			getData(getState().Tournament.shared.drawAnalysis.path.replace('<event>', params.eventId))
				.then(result => {
					//testData(result);
					let last_updated = result.last_updated_epoch;
					let total = result.data.length;
					result = result.data.filter(item => {
						return item.playerId == params.playerId;
					});
					let player = Array.isArray(result) ? result[0] : null;
					player.drawTotal = total;
					player.drawLabel = getDrawAnalysisLabel(player.difficultyCategory);
					player.drawLevel = getDrawAnalysisLevel(player.difficultyCategory);
					player.drawLevel = getDrawAnalysisLevel2(player.drawRank, player.currentRound);
					player.beginDrawLabel = getDrawAnalysisLabel(player.beginDifficultyCategory);
					player.displayRank = getDrawAnalysisDisplayRank(
						player.drawRank,
						player.difficultyCategory,
						player.currentRound
					);
					player.lastupdated = last_updated;

					//test using just begin levels
					// if (getState().Config?.feature?.drawAnalysisBegin) {
					// 	player.drawLabel = getDrawAnalysisLabel(player.beginDifficultyCategory);
					// 	player.drawLevel = getDrawAnalysisLevel(player.beginDifficultyCategory);
					// 	player.displayRank = getDrawAnalysisDisplayRank(
					// 		player.beginDrawRank,
					// 		player.beginDifficultyCategory,
					// 		1
					// 	);
					// }

					delete player.firstName;
					delete player.lastName;
					delete player.drawScore;
					delete player.difficultyCategory;
					delete player.beginDifficultyCategory;
					delete player.matches;

					logger.log('[Tournament]  deps.actions.getDrawAnalysis - drawAnalysis:%o', player);
					dispatch(updateObject('drawAnalysis', { data: player, players: players }, 'loaded'));
				})
				.catch(err => {
					logger.error('[Tournament]  deps.actions.getDrawAnalysis - drawAnalysis error:%o', err);
					dispatch(updateObject('drawAnalysis', { data: [] }, 'error'));
				});
		};

		if ((params.eventId == 'MS' || params.eventId == 'WS') && getState().Config?.enabled?.drawAnalysis) {
			if (!isLoaded(getState(), 'players')?.data) {
				getData(paths.players)
					.then(result => {
						processData(result.players);
					})
					.catch(err => {
						logger.error('[Tournament]  deps.actions.getDrawAnalysis - drawAnalysis error:%o', err);
						dispatch(updateObject('drawAnalysis', { data: [] }, 'error'));
					});
			} else {
				processData(getState().Tournament.data.players.data);
			}
		} else {
			dispatch(updateObject('drawAnalysis', { data: [] }, 'error'));
		}

		/* //testing cached data
		//test loading with expire times in Tournament data
		let config = getState().Tournament.shared.drawAnalysis;

		//check if local caching
		let loadNew = true;
		if (config.refreshSec) {
			if (getState().Tournament?.data?.drawAnalysis?.event == params.event) {
				let loadDiff = moment().diff(
					moment(getState().Tournament?.data?.drawAnalysis?.updated),
					'seconds',
					true
				);
				if (loadDiff < config.refreshSec) {
					loadNew = false;
				}
			}
		}

		files.push({ key: `drawAnalysis_${params.event}`, url: config.path.replace('<event>', params.event) });
		loadData(files, dispatch, loadNew); */
	},

	/**
	 * get the list of matches that have match insights
	 *   - loads through CommonData
	 * @param {*} params
	 * @returns
	 */
	getMatchInsightsAvailable: params => (dispatch, getState, store) => {
		deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let sharedDataConfig = Config.sharedDataConfig['matchInsightsMatches'];
			loadCommonData(sharedDataConfig, dispatch);
		});
	},

	/**
	 * get the match insights matchup data for a match
	 *   - loads through CommonData
	 * @param {*} params
	 * @returns
	 */
	getMatchInsightsMatchup: params => (dispatch, getState, store) => {
		deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let sharedDataConfig = { ...Config.sharedDataConfig['matchInsightsMatchup'] };
			sharedDataConfig.path = sharedDataConfig.path.replace('<matchId>', params.matchId);
			loadCommonData(sharedDataConfig, dispatch);
		});
	},

	/**
	 * get the overall power index list
	 *   - loads through CommonData
	 *  if event has draw analysis, add that data
	 * @param {*} params
	 * @returns
	 */
	getPowerIndex: params => (dispatch, getState, store) => {
		deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let sharedDataConfig = Config.sharedDataConfig['powerIndex'];
			loadCommonData(sharedDataConfig, dispatch)
				.then(result => {
					logger.log('[Tournament]  deps.actions.getPowerIndex - result:%o', store.getState()['CommonData']);
					let piData;
					if (params.eventId == 'MS') {
						piData = {
							players: store.getState()['CommonData']['powerIndex']['result']['atp'],
							publicationTimestamp: store.getState()['CommonData']['powerIndex']['result'][
								'publication_date_milliseconds'
							],
						};
					} else if (params.eventId == 'WS') {
						piData = {
							players: store.getState()['CommonData']['powerIndex']['result']['wta'],
							publicationTimestamp: store.getState()['CommonData']['powerIndex']['result'][
								'publication_date_milliseconds'
							],
						};
					}

					//load draw analysis
					if (piData) {
						if (getState().Config?.enabled?.drawAnalysis) {
							getData(getState().Tournament.shared.drawAnalysis.path.replace('<event>', params.eventId))
								.then(result => {
									//logger.log('[Tournament]  deps.actions.getPowerIndex - drawAnalysis:%o', result);
									// if (getState().Config?.feature?.drawAnalysisBegin) {
									// 	result.data.forEach(drawPlayer => {
									// 		drawPlayer.difficultyCategory = drawPlayer.beginDifficultyCategory;
									// 	});
									// 	//logger.log('[Tournament]  deps.actions.getPowerIndex - result.data:%o', result.data);
									// }

									dispatch(
										updateObject(
											'powerIndex',
											{ data: piData, drawAnalysis: result.data },
											'loaded'
										)
									);
								})
								.catch(err => {
									logger.error(
										'[Tournament]  deps.actions.getDrawAnalysis - drawAnalysis error:%o',
										err
									);
									dispatch(updateObject('powerIndex', { data: piData }, 'loaded'));
								});
						} else {
							dispatch(updateObject('powerIndex', { data: piData }, 'loaded'));
						}
					}
				})
				.catch(err => {
					dispatch(updateObject('powerIndex', { data: [] }, 'error'));
				});
		});
	},

	/**
	 * get the power index trend data
	 *   - loads through CommonData
	 * @param {*} params
	 * @returns
	 */
	getPowerIndexTrends: params => (dispatch, getState, store) => {
		deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let sharedDataConfig = Config.sharedDataConfig['powerIndexTrending'];
			loadCommonData(sharedDataConfig, dispatch);
		});
	},

	/**
	 * get matchup data for a power index match
	 *   - loads through CommonData
	 *   - is this needed over other matchup data?
	 * @param {*} params  {matchId}
	 * @returns
	 */
	getPowerIndexMatchup: params => (dispatch, getState, store) => {
		deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let sharedDataConfig = { ...Config.sharedDataConfig['powerIndexMatchup'] };
			sharedDataConfig.path = sharedDataConfig.path.replace('<matchId>', params.matchId);
			loadCommonData(sharedDataConfig, dispatch);
		});
	},

	/**
	 * get the player status file
	 *   - loads through CommonData
	 * @param {*} params {playerId}
	 * @returns
	 */
	getPlayerStatus: params => (dispatch, getState, store) => {
		deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let sharedDataConfig = { ...Config.sharedDataConfig['playerStatus'] };
			sharedDataConfig.path = sharedDataConfig.path.replace('<playerId>', params.playerId);
			sharedDataConfig.key = params.playerId;
			loadCommonData(sharedDataConfig, dispatch);
		});
	},

	/**
	 * get the player status file
	 *   - loads through CommonData
	 * @param {*} params {playerId}
	 * @returns
	 */
	getPlayerMatches: params => (dispatch, getState, store) => {
		deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let sharedDataConfig = { ...Config.sharedDataConfig['playerMatches'] };
			sharedDataConfig.path = sharedDataConfig.path.replace('<playerId>', params.playerId);
			sharedDataConfig.key = params.playerId;
			loadCommonData(sharedDataConfig, dispatch);
		});
	},

	/**
	 * get the player lookup file
	 *   - loads through CommonData
	 *   - contains players in historical data
	 * @param {*} params {playerId}
	 * @returns
	 */
	getPlayerLookup: params => (dispatch, getState, store) => {
		deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let sharedDataConfig = Config.sharedDataConfig['playerList'];
			loadCommonData(sharedDataConfig, dispatch);
		});
	},

	// stopSchedule: params => (dispatch, getState) => {
	// 	logger.log('[Tournament] deps.actions.stopSchedule');
	// 	dispatch(deps.actions.ScoreManager.setScoreManagerStatus({ mip: false }));
	// },

	clearDraws: params => (dispatch, getState) => {
		logger.log('[Tournament] deps.actions.clearDraws');
		dispatch({ type: deps.actionTypes.TOURNAMENT_CLEAR_DRAWS, data: null });
	},

	clearResults: params => (dispatch, getState) => {
		logger.log('[Tournament] deps.actions.clearResults');
		dispatch({ type: deps.actionTypes.TOURNAMENT_CLEAR_RESULTS, data: null });
	},

	clearSchedule: params => (dispatch, getState) => {
		logger.log('[Tournament] deps.actions.clearSchedule');
		dispatch({ type: deps.actionTypes.TOURNAMENT_CLEAR_SCHEDULE, data: null });
	},

	clearAll: params => (dispatch, getState) => {
		logger.log('[Tournament] deps.actions.clearAll');
		dispatch({ type: deps.actionTypes.TOURNAMENT_CLEAR, data: null });
	},

	checkExpired: dataConfig => (dispatch, getState, store) => {
		return deps.services.Tournament.checkExpired(dispatch, store, dataConfig);
	},
};

/**
 * form an update object to dispatch
 */
const updateObject = (key, data, status, errMessage) => {
	let dispatchObject = {
		type: deps.actionTypes.TOURNAMENT_UPDATE,
		key: key,
		data: data,
		status: status,
		updated: moment().toISOString(),
	};
	if (errMessage) {
		dispatchObject.type = deps.actionTypes.TOURNAMENT_ERROR;
		dispatchObject.errorMessage = errMessage;
	}

	return dispatchObject;
};

/**
 generic load data function
 loads and dispatches update for the given key
 */
const loadData = (files, dispatch, loadNew, other) => {
	if (loadNew) {
		let requests = files.map(file => {
			dispatch({ type: deps.actionTypes.TOURNAMENT_LOADING, key: file.key, status: 'loading' });
			return axios.get(file.url);
		});

		return axios
			.all(requests)
			.then(data => {
				data.forEach((result, i, a) => {
					if (result?.statusText != 'error') {
						dispatch(updateObject(files[i].key, result.data, 'loaded', null));
					} else {
						dispatch(updateObject(files[i].key, [], 'error', 'error - json parse'));
					}
				});
			})
			.catch(err => {
				logger.error('[Tournament] loadData - err:%o', err);
				dispatch(updateObject(files[i].key, [], 'error', err));
			});
	}
};

/**
 generic load data function for loading multiple data files together
 loads and dispatches update for the given key
 */
 const loadMultiple = (key, files, dispatch) => {
	dispatch({ type: deps.actionTypes.TOURNAMENT_LOADING, key: key, status: 'loading' });
	let requests = files.map(file => {
		return axios.get(file.url);
	});

	return axios
		.all(requests)
		.then(
			axios.spread((...response) => {
				logger.log('[Tournament] loadMultiple - response:%o', response);
				let data = [];
				response.forEach(result => {
					if (result.status == 200) data.push(result.data);
				});
				if (data.length > 0) {
					dispatch(updateObject(key, data, 'loaded', null));
				} else {
					dispatch(updateObject(key, [], 'error', 'error - json parse'));
				}
			})
		)
		.catch(err => {
			logger.error('[Tournament] loadData - err:%o', err);
			dispatch(updateObject(files[i].key, [], 'error', err));
		});
};

/**
 generic load data function
 */
const getData = url => {
	return new Promise((resolve, reject) => {
		return axios
			.get(url)
			.then(result => {
				if (result?.statusText != 'error') {
					resolve(result.data);
				} else {
					reject(new Error('parse error'));
				}
			})
			.catch(err => {
				reject(err);
			});
	});
};

/**
 * load data through CommonData component
 *  - used sharedData config in config web
 */
const loadCommonData = (config, dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(deps.actions.CommonData.checkExpired(config))
			.then(response => {
				if (response.status == 'expired') {
					dispatch(deps.actions.CommonData.update(config)).then(resp => {
						logger.log('[Tournament] loadCommonData - response:%o', resp);
						if (resp == 'loaded') {
							resolve(true);
						} else {
							reject(false);
						}
					});
				} else {
					resolve(true);
				}
			})
			.catch(error => {
				//logger.error('[Tournament] deps.actions.getMatchInsightsAvailable - error:o', error);
				dispatch(deps.actions.CommonData.error(config));
				reject(false);
			});
	});
};

const getPlayerAnalysis = (id, list) => {
	let result = list?.filter(item => {
		return item.playerId == id;
	});
	let player = Array?.isArray(result) ? result[0] : null;
	return player;
};

const isLoaded = (state, key) => {
	return state['Tournament']['data'][key];
};

const testData = data => {
	let csv = '';
	data.forEach(player => {
		logger.log('[Tournament] testData - score:%o', player.drawScore);
		csv += player.drawScore + ',';
	});
	logger.log('[Tournament] testData - csv:%o', csv);
};
