/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

import { fetch, getQuerystringHash } from 'appdir/components/general/Util';
import Template from 'components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';

// import ClickOverlayContent from 'components/cms/ClickOverlayContent';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';
import DiningCard from '../../common-ui/DiningCard/DiningCard';

/**
 * -----------------------------------------------------------------------------
 * React Component: Dining
 * -----------------------------------------------------------------------------
 */

export default class Dining extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: {
				open: '',
			},
		};

		this.init = true;
		this.parsedFoodType = '';
	}

	componentDidMount() {
		this.parsedFoodType = getQuerystringHash();
		logger.log('[Dining] componentDidMount parsedFoodType:%o', this.parsedFoodType);

		this.setState({
			status: 'loaded',
		});
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Dining',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.init && !this.state.diningData && this.props.otherData?.dining) {
			this.init = false;

			fetch(this.props.otherData?.dining).then(data => {
				this.setState({
					diningData: data,
				});
			});
		}
	}

	/** set Location dropdown */
	setLocation = event => {
		// logger.log('[Dining] setLocation event:%o', event);

		if (typeof webview === 'undefined') {
			MeasurementUtils.dispatchMeasurementCall('DiningLocation', {
				location: event.target.value,
			});
		} else {
			measureInApp(['Metrics', 'DiningLocation', 'location', event.target.value]);
		}
		this.setState({
			selectedLocation: event.target.value,
			filters: {
				open: '',
			},
		});
	};

	/** set Food Type dropdown */
	setFood = e => {
		// logger.log('[Dining] setFood foodType:%o', foodType);
		this.parsedFoodType = '';

		if (typeof webview === 'undefined') {
			MeasurementUtils.dispatchMeasurementCall('DiningFilter', {
				food: e.target.value,
			});
		} else {
			measureInApp(['Metrics', 'DiningFilter', 'food type', e.target.value]);
		}
		this.setState({
			selectedFood: e.target.value,
			filters: {
				open: '',
			},
		});
	};

	onOpen = which => {
		this.setState({
			filters: {
				open: which,
			},
		});

		//measureApp('Draws', 'Menu', 'Open');
	};

	getContent = () => {
		let { diningData } = this.state;

		if (diningData?.restaurants?.length > 0) {
			let filteredContent = diningData?.restaurants;

			/** filter by the restaurant */
			if (
				this.state.selectedLocation &&
				this.state.selectedLocation !== 'All' &&
				this.state.selectedLocation !== this.locationData.options[0].name
			) {
				filteredContent = diningData.restaurants.filter(d => d.location.includes(this.state.selectedLocation));
			}

			/** filter by the restaurant && food type */
			if (this.parsedFoodType !== '' || null) {
				filteredContent = filteredContent.filter(d => d.foods.includes(this.parsedFoodType));
			} else if (
				filteredContent.length > 0 &&
				this.state.selectedFood &&
				this.state.selectedFood !== 'All' &&
				this.state.selectedFood !== this.foodsData.options[0].name
			) {
				filteredContent = filteredContent.filter(d => d.foods.includes(this.state.selectedFood));
			}

			if (filteredContent.length > 0) {
				return filteredContent.map((item, index) => {
					logger.log('filtered content', item, index);
					return (
						// <ClickOverlayContent
						// 	data={{ ...item, index }}
						// 	measureData={{
						// 		...item,
						// 		actionType: 'diningClick',
						// 	}}
						// 	key={item.title + index}
						// />
						<DiningCard data={{ ...item }} />
					);
				});
			} else {
				return <p>There are no restaurants available. </p>;
			}
		}
	};

	render() {
		// logger.log('[Dining] render this:%o', this);
		let header_attributes = {
			headerType: 'visit',
			title: 'Dining',
			metaTitle: 'Dining',
			metaDescription:
				'From food courts and concession stands to fine dining, our guide to food and beverage at the US Open provides all the info you need to satisfy your cravings.',
			metaDate: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/visit/on_site.html',
					title: 'visit',
				},
			],
			title: 'Dining',
			sponsor: this.props?.sponsor,
			page_header: 'Dining',
		};

		if (this.state.diningData) {
			let { diningData } = this.state;

			let locationDropdownVals, foodsDropdownVals;
			let filteredContent = [];

			let selectLocationTitle = [
				{ name: 'Filter by Location', value: 'Filter by Location' },
				{ name: 'All', value: 'All' },
			];

			if (diningData?.locations?.length > 0) {
				locationDropdownVals = diningData.locations.map((item, index) => {
					return { name: item, value: item };
				});
			}

			let locationDataArr = selectLocationTitle.concat(locationDropdownVals);
			this.locationData = {
				options: locationDataArr,
			};

			let selectFoodTitle = [
				{ name: 'Filter by Food Type', value: 'Filter by Food Type' },
				{ name: 'All', value: 'All' },
			];

			if (diningData?.foods?.length > 0) {
				foodsDropdownVals = diningData.foods.map((item, index) => {
					if (item.includes(this.parsedFoodType)) {
						filteredContent.push(item);
					}
					return { name: item, value: item };
				});
			}

			let foodsDataArr = selectFoodTitle.concat(foodsDropdownVals);
			this.foodsData = {
				options: foodsDataArr,
			};

			let title =
				this.state.selectedLocation && this.state.selectedLocation !== this.locationData.options[1].name
					? this.state.selectedLocation
					: 'All';

			logger.log('[Dining] display filteredContent:%o', filteredContent);

			//display for select
			let displayFood = this.foodsData.options[0].name;
			if (this.parsedFoodType !== '') {
				if (filteredContent.length > 0) {
					displayFood = this.parsedFoodType;
				}
			} else if (this.state?.selectedFood) {
				displayFood = this.state?.selectedFood;
			}
			logger.log('[Dining] displayFood:%o', displayFood);

			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper article-wrapper">
						<h1 className="header">Dining</h1>

						<PageSubMenu
							mode="Visit"
							selected="At the Open"
							// filters={this.props.filters}
						/>

						<div className={`general_content dining`}>
							<div className="dining-wrapper">
								<div className="filter">
									<div className="dining-dropdown-wrapper">
										<div className="dining-location-wrapper">
											<select onChange={this.setLocation} className="dining-location-select-menu">
												{this.locationData.options.map(option => (
													<option key={option.name} value={option.name}>
														{option.name}
													</option>
												))}
											</select>
										</div>
										<div className="dining-food-types-wrapper">
											<select onChange={this.setFood} className="dining-food-types-select-menu">
												{this.foodsData.options.map(option => (
													<option key={option.name} value={option.name}>
														{option.name}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
								<h3 className="title">{title}</h3>

								<div className="dining-content">{this.getContent()}</div>
							</div>
						</div>
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper article-wrapper">
						<h1 className="header">Dining</h1>
						<LoadingIndicator />
					</section>
				</Template>
			);
		}
	}
}

Dining.defaultProps = {};
