import React, { Component } from 'react';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';

export default class InlineScript extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		logger.log('[InlineScript] constructor: %o', props);
	}

	componentDidMount() {
		logger.log('[InlineScript] componentDidMount');
		if (this.state.data) {
			logger.log('[InlineScript] componentDidMount script %o', this.state.data.link);
			// const s = document.createElement('script');
			// s.type = 'text/javascript';
			// s.src = this.state.data.link;
			// s.async = true;
			// this.instance.appendChild(s);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[InlineScript] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[InlineScript] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	render() {
		logger.log('[InlineScript] render - state:%o', this.state);
		return this.state ? (
			<div className="premium_content_full" ref={el => (this.instance = el)}>
				{ReactHtmlParser(this.state.data.text)}
			</div>
		) : null;
	}
}
