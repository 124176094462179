import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.OPEN_QUESTIONS_DETAIL_MOUNT:
			newState = {
				...state,
				status: 'loaded',
				...action.data,
			};
			logger.log('[OpenQuestionsDetail] reducer.OPEN_QUESTIONS_DETAIL_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.OPEN_QUESTIONS_DETAIL_UNMOUNT:
			newState = {
				...state,
				status: 'unloaded',
				...action.data,
			};
			logger.log('[OpenQuestionsDetail] reducer.OPEN_QUESTIONS_DETAIL_UNMOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.OPEN_QUESTIONS_DETAIL_ERROR:
			logger.log('[OpenQuestionsDetail] reducer.OPEN_QUESTIONS_DETAIL_ERROR - action:%o', action);
			newState = Object.assign({}, state, {
				status: 'error',
				error: action.error,
			});
			return newState;

		case deps.actionTypes.OPEN_QUESTIONS_DETAIL_LOAD:
			logger.log('[OpenQuestionsDetail] reducer.OPEN_QUESTIONS_DETAIL_LOAD');

			newState = {
				...state,
				status: 'loaded',
				...action.data,
			};
			//logger.log('[OpenQuestionsDetail] reducer.OPEN_QUESTIONS_DETAIL_LOAD - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
