import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	//logger.log('[DrawAnalysis] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.DRAWANALYSIS_MOUNT:
			// logger.log('[DrawAnalysis] reducers - action:%o',action);

			newState = {
				...state,
				status: 'loaded',
				...action.data,
			};
			// logger.log('[DrawAnalysis] reducers DRAWANALYSIS_MOUNT - newState:%o',newState);
			return newState;
			break;
		case deps.actionTypes.DRAWANALYSIS_UPDATE:
			logger.log('[DrawAnalysis] reducers - DRAWANALYSIS_UPDATE action:%o', action);
			newState = {
				...state,
				...action.data,
			};
			logger.log('[DrawAnalysis] deps.actionTypes.DRAWANALYSIS_UPDATE - newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};
