/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsButton from 'appdir/components/common-ui/EventsButton';
import MISectionHeader from './MISectionHeader';

/**
 * -----------------------------------------------------------------------------
 * React Component: PowerIndexNumbers for Match Insights
 * -----------------------------------------------------------------------------
 */
const PowerIndexNumbers = props => {
	// logger.log('[PowerIndexNumbers] - props:%o', props);

	let { powerIndexMatchupData, teams } = props;
	const isSlamtracker = props?.type == 'slamtracker';

	const getPowerIndexLBBtn = () => {
		let selectTab = teams[0]?.idA.includes('wta') ? 'wta' : 'atp';

		if (props.isPanel === true) {
			return (
				<div
					className={`btn-border center rounded-corner full`}
					onClick={() => {
						props.onButtonClick(`/en_US/powerindex/index.html`);
					}}>
					IBM Power Index Leaderboard
				</div>
			);
		} else {
			return (
				<EventsButton
					className={`btn-border center rounded-corner full`}
					to={`/en_US/powerindex/index.html?selectTab=${selectTab}`}>
					IBM Power Index Leaderboard
				</EventsButton>
			);
		}
	};

	let klass = 'mi-section ';
	klass += props?.windowSize == 'mobile' || props?.windowSize == 'tablet' || isSlamtracker ? 'no-border-bottom ' : '';
	klass += props?.type == 'blog' ? 'no-border-bottom blog-style' : '';

	if (powerIndexMatchupData[teams[0]?.idA]?.wpi_rank && powerIndexMatchupData[teams[1]?.idA]?.wpi_rank) {
		return (
			<div className={klass}>
				{!props?.hideTitle ? (
					<div className="above-tablet">
						<MISectionHeader title="IBM Power Index" />
					</div>
				) : null}

				<div className="mi-pr-ranks-wrapper">
					<div className="mi-pr-ranks">
						<div className="ranking-row">
							<div className="ranking-cell player1-pr-rank">
								{powerIndexMatchupData[teams[0]?.idA]?.wpi_rank}

								{props?.type == 'blog' ? (
									<div className="mobile-only">
										<span className="player-name blog-name">{`${teams[0]?.displayNameA}`}</span>
									</div>
								) : null}

								<div className="above-tablet">
									<span className="player-name">{`${teams[0]?.displayNameA}`}</span>
								</div>
							</div>

							{/** mobile or slamtracker */}
							<div className="mobile-only">
								<div className="ranking-cell rank-label">IBM Power Index</div>
							</div>

							<div className="above-tablet vmiddle">
								<div className="ranking-cell rank-label">{getPowerIndexLBBtn()}</div>
							</div>

							<div className="ranking-cell player2-pr-rank">
								{powerIndexMatchupData[teams[1]?.idA]?.wpi_rank}
								{props?.type == 'blog' ? (
									<div className="mobile-only">
										<span className="player-name blog-name">{`${teams[1]?.displayNameA}`}</span>
									</div>
								) : null}

								<div className="above-tablet">
									<span className="player-name">{`${teams[1]?.displayNameA}`}</span>
								</div>
							</div>
						</div>
					</div>

					{/** App needs this link to be open in their webview - no need to use bypassAppLink */}
					{/** mobile or slamtracker */}
					<div className="below-desktop show-slamtracker">
						<div className="ranking-cell rank-label">{getPowerIndexLBBtn()}</div>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default PowerIndexNumbers;
