import React, { Component } from 'react';

class TournamentDayBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currSelection: '',
		};
	}

	handleChange = event => {
		const selectedOption = event.target.value;
		this.setState({ currSelection: selectedOption });
		this.props.onOptionSelected(selectedOption);

		return selectedOption;
	};

	render() {
		// logger.log('[TournamentDayBar] render - props:%o, state:%o', this.props, this.state);
		const { options, pageMode } = this.props;
		let daySelectOptions = [];
		const tmpOptions = [...options];
		const nonQualOptions = tmpOptions.filter(option => {
			return !option.quals && !option.practice;
		});

		// match insights day selector does not display qual days
		daySelectOptions = pageMode === 'tourn' ? nonQualOptions : options;

		return (
			<div className="tournament-day-selector-wrapper">
				<span className="day-prefix">DAY:</span>
				{daySelectOptions.map(option => {
					// logger.log(
					// 	'[TournamentDayBar] pageMode: %o, this.props.enableFuture: %o, this.props.selectedDay: %o, option.tournDay: %o, option.winnerCount: %o, option.released: %o, option: %o',
					// 	pageMode,
					// 	this.props.enableFuture,
					// 	this.props.selectedDay,
					// 	option.tournDay,
					// 	option.winnerCount,
					// 	option.released,
					// 	option
					// );

					// on the schedule page all days should be selectable and therefore active
					// all other day selector pages first check if the day has a winner count and has a schedule
					// then check if the box should be disabled (check enableFuture)
					const className =
						pageMode === 'schedule'
							? `day-selector-box ${String(this.props.selectedDay) === String(option.tournDay) &&
									'selectedDay'} active`
							: option.displayDay &&
							  option.released &&
							  ((pageMode == 'scores' && option.winnerCount > 0) || pageMode != 'scores')
							? `day-selector-box ${String(this.props.selectedDay) === String(option.tournDay) &&
									'selectedDay'} active`
							: `day-selector-box ${this.props?.enableFuture ? '' : 'disabled'}`;

					return option.displayDay ? (
						<button
							key={option.messageShort}
							disabled={
								pageMode === 'schedule'
									? false
									: pageMode === 'scores' && option.winnerCount > 0 && option.released
									? false
									: pageMode === 'tourn' && option.released
									? false
									: true
							}
							className={className}
							value={option.tournDay}
							onClick={this.handleChange}>
							{/* {logger.log(
								"pageMode === 'scores' %o: option.winnerCount > 0 %o: && option.released%o: ",
								pageMode === 'scores',
								option.winnerCount > 0,
								option.released
							)} */}
							{option.messageShort.charAt(0) === 'Q'
								? option.messageShort.charAt(0).concat(option.displayDay)
								: option.displayDay}
						</button>
					) : null;
				})}
			</div>
		);
	}
}

export default TournamentDayBar;
