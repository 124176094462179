import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';
import EventsLink from 'appdir/components/general/EventsLink';

const mapStateToProps = (state, props) => {
	return {
		...props,
		Controller: state['Controller'].favorites,
		EventsWindow: state['WindowSize'].EventsWindow,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	showFavorite: data => dispatch(deps.actions.Controller.update(data)),
});

class Favorites extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		// console.log('favorites', props);
	}

	componentDidMount() {}

	toggleFavorite() {
		let newFavoriteState = {};
		let favoriteFlag = this.props.Controller.show;
		let toggleFavoriteFlag = !favoriteFlag;

		newFavoriteState['favorites'] = {
			...this.props.Controller,
			show: toggleFavoriteFlag,
		};
		this.props.showFavorite(newFavoriteState);

		let measure_val = `favorite_${toggleFavoriteFlag ? 'on' : 'off'}`;
		MeasurementUtils.dispatchMeasurementCall('Favorites', {
			action: measure_val,
		});
	}

	componentDidUpdate(prevState) {}

	render() {
		let isShow = this.props.Controller?.show;
		let fontcase = this.props?.upperCase ? 'uppercase' : '';
		this.playersPlayingData = this.props?.playersData?.length > 0 ? this.props?.playersData : null;
		let numFavorites = this.props.Controller.players.length;
		let isMobile = this.props?.EventsWindow?.windowSize === 'mobile';
		// favoritesMidMessage should change based on open or closed state
		let favoritesMidMessage = isShow
			? 'Select a player to locate a match'
			: isMobile
			? 'and locate their matches'
			: '\tView your favorite players and locate their matches';
		let iconShow = isMobile ? 'hideIcon' : 'showIcon';
		let ssbShow = this.props?.sumScoreBoard ? true : false;

		if (ssbShow) {
			return (
				<div
					onClick={() => {
						this.toggleFavorite();
					}}
					tabIndex={0}
					onKeyPress={() => (event.key === 'Enter' ? this.toggleFavorite() : null)}
					className="ssb-favorites">
					<div className="favorites-button-wrapper">
						<span className="view-favorites-button">Favorites</span>
					</div>
					<div
						className={`favorites-toggle ${this.props?.style ? this.props.style : ''}
						${isShow ? 'on ' + this.props.favColor : this.props.unFavColor} ${fontcase}`}></div>
				</div>
			);
		} else {
			return (
				<>
					<div
						onClick={() => {
							this.toggleFavorite();
						}}
						tabIndex={0}
						onKeyPress={() => (event.key === 'Enter' ? this.toggleFavorite() : null)}
						className={`favorites-toggle  
						${this.props?.style ? this.props.style : ''}
			${isShow ? 'on ' + this.props.favColor + ' outline' : this.props.unFavColor} 
				${fontcase}`}>
						<div className="favorites-button-wrapper" onClick={e => e.stopPropagation()}>
							<span className="view-favorites-button">View Favorites &#40;{numFavorites}&#41;</span>
							<span className="favorites-mid-message">
								{!isShow ? (
									<div>
										<i className={`icon-arrow-large-left ${iconShow}`} />
										<i className={`icon-arrow-large-left ${iconShow}`} />
										<span>{favoritesMidMessage}</span>
									</div>
								) : (
									<span>{favoritesMidMessage}</span>
								)}
							</span>
						</div>
					</div>
					<div className="favorites-edit-wrapper">
						<EventsLink
							to="/en_US/players/index.html"
							style="favorites-edit"
							measureData={{
								actionType: 'FavoriteEdit',
							}}>
							EDIT
							<i className="icon-arrow-right" />
						</EventsLink>
					</div>
				</>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
