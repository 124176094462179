import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import cookie from 'react-cookies';
import isEmpty from 'lodash/isEmpty';
import { getCleanLink, getIconClass } from 'appdir/components/general/Util';
import op from 'object-path';

const mapStateToProps = (state, props) => {
	return {
		...state['BreakingAlert'],
		alertData: op.get(state.ActiveData, 'homepage.alertMessage'),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.BreakingAlert.mount()),
	setOpenStatus: data => dispatch(deps.actions.BreakingAlert.setOpenStatus(data)),
	setGlobalLink: data => dispatch(deps.actions.Controller.setGlobalLink(data)),
});

class BreakingAlert extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// ...this.props,
			webCookie: cookie.load('breakingNews'), //value of open/closed
		};
		this.loaded = false;

		logger.log('[BreakingAlert] constructor - props:%o', props);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log(
		// 	'[BreakingAlert] componentDidUpdate - prevState:%o, prevProps:%o, this:%o',
		// 	prevState,
		// 	prevProps,
		// 	this
		// );

		/** component has loaded and we have alert data */
		if (this.props.loaded && this.props.alertData) {
			/** The webcooke has changed, get open value */
			if (prevState.webCookie != this.state.webCookie) {
				// logger.log("[BreakingAlert] componentDidUpdate - cookie value has changed");
				let cookieOpen = '';

				if (this.state.webCookie) {
					let cookieArray = this.state.webCookie.split(',');
					cookieOpen = cookieArray[0];
					this.props.setOpenStatus(cookieOpen);
				}
			}

			/** There is alert data and no cookie - the banner is visible */
			if (!this.state.webCookie && this.props.status != 'open') {
				// logger.log("[BreakingAlert] componentDidUpdate - setting status to open");
				this.props.setOpenStatus('open');
			}
		}
	}

	componentDidMount() {
		// logger.log("[BreakingAlert] componentDidMount");
		if (!this.props.loaded) {
			this.props.mount();
		}
	}

	closeAlert = (e, alertId) => {
		// logger.log("[BreakingAlert] closeAlert");
		if (e.key == ' ' || e.code == 'Space' || e.type == 'click') {
			this.loaded = false;
			let cookieString = 'closed,' + alertId;
			const expires = new Date();
			e.stopPropagation();

			expires.setDate(expires.getDate() + 7);

			cookie.save('breakingNews', cookieString, { expires, path: '/' });

			this.setState({
				webCookie: cookieString,
			});
		}
	};

	handleLinkClick = (e, url) => {
		if (!url || url == '#') {
			return null;
		}
		if (e.key == ' ' || e.code == 'Space' || e.keyCode == 32 || e.type == 'click') {
			let link = getCleanLink(url);
			logger.log('[BreakingAlert] handleLinkClick link:%o', link);
			if (!link.external) {
				this.props.setGlobalLink({
					globalLink: {
						link: link.url,
						query: 'promo=breaking',
					},
				});
			} else {
				window.open(link.url, '_blank');
			}
		}
	};

	renderAlert(alertClass, alertMsg) {
		// logger.log("[BreakingAlert] renderAlert alertMsg:%o", alertMsg);
		let isThereLink = alertMsg?.link !== '' ? true : false;

		return (
			<div
				className={`breakingNews bn-theme${alertMsg.theme ? alertMsg.theme : ''}`}
				onClick={e => this.handleLinkClick(e, alertMsg.button.link)}
				onKeyDown={e => this.handleLinkClick(e, alertMsg.button.link)}
				tabIndex={0}>
				<div className="bn-flex-container">
					{alertMsg.icon && alertMsg.icon !== '' ? (
						<div className="bn-icon">
							<i className={getIconClass(alertMsg.icon)}></i>
						</div>
					) : null}
					<div className={`bn-content ${isThereLink ? 'hoverable' : ''}`}>
						{alertMsg.title ? `${alertMsg.title}:` : ``}&nbsp;
						{alertMsg.link !== '' ? (
							<span className="bn-link" onClick={() => this.handleLinkClick(alertMsg.link)}>
								{alertMsg.text}
							</span>
						) : (
							// <a href={alertMsg.link} target="_self">
							//   {alertMsg.text}
							// </a>
							<span>{alertMsg.text}</span>
						)}
						{!isEmpty(alertMsg.button) ? (
							<span className={`bn-link ${alertMsg.button.bold ? 'bn-bold-link' : ''}`}>
								{alertMsg.button.text}
							</span>
						) : null}
					</div>
				</div>
				{/* {!isEmpty(alertMsg.button) ? (
                  <div className="bn-button">
                      <span
                          className="uso-button"
                          onClick={() => this.handleLinkClick(alertMsg.button.link)}
                      >
                          {alertMsg.button.text}
                      </span>
                  </div>
              ) : null} */}
				<div
					className="ar-icon"
					role="button"
					tabIndex={0}
					onKeyDown={e => {
						this.closeAlert(e, alertMsg.id);
					}}
					onClick={e => {
						this.closeAlert(e, alertMsg.id);
					}}>
					<div className="icon">
						<i className="icon-close" />
					</div>
				</div>
			</div>
		);
	}

	render() {
		// logger.log("[BreakingAlert] render - this:%o", this);

		// let alertClass = '';
		let cookieId = '';
		let cookieOpen = '';

		if (this.state.webCookie) {
			let cookieArray = this.state.webCookie.split(',');
			cookieOpen = cookieArray[0];
			cookieId = cookieArray[1];
		} else {
			cookieOpen = 'open';
		}

		if (!isEmpty(this.props.alertData) && this.props.alertData !== '') {
			let alertMsg = this.props.alertData;

			if (!isEmpty(alertMsg.text) && alertMsg.text !== '') {
				//first time to site no cookies/cookie open
				if (cookieOpen === 'open') {
					// render alert
					return this.renderAlert('', alertMsg);
				} else {
					//cookie closed but id different
					if (alertMsg.id !== cookieId) {
						return this.renderAlert('', alertMsg);
					} else {
						return null;
					}
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakingAlert);
