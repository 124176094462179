/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';
import DividerHeader from 'appdir/components/common-ui/DividerHeader';


/**
 * -----------------------------------------------------------------------------
 * Functional Component: YTDStats
 * -----------------------------------------------------------------------------
 */
const HeadtoHeadTournamentStats = props => {
    // const uom = useSelector((state) => state?.['Controller']?.userPreferences?.speed ?? null);
    const uom = "I";
    const tourn_stats = useSelector((state) => state?.['Slamtracker']?.tourn_stats ?? null);
    const tournamentStatsConfig = useSelector((state) => state?.['Config']?.slamtracker?.tournamentStatsConfig ?? []);
    const p1 = tourn_stats?.team1;
    const p2 = tourn_stats?.team2;

    // logger.log('[HeadtoHeadTournamentStats] tourn_stats:%o', tourn_stats);

    const getPlayerName = (player) => {
        let name = null;

        if(player?.first_name && player?.last_name) {
            name = player.first_name.charAt(0) + ' ' + player.last_name;
        }
        return name;
    }

    const getStatRow = (statArray, i) => {
        let title = statArray[1] || "";
        let p1_stat_val = "";
        let p2_stat_val = "";
        let winner = "";
        let rowClass="";
        let valueMore="";

        if (title == "") {
            return null;
        }

        p1_stat_val = p1?.player_stats?.[title] || 'N/A';
        p2_stat_val = p2?.player_stats?.[title] || 'N/A';

        // logger.log('[HeadtoHeadTournamentStats] p1_stat_val:%o, p2_stat_val:%o, statArray[2]:%o', p1_stat_val, p2_stat_val, statArray[2]);

        if(p1_stat_val == 'N/A') { 
            winner = 't2'; 
        } else if (p2_stat_val == 'N/A') { 
            winner = 't1'; 
        } else if(statArray[2] == "high") {
            if(p1_stat_val > p2_stat_val) { winner = 't1'; }
            else if (p2_stat_val > p1_stat_val) { winner = 't2'; }
        } else {
            if(p1_stat_val < p2_stat_val) { winner = 't1'; }
            else if (p2_stat_val < p1_stat_val) { winner = 't2'; }
        }

        if(statArray[1] == "serve_mph") {
            rowClass = " imperial";
        } else if (statArray[1] == "serve_kmh") {
            rowClass = " metric"
        }

        if(title.indexOf('pct') > 0) {
            valueMore = "%";
        } else if(statArray[1] == "serve_mph") {
            valueMore = " MPH";
        } else if(statArray[1] == "serve_kmh") {
            valueMore = " KMH";
        }

        if((statArray[1] == "serve_mph" && uom == 'M') || (statArray[1] == "serve_kmh" && uom == 'I')) {
            return null;
        } else {
            return (
                <div className={`stats-content-row${rowClass}`} key={`tournament-stat-row-${i}`}>
                    <div className={`t1${winner == 't1' ? ' winner' : ''}`}>
                        <span className="stat">{`${p1_stat_val}${p1_stat_val !== 'N/A' ? valueMore : ''}`}</span>
                    </div>
                    <div className="label">{statArray[0]}</div>
                    <div className={`t2${winner == 't2' ? ' winner' : ''}`}>
                        <span className="stat">{`${p2_stat_val}${p2_stat_val !== 'N/A' ? valueMore : ''}`}</span>                            
                    </div>
                </div>
            )
        }
    }

    if(tourn_stats) {
        return (
            <>
                {/* <div className="stats-content-row header">
                    <div className="t1">{ p1?.name }</div>
                    <div className="t2">{ p2?.name }</div>
                </div> */}
                <DividerHeader text='Tournament Stats' />

                <div className={`stats-content ${uom == "I" ? 'imperial' : 'metric'}`}>
                    
                    {tournamentStatsConfig.tournStatRows.length > 0 &&
                        tournamentStatsConfig?.tournStatRows.map((stat, i) => {
                            return getStatRow(stat, i);
                        }) 
                    }
                </div>
            </>
        )
    } else {
        return null;
    }
};

export default HeadtoHeadTournamentStats;