/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Button
 * -----------------------------------------------------------------------------
 */

class SelectMenu extends Component {
	constructor(props) {
		super(props);

		//logger.log('[SelectMenu] constructor - props:%o', props);
		let selected = props.selected;
		if (!selected) {
			selected = props.attributes.options[0].value;
		}

		//logger.log('[SelectMenu] constructor selected:%o', selected);

		this.state = {
			...this.props,
			selected: selected,
		};
		// logger.log('[SelectMenu] constructor state:%o', this.state);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let selected = nextProps.selected;
		if (!selected) {
			selected = nextProps.attributes.options[0].value;
		}

		// logger.log('[SelectMenu] getDerivedStateFromProps selected:%o', selected);

		return {
			...prevState,
			...nextProps,
			selected: selected,
		};
	}

	getName(value) {
		let name = '';

		this.state.attributes.options.forEach(item => {
			if (item.value == value) {
				//logger.log('[SelectMenu] getName - item:%o value:%o name:%o', item, value, item.name);
				name = item.name;
			}
		});

		return name;
	}

	modeGetName() {
		let name;
		name = this.props.selected;
		return name;
	}

	showMenu() {
		if (!this.state.show) {
			this.props.onOpen(this.state.name);
		} else {
			// clear open value when show is false to avoid opening the dropdown on other state update
			this.props.onOpen('');
		}

		logger.log('[SelectMenu] showMenu state:%o', this.state);

		this.setState({
			show: !this.state.show,
		});
	}

	setSelected(e, selected, name) {
		logger.log('[SelectMenu] setSelected:%o', selected);
		if (e.key == ' ' || e.code == 'Space' || e.keyCode == 32 || e.type == 'click') {
			this.setState({
				selected: selected,
				name: name,
				show: false,
			});
			this.props.onSelected(selected, name);
		}
	}

	render() {
		// logger.log('[SelectMenu] render - state:%o', this.state);
		//let arrow = " ▾";
		// let arrowUp = " ▴";
		let arrow = '';
		let arrowUp = '';
		let { attributes } = this.state;
		let title = this.props.mode == 'players' ? this.modeGetName() : this.getName(this.state.selected);

		/**
		 * select-menu klass options
		 * - select-menu (default)
		 * - dark select-menu ---- dark theme
		 */
		let klass = this.state.klass ? this.state.klass : 'select-menu';
		let imgSrc = this.state?.imgSrc;

		return (
			<div className={`${klass}`}>
				{/* {logger.log("[SelectMenu] render attributes:%o", this.state)} */}
				<button className={`title ${this.state.show ? 'active' : ''} `} onClick={() => this.showMenu()}>
					{imgSrc !== undefined ? <img src={imgSrc} /> : null}
					<span className="title-header">{title}</span>
					<span className="menu-arrow">{this.state.show ? `${arrowUp}` : `${arrow}`}</span>
				</button>
				<div className={`${klass}-dropdown ${this.state.show ? 'show' : ''}`}>
					{attributes.options.map(({ name, value, disabled }) => {
						//logger.log('[SelectMenu] display options map name:%o value:%o', name, value);
						if (value && value != 'section' && !disabled) {
							return (
								<button
									key={name}
									className={this.state.selected === value ? 'option clicked' : 'option'}
									onClick={e => this.setSelected(e, value, name)}
									onKeyDown={e => this.setSelected(e, value, name)}
									tabIndex={0}>
									<i className="icon-check" />
									<span
										className="option-name"
										onClick={this.props.mode == 'players' ? this.props?.scrollToListHandler : null}>
										{name}
									</span>
								</button>
							);
						} else if (disabled) {
							return (
								<button className="option disabled" key={name}>
									<span>{name}</span>
								</button>
							);
						} else {
							return (
								<div className="section" key={name}>
									{name}
								</div>
							);
						}
					})}
				</div>
			</div>
		);
	}
}

export default SelectMenu;
