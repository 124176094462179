/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import PlayerProfileButton from 'appdir/components/pages/Slamtracker/elements/PlayerProfileButton';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: HeadtoHead
 * -----------------------------------------------------------------------------
 */
const HeadtoHead = props => {
    // logger.log('[HeadtoHead] props:%o', props);

    const playerDetailData = useSelector((state) => state?.['Tournament']?.data?.playerDetail?.data ?? []);
    const headToHead = useSelector((state) => state?.['Slamtracker']?.headtohead ?? {});
    
    const getPlayerName = (player) => {
        let name = null;

        if(player?.first_name && player?.last_name) {
            name = player.first_name.charAt(0) + '. ' + player.last_name;
        }
        return name;
    }

    const getPlayerAge = (player) => {
        let age = null;

        if(player?.birth?.date) {
            let today = moment();
            let birthdate = moment(player.birth.date);

            // logger.log('[HeadtoHead] getPlayerAge today:%o, birthdate:%o', today, birthdate);

            age = today.diff(birthdate, 'years');
            // logger.log('[HeadtoHead] getPlayerAge age:%o', age);
        }
        return age;
    }

    const headtohead_rows = team => {
        let p1, p2;
        let p1_h2h = null;
        let p2_h2h = null;
        // logger.log('[HeadtoHead] playerDetailData:%o', playerDetailData);

        if(team == '1') {
            p1 = playerDetailData?.[0];
            p2 = playerDetailData?.[1];
        }
        if(team == '2') {
            p1 = playerDetailData?.[2];
            p2 = playerDetailData?.[3];
        }

        let p1_name = getPlayerName(p1);
        let p2_name = getPlayerName(p2);
        let p1_age = getPlayerAge(p1);
        let p2_age = getPlayerAge(p2);

        if(props?.playersHaveStats === true && headToHead?.player1Wins && headToHead?.player2Wins) {
            p1_h2h = `${headToHead.player1Wins} - ${headToHead.player2Wins}`;
            p2_h2h = `${headToHead.player2Wins} - ${headToHead.player1Wins}`;
        }

        return (
            <>
                <div className="stats-content-row header">
                    <div className="t1">{p1_name}</div>
                    <div className="t2">{p2_name}</div>
                </div>
                {(props.playersHaveStats === true && headToHead?.player1Wins && headToHead?.player2Wins) &&
                    <div className="headtohead-content-row">
                        <div className="t1">{headToHead?.player1Wins || '0'}</div>
                        <div className="label">CAREER H2H WINS</div>
                        <div className="t2">{headToHead?.player2Wins || '0'}</div>
                    </div>
                }
                <div className="headtohead-content-row">
                    <div className="t1">{p1?.nation?.country || ''}</div>
                    <div className="label">COUNTRY</div>
                    <div className="t2">{p2?.nation?.country || ''}</div>
                </div>
                <div className="headtohead-content-row">
                    <div className="t1">{p1_age || 'N/A'}</div>
                    <div className="label">AGE</div>
                    <div className="t2">{p2_age || 'N/A'}</div>
                </div>
                <div className="headtohead-content-row">
                    <div className="t1">{p1?.birth.date || 'N/A'}</div>
                    <div className="label">BIRTH DATE</div>
                    <div className="t2">{p2?.birth.date || 'N/A'}</div>
                </div>
                <div className="headtohead-content-row">
                    <div className="t1">{p1?.birth?.place || 'N/A'}</div>
                    <div className="label">BIRTH PLACE</div>
                    <div className="t2">{p2?.birth?.place || 'N/A'}</div>
                </div>
                <div className="headtohead-content-row">
                    <div className="t1">{`${p1?.height?.imperial || 'N/A'} (${p1?.height?.metric || 'N/A'})`}</div>
                    <div className="label">HEIGHT</div>
                    <div className="t2">{`${p2?.height?.imperial || 'N/A'} (${p2?.height?.metric || 'N/A'})`}</div>
                </div>
                <div className="headtohead-content-row">
                    <div className="t1">{p1?.plays || 'N/A'}</div>
                    <div className="label">PLAYS</div>
                    <div className="t2">{p2?.plays || 'N/A'}</div>
                </div>
                <div className="headtohead-content-row">
                    <div className="t1">{p1?.results?.career?.prize_money || 'N/A'}</div>
                    <div className="label">PRIZE MONEY</div>
                    <div className="t2">{p2?.results?.career?.prize_money || 'N/A'}</div>
                </div>
                <div className="headtohead-content-row">
                    <div className="t1 profile-button"><PlayerProfileButton playerId={p1?.id} /></div>
                    <div className="label"></div>
                    <div className="t2 profile-button"><PlayerProfileButton playerId={p2?.id} /></div>
                </div>
            </>
        )
    }
    
    return (
        <div className="headtohead">
            <div className={`headtohead-content${props.doubles === true ? ' doubles' : ''}`}>
                {/* team 1, player 1 & team 2, player 1 */}
                { headtohead_rows('1') } 

                {/* team 1, player 2 & team 2, player 2 */}
                {props.doubles === true ?
                    headtohead_rows('2')
                    : null
                }

                <div className="courtesy-of-text">
                    Profile data courtesy of{' '}
                    <EventsLink to="https://www.atptour.com/en/" external="true">
                        ATP
                    </EventsLink>
                    ,{' '}
                    <EventsLink to="https://www.wtatennis.com/" external="true">
                        WTA
                    </EventsLink>{' '}
                    &{' '}
                    <EventsLink to="https://www.itftennis.com/en/" external="true">
                        ITF
                    </EventsLink>
                </div>
            </div>
        </div>
    )
};

export default HeadtoHead;