import LiveScores from './index';
import deps from 'dependencies';

export default {
	path: [
		'/:lang(en_US)/scores/index.html',
		'/:lang(en_US)/scores/',
		'/:lang(en_US)/scores',
	],
	exact: true,
	component: LiveScores,
	load: params => deps.actions.LiveScores.mount(params),
};
