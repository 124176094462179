import PastChamps from './index';
import deps from 'dependencies';

export default {
	path: [
		'/:lang(en_US)/visit/history/pastchampions.html',
		'/:lang(en_US)/visit/history/:event(ms|ws|md|wd|xd|jr|jrb|jrg|cs|ds|cd|dd|sq|dq)champs.html',
	],
	exact: true,
	component: PastChamps,
	load: params => deps.actions.PastChamps.mount(params),
};
