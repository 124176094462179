import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.LIVEVIDEOPLAYER_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.LIVEVIDEOPLAYER_UNMOUNT:
			newState = {
				...state,
				...action.data,
				playing: [],
			};
			return newState;

		case deps.actionTypes.LIVEVIDEOPLAYER_CURRENTLY_PLAYING:
			logger.log('[LiveVideoPlayer] LIVEVIDEOPLAYER_CURRENTLY_PLAYING %o', action);
			newState = {
				...state,
				playing: [...state.playing, action.data],
			};
			return newState;

		default:
			return state;
	}
};
