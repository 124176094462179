/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import StubBox from 'appdir/components/common-ui/StubBox';
import TemplateGeneric from 'components/TemplateGeneric';
import MeasurementUtils from 'appdir/lib/analytics';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import EventsLink from 'components/general/EventsLink';
import SocialEmbed from 'appdir/components/content/SocialEmbed';
import MatchInsights from 'appdir/components/cms/MatchInsights';
import ScoresBox from 'appdir/components/cms/ScoresBox';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import find from 'lodash/find';
import Video from 'appdir/components/content/Video';
import DaySelect from './DaySelect';
import axios from 'axios';
import PowerRankingBlog from 'appdir/components/cms/PowerRankingBlog';
import { fetchAll, getAppVideoLink } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveAtUSOpenWebview
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['LiveAtUSOpenWebview'],
		stubs: state['Config'].stubPages,
		miMatches: state['Controller'].miMatches,
		currentDay: state['ActiveData']['currentDay'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveAtUSOpenWebview.mount()),
	unmount: () => dispatch(deps.actions.LiveAtUSOpenWebview.unmount()),
	setDay: settings => dispatch(deps.actions.LiveAtUSOpen.setDay(settings)),
});

class LiveAtUSOpenWebview extends Component {
	constructor(props) {
		super(props);
		let filters = props.filters;

		this.state = {
			blogData: [],
			show_more: [],
			filters: filters,
			showModal: false,
			...props,
		};

		this.init = true;
		this.blog_init = true;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Live At The US Open',
		});
		//logger.log('[LiveAtUSOpenWebview] constructor - props:%o', props);
	}

	componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		logger.log('[LiveAtUSOpenWebview] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
	}

	componentDidMount() {
		logger.log('[LiveAtUSOpenWebview] componentDidMount');
		// this.props.mount();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[LiveAtUSOpenWebview] componentDidUpdate - state:%o, props: %o', this.state, this.props);

		if (prevProps.status !== this.props.status && this.props.status == 'load') {
			if (this.init) {
				this.init = false;
				this.getBlogComments();
			}
		}

		if (prevState.filters !== this.state.filters) {
			this.getBlogComments();
		}
	}

	getMode(day) {
		let mode = '';
		this.state.tourn_days.eventDays.forEach(item => {
			if (item.tournDay == day) {
				mode = item.quals ? 'quali' : 'tourn';
			}
		});
		return mode;
	}

	getBlogComments() {
		if (this.blog_init) {
			this.blog_init = false;
			let blogList = [];
			let showDay = '';

			if (this.state.filters.day !== 'current') {
				showDay = 'day' + this.state.filters.day;
			} else {
				showDay = 'current';
			}

			let blogUrl = this.props.liveBlogPath.replace('<day>', showDay); //temp for testing day selector
			let daysUrl = this.state.daysPath;

			let pathsObj = [blogUrl, daysUrl];

			fetchAll(pathsObj)
				.then(
					axios.spread((blogResults, daysResults) => {
						this.blog_init = true;
						if (!isEqual(this.state.blogData.content, blogResults.content)) {
							blogResults.content.map(item => {
								let temp = {};
								temp.blogId = item.date;
								temp.active = false;
								blogList.push(temp);
							});

							this.setState({
								blogData: blogResults,
								show_more: blogList,
								tourn_days: daysResults,
							});
						}

						if (this.blog_timeout) {
							clearTimeout(this.blog_timeout);
						}

						this.blog_timeout = setTimeout(() => {
							this.blog_init = true;
							this.getBlogComments();
						}, 30000);
					})
				)
				.catch(error => {
					logger.log('[LiveAtUSOpenWebview] getBlogComments error:%o', error);
					this.setState({
						hasError: true,
						errorType: error,
					});
				});
		}
	}

	showMore(checkId) {
		logger.log('[LiveAtUSOpenWebview] showMore - blogId:%o, blogList:%o', checkId, this.state.show_more);
		let showItems = this.state.show_more;
		let newList = [];

		showItems.map(item => {
			let temp = {};

			temp.blogId = item.blogId;

			//change show more status
			if (item.blogId === checkId) {
				temp.active = item.active ? false : true;
			} else {
				temp.active = item.active;
			}

			newList.push(temp);
		});

		this.setState({
			show_more: newList,
		});
	}

	renderBlog(blog) {
		// logger.log('[LiveAtUSOpenWebview] renderBlog blog comment:%o', blog);
		let blogClass = !isEmpty(blog.icon) ? blog.icon : 'news';

		return (
			<div className="blogInsert" key={blog.type + blog.date}>
				<div className="commentRow">
					<div className="commentIcon">
						<div className="iconType">
							<i className={`icon-blogIcon-${blogClass}`} />
						</div>
					</div>
					{this.renderBody(blog)}
				</div>
				<div className="footerRow">
					<div className="commentIcon" />
					<div className="time">{moment(blog.date).fromNow()}</div>
				</div>
			</div>
		);
	}

	renderBody(blog) {
		// logger.log('[LiveAtUSOpen] renderBody blog comment:%o', blog);
		let activeInfo = find(this.state.show_more, ['blogId', blog.date]);
		let activeClass = activeInfo.active ? 'active' : '';

		switch (blog.type) {
			case 'social':
				let socialProps = {visible: true };

				switch (blog.platform) {
					case 'facebook':
						socialProps = {
							...socialProps,
							platform: blog.platform,
							postURL: blog.socialURL,
						};
						break;

					case 'instagram':
						socialProps = {
							...socialProps,
							platform: blog.platform,
							instagramId: blog.socialURL,
						};
						break;

					case 'twitter':
						socialProps = {
							...socialProps,
							platform: blog.platform,
							tweetId: blog.socialId,
						};
						break;

					case 'youtube':
						socialProps = {
							...socialProps,
							platform: blog.platform,
							youTubeVideoId: blog.socialId,
						};
						break;
					default:
						logger.log('[LiveAtUSOpen] renderBody social not found');
						break;
				}

				return (
					<div className={`comment ${blog.platform}`}>
						{!isEmpty(blog.header) ? <div className="title">{blog.header}</div> : <div />}
						<div className="body">
							<div className={`desc ${activeClass}`}>
								<SocialEmbed data={socialProps} />
							</div>
						</div>
					</div>
				);

			case 'video':
				// let video_attributes = {
				// 	playerId: 'main',
				// 	contentId: blog.id,
				// 	videoUrl: null,
				// 	title: null,
				// 	thumb: blog.image.small,
				// 	autoplay: true,
				// 	fullscreenMode: '',
				// 	style: `video-wrapper one-col modal header`,
				// 	aspect: 'wide',
				// 	url: null,
				// };
				// logger.log('[LiveAtUSOpen] renderBody - video type - video attributes:%o', video_attributes);
				return (
					<div className="comment">
						{!isEmpty(blog.header) ? (
							// <EventsLink to={blog.link}>
								<div className="title video-title" onClick={()=> {this.showVideo(blog?.video)}}>{blog.header}</div>
							// </EventsLink>
						) : (
							<div />
						)}
						<div className="body">
							<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
							<div className="video media-16x9">
								<div className="photo video-image" onClick={()=> {this.showVideo(blog?.video)}}>
									<img src={blog?.video?.poster} />
									<div className="playBtn">&nbsp;</div> 
								</div>
							</div>
							{!isEmpty(blog.title) ? <div className="caption">{ReactHtmlParser(blog.title)}</div> : null}
						</div>
					</div>
				);
			
			{/* MD - Innovation 2024 - Decommision Power Index */}
			{/* 
			case 'power_ranking':
				// logger.log('[LiveAtUSOpen] renderBody - power index id:%o', blog.id);
				return (
					<div className="comment pr">
						{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
						<PowerRankingBlog matchId={blog.id} likelihood={false} />
					</div>
				);

			case 'power_ranking_ltw':
				// logger.log('[LiveAtUSOpen] renderBody - power index LTW id:%o', blog.id);
				return (
					<div className="comment pr_ltw">
						{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
						<PowerRankingBlog matchId={blog.id} likelihood={true} />
					</div>
				);
			*/}

			case 'insight':
				return (
					<div className="comment insight">
						{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
						<div className="body">
							<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
							{this.props.miMatches ? (
								<div>
									{this.props.miMatches.indexOf(blog.id) > -1 ? (
										<MatchInsights matchId={blog.id} />
									) : null}
								</div>
							) : null}
						</div>
					</div>
				);
				
			case 'scores':
				return (
					<div className="comment matchbox">
						{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
						<div className="body">
							<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
							<div>
								<ScoresBox matchId={blog.id} game={blog.game} />
							</div>
						</div>
					</div>
				);
				
			default:
				return (
					<div className="comment">
						{!isEmpty(blog.header) ? (
							<EventsLink
								to={blog.type === 'match' ? `/en_US/scores/stats/${blog.id}.html` : blog.link}
								external={true}>
								<div className="title">{blog.header}</div>
							</EventsLink>
						) : (
							<div />
						)}
						<div className="body">
							<EventsLink
								to={blog.type === 'match' ? `/en_US/scores/stats/${blog.id}.html` : blog.link}
								external={true}>
								<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
								{!isEmpty(blog.image.medium) ? (
									<div className="photo">
										<img src={blog.image.medium} />
									</div>
								) : null}
								{!isEmpty(blog.title) ? (
									<div className="caption">{ReactHtmlParser(blog.title)}</div>
								) : null}
							</EventsLink>
							{/* <div
								className="showMore"
								onClick={() => {
									this.showMore(blog.date);
								}}>
								Show More
							</div> */}
						</div>
					</div>
				);
				
		}
	}

	showVideo(data) {
		// logger.log('[LiveAtUSOpenWebview] showVideoModal');
		const videoData = { 
			video: {
				id: data?.id,
				title: data?.title,
				shareUrl: data?.shareUrl,
				poster: data?.poster
			},
			autoplay:'true'
		};

		let videoLink = getAppVideoLink(data?.id);
        videoLink?.onclick();
	}

	render() {
		logger.log('[LiveAtUSOpenWebview] render state:%o', this.state);

		// if (this.state.scheduleData && this.state.selectedDay) {
		// let dayDetails = this.state.scheduleData.days;
		// let selectedDayID = this.state.selectedDay;
		// let dayIndex = findIndex(dayDetails, { id: selectedDayID });

		// if you are creating a header like scores which is transparent, make sure you update the
		// header component and make a new style for the new header.
		let header_attributes = {
			headerType: 'visit',
			title: 'Live At The US Open',
			metaTitle: 'Live At The US Open',
			metaDescription: 'The 2020 US Open begins August 31st to September 13th.',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			title: 'Live At The US Open',
		};

		let hideAd = true;

		if (this.state.stubs && this.state.stubs.live_at_us_open && this.state.stubs.live_at_us_open.stub === 'stub') {
			return (
				<TemplateGeneric header={header_attributes} subHeader={subheader_attributes}>
					<StubBox
						attributes={{
							title: header_attributes.title,
							message: this.state.stubs.live_at_us_open.text,
							basePicPath: this.state.basePicPath,
						}}
					/>
				</TemplateGeneric>
			);
		} else {
			return (
				<TemplateGeneric hideAd={hideAd} header={header_attributes} subHeader={subheader_attributes}>
					<ErrorBoundary message="Live blog is not available at this time.">
						{!this.state.hasError ? (
							<section className="live-blog webview">
								{!isEmpty(this.state.blogData) ? (
									<div className="wrapper">
										<div className="pageTitle">{this.state.blogData.title}</div>
										<div className="schedulefilter">
											<div className={`daynav-wrapper ${this.preview_mode ? 'disable' : ''}`}>
												{this.state.currentDay !== undefined ? (
													<DaySelect
														days={this.state.tourn_days.eventDays}
														filters={this.state.filters}
														default={this.state.currentDay.schedule}
														mode={this.getMode(
															this.state.tournDay
																? this.state.tournDay
																: this.state.currentDay.schedule
														)}
													/>
												) : null}
											</div>
										</div>

										<div className="content">
											<div className="blog">
												{this.state.blogData.content.map((blog, i) => {
													return this.renderBlog(blog);
												})}
											</div>
										</div>
									</div>
								) : null}
							</section>
						) : (
							<GenericError message="The live blog is unavailable at this time." />
						)}
					</ErrorBoundary>
				</TemplateGeneric>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAtUSOpenWebview);
