import React, { Component } from 'react';
import Template from 'components/Template';

class AnyclipTest extends Component {
	constructor(props) {
		super(props);

		this.script = document.createElement('script');
		this.script.async = true;
		this.script.src = 'https://player.anyclip.com/anyclip-widget/lre-widget/prod/v1/src/lre.js';
		this.script.setAttribute('pubname', '2152');
		this.script.setAttribute('data-tm-videoguids', '["ashe16934516102275337"]');
		this.script.setAttribute('widgetname', '0016M00002Tye8hQAB_M10008');

		logger.log('[AnyclipTest] constructor - this.script:%o', this.script);
	}

	componentDidMount() {
		logger.log('[AnyclipTest] componentDidMount');

		document.getElementById('anyclip-container').appendChild(this.script);
	}

	render() {
		logger.log('[AnyclipTest] - render this:%o', this);
		let header_propsData = {
			headerType: 'home',
			metaDescription:
				'The official site of the 2024 US Open Tennis Championships. Follow the action with live scoring, live radio, news and highlights. Buy tickets and plan your visit.',
			canonicalLink: 'https://www.usopen.org/index.html',
		};

		return (
			<Template header={header_propsData} subHeader={false}>
				<section className="page-content">
					<h1>AnyClip Test Page</h1>
					<p>Staging - Ashe (example has calling on GUID)</p>

					<div id="anyclip-container"></div>
				</section>
			</Template>
		);
	}
}
export default AnyclipTest;
