/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: SiteMap
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SiteMap'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SiteMap.mount()),
});

class SiteMap extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.mount();
	}

	render() {
		logger.log('[SiteMap] render - menus:%o', this.props.menus);

		if (this.props.menus) {
			return (
				<div className="sitemap">
					<ul>
						{this.props.menus.map(menu => {
							if ('link' in menu) {
								return (
									<li key={menu.title}>
										<EventsLink to={menu.link}>{menu.title}</EventsLink>
									</li>
								);
							}

							if ('links' in menu) {
								return (
									<li key={menu.title}>
										{menu.title}
										<ul>
											{menu.links.map(link => (
												<li key={link.title}>
													<EventsLink to={link.link}>{link.title}</EventsLink>
												</li>
											))}
										</ul>
									</li>
								);
							}
						})}
						<li>
							<a
								href="http://shop.wimbledon.com/stores/wimbledon/en?portal=WDS8H1I4&amp;CMP=PSC-WDS8H1I4"
								target="_blank">
								Shop
							</a>
						</li>
					</ul>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMap);
