import AppLanding from './AppLanding';
import IosLanding from './IosLanding';
import AndroidLanding from './AndroidLanding';

export default [
	{
		path: '/:lang(en_US)/interactive/mobile/:category(index).html',
		exact: true,
		component: AppLanding,
	},
	{
		path: '/:lang(en_US)/interactive/mobile/',
		exact: true,
		component: AppLanding,
	},
	{
		path: '/:lang(en_US)/interactive/mobile/:category(ios).html',
		exact: true,
		component: AppLanding,
	},
	{
		path: '/:lang(en_US)/interactive/mobile/:category(android).html',
		exact: true,
		component: AppLanding,
	},
];
