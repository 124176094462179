/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
/**
 * -----------------------------------------------------------------------------
 * React Component: Tournament
 * -----------------------------------------------------------------------------
 */

export default class Tournament extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		//logger.log('[Tournament] constructor - props:%o', props);
	}

	componentDidMount() {
		this.props.mount();
	}

	componentDidUpdate(prevState) {
		//logger.log('[Tournament] componentDidUpdate - state:%o', this.state);
	}

	render() {
		return null;
	}
}
