/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import filter from 'lodash/filter';

import Translate from 'appdir/components/pages/Slamtracker/Translate';
import { getServeTeam } from 'appdir/components/pages/Slamtracker/Utilities';
import Slider from 'react-slick';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ServeSpeed
 * -----------------------------------------------------------------------------
 */

const ServeSpeed = props => {
	// logger.log('[StageItems | ServeSpeed] props: %o',props);

  let { data } = props;
  let { currentServer } = data;

	const [isToggled, setToggle] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const handleToggle = () => {
		setToggle(!isToggled);
		return [isToggled, handleToggle];
	};

	const getServeSpeedData = (setNo, pointNumber, unit) => {
		try {
			let p1Data;
			let p2Data;

			if (unit == 'MPH') {
				if (setNo == 0) {
					p1Data = filter(props.data.pointHistory, function (e) {
						return (
							getServeTeam(e.PointServer) == '1' &&
							parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) &&
							parseInt(e.Speed_MPH) > 0
						);
					});
					p2Data = filter(props.data.pointHistory, function (e) {
						return (
							getServeTeam(e.PointServer) == '2' &&
							parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) &&
							parseInt(e.Speed_MPH) > 0
						);
					});
				} else {
					p1Data = filter(props.data.pointHistory, function (e) {
						return (
							getServeTeam(e.PointServer) == '1' &&
							parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) &&
							e.SetNo == setNo &&
							parseInt(e.Speed_MPH) > 0
						);
					});
					p2Data = filter(props.data.pointHistory, function (e) {
						return (
							getServeTeam(e.PointServer) == '2' &&
							parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) &&
							e.SetNo == setNo &&
							parseInt(e.Speed_MPH) > 0
						);
					});
				}
			} else if (unit == 'KMH') {
				if (setNo == 0) {
					p1Data = filter(props.data.pointHistory, function (e) {
						return (
							getServeTeam(e.PointServer) == '1' &&
							parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) &&
							parseInt(e.Speed_KMH) > 0
						);
					});
					p2Data = filter(props.data.pointHistory, function (e) {
						return (
							getServeTeam(e.PointServer) == '2' &&
							parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) &&
							parseInt(e.Speed_KMH) > 0
						);
					});
				} else {
					p1Data = filter(props.data.pointHistory, function (e) {
						return (
							getServeTeam(e.PointServer) == '1' &&
							parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) &&
							e.SetNo == setNo &&
							parseInt(e.Speed_KMH) > 0
						);
					});
					p2Data = filter(props.data.pointHistory, function (e) {
						return (
							getServeTeam(e.PointServer) == '2' &&
							parseInt(e.PointNumber, 10) <= parseInt(pointNumber, 10) &&
							e.SetNo == setNo &&
							parseInt(e.Speed_KMH) > 0
						);
					});
				}
			}

			let p1Total = 0;
			let p2Total = 0;
			let p1Avg = 0;
			let p2Avg = 0;
			let p1Fastest = 0;
			let p2Fastest = 0;
			let p1Last = 0;
			let p2Last = 0;
			let i, j;

			// total all the serve speeds.  used to get the average
			// find the fastest serve speed
			// set the latest serve speed

			// p1
			for (i = 0; i < p1Data.length; i++) {
				if (p1Data[i]) {
					let p1Speed = unit == 'MPH' ? parseInt(p1Data[i].Speed_MPH, 10) : parseInt(p1Data[i].Speed_KMH);
					p1Total += p1Speed;

					// get the fastest
					if (p1Speed > p1Fastest) {
						p1Fastest = p1Speed;
					}

					// set the latest
					p1Last = p1Speed;
				}
			}

			// p2
			for (j = 0; j < p2Data.length; j++) {
				if (p2Data[j]) {
					let p2Speed = unit == 'MPH' ? parseInt(p2Data[j].Speed_MPH, 10) : parseInt(p2Data[j].Speed_KMH);
					p2Total += p2Speed;

					// get the fastest
					if (p2Speed > p2Fastest) {
						p2Fastest = p2Speed;
					}

					// set the latest
					p2Last = p2Speed;
				}
			}

			// get the average for p2
			if (p1Data.length > 0) {
				p1Avg = p1Total / p1Data.length;
			}

			// get the average for p2
			if (p2Data.length > 0) {
				p2Avg = p2Total / p2Data.length;
			}

			let avg = new Array(parseInt(p1Avg.toFixed(), 10), parseInt(p2Avg.toFixed(), 10));
			let max = new Array(p1Fastest, p2Fastest);
			let last = new Array(p1Last, p2Last);

			return new Array(avg, max, last);
		} catch (e) {
			//traceError('getServeSpeeds: Error - '+e);
		}
	};

	let serve_speed_data_KMH = getServeSpeedData(0, props.data.point, 'KMH');
	let serve_speed_data_MPH = getServeSpeedData(0, props.data.point, 'MPH');

	const getPlayerImage = pid => {
		let playerImagePath = props.data.image_url.replace('<playerid>', pid);

		return playerImagePath;
	};

	const getStage1 = () => {
		let p1_img_path = getPlayerImage(props.data.teams[0].players[0].tourId);
		let p2_img_path = getPlayerImage(props.data.teams[1].players[0].tourId);

		return (
			<div className="item stage1">
				<div className="team-container team-one">
					<div className="heading-wrapper">
						<div className="heading">
							<Translate originalText="average_serve_speed" />
						</div>
					</div>
					<div className={`team1`}>
						<div className="player-data">
							<div className="name">{props.data.teams[0].players[0].name}</div>
							<div className="player-image" style={{ backgroundImage: `url(${p1_img_path})` }} />
							<div className="serve-speed-wrapper">
								<div className="serve-speed-value imperial">
									{serve_speed_data_MPH[0][0]} <Translate originalText="mph" />
								</div>
								<div className="serve-speed-value metric">
									{serve_speed_data_KMH[0][0]} <Translate originalText="kmh" />
								</div>
							</div>
							<div className="si-row">
								<div className="si-cell category last">
									<Translate originalText="last_serve" />
								</div>
								<div clss="si-cell">
									<div className="value last imperial">
										{serve_speed_data_MPH[2][0]} <Translate originalText="mph" />
									</div>
									<div className="value last metric">
										{serve_speed_data_KMH[2][0]} <Translate originalText="kmh" />
									</div>
								</div>
							</div>
							<div className="si-row">
								<div className="si-cell category fastest">
									<Translate originalText="fastest_serve" />
								</div>
								<div className="si-cell">
									<div className="value fastest imperial">
										{serve_speed_data_MPH[1][0]} <Translate originalText="mph" />
									</div>
									<div className="value fastest metric">
										{serve_speed_data_KMH[1][0]} <Translate originalText="kmh" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="team-container team-two">
					<div className="heading-wrapper">
						<div className="heading">
							<Translate originalText="average_serve_speed" />
						</div>
					</div>
					<div className={`team2`}>
						<div className="player-data">
							<div className="name">{props.data.teams[1].players[0].name}</div>
							<div className="player-image" style={{ backgroundImage: `url(${p2_img_path})` }} />
							<div className="serve-speed-wrapper">
								<div className="serve-speed-value imperial">
									{serve_speed_data_MPH[0][1]} <Translate originalText="mph" />
								</div>
								<div className="serve-speed-value metric">
									{serve_speed_data_KMH[0][1]} <Translate originalText="kmh" />
								</div>
							</div>

							<div className="si-row">
								<div className="si-cell category last">
									<Translate originalText="last_serve" />
								</div>
								<div clss="si-cell">
									<div className="value last imperial">
										{serve_speed_data_MPH[2][1]} <Translate originalText="mph" />
									</div>
									<div className="value last metric">
										{serve_speed_data_KMH[2][1]} <Translate originalText="kmh" />
									</div>
								</div>
							</div>
							<div className="si-row">
								<div className="si-cell category fastest">
									<Translate originalText="fastest_serve" />
								</div>
								<div className="si-cell">
									<div className="value fastest imperial">
										{serve_speed_data_MPH[1][1]} <Translate originalText="mph" />
									</div>
									<div className="value fastest metric">
										{serve_speed_data_KMH[1][1]} <Translate originalText="kmh" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const getStage2 = () => {
		return (
			<div className="item stage2">
				<div
					className={`si-table player-info-wrapper team1 ${
						serve_speed_data_MPH[0][0] > serve_speed_data_MPH[0][1] ? 'winning' : ''
					}`}>
					<div className="si-caption player">{props.data.teams[0].players[0].name}</div>
					<div className="si-row">
						<div className="si-cell category fastest">
							<Translate originalText="fastest_serve" />
						</div>
						<div className="si-cell">
							<div className="value fastest imperial">
								{serve_speed_data_MPH[1][0]} <Translate originalText="mph" />
							</div>
							<div className="value fastest metric">
								{serve_speed_data_KMH[1][0]} <Translate originalText="kmh" />
							</div>
						</div>
					</div>
					<div className="si-row">
						<div className="si-cell category last">
							<Translate originalText="last_serve" />
						</div>
						<div clss="si-cell">
							<div className="value last imperial">
								{serve_speed_data_MPH[2][0]} <Translate originalText="mph" />
							</div>
							<div className="value last metric">
								{serve_speed_data_KMH[2][0]} <Translate originalText="kmh" />
							</div>
						</div>
					</div>
				</div>

				<div
					className={`si-table player-info-wrapper team2 ${
						serve_speed_data_MPH[0][1] > serve_speed_data_MPH[0][0] ? 'winning' : ''
					}`}>
					<div className="si-caption player">{props.data.teams[1].players[0].name}</div>
					<div className="si-row">
						<div className="si-cell category fastest">
							<Translate originalText="fastest_serve" />
						</div>
						<div className="si-cell">
							<div className="value fastest imperial">
								{serve_speed_data_MPH[1][1]} <Translate originalText="mph" />
							</div>
							<div className="value fastest metric">
								{serve_speed_data_KMH[1][1]} <Translate originalText="kmh" />
							</div>
						</div>
					</div>
					<div className="si-row">
						<div className="si-cell category last">
							<Translate originalText="last_serve" />
						</div>
						<div clss="si-cell">
							<div className="value last imperial">
								{serve_speed_data_MPH[2][1]} <Translate originalText="mph" />
							</div>
							<div className="value last metric">
								{serve_speed_data_KMH[2][1]} <Translate originalText="kmh" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const getTeamOne = () => {
		let p1_img_path = getPlayerImage(props.data.teams[0].players[0].tourId);

		return (
			<div className="item stage1">
				<div className="team-container team-one">
					<div className="heading-wrapper">
						<div className="heading">
							<Translate originalText="average_serve_speed" />
						</div>
					</div>
					<div className={`team1`}>
						<div className="player-data">
							<div className="name">
								<span>{props.data.teams[0].players[0].name}</span>
							</div>
							<div className="img-serve">
								<div className="player-image" style={{ backgroundImage: `url(${p1_img_path})` }} />
								<div className="serve-speed-wrapper">
									<div className="serve-speed-value imperial">
										{serve_speed_data_MPH[0][0]} <Translate originalText="mph" />
									</div>
									<div className="serve-speed-value metric">
										{serve_speed_data_KMH[0][0]} <Translate originalText="kmh" />
									</div>
								</div>
							</div>

							<div className="si-row">
								<div className="serve-stat">
									<div className="si-cell category last">
										<Translate originalText="last_serve" />
									</div>
									<div clss="si-cell-last">
										<div className="value last imperial">
											{serve_speed_data_MPH[2][0]} <Translate originalText="mph" />
										</div>
										<div className="value last metric">
											{serve_speed_data_KMH[2][0]} <Translate originalText="kmh" />
										</div>
									</div>
								</div>
							</div>
							<div className="si-row">
								<div className="serve-stat">
									<div className="si-cell category fastest">
										<Translate originalText="fastest_serve" />
									</div>
									<div className="si-cell-fastest">
										<div className="value fastest imperial">
											{serve_speed_data_MPH[1][0]} <Translate originalText="mph" />
										</div>
										<div className="value fastest metric">
											{serve_speed_data_KMH[1][0]} <Translate originalText="kmh" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const getTeamTwo = () => {
		let p2_img_path = getPlayerImage(props.data.teams[1].players[0].tourId);

		return (
			<div className="item stage1">
				<div className="team-container team-two">
					<div className="heading-wrapper">
						<div className="heading">
							<Translate originalText="average_serve_speed" />
						</div>
					</div>
					<div className={`team2`}>
						<div className="player-data">
							<div className="name">
								<span>{props.data.teams[1].players[0].name}</span>
							</div>
							<div className="img-serve">
								<div className="player-image" style={{ backgroundImage: `url(${p2_img_path})` }} />
								<div className="serve-speed-wrapper">
									<div className="serve-speed-value imperial">
										{serve_speed_data_MPH[0][1]} <Translate originalText="mph" />
									</div>
									<div className="serve-speed-value metric">
										{serve_speed_data_KMH[0][1]} <Translate originalText="kmh" />
									</div>
								</div>
							</div>
							<div className="si-row">
								<div className="serve-stat">
									<div className="si-cell category last">
										<Translate originalText="last_serve" />
									</div>
									<div clss="si-cell-last">
										<div className="value last imperial">
											{serve_speed_data_MPH[2][1]} <Translate originalText="mph" />
										</div>
										<div className="value last metric">
											{serve_speed_data_KMH[2][1]} <Translate originalText="kmh" />
										</div>
									</div>
								</div>
							</div>
							<div className="si-row">
								<div className="serve-stat">
									<div className="si-cell category fastest">
										<Translate originalText="fastest_serve" />
									</div>
									<div className="si-cell-fastest">
										<div className="value fastest imperial">
											{serve_speed_data_MPH[1][1]} <Translate originalText="mph" />
										</div>
										<div className="value fastest metric">
											{serve_speed_data_KMH[1][1]} <Translate originalText="kmh" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

  const getCurrentServer = () => {
    let serving;
    if(!currentServer || (currentServer == 'A' || currentServer == 'B')) {
      serving = 0;
    } else if ((currentServer == 'C' || currentServer == 'D')) {
      serving = 1
    }

    return serving;
  }
	// logger.log('[StageItems | ServeSpeed] currentServer:%o, getCurrentServer:%o', currentServer, getCurrentServer());

	var sliderSettings = {
		dots: true,
		arrows: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		variableWidth: false,
		className: 'stageItemSlide',
    adaptiveHeight: true,
    initialSlide: getCurrentServer(),
	};

	const showPlayer = () => {
		<button className={isActive ? 'active' : ''} aria-pressed={isActive} onClick={toggleActive}>
			Toggle Status
		</button>;
	};

	if (props.stage_location == 'slamtracker') {
		return (
			<div className="stage-item serve-speed si-wrapper">
				<div className="si-inner-wrapper">
					<Slider {...sliderSettings}>
            <div>{getTeamOne()}</div>
            <div>{getTeamTwo()}</div>
					</Slider>
				</div>
			</div>
		);
	} else {
		return null;
	}

	// else {
	// 	return (
	// 		<div className="embedded-stage-item">
	// 			<div className="stage-item serve-speed si-wrapper small">
	// 				<div className="heading-wrapper">
	// 					<div className="heading">
	// 						<Translate originalText="average_serve_speed" />
	// 					</div>
	// 				</div>
	// 				<div className="stage-container">
	// 					<div className="si-inner-wrapper">
	// 						<Slider {...sliderSettings}>
	// 							{getStage1()}
	// 							{getStage2()}
	// 						</Slider>
	// 					</div>
	// 				</div>
	// 			</div>
	// 			<div className="stage-item serve-speed si-wrapper large">
	// 				<div className="heading-wrapper">
	// 					<div className="heading">
	// 						<Translate originalText="average_serve_speed" />
	// 					</div>
	// 				</div>
	// 				<div className="stage-container">
	// 					<div className="si-inner-wrapper">
	// 						{getStage1()}
	// 						{getStage2()}
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	);
	// }
};

export default ServeSpeed;
