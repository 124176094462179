import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	//logger.log('[HaveYourSay] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.HAVEYOURSAY_LOAD:
			// logger.log('[HaveYourSay] reducers - action:%o',action);

			newState = {
				...state,
				status: 'loaded',
				...action.data,
			};
			// logger.log('[HaveYourSay] reducers HAVEYOURSAY_LOAD - newState:%o',newState);
			return newState;
			break;
		case deps.actionTypes.HAVEYOURSAY_VOTE:
			logger.log('[HaveYourSay] reducers - HAVEYOURSAY_VOTE action:%o', action);
			newState = {
				...state,
				voted: {
					...state.voted,
					...action.data.voted,
				},
			};
			logger.log('[HaveYourSay] deps.actionTypes.HAVEYOURSAY_VOTE - newState:%o', newState);
			return newState;
			break;
		case deps.actionTypes.HAVEYOURSAY_UNMOUNT:
			newState = {
				...state,
				...action.data,
				status: null,
			};

			// logger.log('[HaveYourSay] ORDEROFPLAY_UNMOUNT newState:%o', newState);
			return newState;
			break;

		default:
			return state;
	}
};
