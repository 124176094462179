/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * React Component: OverlayContent
 * -----------------------------------------------------------------------------
 */
export default class OverlayContent extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		logger.log('[OverlayContent] componentDidMount');
		if (this.state.data) {
			logger.log('[OverlayContent] componentDidMount script %o', this.state.data);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[OverlayContent] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[OverlayContent] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	renderMedia() {
		let media = [];
		if (this.state.data.media) {
			media = this.state.data.media;
		}

		if (media.length > 0) {
			media.map((item, i) => {});
		}
	}

	renderRTF() {}

	renderImage() {}

	renderButtons() {
		logger.log('[OverlayContent] renderButtons - state:%o', this.state);
		let buttons = [];

		if (this.state.data.buttons && Array.isArray(this.state.data.buttons.button)) {
			buttons = this.state.data.buttons.button;
		} else if (this.state.data.buttons) {
			buttons.push(this.state.data.buttons.button);
		}

		if (buttons.length > 0) {
			return (
				<div className="buttons">
					{buttons.map((item, i) => {
						if (item.link && item.link != '') {
							return (
								<EventsLink to={item.link}>
									<div className={item.style} key={i}>
										{item.title}
									</div>
								</EventsLink>
							);
						} else {
							return (
								<div className={item.style} key={i}>
									{item.title}
								</div>
							);
						}
					})}
				</div>
			);
		} else {
			return null;
		}
	}

	renderLogos() {
		logger.log('[OverlayContent] renderLogos - state:%o', this.state);
		let images = [];
		if (this.state.data.images && Array.isArray(this.state.data.images.image)) {
			images = this.state.data.images.image;
		} else if (this.state.data.images) {
			images.push(this.state.data.images.image);
		}
		if (images.length > 0) {
			return (
				<div className="logos">
					{images.map((item, i) => (
						<img key={i} src={item.url} alt={item.caption} />
					))}
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		logger.log('[OverlayContent] render - state:%o', this.state);
		if (this.state.data) {
			let type = '';
			let section2Style = {};
			let sectionStyle = {};
			if (this.state.data.background) {
				sectionStyle = {
					backgroundImage: `url(${this.state.data.background.image['url']})`,
				};
			}
			if (this.state.data.style == 'image-overlay') {
				type = 'section1';
			} else if (this.state.data.style == 'image-overlay-dark') {
				type = 'section1 dark';
			} else if (this.state.data.style == 'full-background') {
				type = 'section2 section-bg';
				section2Style = sectionStyle;
				sectionStyle = {};
			} else if (this.state.data.style == 'full-background-dark') {
				type = 'section2 section-bg dark';
				section2Style = sectionStyle;
				sectionStyle = {};
			} else if (this.state.data.style == 'overlay-background') {
				type = 'section4';
			} else if (this.state.data.style == 'overlay-background-dark') {
				type = 'section4 dark';
			} else if (this.state.data.style == 'full-background-buttons') {
				type = 'section5';
			} else if (this.state.data.style == 'full-background-buttons-dark') {
				type = 'section5 dark';
			}

			return this.state.data ? (
				<div className={`amex-content ${type}`} style={section2Style}>
					<div className="image-bg" style={sectionStyle}>
						<div className="caption">
							<h2>{this.state.data.title}</h2>
							<div className="text">{ReactHtmlParser(this.state.data.description)}</div>
							{type.indexOf('section5') < 0 ? this.renderLogos() : null}
							{this.renderButtons()}
							{type.indexOf('section5') === 0 ? this.renderLogos() : null}
						</div>
					</div>
				</div>
			) : null;
		} else {
			return null;
		}
	}
}
