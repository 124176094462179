/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getComponents, getDeviceDetails } from 'appdir/components/general/Util';
import React, { Component } from 'react';
import { parseString } from 'xml2js';
import endsWith from 'lodash/endsWith';
import MeasurementUtils from 'appdir/lib/analytics';
import { fetch } from 'appdir/components/general/Util';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Footer from 'appdir/components/general/Footer';
import Time from 'appdir/components/common-ui/Time';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import ArticleContent from './ArticleContent';
import InfiniteScroll from 'react-infinite-scroller';
import Template from 'components/Template';
import TemplateGeneric from 'components/TemplateGeneric';
import NotFound from 'appdir/components/NotFound';
import { getAttributesArray, getBreadcrumbs, getComponentListArray, getSponsor, getAiCatchUpContent } from './Utils';

/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['ContentPage'],
		EventsWindow: state['WindowSize'].EventsWindow,
		enabled: state['Config'].enabled,
		newPath: state?.['MainNav']?.newPath,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ContentPage.mount()),
	unmount: () => dispatch(deps.actions.ContentPage.unmount()),
	unmountMainNav: data => dispatch(deps.actions.MainNav.unmount(data)),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
});

class ContentPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currArticleIndex: 0,
			contentPageArray: [],
		};

		this.onShareClick = this.onShareClick.bind(this);
		this.lastDataPath = '';
		this.hasMore = true;
		this.pageYOffset = 0;
		this.firstTime = true;
		this.measured = false;
		this.headerImgArray = [];
		this.deviceDetails = getDeviceDetails();
		this.onArticleChange = this.onArticleChange.bind(this);
		this.contentArrayUpdate = false;

		// logger.log("[ContentPage] deviceDetails:%o", this.deviceDetails);

		logger.log('[ContentPage] constructor:%o', this);
		if ('scrollRestoration' in history) {
			// Back off, browser, I got this...
			// logger.log('[ContentPage] scrollRestoration');
			history.scrollRestoration = 'manual';
		}
	}

	componentDidMount() {
		logger.log('[ContentPage] componentDidMount this:%o', this);
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		logger.log('[ContentPage] componentWillUnmount');
		this.props.unmount();
	}

	setViewedContent(timestamp, contentId) {
		//logger.log('[ContentPage] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.updateViewedContent(timestamp, contentId);
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props?.newPath) {
			// remove nav redirect from slamtracker if it exists
			this.props.unmountMainNav({
				newPath: null,
				nav: null,
			});
		}

		if (this.props.dataUrl != undefined && endsWith(this.props.dataUrl, '.xml')) {
			// logger.log('[ContentPage] componentDidUpdate - fetch:%o', this.props.dataUrl);
			logger.log('[ContentPage] componentDidUpdate - this:%o', this);

			//if path same but key diff (new nav to existing location?)
			//  reload the data
			if (
				this.props.location.pathname == prevProps.location.pathname &&
				this.props.location.key != prevProps.location.key
			) {
				this.lastDataPath = '';
			}

			/**
			 * dataUrl is different than previous data path, this is the first load, and not the panel OR
			 * dataURl is different than previous dataUrl, and this is the panel
			 *
			 * - set current dataUrl to lastDataPath
			 * - fetch article
			 *
			 * if panel, set contentArray as only retrieved data - so page doesn't continuously scroll to
			 * next article(s)
			 * if not panel, push data to contentArray - so page does continuously scroll to next
			 * article(s)
			 */

			if (
				(this.props.dataUrl != this.lastDataPath && this.firstTime && !this.props?.location?.state?.panel) ||
				(this.props.dataUrl != prevProps.dataUrl && this.props?.location?.state?.panel === true)
			) {
				logger.log(
					'[ContentPage] componentDidUpdate - this.props.dataUrl:%o,  this.lastDataPath:%o, prevProps.dataUrl:%o, this.firstTime:%o, this.props.location:%o',
					this.props.dataUrl,
					this.lastDataPath,
					prevProps.dataUrl,
					this.firstTime,
					this.props.location
				);

				this.lastDataPath = this.props.dataUrl;
				this.fetchArticle().then(data => {
					logger.log('[ContentPage] componentDidUpdate - data:%o', data);
					this.firstTime = false;
					if (data) {
						let contentArray;
						if (this.props?.location?.state?.panel === true) {
							logger.log('[ContentPage] componentDidUpdate - panelclick is true');
							contentArray = [data];
							window.scrollTo(0, 0);
						} else {
							logger.log('[ContentPage] componentDidUpdate - panelclick is false or undefined');
							contentArray = this.state.contentPageArray;
							contentArray.push(data);
						}

						logger.log('[ContentPage] componentDidUpdate - contentArray:%o', contentArray);
						this.setState(prevState => {
							return {
								...prevState,
								contentPageArray: contentArray,
							};
						});
					}
				});
				/**
				 * if there is a prevNextUrl and this is first load,
				 * fetch the url for the next article
				 */
				if (
					this.props.prevNextUrl != '' &&
					this.props.prevNextUrl !== undefined &&
					this.firstTime
					// (this.firstTime || this.props.dataUrl != prevProps.dataUrl)
				) {
					this.fetchNext();
				}
			}
		} else {
			logger.warn('[ContentPage] componentDidUpdate - improper data url: %o', this.props.dataUrl);
		}
	}

	generateArticleURL() {
		let strArray = this.state.nextURL.split('/');
		let contentId = strArray.pop().split('.')[0];
		let dt = strArray.pop();
		let combinedStr = `${dt}/${contentId}`;

		logger.log(
			'[ContentPage] generateArticleURL - strArray:%o, contentId:%o, dt:%o, combinedStr:%o',
			strArray,
			contentId,
			dt,
			combinedStr
		);
		let prevNextPath = this.props.relatedContent.nextPrevious
			.replace('<contentId>', contentId)
			.replace('<count>', 1)
			.replace('<true|false>', false)
			.replace('<type>', 'news');

		let path = this.props.cmsData.news.replace('<contentId>', combinedStr);
		return { path, prevNextPath };
	}

	onShareClick() {
		//logger.log('[ContentPage] onShareClick');
		this.setState({
			sharePopup: 'hide',
		});
	}

	fetchArticle(url) {
		return fetch(url ? url : this.props.dataUrl)
			.then(result => {
				//logger.log('[ContentPage] componentDidUpdate result:%o', result);
				//set the xml parse options
				let options = {
					explicitArray: false,
					normalize: true,
					trim: true,
					mergeAttrs: true,
					valueProcessors: [],
				};

				//parse the xml
				parseString(result, options, function(err, json) {
					result = json.contentItem;
				});

				//clean the jsx block
				result.jsx = result.jsx.replace(/data="{(.*?)}"/gi, 'data={$1}');
				//logger.log('[ContentPage] - result:%o', result);

				//update viewed content if has a date
				if (result.date) {
					this.setViewedContent(parseInt(result.date), result.contentId);
				}

				// MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				// 	content: result,
				// });

				return result;
			})
			.catch(error => {
				logger.error('[ContentPage] componentDidUpdate error:%o', error);
				if (error.status == 404) {
					this.setState({
						data: 'notfound',
					});
					return false;
				}
			});
	}

	fetchNext(url) {
		return fetch(url ? url : this.props.prevNextUrl)
			.then(result => {
				logger.log('[ContentPage] fetchNext prevNextUrl result:%o', result);

				this.setState({
					nextURL: result.next.length > 0 ? result.next[0].url : null,
				});
			})
			.catch(error => {
				logger.error('[ContentPage] fetchNext error:%o', error);
				if (error.status == 404) {
					this.setState({
						prevNextData: 'notfound',
					});
				}
			});
	}

	toggleShare() {
		//logger.log('[ContentPage] toggleShare - sharePopup:%o', this.state.sharePopup);
		this.setState({
			sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
		});
	}

	getHeader() {
		logger.log('[ContentPage] getHeader - this:%o', this);

		let { data } = this.state;

		let videoId = data.header && data.header.videoId && data.header.video ? data.header.videoId : null;

		let video_attributes = {
			playerId: 'main',
			contentId: videoId,
			videoUrl: null,
			title: null,
			thumb: null,
			autoplay: true,
			fullscreenMode: '',
			style: `video-wrapper one-col modal header`,
			aspect: 'wide',
			url: null,
		};

		//content page, (category)
		if (this.props.category != 'articles' && !this.props.landing) {
			let videoclass = videoId ? 'video' : '';

			return (
				<div className="news--header-wrapper">
					<div className={`news--header ${videoclass}`}>
						<div className="news--header-title">
							<h2>{data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data.abstract}</h4>
						</div>
					</div>
					{videoId ? (
						<div className="news--header-videoLink">
							<VideoWrapper attributes={video_attributes} type="header" />
						</div>
					) : null}
				</div>
			);
		} else if (this.props.landing && videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video" />
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (this.props.landing) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header" />
				</div>
			);
		}

		//article pages
		else if (videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video">
						<div className="news--header-icon">
							<i className="icon-news" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time epoch_ms={data.date} format="ddd DD MMM YYYY kk:mm z" options="upper" />
							</h3>
						</div>
						<div className="news--header-title">
							<h2>{data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (data.header.image && data.header.image.length > 25) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header">
						<div className="news--header-icon">
							<i className="icon-news" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time epoch_ms={data.date} format="ddd DD MMM YYYY kk:mm z" options="upper" />
							</h3>
						</div>
						<div className="news--header-title">
							<h2>{data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	playFullVideo(id) {
		logger.log('[ContentPage] playFullVideo - id:%o', id);
	}

	fetchMoreArticle() {
		let prevURL = this.state.nextURL;
		let articleData = this.generateArticleURL();

		this.fetchArticle(articleData.path).then(data => {
			if (data) {
				logger.log('[ContentPage] fetchMoreArticle - data:%o', data);
				let contentArray = this.state.contentPageArray;
				contentArray.push(data);
				// debugger;
				this.headerImgArray.push(data.header);
				logger.log('[ContentPage] fetchMoreArticle - contentArray:%o', contentArray);
				this.setState(
					prevState => {
						return { ...prevState, contentPageArray: contentArray };
					},
					() => {
						logger.log('[ContentPage] fetchMoreArticle - this.props:%o', this.props);
						//this.props?.location?.state?.panel
						// previously where the article url was updated
						// this.props.history.push({
						// 	pathname: prevURL,
						// 	state: { panel: false },
						// });
						logger.log('[ContentPage] fetchMoreArticle - this.props.history:%o', this.props.history);
					}
				);
			} else {
				this.setState(prevState => {
					return {
						...prevState,
						data: 'notfound',
					};
				});
			}
		});

		this.fetchNext(articleData.prevNextPath);
	}

	onArticleChange = (isVisibile, url, content) => {
		logger.log(
			'[ContentPage] onArticleChange - isVisibile:%o, url:%o, firsttime:%o, content:%o',
			isVisibile,
			url,
			this.firstTime,
			content
		);
		url = new URL(url).pathname;
		let oldUrl = new URL(window.location.href).pathname;
		
		/** Find AI Catch Up Pre/Post content for metrics data */
		let aicontent = this.props?.enabled?.aicatchup ? getAiCatchUpContent(content?.dependencies?.data) : [];

		if (isVisibile && (oldUrl != url || !this.measured)) {
			this.measured = true;
			let aicontentType = aicontent?.[0]?.style;
			let eventType = aicontent?.[0]?.eventcode ?? "";
			let matchId = aicontent?.[0]?.matchid ?? "";
			let articleType = content?.category ? content.category : "News";

			logger.log('[ContentPage] renderArticles - href:%o, url:%o, aicontent:%o', oldUrl, url, aicontent);
			/** Pre/Post|MS/WS|1101 */
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				content,
				...(aicontent?.length > 0 && { 
					aicontent: aicontentType?.charAt(0)?.toUpperCase() + aicontentType.slice(1) + "|" + eventType + "|" + matchId
				}),
				articleType: articleType
			});
			this.props.history.push({
				pathname: url,
				state: { panel: false },
			});
		}
	};

	/**
	 * get component Array for page based on xml file
	 * @returns article content
	 */
	renderArticles() {
		let { contentPageArray } = this.state;
		let contentPageArrayMap = [];

		logger.log('[ContentPage] renderArticles - this:%o', this);

		for (let i = 0; i < contentPageArray.length; i++) {
			let componentsAttributes = {
				attributes: getAttributesArray(contentPageArray[i].dependencies.data),
				components: getComponents(getComponentListArray(contentPageArray[i].dependencies.data)),
				jsx: contentPageArray[i].jsx,
			};
			contentPageArrayMap.push(componentsAttributes);
		}

		logger.log('[ContentPage] renderArticles - contentPageArrayMap:%o', contentPageArrayMap);

		return contentPageArrayMap.map((d, i) => {
			let currContent = contentPageArrayMap[i];
			// logger.log('[ContentPage] renderArticles - currContent:%o', contentPageArray[i]);
			let header_propsData = {
				headerType: this.props.category || this.props.landing,
				shortTitle: contentPageArray[i].shortTitle,
				scrollElem: '.content-main',
				metaTitle: contentPageArray[i].title,
				metaDescription: contentPageArray[i].abstract,
				metaDate: contentPageArray[i].date,
				metaPlayers: contentPageArray[i].players,
				scroll: false,
				credit: contentPageArray[i].credit,
				shareUrl: contentPageArray[i].url.share,
				watsonxBranding: this.props?.enabled?.aicatchup ? getAiCatchUpContent(contentPageArray[i]?.dependencies?.data)?.length > 0 : false
			};

			let subheader_attributes = {
				breadcrumbs: getBreadcrumbs(contentPageArray),
				title: contentPageArray[i].shortTitle,
				sponsor: getSponsor(contentPageArray, this.props),
			};
			let adConf = 'article_1';
			let adConf2 = 'article_2';

			// logger.log(this.props.adConfig[adConf2]);
			//initially was checking for seemingly valid url to image or video due to invalid test data
			if (contentPageArray[i].header.image && contentPageArray[i].header.image.length > 25) {
				header_propsData['imageSrc'] = contentPageArray[i].header.image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (contentPageArray[i].header.video) {
				header_propsData['videoSrc'] = contentPageArray[i].header.video;
			}

			return (
				<ArticleContent
					key={`article_content_${i}-${d.contentId}`}
					data={{
						articleContent: contentPageArray[i],
						currContent: currContent,
						headerData: header_propsData,
						subheaderData: subheader_attributes,
						articleIndex: i,
						adConfig: adConf,
						eventsWindow: this.props.EventsWindow,
					}}
					onArticleChange={this.onArticleChange}
				/>
			);
		});
	}

	render() {
		// logger.log('[ContentPage] render - this:%o', this);
		// logger.log('[ContentPage] render - props:%o', this.props);
		// logger.log('[ContentPage] render - this.deviceDetails:%o', this.deviceDetails);

		let flex = '';
		if (this.state.data) {
			if (this.state.data.tiled) {
				if (this.state.data.tiled == 'true') {
					flex = 'flex';
				}
			}
		} else {
			flex = '';
		}

		if (this.state.data == 'notfound') {
			return <NotFound />;
		} else if (this.state.contentPageArray.length > 0) {
			if (this.props.category == 'interviews') {
				this.hasMore = false;
			}
			logger.log('[ContentPage] [SocialEmbed] render - data:%o', this);
			return (
				<TemplateGeneric>
					<section className="wrapper article-wrapper">
						<InfiniteScroll
							loadMore={this.fetchMoreArticle.bind(this)}
							hasMore={
								this.deviceDetails &&
								((this.deviceDetails.userAgent &&
									this.deviceDetails.userAgent.indexOf('HeadlessChrome') == -1) ||
									(this.deviceDetails.ua && this.deviceDetails.ua.indexOf('HeadlessChrome') == -1))
									? this.hasMore
									: false
							}
							loader={
								<div key="infinite-loader" style={{ position: 'relative' }}>
									<LoadingIndicator />
								</div>
							}
							initialLoad={false}
							useWindow={true}
							threshold={100}>
							{this.renderArticles()}
						</InfiniteScroll>

						<Footer />
					</section>
				</TemplateGeneric>
			);
		} else {
			// logger.warn('[ContentPage] render - data not loaded, this:%o', this);

			let header_attributes = {
				headerType: 'generic',
				titleElem: '.news--header',
				scroll: false,
			};

			return (
				<TemplateGeneric header={header_attributes} hideAd>
					<section className="wrapper article-wrapper">
						<LoadingIndicator key="opening-loader" />
					</section>
				</TemplateGeneric>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);
