/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

/* stage item components */
import DepthOfReturn from 'appdir/components/pages/Slamtracker/elements/stage/DepthOfReturn';
import ServePlacement from 'appdir/components/pages/Slamtracker/elements/stage/ServePlacement';
import ServeSpeed from 'appdir/components/pages/Slamtracker/elements/stage/ServeSpeed';

const StageItems = props => {
	const slamtrackerData = useSelector(state => state?.['Config']?.slamtracker ?? {});
	const otherData = useSelector(state => state?.['Config']?.otherData ?? {});
	const dispatch = useDispatch();

	let { data } = props;
	// let { gameObj } = data;
	// let { pointObj } = data;
	let { statMatch } = data;
	let { pointsById } = data;

	let point = data.pointObj;
	let pointNumber = point.PointNumber;
	// let setNo = point.SetNo;
	// let gameNo = point.GameNo;
	// let pointHistoryArray = data.pointHistoryArray;
	let team1 = data.team1;
	let team2 = data.team2;
	// let isMatchComplete = data.isMatchComplete;
	// let { isLastPoint } = data;
	let { gameNo } = data;
	let { setNo } = data;
	let { pointObjIndex } = data;

	// handle who is server prior to first score. The point object comes in as OZ
	const handleStatusPoint = pointNumber => {
		const pointObj = point;
		if (pointNumber.indexOf('Z') !== -1) {
			const pointServer = pointNumber == '0Z' ? pointObj.PointServer : pointObj.ServeIndicator;
			const teamNo = pointServer == '1' || pointServer == '3' ? '1' : '2';
			const serverData = {
				teamObj: statMatch[`team${pointServer == '1' || pointServer == '3' ? '1' : '2'}`],
				teamNo,
				pointServer,
			};
			return serverData;
		} else {
			return null;
		}
  	};

	// get server name based on pointServer or ServeIndicator
	const getServerName = data => {
		if (data) {
			let { teamObj } = data;
			let { teamNo } = data;
			let { pointServer } = data;

			return teamObj.displayNameB ? `${teamObj.displayNameA}/${teamObj.displayNameB}` : `${teamObj.displayNameA}`;
		} else {
			return null;
		}
	};

	const renderPointScore = (pointObj, index) => {
		return (
			<div className="pointScore point-info-item">
				<div>
					{index.indexOf('Z') !== -1 && index !== '0Z' && gameNo == 'tiebreak'
						? ''
						: index == '0Z'
						? '0-0'
						: pointObj.P1Score == '0' &&
						  pointObj.P2Score == '0' &&
						  (pointObj.GameWinner == '1' || pointObj.GameWinner == '2')
						? 'FINISH'
						: pointObj.PointServer == '1' || pointObj.PointServer == '3'
						? `${pointObj.P1Score}-${pointObj.P2Score}`
						: pointObj.PointServer == '2' || pointObj.PointServer == '4'
						? `${pointObj.P2Score}-${pointObj.P1Score}`
						: ''}
				</div>
			</div>
		);
	};

	const renderPointSentence = (pointObj, index) => {
		return (
			<div className="pointSentence point-info-item">
				<div>{index.indexOf('Z') !== -1 ? `${getServerName(handleStatusPoint(index))} is serving` : pointObj.Sentence } </div>
			</div>
		);
	};

	const renderStageItem = (data) => {
		switch (data.stageItem) {
		case 'ret':
			return (
				<ErrorBoundary>
					<DepthOfReturn data={data} stage_location="slamtracker" />
				</ErrorBoundary>
			);
			
		case 'trk':
			return (
				<ErrorBoundary>
					<ServePlacement data={data} stage_location="slamtracker" />
				</ErrorBoundary>
			);

		case 'asp':
			return (
				<ErrorBoundary>
					<ServeSpeed data={data} stage_location="slamtracker" />
				</ErrorBoundary>
			);

		default:
			return null;
		}
	}

	const getStageItemData = (pointObj, index) => {    
		if (pointObj && index !== '0Z') {
			let stage_item_data = '';
			let currentPoint = pointsById[pointNumber];
			let stageItemList = slamtrackerData?.stageItems;
			if (stageItemList.includes(pointObj.Stage)) {
				switch (pointObj.Stage) {
					// Depth of Return
					case 'ret':
						stage_item_data = {
							selector: 'stage-' + pointNumber,
							animate: true,
							set: currentPoint.SetNo,
							match: statMatch.match_id,
							point: pointNumber,
							pointHistory: pointsById,
							stageItem: pointObj.Stage,
							team: {
								number: currentPoint.PointWinner,
								name: statMatch[`team${parseInt(currentPoint.PointWinner)}`].displayNameA,
								tourId: statMatch[`team${parseInt(currentPoint.PointWinner)}`].idA,
							},
						};
						break;

					// Serve Placement
					case 'trk':
						stage_item_data = {
							selector: 'stage-' + pointNumber,
							animate: true,
							set: currentPoint.SetNo,
							match: statMatch.match_id,
							point: pointNumber,
							pointData: currentPoint,
							pointHistory: pointsById,
                			stageItem: pointObj.Stage,
							team: {
								number: currentPoint.PointWinner,
								name: statMatch[`team${parseInt(currentPoint.PointWinner)}`].displayNameA,
								tourId: statMatch[`team${parseInt(currentPoint.PointWinner)}`].idA,
							},
						};
						break;

						// Serve Speed
						case 'asp':
							stage_item_data = {
								selector: '#stage-' + pointNumber,
								animate: true,
								set: currentPoint.SetNo,
								match: statMatch.match_id,
								point: pointNumber,
								doubles: statMatch.team1.idB == null ? false : true,
								pointData: currentPoint,
								stageItem: pointObj.Stage,
								pointHistory: pointsById,
                				currentServer: statMatch.server,

								image_url: otherData.playerProfileImagePath,
								teams: [
									{
										players: [
											{
												name: statMatch.team1.displayNameA,
												tourId: statMatch.team1.idA,
											},
											{
												name: statMatch.team1.displayNameB,
												tourId: statMatch.team1.idB,
											},
										],
									},
									{
										players: [
											{
												name: statMatch.team2.displayNameA,
												tourId: statMatch.team2.idA,
											},
											{
												name: statMatch.team2.displayNameB,
												tourId: statMatch.team2.idB,
											},
										],
									},
								],
							};
							break;

						default:
							break;
					}
				} 

        if (stage_item_data != '') {
          	return renderStageItem(stage_item_data);
        } else {
          	stage_item_data = 'stage data is not available'
        }

		} else {
			return null;
		}
	};
  
	if (data) {
		return (
			<div className="stage-container">
				<div className={`point-commentary`}>
					<div className={`score`}>{renderPointScore(point, pointObjIndex)}</div>
					<div className={`sentence`}>{renderPointSentence(point, pointObjIndex)}</div>
				</div>
				<div className='stage-item'>
					{getStageItemData(point, pointObjIndex)}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default StageItems;
