/**
 Live Video Replay Dropdown
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsLink from 'appdir/components/general/EventsLink';
import op from 'object-path';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

const mapStateToProps = (state, props) => {
	return {
		...state['LiveVideo'],
		...props,
		liveVideoSubnav: op.get(state['ActiveData'], 'liveVideo', []),
		windowSize: state['Controller'].windowSize,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class LiveVideoSubnav extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
		};
		this.handleChange = this.handleChange.bind(this);
		this.ddSizes = ['mobile', 'smallMobile', 'landscape'];
		this.maxItems = 6;
	}

	handleChange(which) {
		logger.log('[LiveVideoSubnav] handleChange - which:%o', which.target.value);
		const subnav = this.props.liveVideoSubnav;
		let channelData = find(subnav, { id: which.target.value });
		if (channelData?.url) {
			this.props.history.push(channelData.url);
		}
	}

	onOpen(which) {}

	render() {
		let options = [];
		logger.log('[LiveVideoSubnav] render - props:%o', this.props);
		if (this.props.windowSize == 'smdesktop') {
			this.maxItems = 5;
		} else if (this.props.windowSize == 'tablet' || this.props.windowSize == 'smtablet') {
			this.maxItems = 3;
		} else {
			this.maxItems = 6;
		}

		let { liveVideoSubnav } = this.props;
		let selectedData = liveVideoSubnav.find(item => item.id === this.props.selected);
		let liveVideoSubnavCloned = cloneDeep(liveVideoSubnav);
		remove(liveVideoSubnavCloned, o => {
			return o.hidden == true;
		});

		if (liveVideoSubnavCloned && selectedData) {
			let minItems = this.maxItems; //minimum number of items before adding overflow dropdown
			if (this.ddSizes.includes(this.props.windowSize)) {
				minItems = 0;
			}

			liveVideoSubnavCloned.map((i, x) => {
				if (x >= minItems) {
					options.push({
						name: i.name,
						value: i.id,
						query: 'select',
						selected: i.id == this.props.selected ? true : false,
					});
				}
			});
			let isSelected = find(options, { selected: true });

			if (minItems < liveVideoSubnavCloned.length && !this.ddSizes.includes(this.props.windowSize)) {
				options.unshift({
					name: 'More Channels',
					value: '-',
					query: 'select',
					selected: typeof isSelected === 'undefined' ? true : false,
				});
			}
			// logger.log('[LiveVideoSubnav] render - isSelected:%o',isSelected);

			return (
				<>
					<PageSubMenu mode="Watch" selected="watch" />
					<div className="live-subnav">
						{this.ddSizes.includes(this.props.windowSize) ? (
							<select onChange={this.handleChange} className="live-subnav-select-menu">
								{options.map(option => (
									<option key={option.name} value={option.value}>
										{option.name}
									</option>
								))}
							</select>
						) : (
							<div className="live-subnav-item-wrapper">
								<ul className="live-subnav-items">
									{!selectedData.hidden &&
										liveVideoSubnavCloned.map((i, x) => {
											if (x < this.maxItems) {
												return (
													<li
														className={i.id == this.props.selected ? 'selected' : ''}
														data-text={i.name}
														key={`live-subnav-item-${x}`}>
														<EventsLink to={i.url}>{i.name}</EventsLink>
													</li>
												);
											} else {
												return null;
											}
										})}
								</ul>
								{!selectedData.hidden && options.length > 0 && (
									<select onChange={this.handleChange} className="live-subnav-select-menu">
										{options.map(option => (
											<option key={option.name} value={option.value}>
												{option.name}
											</option>
										))}
									</select>
								)}
							</div>
						)}
					</div>
				</>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveVideoSubnav);
