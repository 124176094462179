/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import deps from 'dependencies';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PlayerGeneralInfo from './PlayerGeneralInfo';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import PlayerMatches from './PlayerMatches';
import MeasurementUtils from 'appdir/lib/analytics';
import Template from '../../Template';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import RelatedContent from 'appdir/components/cms/RelatedContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerPage
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => ({
	...state['PlayerPage'],
	...props,
	stubs: state['Config'].stubPages,
});

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerPage.mount()),
	unmountMainNav: data => dispatch(deps.actions.MainNav.unmount(data)),
});

class PlayerPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			playerDetailData: null,
			playerMatchesData: null,
			relatedContentData: null,
			showAll: null,
		};
		this.fetchData = false;
		this.playerName = '';
		this.scrollToMedia = createRef();
		// logger.log('[PlayerPage] constructor - state:%o', this.state);
	}

	componentDidMount() {
		if (!this.state.playerDetailData && !this.fetchData && this.props.playerInfo) {
			this.fetchData = true;
			this.fetchPlayerData();
		}
		// awt:  moving to when the fetch happens
		// MeasurementUtils.dispatchMeasurementCall('playersPageSelectPlayer', {
		// 	player: this.state.match.params.player,
		// });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.player !== this.props.match.params.player) {

			// remove nav redirect from slamtracker if it exists
			this.props.unmountMainNav({
				newPath: null,
				nav: null,
			});

			this.readyNewSearch();
		} else if (!this.state.playerDetailData && !this.fetchData && this.props.playerInfo) {
			this.fetchData = true;
			this.fetchPlayerData();
		}
	}

	componentWillUnmount() {
		// logger.log('[PlayerPage] unmounted');
		this.setState({
			playerDetailData: undefined,
			playerMatchesData: undefined,
			relatedContentData: undefined,
			showAll: undefined,
			showNews: undefined,
			showVideos: undefined,
			showPhotos: undefined,
		});
	}

	readyNewSearch() {
		const items = this.state;
		items.playerDetailData = undefined;
		this.fetchData = false;

		this.setState({
			items,
		});
	}

	fetchPlayerData() {
		let pathObj = {};
		pathObj.playerInfo = this.props.playerInfo.replace('<id>', this.props.match.params.player);
		pathObj.playerMatches = this.props.playerMatches.replace('<playerId>', this.props.match.params.player);
		pathObj.playerRelated = this.props.relatedContentPath.playerRelated.replace(
			'<playerId>',
			this.props.match.params.player
		);

		pathObj.playerDataPath = this.props.playerDataPath;

		deps.services.PlayerPage.fetch(pathObj.playerInfo)
			.then(player_info_data => {
				
				this.playerName = player_info_data.first_name + ' ' + player_info_data.last_name;
				MeasurementUtils.dispatchMeasurementCall('playersPageSelectPlayer', {
					player: this.playerName,
					playerData: player_info_data,
				});
				
				deps.services.PlayerPage.fetch(pathObj.playerMatches)
					.then(player_matches_data => {
						
						deps.services.PlayerPage.fetch(pathObj.playerRelated)
							.then(player_related_data => {
								
								deps.services.PlayerPage.fetch(pathObj.playerDataPath)
									.then(player_scoring_data => {
										
										let playerScoringData = find(player_scoring_data.players, o => {
											return o.id == this.props.match.params.player;
										});
										this.setState(prevState => {
											return {
												...prevState,
												playerDetailData: player_info_data,
												playerMatchesData: player_matches_data,
												relatedContentData: player_related_data,
												playerScoringData: playerScoringData === undefined ? [] : playerScoringData,
											};
										});
									})
									.catch(error => {
										this.setState(prevState => {
											return {
												...prevState,
												playerScoringData: 'error',
											};
										});
									});
							})
							.catch(error => {
								this.setState(prevState => {
									return {
										...prevState,
										relatedContentData: 'error',
									};
								});
							});
					})
					.catch(error => {
						this.setState(prevState => {
							return {
								...prevState,
								playerMatchesData: 'error',
							};
						});
					});
			})
			.catch(error => {
				this.setState(prevState => {
					return {
						...prevState,
						playerDetailData: 'error',
					};
				});
			});
	}

	scrollToMediaHandler = () => {
		if (this.scrollToMedia.current !== null) {
			this.scrollToMedia.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
		//this.scrollToMedia.current.scrollIntoView({ behavior: "smooth" });
	};

	renderHistory(history, type) {
		// logger.log('[PlayerPage] renderhistory - history:%o', history);
		history = orderBy(history, ['year_value'], ['desc']);
		return history.map(item => {
			return (
				<tr key={`${item.year_value}-${type}`}>
					<td>{item.year_value}</td>
					<td>{item.AO ? item.AO : `-`}</td>
					<td>{item.RG ? item.RG : `-`}</td>
					<td>{item.WIM ? item.WIM : `-`}</td>
					<td>{item.USO ? item.USO : `-`}</td>
				</tr>
			);
		});
	}

	renderMatches() {
		if (this.state.playerMatchesData.matches) {
			if (this.state.playerMatchesData.matches.length > 0) {
				return (
					<div className="playerMargins">
						<div className="contentTitle">RECENT MATCHES</div>
						<div className="matches">
							<PlayerMatches
								matchData={this.state.playerMatchesData}
								playerConfiguration={this.props}
								playerID={this.props.match.params.player}
							/>
						</div>
					</div>
				);
			}
		}
	}

	renderRelated() {
		if (this.state.relatedContentData.content) {
			if (this.state.relatedContentData.content.length > 0) {
				return (
					<div className="three-col">
						<RelatedContent data={this.state} location="playerPage" url={this.props.relatedContentPath.playerRelated.replace('<playerId>', this.props.match.params.player)} />
					</div>
				);
			}
		}
	}

	renderSinglesHistory() {
		if (!isEmpty(this.state.playerDetailData.singles_history) && this.props.showHistory) {
			return (
				<div className="singlesContainer">
					<div className="contentTitle">SINGLES HISTORY</div>
					<table className="playerHistory">
						<thead>
							<tr>
								<th>Year</th>
								<th>
									Australian
									<br /> Open
								</th>
								<th>
									Roland
									<br /> Garros
								</th>
								<th>Wimbledon</th>
								<th>US Open</th>
							</tr>
						</thead>
						<tbody>{this.renderHistory(this.state.playerDetailData.singles_history, 'singles')}</tbody>
					</table>
				</div>
			);
		} else {
			return null;
		}
	}

	renderDoublesHistory() {
		if (!isEmpty(this.state.playerDetailData.doubles_history) && this.props.showHistory) {
			return (
				<div className="doublesContainer">
					<div className="contentTitle">DOUBLES HISTORY</div>
					<table className="playerHistory">
						<thead>
							<tr>
								<th>Year</th>
								<th>
									Australian
									<br /> Open
								</th>
								<th>
									Roland
									<br /> Garros
								</th>
								<th>Wimbledon</th>
								<th>US Open</th>
							</tr>
						</thead>
						<tbody>{this.renderHistory(this.state.playerDetailData.doubles_history, 'doubles')}</tbody>
					</table>
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		// logger.log('[PlayerProfile] render - state:%o', this.state);

		if (this.state.playerDetailData == 'error' || this.props?.stubs?.players.stub === 'stub') {
			let url = '/en_US/players/index.html?promo=topnav';
			return <EventsRedirect to={url} />;
		}

		let header_attributes = {
			headerType: 'generic',
			title: 'Players',
			scroll: false,
			metaTitle: this.playerName + ' Player Profile',
			metaDescription:
				'View the full player profile, include bio, stats and results for ' + this.playerName + '.',
			metaDate: '',
			metaPlayers: this.playerName,
			// canonicalLink:${document.location.href.split('?')[0]
			canonicalLink:`https://www.usopen.org${this.props?.location?.pathname}`
		};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/players/index.html',
					title: 'players',
				},
			],
			sponsor: {
				link: 'https://www.emirates.com/us/english/',
				title: 'Presented by',
				name: 'Emirates Airline',
				imgClass: '',
				imgSrc: '/assets/images/logos/emirates_logo_red.png',
				tagline: 'Presented by',
			},
			page_header: 'Players',
			title: this.playerName + ' Player Profile',
		};
		let backData = { category: '', history: this.props.history };

		return (
			<Template header={header_attributes} subHeader={subheader_attributes}>
				<section className="wrapper playerProfile">
					{this.state.playerDetailData && this.state.playerScoringData !== undefined ? (
						<div className="four-col">
							<PlayerGeneralInfo
								player={this.state.playerDetailData}
								playerScoringData={this.state.playerScoringData}
								showHistory={this.props.showHistory}
								relatedContentData={this.state.relatedContentData}
								scrollToMediaHandler={this.scrollToMediaHandler}
							/>
							{this.state.playerMatchesData ? (
								<div className="recentMatchesContainer">{this.renderMatches()}</div>
							) : null}
							{this.state.relatedContentData ? (
								<div className="relatedContentContainer" ref={this.scrollToMedia}>
									{this.renderRelated()}
								</div>
							) : null}
							{this.state.playerDetailData ? (
								<div className="historyOverviewContainer">
									{this.renderSinglesHistory()}
									{this.renderDoublesHistory()}
								</div>
							) : null}
						</div>
					) : (
						<LoadingIndicator />
					)}
				</section>
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPage);
