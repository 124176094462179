/**
 AgeGate Initial State
*/

export default {
  allowed: false,

  // 2592000000 = 30days in milliseconds
  // persist: 2592000000
  persist: {
    allowed: 2592000000
  }
};
