import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import cookie from 'react-cookies';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const mapStateToProps = (state, props) => {
	return {
		...state['NewsletterBanner'],
		...state['Header'],
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Header.mount()),
});

class NewsletterBanner extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailValue: '',
			placeHolder: 'Enter Email Address',
			errorState: false,
			webCookie: cookie.load('newsletterBanner'), //value of open/closed
		};
		this.closeAlert = this.closeAlert.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.loaded = false;

		logger.log('[NewsletterBanner] constructor this:%o', this);
	}

	componentDidUpdate(prevState) {
		// logger.log('[NewsletterBanner] componentDidUpdate state:%o',this.state);
		if (this.props.alertPath && !this.loaded) {
			this.fetchMessage();
		}
	}

	componentDidMount() {
		// logger.log('[NewsletterBanner] componentDidMount');
		if (this.props.alertPath && !this.loaded) {
			this.fetchMessage();
		}
	}

	fetchMessage() {
		fetch(this.props.alertPath).then(data => {
			this.setState({});
			this.loaded = true;
		});
	}

	isEmail() {
		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(this.state.emailValue);
	}

	handleChange(event) {
		this.setState({ emailValue: event.target.value });
	}

	handleSubmit(event) {
		let actionUrl = 'http://t.email.usopen.org/lp/ustaProAcq?email=';
		if (this.isEmail()) {
			this.setState({
				placeHolder: 'Enter Email Address',
				errorState: false,
			});
			window.open(actionUrl + this.state.emailValue);
			event.preventDefault();
			let alertId = event.target.elements['alertMsgId'].value;
			this.closeAlert(alertId, 365);
		} else {
			this.setState({
				emailValue: '',
				placeHolder: 'Please enter a valid email address!',
				errorState: true,
			});
			event.preventDefault();
		}
	}

	closeAlert(alertId, hideDays) {
		this.loaded = false;
		let cookieString = 'closed,' + alertId;
		const expires = new Date();
		let expDays = this.props.newsletter.defExpire;
		if (hideDays) {
			expDays = hideDays;
		}
		expires.setDate(expires.getDate() + expDays);

		cookie.save('newsletterBanner', cookieString, { expires, path: '/' });

		this.setState({
			webCookie: cookieString,
		});
	}

	renderAlert(alertClass, alertMsg) {
		logger.log('[NewsletterBanner] Router:%o', this.props.Router);
		let route = get(this.props, 'Router.pathname', '');
		let homepage = route == '/' || route == '/index.html' ? 'pinned' : '';
		if (homepage) {
			return null;
		}
		if (alertMsg.type == 'emailSubmit') {
			return (
				<div className={`breakingNews newsletter ${alertClass}`}>
					<form id="newsRegForm" onSubmit={this.handleSubmit}>
						<div className="bn-content textfield">
							<div className="msg-area">
								<span>{alertMsg.text}</span>
								{alertMsg.subText && <span className="alert-subtext">{alertMsg.subText}</span>}
							</div>
							<div className="input-area">
								<input
									type="text"
									id="newsletter-input"
									name="emailAddr"
									placeholder={this.state.placeHolder}
									value={this.state.emailValue}
									className={this.state.errorState ? 'error' : ''}
									onChange={this.handleChange}
								/>
								<input type="submit" id="newsletterSubmit" value={alertMsg.buttonText} />
								<input type="hidden" id="alertMsgId" value={alertMsg.id} />
							</div>
						</div>
					</form>
					{alertClass != 'article-banner' && (
						<div
							className="ar-icon"
							onClick={() => {
								this.closeAlert(alertMsg.id);
							}}>
							<div className="icon">
								<i className="icon-close" />
							</div>
						</div>
					)}
				</div>
			);
		} else {
			return (
				<div className={`breakingNews newsletter ${alertClass}`}>
					<div className="bn-title">{alertMsg.title}:&nbsp;</div>
					<div className="bn-content">
						{alertMsg.link !== '' ? (
							<a href={alertMsg.link} target="_self">
								{alertMsg.text}
							</a>
						) : (
							<span>{alertMsg.text}</span>
						)}
						{alertMsg.subText && <span className="alert-subtext">{alertMsg.subText}</span>}
					</div>
					{alertClass != 'article-banner' && (
						<div
							className="ar-icon"
							onClick={() => {
								this.closeAlert(alertMsg.id);
							}}>
							<div className="icon">
								<i className="icon-close" />
							</div>
						</div>
					)}
				</div>
			);
		}
	}

	render() {
		// logger.log("[NewsletterBanner] render - this:%o", this);
		logger.log('[NewsletterBanner] render - props:%o', this.props);

		let alertClass = this.props.style;
		let cookieId = '';
		let cookieOpen = '';

		if (this.state.webCookie && alertClass != 'article-banner') {
			let cookieArray = this.state.webCookie.split(',');
			cookieOpen = cookieArray[0];
			cookieId = cookieArray[1];
		} else {
			cookieOpen = 'open';
		}

		//add to active.json path
		let alertMsg = {
			id: '1',
			type: 'emailSubmit',
			text: 'Sign Up for the US Open Insider Newsletter',
			buttonText: 'Submit',
		};

		if (alertClass == 'article-banner') {
			alertMsg.text = 'US Open Insider Newsletter';
			alertMsg.subText =
				"Don't miss your chance to receive USTA & US Open News, Section News, Shop News and more.";
		}

		if (!isEmpty(alertMsg.text) && alertMsg.text !== '') {
			//first time to site no cookies/cookie open
			if (cookieOpen === 'open') {
				// render alert
				return this.renderAlert(alertClass, alertMsg);
			} else {
				//cookie closed but id different
				if (alertMsg.id !== cookieId) {
					return this.renderAlert(alertClass, alertMsg);
				} else {
					return null;
				}
			}
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterBanner);
