import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import { values } from 'appdir/main';
import { doMeasurement } from 'appdir/components/general/Util';

import EventsLink from 'appdir/components/general/EventsLink';
import DrawAnalysisBar from 'appdir/components/common-ui/DrawAnalysis/DrawAnalysisBar';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import ToggleButton from 'appdir/components/common-ui/ToggleButton';
import BracketViewButton from 'appdir/components/common-ui/DrawAnalysis/BracketViewButton';

/**
 *
 * @param {*} props ---- bool, true to be On, false to be Off - toggle switch
 * props.page
 * page, playerId, bracketViewLink
 * @returns
 */
const DrawAnalysisHelp = props => {
	let drawAnalysisHelpRedux = useSelector(state => state['DrawAnalysisHelp']);
	let drawAnalysisRedux = useSelector(state => state['DrawAnalysis']);

	const attributes = props?.attributes;
	const page = attributes?.page;
	const playerId = attributes?.playerId;
	const bracketViewLink = attributes?.bracketViewLink;

	const dispatch = useDispatch();

	useEffect(() => {
		/** set Draw Analysis Help Overlay init redux */
		if (!drawAnalysisHelpRedux?.status) {
			dispatch(deps.actions.DrawAnalysisHelp.mount());
		}

		/** unmounting - clean up the redux state so the help overlay doesn't auto open after viewed
		 *  unmounting from Draws bracket view, set help to false so it doesn't auto open on Power Index
		 */
		return () => {
			let data = {
				help: drawAnalysisHelpRedux?.viewed || page == 'draws' ? false : true,
			};
			dispatch(deps.actions.DrawAnalysisHelp.unmount(data));
		};
	}, []);

	/** on the bracket view, if the onboarding message hasn't seen, auto open the help overlay */
	useEffect(() => {
		if (!drawAnalysisHelpRedux?.viewed && page == 'draws' && drawAnalysisHelpRedux?.status === 'loaded') {
			let data = {
				help: true,
			};

			dispatch(deps.actions.DrawAnalysisHelp.update(data));
		}
	}, [!drawAnalysisHelpRedux?.viewed && drawAnalysisHelpRedux.status === 'loaded']);

	/** on the bracket view, there is a toggle button to show/hide draw analysis */
	const setDrawAnalysisToggle = isToggled => {
		/** set the one time onboarding help screen to be viewed */
		let data = {
			show: isToggled,
		};

		dispatch(deps.actions.DrawAnalysis.update(data));

		let val = isToggled ? 'On' : 'Off';

		let measureArgs = [val];
		let contextData = [];
		if (window.webview) {
		}
		doMeasurement('Draws', 'Draw Analysis Switch', measureArgs, contextData);
	};

	/** show/hide help content overlay
	 *  add no scroll class if it doesn't exist on open
	 *  remove no scroll class if it's opened on the Power Index page
	 *  don't remove the class on Path to the Finals screen (draws) as
	 *  it's on top of the overlay that also needs no scroll class
	 */
	const toggleHelp = val => {
		// let pathToFinalPlayerImageContainerEl = document?.querySelector('.player-wrapper .col');

		if (val == 'open') {
			if (!document.body.classList.contains('modal-on-noscroll')) {
				document.body.classList.add('modal-on-noscroll');
			}
		} else if (val == 'close') {
			document.body.classList.remove('modal-on-noscroll');
		}

		let data = {};

		/** set the one time onboarding help screen to be viewed */
		if (page == 'draws' && drawAnalysisRedux && !drawAnalysisHelpRedux?.viewed) {
			data = {
				viewed: true,
				help: val == 'open' ? true : false,
			};
		} else {
			data = {
				help: val == 'open' ? true : false,
			};
		}

		dispatch(deps.actions.DrawAnalysisHelp.update(data));

		let measureArgs = [val]; // needs in an array and string, not obj
		let contextData = [];
		if (window.webview) {
		}
		doMeasurement(attributes.metricsPage, 'Draw Analysis Helper', measureArgs, contextData);

		if (drawAnalysisHelpRedux?.defaultTop) {
			window.scrollBy(0, drawAnalysisHelpRedux.defaultTop);
		}
	};

	/** if help is opened on power index page, don't link to the page */
	const getPowerIndexLink = () => {
		if (page == 'powerindex') {
			return <b>{values.powerIndexTitle}</b>;
		} else {
			return (
				<EventsLink to={`/en_US/powerindex/index.html`} title={values.powerIndexTitle}>
					<b>{values.powerIndexTitle}</b>
				</EventsLink>
			);
		}
	};

	if (drawAnalysisHelpRedux?.help) {
		return (
			<div className="draw-analysis-help-overlay">
				<div className="overlay-content">
					<CloseBtn closeFn={() => toggleHelp('close')} className={''} />
					<h4>{values.aiDrawTitle}</h4>

					{page == 'draws' && (
						<div className="draw-analysis-switch">
							<ToggleButton
								attributes={{
									label: values.aiDrawTitle,
									toggled: drawAnalysisRedux?.show,
									area: 'help',
									onClickCallback: val => setDrawAnalysisToggle(val),
								}}
							/>
						</div>
					)}

					{/** because of the link to Power Index page, the help text is added here
					 *  and not in the static json file so it can dynamically set applink
					 */}
					<p className="text">
						The IBM {values.aiDrawTitle} uses machine learning models built on watsonx to assess the
						favorability of each player's draw based on their {getPowerIndexLink()} ranking. Every draw is
						ranked from 1 (most favorable) to 128 (most difficult). Every round is also projected favorable
						to difficult based on potential opponents. To use, activate the toggle switch in the Bracket
						View for Women's and Men's Singles Draws, and select any player to see their draw favorability.
						Click <strong>See IBM Likely to Play</strong> to see who is projected to be the player's most
						likely opponent in each round.
					</p>
					<DrawAnalysisBar
						data={{
							demo: true,
						}}
					/>

					{page == 'powerindex' && (
						<>
							<p>See it in action. Check out the Draws Bracket View.</p>
							<BracketViewButton
								attributes={{
									bracketViewLink,
									playerId,
								}}
							/>
						</>
					)}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default DrawAnalysisHelp;
