import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		logger.log('[RadioLanding] action.mount - ensureConfig');

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[RadioLanding] action.mount - dispatch - param:%o', params);
			logger.log('[RadioLanding] Config: ', Config);

			let data = {
				basePicPath: Config.otherData.basePicPath,
				sponsors: Config.sponsors,
			};

			dispatch({
				type: deps.actionTypes.RADIOLANDINGPAGE_MOUNT,
				data: data,
			});
		});
	},
	unmount: data => (dispatch, getState, store) => {
		logger.log('[MainNav] actions.unmount data:%o', data);
		dispatch({ type: deps.actionTypes.RADIOLANDINGPAGE_UNMOUNT, data: data });
	},
};
