/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { getLikelihoodWinner, getLikelyhoodWinData } from '../MatchInsightsUtils';
import PlayerImage from 'appdir/components/content/PlayerImage';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: LikelihoodWinOverlay for Match Insights
 * -----------------------------------------------------------------------------
 */
const LikelihoodWinOverlay = props => {
	logger.log('[LikelihoodWinOverlay] - props:%o', props);

	let { teams, powerIndexMatchupData, showInfoTip, onCloseClickFn } = props;

	let prematchWinner = getLikelihoodWinner(powerIndexMatchupData);

	if (showInfoTip) {
		for (let i = 0; 1 < teams.length; i++) {
			if (teams[i]['idA'] === prematchWinner) {
				return (
					<div className="modal-fade-bg" key={`player${i + 1}-overlay-box`}>
						<div className="insights-prediction-overlay">
							<h4 className="watson-logo">Watson Insights</h4>
							<div className="close">
								<i
									className="icon-close"
									onClick={() => {
										onCloseClickFn();
									}}
								/>
							</div>

							<div className="insights-prediction-overlay-content">
								<div className="insights-prediction-overlay-row">
									<div className="insights-prediction-overlay-col">
										<PlayerImage
											attributes={{
												'player-image': props.imgPath.replace('<playerid>', teams[i]['idA']),
												style: ``,
											}}
										/>
									</div>
									<div className="insights-prediction-overlay-col">
										<h5>Likelihood to Win</h5>
										<p className="num">{` ${
											getLikelyhoodWinData(powerIndexMatchupData, teams[i]['idA'])[
												'likelihoodWinNum'
											]
										} %`}</p>

										<EventsLink to={`#`}>
											<p
												className="why-link"
												onClick={() => {
													onCloseClickFn();
												}}>
												Learn Why
											</p>
										</EventsLink>
									</div>
								</div>
								<div className="insights-prediction-overlay-row">
									<div className="prediction-body">
										Watson predicts R. Federer as underdog will upset N. Djokovic in the fourth
										round of Gentlemen’s Singles on Centre Court starting at 2:00 PM Sunday 13 July.
									</div>
								</div>
							</div>
						</div>
					</div>
				);
				break;
			}
		}
	}
};

export default LikelihoodWinOverlay;
