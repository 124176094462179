import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log('[Home] actions.mount: Config.cmsData', Config.cmsData);
			let data = {
				homeData: Config.cmsData.homeContent,
				basePicPath: Config.otherData.basePicPath,
				jsonServer: Config.cmsData.home.jsonServer,
				relatedChips: Config.relatedContent.homeRelated,
				adMode: Config.landingPages.adMode,
				status: 'loaded',
				start: 0,
				count: 11,
				end: 11,
				siteBG: Config.cmsData.siteBackgroundImage,
				geoCheck: Config.otherData.homeGeoCheck
			};
			dispatch({ type: deps.actionTypes.HOME_MOUNT, data: data });
		});
	},
	unmount: () => dispatch => {
		let data = {
			status: null,
		};
		dispatch({ type: deps.actionTypes.HOME_UNMOUNT, data: data });
	},
};
