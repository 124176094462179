import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * This function splits an array up into equal size arrays of your choosing
 * @param {*} arr - array to chunk
 * @param {*} size - size you want to chunk it to
 */
export const chunkArray = (arr, size) => {
	let chunked = [];
	for (let ele of arr) {
		let last = chunked[chunked.length - 1];
		if (!last || last.length === size) {
			chunked.push([ele]);
		} else {
			last.push(ele);
		}
	}
	return chunked;
};

export const renderEmptyMatch = match => {
	return (
		<div key={match.match_id} className="match">
			<div className="row">
				<div className="match-info header" colSpan="3">
					{match.notBefore ? <div className="match-info-notBefore">NOT BEFORE: {match.notBefore}</div> : null}
					{match.comment ? <div className="match-info-comment">{match.comment}</div> : null}
					<span className="event" />
				</div>
			</div>
			<div className="row teams" />
			<div className="row mobile" />
		</div>
	);
};

/** used on Schedule, Draws, and Scores
 * webview favs is just a simple array,
 * www favs is Obj containing {show: bool, players: []}
 */
export const determinePlayerHighlightClass = (id, favs, searchedPlayer, webviewShowFavs) => {
	let playerClasses = [];

	if (!window.webview && favs?.show && favs?.players.indexOf(id) !== -1) {
		playerClasses.push('favorite');
	} else if (window.webview && webviewShowFavs && favs?.indexOf(id) !== -1) {
		playerClasses.push('favorite');
	}
	if (typeof id === 'string') {
		let searchedPlayerId;
		if (window?.webview) {
			searchedPlayerId = !window.webview ? searchedPlayer : searchedPlayer?.id;
		} else {
			searchedPlayerId = searchedPlayer?.id;
		}

		if (searchedPlayerId?.toString() === id.toString()) {
			playerClasses.push('search');
		}
	}
	return playerClasses.join(' ');
};

const getPlayerNames = (team, favs, searchedPlayer, webviewShowFavs) => {
	// logger.log('[ScheduleWebview] getPlayerNames - team:%o, favs:%o', team, favs);

	return (
		<span>
			<div
				className={`schedule-player player1  ${determinePlayerHighlightClass(
					team.idA,
					favs,
					searchedPlayer,
					webviewShowFavs
				)}`}
				data-player={team.idA}
				id={team.idA}>
				<span>
					<EventsLink className="name" to={`/en_US/players/overview/${team.idA}.html`}>
						{team.displayNameA}
					</EventsLink>
					<span className="nation"> ({team.nationA}) </span>
					<span className="seed">{team.seed ? `[${team.seed}]` : null}</span>
				</span>
			</div>
			{team.idB ? (
				<div
					className={`schedule-player player2 ${determinePlayerHighlightClass(
						team.idB,
						favs,
						searchedPlayer,
						webviewShowFavs
					)}`}
					data-player={team.idB}
					id={team.idB}>
					<span>
						<EventsLink className="name" to={`/en_US/players/overview/${team.idB}.html`}>
							{team.displayNameB}
						</EventsLink>
						<span className="nation"> ({team.nationB}) </span>
						<span className="seed">{team.seed ? `[${team.seed}]` : null}</span>
					</span>
				</div>
			) : null}
		</span>
	);
};

export const renderTeamNames = (team, favs, searchedPlayer, webviewShowFavs) => {
	if (team.length == 1) {
		return <span>{getPlayerNames(team[0], favs, searchedPlayer, webviewShowFavs)}</span>;
	} else if (team.length == 2) {
		return (
			<span className="winner-of-holder">
				<div className="winner-of">winner of</div>
				<div className="winner-of1">{getPlayerNames(team[0], favs, searchedPlayer, webviewShowFavs)}</div>
				<div className="winner-versus">{window.webview ? 'versus' : 'v'}</div>
				<div className="winner-of2">{getPlayerNames(team[1], favs, searchedPlayer, webviewShowFavs)}</div>
			</span>
		);
	}
};

export const matchHasInsights = (match_list, match_id) => {
	if (match_list.indexOf(match_id) > -1) {
		return true;
	} else {
		return false;
	}
};

/* match.statusCode
	A = In Progress
	B = Future
	C = Cancelled
	D = Complete
	E = Retired
	F = Walkover
	G = Default
	K = Suspended
	P = Postponed
	X = Arrive on Court / In Progress (Schedule)
	Y = Warming Up / In Progress (Schedule)
*/
export const matchIsFutureState = match => {
	//logger.log('[ScheduleUtils] matchIsFutureState - match:%o', match);
	if (match.statusCode === 'B' || match.statusCode === 'P') {
		return true;
	} else {
		return false;
	}
};

export const matchIsInProgress = match => {
	//logger.log('[ScheduleUtils] matchIsLiveState - match:%o', match);
	if (match.statusCode === 'A' || match.statusCode === 'X' || match.statusCode === 'Y') {
		return true;
	} else {
		return false;
	}
};

export const matchIsLiveState = match => {
	//logger.log('[ScheduleUtils] matchIsLiveState - match:%o', match);
	if (match.statusCode === 'A' || match.statusCode === 'K' || match.statusCode === 'X' || match.statusCode === 'Y') {
		return true;
	} else {
		return false;
	}
};

export const matchIsLiveNotInProgress = match => {
	//logger.log('[ScheduleUtils] matchIsLiveNotInProgress - match:%o', match);
	if (match.statusCode === 'K' || match.statusCode === 'X' || match.statusCode === 'Y') {
		return true;
	} else {
		return false;
	}
};

export const matchIsCompleteState = match => {
	//logger.log('[ScheduleUtils] matchIsCompleteState - match:%o', match);
	if (match.statusCode === 'D' || match.statusCode === 'E' || match.statusCode === 'F' || match.statusCode === 'G') {
		return true;
	} else {
		return false;
	}
};

export const matchIsCancelled = match => {
	//logger.log('[ScheduleUtils] matchIsCompleteState - match:%o', match);
	if (match.statusCode === 'C') {
		return true;
	} else {
		return false;
	}
};
