/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { fetch } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: NextUp
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['NextUp'],
		VideoPlayer: state['VideoPlayer'],
		RelatedContent: state['Config'].relatedContent,
		adConfig: state['Config']?.adConfig,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	autoplayToggle: toggle => dispatch(deps.actions.VideoPlayer.autoplayToggle(toggle)),
});

class NextUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			seconds: this.props.seconds,
		};

		this.countDown = this.countDown.bind(this);
		this.getNextUpData = this.getNextUpData.bind(this);
		this.mapVideoData = this.mapVideoData.bind(this);
		this.enableToggle = this.enableToggle.bind(this);
		this.timer;

		// if (!this.timer && this.props.VideoPlayer.auto) {
		// 	this.timer = setInterval(this.countDown, 1000);
		// }
	}

	componentDidMount() {
		logger.log('[NextUp] componentDidMount');
		this.getNextUpData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.vidId != prevProps.vidId) {
			this.getNextUpData();
		}

		if (!this.timer && this.props.VideoPlayer.auto) {
			this.timer = setInterval(this.countDown, 1000);
		}
	}

	countDown() {
		// Remove one second, set state so a re-render happens.
		let seconds = this.state.seconds - 1;
		this.setState({
			seconds,
		});

		// Check if we're at zero.
		if (seconds == 0) {
			// this.nextUpPlay(true);
			this.props.playNext(this.state.content);
		}
	}

	nextUpPlay(auto) {
		this.stopCountdown();
		// this.props.playNext(this.props.content);
	}

	enableToggle() {
		let enabled = this.props.VideoPlayer.auto ? false : true;
		logger.log('[NextUp] enableToggle enabled:%o', enabled);
		if (!enabled) {
			this.stopCountdown();
		} else {
			this.timer = setInterval(this.countDown, 1000);
		}

		if (this.props.onUserEnable) {
			this.props.onUserEnable(enabled);
		}

		// this.setState({
		// 	auto: enabled,
		// });

		this.props.autoplayToggle(enabled);
	}

	stopCountdown() {
		clearInterval(this.timer);
		this.timer = null;
		this.setState({ seconds: this.props.seconds });
	}

	mapVideoData(data) {
		logger.log('[NextUp] mapVideoData data:%o', data);
		if (data && !isEmpty(data)) {
			let vastCategory = 'default';
			return {
				id: data.cmsId,
				title: data.title,
				description:
					data.description && data.description.length > 0
						? data.description
						: 'View the latest US Open Highlights and Features.',
				poster: data.images[0].xlarge,
				shareUrl: data.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: data.media.m3u8,
						mimeType: data.media.m3u8.includes('m3u8') ? 'application/x-mpegURL' : 'video/mp4',
						streamType: 'vod',
					},
				],
				tracks:
					get(data, 'media.tracks', false) && get(data, 'media.tracks[0].file', false)
						? [
								{
									kind: 'captions',
									type: 'text/vtt',
									srclang: 'en',
									src: data.media.tracks[0].file,
								},
						  ]
						: [],
				startSeconds: 0,
				autoplay: true, //autoPlay ? autoPlay : false,
				autoplayPolicy: 'mutedinline',
				playsinline: true,
				displayDate: data.displayDate,
				ads: [this.props?.adConfig?.vast['default']],
				category: data.subType,
			};
		}
	}

	getNextUpData() {
		let prevNextPath = get(this.props.RelatedContent, 'nextPrevious', '')
			.replace('<contentId>', this.props.vidId)
			.replace('<count>', 1)
			.replace('<true|false>', false)
			.replace('<type>', 'video');

		fetch(prevNextPath)
			.then(result => {
				logger.log('[NextUp] getNextUpData result:%o', result);
				let content = this.mapVideoData(result.next[0]);
				logger.log('[NextUp] getNextUpData content:%o', content);
				this.setState({
					content,
				});
			})
			.catch(error => {
				logger.error('[NextUp] getNextUpData error:%o', error);
				if (error.status == 404) {
					this.setState({
						content: 'notfound',
					});
				}
			});
	}

	render() {
		logger.log('[NextUp] render - this:%o', this);

		if (this.state.content && this.state.content != 'not found') {
			return (
				<div id="overlayNextUp" className="overlayNextUp">
					<div className="nextup" style={{ display: 'block' }}>
						<div className="next_banner">
							<div className="photo">
								<img src={this.state.content !== null ? this.state.content.poster : ''} />
							</div>
							<div
								onClick={() => {
									this.props.playNext(this.state.content);
								}}
								className="next">
								<img
									src="/assets/images/video/btn_next_play.png"
									style={{
										width: '25px',
										height: '28px',
										position: 'absolute',
										left: '0',
										right: '0',
										bottom: '0',
										top: '0',
										margin: 'auto',
										display: 'block',
										flexShrink: '0',
									}}
								/>
							</div>
							<div className="countdown">
								<div
									className="label"
									style={{ display: this.props.VideoPlayer.auto ? 'block' : 'none' }}>
									Starts in...
								</div>
								<div
									id="nextCounter"
									className="count"
									style={{
										display: this.props.VideoPlayer.auto ? 'block' : 'none',
									}}>
									{this.state.seconds}
								</div>
								<div className="allow">
									<a
										onClick={() => {
											this.enableToggle();
										}}
										style={{ color: '#000000' }}>
										{this.props.VideoPlayer.auto ? 'Disable' : 'Enable'} autoplay
									</a>
								</div>
							</div>
							<div className="next-content">
								<div className="prompt">Up Next...</div>
								<div className="title">{this.state.content?.title ? this.state.content.title : ''}</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}
// export default NextUp;
export default connect(mapStateToProps, mapDispatchToProps)(NextUp);
