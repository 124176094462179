/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Image
 * -----------------------------------------------------------------------------
 */
const YouTube = props => {
	logger.log('[Youtube] - props:%o', props);

	let attributes = props.data;
	return <YouTube videoId="2g811Eo7K8U" opts={opts} />;
};
export default YouTube;
