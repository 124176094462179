import React, { PureComponent } from 'react';

/**
 * -----------------------------------------------------------------------------
 * Class Component: TeamIcon
 * @params
 *  - duration
 * -----------------------------------------------------------------------------
 */

export default class TeamOneIcon extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.serveSpeed !== prevState.serveSpeed) {
			//Change in props
			return {
				animationClass: nextProps?.serveSpeed && nextProps.useAnimation ? 'animation' : '',
				serveSpeed: nextProps.serveSpeed,
			};
		} else {
			return {
				animationClass: '',
			};
		}
	}

	onAnimationEnd = () => {
		this.setState({
			animationClass: '',
		});
	};

	render() {
		let animationCallback = this.state?.animationClass === 'animation' ? this.onAnimationEnd : null;

		return (
			<div
				id={`match-${this.props.matchId}-team-one-icon`}
				className={`${this.props.iconClasses} ${this.state?.animationClass}`}
				onAnimationEnd={animationCallback}>
				<i className="icon-check" />
				<i className="icon-ballSolid" />
			</div>
		);
	}
}
