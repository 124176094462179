import GeneralContentPage from './index';
import deps from 'dependencies';
import { MENU_ITEMS } from 'appdir/components/general/Util';

export default [
	{
		path: [
			'/:prefix(preview)?/:lang(en_US)/:landing(tickets|visit|players_only|us-open-at-home)/:id(index).html',
			'/:prefix(preview)?/:lang/(en_US):landing(tickets|visit|players_only|us-open-at-home)/',
			'/:prefix(preview)?/:lang(en_US)/:landing(tickets)/:category(individual_tickets)/:date(2024)/:id',
		],
		order: 10,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:lang(en_US)/about/:landing(tv_intl).html',
		order: 10,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/info/:landing(privacy|terms|contact_us)/:id(index).html',
		order: 10,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/about/:landing(sponsors|partners)/:id.html',
		order: 10,
		exact: true,
		component: GeneralContentPage,
		section: MENU_ITEMS.foundation,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/about/:landing(us_open_fan_week).html',
		order: 10,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: [
			'/:prefix(preview)?/:lang(en_US)/social/:landing(fanaccesspass)/:id(index).html',
			'/:prefix(preview)?/:lang(en_US)/social/:landing(fanaccesspass)/',
		],
		order: 10,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: [
			'/:prefix(preview)?/:lang(en_US)/social/:category(fanaccesspass)/:id/index.html',
			'/:prefix(preview)?/:lang(en_US)/social/:category(fanaccesspass)/:id.html',
			'/:prefix(preview)?/:lang(en_US)/social/:category(fanaccesspass)/:id/',
		],
		order: 11,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/:category(atoz)/:id(schedule|tv_schedules).html',
		order: 10,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/:category(atoz)/:id(official_suppliers).html',
		order: 10,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/:category(atoz)/:id(shopping).html',
		order: 10,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path:
			'/:prefix(preview)?/:lang(en_US)/:category(content|info|about|tickets|visit|event_guide|players_only|us-open-at-home|fan-week)/:id.html',
		order: 11,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/:category(tickets|visit_and_tickets|visitandtickets|atoz)/:id.html',
		order: 12,
		exact: true,
		component: GeneralContentPage,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/fan-week/:id(schedule-of-events)/:tabName',
		order: 12,
		exact: true,
		component: GeneralContentPage,
		section: MENU_ITEMS.foundation,
		load: params => deps.actions.GeneralContentPage.mount(params),
	},
];
