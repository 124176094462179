/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import { getInterfaceLink } from 'appdir/components/general/Util/index.js';

/**
 * -----------------------------------------------------------------------------
 * Component: ImmersivCTA
 * -----------------------------------------------------------------------------
 */
const ImmersivCTA = props => {
    const dispatch = useDispatch();

	const launchImmersiv = () => {
		if (window.webview) {
			let contextData = [
				{ slamtracker_matchid: props.matchId }
			];
			
			props.measureIndex(`${props.currentTab}:Follow in 3D`, '', contextData);
        
			let interfaceLink = getInterfaceLink('openImmersive', {id: props.matchId});
			interfaceLink?.onclick();
		} 
    }

    return (
		<div className="immersiv-container">
			<div
				className="immersiv-cta"
				onClick={() => {
					launchImmersiv();
				}}>
				Follow in 3D
				<div className="arrow-icon">
					<i className="icon-arrow-right" />
					<i className="icon-arrow-right arrow" />
				</div>
			</div>
		</div>
	);
}

export default ImmersivCTA;
