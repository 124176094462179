/**
 TicketsModule Initial State
*/

export default {
  key: "value",

  // do not persist to local storage (default)
  // see https://www.npmjs.com/package/redux-local-persist
  persist: false
};
