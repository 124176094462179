/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';
import { fetch, getAppVideoLink } from 'appdir/components/general/Util';
import Slider from 'react-slick';
import sortBy from 'lodash/sortBy';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: MatchHighlights
 * -----------------------------------------------------------------------------
 */
const MatchHighlights = props => {
    let highlightPath = useSelector((state) => state?.['Config']?.relatedContent?.stMatchHighlights ?? '');
    const statMatch = useSelector((state) => state?.['ScoreManager']?.statMatch ?? {});
    const useHighlightTestData = useSelector((state) => state?.['Config']?.slamtracker?.useHighlightTestData ?? false);
    const [data, setData] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        logger.log('[MatchHighlights] statMatch:%o, highlightPath:%o', statMatch, highlightPath);
        if(statMatch.match_id && highlightPath){
            let matchId = statMatch.match_id;
            logger.log('[MatchHighlights] useHighlightTestData:%o', useHighlightTestData);
            
            if(useHighlightTestData){
                matchId = "2701";
            }
            
            highlightPath = highlightPath.replace("<matchId>", matchId);      
            fetch(highlightPath)
				.then(result => {
                    let highlightData = result?.content;
                    
                    if(statMatch.eventCode == "B"){ //if men's singles, filter out sets 2 and 5
                        highlightData = highlightData.filter(i => (!i.title.includes("Set 2") && !i.title.includes("Set 5")));
                    }
                    let sortedHighlights = sortBy(highlightData, ['displayDate']).reverse();
                    // logger.log('[MatchHighlights] highlightData:%o', highlightData);
                    logger.log('[MatchHighlights] sortedHighlights:%o', sortedHighlights);
					setData(sortedHighlights);
				})
				.catch(error => {
					logger.error('[MatchHighlights] error loading related content:%o', error);
				})
        }
    }, [statMatch.match_id, highlightPath]);
    // logger.log('[MatchHighlights] data:%o', data);

    const renderVideoItem = data => {
        const videoData = { 
			video: {
                id: data?.cmsId,
				title: data?.title,
				shareUrl: data?.shareUrl,
				poster: data?.images[0]?.xlarge
			},
			autoplay:'true'
		};

        if(!window.webview) {
            dispatch(deps.actions.ModalOverlay.loadOverlayModal('VIDEO_MODAL', videoData))
        } else {
            let videoLink = getAppVideoLink(data?.cmsId);
            videoLink?.onclick();
        }
	};
    
    if(data && data.length >= 1){

        let settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 2.1,
			slidesToScroll: 1,
		};

        return(
            <>
                <div className="section-title-header">
                    <span>Watch Highlights</span>
                </div>
                <div className={`match-highlights`}>                    
                    <Slider {...settings}>
                        {data.map(i => {
                            return <button 
                                className="highlight-button" 
                                onClick={() => {renderVideoItem(i);}}
                                onKeyDown={e => {renderVideoItem(i);}}
                                style={{width: "156px"}}
                                key={`highlight-${i.cmsId}`}
                                >
                                <div className="highlight-img">
                                    <img src={i?.images?.[0]?.medium} />
                                    <div className="playBtn" />
                                </div>
                                <div className="highlight-desc">
                                    {i?.title}
                                </div>
                            </button>
                        })} 
                    </Slider>           
                </div>
            </>
        )
    }else{
        return null;
    }
}

export default MatchHighlights;