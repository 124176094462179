/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import EventsLink from 'appdir/components/general/EventsLink';
import { determinePlayerHighlightClass } from 'appdir/components/pages/SchedulePage/ScheduleUtils';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ScoreTable
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['DrawsMatchBox'],
		// favorites: state['DrawsWebview'].favorite_players,
		favorites: state['Controller'].favorites,
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({});

class DrawsMatchBox extends Component {
	constructor(props) {
		// logger.log("[DrawsMatchBox] constructor");
		super(props);
		this.scoreData = this.props.attributes.data;
		this.showLinks = this.props.attributes.showLinks;

		this.winnerTeam = this.scoreData.team1.won ? 'team1' : 'team2';

		const mainProfilePath = '../players/overview/';
		const archiveProfilePath = './player_profile/';

		this.profileLinks = this.props.attributes.profileLinks === 'main' ? mainProfilePath : archiveProfilePath;

		this.boxStyle = this.props.attributes.style ? this.props.attributes.style : '';

		this.winnerClass_t1 = classNames(this.scoreData.team1.won ? 'winner' : '');
		this.winnerClass_t2 = classNames(this.scoreData.team2.won ? 'winner' : '');

		this.iconClasses_t1 = classNames('crticon', this.scoreData.team1.won ? 'winner' : '');
		this.iconClasses_t2 = classNames('crticon', this.scoreData.team2.won ? 'winner' : '');

		this.flagImagePathSmall = this.props.attributes.flagImagePathSmall;

		this.playerProfileImagePath = this.props.attributes.playerProfileImagePath;

		this.hasMatchInsights = this.props.attributes.hasMatchInsights;

		this.emptySets = [];
		for (var i = 0; i < 5 - this.scoreData.scores.sets.length && this.scoreData.scores.sets.length < 5; i++) {
			this.emptySets[i] = 5 - i;
		}
	}
	componentDidMount() {}
	componentWillUnmount() {}
	componentDidUpdate(prevProps, prevState) {
		const curAttributes = this.props.attributes;
		// logger.log('[DrawsMatchBox] componentDidUpdate state:%o', this.props);
	}

	onHover(pid) {
		logger.log('[DrawsMatchBox] onHover:%o, this:%o', pid, this);

		this.props.onHovered(pid);
	}

	getSlamTrackerLinkBox(active) {
		//stats level only set after match complete, so won't allow active pre/during match
		// if (
		//   active &&
		//   this.scoreData.statsLevel !== null &&
		//   this.scoreData.statsLevel !== "N"
		// ) {
		//   //if (active){
		//   return (
		//     <div className="slamtracker-stats">
		//       <EventsLink
		//         to={`/en_US/scores/stats/${this.scoreData.match_id}.html`}
		//         style="white"
		//       >
		//         <div className="slamtracker-stats_text">
		//           Match Details by IBM Slamtracker
		//         </div>
		//       </EventsLink>
		//     </div>
		//   );
		// } else {
		//   return (
		//     <a className="slamtracker-stats disabled">
		//       <div className="slamtracker-stats_text">
		//         Match Details by IBM Slamtracker
		//       </div>
		//     </a>
		//   );
		// }

		/**
		 * if the match has not begun and this match has match insights, show pre-match analysis button
		 */
		if (this.scoreData.statusCode == 'B' && this.hasMatchInsights) {
			if (window.webview) {
				return (
					<div className="slamtracker-stats">
						<EventsLink to={`/webview/en_US/matchinsights/${this.scoreData.match_id}.html`} external={true}>
							<div className="match-insights_text">Match Insights</div>
						</EventsLink>
					</div>
				);
			} else {
				return (
					<div className="slamtracker-stats">
						<EventsLink to={`/en_US/matchinsights/${this.scoreData.match_id}.html`}>
							<div className="match-insights_text">Match Insights</div>
						</EventsLink>
					</div>
				);
			}
		} else if (
			/**
			 * if the match has not begun and this match will not have match insights (not LS and not MS) show disabled slamtracker button
			 * -OR- if the match is Complete w/ a null eventDay, we will assume this is a bye and disable the slamtracker button
			 */
			(this.scoreData.statusCode == 'B' &&
				(this.scoreData.eventCode != 'MS' || this.scoreData.eventCode != 'LS') &&
				!this.hasMatchInsights) ||
			(this.scoreData.statusCode == 'D' && this.scoreData.eventDay === null)
		) {
			return (
				<a className="slamtracker-stats disabled">
					<div className="slamtracker-stats_text">Match Details by IBM Slamtracker</div>
				</a>
			);
		} else if (this.scoreData.statusCode != 'B') {
			/**
			 * if the match has begun, show the enabled slamtracker button
			 */
			return (
				<div className="slamtracker-stats">
					<EventsLink to={`/en_US/scores/stats/${this.scoreData.match_id}.html`} style="white">
						<div className="slamtracker-stats_text">Match Details by IBM Slamtracker</div>
					</EventsLink>
				</div>
			);
		}
	}

	checkEventStatus() {
		//logger.log('[DrawsMatchBox] checkEventStatus scoreData:%o', this.scoreData);
		if (
			this.scoreData.statusCode == 'B' &&
			(this.scoreData.eventCode == 'MS' || this.scoreData.eventCode == 'WS') &&
			(this.scoreData.team1.idA !== null || this.scoreData.team2.idA !== null)
		) {
			return (
				<EventsLink
					to={`https://www.ticketmaster.com/usopentennis?cid=TMSchedule_07122021_phos_chluso_ctusot_endcid`}>
					<div className="buy-tickets-btn">Buy Tickets</div>
				</EventsLink>
			);
		} else if (
			(this.scoreData.statusCode !== null && this.scoreData.statusCode == 'E') ||
			this.scoreData.statusCode == 'F' ||
			this.scoreData.statusCode == 'G'
		) {
			return (
				<div className="event-notes">
					<span className="update">({this.scoreData.status})</span>
				</div>
			);
		}
	}

	checkWonStatus(team, index) {
		// return class name if won
		let wonclass = this.scoreData.scores.setsWon[parseInt(index, 10) + 1] === team ? 'won' : '';

		return wonclass;
	}

	isSearchedPlayer(searchePlayerId, playerId) {
		return searchePlayerId === playerId ? 'search' : '';
	}

	renderRegularMatchBox() {
		let t1_higlight_condition =
			this.props.highlightPid !== '' &&
			(this.props.highlightPid === this.scoreData.team1.idA ||
				this.props.highlightPid === this.scoreData.team1.idB) &&
			(this.scoreData.team1.idA !== '' ||
				this.scoreData.team1.idB !== '' ||
				this.scoreData.team1.idA !== null ||
				this.scoreData.team1.idB !== null);

		let t2_higlight_condition =
			this.props.highlightPid !== '' &&
			(this.props.highlightPid === this.scoreData.team2.idA ||
				this.props.highlightPid === this.scoreData.team2.idB) &&
			(this.scoreData.team2.idA !== '' ||
				this.scoreData.team2.idB !== '' ||
				this.scoreData.team2.idA !== null ||
				this.scoreData.team2.idB !== null);

		let hilightClass_t1 = t1_higlight_condition ? 'highlight' : '';
		let hilightClass_t2 = t2_higlight_condition ? 'highlight' : '';

		// set up to show Favorite stars on webviews if Favorite players are passed
		// let favorite_t1_a = '';
		// let favorite_t1_b = '';
		// let favorite_t2_a = '';
		// let favorite_t2_b = '';

		// if (this.props.favorites) {
		// 	favorite_t1_a = this.props.favorites.indexOf(this.scoreData.team1.idA) !== -1 ? 'show' : '';
		// 	favorite_t1_b = this.props.favorites.indexOf(this.scoreData.team1.idB) !== -1 ? 'show' : '';
		// 	favorite_t2_a = this.props.favorites.indexOf(this.scoreData.team2.idA) !== -1 ? 'show' : '';
		// 	favorite_t2_b = this.props.favorites.indexOf(this.scoreData.team2.idB) !== -1 ? 'show' : '';
		// }

		let allowSlamtrackerLink = this.scoreData.courtId && this.scoreData.team1.idA && this.scoreData.team2.idA;

		return (
			<div
				className={`match-box match-box-draws ${this.boxStyle}`}
				data-dna="match-box-draws-page"
				data-match={this.scoreData.match_id}>
				<div className="match-status">
					<div
						className={`team-info team-one ${
							this.scoreData.team1.displayNameB != null ? 'doubles' : 'singles'
						} ${this.winnerClass_t1} ${hilightClass_t1}
						
						${determinePlayerHighlightClass(this.scoreData.team1.idA, this.props?.favorites, this.props.attributes.searchedPlayer)}
						${determinePlayerHighlightClass(
							this.scoreData.team1.idB,
							this.props?.favorites,
							this.props.attributes.searchedPlayer
						)}`}
						onMouseEnter={() => this.props.onHovered(this.scoreData.team1.idA)}
						onMouseLeave={() => this.props.onHovered('')}>
						<div className="team-name">
							{/* <div className="member-one" onClick={(e) => {this.onLinkClick(e,`${this.profileLinks}${this.scoreData.team1.idA}.html`)}} > */}
							<div
								className={`member-one 
								 ${this.scoreData.team1.idA}`}>
								{/* <div className={`favorite ${favorite_t1_a}`} /> */}
								<div className="flag">
									<img
										alt={this.scoreData.team1.nationA}
										src={
											this.flagImagePathSmall
												? this.flagImagePathSmall.replace(
														'<code>',
														this.scoreData.team1.nationA
												  )
												: null
										}
									/>
								</div>
								<div className="name">
									<EventsLink to={`${this.profileLinks}${this.scoreData.team1.idA}.html`}>
										{this.scoreData.team1.displayNameA}
									</EventsLink>
								</div>
								<div className={`seed ${this.scoreData.team1.seed ? '' : 'hide'}`}>
									[{this.scoreData.team1.seed}]
								</div>
							</div>
							{/* <div className="member-two" onClick={(e) => {this.onLinkClick(e,`${this.profileLinks}${this.scoreData.team1.idB}.html`)}}> */}
							<div className={`member-two ${this.scoreData.team1.idB}`}>
								{/* <div className={`favorite ${favorite_t1_b}`} /> */}
								<div className="flag">
									<img
										alt={this.scoreData.team1.nationB}
										src={
											this.flagImagePathSmall
												? this.flagImagePathSmall.replace(
														'<code>',
														this.scoreData.team1.nationB
												  )
												: null
										}
									/>
								</div>
								<div className="name">
									<EventsLink to={`${this.profileLinks}${this.scoreData.team1.idB}.html`}>
										{this.scoreData.team1.displayNameB}
									</EventsLink>
								</div>
								<div className={`seed ${this.scoreData.team1.seed ? '' : 'hide'}`}>
									[{this.scoreData.team1.seed}]
								</div>
							</div>
						</div>
						<div className="match-result-icon">
							<div className={this.iconClasses_t1}>
								<i className="icon-check" />
								<i className="icon-ballSolid" />
							</div>
						</div>
						<div className="match-results">
							<div className="results-wrapper">
								{/* <div className={this.iconClasses_t1}>
                                    <i className="icon-check"></i>
                                    <i className="icon-ballSolid"></i>
                                </div> */}
								{this.scoreData.scores.sets.map((d1, i) => {
									let setno = parseInt(i, 10) + 1;
									let wonclass = this.checkWonStatus(1, i);
									return (
										<div key={i} className={`set set${setno} ${wonclass}`}>
											<span className="scores">
												{d1[0].scoreDisplay}
												<span className="tiebreak">
													{d1[0].tiebreakDisplay ? d1[0].tiebreakDisplay : ''}
												</span>
											</span>
										</div>
									);
								})}
								{this.emptySets.map((set, i) => {
									return (
										<div key={i} className={`set set${set}`}>
											<span className="scores">
												<span className="tiebreak" />
											</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className="results-info">
						<div className="results-info-status">&nbsp;</div>
						<div className="results-info-pts">PTS</div>
						<div className="results-info-set">1</div>
						<div className="results-info-set">2</div>
						<div className="results-info-set">3</div>
						<div className="results-info-set">4</div>
						<div className="results-info-set">5</div>
					</div>
					<div
						className={`team-info team-two ${
							this.scoreData.team2.displayNameB != null ? 'doubles' : 'singles'
						}  ${this.winnerClass_t2} ${hilightClass_t2}
						${determinePlayerHighlightClass(this.scoreData.team2.idA, this.props?.favorites, this.props.attributes.searchedPlayer)}
						${determinePlayerHighlightClass(
							this.scoreData.team2.idB,
							this.props?.favorites,
							this.props.attributes.searchedPlayer
						)}`}
						onMouseEnter={() => this.props.onHovered(this.scoreData.team2.idA)}
						onMouseLeave={() => this.props.onHovered('')}>
						<div className="team-name">
							<div className={`member-one  ${this.scoreData.team2.idA}`}>
								{/* <div className={`favorite ${favorite_t2_a}`} /> */}
								<div className="flag">
									<img
										alt={this.scoreData.team2.nationA}
										src={
											this.flagImagePathSmall
												? this.flagImagePathSmall.replace(
														'<code>',
														this.scoreData.team2.nationA
												  )
												: null
										}
									/>
								</div>
								<div className="name">
									<EventsLink to={`${this.profileLinks}${this.scoreData.team2.idA}.html`}>
										{this.scoreData.team2.displayNameA}
									</EventsLink>
								</div>
								<div className={`seed ${this.scoreData.team2.seed ? '' : 'hide'}`}>
									[{this.scoreData.team2.seed}]
								</div>
							</div>
							<div className={`member-two ${this.scoreData.team2.idB}`}>
								{/* <div className={`favorite ${favorite_t2_b}`} /> */}
								<div className="flag">
									<img
										alt={this.scoreData.team2.nationB}
										src={
											this.flagImagePathSmall
												? this.flagImagePathSmall.replace(
														'<code>',
														this.scoreData.team2.nationB
												  )
												: null
										}
									/>
								</div>
								<div className="name">
									<EventsLink to={`${this.profileLinks}${this.scoreData.team2.idB}.html`}>
										{this.scoreData.team2.displayNameB}
									</EventsLink>
								</div>
								<div className={`seed ${this.scoreData.team2.seed ? '' : 'hide'}`}>
									[{this.scoreData.team2.seed}]
								</div>
							</div>
						</div>
						<div className="match-result-icon">
							<div className={this.iconClasses_t2}>
								<i className="icon-check" />
								<i className="icon-ballSolid" />
							</div>
						</div>
						<div className="match-results">
							<div className="results-wrapper">
								{/* <div className={this.iconClasses_t2}>
                                    <i className="icon-check"></i>
                                    <i className="icon-ballSolid"></i>
                                </div> */}
								{this.scoreData.scores.sets.map((d1, i) => {
									let setno = parseInt(i, 10) + 1;
									let wonclass = this.checkWonStatus(2, i);

									return (
										<div key={i} className={`set set${setno} ${wonclass}`}>
											<span className="scores">
												{d1[1].scoreDisplay}
												<span className="tiebreak">
													{d1[1].tiebreakDisplay ? d1[1].tiebreakDisplay : ''}
												</span>
											</span>
										</div>
									);
								})}
								{this.emptySets.map((set, i) => {
									return (
										<div key={i} className={`set set${set}`}>
											<span className="scores">
												<span className="tiebreak" />
											</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					{this.checkEventStatus()}
				</div>
				{this.showLinks ? (
					<div className="match-box-buttons">
						<a className="watch-and-read">Watch &amp; Read</a>
						{this.getSlamTrackerLinkBox(allowSlamtrackerLink)}
					</div>
				) : null}
			</div>
		);
	}

	renderBrackets(isFinalRd) {
		let finalBraketClass = isFinalRd ? 'final' : '';

		return (
			<>
				<div className={`connector ${finalBraketClass}`}>
					<div className="merger" />
					<div className="line" />
				</div>
			</>
		);
	}

	renderWinnerMatchBox() {
		let winner_higlight_condition =
			this.props.highlightPid !== '' &&
			(this.props.highlightPid === this.scoreData[this.winnerTeam].idA ||
				this.props.highlightPid === this.scoreData[this.winnerTeam].idB) &&
			(this.scoreData[this.winnerTeam].idA !== '' ||
				this.scoreData[this.winnerTeam].idB !== '' ||
				this.scoreData[this.winnerTeam].idA !== null ||
				this.scoreData[this.winnerTeam].idB !== null);

		let hilightClass_winner = winner_higlight_condition ? 'highlight' : ''; // set up to show Favorite stars on webviews if Favorite players are passed

		let favorite_winner_a = '';
		let favorite_winner_b = '';

		if (this.props.favorites) {
			favorite_winner_a =
				this.props?.favorites?.players?.indexOf(this.scoreData[this.winnerTeam].idA) !== -1 ? 'show' : '';
			favorite_winner_b =
				this.props?.favorites?.players?.indexOf(this.scoreData[this.winnerTeam].idB) !== -1 ? 'show' : '';
		}

		return (
			<div
				className={`match-box match-box-draws winner ${this.boxStyle}`}
				data-match={this.scoreData.id}
				data-dna="match-box-draws-winner">
				<div className="match-status">
					<div
						className={`team-info team-one ${
							this.scoreData[this.winnerTeam].displayNameB !== null ? 'doubles' : 'singles'
						} ${hilightClass_winner}`}
						onMouseEnter={() => this.onHover(this.scoreData[this.winnerTeam].idA)}
						onMouseLeave={() => this.onHover('')}>
						<div className="team-name">
							<div className="member-one">
								<div className={`favorite ${favorite_winner_a}`} />
								<div className="flag">
									<img
										alt={this.scoreData[this.winnerTeam].nationA}
										src={
											this.flagImagePathSmall
												? this.flagImagePathSmall.replace(
														'<code>',
														this.scoreData[this.winnerTeam].nationA
												  )
												: null
										}
									/>
								</div>
								<div className="name">
									<EventsLink to={`${this.profileLinks}${this.scoreData[this.winnerTeam].idA}.html`}>
										{this.scoreData[this.winnerTeam].displayNameA}
									</EventsLink>
								</div>
								<div className={`seed ${this.scoreData[this.winnerTeam].seed ? '' : 'hide'}`}>
									[{this.scoreData[this.winnerTeam].seed}]
								</div>
							</div>
							<div className="member-two">
								<div className={`favorite ${favorite_winner_b}`} />
								<div className="flag">
									<img
										alt={this.scoreData[this.winnerTeam].nationB}
										src={
											this.flagImagePathSmall
												? this.flagImagePathSmall.replace(
														'<code>',
														this.scoreData[this.winnerTeam].nationB
												  )
												: null
										}
									/>
								</div>
								<div className="name">
									<EventsLink to={`${this.profileLinks}${this.scoreData[this.winnerTeam].idB}.html`}>
										{this.scoreData[this.winnerTeam].displayNameB}
									</EventsLink>
								</div>
								<div className="seed">[{this.scoreData[this.winnerTeam].seed}]</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		this.emptySets.reverse();

		if (this.scoreData.roundCode === 'W' || this.scoreData.roundCode === 'WQ') {
			return <>{this.renderWinnerMatchBox()}</>;
		} else if (this.scoreData.roundCode === 'C') {
			// Challenge Match
			return (
				<>
					{this.renderRegularMatchBox()}
					{this.renderWinnerMatchBox()}
				</>
			);
		} else if (this.props.attributes.drawBrackets) {
			return (
				<>
					{this.renderRegularMatchBox()}
					{this.props.attributes.isFinalRd ? this.renderBrackets(true) : this.renderBrackets()}
				</>
			);
		} else {
			return <>{this.renderRegularMatchBox()}</>;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawsMatchBox);
