/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Hero
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const Hero = props => {
	//logger.log('[Hero] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	//logger.log('[Hero] - attr:%o', attributes);

	if (attributes && attributes.images) {
		let image = attributes['images']['image'];

		return (
			<section className={`hero_container`} style={{ backgroundImage: `url(${image.url})` }}>
				<div className={`hero_content`}>
					<div className={`title_container`}>
						<div className={`landing_title`}>
							<h1>{attributes['title']}</h1>
							{image.caption}
						</div>
					</div>
				</div>
			</section>
		);
	} else {
		return null;
	}
};

export default Hero;
