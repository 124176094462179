import React, { Component } from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import get from 'lodash/get';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * @GalleryView
 * Image gallery for photos page
 * Props:
 *  galleryData
 *  imageSize
 *  imagePrefix
 * -----------------------------------------------------------------------------
 */
class GalleryView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			currentIndex: 0,
		};
		this.viewedIndex;
		this.shareClick = this.shareClick.bind(this);
	}

	componentDidMount() {
		this.viewedIndex = this.props.selectedItem;
	}

	shareClick(type, caption) {
		MeasurementUtils.dispatchMeasurementCall(`Photos${type}Click`, { caption });
	}

	onCarouselChange(e) {
		logger.log('[GalleryView] onchange:%o', e);
		let item = this.props.galleryData[e];
		if (e > this.viewedIndex) {
			MeasurementUtils.dispatchMeasurementCall('PhotosGalleryNext', {
				caption: item.title,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('PhotosGalleryPrev', {
				caption: item.title,
			});
		}
		this.viewedIndex = e;
	}

	renderGallery() {
		logger.log('[GalleryView] this.props.galleryData:%o', this.props.galleryData);
		let imageSize = this.props.imageSize;
		return this.props.galleryData ? (
			<div className="inline-carousel-container">
				<ResponsiveCarousel
					showThumbs={false}
					useKeyboardArrows={true}
					infiniteLoop
					showIndicators={false}
					centerMode
					dynamicHeight={true}
					centerSlidePercentage={90}
					selectedItem={this.props.selectedItem}
					onChange={e => this.onCarouselChange(e)}>
					{this.props.galleryData.map((item, i) => (
						<div key={`slide-${i}`}>
							<img src={get(item, `images[0][${imageSize}]`, '')} alt={item.title || item.caption} />
							<div className="slider-caption">
								{item.description}
								<div className="gallery share-bar white">
									<FacebookShareButton
										url={this.props.imagePrefix + item.shareUrl}
										quote={item.description}
										beforeOnClick={() => this.shareClick('Facebook', item.title)}>
										<img
											src="/assets/images/nav/social_icons/facebook_white.png"
											alt="facebook"
											border="0"
										/>
									</FacebookShareButton>
									<TwitterShareButton
										url={this.props.imagePrefix + item.shareUrl}
										title={item.description}
										beforeOnClick={() => this.shareClick('Twitter', item.title)}>
										<img
											src="/assets/images/nav/social_icons/twitter_white.png"
											alt="twitter"
											border="0"
										/>
									</TwitterShareButton>
								</div>
							</div>
						</div>
					))}
				</ResponsiveCarousel>
			</div>
		) : null;
	}

	render() {
		logger.log('[GalleryView] render - state:%o ', this.state);
		logger.log('[GalleryView] render - props:%o ', this.props);
		let imageSize = this.props.imageSize;

		return this.props.galleryData ? (
			<span>
				<div className={`Gallery-page photo`}>
					{this.props.galleryData.content ? (
						this.props.galleryData.content.length > 0 ? (
							this.props.galleryData[this.state.currentIndex].title !== '' ? (
								<div className="slider-title-mobile">
									{this.props.galleryData[this.state.currentIndex].title}
									<span className="gallery-credit-mobile">
										{this.props.galleryData[this.state.currentIndex].credit}
									</span>
								</div>
							) : null
						) : null
					) : this.props.galleryData.title !== '' ? (
						<div className="slider-title-mobile">
							{this.props.galleryData[this.state.currentIndex].title}
							<span className="gallery-credit-mobile">
								{/* this.props.galleryData["photos"][0].credit */}
							</span>
						</div>
					) : null}
					<i
						onClick={() => {
							this.props.closeGallery();
						}}
						className="icon-close"
					/>
					{this.renderGallery()}
				</div>
			</span>
		) : null;
	}
}

export default GalleryView;
