/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import deps from "dependencies";

/* page elements */
import LoadingIndicator from "appdir/components/common-ui/LoadingIndicator";
import MatchBox from "appdir/components/_scoring/MatchBox";

/**
 * -----------------------------------------------------------------------------
 * React Component: GroundsMapMatchBox
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
  return {
    ...state["GroundsMapMatchBox"],
    ScoreManager: state["ScoreManager"],
    scoring: state["Controller"].scoring,
    controllerLoaded: state["Controller"].loaded,
    ...props
  };
};

const mapDispatchToProps = dispatch => ({
  mount: params => dispatch(deps.actions.GroundsMapMatchBox.mount(params)),
  unmount: () => dispatch(deps.actions.GroundsMapMatchBox.unmount()),
  setScoringStatus: data =>
    dispatch(deps.actions.Controller.setScoringStatus(data))
});

class GroundsMapMatchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props
    };
    this.requestedMatchChecked = false;
    this.requestedMatch = null;
    this.scoresInit = false;

    // logger.log("[GroundsMapMatchBox] constructor this:%o", this);
  }

  componentDidMount() {
    // logger.log("[GroundsMapMatchBox] componentDidMount");
  }

  componentDidUpdate(prevProps, prevState) {
    // logger.log(
    //   "[GroundsMapMatchBox] componentDidUpdate: prevProps:%o, prevState:%o",
    //   prevProps,
    //   prevState
    // );

    if (
      !this.scoresInit &&
      this.state.scoring &&
      !this.state.scoring.mip &&
      this.state.controllerLoaded
    ) {
      logger.log(
        "[GroundsMapMatchBox] componentDidUpdate set status of mip in controller"
      );
      this.scoresInit = true;
      this.state.setScoringStatus({ mip: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    // logger.log('[GroundsMapMatchBox] componentWillReceiveProps: nextProps:%o', nextProps);
    this.setState(prevState => ({
      ...prevState,
      ...nextProps
    }));
  }

  componentWillUnmount() {
    // logger.log('[GroundsMapMatchBox] redirect unmounting');
    this.state.unmount();
  }

  setRequestedMatch() {
    // logger.log(
    //   "[GroundsMapMatchBox] setRequestedMatch - state:%o, requestedMatch:%o",
    //   this.state,
    //   this.requestedMatch
    // );

    let { liveMatches } = this.state.ScoreManager;
    let { court } = this.state;

    if (liveMatches.length > 0 && court) {
      this.requestedMatch = liveMatches.find(function(match) {
        return match.courtId == court;
      });
    }

    // logger.log(
    //   "[GroundsMapMatchBox] setRequestedMatch - requestedMatch:%o",
    //   this.requestedMatch
    // );

    if (
      (typeof this.requestedMatch !== "undefined" &&
        this.requestedMatch.scores) ||
      typeof this.requestedMatch === "undefined"
    ) {
      this.requestedMatchChecked = true;
    }
  }

  render() {
    logger.log(
      "[GroundsMapMatchBox] render - state: %o, requestedMatch:%o, requestedMatchChecked:%o",
      this.state,
      this.requestedMatch,
      this.requestedMatchChecked
    );

    if (this.state.ScoreManager && this.state.ScoreManager.liveMatches) {
      // try to set requestedMatch if we have live scores and no requested match and we haven't already checked for a requested match
      if (
        this.state.ScoreManager.liveMatches.length > 0 &&
        !this.requestedMatchChecked
      ) {
        this.setRequestedMatch();
      }

      // no live matches, or I have live matches and a requested match, but no scores for the requested match
      if (
        this.state.ScoreManager.liveMatches.length == 0 ||
        (this.state.ScoreManager.liveMatches.length > 0 &&
          this.requestedMatch &&
          !this.requestedMatch.scores)
      ) {
        return <LoadingIndicator />;
      }
      // live matches, requested match, and scores for requested match
      else if (
        this.requestedMatchChecked &&
        this.state.ScoreManager.liveMatches.length > 0 &&
        this.requestedMatch &&
        this.requestedMatch.scores
      ) {
        return (
          <div className="grounds-map-matchbox">
            <MatchBox
              attributes={{
                data: this.requestedMatch,
                style: "live",
                event: this.requestedMatch.shortEventName,
                tableHeader: this.requestedMatch.courtName,
                showLinks: false,
                suppressProfileLink: true
              }}
            />
          </div>
        );
      }
      // live matches and no requested match
      else if (
        this.requestedMatchChecked &&
        this.state.ScoreManager.liveMatches.length > 0 &&
        !this.requestedMatch
      ) {
        return (
          <div className="grounds-map-matchbox no-content">
            No active match on this court
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroundsMapMatchBox);
