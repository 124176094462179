/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import isEqual from 'lodash/isEqual';
import ReactHtmlParser from 'html-react-parser';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MatchBox from 'appdir/components/_scoring/MatchBox';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import StubBox from 'appdir/components/common-ui/StubBox';
import { windowSizeVals, getAvailablePlayers, scrollIntoViewWithOffset } from 'appdir/components/general/Util';
import AdTag from 'appdir/components/general/AdTag';
import AdUnitLazy from 'appdir/components/general/AdUnitLazy';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import filter from 'lodash/filter';

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveScores
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LiveScores'],
		resultsPage: state['ResultsPage'],
		ScoreManager: state['ScoreManager'],
		scoring: state['Controller'].scoring,
		configScoring: state['Config'].scoring,
		controllerLoaded: state['Controller'].loaded,
		stubs: state['Config'].stubPages,
		windowSize: state['Controller'].windowSize,
		windowSizeVal: state['Controller'].windowSizeVal,
		EventsWindow: state['WindowSize'].EventsWindow,
		adConfig: state['Config'].adConfig,
		newPath: state?.['MainNav']?.newPath,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveScores.mount()),
	unmount: () => dispatch(deps.actions.LiveScores.unmount()),
	unmountMainNav: data => dispatch(deps.actions.MainNav.unmount(data)),
	setScoringStatus: data => dispatch(deps.actions.Controller.setScoringStatus(data)),
});

class LiveScores extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};

		this.init = true;
		this.ads_refresh = true;
		this.setPlayersPlaying = true;

		logger.log('[LiveScores] constructor this:%o', this);
	}

	componentDidMount() {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'LiveScores',
		});
		//forces a state change so that componentDidUpdate is hit on page load
		this.setState({ mounted: true });
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[LiveScores] componentDidUpdate this:%o', this);

		if(this.props?.newPath) {
			// remove nav redirect from slamtracker if it exists
			this.props.unmountMainNav({
				newPath: null,
				nav: null,
			});
		}

		if (this.props.stubs) {
			if (
				this.init &&
				this.props.status == 'load' &&
				this.props.controllerLoaded &&
				this.props.stubs &&
				this.props.stubs.scores.stub !== 'stub'
			) {
				// logger.log('[LiveScores] componentDidUpdate dispatch setScoringStatus');
				this.init = false;
				this.props.setScoringStatus({ mip: true });
			} else if (this.props.scoring && !this.props.scoring.mip && this.props.stubs.scores.stub !== 'stub') {
				this.props.setScoringStatus({ mip: true });
			}
		}
		if (this.state.scoringErrors) {
			logger.error('[LiveScores] componentDidUpdate scoringError: %o', this.state.scoringErrors);
			this.setState({
				hasError: true,
			});
		}

		/** live score is loaded or updated - find the searchable players */
		let curLiveScoresData = this.props?.ScoreManager?.liveMatches;
		let prevLiveScoresData = prevProps?.ScoreManager?.liveMatches;
		if (
			!isEqual(curLiveScoresData, prevLiveScoresData) ||
			(this.props?.scoring?.mip && !this.state.playersPlaying && this.setPlayersPlaying)
		) {
			let playersPlaying = this.getPlayersPlaying(curLiveScoresData);

			// added check for making sure returning the page set playersPlaying state
			this.setPlayersPlaying = false;

			this.setState({
				playersPlaying,
			});
		}
	}

	componentWillUnmount() {
		// sending mip:true tells controller that we are turning mip page off
		//this.state.setScoringStatus({ mip: false });
		clearTimeout(this.adRefresh_timeout);
		this.props.unmount();
	}

	renderMatch(match) {
		// logger.log("[LiveScores] renderMatch - match:%o ", match);
		if (match.scores) {
			return (
				<div
					key={match.match_id}
					className={`one-col ${match.team1.idA}${match.team1.idB ? ' ' + match.team1.idB : ''} ${
						match.team2.idA
					}${match.team2.idB ? ' ' + match.team2.idB : ''}`}>
					<MatchBox
						key={`matchbox-${match.match_id}`}
						attributes={{
							data: match,
							style: 'live',
							mode: 'live',
							event: match.shortEventName,
							tableHeader: match.courtName,
							showLinks: true,
							liveVideo: true,
							searchedPlayer: this.state?.searchedPlayer,
						}}
					/>
				</div>
			);
		} else {
			return null;
		}
	}

	/**
	 * This function splits an array up into equal size arrays of your choosing
	 * @param {*} arr - array to chunk
	 * @param {*} size - size you want to chunk it to
	 */
	chunkArray(arr, size) {
		let chunked = [];
		for (let ele of arr) {
			let last = chunked[chunked.length - 1];
			if (!last || last.length === size) {
				chunked.push([ele]);
			} else {
				last.push(ele);
			}
		}
		return chunked;
	}

	getPlayersPlaying = (matches = this.props?.ScoreManager?.liveMatches) => {
		let playersPlaying;

		if (matches?.length > 0) {
			playersPlaying = getAvailablePlayers(matches);
		}

		return playersPlaying;
	};

	onClick = value => {
		// logger.log('[LiveScores] onClick - value:%o', value);
		let searchedPlayer = value;
		this.setState({ searchedPlayer }, () => {
			//get element page offset
			let el = document.querySelector(`.${searchedPlayer?.id}`);

			const stickyMenu = document.querySelector(`#uso-header.sticky`);
			const stickyFavorites = document.querySelector(`.favorites-details.sticky`);
			const isMobile = this.props?.EventsWindow?.windowSize == 'mobile';

			let offset = stickyFavorites ? 96 : stickyMenu ? 150 : 200;

			if (isMobile) {
				offset = stickyFavorites ? 116 : stickyMenu ? 170 : 470;
			}

			if (el) {
				scrollIntoViewWithOffset(`.${searchedPlayer?.id}`, offset);
			}
		});
	};

	render() {
		// logger.log('[LiveScores] render - this:%o', this);

		let header_propsData = {
			headerType: 'scores',
			title: 'Live Scores',
			metaTitle: 'Live Scores',
			metaDescription: 'Stay up to date with live scoring and results from every match at the US Open, including singles and doubles plus the junior and wheelchair tournaments.',
			metaDate: '',
			metaPlayers: '',
			canonicalLink: 'https://www.usopen.org/en_US/scores/index.html'
		};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'http://www.ibm.com/sports/usopen',
				title: 'Presented by',
				name: 'IBM',
				imgClass: '',
				imgSrc: '/assets/images/logos/ibm_logo_black.png',
				tagline: 'Presented by',
			},
			page_header: this.props?.configScoring?.liveScoresText,
			title: this.props?.configScoring?.liveScoresText,
		};
		let { liveMatches } = this.props.ScoreManager;
		let eventFilter = this.props.resultsPage?.filters?.event;
		if (eventFilter && eventFilter != 'AE') {
			liveMatches = filter(liveMatches, o => {
				return o.eventCode == eventFilter;
			});
		}

		if (this.props.stubs && this.props.stubs.scores.stub === 'stub') {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: header_propsData.title,
								message: this.props.stubs.scores.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.state.hasError) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: header_propsData.title,
								subTitle: '',
								message: 'Live scores are unavailable at this time',
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (
			((liveMatches && liveMatches.length > 0) || (eventFilter && eventFilter != 'AE')) &&
			this.props.windowSize
		) {
			let chunkedMatches;
			if (this.props.windowSizeVal == windowSizeVals.MOBILE_WINSIZE) {
				chunkedMatches = this.chunkArray(liveMatches, 2);
			} else if (this.props.windowSizeVal <= windowSizeVals.SMDESKTOP_WINSIZE) {
				chunkedMatches = this.chunkArray(liveMatches, 4);
			} else if (this.props.windowSizeVal >= windowSizeVals.DESKTOP_WINSIZE) {
				chunkedMatches = this.chunkArray(liveMatches, 6);
			}

			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<h1 className="header" style={{ marginBottom: '0px' }}>
							{this.props?.configScoring?.liveScoresText}
						</h1>
						<ErrorBoundary message="Unable to render content.">
							<PageSubMenu
								mode="Scores"
								selected="Live Scores"
								filters={null}
								showFavorites={
									liveMatches && liveMatches.length == 0 && this.props?.stubs?.nomatches
										? false
										: true
								}
								playersData={this.state?.playersPlaying}
								searchedPlayer={this.state.searchedPlayer?.id}
								onClick={this.onClick}
							/>
						</ErrorBoundary>
						{liveMatches && liveMatches.length == 0 ? (
							<>
								<div className="match-content">
									<div className="column-layout scoresgrid">
										<GenericError message="There are no live scores for the selected day and event." />
										{/* {(this.props.windowSizeVal >= windowSizeVals.DESKTOP_WINSIZE &&
										liveMatches.length <= 6) ||
										(this.props.windowSizeVal !== windowSizeVals.DESKTOP_WINSIZE &&
											this.props.windowSizeVal !== windowSizeVals.MOBILE_WINSIZE &&
											liveMatches.length <= 4) ? ( */}
										<div className="mip-ad-container">
											{/* <AdTag
													adConfig={this.props.adConfig.mip}
													dfpNetworkId={this.props.adConfig.dfpNetworkId}
												/> */}
											<AdUnitLazy data={{ adType: 'mip' }} />
										</div>
										{/* ) : null} */}
									</div>
								</div>
							</>
						) : (
							<ErrorBoundary message="Live Scores are unavailable at this time.">
								<div className="livescores-content">
									{chunkedMatches.map((chunk, index) => (
										<React.Fragment key={`frag${index}`}>
											<div className="column-layout scoresgrid">
												{chunk.map((item, index2) => this.renderMatch(item))}
											</div>
											{(index !== chunkedMatches.length - 1 && // - 2022 ads no long cap at 3 rows anymore
												this.props.windowSizeVal >= windowSizeVals.DESKTOP_WINSIZE &&
												(chunk.length > 3 || liveMatches.length <= 6)) ||
											(this.props.windowSizeVal == windowSizeVals.MOBILE_WINSIZE &&
												chunk.length > 0) ||
											(this.props.windowSizeVal !== windowSizeVals.DESKTOP_WINSIZE &&
												this.props.windowSizeVal !== windowSizeVals.MOBILE_WINSIZE &&
												(chunk.length > 2 || liveMatches.length <= 4)) ? (
												<div className="mip-ad-container">
													<AdTag
														adConfig={this.props.adConfig.mip}
														dfpNetworkId={this.props.adConfig.dfpNetworkId}
													/>
												</div>
											) : null}
										</React.Fragment>
									))}
								</div>
							</ErrorBoundary>
						)}
					</section>
				</Template>
			);
		} else if (liveMatches && liveMatches.length == 0 && this.props.stubs) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<h1 className="header" style={{ marginBottom: '0px' }}>
							Live Scores
						</h1>
						<ErrorBoundary message="Unable to render content.">
							<PageSubMenu
								mode="Scores"
								selected="Live Scores"
								filters={null}
								showFavorites={
									liveMatches && liveMatches.length == 0 && this.props?.stubs?.nomatches
										? false
										: true
								}
								playersData={this.state?.playersPlaying}
								searchedPlayer={this.state.searchedPlayer?.id}
								onClick={this.onClick}
							/>
						</ErrorBoundary>
						{/* <div className="livescores-content"> */}
						<div className="match-content">
							<div className="column-layout scoresgrid">
								<div className="message">{ReactHtmlParser(this.props?.stubs?.nomatches?.text)}</div>
								<div className="mip-ad-container">
									{/* <AdTag
											adConfig={this.props.adConfig.mip}
											dfpNetworkId={this.props.adConfig.dfpNetworkId}
										/> */}
									<AdUnitLazy data={{ adType: 'mip' }} />
								</div>
							</div>
						</div>
						{/* </div> */}
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<div className="content-main">
							<LoadingIndicator />
						</div>
					</section>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveScores);
