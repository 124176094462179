/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import throttle from 'lodash/throttle';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { isIE, deviceDetect } from 'react-device-detect';
// import { throttle } from 'lodash';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import EventsLink from 'appdir/components/general/EventsLink';
import Time from 'appdir/components/common-ui/Time';
import IBMwatsonx from 'appdir/components/common-ui/IBMwatsonx/index.js';
/**
 * -----------------------------------------------------------------------------
 * React Component: PageHeader
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['PageHeader'],
		userOpen: state['Controller']?.sidepanel?.userOpen,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PageHeader.mount()),
	setWindowSize: windowsize => dispatch(deps.actions.PageHeader.setWindowSize(windowsize)),
	sidepanelExpanded: expanded => dispatch(deps.actions.PageHeader.sidepanelExpanded(expanded)),
});

const b_image_ratio = 1.5;
const h_image_ratio = 1.55;
const j_image_ratio = 1.78;
const video_image_ratio = 1.78;

const mobileMatch = window.matchMedia('(max-width: 767px)');
const tabletMatch = window.matchMedia('(max-width: 1023px)');
const desktopMatch = window.matchMedia('(max-width: 1364px)');
const midDesktopMatch = window.matchMedia('(min-width: 1365px) and (max-width: 1600px)');
const largeDesktopMatch = window.matchMedia('(min-width: 1601px)');

class PageHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoData: null,
		};
		//logger.log('[PageHeader] constructor - props:%o, state:%o', props, this.state);

		this.pageWrapper = null; // the page wrapper
		this.pageHeader = null; // header of a page, includes bg image and title
		this.contentMain = null; // main content area of page
		this.contentFiltersScroll = null; // indicates top position of filters
		this.bgImage = null; // bg image div of the page header
		this.bgSpace = null; // bg space div
		this.titleElem = null; // title elem within the page header
		this.scrollElem = null; // element to check for header collapse during scroll, generally content-main
		this.pageTxt = null; // page title text (section title)
		this.contentTxt = null; // specific title related to particular page content
		this.video = null; //reference to bg video tag

		this.imageRatio = b_image_ratio;
		this.videoRatio = video_image_ratio;

		this.lastWindowSize = '';
		this.fixedHeader = false;
		this.fixedBeforeRender = false;

		this.checkWindowSizes = this.checkWindowSizes.bind(this);

		this.ieCheck = isIE || deviceDetect().browserName == 'IE';

		logger.log('[PageHeader] constructor - this:%o', this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.log(
			'[PageHeader] getDerivedStateFromProps this:%o, nextProps:%o, prevState:%o',
			this,
			nextProps,
			prevState
		);

		let imageSrc = prevState.imageSrc;
		let type = prevState.headerType;
		let scroll = prevState.scroll;
		let pageHeaders = {};

		// check 2 values from config page_headers (imageSrc, scroll)
		if (nextProps['attributes'] && nextProps['attributes']['headerType']) {
			type = nextProps['attributes']['headerType'];

			if (nextProps['attributes'].hasOwnProperty('imageSrc') && nextProps['attributes']['imageSrc']) {
				imageSrc = nextProps['attributes']['imageSrc'];
			} else if (nextProps['pageHeaders']) {
				pageHeaders = nextProps['pageHeaders'][type];
				if (pageHeaders && pageHeaders['imageSrc']) {
					imageSrc = pageHeaders['imageSrc'];
				}
			}

			//logger.log('[PageHeader] getDerivedStateFromProps - nextProps: %o scroll:%o', nextProps, nextProps['attributes']['scroll']);
			if (nextProps['attributes'].hasOwnProperty('scroll')) {
				scroll = nextProps['attributes']['scroll'];
			} else if (nextProps['pageHeaders']) {
				pageHeaders = nextProps['pageHeaders'][type];
				if (pageHeaders && pageHeaders['scroll']) {
					scroll = pageHeaders['scroll'];
				}
			}
		}

		if (!pageHeaders) {
			logger.log('[PageHeader] no page headers in config, using props: %o', nextProps);
		}

		//logger.log('[PageHeader] getDerivedStateFromProps - fixedBeforeRender:%o fixedHeader:%o', this.fixedBeforeRender, this.fixedHeader);

		let newState = {
			// ...prevState,
			...pageHeaders,
			// ...nextProps['attributes'],
			// userOpen: nextProps.userOpen,
			imageSrc: imageSrc,
			scroll: scroll,
			status: 'loaded',
			// windowSize: nextProps.windowSize
		};

		// logger.log('[PageHeader] getDerivedStateFromProps - state:%o nextProps:%o', this.state, nextProps);

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[PageHeader] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	// componentWillUpdate() {
	//logger.log('[PageHeader] componentWillUpdate - fixedHeader:%o', this.fixedHeader);
	//this.fixedBeforeRender = true;
	// }

	componentDidMount() {
		this.props.mount();
		//window.addEventListener('scroll', throttle(this.positionChange.bind(this), 100, { 'trailing': true }), false);
		window.addEventListener('resize', throttle(this.onResize.bind(this), 100, { trailing: true }), false);
		logger.log('[PageHeader] componentDidMount - this:%o pageElem:%o', this, this.pageHeader);
		//logger.log('[PageHeader] componentDidMount');
	}

	componentWillUnmount() {
		//logger.log('[PageHeader] componentWillUnmount - state.scroll:%o', this.state.scroll);
		//window.removeEventListener('scroll', this.positionChange.bind(this), false);
		window.removeEventListener('resize', this.onResize.bind(this), false);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PageHeader] componentDidUpdate - fixedHeader:%o state:%o', this.fixedHeader, this.state);

		//logger.log('[PageHeader] componentDidUpdate - shortTitle:%o prevShortTitle:%o', this.state.shortTitle, prevState.shortTitle);
		//force position change if found new title
		if (this.props?.attributes?.shortTitle != prevProps?.attributes?.shortTitle) {
			//this.positionChange({type: 'newpage'});
		}
	}

	assignElements() {
		this.pageWrapper = document.querySelector('.article-wrapper');
		this.scrollElem = document.querySelector(this.props.attributes.scrollElem);
		this.titleElem = document.querySelector(this.props.attributes.titleElem);
		this.contentMain = document.querySelector('.content-main');
		this.contentFiltersScroll = document.querySelector('.content-filters-scroll');
		this.pageHeader = document.querySelector('.page-header');
		this.bgImage = document.querySelector('.backgroundImage');
		this.bgSpace = document.querySelector('.backgroundSpace');
		this.pageTxt = document.querySelector('.title-txt-page');
		this.contentTxt = document.querySelector('.title-txt-content');
	}

	initElements() {
		this.assignElements();

		// logger.log('[PageHeader] initElements scrollElem:%o titleElem:%o', this.scrollElem, this.titleElem);

		this.onResize();
	}

	positionChange(event) {
		if (!event) {
			this.fixedBeforeRender = true;
			//logger.log('[PageHeader] positionChange - no event');
			return;
		}
		this.fixedBeforeRender = false;
		// logger.log('[PageHeader] positionChange - event:%o state:%o', event, this.state);

		let checkHeight = this.props.attributes.minHeight ? this.props.attributes.minHeight : 73;
		let panelCheck = true;

		if (!this.titleElem || !this.scrollElem) {
			this.assignElements();
		}

		if (!tabletMatch.matches) {
			checkHeight = this.props.attributes.minHeight ? this.props.attributes.minHeight : 106;
			//panelCheck = false;
		}

		//decides if title element (header title) needs to be hidden based on position
		if (this.titleElem) {
			//logger.log('[PageHeader] scroll - titleElem - top:%o', this.titleElem.getBoundingClientRect().top);
			if (this.titleElem.getBoundingClientRect().top <= checkHeight && event.type != 'newpage') {
				this.titleElem.classList.add('hide');
				this.titleElem.classList.remove('show');
				if (this.contentTxt) {
					if (this.props.windowSize !== 'mobile') {
						this.pageTxt.classList.remove('show');
						this.contentTxt.classList.add('show');
					} else {
						this.pageTxt.classList.add('show');
						this.contentTxt.classList.remove('show');
					}
				}
			} else {
				this.titleElem.classList.add('show');
				this.titleElem.classList.remove('hide');
				if (this.contentTxt) {
					this.pageTxt.classList.add('show');
					this.contentTxt.classList.remove('show');
				}
			}
		}

		// sets fixed state when the scroll elem hits the check height (min height)
		if (this.state.scroll && this.scrollElem) {
			//logger.log('[PageHeader] scroll - scrollElem:%o top:%o checkHeight:%o', this.scrollElem, this.scrollElem.getBoundingClientRect().top, checkHeight);
			if (this.scrollElem.getBoundingClientRect().top <= checkHeight && event.type != 'newpage') {
				if (!this.pageWrapper.classList.contains('fixed')) {
					this.pageHeader.classList.add('fixed');
					this.pageWrapper.classList.add('fixed');
					this.fixedHeader = true;
					this.checkVideo();
					this.checkGallery();
				}
			} else {
				if (this.pageWrapper.classList.contains('fixed')) {
					this.pageHeader.classList.remove('fixed');
					this.pageWrapper.classList.remove('fixed');
					this.fixedHeader = false;
					this.checkVideo();
					this.checkGallery();
				}
			}
		}

		if (this.state.scroll && this.contentFiltersScroll) {
			// logger.log('[PageHeader] scroll - contentFiltersScroll - top:%o checkHeight:%o ', this.contentFiltersScroll.getBoundingClientRect(), checkHeight);
			if (this.contentFiltersScroll.getBoundingClientRect().top <= checkHeight) {
				if (!this.contentMain.classList.contains('fixed')) {
					this.contentMain.classList.add('fixed');
					//this.checkVideo();
					//this.checkGallery();
				}
			} else if (this.contentFiltersScroll.getBoundingClientRect().top > checkHeight) {
				if (this.contentMain.classList.contains('fixed')) {
					this.contentMain.classList.remove('fixed');
					//this.checkVideo();
					//this.checkGallery();
				}
			}
		} else if (!this.state.scroll && this.contentFiltersScroll) {
			// logger.log('[PageHeader] scroll - contentFiltersScroll - top:%o checkHeight:%o ', this.contentFiltersScroll.getBoundingClientRect(), checkHeight);
			if (this.contentFiltersScroll.getBoundingClientRect().top <= checkHeight) {
				if (!this.contentMain.classList.contains('fixed')) {
					this.contentMain.classList.add('fixed');
				}
			} else if (this.contentFiltersScroll.getBoundingClientRect().top > checkHeight) {
				if (this.contentMain.classList.contains('fixed')) {
					this.contentMain.classList.remove('fixed');
				}
			}
		}

		// hide/show variable list of elements based on position
		if (this.titleElem && this.state.hideElems) {
			this.state.hideElems.forEach(elem => {
				let domElem = document.querySelector(elem);
				// logger.log('[PageHeader] scroll - titleElem.top:%o hideElem.bottom:%o', this.titleElem.getBoundingClientRect().top, domElem.getBoundingClientRect().bottom);
				if (domElem) {
					if (
						panelCheck &&
						this.titleElem.getBoundingClientRect().top < domElem.getBoundingClientRect().bottom
					) {
						domElem.classList.add('hide');
						domElem.classList.remove('show');
					} else {
						domElem.classList.add('show');
						domElem.classList.remove('hide');
					}
				}
			});
		}

		//if no title elem (like searchpage)
		else if (this.state.hideElems) {
			this.state.hideElems.forEach(elem => {
				let domElem = document.querySelector(elem);
				// logger.log('[PageHeader] scroll - titleElem.top:%o hideElem.bottom:%o', this.titleElem.getBoundingClientRect().top, domElem.getBoundingClientRect().bottom);
				if (domElem) {
					if (this.contentMain.getBoundingClientRect().top < domElem.getBoundingClientRect().bottom) {
						domElem.classList.add('hide');
						domElem.classList.remove('show');
					} else {
						domElem.classList.add('show');
						domElem.classList.remove('hide');
					}
				}
			});
		}

		// check if page scrolled at all
		if (this.bgSpace) {
			if (this.bgSpace.getBoundingClientRect().top < 0) {
				this.bgImage.classList.add('scrolled');
			} else {
				this.bgImage.classList.remove('scrolled');
			}
		}
	}

	onResize(event) {
		//logger.log('[PageHeader] resize - event:%o', event);

		this.checkWindowSizes();

		if (this.pageHeader) {
			let checkWidth = this.pageWrapper.getBoundingClientRect().width;
			let checkHeight = this.pageHeader.getBoundingClientRect().height;

			let videoBgElem;
			let imageBgElem;
			let galleryBgElems;

			// if using a video bg
			if (document.querySelector('.backgroundImage.video')) {
				videoBgElem = document.querySelector('.backgroundImage > video');
				imageBgElem = document.querySelector('.backgroundImage > img');
				//logger.log('[PageHeader] resize - using video bg');
			}
			// if has gallery
			if (document.querySelector('.image-gallery-image > img')) {
				galleryBgElems = document.querySelectorAll('.image-gallery-image > img');
				// logger.log('[PageHeader] resize - using video bg');
			}

			//check aspect ratio of holding space (pageWrapper)
			// logger.log('[PageHeader] resize - width:%o height:%o ratio:%o', checkWidth, checkHeight, (checkWidth / checkHeight));

			if (this.pageWrapper && checkWidth / checkHeight < this.imageRatio) {
				this.pageHeader.classList.add('fit-height');
				this.pageHeader.classList.remove('fit-width');
			} else {
				this.pageHeader.classList.add('fit-width');
				this.pageHeader.classList.remove('fit-height');
			}

			if (videoBgElem) {
				//logger.log('[PageHeader] resize - videobg %o', (this.pageWrapper && checkWidth / checkHeight < this.videoRatio));
				if (this.pageWrapper && checkWidth / checkHeight < this.videoRatio) {
					videoBgElem.classList.add('fit-height');
					videoBgElem.classList.remove('fit-width');
				} else {
					videoBgElem.classList.add('fit-width');
					videoBgElem.classList.remove('fit-height');
				}
			}

			if (imageBgElem) {
				if (this.pageWrapper && checkWidth / checkHeight < this.imageRatio) {
					imageBgElem.classList.add('fit-height');
					imageBgElem.classList.remove('fit-width');
				} else {
					imageBgElem.classList.add('fit-width');
					imageBgElem.classList.remove('fit-height');
				}
			}

			if (galleryBgElems && isIE) {
				if (this.pageWrapper && checkWidth / checkHeight < this.imageRatio) {
					forEach(galleryBgElems, function(value) {
						value.classList.add('fit-height');
						value.classList.remove('fit-width');
					});
				} else {
					forEach(galleryBgElems, function(value) {
						value.classList.add('fit-width');
						value.classList.remove('fit-height');
					});
				}
			}
		}

		if (event && event.detail != 99) {
			//this.positionChange(event);
		} else {
			//this.positionChange(null);
		}

		//this.checkVideo();
	}

	checkWindowSizes() {
		let size = '';

		if (mobileMatch.matches) {
			size = 'mobile';
		} else if (tabletMatch.matches) {
			size = 'tablet';
		} else if (desktopMatch.matches || largeDesktopMatch.matches || midDesktopMatch.matches) {
			size = 'desktop';
		}

		if (size !== this.lastWindowSize) {
			this.lastWindowSize = size;
			this.props.setWindowSize({
				windowSize: size,
			});
			// logger.log('[PageHeader] checkWindowSizes - size: ', size);
		}
	}

	checkVideo() {
		//if has a video bg, check size to start or stop
		if (this.state.videoSrc && !this.fixedBeforeRender) {
			this.video = document.querySelector('#videobg');
			if (this.video) {
				// logger.log(
				// 	'[PageHeader] checkVideo - fixedHeader:%o mobileMatch:%o tabletMatch:%o desktopMatch:%o',
				// 	this.fixedHeader,
				// 	mobileMatch.matches,
				// 	tabletMatch.matches,
				// 	desktopMatch.matches
				// );

				if (mobileMatch.matches || tabletMatch.matches) {
					if (this.video.src === this.video.dataset.src) {
						// logger.log('[PageHeader] checkVideo - stop video');
						this.video.src = '';
					}
				} else if (this.isDesktopSize() && !this.fixedHeader) {
					if (this.video.src !== this.video.dataset.src) {
						// logger.log('[PageHeader] checkVideo - start video');
						this.video.src = this.video.dataset.src;
					}
				} else if (this.fixedHeader) {
					if (this.video.src === this.video.dataset.src.trim()) {
						// logger.log('[PageHeader] checkVideo - stop video');
						this.video.src = '';
					}
				}
			}
		}
	}

	checkGallery() {
		if (this.refs.carousel) {
			// logger.log('[PageHeader] checkGallery - refs:%o tablet:%o desktop:%o', this.refs, tabletMatch.matches, desktopMatch.matches);
			if (tabletMatch.matches) {
				// logger.log('[PageHeader] checkGallery - tablet');
				this.refs.carousel.pause();
			} else if (this.isDesktopSize() && !this.fixedHeader) {
				this.refs.carousel.play();
			} else if (this.fixedHeader) {
				this.refs.carousel.pause();
			}
		}
	}

	isDesktopSize() {
		return desktopMatch.matches || largeDesktopMatch.matches || midDesktopMatch.matches;
	}

	getVideoTag(videoSrc, imageSrc, posterSrc) {
		if (this.state.windowSize == 'desktop') {
			return (
				<video
					id="videobg"
					data-src={`${videoSrc}`}
					src={`${videoSrc}`}
					poster={`${posterSrc}`}
					loop="true"
					autoPlay
					muted
					playsInline
				/>
			);
		} else {
			return (
				<video
					id="videobg"
					style={{ display: 'none' }}
					data-src={`${videoSrc}`}
					src=""
					poster={`${posterSrc}`}
					loop="true"
					autoPlay
					muted
					playsInline
				/>
			);
		}
	}

	showVideo() {
		logger.log('[PageHeader] showVideo', this.state.config, this.state.videoData);
		if (this.state.videoData == null) {
			let videoData = {
				id: this.props.attributes.videoSrc.id,
				title: this.props.attributes.videoSrc.title,
				poster: this.props.attributes.videoSrc.poster,
				shareUrl: this.props.attributes.videoSrc.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: this.props.attributes.videoSrc.streams.stream.url,
						mimeType: 'application/x-mpegURL',
						streamType: 'vod',
					},
				],
				tracks: [
					{
						kind: 'captions',
						type: 'text/vtt',
						srclang: 'en',
						src: this.props.attributes.videoSrc.tracks
							? this.props.attributes.videoSrc.tracks.track.file
							: '',
					},
				],
				startSeconds: 0,
				autoplay: true,
				autoplayPolicy: 'mutedinline',
				playsinline: true,
				displayDate: this.props.attributes.videoSrc.displayDate,
				ads: [
					{
						type: 'vast',
						tag:
							'https://pubads.g.doubleclick.net/gampad/ads?iu=/5681/usopen_2020_web/USOPEN_2020_PreRoll&description_url=https%3A%2F%2Fwww.usopen.org%2Fen_US%2Fvideo%2Findex.html&tfcd=0&npa=0&sz=960x540&ciu_szs=320x50%2C384x384&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=',
					},
				],
			};
			this.setState(prevState => {
				return {
					...prevState,
					videoData,
				};
			});
		}
	}

	onComplete() {
		this.setState({
			videoData: null,
		});
	}
	
	/** if the article has Pre/Post AI catch up content, display watsonx branding */
	getwatsonxBranding = () => {
		if(this.props?.attributes?.watsonxBranding) {
			return <IBMwatsonx onclickClass={true} />
		}
	}

	render() {
		logger.log('[PageHeader] render - this:%o', this);

		const typeHeader = this.props.attributes.metaTitle;
		const titleHeader = this.props.attributes.title;

		// logger.log(
		// 	'[PageHeader] render - windowSize:%o desktop:%o this:%o',
		// 	this.props.windowSize,
		// 	this.props.windowSize === 'desktop',
		// 	this
		// );
		//logger.log('[PageHeader] render - isIE:%o device:%o', isIE, JSON.stringify(deviceDetect()));

		//let size = this.props.stickyOn ? 'min' : '';

		let headerType = this.props.attributes.headerType;
		let fixedType = !this.state.scroll ? 'noscroll' : '';
		let images = !isArray(this.state.imageSrc) ? [this.state.imageSrc] : this.state.imageSrc;
		let browserStyle = '';

		if (images.length > 1 && this.props.windowSize == 'mobile') {
			images = [images[0]];
		}

		if (this.ieCheck) {
			browserStyle = 'ie';
		}

		//logger.log('[PageHeader] render - headerType:%o fixedType:%o browserStyle:%o', headerType, fixedType, browserStyle);

		//header has not been set yet, return nothing
		if (headerType === 'unset') {
			return (
				<div className={`page-header ${headerType} `}>
					{/* <div className="backgroundSpace"></div> */}
					<div className="backgroundImage" />
				</div>
			);
		}

		//video bg wo/matching poster
		else if (this.props.attributes.videoSrc && !this.props.poster) {
			this.imageRatio = j_image_ratio;
			this.videoRatio = video_image_ratio;
			// logger.log('[PageHeader] render - videoBg');

			return (
				<div className={`heroSection page-header ${headerType} ${fixedType}`}>
					{/* <div className="backgroundSpace"><img src={`${images[0]}`} onLoad={() => { this.initElements() }} /></div> */}
					<div className={`heroVideo video`} style={{ minHeight: '500px' }}>
						{this.state.videoData ? (
							<VideoPlayer
								attributes={{
									videoData: this.state.videoData,
									onComplete: null,
									id: 'pageheader',
									showPanelIcon: true,
								}}
							/>
						) : (
							<div className="heroImage">
								<div
									className="heroContainer"
									style={{
										backgroundImage: `url(${this.props.videoSrc.poster})`,
									}}>
									<div
										className="inlinePlayer"
										onClick={() => {
											this.showVideo();
										}}>
										<img
											src={this.props.videoSrc.poster}
											alt={`Play video ${this.props.videoSrc.title}`}
										/>
										<div className="playBtn" />
									</div>
									<div className="heroInfo">
										<h1>{this.props.videoSrc.title}</h1>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			);
		}

		//image bg
		else if (images.length === 1) {
			this.imageRatio = b_image_ratio;
			this.videoRatio = null;

			if (fixedType && headerType != 'scores' && headerType != 'schedule') {
				return (
					<section className={`heroSection ${headerType} ${fixedType}`}>
						<EventsLink to={this.state.link} style={'white'}>
							<div className={`heroImage`}>
								<div className="heroContainer">
									<img src={images[0]} />
									<div className="heroInfo">
										<h2>{typeHeader}</h2>
										<h1>{titleHeader}</h1>
									</div>
								</div>
							</div>
						</EventsLink>
					</section>
				);
			} else if (fixedType && (headerType == 'scores' || headerType == 'schedule')) {
				return (
					<section className={`heroSection ${headerType} `}>
						<EventsLink to={this.state.link} style={'white'}>
							<div className={`heroImage`}>
								<div className="heroContainer">
									<div className="heroInfo">
										<h2>{typeHeader}</h2>
										<h1>{titleHeader}</h1>
									</div>
								</div>
							</div>
						</EventsLink>
					</section>
				);
			} else {
				return (
					<section className={`heroSection ${headerType} ${fixedType}`}>
						<div className={`heroImage ${this.props.windowSize} ${browserStyle}`}>
							<EventsLink to={this.state.link} style={'white'}>
								<div className="heroInfo">
									<h1>{this.props.attributes.metaTitle}</h1>
									<div className="articleInfo">
										<div className="info-row">
											<div className="info">
												<div className="info-cell">
													{this.props.attributes.credit.length > 0 && (
														<span className="author">{this.props.attributes.credit}/</span>
													)}
													<span className="date">
														<Time
															epoch_ms={this.props.attributes.metaDate}
															format="dddd, MMMM DD, YYYY"
														/>
													</span>
												</div>
											</div>
										</div>
										{ /** if the article has Pre/Post AI catch up content, display watsonx branding */
											this.getwatsonxBranding()
										}
									</div>
								</div>
									
								<div className="heroContainer">
									<img src={`${images[0]}`} />
								</div>
							</EventsLink>
						</div>
					</section>
				);
			}
		} else {
			// logger.log('[PageHeader] render - null');
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
