import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	switch (action.type) {
		case deps.actionTypes.TVSCHEDULEPAGE_MOUNT:
			newState = {
				...state,
				...action.data,
			};

			return newState;

		case deps.actionTypes.TVSCHEDULEPAGE_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};

			return newState;

		case deps.actionTypes.TVSCHEDULEPAGE_LOAD:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.TVSCHEDULEPAGE_FILTER:
			filters = {
				...state.filters,
				...action.data,
			};

			logger.log('[TVSchedule] TVSCHEDULEPAGE_FILTER - state:%o', state);

			newState = {
				...state,
				filters: filters,
			};

			logger.log('[TVSchedule] TVSCHEDULEPAGE_FILTER - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
