/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from 'appdir/components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import AdTag from 'appdir/components/general/AdTag';

import EventsLink from 'appdir/components/general/EventsLink';
import SocialEmbed from 'appdir/components/content/SocialEmbed';
import MatchInsights from 'appdir/components/cms/MatchInsights';
import ScoresBox from 'appdir/components/cms/ScoresBox';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import find from 'lodash/find';
import Video from 'appdir/components/content/Video';
import DaySelect from 'appdir/components/pages/LiveAtUSOpen/DaySelect';
import axios from 'axios';
import { fetchAll, scrollIntoViewWithOffset } from 'appdir/components/general/Util';
import PowerRankingBlog from 'appdir/components/cms/PowerRankingBlog';

const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: LiveAtUSOpen
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['LiveAtUSOpen'],
		stubs: state['Config'].stubPages,
		adConfigData: state['Config'].adConfig,
		miMatches: state['Controller'].miMatches,
		currentDay: state['ActiveData']['currentDay'],
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveAtUSOpen.mount()),
	unmount: () => dispatch(deps.actions.LiveAtUSOpen.unmount()),
	setDay: settings => dispatch(deps.actions.LiveAtUSOpen.setDay(settings)),
});

class LiveAtUSOpen extends Component {
	constructor(props) {
		super(props);
		let filters = props.filters;

		this.state = {
			blogData: [],
			show_more: [],
		};

		this.init = true;
		this.blog_init = true;
		this.preview_mode = false;
		this.pageTitle = '';
		this.anchor = null;
		this.anchorFound = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Live At The US Open',
		});
		logger.log('[LiveAtUSOpen] constructor - props:%o', props);
	}

	componentDidMount() {
		// this.props.mount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[LiveAtUSOpen] componentDidUpdate - state:%o, props: %o', this.state, this.props);

		if (prevProps.status !== this.props.status && this.props.status == 'load') {
			if (this.init) {
				this.init = false;
				this.getBlogComments();
			}
		}

		// debugger;
		if (prevProps.filters !== this.props.filters) {
			this.getBlogComments();
		}

		if (this.anchor && !this.anchorFound) {
			let anchorid = this.anchor.replace('#', '#anchor-');
			let el = document.querySelector(`${anchorid}`);
			const isMobile = this.props?.EventsWindow?.windowSize == 'mobile';

			logger.log('[LiveAtUSOpen] componentDidUpdate - el: %o', el);

			let offset = 126;

			// if (isMobile) {
			// 	offset = stickyFavorites ? 116 : stickyMenu ? 170 : 470;
			// }

			if (el) {
				this.anchorFound = true;
				scrollIntoViewWithOffset(`${anchorid}`, offset);
			}
		}
	}

	componentWillUnmount() {
		this.init = true;
		this.blog_init = true;
		this.anchor = null;
		this.anchorFound = false;
		clearTimeout(this.blog_timeout);
		this.props.unmount();
	}

	getMode(day) {
		let mode = '';
		this.state.tourn_days.eventDays.forEach(item => {
			if (item.messageShort == day) {
				mode = item.quals ? 'quali' : 'tourn';
			}
		});
		return mode;
	}

	getBlogComments() {
		if (this.blog_init) {
			this.anchor = this.props?.location?.hash || null;
			this.blog_init = false;
			let blogList = [];
			let showDay = '';

			// debugger;
			//testing
			// if(this.state.filters.day === '1'){
			// 	showDay = "day0";
			// }else
			if (this.props.filters?.day !== 'current') {
				showDay = 'day' + this.props.filters.day;
			} else {
				showDay = 'current';
			}

			let blogUrl = this.props.liveBlogPath.replace('<day>', showDay);
			let daysUrl = this.props.daysPath;
			let pathsObj = [blogUrl, daysUrl];

			fetchAll(pathsObj)
				.then(
					axios.spread((blogResults, daysResults) => {
						this.blog_init = true;
						if (!isEqual(this.state.blogData.content, blogResults.content)) {
							blogResults.content.map(item => {
								let temp = {};
								temp.blogId = item.date;
								temp.active = false;
								blogList.push(temp);
							});

							this.setState({
								blogData: blogResults,
								show_more: blogList,
								tourn_days: daysResults,
							});
						}

						if (this.blog_timeout) {
							clearTimeout(this.blog_timeout);
						}

						this.blog_timeout = setTimeout(() => {
							this.blog_init = true;
							this.getBlogComments();
						}, 30000);
					})
				)
				.catch(error => {
					logger.log('[LiveAtUSOpen] getBlogComments - error: %o', error);

					this.setState({
						hasError: true,
						errorType: error,
					});
				});
		}
	}

	showMore(checkId) {
		// logger.log('[LiveAtUSOpen] showMore - blogId:%o, blogList:%o', checkId, this.state.show_more);
		let showItems = this.state.show_more;
		let newList = [];

		showItems.map(item => {
			let temp = {};

			temp.blogId = item.blogId;

			//change show more status
			if (item.blogId === checkId) {
				temp.active = item.active ? false : true;
			} else {
				temp.active = item.active;
			}

			newList.push(temp);
		});

		this.setState({
			show_more: newList,
		});
	}

	renderBlog(blog) {
		// logger.log('[LiveAtUSOpen] renderBlog blog comment:%o', blog);
		let blogClass = !isEmpty(blog.icon) ? blog.icon : 'news';

		return (
			<div className="blogInsert" id={`anchor-${blog.type}${blog.date}`} key={blog.type + blog.date}>
				<div className="commentRow">
					<div className="commentIcon">
						<div className="iconType">
							<i className={`icon-blogIcon-${blogClass}`} />
						</div>
					</div>
					{this.renderBody(blog)}
				</div>
				<div className="footerRow">
					<div className="commentIcon" />
					<div className="time">{moment(blog.date).fromNow()}</div>
				</div>
			</div>
		);
	}

	renderBody(blog) {
		// logger.log('[LiveAtUSOpen] renderBody blog comment:%o', blog);
		let activeInfo = find(this.state.show_more, ['blogId', blog.date]);
		let activeClass = activeInfo.active ? 'active' : '';

		switch (blog.type) {
			case 'social':
				let socialProps = {visible: true };

				switch (blog.platform) {
					case 'facebook':
						socialProps = {
							...socialProps,
							platform: blog.platform,
							postURL: blog.socialURL,
						};
						break;

					case 'instagram':
						socialProps = {
							...socialProps,
							platform: blog.platform,
							instagramId: blog.socialURL,
						};
						break;

					case 'twitter':
						socialProps = {
							...socialProps,
							platform: blog.platform,
							tweetId: blog.socialId,
						};
						break;

					case 'youtube':
						socialProps = {
							...socialProps,
							platform: blog.platform,
							youTubeVideoId: blog.socialId,
						};
						break;

					default:
						logger.log('[LiveAtUSOpen] renderBody social not found');
						break;
				}

				return (
					<ErrorBoundary message={'Unable to render blog entry.'}>
						<div className={`comment ${blog.platform}`}>
							{!isEmpty(blog.header) ? <div className="title">{blog.header}</div> : <div />}
							<div className="body">
								<div className={`desc ${activeClass}`}>
									<SocialEmbed data={socialProps} />
								</div>
							</div>
						</div>
					</ErrorBoundary>
				);

			case 'video':
				let video_attributes = {
					playerId: 'main',
					contentId: blog.id,
					videoUrl: null,
					title: null,
					thumb: blog.image.small,
					autoplay: true,
					fullscreenMode: '',
					style: `video-wrapper one-col modal header`,
					aspect: 'wide',
					url: null,
				};
				// logger.log('[LiveAtUSOpen] renderBody - video type - video attributes:%o', video_attributes);
				return (
					<div className="comment">
						{!isEmpty(blog.header) ? (
							<EventsLink to={blog.link}>
								<div className="title">{blog.header}</div>
							</EventsLink>
						) : (
							<div />
						)}
						<div className="body">
							<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
							<div className="video media-16x9">
								<div className="media-wrapper">
									<Video data={{ video: blog.video }} />
								</div>
							</div>
							{!isEmpty(blog.title) ? <div className="caption">{ReactHtmlParser(blog.title)}</div> : null}
						</div>
					</div>
				);

			{/* MD - Innovation 2024 - Decommision Power Index */}
			{/* 
			case 'power_ranking':
				// logger.log('[LiveAtUSOpen] renderBody - power index id:%o', blog.id);
				return (
					<ErrorBoundary message={'Unable to render blog entry.'}>
						<div className="comment pr">
							{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
							<PowerRankingBlog matchId={blog.id} likelihood={false} />
						</div>
					</ErrorBoundary>
				);

			case 'power_ranking_ltw':
				// logger.log('[LiveAtUSOpen] renderBody - power index LTW id:%o', blog.id);
				return (
					<ErrorBoundary message={'Unable to render blog entry.'}>
						<div className="comment pr_ltw">
							{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
							<PowerRankingBlog matchId={blog.id} likelihood={true} />
						</div>
					</ErrorBoundary>
				);
			*/}

			case 'insight':
				return (
					<ErrorBoundary message={'Unable to render blog entry.'}>
						<div className="comment insight">
							{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
							<div className="body">
								<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
								{this.props.miMatches ? (
									<div>
										{this.props.miMatches.indexOf(blog.id) > -1 ? (
											<MatchInsights matchId={blog.id} />
										) : null}
									</div>
								) : null}
							</div>
						</div>
					</ErrorBoundary>
				);

			case 'match':
				return (
					<ErrorBoundary message={'Unable to render blog entry.'}>
						<div className="comment match">
							{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
							<div className="body">
								<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
								{this.props.miMatches ? (
									<div>
										{this.props.miMatches.indexOf(blog.id) > -1 ? (
											<MatchInsights matchId={blog.id} />
										) : null}
									</div>
								) : null}
							</div>
						</div>
					</ErrorBoundary>
				);

			case 'scores':
				return (
					<ErrorBoundary message={'Unable to render blog entry.'}>
						<div className="comment matchbox">
							{!isEmpty(blog.title) ? <div className="title">{blog.title}</div> : <div />}
							<div className="body">
								<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
								<div>
									<ScoresBox matchId={blog.id} game={blog.game} matchData={blog.matchData} />
								</div>
							</div>
						</div>
					</ErrorBoundary>
				);

			default:
				return (
					<div className="comment">
						{!isEmpty(blog.header) ? (
							<EventsLink
								to={blog.type === 'match' ? `/en_US/scores/stats/${blog.id}.html` : blog.link}
								external={true}>
								<div className="title">{blog.header}</div>
							</EventsLink>
						) : (
							<div />
						)}
						<div className="body">
							<EventsLink
								to={blog.type === 'match' ? `/en_US/scores/stats/${blog.id}.html` : blog.link}
								external={true}>
								<div className={`desc ${activeClass}`}>{ReactHtmlParser(blog.body)}</div>
								{!isEmpty(blog.image.medium) ? (
									<div className="photo">
										<img src={blog.image.medium} />
									</div>
								) : null}
								{!isEmpty(blog.title) ? (
									<div className="caption">{ReactHtmlParser(blog.title)}</div>
								) : null}
							</EventsLink>
							{/* <div
								className="showMore"
								onClick={() => {
									this.showMore(blog.date);
								}}>
								Show More
							</div> */}
						</div>
					</div>
				);
		}
	}

	render() {
		// logger.log('[LiveAtUSOpen] render state:%o', this.state);

		//check for preview mode
		let urlString = this.props.location.pathname.replace(/^\?/, '');
		let previewCheck = urlString.substring(1, 8).toLowerCase();
		if (previewCheck === 'preview') {
			this.preview_mode = true;
		}

		// if you are creating a header like scores which is transparent, make sure you update the
		// header component and make a new style for the new header.
		let header_attributes = {
			headerType: 'visit',
			title: 'Live At The US Open',
			metaTitle: 'Live At The US Open',
			metaDescription: 'The 2024 US Open begins August 19th to September 8th.',
			metaDate: '',
			metaPlayers: '',
		};

		this.pageTitle = this.state?.blogData.title ? this.state?.blogData.title : 'Live At the US Open';
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: [],
			title: 'Live At The US Open',
			page_header: this.pageTitle,
		};

		let hideAd = false;

		if (this.props.stubs && this.props.stubs.live_at_us_open && this.props.stubs.live_at_us_open.stub === 'stub') {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<StubBox
						attributes={{
							title: header_attributes.title,
							message: this.props.stubs.live_at_us_open.text,
							basePicPath: this.props.basePicPath,
						}}
					/>
				</Template>
			);
		} else {
			return (
				<Template hideAd={hideAd} header={header_attributes} subHeader={subheader_attributes}>
					<ErrorBoundary message="Live blog is not available at this time.">
						{!this.state.hasError ? (
							<section className="live-blog">
								{!isEmpty(this.state.blogData) ? (
									<div className="wrapper">
										{/* <div className="pageTitle">{this.state.blogData.title}</div> */}
										<div className="schedulefilter">
											<div className={`daynav-wrapper ${this.preview_mode ? 'disable' : ''}`}>
												{this.props.currentDay !== undefined ? (
													<DaySelect
														days={this.state.tourn_days.eventDays}
														filters={this.props.filters}
														default={this.props.currentDay.schedule}
														mode={this.getMode(
															this.props.tournDay
																? this.props.tournDay
																: this.props.currentDay.schedule
														)}
													/>
												) : null}
											</div>
										</div>
										<div className="content">
											<div className={'article-sticky-ad'}>
												{/* <AdChip data={{ adType: `live_blog_1` }} id={`adunit`} /> */}
												<AdTag
													key={`adunit`}
													adConfig={this.props.adConfig['live_blog_1']}
													dfpNetworkId={this.props.adConfig.dfpNetworkId}
												/>
											</div>
											<div className="blog">
												{this.state.blogData.content.map((blog, i) => {
													return <>{this.renderBlog(blog)}</>;
												})}
											</div>
										</div>
									</div>
								) : null}
							</section>
						) : (
							<div className="blog-spacing">
								<GenericError message="The live blog is unavailable at this time." />
							</div>
						)}
					</ErrorBoundary>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAtUSOpen);
