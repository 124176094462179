/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
/**
 * -----------------------------------------------------------------------------
 * Functional Component: VisitTabs
 * -----------------------------------------------------------------------------
 */
const VisitTabs = props => {
	logger.log('[VisitTabs] - props:%o', props);
	logger.log('[VisitTabs] - window.webview:%o', window.webview);

	if (window.webview) {
		return null;
	}
	let attributes = props.data;

	let azActive = '';
	let onsiteActive = '';
	let planActive,
		atHomeActive = '';

	switch (attributes['active']) {
		case 'a_z':
			azActive = 'active';
			break;

		case 'on_site':
			onsiteActive = 'active';
			break;

		case 'plan_your_visit':
			planActive = 'active';
			break;

		case 'at_home':
			planActive = 'active';
			break;

		default:
			break;
	}

	return (
		<div class="page-submenu-wrapper Visit-mode">
			<div class="blue-bar-menu-wrapper">
				<div class={`blue-bar-menu-event ${planActive}`}>
					<EventsLink to="/en_US/visit/plan_your_visit.html">Plan Your Visit</EventsLink>
				</div>
				<div class={`blue-bar-menu-event ${onsiteActive}`}>
					<EventsLink to="/en_US/visit/on_site.html">At the Open</EventsLink>
				</div>
				<div class={`blue-bar-menu-event ${atHomeActive}`}>
					<EventsLink to="/en_US/us-open-at-home/index.html">At Home</EventsLink>
				</div>
				<div class={`blue-bar-menu-event ${azActive}`}>
					<EventsLink to="/en_US/visit/a_z.html">A-Z Guide</EventsLink>
				</div>
			</div>
		</div>
	);
};

export default VisitTabs;
