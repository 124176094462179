/**
 Header Initial State
*/

export default {
	imageSrc: '/assets/images/headers/generic_default.jpg',
	headerType: 'generic',
	scroll: false,
	headerTitle: '',
	title: '',
	shortTitle: '',
	stickyOn: false,
	statusPanel: 'small',
	pageTitle: '',
	htsearch: '',
	live_open: false,
};
