/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import deps from 'dependencies';
import { connect } from 'react-redux';
import get from 'lodash/get';
import VisibilitySensor from 'react-visibility-sensor';

const mapStateToProps = (state, props) => {
	return {
		...state['Video'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Video.mount()),
});
/**
 * -----------------------------------------------------------------------------
 * Functional Component: Video
 * -----------------------------------------------------------------------------
 */
class Video extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoData: null,
			displayThumbnail: false,
		};

		if(!this.script) {
			this.script = document.createElement('script');
			this.script.async = true;
			this.script.src = 'https://player.anyclip.com/anyclip-widget/lre-widget/prod/v1/src/lre.js';
			this.script.setAttribute('pubname', '2152');
			// this.script.setAttribute('data-tm-videoguids', '[]');
			// this.script.setAttribute('data-tm-videoguids', '["ashe16934516102275337"]');
			this.script.setAttribute('widgetname', '0016M00002Tye8hQAB_M10008');
		}
	}

	componentDidMount() {
		// logger.log('[Video] componentDidMount() %o', this);
		this.props.mount();
	}

	togglePlay() {
		//toggle the video player to play/pause
	}

	mapVideoData(data, autoPlay) {
		// logger.log('[Video] the video data %o', data);
		if (!this.props.config) {
			return {
				id: data.id,
				title: data.title,
				poster: data.poster,
				shareUrl: data.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: data.streams.stream.url,
						mimeType: this.getMimeType(data.streams.stream.url),
						streamType: 'vod',
					},
				],
				tracks: get(data, 'media.tracks[0].file', false)
					? [
							{
								kind: 'captions',
								type: 'text/vtt',
								srclang: 'en',
								src: data.media.tracks[0].file,
							},
					  ]
					: [],
				startSeconds: 0,
				autoplay: autoPlay ? autoPlay : false,
				autoplayPolicy: 'mutedinline',
				playsinline: true,
				displayDate: data.displayDate,
			};
		} else {
			let videoConfigTemplate = {
				...this.props.config.videoConfigTemplate,
			};
			videoConfigTemplate['id'] = data.id;
			videoConfigTemplate['autoplay'] = autoPlay ? autoPlay : false;
			videoConfigTemplate['title'] = data.title;
			videoConfigTemplate['poster'] = data.poster;
			videoConfigTemplate['shareUrl'] = data.shareUrl.startsWith('http') ? data.shareUrl : '';
			videoConfigTemplate['streams'][0]['mimeType'] = data.streams.stream
				? this.getMimeType(data.streams.stream.url)
				: this.getMimeType(data.streams[0].stream.url);
			videoConfigTemplate['streams'][0]['streamType'] = data.streams.stream
				? this.getStreamType(data.streams.stream.url)
				: this.getStreamType(data.streams[0].stream.url);
			videoConfigTemplate['streams'][0]['url'] = data.streams.stream
				? data.streams.stream.url
				: data.streams[0].stream.url;
			videoConfigTemplate['tracks'][0]['kind'] = 'captions';
			videoConfigTemplate['tracks'][0]['type'] = 'text/vtt';
			videoConfigTemplate['tracks'][0]['srclang'] = 'en';
			if (!get(data, 'media.tracks[0].file', false) && !get(data, 'tracks.track.file', false)) {
				videoConfigTemplate['tracks'] = null;
			} else {
				videoConfigTemplate['tracks'][0]['src'] = data.tracks.track.file;
			}
			// videoConfigTemplate["tracks"][0]["src"] = "";
			videoConfigTemplate['displayDate'] = data.displayDate;
			// logger.log(videoConfigTemplate, "The video config template");
			return videoConfigTemplate;
		}
	}

	getMimeType(url) {
		let extensionArry = url.split('.');
		let vidExtension = extensionArry[extensionArry.length - 1];
		if (vidExtension.indexOf('mp4') !== -1) {
			return 'video/mp4';
		} else {
			return 'application/x-mpegURL';
		}
	}

	getStreamType(url) {
		let extensionArry = url.split('.');
		let vidExtension = extensionArry[extensionArry.length - 1];
		if (vidExtension.indexOf('mp4') !== -1) {
			return 'vod';
		} else {
			return 'vod';
		}
	}

	vidComplete = () => {
		logger.log('[Video] completed playing video');
	};

	showVideo = () => {
		logger.log('[Video] showVideo');
		if (this.props.config && this.state.videoData == null) {
			let videoData = this.mapVideoData(this.props.data.video, true);
			if (!window.webview) {
				
				this.setState(prevState => {
					return {
						...prevState,
						videoData,
					};
				}, () => {
					
					this.script.setAttribute('data-tm-videoguids', `["${this.props.data.video.id}"]`);
					logger.log('[Video] showVideo this.script:%o', this.script);
					let videoContainer = document.getElementById(`anyclip-container-${this.props.data.video.id}`);
					videoContainer.appendChild(this.script);
				});
			} else {
				if (window.webviewPlatform == 'ios') {
					window.webkit.messageHandlers.video.postMessage(videoData.id);
				} else {
					//logger.log('http://usopen/video?id=' + videoData.id)
					location.href = 'http://usopen/video?id=' + videoData.id;
				}
			}
		}
	};

	onChange(isVisible) {
		logger.log('[Video] onChange isVisible:%o', isVisible);
	}

	render() {
		logger.log('[Video] render %o', this);
		let share_link = {};
		if (
			this.props.data.video &&
			this.props.data.video.shareUrl &&
			this.props.data.video.shareUrl.startsWith('http')
		) {
			share_link['share-link'] = this.props.data.video.shareUrl;
		}
		let videoStyle={
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: '0',
			left: '0'
		}
		return this.props.config ? (
			<VisibilitySensor onChange={isVisible => this.onChange(isVisible)} partialVisibility={true}>
				{({ isVisible }) => (
					<div className="inlineVideo" id={`_${this.props.data.video.id}_inline`} {...share_link}>
						{this.state.videoData !== null ? (
							<div id={`anyclip-container-${this.props.data.video.id}`} style={videoStyle}></div>
							// <VideoPlayer
							// 	attributes={{
							// 		videoData: this.state.videoData,
							// 		id: `_${this.state.videoData.id}`,
							// 		nextVid: null,
							// 		showPanelIcon: true,
							// 	}}
							// 	isVisible={isVisible}
							// />
						) : (
							<a
								onClick={() => {
									this.showVideo();
								}}
								className="playVideo">
								
								<img
									src={this.props.data.video.poster}
									alt={`play video ${this.props.data.video.title}`}
								/>
								<div className="playBtn" />
							</a>							
						)}						
					</div>
				)}
			</VisibilitySensor>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Video);
