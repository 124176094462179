/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import { fetch } from 'appdir/components/general/Util';
import ReactHtmlParser from 'html-react-parser';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Module
 * -----------------------------------------------------------------------------
 */
const Module = props => {
	logger.log('[Module] - props:%o', props);

	const { data } = props;
	const [doubleStackItems, setDoubleStackItems] = useState([]);

	useEffect(() => {
		async function getModuleData() {
			try {
				const result = await fetch(data?.feed);
				setDoubleStackItems(result?.module[0]?.content?.items);
			} catch (err) {
				logger.log(err);
			}
		}
		if (data.moduleType == 'Double Stack' && data?.feed) {
			getModuleData();
		}
	}, []);

	/** inner content of page module */
	const getPageItem = props => {
		return (
			<div className="content-module page">
				<div className="content-module-image image-wrapper">
					<img src={data.items.item.photo} alt={data.items.item.title} />
				</div>
				<div className="content-module-description">
					<div className="title">{data.items.item.title}</div>
					<div className="text">{ReactHtmlParser(data.items.item.description)}</div>
				</div>
			</div>
		);
	};

	const getDoubleStackItem = item => {
		// logger.log('[Module] getDoubleStackItem - item:%o', item);
		return (
			<div className="double-stack-item">
				<div className="double-stack-item-image image-wrapper">
					<img src={item.photo} alt={item.title} />
				</div>
				<div className="double-stack-item-description">
					<div className="title">{item.title}</div>
				</div>
			</div>
		);
	};

	/** return html for module items */
	const getModuleLayout = type => {
		/** double stack module */
		if (type == 'Double Stack' && data?.feed) {
			// logger.log('[Module] getModuleLayout - doubleStackItems:%o', doubleStackItems);

			return (
				<div className="content-module double-stack">
					{doubleStackItems.map((item, i) => {
						/** double stack item with link */
						if (item?.link !== '') {
							return (
								<EventsLink to={item.link} style="double-stack-module-item">
									{getDoubleStackItem(item)}
								</EventsLink>
							);
						} else {
							/** double stack item without link */
							return getDoubleStackItem(item);
						}
					})}
				</div>
			);
		} else if (type == 'Page') {
			/** page module */
			/** page module with link */
			if (data.items?.item?.link) {
				return (
					<EventsLink to={data.items.item.link} style="page-module-item">
						{getPageItem()}
					</EventsLink>
				);
			} else {
				/** page module without link */
				return getPageItem();
			}
		} else {
			return null;
		}
	};

	return getModuleLayout(data.moduleType);
};

export default Module;
