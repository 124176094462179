/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: Modal to dislay simple text info
 * @props - content: body
 *          closeModal: callback for X button
 * -----------------------------------------------------------------------------
 */
const HelpModal = props => {
	let styleClasses = props?.styleClasses ?? '';

	logger.log('[IBMwatsonx] props:%o', props);
	
	return (
		<div className={`help-modal ${styleClasses}`}>
			<div className="help-modal-inner">
			<div className="modal-container">
				<div className="modal-close" onClick={() => props.closeModal()}>
					<i className="icon-close" />
				</div>
				<div className="modal-body">{ReactHtmlParser(props.body)}

					{ 
					/** for external link 
					 *  Android doesn't work - hide the link from Android only
					 */
						window?.webviewPlatform !== "android" && props?.marketingLink?.show && props?.marketingLink?.url && props?.marketingLink?.description && (
							<p>
								{props?.marketingLink?.description} <EventsLink to={props.marketingLink.url} external={true}>{props?.marketingLink?.linkText}</EventsLink>
							</p>
						)
					}
				</div>
			</div>
			</div>
		</div>
	);
};

export default HelpModal;
