import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.CONTENT_LOAD:
			newState = {
				...state,
				...action.data,
				data: '',
				prevNextData: '',
			};
			//logger.log('[ContentPage] CONTENT_LOAD - newState:%o',newState);
			return newState;

		case deps.actionTypes.CONTENT_UNMOUNT:
			newState = {
				...state,
				...action.data,
				data: '',
				prevNextData: '',
			};
			//logger.log('[ContentPage] CONTENT_UNMOUNT - newState:%o',newState);
			return newState;

		default:
			return state;
	}
};
