import React, { Component } from 'react';
import { fetch } from 'appdir/components/general/Util';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import MeasurementUtils from 'appdir/lib/analytics';
import ReactHtmlParser from 'html-react-parser';

const mapStateToProps = (state, props) => ({
	...state['TicketOptions'],
	ticketOptionsURL: state['Config'].otherData.ticket_options,
	ticketOptionsData: null,
	EventsWindow: state['WindowSize'].EventsWindow,
	Router: state['Router'],
	...props,
});

const mapDispatchToProps = dispatch => ({});

class TicketOptions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null,
		};
		this.init = true;
		this.loadedJson = false;
	}

	componentDidMount() {
		this.setState({
			status: 'loaded',
		});
	}

	componentWillUnmount() {}

	componentDidUpdate(prevProps, prevState) {
		/** fetch master players data */
		logger.log('[TicketOptions] componentDidUpdate %o', prevProps);
		if (!this.loadedJson) {
			this.loadedJson = true;
			logger.log('[TicketOptions] componentDidUpdate preview: %o', this.props.Router.params.prefix);
			let ticketOptionsUrl =
				this.props.Router.params.prefix == 'preview'
					? this.props.ticketOptionsURL.replace(/\/en_US\//, '/preview/en_US/')
					: this.props.ticketOptionsURL;
			logger.log('[TicketOptions] componentDidUpdate fetch %o', ticketOptionsUrl);

			fetch(ticketOptionsUrl)
				.then(result => {
					logger.log('[TicketOptions] componentDidUpdate result %o', result);
					this.setState({
						ticketOptionsData: result.ticket_options,
					});
				})
				.catch(error => {
					logger.log('[TicketOptions] componentDidUpdate error:%o', error);
					this.setState({
						status: 'error',
						errorType: error,
					});
				});
		}
	}

	measureNextPrev = (oldIndex, newIndex) => {
		if (oldIndex < newIndex) {
			MeasurementUtils.dispatchMeasurementCall('TicketOptionsSlider', {
				action: 'next',
				curSlide: newIndex,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('TicketOptionsSlider', {
				action: 'prev',
				curSlide: newIndex,
			});
		}
	};

	sessionsRender = (optionKey, sessionid, session) => {
		logger.log('[TicketOptions] - section: %o, sessionid: %o, session: %o', optionKey, sessionid, session);
		return (
			<div className={`to-session to-${sessionid}`} key={optionKey + sessionid}>
				<h3>{session.title}</h3>
				{ReactHtmlParser(session.text)}
			</div>
		);
	};
	optionRender = (option, showButton) => {
		logger.log('[TicketOptions] - option: %o, buttons: %o', option, showButton);
		let optionKey = option.title.replace(/\W/g, '_').toLowerCase();
		return (
			<div className="to-option" key={optionKey}>
				<div className="to-photo-container">
					<img src={option.photo} />
					<h4>{option.title}</h4>
				</div>
				{showButton && option.button?.link ? (
					<div className="to-button">
						<a
							className="uso-button uppercase blue full-width round"
							target={option.button.external ? '_blank' : '_self'}
							href={option.button.link}>
							{option.button.text}
						</a>
					</div>
				) : showButton ? (
					<div className="to-button to-empty"></div>
				) : null}
				{Object.keys(option.sessions).map(session =>
					this.sessionsRender(optionKey, session, option.sessions[session])
				)}
			</div>
		);
	};

	render() {
		logger.log('[TicketOptions] - render: props:%o state: %o', this.props, this.state);

		let numericWindowSize = this.props?.EventsWindow?.numericWindowSize;

		let slidesNum = 1.4;
		let slidesScrollNum = 1;

		if (numericWindowSize == 2) {
			slidesNum = 2.1;
			slidesScrollNum = 2;
		} else if (numericWindowSize > 2 && numericWindowSize < 4) {
			slidesNum = 2.5;
			slidesScrollNum = 2;
		} else if (numericWindowSize > 3 && numericWindowSize < 5) {
			slidesNum = 3.1;
			slidesScrollNum = 1;
		} else if (numericWindowSize > 4) {
			slidesNum = 4;
			slidesScrollNum = 4;
		}

		var settings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: slidesNum,
			slidesToScroll: slidesScrollNum,
			swipeToSlide: true,
			beforeChange: (oldIndex, newIndex) => {
				this.measureNextPrev(oldIndex, newIndex);
			},
		};

		if (this.state.ticketOptionsData) {
			logger.log('[TicketOptions] - ticketOptions: %o', this.state.ticketOptionsData);
			return (
				<section className="contentWrapper ticket-options">
					<div className="title-bar">
						<h3>{this.props.data.title}</h3>
					</div>
					<div className="to-container">
						<Slider {...settings}>
							{this.state.ticketOptionsData.options.map(option =>
								this.optionRender(option, this.state.ticketOptionsData.showButtons)
							)}
							{/* <div className="to-option">
								<div className="to-photo-container">
									<img src="/assets/images/misc/to-viewing-parties.jpg" />
									<h4>Chase Viewing Parties</h4>
								</div>
								<div className="to-button">
									{/* <a
										className="uso-button uppercase blue full-width round"
										target="_blank"
										href="https://www.ticketmaster.com/billie-jean-king-national-tennis-center-tickets-flushing/venue/237620?sort=date%2Casc&tab=eventSchedule&daterange=from20220910-to-20220911">
										View Tickets
									</a> */}
							{/* </div>
								<div className="to-session to-day">
									<h3>&nbsp;</h3>
									<p>
										<b>What's Included?</b>
									</p>
									<ul>
										<li>
											Complimentary Grounds passes courtesy of Chase for the Women’s and Men’s
											Final Matches
										</li>
										<li>Limit of 4 passes per day</li>
										<li>Seating in South Plaza is first come, first-served</li>
									</ul>
								</div>
								<div className="to-session to-night">
									<h3>&nbsp;</h3>
									<p className="to-no-session"></p>
								</div>
							</div> */}
						</Slider>
					</div>
				</section>
			);
		}

		return null;
	}
}

// export default TicketOptions;
export default connect(mapStateToProps, mapDispatchToProps)(TicketOptions);
