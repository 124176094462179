/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

import { fetch } from 'appdir/components/general/Util';
import get from 'lodash/get';
import ReactHtmlParser from 'html-react-parser';
import Slider from 'react-slick';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import EventsLink from 'components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: FanWeekHighlights
 * -----------------------------------------------------------------------------
 */

export default class FanWeekHighlights extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.init = true;
	}

	componentDidMount() {
		this.setState({
			status: 'loaded',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.init && !this.state.highlightsData && this.props.otherData?.fanweek_highlights) {
			this.init = false;

			fetch(this.props.otherData?.fanweek_highlights).then(data => {
				this.setState({
					highlightsData: data,
				});
			});
		}
	}

	measureNextPrev = (oldIndex, newIndex) => {
		if (oldIndex < newIndex) {
			MeasurementUtils.dispatchMeasurementCall('FanWeekHighlightsSlider', {
				action: 'next',
				curSlide: newIndex,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('FanWeekHighlightsSlider', {
				action: 'prev',
				curSlide: newIndex,
			});
		}
	};

	render() {
		logger.log('[FanWeekHighlights] render this:%o', this);

		let numericWindowSize = this.props?.EventsWindow?.numericWindowSize;

		let slidesNum = 1.2;
		let slidesScrollNum = 1;

		if (numericWindowSize > 1 && numericWindowSize < 4) {
			slidesNum = 2.2;
			slidesScrollNum = 2;
		} else if (numericWindowSize > 3) {
			slidesNum = 3;
			slidesScrollNum = 3;
		}

		var settings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: slidesNum,
			slidesToScroll: slidesScrollNum,
			swipeToSlide: true,
			beforeChange: (oldIndex, newIndex) => {
				this.measureNextPrev(oldIndex, newIndex);
			},
		};

		if (this.state.highlightsData && this.state.highlightsData?.items?.length !== 0) {
			let firstEventDate = moment(this.state.highlightsData?.items[0].date).format('YYYY-MM-DD');
			let now = moment()
				.tz('America/New_York')
				.format('YYYY-MM-DD');

			return (
				<div className="fanweek-highlights-wrapper">
					<h3 className="title">{this.props?.data?.title ? this.props.data.title : 'Highlights'}</h3>
					<div className="fanweek-highlights-content">
						<Slider {...settings}>
							{this.state.highlightsData?.items?.length > 0
								? this.state.highlightsData.items.map((item, index) => {
										let image = get(item, 'images[0].url', false);
										let image_alt = get(item, 'images[0].caption', '');

										let end = moment(item.end_tme_epoch).format('YYYY-MM-DD');
										let date = moment(item.date).format('YYYY-MM-DD');

										// if(date === firstEventDate) {
										return (
											<div className="fanweek section" key={item.id + index}>
												{image && (
													<div className="image">
														<img src={image} alt={image_alt} />
													</div>
												)}
												<div className="color-bg">
													<div className="content">
														<div className="title">{item.title}</div>
														<span className="fanweek-date">{item.date}</span>
														<span className="location">{item.location}</span>
														<span className="time">{`${item.start_time} to ${item.end_time} (ET)`}</span>
													</div>
												</div>
												<div className="content">
													<div className="description">
														{ReactHtmlParser(item.description)}
													</div>
													{item.button_text && item.link && (
														<div className="button">
															<EventsLink to={item.link}>{item.button_text}</EventsLink>
														</div>
													)}
												</div>
											</div>
										);
										// }
								  })
								: null}
						</Slider>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

FanWeekHighlights.defaultProps = {};
