/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Header from './general/Header';
import WindowSize from './general/WindowSize';
import EventsRedirect from './general/EventsRedirect';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * React Component: Template
 * -----------------------------------------------------------------------------
 */

export default class TemplateNoFooter extends Component {
	constructor(props) {
		super(props);

		this.mb = null;
		this.navbar = null;
		this.header = null;
		this.ival = null;
		this.state = { ...this.props };
		this.onLoad = this.onLoad.bind(this);
		this.onScroll = this.onScroll.bind(this);
	}

	componentDidMount() {
		window.addEventListener('load', this.onLoad);
		window.addEventListener('scroll', this.onScroll);
		// this.ival = setInterval(() => {
		//     this.setState({ mounted: window.templateMounted });
		// });
	}

	componentWillUnmount() {
		// clearInterval(this.ival);
		// this.ival = null;
		// window.removeEventListener("scroll", this.onScroll);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[TemplateNoFooter] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[TemplateNoFooter] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	onLoad() {
		window.templateMounted = true;
	}

	onScroll(e) {
		// if (!this.navbar || !this.header) {
		//     return;
		// }
		// let {
		//     height: headerHeight
		// } = this.header.container.getBoundingClientRect();
		// let { top, height } = this.navbar.container.getBoundingClientRect();
		// let y = scrollY - headerHeight;
		// if (this.mb !== y) {
		//     let fixed = y >= 0;
		//     this.mb = y >= 0 ? height : 0;
		//     this.setState({ navbarFixed: fixed, headerMarginBottom: this.mb });
		// }
	}

	render() {
		let {
			mounted,
			title,
			bodyClass,
			navbarFixed,
			headerMarginBottom,
			children,
			style,
			description,
			header = {},
		} = this.state;

		//let headerStyle = { marginBottom: headerMarginBottom };

		// if (mounted !== true) {
		//     return (
		//         null
		//     );
		// }

		// logger.log("[TemplateHome] globalLink - %o", this.state.globalLink);
		let redirectLink = op.get(this.state.globalLink, 'link');
		let redirectQuery = op.get(this.state.globalLink, 'query');

		if (redirectLink) {
			// logger.log("[TemplateHome] has globalLink - %o", this.state.globalLink);
			return <EventsRedirect to={redirectLink} query={redirectQuery} />;
		} else {
			return (
				<Fragment>
					<WindowSize />
					<Helmet>
						<title>{title}</title>
						<meta name="description" content={description} />
						<html lang="en" />
						<body className={bodyClass} />
					</Helmet>
					<Header />
					<main role="main">{children}</main>
				</Fragment>
			);
		}
	}
}

TemplateNoFooter.defaultProps = {
	header: {},
	mounted: false,
	navbarFixed: false,
	headerMarginBottom: 0,
	title: 'US Open',
	description: 'This is a react US Open Site',
};
