import React from 'react';
import PlayerMatchUpOverview from './PlayerMatchUpOverview';

const formatMatchOverviewData = (data = []) => {
	return data.map((d, i) => {
		return {
			...d,
			matchId: d.match_id,
			location: d.courtName,
			event: d.eventName,
			round: d.roundName,
			progress: d.status,
			name1: d.team1.displayNameA,
			name2: d.team2.displayNameA,
			id1: d.team1.idA,
			id2: d.team2.idA,
			nation1: d.team1.nationA,
			nation2: d.team2.nationA,
			rank1: d.team1.seed,
			rank2: d.team2.seed,
			duration: d.duration,
			playerPts: d.scores.sets,
			playerWon: d.team1.won ? 'player1' : 'player2',
			showDetail: 0,
		};
	});
};

const PlayerMatches = ({ matchData, playerConfiguration, playerID }) => {
	let playerMatchOverview = formatMatchOverviewData(matchData.matches);
	let playerConfig = playerConfiguration;
	return (
		<section>
			<PlayerMatchUpOverview
				attributes={playerMatchOverview}
				playerConfiguration={playerConfig}
				playerID={playerID}
			/>
		</section>
	);
};

export default PlayerMatches;
