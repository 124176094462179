/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Template from 'components/Template';

import FanWeekSchedule from 'appdir/components/general/FanWeekSchedule';
import MeasurementUtils from 'appdir/lib/analytics';
import { fetch } from 'appdir/components/general/Util';
/**
 * -----------------------------------------------------------------------------
 * React Component: Home
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['FanWeek'],
		...props,
	};
};

class FanWeek extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
		};

		this.currentDay = 0;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Fan Week Calendar',
		});
		//logger.log('[FanWeek] constructor - props:%o', props);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.fanweekPath && !this.state.fanweekData) {
			fetch(this.props.fanweekPath)
				.then(result => {
					//logger.log('[FanWeek] componentDidUpdate - result:%o', result);
					this.setState({
						fanweekData: result.fanweek,
					});
				})
				.catch(error => {
					logger.error('[FanWeek] componentDidUpdate error:%o', error);
				});
		}

		if (this.state.fanweekData && this.currentDay == 0) {
			this.getSelectedDay(this.state.fanweekData.days);
		}

		//logger.log('[FanWeek] componentDidUpdate - state:%o', this.state);
	}

	onDaySelect(id) {
		//logger.log('[FanWeek] onDaySelect - id:%o', id);
		this.setState({
			selectedDay: id,
		});

		const currentState = this.state.active;
		this.setState({ active: !currentState });
	}

	getSelectedDay(data) {
		for (var i = 0; i < data.length; i++) {
			if (data[i].current == true) {
				this.currentDay = data[i].id;
				this.setState({
					selectedDay: this.currentDay,
				});
			}
		}
	}

	render() {
		//logger.log('[FanWeek] render props:%o', this.props);

		// if (this.state.fanweekData && this.state.selectedDay) {
		// let dayDetails = this.state.fanweekData.days;
		// let selectedDayID = this.state.selectedDay;

		// if you are creating a header like scores which is transparent, make sure you update the
		// header component and make a new style for the new header.
		let header_attributes = {
			headerType: 'fanweek',
			title: 'US Open 2024 FanWeek',
			metaTitle: 'US Open 2024 FanWeek',
			metaDescription:
				'The 2024 US Open begins August 19th to September 8th. View the session times and rounds of the 2024 Tournament FanWeek',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/about/eventschedule.html',
					title: 'Schedule',
				},
			],
			sponsor: {
				link: 'http://www.rolex.com',
				title: 'Rolex',
				name: 'Rolex',
				imgClass: '',
				imgSrc: '/assets/images/logos/rolex_logo_sm.png',
				tagline: 'In partnership with',
			},
			title: 'FanWeek',
		};

		return (
			<Template header={header_attributes} subHeader={subheader_attributes}>
				<h1 className="header">2019 Tournament FanWeek</h1>
				<section className="page-content tourn-fanweek uso-tickets-calendar">
					<FanWeekSchedule />
				</section>
			</Template>
		);
	}
}

export default connect(mapStateToProps)(FanWeek);
