/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { Link } from 'react-router-dom';
import { fetch } from 'appdir/components/general/Util';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from 'components/Template';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: SiteMap
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SiteMap'],
		stubs: state['Config'].stubPages,
		scoringVars: state['Config'].scoring,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	unmount: data => dispatch(deps.actions.SiteMap.unmount(data)),
});

class SiteMap extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedIndex: 0,
			sponsorList: null,
			loadedJson: false,
		};

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Site Map',
		});
		// logger.log('[SiteMap] constructor - this: %o', this);
	}

	componentDidMount() {
		// logger.log('[SiteMap] componentDidMount');
		if (
			!this.state.sponsorList &&
			!this.state.loadedJson &&
			this.props.sponsorListPath &&
			this.props.scoringVars !== undefined
		) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		// logger.log('[SiteMap] unmounted');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[SiteMap] componentDidUpdate - this:%o', this);

		// calculate new data path from path in state and current filters
		if (
			!this.state.loadedJson &&
			!this.isStubbed() &&
			this.props.sponsorListPath &&
			this.props.scoringVars !== undefined
		) {
			this.fetchData();
		}
	}

	isStubbed() {
		return this.props.stubs && this.props.stubs.competitors
			? this.props.stubs.competitors == 'stub'
				? true
				: false
			: false;
	}

	fetchData() {
		logger.log('[SiteMap] fetchData - this:%o', this);

		if (this.props.scoringVars.seedsAvailable == 'true') {
			fetch(this.props.sponsorListPath)
				.then(data => {
					this.setState(prevState => {
						return { ...prevState, sponsorList: data };
					});
				})
				.catch(error => {
					logger.log('[SiteMap] fetchData error loading data: %o', error);
				});
		}

		this.setState(prevState => {
			return { ...prevState, loadedJson: true };
		});
	}

	createTableHeaders() {
		if (this.props.filters.listView == 'seeds') {
			return ['Seed', 'Name', '', 'Opponent', 'Round'];
		} else {
			return ['Rank', 'Name'];
		}
	}

	formatTableData(data) {
		if (this.props.filters.listView == 'seeds') {
			let seedData = data.seeds;
			return seedData.map((d, i) => {
				return {
					seed: d['rank'],
					name:
						d['tour-id'] != null ? (
							<Link to={`/en_US/players/overview/${d['tour-id']}.html`}>{d['long-name']}</Link>
						) : (
							d['long-name']
						),
					text: d['result'],
					opponent:
						d['opp-tour-id'] != null ? (
							<Link to={`/en_US/players/overview/${d['opp-tour-id']}.html`}>{d['opp-long-name']}</Link>
						) : (
							d['opp-long-name']
						),
					round: d['round'],
				};
			});
		}
	}

	renderSubMenu(data) {
		logger.log('[SiteMap] renderSubMenu data: %o', data);
		return (
			<ul className="submenu">
				{data.map((d, i) => {
					return (
						<li key={i} className={`submenu_item`}>
							<EventsLink to={d.link.url}>{d.title}</EventsLink>
						</li>
					);
				})}
			</ul>
		);
	}

	getPageData() {
		let tableTitle;
		let headerData;
		let tableRowData;
		let pageClass;

		tableTitle = "Men's Seeds";
		headerData = this.createTableHeaders();
		tableRowData = this.formatTableData(this.state.sponsorList);

		return {
			tableTitle: tableTitle,
			headers: headerData,
			data: tableRowData,
			tableContainer: 'competitorList',
			pageClass: pageClass,
		};
	}

	setSelected(index) {
		this.setState(prevState => {
			return { ...prevState, selectedIndex: index };
		});
	}

	render() {
		logger.log('[SiteMap] render - this:%o', this);

		let header_propsData = {
			headerType: 'site',
			metaTitle: 'Site Map',
			metaDescription: '',
			scroll: false,
			canonicalLink: 'https://www.usopen.org/en_US/info/sitemap/index.html',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			title: 'Site Map',
			page_header: 'Site Map'
		};

		if (!this.props.stubs) {
			return null;
		}

		if (this.isStubbed()) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<StubBox
						attributes={{
							title: header_propsData.title,
							message: this.props.stubs.competitors.text,
							basePicPath: this.props.basePicPath,
						}}
					/>
				</Template>
			);
		} else {
			if (this.state.loadedJson) {
				if (this.state.sponsorList) {
					//let propsData = this.getPageData();

					return (
						<Template header={header_propsData} subHeader={subheader_attributes}>
							<h1 className="header">Site Map</h1>
							<section className="wrapper article-wrapper">
								<div className="general_content site-map">
								<div className="siteDetailContainer">
									<div id="sitemap" className="siteDetail">
										<ul className="main_menu">
											<li className="main_menu_item">
												<a href="/index.html" title="Home">
													Home
												</a>
											</li>
											{this.state.sponsorList.menu.item.map((item, i) => {
												if (('show' in item && item.show == 'true') || !('show' in item)) {
													return (
														<li key={i} className="main_menu_item">
															<EventsLink to={item.link.url}>{item.title}</EventsLink>
															{item.sub_menu && item.sub_menu.length
																? this.renderSubMenu(item.sub_menu)
																: null}
														</li>
													);
												}
											})}
										</ul>
									</div>
								</div>
								</div>
							</section>
						</Template>
					);
				} else {
					return (
						<Template header={header_propsData} subHeader={subheader_attributes}>
							<h1 className="header">Site Map</h1>
							<div className="site_content container">
								<div className="siteDetailContainer">
									<div id="sitemap">
										<ul className="main_menu">
											<li className="main_menu_item">
												<a href="/index.html" title="Home">
													Home
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</Template>
					);
				}
			} else {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes}>
						<h1 className="header">Site Map</h1>
						<div className="site_content container">
							<div className="siteDetailContainer">
								<LoadingIndicator />
							</div>
						</div>
					</Template>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMap);
