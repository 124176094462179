import React, { Component } from 'react';
import Template from 'components/Template';

/**
 * -----------------------------------------------------------------------------
 * React Component: NotFound
 * -----------------------------------------------------------------------------
 */

export default class InternationalSchedule extends Component {
	render() {
		let header_attributes = {
			headerType: 'stub',
			minHeight: 190,
			metaTitle: 'International TV Schedule',
			metaDescription:
				'View the TV Schedule to learn where you can watch the 2020 US Open both domestic and internationally.',
			metaDate: '',
			metaPlayers: '',
			scroll: true,
			hideElems: ['.category-question-list'],
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/about/eventschedule.html',
					title: 'Schedule',
				},
			],
			sponsor: [],
			title: 'International TV Schedule',
			page_header: 'International TV Schedule',
		};

		return (
			<Template header={header_attributes} subHeader={subheader_attributes}>
				<div className="content-main">
					<section className="heroSection">
						<div className="heroImage">
							<div className="heroContainer heroStub">
								<img
									src="/images/pics/large/f_stub_image_2020.jpg"
									alt="LET! This page does not exist."
								/>
								<div className="heroInfo">
									<h2>Coming Soon</h2>
									<h1>International TV Schedule</h1>
									<div>
										<p>
											In addition to its domestic coverage, the 2019 US Open will also be
											showcased around the world by its international broadcast media partners.
											Eurosport is the exclusive broadcast partner of the US Open in continental
											Europe, reaching 52 countries. WOWOW has partnered with the US Open since
											1992 and holds the exclusive US Open television rights in Japan. Other
											international partners include Prime Video in the United Kingdom and
											Ireland; CCTV and Tencent in China; Star India in the Indian subcontinent;
											beIN Sports in the Middle East and North Africa; ESPN International in
											Mexico, Central America, the Caribbean, South America and Oceania; Fox
											Sports in Southeast Asia; TSN and RDS in Canada; and SuperSport in
											Sub-Saharan Africa.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</Template>
		);
	}
}
