import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import isEmpty from 'lodash/isEmpty';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: Gallery
 * -----------------------------------------------------------------------------
 */

class Gallery extends Component {
	constructor(props) {
		super(props);
		//logger.log('[Gallery] - props:%o', props);
		this.state = { ...this.props.data };
		this.viewedIndex = 0;
	}

	componentDidMount() {
		logger.log('[GalleryMount] - this:%o', this);
	}

	onChange(e) {
		// logger.log('[GalleryMount] - changed:%o', e);
		let images = this.state['images']['image'];

		if (e > this.viewedIndex) {
			MeasurementUtils.dispatchMeasurementCall('News Carousel: Next', {
				caption: images[e].caption,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('News Carousel: Prev', {
				caption: images[e].caption,
			});
		}
		this.viewedIndex = e;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[Gallery] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[Gallery] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	// renderNav(direction, clickFn) {
	//   return (
	//     <div
	//       onClick={() => {
	//         clickFn(direction);
	//       }}
	//       className={`arrow-wrapper-${direction}`}
	//     >
	//       <i className={`icon-${direction}-arrow`} />
	//     </div>
	//   );
	// }

	componentDidUpdate(prevProps, prevState) {}

	render() {
		logger.log('[Gallery] - this:%o', this);

		let images = this.state['images']['image'];

		return images ? (
			<div className="inline-carousel-container">
				<Carousel
					showThumbs={false}
					useKeyboardArrows={true}
					infiniteLoop
					showIndicators={false}
					centerMode
					onChange={e => this.onChange(e)}
					centerSlidePercentage={90}>
					{images.map((item, i) => (
						<div key={i}>
							<img src={item.url} alt={item.title || item.caption} />
						</div>
					))}
				</Carousel>
			</div>
		) : null;
	}
}

export default Gallery;
