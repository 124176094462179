import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//pageView(ANALYTICS_PROPS.liveScores);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// this will call the reducer in controller which will set the redux state
			//logger.log('[SummaryScoreboard] mount state.status:%o',getState().LiveScores.status);
			let data = {
				status: 'loaded',
				basePicPath: Config.otherData.basePicPath,
				paths: {
					schedule: Config.scoringData.schedule,
					completed: Config.scoringData.completedMatches,
					final: Config.scoringData.liveScore.path,
				},
			};
			dispatch({ type: deps.actionTypes.SUMSCORES_MOUNT, data: data });
		});
	},
	unmount: () => (dispatch, getState, store) => {
		// when unloading the mip page, reset Scoring data in the redux state
		// also reset the status of the page to null
		// I think this will change later.  May not need to reset scoring data in all cases.
		// Cannot reset Scoring data in Controller, because Controller puts everything in localstorage and
		// we don't want to do that.
		status = null;
		dispatch({
			type: deps.actionTypes.SUMSCORES_UNMOUNT,
			data: { status: status },
		});
	},
};
