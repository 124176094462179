import OpenQuestionsDetail from './index';

export default {
	path: [
		'/:lang(en_US)/open_questions/detail/:question/index.html',
		'/:lang(en_US)/open_questions/detail/:question/',
	],
	exact: true,
	component: OpenQuestionsDetail,
};
