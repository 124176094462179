import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import throttle from 'lodash/throttle';
import { getScrollPos } from 'appdir/components/general/Util';

let popstateEvent = false;

const mapStateToProps = (state, props) => {
	return {
		...state['ScrollHandler'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ScrollHandler.mount()),
	unmount: data => dispatch(deps.actions.ScrollHandler.unmount(data)),
});

class ScrollHandler extends Component {
	constructor(props) {
		super(props);

		//logger.log('[ScrollHandler] constructor - this:%o', this);

		this.bgSpace;
		this.wrapper;
		this.spacer;
		this.scrollPosY;
		this.scrolled = false;

		this.checkScroll();
	}

	componentDidMount() {
		//logger.log('[ScrollHandler] componentDidMount - this:%o ', this);
		this.props.mount();

		this.wrapper = document.querySelector('.wrapper');
		this.bgSpace = document.querySelector('.backgroundSpace');
		this.spacer = document.querySelector('.content-filters');

		window.addEventListener('scroll', throttle(this.onScroll.bind(this), 0), false);

		let self = this;
		window.onpopstate = event => {
			// logger.log('[ScrollHandler] onpopstate - state:%o this:%o', event, self);
			popstateEvent = true;
		};
	}

	componentWillUnmount() {
		let scroll = getScrollPos();
		logger.log('[ScrollHandler] componentWillUnmount - scroll:%o ', scroll);
		this.props.unmount({
			scrollPos: scroll,
		});

		popstateEvent = false;
		window.removeEventListener('scroll', this.onScroll.bind(this), false);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[ScrollHandler] componentDidUpdate - state:%o', this.state);

		this.wrapper = document.querySelector('.wrapper');
		this.bgSpace = document.querySelector('.backgroundSpace');
		this.spacer = document.querySelector('.content-filters');

		//if filter has changed and scrollPos.y is 0, scroll to top
		if (this.scrollPosY && this.props.filters != prevProps.filter) {
			//logger.log('[ScrollHandler] componentDidUpdate - this.scrollPosY:%o', this.scrollPosY);
			window.scrollTo(0, this.scrollPosY);
		}

		this.checkScroll();
	}

	onScroll() {
		//logger.log('[ScrollHandler] onScroll - prev:%o next:%o' );
		if (this.wrapper) {
			if (!this.wrapper.classList.contains('fixed')) {
				//this.scrollPosY = this.bgSpace.getBoundingClientRect().top * -1;
				if (this.bgSpace) {
					this.scrollPosY = this.bgSpace.getBoundingClientRect().top * -1;
				}
			} else {
				this.scrollPosY = this.bgSpace.getBoundingClientRect().height - this.spacer.getBoundingClientRect().top;
			}
			//logger.log('[ScrollHandler] onScroll - this.scrollPosY:%o spacer:%o bgSpace:%o', this.scrollPosY, this.spacer.getBoundingClientRect().top, this.bgSpace.getBoundingClientRect().top);
		}
	}

	checkScroll() {
		logger.log(
			'[ScrollHandler] checkScroll - popstateEv:%o scrolled:%o props:%o',
			popstateEvent,
			this.scrolled,
			this.props
		);

		// scroll to last position if browser back/history nav used
		let scroll = getScrollPos();
		if (
			popstateEvent &&
			!this.scrolled &&
			this.props.hasData &&
			this.props.scrollPos &&
			this.props.scrollPos.y != scroll.y
		) {
			//logger.log('[NewsIndex] componentDidUpdate - scroll:%o', this.state.scrollPos.y);
			this.scrolled = true;
			window.scrollTo(0, this.props.scrollPos.y);
		}
	}

	render() {
		//logger.log('[Login] render - state:%o', this.state);

		return <span />;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollHandler);
