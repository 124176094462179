/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';
import EventsLink from 'appdir/components/general/EventsLink';
// import VideoPlayer from 'appdir/components/content/VideoPlayer';
import { getAppVideoLink } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Tile
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const Tile = props => {
	//logger.log('[Tile] - props:%o', props);
	const dispatch = useDispatch();

	const renderVideoItem = (video) => {
		let videoData = {
			video: {
				...video,
				autoplay: true,
				playsinline: true,
				streams: [video?.streams?.stream],
				tracks: [video?.tracks?.track]
			}
		};

		// logger.log('[Tile] - videoData:%o', videoData);

		if(!window.webview) { 
			dispatch(deps.actions.ModalOverlay.loadOverlayModal('VIDEO_MODAL', videoData))
		} else {
			let videoLink = getAppVideoLink(video.id);
            videoLink?.onclick();
		}
	}

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	logger.log('[Tile] - attr:%o', attributes);
	if (attributes && attributes.images && attributes['style'] == 'chip-4col') {
		let image = attributes['images']['image'];

		return (
			<div className="col">
				<img src={image['url']} alt={image['title']} />
				<span className="desc">{attributes['title']}</span>
			</div>
		);
	} else if (attributes && attributes.images && attributes['style'] == 'chip-3col') {
		let image = attributes['images']['image'];
		return (
			<div className="chip">
				<EventsLink to={`${attributes['link']}`}>
					<div className="photoContainer">
						<div>
							<img src={image['url']} alt={image['title']} />
						</div>
					</div>
					<h3>{attributes['title']}</h3>
					<div className="description">
						{attributes['description']}

						{attributes['linkStr'] ? (
							<>
								{' '}
								<span>{attributes['linkStr']}</span>
							</>
						) : null}
					</div>
				</EventsLink>
			</div>
		);
	} else if (attributes && attributes.images) {
		let image = attributes['images']['image'];
		
		if (attributes.contentType == 'video') {
			return (
				<div
					className="chip video-chip"
					onClick={() => renderVideoItem(attributes?.video)}
					onKeyDown={e => {
						if (e.key == ' ' || e.code == 'Space') {
							renderVideoItem(attributes?.video);
						}
					}}
					tabIndex={0}>
					<div className="photoContainer">
						<div className="playBtn"> </div>
						<img src={image['url']} alt={image['title']} />
					</div>
					<div className="caption">{attributes['title']}</div>
					<div className="description">{attributes['description']}</div>
					<button className="uso-button">{attributes.contentType == 'video' ? `Watch` : `Learn More`}</button>
				</div>
			);
		} else {
			if (attributes.link !== '' && attributes.link !== 'undefined') {
				return (
					<EventsLink to={`${attributes['link']}`}>
						<div className="chip">
							<div className="photoContainer">
								<img src={image['url']} alt={image['title']} />
							</div>
							<div className="caption">{attributes['title']}</div>
							<div className="description">{attributes['description']}</div>
							<button className="uso-button">Learn More</button>
						</div>
					</EventsLink>
				);
			} else {
				return (
					<div className="chip">
						<div className="photoContainer">
							<img src={image['url']} alt={image['title']} />
						</div>
						<div className="caption">{attributes['title']}</div>
						<div className="description">{attributes['description']}</div>
					</div>
				);
			}
		}
	} else {
		return null;
	}
};

export default Tile;
