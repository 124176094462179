import LiveAtUSOpen from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/:lang(en_US)/live-at-the-us-open', '/:lang(en_US)/live-at-the-us-open/index.html'],
		exact: true,
		component: LiveAtUSOpen,
		load: params => deps.actions.LiveAtUSOpen.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/live-at-the-us-open/:id.html',
		exact: true,
		component: LiveAtUSOpen,
		load: params => deps.actions.LiveAtUSOpen.mount(params),
	},
];
