import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import uniq from 'lodash/uniq';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...props,
		favorites: state['Controller'].favorites,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	update: data => dispatch(deps.actions.Controller.update(data)),
});

class FavoriteStar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	isFavorited = (id, type) => {
		let contentType = type;
		let favArry = {
			...this.props.favorites,
		};

		favArry[contentType] = op.get(this.props, `favorites.${contentType}`, []);

		if (favArry[contentType].indexOf(id) !== -1) {
			return true;
		} else {
			return false;
		}
	};

	updateFavorites = (id, type) => {
		let contentType = type;
		let updatedFav = {};
		let favArry = {
			...this.props.favorites,
		};

		favArry[contentType] = op.get(JSON.parse(JSON.stringify(this.props)), `favorites.${contentType}`, []);

		if (favArry[contentType].indexOf(id) !== -1) {
			const contentIndex = favArry[contentType].indexOf(id);
			favArry[contentType].splice(contentIndex, 1);
			this.measureCall('Favorite Remove', id);
		} else if (id !== undefined) {
			favArry[contentType].push(id);
			this.measureCall('Favorite Add', id);
		}
		favArry[contentType] = favArry[contentType].filter(d => d !== null && d !== '');
		favArry[contentType] = uniq(favArry[contentType]);
		updatedFav = favArry;
		this.props.update({ favorites: { ...updatedFav } });
	};

	measureCall = (action, id) => {
		MeasurementUtils.dispatchMeasurementCall(action, { val: id });
	};

	render() {
		// logger.log('[FavoriteStar] render - this %o', this);
		return this.props.alwaysShow ? (
			<span
				onClick={() => {
					this.updateFavorites(this.props.id, this.props.type);
				}}
				onKeyPress={() => (event.key === 'Enter' ? this.updateFavorites(this.props.id, this.props.type) : null)}
				tabIndex={0}
				className={`favorite ${
					this.isFavorited(this.props.id, this.props.type)
						? 'favorited ' + this.props.favColor
						: this.props.unFavColor
				}
		  ${this.props?.style ? this.props?.style : ''}`}></span>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteStar);
