import React from 'react';
import Button from 'appdir/components/common-ui/Button';
import EventsLink from 'appdir/components/general/EventsLink';
const mobileMatch = window.matchMedia('(max-width: 510px)');

const PlayerStatus = props => {
	//logger.log('[PowerIndexLeaderboard - PlayerStatus] props:%o', props);

	const { statusText, preMatchInsight, matchId } = props;
	return (
		<>
			<div className="player-match-status">{statusText.matchStatus}</div>
			{statusText.playerOpponent ? (
				<div className="player-oponent">
					<span className="versus-text">vs</span> {statusText.playerOpponent}
				</div>
			) : null}
			{matchId !== null &&
				(preMatchInsight &&
				statusText.statusCode == 'B' &&
				statusText.court &&
				(statusText.eventCode == 'MS' || statusText.eventCode == 'WS') ? (
					<Button className={`blue full ${mobileMatch.matches ? 'compact' : ''}`}>
						<EventsLink to={`/en_US/scores/stats/${matchId}.html`} webviewCare={true}>
							Match Preview
						</EventsLink>
					</Button>
				) : statusText.statusCode == 'B' && (statusText.eventCode == 'MS' || statusText.eventCode == 'WS') ? (
					<Button className="blue full disabled">Match Preview</Button>
				) : statusText.statusCode !== 'B' && (statusText.eventCode == 'MS' || statusText.eventCode == 'WS') ? (
					<Button className={`blue full ${mobileMatch.matches ? 'compact' : ''}`}>
						<EventsLink to={`/en_US/scores/stats/${matchId}.html`} webviewCare={true}>
							Match Details By IBM SlamTracker
						</EventsLink>
					</Button>
				) : (
					<Button className={`blue disabled`}>Match Details By IBM SlamTracker</Button>
				))}
		</>
	);
};

export default PlayerStatus;
