export const MatchInsightsInfoText = {
	header: {
		title: 'Match Insights with Watson',
		body:
			"Match Insights with IBM Watson are AI-generated previews that help fans get up to speed on every singles match. Combining the power of hybrid cloud and AI, Match Insights analyze data from thousands of sources -- from recent statistics to expert commentary -- producing projections of each player's Likelihood to Win, an explanation of their Win Factors, and a measurement of player momentum called the IBM Power Index. Match Insights also include relevant quotes from the media and a natural language summary of key performance metrics.",
	},
	'power-ranking': {
		title: 'IBM Power Index',
		body: "The world's first AI-powered measurement of player momentum board:",
	},
	'win-factors': {
		title: 'Win Factors',
		body:
			'<strong>Win Factors</strong> detail the reasoning of the AI algorithm that calculates the Likelihood to Win predictions. These predictions go beyond scores and statistics to include analysis of the volume and sentiment of media commentary as a critical measure of player momentum.',
	},
};
