/**
 Header Initial State
*/

export default {
  imageSrc: "/assets/images/headers/generic_default.jpg",
  headerType: "unset",
  scrollElem: ".content-main",
  titleElem: ".init",
  scroll: false,
  stickyOn: false,
  status: ""
};
