import deps from "dependencies";

export default {
  mount: () => (dispatch, getState, store) => {
    logger.log("[SlamtrackerLanding] action.mount - ensureConfig");
    return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
      Config => {
        logger.log("[SlamtrackerLanding] action.mount");
        let data = {
          status: "loaded"
        };
        dispatch({
          type: deps.actionTypes.SLAMTRACKERLANDING_MOUT,
          data
        });
      }
    );
  }
};
