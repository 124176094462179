import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MATCHBOX_MOUNTED: {
			newState = { ...state, MatchBoxConfig: action.data };
			return newState;
		}
		default:
			return state;
	}
};
