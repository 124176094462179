/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import JSXParser from 'react-jsx-parser';
import Translate from '../../Translate';

/**
 * -----------------------------------------------------------------------------
 * React Component: STChallenges
 * -----------------------------------------------------------------------------
 */

const STChallenges = props => {
	const renderChallengeContent = () => {
		let html = '';
		let i;

		for (i = 0; i < props.data.available; i++) {
			if (i < props.data.used) {
				html += '<div className="item"></div>';
			} else {
				html += '<div className="item available"></div>';
			}
		}

		return <JSXParser jsx={html} />;
	};

	if (props.data.available > 0) {
		return (
			<div className="content">
				{renderChallengeContent()}
				<div className="text">
					<Translate originalText="challenges" />
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default STChallenges;
