import React from 'react';
import { isNumeric } from 'appdir/components/general/Util';
import cn from 'classnames';

const RankingBar = props => {
	const { data = {} } = props;

	//logger.log('[RankingBar] props:%o', props);

	const renderBar = type => {
		const getBarWidth = value => {
			return `${value}%`;
		};

		const getBarClassnames = (type, value, item) => {
			return cn({
				'bar-graph-bar': true,
				neg: type == 'double' && value < 0 && item === 1,
				pos: type == 'double' && value > 0 && item === 2,
			});
		};

		switch (type) {
			case 'single':
				return (
					<div className="bar-graph-wrapper">
						<div className="bar-graph-title">{data.title}</div>
						<div className="bar-graph-container">
							<div
								className={getBarClassnames(data.type, data.value)}
								style={{ width: getBarWidth(data.value) }}></div>
						</div>
					</div>
				);
				break;
			case 'double':
				return (
					<div className="bar-graph-wrapper">
						<div className="bar-graph-title">{data.title}</div>
						<div className="bar-graph-container double">
							<div
								className={getBarClassnames(data.type, data.value, 1)}
								style={{ width: getBarWidth((Math.abs(data.value) * 100) / 2) }}></div>
							<div
								className={getBarClassnames(data.type, data.value, 2)}
								style={{ width: getBarWidth((Math.abs(data.value) * 100) / 2) }}></div>
							<div className="bar-graph-divider"></div>
						</div>
						<div className="bar-graph-footer">Neutral</div>
					</div>
				);
			default:
				return null;
		}
	};

	return <>{data.type && isNumeric(data.value) ? renderBar(data.type, data.value) : null}</>;
};

export default RankingBar;
