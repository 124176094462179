/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * ---------------------------------------
 * Functional Component: ButtonGeneral - generic button
 *
 * Props.data
 *  @param {String} url || @param {String} link
 *  @param {String} title || @param {String} text
 *  @param {Object} measureData
 *
 * ---------------------------------------
 */

const ButtonGeneral = props => {
	let attributes = props.data;
	// logger.log('[ButtonGeneral] - attr:%o', attributes);

	let link = attributes?.url;
	let title = attributes?.title;
	let style = attributes?.style;
	let measureData = attributes?.measureData || null;
	let onClick = attributes?.onClick;

	if (attributes?.link && attributes?.link !== '') {
		link = attributes.link;
	} else if (attributes?.url) {
		/** in case spread button object is passed and there is `link`,
		 *  if it's empty, falls back to look for `url`
		 */
		link = attributes.url;
	}

	if (attributes?.text && attributes?.text !== '') {
		title = attributes.text;
	} else if (attributes?.title) {
		/** in case spread button object is passed and there is `text`,
		 *  if it's empty, falls back to look for `title`
		 */
		title = attributes.title;
	}

	if (attributes && link && title) {
		if(onClick) {
			return (
				<div className={style} to={link} onClick={() => props.data.onClick(measureData)}>
					{/* <button className={style}>{title}</button> */}
					{title}
				</div>
			);
		} else {
			return (
				<EventsLink to={link} {...(measureData && { measureData })}>
					<button className={style}>{title}</button>
				</EventsLink>
			);
		}
	} else {
		return null;
	}
};

export default ButtonGeneral;
