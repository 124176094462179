import deps from 'dependencies';

export default {
	loadOverlayModal: (modalType, modalParams) => (dispatch, getState, store) => {
		logger.log('[ModalOverlay] actions.loadOverlayModal: modalType:%o, modalParams:%o', modalType, modalParams);

		let data;

		switch (modalType) {
			case 'SHARE_MODAL':
				// in case url is passed in
				data = {
					modalType: modalType,
					share_url: modalParams.share_url ? modalParams.share_url : null,
					share_section: modalParams.share_section ? modalParams.share_section : null,
					share_title: modalParams.share_title ? modalParams.share_title : null,
				};
				break;
			case 'MATCH_INSIGHTS':
				data = {
					modalType: modalType,
					matchId: modalParams.matchId ? modalParams.matchId : null,
				};
				break;
			case 'TICKETS_INFO':
			case 'FAN_WEEK_SCHEDULE':
			case 'VIDEO_MODAL':
				data = {
					modalType: modalType,
					modalParams,
				};
				break;			
			default:
				data = {
					modalType: modalType,
				};
				break;
		}

		dispatch({ type: deps.actionTypes.SHOW_MODAL, data: data });
	},

	hideOverlayModal: () => (dispatch, getState, store) => {
		// logger.log('[ModalOverlay] actions.hideOverlayModal');
		document.body.classList.remove('modal-open');

		dispatch({ type: deps.actionTypes.HIDE_MODAL });
	},
};
