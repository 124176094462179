import React, { Component } from 'react';
import PowerIndexLeaderboard from 'appdir/components/pages/PowerIndexLeaderboard';

export default class PowerIndexLeaderboardWebview extends Component {
	constructor(props) {
		super(props);
	}

	componentWillUnmount() {
		// logger.log('[HistoricalStats] componentWilUnmount - state:%o', this.state);
	}

	componentDidMount() {
		// logger.log('[HistoricalStats] componentDidMount - state:%o', this.state);
	}

	componentDidUpdate(prevState, newState) {
		// logger.log('[HistoricalStats] - componentDidUpdate prevState:%o', prevState);
	}

	render() {
		// logger.log('[HistoricalStats] render - this.props: %o', this.props);

		return <PowerIndexLeaderboard />;
	}
}
