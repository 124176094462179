/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Header from './general/Header';
import Footer from './general/Footer';
import MobileNav from './general/MobileNav';
import SubHeader from './general/SubHeader';
// import BackToTop from './general/BackToTop';
import WindowSize from './general/WindowSize';
import isEmpty from 'lodash/isEmpty';
import ModalOverlayContainer from './general/ModalOverlay/ModalOverlayContainer';
import AdUnitLazy from './general/AdUnitLazy';
import AdTag from './general/AdTag';

import op from 'object-path';
import { DFPSlotsProvider, DFPManager } from 'react-dfp';

import EventsRedirect from './general/EventsRedirect';

/**
 * -----------------------------------------------------------------------------
 * React Component: Template
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Template'],
		Config: state['Config']['dataSettings'],
		globalLink: state['Controller'].globalLink,
		Router: state['Router'],
		stubHideAd: state['MainNav'].hideAd,
		adConfig: state['Config'].adConfig,
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	//mount: () => dispatch(actions.Template.mount()),
});

class Template extends Component {
	constructor(props) {
		super(props);

		this.mb = null;
		this.navbar = null;
		this.header = null;
		this.hashScrolling = false;
		this.hashHandled = false;
		this.count = 0;
		this.adReload_timeout = {};
		this.adStatus = {};
		this.state = {
			scrollToElement: null,
		};
		this.handleEmptyAd = this.handleEmptyAd.bind(this);
		logger.log('[Template] constructor this:%o',this);


	}

	componentDidMount() {
		window.addEventListener('load', this.onLoad);
		//window.addEventListener('scroll', this.onScroll);
		
		this.setState({
			templateLoaded: true
		}, () => {
			DFPManager.getGoogletag().then((googletag) => {
				logger.log('[Template AdTag] componentDidMount googletag:%o',googletag);
				
				googletag.cmd.push(() => {
					googletag.pubads().addEventListener('slotRenderEnded', event => this.handleEmptyAd(event));
				})
				
			})
			.catch((error) => {
				logger.log('[Template AdTag] componentDidMount googletag getSlots error:%o', error);
			})
		})

	}

	handleEmptyAd(event) {
		let slotId = event?.slot?.getSlotElementId();
		try {
			if (event.isEmpty == true && slotId) {
				logger.log('[Template AdTag] handleEmptyAd event.isEmpty:%o, slotId:%o, adStatus:%o',event.isEmpty, slotId, this?.adStatus);
	
				// if (this?.adStatus?.[slotId] == undefined) {
				// 	if (this.adReload_timeout[slotId]) {
				// 		clearTimeout(this.adReload_timeout[slotId]);
				// 	}
				// 	//this.containsAd = !event.isEmpty;  
				// 	this.adStatus[slotId] = 'loading';
				// 	logger.log('[Template AdTag] handleEmptyAd slot reload slotId:%o, startTimer:%o', slotId, this?.adStatus?.[slotId]);
	
				// 	this.adReload_timeout[slotId] = setTimeout(() => {
				// 		//DFPManager.refresh(slotId);	// i don't think we should be forcing a refresh.  
				// 		this.adStatus[slotId] = 'loaded';
				// 		logger.log('[Template AdTag] handleEmptyAd slot reload slotId:%o, endTimer:%o', slotId, this?.adStatus?.[slotId]);
				// 	}, 5000);
				// }
			}
		} catch (e) {
			logger.log('[Template AdTag] handleEmptyAd e:%o', e);

		}
		
	}

	componentWillUnmount() {
		// logger.log('[Template] unmount: slots:%o', slots);
		logger.log('[Template] componentWillUnmount');

		DFPManager.getGoogletag().then((googletag) => {
			logger.log('[Template AdTag] componentWillUnmount googletag:%o',googletag);

			googletag.cmd.push(() => {
				logger.log('[Template AdTag] componentWillUnmount slots:%o',googletag.pubads().getSlots());
				googletag.pubads().removeEventListener('slotRenderEnded', event => this.handleEmptyAd(event));
			})
		})
		.catch((error) => {
			logger.log('[Template AdTag] componentWillUnmount googletag getSlots error:%o', error);

		})

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.Router.hash !== this.props.Router.hash) {
			this.hashScrolling = false;
			this.hashHandled = false;
			if (this.props.Router.hash.length > 0) {
				logger.log('[Template] componentDidUpdate :hash', this.props.Router.hash);

				let element = document.getElementById(this.props.Router.hash.split('#')[1]);

				if (element) {
					logger.log('[Template] componentDidUpdate setting element in state');
					this.hashHandled = true;

					this.setState({
						scrollToElement: element,
					});
				}
			}
		}

		if (prevState.scrollToElement !== this.state.scrollToElement && !this.hashScrolling) {
			logger.log('[Template] componentDidUpdate scrollIntoView');
			this.hashScrolling = true;

			window.setTimeout(() => {
				let element = document.getElementById(this.props.Router.hash.split('#')[1]);
				element.scrollIntoView();
			}, 2000);
		} else if (
			this.props.Router.hash &&
			this.props.Router.hash.length > 0 &&
			!this.state.scrollToElement &&
			!this.hashHandled
		) {
			logger.log('[Template] componentDidUpdate wait for element.');

			window.setTimeout(() => {
				let element = document.getElementById(this.props.Router.hash.split('#')[1]);

				if (element) {
					this.hashHandled = true;

					this.setState({
						scrollToElement: element,
					});
				}
			}, 100);
		}
	}

	render() {
		// logger.log('[Template] render this:%o', this);

		let {
			title,
			bodyClass,
			children,
			description,
			keywords,
			header = {},
			subHeader = {},
		} = this.props;

		// logger.log("[Template] attributes - %o", this.props.attributes);
		if (this.props.attributes) {
			//debugger;
			// logger.log(this.props.attributes.metaTitle);
			title = this.props.attributes.metaTitle !== '' ? this.props.attributes.metaTitle : title;
			description =
				this.props.attributes.metaDescription !== '' ? this.props.attributes.metaDescription : description;
		}
		if (subHeader) {
			subHeader = isEmpty(subHeader)
				? {
						breadcrumbs: [
							{
								link: '/index.html',
								title: 'home',
							},
						],
						title: '',
				  }
				: subHeader;
		}

		// logger.log("[Template] subHeader - %o", subHeader);
		// logger.log("[Template] globalLink - %o", this.props.globalLink);
		let redirectLink = op.get(this.props.globalLink, 'link');
		let redirectQuery = op.get(this.props.globalLink, 'query');

		//logger.log('[Template] render hideAd:%o, stubHideAd:%o, adConfig:%o', this.props.hideAd, this.props.stubHideAd, this?.props?.adConfig);

		if (redirectLink) {
			// logger.log("[Template] has globalLink - %o", this.props.globalLink);
			return <EventsRedirect to={redirectLink} query={redirectQuery} />;
		} else {
			return (
				<>
					<WindowSize />
					<Helmet>
						<title>{title}</title>
						<meta name="description" content={description} />
						<meta name="keywords" content={keywords} />
						<html lang="en" />
						<body className={bodyClass} />
					</Helmet>
					<Header attributes={header} />
					<MobileNav />
					<DFPSlotsProvider
						dfpNetworkId={"5681"}
						collapseEmptyDivs={true}
						singleRequest={true}
						lazyLoad={{
							fetchMarginPercent: 100,
							renderMarginPercent: 25,
							mobileScaling: 0
						}}

					>
					{subHeader && this.props.subHeader !== false ? <SubHeader attributes={subHeader} /> : null}
					{!this.props.hideAd && !this.props.stubHideAd && this?.props?.adConfig ? (
						<div className="topAd">
							{this.props.noRefresh ? (
								// <AdUnitLazy data={{ adType: this.props.adType ? this.props.adType : `topAd` }} />
								<AdTag
									key={'lazy-ad'}
									id={'lazy-ad'}
									adConfig={this?.props?.adConfig?.[`topAd`]}
									dfpNetworkId={this?.props?.adConfig?.dfpNetworkId}
								/>
							) : (
								// <AdUnitLazy
								// 	data={{
								// 		adType: this.props.adTypeRefresh ? this.props.adTypeRefresh : `topAdRefresh`,
								// 	}}
								// />

								<AdTag
									key={'lazy-ad'}
									id={'lazy-ad'}
									adConfig={this?.props?.adConfig?.[`topAdRefresh`]}
									dfpNetworkId={this?.props?.adConfig?.dfpNetworkId}
								/>
							)}
						</div>
					) : null}
			
					<main id="uso-main" role="main">
						{children}
					</main>

					<Footer />
					{/* <BackToTop /> */}
					<ModalOverlayContainer title={this.props.title} description={this.props.description} />
					</DFPSlotsProvider>
				</>
			);
		}
	}
}

Template.defaultProps = {
	header: {},
	mounted: false,
	navbarFixed: false,
	headerMarginBottom: 0,
	bodyClass: 'uso-site',
	title: 'Official Site of the 2024 US Open Tennis Championships - A USTA Event',
	description:
		'The official site of the 2024 US Open Tennis Championships.  Live tennis scores, watch every match live stream, listen to live radio, and follow the action behind every match.',
	keywords: 'US Open',
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
