/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { values } from 'appdir/main';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import Helmet from 'react-helmet';
import MainNav from 'appdir/components/general/MainNav';
import EventsLink from 'components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';
import op from 'object-path';
import LiveBar from 'appdir/components/general/LiveBar';

/**
 * -----------------------------------------------------------------------------
 * React Component: Header
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Header'],
		menu_open: state['Header'].menu_open,
		windowWidth: state['Controller'].windowWidth,
		stPanelOpen: op.get(state['Controller'], 'stpanel.open'),
		componentConfig: state['Config']['components'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Header.mount()),
	toggleMenu: isOpen => dispatch(deps.actions.Header.toggleMenu(isOpen)),
	toggleLiveMenu: isOpen => dispatch(deps.actions.Header.toggleLiveMenu(isOpen)),
	hoverMobile: (isHover, menuName) => dispatch(deps.actions.MainNav.hoverMobile(isHover, menuName)),
});

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displaySearch: false,
			searchQuery: '',
		};
		this.toggleOpen = false;
		this.contentMain = null; // main content area of page
		this.contentFiltersScroll = null; // indicates top position of filters
		this.showSearch = this.showSearch.bind(this);
		this.headerElem = null;
		// logger.log('[Header] constructor - this:%o', this);
	}

	showSearch(e, toggle) {
		if (e.key == ' ' || e.code == 'Space' || e.keyCode == 32 || e.type == 'click') {
			if (this.state.displaySearch !== toggle) {
				MeasurementUtils.dispatchMeasurementCall('showSearch', {
					display: toggle,
				});
				this.setState(prevState => {
					return {
						...prevState,
						displaySearch: toggle,
					};
				});
			}
		}
	}

	makeSticky() {
		if (document.querySelector('body') && !document.querySelector('body').classList.contains('modal-on-noscroll')) {
			if (window.pageYOffset > this.sticky) {
				this.headerElem.classList.add('sticky');
			} else {
				this.headerElem.classList.remove('sticky');
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[Header] getDerivedStateFromProps - name:%o next:%o', nextProps.name, nextProps);
		let type = prevState.headerType;
		let pageHeaders = {};

		if (nextProps['attributes'] && nextProps['attributes']['headerType']) {
			type = nextProps['attributes']['headerType'];
		}

		if (nextProps['pageHeaders']) {
			pageHeaders = nextProps['pageHeaders'][type];
		}

		if (!pageHeaders) {
			logger.log('[Header] no page headers in config, using props: %o', nextProps);
		}

		let newState = {
			...prevState,
			...pageHeaders,
			...nextProps['attributes'],
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[Header] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate(prevState) {
		// logger.log('[Header] componentDidUpdate - this:%o', this);
		if (this.state.displaySearch) {
			this.searchField.focus();
		}
	}

	componentDidMount() {
		this.props.mount();
		// Get the header
		this.headerElem = document.querySelector('#uso-header');

		// Get the offset position of the navbar
		this.sticky = this?.headerElem?.getBoundingClientRect()?.height;

		window.addEventListener('scroll', throttle(this.positionChange.bind(this), 100, { trailing: true }), false);
		// logger.log('[Header] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.positionChange.bind(this), false);
	}

	assignElements() {
		this.contentMain = document.querySelector('.content-main');
		this.contentFiltersScroll = document.querySelector('.content-filters-scroll');
	}

	positionChange(event) {
		let checkHeight = Math.round(this.sticky + 12);

		if (this.props.stPanelOpen === false) {
			if (this.contentFiltersScroll !== null) {
				// logger.log('[Header] positionChange - this: %o', this);
				let cfsTop = Math.round(this.contentFiltersScroll.getBoundingClientRect().top);

				// logger.log('[Header] scroll - contentFiltersScroll - top:%o checkHeight:%o ', cfsTop, checkHeight);
				if (cfsTop < checkHeight) {
					if (!this.contentMain.classList.contains('fixed')) {
						this.contentMain.classList.add('fixed');
					}
				} else if (cfsTop >= checkHeight) {
					if (this.contentMain.classList.contains('fixed')) {
						this.contentMain.classList.remove('fixed');
					}
				}
			} else {
				this.assignElements();
			}
		}

		this.makeSticky();
	}

	showNav(e) {
		// logger.log('[Header] showNav - this: %o, state:%o', this, this.state);
		//get current toggle state and set opposite to open/close menu
		// this.props.toggleLiveMenu(false);
		if (e.key == ' ' || e.code == 'Space' || e.keyCode == 32 || e.type == 'click') {
			this.toggleOpen = this.props.menu_open;
			MeasurementUtils.dispatchMeasurementCall('mobileShowNav', {
				display: !this.props.menu_open,
			});
			if (this.toggleOpen) {
				this.props.toggleMenu(this.toggleOpen ? false : true);
				this.toggleOpen = this.toggleOpen ? false : true;
				this.props.hoverMobile(false, '');
				//this.props.hoverMobile(false,'');
			} else {
				//first time clicking menu
				this.props.toggleMenu(true);
				this.toggleOpen = true;
			}

			if (this.toggleOpen) {
				this.props.toggleLiveMenu(false);
			}
		}
	}

	onUnHover(subMenuName, e) {
		// logger.log("[SubNav] onHover submenu:%o", subMenuName);
		// this.props.hoverMobile(false, subMenuName);
		//if (moreClass !== '') {
		//this.props.hoverMobile(false, subMenuName);
		//}
	}

	render() {
		// logger.log("[Header] render - this:%o", this);
		this.assignElements();

		let title = this.state.metaTitle ? this.state.metaTitle + ' - ' + values.pageTitle : values.pageTitle;
		let description = this.state.metaDescription ? this.state.metaDescription : '';
		let pageTitle = this.state.metaTitle ? this.state.metaTitle : values.metaTitle;
		let pageDate = this.state.metaDate ? this.state.metaDate : '';
		let pagePlayers = this.state.metaPlayers ? this.state.metaPlayers : '';
		let ibmLink = this.state.ibmLink ? this.state.ibmLink : '';

		let iconClose = this.props.menu_open ? '' : 'hide';
		let iconMenu = this.props.menu_open ? 'hide' : '';
		return (
			<header id="uso-header" className={`container-fluid`}>
				<Helmet>
					<title>{`${title}`}</title>
					<meta name="resulttitle" content={pageTitle} />
					<meta name="resultdate" content={pageDate} />
					<meta name="resultplayers" content={pagePlayers} />
					<meta name="description" content={description} />
					<meta name="language" content="EN" />
					<meta name="apple-itunes-app" content="app-id=327455869" />
					{this.props.attributes.canonicalLink ? (
						<link rel="canonical" href={this.props.attributes.canonicalLink} />
					) : null}
				</Helmet>

				<noindex>
					<div id="header-container" onMouseOver={this.props.menu_open ? e => this.onUnHover('', e) : null}>
						<div className="row">
							<div className="left-col align-self-center">
								<div className="menu-icon-livebar-wrapper">
									<div
										className="menu-icon-container"
										onClick={e => {
											this.showNav(e);
										}}
										onKeyDown={e => {
											this.showNav(e);
										}}
										tabIndex={0}>
										<div className="ar-icon">
											<div className={`icon ${iconMenu}`}>
												<i className="icon-menu" />
											</div>
											<div className={`icon ${iconClose}`}>
												<i className="icon-close" />
											</div>
										</div>
									</div>
									{this.props.windowWidth < 1140 && <LiveBar />}
								</div>

								<EventsLink to="/index.html">
									<img
										className="img-fluid d-none d-llg-block mx-auto uso-logo"
										src="/assets/images/header/usopen-header-logo.png"
										alt="US Open"
									/>
								</EventsLink>
								{/* <span className="date d-none d-llg-block d-xl-none text-center">
									August 19 &ndash; September 8, 2024
								</span> */}
							</div>
							<div className="middle-col align-self-center">
								<EventsLink to="/index.html">
									<img
										className="img-fluid d-block d-llg-none uso-logo"
										src="/assets/images/header/usopen-header-logo.png"
										alt="US Open"
									/>
								</EventsLink>
								{this.props.windowWidth >= 1140 && <LiveBar />}
								<div
									onClick={e => {
										this.showSearch(e, true);
									}}
									onKeyDown={e => {
										this.showSearch(e, true);
									}}
									tabIndex={0}
									className={`search-box ${this.state.displaySearch ? 'open' : ''}`}>
									<form
										id="mainSearch"
										action={`/en_US/search/results.html`}
										method="get"
										name="value">
										{/* <a
                        href="/en_US/search/index.html"
                        className="clear-decorate"
                      > */}
										<img
											className="img-fluid d-none d-md-inline-block search-icon"
											src="/assets/images/header/search-icon.png"
											srcSet="/assets/images/header/search-icon.svg"
											alt="Search"
										/>
										{/* </a> */}
										<input
											type="text"
											id="search-input"
											name="query"
											placeholder="search"
											ref={input => {
												this.searchField = input;
											}}
										/>
										<a
											onClick={e => {
												this.showSearch(e, false);
											}}
											onKeyDown={e => {
												this.showSearch(e, false);
											}}
											tabIndex={0}
											className="close-search">
											<img
												className="img-fluid d-none d-md-inline-block"
												src="/assets/images/header/close-x.png"
												srcSet="/assets/images/header/close-x.svg"
												alt="SearchClose"
											/>
										</a>
										<input type="submit" id="searchSubmit" />
									</form>
								</div>
								<MainNav data={this.state} />
							</div>
							<div className="right-col align-self-center text-right">
								<a
									href={get(
										this.props,
										'componentConfig.masthead.ibmRibbon.link',
										'https://www.ibm.com/sports/usopen'
									)}
									target="_blank">
									<img
										className="img-fluid d-lg-none ibm-logo"
										src="/assets/images/header/ibm-header-logo.png"
										srcSet="/assets/images/header/ibm-header-logo.svg"
										alt="IBM"
									/>
								</a>
								<a
									href={get(
										this.props,
										'componentConfig.masthead.ibmRibbon.link',
										'https://www.ibm.com/sports/usopen'
									)}
									target="_blank">
									<img
										className="img-fluid d-none d-lg-inline-block ibm-ribbon"
										src={get(
											this.props,
											'componentConfig.masthead.ibmRibbon.imgSrc',
											'/assets/images/header/ribbon.gif'
										)}
										alt={get(this.props, 'componentConfig.masthead.ibmRibbon.title', 'IBM')}
									/>
								</a>
							</div>
						</div>
					</div>
				</noindex>
			</header>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
