import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			/** replace with open question data **/
			// let mapMarkersPath = Config.otherData.mapMarkers;
			// deps.services.GroundsMap.fetch([mapMarkersPath]).then(data => {
			//   //logger.log('[GroundsMap] mount - data:%o', data);
			//   dispatch({
			//     type: deps.actionTypes.OPEN_QUESTIONS_MOUNT,
			//     updated: moment().toISOString(),
			//     status: "loaded",
			//     data: { mapMarkers: data }
			//   });
			// });
		});
	},
};
