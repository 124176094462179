import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let playerConfig = Config.playerConfig;
			dispatch({
				type: deps.actionTypes.LIVEVIDEOPLAYER_MOUNT,
				data: { playerConfig },
			});
		});
		// dispatch({ type: deps.actionTypes.ADTAG_MOUNT, data: { status: "load" } });
	},
	unmount: () => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.LIVEVIDEOPLAYER_UNMOUNT,
			data: {},
		});
	},
	currentlyPlaying: (id, player) => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.LIVEVIDEOPLAYER_CURRENTLY_PLAYING,
			data: { id, player },
		});
	},
};
