/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

import ScheduleContent from './ScheduleContent';
import ScheduleBuyTickets from './ScheduleBuyTickets';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import StubBox from 'appdir/components/common-ui/StubBox';
import AdUnitLazy from 'appdir/components/general/AdUnitLazy';
import MeasurementUtils from 'appdir/lib/analytics';
import Template from '../../Template';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import axios from 'axios';
import { fetchPromiseAll, scrollIntoViewWithOffset } from 'appdir/components/general/Util';

import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import RolexWatch from 'components/general/RolexWatch';
import EventsRedirect from 'appdir/components/general/EventsRedirect';

const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: SchedulePage
 * this is the order of play page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SchedulePage'],
		currentDay: state['ActiveData']['currentDay'],
		stubs: state['Config'].stubPages,
		favorites: state['Controller'].favorites,
		adConfig: state['Config']['adConfig'],
		EventsWindow: state['WindowSize'].EventsWindow,
		scoring: state['Controller'].scoring,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SchedulePage.mount()),
	setDefaultDay: settings => dispatch(deps.actions.SchedulePage.setDay(settings)),
	setScoringStatus: data => dispatch(deps.actions.Controller.setScoringStatus(data)),
});

class SchedulePage extends Component {
	constructor(props) {
		super(props);

		//set default filter based on querystring
		let filters = props.filters;
		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);

		if (parsedQs.filter) {
			index = findIndex(menu, function(o) {
				return o.query == parsedQs.filter.toLowerCase();
			});
		}

		// now add all the props and filters to state
		this.state = {
			showSelected: 0,
		};
		this.defaultSet = false;
		this.hasError = false;
		this.loadedJson = ''; // local class var to keep track of last loaded json (used to detect if redirect is needed)

		// logger.log('[SchedulePage] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentDidMount() {
		//logger.log('[SchedulePage] componentDidMount - state:%o', this.state);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'ScheduleOfPlay',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[SchedulePage] componentDidUpdate - this:%o', this);

		// if (this.loadedJson !== "" && !this.state.schedule_matches) {
		//   this.loadedJson = "";
		//   logger.log(
		//     "[SchedulePage] componentDidUpdate - blank out loadedJson:%o",
		//     this.loadedJson
		//   );
		// }

		/**
		 * status is change when we want to go to a new page
		 * status is load when we want to load data
		 */

		let url = '';
		let schedule_matches = this.state.schedule_matches;
		let tourn_days = this.state.tourn_days;
		/** we only want to do these actions if we need to change the page */
		if (this.props.filters.day != prevProps.filters.day && this.props.status != 'load') {
			if (prevProps.filters.day) {
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					day: this.props.filters.day,
				});
			}
			if (this.props.filters.day && this.props.filters.day != '' && this.props.filters.day !== 'undefined') {
				url = `schedule${this.props.filters.day}.html`;
				this.loadedJson = '';
			}
			//this.loadedJson = url == this.props.location.pathname ? this.props.location.pathname : '';
			this.hasError = false;
			schedule_matches = null;

			this.setState({
				nav: url,
				schedule_matches: schedule_matches,
				tourn_days: tourn_days,
			});
		}

		if (!this.props?.match?.params?.tournDay && this.props?.currentDay?.schedule && !this.defaultSet) {
			this.defaultSet = true;
			this.props.setDefaultDay({
				day: this.props.currentDay.schedule,
			});
		} else if (
			/** we only want to do these actions if we need to load page data */
			this.props.scoring &&
			this.props?.stubs?.schedule?.stub !== 'stub' &&
			this.props.path &&
			this.props?.filters?.day !== '' &&
			this.props?.currentDay?.schedule &&
			this.props.status != 'change' &&
			(this.loadedJson == '' ||
				this.props.filters.day != prevProps.filters.day ||
				this.props.filters.day == 'Current')
		) {
			// logger.log('[SchedulePage] componentDidUpdate dispatch setScoringStatus');
			if (!this.props.scoring.started) {
				this.props.setScoringStatus({ schedule: true });
			}

			let dataPath = this.props.path.replace(
				'<day>',
				this.props.filters.day == 'Current' ? this.props.currentDay.schedule : this.props.filters.day
			);
			let completedPath = this.props.completedMatches.replace(
				'<day>',
				this.props.filters.day == 'Current' ? this.props.currentDay.schedule : this.props.filters.day
			);

			// if the data path is not what was last loaded,
			//   and not an update caused by filter status change, load now
			if (dataPath != this.loadedJson && this.props.status != 'change' && this.props.filters.day != '') {
				this.loadedJson = dataPath;

				let schedulePath = dataPath;
				let daysPath = this.props.daysPath;
				let highlightsPath = this.props.highlightsPath;
				let liveScoresPath = this.props.liveScores;
				// let futureSchedulePath = this.props.futureSchedule;
				let tournamentSchedulePath = this.props.tournamentSchedule;
				let pathObj = [schedulePath, highlightsPath, completedPath, liveScoresPath];
				let futurePathObj = [daysPath, tournamentSchedulePath];

				if (this.defaultSet === true) {
					this.defaultSet = false;
				}

				// call the service fetchAll and pass in the object containing multiple request urls
				if (this.loadedJson != '' && !this.hasError) {
					fetchPromiseAll(futurePathObj)
						.then(
							axios.spread((daysResults, tournamentResults) => {
								this.hasError = false;
								this.setState(
									{
										tourn_days: daysResults,
										// future_schedule: futureResults,
										tournament_schedule: tournamentResults,
									},
									() => {
										let selectedDay = find(this.state?.tourn_days?.eventDays, d => {
											return d.tournDay == parseInt(this.props.filters.day);
										});
										if (!selectedDay?.released) {
											this.setState({ playersPlaying: [] });
										}
										if (selectedDay?.released) {
											fetchPromiseAll(pathObj)
												// take the returned results and save them to state.
												.then(
													axios.spread(
														(
															scheduleResults,
															highlightResults,
															completedResults,
															liveResults
														) => {
															this.hasError = false;
															// logger.log(
															// 	'[SchedulePage] componentDidUpdate - dataPath:%o, scheduleResults:%o, daysResults:%o, futureResults:%o',
															// 	dataPath,
															// 	scheduleResults,
															// 	daysResults,
															// 	futureResults
															// );

															//get a list of players playing that day
															let playerArry = [];
															scheduleResults.courts.forEach(d => {
																d.matches.forEach(d1 => {
																	d1.team1.forEach(d2 => {
																		let player1a = null,
																			player1b = null;
																		player1a = {
																			firstName: d2.firstNameA,
																			lastName: d2.lastNameA,
																			id: d2.idA,
																		};
																		player1b = {
																			firstName: d2.firstNameB,
																			lastName: d2.lastNameB,
																			id: d2.idB,
																		};
																		playerArry.push(player1a, player1b);
																	});

																	d1.team2.forEach(d2 => {
																		let player1a = null,
																			player1b = null;
																		player1a = {
																			firstName: d2.firstNameA,
																			lastName: d2.lastNameA,
																			id: d2.idA,
																		};
																		player1b = {
																			firstName: d2.firstNameB,
																			lastName: d2.lastNameB,
																			id: d2.idB,
																		};
																		playerArry.push(player1a, player1b);
																	});
																});
															});

															let playersPlaying = playerArry.filter(d => d.id !== null);

															this.setState({
																schedule_matches:
																	scheduleResults?.status === 'error'
																		? (this.hasError = true)
																		: scheduleResults,
																match_highlights: highlightResults,
																playersPlaying,
																completed_matches: completedResults,
																live_matches: liveResults,
															});
														}
													)
												)
												.catch(error => {
													logger.log('[SchedulePage] componentDidUpdate error:%o', error);
													this.loadedJson = '';
													this.hasError = true;
												});
										}
									}
								);
							})
						)
						.catch(error => {
							logger.log('[SchedulePage] componentDidUpdate error:%o', error);
							this.loadedJson = '';
							this.hasError = true;
						});
				}
			}
		}
	}

	getMode(day) {
		let mode = '';
		this.state.tourn_days.eventDays.forEach(item => {
			if (item.tournDay == day) {
				mode = item.quals ? 'quali' : 'tourn';
			}
		});
		return mode;
	}

	onClick = value => {
		// logger.log('[SchedulePage] onEnter - value:%o', value);
		let searchedPlayer = value;
		this.setState({ searchedPlayer }, () => {
			//scroll to player

			let el = document.querySelector(`.${searchedPlayer?.id}`);

			const stickyMenu = document.querySelector('#uso-header.sticky'); //56px tall
			const stickyFavorites = document.querySelector('.favorites-details.sticky'); //112px tall
			const isMobile = this.props?.EventsWindow?.windowSize == 'mobile';

			// logger.log('[SchedulePage] isMobile:%o, stickyMenu:%o, stickyFavorites:%o', isMobile, stickyMenu, stickyFavorites);

			let offset = stickyFavorites ? 175 : stickyMenu ? 290 : 340;

			if (isMobile) {
				offset = stickyFavorites ? 175 : stickyMenu ? 290 : 350;
			}

			if (el) {
				scrollIntoViewWithOffset(`.${searchedPlayer?.id}`, offset);
			}
		});
	};

	displayDate(selectedDay) {
		let index = this.props.tournDay;
		if (index === 'currentDay') {
			index = selectedDay.tournDay;
		}
		let date =
			selectedDay.tournDay > 4
				? new Date(
						this.state.tournament_schedule.tournament_schedule.draws.maindraw.dates[
							parseInt(index) - 1
						].date
				  )
				: new Date(
						this.state.tournament_schedule.tournament_schedule.draws.qualifying.dates[
							parseInt(index) - 1
						].date
				  );
		let options = { weekday: 'long', month: 'long', day: 'numeric' };
		let formattedDate = 'Day ' + selectedDay.displayDay + ': ' + date.toLocaleString('en-us', options);

		return formattedDate;
	}

	render() {
		// logger.log('[SchedulePage] render - this:%o', this);
		let header_propsData = {};

		// if you are creating a header like scores which is transparent, make sure you update the
		// header component and make a new style for the new header.
		header_propsData = {
			headerType: 'schedule',
			title: 'Schedule of Play',
			metaTitle: 'Schedule of Play',
			metaDescription:
				'Find out when matches are happening with the US Open official schedule. See which players are facing off and when and catch all the excitement of the US Open.',
			metaDate: '',
			metaPlayers: '',
			canonicalLink: 'https://www.usopen.org/en_US/scores/schedule/index.html'
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'http://www.rolex.com',
				title: 'Rolex',
				name: 'Rolex',
				imgClass: '',
				imgSrc: '/assets/images/logos/rolex_logo_sm.png',
				tagline: 'In partnership with',
			},
			title: 'Schedule of Play',
			page_header: 'Schedule of Play',
		};

		let keywords = 'us open tennis, us open schedule';

		// iframe style for rolex clock
		let iframeStyle = {};
		iframeStyle['width'] = '200px';
		iframeStyle['height'] = '100%';
		iframeStyle['border'] = '0';
		iframeStyle['margin'] = '0';
		iframeStyle['padding'] = '0';
		iframeStyle['overflow'] = 'hidden';
		iframeStyle['scroll'] = 'none';

		//get selected day; find if day has been released yet; show schedule or buy tickets
		let selectedDay = find(this.state?.tourn_days?.eventDays, d => {
			return d.tournDay == parseInt(this.props.filters.day);
		});

		// get selected future info to first day
		let futureDayInfo;
		futureDayInfo = find(this.state?.tourn_days?.eventDays, f => {
			return !f.practice && f.released === false;
		});

		// logger.log(
		// 	'[SchedulePage] render - tournDay:%o, tourn_days:%o, selectedDay:%o, futureDayInfo:%o',
		// 	this.props.filters.day,
		// 	this.state?.tourn_days,
		// 	selectedDay,
		// 	futureDayInfo
		// );

		if (this.state.nav && !this.props.location.pathname.includes(this.state.nav) && this.props.status != 'load') {
			logger.log('[SchedulePage] render - about to redirect');
			return <EventsRedirect push to={this.state.nav} />;
		} else if (this.props.stubs && this.props.stubs.schedule.stub === 'stub') {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<section id="schedule" className="wrapper scorespage">
						<h1 className="header" style={{ marginBottom: '0px' }}>
							Schedule of Play
						</h1>
						<StubBox
							attributes={{
								title: header_propsData.title,
								message: this.props.stubs.schedule.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (!selectedDay?.released) {
			if (futureDayInfo) {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes} keywords={keywords}  hideAd={true}>
						<section id="schedule" className="wrapper scorespage">
							<div id="schedule-ad-1" className="schedule-ad-container">
								<AdUnitLazy data={{ adType: `schedule_1` }} />
							</div>
							<PageSubMenu
								mode="Schedule"
								selected="Schedule of Play"
								filters={this.props.filters}
								showFavorites={true}
								playersData={this.state?.playersPlaying}
								searchedPlayer={this.state?.searchedPlayer?.id}
								onClick={this.onClick}
								showAllDays={this.props.filters?.showAllDays}
								enableFuture={true}
								schedulePdfPath={`${this.props.pdfPath}${
									this.props.match.params.tournDay
										? this.props.match.params.tournDay
										: this.props.currentDay.schedule
								}.pdf`}
								showPdfIcon={true}
							/>
							<ErrorBoundary message="The schedule of play is currently unavailable at this time.">
								<div className="column-layout">
									<div className="schedule-info">
										<div className="day">
											{/* {this.state.tournament_schedule} */}
											{/* {this.displayDate(selectedDay)} */}
											{selectedDay?.message}
										</div>
										<div className="revised">
											{selectedDay?.released ? '' : 'SCHEDULE NOT RELEASED YET FOR THIS DAY'}
										</div>
									</div>
									<div className="sponsor">
										<RolexWatch />
									</div>
								</div>
								{/* //show buy tickets for future days */}
								<ScheduleBuyTickets
									attributes={{
										data: futureDayInfo,
										tournData: this.state.tournament_schedule,
										selectedDayData: selectedDay,
										scheduleMatches: this.state.schedule_matches,
									}}
								/>
							</ErrorBoundary>
						</section>
					</Template>
				);
			} else {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes} keywords={keywords} hideAd={true}>
						<section id="schedule" className="wrapper scorespage">
							<h1 className="header" style={{ marginBottom: '0px' }}>
								Schedule of Play
							</h1>
							<LoadingIndicator />
						</section>
					</Template>
				);
			}
		} else if (this.state.schedule_matches && this.state.tourn_days) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} keywords={keywords} hideAd={true}>
					<section id="schedule" className="wrapper scorespage">
						<div id="schedule-ad-1" className="schedule-ad-container">
							<AdUnitLazy data={{ adType: `schedule_1` }} />
							{/* <AdTag
								adConfig={this.props.adConfig.schedule_1}
								dfpNetworkId={this.props.adConfig.dfpNetworkId}
							/> */}
						</div>
						<PageSubMenu
							mode="Schedule"
							selected="Schedule of Play"
							filters={this.props.filters}
							showFavorites={true}
							playersData={this.state.playersPlaying}
							searchedPlayer={this.state.searchedPlayer?.id}
							onClick={this.onClick}
							showAllDays={this.props.filters?.showAllDays}
							enableFuture={true}
							schedulePdfPath={`${this.props.pdfPath}${
								this.props.match.params.tournDay
									? this.props.match.params.tournDay
									: this.props.currentDay.schedule
							}.pdf`}
							showPdfIcon={true}
						/>
						<ErrorBoundary message="The schedule of play is currently unavailable at this time.">
							<div className="column-layout">
								<div className="schedule-info">
									<div className="day">
										{selectedDay?.message}
										{/* {this.displayDate(selectedDay)} */}
										{/* {futureDayInfo?.displayDate
											? futureDayInfo.displayDate
											: this.state.schedule_matches.displayDate} */}
									</div>
									<div className="revised">
										{selectedDay?.released
											? this.state.schedule_matches.comments
											: 'SCHEDULE NOT RELEASED YET FOR THIS DAY'}
									</div>
								</div>
								<div className="sponsor">
									<RolexWatch />
								</div>
							</div>
							{!selectedDay?.released ? (
								//show buy tickets for future days
								<ScheduleBuyTickets
									attributes={{
										data: futureDayInfo,
										tournData: this.state.tournament_schedule,
										selectedDayData: selectedDay,
										scheduleMatches: this.state.schedule_matches,
									}}
								/>
							) : (
								<ScheduleContent
									attributes={{
										data: this.state.schedule_matches,
										chunkVal: 3,
										mode: this.getMode(
											this.props.match.params.tournDay
												? this.props.match.params.tournDay
												: this.props.currentDay.schedule
										),
										highlights: this.props.match_highlights,
										searchedPlayer: this.state?.searchedPlayer,
										completedMatches: this.state.completed_matches,
										liveMatches: this.state.live_matches,
									}}
								/>
							)}
						</ErrorBoundary>
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} keywords={keywords} hideAd={true}>
					<ErrorBoundary message="The schedule of play is currently unavailable at this time.">
						<section id="schedule" className="wrapper scorespage">
							<h1 className="header" style={{ marginBottom: '0px' }}>
								Schedule of Play
							</h1>
							<LoadingIndicator />
						</section>
					</ErrorBoundary>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage);
