import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filter;
	switch (action.type) {
		case deps.actionTypes.PHOTOSINDEX_LOAD:
			filter = {
				...state.filter,
				year: action.data.year,
			};

			newState = {
				...state,
				...action.data,
				status: 'load',
				filter,
			};
			// logger.log("[Photos] PHOTOSINDEX_LOAD - newState:%o", newState);
			return newState;

		case deps.actionTypes.PHOTOSINDEX_FILTER:
			// logger.log("[Photos] PHOTOSINDEX_FILTER - data:%o", action.data);

			filter = {
				...state.filter,
				...action.data,
			};

			newState = {
				...state,
				path: action.data.path,
				relatedContentPath: action.data.relatedContentPath,
				archive: action.data.archive,
				status: 'load',
				filter: filter,
			};
			// logger.log("[Photos] PHOTOSINDEX_FILTER - newState:%o", newState);
			return newState;

		default:
			return state;
	}
};
