import React from 'react';
import ReactHtmlParser from 'html-react-parser';

import { getServeSpeed } from '../MatchBoxUtils';
import {
	matchIsCancelled,
	matchIsInProgress,
	matchIsLiveState,
	matchIsLiveNotInProgress,
} from 'appdir/components/pages/SchedulePage/ScheduleUtils';

/**
 * -----------------------------------------------------------------------------
 * Class Component: EventInfoStatus
 * @params
 *  - attributes
 *  - uom
 *  - sumscores
 * -----------------------------------------------------------------------------
 */

const EventInfoStatus = props => {
	const { data } = props;
	const { attributes } = props.data;
	const scoreData = attributes.data;
	const boxStyle = attributes.style ? attributes.style : '';
	const notBefore = scoreData.notBefore ? scoreData.notBefore : null;
	const comment = scoreData.comment ? scoreData.comment : null;
	let serveSpeed = getServeSpeed(scoreData.last_serve_speed, data.uom);
	const { isFinal } = attributes ? attributes : false;
	let status = '';

	let eventStatus = (isFinal, attributes, status, inProgress) => {
		if (isFinal) {
			return 'Final';
		} else if (attributes?.matchType == 'upcoming') {
			return 'Upcoming';
		} else if (inProgress) {
			return `<i class="icon-pagenation-dot-selected"></i> ${status}`;
		} else {
			return status;
		}
	};

	if (attributes.mode == 'schedule' || attributes.mode == 'draws') {
		switch (scoreData.statusCode) {
			case 'B':
				if (notBefore !== null) {
					status = 'Not before ' + notBefore;
				} else {
					status = 'Upcoming';
				}
				break;

			/** Completed, Suspended, Postponed, Cancelled */
			case 'D':
			case 'K':
			case 'P':
			case 'C':
				status = scoreData.status;
				break;

			case 'E':
				status = 'Retired';
				break;

			case 'F':
				status = 'Walkover';
				break;

			case 'G':
				status = 'Default';
				break;

			case 'X':
				status = '<i class="icon-pagenation-dot-selected"></i> Arrive on Court';
				break;

			case 'Y':
				status = '<i class="icon-pagenation-dot-selected"></i> Warming Up';
				break;

			default:
				if (scoreData.statusCode !== null) {
					status = `<i class="icon-pagenation-dot-selected"></i> ${scoreData.status}`;
				} else {
					if (comment) {
						status = comment;
					} else {
						status = '';
					}
				}
				break;
		}
	} else if (boxStyle == 'prematch') {
		status = 'Upcoming';
	} else {
		if (data.sumscores === true) {
			status = eventStatus(isFinal, attributes?.matchType, scoreData.status, matchIsInProgress(scoreData));
		} else {
			if (matchIsLiveNotInProgress(scoreData)) {
				status = scoreData.status;
			} else if (matchIsInProgress(scoreData)) {
				if (attributes.mode == 'draws') {
					if (scoreData.statusCode == 'A') {
						status = `<i class="icon-pagenation-dot-selected"></i> ${scoreData.status}`;
					} else {
						status = scoreData.status;
					}
				} else if (serveSpeed) {
					status = serveSpeed;
				}
			} else if (!matchIsLiveState(scoreData)) {
				if (scoreData.statusCode == 'B') {
					status = 'Upcoming';
				} else {
					status = scoreData.status;
				}
			} else if (matchIsCancelled(scoreData)) {
				status = scoreData.status;
			} else {
				status = '';
			}
		}
	}

	return status ? (
		<span className="results-info-status">{ReactHtmlParser(status)}</span>
	) : null;
};

export default React.memo(EventInfoStatus);
