/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState}  from 'react';
import { useSelector } from 'react-redux';
import { values } from 'appdir/main';

import DividerHeader from 'appdir/components/common-ui/DividerHeader';
import PastMatchUpsItem from 'appdir/components/pages/Slamtracker/elements/preview/PastMatchUpsItem';
import Button from 'appdir/components/common-ui/Button';

import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';


/**
 * -----------------------------------------------------------------------------
 * Functional Component: PastMatchUps
 * -----------------------------------------------------------------------------
 */
const PastMatchUps = props => {
    // logger.log('[PastMatchUps] props:%o', props);

    const headToHead = useSelector((state) => state?.['Slamtracker']?.headtohead ?? {});
    const statMatch = useSelector((state) => state?.['ScoreManager']?.statMatch ?? {});
    const [showMore, setShowMore] = useState(false);
    const maxDisplay = showMore ? props.max : props.min;

    logger.log('[PastMatchUps] statMatch:%o', statMatch);

    const setShowMoreAction = () => {
        // if (!window.webview) {
        //     measureInWeb({
        //         action: 'Preview',
        //         context: [{ pageTitle: 'Slamtracker' }, { tab: "Past Matchups" }, {action: "Show More"}],
        //     });
        // } else {
        //     measureInAppContext({
        //         pageTitle: 'Slamtracker',
        //         action: 'Preview',
        //         args: ['Past Matchups', 'Show More'],
        //         context: []
        //     });
        // }

        setShowMore(true)
    }

    return headToHead?.results?.length > 0 ? (
        <div className="pastmatchups">
            <DividerHeader text='Past Match Ups' />
            
            <div className="pastmatchups-content">
                {
                    headToHead.results.map((match, i) => {
                        if(i < maxDisplay) { 
                            return (
                            <PastMatchUpsItem match={match} 
                                player1={statMatch?.team1?.displayNameA} 
                                player2={statMatch?.team2?.displayNameA} 
                                country1={headToHead?.player1Nation}
                                country2={headToHead?.player2Nation}
                                key={`match-${i}`} />)
                        }
                    })
                }
                {headToHead.results.length > 2 && maxDisplay == 2 ?
                    <div className="pastmatchups-showmore">
                        <Button onClick={() => { setShowMoreAction() }}>
                            Load More
                        </Button>
                    </div>
                    : null
                }
            </div>
        </div>
    ) : null
};

export default PastMatchUps;