/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import isEmpty from 'lodash/isEmpty';
import ReactHtmlParser from 'html-react-parser';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ClickOverlayContent
 * -----------------------------------------------------------------------------
 */
export default class ClickOverlayContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			isHovering: false,
		};

		this.overlayRef = React.createRef();
	}

	setOverlay = args => {
		// logger.log('[ClickOverlayContent] setOverlay - args:%o', args);

		if (typeof webview === 'undefined') {
			if (args?.actionType) {
				MeasurementUtils.dispatchMeasurementCall(args.actionType, { args: args });
			} else {
				MeasurementUtils.dispatchMeasurementCall('clickOverlayContent', { args: args });
			}
		} else {
			/** [TODO] Add apps measure  */
			// measureArray = ['Metrics', pageTitle].concat(measureAction).concat(measure_args);
			// measureInApp(measureArray)
		}

		// if (typeof webview === 'undefined') {
		// 	MeasurementUtils.dispatchMeasurementCall('DiningFilter', {
		// 		location: event,
		// 	});
		// } else {
		// 	measureInApp(['Metrics', 'DiningFilter', 'location', event]);
		// }

		this.setState(
			{
				show: !this.state.show,
			},
			() => {
				if (this.state.show) {
					/** if the View More text is clicked to show overlay,
					 *  scroll the header element to the view so user doesn't have to scroll
					 */
					this.overlayRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			}
		);
	};

	handleMouseIn = () => {
		this.setState({ isHovering: true });
	};

	handleMouseOut = () => {
		this.setState({ isHovering: false });
	};

	render() {
		// logger.log("[ClickOverlayContent] - this:%o", this);
		let attributes = this.props.data;
		// logger.log('[ClickOverlayContent] - attributes:%o', attributes);

		//look for error
		if (!attributes) {
			return null;
		}

		let imagesData = attributes?.images[0];
		let measureData = {};

		if (this.props?.measureData) {
			measureData = { ...this.props.measureData };
		}

		return (
			<div
				className={`click-overlay-wrapper ${this.state.isHovering ? 'hovering' : ''}`}
				ref={this.overlayRef}
				onMouseMove={this.handleMouseIn}
				onMouseOut={this.handleMouseOut}>
				<div className="image-container">
					{!isEmpty(imagesData) && imagesData?.url ? (
						<img src={imagesData?.url} alt={imagesData?.caption} />
					) : (
						<img src="/assets/images/misc/noimage.png" />
					)}
				</div>
				<div className="click-overlay-content">
					{attributes?.title ? <h4>{attributes['title']}</h4> : <h4></h4>}

					<ul>
						{attributes?.location &&
							attributes.location.map(i => {
								return <li>{i}</li>;
							})}

						{/** if additional info exists, display */
						attributes?.additional_info?.length > 0
							? attributes.additional_info.map((info, index) => {
									return (
										<li className="red" key={info + index}>
											{info}
										</li>
									);
							  })
							: null}
					</ul>
					{/** if link and button text exist, add a link here */
					attributes?.link &&
					attributes?.link !== '' &&
					attributes?.button_text &&
					attributes?.button_text !== '' ? (
						<div className="reserve">
							<EventsLink to={attributes['link']}>{attributes['button_text']}</EventsLink>
						</div>
					) : null}
				</div>

				{/** if there is description, display on overlay */
				attributes?.description && attributes?.description !== '' ? (
					<>
						<div className="click-overlay-cta">
							<span onClick={() => this.setOverlay(measureData)}>View More</span>
						</div>
						<div className={`click-overlay-overlay ${this.state.show ? 'show' : ''}`}>
							<i className="icon-close" onClick={() => this.setOverlay(measureData)} />
							<div className="click-overlay-content">
								<h4>{attributes['title']}</h4>
								{ReactHtmlParser(attributes['description'])}
							</div>
						</div>
					</>
				) : null}
			</div>
		);
	}
}
