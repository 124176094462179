/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import Helmet from 'react-helmet';
import { fetch } from 'appdir/components/general/Util';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

/** MI Specific components */
import MIHeaderContents from './elements/MIHeaderContents';
import PowerRanking from './elements/PowerRanking';
// import ByTheNumbers from './elements/ByTheNumbers';
import InTheMedia from './elements/InTheMedia';
// import PowerIndexNumbers from './elements/PowerIndexNumbers';
import WinFactors from './elements/WinFactors';
import HaveYourSay from 'appdir/components/common-ui/HaveYourSay';
import MIModal from './elements/MIModal';
import { MatchInsightsInfoText } from './MIInfoContent';
import { getLikelihoodWinner } from './MatchInsightsUtils';
import BackToAllMI from './elements/BackToAllMI';

import ErrorBoundary from 'components/general/ErrorBoundary';
import StubBox from 'appdir/components/common-ui/StubBox';
import { getQuerystringValues } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';
import { doMeasurement } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsight
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		innovationTest: state['Config'].innovationTest,
		sharedDataConfig: state['Config'].sharedDataConfig,
		matchInsightsMatches: state['CommonData'].matchInsightsMatches,
		configScoringData: state['Config'].scoringData,
		configOtherData: state['Config'].otherData,
		EventsWindow: state['WindowSize'].EventsWindow,
		stubs: state['Config'].stubPages,
		scoring: state['Controller'].scoring,
		tournamentYear: state['Config']?.scoring?.tournamentYear,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchInsights.mount()),
	unmount: () => dispatch(deps.actions.MatchInsights.unmount()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});

class MatchInsightsContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stub: false,
			expired: false,
			matchInsightsData: null,
			atAGlance: null,
			inTheMedia: null,
			sharePopup: 'hide',
			openModal: false,
			modalContent: null,
			selectedTab: 'winfactors',
		};

		this.matchId = null;
		this.sharedDataLoaded = false;
		this.checkMatchExists = false;

		this.statsDataTimer = null;
		this.statsDataLoaded = false;
		this.parsedQs = null;
		// logger.log('[MatchInsightsContent] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentDidMount() {
		/** ?override=true ignores the match status and always set vote to open
		 *  ?test=true to use test data
		 */
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		this.isInnovationTest = this.parsedQs?.test;

		let metricsInitialTabVal = 'Win Factors';

		/**
		 * due to bypassAppLink, ios is no longer firing the pageView metrics call
		 * that's navigated to through bypassAppLink={true} b/w Insights Index and Insights Details
		 * Detect the previous page path and if it's from MI Index page,
		 * send using web link AKA measureInApp (http://usopen/metrics)
		 * DON'T use messageHandlers for items that has contextData - iOS set up to get additinal query string requires web links
		 */
		const insightsRegex = /\/en_US\/matchinsights\/(index)\.html/;
		let insightsMatch = document.referrer.match(insightsRegex);

		/** if it's on live blog, ignorePageView */
		if (!this.props.ignorePageView) {
			if (window.webviewPlatform == 'ios' && insightsMatch) {
				let contextData = [{ mi_tab: metricsInitialTabVal }, { mi_matchid: this.props.matchId }];
				let metricsVal = ['Metrics', 'Match Insights', 'Match Details'];
				measureInApp(metricsVal, contextData);
			} else {
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Match Insights:Match Details',
					match: this.props.matchId,
					mi_tab: metricsInitialTabVal,
				});
			}
		}
	}

	componentWillUnmount() {
		this.setState({
			matchInsightsData: null,
			atAGlance: null,
		});

		if (this.statsDataTimer) {
			clearInterval(this.statsDataTimer);
		}

		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		this.matchId = this.props.matchId;
		// logger.log('[MatchInsightsContent] componentDidUpdate - prevProps:%o, props:%o, state:%o', prevProps, this.props, this.state);

		/** if match ids are different, reset the flag */
		if (prevProps.matchId !== this.matchId) {
			this.setState({
				matchExists: false,
			});

			this.sharedDataLoaded = false;
			this.checkMatchExists = false;
		}

		/** Pull shared matches json file to get all the available matches ids */

		if (prevProps.status !== this.props.status && this.props.status == 'loaded' && !this.sharedDataLoaded) {
			// logger.log('[MatchInsightsContent] componentDidUpdate  - getting match insights matches');

			this.sharedDataLoaded = true;
			// load all the available MI matches list to check if the selected MatchId exists
			this.props
				.checkExpired(this.props.sharedDataConfig['matchInsightsMatches'])
				.then(response => {
					logger.log(
						'[MatchInsightsContent] componentDidUpdate matchInsightsMatches - response:%o',
						response
					);
					if (response.status == 'expired') {
						this.props.update(this.props.sharedDataConfig['matchInsightsMatches']);
					}
				})
				.catch(error => {
					logger.error('[MatchInsightsContent] componentDidUpdate matchInsightsMatches - :o', error);
				});
		}

		/** Check if cloud Match Insight data exists in the master maches ids list file */
		if (
			this.props.status == 'loaded' &&
			this.sharedDataLoaded &&
			this.props.matchInsightsMatches &&
			op.get(this.props, 'matchInsightsMatches.status', '') == 'loaded' &&
			!this.checkMatchExists
		) {
			let matchesList = op.get(this.props, 'matchInsightsMatches.result.matches', []);
			this.checkMatchExists = true;

			if (op.get(this, 'parsedQs.allow', 'false') == 'true') {
				this.setState({
					matchExists: true,
				});
			} else if (matchesList.length > 0) {
				this.setState({
					matchExists: matchesList.includes(this.matchId),
				});
			} else {
				this.setState({
					matchExists: false,
				});
			}
		}

		if (
			(prevProps.status !== this.props.status &&
				this.props.status == 'loaded' &&
				this.matchId &&
				this.state.matchExists) ||
			(prevProps.matchId !== this.matchId && this.state.matchExists) ||
			(prevState.matchExists !== this.state.matchExists &&
				this.state.matchExists &&
				this.props.configScoringData?.matchStat)
		) {
			logger.log('[MatchInsightsContent] componentDidUpdate - loading data files');
			let { configScoringData, configOtherData } = this.props;

			let matchInsightsDataPath, matchFactsPath, powerIndexMatchupPath;

			/** start pulling match stats to monitor if the match is started or over
			 *  The stat feed doesn't exist until players show up on the court
			 *  status: "Players Arrive On Court" <-- this is the first status in the stat file
			 */
			this.getStatsData();

			/** set testing URLs  */
			if (this.isInnovationTest) {
				matchInsightsDataPath = this.props.innovationTest.matchInsights.replace('<matchId>', this.matchId);
				matchFactsPath = this.props.innovationTest.matchInsightscloudData.matchInsightsFacts.replace(
					'<matchId>',
					this.matchId
				);
				powerIndexMatchupPath = this.props.innovationTest.matchInsightscloudData.powerIndexMatchup.replace(
					'<matchId>',
					this.matchId
				);
			} else {
				matchInsightsDataPath = configScoringData.matchInsights.replace('<matchId>', this.matchId);
				matchFactsPath = configOtherData.innovations.matchInsightsFacts.replace('<matchId>', this.matchId);
				powerIndexMatchupPath = configOtherData.innovations.powerIndexMatchup.replace(
					'<matchId>',
					this.matchId
				);
			}

			// fetch scores match insights data file - used to get event name, court name, etc
			fetch(matchInsightsDataPath).then(mi_result => {
				logger.log('[MatchInsightsContent] componentDidUpdate - mi_result:%o', mi_result);

				logger.log('[MatchInsightsContent] componentDidUpdate - matchFactsPath:%o', matchFactsPath);
				logger.log(
					'[MatchInsightsContent] componentDidUpdate - powerIndexMatchupPath:%o',
					powerIndexMatchupPath
				);

				// fetch nlg match insights data file
				fetch(matchFactsPath)
					.then(mf_result => {
						logger.log('[MatchInsightsContent] componentDidUpdate - mf_result:%o', mf_result);

						// fetch nlg match insights data file
						fetch(powerIndexMatchupPath)
							.then(pim_result => {
								logger.log('[MatchInsightsContent] componentDidUpdate - pim_result:%o', pim_result);

								if (op.get(mi_result, 'matches', []).length > 0) {
									this.setState({
										powerIndexMatchup: pim_result,
										matchInsightsData: mi_result.matches[0],
										atAGlance: mf_result.nlg[0].doc,
										inTheMedia: [mf_result.p1_insights, mf_result.p2_insights],
										matchInsightsLastUpdate: mf_result['published_date_milliseconds'],
										matchInsightsStatus: pim_result['publication_date_milliseconds']
											? 'loaded'
											: 'error',
									});
								} else {
									this.setState({
										matchInsightsStatus: 'error',
									});
								}
							})
							.catch(error => {
								logger.log(
									'[MatchInsightsContent] componentDidUpdate error loading powerIndexMatchup data:%o',
									error
								);
								this.setState({
									powerIndexMatchup: null,
									matchInsightsData: mi_result.matches[0],
									atAGlance: mf_result.nlg[0].doc,
									inTheMedia: [mf_result.p1_insights, mf_result.p2_insights],
									matchInsightsLastUpdate: mf_result['published_date_milliseconds'],
									matchInsightsStatus: 'error',
								});
							})
							.catch(error => {
								logger.log(
									'[MatchInsightsContent] componentDidUpdate error loading nlg match insights data:%o',
									error
								);
								this.setState({
									powerIndexMatchup: null,
									matchInsightsData: mi_result.matches[0],
									atAGlance: null,
									inTheMedia: null,
									matchInsightsLastUpdate: null,
									matchInsightsStatus: 'error',
								});
							});
					})
					.catch(error => {
						logger.log(
							'[MatchInsightsContent] componentDidUpdate error loading scores match insights data:%o',
							error
						);
						this.setState({
							powerIndexMatchup: null,
							matchInsightsData: null,
							atAGlance: null,
							inTheMedia: null,
							matchInsightsLastUpdate: null,
							matchInsightsStatus: 'error',
						});
					});
			});
		}
		// logger.log('[MatchInsightsContent] componentDidUpdate  - this.props:%o, this.state:%o', this.props, this.state);
	}

	getStatsData = () => {
		let path = this.props.configScoringData.matchStat.path.replace('<matchId>', this.matchId);

		fetch(path)
			.then(result => {
				let results = result.matches;
				let isThereStat = results.length > 0;
				let isCompleted = false;

				if (isThereStat) {
					isCompleted = results[0].team1.won || results[0].team2.won ? true : false;
				}

				// logger.log('[MatchInsights] getStatsData - this:%o, result:%o', this, result);

				this.setState({
					statsData: isThereStat ? results[0] : [],
					statsStatus: 'loaded',
				});

				/** If the match is already over, don't keep pulling the data */
				if (!this.statsDataTimer && !isCompleted) {
					// logger.log('[MatchInsights] getScoresData - this:%o', this);
					this.statsDataTimer = setInterval(() => {
						this.getStatsData();
					}, 60 * 1000);
				}
			})
			.catch(error => {
				logger.error('[MatchInsights] componentDidUpdate error:%o', error);

				if (!this.statsDataTimer) {
					// logger.log('[MatchInsights] getScoresData - this:%o', this);
					this.statsDataTimer = setInterval(() => {
						this.getStatsData();
					}, 60 * 1000);
				}

				this.setState({
					statsStatus: 'error',
				});
			});
	};

	closeInfoOverlay = () => {
		//MeasurementUtils.dispatchMeasurementCall('closeMatchInsightsModal', {});
		doMeasurement('Match Insights', 'closeMatchInsightsModal', []);
		document.querySelector('body').classList.remove('modal-on-noscroll');

		this.setState({
			showInfoOverlay: false,
		});
	};

	openInfoWindow = contentType => {
		// logger.log('[MatchInsightsContent] openInfoWindow contentType:%o', contentType);
		//MeasurementUtils.dispatchMeasurementCall('infoWindow', { action: 'open', contentType });
		doMeasurement('Match Insights', 'infoWindow', ['open', contentType]);
		document.querySelector('body').classList.add('modal-on-noscroll');

		this.setState({
			openModal: true,
			modalContent: contentType,
		});
	};

	closeInfoWindow = () => {
		//MeasurementUtils.dispatchMeasurementCall('infoWindow', { action: 'close' });
		doMeasurement('Match Insights', 'infoWindow', ['close']);
		document.querySelector('body').classList.remove('modal-on-noscroll');

		this.setState({
			openModal: false,
			modalContent: null,
		});
	};

	measureNextPrev = (oldIndex, newIndex, playerData) => {
		// logger.log('[MatchInsightsContent] measureNexPrev oldIndex:%o, newIndex:%o, playerData:%o', oldIndex, newIndex, playerData);

		if (oldIndex < newIndex) {
			// MeasurementUtils.dispatchMeasurementCall('InTheMedia', {
			// 	action: 'next',
			// 	curSlide: newIndex,
			// 	playerData,
			// });
			doMeasurement('Match Insights', 'InTheMedia', [
				'next',
				newIndex,
				// playerData.firstNameA,
				// playerData.lastNameA,
			]);
		} else {
			// MeasurementUtils.dispatchMeasurementCall('InTheMedia', {
			// 	action: 'prev',
			// 	curSlide: newIndex,
			// 	playerData,
			// });
			doMeasurement('Match Insights', 'InTheMedia', [
				'prev',
				newIndex,
				// playerData.firstNameA,
				// playerData.lastNameA,
			]);
		}
	};

	onShareClick = () => {
		doMeasurement('Match Insights', 'toggleShare', ['hide']);

		this.setState({
			sharePopup: 'hide',
		});
	};

	toggleShare = () => {
		if (window.webview) {
			let url = window.location.href;

			/** clean up the URL so it sharers the correct www URL and not webview URL */
			if (url.indexOf('/webview') > -1) {
				url = url.replace('/webview', '');
			}

			window.webviewPlatform == 'ios'
				? window.webkit.messageHandlers.share.postMessage(url)
				: window.JSInterface.share(url);

			// doMeasurement('Match Insights', 'toggleShare', [this.state.sharePopup == 'hide' ? 'show' : 'hide']);
		} else {
			doMeasurement('Match Insights', 'toggleShare', [this.state.sharePopup == 'hide' ? 'show' : 'hide']);

			this.setState({
				sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
			});
		}
	};

	getStubContent = () => {
		return (
			<div className="column-layout news">
				<StubBox attributes={{ message: op.get(this.props, 'stubs.matchinsights.text', '') }} />
			</div>
		);
	};

	setTab = (val, metricsVal = '') => {
		if (val) {
			this.setState({
				selectedTab: val,
			});

			let contextData;
			let metricsArgs = [{ tab: metricsVal }];

			if (window.webviewPlatform) {
				/** contextData is new in 2022 for webviews -
				 * passing this as fourth data adds a query string key=value to measureInApp */
				contextData = [{ mi_tab: metricsVal }];
				metricsArgs = [];
			}

			doMeasurement('IBM Match Insights', 'Tab Selected', metricsArgs, contextData);
		}
	};

	getWinFactorsComponent = () => {
		return (
			<WinFactors
				matchId={this.matchId}
				hideTitle={!this.showTabs || this.props?.type == 'blog' ? false : true}
			/>
		);
	};

	getHaveYourSayComponent = () => {
		if (this.props?.type !== 'blog') {
			return (
				<HaveYourSay
					powerIndexMatchupData={this.state?.powerIndexMatchup}
					matchStatsData={this.state.statsData}
					teams={this.teams}
					matchId={this.matchId}
					imgPath={this.props.configOtherData.playerProfileImagePath}
				/>
			);
		}
	};

	renderDesktopFactors = prematchWinnerPos => {
		if (this.props.configOtherData?.matchInsights?.disableFanPrediction) {
			return <div className="mi-section row no-border-bottom">{this.getWinFactorsComponent()}</div>;
		} else {
			return (
				<div className="mi-section factors no-border-bottom">
					<div className={`silo ${prematchWinnerPos == 'right' ? 'blog-hide' : ''}`}>
						{prematchWinnerPos == 'left' ? this.getWinFactorsComponent() : this.getHaveYourSayComponent()}
					</div>

					<div className={`silo ${prematchWinnerPos == 'left' ? 'blog-hide' : ''}`}>
						{prematchWinnerPos == 'right' ? this.getWinFactorsComponent() : this.getHaveYourSayComponent()}
					</div>
				</div>
			);
		}
	};

	render() {
		logger.log('[MatchInsightsContent] render - this:%o', this);

		let isStub = op.get(this.props, 'stubs.matchinsights.stub', '') == 'stub';

		/**  make the default true to matchExists so it doesn't flash stub content
		 *  while setting the correct state
		 */
		let isError = op.get(this.state, 'matchInsightsStatus', false) == 'error';
		let isExists = op.get(this.state, 'matchExists', true);
		this.showTabs = this.props.EventsWindow?.numericWindowSize < 4;

		let isWebview = window.webviewPlatform;

		if (
			this.props.status == 'loaded' &&
			op.get(this.state, 'matchInsightsData', false) &&
			op.get(this.props, 'EventsWindow.windowSize', false) &&
			op.get(this.props, 'scoring', false) &&
			isExists &&
			!isError
		) {
			let { matchInsightsData, powerIndexMatchup, statsData } = this.state;
			// let teams = [matchInsightsData.team1, matchInsightsData.team2];
			this.teams = [matchInsightsData.team1, matchInsightsData.team2];

			/** reset the teams data if stats data exist as it has won status */
			if (statsData && statsData !== 'error') {
				// && statsData !== []
				this.teams = [this.state.statsData.team1, this.state.statsData.team2];
			}

			// let prematchWinner = getLikelihoodWinner(powerIndexMatchup);
			let pageTitle = `${this.teams[0]['lastNameA']} vs ${this.teams[1]['lastNameA']}: Match Insights with Watson`;

			let prematchWinner = getLikelihoodWinner(powerIndexMatchup);
			let prematchWinnerPos = this.teams[0]['idA'] === prematchWinner ? 'left' : 'right';

			return (
				<div className="content-main match-insights" id="match-insights">
					{!this.props.ignoreHelmet && !isWebview ? (
						<Helmet>
							<meta property="og:title" content={pageTitle} />
							<meta property="og:description" content={pageTitle} />
							<meta
								property="og:image"
								content="https://www.usopen.org/images/pics/misc/uso_generic_share_2021.jpg"
							/>
							<meta property="og:url" content={window.location.href} />
							<meta property="og:type" content="article" />
							<meta property="fb:app_id" content="471867639491882" />

							<meta name="twitter:card" content="summary_large_image" />
							<meta
								property="twitter:image"
								content="https://www.usopen.org/images/pics/misc/uso_generic_share_2021.jpg"
							/>
							<meta name="twitter:site" content="@usopen" />
							<meta name="twitter:creator" content="@usopen" />
						</Helmet>
					) : null}

					<div>
						{this.state.openModal && this.state.modalContent && (
							<MIModal
								header={MatchInsightsInfoText[this.state.modalContent]['title']}
								body={MatchInsightsInfoText[this.state.modalContent]['body']}
								closeModal={this.closeInfoWindow}
							/>
						)}
						<MIHeaderContents
							data={{
								type: '',
								headerTitle: `${this.teams[0]['lastNameA']} vs ${this.teams[1]['lastNameA']}: Match Insights with Watson`,
								event: matchInsightsData.eventName,
								round: matchInsightsData.roundName,
								court: matchInsightsData.shortCourtName,
								headerDate: this.state.matchInsightsLastUpdate,
								infoIcon: true,
								url: `https://www.usopen.org/en_US/matchinsights/${this.props.matchId}.html`,
								onInfoClickFn: () => this.openInfoWindow('header'),
								windowSize: this.props?.EventsWindow?.windowSize,
								matchId: this.matchId,
								cmsLink: this.props.cmsLink,
								isWebview: this.props.isWebview,
							}}
						/>
					</div>
					<div className="match-insights-content">
						{window.webviewPlatform && <BackToAllMI parsedQs={this.parsedQs} />}

						{powerIndexMatchup !== null && this.state.atAGlance !== null ? (
							<ErrorBoundary
								message="IBM Power Index is not currently available for this match"
								klass="mi-section">
								<PowerRanking
									matchData={matchInsightsData}
									teams={this.teams}
									powerIndexMatchupData={powerIndexMatchup}
									imgPath={this.props.configOtherData.playerProfileImagePath}
									flagPath={this.props.configOtherData.flagImagePathSmall}
									windowSize={this.props.EventsWindow?.windowSize}
									infoIcon={true}
									eventId={this.state.atAGlance['event_id']}
									onInfoClickFn={() => this.openInfoWindow('power-ranking')}
									scoring={this.props.scoring}
									isWebview={this.props.isWebview}
								/>
							</ErrorBoundary>
						) : null}

						{/** render side by side WinFactors and Have Your Say components
						 *    WinFactors component should in line with the winner headshot image above
						 */}
						<div className="above-desktop">{this.renderDesktopFactors(prematchWinnerPos)}</div>

						{/** Win Factors, In the Media, At a Glance show in tabs format on mobile & tablet windowSize */}
						<ul className="mi-tags">
							<li
								onClick={() => this.setTab('winfactors', 'Win Factors')}
								className={this.state.selectedTab === 'winfactors' ? 'selected' : ''}>
								Win Factors
							</li>
							<li
								onClick={() => this.setTab('inthemedia', 'In The Media')}
								className={this.state.selectedTab === 'inthemedia' ? 'selected' : ''}>
								In The Media
							</li>
							<li
								onClick={() => this.setTab('ataglance', 'At a Glance')}
								className={this.state.selectedTab === 'ataglance' ? 'selected' : ''}>
								At a Glance
							</li>
						</ul>

						{this.state.selectedTab === 'winfactors' ? (
							<div className="below-desktop">{this.getWinFactorsComponent()}</div>
						) : null}

						{this.state.inTheMedia !== null &&
						((this.state.selectedTab === 'inthemedia' && this.showTabs) || !this.showTabs) ? (
							<ErrorBoundary
								message="In The Media is not currently available for this match"
								klass="mi-section">
								<InTheMedia
									teams={this.teams}
									inTheMediaData={this.state.inTheMedia}
									lastUpdate={op.get(this.state, 'matchInsightsLastUpdate', null)}
									infoIcon={false}
									onNextPrevClickFn={this.measureNextPrev}
									windowSize={op.get(this.props, 'EventsWindow.windowSize', null)}
								/>
							</ErrorBoundary>
						) : null}

						{/* MD - Innovation 2024 - remove By the Numbers */}
						{/*this.state.atAGlance !== null &&
						((this.state.selectedTab === 'ataglance' && this.showTabs) || !this.showTabs) ? (
							<ErrorBoundary
								message="At A Glance is not currently available for this match"
								klass="mi-section">
								<ByTheNumbers
									teams={this.teams}
									byTheNumbersData={this.state.atAGlance}
									userPreference={this.props.scoring.uom}
									infoIcon={false}
									tournamentYear={
										this.props?.tournamentYear ? parseInt(this.props.tournamentYear) : 2023
									}
								/>
							</ErrorBoundary>
						) : null */}
						{/* MD - Innovation 2024 - Decommision Power Index */}
						{/* powerIndexMatchup !== null ? (
							<ErrorBoundary message="">
								<PowerIndexNumbers
									powerIndexMatchupData={powerIndexMatchup}
									teams={this.teams}
									windowSize={this.props?.EventsWindow?.windowSize}
									type={this.props?.type}
								/>
							</ErrorBoundary>
						) : null */}

						<div className="below-desktop">{this.getHaveYourSayComponent()}</div>

						{!window.webviewPlatform && <BackToAllMI parsedQs={this.parsedQs} />}
					</div>
				</div>
			);
		} else if (isStub || !isExists || isError) {
			return <div className="content-main">{this.getStubContent()}</div>;
		} else {
			return (
				<div className="content-main  match-insights">
					{/* <div> */}
					<MIHeaderContents
						data={{
							type: '',
							headerTitle: 'Match Insights with Watson',
							event: '',
							round: '',
							court: '',
							headerDate: '',
							url: '',
							infoIcon: false,
							windowSize: '',
						}}
					/>
					{/* </div> */}
					<div className="match-insights-content">
						{op.get(this.state, 'matchExists', null) == false ? (
							this.getStubContent()
						) : (
							<LoadingIndicator />
						)}
					</div>
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsightsContent);
