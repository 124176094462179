/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: PastMatchUpsItem
 * 
 * currently used in slamtracker head to head section
 * 
 * -----------------------------------------------------------------------------
 */
const PastMatchUpsItem = props => {
    logger.log('[PastMatchUpsItem] props:%o', props);

    const roundNames = useSelector((state) => state?.['Config']?.scoringConfig?.roundNames ?? []);
    const flagPath = useSelector((state) => state?.['Config']?.otherData?.flagImagePathSmall ?? "");

    const getFinalStatusText = () => {
        return(
            <div className="status">
                <div className={`p1`}>
                    <div className="flag">
                        <img
                            alt={props.country1}
                            src={flagPath?.replace('<code>', props.country1)}
                        />
                    </div>
                    <span className="player">{props?.player1}</span>
                    {props?.match?.winner == '1' && <i className="icon-check" />}
                </div>
                <div className={`p2`}>
                    <div className="flag">
                        <img
                            alt={props.country2}
                            src={flagPath?.replace('<code>', props.country2)}
                        />
                    </div>
                    <span className="player">{props?.player2}</span>
                    {props?.match?.winner == '2' && <i className="icon-check" />}
                </div>
            </div>
        );

    }

    const getRoundName = () => {
        let found = roundNames.filter(round => {
            return round.roundNameShort == props.match.round;
        });

        if(found.length > 0) {
            return found[0].roundName;
        } else {
            return null;
        }
    }

    return (
        <div className='pastmatchups-item'>
            <div className="pastmatchups-item-row">
                <div className="tournament">{`${props.match.year} ${props.match.tournamentName} ${getRoundName() ? '- '+getRoundName() : ''}`}</div>
                <div className="status-container">
                    {/* <div className="round-opponent"><span className="round">{ getRoundName() }</span></div>  */}
                    {/* - {props.match.opponent} */}
                    { getFinalStatusText() }
                    {/* <div className="status">{props.match.status}</div> */}
                    <div className="score">{props.match.scores}</div>
                </div>
            </div>
        </div>
    )
};

export default PastMatchUpsItem;