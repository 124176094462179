/**
 SiteMap Initial State
*/

export default {
  stub: false,
  filters: {
    listView: "seeds",
    open: ""
  }
};
