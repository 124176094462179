import PlayersIndex from './index';
import deps from 'dependencies';

export default {
	path: '/:lang(en_US)/players/index.html',
	order: 2,
	exact: true,
	component: PlayersIndex,
	load: params => deps.actions.PlayersIndex.mount(params),
};
