import PlayerPage from './index';
import deps from 'dependencies';

export default {
	order: 2,
	path: '/:lang(en_US)/players/overview/:player.html',
	exact: true,
	component: PlayerPage,
	load: params => deps.actions.PlayerPage.mount(params),
};
