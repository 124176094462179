/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import { getInterfaceLink } from 'appdir/components/general/Util/index.js';

/**
 * -----------------------------------------------------------------------------
 * Component: ViewMoreMatches
 * -----------------------------------------------------------------------------
 */
const ViewMoreMatches = props => {
    const dispatch = useDispatch();

	const goToMatches = path => {
		props.measureIndex(`${props.currentTab}:View More Matches`, '', []);

        if (window.webview) {
			let interfaceLink = getInterfaceLink('liveMatches', {id: props.matchId});
			interfaceLink?.onclick();
		} else if (path) {
			if (props.windowSize == 'mobile') {
				dispatch(deps.actions.Controller.setSTPanelStatus({ open: false }));
			}
            dispatch(deps.actions.MainNav.navigate({path: path}));
		}
    }

    return (
		<div className="view-more-matches-container">
			<ButtonLinkText
				className="view-more-matches"
				onClick={() => {
					goToMatches('/en_US/scores/index.html');
				}}>
				View More Matches
			</ButtonLinkText>
		</div>
	);
}

export default ViewMoreMatches;
