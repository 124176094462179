/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import filter from 'lodash/filter';
import Translate from 'appdir/components/pages/Slamtracker/Translate';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ServePlacement
 * -----------------------------------------------------------------------------
 */

const ServePlacement = props => {
	// logger.log('[ServePlacement] props: %o',props);
	const getServePlacement = (court, which) => {
		var objArray = filter(props.data.pointHistory, function (e) {
			return (
				parseInt(e.PointNumber, 10) <= parseInt(props.data.point, 10) &&
				e.ServeWidth == which &&
				e.Court == court &&
				e.ServeNumber == '1' &&
				e.PointWinner == e.PointServer &&
				e.PointWinner == props.data.pointData.PointWinner
			);
		});

		return objArray.length;
	};

	const getWidthObj = () => {
		var widthObj = {
			A: {
				W: getServePlacement('A', 'W'),
				BW: getServePlacement('A', 'BW'),
				B: getServePlacement('A', 'B'),
				BC: getServePlacement('A', 'BC'),
				C: getServePlacement('A', 'C'),
			},
			D: {
				W: getServePlacement('D', 'W'),
				BW: getServePlacement('D', 'BW'),
				B: getServePlacement('D', 'B'),
				BC: getServePlacement('D', 'BC'),
				C: getServePlacement('D', 'C'),
			},
		};
		return widthObj;
	};

	const widthObjAvailable = widthData => {
		if (
			Number(widthData.A.W) +
				Number(widthData.A.BW) +
				Number(widthData.A.BW) +
				Number(widthData.A.B) +
				Number(widthData.A.BC) +
				Number(widthData.A.C) +
				Number(widthData.D.W) +
				Number(widthData.D.BW) +
				Number(widthData.D.B) +
				Number(widthData.D.BC) +
				Number(widthData.D.C) ==
			0
		) {
			return false;
		} else {
			return true;
		}
	};

	let widthData = getWidthObj();
	// logger.log('[StageItems | ServePlacement] widthData: %o', widthData);

	if (widthObjAvailable(widthData)) {
		if (props.stage_location == 'slamtracker') {
			return (
				<div className="si-outer-wrapper serve-placement">
					<div className="subheading-title">
						<Translate
							data={{
								text: 'serve_points_title',
								replace: [props.data.team.name, props.data.set],
							}}
						/>
					</div>
					<div className="stage-item serve-placement si-wrapper">
						<div className="si-wrapper-inner">
							<div className="stage-container">
								<div className="si-inner-wrapper">
									<div className="item stage1">
										<div className="court-flat-wrapper">
											<div className="results">
												<div className="results-inner left">
													<div className="court-advantage">
														<Translate originalText="ad_court" />
													</div>
													<div className="position-label">
														<div className="position-label-items">
															<div className="label left wide">
																<div>wide</div>
															</div>
															<div className="label left body-wide">
																<div>body-wide</div>
															</div>
															<div className="label left body">
																<div>body</div>
															</div>
															<div className="label left body-t">
																<div>body-t</div>
															</div>
															<div className="label left the-t">
																<div>t</div>
															</div>
														</div>
													</div>
													<div className="team1">
														<div className="results-wrapper advantage">
															<div className="set-result wide">{widthData.A.W}</div>
															<div className="set-result body-wide">
																<div>{widthData.A.BW}</div>
															</div>
															<div className="set-result body">
																<div>{widthData.A.B}</div>
															</div>
															<div className="set-result body-t">
																<div>{widthData.A.BC}</div>
															</div>
															<div className="set-result the-t">
																<div>{widthData.A.C}</div>
															</div>
														</div>
													</div>
												</div>
												<div className="results-inner right">
													<div className="court-deuce">
														<Translate originalText="deuce_court" />
													</div>
													<div className="position-label">
														<div className="position-label-items">
															<div className="label right the-t">
																<div>t</div>
															</div>
															<div className="label right body-t">
																<div>body-t</div>
															</div>
															<div className="label right body">
																<div>body</div>
															</div>
															<div className="label right body-wide">
																<div>body-wide</div>
															</div>
															<div className="label right wide">
																<div>wide</div>
															</div>
														</div>
													</div>
													<div className="team2">
														<div className="results-wrapper deuce">
															<div className="set-result the-t">
																<div>{widthData.D.C}</div>
															</div>
															<div className="set-result body-t">
																<div>{widthData.D.BC}</div>
															</div>
															<div className="set-result body">
																<div>{widthData.D.B}</div>
															</div>
															<div className="set-result body-wide">
																<div>{widthData.D.BW}</div>
															</div>
															<div className="set-result wide">
																<div>{widthData.D.W}</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="heading-wrapper">
							<div className="heading">
								<Translate originalText="balltracking_title" />
							</div>
						</div>
						<div className="court-position-empty"></div>
					</div>
				</div>
			);
		}
	} else {
		// logger.log('[StageItems | ServePlacement] No data to display stage item');
		return null;
	}
};

export default ServePlacement;
