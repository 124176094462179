import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetch = paths => {
	let hdr = restHeaders();
	return axios.all(paths.map(path => axios.get(path, { headers: hdr }))).then(function(results) {
		const result = results.map(r => {
			if (r.status === 200) {
				logger.log('[OpenQuestions] fetch - 200 valid response');

				return r.data;
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		});
		return result;
	});
};

export default {
	fetch,
};
