/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * React Component: ActiveData
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign({}, state['ActiveData'], props);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ActiveData.mount()),
});

class ActiveData extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	componentDidMount() {
		this.props.mount();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[ActiveData] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[ActiveData] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	render() {
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveData);
