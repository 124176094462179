import EventScheduleWebview from './index';
import deps from 'dependencies';

export default [
	{
		path: '/webview/:lang(en_US)/info/fanweek.html',
		exact: true,
		component: EventScheduleWebview,
		load: params => deps.actions.EventScheduleWebview.mount(params),
	},
	{
		path: '/ios/:lang(en_US)/info/fanweek.html',
		exact: true,
		component: EventScheduleWebview,
		load: params => deps.actions.EventScheduleWebview.mount(params),
	},
];
