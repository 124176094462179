import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Template from 'components/Template';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import StubBox from 'appdir/components/common-ui/StubBox';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import OQModal from './OQModal';

const mapStateToProps = (state, props) => {
	return {
		...state['OpenQuestions'],
		stubs: state['Config'].stubPages,
		OQDataFeeds: state['Config'].open_questions,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.OpenQuestions.mount()),
	toggleModal: data => dispatch(deps.actions.OpenQuestions.toggleModal(data)),
});

class OpenQuestions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			loaded: false,
		};
		this.listener;

		this.genChip = this.genChip.bind(this);
		this.getQuestions = this.getQuestions.bind(this);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Open Questions',
		});
		logger.log('[OpenQuestions] constructor - props:%o', props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('mount')) {
			this.props.mount();
		}
		if (!isEmpty(this.props.OQDataFeeds)) {
			this.getQuestions();
		}
		this.listener = window.addEventListener('keydown', e => this.toggleModal(e));
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEmpty(this.props.OQDataFeeds) && !isEqual(this.props.OQDataFeeds, prevProps.OQDataFeeds)) {
			this.getQuestions();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.listener);
	}

	getQuestions() {
		deps.services.OpenQuestions.fetch([this.props.OQDataFeeds.question_list])
			.then(data => {
				this.setState(prevState => {
					return { ...prevState, OQData: data, loaded: true };
				});
				if (!this.state.loaded) {
					this.loaded = true;
				}
			})
			.catch(e => {
				this.setState({
					loadingError: true,
				});
			});
	}

	genChip(link, name, desc, index) {
		return (
			<div className="debate_chip_wrapper" key={`debate-chip-${index}`}>
				<Link to={link}>
					<div className="debate_chip">
						<div className="video_img">
							<img src={`/assets/images/openQuestions/${name}.jpg`} alt={name} />
						</div>
						<div className="caption">{desc}</div>
					</div>
				</Link>
			</div>
		);
	}

	toggleModal(e) {
		logger.log('[OpenQuestions] toggleModal:%e', e.key);
		let showModal = this.props.showModal == true ? false : true;
		if (e.key == 'Escape') {
			showModal = false;
		} else if (e.key) {
			return;
		}
		this.props.toggleModal(showModal);
		// this.setState({ showModal });
	}

	render() {
		logger.log('[OpenQuestions] render - this.state: %o', this.state);
		logger.log('[OpenQuestions] render - this.props: %o', this.props);

		let header_attributes = {
			headerType: 'visit',
			title: 'Open Questions',
			metaTitle: 'Open Questions ',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		let openquestions = get(this.state, 'OQData[0].openquestions', []);
		let postTournament = get(this.props, 'OQDataFeeds.post_tournament', false);

		//Stub check
		if (this.props.stubs && this.props.stubs.open_questions.stub === 'stub') {
			return (
				<Template header={header_attributes} subHeader={false} hideAd={true}>
					<section id="open_questions" className="wrapper openquestions">
						<h1 className="header" style={{ marginBottom: '0px' }}>
							Open Questions
						</h1>
						<StubBox
							attributes={{
								title: header_attributes.title,
								message: this.props.stubs.open_questions.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.props.stubs && this.state.loaded && !isEmpty(openquestions)) {
			return (
				<Template header={header_attributes} subHeader={false} hideAd={true}>
					<OQModal showModal={this.props.showModal} toggleModal={e => this.toggleModal(e)} />
					<div className="openquestions general_content">
						<div className="open_questions_header">
							<img
								src="/assets/images/matchInsights/Watson_product_logo_color_RGB.png"
								alt="Open Questions with Watson"
							/>
							<div className="header_text">
								<span className="first">Open Questions </span>
								<span className="second">with Watson</span>
							</div>
							<span className="info" onClick={e => this.toggleModal(e)}>
								<i className="icon-info-icon" />
							</span>
						</div>
						{postTournament && (
							<div className="end_message">
								Thank you for your interest in Open Questions with IBM Watson! The debates are now
								closed, but you can view the results with Watson's analysis of fan input by clicking on
								each question below.
							</div>
						)}
						<div className="question_chips">
							{openquestions.map((item, i) => {
								// logger.log('[OpenQuestions] topic:%o', item.topic);
								const searchRegExp = /\s/g;
								const replaceWith = '-';
								let detail_url = item.topic.toLowerCase().replace(searchRegExp, replaceWith);
								return this.genChip(
									`/en_US/open_questions/detail/${detail_url}/index.html`,
									detail_url,
									item.human_text,
									i
								);
							})}
						</div>
					</div>
				</Template>
			);
		} else {
			return (
				<Template header={header_attributes} subHeader={false} hideAd={true}>
					<LoadingIndicator />
				</Template>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(OpenQuestions);
