/**
 ScheduleWebview Initial State
*/

export default {
  mode: "",
  filters: {
    day: "",
    open: ""
  }
};
