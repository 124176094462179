/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import Slider from 'react-slick';
import Time from 'appdir/components/common-ui/Time';
import EventsLink from 'appdir/components/general/EventsLink';
import MISectionHeader from './MISectionHeader';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: InTheMedia for Match Insights
 *
 *  props = {
 *             teams,  --------- Array of Match Insights data - [team1, team2]
 *             inTheMediaData ---- Media data that are stored from Match Insights data
 *             matchRoundCode ---- Match round code from scoring Match data, `publication_date_milliseconds`
 * 			   lastUpdate, ------- epoch time from Match Insights data  - not sure where this data is...
 *             onNextPrevClickFn- callback method for metrics call
 *             infoIcon, ------ boolean - to show (i) or not
 *             onInfoClickFn, -- callback method to open a modal
 *
 *           }
 *
 * -----------------------------------------------------------------------------
 */
function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={style} onClick={onClick}>
			<i className="icon-arrow-right" />
		</div>
	);
}

function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={style} onClick={onClick}>
			<i className="icon-arrow-left" />
		</div>
	);
}

const InTheMedia = props => {
	logger.log('[InTheMedia] props:%o', props);

	const renderInTheMedia = () => {
		let team1data = props.inTheMediaData[0];
		let team2data = props.inTheMediaData[1];

		/** this is a pre match insights, no round code as it's from completed match data */
		// let currentrounddata = teamdata.filter(data => {
		// 	return data.doc.insight.published_round == 'F'; // to test multiple items
		// 	return data.doc.insight.published_round == props.matchRoundCode;
		// });
		let isItSlamTracker = props?.type == 'slamtracker';
		let isBlog = props?.type == 'blog';
		// let maxcount = isItSlamTracker ? 1 : 3;
		let maxcount = 3;
		let settings;

		if (op.get(props, 'windowSize', false) != 'mobile' && op.get(props, 'windowSize', false) != 'landscape') {
			settings = {
				dots: true,
				infinite: false,
				nextArrow: <NextArrow />,
				prevArrow: <PrevArrow />,
				speed: 500,
				className: `team-media`,
				slidesToScroll: 1,
				slidesToScroll: 1,
				beforeChange: (oldIndex, newIndex) => {
					props.onNextPrevClickFn(oldIndex, newIndex);
				},
			};
		} else {
			settings = {
				dots: true,
				arrows: false,
				infinite: false,
				speed: 500,
				className: `team-media`,
				slidesToScroll: 1,
				slidesToScroll: 1,
				swipeToSlide: true,
				beforeChange: (oldIndex, newIndex) => {
					props.onNextPrevClickFn(oldIndex, newIndex);
				},
			};
		}

		if (team1data.length > 0 || team2data.length > 0) {
			let datatocheck;

			if (team1data.length > team2data.length || team1data.length == team2data.length) {
				datatocheck = team1data;
			} else if (team2data.length > team1data.length) {
				datatocheck = team2data;
			}

			return (
				<div className={`mi-media-carousel ${props?.type ? props?.type : ''}`}>
					<div className="si-inner-wrapper">
						<Slider {...settings}>
							{datatocheck.map((data, i) => {
								if (i < maxcount) {
									return (
										<div className="media-slide-container" key={`mi-media-slide-${i}`}>
											{team1data[i] ? (
												<div className="media-item mi-media-content">
													<div className="media-item-text">{`"${team1data[i].doc.insight.factoid.text}"`}</div>
													<div className="media-item-info">
														<span className="media-item-year">
															<Time
																epoch_ms={team1data[i].doc.article.publication_date_ms}
																format="YYYY"
															/>
														</span>
														<span className="media-item-host">{` - ${team1data[i].doc.article.host}`}</span>
														<span className="media-item-link">
															<EventsLink
																to={team1data[i].doc.article.url}
																external={true}>
																<i className="icon-link-out"></i>
															</EventsLink>
														</span>
													</div>
												</div>
											) : (
												<div className="media-item mi-media-content">
													{`No media found for ${props.teams[0].firstNameA} ${props.teams[0].lastNameA}`}
												</div>
											)}

											{team2data[i] ? (
												<div className="media-item mi-media-content">
													<div className="media-item-text">{`"${team2data[i].doc.insight.factoid.text}"`}</div>
													<div className="media-item-info">
														<span className="media-item-year">
															<Time
																epoch_ms={team2data[i].doc.article.publication_date_ms}
																format="YYYY"
															/>
														</span>
														<span className="media-item-host">{` - ${team2data[i].doc.article.host}`}</span>
														<span className="media-item-link">
															<EventsLink
																to={team2data[i].doc.article.url}
																external={true}>
																<i className="icon-link-out"></i>
															</EventsLink>
														</span>
													</div>
												</div>
											) : (
												<div className="media-item mi-media-content">
													{`No media found for ${props.teams[1].firstNameA} ${props.teams[1].lastNameA}`}
												</div>
											)}
										</div>
									);
								}
							})}
						</Slider>
					</div>
				</div>
			);
		} else {
			return (
				<div className="mi-section mi-in-the-media-wrapper">
					<div className="mi-media-content">
						{/* <div className="player-name">{team['displayNameA']}</div> */}
						There is no information for {teams[0].firstNameA} {teams[0].lastNameA} or {teams[1].firstNameA}{' '}
						{teams[1].lastNameA} in the media.
					</div>
				</div>
			);
		}
	};

	return (
		<div className="mi-section mi-in-the-media-wrapper no-border-bottom">
			<MISectionHeader
				title="In the Media"
				infoIcon={props.infoIcon ? props.infoIcon : false}
				onInfoClickFn={props.onInfoClickFn}
				lastUpdate={props.lastUpdate ? props.lastUpdate : ''}
			/>

			<div className="mi-media">{renderInTheMedia()}</div>
		</div>
	);
};

export default InTheMedia;
