/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector } from 'react-redux';
import op from 'object-path';

import { getStatusConfig } from 'appdir/components/pages/Slamtracker/Utilities';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Tabs
 * -----------------------------------------------------------------------------
 */
const Tabs = props => {
	// logger.log('[Tabs] props: %o', props);

    const statMatch = useSelector((state) => op.get(state['ScoreManager'], 'statMatch', {}),);
    const pointHistory = useSelector(state => state?.['ScoreManager']?.statMatch?.pointHistory ?? {});
    let statusConfig = getStatusConfig(statMatch.statusCode);

    let current = props?.tab 
                    ? props.tab 
                    : statusConfig.status 
                        ? statusConfig.status
                        : null;

    // logger.log('[Tabs] statusConfig: %o, current: %o', statusConfig, current);
    
    return (
        <div className="content-tabs">
            <a
                onClick={() => { 
                    props.setPageViews('preview'); 
                    props.measureIndex('Tab Click', ['Preview'], [{ slamtracker_tab: 'Preview' }]);
                }}
                className={current == 'preview' ? 'selected' : ''}>
                Preview
            </a>

            {/* 
                Match status is pre, tab text is Live and disabled
                Match status is live, tab text is Live and enabled
                Match status is post, tab text is Replay and enabled
            */}
            {statusConfig.status == 'live' ?
                <a
                    onClick={() => { 
                        props.setPageViews('live'); 
                        props.measureIndex('Tab Click', ['Live'], [{ slamtracker_tab: 'Live' }]);
                    }}
                    className={`${current == 'live' ? 'selected' : ''}${statusConfig.status == 'pre' ? ' disabled' : ''}`}>
                    Live
                </a>
                : statusConfig.status == 'post' ?
                    <a
                        onClick={() => { 
                            props.setPageViews('live'); 
                            props.measureIndex('Tab Click', ['Replay'], [{ slamtracker_tab: 'Replay' }]);
                        }}
                        className={`${current == 'live' ? 'selected' : ''}${statusConfig.status == 'pre' ? ' disabled' : ''}`}>
                        Replay
                    </a>
                    :
                    <a className='disabled'>
                        Live
                    </a>
                }

            {statusConfig.status == 'post' ?
                <a
                    onClick={() => {
                        props.setPageViews('summary');
                        props.measureIndex('Tab Click', ['Summary'], [{ slamtracker_tab: 'Summary' }]);
                    }}
                    className={`${current == 'post'|| current == 'summary' ? 'selected' : ''}`}>
                    Summary
                </a>
                : 
                <a className='disabled'>
                    Summary
                </a>
            }
        </div>
    );
};

export default Tabs;