import React, { Component } from 'react';

class BlueBarDropdown extends Component {
	constructor(props) {
		super();
		this.state = { selectedOption: null };
	}

	handleChange = event => {
		const selectedOption = this.props.options.find(option => option.eventCode === event.target.value);
		this.props.onOptionSelected(selectedOption, selectedOption.eventCode);
	};

	render() {
		const { options, inDropdown, displayVal, isMobile } = this.props;
		// if inDropdown is true, value in select will be the selected option
		// if inDropdown is false, value in select will be -1
		return (
			<div className="blue-bar-dropdown-wrapper">
				<select
					onChange={this.handleChange}
					className="blue-bar-dropdown-selected"
					value={inDropdown && !isMobile ? displayVal : -1}>
					<option value="View By Event" default className="blue-bar-dropdown-item default">
						View By Event
					</option>
					{options.map(option => (
						<option key={option.eventCode} value={option.eventCode} className="blue-bar-dropdown-item">
							{option.title}
						</option>
					))}
				</select>
			</div>
		);
	}
}

export default BlueBarDropdown;
