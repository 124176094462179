/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { validateData } from 'appdir/components/general/Util';
import { fetch } from 'appdir/components/general/Util';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Template from 'components/Template';
import PlayerEventStats from './PlayerEventStats';
import PlayerStatsSelect from './PlayerStatsSelect';
import PlayerStatsTopLeader from './PlayerStatsTopLeader';
import StubBox from 'appdir/components/common-ui/StubBox';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PlayerStatsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign(
		{},
		state['PlayerStatsPage'],
		{ stubs: state['Config'].stubPages },
		{ countries: state['Controller'].countriesLookUp },
		props
	);
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerStatsPage.mount()),
	setGender: data => dispatch(deps.actions.PlayerStatsPage.setGender(data)),
});

class PlayerStatsPage extends Component {
	constructor(props) {
		super(props);

		this.loading = false;
		this.loading_ctry = false;
		logger.log('[PlayerStatsPage] constructor - this:%o', this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.stubs &&
			this.props.stubs.playerstats.stub !== 'stub' &&
			this.props.path &&
			!this.loading &&
			!this.state?.playerStats &&
			!this.state?.playerStatsMS &&
			!this.state?.playerStatsWS
		) {
			this.loading = true;
			fetch(this.props.path.replace('<gender>', 'ms'))
				.then(result => {
					this.setState(prevState => {
						return {
							...prevState,
							playerStats: result,
							playerStatsMS: result,
						};
					});
				})
				.catch(error => {
					logger.error('[PlayerStatsPage] componentDidUpdate loadingError: error', error);

					this.setState({
						loadingError: true,
					});
				});

			fetch(this.props.path.replace('<gender>', 'ws'))
				.then(result => {
					this.setState(prevState => {
						return {
							...prevState,
							playerStatsWS: result,
						};
					});
				})
				.catch(error => {
					logger.error('[PlayerStatsPage] componentDidUpdate loadingError: error', error);

					this.setState({
						loadingError: true,
					});
				});
		}

		logger.log('[PlayerStatsPage] componentDidUpdate this%o', this);
	}

	componentDidMount() {
		logger.log('[PlayerStatsPage] componentDidMount');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'ExtraStats',
		});
	}

	componentWillUnmount() {
		logger.log('[PlayerStatsPage] componentWillUnmount');
	}

	showData(type) {
		this.props.setGender({
			genderType: type,
		});
	}

	getTopLeader() {
		let filter = this.props.filters.playerStats ? this.props.filters.playerStats : 'stats';

		// find index of objects from value
		let index = this.state.playerStatsMS.event_stats.findIndex(item => item.stat_label == filter);
		let msLeaderData = this.state.playerStatsMS.event_stats[index].stat_lines[0];
		let wsLeaderData = this.state.playerStatsWS.event_stats[index].stat_lines[0];

		let msCountryIndex = this.props.countries.findIndex(item => item.code == msLeaderData.nation);
		let wsCountryIndex = this.props.countries.findIndex(item => item.code == wsLeaderData.nation);

		let msCountry = msCountryIndex > -1 ? this.props.countries[msCountryIndex].display : msLeaderData.nation;
		let wsCountry = wsCountryIndex > -1 ? this.props.countries[wsCountryIndex].display : wsLeaderData.nation;

		msLeaderData = {
			...msLeaderData,
			country: msCountry,
			title: this.state.playerStatsMS.event_stats[index].stat_title,
		};

		wsLeaderData = {
			...wsLeaderData,
			country: wsCountry,
			title: this.state.playerStatsWS.event_stats[index].stat_title,
		};

		return {
			ms: msLeaderData,
			ws: wsLeaderData,
		};
	}

	render() {
		logger.log('[PlayerStatsPage] render - this:%o', this);

		let header_attributes = {
			headerType: 'scores',
			title: 'US Open Event Statistics | Official Site of the 2023 US Open Tennis Championships - A USTA Event',
			metaTitle: 'US Open Event Statistics',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			canonicalLink: 'https://www.usopen.org/en_US/scores/extrastats/index.html'
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/scores/index.html',
					title: 'Scores & Stats',
				},
			],
			sponsor: {
				link: 'http://www.ibm.com/sports/usopen',
				title: 'Presented by',
				name: 'IBM',
				imgClass: '',
				imgSrc: '/assets/images/logos/ibm_logo_black.png',
				tagline: 'Presented by',
			},
			page_header: 'Event Statistics',
			title: 'Event Statistics',
		};

		const excludeList = ['stats', 'longest rally count'];

		if (this.state?.loadingError) {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: 'Event Statistics',
								message: 'Event statistics is unavailable.',
								basePicPath: this.props.basePicPath,
								subTitle: '',
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.props.stubs && this.props.stubs.playerstats.stub === 'stub') {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: 'Event Statistics',
								message: this.props.stubs.playerstats.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (
			this.state?.playerStats &&
			this.state?.playerStatsMS &&
			this.state?.playerStatsWS &&
			validateData(this.state?.playerStats?.event_stats, 'array')
		) {
			//format the dropdown data
			let selectData = this.state.playerStats.event_stats
				.filter((d, i) => i !== 0)
				.map((d, i) => {
					return { name: d.stat_label, value: d.stat_label };
				});

			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<h1 className="header draws">Event Statistics</h1>
						<div className="content-main player-stats">
							<PlayerStatsSelect selectData={selectData} filters={this.props.filters} />
							<div id="uso-main">
								<div className="column-layout">
									<div className="four-col">
										{// if it's not stats or longest rally, then create Top Leader component
										excludeList.indexOf(this.props.filters.playerStats.toLowerCase()) < 0 &&
										this.props.countries ? (
											<div id="uso-topLeaders">
												<PlayerStatsTopLeader data={this.getTopLeader().ws} type="ws" />
												<PlayerStatsTopLeader data={this.getTopLeader().ms} type="ms" />
											</div>
										) : null}

										<PlayerEventStats data={{ ...this.state.playerStatsWS }} type="ws" />
										<PlayerEventStats data={{ ...this.state.playerStatsMS }} type="ms" />
									</div>
								</div>
							</div>
						</div>
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<h1 className="header draws">Event Statistics</h1>
						<LoadingIndicator />
					</section>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStatsPage);
