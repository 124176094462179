import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import EventsLink from 'appdir/components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';
import { isMobile, isTablet } from 'react-device-detect';
import isEqual from 'lodash/isEqual';

const mapStateToProps = (state, props) => {
	return {
		...state['LiveBar'],
		live_open: state['Header'].live_open,
		menu_open: state['Header'].menu_open,
		live_bar_components: state['ActiveData'].live_bar_components,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveBar.mount()),
	toggleLiveMenu: isOpen => dispatch(deps.actions.Header.toggleLiveMenu(isOpen)),
	toggleMenu: isOpen => dispatch(deps.actions.Header.toggleMenu(isOpen)),
});

class LiveBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			titleOnAir: false,
			showMenu: false,
			active: false,
		};

		this.loaded = false;
		this.showMenu = this.showMenu.bind(this);
	}

	componentDidMount() {
		this.props.mount();
		this.updateLiveData();
		this.closeLiveBar();
	}

	componentDidUpdate(prevProps) {
		// logger.log('[LiveBar] componentDidUpdate - props: %o, state:%o', this.props, this.state);

		if (!isEqual(prevProps?.live_bar_components, this.props?.live_bar_components)) {
			this.updateLiveData();
		}
	}

	updateLiveData() {
		// logger.log('[LiveBar] updateLiveData - this:%o', this);
		let { live_bar_components } = this.props;

		if (live_bar_components) {
			Object.keys(live_bar_components).map(type => {
				let liveDataType = live_bar_components[type];

				if (liveDataType.onAir == 'true') {
					this.setState({ titleOnAir: liveDataType.onAir });
				}
			});
		}
	}

	openRadio = (comp, measureData, debug) => {
		let compType = this.props?.live_bar_components[comp];
		let radioWindow = '';
		let radioLaunchPage = '';
		let baseUrl = compType.url;

		let lang = 'en';

		if (measureData) {
			MeasurementUtils.dispatchMeasurementCall(measureData.actionType, { args: measureData });
		}

		if (isMobile || isTablet) {
			window.open(baseUrl, '_blank');
		} else {
			var d = new Date();
			var t = d.getTime();

			radioLaunchPage += baseUrl + '?ts=' + t;
			radioLaunchPage += debug && debug.indexOf('debug') > -1 ? '&db=true' : '&db=false';
			radioLaunchPage += '&ref=' + document.location.host + document.location.pathname;
			radioLaunchPage += '&lang=' + lang;
			if (radioWindow.closed || radioWindow == '') {
				//consoleWindow = window.open(consolelaunchPage,"consoleWindow","width=510,height=" + mcWindowHeight + ",top=50,left=50")
				radioWindow = window.open(radioLaunchPage, 'radioWindow', 'width=1024,height=708,top=50,left=50');
				if (radioWindow.opener == null) radioWindow.opener = self;
			}
			radioWindow.focus();
		}
	};

	renderItems(comp, index) {
		let compType = this.props?.live_bar_components[comp];
		let btnClass = compType.onAir == 'true' ? 'onAir' : 'offAir';
		let disableLink = btnClass == 'offAir' ? true : false;
		let btnInnerHTML = compType.onAir == 'true' ? 'ON AIR' : 'OFF AIR';
		let longTitle = compType.title.length > 13 ? 'longTitle' : '';
		// logger.log('[LiveBar] renderComponent - comp: %o, compType: %o', comp, compType);
		if (compType.show_in_bar === 'show') {
			return (
				<div
					className={`bar-item ${btnClass}`}
					key={compType.title + index}
					style={{
						backgroundImage: `url(${
							compType?.photo ? compType.photo : '/assets/images/liveDrawer/Scores.png'
						})`,
					}}>
					{disableLink ? (
						<>
							<div className={`btn ${btnClass}`}>{btnInnerHTML}</div>
							<div className="title">{compType.title}</div>
						</>
					) : comp == 'radio' ||
					  comp == 'radio_' ||
					  comp == 'action_radio' ||
					  comp == 'action_radio_' ||
					  comp == 'action_audio' ||
					  comp == 'action_audio_' ? (
						<div
							className={`link-tile ${btnClass}`}
							onClick={() => {
								this.openRadio(comp, {
									item: compType,
									itemName: compType.name,
									actionType: 'liveBarClick',
								});
							}}
							onKeyDown={e => {
								if (e.key == ' ' || e.code == 'Space') {
									this.openRadio(comp);
								}
							}}
							tabIndex={0}>
							{btnClass == 'offAir' ? <div className={`btn ${btnClass}`}>{btnInnerHTML}</div> : null}
							<div className="title">
								{compType.onAir == 'true' && compType.icon == 'true' ? (
									<span className={`dot ${longTitle}`} />
								) : null}
								<div className="live-btn-text">{compType.title}</div>
								{btnClass == 'onAir' && compType.icon == 'true' ? (
									<i className="icon-arrow-right"></i>
								) : null}
							</div>
						</div>
					) : (
						<EventsLink
							to={compType.url}
							measureData={{
								item: compType,
								itemName: compType.name,
								actionType: 'liveBarClick',
							}}>
							{btnClass == 'offAir' ? <div className={`btn ${btnClass}`}>{btnInnerHTML}</div> : null}
							<div className="title">
								{compType.onAir == 'true' && compType.icon == 'true' ? (
									<span className={`dot ${longTitle}`} />
								) : null}
								<div className="live-btn-text">{compType.title}</div>
								{btnClass == 'onAir' && compType.icon == 'true' ? (
									<i className="icon-arrow-right"></i>
								) : null}
							</div>
						</EventsLink>
					)}
				</div>
			);
		} else {
			return null;
		}
	}

	showMenu() {
		this.props.toggleLiveMenu(true);
		// this.props.toggleMenu(false);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.liveBar, {
			pageName: 'Live Bar',
			display: !this.state.active,
		});
		if (this.state.menu_open) {
			this.props.toggleMenu(false);
		}
	}

	closeLiveBar() {
		this.props.toggleLiveMenu(false);
	}

	render() {
		// logger.log('[LiveBar] render - props: %o, state:%o', this.props, this.state);
		// logger.log('[LiveBar] render - props: %o', this.props.live_bar_components);
		this.props.live_open && window.innerWidth <= 767
			? document.body.classList.add('livebar-open-noscroll')
			: document.body.classList.remove('livebar-open-noscroll');
		return this.props.liveBarShow ? (
			<div id="uso-livebar">
				{/* desktop view for livebar */}
				{window.innerWidth > 767 ? (
					<div className="uso-livebar-wrapper">
						<div className="menu-title-bar">
							<div className={this.state.active ? 'menu-title' : 'menu-title'} tabIndex={0}>
								<span className="dot" />
								LIVE{' '}
							</div>
						</div>
						<div className="menu-bar-items desktop-show">
							{this.props?.live_bar_components
								? Object.keys(this.props.live_bar_components).map((type, index) => {
										return this.renderItems(type, index);
								  })
								: null}
						</div>
					</div>
				) : (
					// mobile view for livebar
					<div className="uso-livebar-wrapper">
						<div className="menu-title-bar" onClick={() => this.showMenu()}>
							<div className={this.state.active ? 'menu-title ' : 'menu-title'} tabIndex={0}>
								<span className="dot" />
								LIVE{' '}
							</div>
						</div>

						{this.props.live_open ? (
							<div className="menu-bar-items">
								<div className="livebar-mobile-show">
									<span className="dot" />
									LIVE
								</div>
								{this.props?.live_bar_components
									? Object.keys(this.props.live_bar_components).map((type, index) => {
											return this.renderItems(type, index);
									  })
									: null}
								<i className="icon-close live-bar" onClick={() => this.closeLiveBar()} />
							</div>
						) : null}
					</div>
				)}
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveBar);
