/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import StubBox from 'appdir/components/common-ui/StubBox';

// components
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import EventsLink from 'appdir/components/general/EventsLink';
import { getQSParams } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import { determinePlayerHighlightClass } from 'appdir/components/pages/SchedulePage/ScheduleUtils';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: PracticeScheduleWebview
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PracticeScheduleWebview'],
		stubs: state['Config'].stubPages,
		liveVideo: state?.['ActiveData']?.video,
		practiceSchedule: state?.['Config']?.practiceSchedule,
		appFavs: window.location.search ? getQSParams(window.location.search, 'filterPlayers').split(',') : [],
		appSearchedPlayer: window.location.search ? getQSParams(window.location.search, 'searchedPlayer') : null,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PracticeScheduleWebview.mount()),
});

class PracticeScheduleWebview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingError: false,
		};
		this.loadedJson = false; // local class var to keep track of last loaded json (used to detect if redirect is needed)
		this.firstLoad = true;

		this.appShowFavs = window.location.search ? getQSParams(window.location.search, 'showFavs') == 'true' : false;
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PracticeScheduleWebview] componentDidUpdate - this:%o', this);

		if (this.props.location.search) {
			/* favorites */
			let favPlayers = this.props.appFavs;

			if (favPlayers != this.state?.favorite_players) {
				this.setState(prevState => ({
					...prevState,
					favorite_players: favPlayers,
					favorite_filter: true,
				}));
			}
		}

		if (
			this.props.schedulePath &&
			!this.loadedJson &&
			!this.isStubbed()
		) {
			fetch(this.props.schedulePath)
				.then(data => {
					//get a list of players playing that day
					let playerArry = [];
					data?.Days.forEach(day => {
						day.courts.forEach(court => {
							court.practiceSlots.forEach(d1 => {
								d1.players.forEach(d2 => {
									let player1a = {
										firstName: d2.firstName,
										lastName: d2.lastName,
										id: d2.id,
									};
									playerArry.push(player1a);
								});
							});
						});
					});

					logger.log('[PracticeScheduleWebview] componentDidUpdate loading Days:%o, data:%o', data.Days, data);

					let playersPlaying = playerArry.filter(d => d.id !== null);

					this.setState({
						scheduleData: data,
						playersPlaying,
					});
					this.loadedJson = true;
				})
				.catch(error => {
					this.setState({
						loadingError: true,
					});
					logger.log('[PracticeScheduleWebview] componentDidUpdate error loading data: %o', error);
				});
		}

		if (
			(this.firstLoad || this.props?.match?.params?.practiceDay !== prevProps?.match?.params?.practiceDay) &&
			this.state?.scheduleData?.Days
		) {
			this.firstLoad = false;
			let daysArray = [];
			let displayDayData = '';
			let found = [];

			/**
			 * create a days array for the current day/next day links
			 **/
			this.state?.scheduleData?.Days.forEach(day => {
				daysArray.push({ displayDay: day.day, displayDate: day.displayDate });
			});

			/**
			 * for each item in the days array, determing if it is the selected
			 * day using the param from the url.  if it is the current day,
			 * set the current day's data in to displayDayData
			 **/
			daysArray.forEach((day, i) => {
				if (
					this.firstLoad ||
					(!this.props?.match?.params?.practiceDay && i == 0) ||
					this.props?.match?.params?.practiceDay == day.displayDay
				) {
					day.selectedPracticeDay = true;
					displayDayData = this.state?.scheduleData?.Days[i];
				} else {
					day.selectedPracticeDay = false;
				}
			});

			/**
			 * If there is a practicDay param, make sure that we have data for that
			 * day.  if not set value in state to 'daynotfound'
			 **/
			if (this.props?.match?.params?.practiceDay) {
				found = daysArray.filter(day => {
					return day.displayDay == this.props.match.params.practiceDay;
				});
				if (found.length == 0) {
					displayDayData = 'daynotfound';
				}
			}

			this.setState({
				practiceScheduleDays: daysArray,
				displayData: displayDayData,
			});
		}
	}

	isStubbed() {
		return this.props?.stubs?.practice ? (this.props.stubs.practice.stub == 'stub' ? true : false) : false;
	}

	hasLiveVideo(courtName) {
		let liveVideo = {};
		// logger.log('[PracticeScheduleWebview] courtName in:%o', courtName);
		if (this.props?.liveVideo?.practice) {
			Object.keys(this.props.liveVideo.practice).map(p => {
				// logger.log('[PracticeScheduleWebview] courtName %o', this.props.liveVideo.practice[p].name);
				if (this.props.liveVideo.practice[p].name == courtName) {
					liveVideo['live'] = this.props.liveVideo.practice[p].live;
					liveVideo['url'] = `/en_US/video/practice-courts/court-${p}.html`;
				}
			});
		}
		// logger.log('[PracticeScheduleWebview] liveVideo:%o', liveVideo);
		return liveVideo;
	}

	render() {
		//logger.log('[PracticeScheduleWebview] render - this:%o', this);

		if (this.isStubbed()) {
			return (
				<section className="wrapper practiceSchedule">
					<main role="main">
						<StubBox
							attributes={{
								title: this.props.stubs.practice.title,
								message: this.props.stubs.practice.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</main>
				</section>
			);
		} else if (this.state.loadingError) {
			return (
				<section className="wrapper practiceSchedule">
					<StubBox
						attributes={{
							title: this.props.stubs.practice.title,
							message: 'Practice schedule is not available.',
							basePicPath: this.props.basePicPath,
							subTitle: '',
						}}
					/>
				</section>
			);
		} else {
			/** data hasn't loaded yet */

			if (!this.loadedJson) {
				return (
					<section className="wrapper practiceSchedule">
						<div className="content-main">
							<LoadingIndicator />
						</div>
					</section>
				);
			} else {
				/** current day param is not valid */
				if (this.state.displayData == 'daynotfound' && this.props?.practiceSchedule?.notfoundText) {
					return (
							<section className="wrapper practiceSchedule">
								<h1 className="header">Practice Schedule</h1>
								<div className="error-text">
									<GenericError message={this.props?.practiceSchedule?.notfoundText} />
								</div>
							</section>
						);
				} else if (this.state?.displayData && this.state?.practiceScheduleDays) {
					/** current day param is valid - load data */
					if (this.state?.displayData?.courts.length > 0) {
						return (
							<section className="wrapper practiceSchedule webview">
								<div className="content-main">
									{/* <div className="contentTitle">Practice Schedule</div> */}
									<div className="schedule-date webview">
										<h3 className="full">{this.state.displayData.displayDate}</h3>
										<h3 className="mobile">{this.state.displayData.shortDate}</h3>
									</div>
									<div className="schedule-section">
									{this.state?.displayData?.courts.map(d => {
										let liveButton = this.hasLiveVideo(d.courtName);
										return (
											<div className="schedule-court" key={'schedule_' + d.courtName}>
												<div className="schedule-content">
													<div className="courtName">
														<div className="courtLive">
															<div
																className={
																	Object.keys(liveButton).length !== 0
																		? 'practice-schedule-court-name live'
																		: 'practice-schedule-court-name'
																}>
																{d.courtName}
															</div>
															<div
																className={
																	Object.keys(liveButton).length !== 0
																		? 'practice-schedule-buttons-cta'
																		: 'practice-schedule-buttons-cta offair'
																}>
																{Object.keys(liveButton).length !== 0 ? (
																	<EventsLink
																		to={liveButton.live ? liveButton.url : null}>
																		<div
																			key={'live_' + d.courtName}
																			className={`live-cta-button ${
																				liveButton.live ? 'live' : 'offair'
																			}`}>
																			{liveButton.live ? 'Watch Live' : 'Off Air'}
																		</div>
																	</EventsLink>
																) : null}
															</div>
														</div>
													</div>
													<div className="schedule-header">
														<div className="header-title-time">Time</div>
														<div className="header-title-players">Players</div>
													</div>
													{d.practiceSlots.map(i => {
														return (
															<div className="schedule-time" key={'time_' + i.slotTime}>
																<div className={`content-row `}>
																	<div className="time">{i.slotTime}</div>
																	<div className="players">
																		{i.players.map(j => {
																			let flagImg = this.props.flagImagePathSmall.replace(
																				'<code>',
																				j.nation
																			);
																			return (
																				<div
																					className={`info ${determinePlayerHighlightClass(
																						j.id,
																						this.state
																							?.favorite_players,
																						this.props
																							?.appSearchedPlayer,
																						this.appShowFavs
																					)}
																						${j.id}`}
																					key={i.slotTime + j.lastName}>
																					{j.nation ? (
																						<div className="flag">
																							<img src={flagImg} />
																						</div>
																					) : (
																						''
																					)}
																					<b>{j.lastName}</b>, {j.firstName}
																				</div>
																			);
																		})}
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										);
									})}
									</div>
								</div>
							</section>
							)
					}
				} else {
					return (
						<section className="wrapper practiceSchedule">
							<h1 className="header">Practice Schedule</h1>
							<LoadingIndicator />
						</section>
					);
				}
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeScheduleWebview);
