/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import StubBox from 'appdir/components/common-ui/StubBox';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import EventsLink from 'appdir/components/general/EventsLink';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import { determinePlayerHighlightClass } from 'appdir/components/pages/SchedulePage/ScheduleUtils';

/**
 * -----------------------------------------------------------------------------
 * React Component: PracticeSchedulePage
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PracticeSchedulePage'],
		stubs: state?.['Config']?.stubPages,
		practiceSchedule: state?.['Config']?.practiceSchedule,
		liveVideo: state?.['ActiveData']?.video,
		favorites: state?.['Controller']?.favorites,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PracticeSchedulePage.mount()),
});

class PracticeSchedulePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingError: false,
		};
		this.loadedJson = false;
		this.firstLoad = true;
	}

	componentDidMount() {
		// logger.log('[PracticeSchedulePage] componentDidMount');
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'PracticeSchedule',
		});

		//forces a state change so that componentDidUpdate is hit on page load
		this.setState({ mounted: true });
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PracticeSchedulePage] componentDidUpdate - this:%o', this);
		if (this.props?.schedulePath && !this.loadedJson && !this.isStubbed()) {
			this.loadedJson = true;

			fetch(this.props.schedulePath)
				.then(data => {
					logger.log('[PracticeSchedulePage] componentDidUpdate - data:%o', data);

					this.setState({
						scheduleData: data,
					});
				})
				.catch(error => {
					this.setState({
						loadingError: true,
					});
					logger.log('[PracticeSchedulePage] componentDidUpdate error loading data: %o', error);
				});
		}

		if (
			(this.firstLoad || this.props?.match?.params?.practiceDay !== prevProps?.match?.params?.practiceDay) &&
			this.state?.scheduleData?.Days
		) {
			this.firstLoad = false;
			let daysArray = [];
			let displayDayData = '';
			let found = [];

			/**
			 * create a days array for the current day/next day links
			 **/
			this.state?.scheduleData?.Days.forEach(day => {
				daysArray.push({ displayDay: day.day, displayDate: day.displayDate });
			});

			/**
			 * for each item in the days array, determing if it is the selected
			 * day using the param from the url.  if it is the current day,
			 * set the current day's data in to displayDayData
			 **/
			daysArray.forEach((day, i) => {
				if (
					this.firstLoad ||
					(!this.props?.match?.params?.practiceDay && i == 0) ||
					this.props?.match?.params?.practiceDay == day.displayDay
				) {
					day.selectedPracticeDay = true;
					displayDayData = this.state?.scheduleData?.Days[i];

					//get a list of players practicing on the selected day
					this.playersPlaying = [];

					displayDayData.courts.forEach(court => {
						court.practiceSlots.forEach(d1 => {
							d1.players.forEach(d2 => {
								let player1a = {
									firstName: d2.firstName,
									lastName: d2.lastName,
									id: d2.id,
								};
								this.playersPlaying.push(player1a);
							});
						});
					});

					this.playersPlaying = this.playersPlaying.filter(d => d.id !== null);
				} else {
					day.selectedPracticeDay = false;
				}
			});

			/**
			 * If there is a practicDay param, make sure that we have data for that
			 * day.  if not set value in state to 'daynotfound'
			 **/
			if (this.props?.match?.params?.practiceDay) {
				found = daysArray.filter(day => {
					return day.displayDay == this.props.match.params.practiceDay;
				});
				if (found.length == 0) {
					displayDayData = 'daynotfound';
				}
			}

			this.setState({
				practiceScheduleDays: daysArray,
				displayData: displayDayData,
				playersPlaying: this.playersPlaying,
			});
		}
	}
	isStubbed() {
		return this.props?.stubs?.practice ? (this.props.stubs.practice.stub == 'stub' ? true : false) : false;
	}

	hasLiveVideo(courtName) {
		let liveVideo = {};
		// logger.log('[PracticeSchedulePage] courtName in:%o', courtName);
		if (this.props?.liveVideo?.practice) {
			Object.keys(this.props.liveVideo.practice).map(p => {
				// logger.log('[PracticeSchedulePage] courtName %o', this.props.liveVideo.practice[p].name);
				if (this.props.liveVideo.practice[p].name == courtName) {
					liveVideo['live'] = this.props.liveVideo.practice[p].live;
					liveVideo['url'] = `/en_US/video/practice-courts/court-${p}.html`;
				}
			});
		}
		// logger.log('[PracticeSchedulePage] liveVideo:%o', liveVideo);
		return liveVideo;
	}

	onClick = value => {
		logger.log('[PracticeSchedulePage] onEnter - value:%o', value);
		let searchedPlayer = value;
		this.setState({ searchedPlayer }, () => {
			//scroll to player
			let el = document.querySelector(`.${searchedPlayer?.id}`);

			const stickyMenu = document.querySelector(`#uso-header.sticky`);
			const stickyFavorites = document.querySelector(`.favorites-details.sticky`);
			let offset = stickyFavorites ? 96 : stickyMenu ? 150 : 200;

			if (el) {
				el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
			}
		});
	};

	render() {
		// logger.log('[PracticeSchedulePage] render - this:%o', this);

		let header_attributes = {
			headerType: 'scores',
			title: 'Practice Schedule',
			metaTitle: 'Practice Schedule',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			canonicalLink: 'https://www.usopen.org/en_US/scores/schedule/practice/index.html',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: {
				link: 'http://www.rolex.com',
				title: 'Rolex',
				name: 'Rolex',
				imgClass: '',
				imgSrc: '/assets/images/logos/rolex_logo_sm.png',
				tagline: 'In partnership with',
			},
			title: 'Practice Schedule',
			page_header: 'Practice Schedule',
		};

		/** page is stubbed */
		if (this.isStubbed()) {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper practiceSchedule">
						<StubBox
							attributes={{
								title: header_attributes.title,
								message: this.props.stubs.practice.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.state.loadingError) {
			/** error loading data */
			return (
				<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper practiceSchedule">
						<StubBox
							attributes={{
								title: header_attributes.title,
								message: 'Practice schedule is not available.',
								basePicPath: this.props.basePicPath,
								subTitle: '',
							}}
						/>
					</section>
				</Template>
			);
		} else {
			/** current day param is not valid */
			if (this.state.displayData == 'daynotfound' && this.props?.practiceSchedule?.notfoundText) {
				return (
					<Template header={header_attributes} subHeader={subheader_attributes}>
						<section className="wrapper practiceSchedule">
							<PageSubMenu
								mode="Schedule"
								selected="Practice Schedule"
								showFavorites={true}
								playersData={this.state.playersPlaying}
								searchedPlayer={this.state.searchedPlayer?.id}
								practiceScheduleDays={this.state?.practiceScheduleDays}
								onClick={this.onClick}
							/>
							<div className="column-layout">
								<div className="no-schedule-section">
									<div className="daynotfound">{this.props?.practiceSchedule?.notfoundText}</div>
								</div>
							</div>
						</section>
					</Template>
				);
			} else if (this.state?.displayData && this.state?.practiceScheduleDays) {
				/** current day param is valid - load data */
				if (this.state?.displayData?.courts.length > 0) {
					return (
						<Template header={header_attributes} subHeader={subheader_attributes}>
							<section className="wrapper practiceSchedule">
								<PageSubMenu
									mode="Schedule"
									selected="Practice Schedule"
									showFavorites={true}
									playersData={this.state.playersPlaying}
									searchedPlayer={this.state.searchedPlayer?.id}
									practiceScheduleDays={this.state?.practiceScheduleDays}
									onClick={this.onClick}
								/>
								<div className="schedule-date">
									<h3 className="full">{this.state.displayData.displayDate}</h3>
									<h3 className="mobile">{this.state.displayData.shortDate}</h3>
								</div>
								<div className="schedule-section">
									{this.state?.displayData?.courts.map(d => {
										let liveButton = this.hasLiveVideo(d.courtName);
										return (
											<div className="schedule-court" key={'schedule_' + d.courtName}>
												<div className="schedule-content">
													<div className="courtName">
														<div className="courtLive">
															<div
																className={
																	Object.keys(liveButton).length !== 0
																		? 'practice-schedule-court-name live'
																		: 'practice-schedule-court-name'
																}>
																{d.courtName}
															</div>
															<div
																className={
																	Object.keys(liveButton).length !== 0
																		? 'practice-schedule-buttons-cta'
																		: 'practice-schedule-buttons-cta offair'
																}>
																{Object.keys(liveButton).length !== 0 ? (
																	<EventsLink
																		to={liveButton.live ? liveButton.url : null}>
																		<div
																			key={'live_' + d.courtName}
																			className={`live-cta-button ${
																				liveButton.live ? 'live' : 'offair'
																			}`}>
																			{liveButton.live ? 'Watch Live' : 'Off Air'}
																		</div>
																	</EventsLink>
																) : null}
															</div>
														</div>
													</div>
													<div className="schedule-header">
														<div className="header-title-time">Time</div>
														<div className="header-title-players">Players</div>
													</div>
													{d.practiceSlots.map(i => {
														return (
															<div className="schedule-time" key={'time_' + i.slotTime}>
																<div className={`content-row `}>
																	<div className="time">{i.slotTime}</div>
																	<div className="players">
																		{i.players.map(j => {
																			let flagImg = this.props.flagImagePathSmall.replace(
																				'<code>',
																				j.nation
																			);
																			return (
																				<div
																					className={`info ${determinePlayerHighlightClass(
																						j.id,
																						this.props?.favorites,
																						this.state.searchedPlayer
																					)}
																						${j.id}`}
																					key={i.slotTime + j.lastName}>
																					{j.nation ? (
																						<div className="flag">
																							<img src={flagImg} />
																						</div>
																					) : (
																						''
																					)}
																					<b>{j.lastName}</b>, {j.firstName}
																				</div>
																			);
																		})}
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										);
									})}
								</div>
							</section>
						</Template>
					);
				} else {
					return (
						<Template header={header_attributes} subHeader={subheader_attributes}>
							<section className="wrapper practiceSchedule">
								<h1 className="header">Practice Schedule</h1>
								<div className="error-text">
									<GenericError message="There are currently no practice sessions scheduled." />
								</div>
							</section>
						</Template>
					);
				}
			} else {
				/** loading indicator */
				return (
					<Template header={header_attributes} subHeader={subheader_attributes}>
						<section className="wrapper practiceSchedule">
							<h1 className="header">Practice Schedule</h1>
							<LoadingIndicator />
						</section>
					</Template>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeSchedulePage);
