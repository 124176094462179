/**
 *
 *  getSearchedPlayers(player, searchedTerms)
 *  getFilteredPlayers(player, playersFilter)
 */

// Players Related Util
export const getSearchedPlayers = function(player, searchTerms) {
	return (
		player['first_name'].toLowerCase().indexOf(searchTerms) == 0 ||
		player['last_name'].toLowerCase().indexOf(searchTerms) == 0
	);
};

export const getFilteredPlayers = function(player, playersFilter) {
	switch (playersFilter) {
		case 'all':
			return player['all'];

		case 'amateurs':
			return player['amateur'];

		case 'pros':
			return !player['amateur'];

		default: {
			return player['all'];
		}
	}
};
