import React, { Component } from 'react';
import { connect } from 'react-redux';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';

import deps from 'dependencies';

import { eventDef, getCorrectEventId } from 'appdir/components/pages/DrawsPage/DrawsUtils';

const mapStateToProps = (state, props) => {
	return Object.assign(
		{},
		state['DrawsPage'],
		state['Config']?.scoringConfig,
		{ otherData: state['Config']?.otherData },
		props
	);
};

const mapDispatchToProps = (dispatch, props) => ({
	update: data => dispatch(deps.actions.DrawsPage.update(data)),
	changeView: data => dispatch(deps.actions.DrawsPage.changeView(data)),
});

class DrawsSub extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		//logger.log('[DrawsSub] constructor state:%o', this);
	}

	componentDidMount() {
		//logger.log('[DrawsSub] componentDidMount state:%o', this.props);
	}

	componentDidUpdate(prevState) {
		//logger.log('[DrawsSub] componentDidUpdate this.props:%o', this.props);
	}

	onRoundSelect(selectedRound, view) {
		logger.log('[DrawsSub] onRoundSelect selectedRound:%o', selectedRound, view);

		let selectedIndex = selectedRound; // selected index will always equal round since there's no top/bottom
		this.props.update({
			eventRound: {
				...this.props.eventRound,
				selectedIndex,
				selectedRound,
				clicked: true,
			},
			view,
		});

		// MeasurementUtils.dispatchMeasurementCall('Draws', {
		// 	'Round Selector': view == 'bracket' ? 'Bracket View' : selectedRound,
		// 	webview: window.webview ? true : false,
		// });

		if (window.webview) {
			let contextData = [
				{ 'Round Selector': view == 'bracket' ? 'Bracket View' : `Round ${selectedRound}` },
				{ webview: true },
			];
			let metricsVal = [
				'Metrics',
				'Draws',
				'Round Selector',
				`Round ${view == 'bracket' ? 'Bracket View' : selectedRound}`,
			];
			measureInApp(metricsVal, contextData);
		} else {
			MeasurementUtils.dispatchMeasurementCall('Draws', {
				'Round Selector': view == 'bracket' ? 'Bracket View' : selectedRound,
				webview: false,
			});
		}

		//measureApp('Draws', 'Round Selector', 'Round ' + selectedRound );
	}

	getBracketLabel(round, labelIndex) {
		//logger.log('[DrawsSub] function passed in value:%o', round);
		let labelArray = this.props.roundNames;

		for (let i = 0; i < labelArray.length; i++) {
			if (labelArray[i].roundCode == round) {
				let roundNumber = labelIndex + 1;

				// logger.log(
				// 	'[DrawsSub] getBracketLabel round:%o, roundCode:%o, roundNumber:%o, selectedRound',
				// 	round,
				// 	labelArray[i].roundCode,
				// 	roundNumber,
				// 	this.props.eventRound.selectedRound
				// );

				return (
					// <div className="item">R</div>
					<div
						key={i}
						className={`round${labelArray[i].roundCode} item ${
							this.props.eventRound.selectedRound == roundNumber && this.props.view == 'round'
								? 'selected'
								: ''
						}`}
						onClick={() => {
							this.onRoundSelect(roundNumber, 'round');
						}}>
						{/* <span className="long">{labelArray[i].roundNameShort}</span> */}
						<div className="short">
							<span className="item-prefix">RD</span>
							<br />
							{labelArray[i].roundNameShort.replace('R', '')}
						</div>
					</div>
				);
			}
		}
	}

	displayBracketColumn(type, index) {
		let round = index + 1;
		let bracketName = round.toString();
		//logger.log('[DrawsSub] displayBracketColumn type, round:%o', type, round);
		switch (type) {
			// 12 member top-bottom bracket
			case 'a':
				return (
					<div key={type} className="bracket-round s4l12">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'top', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'top' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'top', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'top' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'bottom', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'bottom' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'bottom', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'bottom' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				this.count++;
				break;
			// 10 member top-bottom bracket
			case 'b':
				return (
					<div key={type} className="bracket-round s2l10">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'top', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'top' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
						<div
							onClick={() => {
								this.onBracketSelect(bracketName + 'bottom', round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName + 'bottom' ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 10 member single bracket
			case 'c':
				return (
					<div key={type} className="bracket-round s1l10">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 8 member single bracket
			case 'd':
				return (
					<div key={type} className="bracket-round s1l8">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 4 member single bracket
			case 'e':
				return (
					<div key={type} className="bracket-round s1l4">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 2 member single bracket
			case 'f':
				return (
					<div key={type} className="bracket-round s1l2">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
							<div className="line" />
						</div>
					</div>
				);
				break;
			// 1 member bracket
			case 'g':
				return (
					<div key={type} className="bracket-round s1l1">
						<div
							onClick={() => {
								this.onBracketSelect(bracketName, round);
							}}
							className={`sub-round-container ${
								this.props.eventRound['selectedIndex'] == bracketName ? 'clicked' : ''
							}`}>
							<div className="line" />
						</div>
					</div>
				);
				break;

			default:
				return null;

				break;
		}
	}

	onBracketSelect(index, round) {
		logger.log('[DrawsSub] onBracketSelect - index:%o, round:%o, this:%o', index, round, this);

		//measureApp('Draws', 'Round Selector', 'Round ' + round );

		this.props.update({
			eventRound: {
				...this.props.eventRound,
				selectedIndex: index,
				selectedRound: round,
				clicked: true,
			},
		});
	}
	onChangeView(view) {
		this.props.changeView({ view });
	}

	render() {
		//logger.log('[DrawsSub] render - this:%o, eventDef:%o', this, eventDef);
		if (this.props.eventRound) {
			let correctedEvent = getCorrectEventId(
				this.props.filters.event,
				this?.props?.data?.drawsEventData?.totalRounds
			);
			let labelDisp = eventDef[correctedEvent] ? eventDef[correctedEvent].roundNum : null;
			let bracketDisp = eventDef[correctedEvent] ? eventDef[correctedEvent].roundDisp : null;
			//logger.log('[DrawsSub] render - labelDisp:%o, bracketDisp:%o', labelDisp, bracketDisp);

			if (labelDisp && bracketDisp) {
				return (
					<div className="draws">
						{this?.props?.otherData?.draws?.showBracketView ? (
							<>
								<div className="bracket-wrapper">
									<div
										className={`item ${this.props.view == 'bracket' ? 'selected' : ''}`}
										onClick={() => this.onRoundSelect(1, 'bracket')}>
										Bracket
										<br />
										View
									</div>
								</div>
								<div className="separator"></div>
							</>
						) : null}

						<div className="round-wrapper">
							{Object.keys(labelDisp).map((round, i) => {
								return this.getBracketLabel(round, i);
							})}
						</div>
					</div>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
		// if (this.props.eventRound) {
		// 	let correctedEvent = getCorrectEventId(this.props.event, this.props.totalRounds);

		// 	let labelDisp = eventDef[correctedEvent] ? eventDef[correctedEvent].roundNum : null;
		// 	let bracketDisp = eventDef[correctedEvent] ? eventDef[correctedEvent].roundDisp : null;

		// 	logger.log('[DrawsSub] render - values:%o', labelDisp, bracketDisp);

		// 	if (labelDisp && bracketDisp) {
		// 		return (
		// 			<div data-dna="draws-nav-base" className="column-layout">
		// 				<div className="draws-nav">
		// 					<div className="draws-nav-column-titles">
		// 						<div className="title-buffer" />
		// 						{Object.keys(labelDisp).map((round, i) => {
		// 							return this.getBracketLabel(round, i);
		// 						})}
		// 						<div className="title-buffer" />
		// 					</div>
		// 					<div className="draws-nav-chart">
		// 						<i
		// 							onClick={() => {
		// 								this.onArrowSelect(0);
		// 							}}
		// 							onKeyPress={() => (event.key === 'Enter' ? this.onArrowSelect(0) : null)}
		// 							tabIndex={0}
		// 							className="icon-left-arrow"
		// 						/>
		// 						<div className="draws-nav-chart-bracket">
		// 							<div className="bracket-buffer" />
		// 							{bracketDisp.map((letter, i) => {
		// 								return this.displayBracketColumn(letter, i);
		// 							})}
		// 							<div className="bracket-buffer" />
		// 						</div>
		// 						<i
		// 							onClick={() => {
		// 								this.onArrowSelect(1);
		// 							}}
		// 							onKeyPress={() => (event.key === 'Enter' ? this.onArrowSelect(1) : null)}
		// 							tabIndex={0}
		// 							className="icon-right-arrow"
		// 						/>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		);
		// 	} else {
		// 		return null;
		// 	}
		// } else {
		// 	return null;
		// }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsSub);
