import RadioPlayer from './index';
import deps from 'dependencies';

export default {
	path: [
		'/:lang/radio/:type(radio|actionaudio|watch|listen).html',
		'/webview/:lang/radio/:type(radio|actionaudio|watch|listen).html',
		'/:lang/social/radio/radio.html',
	],
	exact: true,
	component: RadioPlayer,
	load: () => deps.actions.RadioPlayer.mount(),
};
