import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { fetch } from 'appdir/components/general/Util';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let mapMarkersPath = Config.otherData.mapMarkers;
			fetch([mapMarkersPath]).then(data => {
				//logger.log('[GroundsMap] mount - data:%o', data);
				dispatch({
					type: deps.actionTypes.GROUNDS_MAP_MOUNT,
					updated: moment().toISOString(),
					status: 'loaded',
					data: { mapMarkers: data },
				});
			});
		});
	},
};
