import ContentPage from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:prefix(preview)?/:lang(en_US)/news/:category(interviews)/:date/:id.html',
		exact: true,
		component: ContentPage,
		onRouteChange: () => {},
		load: params => deps.actions.ContentPage.mount(params),
	},
	{
		path: '/:prefix(preview)?/:lang(en_US)/news/:category(articles)/:date?/:id.html',
		exact: true,
		component: ContentPage,
		onRouteChange: () => {},
		load: params => deps.actions.ContentPage.mount(params),
	},
];
