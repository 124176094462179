/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * React Component: ModalOverlay
 * -----------------------------------------------------------------------------
 */
class ModalOverlay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// ...this.props,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[ModalOverlay] getDerivedStateFromProps - nextProps:%o', nextProps);

		let newState = {
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	componentDidMount() {
		if (this.props.onClose) {
			window.addEventListener('keydown', this.listenKeyboard, true);
		}
	}

	componentWillUnmount() {
		if (this.props.onClose) {
			window.removeEventListener('keydown', this.listenKeyboard, true);
		}
	}

	listenKeyboard = event => {
		if (event.key === 'Escape' || event.keyCode === 27) {
			this.props.onClose();
		}
	};

	onOverlayClick = () => {
		this.props.onClose();
	};

	onDialogClick = event => {
		event.stopPropagation();
	};

	render() {
		logger.log('[ModalOverlay] render - this:%o', this);

		return (
			<div className={this?.props?.containerClass ? this?.props?.containerClass : ''}>
				<div className="modal-overlay-div" />
				<div className="modal-content-div" onClick={this.onOverlayClick}>
					<div
						className={`modal-dialog-div${this.props.modalClass ? ' ' + this.props.modalClass : ''}`}
						onClick={this.onDialogClick}>
						{this.props.children}
						<div className="close-icon" onClick={this.onOverlayClick}>
							<i className="icon-close" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ModalOverlay;
