import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.SCORE_MANAGER_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[ScoreManager] actionTypes.SCORE_MANAGER_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.SCORE_MANAGER_UPDATE_SCORES_DATA:
			// logger.log("[ScoreManager] actionTypes.SCORE_MANAGER_UPDATE_SCORES_DATA - action.data:%o", action.data);

			let matchIdData = [];
			
			if(action?.data) {
				Object.keys(action.data).map((key, i) => {
					matchIdData.push(action.data[key].match_id);
				});
			}

			newState = {
				...state,
				status: 'loaded',
				liveMatches: action.data,
				liveMatchIds: matchIdData,
			};
			// logger.log('[ScoreManager] actionTypes.SCORE_MANAGER_UPDATE_SCORES_DATA - newState:%o', newState);
			return newState;

		case deps.actionTypes.SCORE_MANAGER_SET_CURRENT_MATCH:
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_CURRENT_MATCH - data:%o", action.data;
			newState = {
				...state,
				currentMatch: action.data,
				statMatch: {
					loaded: true,
					...state.statMatch,
					...action.data,
					stats: {
						...state.statMatch.stats,
						base_stats: action.data.base_stats,
						serve_stats: action.data.serve_stats,
						return_stats: action.data.return_stats,
						rally_stats: action.data.rally_stats,
						dss: action.data.dss,
						serves: action.data.serves,
						distance_run: action.data.distance_run,
						keys: action.data.keys,
					},
				},
			};
			// logger.log('[ScoreManager] SCORE_MANAGER_SET_CURRENT_MATCH - newState:%o', newState);
			return newState;

		case deps.actionTypes.SCORE_MANAGER_SET_COMPLETED_STATMATCH:
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_COMPLETED_STATMATCH - data:%o", action.data);
			newState = {
				...state,
				statMatch: {
					loaded: true,
					...state.statMatch,
					...action.data,
					stats: {
						...state.statMatch.stats,
						base_stats: action.data.base_stats,
						serve_stats: action.data.serve_stats,
						return_stats: action.data.return_stats,
						rally_stats: action.data.rally_stats,
						dss: action.data.dss,
						serves: action.data.serves,
						distance_run: action.data.distance_run,
						keys: action.data.keys,
					},
				},
			};
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_COMPLETED_STATMATCH - newState:%o", newState);
			return newState;

		case deps.actionTypes.SCORE_MANAGER_SET_LIVE_STATMATCH:
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_LIVE_STATMATCH - data:%o", action.data;
			newState = {
				...state,
				statMatch: {
					loaded: true,
					...state.statMatch,
					...action.data,
				},
			};
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_LIVE_STATMATCH - newState:%o", newState);
			return newState;

		case deps.actionTypes.SCORE_MANAGER_SET_STATMATCH_STATS:
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_STATMATCH_STATS - data:%o", action.data);
			newState = {
				...state,
				statMatch: {
					...state.statMatch,
					stats: {
						...state.statMatch.stats,
						base_stats: action.data.base_stats,
						serve_stats: action.data.serve_stats,
						return_stats: action.data.return_stats,
						rally_stats: action.data.rally_stats,
						dss: action.data.dss,
						serves: action.data.serves,
						distance_run: action.data.distance_run,
						keys: action.data.keys,
					},
				},
			};
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_STATMATCH_STATS - newState:%o", newState);
			return newState;

		case deps.actionTypes.SCORE_MANAGER_SET_STATMATCH_HISTORY:
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_STATMATCH_HISTORY - data:%o", action.data);
			newState = {
				...state,
				statMatch: {
					...state.statMatch,
					pointHistory: action.data,
				},
			};
			// logger.log("[ScoreManager] SCORE_MANAGER_SET_STATMATCH_HISTORY - newState:%o", newState);
			return newState;

		case deps.actionTypes.SCORE_MANAGER_CLEAR_STATMATCH:
			// logger.log("[ScoreManager] SCORE_MANAGER_CLEAR_STATMATCH - data:%o", action.data);
			newState = {
				...state,
				currentMatch: null,
				statMatch: {
					loaded: false,
					pointHistory: [],
					stats: {},
				},
			};
			logger.log('[ScoreManager] SCORE_MANAGER_CLEAR_STATMATCH - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
