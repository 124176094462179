import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[HaveYourSay] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log('[HaveYourSay] action.mount - data: %o', data);

			let data = {
				refreshRate:
					Config.otherData.activeData.rateSec > 5 ? Config.otherData.activeData.rateSec * 1000 : 5000,
			};
			logger.log('[HaveYourSay] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.HAVEYOURSAY_LOAD, data: data });
		});
	},

	sendVote: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.HAVEYOURSAY_VOTE, data: data });
	},

	unmount: () => dispatch => {
		logger.log('[HaveYourSay] unmount');
		dispatch({ type: deps.actionTypes.HAVEYOURSAY_UNMOUNT, data: null });
	},
};
