import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import ButtonGeneral from 'components/cms/ButtonGeneral';

/**
 * ---------------------------------------
 * Functional Component: HomeHero
 *
 * Supports the Hero content main section from
 * previous year
 *
 * Props
 *  @param {Object} EventsWindow
 *                                      this also makes the list to display in two columns
 *  @param {Object} <cmsdata> --------- content
 *
 * ---------------------------------------
 */

const HomeHeroStandalone = props => {
	logger.log('[HomeHero] props:%o', props.data);
	let HomeHeroContent = props.data.content;
	let { EventsWindow } = props.data;

	let measureData = Object.assign({}, props.data);
	delete measureData.data?.content;

	let isCenterCol =
		props.data?.desktopPosition?.toLowerCase() !== 'sideleft' &&
		props.data?.desktopPosition?.toLowerCase() !== 'sideright';

	const getHeroClassNames = () => {
		return cn({
			mainHero: true,
			dark: HomeHeroContent?.theme === 'dark',
		});
	};

	if (!isEmpty(HomeHeroContent)) {
		logger.log('[HomeHeroStories] renderHeroStory - heroMain: %o', HomeHeroContent);

		let mainInfo = HomeHeroContent.items[0];
		let playClass = 'hide';
		var minute = {};

		if (mainInfo.type) {
			playClass = mainInfo.type === 'video' ? '' : 'hide';

			if (mainInfo.type === 'video' && mainInfo.shareLink) {
				minute['share-link'] = mainInfo.shareLink;
			}
		}

		const getMeasureData = which => {
			let metricsData = {
				...mainInfo,
				...measureData,
				itemTitle: mainInfo.title,
				itemId: mainInfo.id,
				itemType: mainInfo.type,
				actionType: 'homePageClick',
				which: which,
			};
			return metricsData;
		};

		return (
			<div className="homeHeroStandalone">
				<div className={getHeroClassNames()}>
					<EventsLink
						to={mainInfo.link}
						measureData={{
							...mainInfo,
							...measureData,
							itemTitle: mainInfo.title,
							itemId: mainInfo.id,
							itemType: mainInfo.type,
							actionType: 'homePageClick',
							which: 'hero_photo',
						}}>
						<div className="storyImg" {...minute}>
							<div className="image-wrapper">
								<img src={mainInfo.photo} />
							</div>
							<div className={`playBtn ${playClass}`}>&nbsp;</div>
						</div>
					</EventsLink>
					<div className="storySect">
						<div className="title">
							<EventsLink to={mainInfo.link} measureData={getMeasureData('hero_title')}>
								{mainInfo.title}
							</EventsLink>
						</div>
						{mainInfo?.author || mainInfo?.date ? (
							<div className="articleInfo">
								<div className="date">
									{mainInfo.author ? mainInfo.author : ''}{' '}
									{mainInfo.date ? ' / ' + mainInfo.date : ''}
								</div>
							</div>
						) : null}

						<div className="desc">
							<div className="text">{ReactHtmlParser(mainInfo.abstract)}</div>
							{/** in case there is empty button text in the feed,
							 *  set Read More to display
							 */
							!isEmpty(HomeHeroContent?.button) ? (
								<ButtonGeneral
									data={{
										...HomeHeroContent.button,
										style: 'uso-button uppercase dkerBlue',
										title: 'Read More',
										measureData: getMeasureData('hero_action'),
									}}
								/>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default HomeHeroStandalone;
