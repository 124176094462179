/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import { Rnd } from 'react-rnd';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
/**
 * -----------------------------------------------------------------------------
 * React Component: PipController
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state['LiveVideo'],
	Router: state['Router'],
	EventsWindow: state['WindowSize'].EventsWindow,
	...props,
});

const mapDispatchToProps = dispatch => ({
	closePip: () => dispatch(deps.actions.LiveVideo.closePip()),
});

class PipController extends Component {
	targetElement = null;

	constructor(props) {
		super(props);
		this.state = {
			//...this.props,
			width: '300px',
			height: '180px',
			x: 0,
			y: 0,
		};
		this.rndRef = React.createRef();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.videoPip && this.props.videoPip != prevProps.videoPip) {
			// logger.log('[PipController] componentDidUpdate EventsWindow:%o', this.props.EventsWindow);
			// logger.log('[PipController] componentDidUpdate this.state.height:%o', this.state.height);
			let width = parseInt(this.state.width.replace('px', ''), 10);
			let height = parseInt(this.state.height.replace('px', ''), 10);
			let x = this.props.EventsWindow.windowWidth - width - 150;
			let y = this.props.EventsWindow.windowHeight - height + window.scrollY - 60;
			this.setState({ x, y });
		}
	}

	componentDidMount() {}

	componentWillUnmount() {}

	render() {
		// logger.log('[PipController] render this - %o', this);
		let pipped = this.props.videoPip;
		let width = parseInt(this.state.width.replace('px', ''), 10);
		return (
			<>
				{pipped ? (
					<Rnd
						size={{ width: this.state.width, height: this.state.height }}
						position={{ x: this.state.x, y: this.state.y }}
						onDragStop={(e, d) => {
							this.setState({ x: d.x, y: d.y });
						}}
						style={{ position: 'fixed' }}
						onResize={(e, direction, ref, delta, position) => {
							this.setState({
								width: ref.style.width,
								height: ref.style.height,
								...position,
							});
						}}
						lockAspectRatio={true}
						minWidth={250}
						ref={this.rndRef}
						cancel=".close-wrapper, .react-ecp">
						<div
							className={`pip-window ${
								parseInt(this.state.width.replace('px', ''), 10) < 360 ? 'small' : ''
							}`}>
							<div
								className="close-wrapper"
								onClick={() => this.props.closePip()}
								onKeyPress={e => {
									if (e.key == ' ' || e.code == 'Space') {
										this.props.closePip();
									}
								}}
								tabIndex={0}>
								<i className="icon-close" />
							</div>
							<ErrorBoundary message="Unable to display video">
								<VideoPlayer
									attributes={{
										videoData: {
											...this.props.pipData.videoConfig,
										},
										shouldAutoplay: true,
										toggleAutoPlay: true,
										id: this.props.pipData.videoConfig.id,
										reset: false,
										showPanelIcon: false,
									}}
								/>
							</ErrorBoundary>
						</div>
					</Rnd>
				) : null}
			</>
		);
	}
}

PipController.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PipController);
