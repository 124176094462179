/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';
import op from 'object-path';

import { fetchPromiseAll } from 'appdir/components/general/Util';
import { hasInsights } from 'components/common-ui/PowerRanking/Utils';

import ErrorBoundary from 'components/general/ErrorBoundary';
import PowerRanking from 'appdir/components/pages/MatchInsights/elements/PowerRanking';
/**
 * -----------------------------------------------------------------------------
 * Functional Component: MatchInsightsCard
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsightsCard'],
		sharedDataConfig: state['Config'].sharedDataConfig,
		matchInsightsMatches: state['CommonData']['matchInsightsMatches'],
		configScoringData: state['Config'].scoringData,
		configOtherData: state['Config'].otherData,
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchInsightsCard.mount()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
	unmount: () => dispatch(deps.actions.MatchInsightsCard.unmount()),
});

class MatchInsightsCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.sharedDataLoaded = false;
		this.insightsDataLoaded = false;
	}

	componentDidMount() {
		logger.log('[MatchInsightsCard] -  componentDidMount');
		this.matchId = this.props?.matchId;

		// this.matchId = "1227"; // for testing
		this.props.mount();
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[MatchInsightsCard] componentDidUpdate - this:%o', this);

		/** Pull shared matches json file to get all the available mathces ids */
		if (prevProps.status !== this.props.status && this.props.status == 'loaded' && !this.sharedDataLoaded) {
			// logger.log('[MatchInsightsCard] componentDidUpdate  - this.props:%o', this.props);

			// load all the available MI matches list to check if the selected MatchId exists
			this.props
				.checkExpired(this.props.sharedDataConfig['matchInsightsMatches'])
				.then(response => {
					this.sharedDataLoaded = true;
					// logger.log('[MatchInsightsCard] componentDidUpdate matchInsightsMatches - response:%o', response);
					if (response.status == 'expired') {
						this.props.update(this.props.sharedDataConfig['matchInsightsMatches']);
					}
				})
				.catch(error => {
					this.sharedDataLoaded = false;
					logger.error('[MatchInsightsCard] componentDidUpdate matchInsightsMatches - :o', error);
				});
		}

		/** data pulling if match exists */

		let miList = op.get(this.props, `matchInsightsMatches.result.matches`);
		let { configOtherData, configScoringData } = this.props;

		if (hasInsights(miList, this.matchId) && !this.insightsDataLoaded) {
			let matchInsightsDataPath = configScoringData.matchInsights.replace('<matchId>', this.matchId);
			let powerIndexMatchupPath = configOtherData.innovations.powerIndexMatchup.replace(
				'<matchId>',
				this.matchId
			);

			let statsBasicPath = configScoringData.matchStat.path.replace('<matchId>', this.matchId);

			this.insightsDataLoaded = true;

			fetchPromiseAll([matchInsightsDataPath, powerIndexMatchupPath, statsBasicPath])
				.then(
					axios.spread((matchInsightsDataResults, powerIndexMatchupResults, statsBasicResults) => {
						let statsResults;
						let isCompleted,
							isThereStat = false;

						if (statsBasicResults?.status !== 'error') {
							statsResults = statsBasicResults.matches;
							isThereStat = statsResults?.length > 0;
						}

						if (isThereStat) {
							isCompleted = statsResults[0].team1.won || statsResults[0].team2.won ? true : false;
						}

						this.setState({
							matchInsightsData:
								matchInsightsDataResults?.status !== 'error'
									? matchInsightsDataResults.matches[0]
									: matchInsightsDataResults?.status,
							powerIndexMatchup: powerIndexMatchupResults,
							statsData: isThereStat ? statsResults[0] : [],
						});
					})
				)
				.catch(error => {
					logger.error('[MatchInsightsCard] componentDidUpdate error:%o', error);
					this.insightsDataLoaded = true;
					this.setState({
						matchInsightsData: 'error',
						powerIndexMatchup: 'error',
						statsData: 'error',
					});
				});
		}
	}

	render() {
		logger.log('[MatchInsightsCard] render - this:%o', this);

		if (this.state.matchInsightsData && this.state.matchInsightsData) {
			this.teams = [this.state?.matchInsightsData['team1'], this.state?.matchInsightsData['team2']];
		}

		return this.state.matchInsightsData &&
			this.state.matchInsightsData !== 'error' &&
			this.state.powerIndexMatchup &&
			this.state.powerIndexMatchup !== 'error' ? (
			<ErrorBoundary message="">
				<div className={` `}>
					<PowerRanking
						matchData={this.state.matchInsightsData}
						teams={this.teams}
						powerIndexMatchupData={this.state.powerIndexMatchup}
						imgPath={this.props.configOtherData.playerProfileImagePath}
						flagPath={this.props.configOtherData.flagImagePathSmall}
						infoIcon={false}
						showWhyButton={false}
						type="news"
					/>
				</div>
			</ErrorBoundary>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsightsCard);
