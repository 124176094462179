/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import EventsLink from 'appdir/components/general/EventsLink';
import { Carousel } from 'react-responsive-carousel';
import VideoHero from 'appdir/components/general/VideoHero';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: HomeHero
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['HomeHero'],
		EventsWindow: state['WindowSize'].EventsWindow,
		Router: state['Router'],
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.HomeHero.mount()),
});

class HomeHero extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: undefined,
			height: undefined,
			currVideoIndex: 0,
			playVideo: false,
			showDots: true,
			viewedIndex: 0,
		};

		this.viewedIndex;
		this.onResize = this.onResize.bind(this);
		this.playVideoHeader = this.playVideoHeader.bind(this);
		this.onPlay = this.onPlay.bind(this);
		this.onPause = this.onPause.bind(this);
		this.imageConfigLoaded = false;
	}

	componentDidMount() {
		logger.log('[HomeHero] - componentDidMount this:%o', this);
		if (this.state.hasOwnProperty('mount')) {
			this.props.mount();
		}
		//window.addEventListener('resize', throttle(this.onResize.bind(this), 100, { trailing: true }), false);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[HomeHero] - componentDidUpdate state:%o', this.state);
		if (!this.state.heroData) {
			logger.log('[HomeHero] - componentDidUpdate no heroData state:%o', this.state);
			//this.imageConfigLoaded == false;
		}
		if (!this.imageConfigLoaded && this.props.heroPath && !this.state.heroData) {
			logger.log('[HomeHero] - componentDidUpdate need to fetch data state:%o', this.state);
			let heroPath = this.props.heroPath;
			if (this.state.preview) {
				heroPath = this.props.heroPath.replace('en_US/', 'preview/en_US/');
			}
			// logger.log('[Home] componentDidUpdate heroPath:%o', heroPath);
			this.imageConfigLoaded = true;
			fetch(heroPath)
				.then(result => {
					this.setState({
						heroData: result,
					});
				})
				.catch(error => {
					logger.error('[Home] componentDidUpdate error:%o', error);
				});
		}
	}

	onResize(width, height) {
		// logger.log('[HomeHero] - onResize');
		if (this.state.height != height) {
			this.setState({
				width,
				height,
			});
		}
	}

	getHeroHeight() {
		let node = this.refs.tag;
		let { heroData } = this.state;
		// logger.log('[HomeHero] getHeroHeight node:%o', node);
		if (
			(node && heroData.position == 'center') ||
			(node &&
				(heroData.position == 'left' || heroData.position == 'right') &&
				this.props.EventsWindow.windowSize !== 'desktop')
		) {
			let nodeSize = node.getBoundingClientRect();
			// logger.log('[HomeHero] getHeroHeight nodeSize:%o', nodeSize);
			// logger.log('[HomeHero] getHeroHeight nodeSize.height:%o', nodeSize.height / 2);
			return `calc(100% - ${nodeSize.height / 2}px)`;
		} else {
			// logger.log('[HomeHero] getHeroHeight state.height:%o', this.state.height / 2);
			return `calc(100% - ${0}px)`;
		}
	}

	getTagPosition() {
		let node = this.refs.tag; //assuming this as your target node
		let { heroData } = this.state;
		// logger.log('[HomeHero] getTagPosition node:%o', node);
		if (
			(node && heroData.position == 'center') ||
			(node &&
				(heroData.position == 'left' || heroData.position == 'right') &&
				this.props.EventsWindow.windowSize !== 'desktop')
		) {
			let nodeSize = node.getBoundingClientRect();
			logger.log('[HomeHero] getTagPosition nodeSize:%o', nodeSize);
			return `${0 - (nodeSize.height / 2 + 7.5)}px`;
		} else {
			return '0px';
		}
	}

	getFontSize() {
		// logger.log('[HomeHero] getFontSize state:%o', this.state);
		if (this.props.EventsWindow) {
			if (this.props.EventsWindow.windowSize == 'desktop') {
				if (this.state.height > 34) {
					return '30px';
				}
			} else {
				if (this.state.height > 21) {
					return '18px';
				}
			}
		}
	}

	getImageConfig() {
		let { heroData } = this.state;
		return {
			image: {
				alt: '',
				className: 'hero-bkg',
				background: true,
				style: { height: this.getHeroHeight() },
			},
			imageList: heroData.image,
		};
	}

	onPlay() {
		// logger.log('[HomeHero] onPlay');
		this.setState({ showDots: false, viewedIndex: this.viewedIndex });
	}

	onPause() {
		// logger.log('[HomeHero] onPause');
		this.setState({ showDots: true, viewedIndex: this.viewedIndex });
	}

	playNext() {
		if (this.nextVid !== null) {
		}
	}

	playVideoHeader() {
		if (!this.state.playVideo)
			this.setState(prevState => {
				return {
					...prevState,
					playVideo: true,
				};
			});
	}

	renderSlidePanel(data, index) {
		// logger.log('[HomeHero] renderSlidePanel - data:%o', data);
		let playVideo = this.state.playVideo;
		return (
			<Fragment>
				{data.video && this.state.videoConfig ? (
					<div
						className="panel slick-slider"
						style={{
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
						}}>
						<div className="imgCrop_container">
							<div className="single-image">
								<VideoHero
									data={{
										...data,
										playVideo,
										videoConfig: this.state.videoConfig,
									}}
									onPlay={this.onPlay}
									onPause={this.onPause}
								/>
							</div>
						</div>
						<div className={`bx-caption`}>
							{data.related_content ? (
								<div className="videoNum">{data.related_content.length + 1}</div>
							) : null}
							<span>{data.headline}</span>
						</div>
					</div>
				) : (
					<EventsLink
						to={`${data.link}?promo=media_wall&slide=${index}`}
						style={`caption-${data.text_location}`}
						measureData={{
							...data,
							actionType: 'homePageClick',
							itemTitle: data.headline,
							shortTitle: data.headline,
							which: index,
							type: 'home hero carousel',
						}}>
						<div className="panel slick-slider">
							<div className="imgCrop_container">
								<div className="single-image">
									<img src={data.image_large} title={data.headline} />
								</div>
								{/* <div className="imgDate">{data.date}</div> */}
							</div>
							<div className="bx-caption">
								<span>{data.headline}</span>
							</div>
						</div>
					</EventsLink>
				)}
			</Fragment>
		);
	}

	onCarouselChange(e) {
		// logger.log("[HomeHero] onCarouselChange:%o", e);

		let item = this.state.heroData[e];
		if (e > this.viewedIndex) {
			// logger.log("[HomeHero] onCarouselChange  - Next: %o", item.headline);
			MeasurementUtils.dispatchMeasurementCall('Home  Hero Carousel: Next', {
				caption: item.headline,
			});
		} else {
			// logger.log("[HomeHero] onCarouselChange - Prev: %o", item.headline);
			MeasurementUtils.dispatchMeasurementCall('Home Hero Carousel: Prev', {
				caption: item.headline,
			});
		}
		this.viewedIndex = e;
	}

	render() {
		// logger.log('[HomeHero] render state:%o', this.state);
		//const { width, height } = this.state;
		let { heroData } = this.state;
		let renderSlidePanel = this.renderSlidePanel;
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
		};
		// logger.log('[HomeHero] render heroData:%o', heroData);
		if (heroData) {
			return (
				<section id={`hpNews`} className={`heroSection home-page`}>
					<div className={`trans_slider ${!this.state.showDots ? `hideDots` : ``}`}>
						<div className="bx-wrapper">
							<Carousel
								showThumbs={false}
								useKeyboardArrows={false}
								infiniteLoop
								showStatus={false}
								showIndicators={this.state.showDots}
								showArrows={false}
								onChange={e => this.onCarouselChange(e)}
								selectedItem={this.state.viewedIndex}
								// autoPlay={true}
								// interval={5000}
							>
								{heroData.map((slide, i) => {
									return <div key={i}>{this.renderSlidePanel(slide, i)}</div>;
								})}
							</Carousel>
						</div>
					</div>
				</section>
			);
		} else {
			return null;
		}
	}
}

HomeHero.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HomeHero);
