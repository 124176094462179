import React, { Component } from 'react';
import { connect } from 'react-redux';
import Script from 'react-load-script';
import deps from 'dependencies';
import Template from 'components/Template';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import queryString from 'querystring-browser';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';

const mapStateToProps = (state, props) => {
	return {
		...state['GroundsMap'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.GroundsMap.mount()),
});

class GroundsMap extends Component {
	constructor(props) {
		super(props);

		this.icons = new Array();
		this.marker = new Array();
		this.infowindow = new Array();
		this.map = null;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Grounds Map',
		});
		logger.log('[GroundsMap] constructor - props:%o', props);
	}

	componentDidMount() {
		if (this.props.hasOwnProperty('mount')) {
			this.props.mount();
		}
	}

	showMarkers = group => {
		if (group == 'none') {
			return;
		}
		let mapCategories = get(this.props, 'mapMarkers.mapCategories', []);
		let markerSet = find(mapCategories, { displayName: group });
		if (!isEmpty(markerSet)) {
			markerSet.mapMarkers.map(item => {
				this.marker[item.markerId].setMap(this.map);
			});
		}
	};

	showMarkersById = group => {
		group = parseInt(group);
		let mapCategories = get(this.props, 'mapMarkers.mapCategories', []);
		let markerSet = find(mapCategories, { categoryId: group });
		logger.log('[GroundsMap] showMarkersById - markerSet: %o', markerSet);
		if (!isEmpty(markerSet)) {
			this.setState({ category: markerSet.description });
			markerSet.mapMarkers.map(item => {
				this.marker[item.markerId].setMap(this.map);
			});
		} else {
			this.setState({ category: 'none' });
		}
	};

	hideMarkers = group => {
		if (group == 'none') {
			return;
		}
		let mapCategories = get(this.props, 'mapMarkers.mapCategories', []);
		let markerSet = find(mapCategories, { displayName: group });
		if (!isEmpty(markerSet)) {
			markerSet.mapMarkers.map(item => {
				this.marker[item.markerId].setMap(null);
			});
		}
	};

	pickCategory = (category, e) => {
		this.hideMarkers(this.state.category);
		if (category == this.state.category) {
			category = 'none';
		}

		this.showMarkers(category);
		this.setState({ category });
	};

	genCatButtons = () => {
		let mapCategories = get(this.props, 'mapMarkers.mapCategories', []);
		let catButtons = [];
		mapCategories.map(item => {
			//categories
			// logger.log("[GroundsMap] genCatButtons - item: %o",item);
			let category = item.displayName;

			// load category buttons
			let hiddenButtons = ['Onsite Experiences', 'Seating Availability'];
			if (!hiddenButtons.includes(category)) {
				catButtons.push(
					<button
						key={category}
						className="catBtn button"
						name={category}
						onClick={e => this.pickCategory(category, e)}>
						<img
							src={`/assets/images/mapIcons/${category.replace(' ', '_')}_${
								category == this.state?.category ? `down` : `up-wht`
							}.png`}
							alt={`${category} button`}
						/>
						<span className="catLabel">{category}</span>
					</button>
				);
			}
		});
		return catButtons;
	};

	render() {
		logger.log('[GroundsMap] render - this.state: %o', this.state);
		logger.log('[GroundsMap] render - this.props: %o', this.props);

		let header_attributes = {
			headerType: 'visit',
			title: 'Grounds Map',
			metaTitle: 'Grounds Map ',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			title: 'Grounds Map',
		};

		if (!isEmpty(get(this.props, 'mapMarkers', []))) {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<Script
						url={`https://maps.google.com/maps/api/js?key=AIzaSyAIe9JqV1iTHE8HyndalVsYbFcXFJs_a2Y&sensor=false`}
						onCreate={this.handleScriptCreate}
						onError={this.handleScriptError}
						onLoad={this.handleScriptLoad}
					/>
					<div className="general_content">
						{/*<h1>Grounds Map</h1>*/}
						<PageSubMenu mode="Visit" selected="At the Open" />
						<div className="interactive_map">
							<div className="catBtns">{this.genCatButtons()}</div>
							<div id="map" />
						</div>
					</div>
				</Template>
			);
		} else {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<LoadingIndicator />
				</Template>
			);
		}
	}

	handleScriptCreate = () => {
		logger.log('[GroundsMap] handleScriptCreate script create');
		this.setState({ scriptLoaded: false });
	};

	handleScriptError = () => {
		logger.log('[GroundsMap] handleScriptError script error');
		this.setState({ scriptError: true });
	};

	handleScriptLoad = () => {
		logger.log('[GroundsMap] handleScriptLoad script loaded');
		this.setState({ scriptLoaded: true });

		let mapBounds = new google.maps.LatLngBounds(
			new google.maps.LatLng(40.73785, -73.852681),
			new google.maps.LatLng(40.760199, -73.837299)
		);
		let mapMinZoom = 15;
		let mapMaxZoom = 21;
		let maptiler = new google.maps.ImageMapType({
			getTileUrl: (coord, zoom) => {
				let proj = this.map.getProjection();
				let z2 = Math.pow(2, zoom);
				let tileXSize = 256 / z2;
				let tileYSize = 256 / z2;
				let tileBounds = new google.maps.LatLngBounds(
					proj.fromPointToLatLng(new google.maps.Point(coord.x * tileXSize, (coord.y + 1) * tileYSize)),
					proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * tileXSize, coord.y * tileYSize))
				);
				let x = coord.x >= 0 ? coord.x : z2 + coord.x;
				let y = coord.y;
				if (mapBounds.intersects(tileBounds) && mapMinZoom <= zoom && zoom <= mapMaxZoom)
					return '/map/tiles/standard/' + zoom + '/' + x + '/' + y + '.png';
				else return 'https://www.maptiler.com/img/none.png';
			},
			tileSize: new google.maps.Size(256, 256),
			isPng: true,
			name: 'Rendered with MapTiler Desktop',
			alt: 'Rendered with MapTiler Desktop',

			opacity: 1.0,
		});
		let opts = {
			tilt: 0,
			streetViewControl: false,
			center: new google.maps.LatLng(40.74938, -73.846249),
			zoom: 18,
		};
		this.map = new google.maps.Map(document.getElementById('map'), opts);
		this.map.setMapTypeId('satellite');
		this.map.overlayMapTypes.insertAt(0, maptiler);

		let mapCategories = get(this.props, 'mapMarkers.mapCategories', []);
		mapCategories.map(item => {
			//categories
			//logger.log("[GroundsMap] genCatButtons - item: %o",item);
			let category = item.displayName;

			// icons
			this.icons[category] = {
				url: '/assets/images/mapIcons/' + category.replace(' ', '_') + '.png',
				size: new google.maps.Size(44, 52),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(22, 52),
			};
			item.mapMarkers.map(item => {
				//individual markers
				// logger.log("[GroundsMap] item:%o", item);
				this.marker[item.markerId] = new google.maps.Marker({
					position: new google.maps.LatLng(parseFloat(item.markerLatitude), parseFloat(item.markerLongitude)),
					title: item.markerTitle,
					icon: this.icons[category],
					map: null,
				});
				let courtIframe = '';
				if (item.markerGraphic == 'pin_courts') {
					courtIframe =
						'<iframe src="/webview/en_US/scores/groundsmap/' +
						item.markerCourtId +
						'.html" style="width:100%"></iframe>';
				}
				let contentString =
					'<span class="infoBoxTitle">' +
					item.markerTitle +
					'</span><span class="infoBoxDescription">' +
					item.markerLongDescription +
					'</span>' +
					courtIframe;
				this.infowindow[item.markerId] = new google.maps.InfoWindow({
					content: contentString,
				});

				this.marker[item.markerId].addListener('click', () => {
					this.infowindow[item.markerId].open(this.map, this.marker[item.markerId]);
				});
			});
		});

		/** Open based on url **/
		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);
		let categoryId = get(parsedQs, 'categoryId', '');
		if (categoryId != '') {
			this.showMarkersById(categoryId);
		}
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(GroundsMap);
