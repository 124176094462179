import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		// logger.log('[PageSubMenu] actions.mount - ');

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				status: 'loaded',
				daysPath: Config.scoringData.scheduleDays,
				daysUrl: Config.scoringData.schedule,
				pdfPath: Config.scoringData.schedulePdf,
				tourn_events: Config.scoringConfig.eventNames,
				practicePath: Config.scoringData.practice,
			};

			// logger.log('[PageSubMenu] action.mount - data: %o', data);
			dispatch({ type: deps.actionTypes.PAGESUBMENU_MOUNT, data: data });
		});
	},
	unmount: () => dispatch => {
		let data = {
			status: null,
			menuData: null,
		};
		// logger.log('[PageSubMenu] action.unmount - data: %o', data);
		dispatch({ type: deps.actionTypes.PAGESUBMENU_UNMOUNT, data: data });
	},
	filter: data => (dispatch, getState, store) => {
		// logger.log('[PageSubMenu] action.filter - data: %o', data);
		dispatch({ type: deps.actionTypes.PAGESUBMENU_FILTER, data: data });
	},
};
