import { adobePageview, adobeExternalLink, adobeMeasureApp, adobeMeasureAppProps } from 'appdir/lib/tracking';
import { getContextQuery } from 'appdir/components/general/Util';

import findIndex from 'lodash/findIndex';

/**
 * handle standard page views calls
 * @param {Object} data - object with page_sectiona nd page_name
 * @param {String} mod - addtl modifier added after page_name
 */
export const pageView = (data, mod = '') => {
	logger.log('[Analytics] pageView - data:%o mod:%o', data, mod);
	let copyData = {
		page_name: data.page_name,
		page_section: data.page_section,
	};
	let propsData = data.props ? data.props : null;

	if (mod.length > 0) {
		copyData.page_name = copyData.page_name + ':' + mod;
	}

	adobePageview(copyData, propsData);
};

/**
 * handle app measurement calls
 * used for actions
 * @param {Array} args - array of string for tracking call
 */
export const measureApp = (...args) => {
	let idx = findIndex(args, function(o) {
		return !o;
	});
	if (idx >= 0) {
		args[idx] = '';
	}
	logger.log('[Analytics] measureApp - args:%o', args);

	adobeMeasureApp(args);
};

/**
 * call measureApp and set optional properties
 * @param {Object} props
 * @param {Array} args
 */
export const measureAppProps = (args, props) => {
	let idx = findIndex(args, function(o) {
		return !o;
	});
	if (idx >= 0) {
		args[idx] = '';
	}
	logger.log('[Analytics] measureAppProps - args:%o', args);

	adobeMeasureAppProps(args, props);
};

/**
 * handle external link tracking
 * @param {array} option - list of measurement options
 * @param contextData @Array [{key: value}, {key:value}, {key:value}]  - if there is contextData passed, pass the same format &key=value to app calls
 */
export const measureInApp = (mArgs, contextData) => {
	let contextQuery = '';

	if (contextData) {
		/** this will form &<key>=<value> */
		contextQuery = getContextQuery(contextData);
	}

	if (navigator.userAgent.indexOf('IBM_Events_Android_APK') != -1) {
		let queryString = '?text=';
		let measureArray = mArgs.slice(1);
		queryString += encodeURIComponent(measureArray.join(':'));

		/** send extra param for context data for metrics if there is contextData */
		if (contextData) {
			queryString += contextQuery;
		}

		if (mArgs[0].toLowerCase() == 'metrics') {
			logger.log('[measureInApp] Android measuring click - measureText:%o', queryString);

			document.location.href = 'http://usopen/metrics' + queryString;
		} else if (mArgs[0].toLowerCase() == 'metricsstate') {
			// logger.log("[measureInApp] Android measuring state - measureText:%o", queryString);
			document.location.href = 'http://usopen/track/' + queryString;
		}
	} else {
		/** for iOS */
		let measureArray = mArgs.slice(1);
		let measureText = measureArray.join(':');

		if (contextData) {
			measureText += contextQuery;
		}

		if (mArgs[0].toLowerCase() == 'metrics') {
			// logger.log("[measureInApp] iOS measuring click - measureText:%o", measureText);

			/** when additional query strings are passed for context variables,
			 * need to use a web link call for iOS instead of messageHandlers */
			if (contextData) {
				let actionQueryPrefix = '?text=';
				measureText += contextQuery;				
				document.location.href = 'http://usopen/metrics' + actionQueryPrefix + measureText;
			} else {
				window.webkit.messageHandlers.metrics.postMessage(measureText);
			}
		} else if (mArgs[0].toLowerCase() == 'metricsstate') {
			// logger.log("[measureInApp] iOS measuring state - measureText:%o", measureText);
			window?.webkit?.messageHandlers?.metricsState?.postMessage(measureText);
		}
	}
};

/**
 * handle page views for content pages
 * @param {Object} data - contains category, aldning, and title values
 */
export const pageViewContent = data => {
	logger.log('[Analytics] pageViewContent - data:%o', data);

	let props = {};
	let vals = {};

	// let pageNameVal = "";
	// let qstringVal = "";

	// qstringVal = document.getElementsByTagName("qstring");
	// if (pageNameVal.length > 0) {
	// var page_name = pageNameVal[0].getAttribute("content");

	// if (qstringVal.length > 0) {
	// 	for (var i = 0; i < qstringVal.length; i++) {
	// 	if (null === qsParse.get(qstringVal[i].getAttribute("content"))) {
	// 		page_name += ":" + qstringVal[i].getAttribute("default");
	// 	} else {
	// 		page_name += ":" + qsParse.get(qstringVal[i].getAttribute("content"));
	// 	}
	// 	}
	// }
	// }

	// set category data
	if (data.category) {
		switch (data.category) {
			case 'articles':
				vals = {
					page_section: 'News',
					page_name: 'Articles:Detail',
				};
				props = {
					prop4: 'Article',
					prop5: data.title,
				};
				adobePageview(vals, props);
				break;

			case 'galleries':
				vals = {
					page_section: 'News',
					page_name: 'Galleries:Detail',
				};
				props = {
					prop4: 'Gallery',
					prop16: data.photoTitle,
					prop35: data.title,
				};
				adobePageview(vals, props);
				break;

			case 'about_wimbledon':
			case 'about_wimbledon':
			case 'aboutwimbledon':
				vals = {
					page_section: 'About',
					page_name: 'About Wimbledon:' + data.title,
				};
				adobePageview(vals);
				break;

			case 'atoz':
				vals = {
					page_section: 'Visit',
					page_name: 'Visitors A to Z Guide:' + data.title,
				};
				adobePageview(vals);
				break;

			case 'tickets':
				vals = {
					page_section: 'Visit',
					page_name: 'Tickets:' + data.title,
				};
				adobePageview(vals);
				break;

			case 'jobs':
				vals = {
					page_section: 'About',
					page_name: 'Jobs:' + data.title,
				};
				adobePageview(vals);
				break;

			case 'visitandtickets':
			case 'visit_and_tickets':
				vals = {
					page_section: 'Visit',
					page_name: 'Visit & Tickets:' + data.title,
				};
				adobePageview(vals);
				break;

			case 'wimbledonfoundation':
			case 'foundation':
				vals = {
					page_section: 'Foundation',
					page_name: data.title,
				};
				adobePageview(vals);
				break;

			case 'champion':
				vals = {
					page_section: 'Draws Archive',
					page_name: 'Detail:' + data.title,
				};
				adobePageview(vals);
				break;

			default:
				break;
		}
	}

	// set landing data
	if (data.landing) {
		switch (data.landing) {
			case 'jobs':
			case 'media':
			case 'about_wimbledon':
				vals = {
					page_section: 'About',
					page_name: data.title,
				};
				adobePageview(vals);
				break;

			case 'your_visit':
			case 'tickets':
			case 'learning':
			case 'museum_and_tours':
			case 'foodanddrink':
			case 'visit_and_tickets':
				vals = {
					page_section: 'Visit',
					page_name: data.title,
				};
				adobePageview(vals);
				break;

			case 'inpursuitofgreatness':
				vals = {
					page_section: 'News',
					page_name: data.title,
				};
				adobePageview(vals);
				break;

			case 'fast_facts':
				vals = {
					page_section: 'Draws Archive',
					page_name: 'Draws Archive Fast Facts',
				};
				adobePageview(vals);
				break;

			case 'foundation':
				vals = {
					page_section: 'Foundation',
					page_name: 'Foundation',
				};
				adobePageview(vals);
				break;

			case 'hospitality':
				vals = {
					page_section: 'Hospitality',
					page_name: 'Hospitality',
				};
				adobePageview(vals);
				break;

			case 'road_to_wimbledon':
				vals = {
					page_section: 'Road to Wimbledon',
					page_name: 'About',
				};
				adobePageview(vals);
				break;

			default:
				break;
		}
	}
};

/**
 * handle page views for news index page
 * @param {Object} data
 */
export const pageViewNews = data => {
	logger.log('[Analytics] pageViewNews - data:%o', data);

	let props = {};
	let vals = {};
	let year = data.year != 'current' ? ':' + data.year : '';

	switch (data.type) {
		case 'all':
			vals = {
				page_section: 'News' + year,
				page_name: 'All',
			};
			adobePageview(vals, props);
			break;

		case 'news':
			vals = {
				page_section: 'News' + year,
				page_name: 'News',
			};
			adobePageview(vals, props);
			break;

		case 'gallery':
			vals = {
				page_section: 'News' + year,
				page_name: 'Photos',
			};
			adobePageview(vals, props);
			break;

		case 'video':
			vals = {
				page_section: 'News' + year,
				page_name: 'Videos',
			};
			adobePageview(vals, props);
			break;

		case 'press release':
			vals = {
				page_section: 'News' + year,
				page_name: 'Press Releases',
			};
			adobePageview(vals, props);
			break;

		case 'interview':
			vals = {
				page_section: 'News' + year,
				page_name: 'Interviews',
			};
			adobePageview(vals, props);
			break;

		case 'favorite':
			vals = {
				page_section: 'News' + year,
				page_name: 'Your Players',
			};
			adobePageview(vals, props);
			break;

		default:
			break;
	}
};

/**
 * handle external link tracking
 * @param {Object} data - has external link data
 */
export const openExternal = data => {
	//data: lnk, newWindow, w, h
	if (data.url.indexOf('http://' == -1)) {
		switch (data.url) {
			case 'ibm':
				data.url = 'http://www.ibm.com/';
				break;
			case 'tix1':
				data.url =
					'https://oss.ticketmaster.com/html/outsider.htmI?CAMEFROM=&GOTO=https%3A%2F%2Foss.ticketmaster.com%2Fhtml%2Frequest.htmI%3Fl%3DEN%26team%3Dusopentennis%26STAGE%3D1%26PROC%3DBUY%26EventName%3D11DEPSIT';
				break;
			default:
				break;
		}
	}

	logger.log('[Analytics] openExternal - data:%o ', data);
	adobeExternalLink(data);
};

export const ANALYTICS_PROPS = {
	home: {
		page_section: 'Home',
		page_name: 'US Open 2018 Home',
	},
	search: {
		page_section: 'Site Functions',
		page_name: 'Search Results',
	},
	atozguide: {
		page_section: 'Visit',
		page_name: 'Visitors A to Z Guide',
	},
	sitemap: {
		page_section: 'Site Functions',
		page_name: 'Sitemap',
	},
	playerIndex: {
		page_section: 'Players',
		page_name: 'Players by Name',
	},
	playerDetail: {
		page_section: 'Players',
		page_name: 'Player Profiles',
	},
	playerSeedsList: {
		page_section: 'Players',
		page_name: 'Players by Seed',
	},
	playerCountryStatistics: {
		page_section: 'Players',
		page_name: 'Country Leaderboard',
	},
	playerByCountry: {
		page_section: 'Players',
		page_name: 'Players by Country',
	},
	playerSearch: {
		page_section: 'Players',
		page_name: 'Players by Name',
	},
	playerStatistics: {
		page_section: 'Scores & Stats',
		page_name: 'Event Statistics',
	},
	playerChallenge: {
		page_section: 'Players',
		page_name: 'Player Challenge',
	},
	liveScores: {
		page_section: 'Scores',
		page_name: 'Live Scores',
	},
	liveCommentary: {
		page_section: 'Scores',
		page_name: 'Live Commentary',
	},
	liveVideoWimChan: {
		page_section: 'Video & Radio',
		page_name: 'Wimbledon Channel Live Video',
	},
	liveVideoCentre: {
		page_section: 'Video & Radio',
		page_name: 'Wimbledon Channel - Centre Court Live',
	},
	liveVideoEspn: {
		page_section: 'Video & Radio',
		page_name: 'Wimbledon Channel - ESPN',
	},
	liveVideoHill: {
		page_section: 'Video & Radio',
		page_name: 'Hill Video',
	},
	liveRadio: {
		page_section: 'Video & Radio',
		page_name: 'Wimbledon Channel Live Radio',
	},
	results: {
		page_section: 'Scores',
		page_name: 'Results',
	},
	schedule: {
		page_section: 'Scores',
		page_name: 'Schedule',
	},
	weather: {
		page_section: 'News',
		page_name: 'Weather',
	},
	mobileApps: {
		page_section: 'News',
		page_name: 'Mobile Apps',
	},
	myWimbledon: {
		page_section: 'About',
		page_name: 'Wimbledon Newsletter',
	},
	myWimbledonResidents: {
		page_section: 'About',
		page_name: 'Wimbledon Newsletter - Residents',
	},
	myWimbledonThankYou: {
		page_section: 'About',
		page_name: 'Wimbledon Newsletter:Thank You',
	},
	contact: {
		page_section: 'About',
		page_name: 'Contact Us',
	},
	contactThankYou: {
		page_section: 'About',
		page_name: 'Contact Us:Thank You',
	},
	videoGallery: {
		page_section: 'Video & Radio',
		page_name: 'Video Gallery',
	},
	shop: {
		page_section: 'Shop',
		page_name: 'Shop',
	},
	jobs: {
		page_section: 'Jobs',
		page_name: 'Jobs',
	},
	draws: {
		page_section: 'Draws',
		page_name: 'Draws',
	},
	drawsArchiveYear: {
		page_section: 'Draws Archive',
		page_name: 'Draws Archive Year',
	},
	drawsArchiveEvents: {
		page_section: 'Draws Archive',
		page_name: 'Draws Archive Events',
	},
	drawsArchiveTopStats: {
		page_section: 'Draws Archive',
		page_name: 'Draws Archive Top Stats',
	},
	drawsArchiveLadies: {
		page_section: 'Draws Archive',
		page_name: "Ladies' Names",
	},
	drawsArchiveCountry: {
		page_section: 'Draws Archive',
		page_name: 'Country Abbreviations',
	},
	drawsArchivePlayer: {
		page_section: 'Draws Archive',
		page_name: 'Player',
	},
	drawsArchiveSearch: {
		page_section: 'Draws Archive',
		page_name: 'Search',
	},
	slamtracker: {
		page_section: 'Slamtracker',
		page_name: 'Slamtracker',
		props: {
			events: 'event12',
		},
	},
	rolexScoreboard: {
		page_section: 'Rolex Scoreboard',
		page_name: 'Scores:Rolex Scoreboard',
		props: {
			events: 'event40',
		},
	},
	chinaScoreboard: {
		page_section: 'China Scoreboard',
		page_name: 'Scores:China Scoreboard',
		props: {
			events: 'event29',
		},
	},
	socialLanding: {
		page_section: 'Social',
		page_name: 'Social',
	},
};
