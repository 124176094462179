import React, { Component, Fragment } from 'react';
import Script from 'react-load-script';
import deps from 'dependencies';
import Template from 'components/Template';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import queryString from 'querystring-browser';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';

class Accessibility extends Component {
	constructor(props) {
		super(props);

		this.script = document.createElement('script');
		this.script.async = true;
		this.script.src = 'https://a42cdn.usablenet.com/a42/usopen/default/prod/accessibilitystatement.js';

		logger.log('[Accessibility] constructor - props:%o', props);
	}

	componentDidMount() {
		document.getElementById('a42AccessibilityStatement').appendChild(this.script);
	}

	render() {
		logger.log('[Accessiblity] render - this.state: %o', this.state);

		let header_attributes = {
			headerType: 'visit',
			title: 'Accessiblity',
			metaTitle: 'Accessiblity ',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			title: 'Accessiblity',
		};

		return (
			<Template header={header_attributes} subHeader={subheader_attributes}>
				<section className="wrapper accessiblity">
					<div className="general_content info">
						<div className="siteDetailContainer">
							<div className="siteDetail" id="a42AccessibilityStatement"></div>
						</div>
					</div>
				</section>
			</Template>
		);
	}
}
export default Accessibility;
