/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import isString from 'lodash/isString';

class Time extends Component {
	constructor(props) {
		super(props);

		this.state = Object.assign({}, this.props);
	}

	render() {
		let time = '';
		let timeNum;

		if (this.state.epoch_ms) {
			timeNum = isString(this.state.epoch_ms) ? parseFloat(this.state.epoch_ms) : this.state.epoch_ms;
			time = moment(timeNum)
				.tz(this.state.zone)
				.format(this.state.format);
		} else if (this.state.epoch_sec) {
			timeNum = isString(this.state.epoch_sec) ? parseFloat(this.state.epoch_sec) : this.state.epoch_sec;
			time = moment
				.unix(this.state.epoch_sec)
				.tz(this.state.zone)
				.format(this.state.format);
		} else {
			time = moment()
				.tz(this.state.zone)
				.format(this.state.format);
		}

		if (this.state.options.includes('upper')) {
			time = time.toUpperCase();
		}

		return <div>{time}</div>;
	}
}

/*
 *  default props
 *  - options are 'upper'
 */
Time.defaultProps = {
	format: 'ddd DD MMM YYYY kk:mm z',
	zone: 'America/New_York',
	options: '',
};

export default Time;
