import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import isEqual from 'lodash/isEqual';
import op from 'object-path';
import Slider from 'react-slick';

import { fetch, sortFavPlayers, getAvailableFavPlayersNum } from 'appdir/components/general/Util';
import PlayerImage from 'appdir/components/pages/PlayerPage/PlayerImage';
import FavoriteStar from 'appdir/components/common-ui/FavoriteStar';
import EventsLink from 'appdir/components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * Class Component: Favorites Module that's used on Home Page that includes players status
 *
 * Pull players status data from SharedData and keep fetching to get the updated player status
 *
 * props: {
 *     status: 'loaded' to ensure Config is loaded
 * }
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['HomeFavorites'],
		favorites: state['Controller'].favorites,
		sharedDataConfig: state['Config'].sharedDataConfig,
		scoringData: state['Config'].scoringData,
		playerStatusData: state['CommonData']['playerStatus'],
		playerImagePath: state['Config']?.otherData?.playerImagePath,
		flagImagePathSmall: state['Config']?.otherData?.flagImagePathSmall,
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});

class HomeFavorites extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			status: null,
		};

		this.statusLoaded = false;
		this.statusTimer = null;
		this.expandRef = React.createRef();
		this.measureData = {
			...props.data,
			type: `${props.data.type}${props.data.desktopPosition}`,
			itemTitle: props.data.title,
			itemId: props.data.id,
			itemType: `${props.data.type}${props.data.desktopPosition}`,
			actionType: 'homePageClick',
			which: 'favorites_link',
		};
		delete this.measureData.content;
		// logger.log('[FavoritesModule] constructor - measureData: %o', this.measureData);
	}
	componentDidMount() {
		this.setState({
			status: 'loaded',
		});
	}

	componentWillUnmount() {
		if (this.statusTimer) {
			clearInterval(this.statusTimer);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[HomeFavorites] componentDidUpdate - prevProps:%o', prevProps);

		/** first load, check if favorites exist, then pull playerStatusData */
		if (
			this.props.status == 'loaded' &&
			this.state.status == 'loaded' &&
			this.props.favorites?.players?.length > 0 &&
			!this.statusLoaded
		) {
			this.statusLoaded = true;

			this.setFetchingPlayerStatusRequests();
			this.fetchPlayersList();
		}

		/** if favorites data are changed, update the fetching and redo timer */
		let curFavPlayers = this.props.favorites?.players;
		let prevFavPlayers = prevProps?.favorites?.players;

		if (!isEqual(curFavPlayers, prevFavPlayers)) {
			if (this.statusTimer) {
				clearInterval(this.statusTimer);
			}

			this.sortFavPlayers();
			this.setFetchingPlayerStatusRequests();

			if (!this.state.allPlayers) {
				this.fetchPlayersList();
			} else {
				this.updateSortedPlayersList();
			}
		}

		/** if playerStatusData is updated, sort data */
		let curPlayerStatusData = this.props?.playerStatusData;
		let prevPlayerStatusData = prevProps?.playerStatusData;
		if (
			!isEqual(curPlayerStatusData, prevPlayerStatusData) ||
			(!this.state.sortedPlayers && this.favorites?.length > 0)
		) {
			this.sortFavPlayers();
		}
	}

	setFetchingPlayerStatusRequests = () => {
		for (let i = 0; i < this.favorites.length; i++) {
			this.fetchPlayerStatus(this.favorites[i]);
		}

		if (!this.statusTimer) {
			this.statusTimer = setInterval(() => {
				this.setFetchingPlayerStatusRequests();
			}, 30000);
		}
	};

	sortFavPlayers = async () => {
		let tmpData = [];
		let sortedPlayers = [];
		let isPlayerStatusLoaded = false;
		if (this.favorites?.length > 0 && this.props?.playerStatusData) {
			let _this = this;

			/** get favorited player's playerStatusData */
			this.favorites.map((player, index) => {
				isPlayerStatusLoaded = _this.props?.playerStatusData[player].status === 'loaded';

				/** stubbed player status data has inOut value as 'out',
				 *  check matches array to make sure it's not empty so
				 *  the stubbed data doesn't display Final Match
				 */
				if (isPlayerStatusLoaded) {
					let isStubbedData = _this.props?.playerStatusData[player]?.result?.matches?.length === 0;
					!isStubbedData ? tmpData.push(_this.props?.playerStatusData[player]) : null;
				}
			});

			if (tmpData.length > 0) {
				sortedPlayers = tmpData.sort((a, b) => {
					return a?.result?.playerLastName < b?.result?.playerLastName ? -1 : 1;
				});

				this.setState({
					sortedPlayers: sortedPlayers,
				});
			}
		}
	};

	fetchPlayerStatus = playerId => {
		this.props
			.checkExpired({
				...this.props.sharedDataConfig['playerStatus'],
				key: playerId,
				path: this.props.sharedDataConfig['playerStatus'].path.replace('<playerId>', playerId),
			})
			.then(response => {
				// logger.log('[HomeFavorites] fetchPlayerStatus playerStatus - response:%o', response);
				if (response.status == 'expired') {
					this.props.update({
						...this.props.sharedDataConfig['playerStatus'],
						key: playerId,
						path: this.props.sharedDataConfig['playerStatus'].path.replace('<playerId>', playerId),
					});
				}
			})
			.catch(error => {
				logger.error('[HomeFavorites] fetchPlayerStatus playerStatus - :o', error);
				this.setState({
					status: 'playerStatusError',
					errorType: error,
				});
			});
	};

	fetchPlayersList = () => {
		fetch(this.props.scoringData.players)
			.then(result => {
				/** loop through favorited players and sorte the players alphabetically */
				let sortedPlayers = sortFavPlayers(result.players, this.favorites);

				this.setState({
					allPlayers: result?.players,
					sortedPlayersList: sortedPlayers,
				});
			})
			.catch(error => {
				logger.log('[FavoritesModule] fetchPlayersList error:%o', error);
			});
	};

	/** favorite list is updated, resort the array */
	updateSortedPlayersList = () => {
		let sortedPlayers = sortFavPlayers(this.state.allPlayers, this.favorites);

		this.setState({
			sortedPlayersList: sortedPlayers,
		});
	};

	toggleOpen = () => {
		this.setState(
			{
				open: !this.state.open,
			},
			() => {
				if (this.state.open) {
					/** if the expand icon is clicked
					 *  scroll the header element to the view so user doesn't have to scroll
					 */
					this.expandRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				MeasurementUtils.dispatchMeasurementCall(this.measureData.type, {
					action: this.state.open ? 'Open' : 'Close',
				});
			}
		);
	};

	measureNextPrev = (oldIndex, newIndex) => {
		if (oldIndex < newIndex) {
			MeasurementUtils.dispatchMeasurementCall(this.measureData.type, {
				action: 'next',
				curSlide: newIndex,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall(this.measureData.type, {
				action: 'prev',
				curSlide: newIndex,
			});
		}
	};

	getMatchStatus = (data, selectedMatch) => {
		let status = '';
		let statusClass = '';

		if (data?.inOut?.toLowerCase() === 'out') {
			status = 'Final Match';
			statusClass = 'final-match';
		} else if (selectedMatch) {
			switch (selectedMatch?.statusCode) {
				case 'B':
					status = 'Upcoming Match';
					statusClass = 'upcoming';
					break;

				case 'A':
				case 'X':
				case 'Y':
					status = 'On Court Now';
					statusClass = 'in-progress';
					break;

				default:
					break;
			}
		}

		return { status, statusClass };
	};

	getFavoritePlayerBasicCard = (data, index) => {
		if (data) {
			let { id } = data;
			let bioLink = `/en_US/players/overview/${id}.html`;
			let playerData;

			// logger.log('[HomeFavorites] getFavoritePlayerBasicCard - data: %o', data);

			/** find if there are players status available */
			if (this?.state?.sortedPlayers?.length > 0) {
				playerData = this?.state?.sortedPlayers.filter(p => p?.result?.playerID === id);
			}

			if (playerData?.length > 0) {
				return this.getFavoritePlayerCard(playerData[0].result, index);
			} else {
				return (
					<div className={`favorite-card`} key={`fav-card${id}${index}`}>
						<div className="top">
							<EventsLink
								to={bioLink}
								measureData={{
									...this.measureData,
								}}>
								<PlayerImage
									attributes={{
										'player-image': this.props?.playerImagePath.replace('<id>', id),
										style: 'regular',
									}}
								/>
							</EventsLink>
							<div className="row">
								<span className="player-name">
									<EventsLink
										to={bioLink}
										measureData={{
											...this.measureData,
										}}>
										{data.first_name}
										<span className="last-name">
											{data.last_name}
											{data.seed && data.seedValue && data.seedValue !== ''
												? ` [${data.seedValue}]`
												: ''}
										</span>
									</EventsLink>
								</span>

								<FavoriteStar
									id={id}
									playerName={data['tv_name']}
									type="players"
									unFavColor="gray"
									favColor="yellow"
									alwaysShow={true}
									style="outline"
								/>
								<div className="row">
									<div className="flag-image">
										<img src={this.props?.flagImagePathSmall.replace('<code>', data.country)} />
									</div>
								</div>
							</div>
						</div>
						<div className="bottom">
							<p className={`player-status`}>&nbsp;</p>
						</div>
						<div className="bio-link">
							<EventsLink
								to={bioLink}
								measureData={{
									...this.measureData,
								}}>
								Player Bio
							</EventsLink>
						</div>
					</div>
				);
			}
		}
	};

	getFavoritePlayerCard = (data, index) => {
		if (data) {
			let { playerID, matches, displayMatch } = data;
			let bioLink = `/en_US/players/overview/${playerID}.html`;
			let team, ab, opponentTeam;
			let selectedMatch;
			let playerData;

			// logger.log('[HomeFavorites] getFavoritePlayerCard - data: %o', data);

			/** find if there are players status available */
			if (this?.state?.sortedPlayers?.length > 0) {
			}

			if (matches?.length > 0) {
				selectedMatch = matches.filter(match => match['match_id'] === displayMatch)[0];
			}

			let statusClass = this.getMatchStatus(data, selectedMatch);

			if (selectedMatch) {
				/** find out which team the selected player is with */
				team =
					selectedMatch.team1[0].idA === playerID || selectedMatch.team1[0].idB === playerID
						? 'team1'
						: selectedMatch.team2[0].idA === playerID || selectedMatch.team2[0].idB === playerID
						? 'team2'
						: null;
				if (team) {
					/** find out which team the opponent team is */
					opponentTeam =
						team == 'team1' && selectedMatch.team2[0].idA !== null
							? 'team2'
							: team == 'team2' && selectedMatch.team1[0].idA !== null
							? 'team1'
							: null;

					/** find out if the player is A or B */
					ab =
						selectedMatch[team][0].idA === playerID
							? 'A'
							: selectedMatch[team][0].idB === playerID
							? 'B'
							: null;
				}
			}

			return (
				<div className={`favorite-card`} key={`fav-card${playerID}${index}`}>
					<div className="top">
						<EventsLink
							to={bioLink}
							measureData={{
								...this.measureData,
							}}>
							<PlayerImage
								attributes={{
									'player-image': this.props?.playerImagePath.replace('<id>', playerID),
									style: 'regular',
								}}
							/>
						</EventsLink>
						<div className="row">
							<span className="player-name">
								<EventsLink
									to={bioLink}
									measureData={{
										...this.measureData,
									}}>
									{data.playerFirstName}
									<span className="last-name">
										{data.playerLastName}
										{selectedMatch && team
											? selectedMatch[team][0]?.seed
												? ` [${selectedMatch[team][0]?.seed}]`
												: ''
											: ''}
									</span>
								</EventsLink>
							</span>

							<FavoriteStar
								id={playerID}
								playerName={data['playerTvName']}
								type="players"
								unFavColor="gray"
								favColor="yellow"
								alwaysShow={true}
								style="outline"
							/>
							<div className="row">
								{team && ab ? (
									<div className="flag-image">
										<img
											src={this.props?.flagImagePathSmall.replace(
												'<code>',
												selectedMatch[team][0][`nation${ab}`]
											)}
										/>
									</div>
								) : null}

								{statusClass['statusClass'] === 'in-progress' ? (
									<div className={`player-status ${statusClass['statusClass']}`}>
										{statusClass['status']}
									</div>
								) : null}
							</div>
						</div>
					</div>
					<div className="bottom">
						<p className={`player-status ${statusClass['statusClass']}`}>{statusClass['status']}</p>
						{selectedMatch?.shortEventName ? (
							<p>
								<b>
									{selectedMatch?.shortEventName}{' '}
									{selectedMatch?.roundNameShort ? selectedMatch?.roundNameShort : ''}
								</b>
							</p>
						) : null}

						{opponentTeam ? (
							<p>
								VS. {selectedMatch[opponentTeam][0].displayNameA}
								{selectedMatch && opponentTeam
									? selectedMatch[opponentTeam][0]?.seed
										? ` [${selectedMatch[opponentTeam][0]?.seed}]`
										: ''
									: ''}
								{/** for doubles */
								selectedMatch[opponentTeam][0].displayNameB ? (
									<>
										{', ' + selectedMatch[opponentTeam][0].displayNameB}
										{selectedMatch && opponentTeam
											? selectedMatch[opponentTeam][0]?.seed
												? ` [${selectedMatch[opponentTeam][0]?.seed}]`
												: ''
											: ''}
									</>
								) : null}
							</p>
						) : null}
					</div>
					<div className="bio-link">
						<EventsLink
							to={bioLink}
							measureData={{
								...this.measureData,
							}}>
							Player Bio
						</EventsLink>
					</div>
				</div>
			);
		}
	};

	render() {
		/**
		 * [TODO] Check the timing of the player status files creation to avoid throwing 404
		 *  e.g. feed) https://www-cdt.usopen.org/en_US/scores/feeds/2021/players/status/atpd643.json
		 */
		this.favorites = this.props?.favorites?.players;
		let settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow:
				this.props.EventsWindow?.numericWindowSize < 2
					? 2
					: this.props.EventsWindow?.numericWindowSize < 3
					? 3
					: 4, // max 767px - display 2
			slidesToScroll: 1,
			swipeToSlide: true,
			adaptiveHeight: true,
			beforeChange: (oldIndex, newIndex) => {
				this.measureNextPrev(oldIndex, newIndex);
			},
		};

		logger.log('[HomeFavorites] render - this: %o', this);
		// logger.log(
		// 	'[HomeFavorites] render - this %o, favorites:%o, playerStatsdata:%o',
		// 	this,
		// 	this.props?.favorites.players,
		// 	op.get(this.props, `playerStatusData.${this.favorites}`, false)
		// );

		this.favNum = 0;

		if (this.state?.allPlayers?.length > 0 && this.props?.favorites?.players?.length > 0) {
			this.favNum = getAvailableFavPlayersNum(this.state?.allPlayers, this.props?.favorites?.players);
		}

		return (
			<section className="home-favorites">
				<div className="title-bar">
					<h3 className="star solid">Favorites ({this.favNum})</h3>
					<span className={`find-player ${this.favorites?.length == 0 ? 'right' : ''}`}>
						<EventsLink
							to="/en_US/players/index.html"
							measureData={{
								...this.measureData,
							}}>
							Find a Player
						</EventsLink>
					</span>
					{this.favNum > 0 ? (
						<span className="toggle-icon" onClick={() => this.toggleOpen()} ref={this.expandRef}>
							<i className={`${this.state.open ? 'icon-up-arrow' : 'icon-down-arrow'}`}></i>
						</span>
					) : null}
				</div>
				<div className="home-favorites-content">
					{this.state?.sortedPlayersList?.length > 0 && this.favorites?.length > 0 ? (
						<div className={`favorites-list ${this.state.open ? 'open' : ''}`}>
							<Slider {...settings}>
								{this.state?.sortedPlayersList?.map((player, index) => {
									return this.getFavoritePlayerBasicCard(player, index);
								})}
							</Slider>
						</div>
					) : (
						<div className="welcome">
							<EventsLink
								to="/en_US/players/index.html"
								measureData={{
									...this.measureData,
								}}>
								<img src="/assets/images/misc/favorites-promo.png" />
								{/* <h4>New For 2022</h4> */}
								<p>Start following your favorite players &gt;</p>
							</EventsLink>
						</div>
					)}
				</div>
			</section>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeFavorites);
