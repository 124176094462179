import deps from "dependencies";

export default (state = {}, action) => {
  let newState;
  let filter;
  switch (action.type) {
    case deps.actionTypes.GALLERYLANDING_LOAD:
      filter = {
        ...state.filter,
        year: action.data.year
      };

      newState = {
        ...state,
        path: action.data.path,
        relatedContentPath: action.data.relatedContentPath,
        archive: action.data.archive,
        status: "load",
        filter: filter,
        newsFilterPath: action.data.newsFilterPath,
        heroPath: action.data.heroPath,
        newsImagePath: action.data.newsImagePath,
        adConfig: action.data.adConfig,
        adMode: action.data.adMode,
        sponsor: action.data.sponsor
      };
      logger.log(
        "[GalleryLanding] GALLERYLANDING_LOAD - newState:%o",
        newState
      );
      return newState;
      break;

    case deps.actionTypes.GALLERYLANDING_FILTER:
      logger.log(
        "[GalleryLanding] GALLERYLANDING_FILTER - data:%o",
        action.data
      );

      filter = {
        ...state.filter,
        ...action.data
      };

      newState = {
        ...state,
        path: action.data.path,
        relatedContentPath: action.data.relatedContentPath,
        archive: action.data.archive,
        status: "load",
        filter: filter
      };
      logger.log(
        "[GalleryLanding] GALLERYLANDING_FILTER - newState:%o",
        newState
      );
      return newState;
      break;

    default:
      return state;
  }
};
