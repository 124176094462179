import React, { Fragment, Component } from 'react';
import Template from 'components/Template';
import { connect } from 'react-redux';
import AdTag from 'appdir/components/general/AdTag';
import op from 'object-path';

const mapStateToProps = (state, props) => {
	return {
		...state['AdsLanding'],
		adConfig: state['Config'].adConfig,
		...props,
	};
};

class AdsLanding extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		logger.log('[AdsLanding] - render props:%o', this.props);
		let header_propsData = {
			headerType: 'home',
			metaDescription:
				'The official site of the 2024 US Open Tennis Championships. Follow the action with live scoring, live radio, news and highlights. Buy tickets and plan your visit.',
			canonicalLink: 'https://www.usopen.org/index.html',
		};

		let adTypes = ['homehero', 'homeLeader', 'landingpage', 'article_1'];

		return (
			<Template header={header_propsData} subHeader={false}>
				{this.props.adConfig ? (
					<section className="page-content adslanding">
						<h1>IBM Ads</h1>
						{adTypes.map((item, i) => {
							let strAdConfig = op.get(this.props.adConfig, item);

							return (
								<div key={i}>
									{strAdConfig.adUnit}:
									<AdTag adConfig={strAdConfig} dfpNetworkId={this.props.adConfig.dfpNetworkId} />
									<br />
								</div>
							);
						})}
					</section>
				) : null}
			</Template>
		);
	}
}
export default connect(mapStateToProps)(AdsLanding);
