import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsights
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadOverlayModal: (type, data) => dispatch(deps.actions.ModalOverlay.loadOverlayModal(type, data)),
});

class MatchInsights extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		logger.log('[MatchInsights] props:%o', this.props);
	}

	componentDidMount() {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			match: this.props.match.params.matchId,
		});

		this.props.loadOverlayModal('MATCH_INSIGHTS', { matchId: this.props.match.params.matchId });
	}

	render() {
		let header_propsData = {
			headerType: 'scores',
			title: 'Match Insights',
			metaTitle: 'Match Insights',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/matchinsights/index.html',
					title: 'Match Insights',
				},
			],
			title: 'Match Insights',
		};

		return (
			<Template
				header={header_propsData}
				subHeader={this.props.windowSize !== 'mobile' ? subheader_attributes : null}></Template>
		);
	}
}

MatchInsights.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsights);
