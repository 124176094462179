import Config from './index';
import deps from 'dependencies';

export default {
	// Route pattern
	path: ['/demo/config'],

	// Route should be exact
	exact: true,

	// the component to load for this route
	component: Config,

	// load callback should return thunk that uses route params.
	load: (params) => deps.actions.Config.update(params)
};
