/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import EventsLink from 'components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: AZItem
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const AZItem = props => {
	// logger.log('[AZItem] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	// logger.log('[AZItem] - attr:%o', attributes);

	if (attributes) {
		let image = attributes['photo'];

		return (
			<div className="compact-item">
				{image.length > 10 && (
					<div className="compact-photo">
						<img src={image} alt={attributes['title']} />
					</div>
				)}
				<div className="compact-column">
					<div className="compact-title">
						<h3>{attributes['title']}</h3>
					</div>
					<div className="compact-content">
						{ReactHtmlParser(attributes['html'])}
						{/* {ReactHtmlParser(attributes['html'], {
							transform(node, index) {
								logger.log('[AZItem] ReactHtmlParser - node:%o', node);
								if (node.type === 'a') { //=== 'tag' && node.name 
									logger.log('[AZItem] ReactHtmlParser - node:%o', node);
									return (
										<EventsLink key={index} to={node.props['href']}>
											{node.children[0].data}
										</EventsLink>
									);
								}
							},
						})} */}
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default AZItem;
