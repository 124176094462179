import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				status: 'loaded',
			};

			logger.log('[InsightsIndex] action.mount - config: %o', Config);

			dispatch({ type: deps.actionTypes.INSIGHTS_INDEX_MOUNT, data: data });
		});
	},
};
