/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: NotFound
 * -----------------------------------------------------------------------------
 */
const NotFound = props => {
	return <div>PAGE NOT FOUND</div>;
};

export default NotFound;
