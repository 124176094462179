import { deviceDetect, isWearable, isMobileOnly, isTablet, isBrowser, isSmartTV } from 'react-device-detect';
import axios from 'axios';

import { measureInApp } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';

export const MENU_ITEMS = {
	news: 'News',
	scoresSchedule: 'Scores & Schedule',
	draws: 'Draws',
	players: 'Players',
	videoRadio: 'Video & Radio',
	visitTickets: 'Visit & Tickets',
	about: 'About',
	foundation: 'Foundation',
	shop: 'Shop',
};

export const externalLinkRegex = /^(http|https|www)/;
export const webviewRegex = /^\/webview\//;
/* add config for year */
export const eventsLinkRegex = /^(?:(http|https)?:\/\/.*(2023|2022|2021|2020|2019|www|ashe|secure)\.?usopen.org)?/i;
export const playerRegex = /players\/overview\/([a-z0-9]*)\.html/;
export const newsRegex = /\/en_US\/news\/articles\/.*.html/;
export const contentRegex = /\/en_US\/(us-open-at-home|visit).*/;
export const galleryRegex = /\/en_US\/news\/galleries\/.*.html/;
export const statsRegex = /\/en_US\/scores\/stats\/(\d*)\.html/;
export const videoRegex = /\/en_US\/video\/media\/(.*).html/;
export const mapRegex = /\/en_US\/interactive_map\/index.html/;
export const fanweekRegex = /\/en_US\/about\/us_open_fan_week.html/;
export const amexRegex = /\/en_US\/visit\/american_express_on_site_benefits.html/;
export const fanpassRegex = /\/en_US\/social\/fanaccesspass\/index.html/;
export const liveVideoRegex = /\/en_US\/video\/live.html\?court=(.*)/;
export const insightsRegex = /\/en_US\/matchinsights\/(\d*)\.html/;
export const insightsIndexRegex = /\/en_GB\/matchinsights\/(index)\.html/;
export const powerrankRegex = /\/en_US\/powerindex\/.*.html/;
export const eventSchedRegex = /\/en_US\/about\/eventschedule.html/;
export const drawsRegex = /\/en_US\/draws\/.*/;
export const livePracticeRegex = /\/en_US\/video\/practice-courts\/court-p(\d*)\.html/;

export const addMatchFavRegex = /\/removeMatchFav\/(\d*)\.html/;
export const removeMatchFavRegex = /\/addMatchFav\/(\d*)\.html/;
export const addArticleFavRegex = /\/removeArticleFav\/(.+).html/;
export const removeArticleFavRegex = /\/addArticleFav\/(.+).html/;
export const addPlayerFavRegex = /\/addPlayerFav\/(.+).html/;
export const removePlayerFavRegex = /\/removePlayerFav\/(.+).html/;

export const pdfRegex = /.*\.pdf/;
export const imgRegex = /.*\.(jpg|jpeg|png|gif)/;

export const getCleanLink = url => {
	// make usopen links relative
	url = url.replace(eventsLinkRegex, '');
	url == '' ? (url = '/') : null;
	//logger.log("[Util] getCleanLink url:%o", url);

	// check if still external for std link or make any pdf link external
	let external = url.match(externalLinkRegex) ? url.match(externalLinkRegex).length > 0 : false;
	external = url.match(pdfRegex) ? url.match(pdfRegex).length > 0 : external;

	external = url.match(imgRegex) ? url.match(imgRegex).length > 0 : external;
	//logger.log("[Util] isExternal getCleanLink url:%o", url);

	return {
		url: url,
		external: external,
	};
};

export const getScrollPos = () => {
	let supportPageOffset = window.pageXOffset !== undefined;
	let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
	let scroll = {
		x: supportPageOffset
			? window.pageXOffset
			: isCSS1Compat
			? document.documentElement.scrollLeft
			: document.body.scrollLeft,
		y: supportPageOffset
			? window.pageYOffset
			: isCSS1Compat
			? document.documentElement.scrollTop
			: document.body.scrollTop,
	};

	return scroll;
};

//determine image size to render based on device application is running on
export const getPlatformImageSize = platform => {
	switch (platform) {
		case 'mobile':
			return 'medium';

		case 'landscape':
			return 'medium';

		case 'tablet':
			return 'xlarge';

		case 'smdesktop':
			return 'xlarge';

		case 'desktop':
			return 'xlarge';

		default:
			return 'large';
	}
};

export const getComponents = types => {
	let cmps = {};

	types = typeof types === 'string' ? [types] : types;
	//logger.log('[App] types:', types);
	types.forEach(cname => {
		let req, cpath;

		let paths = [
			cname,
			`${cname}/index`,
			`components/${cname}`,
			`components/${cname}/index`,
			`components/cms/${cname}`,
			`components/cms/${cname}/index`,
			`components/common-ui/${cname}`,
			`components/common-ui/${cname}/index`,
			`components/general/${cname}`,
			`components/general/${cname}/index`,
			`components/content/${cname}`,
			`components/content/${cname}/index`,
			`components/data/${cname}`,
			`components/data/${cname}/index`,
		];

		while (!req && paths.length > 0) {
			cpath = paths.shift();

			try {
				//logger.log('[Util] getComponents - approot:%o cpath:%o', '', cpath);
				req = require('compdir/' + cpath);
				// let requiredPath = `./Users/Micah/Desktop/Sports-GBS/US-Open/usopen-react2/src/app/${cpath}`;
				// req = require("./src/app/" + cpath);
				// req = require(requiredPath);

				logger.log('[Util] getComponents - %o found at %o ', cname, cpath);
			} catch (err) {
				// logger.error('[Util] getComponents - error:%o', err);
			}

			if (!req) {
				try {
					req = require(cpath + '');
					logger.log('[Util] getComponents - %o found at %o ', cname, cpath);
				} catch (err) {
					// logger.error('[Util] getComponents - error:%o', err);
				}
			}

			if (req) {
				if (req.hasOwnProperty('default')) {
					req = req.default;
				}
				break;
			} else {
				//logger.error('[Util] getComponents - no component');
			}

			// logger.log('[Util] getComponents - cname:%o, cpath:%o', cname, cpath);
		}

		if (req) {
			cmps[cname] = req;
		}
	});

	return cmps;
};

// //resusable data validation function for checking if right data type is returned
export const validateData = (data, dataType) => {
	//dataType map for returning true or false if first function parameter (data) matches second parameter (data type)
	let validDataMap = {
		array: {
			validate: d => {
				return Array.isArray(d);
			},
		},
		json: {
			validate: d => {
				let jsonData = typeof d !== 'string' ? JSON.stringify(d) : d;
				try {
					JSON.parse(jsonData);
					return true;
				} catch (e) {
					return false;
				}
			},
		},
		string: {
			validate: d => {
				return typeof d === 'string';
			},
		},
		int: {
			validate: d => {
				return typeof d == 'number' && d % 1 === 0;
			},
		},
		float: {
			validate: d => {
				return typeof d == 'number' && d % 1 !== 0;
			},
		},
	};
	//return validation boolean of data and its assumed data type
	return validDataMap[dataType].validate(data);
};

export const getAppLink = (url, title = '', bypassAppLink = false) => {
	// logger.log('[Util] getAppLink url:%o', url);
	let playerMatch = url.match(playerRegex);
	let articleMatch = url.match(newsRegex);
	let amexMatch = url.match(amexRegex) && window.webviewPlatform == 'ios' ? true : false;
	let contentMatch = url.match(contentRegex);
	let galleryMatch = url.match(galleryRegex);
	let slamtrackerMatch = url.match(statsRegex);
	let videoMatch = url.match(videoRegex);
	let mapMatch = url.match(mapRegex);
	// let fanweekMatch = url.match(fanweekRegex) && window.webviewPlatform == 'ios' ? true : false;
	let fanpassMatch = url.match(fanpassRegex);
	let liveVideoMatch = url.match(liveVideoRegex);
	let livePracticeMatch = url.match(livePracticeRegex);
	let insightsMatch = url.match(insightsRegex);
	let insightsIndexMatch = url.match(insightsIndexRegex);
	let powerrankMatch = url.match(powerrankRegex);
	let eventSchedMatch = url.match(eventSchedRegex);
	let addMatchFav = url.match(addMatchFavRegex);
	let removeMatchFav = url.match(removeMatchFavRegex);
	let addArticleFav = url.match(addArticleFavRegex);
	let removeArticleFav = url.match(removeArticleFavRegex);
	let addPlayerFav = url.match(addPlayerFavRegex);
	let removePlayerFav = url.match(removePlayerFavRegex);
	let drawsMatch = url.match(drawsRegex);

	let appProtocol;
	if (window.webviewPlatform == 'ios') {
		appProtocol = 'ustaopen';
		if (location.hostname.indexOf('-cdt') > -1) {
			appProtocol = 'ustaopen-cdt';
		} else if (location.hostname.indexOf('ashe.') > -1) {
			appProtocol = 'ustaopen-pre';
		}
	} else {
		appProtocol = 'ustaopen';
		if (location.hostname.indexOf('-cdt') > -1) {
			appProtocol = 'ustaApp-cdt';
		} else if (location.hostname.indexOf('ashe.') > -1) {
			appProtocol = 'ustaApp-pre';
		}
	}
	let linkhost = 'www';
	if (location.hostname.indexOf('-cdt') > -1) {
		linkhost = 'www-cdt';
	} else if (location.hostname.indexOf('ashe.') > -1) {
		linkhost = 'ashe';
	}

	if (bypassAppLink) {
		/** to avoid multiple webview windows open up in app for deeper links,
		 * provide a way to bypass webview link and use simple to link navigating b/w webviews
		 */

		return {
			url: url,
			external: false,
		};
	}
	// match favorite url
	else if (addMatchFav) {
		let link = {
			url: '',
			external: true,
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] addMatchFav - window.webkit.messageHandlers.addMatch.postMessage(%o)',
								addMatchFav[1]
							);
							window.webkit.messageHandlers.addMatch.postMessage(addMatchFav[1]);
					  }
					: () => {
							logger.info('[Util] addMatchFav - window.JSInterface.addMatch(%o)', addMatchFav[1]);
							window.JSInterface.addMatch(addMatchFav[1]);
					  },
		};
		//logger.info('[Util] addMatchFavRegex - match:%o, link:%o', addMatchFav, link);

		return link;
	} else if (removeMatchFav) {
		let link = {
			url: '',
			// url:
			// 	window.webviewPlatform == 'ios'
			// 		? `javascript:window.webkit.messageHandlers.removeMatch.postMessage('${removeMatchFav[1]}');`
			// 		: `javascript:window.JSInterface.removeMatch('${removeMatchFav[1]}');`,
			external: true,
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] removeMatchFav - window.webkit.messageHandlers.removeMatch.postMessage(%o)',
								removeMatchFav[1]
							);
							window.webkit.messageHandlers.removeMatch.postMessage(removeMatchFav[1]);
					  }
					: () => {
							logger.info(
								'[Util] removeMatchFav - window.JSInterface.removeMatch(%o)',
								removeMatchFav[1]
							);
							window.JSInterface.removeMatch(removeMatchFav[1]);
					  },
		};
		//logger.info('[Util] removeMatchFavRegex - match:%o, link:%o', removeMatchFav, link);

		return link;
	}

	// Article favorite url
	else if (addArticleFav) {
		let link = {
			url: '',
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] addArticleFav - window.webkit.messageHandlers.addArticle.postMessage(%o)',
								addArticleFav[1]
							);
							window.webkit.messageHandlers.addArticle.postMessage(addArticleFav[1]);
					  }
					: () => {
							logger.info('[Util] addArticleFav - window.JSInterface.addArticle(%o)', addArticleFav[1]);
							window.JSInterface.addArticle(addArticleFav[1]);
					  },
			external: true,
		};
		//logger.info('[Util] addArticleFavRegex - article:%o, link:%o', addArticleFav, link);

		return link;
	} else if (removeArticleFav) {
		let link = {
			url: '',
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] removeArticleFav - window.webkit.messageHandlers.removeArticle.postMessage(%o)',
								removeArticleFav[1]
							);
							window.webkit.messageHandlers.removeArticle.postMessage(removeArticleFav[1]);
					  }
					: () => {
							logger.info(
								'[Util] removeArticleFav - window.JSInterface.removeArticle(%o)',
								removeArticleFav[1]
							);
							window.JSInterface.removeArticle(removeArticleFav[1]);
					  },
			external: true,
		};
		//logger.info('[Util] removeArticleFavRegex - article:%o, link:%o', removeArticleFav, link);

		return link;
	}

	// Player favorite url
	else if (addPlayerFav) {
		let link = {
			url: '',
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] addPlayerFav - window.webkit.messageHandlers.addPlayerFavorite.postMessage(%o)',
								addPlayerFav[1]
							);
							window.webkit.messageHandlers.addPlayerFavorite.postMessage(addPlayerFav[1]);
					  }
					: () => {
							logger.info(
								'[Util] addPlayerFav - window.JSInterface.addPlayerFavorite(%o)',
								addPlayerFav[1]
							);
							window.JSInterface.addPlayerFavorite(addPlayerFav[1]);
					  },
			external: true,
		};
		//logger.info('[Util] addPlayerFavRegex - player:%o, link:%o', addPlayerFav, link);

		return link;
	} else if (removePlayerFav) {
		let link = {
			url: '',
			onclick:
				window.webviewPlatform == 'ios'
					? () => {
							logger.info(
								'[Util] removePlayerFav - window.webkit.messageHandlers.removePlayerFavorite.postMessage(%o)',
								removePlayerFav[1]
							);
							window.webkit.messageHandlers.removePlayerFavorite.postMessage(removePlayerFav[1]);
					  }
					: () => {
							logger.info(
								'[Util] removePlayerFav - window.JSInterface.removePlayerFavorite(%o)',
								removePlayerFav[1]
							);
							window.JSInterface.removePlayerFavorite(removePlayerFav[1]);
					  },
			external: true,
		};
		//logger.info('[Util] removePlayerFavRegex - player:%o, link:%o', removePlayerFav, link);

		return link;
	}

	// match player url
	if (playerMatch) {
		// logger.info("[Util] playerRegex - match:%o", playerMatch);
		let link = {
			url:
				window.webviewPlatform == 'ios'
					? `javascript:window.webkit.messageHandlers.playerDetails.postMessage('${playerMatch[1]}');`
					: `http://usopen/player?id=${playerMatch[1]}`,
			external: true,
		};
		// logger.info(
		//   "[Util] playerRegex - match:%o, link:%o",
		//   playerMatch,
		//   link
		// );

		return link;
	}
	// article match
	else if (articleMatch) {
		let filename = articleMatch[0].match(/([^\/]+)(?=\.\w+$)/)[0];
		// let articleId = `uso_2020_${filename}_en`;
		// old url string: `http://usopen/article?url=/webview${articleMatch[0]}&shareUrl=${articleMatch[0]}&title=${title}`,
		let link = {
			url:
				window.webviewPlatform == 'ios'
					? `javascript:window.webkit.messageHandlers.articles.postMessage('${filename}');`
					: `http://${appProtocol}/article?id=${filename}`,
			external: false,
		};

		return link;
	}

	// slamtracker match
	else if (slamtrackerMatch) {
		logger.log('[Util] getAppLink slamtrackerMatch:%o', slamtrackerMatch);

		let link = {
			url:
				window.webviewPlatform == 'ios'
					? `javascript:window.webkit.messageHandlers.matchDetails.postMessage('${slamtrackerMatch[1]}');`
					: `http://usopen/match?id=${slamtrackerMatch[1]}`,
			external: true,
		};
		//: `javascript:window.JSInterface.matchDetails('${slamtrackerMatch[1]}');`,

		// logger.info(
		//   "[Util] slamtrackerMatchRegex - match:%o, link:%o",
		//   slamtrackerMatch,
		//   link
		// );

		return link;
	}

	// Practive Court Live Video
	else if (livePracticeMatch) {

		let link = {
			url:
				window.webviewPlatform == 'ios'
					? `javascript:window.webkit.messageHandlers.practiceCourt.postMessage('P${livePracticeMatch[1]}');`
					: `http://usopen/practice?id=P${livePracticeMatch[1]}`,
			external: false,
		};
		// logger.log('[Util] getAppLink livePracticeMatch:%o, link:%o', livePracticeMatch, link);
		return link;
	}

	// photo match
	else if (false) {
		return {
			url: url,
			external: false,
		};
	}

	// gallery match
	else if (false) {
		return {
			url: url,
			external: false,
		};
	}

	// video match
	else if (videoMatch) {
		return {
			url: `http://usopen/video?id=${videoMatch[1]}`,
			external: false,
		};

		// map match
	} else if (mapMatch) {
		return {
			url: `${appProtocol}:///maps`,
			external: false,
		};

		// 	// fanweek match
		// } else if (fanweekMatch) {
		// 	return {
		// 		url: `${appProtocol}:///fanweekSchedule`,
		// 		external: false,
		// 	};

		// fanpass match
	} else if (fanpassMatch) {
		return {
			url: `${appProtocol}:///open_access`,
			external: false,
		};

		// amex match - disabled
		// } else if (amexMatch) {
		// 	return {
		// 		url: `${appProtocol}:///amexexp`,
		// 		external: false,
		// 	};

		// live video match
	} else if (liveVideoMatch) {
		logger.log('[Util] getAppLink liveVideoMatch:%o', liveVideoMatch);
		return {
			url: `${appProtocol}:///live_video?court=${liveVideoMatch[1]}`,
			external: false,
		};
	}

	// if a content link which can be handled by webview
	else if (contentMatch) {
		url = url.replace(eventsLinkRegex, '');
		url == '' ? (url = '/') : null;
		return {
			url: `https://${linkhost}.usopen.org/webview`.concat(url),
			external: false,
		};
	}

	//match insights
	else if (insightsMatch) {
		logger.log('[Util] getAppLink insightsMatch:%o', slamtrackerMatch);

		let link = {
			url:
				window.webviewPlatform == 'ios'
					? `javascript:window.webkit.messageHandlers.matchInsights.postMessage('${insightsMatch[1]}');`
					: `http://usopen/matchinsights?matchid=${insightsMatch[1]}`,
			external: false,
		};
		return link;
	}

	// insights index
	else if (insightsIndexMatch) {
		let path = '';

		if (window.webviewPlatform == 'ios') {
			path = 'http://usopen/matchInsightsLanding';
		} else {
			path = 'http://usopen/matchinsights';
		}

		return {
			url: path,
			external: false,
		};
	}

	// match powerrank
	else if (powerrankMatch) {
		logger.log('[Util] getAppLink powerrankMatch:%o', powerrankMatch);
		let link = '';

		if (powerrankMatch[1]) {
			link = {
				url:
					window.webviewPlatform == 'ios'
						? `javascript:window.webkit.messageHandlers.powerrankings.postMessage('${powerrankMatch[1]}');`
						: `http://usopen/powerranking?playerId=${powerrankMatch[1]}`,
				external: false,
			};
		} else {
			link = {
				url:
					window.webviewPlatform == 'ios'
						? `javascript:window.webkit.messageHandlers.powerrankings.postMessage('');`
						: `http://usopen/powerranking`,
				external: false,
			};
		}

		logger.log('[Util] getAppLink powerrankMatch - link:%o', link);
		return link;
	}

	// draws match
	/** the playerId query string enables Path to the Final overlay on the draws page */
	else if (drawsMatch) {
		//"/en_US/draws/mens-singles.html?event=MS&view=bracket&playerId=atprh16"

		let matchedVal = drawsMatch[0]?.split('?');
		let link = '';

		if (matchedVal[1]) {
			// "event=MS&view=bracket&playerId=atprh16"
			link = {
				url:
					window.webviewPlatform == 'ios'
						? `javascript:window.webkit.messageHandlers.draws.postMessage('${matchedVal[1]}');`
						: `http://usopen/draws?${matchedVal[1]}`,
				external: false,
			};
		} else {
			link = {
				url:
					window.webviewPlatform == 'ios'
						? `javascript:window.webkit.messageHandlers.powerrankings.postMessage('');`
						: `http://usopen/powerranking`,
				external: false,
			};
		}
		logger.info('[Util] drawsMatchRegex - link:%o', link);

		return link;
	} else if (eventSchedMatch) {
		url = url.replace(eventsLinkRegex, '');
		url == '' ? (url = '/') : null;
		return {
			url: `https://${linkhost}.usopen.org/webview`.concat(url),
			external: false,
		};
	} else if (url.indexOf('#') == 0) {
		return {
			url: url,
			external: false,
		};
	}
	// if not handled by app or content webview, link externally
	else {
		// logger.log('[Util] getAppLink external:%o', url);
		if (url.indexOf('?') > 0 && url.indexOf('external=') < 0) {
			url = url.replace(/\?/, '?external=true&');
			// logger.log('[Util] getAppLink noext:%o', url);
		} else if (url.indexOf('?') > 0 && url.indexOf('external=') > 0) {
			url = url;
			// logger.log('[Util] getAppLink qs:%o', url);
		} else {
			url = url + '?external=true';
			// logger.log('[Util] getAppLink noqs:%o', url);
		}

		return {
			url: url,
			external: true,
		};
		// if (!url.match(webviewRegex) && !url.match(externalLinkRegex)) {
		// 	return {
		// 		url: '/webview'.concat(url),
		// 		external: false
		// 	};
		// }
		// else {
		// 	return {
		// 		url: url,
		// 		external: false
		// 	};
		// }
	}
};

export class DelayExecutor {
	constructor() {
		this.promise = Promise.resolve();
	}

	delay(fn, ms) {
		this.promise = this.promise.then(() => 
		new Promise(resolve => setTimeout(() => {
			fn();
			resolve();
		}, ms))
		);
		return this;
	}
}

/** 
JS Interface

Handled by apps (string is function we call)
	case news = "news" //Pass news ID or nil
    case map = "map" //No Value accepted, will just open map section
   
    case video = "video" //Pass video ID
    case match = "match" //Pass a match ID, for use in scores/slamtracker
    case photo = "photo" //Pass photo ID
    case gallery = "gallery" //Pass gallery ID
    case share = "share" //Pass share URL
    case metrics = "metrics" //Pass metrics state text

	DATA EXAMPLE
	message modal:
		{
			title: 'Modal Title',
			text: 'modal text'
		}
*/

export const getInterfaceLink = (type, data) => {
	switch (type) {
		case 'map':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.map.postMessage('map') }
						: () => { window.JSInterface.map() }
			};

		case 'video':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.video.postMessage(data.id) }
						: () => { window.JSInterface.map(data.id) }
			};

		case 'news':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.news.postMessage(data.id) }
						: () => { window.JSInterface.map(data.id) }
			};

		case 'catchup':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.catchup.postMessage(data.id) }
						: () => { window.JSInterface.catchup(data.id) }
			};

		case 'liveMatches':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.liveMatches.postMessage(data.id) }
						: () => { window.JSInterface.liveMatches() }
			};

		case 'openImmersive':
			return {
				url: '',
				external: true,
				appLink: true,
				onclick:
					window.webviewPlatform == 'ios'
						? () => { window.webkit.messageHandlers.openImmersive.postMessage(data.id) }
						: () => { window.JSInterface.openImmersive(data.id) }
			};

		default:
			break;
	}
}

export const getAppVideoLink = (id) => {
	return {
		url: '',
		external: true,
		appLink: true,
		onclick:
			window.webviewPlatform == 'ios'
				? () => { window.webkit.messageHandlers.video.postMessage(id) }
				: () => { window.JSInterface.map(id) }
	};
}

export const isNumeric = data => {
	return !isNaN(parseFloat(data)) && isFinite(data);
};

export const windowSizeVals = {
	MOBILE_WINSIZE: 0,
	LANDSCAPE_WINSIZE: 1,
	SMTABLET_WINSIZE: 2,
	TABLET_WINSIZE: 3,
	SMDESKTOP_WINSIZE: 4,
	DESKTOP_WINSIZE: 5,
	LGDESKTOP_WINSIZE: 6,
};

export const getScreenType = () => {
	if (isMobileOnly) {
		return 'Phone';
	} else if (isTablet) {
		return 'Tablet';
	} else if (isBrowser) {
		return 'PC';
	} else if (isWearable) {
		return 'Wearable';
	} else if (isSmartTV) {
		return 'SmartTv';
	}
};

/**
 * get parsed querystring object
 */
export const getQuerystringValues = loc => {
	let search = loc ? loc : location.search.replace(/^\?/, '');
	//logger.log('[Util] getQuerystringValues - loc: %o: search:%o', loc, '{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}');

	let params = {};
	search.split('&').forEach(function(pair) {
		pair = pair.split('=');
		if (pair[1] !== undefined) {
			let key = decodeURIComponent(pair[0]);
			let val = decodeURIComponent(pair[1]);
			val = val ? val.replace(/\++/g, ' ').trim() : '';

			if (key.length === 0) {
				return;
			}
			if (params[key] === undefined) {
				params[key] = val;
			} else {
				if ('function' !== typeof params[key].push) {
					params[key] = [params[key]];
				}
				params[key].push(val);
			}
		}
	});

	return params;
};

export const getQuerystringHash = loc => {
	let formatValue = '';
	//get querystring hash values
	let hashValue = loc ? loc : window.location.hash.replace(/[#-]/g, ' ').trim();

	if (hashValue !== '') {
		//format values to camel case
		let tempQS = hashValue.split(' ');

		formatValue = tempQS
			.map(item => {
				return item[0].toUpperCase() + item.substr(1);
			})
			.join(' ');
	}
	logger.log('[Util] - getQuerystringHash formatValue:%o', formatValue);
	return formatValue;
};

export const getDeviceDetails = () => {
	return deviceDetect();
};

export const restHeaders = () => {
	return {};
};

export const fetch = path => {
	let hdr = restHeaders();
	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch(error => {
			//logger.log('[Util] fetch - error:%o', error);
			if (error.response && error.response.status == 404) {
				//logger.log('[Util] fetch - error1:%o', error.response);
				throw error.response;
			} else {
				//logger.log('[Util] fetch - error2:%o', error);
				throw error;
			}
		});
};

export const fetchAll = paths => {
	let hdr = restHeaders();
	let pathList = [];
	const pathsType = typeof paths;
	// logger.log('[Util] fetch - paths:%o', paths);

	if (Array.isArray(paths)) {
		pathList = paths;
	} else {
		return new Error('Invalid type for paths');
	}
	// logger.log('[Util] fetchAll - pathList:%o', pathList);

	return axios
		.all(pathList.map(path => fetch(path, { headers: hdr })))
		.then(function(results) {
			// logger.log('[Util] fetchAll - results:%o', results);

			const result = results.map(response => {
				// logger.log('[Util] fetchAll - 200 valid response:%o', response);
				return response;
			});

			// logger.log('[Util] fetchAll - result:%o', result);

			let data = Array.isArray(paths) ? result : Object.assign({}, ...result);
			// logger.log('[Util] fetchAll - return data:%o', data);

			return data;
		})
		.catch(error => {
			// logger.log('[Util] fetchAll - error:%o', error);
			throw error;
		});
};

/** create a new promise to use in fetchPromiseAll() */
export const fetchNewPromise = path => {
	logger.log('[Util] fetchPromise path:%o', path);
	return new Promise((resolve, reject) => {
		try {
			fetch(path)
				.then(result => {
					logger.log('[Util] fetchPromise result:%o', result);
					return resolve(result);
				})
				.catch(error => {
					logger.error('[Util] fetchPromise error:%o', error);
					return resolve({ status: 'error', error });
				});
		} catch (error) {
			logger.error('[Util] fetchPromise error:%o', error);
			return resolve({ status: 'error', error });
		}
	});
};

/** gets an array of paths and create a new Promise for each and perform Promise.all */
export const fetchPromiseAll = paths => {
	let pathList = [];
	if (Array.isArray(paths)) {
		pathList = paths;
	} else {
		return new Error('Invalid type for paths');
	}

	return Promise.all(pathList.map(path => fetchNewPromise(path)));
};

/** icon fonts class name lookups */
export const getIconClass = iconName => {
	let klass = '';

	switch (iconName.toLowerCase()) {
		case 'ball':
			klass = 'icon-blogIcon-scoring';
			return klass;
			break;

		case 'tickets':
			klass = 'icon-tickets';
			return klass;
			break;

		case 'news':
			klass = 'icon-blogIcon-news';
			return klass;
			break;

		case 'mobile':
			klass = 'icon-mobile';
			return klass;
			break;

		case 'plans':
			klass = 'icon-plans';
			return klass;

		case 'hospitality':
			klass = 'icon-hospitality';
			return klass;
			break;

		default:
			return klass;
	}
};

/** sort favorite players list alphabetically
 *  @Array allPlayers - players.json data
 *  @Array favorites -- Redux Favorites['players']
 */
export const sortFavPlayers = (allPlayers, favorites) => {
	let tmpData = [];
	let sortedPlayers;

	favorites.map((player, index) => {
		let tmp = allPlayers.filter(p => p.id === player);
		if (tmp.length > 0) {
			tmpData.push(tmp[0]);
		}
	});

	if (tmpData.length > 0) {
		sortedPlayers = tmpData.sort((a, b) => {
			return a?.last_name < b?.last_name ? -1 : 1;
		});
	}

	return sortedPlayers;
};

/** get searchable (available) players on the page, e.g.) Draws, Scores
 *  Note: Schedule page's array data are different as it uses its own method
 */
export const getAvailablePlayers = dataArry => {
	let playerArry = [];

	dataArry.forEach(d1 => {
		let team1_player1a = null,
			team1_player1b = null;
		team1_player1a = {
			firstName: d1.team1.firstNameA,
			lastName: d1.team1.lastNameA,
			id: d1.team1.idA,
		};
		team1_player1b = {
			firstName: d1.team1.firstNameB,
			lastName: d1.team1.lastNameB,
			id: d1.team1.idB,
		};
		playerArry.push(team1_player1a, team1_player1b);

		let team2_player1a = null,
			team2_player1b = null;
		team2_player1a = {
			firstName: d1.team2.firstNameA,
			lastName: d1.team2.lastNameA,
			id: d1.team2.idA,
		};
		team2_player1b = {
			firstName: d1.team2.firstNameB,
			lastName: d1.team2.lastNameB,
			id: d1.team2.idB,
		};
		playerArry.push(team2_player1a, team2_player1b);
	});
	return playerArry.filter(d => d.id !== null);
};

/** set query string that's used to send to apps to set up context call
 *  @Array contextData - [{key: value}, {key:value}, {key:value}]
 */
export const getContextQuery = contextData => {
	let contextQuery = '';

	if (contextData && contextData?.length > 0) {
		contextData.map((item, index) => {
			/** forms &<key>=<value> string */
			if (window.webviewPlatform == 'ios') {
				contextQuery += `&${Object.keys(item)[0]}=${item[Object.keys(item)[0]]}`;
			} else {
				/** convert space to %20, etc for Android */
				contextQuery += `&${encodeURIComponent(Object.keys(item)[0])}=${encodeURIComponent(
					item[Object.keys(item)[0]]
				)}`;
			}
		});
	}

	logger.log('[Util] measurement getContextQuery contextQuery:%o', contextQuery);
	return contextQuery;
};

/**
 * This function will allow you to scroll to a particular element allowing you to pass a
 * offset value.  normal scrollIntoView does not allow that.
 *
 * @param {*} selector
 * @param {*} offset
 */
export const scrollIntoViewWithOffset = (selector, offset) => {
	window.scrollTo({
		behavior: 'smooth',
		top:
			document?.querySelector(selector)?.getBoundingClientRect()?.top -
			document?.body?.getBoundingClientRect()?.top -
			offset,
	});
};

/** set query string that's used to send to apps to set up context call
 *  @Array playersList --- players.json data
 *  @Array favs  --------- Redux Favorites['players']- ['id1', 'id2', 'id3'];
 */
export const getAvailableFavPlayersNum = (playersList, favs) => {
	let favNum = 0;

	if (playersList.length > 0 && favs.length > 0) {
		for (let i = 0; i < favs.length; i++) {
			let tmpNum = 0;
			// debugger;
			tmpNum = playersList.filter(pl => pl.id === favs[i]);

			if (tmpNum.length > 0) {
				favNum++;
			}
		}
	}

	return favNum;
};

/** add st, nd, rd, and th to a number
 *  A. find s[v%10] if >= 20 (20th...99th),
 *  B. if not found, try s[v] (0th..3rd),
 *  C. if still not found, use s[0] (4th..19th)
 */
export const getNumberWithOrdinal = n => {
	var s = ['th', 'st', 'nd', 'rd'],
		v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * Send www or webview metrics depending on
 * the platform that the function is called from
 * 
 * @param {*} pageTitle ----- used for webview only
 * @param {*} measureAction 
 * @param {*} measure_args 
 * @param {*} contextData 
 */
export const doMeasurement = (pageTitle, measureAction, measure_args, contextData) => {
	let measureArray = [];

	if (window.webview) {
		measureArray = ['Metrics', pageTitle].concat(measureAction);

		/** avoid sending an empty value in measure_args */
		if (measure_args && measure_args?.length > 0) {
			measureArray = measureArray.concat(measure_args);
		}
		//awt:  remove join for usta only
		//.join(':');
		logger.log('[Util] measureArray:%o', measureArray);

		/** contextData is new in 2022 for webviews -
		 * passing this will add query strings &key=value to measureInApp
		 * @Array contextData - [{key: value}, {key:value}, {key:value}] */
		measureInApp(measureArray, contextData);
	} else {
		//measureArray = [pageTitle].concat(measureAction).concat(measure_args);
		MeasurementUtils.dispatchMeasurementCall(measureAction, {
			data: measure_args,
		});
	}
};

/*
Share link
http://usopen/share?url=<shareurl>
*** no native in apps

Player Profile
http://usopen/player?id=<playerid>
*** done

News Article
http://usopen/article?url=<shareurl>
http://usopen/news?url=<shareurl>
*** done

Single Photo
http://usopen/photo?url=<photourl>
*** not doing

Gallery​​​​​​​
http://usopen/gallery?id=<gallery content id>
*** not doing

Video​​​​​​​​​​​​​​
http://usopen/video?id=<video content id>

Slamtracker
http://usopen/match?id=<match id>

Map
http://usopen/map

Reload (for font size change)
http://usopen/fontSizeChange

Metrics
http://usopen/track?text=<metrics text to send>
http://usopen/metrics?text=<metrics text to send>
*/
