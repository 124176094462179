import deps from 'dependencies';

export default {
	mount: data => dispatch => {
		return dispatch({ type: deps.actionTypes.AGEGATE_MOUNT, data: data });
	},
	ungate: () => (dispatch, getState, store) => {
		return dispatch({
			type: deps.actionTypes.AGEGATE_UNGATE,
			data: { allowed: true },
		});
	},
};
