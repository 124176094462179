import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * ---------------------------------------
 * HomeContentWrapper - content wrapper for Home
 * use this component and wrap around each home component
 *
 * Show/Hide title
 * Show/Hide sponsor
 * Show/Hide border top
 *
 * props
 * @param {String}  borderTop --------- should match css class, .homeContent-border-<borderTop>
 * @param {Boolean} suppressHeader ---- true to hide title row, default is false
 * @param {String}  shortTitle -------- if not suppressHeader: true, display title
 * @param {String}  sponsor ----------- if not empty,  get matched sponsor info from sponsorsLookUps object in Config
 * ---------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['HomeContentWrapper'],
		EventsWindow: state['WindowSize'].EventsWindow,
		sponsorsLookUps: state['Config'].sponsorsLookUps,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class HomeContentWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		// logger.log('[HomeContentWrapper] props:%o', this.props);

		let { data } = this.props;
		let klass = 'homeContentWrapper';
		let sponsorsLookUps = this.props?.sponsorsLookUps[0];
		let sponsor = data?.sponsor?.toLowerCase();

		if (data?.borderTop && data?.borderTop !== '') {
			klass += ` homeContent-border homeContent-border-${data.borderTop.toLowerCase()}`;
		}

		if (data?.style && data?.style !== '') {
			klass += ` ${data.style}`;
		}

		return (
			<section className={klass}>
				{/** display shortTitle if not surpressed and available */
				data?.shortTitle && data?.shortTitle !== '' && !data?.suppressHeader ? (
					<div className="title-bar">
						<h3>{data.shortTitle}</h3>

						{/** display sponsor logo if available */
						data?.sponsor &&
						data?.sponsor !== '' &&
						sponsorsLookUps &&
						sponsorsLookUps?.hasOwnProperty(sponsor) ? (
							<div className="sponsor">
								<EventsLink
									to={sponsorsLookUps[sponsor]['link']}
									title={sponsorsLookUps[sponsor]['name']}>
									<span className={`label`}>Presented by</span>
									<span className="logo">
										<img
											src={sponsorsLookUps[sponsor]['imgSrc']}
											alt={sponsorsLookUps[sponsor]['name']}
											className={`${
												sponsor == 'rolex'
													? 'rolex'
													: sponsor == 'americanexpress'
													? 'amex'
													: ''
											}`}
										/>
									</span>
								</EventsLink>
							</div>
						) : null}
					</div>
				) : null}

				{this.props.children}
			</section>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContentWrapper);
