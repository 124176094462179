/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import op from 'object-path';
import VideoPlayer from '../../content/VideoPlayer';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Hero
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

class VideoGallery extends Component {
	//logger.log('[VideoGallery] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	constructor(props) {
		super(props);

		this.nextVidIndex = 1;
		this.onPlay = this.onPlay.bind(this);
		this.mapVideoData = this.mapVideoData.bind(this);
		this.playNext = this.playNext.bind(this);
		this.playVideo = this.playVideo.bind(this);
		this.alreadyStartedCountdown = false;
		this.initVideo = this.initVideo.bind(this);

		logger.log('[VideoHero] constructor props:%o', this.props);
		this.state = {
			mainThumb: op.get(this.props, 'data.videos.video.0') ? this.props.data.videos.video[0].poster : null,
			currVideo: op.get(this.props, 'data.videos.video.0')
				? this.mapVideoData(op.get(this.props, 'data.videos.video.0'), true, 0)
				: null,
			vidPlaylist: op.get(this.props, 'data.videos.video')
				? op.get(this.props, 'data.videos.video').map((d, i) => {
						return this.mapVideoData(d, true, i);
				  })
				: null,
			nextVid:
				op.get(this.props, 'data.videos.video') && this.props.data.videos.video.length > 0
					? this.mapVideoData(this.props.data.videos.video[this.nextVidIndex], true, this.nextVidIndex)
					: null,
			startPlaying: false,
			showNext: false,
			shouldAutoplay: true,
		};
	}

	initVideo() {
		logger.log('[VideoGallery] initVideo');
		this.nextVidIndex = 1;
		this.setState({
			mainThumb: op.get(this.props, 'data.videos.video.0') ? this.props.data.videos.video[0].poster : null,
			currVideo: op.get(this.props, 'data.videos.video.0')
				? this.mapVideoData(op.get(this.props, 'data.videos.video.0'), true, 0)
				: null,
			vidPlaylist: op.get(this.props, 'data.videos.video')
				? op.get(this.props, 'data.videos.video').map((d, i) => {
						return this.mapVideoData(d, true, i);
				  })
				: null,
			nextVid:
				op.get(this.props, 'data.videos.video') && this.props.data.videos.video.length > 0
					? this.mapVideoData(this.props.data.videos.video[this.nextVidIndex], true, this.nextVidIndex)
					: null,
			startPlaying: false,
			showNext: false,
			shouldAutoplay: true,
		});
	}

	componentDidMount() {
		logger.log('[VideoGallery] - componentDidMount state:%o', this.state);
	}

	componentWillUnmount() {}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[VideoGallery] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[VideoGallery] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	getMimeType(url) {
		let extensionArry = url.split('.');
		let vidExtension = extensionArry[extensionArry.length - 1];
		if (vidExtension.indexOf('mp4') !== -1) {
			return 'video/mp4';
		} else {
			return 'application/x-mpegURL';
		}
	}

	updateVideo() {
		let currVid = this.state.nextVid;
		this.nextVidIndex++;
		let nextVid =
			this.nextVidIndex < this.state.vidPlaylist.length ? this.state.vidPlaylist[this.nextVidIndex] : null;
		logger.log('[VideoGallery] - updateVideo currVid:%o', currVid);
		logger.log('[VideoGallery] - updateVideo nextVid:%o', nextVid);

		if (nextVid) {
			this.setState(prevState => {
				return {
					...prevState,
					currVideo: currVid,
					nextVid,
					showNext: false,
				};
			});
		} else {
			this.setState({
				nextVid,
				currVideo: currVid,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[VideoGallery] - componentDidUpdate state:%o', this.state);
		if (this.state.showNext && !this.alreadyStartedCountdown && this.state.shouldAutoplay) {
			let counter = 5;
			this.alreadyStartedCountdown = true;
			this.countDownIntervall = setInterval(() => {
				document.getElementById('nextCounter').innerText = counter;
				counter--;
				if (counter === -1) {
					clearInterval(this.countDownIntervall);
					counter = 5;
					this.updateVideo();
					this.alreadyStartedCountdown = false;
				}
			}, 1000);
		}
	}

	mapVideoData(data, autoPlay, index) {
		return {
			...data,
			index,
			startSeconds: 0,
			//playsinline: data.playsinline == "true" ? true : false,
			playsinline: true,
			autoplay: autoPlay ? autoPlay : false,
			streams: [data.streams.stream],
		};
	}

	playNext() {
		// if (this.state.nextVid) {
		// 	this.setState(prevState => {
		// 		return {
		// 			...prevState,
		// 			showNext: true,
		// 		};
		// 	});
		// }
	}

	playVideo() {
		logger.log('[VideoGallery] playVideo');

		if (!this.state.startPlaying) {
			this.setState(prevState => {
				return {
					...prevState,
					startPlaying: true,
				};
			});
		}
	}

	onPlay() {
		logger.log('[VideoGallery] onPlay');
		this.setState(prevState => {
			return {
				...prevState,
				//showNext: false
			};
		});
	}

	render() {
		let data = this.props.data;
		logger.log('[VideoGallery] - data:%o, state:%o', data, this.state);

		if (data) {
			return (
				<section className="uso-media">
					<div className="media-col">
						<div className="media-16x9">
							<div className="media-wrapper">
								{this.state.currVideo ? (
									this.state.startPlaying ? (
										<Fragment>
											<VideoPlayer
												attributes={{
													videoData: this.state.currVideo,
													onComplete: this.playNext,
													onPlay: this.onPlay,
													nextVid: this.state.nextVid,
													id: `videoGallery`,
													playNextFn: this.updateVideo,
													reset: false,
													showPanelIcon: true,
												}}
											/>
											<i className="icon-close videoClose" onClick={() => this.initVideo()}></i>
										</Fragment>
									) : (
										<div
											className="inlineVideo"
											onClick={() => {
												this.playVideo();
											}}>
											<div className="playBtn">&nbsp;</div>
											<img src={this.state.mainThumb} title={this.state.currVideo.title} />
										</div>
									)
								) : null}
							</div>
						</div>
					</div>
				</section>
			);
		} else {
			return null;
		}
	}
}

export default VideoGallery;

VideoGallery.defaultProps = {};
