/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

import DrawsPage from 'appdir/components/pages/DrawsPage';

const DrawsWebview = props => {
	return <DrawsPage {...props} />;
};

export default DrawsWebview;
