import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		// logger.log('[MainNav] actions.mount data:%o', data);
		//logger.log('[MainNav] actions.mount state:%o', getState() );
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// let menus = Config.menus;
			//let menus = Config.cmsData.mainMenu;
			let flagImagePathSmall = Config.otherData.flagImagePathSmall;
			dispatch({
				type: deps.actionTypes.MAINNAV_MOUNT,
				data: { flagImagePathSmall, status: 'ready' },
			});
		});
	},
	unmount: data => (dispatch, getState, store) => {
		// logger.log('[MainNav] actions.unmount data:%o', data);
		dispatch({ type: deps.actionTypes.MAINNAV_UNMOUNT, data: data });
	},
	hoverMobile: (isHover, menuName) => dispatch => {
		dispatch({
			type: deps.actionTypes.MAINNAV_HOVERMOBILE,
			data: { menu_hover: isHover, menu_name: menuName },
		});
	},
	update: data => dispatch => {
		dispatch({
			type: deps.actionTypes.MAINNAV_UPDATE,
			data,
		});
	},
	//RLL 8/20/2022
	navigate: data => (dispatch, getState, store) => {
		logger.log('[MainNav]  deps.actions.navigate data:%o', data);
		let search = data.path.indexOf('?') !== -1 ? data.path.substring(data.path.indexOf('?')) : '';
		let pathname = search !== '' ? data.path.replace(search, '') : data.path;

		let newData = {
			newPath: pathname,
			newSearch: search,
			panelClick: data.panelClick ? data.panelClick : false,
		};
		dispatch({ type: deps.actionTypes.MAINNAV_NAVIGATE, data: newData });
	},
};
