import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(response => {
		if (response.status === 200) {
			// logger.log('[TicketsModule] fetch - 200 valid response');

			return response.data;
		} else {
			// throw error and go to catch block
			throw new Error('invalid response code');
		}
	});
};

export default {
	fetch,
};
