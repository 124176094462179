/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PowerRanking from 'appdir/components/pages/MatchInsights/elements/PowerRanking';
import { getTeamsLikelihoodWinData } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import { getAppLink } from 'appdir/components/general/Util';


/**
 * -----------------------------------------------------------------------------
 * React Component: Slamtracker Likelihood To Win
 * -----------------------------------------------------------------------------
 */

const LikelihoodToWin = props => {
	logger.log('[LikelihoodToWin] props:%o', props);
	
	const dispatch = useDispatch();
    const teams = [props.data.statMatch.team1, props.data.statMatch.team2];

	const goToLink = path => {
        if (window.webview) {
			let link = getAppLink(path);
			location.href = link.url;
		} else if (path) {
        	if (props?.data?.windowSize == 'mobile') {
				dispatch(deps.actions.Controller.setSTPanelStatus({ open: false }));
			}
            dispatch(deps.actions.MainNav.navigate({path: path}));
		}
    }

	return (
		<section className="slamtracker-powerranking content">
			{props?.data?.powerIndexMatchup && props?.data?.statMatch ? (
				<>
					{/** make sure players have the probablility number  */
					getTeamsLikelihoodWinData(props.data.powerIndexMatchup, teams).filter(d => d['likelihoodWinNum'])
						.length > 0 && (
						<PowerRanking
							matchData={props.data.statMatch}
							teams={teams}
							powerIndexMatchupData={props.data.powerIndexMatchup}
							imgPath={props.data.imgPath}
							flagPath={props.data.flagPath}
							infoIcon={false}
							isPanel={true}
							type="slamtracker"
						/>
					)}

					<div className="where-to-watch">
						<div className="button"
							onClick={() => {
								props.measureIndex(`Preview`, 'Where To Watch');
								goToLink('/en_US/about/tv_intl.html');
							}}>
							<i className="icon-blogIcon-video"></i>
							Where To Watch
						</div>
					</div>
				</>
			) : (
				<LoadingIndicator type="small" />
			)}
		</section>
	);
};

export default LikelihoodToWin;
