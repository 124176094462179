/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetchAll, getAvailablePlayers } from 'appdir/components/general/Util';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';

import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import MatchBox from 'appdir/components/_scoring/MatchBox';
import StubBox from 'appdir/components/common-ui/StubBox';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import MeasurementUtils from 'appdir/lib/analytics';
import { windowSizeVals, scrollIntoViewWithOffset } from 'appdir/components/general/Util';
import AdUnitLazy from 'appdir/components/general/AdUnitLazy';
import Template from 'components/Template';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';

import axios from 'axios';

const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: ResultsPage
 * this is the completed matches page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ResultsPage'],
		currentDay: state['ActiveData']['currentDay'],
		stubs: state['Config'].stubPages,
		windowSize: state['Controller'].windowSize,
		windowSizeVal: state['Controller'].windowSizeVal,
		EventsWindow: state['WindowSize'].EventsWindow,
		adConfig: state['Config']['adConfig'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	setDefaultDay: settings => dispatch(deps.actions.ResultsPage.setDay(settings)),
});

class ResultsPage extends Component {
	constructor(props) {
		super(props);

		this.setFilter = this.setFilter.bind(this);

		//set default filter based on querystring
		//let filters = props.filters;
		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);

		if (parsedQs.filter) {
			index = findIndex(menu, function(o) {
				return o.query == parsedQs.filter.toLowerCase();
			});
		}

		this.state = {
			loadingError: false,
		};
		this.defaultSet = false;
		this.loadedJson = '';
		this.hasError = false;
		this.waitForEvents = false;

		// this.onOpen = this.onOpen.bind(this);
	}

	componentDidMount() {
		//logger.log('[ResultsPage] componentDidMount - state:%o', this.state);
		//logger.log('[ResultsPage] componentDidMount - props:%o', this.props);
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'CompletedMatches',
		});
		//forces a state change so that componentDidUpdate is hit on page load
		this.setState({ mounted: true });
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[ResultsPage] componentDidUpdate - 1 - this:%o loadedJson:%o', this, this.loadedJson);

		// calculate new data path from path in state and current fitlers
		if (
			!this.props?.match?.params?.tournDay &&
			this.props?.currentDay &&
			this.props?.currentDay?.completed &&
			this.props?.path &&
			!this.defaultSet &&
			this.props?.status === 'load'
		) {
			this.defaultSet = true;
			this.props.setDefaultDay({
				day: this.props?.currentDay?.completed,
			});
		} else if (
			this.props?.stubs &&
			this.props?.stubs?.results?.stub !== 'stub' &&
			this.props?.path &&
			this.props?.filters.day &&
			this.props?.filters.day !== '' &&
			(this.loadedJson == '' || this.props?.filters?.day != prevProps?.filters?.day)
		) {
			// logger.log('[ResultsPage] componentDidUpdate loading');
			let dataPath = this.props.path.replace('<day>', this.props.filters.day);
			// let dataPath = this.props.path.replace('<day>', '8');

			// if the data path is not what was last loaded,
			// and not an update caused by filter status change, load now
			if (
				dataPath != this.loadedJson &&
				this.props.filters.day != '' &&
				(this.props.status != 'change' || this.props.filters.day != prevProps.filters.day)
			) {
				this.loadedJson = dataPath;

				// pageView(ANALYTICS_PROPS.results);
				let matchPath = dataPath;
				let daysPath = this.props.daysPath;

				let pathObj = [matchPath, daysPath];

				if (this.loadedJson != '' && !this.hasError) {
					logger.log('[ResultsPage] componentDidUpdate loadedJson:%o', this.loadedJson);
					// call the service fetchAll and pass in the object containing multiple request urls
					fetchAll(pathObj)
						// take the returned results and save them to state.
						.then(
							axios.spread((matchesResults, daysResults) => {
								logger.log('[ResultsPage] componentDidUpdate completed_matches:%o', matchesResults);
								// logger.log('[ResultsPage] componentDidUpdate tourn_days:%o', daysResults);
								this.hasError = false;

								let playersPlaying = this.getPlayersPlaying(matchesResults.matches);

								this.setState(prevState => ({
									...prevState,
									completed_matches: matchesResults,
									tourn_days: daysResults,
									playersPlaying,
								}));
							})
						)
						.catch(error => {
							logger.log('[ResultsPage] componentDidUpdate error:%o', error);
							this.loadedJson = '';
							this.hasError = true;
							this.setState({
								loadingError: true,
							});
						});
				}
			}
		}

		/** if filter event is switched, refilter the searchable players */
		let curEvent = this.props?.filters?.event;
		let prevEvent = prevProps?.filters?.event;

		if (curEvent !== prevEvent && this.state?.completed_matches) {
			// let playersPlaying = this.getPlayersPlaying(this.state.completed_matches?.matches);
			let prevPlayersPlaying = prevState.playersPlaying;
			logger.log(
				'[ResultsPage] componentDidUpdate filter event switched - prevState playersPlaying: %o',
				prevState.playersPlaying
			);
			this.setState({
				playersPlaying: prevPlayersPlaying,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	// UNSAFE_componentWillReceiveProps(nextProps) {
	// 	logger.log('[ResultsPage] componentWillReceiveProps - prev:%o', this.state);
	// 	// logger.log('[ResultsPage] componentWillReceiveProps - next:%o', nextProps);
	// 	let url = '';
	// 	let completed_matches = this.state?.completed_matches;
	// 	let tourn_days = this.state?.tourn_days;

	// 	if (this.state?.tourn_days && this.waitForEvents) {
	// 		let dayObj = this.redirectDay(this.props.filters.event, this.props.filters.day);
	// 		if (dayObj) {
	// 			url = `day${dayObj.tournDay}.html`;
	// 			if (dayObj.tournDay != this.props.filters.day) {
	// 				this.loadedJson = '';
	// 				this.hasError = false;
	// 				completed_matches = null;
	// 			}
	// 			this.waitForEvents = false;
	// 		}
	// 	} else if (this.props?.filters?.event != nextProps?.filters?.event) {
	// 		if (this.state?.tourn_days) {
	// 			let dayObj = this.redirectDay(nextProps.filters.event, this.props?.match?.params?.tournDay);
	// 			if (dayObj) {
	// 				url = `day${dayObj.tournDay}.html`;
	// 				if (dayObj.tournDay != this.props.match.params.tournDay) {
	// 					this.loadedJson = '';
	// 					this.hasError = false;
	// 					completed_matches = null;
	// 				}
	// 				this.waitForEvents = false;
	// 			}
	// 		} else {
	// 			this.waitForEvents = true;
	// 		}
	// 	} else if (this.props?.filters?.day != nextProps?.filters?.day) {
	// 		if (nextProps.filters.day && nextProps.filters.day != '' && nextProps.filters.day !== 'undefined') {
	// 			url = `day${nextProps.filters.day}.html`;
	// 			this.loadedJson = '';
	// 		}
	// 		//this.loadedJson = url == this.state.location.pathname ? this.state.location.pathname : '';
	// 		this.hasError = false;
	// 		completed_matches = null;
	// 	}
	// 	this.setState(prevState => {
	// 		return {
	// 			// ...prevState,
	// 			...nextProps,
	// 			nav: this.props.match.params.tournDay || url !== '' ? url : null,
	// 			completed_matches: completed_matches,
	// 			tourn_days: tourn_days,
	// 		};
	// 	});

	// 	// logger.log('[componentWillReceiveProps] getDerivedStateFromProps newState:%o',newState);
	// }

	/**
	 * This function splits an array up into equal size arrays of your choosing
	 * @param {*} arr - array to chunk
	 * @param {*} size - size you want to chunk it to
	 */
	chunkArray(arr, size) {
		let chunked = [];
		for (let ele of arr) {
			let last = chunked[chunked.length - 1];
			if (!last || last.length === size) {
				chunked.push([ele]);
			} else {
				last.push(ele);
			}
		}
		return chunked;
	}

	getPlayersPlaying = (matches = this.state.completed_matches.matches) => {
		let filteredMatches = [];
		let playersPlaying;

		if (matches) {
			filteredMatches = matches.filter(match => {
				if (!this.props.filters || this.props.filters.event == 'AE' || this.props.filters.event == '') {
					return match;
				} else {
					return match.eventCode === this.props.filters.event;
				}
			});

			if (filteredMatches.length > 0) {
				playersPlaying = getAvailablePlayers(filteredMatches);
			}
		}

		return playersPlaying;
	};

	renderMatches() {
		//logger.log("[ResultsPage] renderMatches - filters:%o",this.state.filters);
		// logger.log('[ResultsPage] renderMatches - this:%o', this);

		if (!this.state.completed_matches) {
			return <LoadingIndicator type="white" />;
		} else {
			let filteredMatches = this.state.completed_matches.matches.filter(match => {
				if (!this.props.filters || this.props.filters.event == 'AE' || this.props.filters.event == '') {
					return match;
				} else {
					//check for new sb_code
					let newEventCode;
					this.props.tourn_events.map(e => {
						if (match.eventCode === e.code) {
							newEventCode = e.sb_code;
						}
					});
					// logger.log('[ResultsPage] renderMatches - newEventCode:%o', newEventCode);
					return newEventCode === this.props.filters.event;
				}
			});

			let chunkedMatches;

			if (filteredMatches) {
				if (this.props.windowSizeVal == windowSizeVals.MOBILE_WINSIZE) {
					chunkedMatches = this.chunkArray(filteredMatches, 2);
				} else if (this.props.windowSizeVal <= windowSizeVals.SMDESKTOP_WINSIZE) {
					chunkedMatches = this.chunkArray(filteredMatches, 4);
				} else if (this.props.windowSizeVal >= windowSizeVals.DESKTOP_WINSIZE) {
					chunkedMatches = this.chunkArray(filteredMatches, 6);
				}
				// logger.log('[ResultsPage] renderMatches - chunkedMatches:%o', chunkedMatches);
			}

			return !filteredMatches || chunkedMatches.length == 0 ? (
				<div className="column-layout scoresgrid">
					<GenericError message="There are no completed matches for the selected day and event." />
					<div className="mip-ad-container">
						{/* <AdTag
							adConfig={this.props.adConfig.mip}
							dfpNetworkId={this.props.adConfig.dfpNetworkId}
						/> */}
						<AdUnitLazy data={{ adType: 'mip' }} />
					</div>
				</div>
			) : (
				chunkedMatches.map((chunkedIds, index) => {
					//logger.log("[ResultsPage] renderMatches filter map match:%o",match);
					return (
						<React.Fragment key={`frag${index}`}>
							<div className="column-layout scoresgrid">
								{chunkedIds.map((match, j) => {
									return (
										<div
											key={j}
											className={`one-col ${match.team1.idA}${
												match.team1.idB ? ' ' + match.team1.idB : ''
											} ${match.team2.idA}${match.team2.idB ? ' ' + match.team2.idB : ''}`}>
											<MatchBox
												attributes={{
													data: match,
													event: match.shortEventName,
													tableHeader: match.courtName,
													showLinks: true,
													searchedPlayer: this.state.searchedPlayer,
													mode: 'completed',
												}}
											/>
										</div>
									);
								})}
							</div>
							{index !== chunkedMatches.length - 1 &&
							this.props?.adConfig?.mip?.showAd && // - 2022 ads no long cap at 3 rows anymore
							((this.props.windowSizeVal >= windowSizeVals.DESKTOP_WINSIZE &&
								(chunkedIds.length > 3 || filteredMatches.length <= 6)) ||
								(this.props.windowSizeVal == windowSizeVals.MOBILE_WINSIZE && chunkedIds.length > 0) ||
								(this.props.windowSizeVal !== windowSizeVals.DESKTOP_WINSIZE &&
									this.props.windowSizeVal !== windowSizeVals.MOBILE_WINSIZE &&
									(chunkedIds.length > 2 || filteredMatches.length <= 4))) ? (
								<div className="mip-ad-container">
									{/* <AdTag
										key={`completed-ad-${index}`}
										id={`completed-ad-${index}`}
										adConfig={this.props.adConfig.mip}
										dfpNetworkId={this.props.adConfig.dfpNetworkId}
									/> */}
									<AdUnitLazy data={{ adType: 'mip' }} />
								</div>
							) : null}
						</React.Fragment>
					);
				})
			);
		}
	}

	setSearchablePlayers = filteredMatches => {
		let playersPlaying;

		if (filteredMatches) {
			playersPlaying = getAvailablePlayers(filteredMatches);
		}

		return playersPlaying;
	};

	setFilter(filter) {
		//e.preventDefault();

		this.setState({
			filter: filter.toLowerCase(),
		});
	}

	getMode(day) {
		let mode = '';
		this.state.tourn_days.eventDays.forEach(item => {
			if (item.tournDay == day) {
				console.info('got a mode');
				mode = item.quals ? 'quali' : 'tourn';
			}
		});
		return mode;
	}

	redirectDay(eventCode, curDay) {
		let nextDay = false;
		let currentDay = false;

		if (eventCode != '' && eventCode != 'AE') {
			currentDay = this.state.tourn_days.eventDays.find(
				x => x.tournDay == curDay && x.events.includes(eventCode)
			);
			nextDay = this.state.tourn_days.eventDays.find(x => x.events.includes(eventCode));
		}
		//logger.log('[ResultsPage] redirectDay eventCode:%o,  curDay:%o, nextDay:%o, currentDay:%o',eventCode, curDay, nextDay, currentDay);
		return currentDay ? false : nextDay;
	}

	getPlayedEvents() {
		let playedEvents = new Array();
		this.props.tourn_events.map((event, i) => {
			let played = this.state.tourn_days.eventDays.find(x => x.events.includes(event.code));
			if (played) {
				playedEvents.push(event);
			}
		});
		return playedEvents;
		//logger.log('[ResultsPage] getPlayedEvents playedEvents:%o', playedEvents);
	}

	onClick = value => {
		// logger.log('[ResultsPage] onClick - value:%o', value);
		let searchedPlayer = value;
		this.setState({ searchedPlayer }, () => {
			//get element page offset
			let el = document.querySelector(`.${searchedPlayer?.id}`);

			const stickyMenu = document.querySelector(`#uso-header.sticky`);
			const stickyFavorites = document.querySelector(`.favorites-details.sticky`);
			const isMobile = this.props?.EventsWindow?.windowSize == 'mobile';

			let offset = stickyFavorites ? 96 : stickyMenu ? 150 : 200;

			if (isMobile) {
				offset = stickyFavorites ? 116 : stickyMenu ? 170 : 470;
			}

			if (el) {
				scrollIntoViewWithOffset(`.${searchedPlayer?.id}`, offset);
			}
		});
	};

	render() {
		logger.log('[ResultsPage] render - this:%o', this);
		let header_propsData = {};

		// if you are creating a header like scores which is transparent, make sure you update the
		// header component and make a new style for the new header.
		header_propsData = {
			headerType: 'scores',
			title: 'Results',
			metaTitle: 'Results',
			metaDescription:
				'Stay up to date with live scoring and results from every match at the US Open, including singles and doubles plus the junior and wheelchair tournaments.',
			metaDate: '',
			metaPlayers: '',
			canonicalLink: 'https://www.usopen.org/en_US/completed_matches/index.html'
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/' + this.props.match.params.lang + '/scores/index.html',
					title: 'Scores & Stats',
				},
			],
			sponsor: {
				link: 'http://www.ibm.com/sports/usopen',
				title: 'Presented by',
				name: 'IBM',
				imgClass: '',
				imgSrc: '/assets/images/logos/ibm_logo_black.png',
				tagline: 'Presented by',
			},
			title: 'Completed',
			page_header: 'Completed',
		};

		// if theres no tournDay, then redirect to current day that is in the activeData.
		if (this.state?.loadingError) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: header_propsData.title,
								message: this.props.stubs.results.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.props?.stubs) {
			if (this.props?.stubs && this.props?.stubs?.results?.stub === 'stub') {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
						<section className="wrapper scorespage">
							<StubBox
								attributes={{
									title: header_propsData.title,
									message: this.props.stubs.results.text,
									basePicPath: this.props.basePicPath,
								}}
							/>
						</section>
					</Template>
				);
			} else if (this.hasError) {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes}>
						<section className="wrapper scorespage">
							<GenericError message="No results found" />
						</section>
					</Template>
				);
			} else if (
				this.state?.nav &&
				this.state?.nav != this.props?.location?.pathname &&
				this.props?.status != 'load'
			) {
				let nav =
					this.props.filters.event !== 'AE'
						? `${this.state.nav}?event=${this.props.filters.event}`
						: this.state.nav;
				return <EventsRedirect to={nav} />;
			} else if (
				this.state?.completed_matches && //not here
				this.props?.tourn_events &&
				this.state?.tourn_days && // not here
				this.props?.currentDay &&
				this.props?.windowSize
			) {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes}>
						<section className="wrapper scorespage">
							<h1 className="header" style={{ marginBottom: '0px' }}>
								Completed
							</h1>
							<PageSubMenu
								mode="Scores"
								selected="Completed Matches"
								// filters={this.props.filters}
								filters={null}
								showFavorites={true}
								playersData={this.state?.playersPlaying}
								searchedPlayer={this.state.searchedPlayer?.id}
								onClick={this.onClick}
								completedMatchData={this.state.completed_matches.matches}
							/>
							<ErrorBoundary message="Error in Child Component">
								<div className="match-content">{this.renderMatches()}</div>
							</ErrorBoundary>
						</section>
					</Template>
				);
			} else {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes}>
						<section className="wrapper scorespage">
							<h1 className="header" style={{ marginBottom: '0px' }}>
								Completed
							</h1>
							<LoadingIndicator />
						</section>
					</Template>
				);
			}
		} else {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<h1 className="header" style={{ marginBottom: '0px' }}>
							Completed
						</h1>
						<LoadingIndicator />
					</section>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPage);
