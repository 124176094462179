import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * ---------------------------------------
 * ContentWrapper - content wrapper for General Content Pages
 * use this component and wrap around each section component
 *
 * Show/Hide title
 * Show/Hide sponsor
 * Show/Hide border top
 *
 * props
 * @param {String}  borderTop --------- should match css class, .generalContent-border-<borderTop>
 * @param {String}  title -------- display title
 * @param {String}  sponsor ----------- if not empty,  get matched sponsor info from sponsorsLookUps object in Config
 * ---------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ContentWrapper'],
		sponsorsLookUps: state['Config'].sponsorsLookUps,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class ContentWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		logger.log('[ContentWrapper] props:%o', this.props);

		let { data } = this.props;
		let klass = 'contentWrapper';
		let sponsorsLookUps = this.props?.sponsorsLookUps[0];
		let sponsor = data?.sponsor?.toLowerCase();

		if (data?.borderTop && data?.borderTop !== '') {
			klass += ` contentWrapper-border contentWrapper-border-${data.borderTop.toLowerCase()}`;
		}

		if (data?.style && data?.style !== '') {
			klass += ` ${data.style}`;
		}

		return (
			<section className={klass}>
				{/** display shortTitle if not surpressed and available */
				data?.title && data?.title !== '' ? (
					<div className="title-bar">
						<h3>{data.title}</h3>

						{/** display sponsor logo if available */
						data?.sponsor &&
						data?.sponsor !== '' &&
						sponsorsLookUps &&
						sponsorsLookUps?.hasOwnProperty(sponsor) ? (
							<div className="sponsor">
								<EventsLink
									to={sponsorsLookUps[sponsor]['link']}
									title={sponsorsLookUps[sponsor]['name']}>
									<span className={`label ${sponsor == 'rolex' ? 'noshow' : ''}`}>Presented by</span>
									<span className="logo">
										<img
											src={sponsorsLookUps[sponsor]['imgSrc']}
											alt={sponsorsLookUps[sponsor]['name']}
											className={`${
												sponsor == 'rolex'
													? 'rolex'
													: sponsor == 'americanexpress'
													? 'amex'
													: ''
											}`}
										/>
									</span>
								</EventsLink>
							</div>
						) : null}
					</div>
				) : null}

				{this.props.children}
			</section>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentWrapper);
