/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getQuerystringValues } from 'appdir/components/general/Util';
import ReactEcp from 'react-ecp';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoTest
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['VideoTest'],
		playerConfig: state['Config']?.['playerConfig'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({});

class VideoTest extends Component {
	constructor(props) {
		super(props);
		let parsedQs = getQuerystringValues(false, true);

		this.state = {
			vidSrc: parsedQs.src || null,
			play: parsedQs.src ? true : false,
		};

		this.vidSrc = parsedQs.src || null;
	}

	onEvent(event) {
		logger.info('[VideoTest] onVideoEvent - event:%o', event);
	}

	setVideoSrc(vidSrc) {
		this.setState({
			vidSrc,
			play: false,
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({
			play: true,
		});
	}

	render() {
		let attributes = {
			id: 'testvideo',
			title: 'test video',
			streams: [
				{
					cdn: 'Akamai',
					url: this.state.vidSrc,
					mimeType: 'application/x-mpegURL',
					streamType: 'vod',
				},
			],
			tracks: [],
			startSeconds: 0,
			autoplay: true,
			autoplayPolicy: 'mutedinline',
			playsinline: true,
		};

		let colors = {
			highlightColor: '#447fff',
			toolbarBackground: 'rgba(73, 77, 95, .8)',
			progressBackground: '#D8D8D8',
		};

		return (
			<>
				<div className="form-wrapper">
					<form onSubmit={e => this.handleSubmit(e)}>
						<label for="vidSrc">Video Source:</label>
						<br />
						<input
							type="text"
							id="vidSrc"
							value={this.state?.vidSrc}
							size="100"
							onChange={e => this.setVideoSrc(e.target.value)}
						/>
						<input type="submit" value="Submit" style={{ marginLeft: '10px' }} />
					</form>
				</div>
				<br />
				{this.state.vidSrc && this.state.play && (
					<div style={{ position: 'relative' }}>Playing:{this.state?.vidSrc}</div>
				)}
				<br />
				<div className="content-main">
					{this.state.vidSrc && this.state.play && this?.props?.playerConfig ? (
						<>
							<div className="video-wrapper">
								{/* <VideoPlayer
										key={'test'}
										attributes={{videoData:{...attributes}}}
										onEvent={this.onEvent}
									/> */}

								<ReactEcp
									id="videotest"
									ampBasePath="/libs/"
									playerConfig={this?.props?.playerConfig}
									videoConfig={attributes}
									reset={false}
									colors={colors}
								/>
							</div>
						</>
					) : (
						<div className="error">No vid src</div>
					)}
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoTest);
