import OpenQuestionsDetail from './index';

export default {
	path: [
		'/webview/:lang(en_US)/open_questions/detail/:question/index.html',
		'/webview/:lang(en_US)/open_questions/detail/:question/',
	],
	exact: true,
	component: OpenQuestionsDetail,
};
