import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';
import FavoriteStar from 'appdir/components/common-ui/FavoriteStar';
import { determinePlayerHighlightClass } from 'appdir/components/pages/SchedulePage/ScheduleUtils';

const mapStateToProps = (state, props) => {
	return { ...props, favorites: state['Controller'].favorites };
};

const mapDispatchToProps = (dispatch, props) => ({
	update: data => dispatch(deps.actions.Controller.update(data)),
});

class CountryList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		logger.log('[CountryList] constructor - props:%o', props);
	}

	render() {
		return (
			<ul className={`${this.props?.selectedEvent && this.props?.selectedEvent !== 'AE' ? 'selected' : ''}`}>
				{this.props.players
					.filter(player => {
						return player?.sex == this.props.gender;
					})
					.map((player, index) => {
						return (
							<li
								key={index}
								className={`${determinePlayerHighlightClass(
									player.tourid,
									this.props?.favorites,
									this.props?.searchedPlayer
								)} ${this.props?.favorites?.players?.includes(player.tourid) ? 'highlightClass' : ''}`}>
								<div className="favorite-star">
									<FavoriteStar
										id={player.tourid}
										playerName={player['tv_name']}
										type="players"
										unFavColor="gray"
										favColor="yellow"
										alwaysShow={true}
										style="outline"
									/>
								</div>
								<div id="playerLink">
									<Link to={`/en_US/players/overview/${player.tourid}.html`}>
										<span className="flag">
											<img
												src={`//www.usopen.org/images/flags/${this.props.countryFlag}_h.gif`}
												alt={`${this.props.countryFlag} flag`}
											/>
										</span>

										<span className="playerName">
											{player.last_name}, {player.first_name}
										</span>
									</Link>
								</div>
								<div
									className={`${
										this.props?.selectedEvent && this.props?.selectedEvent !== 'AE'
											? 'hide'
											: 'eventsEntered'
									}`}>
									<div>{player?.events_entered}</div>
									{/* {player?.events_codes_entered.map((e, i) => (
										<div key={i}>{e}</div>
									))} */}
								</div>
							</li>
						);
					})}
			</ul>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(CountryList);
