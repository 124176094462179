/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import ButtonGeneral from '../ButtonGeneral';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: NativeContent
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const NativeContent = props => {
	logger.log('[NativeContent] - props:%o', props);
	let attributes = props.data;
	let contentInfo = props.data.content;
	// logger.log('[NativeContent] - attr:%o', attributes);

	let measureData = {
		...props.data,
		type: attributes.type,
		itemId: attributes.id,
		itemType: attributes.type,
		actionType: 'homePageClick',
	};
	delete measureData.content;

	if (attributes) {
		return (
			<section className="uso-native">
				<div className="uso-native-container">
					<div className="uso-native-content">
						<div
							id={attributes['id']}
							className={`uso-native-image`}
							style={{
								backgroundImage: `url(${contentInfo.photo})`,
							}}>
							<img src={contentInfo['photo-mobile']} />
							<div className="quote-overlay">
								<div className="quote">
									<div className="pull-quote">{ReactHtmlParser(contentInfo.pullquote)}</div>
									<div className="abstract-title">
										{contentInfo.title
											? ReactHtmlParser(contentInfo.title)
											: ReactHtmlParser(attributes.title)}
									</div>
									<div className="abstract">
										{/* {ReactHtmlParser(contentInfo.abstract)} */}
										{contentInfo.link.length > 1 ? (
											<ButtonGeneral
												data={{
													style: 'uso-button uppercase blue wider round',
													title: 'More',
													link: contentInfo.link,
													measureData: {
														...measureData,
														which: 'button',
														itemTitle: contentInfo.title,
													},
												}}
											/>
										) : null}
									</div>
								</div>
							</div>
							{!isEmpty(contentInfo.sponsor) ? (
								<div className="sponsor-overlay">
									<div className="sponsor">
										{contentInfo.sponsor.preamble ? (
											<span className="presented-by">{contentInfo.sponsor.preamble}</span>
										) : null}
										<img
											className="img-fluid"
											src={contentInfo.sponsor.logo}
											alt={contentInfo.sponsor.name}
										/>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</section>
		);
	} else {
		return null;
	}
};

export default NativeContent;
