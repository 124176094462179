import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapSateToProps = (state, props) => {
	return {
		...state['PlayerStatsPage'],
	};
};

class PlayerStatsTopLeader extends Component {
	constructor(props) {
		super(props);
	}

	// format stat numbers with symbols
	formatStat(stat) {
		let stat_number = stat;
		let stat_symbol = '';

		if (typeof stat == 'string') {
			if (stat.indexOf('= ') > -1) {
				stat_number = stat.substring(stat.indexOf('=') + 2, stat.indexOf(' %'));
				stat_symbol = '%';
			} else if (stat.indexOf('mph') > -1) {
				stat_number = stat.substring(0, stat.indexOf(' mph'));
				stat_symbol = 'MPH';
			} else if (stat.indexOf('kmh') > -1) {
				stat_number = stat.substring(0, stat.indexOf(' kmh'));
				stat_symbol = 'KM/H';
			}
		}

		return {
			stat_number: stat_number,
			stat_symbol: stat_symbol,
		};
	}

	render() {
		let flagImagePathSmall = null;
		let playerProfileImagePath = null;

		if (this.props.flagImagePathSmall) {
			flagImagePathSmall = this.props.flagImagePathSmall;
			playerProfileImagePath = this.props.playerProfileImagePath;
		}

		if (this.props.data && this.props.flagImagePathSmall && this.props.playerProfileImagePath) {
			// logger.log('[PlayerStatsTopLeader] render - this:%o', this);

			let data = this.props.data;

			return (
				<React.Fragment>
					<div className={`leaderWrapper ${this.props.genderType !== this.props.type ? 'hide' : ''}`}>
						<h3>{data.title}</h3>
						<div className="country">
							<img
								alt={data.nation}
								src={flagImagePathSmall ? flagImagePathSmall.replace('<code>', data.nation) : null}
							/>
							{data.country}
						</div>
						<div className="player-image">
							<img
								alt={data.tv_name}
								src={
									playerProfileImagePath
										? playerProfileImagePath.replace('<playerid>', data.id)
										: null
								}
							/>
						</div>
						<div className="player-info">
							<h4>Tournament Leader</h4>
							<div className="name">
								{data.first_name} <br /> {data.last_name}{' '}
							</div>
							<div className="stat">
								{this.formatStat(data.stat).stat_number}{' '}
								<span className="symbol">{this.formatStat(data.stat).stat_symbol}</span>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		}
	}
}

export default connect(mapSateToProps)(PlayerStatsTopLeader);
