/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: Power Index Find More content for Match Insights
 *
 *  props = {
 *             type, ---- if 'slamtracker' is passed, display shorter discription
 *           }
 * -----------------------------------------------------------------------------
 */
const MIFooter = props => {
	return (
		<div className="mi-section center no-bottom-border mi-footer">
			{props.type && props.type == 'slamtracker' ? (
				<p>
					Click here for all of the{' '}
					<EventsLink to={'/en_US/matchinsights/index.html'} external={false}>
						IBM Match Insights by Watson
					</EventsLink>
					.
				</p>
			) : (
				<p>
					Click here for all of the{' '}
					<EventsLink to={'/en_US/matchinsights/index.html'} external={false}>
						IBM Match Insights by Watson
					</EventsLink>
					.
				</p>
			)}
		</div>
	);
};

export default MIFooter;
