/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import deps from 'dependencies';
import EventsLink from 'appdir/components/general/EventsLink';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import GuestInfo from 'appdir/components/general/GuestInfo';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Footer
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Footer'],
		componentConfig: state['Config']['components'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Footer.mount()),
});

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displaySocial: false,
		};

		// logger.log('[Footer] constructor - props:%o', props);
	}

	componentDidMount() {
		this.props.mount();
	}

	showSocial(toggle) {
		if (this.state.displaySocial !== toggle) {
			this.setState(prevState => {
				return {
					...prevState,
					displaySocial: toggle,
				};
			});
			document.body.classList.add('social');
		}

		if (toggle == false) {
			document.body.classList.remove('social');
		}
	}

	render() {
		let bg = this.props['background'] || 'green';
		let year = moment().format('YYYY');
		let icons = this.props['icons'] || ['googleplus', 'twitter', 'facebook', 'youtube', 'instagram'];
		let links =
			this.props['links'] || this.props.flagImagePathLarge
				? [
						{
							url: '#',
							label: [
								<img
									key={`image-1`}
									src={this.props.flagImagePathLarge.replace('<code>', 'CHN')}
									className="flag"
									alt="China Site"
								/>,
							],
						},
						{ url: '#', label: 'PRIVACY' },
						{ url: '#', label: 'MEDIA' },
						{ url: '#', label: 'JOBS' },
						{ url: '#', label: 'CONTACT' },
						{ url: '#', label: 'SITE MAP' },
				  ]
				: null;

		return (
			<noindex>
				<div id="footer-social-menu" className={this.state.displaySocial ? 'open' : ''}>
					<div className="section">
						<a
							href="#"
							className="close-menu"
							onClick={() => {
								this.showSocial(false);
							}}>
							<img src="/assets/images/nav/menu_close.png" alt="close" border="0" />
						</a>
						<EventsLink to="https://www.facebook.com/usopentennis">
							<img src="/assets/images/nav/social_icons/facebook_lg.png" alt="facebook" border="0" />
							<br />
							US Open Tennis Championships
							<br />
							facebook.com/usopentennis
						</EventsLink>
					</div>
					<div className="section">
						<EventsLink to="https://twitter.com/usopen">
							<img src="/assets/images/nav/social_icons/twitter_lg.png" alt="twitter" border="0" />
							<br />
							US Open Tennis
							<br />
							twitter.com/usopen
						</EventsLink>
					</div>
					<div className="section">
						<EventsLink to="http://instagram.com/usopen">
							<img src="/assets/images/nav/social_icons/instagram_lg.png" alt="instagram" border="0" />
							<br />
							usopen
							<br />
							instagram.com/usopen
						</EventsLink>
					</div>
					<div className="section">
						<EventsLink to="http://www.youtube.com/user/USOPEN">
							<img src="/assets/images/nav/social_icons/youtube_lg.png" alt="youtube" border="0" />
							<br />
							US Open Tennis Championships
							<br />
							youtube.com/user/usopen
						</EventsLink>
					</div>
					<div className="section">
						<EventsLink to="https://www.tiktok.com/@usopen">
							<img src="/assets/images/nav/social_icons/tiktok_lg.png" alt="tiktok" border="0" />
							<br />
							US Open
							<br />
							tiktok.com/@usopen
						</EventsLink>
					</div>
					{/* <div className="section">
            <EventsLink to="https://www.snapchat.com/add/usopentennis">
              <img
                src="/assets/images/nav/social_icons/snapchat_lg.png"
                alt="snapchat"
                border="0"
              />
              <br />
              usopentennis
              <br />
              www.snapchat.com/add/usopentennis
            </EventsLink>
          </div> */}
				</div>
				<footer id="uso-footer" className="container-fluid">
					<div id="footer-container" className="container">
						<div className="row top-row">
							<div className="align-self-center logo">
								<EventsLink to="http://membership.usta.com/">
									<img
										className="img-fluid"
										src="/assets/images/footer/usopen-footer-logo.png"
										srcSet="/assets/images/footer/usopen-footer-logo.svg"
										alt="USTA Membership"
									/>
								</EventsLink>
							</div>

							<div className="text-center">
								<span className="footer-date">
									{get(this.props, 'componentConfig.footer.dates', '')}
								</span>
							</div>

							<div className="align-self-center">
								<div className="socialShare">
									<a
										href="#"
										onClick={() => {
											this.showSocial(true);
										}}>
										<img
											className="d-sm-none img-fluid"
											src="/assets/images/footer/share-icon.png"
											srcSet="/assets/images/footer/share-icon.svg"
											alt="Share"
										/>
									</a>
								</div>

								<div id="footer-icons" className="d-none d-sm-block">
									<ul>
										{/* <li className="v-snapchat">
                      <EventsLink to="https://www.snapchat.com/add/usopentennis">
                        <img
                          className="img-fluid"
                          src="/assets/images/footer/snapchat-icon.png"
                          srcSet="/assets/images/footer/snapchat-icon.svg"
                          alt="Snapchat"
                        />
                      </EventsLink>
                    </li> */}
										<li className="v-tiktok">
											<EventsLink to="https://www.tiktok.com/@usopen">
												<img
													className="img-fluid"
													src="/assets/images/footer/tiktok-icon.png"
													srcSet="/assets/images/footer/tiktok-icon.svg"
													alt="Tiktok"
												/>
											</EventsLink>
										</li>
										<li className="v-youtube">
											<EventsLink to="http://www.youtube.com/user/USOPEN">
												<img
													className="img-fluid"
													src="/assets/images/footer/youtube-icon.png"
													srcSet="/assets/images/footer/youtube-icon.svg"
													alt="YouTube"
												/>
											</EventsLink>
										</li>
										<li className="v-instagram">
											<EventsLink to="http://instagram.com/usopen">
												<img
													className="img-fluid"
													src="/assets/images/footer/instagram-icon.png"
													srcSet="/assets/images/footer/instagram-icon.svg"
													alt="Instagram"
												/>
											</EventsLink>
										</li>
										<li className="v-twitter">
											<EventsLink to="https://twitter.com/usopen">
												<img
													className="img-fluid"
													src="/assets/images/footer/twitter-icon.png"
													srcSet="/assets/images/footer/twitter-icon.svg"
													alt="Twitter"
												/>
											</EventsLink>
										</li>
										<li className="v-facebook">
											<EventsLink to="https://www.facebook.com/usopentennis">
												<img
													className="img-fluid"
													src="/assets/images/footer/facebook-icon.png"
													srcSet="/assets/images/footer/facebook-icon.svg"
													alt="Facebook"
												/>
											</EventsLink>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="row middle-row">
							<div>
								<div id="footer-links">
									<ul>
										{/* //removed social link - amb061620
											<li>
											<EventsLink to="/en_US/social/index.html?promo=footer">
												Social
											</EventsLink>
											</li> */}
										<li>
											<EventsLink to="/en_US/visit/history.html?promo=footer">History</EventsLink>
										</li>
										<li>
											<EventsLink to="/en_US/search/index.html?promo=footer">Search</EventsLink>
										</li>
										<li>
											<EventsLink to="http://careers.usopen.org/">Careers</EventsLink>
										</li>
										{this.props?.componentConfig?.useUsableNet && (
											<li>
												<EventsLink to="/en_US/info/accessibility.html?promo=footer">
													Accessibility Statement
												</EventsLink>
											</li>
										)}
										<li>
											<EventsLink to="/en_US/info/sitemap/index.html?promo=footer">
												Site Map
											</EventsLink>
										</li>
										<li>
											<EventsLink
												to={get(
													this.props,
													'componentConfig.footer.ibmLink',
													'https://www.ibm.com/sports/usopen'
												)}>
												Technology at the US Open
											</EventsLink>
										</li>
										<li>
											<EventsLink to="/en_US/about/green_initiatives.html?promo=footer">
												Green Initiatives
											</EventsLink>
										</li>
										<li>
											<EventsLink to="/en_US/info/privacy/index.html?promo=footer">
												Privacy
											</EventsLink>
										</li>
										<li>
											<EventsLink to="/en_US/info/terms/index.html?promo=footer">
												Terms of Use
											</EventsLink>
										</li>
										<li>
											<EventsLink to="/en_US/about/sponsors.html?promo=footer">
												Partners
											</EventsLink>
										</li>
										<li>
											<EventsLink to="/en_US/content/licensing.html">Licensing</EventsLink>
										</li>
										<li>
											<EventsLink to="/en_US/info/contact_us/index.html?promo=footer">
												Contact Us
											</EventsLink>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="row bottom-row">
							<div className="col-12 align-self-center text-center">
								<p className="d-sm-none">
									Copyright IBM Corp.,
									<br />
									United States Tennis Association{' '}
									{get(this.props, 'componentConfig.footer.copyrightYear', '')}.
									<br />
									All Rights Reserved
								</p>
								<p className="d-none d-sm-block">
									Copyright{' '}
									<EventsLink to="http://www.ibm.com/us" target="_blank">
										IBM Corp.
									</EventsLink>
									,{' '}
									<EventsLink to="http://www.usta.com" target="_blank">
										United States Tennis Association
									</EventsLink>{' '}
									{get(this.props, 'componentConfig.footer.copyrightYear', '')}. All Rights Reserved
								</p>
							</div>
						</div>
					</div>
				</footer>
				{this.props?.componentConfig?.useSatisfi ? <GuestInfo /> : null}
			</noindex>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
