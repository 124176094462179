import React, { Component } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';
import ReactEcp from 'react-ecp';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import { getQuerystringValues } from 'appdir/components/general/Util';
import EventsLink from 'appdir/components/general/EventsLink';
import WatchRadio from './WatchRadio';
import { Helmet } from 'react-helmet';

/**
 * -----------------------------------------------------------------------------
 * React Component: RadioPlayer
 *
 * supports
 *  - USO regular live radio (radio.html -- id = radiousopen, this is default)
 *  - USO action radio (audio-radio.html -- id = actionaudio -- no Amex branding & show Stub if not on Air)
 *
 *  Only Action Radio has stub state coming from stubs_web.json
 *
 * -----------------------------------------------------------------------------
 */
export default class RadioPlayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			radioPlaying: false,
			muted: false,
			intervalId: null,
			volume: 0.5,
			selectedTab: props?.match?.params?.type === 'watch' ? 'watchTab' : 'listenTab',
		};

		this.initialLoad = true;
	}

	componentDidMount() {
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		logger.log('querystring: %o', this.parsedQs);

		this.isActionAudio = this.props?.match?.params?.type?.toLowerCase() == 'actionaudio';

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: this.isActionAudio ? 'Action Radio Player' : 'Live Radio Player',
		});
	}

	componentWillUnmount() {
		// logger.log('[RadioPlayer] componentWillUnmount %o', this);
		['__uspapi', '__cmp', '__tcfapi'].forEach(function(e) {
			if (window[e] !== null) {
				logger.log('[RadioPlayer] componentWillUnmount %o exists, deleting', e);
				delete window[e];
			}
		});
		this.setState(
			{
				inlineLoaded: false,
			},
			() => {
				this.inlineWidget = null;
				this.inlineReady = false;
				this.inlineSubscribed = false;
			}
		);
	}

	componentDidUpdate(prevProps, prevState) {
		let curType = this.props?.match?.params?.type;
		let prevType = prevProps?.match?.params?.type;
		if (
			(this.initialLoad && !this.state.radioId && this.radio?.live.length > 0) ||
			(curType !== prevType && this.radio?.live.length > 0)
		) {
			let radioId = this.isActionAudio ? 'actionaudio' : 'radiousopen';

			this.setState({
				radioId,
			});
		}

		/** live status changed, if playing stop it */
		let curLive = this.radioData?.live;
		let prevRadioData = prevProps.radio?.live?.filter(d => d.id?.toLowerCase() === prevState?.radioId)[0];
		let prevLive = prevRadioData?.live;
		logger.log('[RadioPlayer] - curLive%o, prevLive:%o', curLive, prevLive);
		if (curLive !== prevLive && this.state.radioPlaying && !curLive) {
			this.setState(
				{
					radioPlaying: false,
				},
				() => {
					this.state.player.pause();
				}
			);
		}
	}

	playRadio() {
		// logger.log('[RadioPlayer] - playRadio %o', this.rap.audioEl);
		if (this.state.player) {
			logger.log('[ReactRadioPlayer] - playRadio:%o', this.state.player.amp.paused);
			if (!this.state.radioPlaying && this?.radioData?.live) {
				this.setState(
					{
						radioPlaying: true,
					},
					() => {
						this.state.player.play();
						this.state.player.unmute();
						MeasurementUtils.dispatchMeasurementCall(`RadioPlayer`, {
							action: { playing: true },
						});
					}
				);
			} else {
				this.setState(
					{
						radioPlaying: false,
					},
					() => {
						this.state.player.pause();
						MeasurementUtils.dispatchMeasurementCall(`RadioPlayer`, {
							action: { playing: false },
						});
					}
				);
			}
		}
	}

	kicker() {
		let kickerClassName = 'kicker off-air';
		let kickerText = 'Off Air';

		if (this?.radioData?.live) {
			if (this.state.radioPlaying) {
				kickerClassName = 'kicker live now-playing';
				kickerText = 'Now Playing';
			} else {
				kickerClassName = 'kicker live on-air';
				kickerText = 'On Air';
			}
		}

		return (
			<div className={kickerClassName} tabIndex={0}>
				{kickerText}
			</div>
		);
	}

	clickWatchTab() {
		this.setState(
			{
				selectedTab: 'watchTab',
			},
			() => {
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					selectedTab: 'Radio Watch',
				});
			}
		);
	}

	clickListenTab() {
		this.setState(
			{
				selectedTab: 'listenTab',
			},
			() => {
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					selectedTab: 'Radio Listen',
				});
			}
		);
	}

	onReady(player) {
		logger.log('[ReactRadioPlayer] onReady - player:%o', player);

		this.setState({
			player,
		});
	}

	onPlay() {
		logger.log('[ReactRadioPlayer] onPlay state:%o', this.state);
	}

	onPause() {
		logger.log('[ReactRadioPlayer] onPause state:%o', this.state);
	}

	render() {
		logger.log('[ReactRadioPlayer] - render this:%o', this);
		this.radio = this.props?.radio;

		let dataTmLead = this.radio?.live.find(item => item.id === 'radioVisionUSOpen');
		dataTmLead = dataTmLead?.media[0]?.url;

		let radioPlayerClass = `radio-icon ${this.state.radioPlaying ? 'playing ' : ''} ${
			!this.isActionAudio ? 'extra-space ' : ''
		}`;
		radioPlayerClass += `${this?.radioData?.live ? 'hoverable ' : ''}`;

		// let volumeClass = `${this.state.muted ? 'muted' : 'unmuted'}`;

		let radioConfig = {};

		if (
			(this.isActionAudio && this.props?.stubs?.actionAudio?.stub === 'stub') ||
			(this.props?.stubs?.radio?.stub === 'stub' && !this.isActionAudio)
		) {
			return (
				<section id="radioPage">
					<div className="top">
						<EventsLink to="/en_US/index.html">
							<div id="eventLogo">
								<img
									className="img-fluid d-lg-block mx-auto"
									src="/assets/images/header/usopen-header-logo.png"
									alt="US Open"
								/>
							</div>
						</EventsLink>
					</div>
					<div id="radioContent">
						<div className="left">
							<h3>
								{this.props.stubs?.actionAudio && this.isActionAudio
									? this.props.stubs?.actionAudio?.title
									: this.props.stubs?.radio
									? this.props.stubs?.radio?.title
									: 'Radio'}
							</h3>
							<div className="stubContent">
								{this.isActionAudio
									? this.props.stubs?.actionAudio?.text
									: this.props.stubs?.radio?.text}
							</div>
						</div>
					</div>
				</section>
			);
		} else if (
			this.props?.stubs &&
			this.state.radioId &&
			((this.radio?.live?.length > 0 && this.isActionAudio && this.props?.stubs?.actionAudio?.stub !== 'stub') ||
				(this.radio?.live?.length > 0 && !this.isActionAudio))
		) {
			this.radioData = this.radio?.live?.filter(r => r.id?.toLowerCase() === this.state.radioId)[0];

			if (this.radioData) {
				radioConfig.id = this.radioData.id;
				radioConfig.title = this.radioData.title;
				radioConfig.streams = [
					{
						// url: 'https://usta-radio.akamaized.net/hls/live/2094417/usta/master.m3u8', // test stream HLS
						url: this.radioData.media_high[0].url,
						cdn: this.radioData.media_high[0].cdn,
						mimeType: this.radioData.media_high[0].url.includes('.m3u8')
							? 'application/x-mpegURL'
							: 'audio/mpeg',
						streamType: 'live',
					},
				];
				radioConfig.playsinline = true;
				radioConfig.autoplay = false;
			}

			//logger.log('[RadioPlayer] - render radioConfig:%o', radioConfig);
			return (
				<>
					<Helmet>
						<title>{'US Open Radio'}</title>
						<meta name="measureTitle" content={`${this.radioData.measurement_title}`} />
						<meta property="og:title" content={`${this.radioData.title}`} />
					</Helmet>
					<section id="radioPage">
						<div className="radio-body">
							<div className="top">
								<EventsLink to="/en_US/index.html">
									<div id="eventLogo">
										<img
											className="img-fluid d-lg-block mx-auto"
											src="/assets/images/header/usopen-header-logo.png"
											alt="US Open"
										/>
									</div>
								</EventsLink>
								{!this.isActionAudio && <div id="amexLogo">American Express</div>}
							</div>
							<div className="radio-blue-bar">
								<div className="radio-tabs">
									<div
										className={`listen-tab ${
											this.state.selectedTab === 'listenTab' ? 'selected' : ''
										}`}
										data-text={'Listen'}>
										<EventsLink to="/en_US/radio/listen.html" onClick={() => this.clickListenTab()}>
											Listen
										</EventsLink>
									</div>
									{!this.isActionAudio && (
										<div
											className={`watch-tab ${
												this.state.selectedTab === 'watchTab' ? 'selected' : ''
											}`}
											data-text={'Watch'}>
											<EventsLink
												to="/en_US/radio/watch.html"
												onClick={() => this.clickWatchTab()}>
												Watch
											</EventsLink>
										</div>
									)}
								</div>
							</div>
							{this.state.selectedTab === 'listenTab' && (
								<div className="listen-tab-content">
									<div id="radioContent">
										<div className="left">
											<h3 tabIndex={0}>
												{this.isActionAudio ? 'Action Audio' : 'US Open Radio'}
											</h3>
											{!this.isActionAudio ? (
												<h4>Presented by American Express</h4>
											) : (
												<div className="description" tabIndex={0}>
													<p>
														Action Audio works by turning spatial data from real-time ball
														monitoring into 3D sound. The system emphasizes ball speed and
														trajectory, proximity to line and shot type, and augments
														critical moments to allow blind and low vision audiences to
														follow the game without seeing the ball.
													</p>
												</div>
											)}
											<div className="radio-page-container">
												{this.kicker()}
												<div
													className={this.state.player?.amp ? radioPlayerClass : ''}
													onClick={() => {
														this.playRadio();
													}}
													onKeyDown={e => {
														if (e.key == ' ' || e.code == 'Space') {
															this.playRadio();
														}
													}}
													tabIndex={0}>
													<div
														aria-label={`${
															this?.radioData?.live
																? 'Audio is available. Toggle Audio'
																: ''
														}`}></div>
												</div>
											</div>
											{this.props.playerConfig &&
											radioConfig?.id &&
											radioConfig?.streams?.length > 0 ? (
												<ReactEcp
													id={'uso-radio'}
													ampBasePath="/libs/"
													playerConfig={this.props?.playerConfig}
													videoConfig={radioConfig}
													onReady={amp => this.onReady(amp)}
													onPlay={() => this.onPlay()}
													onPause={() => this.onPause()}
													colors={{}}
												/>
											) : (
												<div className="stubContent">
													There is no audio available at this time.
												</div>
											)}
										</div>
									</div>
								</div>
							)}
							{this.state.selectedTab === 'watchTab' && (
								<div className="watch-tab-content">
									<WatchRadio dataTmLead={dataTmLead} />
								</div>
							)}
						</div>
					</section>
				</>
			);
		} else {
			return <LoadingIndicator />;
		}
	}
}

RadioPlayer.defaultProps = {};
