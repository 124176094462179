/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Template from 'components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import JSXParser from 'react-jsx-parser';
import StubBox from 'appdir/components/common-ui/StubBox';
import LiveVideoPlayer from 'appdir/components/content/LiveVideoPlayer';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { isIOS } from 'react-device-detect';
import AdTag from 'appdir/components/general/AdTag';
import LiveVideoSubnav from '../LiveVideo/LiveVideoSubnav';

/**
 * -----------------------------------------------------------------------------
 * React Component: LivePractice
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LivePractice'],
		...props,
		stubs: state['Config'].stubPages,
		liveVideo: state['ActiveData'].video,
		adConfig: state['Config']['adConfig'],
		windowSize: state['PageHeader'].windowSize,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LivePractice.mount()),
	unmount: () => dispatch(deps.actions.LivePractice.unmount()),
	select: selected => dispatch(deps.actions.LivePractice.select(selected)),
});

class LivePractice extends Component {
	constructor(props) {
		super(props);

		logger.log('[LivePractice] constructoLivePractice:%o', props);

		this.onVideoEvent = this.onVideoEvent.biLivePractice;
		this.onNavClick = this.onNavClick.bind(this);
		this.skip = 0;
		let currVideo = null;
		this.state = {
			// ...props,
			currVideo,
		};
	}

	componentDidMount() {
		logger.log('[LivePractice] componentDidMount - state:%o', this.state);
	}

	componentWillUnmount() {
		//logger.log('[LivePractice] componentWillUnmount');
		this.props.unmount();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[LivePractice] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[LivePractice] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	onVideoEvent(event) {
		logger.info('[LivePractice] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				directVideo: '',
			});
		}
	}

	onNavClick(selected) {
		if (selected != this.state.selected) {
			//logger.info('[LivePractice] onNavClick - selected:%o', selected);
			this.props.select(selected);
		}
	}
	renderLivePracticeHero() {
		// first lets get the video info and dertimine if its live
	}

	render() {
		logger.log('[LivePractice] render - this:%o', this);
		// logger.log('[LivePractice] render - available:%o', this.state.geos.available);

		let header_propsData = {
			headerType: 'live-streaming',
			title: 'US Open Practice Courts',
			shortTitle: 'Practice Courts',
			scroll: false,
			metaTitle: 'US Open Practice Courts | Videos',
			metaDescription: 'US Open Live Practice Court Stream from the US Open Tennis Championships',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/video/index.html',
					title: 'Watch',
				},
				{
					link: '/en_US/video/practice-courts/court-p1.html',
					title: 'Practice Courts',
				},
			],
			title: '',
			showLive: false,
		};

		// if have selected video and geos are set
		if (this.state.stubs && this.state.stubs.practice_courts && this.state.stubs.practice_courts.stub == 'stub') {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<StubBox
						attributes={{
							title: header_propsData.title,
							message: this.state.stubs.liveshow.text,
							basePicPath: this.state.basePicPath,
						}}
					/>
				</Template>
			);
		} else if (this.state.liveVideo && this.state.liveVideo.practice && this.state.selected !== 'index') {
			logger.log('[LivePractice] vidData :%o', this.state.liveVideo.practice[this.state.selected]);
			let vidData = this.state.liveVideo.practice[this.state.selected];
			subheader_attributes.title = vidData && vidData.hasOwnProperty('name') ? vidData.name : '';
			subheader_attributes.showLive = vidData && vidData.hasOwnProperty('live') ? vidData.live : false;
			let currVideoData = {
				id: vidData.code,
				title: `Practice Court ${vidData.code}`,
				poster: get(vidData, 'images.slate', ''),
				shareUrl: `https://www.usopen.org/en_US/video/practice-courts/court-${this.state.selected}.html`,
				streams: [
					{
						cdn: 'Akamai',
						url: vidData.media.desktop[0].url,
						mimeType: 'application/x-mpegURL',
						streamType: 'live',
					},
				],
				tracks: [
					{
						kind: 'captions',
						type: vidData.media.desktop[0].captionsType,
						srclang: 'en',
						src: '',
					},
				],
				startSeconds: 0,
				autoplay: isIOS ? false : true,
				autoplayPolicy: 'mutedinline',
				playsinline: false,
				displayDate: '',
				ads: [],
			};

			logger.log('[LivePractice] render - currVideoData:%o', currVideoData);
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="page-content live_show">
						<LiveVideoSubnav history={this.props.history} selected={this.state.selected} />
						<section className="heroSection landing">
							{currVideoData && vidData.live ? (
								<div className="heroVideo">
									<LiveVideoPlayer
										attributes={{
											videoData: currVideoData,
											shouldAutoplay: true,
											id: `practice_court_${vidData.code}`,
											reset: false,
										}}
									/>
								</div>
							) : (
								<div className="live-video">
									<div className="live-video-slate">
										<div className="slate-container">
											<div className="slate-image">
												<img src={vidData.images.slate} alt={`${vidData.name} Off Air`} />
											</div>
											<div className="slate-message">
												<h1>{vidData.name}</h1>
												<h2>Off Air</h2>
											</div>
										</div>
									</div>
								</div>
							)}
						</section>
						{this.state.stubs &&
						this.state.stubs.liveshowchips &&
						this.state.stubs.liveshowchips.stub != 'stub' ? (
							<React.Fragment>
								<div className="live_show_desc">
									<JSXParser jsx={this.state.stubs.liveshowchips.text} />
								</div>
							</React.Fragment>
						) : null}
						<div
							style={{
								width: '100%',
								textAlign: 'center',
								margin: '20px auto',
							}}>
							<div id="Chip1">
								<AdTag
									adConfig={this.state.adConfig.live_video}
									dfpNetworkId={this.state.adConfig.dfpNetworkId}
								/>
							</div>
						</div>
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LivePractice);
LivePractice;
