import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	switch (action.type) {
		case deps.actionTypes.PLAYERPAGE_LOADING: {
			newState = {
				...state,
				...action.data,
			};
			logger.log('[PlayerPage] reducer - PLAYERPAGE_LOADING: %o', newState);
			return newState;
		}
		default:
			return state;
	}
};
