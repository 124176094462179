import ResultsPage from './index';
import deps from 'dependencies';

// tournDay will be a field in the params obj.
// route is day<dayNum>.html
export default {
	//path:"/:lang(en_US)/scores/results/(index|day):tournDay(|\\d|\\d\\d).html",
	path: [
		'/:lang(en_US)/scores/day:tournDay.html',
		'/:lang(en_US)/scores/completed_matches/index.html',
		'/:lang(en_US)/scores/completed_matches/day:tournDay.html',
	],
	exact: true,
	component: ResultsPage,
	load: params => deps.actions.ResultsPage.mount(params),
};
