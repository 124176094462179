/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import FanWeekSchedule from 'appdir/components/general/FanWeekSchedule';

/**
 * -----------------------------------------------------------------------------
 * React Component: EventScheduleWebview
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['EventScheduleWebview'],
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.EventScheduleWebviewunt()),
});

class EventScheduleWebview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
		this.loadedJson = false; // local class var to keep track of last loaded json (used to detect if redirect is needed)
	}

	componentDidMount() {
		// logger.log('[EventScheduleWebviewomponentDidMount - state:%o', this.state);
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[EventScheduleWebviewomponentDidUpdate - state:%o', this.state);
		if (this.state.schedulePath && !this.loadedJson) {
			fetch(this.state.schedulePath)
				.then(data => {
					this.setState({
						scheduleData: data,
					});
					this.loadedJson = true;
				})
				.catch(error => {
					logger.log('[EventScheduleWebviewomponentDidUpdate error loading data: %o', error);
				});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	componentWillReceiveProps(nextProps) {
		// logger.log('[EventScheduleWebviewomponentWillReceiveProps - prev:%o', this.state);
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	render() {
		logger.log('[EventScheduleWebviewender - state:%o', this.state);

		return (
			<section className="event" id="schedule">
				<section className="page-content tourn-fanweek uso-tickets-calendar">
					<FanWeekSchedule />
				</section>
			</section>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventScheduleWebview);
