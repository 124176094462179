import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		// log('[PlayerStatsPage] action.mount - data:%o', data);
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const path = Config.scoringData.extraStats;
			const countriesPath = Config.scoringData.countries;
			const flagImagePathSmall = Config.otherData.flagImagePathSmall;
			const playerProfileImagePath = Config.otherData.playerProfileImagePath;
			const basePicPath = Config.otherData.basePicPath;

			dispatch({
				type: deps.actionTypes.PLAYERSTATSPAGE_LOAD,
				data: {
					path,
					countriesPath,
					flagImagePathSmall,
					playerProfileImagePath,
					basePicPath,
				},
			});
		});
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.PLAYERSTATSPAGE_FILTER, data: data });
	},

	setGender: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.PLAYERSTATSPAGE_SETGENDER, data: data });
	},
};
