/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';
import CountDownTimer from 'components/general/FanWeekSchedule/CountDownTimer';
/**
 * -----------------------------------------------------------------------------
 * Functional Component: HeroPrime
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const renderButton = (item, index) => {
	logger.log('[HeroPrime] renderButton - item:%o', item);
	return (
		<div className={`hero_button hero_button_count_3`} key={index} data-button-item={index + 1}>
			<EventsLink to={item.link}>
				<div className={`navItem`}>
					{item.title}
					<div className={`arrow-icon ${item.link.startsWith('#') ? 'down' : 'right'}`}>
						<i className={item.link.startsWith('#') ? 'icon-down-arrow' : 'icon-arrow-right'} />
						<i className={`${item.link.startsWith('#') ? 'icon-down-arrow' : 'icon-arrow-right'} arrow`} />
					</div>
				</div>
			</EventsLink>
		</div>
	);
};
const HeroPrime = props => {
	// logger.log('[HeroPrime] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let { data } = props;
	// logger.log('[HeroPrime] - data:%o', data);

	if (data?.background) {
		let images = data?.images;
		let background = data?.background?.image;
		let bgPosition = background?.position ? background.position : 'center';

		if (data?.buttons) {
			let buttons = data.buttons.button;
			logger.log('[HeroPrime] - buttons:%o %o', buttons.length, buttons);
			return (
				<section
					className={`hero_container buttons`}
					data-num-buttons={buttons?.length}
					style={{ backgroundImage: `url(${background.url})`, backgroundPosition: `${bgPosition}` }}>
					<div className={`hero_content`}>
						<div className={`hero_buttons title_container`}>
							<div className={`landing_title ${data?.textColor}`}>
								<h1>{ReactHtmlParser(data?.title)}</h1>
								{ReactHtmlParser(data?.description)}
								{data?.hasCountdown === 'true' ? <CountDownTimer date={data?.targetDate} /> : null}
							</div>
							<div className="hero-button-container">
								{buttons.length
									? buttons.map((item, i) => {
											return renderButton(item, i);
									  })
									: renderButton(buttons, 0)}
							</div>
						</div>
					</div>
				</section>
			);
		} else if (images) {
			return (
				<section className={`heroSection`}>
					<div className={`heroImage desktop`}>
						<div className={`heroContainer`}>
							<img src={background.url} alt={data?.title} style={{ zIndex: 0 }} />
							<div className={`heroInfo`}>
								<h1>{ReactHtmlParser(data?.title)}</h1>
								{data?.description}
								{data?.hasCountdown === 'true' ? <CountDownTimer date={data?.targetDate} /> : null}
							</div>
						</div>
						{/* Do something w/ images */}
						<div className={`border`} />
					</div>
				</section>
			);
		} else {
			return (
				<section
					className={`hero_container`}
					style={{ backgroundImage: `url(${background.url})`, backgroundPosition: `${bgPosition}` }}>
					<div className={`hero_content`}>
						<div className={`title_container`}>
							<div className={`landing_title ${data?.textColor}`}>
								<h1>{ReactHtmlParser(data?.title)}</h1>
								{ReactHtmlParser(data?.description)}
								{data?.hasCountdown === 'true' ? <CountDownTimer date={data?.targetDate} /> : null}
							</div>
						</div>
					</div>
				</section>
			);
		}
	} else if (data) {
		return <h1 className="header">{data?.title}</h1>;
	} else {
		return null;
	}
};

export default HeroPrime;
