import PhotoGalleryPage from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:lang(en_US)/:type(photos)/photogalleries/:date?/:id.html',
		exact: true,
		component: PhotoGalleryPage,
		load: params => deps.actions.GalleryPage.mount(params),
	},

	{
		path: '/:lang(en_US)/photogallery/:date/:id.html',
		exact: true,
		component: PhotoGalleryPage,
		load: params => deps.actions.GalleryPage.mount(params),
	},
];
