import Interviews from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/:lang(en_US)/news/interviews/index.html', '/:lang(en_US)/news/interviews/'],
		exact: true,
		component: Interviews,
		load: params => deps.actions.Interviews.mount(params),
	},
];
