import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	//logger.log('[PracticeScheduleWebview] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.PRACTICESCHEDULEWEBVIEW_MOUNT:
			// logger.log('[PracticeScheduleWebview] reducers - action:%o',action);

			newState = {
				...state,
				...action.data,
			};
			// logger.log('[PracticeScheduleWebview] reducers PRACTICESCHEDULEWEBVIEW_MOUNT - newState:%o',newState);
			return newState;

		default:
			return state;
	}
};
