import React, { Component } from 'react';

class CollapsibleMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	toggleMenu = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	render() {
		return (
			<div className="collapsibleMenu">
				<div className="header" onClick={e => this.toggleMenu(e)}>
					{this.props.title}
					{this.state.isOpen ? <i className="icon-up-arrow" /> : <i className="icon-down-arrow" />}
				</div>

				{this.state.isOpen ? <div className="content">{this.props.children}</div> : null}
			</div>
		);
	}
}

export default CollapsibleMenu;
