import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import { getPlatformImageSize } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';
import EventsLink from 'appdir/components/general/EventsLink';
import Helmet from 'react-helmet';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';

/**
 * -----------------------------------------------------------------------------
 * React Component: Gallery Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['GalleryPage'],
		prevLocation: state['Router']['prevLocation'],
		platform: state['Controller']['platform'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.GalleryPage.mount()),
	// hidePanel: (showFlag) => dispatch(deps.actions.Sidepanel.hidePanel(showFlag)),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
	unmount: () => dispatch(deps.actions.GalleryPage.unmount()),
});

class GalleryPage extends Component {
	constructor(props) {
		super(props);
		this.state = { currentIndex: 0, prevURL: false };
		this.renderGallery = this.renderGallery.bind(this);
	}

	componentDidMount() {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Gallery Page',
		});
	}

	componentWillUnmount() {
		// this.props.hidePanel(true);
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.galleryAPIPath && !this.state.galleryData) {
			let date = this.props.match.params.date;
			let galleryId =
				this.props.match.params.id || this.props.match.params.photoid || this.props.match.params.playerid;
			let galleryPath = this.props.galleryAPIPath
				.replace('<date>', date)
				.replace('<id>', galleryId)
				.replace('<playerId>', galleryId);
			fetch(galleryPath).then(data => {
				logger.info('[GalleryPage] URL used', galleryPath);
				logger.info('[GalleryPage] retrieved data', data);
				if (!this.props.match.params.playerid) {
					this.setViewedContent(parseInt(data.displayDate), data.contentId);
				}

				this.setState(prevState => {
					return {
						...prevState,
						galleryData: data,
						prevURL: this.props.prevLocation !== '' ? true : false,
					};
				});
			});
		}
	}

	constructItems() {
		let imageSize = getPlatformImageSize(this.props.platform);
		let itemContent = [];
		if (this.state.galleryData) {
			if (this.state.galleryData.photos) {
				itemContent = this.state.galleryData.photos.map((d, i) => {
					return {
						src: d[imageSize].url,
						title: d.title,
						credit: d.credit,
						description: d.title,
						id: d.id,
						thumb: d.thumb.url,
					};
				});
			} else {
				let galleryData = this.state.galleryData;
				itemContent.push({
					src: galleryData[imageSize].url,
					title: galleryData.title,
					credit: galleryData.credit,
					description: galleryData.title,
					id: galleryData.id,
					thumb: galleryData.thumb.url,
				});
			}
		}

		return itemContent.map(d => {
			return {
				renderItem: this.renderPlayItems.bind(this, d),
				thumbnail: d.thumb,
			};
		});
	}

	_onSlide(index) {
		this.setState(prevState => {
			return { ...prevState, currentIndex: index };
		});

		let props = {
			prop16: this.state.galleryData.photos[index].title,
			linkTrackVars: 'prop4,prop35,prop16',
		};
	}

	goBack() {
		this.props.history.goBack();
	}

	renderPlayItems(data) {
		return (
			<div className="slider-content">
				<div className="slider-title">
					{data.title} <span className="gallery-credit">{data.credit}</span>
				</div>
				<img src={data.src} />
			</div>
		);
	}

	setViewedContent(timestamp, contentId) {
		//logger.log('[ContentPage] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.updateViewedContent(timestamp, contentId);
	}

	renderGallery(contentItems) {
		logger.log('[GalleryPage] this.state.galleryData.photos:%o', this.state.galleryData.photos);
		let imageSize = getPlatformImageSize(this.props.platform);
		return this.state.galleryData.photos ? (
			<div className="inline-carousel-container">
				<ResponsiveCarousel
					showThumbs={false}
					useKeyboardArrows={true}
					infiniteLoop
					showIndicators={false}
					centerMode
					dynamicHeight={true}
					centerSlidePercentage={90}>
					{this.state.galleryData.photos.map((item, i) => (
						<div key={i}>
							<img src={item[imageSize].url} alt={item.title || item.caption} />
							<div class="slider-caption">{item.title}</div>
						</div>
					))}
				</ResponsiveCarousel>
			</div>
		) : null;
	}

	render() {
		logger.log('[GalleryPage] render - this:%o ', this);
		let contentImageURL;
		let contentItems;
		if (this.state.galleryData) {
			contentItems = this.constructItems();
			if (this.state.galleryData.content) {
				if (this.state.galleryData.content.length > 0) {
					contentImageURL = this.state.galleryData.photos[0][getPlatformImageSize(this.props.platform)].url;
				}
			} else {
				contentImageURL = this.state.galleryData.photos[0][getPlatformImageSize(this.props.platform)].url;
			}
			if (contentImageURL) {
				contentImageURL =
					contentImageURL.indexOf('//') !== -1 || contentImageURL.indexOf('www.usopen.org') !== -1
						? contentImageURL
						: `${window.location.protocol}//${window.location.hostname}${contentImageURL}`;
			}
		}

		return this.state.galleryData ? (
			<span>
				{this.state.galleryData.content ? (
					this.state.galleryData.content.length > 0 ? (
						<Helmet>
							<meta name="measureTitle" content="Detail" />
							<meta property="og:title" content={this.state.galleryData.content[0].title} />
							<meta property="og:description" content={this.state.galleryData.content[0].description} />
							<meta property="fb:app_id" content="471867639491882" />
							<meta property="og:image" content={contentImageURL} />
							<meta property="og:url" content={this.state.galleryData.photos[0].share} />
							<meta name="twitter:card" content="summary_large_image" />
							<meta name="twitter:site" content="@usopen" />
							<meta name="twitter:creator" content="@usopen" />
							<meta name="twitter:title" content={this.state.galleryData.content[0].title} />
							<meta name="twitter:description" content={this.state.galleryData.content[0].description} />
							<meta name="twitter:image" content={contentImageURL} />
							<meta name="twitter:url" content={this.state.galleryData.photos[0].share} />
						</Helmet>
					) : null
				) : (
					<Helmet>
						<meta name="measureTitle" content="Detail" />
						<meta property="og:title" content={this.state.galleryData.title} />
						<meta property="og:description" content={this.state.galleryData.description} />
						<meta property="fb:app_id" content="471867639491882" />
						<meta property="og:image" content={contentImageURL} />
						<meta property="og:url" content={this.state.galleryData.shareUrl} />
						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:site" content="@usopen" />
						<meta name="twitter:creator" content="@usopen" />
						<meta name="twitter:title" content={this.state.galleryData.title} />
						<meta name="twitter:description" content={this.state.galleryData.description} />
						<meta name="twitter:image" content={contentImageURL} />
						<meta name="twitter:url" content={this.state.galleryData.shareUrl} />
					</Helmet>
				)}

				<div className={`Gallery-page photo`}>
					{this.state.galleryData.content ? (
						this.state.galleryData.content.length > 0 ? (
							this.state.galleryData.content[this.state.currentIndex].title !== '' ? (
								<div className="slider-title-mobile">
									{this.state.galleryData.content[this.state.currentIndex].title}
									<span className="gallery-credit-mobile">
										{this.state.galleryData.photos[this.state.currentIndex].credit}
									</span>
								</div>
							) : null
						) : null
					) : this.state.galleryData.title !== '' ? (
						<div className="slider-title-mobile">
							{this.state.galleryData.title}
							<span className="gallery-credit-mobile">{this.state.galleryData['photos'][0].credit}</span>
						</div>
					) : null}
					{!this.state.prevURL ? (
						<EventsLink to={`/`}>
							<i className="icon-close" />
						</EventsLink>
					) : (
						<i
							onClick={() => {
								this.goBack();
							}}
							className="icon-close"
						/>
					)}
					{this.renderGallery(contentItems)}

					{this.state.galleryData.photos ? (
						this.state.galleryData.photos.length > 0 ? (
							<ShareMenu
								type="gallery"
								onClick={this.onShareClick}
								url={this.state.galleryData.photos[this.state.currentIndex].share}
								color={'white'}
							/>
						) : null
					) : (
						<ShareMenu
							type="gallery"
							onClick={this.onShareClick}
							url={this.state.galleryData.shareUrl}
							color={'white'}
						/>
					)}
				</div>
			</span>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryPage);
