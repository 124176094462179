import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[DrawAnalysis] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log('[DrawAnalysis] action.mount - data: %o', data);

			let data = {
				status: 'loaded',
			};
			logger.log('[DrawAnalysis] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.DRAWANALYSIS_MOUNT, data: data });
		});
	},

	update: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWANALYSIS_UPDATE, data: data });
	},
};
