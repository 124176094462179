/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

const mapStateToProps = (state, props) => {
	return {
		...state['Video'],
		anyclipConfig: state?.['Config']?.videoPlayer?.anyclip ?? {},
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Video.mount()),
    setVideoLocation: (data) => dispatch(deps.actions.Video.setVideoLocation(data))
});
/**
 * -----------------------------------------------------------------------------
 * Functional Component: Video
 * -----------------------------------------------------------------------------
 */
class AnyclipFloat extends Component {
    constructor(props) {
		super(props);
		this.state = {
			floatScriptLoaded: false,
            floatWidgetLoaded: false
		};

		this.floatWidget = null;
        this.floatPlaylist = false

		this.isAshe = document.location.href.includes('ashe.') ? true : false;
		this.isLocalhost = document.location.href.includes('localhost') || document.location.href.includes('dev.usopen.org') ? true : false;

		logger.log('[AnyclipFloat] constructor this:%o', this);
	}

    componentDidMount() {
		// logger.log('[AnyclipFloat] componentDidMount() %o', this);
		
        window.addEventListener('message', event => {
			if (
				event?.data?.length > 0 &&
				event?.data?.indexOf('lre:playerReady://') === 0 &&
				!this.state.floatWidgetLoaded
			) {
				if(window?.anyclip?.widgets?.length > 1) {
                    this.floatWidget = window.anyclip.getWidget(this.props?.anyclipConfig?.floatPlayerId);

                    this.setState({
                        floatWidgetLoaded: true,
                    });
                }
			}
		});
	}

    componentDidUpdate(prevProps, prevState) {
        logger.log('[AnyclipFloat] componentDidUpdate prevProps:%o, this:%o', prevProps, this);
        if(
            (prevProps?.currentVideo?.outofport !== this.props?.currentVideo?.outofport) && 
            this.props?.currentVideo?.outofport === true
        ) {
            if(!this.floatScript) {
                this.floatScript = document.createElement('script');
                this.floatScript.async = true;
                this.floatScript.src = this.props?.anyclipConfig?.anyclipScript;
                this.floatScript.setAttribute('pubname', this.props?.anyclipConfig?.pubname);
                // this.floatScript.setAttribute('data-tm-videoguids', '[]');
                // this.floatScript.setAttribute('data-tm-videoguids', '["ashe16934516102275337"]');
                this.floatScript.setAttribute('widgetname', this.props?.anyclipConfig?.floatPlayerId);

                let videoContainer = document.getElementById(`anyclip-float-container`);
                videoContainer.appendChild(this.floatScript);

                this.setState({
                    floatScriptLoaded: true,
                });
            }
        }

        if(
            this.floatWidget && this.state?.floatWidgetLoaded === true && 
            (!this.floatPlaylist ||
            (this.floatPlaylist && !isEqual(prevProps?.currentVideo, this.props?.currentVideo)))
        ) {
            logger.log('[AnyclipFloat] setting playlist:%o', this.props?.currentVideo);	
            
            this.floatPlaylist = true;
            this.floatWidget.setPlaylist(this.props?.currentVideo?.playlist, {startFromTime: this.props?.currentVideo?.timeline});
            // logger.log('[AnyclipFloat] this.floatWidget:%o', this.floatWidget);
            // logger.log('[AnyclipFloat] this.floatWidget:%o', this.floatWidget.playlist());	
		}
    }
    
    render() {
        return (
            <div id="anyclip-float-container"></div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnyclipFloat);