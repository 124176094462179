/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import deps from 'dependencies';
import EventsLink from 'appdir/components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';
import { Carousel } from 'react-responsive-carousel';
import ButtonGeneral from 'components/cms/ButtonGeneral';
import { getAppVideoLink } from 'appdir/components/general/Util';
import { isMobile, isTablet } from 'react-device-detect';

/**
 * -----------------------------------------------------------------------------
 * React Component: PremiumContent
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
};

const PremiumContent = props => {
	logger.log('[PremiumContent] - props:%o', props);

	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);

	let attributes = props.data;
	let type = 'premiumContent';
	let titleLine = '';

	const measureClick = (args) => {
		// logger.log('[PremiumContent] onClick - args:%o', args);
		if (args.actionType) {
			MeasurementUtils.dispatchMeasurementCall(args.actionType, { args: args });
		} else {
			MeasurementUtils.dispatchMeasurementCall('eventsLink', { args: args });
		}
	}

	const renderVideoItem = (margs) => {
		let video = attributes['video'];
		if(margs) {
			measureClick(margs);
		}

		let videoData = {
			video: {
				...video,
				autoplay: true,
				playsinline: true,
				streams: [video?.streams?.stream],
				tracks: [video?.tracks?.track]
			}
		};
		if(!window.webview) {
			dispatch(deps.actions.ModalOverlay.loadOverlayModal('VIDEO_MODAL', videoData ))
		} else {
			let videoLink = getAppVideoLink(video.id);
            videoLink?.onclick();
		}
	}

	let getTitleLine = image => {
		if (image['caption'] && image['credit']) {
			return (
				<div className="photo-title">
					{image['caption']}
					<div className="photo-credit">{image['credit']}</div>
				</div>
			);
		} else if (image['caption']) {
			return <div className="photo-title">{image['caption']}</div>;
		} else if (image['credit']) {
			return <div className="photo-credit">{image['credit']}</div>;
		} else {
			return null;
		}
	};

	let openRadio = debug => {
		let radioWindow = '';
		let radioLaunchPage = '';
		let baseUrl = '/en_US/radio/radio.html';

		let lang = 'en';

		if (isMobile || isTablet) {
			window.open(baseUrl, '_blank');
		} else {
			var d = new Date();
			var t = d.getTime();

			radioLaunchPage += baseUrl + '?ts=' + t;
			radioLaunchPage += debug && debug.indexOf('debug') > -1 ? '&db=true' : '&db=false';
			radioLaunchPage += '&ref=' + document.location.host + document.location.pathname;
			radioLaunchPage += '&lang=' + lang;
			if (radioWindow.closed || radioWindow == '') {
				radioWindow = window.open(radioLaunchPage, 'radioWindow', 'width=1024,height=708,top=50,left=50');
				if (radioWindow.opener == null) radioWindow.opener = self;
			}
			radioWindow.focus();
		}
	};

	let radioButton = () => {
		return (
			<div className="uso-button uppercase blue wider round" onClick={() => openRadio()}>
				Listen
			</div>
		);
	};

	//look for error
	if (!attributes) {
		return null;
	}

	if (attributes['images']) {
		let image = attributes['images']['image'];

		if (attributes['style'] == 'overlay') {
			type = 'premiumOverlay';
		} else if (attributes['style'] == 'button-under') {
			type = 'premiumButtonUnder';
		} else if (attributes['images'].image.length > 1) {
			type = 'gallery';
		}

		if (type == 'premiumOverlay') {
			// logger.log('[PremiumContent] premiumOverlay - attributes:%o', attributes);
			return (
				<div className={`premium-content`}>
					<div className="image-bg" style={{ backgroundImage: `url(${image['url']})` }}>
						<img src={image['url']} alt={attributes['title']} />
						{props?.data?.section == 'fan-week-page' ? (
							<div className="caption">
								<h2>{attributes['title']}</h2>
								<div className="content-container">
									<div className="text">{ReactHtmlParser(attributes['description'])}</div>
									<div className="button-wrapper">
										<ButtonGeneral
											data={{
												...props.data,
												style: 'uso-button uppercase blue round',
												title: props.data.button,
												measureData: `${props.data.type}_link`,
											}}
										/>
									</div>
								</div>
							</div>
						) : (
							<div className="caption">
								<h2>{attributes['title']}</h2>
								<div className="text">{ReactHtmlParser(attributes['description'])}</div>
								<ButtonGeneral
									data={{
										...props.data,
										style: 'uso-button',
										title: props.data.button,
										measureData: `${props.data.type}_link`,
									}}
								/>
							</div>
						)}
					</div>
				</div>
			);
		} else if (type == 'premiumButtonUnder') {
			// logger.log('[PremiumContent] premiumButtonUnder - attributes:%o', attributes);
			return (
				<div className={`premium-content ${attributes['style']}`}>
					<div className="image">
						{type == 'gallery' ? (
							<Carousel showStatus={false} showThumbs={false} emulateTouch useKeyboardArrows infiniteLoop>
								{attributes['images'].image.map((item, i) => (
									<div key={i}>
										<img src={item['url']} alt={item['caption']} />
									</div>
								))}
							</Carousel>
						) : (
							<EventsLink to={`${attributes['link']}`}>
								<img src={image['url']} alt={attributes['title']} />
							</EventsLink>
						)}
						<h2>{attributes['title']}</h2>
						{props.data.button !== '' ? (
							props.data.button && !props.data.link ? (
								<div className="button-wrapper">
									<div className="no-button">{props.data.button}</div>
								</div>
							) : (
								<div className="button-wrapper">
									<ButtonGeneral
										data={{
											...props.data,
											style: 'uso-button uppercase blue full-width round',
											title: props.data.button,
											measureData: `${props.data.type}_link`,
										}}
									/>
								</div>
							)
						) : null}
					</div>
					<div className="text align-self-start">
						<h2>{attributes['title']}</h2>
						{ReactHtmlParser(attributes['description'])}
					</div>
				</div>
			);
		} else {
			// logger.log('[PremiumContent] type:%o', type);
			return (
				<div className={`premium-content ${attributes['style']}`}>
					<div className="image">
						{type == 'gallery' ? (
							<Carousel showStatus={false} showThumbs={false} emulateTouch useKeyboardArrows infiniteLoop>
								{attributes['images'].image.map((item, i) => (
									<div key={i}>
										<img src={item['url']} alt={item['caption']} />
									</div>
								))}
							</Carousel>
						) : (
							<EventsLink to={`${attributes['link']}`}>
								<img src={image['url']} alt={attributes['title']} />
							</EventsLink>
						)}
					</div>
					<div className="text align-self-start">
						<h2>{attributes['title']}</h2>
						{ReactHtmlParser(attributes['description'])}
						<div className="button-wrapper">
							{attributes['link'].includes('/en_US/radio') ? (
								radioButton()
							) : (
								<ButtonGeneral
									data={{
										...props.data,
										style: 'uso-button uppercase blue wider round',
										title: props.data.button,
										measureData: `${props.data.type}_link`,
									}}
								/>
							)}
						</div>
					</div>
				</div>
			);
		}
	} else if (attributes['video']) {
		let video = attributes['video'];
		// logger.log('[PremiumContent] video:%o', video);
		// let videoData = {
		//   id: video.id,
		//   title: video.title,
		//   poster: video.poster,
		//   shareUrl: video.url,
		//   streams: [
		//     {
		//       cdn: "Akamai",
		//       url: video.high,
		//       mimeType: "application/x-mpegURL",
		//       streamType: "vod"
		//     }
		//   ],
		//   tracks: [
		//     {
		//       kind: "captions",
		//       type: "text/vtt",
		//       srclang: "en",
		//       src: video.tracks ? video.tracks.file : ""
		//     }
		//   ],
		//   startSeconds: 0,
		//   autoplay: false,
		//   autoplayPolicy: "mutedinline",
		//   playsinline: true,
		//   displayDate: video.date
		// };
		// logger.log("[PremiumContent] videoData:%o", videoData);
		return (
			<div className={`premium-content ${attributes['style']}`}>
				<div className="image">
					<a
						onClick={() => {
							renderVideoItem();
						}}
						className="playVideo">
						<img
							src={video['poster']}
							alt={video['title']}
						/>
						<div className="playBtn" />
					</a>
				</div>
				<div className="text align-self-center">
					<h2>{attributes['title']}</h2>
					{ReactHtmlParser(attributes['description'])}
					<div className="button-wrapper">
						<ButtonGeneral
							data={{
								...props.data,
								style: 'premium-content-video-button',
								// style: 'uso-button uppercase blue wider round',
								title: props.data.button,
								onClick: () => { renderVideoItem() },
								measureData: `${props.data.type}_link`,
							}}
						/>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
	//   let image = attributes["images"]["image"];
	//   //logger.log('[PremiumContent] link:%o', attributes['link']);

	//   if (attributes["link"]) {
	//     return (
	//       <div className={`photo ${attributes["style"]}`}>
	//         <EventsLink to={attributes["link"]}>
	//           <img src={image["url"]} alt={image["title"]} />
	//           <div className="photo-info">{getTitleLine(image)}</div>
	//         </EventsLink>
	//       </div>
	//     );
	//   } else {
	//     return (
	//       <div className={`photo ${attributes["style"]}`}>
	//         <img src={image["url"]} alt={image["title"]} />
	//         <div className="photo-info">{getTitleLine(image)}</div>
	//       </div>
	//     );
	//   }
	// } else if (type == "multiple") {
	//   return null;
	// }
};

export default PremiumContent;
