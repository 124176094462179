import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[ScheduleWebview] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log('[ScheduleWebview] action.mount - data: %o', data);

			let data = {
				path: Config.scoringData.schedule,
				daysPath: Config.scoringData.scheduleDays,
				tournDay: params.tournDay,
				highlightsPath: Config.otherData.matchhighlights,
				liveScores: Config.scoringData.liveScore.path,
				futureSchedule: Config.otherData.futureSchedule,
			};
			// logger.log('[ScheduleWebview] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.SCHEDULEWEBVIEW_LOAD, data: data });
		});
	},
};
