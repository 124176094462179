import deps from "dependencies";
import { fetch } from 'appdir/components/general/Util';

export default {
  mount: () => (dispatch, getState, store) => {
    deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
      Config => {
        let path = Config.otherData.fanweek_schedule;
        fetch(path).then(data => {
          dispatch({
            type: deps.actionTypes.FANWEEKSCHEDULE_MOUNT,
            data
          });
        });
      }
    );
  }
};
