import deps from 'dependencies';

export default {
	mount: term => (dispatch, getState, store) => {
		logger.log('[PlayerSearch] loadSearch:%o', term);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let playersPath = Config.scoringData.players;
			let flagImagePath = Config.otherData.flagImagePathSmall;

			dispatch({
				type: deps.actionTypes.PLAYER_SEARCH,
				data: { playersPath, flagImagePath },
			});
		});
	},
	unmount: () => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.PLAYER_SEARCH_UNMOUNT,
			data: { loadResults: false },
		});
	},
};
