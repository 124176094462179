import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[GeneralContentPageWebview] action.mount - data:%o', data);

		// Get articles
		if (data.hasOwnProperty('id') || data.landing) {
			// logger.log("[GeneralContentPageWebview] action.mount - ensureConfig");
			return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
				let path = '';
				let prevNextPath = '';
				// logger.log("[GeneralContentPageWebview] action.mount - .landing string:%o", data.landing);
				if (data.category) {
					data.landing = '';
					logger.log('[GeneralContentPageWebview] action.mount - category - data: %o', data);
					switch (data.category) {
						case 'atoz':
							path = Config.cmsData.atoz.replace('<contentId>', data.id);
							break;
						case 'about':
						case 'content':
							path = Config.cmsData.content.replace('<contentId>', data.id);
							break;
						case 'visit':
						case 'event_guide':
							path = Config.cmsData.visit.replace('<contentId>', data.id);
							break;
						case 'tickets':
							path = Config.cmsData.tickets.replace('<contentId>', data.id);
							break;
						case 'players_only':
							path = Config.cmsData.players_only.replace('<contentId>', data.id);
							break;
						case 'us-open-at-home':
							path = Config.cmsData.us_open_at_home.replace('<contentId>', data.id);
							break;
						case 'tv_intl':
							path = Config.cmsData.tv_intl.replace('<contentId>', data.category);
							logger.log('[GeneralContentPageWebview] tv_intl - path: %o', path);
							break;
						case 'fanaccesspass':
							path = Config.cmsData.content.replace('<contentId>', 'fanaccesspass' + data.id);
							break;
						default:
							break;
					}
				} else if (data.landing) {
					data.category = '';
					//logger.log("[GeneralContentPageWebview] action.mount - landing - data: %o", data);
					switch (data.landing) {
						case 'terms':
						case 'privacy':
						case 'contact_us':
						case 'fanaccesspass':
							path = Config.cmsData.content.replace('<contentId>', data.landing);
							break;
						case 'tickets':
							path = Config.cmsData.tickets.replace('<contentId>', data.landing);
							break;
						case 'us-open-at-home':
							path = Config.cmsData.us_open_at_home.replace('<contentId>', 'us_open_at_home');
							logger.log('[GeneralContent]' + path);
							break;
						case 'sponsors':
							path = Config.cmsData.sponsors.replace('<contentId>', data.id);
							break;
						case 'players_only':
							path = Config.cmsData.players_only.replace('<contentId>', data.landing);
							break;
						case 'visit':
							path = Config.cmsData.visit.replace('<contentId>', data.landing);
							break;
						case 'about':
						case 'landing':
							path = Config.cmsData.landing.replace('<section>', data.id);
							break;
						case 'tv_intl':
							path = Config.cmsData.tv_intl.replace('<contentId>', data.landing);
							logger.log('[GeneralContentPageWebview] tv_intl - path: %o', path);
							break;
						default:
							break;
					}
				}

				if (data.prefix === 'preview') {
					path = '/preview' + path;
				}

				data.dataUrl = path;
				data.prevNextUrl = prevNextPath;
				data.cmsData = Config.cmsData;
				data.sponsors = Config.sponsors;
				data.relatedContent = Config.relatedContent;
				data.basePicPath = Config.otherData.basePicPath;

				// logger.log("[GeneralContentPageWebview] action.mount - data: %o", data);
				dispatch({
					type: deps.actionTypes.GENERALCONTENT_WEBVIEW_LOAD,
					data: data,
				});
			});
		}
	},
	unmount: () => (dispatch, getState, store) => {
		let data = {
			category: '',
			landing: '',
			dataUrl: '',
		};
		dispatch({
			type: deps.actionTypes.GENERALCONTENT_WEBVIEW_UNMOUNT,
			data: data,
		});
	},
};
