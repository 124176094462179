import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[EventScheduleWebview] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log('[EventScheduleWebview] action.mount - data: %o', data);

			let data = {
				status: 'mount',
				basePicPath: Config.otherData.basePicPath,
				schedulePath: Config.scoringData.practice,
			};
			// logger.log('[EventScheduleWebview] action.mount - data: %o', data);

			dispatch({
				type: deps.actionTypes.EVENTSCHEDULEWEBVIEW_LOAD,
				data: data,
			});
		});
	},
};
