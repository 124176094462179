/**
 DrawsPage Initial State

 NOTES:  view: round or bracket
*/

export default {
	filters: {
		event: '',
		open: '',
	},
	eventRound: {
		selectedIndex: '',
		selectedRound: 0,
		clicked: false,
		pageReturn: false,
	},
	mountStatus: null,
	status: null,
	view: 'round',
};
