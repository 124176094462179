import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => {
	return {
		...state['Results'],
		PlayerSearch: state['PlayerSearch'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class Results extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
		};
	}

	render() {
		logger.log('[Results] :', this.props.data.playerSearchResults);
		let flagImage;
		let flagNat;

		return (
			<ul className="results">
				{this.props.data.playerSearchResults.length > 0 ? (
					this.props.data.playerSearchResults.map((item, index) => {
						flagNat = item.country.trim();
						flagImage = this.props.PlayerSearch.flagImagePath.replace('<code>', flagNat);

						if (flagNat !== '') {
							//if no country do not display - amb
							return (
								<li key={`${index}-${item.id}`}>
									<Link to={`/en_US/players/overview/${item.id}.html`}>
										<span className="flags">
											<img src={flagImage} />
										</span>
										{item.last_name}, {item.first_name}
									</Link>
								</li>
							);
						}
					})
				) : (
					<div>No Results</div>
				)}
			</ul>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Results);
