import React from 'react';
import op from 'object-path';
import EventsLink from 'appdir/components/general/EventsLink';

const PlayerImage = props => {
	let attributes = props.attributes;

	if (op.get(props, 'attributes.link', false) === true && op.get(props, 'attributes.pid', false)) {
		let attColClass = attributes.colClass ? ' ' + attributes.colClass : '';

		return (
			<EventsLink
				to={`/en_US/players/overview/${props.attributes.pid}.html`}
				style={`player-img-container ` + `${attColClass}`}>
				<img
					className={'player-image ' + (attributes['style'] ? attributes['style'] : '')}
					src={attributes['player-image']}
				/>
			</EventsLink>
		);
	} else {
		return (
			<div className={'player-img-container' + (attributes.colClass ? ' ' + attributes.colClass : '')}>
				<img
					className={'player-image ' + (attributes['style'] ? attributes['style'] : '')}
					src={attributes['player-image']}
				/>
			</div>
		);
	}
};

export default PlayerImage;
