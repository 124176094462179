import React from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";

const VideoShareBar = props => {
  logger.log("[VideoShareBar] - props:%o", props);
  let attributes = props.data;
  return (
    <div className="socialWrapper">
      <div className="socialContainerNew">
        <div className="mobileView">
          <div className="title">Share This Video</div>
          <div className="social">
            <div className="facebook">
              <FacebookShareButton
                url={attributes.url}
                title={attributes.title}
              >
                <img
                  src="https://www.usopen.org/images/nav/social_icons/facebook_gray.png"
                  alt="facebook"
                  border="0"
                />
              </FacebookShareButton>
            </div>
            <div className="twitter">
              <TwitterShareButton url={attributes.url} title={attributes.title}>
                <img
                  src="https://www.usopen.org/images/nav/social_icons/twitter_gray.png"
                  alt="twitter"
                  border="0"
                />
              </TwitterShareButton>
            </div>
          </div>
        </div>
        <div className="link">
          <input
            className="link_url"
            type="text"
            readOnly="readonly"
            value={attributes.url}
            aria-label="Share Link"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoShareBar;
