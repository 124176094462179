/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
// import { NavLink } from "react-router-dom";
import ReactDOM from 'react-dom';
// import { openExternal, measureApp } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';
import isEmpty from 'lodash/isEmpty';

class ShareMenu extends Component {
	constructor(props) {
		super(props);

		// this.state = Object.assign({}, this.props);
		this.state = { ...this.props, showModalFlag: false };
	}

	componentDidMount() {
		this.modalTarget = document.createElement('div');
		document.body.appendChild(this.modalTarget);
		this._render();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[ShareMenu] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[ShareMenu] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(this.modalTarget);
		document.body.removeChild(this.modalTarget);
	}

	componentDidUpdate() {
		this._render();
	}

	_render() {
		ReactDOM.render(this.renderModalContent(), this.modalTarget);
	}

	showModal(bool) {
		this.setState(prevState => {
			return { ...prevState, showModalFlag: bool };
		});
	}

	renderModalContent() {
		let modalContent = this.state['showModalFlag'];
		let image = this.state['image'];
		return this.state.showModalFlag ? (
			<span className="modal-content-overlay" style={{ display: this.state.showModalFlag ? 'block' : 'none' }}>
				<div className={`modal-content wechat ${this.state.showModalFlag ? 'show' : ''}`}>
					<img src="/assets/images/misc/WeChatQR.jpg" alt="WeChat" />
					<div className="scrollable">
						<div className="modal-content-text">
							<h2>WeChat</h2>
							<div className="description">
								<p>Follow US Open on WeChat by scanning the QR code.</p>
							</div>
						</div>
					</div>
					<i
						onClick={() => {
							this.showModal(false);
						}}
						className="icon-close"
					/>
				</div>
			</span>
		) : null;
	}

	openShare(which, url) {
		logger.log('[ShareMenu] openShare - which:%o share:%o', which, url);
		if (url) {
			url =
				url.indexOf('http') !== -1 || url.indexOf('www.usopen.org') !== -1
					? url
					: `${window.location.protocol} //${window.location.hostname}${url}`;
		}
		MeasurementUtils.dispatchMeasurementCall('SocialShare', { network: which });
		switch (which) {
			// case 'googleplus':
			//     if (url) {
			//         window.open('http://plus.google.com/share?url=' + url, '_blank')
			//     } else {
			//         window.open('http://plus.google.com/+usopen', '_blank')
			//     }
			//     break;

			case 'facebook':
				if (url) {
					// measureApp('Article', 'Share', 'Title', 'Facebook');
					window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '_blank');
				} else {
					openExternal({ url: 'https://www.facebook.com/usopentennis' });
					window.open('https://www.facebook.com/usopentennis', '_blank');
				}
				break;
			case 'twitter':
				if (url) {
					// measureApp('Article', 'Share', 'Title', 'Twitter');
					window.open('https://twitter.com/share?url=' + url + '&via=usopen&text=Detail', '_blank');
				} else {
					// openExternal({url: 'http://twitter.com/#!/usopen'});
					window.open('http://twitter.com/#!/usopen', '_blank');
				}
				break;
			case 'instagram':
				if (url) {
					//window.open('' + url, '_blank')
				} else {
					// openExternal({url: 'https://instagram.com/usopen/'})
					window.open('https://instagram.com/usopen/', '_blank');
				}
				break;
			case 'youtube':
				if (url) {
					//window.open('' + url, '_blank')
				} else {
					// openExternal({url: 'http://www.youtube.com/usopen'});
					window.open('http://www.youtube.com/usopen', '_blank');
				}
				break;
			case 'wechat':
				// measureApp('Article', 'Share', 'Title', 'WeChat');
				this.showModal(!this.state.showModalFlag);
				//window.open('', '_blank');
				break;
			case 'weibo':
				if (url) {
					// measureApp('Article', 'Share', 'Title', 'Weibo');
					window.open('http://service.weibo.com/share/share.php?url=' + url, '_blank');
				} else {
					// openExternal({url: 'http://weibo.com/usopen'});
					window.open('http://weibo.com/usopentennis', '_blank');
				}
				break;
		}

		//https:// plus.google.com / share ? url = http % 3A % 2F % 2Fwww.usopen.org % 2Fen_GB % 2Fnews % 2Farticles % 2F2018 - 03 - 15 % 2F2018 - 03 -15_serena_begins_the_comeback_trail.html
		//https://twitter.com/share?url=http%3A%2F%2Fwww.usopen.org%2Fen_GB%2Fnews%2Farticles%2F2018-03-15%2F2018-03-15_serena_begins_the_comeback_trail.html&via=usopen&text=Detail

		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	getGalleryBar(color) {
		return (
			<div className={`gallery share-bar ${color}`}>
				<div className="share-bar-icons">
					<i
						className="icon-twitter"
						title="Twitter"
						onClick={() => {
							this.openShare('twitter', this.state.url);
						}}
					/>
					<i
						className="icon-facebook"
						title="Facebook"
						onClick={() => {
							this.openShare('facebook', this.state.url);
						}}
					/>
					<i
						className="icon-weibo"
						title="Weibo"
						onClick={() => {
							this.openShare('weibo', this.state.url);
						}}
					/>
				</div>
				<div className="share-bar-icons">
					{/* <i className="icon-googleplus" title="Google+" onClick={() => {this.openShare('googleplus', this.state.url)}}></i> */}
					{/* <i className="icon-weibo" title="Weibo" onClick={() => { this.openShare('weibo', this.state.url) }}></i> */}
				</div>
			</div>
		);
	}

	getHorizBar(color) {
		return (
			<div className={`share-bar ${color}`}>
				<div className="share-bar-icons">
					<i
						className="icon-facebook"
						title="Facebook"
						onClick={() => {
							this.openShare('facebook');
						}}
					/>
					<i
						className="icon-twitter"
						title="Twitter"
						onClick={() => {
							this.openShare('twitter');
						}}
					/>
					<i
						className="icon-instagram"
						title="Instagram"
						onClick={() => {
							this.openShare('instagram');
						}}
					/>
					<i
						className="icon-youtube"
						title="YouTube"
						onClick={() => {
							this.openShare('youtube');
						}}
					/>
					<i
						className="icon-wechat"
						title="WeChat"
						onClick={() => {
							this.openShare('wechat');
						}}
					/>
					<i
						className="icon-weibo"
						title="Weibo"
						onClick={() => {
							this.openShare('weibo');
						}}
					/>
					{/* <i className="icon-googleplus" title="Google+" onClick={() => {this.openShare('googleplus')}}></i> */}
				</div>
			</div>
			// <div className="share-bar">
			//     <div className="share-bar-icons">
			//         <NavLink to="http://www.facebook.com/usopentennis" target="_blank"><i className="icon-facebook"></i></NavLink>
			//         <NavLink to="http://twitter.com/#!/usopen" target="_blank"><i className="icon-twitter"></i></NavLink>
			//         <NavLink to="https://instagram.com/usopen/" target="_blank"><i className="icon-instagram"></i></NavLink>
			//         <NavLink to="http://plus.google.com/+usopen" target="_blank"><i className="icon-youtube"></i></NavLink>
			//         <NavLink to="http://www.youtube.com/usopen" target="_blank"><i className="icon-googleplus"></i></NavLink>
			//         <NavLink to="" target="_blank"><i className="icon-wechat"></i></NavLink>
			//         <NavLink to="" target="_blank"><i className="icon-weibo"></i></NavLink>
			//     </div>
			// </div>
		);
	}

	/*
	 * if has specific url for sharing, only display social networks
	 *   which used for sharing specific page
	 *
	 * <i className="icon-wechat" title="WeChat" onClick={() => { this.openShare('wechat', url) }}></i>
	 *
	 */
	getPopup(view, arrow, url) {
		if (url) {
			return (
				<div className={`share-popup ${view}`}>
					<div className={`share-box-arrow ${arrow}`} />
					<div className="share-icons">
						<i
							className="icon-facebook"
							title="Facebook"
							onClick={() => {
								this.openShare('facebook', url);
							}}
						/>
						<i
							className="icon-twitter"
							title="Twitter"
							onClick={() => {
								this.openShare('twitter', url);
							}}
						/>
						<i
							className="icon-weibo"
							title="Weibo"
							onClick={() => {
								this.openShare('weibo', url);
							}}
						/>
						{/* <i className="icon-googleplus" title="Google+" onClick={() => { this.openShare('googleplus', url) }}></i> */}
					</div>
				</div>
			);
		} else {
			return (
				<div className={`share-popup ${view}`}>
					<div className={`share-box-arrow ${arrow}`} />
					<div className="share-icons">
						<i
							className="icon-facebook"
							title="Facebook"
							onClick={() => {
								this.openShare('facebook');
							}}
						/>
						<i
							className="icon-twitter"
							title="Twitter"
							onClick={() => {
								this.openShare('twitter');
							}}
						/>
						<i
							className="icon-instagram"
							title="Instagram"
							onClick={() => {
								this.openShare('instagram');
							}}
						/>
						<i
							className="icon-youtube"
							title="YouTube"
							onClick={() => {
								this.openShare('youtube');
							}}
						/>
						<i
							className="icon-wechat"
							title="WeChat"
							onClick={() => {
								this.openShare('wechat');
							}}
						/>
						<i
							className="icon-weibo"
							title="Weibo"
							onClick={() => {
								this.openShare('weibo');
							}}
						/>
						{/* <i className="icon-googleplus" title="Google+" onClick={() => { this.openShare('googleplus') }}></i> */}
					</div>
				</div>
			);
		}
	}

	render() {
		if (this.state.type == 'gallery') {
			return this.getGalleryBar(this.state.color);
		}
		if (this.state.type == 'horiz') {
			return this.getHorizBar(this.state.color);
		} else if (this.state.type == 'popup') {
			return this.getPopup(this.state.view, this.state.orient, this.state.share);
		} else if (this.state.type == 'webview') {
			return null;
		}
	}
}

/*
 *  default props
 */
ShareMenu.defaultProps = {};

export default ShareMenu;
