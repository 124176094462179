import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import deps from 'dependencies';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';

const mapStateToProps = (state, props) => {
	return {
		...props,
		...state['Interviews'],
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.Interviews.filter(settings)),
});

class InterviewsFilter extends Component {
	static getMenuData() {
		let menuData = {
			options: [
				{ name: 'All', value: 'all', query: 'all', selected: true },
				{ name: 'News', value: 'news', query: 'news' },
				{ name: 'Photos', value: 'gallery', query: 'gallery' },
				{ name: 'Videos', value: 'video', query: 'video' },
				{
					name: 'Press Releases',
					value: 'press release',
					query: 'press release',
				},
				{ name: 'Interviews', value: 'interview', query: 'interview' },
				{ name: 'Your Players', value: 'favorite', query: 'favorite' },
			],
		};
		return menuData;
	}

	constructor(props) {
		super(props);

		this.setFilter = this.setFilter.bind(this);
		this.onOpen = this.onOpen.bind(this);

		this.useFavPlayers = true;
		//logger.log('[InterviewsFilter] props:%o', props);
	}

	setFilter(filter) {
		logger.log('[InterviewsFilter] setFilter:%o', filter);

		let nav = filter != 'all' ? this.props.basePath + '?filter=' + filter : this.props.basePath;
		this.setState({
			nav: nav,
		});
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	redirect() {
		if (this.state.nav) {
			return <EventsRedirect to={this.state.nav} />;
		} else {
			return null;
		}
	}

	render() {
		logger.log('[InterviewsFilter] render - this:%o', this);

		if (!this.props.filters) {
			logger.warn('[InterviewsFilter] no filters specified');
			return null;
		}

		//logger.log('[InterviewsFilter] render - filter:%o', this.state.filters.type);
		if (this.props.basePath) {
			return (
				<div className="news-landing-filters">
					<div className="filter-buttons">
						<NavLink
							to={this.props.basePath}
							className={this.props.filters.type === 'all' ? 'selected' : ''}>
							<i className="icon-all" />
							<div className="filter-text">ALL</div>
						</NavLink>
						<NavLink
							to={`${this.props.basePath}?filter=news`}
							className={this.props.filters.type === 'news' ? 'selected' : ''}>
							<i className="icon-news" />
							<div className="filter-text">NEWS</div>
						</NavLink>
						<NavLink
							to={`${this.props.basePath}?filter=gallery`}
							className={this.props.filters.type === 'gallery' ? 'selected' : ''}>
							<i className="icon-gallery" />
							<div className="filter-text">PHOTOS</div>
						</NavLink>
						<NavLink
							to={`${this.props.basePath}?filter=video`}
							className={this.props.filters.type === 'video' ? 'selected' : ''}>
							<i className="icon-video" />
							<div className="filter-text">VIDEOS</div>
						</NavLink>
						<NavLink
							to={`${this.props.basePath}?filter=press release`}
							className={this.props.filters.type === 'press release' ? 'selected' : ''}>
							<i className="icon-press" />
							<div className="filter-text">PRESS RELEASES</div>
						</NavLink>
						<NavLink
							to={`${this.props.basePath}?filter=interview`}
							className={this.props.filters.type === 'interview' ? 'selected' : ''}>
							<i className="icon-person" />
							<div className="filter-text">INTERVIEWS</div>
						</NavLink>

						{this.useFavPlayers ? (
							<NavLink
								to={`${this.props.basePath}?filter=favorite`}
								className={this.stapropste.filters.type === 'favorite' ? 'selected' : ''}>
								<i className="icon-favStar" />
								<div className="filter-text">YOUR PLAYERS</div>
							</NavLink>
						) : null}
					</div>
					<div className="filter-dropdown">
						<SelectMenu
							name="filter"
							attributes={InterviewsFilter.getMenuData()}
							selected={this.props.filters.type}
							show={this.props.filters.open == 'filter'}
							onSelected={this.setFilter}
							onOpen={this.onOpen}
						/>
					</div>
					{this.redirect()}
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InterviewsFilter);
