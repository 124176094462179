import React, { Component } from "react";

class GenericError extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    logger.error(
      "[GenericError/ErrorBoundary] - message:%o",
      this.props.message
    );
    return <div className="error-indicator">{this.props.message}</div>;
  }
}
export default GenericError;
