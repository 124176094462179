import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['CountryPlayersSelect'],
		countryPlayers: state['PlayerFilters'].countryPlayers,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	filterCountry: country => dispatch(deps.actions.PlayerFilters.filterCountry(country)),
});

class CountryPlayersSelect extends Component {
	constructor(props) {
		super(props);
	}

	setCountry = stat => {
		logger.log('[CountryPlayersSelect] setCountry - stat:%o', stat.target.value);
		this.props.filterCountry(stat.target.value);
		MeasurementUtils.dispatchMeasurementCall('playersPageSelectCountry', {
			country: stat.target.value,
		});
	};

	onOpen = which => {};

	render() {
		let selectData = {
			options: [{ name: 'Select a Country', value: '' }],
		};
		selectData.options = selectData.options.concat(this.props.selectData);
		return (
			<div className="results-select-menu">
				<select
					onChange={this.setCountry}
					className="by-country-players-select-menu"
					value={this.props.countryPlayers}>
					{selectData.options.map(option => (
						<option key={option.name} value={option.value}>
							{option.name}
						</option>
					))}
				</select>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryPlayersSelect);
