/**
 ScoreManager Initial State
*/

export default {
	// liveMatches: [],
	statMatch: {
		loaded: false,
		pointHistory: [],
		stats: {},
	},
};
