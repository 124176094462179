import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		// logger.log('[LiveAtUSOpen] actions.mount - ');

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let blogPath = '';
			//preview mode
			if (params.prefix === 'preview') {
				blogPath = Config.otherData.previewBlog;
			} else {
				blogPath = Config.otherData.liveBlog;
			}

			let day = 'current';

			let data = {
				status: 'load',
				schedulePath: Config.cmsData.schedule,
				adConfig: Config.adConfig,
				liveBlogPath: blogPath,
				daysPath: Config.scoringData.scheduleDays,
				tournDay: day,
			};

			// logger.log('[LiveAtUSOpen] action.mount - alldata: %o', data);

			dispatch({ type: deps.actionTypes.LIVEATUSOPEN_LOAD, data: data });
		});
	},
	unmount: () => dispatch => {
		let data = {
			status: null,
		};
		dispatch({
			type: deps.actionTypes.LIVEATUSOPEN_UNMOUNT,
			data: data,
		});
	},
	setDay: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEATUSOPEN_SETDAY, data: data });
	},
	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEATUSOPEN_FILTER, data: data });
	},
};
