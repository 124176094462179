import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import classNames from 'classnames';
import EventsLink from 'appdir/components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';
import MeasurementUtils from 'appdir/lib/analytics';
import { ArrowKeyNavigation } from 'boundless';
import filter from 'lodash/filter';
import get from 'lodash/get';

const mapStateToProps = (state, props) => {
	return {
		...state['MenuItems'],
		windowSize: state['Controller'].windowSize,
		Header: state['Header'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	hoverMobile: (isHover, menuName) => dispatch(deps.actions.MainNav.hoverMobile(isHover, menuName)),
	toggleMenu: isOpen => dispatch(deps.actions.Header.toggleMenu(isOpen)),
});

class MenuItems extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: '',
			cursor: 0,
		};

		this.openClass = [];
		this.showMobileNav = this.showMobileNav.bind(this);
		this.navClicked = this.navClicked.bind(this);
		this.onHover = this.onHover.bind(this);
		this.navKey = this.navKey.bind(this);
		// logger.log('[MenuItems] this:%o', this);
	}

	navClicked(e, item, promoType) {
		//logger.log('[MenuItems] navClicked - %o', e);
		e.stopPropagation();
		//add metrics call for removed promotype
		MeasurementUtils.dispatchMeasurementCall('Navigation', {
			promo: promoType !== undefined ? promoType : '',
			menuItem: item !== undefined ? item.title : '',
		});

		this.props.toggleMenu(false);
		if (e) {
			this.props.hoverMobile(false, e.target.id);
		}
		this.props?.location.indexOf('en_US/video/index.html') > -1
			? window.dispatchEvent(new Event('ChangeWatchRouterDispatch'))
			: null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.menu_open != prevProps.menu_open) {
			this.setState({ hoverItem: '' });
		}
	}

	onHover(subMenuName, moreClass, hoverItem, e) {
		// logger.log('[MenuItems] onHover submenu:%o, moreClass:%o', subMenuName, moreClass);
		if (e.key == ' ' || e.code == 'Space' || e.type == 'click') {
			if (moreClass !== '') {
				this.props.hoverMobile(true, subMenuName);
				MeasurementUtils.dispatchMeasurementCall('Menu', {
					subSection: subMenuName.subMenuName,
				});
			} else {
				this.props.hoverMobile(false, subMenuName);
			}

			this.setState({ hoverItem });
		}
	}

	navKey(menuHover, e) {
		// logger.log('[MenuItems] navKey key:%o, subMenuName:%o', e.key, menuHover);
		if (e.key == 'ArrowDown') {
			// || e.key == ' ' || e.code == 'Space') {
			this.setState({ menuHover });
		} else if (e.key == 'ArrowUp') {
			this.setState({ menuHover: '' });
		}
	}

	onUnHover(subMenuName, e) {
		//logger.log('[MenuItems] onHover submenu:%o', subMenuName);
		//this.props.hoverMobile(false,'');
		//if (moreClass !== '') {
		//this.props.hoverMobile(false, subMenuName);
		//}
	}

	showMobileNav(evt) {
		//logger.log('[MenuItems] showMobileNav - state:%o, evt: %o', this.state, evt);
		let clicked = evt.target.id;
		//logger.log('[MenuItems] showMobileNav - state:%o, evt: %o, clicked:%o', this.state, evt,clicked);
		if (this.state.expanded) {
			this.setState({ expanded: '' });
		} else {
			this.setState({ expanded: clicked });
		}

		MeasurementUtils.dispatchMeasurementCall('mobileNavDisplay', {
			display: this.state.expanded,
		});

		if (clicked == '') {
			this.navClicked(false);
		}
	}

	getParamChar(string) {
		if (string.indexOf('?') > -1) {
			return '&';
		} else {
			return '?';
		}
	}

	getLinkUrl(url, type) {
		//remove promo added to the menu link - 07312023 amb
		// return url + this.getParamChar(url) + 'promo=' + type;
		return url === '' ? '/index.html' : url;
	}

	renderSubNav(mainNavItem, subNav) {
		//subnav for desktop
		let subNavItems = subNav ? subNav : [];
		let subNavClasses = classNames('submenu', 'more', mainNavItem);

		if (subNav) {
			return (
				<ul
					className={subNavClasses}
					onClick={e => {
						this.navClicked(e);
					}}>
					{subNavItems.map((item, i) => {
						let linkUrl = this.getLinkUrl(item.link.url, 'subnav');
						let selectedClass = '';
						if (window.location.href.includes(linkUrl)) {
							selectedClass = 'selected';
						}
						return (
							<li
								className={`submenu-item ${selectedClass}`}
								key={item + i}
								onClick={e => {
									this.navClicked(e, item, 'subnav');
								}}>
								<EventsLink to={linkUrl} type={item.link.type} active={this.state.cursor == i}>
									{ReactHtmlParser(item.title)}
								</EventsLink>
							</li>
						);
					})}
				</ul>
			);
		}
	}

	render() {
		// logger.log('[MenuItems] render - this:%o', this);
		let mainNavItems = this.props.data ? this.props.data : [];

		//render main menu for desktop
		if (this.props.attributes === 'main') {
			return (
				<ul className="nav-list">
					<ArrowKeyNavigation mode={ArrowKeyNavigation.mode.HORIZONTAL}>
						{mainNavItems.map((item, i) => {
							if (('show' in item && item.show == 'true') || !('show' in item)) {
								let subMenuName = item.title.replace(/\s/g, '');
								let moreClass = item.sub_menu ? 'more' : '';
								let overClass = this.state.menuHover == subMenuName ? 'hover' : '';
								let selectedClass = '';
								let submenuContainsLoc = get(
									filter(item.sub_menu, o => {
										return window.location.href.includes(o.link.url);
									}),
									'length',
									0
								);
								// logger.log('[MenuItems] submenuContainsLoc:%o', submenuContainsLoc);
								if (window.location.href.includes(linkUrl) || submenuContainsLoc > 0) {
									logger.log(
										'[MenuItems] submenuContainsLoc:%o, item',
										submenuContainsLoc,
										item.title
									);
									selectedClass = 'selected';
								}
								// logger.log('[MenuItems] selectedClass:%o', selectedClass);

								let mainClasses = classNames(
									'menu-item',
									item.title,
									moreClass,
									overClass,
									selectedClass
								);

								let linkUrl = this.getLinkUrl(item.link.url, 'topnav');

								if (item.link.type === '') {
									return (
										<li
											className={mainClasses}
											name={item.title}
											key={item + i}
											onClick={e => {
												this.navClicked(e, item, 'topnav');
											}}
											onKeyDown={e => {
												this.navKey(subMenuName, e);
											}}>
											<a href={linkUrl} target={item.link.target}>
												{item.title}
											</a>
											{this.renderSubNav(item.title, item.sub_menu)}
										</li>
									);
								} else {
									return (
										<li
											className={mainClasses}
											name={item.title}
											key={item + i}
											onClick={e => {
												this.navClicked(e, item, 'topnav');
											}}
											onKeyDown={e => {
												this.navKey(subMenuName, e);
											}}>
											<EventsLink to={linkUrl}>{item.title}</EventsLink>
											{this.renderSubNav(item.title, item.sub_menu)}
										</li>
									);
								}
							}
						})}
					</ArrowKeyNavigation>
				</ul>
			);
		} else if (this.props.attributes === 'mobile') {
			//render main menu for mobile views
			//let prevHover = this.state.
			return (
				<ul className="menu">
					{mainNavItems.map((item, i) => {
						if (('show' in item && item.show == 'true') || !('show' in item)) {
							let moreClass = item.sub_menu ? 'more' : '';
							let subMenuName = item.title.replace(/\s/g, '');
							let openClass = this.state.hoverItem === subMenuName ? 'open' : '';
							let selectedClass = '';
							let submenuContainsLoc = get(
								filter(item.sub_menu, o => {
									return window.location.href.includes(o.link.url);
								}),
								'length',
								0
							);
							// logger.log('[MenuItems] submenuContainsLoc:%o', submenuContainsLoc);
							if (window.location.href.includes(linkUrl) || submenuContainsLoc > 0) {
								selectedClass = 'selected';
							}
							// logger.log('[MenuItems] selectedClass:%o', selectedClass);
							let menuClasses = classNames('menu-item', moreClass, subMenuName, openClass, selectedClass);
							let linkUrl = this.getLinkUrl(item.link.url, 'topnav');

							return (
								<li
									id={subMenuName}
									className={menuClasses}
									key={item + i}
									onClick={e => this.onHover({ subMenuName }, moreClass, subMenuName, e)}
									onKeyPress={e => this.onHover({ subMenuName }, moreClass, subMenuName, e)}
									tabIndex={0}>
									<i className={`icon-${subMenuName.toLowerCase()}`} />

									{item.sub_menu ? (
										<span>{item.title}</span>
									) : (
										<>
											{item.link.type === '' ? (
												<a href={linkUrl} target={item.link.target}>
													{item.title}
												</a>
											) : (
												<EventsLink to={linkUrl}>{item.title}</EventsLink>
											)}
										</>
									)}

									{this.renderSubNav(subMenuName, item.sub_menu)}
								</li>
							);
						}
					})}
				</ul>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItems);
