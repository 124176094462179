/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import GalleryView from './GalleryView';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Template from 'components/Template';
import NewsTile from 'appdir/components/content/NewsTile';
import AdTag from 'appdir/components/general/AdTag';
import MeasurementUtils from 'appdir/lib/analytics';
import { getPlatformImageSize } from 'appdir/components/general/Util';
import { fetch } from 'appdir/components/general/Util';
import deps from 'dependencies';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: Photos
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Photos'],
		windowSize: state['Controller'].windowSize,
		landingPages: state['Config'].landingPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Photos.mount()),
});

class PhotosPage extends Component {
	constructor(props) {
		super(props);

		logger.log('[Photos] constructor - props:%o ', props);

		this.state = {
			// ...props,
			loaded: false,
			hero: null,
			showAll: true,
			years: [],
			defaultDay: null,
			defaultDayId: null,
			defaultMonth: null,
			defaultYear: null,
			selectedYear: '',
			selectedMonth: '',
			selectedDay: '',
			selectedDayId: '',
			newsIndex: null,
			tournIndex: null,
			tournIdIndex: null,
			filter: null,
			urlParams: null,
			relatedContent: null,
			yearsAvailable: [],
			tournDatesAvailable: [],
			showingCells: 50,
			photoArr: [],
			photoPlayers: null,
			playerSearch: null,
			listView: false,
			checkedPlayers: [],
			showFilters: false,
			galleryOpen: false,
		};

		this.pageloaded = false;
		this.loadedJson = ''; //keep track of last loaded json url
		this.filterType = '';
		this.skip = 0;
		this.prevLastItem;
		// this.gridSwitch = this.gridSwitch.bind(this);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Photos',
		});
	}

	componentDidMount() {
		logger.log('[Photos] componentDidMount - this:%o', this);
		// if (this.props.location.search != "") {
		//   this.getFilter(this.props.location.search);
		// }
		let photo = this.props.match.params.photo;
		if (photo && photo != 'index') {
			this.setState({ galleryOpen: true });
		}
		if (!photo || photo == 'index') {
			window.addEventListener('click', this.handleBodyClick);
		}
	}

	componentWillUnmount() {
		// this.props.filter({
		//   type: "",
		//   open: ""
		// });
		window.removeEventListener('click', this.handleBodyClick);
	}

	handleBodyClick = () => {
		this.setState({ showFilters: false });
	};

	preventBubble(e) {
		e.stopPropagation();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[Photos] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let url = ''; // redirect url
		let data = prevState.data; // json data
		// logger.log(
		// 	'[Photos] getDerivedStateFromProps - next props status:%o this state:%o next props:%o',
		// 	nextProps.status,
		// 	prevState,
		// 	nextProps
		// );

		let newState = {
			...prevState,
			nav: url,
			data: data,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[Photos] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[Photos] componentDidUpdate - this: %o', this);
		const showAll = this.state.showAll;
		const year = this.state.selectedYear;
		const month = this.state.selectedMonth;
		const day = this.state.selectedDay;
		const filter = JSON.stringify(this.state.urlParams);
		const newsIndex = this.state.newsIndex;
		const tournIndex = this.state.tournIndex;

		if (!isEqual(prevProps, this.props)) {
			this.getFilter(this.props.location.search);
		}

		if (showAll) {
			if (newsIndex == null) {
				this.getNewsIndex();
			} else if (
				prevState.selectedMonth != month ||
				prevState.selectedYear != year ||
				prevState.showAll != showAll
			) {
				this.getRelatedContent(true);
			}
		}

		if (
			isEmpty(prevState.relatedContent) &&
			!isEmpty(this.state.relatedContent) &&
			this.props.match.params.photo &&
			this.props.match.params.photo != 'index'
		) {
			MeasurementUtils.dispatchMeasurementCall('Photos Direct to Gallery', {
				caption: this.state.relatedContent[0].title,
			});
		}

		if (!isEqual(this.state.relatedContent, prevState.relatedContent)) {
			logger.log('[Photos] componentDidUpdate - loaded more');
			let anchorItem = document.getElementById(`${this.prevLastItem}`);
			if (anchorItem) {
				anchorItem.scrollIntoView({ block: 'center' });
			}
		}

		//scroll the item back into view after the gallery closes
		if (prevState.galleryOpen != this.state.galleryOpen && this.state.galleryOpen == false) {
			let scrollToItem = document.getElementById(`photoTile-${this.state.selectedItem}`);
			if (scrollToItem) {
				scrollToItem.scrollIntoView({ block: 'center' });
			}
		}

		// logger.log(
		// 	'[Photos] componentDidUpdate checkedPlayers:%o, prevPlayers:%o',
		// 	this.state.checkedPlayers,
		// 	prevState.checkedPlayers
		// );
		if (!isEqual(this.state.checkedPlayers, prevState.checkedPlayers)) {
			this.getRelatedContent(true);
		}
	}

	getFilter(search) {
		logger.log('[Photos] getFilter - state: %o', this.state);
		search = search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);
		//parsedQs = {year: "2018", month: "11", day: "", mode: "all", filter: ""}
		if (JSON.stringify(this.state.urlParams) != JSON.stringify(parsedQs)) {
			this.setState({
				urlParams: parsedQs,
			});
		}
	}

	parseFilter() {
		// parsedQs = {year: "2018", month: "11", day: "", mode: "all", filter: ""}
		const filter = this.state.urlParams;
		if (filter != null && filter.mode != null) {
			let showAll = this.state.showAll;
			let selectedYear = '';

			// logger.log('[Photos] Parse Filter: %o', filter);

			if (filter.year != undefined) {
				selectedYear = filter.year;
			}
			showAll = true;

			this.setState({
				relatedContent: null,
				showAll: showAll,
				defaultYear: selectedYear,
			});
		}
	}

	getNewsIndex() {
		// logger.log('[Photos] getNewsIndex - this:%o', this);

		if (this.props.path != null) {
			let apiDataPath = this.props.path.replace('<section>', () => {
				return 'photos_index';
			});

			fetch(apiDataPath).then(result => {
				let dates = result.content.dates;
				let newsIndexDates = {};
				let firstObject = null;
				let years = {};
				let defaultYear = this.state.urlParams.year;
				let defaultMonth = this.state.urlParams.month;

				firstObject = dates[0];

				dates.forEach(function(element) {
					newsIndexDates[element.key] = {
						file: element.file,
					};
					years[element.year] = '';
				});

				if (
					(defaultYear == null && defaultMonth == null) ||
					newsIndexDates[defaultYear + defaultMonth] == null
				) {
					defaultYear = dates[0].year;
					defaultMonth = dates[0].month;
				}

				let yearsArray = Object.keys(years)
					.sort()
					.reverse();

				if (this.state.selectedMonth == '' || this.state.selectedYear == '') {
					this.setState(
						{
							newsIndex: newsIndexDates,
							firstNewsObject: firstObject,
							yearsAvailable: yearsArray,
							defaultYear: defaultYear,
							defaultMonth: defaultMonth,
							selectedYear: defaultYear,
							selectedMonth: defaultMonth,
						},
						() => {
							logger.log('[Photos] after getNewsIndex: %o', this.state);
						}
					);
				} else {
					this.setState(
						{
							newsIndex: newsIndexDates,
							firstNewsObject: firstObject,
							yearsAvailable: yearsArray,
							defaultYear: defaultYear,
							defaultMonth: defaultMonth,
						},
						() => {
							logger.log('[Photos] after getNewsIndex: %o', this.state);
						}
					);
				}
			});
		}
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function getRelatedContent
	 * fetches photos from relatedcontent
	 * -----------------------------------------------------------------------------
	 */
	getRelatedContent(newContent = false) {
		logger.log('[Photos] getRelatedContent this: %o', this);
		let count = 45; //how many we're going to pull
		let photoId = this.props.match.params.photo;
		let selectedYear = this.state.selectedYear;
		let firstPhoto = null;
		if (newContent) {
			this.skip = 0;
		}
		let currentOffset = this.skip;
		let filePath = '';
		if ((!photoId || photoId == 'index') && this.skip == 0 && isEmpty(this.state.checkedPlayers)) {
			filePath =
				this.props.photosbyCount.replace('<count>', count).replace('<skip>', currentOffset) +
				'&startDate=' +
				selectedYear +
				'-01-01&endDate=' +
				selectedYear +
				'-12-31';
		} else if (isEmpty(this.state.checkedPlayers)) {
			if (this.skip == 0) {
				let indFilePath = this.props.photobyId.replace('<lastid>', photoId);
				// logger.log('[Photos] indFilePath:%o', indFilePath);
				if (indFilePath != '') {
					fetch(indFilePath).then(result => {
						firstPhoto = result;
					});
				}
				count -= 1;
			}
			filePath = this.props.photosbyId
				.replace('<count>', count)
				.replace('<lastid>', this.state.lastId ? this.state.lastId : photoId);
		} else {
			filePath =
				this.props.photosbyPlayer
					.replace('<tags>', this.state.checkedPlayers.join('&tags='))
					.replace('<count>', count)
					.replace('<skip>', currentOffset) +
				'&startDate=' +
				selectedYear +
				'-01-01&endDate=' +
				selectedYear +
				'-12-31';
		}
		this.skip += count; //next time we fetch, get the next batch

		// logger.log(
		// 	'[Photos] getRelatedContent - filePath:%o, skip:%o, newContent:%o, firstPhoto:%o',
		// 	filePath,
		// 	this.skip,
		// 	newContent,
		// 	firstPhoto
		// );
		if (filePath != '') {
			fetch(filePath).then(result => {
				logger.log('[Photos] getRelatedContent - result:%o', result);
				let resultData = null;
				if (result.content) {
					resultData = result.content;
				} else {
					resultData = result.next;
				}
				if (firstPhoto) {
					resultData.unshift(firstPhoto);
				}
				logger.log('[Photos] getRelatedContent - resultData:%o', resultData);
				let galleryData = cloneDeep(resultData);
				let content = resultData.map((item, index) => {
					return Object.assign(item, { index: index + currentOffset }); //adds index to object before ads get spliced in
				});
				const adObject = {
					type: 'ad',
				};

				if (content.length > 0) {
					// place ad in 3rd spot if 3 or more items, otherwise place in 2nd spot
					if (content.length < 3) {
						content[content.length] = adObject;
					} else {
						content.splice(2, 0, adObject);
					}

					// The following are the options for ad placement:
					//  yearround
					//  tournament

					// place ad at position 7 and then every 5th after
					if (this.state.adMode == 'tournament' && content.length > 7) {
						for (var i = 7; i < content.length; i++) {
							if ((i - 2) % 5 == 0) {
								content.splice(i, 0, adObject);
							}
						}
					}
				}

				if (newContent) {
					this.setState({
						relatedContent: content,
						galleryData,
						showingCells: 50, //TODO phase out showingCells
						lastId: !isEmpty(content) ? content[content.length - 1].cmsId : null,
						showLoadMore: content.length < count || !isEmpty(this.state.checkedPlayers) ? false : true, //for now, removing the load more button if filtering by player
					});
				} else {
					let relatedContent = this.state.relatedContent.concat(content);
					let newGalleryData = this.state.galleryData.concat(galleryData);
					let showLoadMore = content.length > 0 ? true : false;
					this.setState({
						relatedContent,
						galleryData: newGalleryData,
						showingCells: relatedContent.length, //TODO phase out showingCells
						lastId: relatedContent[relatedContent.length - 1].cmsId,
						showLoadMore,
					});
				}
			});
		}
	}

	setEmptyRelatedContent() {
		this.setState({
			relatedContent: {
				content: [],
			},
		});
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function playerSearch
	 * sets the player that is being searched for
	 * -----------------------------------------------------------------------------
	 */
	playerSearch(e) {
		this.setState({ playerSearch: e.target.value });
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function getFilterPlayers
	 * display the checkbox list of players for filtering
	 * -----------------------------------------------------------------------------
	 */
	getFilterPlayers() {
		const path = this.props.photoPlayersPath;
		let filteredPlayers = [];
		// logger.log("[Photos] getFilterPlayers - path: %o", this.props);
		if (isEmpty(this.state.photoPlayers)) {
			fetch(path).then(result => {
				// logger.log("[Photos] getFilterPlayers - result: %o", result);
				this.setState({ photoPlayers: result.players });
			});
		} else {
			let playerSearch = this.state.playerSearch;
			let photoPlayers = sortBy(this.state.photoPlayers, ['name']);
			if (playerSearch || !isEmpty(this.state.checkedPlayers)) {
				photoPlayers.map(item => {
					let checked = this.state.checkedPlayers.includes(item.id);
					if (
						(playerSearch &&
							item.name
								.trim()
								.toLowerCase()
								.startsWith(playerSearch.toLowerCase())) ||
						checked
					) {
						filteredPlayers.push(
							<li className="playerOption" key={item.id}>
								<label>
									<input
										type="checkbox"
										name="playerFilter"
										value={item.id}
										checked={checked}
										onChange={e => this.checkPlayer(e)}
									/>
									{item.name}
								</label>
							</li>
						);
					}
				});
			}
		}
		return filteredPlayers;
	}

	checkPlayer = e => {
		let player = e.target.value;
		let checkedPlayers = cloneDeep(this.state.checkedPlayers);
		if (!this.state.checkedPlayers.includes(player)) {
			checkedPlayers.push(player);
		} else {
			checkedPlayers = remove(checkedPlayers, function(item) {
				return item != player;
			});
		}
		let selectedYear = '2021'; //set the year to 2019 if a player is selected because we have no players in other years
		this.setState({ checkedPlayers, selectedYear });
	};

	checkError() {
		//check if need to display error
		let error = '';
		if (this.count == 0) {
			error = 'show';
		}
		return <div className={`errorMessage ${error}`}>There is no content for your selection.</div>;
	}

	render() {
		logger.log('[Photos] render - this:%o', this);
		let breakpoint = this.props.windowSize;
		let imageSize = getPlatformImageSize(breakpoint); //for gallery view
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: this.props?.sponsor,
			title: 'Photos',
			page_header: 'Photos',
		};
		// let promo = this.state.promo;
		let header_propsData = {
			title: 'Photos',
			shortTitle: 'Photos',
			metaTitle: 'Photos',
			metaDescription:
				'View and share the latest photos from the 2022 US Open. View photos from your favorite matches of the US Open to the best photos from around the US Open grounds.',
			canonicalLink: 'https://www.usopen.org/en_US/photos/index.html',
		};

		// logger.log('[Photos] render - hi header:%o', header_propsData);

		if (this.state.nav && this.state.nav != this.props.location.pathname) {
			return <EventsRedirect push to={this.state.nav} />;
		} else if (this.state.relatedContent) {
			this.count = 0;
			const imagePrefix = this.props.domain;
			return (
				<section className="photosPage wrapper">
					{this.state.galleryOpen ? (
						<div className="galleryContainer">
							<GalleryView
								imageSize={imageSize}
								galleryData={this.state.galleryData}
								imagePrefix={imagePrefix}
								closeGallery={this.closeGallery}
								selectedItem={this.state.selectedItem}
							/>
						</div>
					) : (
						<Template
							header={header_propsData}
							subHeader={subheader_attributes}
							adType={'landingHero'}
							adTypeRefresh={'landingHero'}>
							<div className="wrapper">
								<PageSubMenu
									mode="News"
									selected="Photos"
									// filters={this.props.filters}
								/>
								{this.filter()}
								{this.renderContent()}
							</div>
						</Template>
					)}
				</section>
			);
		} else {
			return (
				<section className="wrapper">
					<Template
						header={header_propsData}
						subHeader={subheader_attributes}
						adType={'landingHero'}
						adTypeRefresh={'landingHero'}>
						{/* <ScrollHandler hasData={(false)} filter={this.state.filters.type} /> */}
						{/*this.filter()*/}
						<div className="content-main">
							<LoadingIndicator />
						</div>
					</Template>
				</section>
			);
		}
	}

	renderContent() {
		if (this.state.relatedContent == null) {
			return <LoadingIndicator />;
		}
		if (this.state.relatedContent.length == 0) {
			return this.renderNoPhotos();
		} else {
			return this.renderPhotos();
		}
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function renderPhotos
	 * renders images
	 * -----------------------------------------------------------------------------
	 */
	renderPhotos() {
		let content = this.state.relatedContent;
		// logger.log('[PhotoPage] this.state.relatedContent:%o', this.state.relatedContent);
		// if(this.state.galleryOpen){ return null;}
		const numberOfCells = this.state.showingCells;
		let breakpoint = this.props.windowSize;
		return (
			<div className={`content-main ${this.state.listView || breakpoint == 'mobile' ? `list` : `grid`}`}>
				<div className="news-tile">
					<div className="newsChips">
						<div className="chip_content">
							{content.map((item, index) => {
								//logger.log("[PhotoPage] item:%o", item);
								if (index < numberOfCells) {
									if (item.type == 'photo') {
										let photoObject = {};
										const imagePrefix = this.props.domain;
										photoObject['col-style'] = 'three-col';
										photoObject.contentId = item.contentId;
										photoObject.date = item.displayDate;
										photoObject.images = item.images[0];
										photoObject.shortTitle = item.description;
										photoObject.title = item.title;
										photoObject.prefix = imagePrefix;
										photoObject.type = item.type;
										photoObject.subType = item.subType;
										photoObject.author = item?.images[0]?.credit ? item?.images[0]?.credit : null;
										//photoObject.url = item.url;
										photoObject.onClick = e => {
											this.photosTileClicked(e);
										};

										return (
											<NewsTile
												id={`photoTile-${item.index}`}
												attributes={photoObject}
												key={`${item.contentId}-${index}`}
												onClick={() => this.openGallery(item.index, item.title)}
												mouseOverCaptions={true}
											/>
										);
									} else {
										return (
											<div id={`ad${index}`} className="news-ad" key={`ad-${index}`}>
												<AdTag
													adConfig={this.props.adConfig.photos} //using the 920 ads
													dfpNetworkId={this.props.adConfig.dfpNetworkId}
												/>
											</div>
										);
									}
								} else {
									return null;
								}
							})}
							{this.renderLoadMore()}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderNoPhotos() {
		// logger.log('[Photos] rendering no photos');
		return (
			<div className="news-tile">
				<div className="no-news">
					<p>No photos were found for the current selection.</p>
				</div>
			</div>
		);
	}

	renderLoadMore() {
		if (this.state.showLoadMore) {
			return (
				<div id="newsLoad">
					<button
						onClick={e => {
							this.loadMorePressed(e);
						}}
						className="label">
						Load More
					</button>
				</div>
			);
		} else {
			return null;
		}
	}

	loadMorePressed(e) {
		MeasurementUtils.dispatchMeasurementCall('PhotosLoadMore');
		let lastItem = this.state.relatedContent.length - 1;
		this.prevLastItem = `photoTile-${lastItem}`;
		this.getRelatedContent();
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function gridSwitch
	 * switch between grid/list view
	 * -----------------------------------------------------------------------------
	 */
	// gridSwitch() {
	// 	let listView = this.state.listView ? false : true;
	// 	MeasurementUtils.dispatchMeasurementCall('PhotosGridList', { listView });
	// 	this.setState({ listView });
	// 	this.getRelatedContent(true);
	// }

	showFilters = e => {
		e.stopPropagation();
		let showFilters = this.state.showFilters ? false : true;
		this.setState({ showFilters });
	};

	/**
	 * -----------------------------------------------------------------------------
	 * @function openGallery
	 * open the gallery
	 * -----------------------------------------------------------------------------
	 */
	openGallery = (selectedItem, title) => {
		this.setState({ galleryOpen: true, selectedItem });
		MeasurementUtils.dispatchMeasurementCall('PhotosOpenGallery', {
			title,
		});
		window.removeEventListener('click', this.handleBodyClick);
	};

	closeGallery = () => {
		this.setState({ galleryOpen: false });
		MeasurementUtils.dispatchMeasurementCall('PhotosCloseGallery');
		window.addEventListener('click', this.handleBodyClick);
	};

	/**
	 * -----------------------------------------------------------------------------
	 * @function filter
	 * render the filter bar
	 * -----------------------------------------------------------------------------
	 */
	filter() {
		const showAll = this.state.showAll;
		let breakpoint = this.props.windowSize;
		return (
			<>
				<section className="filters">
					<div className="selection">&nbsp;</div>
				</section>
				{this?.props?.landingPages?.photos?.hideYearFilter ? null : (
					<section className="filters third">{this.renderYears()}</section>
				)}
			</>
			// <section className="filters">
			// 	<div className="selection">
			// 		<div className="selection">
			// 			<div className="filters">
			// 				<div
			// 					className={`filterButton${this.state.showFilters ? ` show` : ``}`}
			// 					onClick={e => this.showFilters(e)}>
			// 					<div className="ar-icon">
			// 						<div>
			// 							<i className="icon-search" />
			// 						</div>
			// 					</div>
			// 					{`Filter`}
			// 				</div>
			// 				{this.state.showFilters && (
			// 					<div className="filterBox" onClick={e => this.preventBubble(e)}>
			// 						{this.renderYears()}
			// 						<div className="filterPlayersContainer">
			// 							<input
			// 								type="text"
			// 								name="playerSearch"
			// 								placeholder="Search by Last Name"
			// 								className="playerSearch"
			// 								onChange={e => this.playerSearch(e)}
			// 							/>
			// 							<ul className="filterPlayers">{this.getFilterPlayers()}</ul>
			// 						</div>
			// 					</div>
			// 				)}
			// 			</div>
			// 		</div>

			// 		{/*this.renderSubFilter(showAll)*/}
			// 	</div>
			// </section>
		);
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function renderYears
	 * year filter
	 * -----------------------------------------------------------------------------
	 */
	renderYears() {
		const content = this.state.yearsAvailable;
		return (
			<div className="filter-dropdown">
				<select
					name="years"
					id="yearListXs"
					key="filterYears"
					onChange={e => {
						this.getSelectYear(e);
					}}
					value={this.state.selectedYear}>
					{content.map((item, index) => {
						return (
							<option key={`${item}-${index}`} value={item}>
								{item}
							</option>
						);
					})}
				</select>
			</div>
			// <div className="yearFilter">
			// 	{content.map((item, index) => {
			// 		return (
			// 			<label key={`${item}-${index}`} className="yearSelect">
			// 				<input
			// 					type="radio"
			// 					value={item}
			// 					checked={this.state.selectedYear === item}
			// 					onChange={e => {
			// 						this.getSelectYear(e);
			// 					}}
			// 					disabled={!isEmpty(this.state.checkedPlayers) ? 'disabled' : ''}
			// 				/>
			// 				<span className="yearLabel">{item}</span>
			// 			</label>
			// 		);
			// 	})}
			// </div>
		);
	}

	getSelectYear = e => {
		MeasurementUtils.dispatchMeasurementCall('PhotosSelectYear', {
			selection: e.target.value,
		});
		this.setState({
			selectedYear: e.target.value,
			relatedContent: null,
		});
	};

	photosTileClicked(url) {
		// logger.log('[Photos] photosTileClicked - this:%o', this);

		this.setState({
			nav: url,
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotosPage);
