import { load as lsLoad, clear as lsClear } from 'redux-local-persist';
import { createStore, combineReducers, compose } from 'redux';
import { applyMiddleware } from 'redux-super-thunk';
import thunkMiddleware from 'redux-thunk';
import DevTools from 'dotix/components/DevTools';

const { allInitialStates, allReducers, allMiddleware, allEnhancers } = require('manifest').get();

let initialState = {};

let store;

// Make sure initial loaded state matches reducers
const sanitizeInitialState = state =>
	Object.keys(state)
		.filter(s => s in allReducers)
		.reduce(
			(states, key) => ({
				...states,
				[key]: state[key],
			}),
			{}
		);

const loadDependencyStack = (dependency, items) => {
	return Object.keys(dependency).reduce((items, key) => dependency[key](items), items);
};

export default () => {
	let middlewares = [];
	let enhancers = [];

	// Load InitialState first from modules
	let importedStates = allInitialStates;
	initialState = {
		...sanitizeInitialState(importedStates),
		...initialState,
	};

	middlewares = loadDependencyStack(allMiddleware, middlewares);

	if (middlewares.find(mw => mw.name === 'local-persist')) {
		initialState = {
			...initialState,
			...sanitizeInitialState(lsLoad({ initialState: allInitialStates })),
		};
	} else {
		lsClear();
	}

	const createStoreWithMiddleware = applyMiddleware(
		...middlewares.sort((a, b) => a.order - b.order).map(({ mw }) => mw)
	)(createStore);

	// Combine all Top-level reducers into one
	let rootReducer = combineReducers(allReducers);

	// add redux enhancers
	enhancers = loadDependencyStack(allEnhancers, enhancers)
		.sort((a, b) => a.order - b.order)
		.map(({ enhancer }) => enhancer);

	// Avoid replacing existing store.
	if (store) return store;

	// Execute pre store creation callbacks
	middlewares
		.sort((a, b) => a.order - b.order)
		.filter(({ pre }) => pre)
		.forEach(({ pre }) => pre({ initialState, rootReducer, enhancers }));

	// Create the store
	store = createStoreWithMiddleware(rootReducer, initialState, compose(...enhancers));

	// Execute post store creation callbacks
	middlewares
		.sort((a, b) => a.order - b.order)
		.filter(({ post }) => post)
		.forEach(({ post }) => post({ store }));

	return store;
};
