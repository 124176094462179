import deps from "dependencies";

export default (state = {}, action) => {
  let newState;

  switch (action.type) {
    case deps.actionTypes.GROUNDSMAPMATCHBOX_MOUNT:
      newState = {
        ...state,
        ...action.data
      };
      // logger.log('[GroundsMapMatchBox] actionTypes.GROUNDSMAPMATCHBOX_MOUNT - newState:%o', newState);
      return newState;

    case deps.actionTypes.GROUNDSMAPMATCHBOX_UNMOUNT:
      //if (actions.redirect)
      newState = {
        ...state,
        ...action.data
      };
      // logger.log('[GroundsMapMatchBox] actionTypes.GROUNDSMAPMATCHBOX_UNMOUNT - newState:%o', newState);
      return newState;

    case deps.actionTypes.GROUNDSMAPMATCHBOX_ERROR: {
      newState = {
        ...state,
        ...action.data
      };
      return newState;
    }
    default:
      return state;
  }
};
