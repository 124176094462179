/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'components/general/EventsLink';

/***
 * pass in an object that looks like this
 *
 * {
 *    breadcrumbs : [
 *      {
 *          link:"some url",
 *          title:"some title"
 *      },
 *      {
 *          link:"some url",
 *          title:"some title"
 *      },
 *    ],
 *    title : "current page title",
 *    sponsor : {
 *          link:"some url",
 *          title: "link title text for link title",
 *          name: "sponsor name for alt text",
 *          imgClass:  image classname,
 *          imgSrc: image source,
 *          tagline: "presented by"
 *    }
 * }
 *
 *
 */

const SubHeader = props => {
	// logger.log('[SubHeader] props: %o',props);
	let attributes = props.attributes;

	return (
		<section id="sub_header">
			<div className="centerMargins">
				<div id="breadcrumbs">
					{attributes.breadcrumbs.map((breadcrumb, index) => {
						return (
							<span key={index}>
								<EventsLink to={breadcrumb.link}>{breadcrumb.title}</EventsLink> :&nbsp;
							</span>
						);
					})}
					<span className="pagetitle">{attributes.title}</span>
				</div>
				{/* removes "onAir" breadcrumb for practice courts*/}
				{/* {(attributes.title == 'US Open Now Live Show' ||
					attributes.title == 'US Open Live Stream' ||
					(attributes.title && attributes.title.startsWith('Court P'))) &&
					attributes.showLive !== undefined && (
						<div className="live-status">
							<div className="isLive">
								<span className={attributes.showLive ? `onAir` : `offAir`} />
							</div>
						</div>
					)} */}
			</div>
			{attributes.page_header ? (
				<div className="page_header">
					<div className="header">{attributes.page_header}</div>
					{attributes.sponsor ? (
						<div id="sponsor">
							<div className="presented_by">
								<span>{attributes.sponsor.tagline}</span>
								<EventsLink to={attributes.sponsor.link} title={attributes.sponsor.title}>
									<img
										className={attributes.sponsor.imgClass}
										src={attributes.sponsor.imgSrc}
										border="0"
										alt={attributes.sponsor.name}
									/>
								</EventsLink>
							</div>
						</div>
					) : null}
				</div>
			) : null}
		</section>
	);
};

export default SubHeader;
