import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		logger.log('[PastChamps] action.mount - ensureConfig');

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[PastChamps] action.mount - dispatch - param:%o', params);
			logger.log('Config: ', Config);

			let data = {
				pastChampsPath: Config.otherData.pastChampions,
				pastChampionsEvents: Config.otherData.pastChampionsEvents,
				event: params.event ? params.event : 'ms',
				basePicPath: Config.otherData.basePicPath,
			};

			dispatch({
				type: deps.actionTypes.PASTCHAMPS_LOAD,
				data: data,
			});
		});
	},
	unmount: data => (dispatch, getState, store) => {
		logger.log('[PastChamps] actions.unmount data:%o', data);
		dispatch({ type: deps.actionTypes.PASTCHAMPS_UNMOUNT, data: data });
	},
};
