import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MOBILENAV_MOUNT:
			//newState = Object.assign({}, state, {...action.data});
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.MOBILENAV_UNMOUNT:
			//newState = Object.assign({}, state, {...action.data});
			newState = {
				...state,
				...action.data,
			};
			logger.log('[MOBILENAV] reducer MOBILENAV_UNMOUNT newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
