import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.VIDEOLANDING_MOUNT:
			newState = {
				...state,
				config: action.data,
			};
			return newState;

		case deps.actionTypes.VIDEOLANDING_GEO_UPDATE:
			newState = {
				...state,
				showHighlights: action.data,
			};
			return newState;

		default:
			return state;
	}
};
