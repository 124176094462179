import DrawsWebview from './index';
import deps from 'dependencies';

// tournDay will be a field in the params obj.
// route is day<dayNum>.html
export default {
	path: '/:webview(webview)/:lang(en_US)/draws/:event.html',
	exact: true,
	component: DrawsWebview,
	load: params => deps.actions.DrawsPage.mount(params),
};
