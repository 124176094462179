/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import { connect } from 'react-redux';
import deps from 'dependencies';
import Template from 'components/Template';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import ReplaySelect from './ReplaySelect';
import StubBox from 'appdir/components/common-ui/StubBox';
import { isMobile, isTablet } from 'react-device-detect';
import op from 'object-path';
import LiveVideoSubnav from './LiveVideoSubnav';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';

const queryString = require('querystring-browser');
/**
 * -----------------------------------------------------------------------------
 * React Component: LiveVideo
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['LiveVideo'],
		...props,
		stubs: state['Config'].stubPages,
		adConfig: state['Config']['adConfig'],
		// youTubeUrls: op.get(state['Config'], 'otherData.youTubeUrls', []),
		youtubeFeed: op.get(state['Config'], 'cmsData.youtubeLive', ''),
		liveVideoSubnav: op.get(state['ActiveData'], 'liveVideo', []),
		channels: op.get(state['Controller'], 'video.channels', []),
		windowSize: state['Controller'].windowSize,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.LiveVideo.mount()),
	unmount: () => dispatch(deps.actions.LiveVideo.unmount()),
	select: selected => dispatch(deps.actions.LiveVideo.select(selected)),
});

class LiveVideo extends Component {
	constructor(props) {
		super(props);

		logger.log('[LiveVideo] constructor - props:%o', props);

		this.onVideoEvent = this.onVideoEvent.bind(this);
		this.onNavClick = this.onNavClick.bind(this);
		this.state = {
			...props,
		};
	}

	componentDidMount() {
		logger.log('[LiveVideo] componentDidMount - state:%o', this.state);
	}

	componentWillUpdate(nextProps, nextState) {}

	componentWillUnmount() {
		//logger.log('[LiveVideo] componentWillUnmount');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[LiveVideo] componentDidUpdate - props:%o', this.props);
		logger.log('[LiveVideo] componentDidUpdate - selected:%o %o', this.props.selected, prevProps.selected);
		// if (this.props.geos.available.espn && this.props.stubs.livevideo.stub == 'quals' && this.props.selected == 'index') {
		// 	this.onNavClick('espn');
		// }
		if (
			(this.props.selected == 'warmup' ||
				this.props.selected == 'mixer' ||
				this.props.selected == 'changeover' ||
				this.props.selected == 'us-open-matchcast') &&
			this.props.selected != prevProps.selected
		) {
			deps.services.LiveVideo.fetch(this.props.youtubeFeed)
				.then(response => {
					logger.log('[LiveVideo] componentDidUpdate - response:%o %o', response, this.props.selected);
					let channelData = response?.youtubeEmbeds[this.props.selected][0];
					logger.log('[LiveVideo] componentDidUpdate - channelData %o', channelData);
					this.setState({ channelData });
				})
				.catch(error => {
					logger.error('[LiveVideo] componentDidUpdate error loading data:%o', error);
				});
		}
	}

	onVideoEvent(event) {
		logger.info('[LiveVideo] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				directVideo: '',
			});
		}
	}

	onNavClick(selected) {
		if (selected != this.props.selected) {
			//logger.info('[LiveVideo] onNavClick - selected:%o', selected);
			this.props.select(selected);
		}
	}

	getEspn() {
		if (this.props.stubs) {
			if (!isMobile && !isTablet) {
				let channelId = '';
				// Check for a court id
				let search = this.props.location.search.replace(/^\?/, '');
				let parsedQs = queryString.parse(search);

				if (parsedQs.court) {
					let index = this.props.channels.findIndex(p => p.courtId === parsedQs.court);
					if (index > -1) {
						channelId = '&id=' + this.props.channels[index].iOSURL;
					}
					logger.log('[LiveVideo] getEspn - courtId: %o, channelId:%o ', parsedQs.court, channelId);
				} else {
					let index = this.props.channels.findIndex(p => p.live === true);
					if (index > -1) {
						channelId = '&id=' + this.props.channels[index].iOSURL;
						logger.log('[LiveVideo] getEspn - channelId:%o ', channelId);
					}
				}
				return (
					<div>
						<section className="uso-media" style={{ marginTop: '20px', minHeight: '450px' }}>
							<div className="media-col" style={{ padding: 0 }}>
								<div className="media-16x9">
									<div className="media-wrapper">
										<iframe
											src={this.props.espnUrl + channelId}
											scrolling="no"
											height="100%"
											width="100%"
											id="espn-player"
											frameBorder="0"
											crossOrigin="anonymous"
										/>
									</div>
								</div>
							</div>
						</section>
						<div
							style={{
								width: '100%',
								textAlign: 'center',
								margin: '20px auto',
							}}>
							{/* <div id="Chip1">
								<AdTag
									adConfig={this.props.adConfig.live_video}
									dfpNetworkId={this.props.adConfig.dfpNetworkId}
								/>
							</div> */}
						</div>
					</div>
				);
			} else {
				return (
					<div className="general_content espn-holder">
						<h1 className="header">Live Streaming</h1>
						<div className="siteDetailContainer">
							<div className="siteDetail">
								<p>
									Live Video requires the official ESPN mobile app to enjoy live video coverage of the
									US Open. Please visit the app store to download the ESPN app.
								</p>
							</div>
						</div>
						<div className="espn-frame-link">
							<EventsLink to="https://www.espn.com/app" style="uso-button blue">
								Get the ESPN Mobile App
							</EventsLink>
						</div>
					</div>
				);
			}
		} else {
			return (
				<div className="copy">
					<p>
						Live Video requires the official ESPN mobile app to enjoy live video coverage of the US Open.
						Please visit the app store to download the ESPN app.
					</p>
					<p>
						<EventsLink to="http://www.espn.com/app">
							<img src="/images/ios/watch_espn.png" alt="Streaming Live on ESPN App" border="0" />
						</EventsLink>
					</p>
				</div>
			);
		}
	}

	getUSOChan() {
		if (
			this.props.stubs &&
			(this.props.stubs.livevideo.stub == 'live' || this.props.stubs.livevideo.stub == 'quals')
		) {
			// return <WimbledonChannel position="main" name="main" geoSet={this.props.geos.set} geoBlock={!this.props.geos.available.wimchan} />;
		} else {
			return (
				<div className="copy">
					<p>
						Live Video requires the official ESPN mobile app to enjoy live video coverage of the US Open.
						Please visit the app store to download the ESPN app.
					</p>
					<p>
						<EventsLink to="http://www.espn.com/app">
							<img src="/images/ios/watch_espn.png" alt="Streaming Live on ESPN App" border="0" />
						</EventsLink>
					</p>
				</div>
			);
		}
	}

	renderReplayDropdown() {
		let replaysYouTube = this.props.replaysYouTube;
		let replays = this.props.replays;
		let dropdownVals;

		// if YouTube exists, then use YouTube
		if (replaysYouTube && replaysYouTube.length > 0) {
			dropdownVals = replaysYouTube.map(item => ({
				name: item.title,
				value: item.url,
			}));
			// logger.log('[LiveVideo] renderReplayDropdown - YouTube dropdownVals:%o', dropdownVals);
		} else if (replays && replays.length > 0) {
			dropdownVals = replays.map(item => ({
				name: item.title,
				value: item.url,
			}));
			// logger.log('[LiveVideo] renderReplayDropdown - replays dropdownVals:%o', dropdownVals);
		}

		if (dropdownVals) {
			return (
				<div className="replay-selector">
					<ReplaySelect attributes={dropdownVals} filters={this.props.filters} />
				</div>
			);
		}
	}

	renderYT() {
		// logger.log('[LiveVideo] youtube render state: %o', this.state);
		let { selected } = this.props;
		let selectedData = this.props.liveVideoSubnav.find(item => item.id === selected);
		logger.log('[LiveVideo] renderYT selectedData: %o', selectedData);
		
		if (selectedData) {
			return (
				<>
					<div className="youtube-embed">
						<div className="youtube-wrapper">
							<iframe
								src={selectedData.embed}
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen></iframe>
						</div>
					</div>
					<div className="youtube-tag">
						<a href={selectedData.link} target="_blank">
							{selectedData.text}
						</a>
					</div>
				</>
			);
		} else {
			return null;
		}
	}

	render() {
		// logger.log('[LiveVideo] render - state:%o', this.state);
		logger.log('[LiveVideo] render - props:%o', this.props);
		// logger.log('[LiveVideo] render - available:%o', this.state.geos.available);
		let practiceCourtsURL = '/en_US/video/practice-courts/court-p1.html'; //live will redirect to practice courts 2023

		let header_propsData = {
			headerType: 'live-streaming',
			title: 'Live Streaming',
			shortTitle: 'Live Streaming',
			scroll: false,
			metaTitle: 'Live Streaming | Videos',
			metaDescription: 'Live Streaming The 2022 US Open Tennis Championships',
			metaDate: '',
			metaPlayers: '',
			canonicalLink: 'https://www.usopen.org/en_US/video/live.html',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/video/live/live.html',
					title: 'Video',
				},
			],
			title: 'Live Streaming',
		};

		if(this.props?.liveVideoSubnav.length > 0) {

		// if have selected video and geos are set
		let { stubs, selected } = this.props;
		let selectedData = this.props?.liveVideoSubnav.find(item => item.id === selected);
		logger.log('[LiveVideo] render - selectedData:%o', selectedData);

		if (selectedData && selectedData.stub) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<StubBox
						attributes={{
							title: header_propsData.title,
							message: stubs[selected].text,
							basePicPath: this.props.basePicPath,
						}}
					/>
				</Template>
			);
		} else if (selectedData || selected == 'live') {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<div className="live-page content-body">
						<PageSubMenu mode="Watch" selected="watch" />
						{/* {selected == 'live' ? this.getEspn() : null}
						live will redirect to practice courts 2023
						*/}
						{selected == 'live' ? <EventsRedirect to={practiceCourtsURL} /> : null}
						{selectedData && selectedData.embed.includes('youtube') ? this.renderYT() : null}
					</div>
				</Template>
			);
		} else {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</Template>
			);
		}
	} else {
		return null;
	}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveVideo);
