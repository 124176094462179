/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, Fragment } from 'react';
import JSXParser from 'react-jsx-parser';
import Button from 'appdir/components/common-ui/Button';
import Translate from 'appdir/components/pages/Slamtracker/Translate';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: TableComponent
 * -----------------------------------------------------------------------------
 */
const RallyStats = props => {
	// logger.log('[RallyStats] props: %o',props);
	const [statTeam, changeTeam] = useState('t1');
	const rally_stat_rows = [
        'approach_shots',
        'drop_shots',
        'ground_strokes',
        'lobs',
        'overhead_strokes',
        'passing_shots',
        'volleys',
    ];

	let names = props.data.names;
	let t1_stats = props.data.t1;
	let t2_stats = props.data.t2;
	let doubles = props.data.doubles;

	let t1_winners_f, t1_winners_b, t1_forced_f, t1_forced_b, t1_unforced_f, t1_unforced_b;
	let t2_winners_f, t2_winners_b, t2_forced_f, t2_forced_b, t2_unforced_f, t2_unforced_b;

	const getStatData = (stat) => {
		switch (stat) {
			case 'approach_shots':
				t1_winners_f = t1_stats?.a_fh_w == null ? '-' : t1_stats?.a_fh_w;
				t1_winners_b = t1_stats?.a_bh_w == null ? '-' : t1_stats?.a_bh_w;
				t1_forced_f = t1_stats?.a_fh_fe == null ? '-' : t1_stats?.a_fh_fe;
				t1_forced_b = t1_stats?.a_bh_fe == null ? '-' : t1_stats?.a_bh_fe;
				t1_unforced_f = t1_stats?.a_fh_ufe == null ? '-' : t1_stats?.a_fh_ufe;
				t1_unforced_b = t1_stats?.a_bh_ufe == null ? '-' : t1_stats?.a_bh_ufe;

				t2_winners_f = t2_stats?.a_fh_w == null ? '-' : t2_stats?.a_fh_w;
				t2_winners_b = t2_stats?.a_bh_w == null ? '-' : t2_stats?.a_bh_w;
				t2_forced_f = t2_stats?.a_fh_fe == null ? '-' : t2_stats?.a_fh_fe;
				t2_forced_b = t2_stats?.a_bh_fe == null ? '-' : t2_stats?.a_bh_fe;
				t2_unforced_f = t2_stats?.a_fh_ufe == null ? '-' : t2_stats?.a_fh_ufe;
				t2_unforced_b = t2_stats?.a_bh_ufe == null ? '-' : t2_stats?.a_bh_ufe;
				break;
			case 'drop_shots':
				t1_winners_f = t1_stats?.d_fh_w == null ? '-' : t1_stats?.d_fh_w;
				t1_winners_b = t1_stats?.d_bh_w == null ? '-' : t1_stats?.d_bh_w;
				t1_forced_f = t1_stats?.d_fh_fe == null ? '-' : t1_stats?.d_fh_fe;
				t1_forced_b = t1_stats?.d_bh_fe == null ? '-' : t1_stats?.d_bh_fe;
				t1_unforced_f = t1_stats?.d_fh_ufe == null ? '-' : t1_stats?.d_fh_ufe;
				t1_unforced_b = t1_stats?.d_bh_ufe == null ? '-' : t1_stats?.d_bh_ufe;

				t2_winners_f = t2_stats?.d_fh_w == null ? '-' : t2_stats?.d_fh_w;
				t2_winners_b = t2_stats?.d_bh_w == null ? '-' : t2_stats?.d_bh_w;
				t2_forced_f = t2_stats?.d_fh_fe == null ? '-' : t2_stats?.d_fh_fe;
				t2_forced_b = t2_stats?.d_bh_fe == null ? '-' : t2_stats?.d_bh_fe;
				t2_unforced_f = t2_stats?.d_fh_ufe == null ? '-' : t2_stats?.d_fh_ufe;
				t2_unforced_b = t2_stats?.d_bh_ufe == null ? '-' : t2_stats?.d_bh_ufe;
				break;
			case 'ground_strokes':
				t1_winners_f = t1_stats?.g_fh_w == null ? '-' : t1_stats?.g_fh_w;
				t1_winners_b = t1_stats?.g_bh_w == null ? '-' : t1_stats?.g_bh_w;
				t1_forced_f = t1_stats?.g_fh_fe == null ? '-' : t1_stats?.g_fh_fe;
				t1_forced_b = t1_stats?.g_bh_fe == null ? '-' : t1_stats?.g_bh_fe;
				t1_unforced_f = t1_stats?.g_fh_ufe == null ? '-' : t1_stats?.g_fh_ufe;
				t1_unforced_b = t1_stats?.g_bh_ufe == null ? '-' : t1_stats?.g_bh_ufe;

				t2_winners_f = t2_stats?.g_fh_w == null ? '-' : t2_stats?.g_fh_w;
				t2_winners_b = t2_stats?.g_bh_w == null ? '-' : t2_stats?.g_bh_w;
				t2_forced_f = t2_stats?.g_fh_fe == null ? '-' : t2_stats?.g_fh_fe;
				t2_forced_b = t2_stats?.g_bh_fe == null ? '-' : t2_stats?.g_bh_fe;
				t2_unforced_f = t2_stats?.g_fh_ufe == null ? '-' : t2_stats?.g_fh_ufe;
				t2_unforced_b = t2_stats?.g_bh_ufe == null ? '-' : t2_stats?.g_bh_ufe;
				break;
			case 'lobs':
				t1_winners_f = t1_stats?.l_fh_w == null ? '-' : t1_stats?.l_fh_w;
				t1_winners_b = t1_stats?.l_bh_w == null ? '-' : t1_stats?.l_bh_w;
				t1_forced_f = t1_stats?.l_fh_fe == null ? '-' : t1_stats?.l_fh_fe;
				t1_forced_b = t1_stats?.l_bh_fe == null ? '-' : t1_stats?.l_bh_fe;
				t1_unforced_f = t1_stats?.l_fh_ufe == null ? '-' : t1_stats?.l_fh_ufe;
				t1_unforced_b = t1_stats?.l_bh_ufe == null ? '-' : t1_stats?.l_bh_ufe;

				t2_winners_f = t2_stats?.l_fh_w == null ? '-' : t2_stats?.l_fh_w;
				t2_winners_b = t2_stats?.l_bh_w == null ? '-' : t2_stats?.l_bh_w;
				t2_forced_f = t2_stats?.l_fh_fe == null ? '-' : t2_stats?.l_fh_fe;
				t2_forced_b = t2_stats?.l_bh_fe == null ? '-' : t2_stats?.l_bh_fe;
				t2_unforced_f = t2_stats?.l_fh_ufe == null ? '-' : t2_stats?.l_fh_ufe;
				t2_unforced_b = t2_stats?.l_bh_ufe == null ? '-' : t2_stats?.l_bh_ufe;
				break;
			case 'overhead_strokes':
				t1_winners_f = t1_stats?.o_fh_w == null ? '-' : t1_stats?.o_fh_w;
				t1_winners_b = t1_stats?.o_bh_w == null ? '-' : t1_stats?.o_bh_w;
				t1_forced_f = t1_stats?.o_fh_fe == null ? '-' : t1_stats?.o_fh_fe;
				t1_forced_b = t1_stats?.o_bh_fe == null ? '-' : t1_stats?.o_bh_fe;
				t1_unforced_f = t1_stats?.o_fh_ufe == null ? '-' : t1_stats?.o_fh_ufe;
				t1_unforced_b = t1_stats?.o_bh_ufe == null ? '-' : t1_stats?.o_bh_ufe;

				t2_winners_f = t2_stats?.o_fh_w == null ? '-' : t2_stats?.o_fh_w;
				t2_winners_b = t2_stats?.o_bh_w == null ? '-' : t2_stats?.o_bh_w;
				t2_forced_f = t2_stats?.o_fh_fe == null ? '-' : t2_stats?.o_fh_fe;
				t2_forced_b = t2_stats?.o_bh_fe == null ? '-' : t2_stats?.o_bh_fe;
				t2_unforced_f = t2_stats?.o_fh_ufe == null ? '-' : t2_stats?.o_fh_ufe;
				t2_unforced_b = t2_stats?.o_bh_ufe == null ? '-' : t2_stats?.o_bh_ufe;
				break;
			case 'passing_shots':
				t1_winners_f = t1_stats?.p_fh_w == null ? '-' : t1_stats?.p_fh_w;
				t1_winners_b = t1_stats?.p_bh_w == null ? '-' : t1_stats?.p_bh_w;
				t1_forced_f = t1_stats?.p_fh_fe == null ? '-' : t1_stats?.p_fh_fe;
				t1_forced_b = t1_stats?.p_bh_fe == null ? '-' : t1_stats?.p_bh_fe;
				t1_unforced_f = t1_stats?.p_fh_ufe == null ? '-' : t1_stats?.p_fh_ufe;
				t1_unforced_b = t1_stats?.p_bh_ufe == null ? '-' : t1_stats?.p_bh_ufe;

				t2_winners_f = t2_stats?.p_fh_w == null ? '-' : t2_stats?.p_fh_w;
				t2_winners_b = t2_stats?.p_bh_w == null ? '-' : t2_stats?.p_bh_w;
				t2_forced_f = t2_stats?.p_fh_fe == null ? '-' : t2_stats?.p_fh_fe;
				t2_forced_b = t2_stats?.p_bh_fe == null ? '-' : t2_stats?.p_bh_fe;
				t2_unforced_f = t2_stats?.p_fh_ufe == null ? '-' : t2_stats?.p_fh_ufe;
				t2_unforced_b = t2_stats?.p_bh_ufe == null ? '-' : t2_stats?.p_bh_ufe;
				break;
			case 'volleys':
				t1_winners_f = t1_stats?.v_fh_w == null ? '-' : t1_stats?.v_fh_w;
				t1_winners_b = t1_stats?.v_bh_w == null ? '-' : t1_stats?.v_bh_w;
				t1_forced_f = t1_stats?.v_fh_fe == null ? '-' : t1_stats?.v_fh_fe;
				t1_forced_b = t1_stats?.v_bh_fe == null ? '-' : t1_stats?.v_bh_fe;
				t1_unforced_f = t1_stats?.v_fh_ufe == null ? '-' : t1_stats?.v_fh_ufe;
				t1_unforced_b = t1_stats?.v_bh_ufe == null ? '-' : t1_stats?.v_bh_ufe;

				t2_winners_f = t2_stats?.v_fh_w == null ? '-' : t2_stats?.v_fh_w;
				t2_winners_b = t2_stats?.v_bh_w == null ? '-' : t2_stats?.v_bh_w;
				t2_forced_f = t2_stats?.v_fh_fe == null ? '-' : t2_stats?.v_fh_fe;
				t2_forced_b = t2_stats?.v_bh_fe == null ? '-' : t2_stats?.v_bh_fe;
				t2_unforced_f = t2_stats?.v_fh_ufe == null ? '-' : t2_stats?.v_fh_ufe;
				t2_unforced_b = t2_stats?.v_bh_ufe == null ? '-' : t2_stats?.v_bh_ufe;
				break;
			default:
				break;
		}

		return {
			t1_winners_f, t1_winners_b, t1_forced_f, t1_forced_b, t1_unforced_f, t1_unforced_b,
			t2_winners_f, t2_winners_b, t2_forced_f, t2_forced_b, t2_unforced_f, t2_unforced_b
		}
	}

	return (
		<div className="stats-table-wrapper rally">
            <div className="stats-header-container">
				<div className="stats-caption-table">
					<Button
						className={`stats-header-caption t1 ${statTeam == 't1' ? 'selected' : ''}`}
						onClick={() => changeTeam('t1')}>
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[0]} />
						</div>
					</Button>
					<Button
						className={`stats-header-caption t2 ${statTeam == 't2' ? 'selected' : ''}`}
						onClick={() => changeTeam('t2')}>
						<div className={`stats-player ${doubles ? 'doubles' : ''}`}>
							<JSXParser jsx={names[1]} />
						</div>
					</Button>
				</div>
				<div className="stats-header-table">
					<div className="stats-header-cell">
						<Translate originalText="winners" />
					</div>
					<div className="stats-header-cell forced">
						<Translate originalText="forced_errors" />
					</div>
					<div className="stats-header-cell unforced">
						<Translate originalText="unforced_errors" />
					</div>
				</div>
				<div className="stats-sub-header-table">
					<div className="stats-header-cell-small">
						<Translate originalText="forehand_short" />
					</div>
					<div className="stats-header-cell-small">
						<Translate originalText="backhand_short" />
					</div>
					<div className="stats-header-cell-small">
						<Translate originalText="forehand_short" />
					</div>
					<div className="stats-header-cell-small">
						<Translate originalText="backhand_short" />
					</div>
					<div className="stats-header-cell-small">
						<Translate originalText="forehand_short" />
					</div>
					<div className="stats-header-cell-small">
						<Translate originalText="backhand_short" />
					</div>
				</div>
			</div>
            <div className={`stats-table ${statTeam}`}>
            	{rally_stat_rows.map((stat, index) => {
					let rowData = getStatData(stat);

					return (
						<Fragment key={`${stat}-row${index}`}>
							<div className={`stats-row ${stat}`} key={`${stat}-label`}>
								<div className="stats-label">
									<Translate originalText={stat} />
								</div>
							</div>
							<div className={`stats-row ${stat} ${index != rally_stat_rows.length - 1 ? 'border' : ''}`} key={stat}>
								<div className="stats-data winners forehand">{statTeam == 't2' ? rowData.t2_winners_f : rowData.t1_winners_f}</div>
								<div className="stats-data winners backhand">{statTeam == 't2' ? rowData.t2_winners_b : rowData.t1_winners_b}</div>
								<div className="stats-data forced forehand">{statTeam == 't2' ? rowData.t2_forced_f : rowData.t1_forced_f}</div>
								<div className="stats-data forced backhand">{statTeam == 't2' ? rowData.t2_forced_b : rowData.t1_forced_b}</div>
								<div className="stats-data unforced forehand">{statTeam == 't2' ? rowData.t2_unforced_f : rowData.t1_unforced_f}</div>
								<div className="stats-data unforced backhand">{statTeam == 't2' ? rowData.t2_unforced_b : rowData.t1_unforced_b}</div>
							</div>
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default RallyStats;
