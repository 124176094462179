import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import findLastKey from 'lodash/findLastKey';

import Collapsible from 'react-collapsible';
import StageGame from 'appdir/components/pages/Slamtracker/elements/stage/StageGame';

const op = require('object-path');
const humanSort = require('alphanum-sort');

/**
 * -----------------------------------------------------------------------------
 * Functional Component: StageSet
 * -----------------------------------------------------------------------------
 */

const StageSet = props => {
	const statMatch = useSelector(state => state?.['ScoreManager']?.statMatch ?? {});
	// logger.log('[StageSet] statMatch - props:%o', props);

	let { data } = props;
	let { setObj } = data;
	let { setNo } = data;
	let { lastPointData } = data;
	let { pointsById } = data;
	let { pointHistory } = data;
	let { currentSet } = data;
	let { currentSetObj } = data;
	let { matchStatus } = props;

	const [currGame, setCurrGame] = useState(null);
	const [isAccordionOpen, setIsAccordionOpen] = useState(false);
	const [clicked, setClicked] = useState(false); // helper to track accordion open trigger
  
	const lastGame = () =>
		findLastKey(setObj, function (gameObj) {
			return gameObj;
		});

	const gamePoint = (setNo, gameNo, gameObj) =>
		findLastKey(gameObj, function (pointObj) {
			return pointObj.GameWinner !== '0';
		});

	let currGameArr;
	if(currentSetObj && currentSetObj != undefined) {
		currGameArr = Object.keys(currentSetObj);
	}

	useEffect(() => {
			if (currentSetObj && statMatch?.scores?.sets.length == parseInt(setNo)) {
		setCurrGame(currGameArr.length - 1);
			}
	}, [currentSetObj, statMatch.match_id]);

	/** Handle Collapsible open states */
	// const handleSetClick = (index, e) => {
	//   logger.log('[StageSet] handleSetClick - e:%o', e);


	//   if(isAccordionOpen) {
	//     setIsAccordionOpen(false);
	//   } else if(currGame == index){
	//     setIsAccordionOpen(true);
	//   } else {
	//     setIsAccordionOpen(false)
	//   }

	//   setCurrGame((prevIndex) => (prevIndex === index ? null : index));
	//   props.measureIndex(`${props.currentTab}:Game Accordion:${isAccordionOpen ? 'Expand' : 'Close'}`);
	//  };

	const getGameSummaryText = pointObj => {
		if (pointObj) {
			try {
				let summaryText;

				let { scores } = statMatch;
				let sets = op.get(scores, 'sets');
        		let isDoubles;
				if (
					sets &&
					sets[parseInt(pointObj.SetNo, 10) - 1] &&
					sets[parseInt(pointObj.SetNo, 10) - 1].length > 1
				) {
					let t1score = !scores.sets[parseInt(pointObj.SetNo, 10) - 1][0].tiebreakDisplay
						? scores.sets[parseInt(pointObj.SetNo, 10) - 1][0].scoreDisplay
						: `${scores.sets[parseInt(pointObj.SetNo, 10) - 1][0].scoreDisplay}`;

					let t2score = !scores.sets[parseInt(pointObj.SetNo, 10) - 1][1].tiebreakDisplay
						? scores.sets[parseInt(pointObj.SetNo, 10) - 1][1].scoreDisplay
						: `${scores.sets[parseInt(pointObj.SetNo, 10) - 1][1].scoreDisplay}`;

					if (pointObj.MatchWinner !== '0' && pointObj.MatchWinner !== '') {
						let team = statMatch[`team${pointObj.MatchWinner}`];
						let name = team.displayNameB
							? `${team.displayNameA} / ${team.displayNameB}`
							: team.displayNameA;
            				isDoubles = team.displayNameB ? true : false;
            
						summaryText = (
							<div>
								<span className={`summaryName ${isDoubles ? 'doubles' : '' }`}>{name}</span>
								<span className="summaryScore">{` ${t1score}-${t2score}`}</span>
							</div>
						);
					} else if (pointObj.SetWinner !== '0' && pointObj.SetWinner !== '') {
						let team = statMatch[`team${pointObj.SetWinner}`];
						let name = team.displayNameB
							? `${team.displayNameA} / ${team.displayNameB}`
							: team.displayNameA;
							isDoubles = team.displayNameB ? true : false;

						summaryText = (
							<div>
								<span className={`summaryName ${isDoubles ? 'doubles' : '' }`}>{name}</span>
								<span className="summaryScore">{` ${t1score}-${t2score}`}</span>
							</div>
						);
					} else if (pointObj.GameWinner !== '0' && pointObj.GameWinner !== '') {
						let team = statMatch[`team${pointObj.GameWinner}`];
						let name = team.displayNameB
							? `${team.displayNameA} / ${team.displayNameB}`
							: team.displayNameA;
						t1score = pointObj.P1GamesWon;
						t2score = pointObj.P2GamesWon;
            			isDoubles = team.displayNameB ? true : false;

						// summaryText = `${name} ${t1score}-${t2score}`;
						summaryText = (
							<div>
								<span className={`summaryName ${isDoubles ? 'doubles' : '' }`}>{name}</span>
								<span className="summaryScore">{` ${t1score}-${t2score}`}</span>
							</div>
						);
					}
					// logger.log('[StageSet] getGameSummaryText - summaryText:%o',summaryText);

					return summaryText ? summaryText?.props?.children : null;
				} else {
					return null;
				}
			} catch (e) {
				logger.error('[StageSet] getGameSummaryText - error:%o', e);
				return null;
			}
		} else {
			return null;
		}
	};

	// const onOpen = () => {
	//   setIsAccordionOpen(true);
	// }
	const onOpen = () => {
		setIsAccordionOpen(true);
		if(clicked){
			props?.measureIndex(`Game Accordion:Expand`, '', []);
		}
	}
	const onClose = () => {
		setIsAccordionOpen(false);
		props?.measureIndex(`Game Accordion:Close`, '', []);
	}
  
	return (
		<div className="game-info-container">
			{Object.keys(setObj)
				.map((gameNo, j) => {
					const winnerName = getGameSummaryText(pointsById[gamePoint(setNo, gameNo, setObj[gameNo])]);
					const gameData = {
						gameObj: setObj[gameNo],
						setNo,
						gameNo,
						statMatch,
						pointsById,
					};
					return (
						<div key={j} className={`Set num_${j + 1} ${winnerName ? 'winner' : 'in-progress'}`}>
							<Collapsible
								key={j}
								// trigger={`Game ${j + 1} - ${winnerName ? winnerName : 'In Progress' }`}
								trigger={
									<div className="summary-wrap">
										Game {`${j + 1}`} - {winnerName ? winnerName : 'In Progress'}
									</div>
								}
								accordionPosition={`Game_${j}`}
								triggerOpenedClassName="triggerOpen-game"
								openedClassName="opened_game"
								open={currGame === j}
								// handleTriggerClick={(e) => handleSetClick(j, e)}
								contentInnerClassName={'stage-game-inner'}
								className="closed_game"
								// onOpen={onOpen}
								onOpen={() => onOpen()}
								onClose={() => onClose()}
								onTriggerOpening={() => setClicked(true)}
								>
								<StageGame data={{ ...gameData }} matchStatus={matchStatus} />
							</Collapsible>
						</div>
					);
				})}
		</div>
	);
};

export default StageSet;
