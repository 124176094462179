/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/** Modal Components */
/* RLL says:
 * you need to create a component for your modal and
 * add an import statement for your modal here
 */
import ShareModal from 'appdir/components/common-ui/ShareModal';
import MatchInsightsModal from 'appdir/components/common-ui/MatchInsightsModal';
import TicketsInfoModal from 'appdir/components/common-ui/TicketsInfoModal';
import FanWeekScheduleModal from 'appdir/components/common-ui/FanWeekScheduleModal';
import VideoModal from 'appdir/components/common-ui/VideoModal';

/**
 * -----------------------------------------------------------------------------
 * React Component: ModalOverlayContainer
 * -----------------------------------------------------------------------------
 */

/* RLL says:
 * add a constant for your modal here
 * your modal needs to have it's own onClose method
 * (look at /components/common-ui/ShareModal for example)
 *
 * You also need to add your modal to the switch statements
 * in actions.js and the the SHOW_MODAL reducer.
 */
const MODAL_COMPONENTS = {
	SHARE_MODAL: ShareModal,
	MATCH_INSIGHTS: MatchInsightsModal,
	TICKETS_INFO: TicketsInfoModal,
	FAN_WEEK_SCHEDULE: FanWeekScheduleModal,
	VIDEO_MODAL: VideoModal
};

const mapStateToProps = (state, props) => {
	return {
		...state['ModalOverlayContainer'],
		...state['ModalOverlay'],
		...props,
	};
};

class ModalOverlayContainer extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		// logger.log('[ModalOverlayContainer] render:%o', this.state);

		if (!this.props.modalType) {
			return null;
		}

		const SpecificModal = MODAL_COMPONENTS[this.props.modalType];

		return <SpecificModal title={this.props.title} description={this.props.description} />;
	}
}

export default connect(mapStateToProps)(ModalOverlayContainer);
