import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import { getIconClass } from 'appdir/components/general/Util';
import ButtonGeneral from 'components/cms/ButtonGeneral';

import { isMobile, isTablet } from 'react-device-detect';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * ---------------------------------------
 * Functional Component: HomeInfo - generic content box
 *
 * Supports below contents and more
 * - Headlines
 * - Event Information
 * - Tickets Info with icon option
 * - Visit US Open
 *
 * Option to support bullets, icons, and no bullets lists
 *
 * Props
 *  @param {Object} EventsWindow
 *  @param {Boolean} showHeader ------- when the component is set to place in the center,
 *                                      show the header row in case to show button link
 *                                      above mobile size
 *                                      this also makes the list to display in two columns
 *  @param {Object} <cmsdata> --------- content
 *
 * ---------------------------------------
 */

const HomeInfo = props => {
	// logger.log('[HomeInfo] props:%o', props.data);
	let homeInfoContent = props.data.content;
	let buttonData = props.data.content.button;
	let { EventsWindow } = props.data;
	// logger.log('[HomeInfo] buttonData:%o', buttonData);

	let measureData = Object.assign({}, props.data);
	delete measureData.data?.content;

	let mobileSize = EventsWindow?.numericWindowSize < 4;
	let measureNum = !mobileSize ? 2 : 1;
	let isCenterCol =
		props.data?.desktopPosition?.toLowerCase() !== 'sideleft' &&
		props.data?.desktopPosition?.toLowerCase() !== 'sideright';

	const getClassNames = () => {
		return cn({
			bullets: true,
			'two-cols': props.data?.showHeader && !mobileSize,
			'no-bullets': !props.data?.bullets,
		});
	};

	const openRadio = (item, measureData, debug) => {
		let radioWindow = '';
		let radioLaunchPage = '';
		let baseUrl = item.link;

		let lang = 'en';

		if (measureData) {
			MeasurementUtils.dispatchMeasurementCall(measureData.actionType, { args: measureData });
		}

		if (isMobile || isTablet) {
			window.open(baseUrl, '_blank');
		} else {
			var d = new Date();
			var t = d.getTime();

			radioLaunchPage += baseUrl + '?ts=' + t;
			radioLaunchPage += debug && debug.indexOf('debug') > -1 ? '&db=true' : '&db=false';
			radioLaunchPage += '&ref=' + document.location.host + document.location.pathname;
			radioLaunchPage += '&lang=' + lang;
			if (radioWindow.closed || radioWindow == '') {
				//consoleWindow = window.open(consolelaunchPage,"consoleWindow","width=510,height=" + mcWindowHeight + ",top=50,left=50")
				radioWindow = window.open(radioLaunchPage, 'radioWindow', 'width=1024,height=708,top=50,left=50');
				if (radioWindow.opener == null) radioWindow.opener = self;
			}
			radioWindow.focus();
		}
	};

	if (!isEmpty(homeInfoContent)) {
		return (
			<div className="homeInfo">
				{props.data?.showHeader ? (
					<div className="title-bar">
						<h3>{props.data.shortTitle}</h3>

						{
							/**
							 * above mobile size and this is for center col,
							 *  show the button content as a text link in header
							 */
							// !isEmpty(props.data.button) && !mobileSize && isCenterCol ? (
							//     <div className="rightCol">
							//         <EventsLink to={props.data.button?.link} style="uppercase">{props.data.button?.text}</EventsLink>
							//     </div>
							// ): null
						}
					</div>
				) : null}

				<div className="content">
					<ul className={getClassNames()}>
						{homeInfoContent.items.map((item, i) => {
							let listIcon = '';

							if (item?.icon && item?.icon !== '') {
								listIcon = 'icon-check';
							}

							if(item.link.indexOf('/radio.html') > -1 || item.link.indexOf('/actionaudio.html') > -1) {
								return (
									<li key={`${item.id} ${i}`} className={listIcon !== '' ? 'icon-indent' : ''}>
										<a
											className="home-radio-link"
											onClick={() => {
												openRadio(item, {
													...item,
													...measureData,
													itemType: item.type,
													itemTitle: item.title,
													itemId: item.id,
													actionType: 'homePageClick',
													which: `stories_col${measureNum}_item${i}`
												});
											}}
											onKeyDown={e => {
												if (e.key == ' ' || e.code == 'Space') {
													openRadio(item);
												}
											}}
											tabIndex={0}>
											{item?.icon && item?.icon !== '' ? (
												<i className={getIconClass(item.icon)} />
											) : null}
											{ReactHtmlParser(item.title)}
										</a>
									</li>
								);
							} else {
								return (
									<li key={`${item.id} ${i}`} className={listIcon !== '' ? 'icon-indent' : ''}>
										<EventsLink
											to={item.link}
											measureData={{
												...item,
												...measureData,
												itemType: item.type,
												itemTitle: item.title,
												itemId: item.id,
												actionType: 'homePageClick',
												which: `stories_col${measureNum}_item${i}`,
											}}>
											{item?.icon && item?.icon !== '' ? (
												<i className={getIconClass(item.icon)} />
											) : null}
											{ReactHtmlParser(item.title)}
										</EventsLink>
									</li>
								);
							}
						})}
					</ul>

					{!isEmpty(buttonData) ? (
						<div className="button full-col center">
							<ButtonGeneral
								data={{
									style: 'uso-button uppercase blue',
									title: buttonData.text,
									link: buttonData.link,
									measureData: {
										which: 'button',
										buttonText: buttonData.text,
										actionType: 'homepage_button',
										moduleType: props.data.type,
										link: buttonData.link,
										title: props.data.title,
									},
								}}
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default HomeInfo;
