import React, { Component } from 'react';
import GenericError from './GenericError';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false
		};
	}
	componentDidCatch(error, info) {
		this.setState({ hasError: true });
	}
	render() {
		if (this.state.hasError) {
			return <GenericError message={this.props.message} />;
		} else {
			return this.props.children;
		}
	}
}
export default ErrorBoundary;
