import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		// logger.log('[SlamtrackerWebview] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[SlamtrackerWebview] action.mount - data: %o', data);
			let data = {
				status: 'loaded',
				debug: getState().Router.hash == '#ibm' ? true : false,
			};

			dispatch({ type: deps.actionTypes.SLAMTRACKER_MOUNT, data: data });
		});
	},
	unmount: () => dispatch => {
		let data = {
			status: null,
		};
		dispatch({ type: deps.actionTypes.SLAMTRACKER_CHANGEVIEW, data: data });
	},
};
