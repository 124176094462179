/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import FanWeekSchedule from './FanWeekSchedule';
import deps from 'dependencies';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: FanWeekSchedule
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.FanWeekSchedule,
	EventsWindow: state['WindowSize'].EventsWindow,
	showFanWeekCalendar: state?.['Config']?.otherData?.showFanWeekCalendar,
	fanweekInfo: state['Config']?.otherData?.fanweekInfo,
	...props,
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.FanWeekSchedule.mount()),
	loadOverlayModal: (type, data) => dispatch(deps.actions.ModalOverlay.loadOverlayModal(type, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FanWeekSchedule);
