/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Loading Indicator
 * -----------------------------------------------------------------------------
 */
const handleOnClick = link => {
	logger.log('Chat Info: %o', link);
	let chatLink = link.split(':');
	let chatId = chatLink.length > 2 ? chatLink[2] : '21751';
	try {
		SatisfiApp.Global.chatPopupOpen();
	} catch (e) {
		window.open(
			'https://chat.satis.fi/?pageID=' + chatId,
			'USOchat',
			'resizable=no,status=no,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=no,dependent=no,width=375,height=406'
		);
	}
};

const renderWidgetButtons = (link, idx) => {
	return link.link.indexOf('javascript:chat') > -1 ? (
		<a
			key={idx}
			href="#"
			onClick={e => {
				e.preventDefault();
				handleOnClick(link.link);
				return false;
			}}>
			<span className="chat">Chat Icon</span> Chat
		</a>
	) : link.link.indexOf('tel') > -1 || link.link.indexOf('mailto') > -1 ? (
		<a key={idx} href={link.link}>
			<span className={link.link.indexOf('tel') > -1 ? `phone` : link.title.toLowerCase()}>
				{link.link.indexOf('tel') > -1 ? `Phone` : link.title} Icon
			</span>{' '}
			{link.title}
		</a>
	) : (
		<EventsLink key={idx} to={link.link}>
			<span className={link.link.indexOf('tel') > -1 ? `phone` : link.title.toLowerCase()}>
				{link.link.indexOf('tel') > -1 ? `Phone` : link.title} Icon
			</span>{' '}
			{link.title}
		</EventsLink>
	);
};

const TicketsWidget = props => {
	let classes = 'help-container';
	if (props.type) {
		classes = classes.concat(' ' + props.type);
	}

	logger.log('[TicketsWidget] props:%o', props);

	if (props.data && props.data.links) {
		logger.log('[TicketsWidget] render props %o', props);
		return (
			<div className={classes}>
				{Array.isArray(props.data.links.button)
					? props.data.links.button.map((link, i) => renderWidgetButtons(link, i))
					: renderWidgetButtons(props.data.links.button, 0)}
			</div>
		);
	} else {
		return null;
	}
};

export default TicketsWidget;
