/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import { DFPManager, AdSlot } from 'react-dfp';

/**
 * -----------------------------------------------------------------------------
 * React Component: AdTag
 * 
 * To use this component, you need to create a config in config_web.json
 * 
 * Config looks like this (just add a new one under mip):  Description of everything is in the constructor
 * 
 * "adConfig" : {
        "mip": {
            "dfpNetworkId":"5681",
            "sizeMapping":[ 
                {"viewport": [1024, 0], "sizes":[[970, 90]]},
                {"viewport": [768, 0], "sizes":[[728, 90]]},
                {"viewport": [0, 0], "sizes":[[320, 50]] }
            ],
            "shouldRefresh" : true,
            "sizes" : [[970,90],[728,90],[320,50]],
            "isResponsive" : true,
            "adUnit" : "2016_Desktop_ROS/leaderboard",
            "refreshRate" : 30000
        }
        "yourConfig": {
            ...
        }
    }
 * 
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['AdTag'],
		windowSize: state['Controller'].windowSize,
		adConfigData: state['Config'].adConfig,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	//mount: () => dispatch(deps.actions.AdTag.mount()),
});

class AdTag extends Component {
	constructor(props) {
		super(props);
		this.adConfig = this.props.adConfig || {};

		//logger.log('[adTag] adConfig:%o',this.adConfig);

		/* set some default values if there's no config */
		// this is the mapping of viewport sizes to ad sizes.
		this.sizeMapping = this.adConfig.sizeMapping || [
			{ viewport: [1024, 0], sizes: [[970, 90]] },
			{ viewport: [768, 0], sizes: [[728, 90]] },
			{ viewport: [0, 0], sizes: [[320, 50]] },
		];
		this.dfpNetworkId = this.props.dfpNetworkId;
		this.sizes = this.adConfig.sizes || [
			[970, 90],
			[728, 90],
			[320, 50],
		]; // these are the sizes that can be rendered in this slot.
		this.isResponsive = this.adConfig.isResponsive || false; // set to true if you want the ad to refresh on window size change
		(this.adUnit = this.adConfig.adUnit || '2016_Desktop_ROS/leaderboard'); // this is given to us
		
		(this.refreshRate = this.adConfig.refreshRate || 0); // if set, ad will refresh at this rate
		this.showAd = this.adConfig.showAd || false;

		this.collapse = true;
		if (this.adConfig.collapseEmptyDivs === false) {
			this.collapse = false;
		}

		this.ads_refresh = true; // if true, then we need to start the refresh timer.
		this.setAdViewable = this.setAdViewable.bind(this);
		this.randomKey = Math.floor(Math.random() * 10000000);
		this.slotId = `ad_${this.randomKey}`;

		// logger.log('[AdTag] constructor this:%o',this);

	}

	componentDidMount() {
		//this.props.mount();
		//this.props.mount();

		this.setState({
			mounted: true
		}, () => {
			// logger.log('[AdTag] componentDidMount - Timer Started: %o', this.slotId);

			// just log to see how many slots are already
			DFPManager.getGoogletag().then((googletag) => {
				googletag.cmd.push(() => {
					logger.log('[AdTag] componentDidMount slots:%o',googletag.pubads().getSlots());
				})
			})
			.catch((error) => {
				logger.log('[AdTag] componentDidMount googletag getSlots error:%o', error);
			})
		})
		
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[AdTag] componentDidUpdate refreshrate:%o', this.refreshRate);
		//logger.log('[AdTag] componentDidUpdate windowSize:%o', this.props.windowSize);

		if (prevProps.windowSize != this.props.windowSize && this.isResponsive) {
			// logger.log('[AdTag] componentDidUpdate windowSize changed:%o', this.props.windowSize);

			// logger.log(
			// 	'[AdTag] componentDidUpdate window size changed from %o to %o',
			// 	prevProps.windowSize,
			// 	this.props.windowSize
			// );
			this.refreshAd();
		}

		if (this.ads_refresh && this.refreshRate) {
			this.ads_refresh = false;

			if (this.adRefresh_timeout) {
				clearInterval(this.adRefresh_timeout);
			}

			this.adRefresh_timeout = setInterval(() => {
				this.refreshAd();
			}, this.refreshRate);
		}
	}

	componentWillUnmount() {
		// clear the refresh timeout
		clearInterval(this.adRefresh_timeout);
		this.destroyAdSlot(this.slotId);
	}

	destroyAdSlot(which) {
		DFPManager.getGoogletag().then((googletag) => {
			logger.log('[AdTag] destroyAdSlot googletag:%o', googletag);

			googletag.cmd.push(() => {
				logger.log('[AdTag] destroyAdSlot googletag destroy slot:%o', which);
				let slotList = 	googletag.pubads().getSlots();
				slotList.map((slot, index) => {
					//logger.log('[AdTag] destroyAdSlot googletag slot:%o, name:%o', slot, slot.getSlotElementId());
					if (slot.getSlotElementId() == which) {
						let isSlotDestroyed = googletag.destroySlots([slot]);
						logger.log('[AdTag] destroyAdSlot %o isSlotDestroyed:%o', which, isSlotDestroyed);
					}
				})
			});
		})
		.catch((error) => {
			logger.log('[AdTag] componentWillUnmount googletag destroySlots error:%o', error);

		});
	}

	setAdViewable(data) {
		// logger.log('[AdTag] setAdViewable data:%o', data);
		this.setState({
			isVisible: { slot: data.slotId, visibility: true },
		}, () => {
			// logger.log('[AdTag] setAdViewable isVisible:%o', this.state.isVisible);
		});
	}

	refreshAd() {
		//this.ads_refresh = true
		//logger.log('[AdTag] refreshAd() isVisible:%o', this?.props?.isVisible?.slot);

		// logger.log('[AdTag] refreshAd() isVisible:%o', this?.state?.isVisible);

		if (this.state?.isVisible?.slot && this.state?.isVisible?.visibility) {
			logger.log('[AdTag] refreshAd() slot:%o', this?.state?.isVisible?.slot);
			//DFPManager.refresh(this?.state?.isVisible?.slot); // this DFPManager.refresh doesn't seem to work

			// direct call to google to refresh the ad slot
			DFPManager.getGoogletag().then((googletag) => {
				logger.log('[AdTag] refreshAd googletag:%o', googletag);
				googletag.cmd.push(() => {
					let slotList = 	googletag.pubads().getSlots();
					slotList.map((slot, index) => {
						//logger.log('[AdTag] refreshAd googletag slot:%o, name:%o', slot, slot.getSlotElementId());
						if (slot.getSlotElementId() == this.slotId) {
							googletag.pubads().refresh([slot])
							logger.log('[AdTag] refreshAd googletag slot:%o', this.slotId);
						}
					})
				});
			})
			.catch((error) => {
				logger.log('[AdTag] googletag googletag error:%o', error);
	
			});
		}
	}

	render() {
		// logger.log('[AdTag] render this:%o', this);
		return this.showAd ? (
			<ErrorBoundary message="Ad unavailable">
					<AdSlot
						key={`${this.slotId}_${this.props.windowSize}`}
						dfpNetworkId="5681"
						slotId={this.slotId}
						sizes={this.sizes}
						sizeMapping={this.sizeMapping}
						adUnit={this.adUnit}
						onSlotIsViewable={dfpEventData => this.setAdViewable(dfpEventData)}
						//shouldRefresh={() => true}
						//shouldRefresh={() => false}
						shouldRefresh={() => (this.refreshRate > 0 ? true : false)}
					/>
			</ErrorBoundary>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdTag);
