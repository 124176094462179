/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

import EventInfo from './elements/EventInfo';
import EventInfoStatus from './elements/EventInfoStatus';
import EventCourt from './elements/EventCourt';
import MatchDuration from './elements/MatchDuration';
import TeamNameFlag from './elements/TeamNameFlag';
import TeamOneIcon from './elements/TeamOneIcon';
import TeamTwoIcon from './elements/TeamTwoIcon';
import TeamResults from './elements/TeamResults';

import { getAppLink, getCleanLink } from 'appdir/components/general/Util';
import {
	matchHasInsights,
	matchIsFutureState,
	matchIsLiveState,
	matchIsCompleteState,
	determinePlayerHighlightClass,
} from 'appdir/components/pages/SchedulePage/ScheduleUtils';
import { checkSuppressProfileLink } from './MatchBoxUtils';

import { isDoublesMatch } from 'appdir/components/pages/Slamtracker/Utilities';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: ScoreTable
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['MatchBox'],
		favorites: state['Controller'].favorites,
		miMatches: state['Controller'].miMatches,
		showSTPanel: op.get(state, 'Config.scoring.showSTPanel', false),
		stPanelWindowSize: op.get(state, 'Config.scoring.stPanelWindowSize', 0),
		windowSizeVal: state['Controller'].windowSizeVal,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchBox.mount()),
	setGlobalLink: data => dispatch(deps.actions.Controller.setGlobalLink(data)),
	setSTPanelStatus: data => dispatch(deps.actions.Controller.setSTPanelStatus(data)),
});

class SumscoresMatchBox extends Component {
	constructor(props) {
		// logger.log("[SumscoresMatchBox] constructor");
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.mount();
	}

	componentWillUnmount() {
		clearTimeout(this.t1_timer);
		clearTimeout(this.t2_timer);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.MatchBoxConfig) {
			this.props.mount();
		}
	}

	openSTPanel(matchid, status) {
		this.props.setSTPanelStatus({ open: true, matchid: matchid, matchstatus: status });
	}

	onLinkClick = (e, path) => {
		logger.log('[SumscoresMatchBox] e:%o, path:%o', e.target, path);
		e.preventDefault();

		//window.parent.location.href = this.props.MatchBoxConfig.site+path;

		if (typeof webview !== 'undefined') {
			let link = getAppLink(path, '');
			location.href = link.url;
		} else if (path) {
			let link = getCleanLink(path);

			this.props.setGlobalLink({
				globalLink: {
					link: link.url,
					query: 'promo=sumscores',
				},
			});

			//window.top.location.href = path;
		}
	};

	getTeamData(which) {
		// logger.log('[MatchBox] sumscores - this:%o', this);
		let { attributes } = this.props;
		let scoreData = attributes.data;
		let schedule = attributes?.mode == 'schedule' ? true : false;
		let draws = attributes?.mode == 'draws' ? true : false;
		let teamInfo;

		if (which == 'one') {
			if (attributes?.matchType == 'upcoming') {
				teamInfo = scoreData.team1[0];
			} else {
				teamInfo = scoreData.team1;
			}
		} else if (which == 'two') {
			if (attributes?.matchType == 'upcoming') {
				teamInfo = scoreData.team2[0];
			} else {
				teamInfo = scoreData.team2;
			}
		}
		let scoreIndex = which == 'one' ? '0' : '1';
		let suppressProfileLink_a = checkSuppressProfileLink(attributes, teamInfo.idA);
		let suppressProfileLink_b = checkSuppressProfileLink(attributes, teamInfo.idB);

		let is_tbd = this.props.attributes?.matchType == 'upcoming' && teamInfo.length > 1 ? true : false;

		let iconClasses = '';
		let animationClass = false;
		let winnerClass = '';
		let latestClass = '';
		let emptySets = [];

		if (matchIsFutureState(scoreData)) {
			iconClasses = 'crticon';
		} else {
			if (which == 'one') {
				iconClasses = classNames(
					'crticon',
					(scoreData.server == 'A' || scoreData.server == 'B') &&
						!scoreData.team1.won &&
						!scoreData.team2.won &&
						matchIsLiveState(scoreData) &&
						!schedule &&
						!draws
						? 'currentServer'
						: '',
					scoreData.team1.won ? 'winner' : ''
				);

				if (matchIsCompleteState(scoreData)) {
					winnerClass = classNames(scoreData.team1.won ? 'winner' : 'loser');
				}
				latestClass = this.state.lastScore_t1 && matchIsLiveState(scoreData) ? ' latest' : '';
			} else {
				iconClasses = classNames(
					'crticon',
					(scoreData.server == 'C' || scoreData.server == 'D') &&
						!scoreData.team1.won &&
						!scoreData.team2.won &&
						matchIsLiveState(scoreData) &&
						!schedule &&
						!draws
						? 'currentServer'
						: '',
					scoreData.team2.won ? 'winner' : ''
				);

				if (matchIsCompleteState(scoreData)) {
					winnerClass = classNames(scoreData.team2.won ? 'winner' : 'loser');
				}
				latestClass = this.state.lastScore_t2 && matchIsLiveState(scoreData) ? ' latest' : '';
			}
		}

		for (var i = 0; i < 5 - scoreData?.scores?.sets?.length && scoreData?.scores?.sets?.length < 5; i++) {
			emptySets[i] = 5 - i;
		}

		emptySets.reverse();

		let dataObj = {
			team: which,
			teamInfo: teamInfo,
			scoreIndex: scoreIndex,
			suppressProfileLink_a: suppressProfileLink_a,
			suppressProfileLink_b: suppressProfileLink_b,
			iconClasses: iconClasses,
			isTbd: is_tbd,
			iconClasses: iconClasses,
			winnerClass: winnerClass,
			latestClass: latestClass,
			emptySets: emptySets,
		};

		return dataObj;
	}

	render() {
		let { attributes } = this.props;
		let scoreData = attributes.data;
		let boxStyle = attributes.style ? attributes.style : '';
		let boxMode = this.props.attributes.mode || '';
		let isDoubles = scoreData ? isDoublesMatch(scoreData) : false;
		let cta = attributes?.cta;
		let buttonText = !cta?.text ? 'Match Details' : cta.text;
		let schedule = this.props.attributes?.mode == 'schedule' ? true : false;
		let draws = this.props.attributes?.mode == 'draws' ? true : false;
		let useSTPanelLinks =
			this.props.showSTPanel && !window.webview && this.props.windowSizeVal >= this.props.stPanelWindowSize
				? true
				: false;

		/**
		 * RLL 8/23/2022
		 *
		 * Matchbox button configuration
		 *
		 * stLive: variable to let us know slamtracker and slamtracker pages are active
		 * useSTPanelLinks:
		 * 	- checks showSTPanel (should we show the slamtracker panel) value
		 * 	- checks window.webview (for us open 2022 panel is not used on webviews) value
		 *  - compares windowSizeVal with stPanelWindowSize (the minimum window size to show the slamtracker panel)
		 */

		//logger.log('[SumscoresMatchBox] render - scoreData:%o  matchType:%o', scoreData, attributes.matchType);
		if (scoreData) {
			// logger.log('[SumscoresMatchBox] this:%o',this);
			let showLinks = attributes.showLinks;

			let { uom } = this.props;

			let t1_data = attributes.matchType == 'upcoming' ? scoreData.team1[0] : scoreData.team1;
			let t2_data = attributes.matchType == 'upcoming' ? scoreData.team2[0] : scoreData.team2;

			//stats level only set after match complete, so won't allow active pre/during match
			let active = t1_data.idA && t2_data.idA;
			//let slamtrackerActive = (active && scoreData.statsLevel !== null && scoreData.statsLevel !== 'N');
			let slamtrackerActive = active && scoreData.courtId && this.props?.MatchBoxConfig?.stLive;

			//logger.log('[SumscoresMatchBox] render - match:%o  this:%o',scoreData.match_id, this);

			let team1Data = this.getTeamData('one');
			let team2Data = this.getTeamData('two');

			return this.props.MatchBoxConfig ? (
				<div className="match-box-container">
					<div
						className={`match-box match-box-sumscores ${boxStyle}`}
						data-match={scoreData.match_id}
						data-event={scoreData.eventCode}
						data-court={scoreData.courtId}>
						<div className={`match-status ${boxStyle}`}>
							<div className="event-info">
								<EventInfo
									key={`match-${scoreData.match_id}-EventInfo`}
									matchId={scoreData.match_id}
									event={this.props.attributes?.event || null}
									isFeaturedMatch={false}
									round={this.props.attributes?.round || null}
									roundName={this.props.attributes?.data?.roundName || null}
									roundNameShort={this.props.attributes?.data?.roundNameShort || null}
									comments={scoreData?.comment || null}
									style={boxStyle}
									eventCode={scoreData?.eventCode || null}
									mode={boxMode}
								/>
								<EventInfoStatus
									key={`match-${scoreData.match_id}-EventInfoStatus`}
									data={{
										attributes: this.props.attributes,
										uom: uom,
										sumscores: true,
									}}
								/>
							</div>
							<div className="match-box-border">
								<div className="event-court">
									<EventCourt
										key={`match-${scoreData.match_id}-EventCourt`}
										matchId={scoreData.match_id}
										isFinal={this.props?.attributes?.isFinal || false}
										sumscores={true}
										eventName={this.props?.attributes?.data?.eventName || ''}
										courtName={this.props?.attributes?.data?.courtName || ''}
									/>
									<MatchDuration
										key={`match-${scoreData.match_id}-MatchDuration`}
										matchId={scoreData.match_id}
										duration={scoreData.duration}
									/>
								</div>
								{/* team one starts */}
								<div
									className={`team-info team-${team1Data.team} ${isDoubles ? 'doubles' : 'singles'}
										${team1Data.winnerClass}
										${determinePlayerHighlightClass(
											team1Data.teamInfo.idA,
											!window.webview ? this.props?.favorites : this.props?.appFavs,
											this.props.attributes?.searchedPlayer,
											this.props?.appShowFavs
										)}
										${determinePlayerHighlightClass(
											team1Data.teamInfo.idB,
											!window.webview ? this.props?.favorites : this.props?.appFavs,
											this.props.attributes?.searchedPlayer,
											this.props?.appShowFavs
										)}
									`}>
									<div className="team-name">
										<div
											onClick={
												!team1Data.suppressProfileLink_a &&
												!team1Data.isTbd &&
												team1Data.teamInfo.idA
													? e => {
															this.onLinkClick(
																e,
																`/en_US/players/overview/${team1Data.teamInfo.idA}.html`
															);
													  }
													: null
											}
											className={`member-one ${
												!window.webview &&
												this.props?.favorites?.show &&
												this.props?.favorites?.players.indexOf(team1Data.teamInfo.idA) !== -1
													? 'favorite'
													: ''
											}`}>
											<TeamNameFlag
												key={`match-${scoreData}-TeamNameFlag-team-${team1Data.team}-player1`}
												matchId={scoreData.match_id}
												flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
												name={team1Data.teamInfo.displayNameA}
												country={
													!Array.isArray(team1Data.teamInfo.nationA)
														? team1Data.teamInfo.nationA
														: team1Data.teamInfo.nationA[0]
												}
												seed={team1Data.teamInfo.seed}
												isTbd={team1Data.isTbd}
											/>
										</div>
										{isDoubles ? (
											<div
												onClick={
													!team1Data.suppressProfileLink_b &&
													!team1Data.isTbd &&
													team1Data.teamInfo.idB
														? e => {
																this.onLinkClick(
																	e,
																	`/en_US/players/overview/${team1Data.teamInfo.idB}.html`
																);
														  }
														: null
												}
												className={`member-two ${
													!window.webview &&
													this.props?.favorites?.show &&
													this.props?.favorites?.players.indexOf(team1Data.teamInfo.idB) !==
														-1
														? 'favorite'
														: ''
												}`}>
												<TeamNameFlag
													key={`match-${scoreData.match_id}-TeamNameFlag-team-${team1Data.team}-player2`}
													matchId={scoreData.match_id}
													flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
													name={team1Data.teamInfo.displayNameB}
													country={
														!Array.isArray(team1Data.teamInfo.nationB)
															? team1Data.teamInfo.nationB
															: team1Data.teamInfo.nationB[0]
													}
													seed={team1Data.teamInfo.seed}
													isTbd={team1Data.isTbd}
												/>
											</div>
										) : null}
									</div>

									{boxStyle != 'upcoming' ? (
										<div className="match-results">
											<div className="results-wrapper">
												<TeamOneIcon
													key={`match-${scoreData.match_id}-TeamOneIcon-team-${team1Data.team}`}
													matchId={scoreData.match_id}
													iconClasses={team1Data.iconClasses}
													useAnimation={false}
													serveSpeed={scoreData.last_serve_speed}
													team={team1Data.team}
												/>
												<TeamResults
													key={`match-${scoreData.match_id}-TeamResults-team-${team1Data.team}`}
													matchId={scoreData.match_id}
													data={{
														attributes: this.props.attributes,
														scoreIndex: team1Data.scoreIndex,
														sumscores: true,
														schedule: schedule,
														draws: draws,
														latestClass: team1Data.latestClass,
														scores: scoreData.scores,
														emptySets: team1Data.emptySets,
													}}
												/>
											</div>
										</div>
									) : null}
								</div>
								{/* team one ends */}
								{/* team two starts */}
								<div
									className={`team-info team-${team2Data.team} ${isDoubles ? 'doubles' : 'singles'}
										${team2Data.winnerClass}
										${determinePlayerHighlightClass(
											team2Data.teamInfo.idA,
											!window.webview ? this.props?.favorites : this.props?.appFavs,
											this.props.attributes?.searchedPlayer,
											this.props?.appShowFavs
										)}
										${determinePlayerHighlightClass(
											team2Data.teamInfo.idB,
											!window.webview ? this.props?.favorites : this.props?.appFavs,
											this.props.attributes?.searchedPlayer,
											this.props?.appShowFavs
										)}
									`}>
									<div className="team-name">
										<div
											onClick={
												!team2Data.suppressProfileLink_a &&
												!team2Data.isTbd &&
												team2Data.teamInfo.idA
													? e => {
															this.onLinkClick(
																e,
																`/en_US/players/overview/${team2Data.teamInfo.idA}.html`
															);
													  }
													: null
											}
											className={`member-one ${
												!window.webview &&
												this.props?.favorites?.show &&
												this.props?.favorites?.players.indexOf(team2Data.teamInfo.idA) !== -1
													? 'favorite'
													: ''
											}`}>
											<TeamNameFlag
												key={`match-${scoreData.match_id}-TeamNameFlag-team-${team2Data.team}-player1`}
												matchId={scoreData.match_id}
												flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
												name={team2Data.teamInfo.displayNameA}
												country={
													!Array.isArray(team2Data.teamInfo.nationA)
														? team2Data.teamInfo.nationA
														: team2Data.teamInfo.nationA[0]
												}
												seed={team2Data.teamInfo.seed}
												isTbd={team2Data.isTbd}
											/>
										</div>
										{isDoubles ? (
											<div
												onClick={
													!team2Data.suppressProfileLink_b &&
													!team2Data.isTbd &&
													team2Data.teamInfo.idB
														? e => {
																this.onLinkClick(
																	e,
																	`/en_US/players/overview/${team2Data.teamInfo.idB}.html`
																);
														  }
														: null
												}
												className={`member-two ${
													!window.webview &&
													this.props?.favorites?.show &&
													this.props?.favorites?.players.indexOf(team2Data.teamInfo.idB) !==
														-1
														? 'favorite'
														: ''
												}`}>
												<TeamNameFlag
													key={`match-${scoreData.match_id}-TeamNameFlag-team-${team2Data.team}-player2`}
													matchId={scoreData.match_id}
													flagPath={this.props.MatchBoxConfig.flagImagePathSmall}
													name={team2Data.teamInfo.displayNameB}
													country={
														!Array.isArray(team2Data.teamInfo.nationB)
															? team2Data.teamInfo.nationB
															: team2Data.teamInfo.nationB[0]
													}
													seed={team2Data.teamInfo.seed}
													isTbd={team2Data.isTbd}
												/>
											</div>
										) : null}
									</div>
									{boxStyle != 'upcoming' ? (
										<div className="match-results">
											<div className="results-wrapper">
												<TeamTwoIcon
													key={`match-${scoreData.match_id}-TeamTwoIcon-team-${team2Data.team}`}
													matchId={scoreData.match_id}
													iconClasses={team2Data.iconClasses}
													useAnimation={team1Data.useAnimation}
													serveSpeed={scoreData.last_serve_speed}
													team={team2Data.team}
												/>
												<TeamResults
													key={`match-${scoreData.match_id}-TeamResults-team-${team2Data.team}`}
													matchId={scoreData.match_id}
													data={{
														attributes: this.props.attributes,
														scoreIndex: team2Data.scoreIndex,
														sumscores: true,
														schedule: schedule,
														draws: draws,
														latestClass: team2Data.latestClass,
														scores: scoreData.scores,
														emptySets: team2Data.emptySets,
													}}
												/>
											</div>
										</div>
									) : null}
								</div>
								{/* team two ends */}
							</div>

							{!this.props.MatchBoxConfig.stLive && cta?.url && cta?.text ? (
								<div className="match-box-buttons">
									<div
										className={`slamtracker-button blue-button ${cta.status} ${
											!slamtrackerActive ? 'disabled' : ''
										}`}>
										<div className="slamtracker-button_text">
											{cta.text}
											<div className="arrow-icon">
												<i className="icon-arrow-right" />
												<i className="icon-arrow-right arrow" />
											</div>
										</div>
									</div>
								</div>
							) : null}
							{showLinks && this.props.MatchBoxConfig.stLive && !useSTPanelLinks ? (
								<div className="match-box-buttons">
									<div
										className={`slamtracker-button blue-button ${cta.status} ${
											!cta?.url &&
											(!slamtrackerActive ||
												!matchHasInsights(this.props.miMatches, scoreData.match_id))
												? 'disabled'
												: ''
										}`}
										onClick={e => {
											this.onLinkClick(e, cta?.url);
										}}>
										<div className="slamtracker-button_text">
											{buttonText}
											<div className="arrow-icon">
												<i className="icon-arrow-right" />
												<i className="icon-arrow-right arrow" />
											</div>
										</div>
									</div>
								</div>
							) : null}

							{showLinks && this.props.MatchBoxConfig.stLive && useSTPanelLinks ? (
								<div className="match-box-buttons">
									<div
										className={`slamtracker-button blue-button ${cta.status} ${
											!slamtrackerActive ? 'disabled' : ''
										}`}
										onClick={e => {
											this.openSTPanel(scoreData.match_id, cta.status);
										}}>
										<div className="slamtracker-button_text">
											{buttonText}
											<div className="arrow-icon">
												<i className="icon-arrow-right" />
												<i className="icon-arrow-right arrow" />
											</div>
										</div>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			) : null;
		} else {
			return <div className="match-box-container">No Match Data</div>;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SumscoresMatchBox);
