/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: HeroButtons
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const HeroButtons = props => {
	logger.log('[HeroButtons] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	logger.log('[HeroButtons] - attr:%o', attributes);

	if (attributes && attributes.images) {
		let image = attributes['images']['image'];
		if (attributes['buttons']) {
			let buttons = attributes['buttons'];

			return (
				<section className={`hero_container buttons`} style={{ backgroundImage: `url(${image.url})` }}>
					<div className={`hero_content`}>
						<div className={`hero_buttons`}>
							<div className={`landing_title`}>
								<h1>{attributes['title']}</h1>
								{image.caption}
							</div>

							{attributes['buttons'].button.map((item, i) => (
								<div className={`hero_button hero_button_count_3`} key={i}>
									<EventsLink to={item.link}>
										<div className={`navItem`}>
											{item.title}
											<div className="arrow-icon">
												<i className="icon-arrow-right" />
												<i className="icon-arrow-right arrow" />
											</div>
										</div>
									</EventsLink>
								</div>
							))}
						</div>
					</div>
				</section>
			);
		} else if (image) {
			return (
				<section className={`heroSection`}>
					<div className={`heroImage desktop`}>
						<div className={`heroContainer`}>
							<img src={image.url} alt={image.caption} style={{ zIndex: 0 }} />
							<div className={`heroInfo`}>
								<h1>{attributes['title']}</h1>
							</div>
						</div>
						<div className={`border`} />
					</div>
				</section>
			);
		} else {
			return <h1 className="header">{attributes['title']}</h1>;
		}
	} else if (attributes) {
		return <h1 className="header">{attributes['title']}</h1>;
	} else {
		return null;
	}
};

export default HeroButtons;
