import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		logger.log('[PlayerFilter] mount - data');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			const flagImagePathLarge = Config.otherData.flagImagePathLarge;
			const playerProfileImagePath = Config.otherData.playerImagePath;
			const countries = Config.scoringData.countries;
			dispatch({
				type: deps.actionTypes.PLAYERFILTERS_MOUNT,
				data: { flagImagePathLarge, playerProfileImagePath, countries },
			});
		});
	},
	filterCountry: country => dispatch => {
		dispatch({
			type: deps.actionTypes.PLAYERFILTERS_COUNTRY,
			data: { countryPlayers: country },
		});
	},
	filterGender: gender => dispatch => {
		dispatch({
			type: deps.actionTypes.PLAYERFILTERS_GENDER,
			data: { seedGender: gender },
		});
	},
};
