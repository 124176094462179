/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MatchBox from 'appdir/components/_scoring/MatchBox';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import StubBox from 'appdir/components/common-ui/StubBox';
import { windowSizeVals } from 'appdir/components/general/Util';
import AdTag from 'appdir/components/general/AdTag';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: EdgeConnectTest
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['EdgeConnectTest'],
		ScoreManager: state['ScoreManager'],
		scoring: state['Controller'].scoring,
		controllerLoaded: state['Controller'].loaded,
		stubs: state['Config'].stubPages,
		windowSize: state['Controller'].windowSize,
		windowSizeVal: state['Controller'].windowSizeVal,
		adConfig: state['Config'].adConfig,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.EdgeConnectTest.mount()),
	unmount: () => dispatch(deps.actions.EdgeConnectTest.unmount()),
	setScoringStatus: data => dispatch(deps.actions.Controller.setScoringStatus(data)),
});

class EdgeConnectTest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};

		this.init = true;
		this.ads_refresh = true;

		logger.log('[EdgeConnectTest] constructor this:%o', this);
	}

	componentDidMount() {
		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'EdgeConnectTest',
		});
	}

	componentDidUpdate(prevState) {
		if (this.props.stubs) {
			if (
				this.init &&
				this.props.status == 'load' &&
				this.props.controllerLoaded &&
				this.props.stubs &&
				this.props.stubs.scores !== 'stub'
			) {
				logger.log('[EdgeConnectTest] componentDidUpdate dispatch setScoringStatus');
				this.init = false;
				this.props.setScoringStatus({ mip: true });
			} else if (this.props.scoring && !this.props.scoring.mip && this.props.stubs.scores !== 'stub') {
				this.props.setScoringStatus({ mip: true });
			}
		}
		if (this.props.scoringErrors) {
			logger.error('[EdgeConnectTest] componentDidUpdate scoringError: %o', this.props.scoringErrors);
			this.setState({
				hasError: true,
			});
		}
	}

	componentWillUnmount() {
		// sending mip:true tells controller that we are turning mip page off
		//this.props.setScoringStatus({ mip: false });
		clearTimeout(this.adRefresh_timeout);
		this.props.unmount();
	}

	renderMatch(match) {
		// logger.log("[EdgeConnectTest] renderMatch - match:%o ", match);
		if (match.scores) {
			return (
				<div key={match.match_id} className="one-col">
					<MatchBox
						attributes={{
							data: match,
							style: 'live',
							event: match.shortEventName,
							tableHeader: match.courtName,
							showLinks: true,
							liveVideo: true,
						}}
					/>
				</div>
			);
		} else {
			return null;
		}
	}

	/**
	 * This function splits an array up into equal size arrays of your choosing
	 * @param {*} arr - array to chunk
	 * @param {*} size - size you want to chunk it to
	 */
	chunkArray(arr, size) {
		let chunked = [];
		for (let ele of arr) {
			let last = chunked[chunked.length - 1];
			if (!last || last.length === size) {
				chunked.push([ele]);
			} else {
				last.push(ele);
			}
		}
		return chunked;
	}

	render() {
		logger.log('[EdgeConnectTest] render - this:%o', this);

		let header_propsData = {
			headerType: 'scores',
			title: 'Edge Connect Test',
			metaTitle: 'Edge Connect Test',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		let subheader_attributes = {
			breadcrumbs: [],
			sponsor: {
				link: 'http://www.ibm.com/sports/usopen',
				title: 'Presented by',
				name: 'IBM',
				imgClass: '',
				imgSrc: '/assets/images/logos/ibm_logo_black.png',
				tagline: 'Presented by',
			},
			title: 'Live Scores',
		};
		let { liveMatches } = this.props.ScoreManager;

		if (this.props.stubs && this.props.stubs.scores.stub === 'stub') {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: header_propsData.title,
								message: this.props.stubs.scores.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (this.state.hasError) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: header_propsData.title,
								subTitle: '',
								message: 'Live scores are unavailable at this time',
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else if (liveMatches && liveMatches.length > 0 && this.props.windowSize) {
			let chunkedMatches;
			if (this.props.windowSizeVal == windowSizeVals.MOBILE_WINSIZE) {
				chunkedMatches = this.chunkArray(liveMatches, 2);
			} else if (this.props.windowSizeVal <= windowSizeVals.SMDESKTOP_WINSIZE) {
				chunkedMatches = this.chunkArray(liveMatches, 4);
			} else if (this.props.windowSizeVal >= windowSizeVals.DESKTOP_WINSIZE) {
				chunkedMatches = this.chunkArray(liveMatches, 6);
			}
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<h1 className="header" style={{ marginBottom: '0px' }}>
							Edge Connect Test
						</h1>
						<ErrorBoundary message="Live Scores are unavailable at this time.">
							<div className="EdgeConnectTest-content">
								{chunkedMatches.map((chunk, index) => (
									<React.Fragment key={`frag${index}`}>
										<div className="column-layout scoresgrid">
											{chunk.map((item, index2) => this.renderMatch(item))}
										</div>
										{index < 3 &&
										((this.props.windowSizeVal >= windowSizeVals.DESKTOP_WINSIZE &&
											(chunk.length > 3 || liveMatches.length <= 6)) ||
											(this.props.windowSizeVal == windowSizeVals.MOBILE_WINSIZE &&
												chunk.length > 0) ||
											(this.props.windowSizeVal !== windowSizeVals.DESKTOP_WINSIZE &&
												this.props.windowSizeVal !== windowSizeVals.MOBILE_WINSIZE &&
												(chunk.length > 2 || liveMatches.length <= 4))) ? (
											<div className="mip-ad-container">
												<AdTag
													adConfig={this.props.adConfig.mip}
													dfpNetworkId={this.props.adConfig.dfpNetworkId}
												/>
											</div>
										) : null}
									</React.Fragment>
								))}
							</div>
						</ErrorBoundary>
					</section>
				</Template>
			);
		} else if (liveMatches && liveMatches.length == 0 && this.props.stubs) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<StubBox
							attributes={{
								title: header_propsData.title,
								message: this.props.stubs.nomatches.text,
								basePicPath: this.props.basePicPath,
								subTitle: 'There are currently no matches in play.',
							}}
						/>
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper scorespage">
						<div className="content-main">
							<LoadingIndicator />
						</div>
					</section>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EdgeConnectTest);
