import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let playerConfig = Config.playerConfig;
			dispatch({
				type: deps.actionTypes.VIDEOPLAYER_MOUNT,
				data: { playerConfig },
			});
		});
		// dispatch({ type: deps.actionTypes.ADTAG_MOUNT, data: { status: "load" } });
	},
	unmount: () => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.VIDEOPLAYER_UNMOUNT,
			data: {},
		});
	},
	currentlyPlaying: (id, player) => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.VIDEOPLAYER_CURRENTLY_PLAYING,
			data: { id, player },
		});
	},
	autoplayToggle: toggle => (dispatch, getState, store) => {
		dispatch({
			type: deps.actionTypes.VIDEOPLAYER_AUTOPLAY,
			data: { auto: toggle },
		});
	},
	update: data => (dispatch, getState, store) => {
		// logger.log('[VideoPlayer] actions - update:  data:%o', data);
		dispatch({ type: deps.actionTypes.VIDEOPLAYER_UPDATE, data: data });
	},
};
