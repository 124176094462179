import PlayerSearch from './index';
import deps from 'dependencies';

export default {
	path: '/:lang(en_US)/players/search.html:type?',
	exact: true,
	component: PlayerSearch,
	section: 'Players',
	load: params => deps.actions.PlayerSearch.mount(params),
};
