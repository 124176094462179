import React from 'react';
import EventsLink from 'components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Hole
 * -----------------------------------------------------------------------------
 */

const ArticleCard = props => {
	let { data } = props;
	return (
		<div className="item media-card" data-type="article">
			<EventsLink to={data.link}>
				<div className="image">
					<img src={data.image.src} alt={data.image.alt} />
					<div className="caption">
						<div className="info">{'news'}</div>
						<div className="title">{data.caption.title}</div>
					</div>
				</div>
			</EventsLink>
		</div>
	);
};

export default ArticleCard;

ArticleCard.defaultProps = {
	data: {
		link: 'http://www.masters.com/en_US/news/articles/2018-04-06/major_players_mcilroy_spieth_tied_at_4under.html',
		image: {
			alt: 'Article - This img alt',
			src: 'http://www.masters.com/images/pics/large/h_2018040626988.jpg',
		},
		caption: {
			date: '1523150028651',
			title:
				'Garcia Outlasts Rose to Claim First Major Victory he is the greatest of all time to win. needs to wrap more lines so hat we can show the ellipsis stuff work.  this is so unrealistic.',
			info: 'Jane Doe',
		},
	},
};
