import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEPRACTICE_MOUNT });

		logger.log('[LivePractice] action.mount - params: %o', data.channel);

		switch (data.channel) {
			case 'p1':
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Practice Courts',
				});
				break;
			case 'p2':
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Practice Courts',
				});
				break;
			case 'p3':
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Practice Courts',
				});
				break;
			case 'p4':
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Practice Courts',
				});
				break;
			case 'p5':
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Practice Courts',
				});
				break;
			default:
				break;
		}
		//logger.log('[LivePractice] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let values = {
				selected: data.channel,
				basePicPath: Config.otherData.basePicPath,
			};
			logger.log('[LivePractice] action.mount - data%o', values);
			dispatch({ type: deps.actionTypes.LIVEPRACTICE_MOUNT, data: values });
		});
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEPRACTICE_UNMOUNT });
	},

	select: selected => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEPRACTICE_SELECT, data: selected });
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEPRACTICE_FILTER, data: data });
	},
};
