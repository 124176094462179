/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Likelihood to Win bnnner
 *
 *  props = {
 *			   direction --- "left" or "right" or center
 *           }
 *
 * -----------------------------------------------------------------------------
 */
const LikelihoodWinBanner = props => {
	// logger.log('[MITLikelihoodWinBannereaser] props:%o', props);

	let { direction } = props;
	let poweredByText = true;
	direction = direction == 'left' || direction == 'right' || direction == 'center' ? direction : 'right'; //testing purposes
	if(direction == 'center') {
		poweredByText == false;
	}

	return (
		<div className="LikelihoodToWinBanner">
			<div className={`likelihood-win-btn ${direction} ${poweredByText === true ? 'powered-by' : ''}`}>
				{/* <span className="watson-logo"></span> Watson */}
				Likelihood to Win
			</div>
		</div>
	);
};

export default LikelihoodWinBanner;
