import { measureInApp } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';

export const doMeasurement = (pageTitle, measureAction, measure_args, contextData) => {
	let measureArray = [];

	if (window.webview) {
		measureArray = ['Metrics', pageTitle].concat(measureAction);

		/** avoid sending an empty value in measure_args */
		if (measure_args && measure_args?.length > 0) {
			measureArray = measureArray.concat(measure_args);
		}
		//awt:  remove join for usta only
		//.join(':');
		logger.log('[doMeasurement] measureArray:%o', measureArray);

		/** contextData is new in 2022 for webviews -
		 * passing this will add query strings &key=value to measureInApp
		 * @Array contextData - [{key: value}, {key:value}, {key:value}] */
		measureInApp(measureArray, contextData);
	} else {
		//measureArray = [pageTitle].concat(measureAction).concat(measure_args);
		MeasurementUtils.dispatchMeasurementCall(measureAction, {
			data: measure_args,
		});
	}
};

export const hasInsights = (matches = [], matchId) => {
	return matches.indexOf(matchId) !== -1;
};

export const isFavorite = (favorites = [], playerId) => {
	return favorites.indexOf(favorites) !== -1;
};
