import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	//logger.log("[PAGESUBMENU] reducer - action: %o", action);

	switch (action.type) {
		case deps.actionTypes.PAGESUBMENU_ERROR:
			// logger.log('[PAGESUBMENU] reducer.PAGESUBMENU_ERROR - action:%o', action);
			newState = Object.assign({}, state, {
				status: 'error',
				error: action.error,
			});
			return newState;

		case deps.actionTypes.PAGESUBMENU_MOUNT:
			// logger.log('[PAGESUBMENU] reducer.PAGESUBMENU_MOUNT: action:%o', action);
			newState = {
				...state,
				...action.data,
			};

			// logger.log('[PAGESUBMENU] reducers PAGESUBMENU_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.PAGESUBMENU_UNMOUNT:
			// logger.log('[PAGESUBMENU] reducer.PAGESUBMENU_UNMOUNT: action:%o', action);
			newState = {
				...state,
				...action.data,
			};

			// logger.log('[PAGESUBMENU] reducers PAGESUBMENU_UNMOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.PAGESUBMENU_FILTER:
			// logger.log('[PAGESUBMENU] PAGESUBMENU_FILTER - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};
			// logger.log('[PAGESUBMENU] PAGESUBMENU_FILTER - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
