import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		// logger.log("[MatchInsightsModal] action.mount - params: %o", params);

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			dispatch({ type: deps.actionTypes.MATCHINSIGHTSMODAL_MOUNT, data: { configLoaded: true } });
		});
	},
	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.MATCHINSIGHTSMODAL_UNMOUNT, data: { configLoaded: false } });
	},
};
