import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from 'components/Template';

const mapStateToProps = (state, props) => {
	return {
		...state['OpenQuestionsTerms'],
		...props,
	};
};

class OpenQuestionsTerms extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let header_attributes = {
			headerType: 'visit',
			title: 'Open Questions',
			metaTitle: 'Open Questions ',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'Home',
				},
			],
			title: 'Open Questions Terms of Use',
		};
		return (
			<Template header={header_attributes} subHeader={subheader_attributes} hideAd={true}>
				<h1 className="header">Open Questions Terms of Use</h1>
				<section className="open-questions terms general_content">
					<div className="siteDetailContainer">
						<div className="siteDetail">
							<p>
								By posting comments herein, You, as author of the comments, are giving Receivers an
								irrevocable, perpetual license to all comments posted. The license granted to Receivers
								does not extend to use of your name or likeness. Receivers may adapt, use, summarize or
								share your submitted comments freely for any purpose. This website is provided on an “as
								is” basis, without warranties of any kind. Receivers not liable for any claim arising
								from the use of this website. You are responsible for any content you upload, including
								compliance with applicable laws, rules and regulations. Your content will not be
								obscene, offensive, harassing or discriminatory. Receivers, at their sole discretion,
								may remove or take down some or all of your content. For purposes of this paragraph,
								Receivers are any employees, contractors or agents of IBM or the USTA.
							</p>
						</div>
					</div>
				</section>
			</Template>
		);
	}
}
export default connect(mapStateToProps)(OpenQuestionsTerms);
