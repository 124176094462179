import axios from 'axios';
import { values } from 'appdir/main';
import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import { Validator } from 'jsonschema';

const schema = require('appdir/components/data/Schema/index.js');
const v = new Validator();

axios.interceptors.request.use(
	function(config) {
		//logger.log('[Config] interceptor config:% - ', config);
		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

const fetch = () => {
	// logger.log("[Config] fetch path:% - ", values.configPath);

	return (
		axios
			//.get(values.configPath + '?' + moment().toISOString())
			.get(values.configPath)
			.then(response => {
				if (response.status === 200) {
					//logger.log('[Config] fetch - 200 valid response');
					let validateResult = v.validate(response.data, schema.configWeb);
					//if (validateResult.valid) {
					if (true) {
						return response.data;
					} else {
						// throw error and go to catch block
						throw new Error('failed schema validation');
					}
				} else {
					// throw error and go to catch block
					throw new Error('invalid response code');
				}
			})
			.catch(error => {
				//when throw "Error" is executed it runs the catch block code
				logger.warn('[Config] fetch - %o', error.message);
				throw new Error(error.message);
			})
	);
};

const fetchStatic = path => {
	return axios
		.get(path)
		.then(response => {
			if (response.status === 200) {
				//logger.log('[Config] fetchStatic - 200 valid response');

				return response.data;
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			logger.warn('[Config] fetchStatic - %o', error.message);
			throw new Error(error.message);
		});
};

export default {
	fetch,
	fetchStatic,
	ensureConfigurationLoaded: (dispatch, store) =>
		new Promise((resolve, reject) => {
			//logger.log('[Config] ensureConfigurationLoaded - call');
			const unsubscribe = store.subscribe(() => {
				const { Config } = store.getState();

				let loadDiff = moment().diff(moment(Config.updated), 'seconds', true);
				let expired = loadDiff >= Config.refreshSec;

				//logger.log('[Config] ensureConfigurationLoaded - store change');

				if (Config && Config.status === 'loaded' && !expired) {
					//logger.log('[Config] ensureConfigurationLoaded - loaded');
					unsubscribe();
					resolve(Config);
				}

				if (Config && Config.state === 'error') {
					reject({ error: Config.error });
				}
			});

			let Conf = store.getState().Config;
			let loadDiff = moment().diff(moment(Conf.updated), 'seconds', true);
			let expired = loadDiff >= Conf.refreshSec;

			// logger.log(
			//   "[Config] ensureConfigurationLoaded - expired:%o loadDiff:%o state:%o",
			//   expired,
			//   loadDiff,
			//   Conf
			// );

			if (Conf && (Conf.status == null || Conf.status == 'loaded') && expired) {
				// logger.log("[Config] ensureConfigurationLoaded - initial expired");
				dispatch({
					type: deps.actionTypes.CONFIG_EXPIRED,
				});
			}

			if (Conf && Conf.status === 'loaded' && !expired) {
				//logger.log('[Config] ensureConfigurationLoaded - initial loaded');
				unsubscribe();
				resolve(Conf);
			}

			if (Conf && Conf.state === 'error') {
				unsubscribe();
				reject({ error: Conf.error });
			}
		}),
	/*
    checkExpired: (dispatch, store) => new Promise((resolve, reject) => {
        let { Config } = store.getState();

        let loadDiff    = moment().diff(moment(Config.updated), 'seconds', true);
        let expired = loadDiff >= Config.refreshSec;

        if (expired) {
            logger.log('[Config] checkExpired - expired');
        
            let prom = fetch()
                .then((result) => {
                    //log('[WeatherData] loadWeatherDataHelper - data:%o', result);
        
                    dispatch({
                        type       :deps.actionTypes.CONFIG_UPDATE,
                        updated    : moment().toISOString(),
                        status     : 'loaded',
                        data       : result,
                    });
                    resolve(store.getState().Config);
                })
                .catch((err) => {
                    dispatch({
                        type       :deps.actionTypes.CONFIG_ERROR,
                        error      : err,
                    });
                });    
            
        }
        else {
            logger.log('[Config] checkExpired - valid');
            resolve(Config);
           
        }
    })
    */
};
