import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	//logger.log('[ResultsPage] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.RESULTSPAGE_LOAD:
			logger.log('[ResultsPage] reducers - action:%o', action);

			filters = {
				...state.filters,
				day: action.data.tournDay,
				event: action.data.event,
			};

			newState = {
				...state,
				path: action.data.path,
				daysPath: action.data.daysPath,
				tourn_events: action.data.tourn_events,
				basePicPath: action.data.basePicPath,
				status: 'load',
				filters: filters,
			};
			logger.log('[ResultsPage] reducers RESULTSPAGE_LOAD - newState:%o', newState);
			return newState;

		case deps.actionTypes.RESULTSPAGE_FILTER:
			logger.log('[ResultsPage] RESULTSPAGE_FILTER - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'change',
			};
			logger.log('[ResultsPage] RESULTSPAGE_FILTER - newState:%o', newState);
			return newState;

		case deps.actionTypes.RESULTSPAGE_SETDAY:
			logger.log('[ResultsPage] RESULTSPAGE_SETDAY - data:%o', action.data);

			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
				status: 'load',
			};
			logger.log('[ResultsPage] RESULTSPAGE_SETDAY - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
