import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import FavoriteStar from 'appdir/components/common-ui/FavoriteStar';
import RelatedContent from 'appdir/components/cms/RelatedContent';
import EventsLink from 'appdir/components/general/EventsLink';

import TabPanel from 'appdir/components/general/Tabs/TabPanel';
import Tab from 'appdir/components/general/Tabs/Tab';
import CollapsibleMenu from 'appdir/components/general/CollapsibleMenu';

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

const mapStateToProps = (state, props) => ({
	...state['PlayerGeneralInfo'],
	...state['PlayerPage'],
	windowSize: state['Controller'].windowSize,
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({});

class PlayerGeneralInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAll: false,
			showLatestContent: true,
			selectedTab: 'Overview',
		};
		logger.log('[PlayerGeneralInfo] constructor - state:%o', this.state);
	}

	setSelectedTab = tab => {
		this.setState({ selectedTab: tab });
	};

	playerBGimg = () => {
		let imgUrl = '/assets/images/misc/player_bio_bg.png';
		let featureImage = get(this.props, 'playerScoringData.featureImage', null);
		if (featureImage) {
			imgUrl = featureImage;
		}
		return { backgroundImage: `url(${imgUrl})` };
	};

	calculateAge = playerInfo => {
		// var today = new Date();
		var birthDate = new Date(playerInfo.birth.date);
		var years = moment().diff(moment(birthDate), 'years');
		// var age = today.getFullYear() - birthDate.getFullYear();
		// var m = today.getMonth() - birthDate.getMonth();
		// if (m < 0 || today.getDate() < birthDate.getDate()) {
		// 	age--;
		// }
		// return age;
		return years;
	};

	getSex() {
		// logger.log('[PlayerGeneralInfo] getSex - this:%o', this);
		//let sex = get(this.props, 'playerInfo.sex', false);

		/** Need to ask why payerScoringData is used here - there has to be a reason
		 *  adding a quick fix for Men's players that don't have playerScoringData to show Women's
		 */
		let sex = this.props?.playerScoringData?.gender;
		let sexInfo = this.props?.player?.sex;
		if (sex || sexInfo) {
			if (sex == 'M' || sexInfo == 'M') {
				return "Men's";
			} else {
				return "Women's";
			}
		}
		// if (!sex) {
		// 	let idSex = get(this.props, 'playerInfo.id', '')
		// 		.toLowerCase()
		// 		.charAt(0);
		// 	if (idSex != 'm' && idSex != 'w') {
		// 		return false;
		// 	} else {
		// 		sex = idSex;
		// 	}
		// }
		// return sex.toLowerCase() == 'M' ? "Men's" : "Women's";
		//return sex == "M" ? "Men's" : "Women's";
	}

	renderPlayerNewsTile() {
		if (this.props?.relatedContentData?.content.length > 0) {
			return (
				<div className="playerNewsTile">
					<div className="news-tile">
						<RelatedContent data={this.props} />
					</div>
					<div className="newsTileBtnLabel" onClick={this.props.scrollToMediaHandler}>
						<span>VIEW ALL RELATED NEWS</span>
						<i className="icon-arrow-down" />
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	renderHistory(history, type) {
		// logger.log('[PlayerGeneralInfo] renderhistory - history:%o', history);
		history = orderBy(history, ['year_value'], ['desc']);
		return history.map(item => {
			return (
				<tr key={`${item.year_value}-${type}`}>
					<td>{item.year_value}</td>
					<td>{item.AO ? item.AO : `-`}</td>
					<td>{item.RG ? item.RG : `-`}</td>
					<td>{item.WIM ? item.WIM : `-`}</td>
					<td>{item.USO ? item.USO : `-`}</td>
				</tr>
			);
		});
	}

	renderPlayerOverview(playerInfo) {
		return (
			<>
				{/* {mobileView.indexOf(breakpoint) != -1 ? this.renderPlayerInfo(playerInfo) : null} */}
				{playerInfo.results.career.singles_titles ||
				playerInfo.results.career.doubles_titles ||
				playerInfo.results.career.matches_won ||
				playerInfo.results.career.matches_lost ||
				playerInfo.results.year.matches_won ||
				playerInfo.results.year.matches_lost ||
				playerInfo.rank.current_singles ||
				playerInfo.rank.current_doubles ||
				playerInfo.rank.high_singles ||
				playerInfo.rank.high_doubles ||
				playerInfo.rank.high_singles_date ||
				playerInfo.rank.high_doubles_date ||
				playerInfo.results.year.singles_prize_money ||
				playerInfo.results.year.doubles_prize_money ||
				playerInfo.results.career.prize_money ? (
					<div className="playerOverviewContainer">
						<div className="playerOverview">
							<div className="playerOverviewLabel">
								<h4>PLAYER OVERVIEW</h4>
							</div>
							<div className="statsTable">
								<table className="playerStats">
									<tbody>
										{playerInfo.results.career.singles_titles ? (
											<tr>
												<td className="view-label">Career Singles Titles:</td>
												<td className="view-info">
													{playerInfo.results.career.singles_titles}
												</td>
											</tr>
										) : null}
										{playerInfo.results.career.matches_won ? (
											<tr>
												<td className="view-label">Career Matches Won:</td>
												<td className="view-info">{playerInfo.results.career.matches_won}</td>
											</tr>
										) : null}
										{playerInfo.results.career.singles_titles ? (
											<tr>
												<td className="view-label">Year to date matches won:</td>
												<td className="view-info">{playerInfo.results.year.matches_won}</td>
											</tr>
										) : null}
										{playerInfo.rank.high_singles ? (
											<tr>
												<td className="view-label">High rank for singles:</td>
												<td className="view-info">{playerInfo.rank.high_singles}</td>
											</tr>
										) : null}
										{playerInfo.rank.high_singles_date ? (
											<tr>
												<td className="view-label">High rank date for singles:</td>
												<td className="view-info">{playerInfo.rank.high_singles_date}</td>
											</tr>
										) : null}
										{playerInfo.results.year.singles_prize_money ? (
											<tr>
												<td className="view-label">Prize money for singles:</td>
												<td className="view-info">
													{playerInfo.results.year.singles_prize_money}
												</td>
											</tr>
										) : null}
										{playerInfo.results.career.doubles_titles ? (
											<tr>
												<td className="view-label">Career Doubles Titles:</td>
												<td className="view-info">
													{playerInfo.results.career.doubles_titles}
												</td>
											</tr>
										) : null}
										{playerInfo.results.career.matches_lost ? (
											<tr>
												<td className="view-label">Career matches lost:</td>
												<td className="view-info">{playerInfo.results.career.matches_lost}</td>
											</tr>
										) : null}
										{playerInfo.results.year.matches_lost ? (
											<tr>
												<td className="view-label">Year to date matches lost:</td>
												<td className="view-info">{playerInfo.results.year.matches_lost}</td>
											</tr>
										) : null}
										{playerInfo.rank.high_doubles ? (
											<tr>
												<td className="view-label">High rank for doubles:</td>
												<td className="view-info">{playerInfo.rank.high_doubles}</td>
											</tr>
										) : null}
										{playerInfo.rank.high_doubles_date ? (
											<tr>
												<td className="view-label">High rank date for doubles:</td>
												<td className="view-info">{playerInfo.rank.high_doubles_date}</td>
											</tr>
										) : null}
										{playerInfo.results.year.doubles_prize_money ? (
											<tr>
												<td className="view-label">YTD prize money for doubles:</td>
												<td className="view-info">
													{playerInfo.results.year.doubles_prize_money}
												</td>
											</tr>
										) : null}
										{playerInfo.results.career.prize_money ? (
											<tr>
												<td className="view-label careerEarnings">Career prize money:</td>
												<td className="view-info careerEarnings">
													{playerInfo.results.career.prize_money}
												</td>
											</tr>
										) : null}
									</tbody>
								</table>
								<div className="profile-courtesy">
									Profile data courtesy of{' '}
									<EventsLink to="https://www.atptour.com/en/" external={true}>
										<span>ATP</span>
									</EventsLink>
									,{' '}
									<EventsLink to="https://www.wtatennis.com/" external={true}>
										<span>WTA</span>
									</EventsLink>{' '}
									&{' '}
									<EventsLink to="https://www.itftennis.com/en/" external={true}>
										<span>ITF</span>
									</EventsLink>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</>
		);
	}

	renderTabletPlayerOverview(playerInfo) {
		return (
			<>
				{/* {mobileView.indexOf(breakpoint) != -1 ? this.renderPlayerInfo(playerInfo) : null} */}
				{playerInfo.results.career.singles_titles ||
				playerInfo.results.career.doubles_titles ||
				playerInfo.results.career.matches_won ||
				playerInfo.results.career.matches_lost ||
				playerInfo.results.year.matches_won ||
				playerInfo.results.year.matches_lost ||
				playerInfo.rank.current_singles ||
				playerInfo.rank.current_doubles ||
				playerInfo.rank.high_singles ||
				playerInfo.rank.high_doubles ||
				playerInfo.rank.high_singles_date ||
				playerInfo.rank.high_doubles_date ||
				playerInfo.results.year.singles_prize_money ||
				playerInfo.results.year.doubles_prize_money ||
				playerInfo.results.career.prize_money ? (
					<div className="playerOverviewContainer">
						<div className="playerOverview">
							<div className="playerOverviewLabel">
								<h4>PLAYER OVERVIEW</h4>
							</div>
							<div className="statsTable">
								<table className="playerStats">
									<tbody id="singles">
										{playerInfo.results.career.singles_titles ? (
											<tr>
												<td className="view-label">Career Singles Titles:</td>
												<td className="view-info">
													{playerInfo.results.career.singles_titles}
												</td>
											</tr>
										) : null}
										{playerInfo.results.career.matches_won ? (
											<tr>
												<td className="view-label">Career Matches Won:</td>
												<td className="view-info">{playerInfo.results.career.matches_won}</td>
											</tr>
										) : null}
										{playerInfo.results.career.singles_titles ? (
											<tr>
												<td className="view-label">Year to date matches won:</td>
												<td className="view-info">{playerInfo.results.year.matches_won}</td>
											</tr>
										) : null}
										{playerInfo.rank.high_singles ? (
											<tr>
												<td className="view-label">High rank for singles:</td>
												<td className="view-info">{playerInfo.rank.high_singles}</td>
											</tr>
										) : null}
										{playerInfo.rank.high_singles_date ? (
											<tr>
												<td className="view-label">High rank date for singles:</td>
												<td className="view-info">{playerInfo.rank.high_singles_date}</td>
											</tr>
										) : null}
										{playerInfo.results.year.singles_prize_money ? (
											<tr>
												<td className="view-label">Prize money for singles:</td>
												<td className="view-info">
													{playerInfo.results.year.singles_prize_money}
												</td>
											</tr>
										) : null}
									</tbody>
									<tbody id="doubles">
										{playerInfo.results.career.doubles_titles ? (
											<tr>
												<td className="view-label">Career Doubles Titles:</td>
												<td className="view-info">
													{playerInfo.results.career.doubles_titles}
												</td>
											</tr>
										) : null}
										{playerInfo.results.career.matches_lost ? (
											<tr>
												<td className="view-label">Career matches lost:</td>
												<td className="view-info">{playerInfo.results.career.matches_lost}</td>
											</tr>
										) : null}
										{playerInfo.results.year.matches_lost ? (
											<tr>
												<td className="view-label">Year to date matches lost:</td>
												<td className="view-info">{playerInfo.results.year.matches_lost}</td>
											</tr>
										) : null}
										{playerInfo.rank.high_doubles ? (
											<tr>
												<td className="view-label">High rank for doubles:</td>
												<td className="view-info">{playerInfo.rank.high_doubles}</td>
											</tr>
										) : null}
										{playerInfo.rank.high_doubles_date ? (
											<tr>
												<td className="view-label">High rank date for doubles:</td>
												<td className="view-info">{playerInfo.rank.high_doubles_date}</td>
											</tr>
										) : null}
										{playerInfo.results.year.doubles_prize_money ? (
											<tr>
												<td className="view-label">YTD prize money for doubles:</td>
												<td className="view-info">
													{playerInfo.results.year.doubles_prize_money}
												</td>
											</tr>
										) : null}
										{playerInfo.results.career.prize_money ? (
											<tr>
												<td className="view-label careerEarnings">Career prize money:</td>
												<td className="view-info careerEarnings">
													{playerInfo.results.career.prize_money}
												</td>
											</tr>
										) : null}
									</tbody>
								</table>
								<div className="profile-courtesy">
									Profile data courtesy of{' '}
									<EventsLink to="https://www.atptour.com/en/" external={true}>
										<span>ATP</span>
									</EventsLink>
									,{' '}
									<EventsLink to="https://www.wtatennis.com/" external={true}>
										<span>WTA</span>
									</EventsLink>{' '}
									&{' '}
									<EventsLink to="https://www.itftennis.com/en/" external={true}>
										<span>ITF</span>
									</EventsLink>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</>
		);
	}

	renderPlayerInfo = playerInfo => {
		let age = this.calculateAge(playerInfo);
		logger.log('[PlayerGeneralInfo] renderPlayerInfo playerInfo - playerInfo:%o', playerInfo);
		return (
			<div className="player">
				<div className="playerInfo-box">
					<div className="playerPic">
						<img src={`//images.usopen.org/ix-events-usta-players/${playerInfo['id']}.jpg`} />
					</div>
					<div className={`playerStanding ${playerInfo?.bioType == 'WC' ? 'wc' : null}`}>
						{playerInfo?.rank?.current_singles && (
							<div className="playerRank">
								<span className="rankLabel">
									{!playerInfo?.id.startsWith('itf') && this.getSex()}{playerInfo?.bioType == 'WC' ? 'Wheelchair' : playerInfo?.bioType == 'WJ' ? 'Jr. WC' : null} Singles Rank
								</span>
								<span className="rank">{playerInfo.rank.current_singles}</span>
							</div>
						)}
						{playerInfo?.rank?.current_doubles && (
							<div className="playerRank">
								<span className="rankLabel">
									{!playerInfo?.id.startsWith('itf') && this.getSex()}{playerInfo?.bioType == 'WC' ? 'Wheelchair' : playerInfo?.bioType == 'WJ' ? 'Jr. WC' : null} Doubles Rank
								</span>
								<span className="rank">{playerInfo.rank.current_doubles}</span>
							</div>
						)}
						<div>
							<FavoriteStar
								className="favorite"
								id={playerInfo.id}
								playerName={`${playerInfo.first_name} ${playerInfo.last_name}`}
								type="players"
								unFavColor="white"
								favColor="yellow"
								alwaysShow={true}
							/>
						</div>
					</div>
				</div>
				<div className="playerName">
					<div className="firstName">{playerInfo.first_name}</div>
					<div className="lastName">{playerInfo.last_name}</div>
				</div>
				<div className="playerCountry">
					<div className="flagContainer">
						<img
							src={
								playerInfo?.nation?.code
									? this.props.flagImagePathSmall.replace('<code>', playerInfo.nation.code)
									: ''
							}
						/>
					</div>
					<div className="countryContainer">
						{playerInfo.nation.code === 'RUS' || playerInfo.nation.code === 'BLR'
							? ''
							: playerInfo?.nation?.country}
					</div>
				</div>
				{!playerInfo?.birth?.date &&
				!playerInfo?.birth?.place &&
				!playerInfo?.residence &&
				!playerInfo?.height?.imperial &&
				!playerInfo?.weight?.imperial ? (
					''
				) : (
					<div className="playerInfo">
						{playerInfo?.birth?.date && playerInfo?.birth?.date !== 'N/A' ? (
							<div className="info-row">
								<div className="info-field">BIRTH DATE:</div>
								<div className="info-value">{`${playerInfo.birth.date} (Age: ${age})`}</div>
							</div>
						) : null}
						{playerInfo?.birth?.place &&
						!playerInfo?.birth?.place.includes('Russia') &&
						!playerInfo?.birth?.place.includes('Belarus') &&
						!playerInfo?.birth?.place.includes('N/A') ? (
							<div className="info-row">
								<div className="info-field">BIRTH PLACE:</div>
								<div className="info-value">{playerInfo.birth.place}</div>
							</div>
						) : null}
						{playerInfo?.residence &&
						!playerInfo?.birth?.place.includes('Russia') &&
						!playerInfo?.birth?.place.includes('Belarus') &&
						!playerInfo?.birth?.place.includes('N/A') ? (
							<div className="info-row">
								<div className="info-field">RESIDENCE:</div>
								<div className="info-value">{playerInfo.residence}</div>
							</div>
						) : null}
						{playerInfo?.height?.imperial ? (
							<div className="info-row">
								<div className="info-field">HEIGHT:</div>
								<div className="info-value">
									{playerInfo.height.imperial
										? `${playerInfo.height.imperial} (${playerInfo?.height?.metric})`
										: 'N/A'}
								</div>
							</div>
						) : null}
						{playerInfo?.weight?.imperial ? (
							<div className="info-row">
								<div className="info-field">WEIGHT:</div>
								<div className="info-value">
									{playerInfo.weight.imperial
										? `${playerInfo.weight.imperial} (${playerInfo?.weight?.metric})`
										: 'N/A'}
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
		);
	};

	renderPlayerInfoMobile(playerInfo) {
		let age = this.calculateAge(playerInfo);
		return (
			<div className="player">
				<div className="playerName">
					<div className="firstName">{playerInfo.first_name}</div>
					<div className="lastName">{playerInfo.last_name}</div>
				</div>
				<div className="playerInfo-box">
					<div className="playerPic">
						<img src={`//images.usopen.org/ix-events-usta-players/${playerInfo['id']}.jpg`} />
					</div>
					<div className="playerStanding">
						<div className="playerRankContainer">
							{playerInfo?.rank?.current_singles && (
								<div className="playerRank">
									<span className="rankLabel">{!playerInfo?.id.startsWith('itf') && this.getSex()}{playerInfo?.bioType == 'WC' ? 'Wheelchair' : null} Singles Rank</span>
									<span className="rank">{playerInfo.rank.current_singles}</span>
								</div>
							)}
							{playerInfo?.rank?.current_doubles && (
								<div className="playerRank">
									<span className="rankLabel">{!playerInfo?.id.startsWith('itf') && this.getSex()}{playerInfo?.bioType == 'WC' ? 'Wheelchair' : null} Doubles Rank</span>
									<span className="rank">{playerInfo.rank.current_doubles}</span>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="playerCountry">
					<div className="flagContainer">
						<img
							src={
								playerInfo.nation.code
									? this.props.flagImagePathSmall.replace('<code>', playerInfo.nation.code)
									: ''
							}
						/>
					</div>
					<div className="countryContainer">
						{playerInfo.nation.code === 'RUS' || playerInfo.nation.code === 'BLR'
							? ''
							: playerInfo.nation.country}
					</div>
				</div>
				<div className="media-favorite">
					<FavoriteStar
						className="favorite"
						id={playerInfo.id}
						playerName={`${playerInfo.first_name} ${playerInfo.last_name}`}
						type="players"
						unFavColor="white"
						favColor="yellow"
						alwaysShow={true}
					/>
					<div className="playerNewsTile">
						<div className="newsTileBtnLabel" onClick={this.props.scrollToMediaHandler}>
							<span>VIEW ALL RELATED NEWS</span>
							<i className="icon-arrow-down" />
						</div>
					</div>
				</div>
				<div className="playerInfo">
					{playerInfo.birth.date ? (
						<div className="info-row">
							<div className="info-field">BIRTH DATE:</div>
							<div className="info-value">{`${playerInfo.birth.date} (Age: ${age})`}</div>
						</div>
					) : null}
					{playerInfo.birth.place &&
					!playerInfo.birth.place.includes('Russia') &&
					!playerInfo.birth.place.includes('Belarus') ? (
						<div className="info-row">
							<div className="info-field">BIRTH PLACE:</div>
							<div className="info-value">{playerInfo.birth.place}</div>
						</div>
					) : null}
					{playerInfo.residence &&
					!playerInfo.birth.place.includes('Russia') &&
					!playerInfo.birth.place.includes('Belarus') ? (
						<div className="info-row">
							<div className="info-field">RESIDENCE:</div>
							<div className="info-value">{playerInfo.residence}</div>
						</div>
					) : null}
					{playerInfo.height.imperial ? (
						<div className="info-row">
							<div className="info-field">HEIGHT:</div>
							<div className="info-value">
								{playerInfo.height.imperial
									? `${playerInfo.height.imperial} (${playerInfo.height.metric})`
									: 'N/A'}
							</div>
						</div>
					) : null}
					{playerInfo.weight.imperial ? (
						<div className="info-row">
							<div className="info-field">WEIGHT:</div>
							<div className="info-value">
								{playerInfo.weight.imperial
									? `${playerInfo.weight.imperial} (${playerInfo.weight.metric})`
									: 'N/A'}
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}

	renderSinglesHistory(playerInfo) {
		if (!isEmpty(playerInfo.singles_history) && this.props.showHistory) {
			return (
				<div className="singlesContainer">
					<table className="playerHistory">
						<thead>
							<tr>
								<th>Year</th>
								<th>
									Australian
									<br /> Open
								</th>
								<th>
									Roland
									<br /> Garros
								</th>
								<th>Wimbledon</th>
								<th>US Open</th>
							</tr>
						</thead>
						<tbody>{this.renderHistory(playerInfo.singles_history, 'singles')}</tbody>
					</table>
				</div>
			);
		} else {
			return <div className="singlesContainer no-history">This player has no Singles History.</div>;
		}
	}

	renderDoublesHistory(playerInfo) {
		if (!isEmpty(playerInfo.doubles_history) && this.props.showHistory) {
			return (
				<div className="doublesContainer">
					<table className="playerHistory">
						<thead>
							<tr>
								<th>Year</th>
								<th>
									Australian
									<br /> Open
								</th>
								<th>
									Roland
									<br /> Garros
								</th>
								<th>Wimbledon</th>
								<th>US Open</th>
							</tr>
						</thead>
						<tbody>{this.renderHistory(playerInfo.doubles_history, 'doubles')}</tbody>
					</table>
				</div>
			);
		} else {
			return <div className="doublesContainer no-history">This player has no Doubles History.</div>;
		}
	}

	// renderPlayerDetails(playerInfo) {
	// 	logger.log("Related Content props playerInfo:", this.props)
	// 	let age = this.calculateAge(playerInfo);
	// 	return (
	// 		<div className="playerBoxLeft">
	// 			<div className="playerNameBox">
	// 			<div className="playerPic">
	// 				<img
	// 					src={`//images.usopen.org/ix-events-usta-players/${playerInfo['id']}.jpg`}
	// 					// alt={`${playerInfo['first_name + "" + last_name']}player_photo`}
	// 				/>
	// 			</div>
	// <div className="playerName">
	// 	<div className="firstName">{playerInfo.first_name}</div>
	// 	<div className="lastName">{playerInfo.last_name}</div>
	// </div>
	// <div className="flagContainer">
	// 	<img
	// 		src={
	// 			playerInfo.nation.code
	// 				? this.props.flagImagePathLarge.replace('<code>', playerInfo.nation.code)
	// 				: ''
	// 		}
	// 	/>
	// 	<div className="countryContainer">
	// 		<h3>{playerInfo.nation.country}</h3>
	// 	</div>
	// 	<div>
	// 						<FavoriteStar
	// 							id={playerInfo.id}
	// 							type="players"
	// 							unFavColor="white"
	// 							favColor="yellow"
	// 							alwaysShow={true}
	// 						/>
	// 					</div>
	// 				</div>
	// 			</div>

	// {this.getSex() && (
	// 	<div className="playerStanding">
	// 		{get(playerInfo, 'rank.current_singles', false) && (
	// 			<div
	// 				className={`singlesRank ${
	// 					get(playerInfo, 'rank.current_singles', false) &&
	// 					get(playerInfo, 'rank.current_doubles', false)
	// 						? `rankBorder`
	// 						: ``
	// 				}`}>
	// 				<span className="rank">{playerInfo.rank.current_singles}</span>
	// 				<span className="rankLabel">{`${this.getSex()} Singles Rank`}</span>
	// 			</div>
	// 		)}
	// 		{get(playerInfo, 'rank.current_doubles', false) && (
	// 			<div className="doublesRank">
	// 				<span className="rank">{playerInfo.rank.current_doubles}</span>
	// 				<span className="rankLabel">{`${this.getSex()} Doubles Rank`}</span>
	// 			</div>
	// 		)}
	// 	</div>
	// 			)}

	// <div className="playerInfo">
	// 	{playerInfo.birth.date ? (
	// 		<div className="info-row">
	// 			<div className="info-field">Birth Date</div>
	// 			<div className="info-value">{`${playerInfo.birth.date} (Age: ${age})`}</div>
	// 		</div>
	// 	) : null}
	// 	{playerInfo.birth.place ? (
	// 		<div className="info-row">
	// 			<div className="info-field">Birth Place</div>
	// 			<div className="info-value">{playerInfo.birth.place}</div>
	// 		</div>
	// 	) : null}
	// 	{playerInfo.residence ? (
	// 		<div className="info-row">
	// 			<div className="info-field">Residence</div>
	// 			<div className="info-value">{playerInfo.residence}</div>
	// 		</div>
	// 	) : null}
	// 	{playerInfo.height.imperial ? (
	// 		<div className="info-row">
	// 			<div className="info-field">Height</div>
	// 			<div className="info-value">
	// 				{playerInfo.height.imperial
	// 					? `${playerInfo.height.imperial} (${playerInfo.height.metric})`
	// 					: 'N/A'}
	// 			</div>
	// 		</div>
	// 	) : null}
	// 	{playerInfo.weight.imperial ? (
	// 		<div className="info-row">
	// 			<div className="info-field">Weight</div>
	// 			<div className="info-value">
	// 				{playerInfo.weight.imperial
	// 					? `${playerInfo.weight.imperial} (${playerInfo.weight.metric})`
	// 					: 'N/A'}
	// 			</div>
	// 		</div>
	// 	) : null}
	// </div>
	// 		</div>
	// 	);
	// }

	render() {
		let playerInfo = this.props.player;
		let breakpoint = this.props.windowSize;
		let mobileView = ['mobile', 'landscape'];
		logger.log('[PlayerGeneralInfo] playerInfo:%o, breakpoint:%o', playerInfo, breakpoint);
		return (
			<div className="playerInfoContainer">
				{mobileView.indexOf(breakpoint) != -1 ? (
					<div>
						{/* <>
            {this.renderPlayerInfo(playerInfo)}
            {this.renderPlayerNewsTile()}
            {this.renderPlayerOverview(playerInfo)}
          </> */}
						<>{this.renderPlayerInfoMobile(playerInfo)}</>
						<CollapsibleMenu title="PLAYER STATISTICS">
							<TabPanel
								tabs={['Overview', 'Singles History', 'Doubles History']}
								selectedTab={this.state.selectedTab}
								setSelectedTab={this.setSelectedTab}></TabPanel>
							<Tab isSelected={this.state.selectedTab === 'Overview'}>
								<>{this.renderPlayerOverview(playerInfo)}</>
							</Tab>
							<Tab isSelected={this.state.selectedTab === 'Singles History'}>
								<div className="historyOverviewMobile">{this.renderSinglesHistory(playerInfo)}</div>
							</Tab>
							<Tab isSelected={this.state.selectedTab === 'Doubles History'}>
								<div className="historyOverviewMobile">{this.renderDoublesHistory(playerInfo)}</div>
							</Tab>
						</CollapsibleMenu>
					</div>
				) : (
					<div className="tabletViewContainer">
						{this.props?.windowSize === 'smtablet' ||
						this.props?.windowSize === 'tablet' ||
						this.props?.windowSize === 'smdesktop' ? (
							<div className="tabletViewContent">
								<div id="tabletView">
									{this.renderPlayerInfo(playerInfo)}
									{this.renderPlayerNewsTile()}
								</div>
								<div id="mobileAccordion">
									<CollapsibleMenu title="PLAYER STATISTICS">
										<TabPanel
											tabs={['Overview', 'Singles History', 'Doubles History']}
											selectedTab={this.state.selectedTab}
											setSelectedTab={this.setSelectedTab}></TabPanel>
										<Tab isSelected={this.state.selectedTab === 'Overview'}>
											<>{this.renderTabletPlayerOverview(playerInfo)}</>
										</Tab>
										<Tab isSelected={this.state.selectedTab === 'Singles History'}>
											<div className="historyOverviewMobile">
												{this.renderSinglesHistory(playerInfo)}
											</div>
										</Tab>
										<Tab isSelected={this.state.selectedTab === 'Doubles History'}>
											<div className="historyOverviewMobile">
												{this.renderDoublesHistory(playerInfo)}
											</div>
										</Tab>
									</CollapsibleMenu>
								</div>
							</div>
						) : (
							<>
								{this.renderPlayerInfo(playerInfo)}
								{this.renderPlayerNewsTile()}
								{this.renderPlayerOverview(playerInfo)}
							</>
						)}
					</div>
				)}
			</div>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayerGeneralInfo);
