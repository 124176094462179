import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	//logger.log('[EventScheduleWebview] reducers - action:%o',action);
	switch (action.type) {
		case deps.actionTypes.EVENTSCHEDULEWEBVIEW_LOAD:
			// logger.log('[EventScheduleWebview] reducers - action:%o',action);

			newState = {
				...state,
				...action.data,
			};
			// logger.log('[EventScheduleWebview] reducers EVENTSCHEDULEWEBVIEW_LOAD - newState:%o',newState);
			return newState;

		default:
			return state;
	}
};
