/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Button
 * -----------------------------------------------------------------------------
 */

class Button extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return <button {...this.props}>{this.props.children}</button>;
	}
}

export default Button;
