import deps from "dependencies";

export default (state = {}, action) => {
    let newState;

    switch (action.type) {
        case deps.actionTypes.VIDEO_MOUNT:
            newState = {
                ...state,
                config: action.data
            };
            return newState;
        
        case deps.actionTypes.VIDEO_CURRENTLY_PLAYING:
            newState = {
                ...state,
                currentVideo: action.data
            };
            // logger.log('[Video] VIDEO_CURRENTLY_PLAYING newState:%o', newState);
            return newState;
        
        case deps.actionTypes.VIDEO_LOCATION:
            newState = {
                ...state,
                currentVideo: {
                    ...state.currentVideo,
                    outofport: action.data
                }
            }
      
        default:
          return state;
}

};
