/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useEffect, useState } from 'react';

import Template from '../../Template';
import VideoTest from './VideoTest';
import IndexList from './IndexList';

/**
 * -----------------------------------------------------------------------------
 * React Component: TestPage
 * -----------------------------------------------------------------------------
 */

const TestPage = props => {
	let pageTitle = 'Test Page';

	const getTestComponent = () => {
		logger.log(`[TestPage] getTestComponent: props%o`);
		let component = props?.match?.params?.component;

		if (component == 'page') {
			return <div>Test Page</div>;
		} else if (component == 'videotest') {
			return <VideoTest />;
		} else {
			return <IndexList />;
		}
	};

	let header_propsData = {
		headerType: 'site',
		metaTitle: 'Test Page',
		metaDescription: '',
		scroll: false,
	};

	let subheader_attributes = {
		breadcrumbs: [
			{
				link: '/index.html',
				title: 'home',
			},
			{
				link: '/test/',
				title: 'Test Index',
			},
		],
		title: props?.match?.params?.component,
	};

	return (
		<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
			<h1 className="header">Test Page</h1>
			<div className="site_content container">
				<div className="siteDetailContainer">{getTestComponent()}</div>
			</div>
		</Template>
	);
};
export default TestPage;
