/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';
import op from 'object-path';

import { fetch, fetchPromiseAll } from 'appdir/components/general/Util';

import Footer from 'appdir/components/general/Footer';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PlayerImage from 'appdir/components/content/PlayerImage';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import EventsLink from 'appdir/components/general/EventsLink';
import Template from 'components/Template';

/**
 * -----------------------------------------------------------------------------
 * React Component: WinFactorsFinder - Helper for Approvers
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['WinFactorsFinder'],
		Config: state['Config'],
		sharedDataConfig: state['Config'].sharedDataConfig,
		configOtherData: state['Config'].otherData,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});

const dropdownMenu = {
	options: [
		{ name: 'All Matches', value: 'all' },
		{ name: 'Match Quality Wins', value: 'match_quality_wins' },
		{ name: 'Scaled Watson Power Index', value: 'scaled_watson_power_index' },
		{ name: 'Net Sets Won', value: 'net_sets_won' },
		{ name: 'Surface Domain Index', value: 'surface_domain_index' },
		{ name: 'Age Years', value: 'ageyears' },
		{ name: 'Ratio Games Won', value: 'ratio_games_won' },
		{ name: 'Rank', value: 'rank' },
		{ name: 'Overall Domain Power Index', value: 'overall_domain_power_index' },
	],
};

class WinFactorsFinder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null,
			selectedWinFactor: 'ageyears',
			open: '',
		};

		this.firstLoad = true;
		this.winFactorsLoaded = false;
		this.sharedDataLoaded = false;
		this.hasError = false;

		//logger.log('[WinFactorsFinder] state:%o', this.state);
	}

	componentDidMount() {
		this.setState({
			status: 'loaded',
		});

		logger.log(`${this.constructor.name}.componentDidMount()`);
	}

	componentDidUpdate(prevProps, prevState) {
		/** Pull shared matches json file to get all the available mathces ids */

		if (prevProps.status !== this.props.status && this.props.status == 'loaded' && !this.sharedDataLoaded) {
			// logger.log('[WinFactorsFinder] componentDidUpdate  - this.props:%o', this.props);

			// load all the available MI matches list to check if the selected MatchId exists
			this.props
				.checkExpired(this.props.sharedDataConfig['matchInsightsMatches'])
				.then(response => {
					this.sharedDataLoaded = true;
					logger.log('[WinFactorsFinder] componentDidUpdate matchInsightsMatches - response:%o', response);
					if (response.status == 'expired') {
						this.props.update(this.props.sharedDataConfig['matchInsightsMatches']);
					}
				})
				.catch(error => {
					this.sharedDataLoaded = false;
					logger.error('[WinFactorsFinder] componentDidUpdate matchInsightsMatches - :o', error);
				});
		}

		/** fetch the available Win Factors matches feed */
		if (this.firstLoad && !this.state.winFactorsMatches && this.props.status == 'loaded') {
			let winFactorsMatchesPath = this.props?.configOtherData?.matchInsights?.winFactorsMatches;

			this.firstLoad = false;

			fetch(winFactorsMatchesPath)
				.then(result => {
					logger.log('[WinFactorsFinder] componentDidUpdate WinfactorsMatches result:%o', result);

					this.setState({
						winFactorsMatches: result?.matches?.length > 0 ? result.matches : [],
					});
				})
				.catch(error => {
					logger.error('[WinFactors] componentDidUpdate error:%o', error);

					this.setState({
						winFactorsMatches: 'error',
					});
				});
		}

		if (
			this.state.winFactorsMatches &&
			this.state.winFactorsMatches !== 'error' &&
			this.state?.winFactorsMatches?.length > 0 &&
			!this.winFactorsLoaded
		) {
			this.winFactorsLoaded = true;
			this.fetchAllWFs();
		} else if (!this.winFactorsLoaded && this.state.winFactorsMatches && this.state.winFactorsMatches !== 'error') {
			this.winFactorsLoaded = true;

			this.setState({
				winFactorsData: null,
			});
		}
	}

	fetchAllWFs = () => {
		var data;
		var paths = [];

		/** generate paths to fetch all once */
		this.state.winFactorsMatches.map((mid, index) => {
			let winFactorsPath = this.props.configOtherData?.matchInsights?.winFactors.replace('<matchId>', mid);
			paths.push(winFactorsPath);
		});

		fetchPromiseAll(paths)
			.then(
				axios.spread((...responses) => {
					let rawData = [...responses];
					let errorData = rawData.filter(d => d?.status === 'error');

					if (errorData.length > 0) {
						this.hasError = true;
					}

					data = responses.filter(d => d?.status !== 'error');

					/** sort by match_id for a better UX */
					data.sort((a, b) => (a.match_id < b.match_id ? -1 : 1));

					logger.log('[WinFactors] componentDidUpdate WinFactors data:%o, responses:%o', data, responses);

					this.setState({
						winFactorsData: data,
						errorData,
					});
				})
			)
			.catch(error => {
				logger.error('[WinFactors] fetchPromise error:%o', error);

				this.setState({
					winFactorsData: 'error',
				});
			});
	};

	getIcon = key => {
		let iconClass = '';

		switch (key.toLowerCase()) {
			case 'overall_domain_power_index':
				iconClass = 'likes';
				break;
			case 'age_years':
			case 'ageyears':
				iconClass = 'arrows-up';
				break;
			case 'ratio_games_won':
				iconClass = 'checkmark';
				break;
			case 'net_sets_won':
				iconClass = 'head-to-head';
				break;
			case 'rank':
			case 'wta_rank':
			case 'atp_rank':
				iconClass = 'rank';
				break;
			case 'match_quality_wins':
				iconClass = 'ball';
				break;
			case 'scaled_watson_power_index':
				iconClass = 'arrow-out';
				break;
			case 'surface_domain_index':
				iconClass = 'court';
				break;
			default:
				break;
		}

		return iconClass;
	};

	getContent = data => {
		return (
			<>
				<b className="matchNum">{data.match_id}</b>
				<PlayerImage
					attributes={{
						'player-image': this.imgPath.replace('<playerid>', data.p1_id),
					}}
				/>
				<PlayerImage
					attributes={{
						'player-image': this.imgPath.replace('<playerid>', data.p2_id),
					}}
				/>
			</>
		);
	};

	getErrorDetails = () => {
		if (this.state.errorData) {
			return this.state.errorData.map(e => {
				return (
					<p>
						{e.error?.config?.message} <br /> {e.error?.config?.url}
					</p>
				);
			});
		}
	};

	getMatchIdsByKey = () => {
		let miList = op.get(this.props, `matchInsightsMatches.result.matches`);

		if (this.filteredData.length > 0) {
			return this.filteredData.map(d => {
				return (
					<div className="row flex" key={`filteredData ${d.match_id}`}>
						<div className="col">
							<EventsLink to={`/en_US/matchinsights/${d.match_id}.html`} external={true}>
								{this.getContent(d)}
							</EventsLink>
						</div>
					</div>
				);
			});
		} else {
			return <p>No matches found</p>;
		}
	};

	setFilter = criteria => {
		this.setState({
			selectedWinFactor: criteria,
			open: '',
		});
	};

	onOpen = which => {
		this.setState({
			open: which,
		});
	};

	render() {
		logger.log('[WinFactorsFinder] render - this:%o, matches:%o', this, this.props?.matchInsightsMatches);

		let header_propsData = {
			headerType: 'scores',
			title: 'Match Insights',
			metaTitle: 'Match Insights',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/matchinsights/index.html',
					title: 'Match Insights',
				},
			],
			title: 'Match Insights',
		};

		if (this.state.winFactorsData !== 'error' && this.state.winFactorsData) {
			if (
				this.state.selectedWinFactor == '' ||
				!this.state.selectedWinFactor ||
				this.state.selectedWinFactor == 'all'
			) {
				this.filteredData = this.state.winFactorsData;
			} else {
				let selectedVal = this.state.selectedWinFactor;
				/** RANK is all caps in feed, match cases for filtering */
				this.filteredData = this.state.winFactorsData.filter(
					d =>
						d.sentences.hasOwnProperty(selectedVal) || d.sentences.hasOwnProperty(selectedVal.toUpperCase())
				);
			}
		}

		this.imgPath = this.props?.configOtherData?.playerProfileImagePath;

		return (
			<Template
				header={header_propsData}
				subHeader={this.props.windowSize !== 'mobile' ? subheader_attributes : null}
				className="scorespage"
				id="match-insights">
				<section className="wrapper scorespage">
					<div className="content-main match-insights" id="match-insights">
						<div className="columm-layout match-insights-content win-factors-finder mi-win-factors">
							<div className="mi-section">
								<div className="mi-inner-body">
									<div className="news-landing-time-selector latest">
										<div className="champs-dropdown">
											<SelectMenu
												name="wf"
												attributes={dropdownMenu}
												selected={this.state.selectedWinFactor}
												show={this.state.open == 'wf'}
												onSelected={this.setFilter}
												onOpen={this.onOpen}
											/>
										</div>
									</div>

									{this.state.winFactorsData !== 'error' && this.state.winFactorsData ? (
										<>
											<div className="row">
												<div className="col">
													Available Matches: <b>{this.state.winFactorsData.length}</b>
												</div>
												<div className="col">
													Filtered Matches:{' '}
													<i
														className={`col icon ${this.getIcon(
															this.state.selectedWinFactor
														)}`}
													/>{' '}
													<b>{this.filteredData?.length}</b>
												</div>
											</div>

											{this.getMatchIdsByKey()}
											{this.hasError ? (
												<>
													<h3>Fetching Failed Feeds</h3>
													{this.getErrorDetails()}
												</>
											) : null}
										</>
									) : (
										<LoadingIndicator />
									)}
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WinFactorsFinder);
