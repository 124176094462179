import { validateData } from 'appdir/components/general/Util';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

export const getComponentListArray = list => {
	let comps = [];

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item?.type) {
				comps.push(item['type']);
			} else {
				//logger.log('[ContentPage] getComponentListArray - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		comps.push(list['type']);
	}

	comps = uniq(comps);
	// logger.log('[ContentPage] getComponentListArray - %o:', comps);
	return comps;
};

export const getAttributesArray = (list, parentId, section, tabName) => {
	let attr = {};
	// logger.log('[ContentPage]  getAttributesArray - list: %o, contentId: %o, section:%o', list, parentId, section);

	if (validateData(list, 'array')) {
		list.forEach(item => {
			logger.log('[ContentPage]  getAttributesArray - item: %o', item);

			if (item?.type) {
				attr[item['reference']] = item;
				if (parentId) {
					attr[item['reference']]['parentId'] = parentId;
				}
				if (section) {
					attr[item['reference']]['section'] = `${section}-page`;
				}
				if (tabName) {
					attr[item['reference']]['tabName'] = tabName;
				}
			} else {
				//logger.log('[ContentPage]  getAttributesArray - removing: %o', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		attr[list['reference']] = list;
		if (parentId) {
			attr[list['reference']]['parentId'] = parentId;
		}
		if (section) {
			attr[list['reference']]['section'] = `${section}-page`;
		}
		if (tabName) {
			attr[item['reference']]['tabName'] = tabName;
		}
	}
	logger.log('[ContentPage]  getAttributesArray - attr: %o', attr);
	return attr;
};

export const increaseFontSize = () => {
	var txt = document.getElementsByClassName('rich-text');
	for (var i = 0; i < txt.length; i++) {
		var style = window.getComputedStyle(txt[i], null).getPropertyValue('font-size');
		var currentSize = parseFloat(style);
		if (currentSize <= 20) {
			txt[i].style.fontSize = currentSize + 2 + 'px';
		}
	}
};

export const decreaseFontSize = () => {
	var txt = document.getElementsByClassName('rich-text');
	for (var i = 0; i < txt.length; i++) {
		var style = window.getComputedStyle(txt[i], null).getPropertyValue('font-size');
		var currentSize = parseFloat(style);
		if (currentSize >= 18) {
			txt[i].style.fontSize = currentSize - 2 + 'px';
		}
	}
};

export const getBreadcrumbs = contentPageArray => {
	// logger.log("[ContentPage] getBreadcrumbs - this:%o", this);
	let breadCrumbs = [
		{
			link: '/index.html',
			title: 'Home',
		},
	];

	// let { contentPageArray } = this.state;

	if (contentPageArray.length && contentPageArray[0].breadcrumbs) {
		let breadCrumb = contentPageArray[0].breadcrumbs;
		if (typeof breadCrumb !== 'undefined') {
			Object.keys(breadCrumb).map(function(k, i) {
				// logger.log("[ContentPage] getBreadcrumbs - breadcrumb:%o", breadCrumb[k]);
				if (breadCrumb[k]?.title) {
					breadCrumbs.push(breadCrumb[k]);
				} else {
					//logger.log('[ContentPage] getBreadcrumbs - removing: %o:', breadCrumb[k]);
				}
			});
		}
	}
	return breadCrumbs;
};

export const getSponsor = (contentPageArray, props) => {
	// logger.log('[ContentPage] getSponsor - this:%o', this);
	let sponsor = null;
	// let { contentPageArray } = this.state;

	if (contentPageArray.length && contentPageArray[0].sponsor) {
		// logger.log('[ContentPage] getSponsor - sponsor');
		if (contentPageArray[0].sponsor.sponsor.title != 'nosponsor') {
			sponsor = {
				link: contentPageArray[0].sponsor.sponsor.link,
				name: contentPageArray[0].sponsor.sponsor.name,
				title: contentPageArray[0].sponsor.sponsor.tagline,
				imgClass: contentPageArray[0].sponsor.sponsor.imgClass,
				imgSrc: contentPageArray[0].sponsor.sponsor.imgSrc,
				tagline: contentPageArray[0].sponsor.sponsor.tagline,
			};
		}
	} else if (contentPageArray[0].pageSponsor) {
		// logger.log('[ContentPage] getSponsor - pageSponsor');
		sponsor = null;
	} else if (props.category != '' && props.sponsors[props.category]) {
		// logger.log('[ContentPage] getSponsor - category');
		sponsor = props.sponsors[props.category];
	} else if (props.landing != '' && props.sponsors[props.landing]) {
		// logger.log('[ContentPage] getSponsor - landing');
		sponsor = props.sponsors[props.landing];
	}
	// logger.log('[ContentPage] getSponsor - sponsor:%o', sponsor);
	return sponsor;
};

/** check if the article contains ai catch up content */
export const getAiCatchUpContent = (data) => {
	logger.log('[ContentPage] getAiCatchUpContent - data:%o', data);
	let dataArray = [];
	if(data) {
		if(!Array.isArray(data) && !isEmpty(data)) {
			/** in case {} object is passed instead of an array, convert it to an array */
			dataArray = [data];
		} else if(data?.length > 0) {
			dataArray = data;
		} else {
			return []
		}
		/** if dependencies.data contains <style>pre OR post</style> it is a genAI content  */
		return dataArray.filter( d => d?.["style"] == "pre" || d?.["style"] == "post");

	} else {
		return []
	}
}
