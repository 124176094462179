import React, { Component } from 'react';
import MatchBox from 'appdir/components/_scoring/MatchBox';

class PlayerMatchUpOverview extends Component {
	constructor(props) {
		super(props);
	}

	toggleDetails(index) {
		this.setState(prevState => {
			let newProps = this.props.attributes.map((d, i) => {
				return i == index
					? {
							...d,
							showDetail: !d.showDetail || d.showDetail == '0px' ? '720px' : '0px',
					  }
					: d;
			});
			let updateDetailToggle = !this.props.attributes[index].showDetail;
			return { ...prevState, attributes: newProps };
		});
	}

	formatRelatedContentURL(matchID) {
		let relatedContentURL = this.props.playerConfiguration.relatedContentPath.matchRelated;
		relatedContentURL = relatedContentURL.replace('<matchId>', matchID + '&count=4');

		return relatedContentURL;
	}

	render() {
		let matchData = this.props.attributes;
		return (
			<section className="player-matchup">
				{matchData.map((d, i) => {
					let scoreTableHeader = `${d.round} • ${d.location}`;
					let relatedContentURL = this.formatRelatedContentURL(d.matchId);

					return (
						<div key={`${d.matchId}-${i}`} className="featuredtable">
							<div className="player-score-table">
								<MatchBox
									key={`${d.matchId}-${i}`}
									attributes={{
										data: d,
										event: d.event,
										tableHeader: scoreTableHeader,
										showLinks: true,
										usePlayerImages: false,
										liveVideo: false,
										suppressProfileLink: false,
										playerProfileId: this.props.playerID,
										mode: 'players',
									}}
								/>
							</div>
						</div>
					);
				})}
			</section>
		);
	}
}
export default PlayerMatchUpOverview;
