import React, { Component } from 'react';
import deps from 'dependencies';
import { connect } from 'react-redux';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import get from 'lodash/get';
import { fetch } from 'appdir/components/general/Util';
import AtoZNav from 'appdir/components/common-ui/AtoZNav';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['PlayerFilters'],
		countryPlayers: state['PlayerFilters'].countryPlayers,
		windowSize: state['Controller'].windowSize,
		OtherData: state.Config.otherData,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PlayerFilters.mount()),
	filterCountry: country => dispatch(deps.actions.PlayerFilters.filterCountry(country)),
	filterGender: gender => dispatch(deps.actions.PlayerFilters.filterGender(gender)),
});

class PlayerFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			loadResults: false,
			hideDiv: true,
		};

		logger.log('[PlayerFilters] props:%o', this.props);
	}

	componentDidMount() {
		// logger.log('[PlayerFilters] componentDidMount:%o', this.state);
		this.props.mount();
	}

	handleChange = evt => {
		this.setState({
			value: evt.target.value,
		});
	};

	handleKeyPress = evt => {
		if (evt.key == 'Enter') {
			logger.log('[PlayerFilters] handleKeyPress - value:%o', this.state.value);
			if (evt.target.value.trim().length == 0) {
				this.setState({
					value: 'Enter a last name',
				});
			} else {
				this.loadResults();
			}
		}
	};

	handleClick = value => {
		if (value.trim().length == 0) {
			this.setState({
				value: 'Enter a last name',
			});
		} else {
			this.setState({
				value: value,
			});

			this.loadResults();
		}
	};

	clearValue = evt => {
		this.setState({
			value: '',
		});
	};

	loadResults() {
		this.setState({
			loadResults: true,
		});
	}

	showGender = evt => {
		this.props.filterGender(evt);
	};

	handleHideDiv = () => {
		this.setState(prevState => ({
			hideDiv: !prevState.hideDiv,
		}));
	};

	showList = evt => {
		let urlLink;
		this.setState({
			loadResults: false,
		});

		switch (evt) {
			case 'players':
				urlLink = '/en_US/players/index.html';
				MeasurementUtils.dispatchMeasurementCall('playersByNameSelect', {});
				break;

			case 'country':
				urlLink = '/en_US/players/country.html';
				MeasurementUtils.dispatchMeasurementCall('playersByCountrySelect', {});
				break;

			case 'seeds':
				urlLink = '/en_US/players/seed.html';
				MeasurementUtils.dispatchMeasurementCall('playersBySeedSelect', {});
				break;

			default:
				urlLink = '/en_US/players/index.html';
				MeasurementUtils.dispatchMeasurementCall('playersByNameSelect', {});
				break;
		}
		this.setState({
			menuLink: urlLink,
		});
	};

	renderFilters = () => {
		return (
			<div className="filterContainer">
				<div
					data-text="Players by Name"
					className={`playerFilterSelection${!this.props.filterBy ? ` selected` : ``}`}
					onClick={() => this.showList('players')}>
					{`Players by Name`}
				</div>
				<div
					data-text="Players by Country"
					className={`playerFilterSelection${this.props.filterBy == 'country' ? ` selected` : ``}`}
					onClick={() => this.showList('country')}>
					{`Players by Country`}
				</div>
				{get(this.props, 'OtherData.showSeeds', false) && (
					<div
						data-text="Players by Seed"
						className={`playerFilterSelection${this.props.filterBy == 'seeds' ? ` selected` : ``}`}
						onClick={() => this.showList('seeds')}>
						{`Players by Seed`}
					</div>
				)}
			</div>
		);
	};

	getCountries() {
		//fetch country list
		fetch(this.state.countries).then(data => {
			let filterData = data.countries.map(d => {
				return { name: d.display, value: d.code };
			});
			this.setState(prevState => {
				return { ...prevState, countrySelectData: filterData };
			});
		});
	}

	render() {
		// logger.log('[PlayerFilters] render - state:%o', this.state);
		if (this.state.loadResults) {
			let url = '/en_US/players/search.html?term=' + this.state.value;

			return (
				<div>
					<div>{this.renderMenu()}</div>
					<EventsRedirect to={url} />
				</div>
			);
		}

		if (this.state.menuLink) {
			if (this.state.menuLink !== window.location.pathname) {
				return <EventsRedirect to={this.state.menuLink} />;
			}
		}

		return <div>{this.renderMenu()}</div>;
	}

	renderMenu() {
		// logger.log('[PlayerFilters] renderMenu - state:%o', this.state);
		let breakpoint = this.props.windowSize;
		// logger.log('[PlayerFilters] renderMenu - breakpoint:%o', breakpoint);

		switch (this.state.filterBy) {
			case 'players':
				return (
					<div className="centerMargins">
						{this.renderFilters()}
						<div className="searchContainer">
							{breakpoint == 'mobile' ? (
								<AtoZNav settings={this.props.settings} data="" />
							) : (
								<div className="searchInputContainer">
									<input
										type="text"
										className="player-search"
										value={this.state.value}
										onChange={this.handleChange}
										onKeyPress={this.handleKeyPress}
										onClick={this.clearValue}
										placeholder="Search by Last Name"
									/>
									<div className="searchIcon" onClick={() => this.handleClick(this.state.value)}>
										<div className="ar-icon">
											<div>
												<i className="icon-playerSearch" />
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				);

			case 'country':
				if (!this.props.countryPlayers) {
					//default country
					this.props.filterCountry('USA');
				}
				if (this.state.countries && !this.state.countrySelectData) {
					this.getCountries();
				}
				// logger.log('this.state.countries', this.state.countries);
				// logger.log('this.state.countrySelectData', this.state.countrySelectData);
				return (
					<>
						<div className="centerMargins-c">{this.renderFilters()}</div>
					</>
				);

			case 'seeds':
				return <div className="centerMargins-c">{this.renderFilters()}</div>;

			default:
				return (
					<div className="centerMargins">
						{this.renderFilters()}
						<div className="searchContainer">
							{/* RHEA - the below commented section is for the search icon and functionality on players by name */}
							{/* <div className="searchInputContainer">
								<input
									hidden={this.state.hideDiv}
									type="text"
									className="player-search"
									value={this.state.value}
									onChange={this.handleChange}
									onKeyPress={this.handleKeyPress}
									placeholder="Search by Last Name"
								/>
								<div className="searchIcon">
									<div className="ar-icon" onClick={this.handleHideDiv}>
										<div>
											<i className="icon-playerSearch" />
										</div>
									</div>
								</div>
							</div> */}
							{/* )} */}
						</div>
					</div>
				);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayerFilters);
