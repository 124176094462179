import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// this will call the reducer in controller which will set the redux state
			// dispatch({
			//   type: deps.actionTypes.LIVESCORES_MOUNT,
			//   data: { status: "load" }
			// });
			logger.log('[ScoreManager] - Telling controller we have scores');
			dispatch({
				type: deps.actionTypes.CONTROLLER_SCORING_MODULE_LOADED,
				data: true,
			});
		});
	},
	setMatches: matchData => dispatch => {
		// logger.log("[ScoreManager] action.setMatches - matches:%o", matchData);
		dispatch({
			type: deps.actionTypes.SCORE_MANAGER_UPDATE_SCORES_DATA,
			data: matchData,
		});
	},
	setCurrentMatch: data => dispatch => {
		// logger.log('[ScoreManager] action.setCurrentMatch - match:%o', data);
		dispatch({ type: deps.actionTypes.SCORE_MANAGER_SET_CURRENT_MATCH, data });
	},
	setLiveStatMatch: data => dispatch => {
		// logger.log("[ScoreManager] action.setLiveStatMatch - match:%o", data);
		dispatch({ type: deps.actionTypes.SCORE_MANAGER_SET_LIVE_STATMATCH, data });
	},
	setCompletedStatMatch: data => dispatch => {
		// logger.log('[ScoreManager] action.setCompletedStatMatch - match:%o', data);
		dispatch({
			type: deps.actionTypes.SCORE_MANAGER_SET_COMPLETED_STATMATCH,
			data,
		});
	},
	setStatMatchStats: data => dispatch => {
		// logger.log("[ScoreManager] action.setStatMatchStats - match:%o", data);
		dispatch({
			type: deps.actionTypes.SCORE_MANAGER_SET_STATMATCH_STATS,
			data,
		});
	},
	setStatMatchHistory: data => dispatch => {
		// logger.log("[ScoreManager] action.setStatMatchHistory - match:%o", data);
		dispatch({
			type: deps.actionTypes.SCORE_MANAGER_SET_STATMATCH_HISTORY,
			data,
		});
	},
	clearStatMatch: () => dispatch => {
		dispatch({
			type: deps.actionTypes.SCORE_MANAGER_CLEAR_STATMATCH,
		});
	},
};
