import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		/**
		 * controller mount
		 */
		case deps.actionTypes.CONTROLLER_MOUNT:
			// logger.log('[Controller] actionTypes.CONTROLLER_MOUNT - state:%o', state);
			newState = {
				...state,
				...action.data,
				scoring: {
					...state.scoring,
				},
			};
			// logger.log('[Controller] actionTypes.CONTROLLER_MOUNT - newState:%o', newState);
			return newState;

		/**
		 * updating of controller state for uom
		 *  - when user changes uom
		 */
		case deps.actionTypes.CONTROLLER_UPDATEUOM:
			newState = {
				...state,
				scoring: {
					...state.scoring,
					...action.data,
				},
			};
			return newState;

		/**
		 * generic updating of controller state
		 *  - when user changes sidepanel state
		 *  - ?
		 */
		case deps.actionTypes.CONTROLLER_UPDATE:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		/**
		 * what for?
		 */
		case deps.actionTypes.CONTROLLER_SETPLATFORM:
			newState = {
				...state,
				platform: action.data,
				updated: moment().toISOString(),
			};
			return newState;

		/**
		 *
		 */
		case deps.actionTypes.CONTROLLER_SET_SCORING_STATUS:
			newState = {
				...state,
				scoring: {
					...state.scoring,
					...action.data,
				},
			};
			// logger.log('[Controller] actionTypes.CONTROLLER_SET_SCORING_STATUS - newState:%o', newState);
			return newState;

		/**
		 * update the list of viewed content
		 */
		case deps.actionTypes.CONTROLLER_UPDATEVIEWEDCONTENT:
			newState = {
				...state,
				content: action.data,
			};
			// logger.log('[Controller] actionTypes.CONTROLLER_UPDATEVIEWEDCONTENT - newState:%o', newState);
			return newState;

		/**
		 * what for?
		 */
		case deps.actionTypes.CONTROLLER_UPDATEDATE:
			newState = {
				...state,
				date: action.data,
			};
			return newState;

		case deps.actionTypes.CONTROLLER_SETWINDOWSIZE:
			// logger.log('[Controller] CONTROLLER_SETWINDOWSIZE - state:%o, action.data:%o', state, action.data);
			newState = {
				...state,
				...action.data,
			};
			//logger.log('[Controller] CONTROLLER_SETWINDOWSIZE - windowSize:%o', newState.windowSize);
			return newState;

		case deps.actionTypes.CONTROLLER_SETSTWINDOWSIZE:
			//logger.log('[Controller] CONTROLLER_SETSTWINDOWSIZE - state:%o');
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[Controller] CONTROLLER_SETSTWINDOWSIZE - stWindowSize:%o', newState.stWindowSize);
			return newState;

		case deps.actionTypes.CONTROLLER_SETMIWINDOWSIZE:
			//logger.log('[Controller] CONTROLLER_SETMIWINDOWSIZE - state:%o');
			newState = {
				...state,
				...action.data,
			};
			// logger.log('[Controller] CONTROLLER_SETMIWINDOWSIZE - miWindowSize:%o', newState.miWindowSize);
			return newState;

		/**
		 * update value of geoblock
		 */
		case deps.actionTypes.CONTROLLER_UPDATEGEOBLOCK:
			newState = {
				...state,
				geoblock: action.data,
			};
			return newState;

		/**
		 * update value of video channels
		 */
		case deps.actionTypes.CONTROLLER_UPDATEVIDEOCHANNELS:
			newState = {
				...state,
				video: {
					...state.video,
					channels: action.data,
				},
			};
			return newState;

		/**
		 * set the main nave menu items
		 */
		case deps.actionTypes.CONTROLLER_SET_MENUITEMS:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.CONTROLLER_SET_GLOBALLINK:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.CONTROLLER_SCORING_MODULE_LOADED:
			newState = {
				...state,
				scoring: {
					...state.scoring,
					loaded: true,
				},
			};
			// logger.log('[Controller] - actionTypes.CONTROLLER_SCORING_MODULE_LOADED newState:%o', newState);
			return newState;

		case deps.actionTypes.CONTROLLER_SCORECONNECTION_READY:
			newState = {
				...state,
				scoring: {
					...state.scoring,
					...action.data,
				},
			};
			logger.log('[ScoreManager] - actionTypes.CONTROLLER_SCORECONNECTION_READY newState:%o', newState);
			return newState;

		case deps.actionTypes.CONTROLLER_SETSTPANEL_STATUS:
			// logger.log('[Controller] - actionTypes.CONTROLLER_SETSTPANEL_STATUS - data:%o', action.data);
			newState = {
				...state,
				stpanel: {
					open: action.data.open,
					matchid: action.data.matchid,
					matchstatus: action.data?.matchstatus || null,
					panelToST: action.data?.panelToST || false,
				},
			};
			// logger.log('[Controller] - actionTypes.CONTROLLER_SETSTPANEL_STATUS');
			return newState;

		default:
			return state;
	}
};
