import React, { useState } from 'react';
import throttle from 'lodash/throttle';
import EventsLink from 'appdir/components/general/EventsLink';

const mobileMatch = window.matchMedia('(max-width: 767px)');
const tabletMatch = window.matchMedia('(max-width: 1023px)');
const desktopMatch = window.matchMedia('(max-width: 1364px)');
const midDesktopMatch = window.matchMedia('(min-width: 1365px) and (max-width: 1600px)');
const largeDesktopMatch = window.matchMedia('(min-width: 1601px)');

const PageNav = props => {
	// logger.log('[PageNav] props %o', props);
	let [viewPort, setViewPort] = useState(checkWindowSizes());
	//window.addEventListener('resize', throttle(onResize, 100, { trailing: true }), false);
	let [show, setMenuView] = useState(false);

	function showMenu() {
		let menuToggle = !show;
		setMenuView(menuToggle);
	}

	function onResize(evt) {
		//using breakpoints in css now but leaving in case need to revert
		//setViewPort(checkWindowSizes());
	}

	function isMatch(active, value, matchValue) {
		if (Array.isArray(matchValue)) {
			return matchValue.some(regex => regex.test(active));
		} else if (matchValue) {
			return matchValue.test(active);
		} else if (active === value || active === `${value}.html`) {
			return true;
		}
	}

	function checkWindowSizes() {
		let size = '';

		if (mobileMatch.matches) {
			size = 'mobile';
		} else if (tabletMatch.matches) {
			size = 'tablet';
		} else if (desktopMatch.matches || largeDesktopMatch.matches || midDesktopMatch.matches) {
			size = 'desktop';
		}
		return size;
	}

	let arrow = ' ▾';
	let title = props.menuItems.filter(d => isMatch(props.isActive, d.value, d.match)).map(d => d.name);
	title = title && title.length > 0 ? title : props.default;

	let keyHandler = (event, val) => {
		event.preventDefault();
		if (event.key === 'Enter') {
			props.clickFn(val);
		}
	};

	let clickHandler = (event, val) => {
		event.preventDefault();
		logger.log('[PageNav] clickHandler - d:%o', val);
		event.currentTarget.blur();
		props.clickFn(val);
	};

	return (
		<div className="page-nav-container">
			<div className={`nav-container ${props.classes}`}>
				<div className={`select-menu ${props.noDD ? 'no-dd' : ''}`}>
					<button className="title" onClick={() => showMenu()}>
						<span className="menu-text">{title}</span>
						<span className="menu-arrow">{arrow}</span>
					</button>
					<div className={`animation-container${show ? ' show' : ''}`}>
						<div className={`select-menu-dropdown ${show ? 'show' : ''}`}>
							{props.menuItems.map((d, i) => {
								return (
									<button
										key={d.name}
										alt={d.name}
										title={d.name}
										className={
											isMatch(props.isActive, d.value, d.match) ? 'option clicked' : 'option'
										}
										onClick={() => {
											props.clickFn(d.value);
											showMenu();
										}}>
										<i className="wim-icon-check" alt={d.name} />
										<span className="option-name">{d.name}</span>
									</button>
								);
							})}
						</div>
					</div>
				</div>

				 {/* RLL 5/7/2024:
				 	added capability of passing disabled as a class for each item
					by passing a classes param for the item.
					Example:
					{ name: 'S5', value: 'set_5', classes: 'disabled' }

					If disabled is passed a class, component will write out a <span>
					for the element instead of an <a>
				  */}

				<ul className={`page-nav-list ${props.classes} ${props.noDD ? 'no-dd' : ''}`} role="list">
					{props.menuItems.map((d, i) => {
						let val = d.value;
						let url = props.pathname ? props.pathname.replace('<item>', val) : val;
						let external = false;
						let isDisabled = d?.classes?.includes('disabled') ? true : false;
						if (val.indexOf('http') == 0) {
							external = true;
							url = val.split('?qs=');
							url = url[0] + '?qs=' + encodeURIComponent(url[1]);
						}

						return (
							<React.Fragment key={i}>
								<li
									className={
										'page-nav-items' +
										(isMatch(props.isActive, d.value, d.match) ? ' active' : '') +
										' ' +
										props.classes +
										' ' +
										(d.classes ? d.classes : '') +
										' ' +
										(d.liveIndicator ? 'live-indicator' : '') +
										' ' +
										(d.isLive ? 'is-live' : '')
									}
									alt={d.name}
									aria-label={d.name}
									title={d.name}
									tabIndex={0}
									role="listitem">
									{!isMatch(props.isActive, d.value, d.match) && !isDisabled ? (
										props.pathname || props.useLink ? (
											<EventsLink
												to={url}
												external={external}
												target="_self"
												title={d.name}
												bypassAppLink={props?.bypassAppLink}>
												{d.name}
											</EventsLink>
										) : (
											<a
												href={val}
												tabIndex={0}
												onClick={e => clickHandler(e, val)}
												alt={`link to ${d.name}`}
												title={`link to ${d.name}`}
												aria-label={`link to ${d.name}`}
												onKeyPress={e => keyHandler(e, val)}>
												{d.name}
											</a>
										)
									) : (
										<span alt={d.name} aria-label={d.name} title={d.name}>
											{d.name}
										</span>
									)}
								</li>
								{i != (props.menuItems.length - 1) ?
									<li className="divider"></li>
									: null
								}
							</React.Fragment>
						);
					})}
				</ul>
			</div>
		</div>
	);
};
export default PageNav;
