/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import IBMwatsonx from 'appdir/components/common-ui/IBMwatsonx';
import ButtonLinkText from 'appdir/components/common-ui/ButtonLinkText';
import { getAppLink } from 'appdir/components/general/Util';
// import { getInterfaceLink } from 'appdir/components/general/Util/index.js';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: AICatchUpBox
 * 
 * currently used in slamtracker preview and summary tabs
 * 
 * props:
 *   players: header text (required)
 *   shot insight data: required
 *   stRelatedData: associated article data
 * -----------------------------------------------------------------------------
 */
const AICatchUpBox = props => {
    logger.log('[AICatchUpBox] props:%o', props);
    const {stRelatedData, data, type} = props;
    const maxCount = useSelector((state) => state?.['Config']?.slamtracker?.matchInsightsMaxCount ?? 3);
    const dispatch = useDispatch();
    let headerText = type == 'preview' ? 'Preview' : 'Recap';
    let showArticle = type == 'recap' ? false : true;

	const goToArticle = (path, id) => {
        if (window.webview) {		
            // let cmsId = { id: id};
            // if(window.webviewPlatform == 'ios'){
            //     const interfaceLink = getInterfaceLink('news', cmsId);
            //     interfaceLink?.onclick();
            // }else{
                let link = getAppLink(path, '');
                location.href = link.url;
            // }
		} else if (path) {
            logger.log('[AICatchUpBox] goToArticle path:%o', path);
			if (props.windowSize == 'mobile') {
				dispatch(deps.actions.Controller.setSTPanelStatus({ open: false }));
			}
            dispatch(deps.actions.MainNav.navigate({path: path}));
		}
    }

   

    const relatedDataType = type == 'preview' ? "match preview" : "match recap"; 
    let stArticleData = null;
    if(stRelatedData){
        stArticleData = stRelatedData.filter(item => {
            return item.subType == relatedDataType;
        })?.[0];
    }

    if(!data && (!stArticleData || !showArticle))
        return null;

    logger.log('[AICatchUpBox] stArticleData:%o', stArticleData);

    return (
        <>
            <div className={`catch-up-article ${!stArticleData || !showArticle ? 'noArticle' : ''} ${!data ? 'noBox' : ''}`}>                
                {data &&
                    <div className="st-ai-catch-up-box blue-border">
                        <div className="ai-catch-up-header">
                            <div className="title">{`Match ${headerText}`}</div>                            
                            <IBMwatsonx styleClasses='subtext'/>
                        </div>
                        <div className="ai-catch-up-content">
                            <ul>
                                {props?.data?.map((c, i) => {
                                    if (i < maxCount) {
                                        return (
                                            <li key={`sentence-${i}`}>{c.sentence}</li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                }
                {(stArticleData && type == 'preview' ) &&
                    <ButtonLinkText className="article-link" onClick={() => goToArticle(stArticleData?.url, stArticleData?.contentId) }>
                        <div className='article-image'>
                            <img src={stArticleData?.images?.[0]?.medium} alt={stArticleData?.description} />
                        </div>
                        <div className='article-title'>
                            <div className='title'>{stArticleData?.shortTitle}</div>
                            <div className='description'>{stArticleData?.description}</div>
                        </div>
                    </ButtonLinkText>
                }
            </div>
        </>
    )
};

export default AICatchUpBox;