/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { measureInAppContext, measureInWeb } from 'appdir/components/general/Analytics';

import Collapsible from 'react-collapsible';

/** import stage items */
import StageSet from 'appdir/components/pages/Slamtracker/elements/stage/StageSet';
import { getStatusConfig } from 'appdir/components/pages/Slamtracker/Utilities';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Stage
 * -----------------------------------------------------------------------------
 */

const Stage = props => {
	// logger.log('[SlamtrackerContent] Stage props:%o', props);
	const [statsType, setStatsType] = useState('overall');
	const [statsSet, updateStatsSet] = useState('match');
	const [selectOpen, setSelectOpen] = useState();
	const statMatch = useSelector(state => state?.['ScoreManager']?.statMatch ?? {});
	const pointsBySet = useSelector(state => state?.['Slamtracker']?.pointsBySet ?? {});
	const pointsById = useSelector(state => state?.['Slamtracker']?.pointsById ?? {});
	const expandedPoints = useSelector(state => state?.['Slamtracker']?.expandedPoints ?? {});
	const pointHistory = useSelector(state => state?.['ScoreManager']?.statMatch?.pointHistory ?? {});
	const slamtrackerData = useSelector(state => state?.['Config']?.slamtracker ?? {});
	const currentSet = statMatch?.scores?.sets.length; //current set
	const matchStatus = getStatusConfig(statMatch?.statusCode)['status'];

	const [activeSet, setActiveSet] = useState(null);
	const [isAccordionOpen, setIsAccordionOpen] = useState(null);
	const [clicked, setClicked] = useState(false); // helper to track accordion open trigger

	/** get the current live tab -- Live or Recap */
	let currentTab;

	if(matchStatus == 'complete' && props.currentTab == 'live') {
		currentTab = 'Recap'
	}
	if(matchStatus == 'live' && props.currentTab == 'live') {
		currentTab = 'Live'
	}

	useEffect(() => {
		if (statMatch?.scores?.sets?.length > 0) {
			setActiveSet(currentSet - 1)
		}
	}, [currentSet]);

	/** Handle Collapsible open states */
	// const handleSetClick = index => {
	//   if(activeSet == index){
	//     setIsAccordionOpen(false);
	//   } else {
	//     setIsAccordionOpen(true)
	//   }

	//   setActiveSet(prevIndex => (prevIndex === index ? null : index));
	//   props?.measureIndex(`${currentTab}`, `Set Accordion:${!isAccordionOpen ? 'Expand' : 'Close'}`);
	// };

	// const onOpen = () => {
	//   setIsAccordionOpen(true);
	// }
	const onOpen = () => {
		setIsAccordionOpen(true);
		if(clicked) {
			props?.measureIndex(`Set Accordion:Expand`, '', []);
		}
	}
	const onClose = () => {
		setIsAccordionOpen(false);
		props?.measureIndex(`Set Accordion:Close`, '', []);
	}

	/** map data from points history and render */
	const renderPointsBySet = () => {
		/** 
		 * RLL 6/25/24 changed this to 2 so stage doesn't show for 
		 * players arrive on court and players warming up 
		 **/
		if (pointHistory.length > 2) {
			return Object.keys(pointsBySet).map((setNo, i) => {
				// logger.log('[Stage] statMatch - renderPointsBySet - index:%o, activeSet:%o, currentSet:%o', i, activeSet, currentSet);
				const setData = {
					setObj: pointsBySet[setNo],
					setNo,
					pointsById: pointsById,
					lastPointData: statMatch.pointHistory[statMatch.pointHistory.length - 1],
					statMatch: statMatch,
					pointHistory,
					currentSetObj: pointsBySet[currentSet],
				};
				return (
					<Collapsible
						key={i}
						trigger={`Set ${i + 1}`}
						triggerOpenedClassName="triggerOpen"
						openedClassName="opened_set"
						accordionPosition={`set_${i}`}
						open={activeSet === i}
						// handleTriggerClick={() => handleSetClick(i)}
						contentInnerClassName={'stage-inner'}
						// onOpen={onOpen}
						onOpen={() => onOpen()}
						onClose={() => onClose()}
						onTriggerOpening={() => setClicked(true)}
						>
						<StageSet measureIndex={props?.measureIndex} currentTab={currentTab} key={i} data={{ ...setData }} matchStatus={matchStatus} />
					</Collapsible>
				);
			});
		} else {
			return null;
		}
	};

	return (
		<div className="slamtracker-stage">
			<div className="accordionWrap">{renderPointsBySet()}</div>
		</div>
	);
}

export default Stage;