import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import concat from 'lodash/concat';
import { fetch } from 'appdir/components/general/Util';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import EventsLink from 'appdir/components/general/EventsLink';
import Slider from 'react-slick';
import op from 'object-path';

const mapStateToProps = (state, props) => {
	return {
		...state['OnCourtSchedule'],
		currentDay: state['ActiveData']['currentDay'],
		playerImageURL: op.get(state['Config'], 'otherData.playerProfileImagePath'),
		EventsWindow: state['WindowSize'].EventsWindow,
		showPowerrankings: op.get(state['Config'], 'otherData.showOncourtPowerRankings', false),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: data => dispatch(deps.actions.OnCourtSchedule.mount(data)),
	unmount: () => dispatch(deps.actions.OnCourtSchedule.unmount()),
});

class OnCourtSchedule extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nextMatches: [],
			scheduleData: [],
		};

		this.measureData = Object.assign({}, props.attributes.data);
		delete this.measureData.content;

		this.init = true;
		this.schedule_init = true;

		// logger.log('[OnCourtSchedule] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentDidMount() {
		// logger.log("[OnCourtSchedule] componentDidMount - state:%o, props: %o",this.state, this.props);

		this.props.mount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[OnCourtSchedule] componentDidUpdate - state:%o, props: %o', this.state, this.props);
		if (this.props.status == 'loaded' && this.props.currentDay && this.init) {
			this.init = false;
			// this.getWPI();
			this.getScheduledMatches();
		}
	}

	componentWillUnmount() {
		this.init = true;
		this.schedule_init = true;
		clearTimeout(this.schedule_timeout);
		this.props.unmount();
	}

	getScheduledMatches() {
		// logger.log('[OnCourtSchedule] getScheduledMatches - props: %o', this.props);
		if (this.schedule_init) {
			let pathUrl = this.props.currentDayPath.replace('<day>', this.props.currentDay.schedule);
			this.schedule_init = false;

			// logger.log('[OnCourtSchedule] getScheduledMatches - pathUrl: %o', pathUrl);
			fetch(pathUrl)
				.then(result => {
					if (!isEqual(this.state.scheduleData.courts, result.courts)) {
						this.setState(
							{
								scheduleData: result,
							},
							() => {
								this.setMatchLists();
							}
						);
					}

					if (this.schedule_timeout) {
						clearTimeout(this.schedule_timeout);
					}

					this.schedule_timeout = setTimeout(() => {
						this.schedule_init = true;
						// this.getWPI();
						this.getScheduledMatches();
					}, 30000);
				})
				.catch(error => {
					this.setState({
						hasError: true,
						errorType: error,
					});
				});
		}
	}

	getWPI() {
		// logger.log('[OnCourtSchedule] getWPI - props: %o', this.props);
		if (this.schedule_init) {
			let wpiPath = this.props.wpiRankings;

			fetch(wpiPath)
				.then(result => {
					this.setState({
						wpiData: result,
					});
				})
				.catch(error => {
					this.setState({
						hasError: true,
						errorType: error,
					});
				});
		}
	}

	setMatchLists() {
		// logger.log('[OnCourtSchedule] setMatchLists - scheduleData:%o', this.state.scheduleData);
		if (!isEmpty(this.state.scheduleData)) {
			let { courts } = this.state.scheduleData;
			let matchList = [];
			let nextList = [];
			let dayList = [];
			let nightList = [];

			let court_order = this.props.courtList.sort((a, b) => a.prestige - b.prestige);
			// logger.log('[OnCourtSchedule] setMatchLists - court_order:%o', court_order);

			courts.map(item => {
				//get day sessions & night sessions
				if (item.session == 1) {
					dayList.push(item);
				} else {
					nightList.push(item);
				}
			});

			let tempD = this.sortNextList(dayList);

			if (tempD.length < 4) {
				//add night sessions
				let tempN = this.sortNextList(nightList);
				matchList = concat(tempD, tempN);
			} else {
				matchList = tempD;
			}

			let tempNextList = [];
			//create list & check for existing court ids
			for (let i = 0; i < court_order.length; i++) {
				tempNextList.push(matchList.find(match => match.courtId === court_order[i].sb_code));
			}
			//remove undefined /empty values
			nextList = tempNextList.filter(el => {
				return el !== undefined;
			});

			// logger.log('[OnCourtSchedule] setMatchLists - tempNextList:%o, nextMatches:%o', tempNextList, nextList);

			this.setState({
				dayList: dayList,
				nightList: nightList,
				nextMatches: nextList,
			});
		}
	}

	sortNextList(sortList) {
		let matchList = [];
		let tempList = [];

		sortList.map(item => {
			item.matches.map(match => {
				//up next matches only
				if (
					match.status != 'Completed' &&
					match.status != 'In Progress' &&
					match.status != 'Postponed' &&
					match.status != 'Suspended'
				){
					tempList.push(match);
				}
			});
		});

		matchList = tempList;
		// logger.log('[oncourt] sortNextList - matchList length: %o', matchList.length);
		return matchList;
	}

	getPlayerWPI(playerId) {
		let ranking = '';

		if (this.state.wpiData) {
			let tourId = playerId.slice(0, 3);
			let wpiData = this.state.wpiData[tourId] ? this.state.wpiData[tourId] : [];

			//search each object for matching playerid
			for (let i = 0; i < wpiData.length; i++) {
				if (wpiData[i].playerid === playerId) {
					ranking = wpiData[i].wpi_rank;
					break;
				}
			}

			// logger.log('[OnCourtSchedule] getPlayerWPI - playerId: %o, ranking: %o', playerId, ranking);
			return ranking;
		}

		return ranking;
	}

	renderMatch(match, i) {
		// logger.log(
		//   "[OnCourtSchedule] renderMatch - match: %o",
		//   match
		// );
		if (!isEmpty(match) && match.hasOwnProperty('team1') && match.team1.length > 0) {
			let p1A_img, p1B_img, p2A_img, p2B_img;
			p1A_img =
				match.team1[0].idA != null ? this.props.playerImageURL.replace('<playerid>', match.team1[0].idA) : '';
			p2A_img =
				match.team2[0].idA != null ? this.props.playerImageURL.replace('<playerid>', match.team2[0].idA) : '';
			p1B_img =
				match.team1[0].idB != null ? this.props.playerImageURL.replace('<playerid>', match.team1[0].idB) : '';
			p2B_img =
				match.team2[0].idB != null ? this.props.playerImageURL.replace('<playerid>', match.team2[0].idB) : '';

			return (
				<div key={match.match_id} className="match">
					<div className="matchInfo">
						{/* {match.statusCode == 'A' ? (
							<span className="time live">Live Now</span>
						) : (
							<span className="time next">Next - After {match.notBefore}</span>
						)} */}
						{/* <span className="time next">
							{match.notBefore ? 'After ' + match.notBefore : ''}
							&nbsp;
						</span> */}
						<span className="type">
							{match.eventName}&nbsp;{match.roundNameShort}
						</span>
						<span className="court">{match.courtName}</span>
					</div>
					{match.eventCode.includes('S') || match.eventCode.includes('Q') ? (
						<div className="playerInfo">
							<div className="player1">
								<EventsLink
									to={`/en_US/players/overview/${match.team1[0].idA}.html`}
									measureData={{
										...this.measureData,
										itemTitle: 'Player Bio',
										link: `/en_US/players/overview/${match.team1[0].idA}.html`,
										actionType: 'homePageClick',
										which: `item_${i}_team1_player1`,
									}}>
									<img src={p1A_img} />
									{match.team1[0].displayNameA}
								</EventsLink>
							</div>
							<div className="vs">vs</div>
							<div className="player2">
								<EventsLink
									to={`/en_US/players/overview/${match.team2[0].idA}.html`}
									measureData={{
										...this.measureData,
										itemTitle: 'Player Bio',
										link: `/en_US/players/overview/${match.team2[0].idA}.html`,
										actionType: 'homePageClick',
										which: `item_${i}_team2_player1`,
									}}>
									<img src={p2A_img} />
									{match.team2[0].displayNameA}
								</EventsLink>
							</div>
						</div>
					) : (
						<div className="playerInfo doubles">
							<div className="team1">
								<div className="images">
									<EventsLink
										to={`/en_US/players/overview/${match.team1[0].idA}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Player Bio',
											link: `/en_US/players/overview/${match.team1[0].idA}.html`,
											actionType: 'homePageClick',
											which: `item_${i}_team1_player1`,
										}}>
										<img src={p1A_img} />
									</EventsLink>
									<EventsLink
										to={`/en_US/players/overview/${match.team1[0].idB}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Player Bio',
											link: `/en_US/players/overview/${match.team1[0].idB}.html`,
											actionType: 'homePageClick',
											which: `item_${i}_team1_player2`,
										}}>
										<img src={p1B_img} />
									</EventsLink>
								</div>
								<div className="names">
									<EventsLink
										to={`/en_US/players/overview/${match.team1[0].idA}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Player Bio',
											link: `/en_US/players/overview/${match.team1[0].idA}.html`,
											actionType: 'homePageClick',
											which: `item_${i}_team1_player1`,
										}}>
										{match.team1[0].displayNameA}
									</EventsLink>{' '}
									/<br />
									<EventsLink
										to={`/en_US/players/overview/${match.team1[0].idB}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Player Bio',
											link: `/en_US/players/overview/${match.team1[0].idB}.html`,
											actionType: 'homePageClick',
											which: `item_${i}_team1_player2`,
										}}>
										{match.team1[0].displayNameB}
									</EventsLink>
								</div>
							</div>
							<div className="vs">vs</div>
							<div className="team2">
								<div className="images">
									<EventsLink
										to={`/en_US/players/overview/${match.team2[0].idA}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Player Bio',
											link: `/en_US/players/overview/${match.team2[0].idA}.html`,
											actionType: 'homePageClick',
											which: `item_${i}_team2_player1`,
										}}>
										<img src={p2A_img} />
									</EventsLink>
									<EventsLink
										to={`/en_US/players/overview/${match.team2[0].idB}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Player Bio',
											link: `/en_US/players/overview/${match.team2[0].idB}.html`,
											actionType: 'homePageClick',
											which: `item_${i}_team2_player2`,
										}}>
										<img src={p2B_img} />
									</EventsLink>
								</div>
								<div className="names">
									<EventsLink
										to={`/en_US/players/overview/${match.team2[0].idA}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Player Bio',
											link: `/en_US/players/overview/${match.team2[0].idA}.html`,
											actionType: 'homePageClick',
											which: `item_${i}_team2_player1`,
										}}>
										{match.team2[0].displayNameA}
									</EventsLink>{' '}
									/<br />
									<EventsLink
										to={`/en_US/players/overview/${match.team2[0].idB}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Player Bio',
											link: `/en_US/players/overview/${match.team2[0].idB}.html`,
											actionType: 'homePageClick',
											which: `item_${i}_team2_player2`,
										}}>
										{match.team2[0].displayNameB}
									</EventsLink>
								</div>
							</div>
						</div>
					)}
					{/* {this.props.showPowerrankings ? (
						match.eventCode.includes('WS') || match.eventCode.includes('MS') ? (
							<div className="wpi">
								<div className="title">Power Index</div>
								<div className="rankings">
									<div className="player1">{this.getPlayerWPI(match.team1[0].idA)}</div>
									<div className="vs">&nbsp;</div>
									<div className="player2">{this.getPlayerWPI(match.team2[0].idA)}</div>
								</div>
							</div>
						) : (
							<div className="wpi doubles">&nbsp;</div>
						)
					) : null} */}
				</div>
			);
		}
	}

	render() {
		logger.log('[OnCourtSchedule] render - state:%o, props: %o', this.state, this.props);

		let windowWidth = this.props.EventsWindow.windowWidth;
		let viewCount;

		if (windowWidth <= 640) {
			var moduleSize = windowWidth;
			var imageSize = 325;
			viewCount = moduleSize / imageSize;
		} else {
			var moduleSize = 660;
			var imageSize = 294;
			viewCount = moduleSize / imageSize;
		}

		// logger.log('[OnCourtSchedule] render - windowWidth:%o, viewCount: %o', windowWidth, viewCount);

		var settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: viewCount,
			slidesToScroll: 1,
		};

		logger.log('[OnCourtSchedule] render - nextMatches:%o', this.state.nextMatches);

		return (
			<div className="colorWrapper">
				<div id="oncourt-wrapper">
					<ErrorBoundary message="On Court matches are not available at this time.">
						{!this.state.hasError ? (
							<div id="oncourt-sub-wrapper">
								{!isEmpty(this.state.nextMatches) ? (
									<div className="oncourt">
										<div className="header">
											<div className="title">On Court Next</div>
											<div className="link">
												<EventsLink
													to={'/en_US/scores/schedule/index.html'}
													measureData={{
														...this.measureData,
														itemTitle: 'Schedule Link',
														link: '/en_US/scores/schedule/index.html',
														actionType: 'homePageClick',
														which: `action`,
													}}>
													Schedule of Play &gt;
												</EventsLink>
											</div>
										</div>
										<div className="content">
											{this.state.nextMatches.length == 1 ? (
												<div className="match-carousel-container noSlider">
													{this.state.nextMatches.map((match, i) => {
														return this.renderMatch(match, i);
													})}
												</div>
											) : (
												<div className="match-carousel-container">
													<Slider {...settings}>
														{this.state.nextMatches.map((match, i) => {
															return this.renderMatch(match, i);
														})}
													</Slider>
												</div>
											)}
										</div>
									</div>
								) : (
									<div />
								)}
							</div>
						) : (
							<div />
						)}
					</ErrorBoundary>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OnCourtSchedule);
