import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MATCHINSIGHTSMODAL_MOUNT:
			// logger.log("[MatchInsights] MATCHINSIGHTSMODAL_MOUNT - state:%o", state);
			newState = {
				...state,
				...action.data,
			};
			// logger.log("[MatchInsights] MATCHINSIGHTSMODAL_MOUNT - newState:%o", newState);
			return newState;

		case deps.actionTypes.MATCHINSIGHTSMODAL_UNMOUNT:
			// logger.log("[MatchInsights] MATCHINSIGHTSMODAL_MOUNT - state:%o", state);
			newState = {
				...state,
				...action.data,
			};
			// logger.log("[MatchInsights] MATCHINSIGHTSMODAL_MOUNT - newState:%o", newState);
			return newState;

		default:
			return state;
	}
};
