import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.MATCH_INSIGHTS_MOUNT:
			logger.log('[MatchInsights] reducers - MATCH_INSIGHTS_MOUNT action:%o', action);

			newState = {
				...state,
				...action.data,
			};
			logger.log('[MatchInsights] reducers MATCH_INSIGHTS_MOUNT - newState:%o', newState);
			return newState;

		case deps.actionTypes.MATCH_INSIGHTS_UNMOUNT:
			logger.log('[MatchInsights] reducers - MATCH_INSIGHTS_UNMOUNT action:%o', action);

			newState = {
				...state,
				...action.data,
			};
			logger.log('[MatchInsights] reducers MATCH_INSIGHTS_UNMOUNT - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
