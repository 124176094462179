import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactHtmlParser from 'html-react-parser';

export default class IframeScript extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props, {
			iFrameHeight: '0px',
			iFrameScroll: 'auto',
		});
		logger.log('[IframeScript] constructor: %o', props);
	}

	componentDidMount() {
		logger.log('[IframeScript] componentDidMount');
		if (this.state.data) {
			logger.log('[IframeScript] componentDidMount script %o', this.state.data.link);
		}
	}

	updateIframeHeight(height) {
		if (!height) {
			height = '650px';
		}
		logger.log('[IframeScript] height:%o', height);
		this.setState({
			iFrameHeight: height,
			iFrameScroll: 'auto',
		});
	}

	render() {
		// logger.log("[IframeScript] render - state:%o", this.state);
		return this.state ? (
			<div className="premium_content_full" ref={el => (this.instance = el)}>
				{ReactHtmlParser(this.state.data.text)}
				<iframe
					style={{
						width: this.state.data.width ? this.state.data.width : '100%',
						minHeight: this.state.data.height ? this.state.data.height : '1800px',
						height: this.state.data.height ? this.state.data.height : this.state.iFrameHeight,
						overflow: this.state.data.overflow ? this.state.data.overflow : 'visible',
					}}
					onLoad={() => {
						const obj = ReactDOM.findDOMNode(this.refs['iframe']);
						this.updateIframeHeight(obj.contentWindow.document.body.scrollHeight + 50 + 'px');
					}}
					ref="iframe"
					src={this.state.data.link}
					width={this.state.data.width ? this.state.data.width : '100%'}
					height={this.state.data.height ? this.state.data.height : this.state.iFrameHeight}
					scrolling={this.state.data.scrolling ? this.state.data.scrolling : this.state.iFrameScroll}
					frameBorder={this.state.data.frameBorder ? this.state.data.frameBorder : '0'}
					className={this.state.data.className ? this.state.data.className : 'form-iframe'}
				/>
			</div>
		) : null;
	}
}
