import deps from "dependencies";

export default (state = {}, action) => {
  let newState;

  //logger.log("[FanWeek] reducer - action: %o", action);

  switch (action.type) {
    case deps.actionTypes.FANWEEK_MOUNT:
      newState = Object.assign({}, state, { status: "loading" });
      if (newState.hasOwnProperty("error")) {
        delete newState.error;
      }
      return newState;

    case deps.actionTypes.FANWEEK_ERROR:
      logger.log("[FanWeek] reducer.FANWEEK_ERROR - action:%o", action);
      newState = Object.assign({}, state, {
        status: "error",
        error: action.error
      });
      return newState;

    case deps.actionTypes.FANWEEK_LOAD:
      logger.log("[FanWeek] reducer.FANWEEK_LOAD");

      newState = {
        ...state,
        ...action.data
      };

      return newState;

    default:
      return state;
  }
};
