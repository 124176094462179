import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.HOMEPREVIEW_MOUNT: {
			newState = {
				...state,
				...action.data,
				end: action.data.start + action.data.end,
			};
			logger.log('[HomePreview] reducer - HOMEPREVIEW_MOUNT: %o', newState);

			return newState;
		}

		default:
			return state;
	}
};
