/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import VideoPlayer from 'appdir/components/content/VideoPlayer';
import deps from 'dependencies';
import { connect } from 'react-redux';
import get from 'lodash/get';
import VisibilitySensor from 'react-visibility-sensor';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['Video'],
		anyclipConfig: state?.['Config']?.videoPlayer?.anyclip ?? {},
		...props,
	};
};

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Video
 * -----------------------------------------------------------------------------
 */
class WatchRadio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoData: null,
			displayThumbnail: false,
			inlineLoaded: false,
		};

		// active.json
		if (!this.script) {
			this.script = document.createElement('script');
			this.script.async = true;
			this.script.src = 'https://live.geniusplus.ai/anyclip-widget/lre-widget/prod/v1/src/lre.js'; //add to config
			this.script.setAttribute('data-ar', '16:9');
			this.script.setAttribute('data-tm-leid', this?.props?.dataTmLead);
			this.script.setAttribute('pubname', '1987');
			this.script.setAttribute('widgetname', '0016M00002Tye8hQAB_M11133');
		}

		this.inlineWidget = null;
		this.inlineReady = false;
		this.inlineSubscribed = false;

		this.isAshe = document.location.href.includes('ashe.') ? true : false;
		this.isLocalhost =
			document.location.href.includes('localhost') || document.location.href.includes('dev.usopen.org')
				? true
				: false;

		// logger.log('[Video] constructor this:%o', this);
	}

	componentWillUnmount() {
		// logger.log('[Video] componentWillUnmount %o', this);
		['__uspapi', '__cmp', '__tcfapi'].forEach(function(e) {
			if (window[e] !== null) {
				logger.log('[VideoLanding] componentWillUnmount %o exists, deleting', e);
				delete window[e];
			}
		});
		this.setState(
			{
				inlineLoaded: false,
			},
			() => {
				this.inlineWidget = null;
				this.inlineReady = false;
				this.inlineSubscribed = false;
			}
		);
	}

	componentDidMount() {
		// logger.log('[Video] componentDidMount %o', this);

		this.showVideo();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[Video] componentDidUpdate prevProps:%o prevState:%o, this:%o', prevProps, prevState, this);
		/**
		 * once player has loaded or if video data changes,
		 * subscribe to playerOutOfPort message to set playlist
		 * and current time in redux state to be used
		 * by the AnyclipFloat component
		 **/
		if (
			this.state?.inlineLoaded === true &&
			prevState?.inlineLoaded === false &&
			this.inlineWidget &&
			!this.inlineSubscribed
		) {
			let playerName = 'Radio watch player';

			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'adImpression', playerName);
			}, 'adImpression');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'adSkipped', playerName);
			}, 'adSkipped');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'adComplete', playerName);
			}, 'adComplete');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerClipImpression', playerName, 'Play');
			}, 'playerClipImpression');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerFirstQuartile', playerName, 'Play:25%');
			}, 'playerFirstQuartile');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerMidpoint', playerName, 'Play:50%');
			}, 'playerMidpoint');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerThirdQuartile', playerName, 'Play:75%');
			}, 'playerThirdQuartile');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerClipComplete', playerName, 'Play:Complete');
			}, 'playerClipComplete');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerFullscreen', playerName);
			}, 'playerFullscreen');
			this.inlineWidget.subscribe(e => {
				let mute_status = e?.isMute == 0 ? 'Unmuted' : 'Muted';
				this.measureEvent(e, 'playerMute', `Play:${mute_status}`);
			}, 'playerMute');
			this.inlineWidget.subscribe(e => {
				let pause_status = e?.isPaused === true ? 'Pause' : 'Resume';
				this.measureEvent(e, 'playerPause', `Play:${pause_status}`);
			}, 'playerPause');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'ShareScreenOpen', playerName);
			}, 'ShareScreenOpen');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'playerTheaterModeShare', playerName);
			}, 'playerTheaterModeShare');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'ShareVideoClicked', playerName);
			}, 'ShareVideoClicked');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxDisplayed', playerName);
			}, 'LuxDisplayed');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxHidden', playerName);
			}, 'LuxHidden');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxUserOpen', playerName);
			}, 'LuxUserOpen');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxUserClose', playerName);
			}, 'LuxUserClose');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxLabelDisplay', playerName);
			}, 'LuxLabelDisplay');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxLabelHide', playerName);
			}, 'LuxLabelHide');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxClickVideo', playerName);
			}, 'LuxClickVideo');
			this.inlineWidget.subscribe(e => {
				this.measureEvent(e, 'LuxButtonClick', playerName);
			}, 'LuxButtonClick');
		}
	}

	measureEvent = (e, player_event, player, alt_event) => {
		logger.log('[WatchRadio] measureEvent - e:%o, player_event:%o', e, player_event);
		// logger.log('[Video] measureEvent - this.inlineWidget:%o', this.inlineWidget);

		// logger.log(
		// 	'[Video] measureEvent - data:%o',
		// 	this.inlineWidget?.getTMplaylist()[this.inlineWidget?.playlistCurrentIndex()]
		// );

		let title;
		let category = null;
		let matchId = '0000';
		let matchFound = null;
		let labels;

		title = this.inlineWidget?.getTMplaylist()[this.inlineWidget?.playlistCurrentIndex()]?.title || null;
		labels = this.inlineWidget?.getCurrentPlaylistItem()?.label || [];

		// logger.log('[Video] measureEvent - labels:%o', labels);

		if (labels.length > 0) {
			category =
				labels.find((label, i) => {
					return label.name == 'category';
				}).value || null;

			// logger.log('[Video] measureEvent - category:%o, categoryFound:%',category, categoryFound);

			if (category == 'cognitive match highlights') {
				matchFound = filter(labels, (label, i) => {
					return label.name == 'matchid';
				});

				/** isolate match id value from string */
				if (matchFound) {
					matchId = matchFound[0].value.slice(matchFound[0].value.indexOf('_') + 1);
				}
			}

			// logger.log('[Video] measureEvent - categoryFound:%o, matchId:%o', categoryFound, matchId);
		}

		if (window.webview) {
			let contextData = [];
			if (player_event == 'playerClipImpression' && category == 'cognitive match highlights') {
				contextData = [
					{ content_vod: title },
					{ vod_category: category },
					{ vod_matchid: matchId },
					{ ai_commentary: 'aiAA:true|aiCC:true' },
					{ player_event: player_event },
				];
			} else {
				contextData = [
					{ content_vod: title },
					{ vod_category: category },
					{ vod_matchid: matchId },
					{ player_event: player_event },
				];
			}

			// if (player_event == 'playerClipImpression') {
			// 	contextData.push({ tab: this.props.tab });
			// }

			let metricsVal = ['metrics', `Video${alt_event ? `:${alt_event}` : ''}`];

			logger.log('[Video] measureEvent - contextData:%o', contextData);

			measureInApp(metricsVal, contextData);
		} else {
			let metricsData = {};
			if (player_event == 'playerClipImpression') {
				metricsData = {
					playerEventData: e ? e : null,
					// title: title,
					// category: category,
					// matchId: matchId,
					// tab: this.props.tab,
				};
			} else {
				metricsData = {
					playerEventData: e ? e : null,
					title: title,
					category: category,
					matchId: matchId,
				};
			}

			MeasurementUtils.dispatchMeasurementCall(player, {
				action: player_event,
				metricsData: metricsData,
			});
		}
	};

	showVideo = () => {
		if (!window.webview) {
			this.inlineSubscribed = false;

			let prefix = this.isAshe || this.isLocalhost ? 'ashe' : '';

			// uncomment for autoplay
			this.script.setAttribute('data-ap', 'on');

			logger.log('[WatchRadio] showVideo this.script:%o', this.script);
			let videoContainer = document.getElementById('anyclip-container-watchRadio');
			videoContainer.appendChild(this.script);

			window.addEventListener('message', event => {
				if (
					event?.data?.length > 0 &&
					event?.data?.indexOf('lre:playerReady://') === 0 &&
					!this.state?.inlineLoaded &&
					!this.inlineWidget
				) {
					logger.log('[WatchRadio] showVideo playerReady event fired:%o', event);

					let widget_data = JSON.parse(event.data.slice('lre:playerReady://'.length));

					this.inlineWidget = window.anyclip.getWidget(null, widget_data['sessionId']);
					if (this.inlineWidget) {
						this.inlineReady = true;
					}

					this.setState(
						{
							inlineLoaded: true,
						},
						() => {
							MeasurementUtils.dispatchMeasurementCall('playerReady', {
								action: 'playerReady',
								metricsData: widget_data,
							});
						}
					);
				}
			});
		}
	};

	render() {
		// let share_link = {};
		// if (
		// 	this.props.data.video &&
		// 	this.props.data.video.shareUrl &&
		// 	this.props.data.video.shareUrl.startsWith('http')
		// ) {
		// 	share_link['share-link'] = this.props.data.video.shareUrl;
		// }
		let videoStyle = {
			position: 'absolute',
			width: '100%',
			top: '30%',
		};

		return (
			<div className="inlineVideo" id={`anyclip_watchRadio_inline`}>
				<div id="anyclip-container-watchRadio"></div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(WatchRadio);
