import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//logger.log('[GroundsMap] mount - data:%o', data);
			dispatch({
				type: deps.actionTypes.OPEN_QUESTIONS_MOUNT,
				basePicPath: Config.otherData.basePicPath,
			});
		});
	},
};
