import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export default {
	// mount: () => (dispatch, getState, store) => {
	// 	return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
	// 		let data = {
	// 			status:'loaded'
	// 		};
	// 		dispatch({ type: deps.actionTypes.COMMONDATA_MOUNT, data });
	// 	});
	// },
	update: params => (dispatch, getState) => {
		dispatch({ type: deps.actionTypes.COMMONDATA_LOADING, ...params });

		logger.log('[CommonData]  deps.actions.update - params:%o', params);
		return deps.services.CommonData.fetch(params.path)
			.then(result => {
				//logger.log('[CommonData] loadConfigHelper - type:%o result:%o', typeof(result), result);
				if (typeof result === 'object') {
					dispatch({
						type: deps.actionTypes.COMMONDATA_UPDATE,
						configId: params.configId,
						key: params?.key,
						data: {
							result,
							updated: moment().toISOString(),
							status: 'loaded',
						},
					});
					return 'loaded';
				} else {
					dispatch({
						type: deps.actionTypes.COMMONDATA_ERROR,
						configId: params.configId,
						key: params?.key,
						data: {
							error: 'json parse',
						},
					});
					return 'error';
				}
			})
			.catch(err => {
				dispatch({
					type: deps.actionTypes.COMMONDATA_ERROR,
					configId: params.configId,
					key: params.key,
					data: {
						error: err,
					},
				});
				return 'error';
			});
	},

	error: params => (dispatch, getState) => {
		dispatch({
			type: deps.actionTypes.COMMONDATA_ERROR,
			configId: params.configId,
			key: params.key,
			data: {
				error: params.err,
			},
		});
	},
	checkExpired: dataConfig => (dispatch, getState, store) => {
		return deps.services.CommonData.checkExpired(dispatch, store, dataConfig);
	},
};
