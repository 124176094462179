export default {
	CONTROLLER_UPDATE: 'CONTROLLER_UPDATE',
	CONTROLLER_MOUNT: 'CONTROLLER_MOUNT',
	CONTROLLER_VIDEO_PLAY: 'CONTROLLER_VIDEO_PLAY',
	CONTROLLER_VIDEO_STOP: 'CONTROLLER_VIDEO_STOP',
	CONTROLLER_VIDEO_MUTE: 'CONTROLLER_VIDEO_UNMUTE',
	CONTROLLER_SET_SCORING_STATUS: 'CONTROLLER_SET_SCORING_STATUS',
	CONTROLLER_SETPLATFORM: 'CONTROLLER_SETPLATFORM',
	CONTROLLER_UPDATEVIEWEDCONTENT: 'CONTROLLER_UPDATEVIEWEDCONTENT',
	CONTROLLER_UPDATEDATE: 'CONTROLLER_UPDATEDATE',
	CONTROLLER_UPDATEUOM: 'CONTROLLER_UPDATEUOM',
	CONTROLLER_SETSTPANEL_STATUS: 'CONTROLLER_SETSTPANEL_STATUS',
	CONTROLLER_SETWINDOWSIZE: 'CONTROLLER_SETWINDOWSIZE',
	CONTROLLER_SETSTWINDOWSIZE: 'CONTROLLER_SETSTWINDOWSIZE',
	CONTROLLER_SETMIWINDOWSIZE: 'CONTROLLER_SETMIWINDOWSIZE',
	CONTROLLER_UPDATEGEOBLOCK: 'CONTROLLER_UPDATEGEOBLOCK',
	CONTROLLER_UPDATEVIDEOCHANNELS: 'CONTROLLER_UPDATEVIDEOCHANNELS',
	CONTROLLER_SET_MENUITEMS: 'CONTROLLER_SET_MENUITEMS',
	CONTROLLER_SET_GLOBALLINK: 'CONTROLLER_SET_GLOBALLINK',
	CONTROLLER_SCORING_MODULE_LOADED: 'CONTROLLER_SCORING_MODULE_LOADED',
	CONTROLLER_SCORECONNECTION_READY: 'CONTROLLER_SCORECONNECTION_READY',
};
