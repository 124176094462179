import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import ReactHtmlParser from 'html-react-parser';

const CheckerBoard = props => {
	logger.log('[CheckerBoard] props:%o', props.attributes);
	let article = props.attributes.data;
	let { content } = article;

	let measureData = Object.assign({}, props.attributes.data);
	delete measureData.content;

	return (
		<div className="checkered">
			{content.items.map((item, i) => {
				return (
					<div className={`checkContent ${item.style}`} key={`${item.id}-${i}`}>
						<div className="photoContainer">
							<EventsLink
								to={item.link}
								measureData={{
									...item,
									...measureData,
									itemTitle: item.title,
									itemType: item.type,
									itemId: item.id,
									actionType: 'homePageClick',
									which: 'photo',
								}}>
								<img src={item.images[0].url} alt={item.images[0].caption} />
								{item.imageType === 'Video' ? <div className="playBtn">&nbsp;</div> : null}
							</EventsLink>
						</div>
						<div className="captionContainer">
							<EventsLink
								to={item.link}
								measureData={{
									...item,
									...measureData,
									itemTitle: item.title,
									itemType: item.type,
									itemId: item.id,
									actionType: 'homePageClick',
									which: 'caption',
								}}>
								<h2>{ReactHtmlParser(item.title)}</h2>
							</EventsLink>
							{item.date ? (
								<div className="articleInfo">
									<div className="date">{item.date}</div>
								</div>
							) : null}
							<div className="desc">
								<div className="text">{ReactHtmlParser(item.description)}</div>
								<div className="btn">
									<EventsLink
										to={item.link}
										measureData={{
											...item,
											...measureData,
											itemTitle: item.title,
											itemType: item.type,
											itemId: item.id,
											actionType: 'homePageClick',
											which: 'action',
										}}>
										{item.button}
									</EventsLink>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default CheckerBoard;
