import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';
import deps from 'dependencies';

const mapStateToProps = (state, props) => {
	return {
		...state['LiveIndicators'],
		liveIndicators: state['ActiveData']['liveIndicators'],
		showLiveIndicators: state['ActiveData']['showLiveIndicators'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	toggleMenu: isOpen => dispatch(deps.actions.Header.toggleMenu(isOpen)),
});

class MenuLiveIndicators extends Component {
	constructor(props) {
		super(props);

		this.openRadio = this.openRadio.bind(this);
		this.navClicked = this.navClicked.bind(this);

		logger.log('[MenuLiveIndicators] constructor - this:%o', this);
	}

	navClicked(e) {
		logger.log('[SubNav] navClicked');
		this.props.toggleMenu(false);
	}

	openRadio(stream, debug, promo) {
		let radioWindow = '';
		let radioLaunchPage = '';
		let baseUrl = '/webview/en_US/radio/radio.html';
		let lang = 'en';

		if (this.props.liveIndicators && this.props.liveIndicators.radio) {
			if (isMobile || isTablet) {
				window.open(baseUrl, '_blank');
			} else {
				var d = new Date();
				var t = d.getTime();
				radioLaunchPage += baseUrl + '?ts=' + t;
				radioLaunchPage += debug && debug.indexOf('debug') > -1 ? '&db=true' : '&db=false';
				radioLaunchPage += '&ref=' + document.location.host + document.location.pathname;
				radioLaunchPage += '&lang=' + lang;
				if (radioWindow.closed || radioWindow == '') {
					//consoleWindow = window.open(consolelaunchPage,"consoleWindow","width=510,height=" + mcWindowHeight + ",top=50,left=50")
					radioWindow = window.open(radioLaunchPage, 'radioWindow', 'width=1024,height=708,top=50,left=50');
					if (radioWindow.opener == null) radioWindow.opener = self;
				}
				radioWindow.focus();
			}
		}
	}

	getLiveVideoHtml() {
		let liveClass = this.props.liveIndicators && this.props.liveIndicators.video ? 'onAir' : 'offAir';

		return (
			<a href="/en_US/video/live.html">
				<span className={`${liveClass}`}>Watch Live</span>
			</a>
		);
	}

	getLiveRadioHtml() {
		let liveClass = this.props.liveIndicators && this.props.liveIndicators.radio ? 'onAir' : 'offAir';
		let offUrl = '/en_US/social/radio/index.html';

		return (
			<React.Fragment>
				{this.props.liveIndicators && this.props.liveIndicators.radio ? (
					<div
						onClick={() => {
							this.openRadio(0);
						}}>
						<span className={`${liveClass}`}>Listen Live</span>
					</div>
				) : (
					<a href={offUrl}>
						<span className={`${liveClass}`}>Listen Live</span>
					</a>
				)}
			</React.Fragment>
		);
	}

	render() {
		logger.log('[LiveIndicator] render - this:%o', this);

		if (this.props.showLiveIndicators) {
			return (
				<React.Fragment>
					{this.props.showLiveIndicators.show ? (
						<div className="live-status">
							<div
								className="isLive"
								onClick={e => {
									this.navClicked(e);
								}}>
								{this.getLiveVideoHtml()}
							</div>
							<div
								className="isRadio"
								onClick={e => {
									this.navClicked(e);
								}}>
								{this.getLiveRadioHtml()}
							</div>
						</div>
					) : null}
				</React.Fragment>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuLiveIndicators);
