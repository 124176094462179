import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import RankingMovement from 'appdir/components/common-ui/PowerRanking/RankingMovement';
import EventsLink from 'appdir/components/general/EventsLink';
import { measureInApp } from 'appdir/components/general/Analytics';
import { doMeasurement } from 'appdir/components/general/Util';
import { values } from 'appdir/main';

class LeaderboardRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			favs: this.props.data.appFavs,
		};
		this.toggleRow = this.toggleRow.bind(this);
		this.sortColumn = this.sortColumn.bind(this);
		// this.updateFavs = this.updateFavs.bind(this);

		//logger.log('[LeaderboardRow] constructor this.props:%o', this.props);
	}

	componentDidUpdate(prevProps, prevState) {
		/** tab is changed b/w Mens and Womens, reset the selected column */
		if (
			this.props?.data?.selectedTab !== prevProps?.data?.selectedTab &&
			this.props?.data?.selectedTab !== this.state?.sortedColumn
		) {
			this.setState({
				sortedColumn: null,
			});
		}
	}

	isWebviewFav() {
		logger.log(
			'[LeaderboardRow] - isWebviewFav - favs:%o, %o:%o',
			this.state.favs,
			this.props.data.playerId,
			this.state.favs?.indexOf(this.props.data.playerId) !== -1
		);
		return this.state.favs?.indexOf(this.props.data.playerId) !== -1;
	}

	/** separated the call b/w web and apps throw too many issues - have to do a quick fix */
	doAppsOnlyMeasurement = (pageTitle, measureAction, measure_args, contextData) => {
		let measureArray = [];

		if (window.webview) {
			measureArray = ['Metrics', pageTitle].concat(measureAction);

			/** avoid sending an empty value in measure_args */
			if (measure_args && measure_args?.length > 0) {
				measureArray = measureArray.concat(measure_args);
			}
			//awt:  remove join for usta only
			//.join(':');
			logger.log('[doAppsOnlyMeasurement] measureArray:%o', measureArray);

			/** contextData is new in 2022 for webviews -
			 * passing this will add query strings &key=value to measureInApp
			 * @Array contextData - [{key: value}, {key:value}, {key:value}] */
			measureInApp(measureArray, contextData);
		}
	};

	toggleRow(toggle) {
		// logger.log('[LeaderboardRow] toggleRow props:%o, toggle:%o', this.props, toggle);
		this.props.data.expandCallback(this.props.data.playerId, toggle, this.props.data.expandType);

		let metricsLabel = toggle ? 'Open' : 'Close';
		let metricsObj = [
			{ action: metricsLabel },
			{ pi_playerID: this.props.data.playerId },
			{ pi_player: this.props.data.playerName },
		];
		let measureArgs = metricsObj;
		let contextData = [];

		if (window.webviewPlatform) {
			measureArgs = [];
			contextData = metricsObj;
		}

		doMeasurement(values.powerIndexTitle, 'Expand Row', measureArgs, contextData);
	}
	sortColumn(which) {
		this.setState(
			{
				sortedColumn: which,
			},
			() => {
				this.props.data.sortCallback(which);
			}
		);
		doMeasurement(values.powerIndexTitle, 'sortColumn', [which == 'tourrank' ? 'tourRank' : which]);
	}

	/**
	 * only display draw analysis label if the event is still going
	 * and the player is still in the event
	 *
	 * props.playerEventStatus ----- "in" <-- default, "out",  "won"
	 */
	getDrawAnalysisCol = () => {
		let showDrawAnalysis =
			this.props?.data?.playerEventStatus !== 'out' && this.props?.data?.playerEventStatus !== 'won';

		return (
			<div
				className={`leaderboard-cell aidraw ${
					this.props?.data?.drawAnalysis?.drawLabel && showDrawAnalysis
						? this.props?.data?.drawAnalysis?.drawLabel
						: 'no-bullet'
				}`}>
				<span>{showDrawAnalysis ? this.props?.data?.drawAnalysis?.drawLabel : ''}</span>
			</div>
		);
	};

	render() {
		let { data = {} } = this.props;
		// logger.log(
		// 	'[LeaderboardRow] render playerId:%o, expand:%o, expandType:%o',
		// 	data.playerId,
		// 	data.expand,
		// 	data.expandType
		// );

		let countryIndex;

		if (data.type == 'row' && data?.countriesLookUp && !isEmpty(data?.countriesLookUp)) {
			countryIndex = data?.countriesLookUp?.findIndex((item, index) => item.code === data.countryCode);
		}

		return (
			<>
				{data.type === 'row' ? (
					<>
						<div
							className={`leaderboard-row ${data.expand ? 'expand' : 'collapse'}`}
							onClick={() => this.toggleRow(!data.expand)}>
							<div className="leaderboard-cell powerrank">
								<RankingMovement
									data={{
										rank: data.rank,
										movement: data.movement,
										movementArrowsDisplay: data.powerIndexMovementArrows,
									}}
								/>
							</div>
							<div className="leaderboard-cell tourrank">{data.tourrank}</div>
							<div className="leaderboard-cell playername">
								<img
									alt={countryIndex ? data.countriesLookUp[countryIndex].code : ''}
									src={`${data.flagPath.replace('<countryCode>', data.countryCode)}`}
								/>
								<span className="shortname">
									<EventsLink to={`/en_US/players/overview/${data.playerId}.html`}>
										{data.shortName}
									</EventsLink>
								</span>
								<span className="longname">
									<EventsLink to={`/en_US/players/overview/${data.playerId}.html`}>
										{data.playerName}
									</EventsLink>
								</span>
							</div>
							<div className="leaderboard-cell country">
								<img
									alt={countryIndex ? data.countriesLookUp[countryIndex].code : ''}
									src={`${data.flagPath.replace('<countryCode>', data.countryCode)}`}
								/>
								{countryIndex ? data.countriesLookUp[countryIndex].display : data.countryName}
							</div>

							{/** display or hide the draw analysis label - only players in the tournament to display during the event */
							this.props?.data?.enabled &&
								this.props?.data?.playerEventStatus &&
								this.getDrawAnalysisCol()}
							<div className={`leaderboard-cell expand`}>
								{/* <div className={`${data.expand ? 'open' : 'close'}`}></div> */}
								<i className={`${data.expand ? 'icon-twistie-close' : 'icon-twistie-open'}`} />
							</div>
						</div>
						<div className={`leaderboard-data${data.expand ? ' expand' : ' collapse'}`}>
							{data.expand ? this.props.children : null}
						</div>
					</>
				) : null}
				{data.type === 'header' ? (
					<div className="leaderboard-header">
						{/* <div className={`leaderboard-cell star`}>Fav</div> */}
						<div
							className={`leaderboard-cell powerrank ${
								this.state.sortedColumn === 'powerrank' || !this.state.sortedColumn ? 'sort' : ''
							}`}
							onClick={() => this.sortColumn('powerrank')}>
							<span>Power Index</span>
						</div>
						<div
							className={`leaderboard-cell tourrank ${
								this.state.sortedColumn === 'tourrank' ? 'sort' : ''
							}`}
							onClick={() => this.sortColumn('tourrank')}>
							<span>{data.selectedTab} Ranking</span>
						</div>
						<div
							className={`leaderboard-cell playername ${
								this.state.sortedColumn === 'playername' ? 'sort' : ''
							}`}
							onClick={() => this.sortColumn('playername')}>
							<span>Player</span>
						</div>
						<div
							className={`leaderboard-cell country ${
								this.state.sortedColumn === 'country' ? 'sort' : ''
							}`}
							onClick={() => this.sortColumn('country')}>
							<span>Country</span>
						</div>
						{/** if enabled, check the first player's playerEventStatus to prevent
						 *    the AI Draw Analysis table header text from flashing in case of no label to display
						 */
						this.props?.data?.enabled && (
							<div
								className={`leaderboard-cell aidraw ${
									this.state.sortedColumn === 'aidraw' ? 'sort' : ''
								}`}
								onClick={() => this.sortColumn('aidraw')}>
								{!data.hideAidrawHdr && data.playerEventStatus && <span>AI Draw Analysis</span>}
							</div>
						)}
						<div className="leaderboard-cell expand"></div>
					</div>
				) : null}
				{data.type === 'subheader' ? <div className="leaderboard-subheader">{this.props.children}</div> : null}
			</>
		);
	}
}

LeaderboardRow.defaultProps = {
	// type: 'row',
	// tourrank: false,
	// rank: 7,
	// movement: 0,
	// flagPath: '/assets/images/flags/<countryCode>_sm.gif',
	// countryCode: 'RUS',
	// countryName: 'Russia',
	// playerName: 'Andrey Rublev',
	// playerId: 'atpre44',
};

export default LeaderboardRow;
