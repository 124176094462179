import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		let archive = false;
		let year = 'current';
		logger.log('[Interviews] action.mount - params: %o', params);

		if (params.year && params.year != 'index') {
			archive = true;
			year = params.year;
		}

		//logger.log('[INTERVIEWS] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				path: Config.cmsData.index,
				basePicPath: Config.otherData.basePicPath,
				relatedContentPath: Config.relatedContent.newsFavPlayers,
				archive: archive,
				year: year,
				newsFilterPath: Config.cmsData.newsFilter,
				newsImagePath: Config.cmsData.newsImage,
				adConfig: Config.adConfig,
				adMode: Config.landingPages.adMode,
				sponsor: Config.sponsors.news,
			};
			logger.log('[Interviews] action.mount - data: %o', data);

			dispatch({ type: deps.actionTypes.INTERVIEWS_LOAD, data: data });
		});
	},
	filter: filterData => (dispatch, getState, store) => {
		//   return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
		//     Config => {
		//       let data = {
		//         path: Config.cmsData.newsFilter,
		//         relatedContentPath: filterData.url
		//       };
		//       logger.log("[NewsIndex] action.filter - data: %o", data);
		//       dispatch({ type: deps.actionTypes.NEWSINDEX_FILTER, data: data });
		//     }
		//   );
	},
};
