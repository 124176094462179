import React, { PureComponent } from 'react';

/**
 * -----------------------------------------------------------------------------
 * Class Component: EventCourt
 * @params
 * 	- isFinal
 * 	- sumscores
 * 	- eventName
 * 	- courtName
 * -----------------------------------------------------------------------------
 */

export default class EventCourt extends PureComponent {
	render() {
		// 	logger.log('[MatchBox EventCourt] props:%o', this.props);

		let matchLocation =
			this.props.sumscores === true && this.props.isFinal ? this.props.eventName : this.props.courtName;

		return <span>{matchLocation}</span>;
	}
}
