/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import isEmpty from 'lodash/isEmpty';
import EventsLink from 'appdir/components/general/EventsLink';
import { renderKey } from 'appdir/components/pages/Slamtracker/Utilities';
import Slider from 'react-slick';
import PlayerBox from './elements/PlayerBox';
import InTheMedia from './elements/InTheMedia';

import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsightsContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsightsModal'],
		scoring: state['Controller'].scoring,
		configScoringData: state['Config'].scoringData,
		configOtherData: state['Config'].otherData,
		windowSize: state['Controller'].miWindowSize,
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchInsightsModal.mount()),
	unmount: () => dispatch(deps.actions.MatchInsightsModal.unmount()),
	hideOverlayModal: () => dispatch(deps.actions.ModalOverlay.hideOverlayModal()),
});

class MatchInsightsContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			selectedPlayer: '1',
			infoWindow: false,
			matchData: null,
			p1Keys: null,
			p2Keys: null,
			matchInsightsData: null,
			atAGlance: null,
			inTheMedia: null,
		};

		this.onClose = this.onClose.bind(this);
		this.viewPlayerBio = this.viewPlayerBio.bind(this);

		// logger.log('[MatchInsightsContent] constructor - state:%o', this.state);
	}

	componentDidMount() {
		this.props.mount();
		// document.body.classList.add('modal-open');
	}

	componentWillUnmount() {
		// logger.log('[MatchInsightsContent] componentWillUnmount');
		this.setState({
			matchData: null,
			p1Keys: null,
			p2Keys: null,
			matchInsightsData: null,
			atAGlance: null,
		});

		this.props.unmount();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[MatchInsightsContent] getDerivedStateFromProps - nextProps:%o', nextProps);

		let newState = {
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	matchHasKeysData(data) {
		if (!isEmpty(data.keys)) {
			if (!isEmpty(data.keys.team_1) && !isEmpty(data.keys.team_2)) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[MatchInsightsContent] componentDidUpdate - prevProps:%o', prevProps);
		// logger.log('[MatchInsightsContent] componentDidUpdate - prevState:%o', prevState);
		// logger.log('[MatchInsightsContent] componentDidUpdate - state:%o', this.state);

		if (prevState.configLoaded !== this.state.configLoaded && this.state.matchId) {
			// logger.log('[MatchInsightsContent] componentDidUpdate -loading data files');
			let { configScoringData } = this.state;
			let { configOtherData } = this.state;
			let _this = this;

			let matchDataPath = configScoringData.matchScore.path.replace('<matchId>', this.state.matchId);

			// fetch scores complete data file
			fetch(matchDataPath)
				.then(md_result => {
					// logger.log('[MatchInsightsContent] md_result:%o', md_result);

					let matchInsightsDataPath = configScoringData.matchInsights.replace(
						'<matchId>',
						this.state.matchId
					);

					// fetch scores match insights data file
					fetch(matchInsightsDataPath)
						.then(mi_result => {
							// logger.log('[MatchInsightsContent] mi_result:%o', mi_result);

							let matchFactsPath = configOtherData?.innovations?.matchInsightsFacts.replace(
								'<matchId>',
								this.state.matchId
							);

							// fetch nlg match insights data file
							fetch(matchFactsPath)
								.then(mf_result => {
									// logger.log('[MatchInsightsContent] mf_result:%o', mf_result);

									this.setState({
										matchData: md_result.matches[0],
										p1Keys:
											md_result.matches[0].statsLevel === 'K' &&
											this.matchHasKeysData(md_result.matches[0])
												? md_result.matches[0].keys.team_1
												: null,
										p2Keys:
											md_result.matches[0].statsLevel === 'K' &&
											this.matchHasKeysData(md_result.matches[0])
												? md_result.matches[0].keys.team_2
												: null,
										matchInsightsData: mi_result.matches[0],
										atAGlance: mf_result.nlg[0].doc,
										inTheMedia: [mf_result.p1_insights, mf_result.p2_insights],
									});
								})
								.catch(error => {
									logger.log(
										'[MatchInsightsContent] componentDidUpdate error loading nlg match insights data:%o',
										error
									);
									this.setState({
										matchData: md_result.matches[0],
										p1Keys:
											md_result.matches[0].statsLevel === 'K' &&
											this.matchHasKeysData(md_result.matches[0])
												? md_result.matches[0].keys.team_1
												: null,
										p2Keys:
											md_result.matches[0].statsLevel === 'K' &&
											this.matchHasKeysData(md_result.matches[0])
												? md_result.matches[0].keys.team_2
												: null,
										matchInsightsData: mi_result.matches[0],
										atAGlance: null,
										inTheMedia: null,
									});
								});
						})
						.catch(error => {
							logger.log(
								'[MatchInsightsContent] componentDidUpdate error loading scores match insights data:%o',
								error
							);
							this.setState({
								matchData: md_result.matches[0],
								p1Keys:
									md_result.matches[0].statsLevel === 'K' &&
									this.matchHasKeysData(md_result.matches[0])
										? md_result.matches[0].keys.team_1
										: null,
								p2Keys:
									md_result.matches[0].statsLevel === 'K' &&
									this.matchHasKeysData(md_result.matches[0])
										? md_result.matches[0].keys.team_2
										: null,
								matchInsightsData: null,
								atAGlance: null,
								inTheMedia: null,
							});
						});
				})
				.catch(error => {
					logger.log(
						'[MatchInsightsContent] componentDidUpdate error loading scores complete match data:%o',
						error
					);
					this.setState({
						matchData: null,
						p1Keys: null,
						p2Keys: null,
						matchInsightsData: null,
						atAGlance: null,
						inTheMedia: null,
					});
				});
		}
	}

	onClose() {
		MeasurementUtils.dispatchMeasurementCall('closeMatchInsightsModal', {});

		if (this.state.Router.pathname.indexOf('matchinsights') > -1) {
			location.href = '/en_US/index.html';
		}
		this.props.hideOverlayModal();
	}

	viewPlayerBio(pid) {
		// location.href = `/en_US/players/overview/${pid}.html`;

		this.props.hideOverlayModal();
	}

	setSelectedPlayer(which, e) {
		MeasurementUtils.dispatchMeasurementCall('selectPlayer', { action: 'select', player: which });
		this.setState({
			selectedPlayer: which,
		});
	}

	openInfoWindow() {
		MeasurementUtils.dispatchMeasurementCall('infoWindow', { action: 'open' });
		this.setState({
			infoWindow: true,
		});
	}

	closeInfoWindow() {
		MeasurementUtils.dispatchMeasurementCall('infoWindow', { action: 'close' });
		this.setState({
			infoWindow: false,
		});
	}

	measureNextPrev(newIndex) {
		//logger.log('[MatchInsightsContent] measureNexPrev newIndex:%o',newIndex);
		let prevSlide = this.state.curSlide;

		if (prevSlide < newIndex) {
			MeasurementUtils.dispatchMeasurementCall('InTheMedia', {
				action: 'next',
				curSlide: newIndex,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('InTheMedia', {
				action: 'prev',
				curSlide: newIndex,
			});
		}

		this.setState({
			curSlide: newIndex,
		});
	}

	getPlayerFlag(nation) {
		let flagPath;

		flagPath = this.state.configOtherData.flagImagePathSmall.replace('<code>', nation);

		return flagPath;
	}

	renderInfoWindow() {
		return (
			<div className={`mi-info-window${this.state.infoWindow === true ? ' active' : ''}`}>
				Match Insights uses Watson Discovery to help fans get up to speed on every match of the 2020 US Open. It
				uses natural language processing to analyze millions of unstructured data sources, like articles, blogs
				and expert opinions. The "At a Glance" section uses open sourced technology such as natural language
				generation to translate historical statistics into insights. And the "In the Media" section uses Watson
				Discovery to connect to data sources to pull key insights about each player from trusted news sources.
				For more information on Watson's natural language processing capabilities,{' '}
				<EventsLink
					to="https://www.ibm.com/watson/natural-language-processing"
					external={true}
					style="click-here">
					click here
				</EventsLink>
				.
				<div className="got-it" onClick={() => this.closeInfoWindow()}>
					Got It
				</div>
			</div>
		);
	}

	renderPlayerToggle() {
		let { matchData } = this.state;

		return (
			<div className="mi-player-toggles">
				<div
					className={`mi-toggle${this.state.selectedPlayer === '1' ? ' selected' : ''}`}
					onClick={e => {
						this.setSelectedPlayer('1', e);
					}}>
					<div className="mi-toggle-flag">
						{matchData.team1.nationA ? (
							<img src={this.getPlayerFlag(matchData.team1.nationA)} alt={matchData.team1.nationA} />
						) : null}
					</div>
					<div className="mi-toggle-name">
						{matchData.team1.displayNameA}
						{matchData.team1.seed ? <div className="mi-toggle-seed">[{matchData.team1.seed}]</div> : null}
					</div>
				</div>
				<div
					className={`mi-toggle${this.state.selectedPlayer === '2' ? ' selected' : ''}`}
					onClick={e => {
						this.setSelectedPlayer('2', e);
					}}>
					<div className="mi-toggle-flag">
						{matchData.team2.nationA ? (
							<img src={this.getPlayerFlag(matchData.team2.nationA)} alt={matchData.team2.nationA} />
						) : null}
					</div>
					<div className="mi-toggle-name">
						{matchData.team2.displayNameA}
						{matchData.team2.seed ? <div className="mi-toggle-seed">[{matchData.team2.seed}]</div> : null}
					</div>
				</div>
			</div>
		);
	}

	renderMatchInfo() {
		let { matchInsightsData } = this.state;

		return (
			<div className="mi-match-info mi-section">
				<div className="mi-match-event-round">
					{matchInsightsData.eventName} - {matchInsightsData.roundNameShort}
				</div>
				<div className="mi-match-court">{matchInsightsData.courtName}</div>
				<div className="mi-match-date-session">
					{matchInsightsData.displayDate}, 2020 - Session {matchInsightsData.session}
				</div>
			</div>
		);
	}

	getAtAGlanceData(which) {
		let { p1_bullets } = this.state.atAGlance;
		let { p2_bullets } = this.state.atAGlance;
		let p1_filtered = [];
		let p2_filtered = [];
		let p1_maxcharcount;
		let p2_maxcharcount;
		let data;
		which = which.toString();

		if (p1_bullets.length >= 5) {
			p1_maxcharcount = 110;
		} else {
			p1_maxcharcount = 165;
		}

		if (p2_bullets.length >= 5) {
			p2_maxcharcount = 110;
		} else {
			p2_maxcharcount = 165;
		}

		p1_filtered = p1_bullets.filter(bullet => bullet.character_count <= p1_maxcharcount);

		p2_filtered = p2_bullets.filter(bullet => bullet.character_count <= p2_maxcharcount);

		if (which === '1') {
			data = p1_filtered;
		}
		if (which === '2') {
			data = p2_filtered;
		}

		return data;
	}

	renderAtAGlance(team, which) {
		let data = this.getAtAGlanceData(which);

		// logger.log('[MatchInsightsContent] renderAtAGlance - which:%o, data:%o', which, data);

		if (data.length > 0) {
			return (
				<div className="mi-glance-content" key={`player${which}-glance`}>
					{/* {this.state.windowSize !== 'mobile' ? (
						<div className="mi-player-name">{teamdata.displayNameA}</div>
					) : null} */}
					<ul>
						{data.map((d, i) => {
							return <li key={`p${which}-b${i}`}>{d.text}</li>;
						})}
					</ul>
				</div>
			);
		} else {
			return (
				<div className="mi-glance-content" key={`player${which}-glance`}>
					{team.firstNameA} {team.lastNameA} did not compete in the 2019 US Open.
				</div>
			);
		}
	}

	renderInTheMedia(team, which) {
		let teamdata = eval('this.state.inTheMedia[' + (which - 1) + ']');
		let currentrounddata = teamdata.filter(data => {
			return data.doc.insight.published_round == this.state.matchData.roundCode;
		});
		let maxcount = 3;

		// logger.log('[MatchInsightsContent] renderInTheMedia - currentrounddata:%o', currentrounddata);

		let settings = {
			dots: true,
			arrows: false,
			infinite: false,
			speed: 500,
			afterChange: newIndex => {
				this.measureNextPrev(newIndex);
			},
		};

		if (currentrounddata.length > 0) {
			if (this.state.windowSize === 'mobile') {
				return (
					<div className="mi-media-content" key={`player${which}-media`}>
						<Slider {...settings}>
							{currentrounddata.map((data, i) => {
								if (i < maxcount) {
									return (
										<InTheMedia
											data={data}
											teamnum={which}
											count={i}
											key={`player${which}-media-item-${i}`}
										/>
									);
								}
							})}
						</Slider>
					</div>
				);
			} else {
				return (
					<div className="mi-media-content" key={`player${which}-media`}>
						{currentrounddata.map((data, i) => {
							if (i < maxcount) {
								return (
									<InTheMedia
										data={data}
										teamnum={which}
										count={i}
										key={`player${which}-media-item-${i}`}
									/>
								);
							}
						})}
					</div>
				);
			}
		} else {
			return (
				<div className="mi-media-content" key={`player${which}-media`}>
					There is no information for {team.firstNameA} {team.lastNameA} in the media.
				</div>
			);
		}
	}

	renderKeysToTheMatch(which) {
		// logger.log('[MatchInsightsContent] renderKeysToTheMatch - which:%o', which);
		if (op.get(this.state, 'matchData.statsLevel', false) === 'K') {
			// let teamdata = eval('this.state.matchData.team' + which);
			let keysdata = eval('this.state.p' + which + 'Keys');

			// logger.log('[MatchInsightsContent] renderKeysToTheMatch - which:%o, keysdata:%o', which, keysdata);

			return (
				<div className={`team_content team_${which}`} key={`player${which}-keys`}>
					<div className="team">
						{/* {this.state.windowSize !== 'mobile' ? (
							<div className="mi-player-name">{teamdata.displayNameA}</div>
						) : null} */}
						<ul>
							{Object.keys(keysdata).map((key, i) => {
								if (i < 3) {
									return <li>{renderKey(`team_${which}`, key, keysdata[key], i, true)}</li>;
								}
							})}
						</ul>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		logger.log('[MatchInsightsContent] render - state:%o', this.state);

		if (
			op.get(this.state, 'configLoaded', false) &&
			op.get(this.state, 'matchData', false) &&
			op.get(this.state, 'matchInsightsData', false) &&
			op.get(this.state, 'windowSize', false) &&
			op.get(this.state, 'scoring')
		) {
			let teams = [this.state.matchInsightsData.team1, this.state.matchInsightsData.team2];

			return (
				<div className="overlay-content">
					<div className="radial-gradient"></div>
					<div className="radial-gradient2"></div>
					<div className="radial-gradient3"></div>

					<a name="top"></a>

					<div className="mi-header">
						<div className="mi-header-left">
							<div className="mi-header-logo">
								<img
									src="/assets/images/matchInsights/Watson_product_logo_color_RGB.png"
									alt="Match Insights with Watson"></img>
							</div>
							<div className="mi-header-text">
								Match Insights<div className="with-watson-text">with Watson</div>
							</div>
						</div>
						{this.state.infoWindow === false ? (
							<div className="mi-close" onClick={this.onClose}>
								Close
							</div>
						) : null}
					</div>

					<div className="mi-subheader">
						<div className="mi-subheader-text">
							AI-powered insights from Watson Discovery that make you an instant expert before every
							match.
						</div>
						<div className="mi-subheader-icon">
							<div
								onClick={() => {
									this.openInfoWindow();
								}}>
								<i className="icon-info"></i>
							</div>
						</div>
					</div>

					<div className={`mi-content-container ${this.state.scoring['uom']}`}>
						{this.renderInfoWindow()}

						{this.state.windowSize === 'mobile' ? this.renderPlayerToggle() : null}

						<div className="mi-match-info-players">
							{this.renderMatchInfo()}

							{this.state.windowSize === 'mobile' ? (
								<PlayerBox
									data={teams[parseInt(this.state.selectedPlayer) - 1]}
									teamnum={this.state.selectedPlayer}
									imgPath={this.state.configOtherData.playerProfileImagePath}
									flagPath={this.state.configOtherData.flagImagePathSmall}
									windowSize={this.state.windowSize}
									onPlayerBioClick={this.viewPlayerBio}
								/>
							) : (
								teams.map((team, i) => {
									return (
										<PlayerBox
											data={team}
											teamnum={i + 1}
											imgPath={this.state.configOtherData.playerProfileImagePath}
											flagPath={this.state.configOtherData.flagImagePathSmall}
											windowSize={this.state.windowSize}
											key={`player${i + 1}-box`}
											onPlayerBioClick={this.viewPlayerBio}
										/>
									);
								})
							)}
						</div>

						{this.state.atAGlance !== null ? (
							<div className="mi-player-glance mi-section">
								<div className="mi-title">At a Glance</div>
								<div className="mi-content">
									{this.state.windowSize === 'mobile'
										? this.renderAtAGlance(
												teams[parseInt(this.state.selectedPlayer) - 1],
												this.state.selectedPlayer
										  )
										: teams.map((team, i) => {
												return this.renderAtAGlance(team, i + 1);
										  })}
								</div>
							</div>
						) : null}

						{this.state.inTheMedia !== null ? (
							<div className="mi-player-media mi-section">
								<div className="mi-title">In the Media</div>
								<div className="mi-content">
									{this.state.windowSize === 'mobile'
										? this.renderInTheMedia(
												teams[parseInt(this.state.selectedPlayer) - 1],
												this.state.selectedPlayer
										  )
										: teams.map((team, i) => {
												return this.renderInTheMedia(team, i + 1);
										  })}
								</div>
							</div>
						) : null}

						{this.state.p1Keys !== null && this.state.p2Keys !== null ? (
							<div className="mi-player-keys mi-section">
								<div className="mi-title">Keys to the Match</div>
								<div className="mi-content keys_content">
									{this.state.windowSize === 'mobile'
										? this.renderKeysToTheMatch(this.state.selectedPlayer)
										: teams.map((team, i) => {
												return this.renderKeysToTheMatch(i + 1);
										  })}
								</div>
							</div>
						) : null}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsightsContent);
