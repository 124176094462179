/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'html-react-parser';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import EventsLink from 'components/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { measureInApp } from 'appdir/components/general/Analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: FanWeekSchedule
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		pathname: state['Router'].pathname,
	};
};
class FanWeekSchedule extends Component {
	constructor(props) {
		super(props);

		const initTab = this.getTabText();
		let temp = this.props.pathname.indexOf(props.data.parentId);
		this.basePathname = this.props.pathname.substring(0, temp + props.data.parentId.length);
		// this.basePathname = tmp.slice(0, tmp.length - 1).join('/');

		this.state = {
			selectedType: initTab, //"Featured Events" i.e
			filters: {
				open: '',
			},
			// currentTab: initTab,
		};

		this.daysPassed = false;
		this.listViewArr = ['xsmall', 'small'];

		// logger.log('[FanWeekSchedule] constructor - props:%o', props);
	}

	componentDidMount() {
		if (this.props.hasOwnProperty('mount')) {
			this.props.mount(this);
		}
	}

	selectDate = (e, selectedDate, selectedRow) => {
		if (e.key == ' ' || e.code == 'Space' || e.keyCode == 32 || e.type == 'click') {
			let today = new Date();
			let itemDate = new Date(selectedDate);
			let breakpoint = get(this.props, 'EventsWindow.breakpoint', 'desktop');

			if (this?.state?.selectedDate !== selectedDate) {
				if (
					(today.getDate() > itemDate.getDate() + 1 && today.getMonth() == itemDate.getMonth()) ||
					today.getMonth() > itemDate.getMonth() ||
					today.getFullYear() > itemDate.getFullYear()
				) {
					return null;
				}
				if (this.state.selectedDate == selectedDate && this.listViewArr.includes(breakpoint)) {
					selectedDate = false;
					selectedRow = false;
				}
				if (typeof webview === 'undefined') {
					MeasurementUtils.dispatchMeasurementCall('FanWeekDateSelect', {
						selectedDate,
					});
				} else {
					measureInApp(['Metrics', 'FanWeekDateSelect', selectedDate]);
				}
				this.setState({ selectedRow, selectedDate });
			} else {
				MeasurementUtils.dispatchMeasurementCall('FanWeekDateUnselect', {
					filter: { selection: selectedDate },
				});

				this.setState({ selectedDate: 'none' });
			}
		}
	};

	componentDidUpdate() {
		/** ?showCalendar=true ignores the match status and always set vote to open */
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));

		logger.log('[FanWeekSchedule] componentDidUpdate - this:%o', this);

		/** default open the first date **/
		let selectedDate;
		if (
			!this.state.selectedDate &&
			get(this.props, 'dates[0].date', false) //&&
			//!this.listViewArr.includes(breakpoint)
		) {
			if (this.daysPassed == false) {
				selectedDate = this.props.dates[0].date;
				this.setState({
					selectedRow: 1,
					selectedDate: 'none',
				});
			} else {
				selectedDate = moment().format('YYYY-MM-DD');
				this.setState({
					selectedRow: 1,
					selectedDate: 'none',
				});
			}
			// MeasurementUtils.dispatchMeasurementCall('FanWeekDefaultDate', {
			// 	selectedDate,
			// });
		}
	}

	// /** send metrics call for apps on the Draws Bracket view deep link tap on Power Index to Bracket view
	//  *  and deep link to the bracket view
	//  */
	openModal = (showModal, type, date, scheduleItem) => {
		logger.log('[FanWeekSchedule] openModal - showModal:%o, scheduleItem:%o', showModal, scheduleItem);
		this.props.loadOverlayModal('FAN_WEEK_SCHEDULE', { scheduleItem });

		let title = scheduleItem.title;
		MeasurementUtils.dispatchMeasurementCall('openModal', {
			type,
			date,
			title,
			showModal,
		});

		//doMeasurement(values.powerIndexTitle, 'Draws Bracket View', measureArgs, contextData);
	};

	/**
	 * -----------------------------------------------------------------------------
	 * @function filterEvent
	 * drop down selector
	 * -----------------------------------------------------------------------------
	 */

	filterEvent = event => {
		// logger.log('[Fanweek Schedule] filterEvent event:%o', event.target.value);
		if (typeof webview === 'undefined') {
			MeasurementUtils.dispatchMeasurementCall('FanWeekFilter', {
				location: event.target.value,
			});
		} else {
			measureInApp(['Metrics', 'FanWeekFilter', 'location', event.target.value]);
		}
		this.setState({ selectedType: event.target.value });
	};

	/**
	 * -----------------------------------------------------------------------------
	 * @function genDates
	 * creates a specified row of dates
	 * -----------------------------------------------------------------------------
	 */
	genDates(dates, row) {
		logger.log('[FanWeekSchedule] genDates dates:%o', dates);
		let dateJSX = [];
		let breakpoint = get(this.props, 'EventsWindow.breakpoint', 'desktop');
		if (!isEmpty(dates)) {
			dates.map((item, index) => {
				let today = moment();
				let yesterday = moment().add(-1, 'days');
				let itemDate = moment(item.date);
				// start date for Fan week 2024.  They want to display an event before the start date, but not the date in the selector
				let startDate = moment(this?.props?.fanweekInfo?.startDate);
				let endDate = moment(this?.props?.fanweekInfo?.endDate);
				let inPast = false;

				if (itemDate.isBefore(yesterday)) {
					inPast = true;
					this.daysPassed = true;
				}

				if (itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate)) {
					/** above xs breakpoint, render only the row specified **/
					if ((index >= (row - 1) * 8 && index < row * 8) || this.listViewArr.includes(breakpoint)) {
						dateJSX.push(
							<div
								onClick={e => this.selectDate(e, item.date, row)}
								onKeyDown={e => this.selectDate(e, item.date, row)}
								tabIndex={0}
								className={`date
									${inPast ? ` disabled` : ``}
									${item.date == this.state.selectedDate ? ` select` : ``}`}
								id={item.date}
								key={item.date}>
								<time dateTime={item.date} className={`date-as-calendar`}>
									<span className="month">{item.month}</span>
									<span className="day">{item.dateDay}</span>
									<span className="weekday">{item.day}</span>
								</time>
							</div>
						);
					}
				}
			});
			return dateJSX;
		} else {
			return null;
		}
	}

	getTabText = () => {
		// let tab = this.props.pathname.slice(this.props.pathname.lastIndexOf('/'), this.props.pathname);
		let tab = this.props.data.tabName;
		switch (tab) {
			case 'featured':
				return 'Featured Events';
			case 'fan':
				return 'Fan Access Pass';
			case 'ticketed':
				return 'Ticketed Events';
			case 'all':
				return 'All Events';
			default:
				return 'All Events';
		}
	};

	setEvent = event => {
		logger.log('[Fanweek Schedule] setEvent event:%o', event);

		// let tmp = this.props.pathname.split('/');
		// let basePathname = tmp.slice(0, tmp.length - 1).join('/');
		let tempPath;

		switch (event) {
			case 'Fan Access Pass':
				tempPath = `${this.basePathname}/fan`;
				break;
			case 'Ticketed Events':
				tempPath = `${this.basePathname}/ticketed`;
				break;
			case 'All Events':
				tempPath = `${this.basePathname}/all`;
				break;
			case 'Featured':
			default:
				tempPath = `${this.basePathname}/featured`;
				break;
		}

		window.history.pushState({}, '', tempPath);
		if (!window.webviewPlatform) {
			MeasurementUtils.dispatchMeasurementCall('FanWeekFilter', {
				location: event,
			});
		} else {
			measureInApp(['Metrics', 'FanWeekFilter', 'location', event]);
		}
		this.setState({
			selectedType: event,
			filters: {
				open: '',
			},
		});
	};

	onOpen = which => {
		this.setState({
			filters: {
				open: which,
			},
		});

		//measureApp('Draws', 'Menu', 'Open');
	};

	onClose = which => {
		//measureApp('Draws', 'Menu', 'Close');
	};

	/**
	 * -----------------------------------------------------------------------------
	 * @function genSelectDatesInfo
	 * populates info box for selected date
	 * @var row : determines which row the info should be displayed under
	 * -----------------------------------------------------------------------------
	 */
	genSelectDatesInfo(row) {
		logger.log('[FanWeekSchedule] genSelectDatesInfo this:%o', this);
		if (row != this.state.selectedRow) {
			return null;
		}

		let breakpoint = get(this.props, 'EventsWindow.breakpoint', 'desktop');
		// logger.log('[FanweekSchedule] breakpoint:%o', breakpoint);

		let fanWeekInfo = [];

		if (this.state?.selectedDate !== 'none') {
			fanWeekInfo = get(filter(this.props?.dates, { date: this.state.selectedDate }), '0.items', []);
		} else {
			this.props?.dates.map((date, index) => {
				date.items.map((item, index2) => {
					fanWeekInfo.push(item);
				});
			});
		}

		// let fanWeekInfo = get(filter(this.props?.dates, { date: this.state.selectedDate }), '0.items', []);

		logger.log('[FanWeekSchedule] fanWeekInfo:%o', fanWeekInfo);
		let fan_week_items = [];
		/** sort by location alphabetically */
		// fanWeekInfo.sort(function(a, b) {
		// 	var textA = a.location.toUpperCase();
		// 	var textB = b.location.toUpperCase();
		// 	return textA < textB ? -1 : textA > textB ? 1 : 0;
		// });

		fanWeekInfo.map(item => {
			// logger.log('[FanWeekSchedule] item:%o', item);
			// logger.log('[FanWeekSchedule] this.state:%o', this.state);
			let image = get(item, 'images[0].url', false);
			let image_alt = get(item, 'images[0].caption', '');
			let today = moment().format('dddd, MMMM D, YYYY');

			// let hoursAhead = 6;
			let now = Date.now(); // + 3600000 * hoursAhead;

			// .subtract(1, "hours")
			// .format("k");

			let itemEndtime = moment(parseFloat(item.end_time_epoch));
			now = moment(now);
			let date = moment(item.date).format('YYYY-MM-DD');
			today = moment(today).format('YYYY-MM-DD');

			// date === today &&
			// 	logger.log(
			// 		'[FanWeekSchedule] item.title:%o\n item.date:%o\n today:%o\n now:%o\n item.end_time:%o\n date is after today: %o\n date: %o\n today: %o\n date == today: %o\n moment(itemEndtime).isAfter(now): %o\n firstPart: %o\n item.type: %o\n',
			// 		item.title,
			// 		item.date,
			// 		moment(today).format('YYYY-MM-DD HH:mm:ss'),
			// 		moment(now).format('YYYY-MM-DD HH:mm:ss'),
			// 		moment(parseFloat(item.end_time_epoch)).format('YYYY-MM-DD HH:mm:ss'),
			// 		moment(date).isAfter(today, 'day'),
			// 		date,
			// 		today,
			// 		date === today,
			// 		moment(itemEndtime).isAfter(now, 'minute'),
			// 		item.type.includes(this.state.selectedType) ||
			// 			this.state.selectedType == 'View More' ||
			// 			this.state.selectedType == 'All Events',
			// 		item.type
			// 	);

			if (
				(item.type === 'All Events' ||
					item?.type.includes(this.state.selectedType) ||
					this.state.selectedType == 'View More' ||
					this.state.selectedType == 'All Events') &&
				((date === today && moment(itemEndtime).isAfter(now)) ||
					moment(date).isAfter(today, 'day') ||
					(date === today && item.all_day))
			) {
				fan_week_items.push(
					<div className={`fanweek section`} key={`${item.date}-${item.id}`}>
						<div className="image">
							{item.link ? (
								<EventsLink to={item.link}>
									<img src={image} alt={image_alt} />
								</EventsLink>
							) : (
								<span
									onClick={e => {
										this.openModal(true, item.type, item.date, item);
									}}>
									<img src={image} alt={image_alt} />
								</span>
							)}
						</div>
						<div className="content-container">
							<div className="content info">
								{item?.title && <div className="title">{item?.title}</div>}
								{item?.location && <span className="location">{item?.location}</span>}
								{item?.date && <span className="fanweek-date">{item?.date}</span>}
								{item?.all_day === true ? (
									<span className="time">All Day</span>
								) : item?.start_time && item?.end_time ? (
									<span className="time">{`${item?.start_time} to ${item?.end_time} (ET)`}</span>
								) : null}
								{item?.description && (
									<div className="description">{ReactHtmlParser(item?.description)}</div>
								)}
							</div>

							<div className="content-spacer"></div>

							{item.link ? (
								<div className="content-button">
									<EventsLink to={item.link}>
										{item?.button_text ? item?.button_text : 'Learn More'}
									</EventsLink>
								</div>
							) : (
								// showModal, stadium, sessionIndex, tournDay
								<div className="content-button learn-more">
									<span
										onClick={e => {
											this.openModal(true, item.type, item.date, item);
										}}>
										Learn More
									</span>
								</div>
							)}

							<div className="content-link-buttons">
								{item?.free_event && (
									<div className="fanweek-tickets link-button free-event">
										<i className="icon-thin-check"></i>
										Free Event
									</div>
								)}
								{item?.ticket_link && (
									<div className="fanweek-tickets link-button">
										<EventsLink to={item.ticket_link}>
											<i className="icon-tickets"></i>
											<span>Tickets</span>
										</EventsLink>
									</div>
								)}
								{item?.location_map_link && (
									<div className="fanweek-map link-button">
										<EventsLink to={item.location_map_link}>
											<i className="icon-grounds-map"></i>
											<span>Find It On the Map</span>
										</EventsLink>
									</div>
								)}
								{item?.fan_pass_link && (
									<div className="fanweek-fanaccess link-button">
										<EventsLink to={item.fan_pass_link}>
											<i className="icon-qr-code"></i>
											<span>{item?.fan_pass_text || 'Fan Access Pass Experience'}</span>
										</EventsLink>
									</div>
								)}
							</div>
						</div>
					</div>
				);
			}
		});

		let eventsDataArr, eventsDropdownVals;
		let selectTitle = [
			// { name: 'Filter by Location', value: 'Filter by Location' },
			{ name: 'View More', value: 'View More' },
		];
		if (this.props?.filters?.length > 0) {
			eventsDropdownVals = this.props.filters.map((item, index) => {
				return { name: item, value: item };
			});
		}

		eventsDataArr = selectTitle.concat(eventsDropdownVals);
		this.eventsData = {
			options: eventsDataArr,
		};

		logger.log('[FanweekSchedule] fan_week_items:%o', fan_week_items);

		return (
			<>
				<div className="filtersWrapper">
					<div className="filters">
						{/** last minute request came in to use native dropdown */}
						<div className="below-tablet">
							<div className="selected-location">
								<span>{this.state.selectedType}</span>
							</div>
							<div className="filter-dropdown dark">
								<select name="fanweekFilter" onChange={e => this.filterEvent(e)} value={0}>
									<option value="View More" key={`fanweek_filter`}>
										View More
									</option>
									<option value="All Events" key={`fanweek_filter_all`}>
										All Events
									</option>
									{get(this.props, 'filters', []).map((type, index) => (
										<option value={type} key={`fanweek_filter${index}`}>
											{type}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className="above-tablet">
							<div className="fanweek-filters">
								<div
									data-text="All Events"
									onClick={() => this.setEvent('All Events')}
									key={`fanweek_filter_link_all`}
									className={`filter-item ${this.state?.selectedType === 'All Events' && 'active'}`}>
									All Events
								</div>
								{this.props?.filters.map((type, index) => (
									<div
										data-text={type}
										onClick={() => this.setEvent(type)}
										key={`fanweek_filter_link${index}`}
										className={`filter-item ${this.state?.selectedType === type && 'active'}`}>
										{type}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div
					className={`fanweekWrapper ${
						this.state.listView || this.listViewArr.includes(breakpoint) ? `list` : `grid`
					}`}>
					{!isEmpty(fan_week_items) ? (
						fan_week_items
					) : (
						<div className="noEvents">{`There are no events for this day or category. Select a different day or category to view events for Fan Week.`}</div>
					)}
				</div>
			</>
		);
	}

	render() {
		logger.log('[FanWeekSchedule] this:%o', this);

		if (this.props?.showFanWeekCalendar || this.parsedQs?.showCalendar) {
			return (
				<Fragment>
					<div className="fanweek-wrapper">
						<div className="selectDatesCalendar">
							<div className="ticket_row_text">Select A Day To see What Events Await</div>
							<div className="ticket_row">{this.genDates(get(this.props, 'dates', []), 1)}</div>
						</div>
						{this.state.selectedRow == 1 && (
							<div className="selectDatesInfo tourn-fanweek">{this.genSelectDatesInfo(1)}</div>
						)}
					</div>
				</Fragment>
			);
		} else {
			return null;
		}
	}
}

FanWeekSchedule.defaultProps = {
	listView: true,
};

export default connect(mapStateToProps)(FanWeekSchedule);
