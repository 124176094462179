/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import ModalOverlay from 'appdir/components/general/ModalOverlay';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * RLL - some of this may change based on how this works for everyone and once
 * 	measurement gets sorted out
 * React Component: Share
 *
 * props:
 * url - specific url to share if not the current page
 * section - section for measurement
 * title - title for measurement
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ShareModal'],
		...state['ModalOverlay'],
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	hideOverlayModal: () => dispatch(deps.actions.ModalOverlay.hideOverlayModal()),
});

class ShareModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
		};

		// this.facebook_code = '';
		// this.twitter_code = '';
		// this.plusone_code = '';
		// this.email_code = '';

		this.facebook_path = '';
		this.twitter_path = '';
		this.plusone_path = '';
		this.email_path = '';

		this.onClose = this.onClose.bind(this);

		logger.log('[ShareModal] constructor - state:%o', this.state);
	}

	componentWillUnmount() {
		this.facebook_path = '';
		this.twitter_path = '';
		this.plusone_path = '';
		this.email_path = '';
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[ShareModal] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[ShareModal] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	onClose() {
		this.state.hideOverlayModal();
	}

	getSocialInfo() {
		let new_share_url;

		if (this.state.share_url.indexOf('://') === -1) {
			new_share_url = `https://www.masters.com${this.state.share_url}`;
		}

		let enc_url = encodeURIComponent(new_share_url);
		let enc_title = encodeURIComponent(this.state.share_title);
		let line_break = '%0D%0A';

		let app_strings = [
			'Download the Masters iOS App at ' +
				encodeURIComponent('https://itunes.apple.com/us/app/the-masters-tournament/id309025938?mt=8'),
			'Download the Masters Android App at ' +
				encodeURIComponent('https://play.google.com/store/apps/details?id=com.ibm.events.android.masters'),
		].join(line_break);

		var share_footer = app_strings;

		// let track_string = [options.type, options.title, 'Share', options.track_name].join('||');
		let track_string = '';

		// facebook
		this.facebook_path = `https://www.facebook.com/sharer/sharer.php?u=${enc_url}`;
		// this.facebook_code = `<a href="${facebook_path}" data-track="${track_string}" data-title="${this.state.share_title}" data-bypass target="_blank"><img src="/assets/images/now/share_fb.png" alt="Share on Facebook" /></a>`;

		// twitter
		this.twitter_path = `https://twitter.com/share?url=${enc_url}&via=TheMasters&text=${enc_title}`;
		// this.twitter_code = `<a href="${twitter_path}" data-track="${track_string}'" data-title="${this.state.share_title}" data-bypass target="_blank"><img src="/assets/images/now/share_twitter.png" alt="Tweet" /></a>`;

		// plusone
		this.plusone_path = `https://plus.google.com/share?url=${enc_url}`;
		// this.plusone_code = `<a href="${plusone_path}" data-track="${track_string}" data-title="${this.state.share_title}" data-bypass target="_blank"><img src="/assets/images/now/share_gplus.png" alt="Share on Google+"/></a>`;

		// email
		let email_body_text = `${enc_title}${line_break}${line_break}${enc_url}${line_break}${line_break}`;
		this.email_path = `mailto:%20?subject=${enc_title}&body=${email_body_text}${share_footer}`;
		// this.email_code = `<a href="${this.email_path}" data-track="${track_string}" data-title="${this.state.share_title}" data-bypass><img src="/assets/images/now/share_mail.png" alt="Share via Email"/></a>`;
	}

	selectText(event) {
		event.target.select();
	}

	openSocialWindow(href) {
		logger.log('[ShareModal] openSocialWindow - href: ', href);
		let win = window.open(href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');

		let checkBlank = function(openWin) {
			try {
				if (openWin.location.hostname !== undefined && openWin.location.hostname === '') {
					// means empty frame, can close
					openWin.close();
				}
			} catch (e) {
				// do nothing
			}
		};

		setTimeout(checkBlank.bind(this, win), 4000);
	}

	//* **********************************
	// function to call measureApp on social click
	// track_ary: array of tracking strings ['Article','Article Name','Share','Google Plus']
	// Article Name is expected to have apostrophes already escaped
	//* **********************************
	measureShare(track_ary, prop_value) {
		// 	var prop = prop_value;
		// 	// if we only pass type and title, then add Share to end
		// 	// otherwise we assume it's from sharing a service, in which case
		// 	// Share already exists in track_ary
		// 	if(track_ary.length < 3) {
		// 		track_ary.push('Share');
		// 	} else {
		// 		// set s.prop50
		// 		if(prop === undefined) {
		// 			prop = track_ary[0] + ':' + track_ary[1];
		// 		}
		// 		prop = prop.split(':');
		// 		if(track_ary[0].indexOf('Tournament Info') > -1 && track_ary[0].indexOf(':VOD') < 0) {
		// 			// only use page title
		// 			prop[0] = prop[1];
		// 			prop.length = 1;
		// 		} else if(track_ary[0].indexOf('Breaking News') > -1) {
		// 			prop[0] = 'Breaking News';
		// 			prop[1] = prop[2];
		// 			prop.length = 2;
		// 		} else if(track_ary[0].indexOf('Photo Gallery') > -1) {
		// 			// s.prop50 is <Gallery Title>
		// 			prop[0] = 'Photo Gallery';
		// 		} else if(track_ary[0] === 'Photo') {
		// 			// prop should be Photo, metric call should still be Photo Gallery
		// 			prop[0] = 'Photo';
		// 			track_ary[0] = 'Photo Gallery';
		// 		} else if(track_ary[0].indexOf('Leader Board') > -1 && track_ary[0].indexOf('Leader Board:Video') < 0) {
		// 			prop[0] = 'Leader Board';
		// 		} else if(track_ary[0].indexOf('Articles') > -1 && track_ary[0].indexOf(':Highlight') < 0 && track_ary[0].indexOf(':VOD') < 0) {
		// 			prop[0] = 'Articles';
		// 		} else if(track_ary[0].indexOf('Watch') > -1 || track_ary[0].indexOf(':VOD') > -1 || track_ary[0].indexOf('Articles:Highlight') > -1 || track_ary[0].indexOf('Leader Board:Video') > -1) {
		// 			var parts = track_ary[0].split(':');
		// 			if(parts[1] !== 'Live') {
		// 				prop[0] = 'VOD';
		// 			} else {
		// 				prop[0] = parts[1];
		// 			}
		// 		}
		// 		prop = prop.join(':');
		// 		Metrics.trackS({prop50: prop});
		// 	}
		// 	Metrics.measureApp.apply(this, track_ary);
	}

	getFullURL(url) {
		let full_url = 'https://www.masters.com' + url;

		return full_url;
	}

	render() {
		logger.log('[ShareModal] render - state:%o', this.state);

		this.getSocialInfo();

		return (
			<ModalOverlay onClose={this.onClose}>
				<div className="overlay-share overlay_panel">
					<div className="social-overlay">
						<div className="close" onClick={this.onClose} />
						<div className="header">Share</div>
						<div className="social-box social-services">
							<div
								className="mail"
								onClick={() => {
									this.openSocialWindow(this.email_path);
								}}>
								<img src="/assets/images/now/share_mail.png" alt="Share via Email" />
							</div>
							<div
								className="facebook"
								onClick={() => {
									this.openSocialWindow(this.facebook_path);
								}}>
								<img src="/assets/images/now/share_fb.png" alt="Share on Facebook" />
							</div>
							<div
								className="twitter"
								onClick={() => {
									this.openSocialWindow(this.twitter_path);
								}}>
								<img src="/assets/images/now/share_twitter.png" alt="Share on Twitter" />
							</div>
							<div
								className="gplus"
								onClick={() => {
									this.openSocialWindow(this.plusone_path);
								}}>
								<img src="/assets/images/now/share_gplus.png" alt="Share on Google+" />
							</div>
							<div className="link">
								<a href="#">
									<img src="/assets/images/now/share_anchor.png" alt="Share Link" />
								</a>
								<div className="link-url">
									<input
										type="text"
										readOnly="readonly"
										value={this.getFullURL(this.state.share_url)}
										onFocus={this.selectText}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ModalOverlay>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
