/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import isEqual from 'lodash/isEqual';

/* page elements */
import SlamtrackerContent from 'components/pages/Slamtracker/SlamtrackerContent';

import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';

import AdUnitLazy from 'appdir/components/general/AdUnitLazy';
import op from 'object-path';
import throttle from 'lodash/throttle';

/**
 * -----------------------------------------------------------------------------
 * React Component: SlamtrackerPanel
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['SlamtrackerPanel'],
		breakingAlert: state['BreakingAlert'],
		alertData: op.get(state.ActiveData, 'homepage.alertMessage', null),
		stPanel: state['Controller'].stpanel,
		adConfig: state['Config'].adConfig,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	setSTPanelStatus: data => dispatch(deps.actions.Controller.setSTPanelStatus(data)),
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
});

class SlamtrackerPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
      // isHeaderFixed: false,
    };

		this.panelContentMain = null; // main content area of page
		this.panelContainer = null; // panel container
		this.panelFiltersScroll = null; // indicates top position of filters
	}

	componentDidMount() {
		// logger.log('[SlamtrackerPanel] componentDidMount -  this:%o', this);
	}

	componentWillUnmount() {
		this.panelContainer.removeEventListener('scroll', this.positionChange.bind(this), false);
	}

	componentDidUpdate(prevProps, prevState) {
		/** if match id prop has changed, call measure function */
		if (
			!isEqual(prevProps?.stPanel?.matchid, this.props?.stPanel?.matchid) &&
			this.props?.stPanel?.matchid !== ''
		) {
			// this.measurePanel('open', []);
      /** reset sticky header if match id changes  */
      // this.panelContentMain = null;
		  // this.panelContainer = null;
		  // this.panelFiltersScroll = null; 
      // this.setState({
      //   isHeaderFixed: false,
      // })
		}

		/**
		 * Use this block of code if you want to scroll the panel and fix the
		 * scoreboard at the top of the panel like we do for slamtracker mobile,
		 * The commented block will need to be uncommented for it to work.
		 */
		// if (!this.panelContainer && document.querySelector('.stpanel-content')) {
    //   logger.log('[SlamtrackerPanel] componentDidUpdate - this:%o', this);
    //   if(!window.webview) {
    //     this.panelContainer = document.querySelector('.stpanel-content');
    //     this.panelContainer.addEventListener(
    //       'scroll',
    //       throttle(this.positionChange.bind(this), 100, { trailing: true }),
    //       false
    //     );
    //   }
		// }
	}

	measurePanel(measureAction, measure_args) {
		if (window.location.href.indexOf('suite') == -1) {
			let matchStatus;
			let measureArray;

			if (typeof webview === 'undefined') {
				measureArray = ['Slamtracker Panel', this.props.stPanel.matchid].concat(measure_args);

				MeasurementUtils.dispatchMeasurementCall(measureAction, {
					data: measureArray,
				});
			} else {
				measureArray = ['Metrics', 'Slamtracker Panel', this.props.stPanel.matchid]
					.concat(measureAction)
					.concat(measure_args);

				measureInApp(measureArray);
			}
		}
	}

	assignElements() {
		this.panelContentMain = document.querySelector('.content-main');
		this.panelFiltersScroll = document.querySelector('.st-content-scroll');
	}

	/**
	 * If you want to fix the featured match div at the top of the
	 * panel, this function gets called on scroll to calculate the position
	 * of the panel and add/remove the appropriate class
	 */
	positionChange = (event) => {
		/**
		 * close bar - 29px
		 * title bar (width borders) - 57px
		 * ad bar - 70px
     * page header - 35px
		 */
		let checkHeight = (29 + 57 + 70);

		if (this.panelFiltersScroll !== null) {
			let cfsTop = Math.round(this.panelFiltersScroll.getBoundingClientRect().top);

			logger.log('[SlamtrackerPanel] positionChange - panelFiltersScroll - top:%o checkHeight:%o isFixed:%o', cfsTop, checkHeight, this.state.isHeaderFixed);
			if (cfsTop < checkHeight) {
				if (!this.panelContentMain.classList.contains('fixed')) {
          this.setState(prevState => {
            return {
              ...prevState,
              isHeaderFixed: true,
            };
          });

					this.panelContentMain.classList.add('fixed');
				}
			} else if (cfsTop > checkHeight) {
				if (this.panelContentMain.classList.contains('fixed')) {
          this.setState(prevState => {
            return {
              ...prevState,
              isHeaderFixed: false,
            };
          });

					this.panelContentMain.classList.remove('fixed');
				}
			}
		} else {
			this.assignElements();
		}
	}

	/**
	 * @returns the top potsition of the panel based on the currently
	 * visible page elements (the page header and the breaking news bar)
	 */
	calculateTop() {
		let stPanelTop;
		let breakingNewsHeight = 0;
		let headerHeight = 0;

		if (op.get(this.props, 'breakingAlert.status', false) == 'open' && document.querySelector('.breakingNews')) {
			breakingNewsHeight = document.querySelector('.breakingNews').getBoundingClientRect().height;
			// logger.log('[SlamtrackerPanel] calculateTop (checking state) - breakingNewsHeight:%o', breakingNewsHeight);
		}

		if (document.querySelector('#uso-header')) {
			headerHeight = document.querySelector('#uso-header').getBoundingClientRect().height;
			// logger.log('[SlamtrackerPanel] calculateTop - headerHeight:%o', headerHeight);
		}

		stPanelTop = breakingNewsHeight + headerHeight;

		// logger.log('[SlamtrackerPanel] calculateTop - stPanelTop:%o', stPanelTop);

		return stPanelTop;
	}

	/**
	 * when closing the panel, call the measure function,
	 * then call the action to set the panel status
	 */
	onClose() {
		this.measurePanel('close');
		this.props.setSTPanelStatus({ open: false, matchid: null, matchstatus: null, panelToST: false });
	}

	/**
	 * when navigating away from the panel using the view match details link,
	 * call the action to set the panel status, then
	 * call the navigate action to set the new path since the panel
	 * lives outside of the Router.  The new path is captured in MainNav
	 * to redirect the page
	 */
	onLinkClick() {
		this.props.setSTPanelStatus({ open: false, matchid: '', panelToST: true });
		this.props.navigate({ path: `/en_US/scores/stats/${this.props.stPanel.matchid}.html` });
		// location.href = `/en_US/scores/stats/${this.props.stPanel.matchid}.html`;
	}

	render() {
		// logger.log('[SlamtrackerPanel] render - this:%o', this);

		let stPanelTop = this.calculateTop();
		let stPanelStyle = {};
		stPanelStyle = {
			top: `${stPanelTop}px`,
		};

		return this.props?.stPanel?.open && this.props?.stPanel?.matchid !== '' ? (
			<div className="stpanel-container open" style={stPanelStyle}>
				<div className="stpanel-content">
					<div className="stpanel-content-close">
						<i className="icon-close" onClick={() => this.onClose()}></i>
					</div>
					<div className="stpanel-content-title">
						<h3 className="header">IBM SlamTracker</h3>
						{/* <div
							className="st-link"
							onClick={() => {
								this.onLinkClick();
							}}>
							View match details <i className="icon-arrow-right"></i>
						</div> */}
					</div>
					<div className="stpanel-content-ad">
						<AdUnitLazy data={{ adType: 'slamtrackerPanel' }} />
					</div>
					<SlamtrackerContent
						matchId={this.props.stPanel.matchid}
						matchStatus={this.props?.stPanel?.matchstatus}
						location="panel"
            // isHeaderFixed={this.state?.isHeaderFixed}
					/>
				</div>
			</div>
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlamtrackerPanel);
