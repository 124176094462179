import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { fetch } from 'appdir/components/general/Util';
import StubBox from 'appdir/components/common-ui/StubBox';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
// import PowerIndexNumbers from './elements/PowerIndexNumbers';

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		sharedDataConfig: state['Config'].sharedDataConfig,
		matchInsightsMatches: state['CommonData'].matchInsightsMatches,
		configScoringData: state['Config'].scoringData,
		configOtherData: state['Config'].otherData,
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchInsights.mount()),
	unmount: () => dispatch(deps.actions.MatchInsights.unmount()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
});

class MIPlayerPR extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stub: false,
			expired: false,
			matchInsightsData: null,
		};

		this.matchId = null;
		this.sharedDataLoaded = false;
		this.checkMatchExists = false;
		// logger.log('[MIPlayerPR] constructor - state:%o, props: %o', this.state, this.props);
	}

	componentDidUpdate(prevProps, prevState) {
		this.matchId = this.props.matchId;

		if (prevProps.matchId !== this.matchId) {
			this.setState({
				matchExists: false,
			});

			this.checkMatchExists = false;
		}

		if (prevProps.status !== this.props.status && this.props.status == 'loaded') {
			// load all the available MI matches list to check if the selected MatchId exists
			this.props
				.checkExpired(this.props.sharedDataConfig['matchInsightsMatches'])
				.then(response => {
					logger.log('[MIPlayerPR] componentDidUpdate matchInsightsMatches - response:%o', response);
					if (response.status == 'expired') {
						this.props.update(this.props.sharedDataConfig['matchInsightsMatches']);
					}
				})
				.catch(error => {
					logger.error('[MIPlayerPR] componentDidUpdate matchInsightsMatches - :o', error);
				});
		}

		/** Check if cloud Match Insight data exists in the master maches ids list file */
		if (
			this.props.status == 'loaded' &&
			op.get(this.props, 'matchInsightsMatches.status', '') == 'loaded' &&
			!this.checkMatchExists
		) {
			let matchesList = op.get(this.props, 'matchInsightsMatches.result.matches', []);
			this.checkMatchExists = true;

			if (matchesList.length > 0) {
				this.setState({
					matchExists: matchesList.includes(this.matchId),
				});
			} else {
				this.setState({
					matchExists: false,
				});
			}
		}

		if (
			(prevProps.status !== this.props.status &&
				this.props.status == 'loaded' &&
				this.matchId &&
				this.state.matchExists) ||
			(prevProps.matchId !== this.matchId && this.state.matchExists) ||
			(prevState.matchExists !== this.state.matchExists && this.state.matchExists)
		) {
			logger.log('[MIPlayerPR] componentDidUpdate - loading data files');
			let { configScoringData, configOtherData } = this.props;

			let matchInsightsDataPath = configScoringData.matchInsights.replace('<matchId>', this.matchId);

			// fetch scores match insights data file - used to get event name, court name, etc
			fetch(matchInsightsDataPath).then(mi_result => {
				logger.log('[MIPlayerPR] componentDidUpdate - mi_result:%o', mi_result);

				let matchFactsPath = configOtherData.innovations.matchInsightsFacts.replace(
					'<matchId>',
					this.matchId
				);
				let powerIndexMatchupPath = configOtherData.innovations.powerIndexMatchup.replace(
					'<matchId>',
					this.matchId
				);

				logger.log('[MIPlayerPR] componentDidUpdate - matchFactsPath:%o', matchFactsPath);
				logger.log('[MIPlayerPR] componentDidUpdate - powerIndexMatchupPath:%o', powerIndexMatchupPath);

				// fetch nlg match insights data file
				fetch(matchFactsPath)
					.then(mf_result => {
						logger.log('[MIPlayerPR] componentDidUpdate - mf_result:%o', mf_result);

						// fetch nlg match insights data file
						fetch(powerIndexMatchupPath)
							.then(pim_result => {
								logger.log('[MIPlayerPR] componentDidUpdate - pim_result:%o', pim_result);

								if (op.get(mi_result, 'matches', []).length > 0) {
									this.setState({
										powerIndexMatchup: pim_result,
										matchInsightsData: mi_result.matches[0],
										matchInsightsLastUpdate: mf_result['published_date_milliseconds'],
										matchInsightsStatus: pim_result['publication_date_milliseconds']
											? 'loaded'
											: 'error',
									});
								} else {
									this.setState({
										matchInsightsStatus: 'error',
									});
								}
							})
							.catch(error => {
								logger.log(
									'[MIPlayerPR] componentDidUpdate error loading powerIndexMatchup data:%o',
									error
								);
								this.setState({
									powerIndexMatchup: null,
									matchInsightsData: mi_result.matches[0],
									matchInsightsLastUpdate: mf_result['published_date_milliseconds'],
									matchInsightsStatus: 'error',
								});
							})
							.catch(error => {
								logger.log(
									'[MIPlayerPR] componentDidUpdate error loading nlg match insights data:%o',
									error
								);
								this.setState({
									powerIndexMatchup: null,
									matchInsightsData: mi_result.matches[0],
									matchInsightsLastUpdate: null,
									matchInsightsStatus: 'error',
								});
							});
					})
					.catch(error => {
						logger.log(
							'[MIPlayerPR] componentDidUpdate error loading scores match insights data:%o',
							error
						);
						this.setState({
							powerIndexMatchup: null,
							matchInsightsData: null,
							matchInsightsLastUpdate: null,
							matchInsightsStatus: 'error',
						});
					});
			});
		}
		// logger.log('[MIPlayerPR] componentDidUpdate  - this.props:%o, this.state:%o', this.props, this.state);
	}

	getStubContent = () => {
		return (
			<div className="column-layout news">
				<StubBox attributes={{ message: op.get(this.props, 'stubs.matchinsights.text', '') }} />
			</div>
		);
	};

	render() {
		logger.log('[MIPlayerPR] render - props:%o, this.state:%o', this.props, this.state);
		let isError = op.get(this.state, 'matchInsightsStatus', false) == 'error';
		let isExists = op.get(this.state, 'matchExists', true);

		if (this.props.status == 'loaded' && op.get(this.state, 'matchInsightsData', false) && isExists && !isError) {
			let { matchInsightsData, powerIndexMatchup } = this.state;
			this.teams = [matchInsightsData.team1, matchInsightsData.team2];


			{/* MD - Innovation 2024 - Decommision Power Index */}
			{/* return (
				<PowerIndexNumbers
					powerIndexMatchupData={powerIndexMatchup}
					teams={this.teams}
					windowSize={this.props?.EventsWindow?.windowSize}
					type={'blog'}
				/>
				);	
			*/}

			return null;

			/** in case they want to have the player images, keeping the previous version
			 *  to model after
			 */
			// return (
			// 	<div className="content-main match-insights player-pr">
			// 		<div className="player-pr-title">
			// 			<div className="contents-in-header-title">
			// 				<h1>
			// 					IBM Power Index
			// 					<MIInfoIcon onInfoClickFn="" />
			// 				</h1>
			// 			</div>
			// 			<div className="mi-last-updated">
			// 				Last Update:{' '}
			// 				<Time epoch_ms={this.state.matchInsightsLastUpdate} format="ddd hh:mm A" options="upper" />
			// 			</div>
			// 		</div>
			// 		<div className="mi-pr-info-wrapper">
			// 			<div className="mi-pr-player-info-wrapper">
			// 				<PlayerBox
			// 					data={matchInsightsData.team1}
			// 					teamnum="A"
			// 					imgPath={this.props.configOtherData.playerProfileImagePath}
			// 					flagPath={this.props.configOtherData.flagImagePathSmall}
			// 					key={`playerA-box`}
			// 					likelihoodWinData={''}
			// 				/>
			// 			</div>
			// 			<div className="mi-pr-ranks-section tablet">
			// 				<div className="mi-pr-ranks">
			// 					<div className="ranking-row">
			// 						<div className="ranking-cell player1-pr-rank">
			// 							{powerIndexMatchup[matchInsightsData.team1['idA']]['wpi_rank']}
			// 						</div>
			// 						<div className="ranking-cell rank-label">IBM Power Rank</div>
			// 						<div className="ranking-cell player2-pr-rank">
			// 							{powerIndexMatchup[matchInsightsData.team2['idA']]['wpi_rank']}
			// 						</div>
			// 					</div>
			// 					<div className="ranking-row">
			// 						<div className="ranking-cell atp-rank">
			// 							{powerIndexMatchup[matchInsightsData.team1['idA']]['tour_rank']}
			// 						</div>
			// 						<div className="ranking-cell rank-label atp">
			// 							{matchInsightsData.eventId == 'WS' || matchInsightsData.eventCode == 'WS'
			// 								? 'WTA'
			// 								: 'ATP'}{' '}
			// 							Singles Rank
			// 						</div>
			// 						<div className="ranking-cell atp-rank">
			// 							{powerIndexMatchup[matchInsightsData.team2['idA']]['tour_rank']}
			// 						</div>
			// 					</div>
			// 				</div>
			// 				<div className="mi-pr-ranks-wrapper">
			// 					<EventsLink style={`border center`} to="/en_US/powerindex/index.html">
			// 						IBM Power Index Leaderboard
			// 					</EventsLink>
			// 				</div>
			// 			</div>
			// 			<div className="mi-pr-player-info-wrapper">
			// 				<PlayerBox
			// 					data={matchInsightsData.team2}
			// 					teamnum="B"
			// 					imgPath={this.props.configOtherData.playerProfileImagePath}
			// 					flagPath={this.props.configOtherData.flagImagePathSmall}
			// 					key={`playerB-box`}
			// 					likelihoodWinData={''}
			// 				/>
			// 			</div>
			// 			<div className="mi-pr-ranks-section mobile">
			// 				<div className="mi-pr-ranks">
			// 					<div className="ranking-row">
			// 						<div className="ranking-cell player1-pr-rank">
			// 							{powerIndexMatchup[matchInsightsData.team1['idA']]['wpi_rank']}
			// 						</div>
			// 						<div className="ranking-cell rank-label">IBM Power Rank</div>
			// 						<div className="ranking-cell player2-pr-rank">
			// 							{powerIndexMatchup[matchInsightsData.team2['idA']]['wpi_rank']}
			// 						</div>
			// 					</div>
			// 					<div className="ranking-row">
			// 						<div className="ranking-cell atp-rank">
			// 							{powerIndexMatchup[matchInsightsData.team1['idA']]['tour_rank']}
			// 						</div>
			// 						<div className="ranking-cell rank-label atp">
			// 							{matchInsightsData.eventId == 'WS' ? 'WTA' : 'ATP'} Singles Rank
			// 						</div>
			// 						<div className="ranking-cell atp-rank">
			// 							{powerIndexMatchup[matchInsightsData.team2['idA']]['tour_rank']}
			// 						</div>
			// 					</div>
			// 				</div>
			// 				<div className="mi-pr-ranks-wrapper">
			// 					<EventsLink style={`border center`} to="/en_US/powerindex/index.html">
			// 						IBM Power Index Leaderboard
			// 					</EventsLink>
			// 				</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// );
		} else {
			return (
				<div className="content-main match-insights player-pr">
					{op.get(this.state, 'matchExists', null) == false ? this.getStubContent() : <LoadingIndicator />}
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MIPlayerPR);
