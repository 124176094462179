/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import ReactHtmlParser from 'html-react-parser';
import ModalOverlay from 'appdir/components/general/ModalOverlay';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import Button from 'appdir/components/common-ui/Button';
import EventsLink from 'appdir/components/general/EventsLink';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * RLL - some of this may change based on how this works for everyone and once
 * 	measurement gets sorted out
 * React Component: Share
 *
 * props:
 * url - specific url to share if not the current page
 * section - section for measurement
 * title - title for measurement
 * -----------------------------------------------------------------------------
 */

const FanWeekScheduleModal = props => {
	let { modalParams } = useSelector(state => state['ModalOverlay']);
	let { scheduleItem } = modalParams;
	// let session = stadium?.session?.[modalParams?.sessionIndex];
	// session['theme']['title'] = "theme title";
	// session['theme']['description'] = "<p>description</p>";

	logger.log('[FanWeekScheduleModal] props - props:%o', props);
	logger.log('[FanWeekScheduleModal] props - modalParams:%o', modalParams);

	const dispatch = useDispatch();

	const onClose = () => {
		document.body.classList.remove('modal-on-noscroll-nofixed');
		dispatch(deps.actions.ModalOverlay.hideOverlayModal());
	};

	/* on load (componenDidMount) */
	useEffect(() => {
		if (!document.body.classList.contains('modal-on-noscroll-nofixed')) {
			document.body.classList.add('modal-on-noscroll-nofixed');
		} else {
			document.body.classList.remove('modal-on-noscroll-nofixed');
		}
	}, []);

	let image = scheduleItem?.images?.[0].url ?? false;
	let image_alt = scheduleItem?.images?.[0].caption ?? '';

	return (
		<ModalOverlay onClose={onClose} containerClass="fanweek-schedule-modal" modalClass="fanweek-info-modal">
			{scheduleItem && (
				<div className="modal-content-wrapper">
					<div className="fanweek-section">
						{/* <div className="image">
							<img src={image} alt={image_alt} />
						</div> */}
						<div className="content-container">
							<div className="content info">
								{scheduleItem?.title && <div className="title">{scheduleItem?.title}</div>}
								{scheduleItem?.location && <span className="location">{scheduleItem?.location}</span>}
								{scheduleItem?.date && <span className="fanweek-date">{scheduleItem?.date}</span>}
								{scheduleItem?.all_day === true ? (
									<span className="time">All Day</span>
								) : scheduleItem?.start_time && scheduleItem?.end_time ? (
									<span className="time">{`${scheduleItem?.start_time} to ${scheduleItem?.end_time} (ET)`}</span>
								) : null}
								{scheduleItem?.description && (
									<div className="description">{ReactHtmlParser(scheduleItem?.description)}</div>
								)}
							</div>

							{/* {item.button_text ? (
								<div className="content-button">
									<EventsLink to={item.link}>{item.button_text}</EventsLink>
								</div>
							) : (

								// showModal, stadium, sessionIndex, tournDay
								<div className="content-button learn-more">
									<div onClick={e => { this.openModal(true, item.type, item.date, item);}}>
										Learn More
									</div>
								</div>
							)}

							<div className="content-link-buttons">
								{item?.free_event && (
									<div className="fanweek-tickets free-event">
										<i className="icon-thin-check"></i>
										Free Event
									</div>
								)}
								{item?.ticket_link && (
									<div className="fanweek-tickets link-button">
										<EventsLink to={item.ticket_link}>
											<i className="icon-tickets"></i>
											Tickets
										</EventsLink>
									</div>
								)}
								{item?.location_map_link && (
									<div className="fanweek-map link-button">
										<EventsLink to={item.location_map_link}>
											<i className="icon-grounds-map"></i>
											Find It On the Map
										</EventsLink>
									</div>
								)}
								{item?.fan_pass_link && (
									<div className="fanweek-fanaccess link-button">
										<EventsLink to={item.fan_pass_link}>
											<i className="icon-qr-code"></i>
											{item?.fan_pass_text || 'Fan Access Pass Required'}
										</EventsLink>
									</div>
								)}
							</div> */}
						</div>
					</div>
				</div>
			)}
		</ModalOverlay>
	);
};
export default FanWeekScheduleModal;
