import React from 'react';
import BasicDisplay from './BasicDisplay';
import ButtonGroup from './ButtonGroup';

/**
 * ---------------------------------------
 * DoubleStack - Module with 2,4,6 pieces of content. the module will have the ability to have a title.
 * the content within the module has an image and a title
 * ---------------------------------------
 */

const DoubleStack = props => {
	// logger.log('[DoubleStack] render props:%o, ', props);
	let data = props.data.content.items;
	let buttonData = props.data.content.buttons;
	let measureData = {
		...props.data,
		type: props.data.type,
		itemId: props.data.id,
		itemType: props.data.type,
		actionType: 'homePageClick',
	};
	delete measureData.content;

	return (
		<div className="double-stack">
			<div className="story-wrapper">
				{data.map((item, i) => {
					return (
						<div className="story" key={`ds${i}`}>
							<BasicDisplay
								data={item}
								measureData={{ ...measureData, which: `ds_story${i}_link`, itemTitle: item.title }}
							/>
						</div>
					);
				})}
			</div>
			{buttonData.length > 0 ? (
				<ButtonGroup
					data={buttonData}
					measureData={{
						...measureData,
						which: 'button',
						actionType: 'homepage_button',
						moduleType: data.type,
						title: data.title,
					}}
				/>
			) : null}
		</div>
	);
};

export default DoubleStack;
