/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import PageHeader from 'appdir/components/general/PageHeader';
import NewsTile from 'appdir/components/content/NewsTile';
import AdTag from 'components/general/AdTag';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: Media Landing
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['NewsIndex'],
		landingPages: state['Config'].landingPages,
		tournamentYear: state['Config']?.scoring?.tournamentYear,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.NewsIndex.mount()),
	filter: settings => dispatch(deps.actions.NewsIndex.filter(settings)),
});

class MediaLanding extends Component {
	constructor(props) {
		super(props);

		logger.log('[MediaLanding] constructor - props:%o ', props);

		this.state = {
			loaded: false,
			hero: null,
			showAll: true,
			years: [],
			defaultDay: null,
			defaultDayId: null,
			defaultMonth: null,
			defaultYear: null,
			selectedYear: '',
			selectedMonth: '',
			selectedDay: '',
			selectedDayId: '',
			newsIndex: null,
			tournIndex: null,
			tournIdIndex: null,
			filter: null,
			urlParams: null,
			relatedContent: null,
			yearsAvailable: [],
			tournDatesAvailable: [],
			showingCells: 12,
			error: false,
		};

		this.loadedJson = ''; //keep track of last loaded json url
		this.filterType = '';
		this.loadMoreCount = 1;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'News',
		});
	}

	componentDidMount() {
		logger.log('[MediaLanding] componentDidMount - state:%o', this.state);
		// if (this.state.location.search != "") {
		//   this.getFilter(this.state.location.search);
		// }
	}

	componentWillUnmount() {
		this.props.filter({
			type: '',
			open: '',
		});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[MediaLanding] getDerivedStateFromProps - prev:%o next:%o', prevState, nextProps);

		let url = ''; // redirect url
		let data = prevState.data; // json data
		// logger.log(
		// 	'[MediaLanding] getDerivedStateFromProps - next props status:%o this state:%o next props:%o',
		// 	nextProps.status,
		// 	prevState,
		// 	nextProps
		// );

		let newState = {
			...prevState,
			nav: url,
			data: data,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[MediaLanding] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[MediaLanding] componentDidUpdate - this: %o', this);
		const showAll = this.state.showAll;
		const year = this.state.selectedYear;
		const month = this.state.selectedMonth;
		const day = this.state.selectedDay;
		const filter = JSON.stringify(this.state.urlParams);
		const newsIndex = this.state.newsIndex;
		const tournIndex = this.state.tournIndex;

		if (!isEqual(prevProps, this.props)) {
			this.getFilter(this.props.location.search);
		}

		// if (this.state.hero == null) {
		// 	this.getHero();
		// }

		if (showAll) {
			if (newsIndex == null) {
				this.getNewsIndex();
			} else if (
				prevState.selectedMonth != month ||
				prevState.selectedYear != year ||
				prevState.showAll != showAll
			) {
				this.constructUrl();
				this.getRelatedContent();
			}
		} else {
			if (tournIndex == null) {
				this.getTournIndex();
			} else if (prevState.selectedDay != day || prevState.showAll != showAll) {
				this.constructUrl();
				this.getRelatedContent();
			}
		}
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function openGallery
	 * open the gallery
	 * -----------------------------------------------------------------------------
	 */
	openGallery = (selectedItem, title) => {
		this.setState({ galleryOpen: true, selectedItem });
		MeasurementUtils.dispatchMeasurementCall('PhotosOpenGallery', {
			title,
		});
		window.removeEventListener('click', this.handleBodyClick);
	};

	closeGallery = () => {
		this.setState({ galleryOpen: false });
		MeasurementUtils.dispatchMeasurementCall('PhotosCloseGallery');
		window.addEventListener('click', this.handleBodyClick);
	};

	getFilter(search) {
		// logger.log('[MediaLanding] getFilter - this: %o', this);
		search = search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);
		//parsedQs = {year: "2018", month: "11", day: "", mode: "all", filter: ""}
		if (JSON.stringify(this.state.urlParams) != JSON.stringify(parsedQs)) {
			this.setState({
				urlParams: parsedQs,
			});
		}
	}

	parseFilter() {
		// parsedQs = {year: "2018", month: "11", day: "", mode: "all", filter: ""}
		const filter = this.state.urlParams;
		if (filter != null && filter.mode != null) {
			let showAll = this.state.showAll;
			let selectedYear = '';
			let selectedMonth = '';
			let selectedDayId = '';
			let selectedDay = '';

			logger.log('[MediaLanding] Parse Filter: %o', filter);

			if (filter.year != undefined) {
				selectedYear = filter.year;
			}
			if (filter.month != undefined) {
				selectedMonth = filter.month;
			}
			if (filter.day != undefined) {
				selectedDayId = filter.day;
			}
			if (filter.mode != undefined) {
				showAll = filter.mode == 'all' ? true : false;
			} else {
				showAll = true;
			}

			this.setState({
				relatedContent: null,
				showAll: showAll,
				defaultYear: selectedYear,
				defaultMonth: selectedMonth,
				defaultDayId: selectedDayId,
			});
		}
	}

	constructUrl() {
		let urlString = '?year=<y>&month=<m>&day=<d>&mode=<mode>&filter=';

		if (this.state.showAll) {
			urlString = urlString.replace('<y>', () => {
				return this.state.selectedYear == '' ? this.state.defaultYear : this.state.selectedYear;
			});
			urlString = urlString.replace('<m>', () => {
				return this.state.selectedMonth == '' ? this.state.defaultMonth : this.state.selectedMonth;
			});
			urlString = urlString.replace('<d>', () => {
				return '';
			});
			urlString = urlString.replace('<mode>', () => {
				return 'all';
			});
		} else {
			urlString = urlString.replace('<y>', () => {
				return '';
			});
			urlString = urlString.replace('<m>', () => {
				return '';
			});
			urlString = urlString.replace('<d>', () => {
				return this.state.selectedDayId == '' ? this.state.defaultDayId : this.state.selectedDayId;
			});
			urlString = urlString.replace('<mode>', () => {
				return 'tourn';
			});
		}
		logger.log('[MediaLanding] constructUrl: %o', urlString);

		// until url is fixed, this is breaking refresh
		// this.props.history.push({
		//   pathname: "/en_US/news/index.html",
		//   search: urlString
		// });
	}

	getNewsIndex() {
		// logger.log('[MediaLanding] getNewsIndex: %o', this);
		//logger.log('[MediaLanding] api data: %o', apiDataPath);
		if (this.props.path != null) {
			let apiDataPath = this.props.path.replace('<section>', () => {
				return 'latest_index';
			});
			fetch(apiDataPath).then(result => {
				let dates = result.content.dates;
				let newsIndexDates = {};
				let firstObject = null;
				let years = {};
				let defaultYear = this.state?.urlParams?.year;
				let defaultMonth = this.state?.urlParams?.month;

				firstObject = dates[0];

				dates.forEach(function(element) {
					newsIndexDates[element.key] = {
						file: element.file,
					};
					years[element.year] = '';
				});

				if (
					(defaultYear == null && defaultMonth == null) ||
					newsIndexDates[defaultYear + defaultMonth] == null
				) {
					defaultYear = dates[0].year;
					defaultMonth = dates[0].month;
				}

				let yearsArray = Object.keys(years)
					.sort()
					.reverse();

				if (this.state.selectedMonth == '' || this.state.selectedYear == '') {
					this.setState(
						{
							newsIndex: newsIndexDates,
							firstNewsObject: firstObject,
							yearsAvailable: yearsArray,
							defaultYear: defaultYear,
							defaultMonth: defaultMonth,
							selectedYear: defaultYear,
							selectedMonth: defaultMonth,
						},
						() => {
							logger.log('[MediaLanding] after getNewsIndex: %o', this.state);
						}
					);
				} else {
					this.setState(
						{
							newsIndex: newsIndexDates,
							firstNewsObject: firstObject,
							yearsAvailable: yearsArray,
							defaultYear: defaultYear,
							defaultMonth: defaultMonth,
						},
						() => {
							logger.log('[MediaLanding] after getNewsIndex: %o', this.state);
						}
					);
				}
			});
		}
	}

	getTournIndex() {
		// logger.log('[MediaLanding] getTournIndex: %o', this);

		let apiDataPath = this.props.path.replace('<section>', () => {
			return 'news_tourn_index';
		});

		fetch(apiDataPath).then(result => {
			const dates = result.content.dates;
			// logger.log('[MediaLanding] this:%o', this);
			let tournIndexDates = {};
			let tournIdIndex = {};
			let firstObject = null;
			let newsFilterKey = null;
			let tournDates = [];
			let defaultDay = this.state.defaultDay != null ? this.state.defaultDay : null;
			let defaultDayId = this.state.urlParams.day != null ? this.state.urlParams.day : null;

			firstObject = dates[0];
			newsFilterKey = dates[0].display;

			dates.forEach(function(element) {
				tournIndexDates[element.display] = {
					file: element.file,
					key: element.key,
				};
				tournIdIndex[element.key] = {
					file: element.file,
					display: element.display,
				};
				tournDates.push({
					display: element.display,
					dayid: element.dayid,
					key: element.key,
				});
			});

			if (tournIdIndex[defaultDayId] != null) {
				defaultDay = tournIdIndex[defaultDayId].display;
			} else {
				let defaultDayObject = find(dates, function(o) {
					return o.chunk > 0;
				});
				logger.log('[MediaLanding] defaultDayObject:%o', defaultDayObject);
				defaultDay = defaultDayObject.display; //newsFilterKey;
				defaultDayId = defaultDayObject.key; //dates[0].key;
			}

			let tournArray = tournDates.sort((a, b) => {
				return a.dayid - b.dayid;
			});

			logger.log('[MediaLanding] getTournIndex - tournIndexDates: %o', tournIndexDates);

			if (this.state.selectedDay == '' || this.state.selectedDayId == '') {
				this.setState(
					{
						tournIndex: tournIndexDates,
						tournIdIndex: tournIdIndex,
						firstTournObject: firstObject,
						tournDatesAvailable: tournArray,
						defaultDayId: defaultDayId,
						defaultDay: defaultDay,
						selectedDay: defaultDay,
						selectedDayId: defaultDayId,
					},
					() => {
						logger.log('[MediaLanding] after getTournIndex: %o', this.state);
					}
				);
			} else {
				this.setState(
					{
						tournIndex: tournIndexDates,
						tournIdIndex: tournIdIndex,
						firstTournObject: firstObject,
						tournDatesAvailable: tournArray,
						defaultDayId: defaultDayId,
						defaultDay: defaultDay,
					},
					() => {
						logger.log('[MediaLanding] after getTournIndex: %o', this.state);
					}
				);
			}
		});
	}

	getRelatedContent() {
		// logger.log(this.state.relatedContentLatest + 'getRelated');

		let filePath = '';
		const newsIndex = this.state.newsIndex;
		const tournIndex = this.state.tournIndex;
		const showAll = this.state.showAll;
		let month = this.state.selectedMonth == '' ? this.state.defaultMonth : this.state.selectedMonth;
		let year = this.state.selectedYear == '' ? this.state.defaultYear : this.state.selectedYear;
		let day = this.state.selectedDay == '' ? this.state.defaultDay : this.state.selectedDay;

		// if(this.state.firstNewsObject != null) {
		//     filePath = this.state.firstNewsObject.file;
		// } else {
		//     filePath = this.state.newsIndexDates[this.state.newsFilterKey].file;
		// }
		if (showAll) {
			let key = String(year + month);
			if (newsIndex[key] != null) {
				// logger.log(JSON.stringify(this.state.newsIndex) + 'filepath key');
				filePath = newsIndex[key].file;
			} else {
				// logger.log("[MediaLanding] File path doesn't exist for key: %o", key);
				this.setEmptyRelatedContent();
			}
		} else {
			if (tournIndex[day] != null) {
				filePath = tournIndex[day].file;
			} else {
				// logger.log("[MediaLanding] File path doesn't exist for key: %o", day);
				this.setEmptyRelatedContent();
			}
		}

		if (filePath != '') {
			let path = this.props.newsFilterPath.replace('<url>', () => {
				return filePath;
			});
			// logger.log('[MediaLanding] getRelatedContent - filePath:%o', filePath);

			fetch(path).then(result => {
				// logger.log(path + 'filepath2');
				// logger.log('[MediaLanding] getRelatedContent - result:%o', result.content);
				const adObject = {
					type: 'ad',
				};

				if (result.content.length > 0) {
					if (result.content.length < 3) {
						result.content[result.content.length] = adObject;
					} else {
						result.content.splice(2, 0, adObject);
					}
					// The following are the options for ad placement:
					//  yearround
					//  tournament

					if (this.props.adMode == 'tournament' && result.content.length > 7) {
						for (var i = 7; i < result.content.length; i++) {
							if ((i - 2) % 5 == 0) {
								result.content.splice(i, 0, adObject);
							}
						}
					}
				}

				this.setState({
					relatedContent: result,
					showingCells: 10,
					error: false,
				});
			});
		}
	}

	setEmptyRelatedContent() {
		this.setState({
			relatedContent: {
				content: [],
			},
		});
	}

	getHero() {
		const path = this.props.heroPath;
		// logger.log('[MediaLanding] getHero - path: %o', path);
		if (path) {
			fetch(path).then(result => {
				// logger.log('[MediaLanding] getHero - result: %o', result);
				this.setState({
					hero: result.hero,
				});
			});
		}
	}

	checkError() {
		//check if need to display error
		let error = '';
		if (this.count == 0) {
			error = 'show';
		}
		return <div className={`errorMessage ${error}`}>There is no content for your selection.</div>;
	}

	mapVideoData(data, autoPlay) {
		// logger.log('[MediaLanding] data:%o', data);
		if (data && !isEmpty(data)) {
			let vastCategory = 'default';
			// if (data?.subType == 'highlights') {
			// 	vastCategory = 'highlights';
			// } else if (data?.subType == 'features') {
			// 	vastCategory = 'features';
			// }
			return {
				id: data.cmsId,
				title: data.title,
				description: data.description && data.description.length > 0 ? data.description : data.title,
				poster: data.images[0].xlarge,
				shareUrl: data.shareUrl,
				streams: [
					{
						cdn: 'Akamai',
						url: data.media.m3u8,
						mimeType: data.media.m3u8.includes('m3u8') ? 'application/x-mpegURL' : 'video/mp4',
						streamType: 'vod',
					},
				],
				tracks:
					get(data, 'media.tracks', false) && get(data, 'media.tracks[0].file', false)
						? [
								{
									kind: 'captions',
									type: 'text/vtt',
									srclang: 'en',
									src: data.media.tracks[0].file,
								},
						  ]
						: [],
				startSeconds: 0,
				autoplay: true, //autoPlay ? autoPlay : false,
				autoplayPolicy: 'mutedinline',
				playsinline: true,
				displayDate: data.displayDate,
				ads: [this.props.adConfig.vast[vastCategory]],
				category: data.subType,
			};
		}
	}

	renderContent() {
		if (this.state.relatedContent == null) {
			return <LoadingIndicator />;
		}
		if (this.state.relatedContent.content.length == 0) {
			return this.renderNoNews();
		} else {
			return this.renderNews();
		}
	}

	renderNews() {
		let content = this.state.relatedContent.content;
		const numberOfCells = this.state.showingCells;

		return (
			<div className="news-tile">
				<div className="newsChips">
					<div className="chip_content">
						{/* {logger.log('[MediaLanding] renderNews - content: %o', content)} */}
						{content?.map((item, index) => {
							// logger.log('[MediaLanding] renderNews - item: %o', item);

							if (index < numberOfCells) {
								if (item.type === 'news') {
									let newsObject = {};
									const imagePrefix = this.props.newsImagePath.replace('<url>', () => {
										return '';
									});
									newsObject['col-style'] = 'three-col';
									newsObject.contentId = item.contentId;
									newsObject.date = item.displayDate;
									newsObject.images = item.images[0];
									newsObject.shortTitle = item.shortTitle;
									newsObject.title = item.title;
									newsObject.type = item.type;
									newsObject.prefix = imagePrefix;
									newsObject.metadata = item.metadata;
									newsObject.subType = item.subType;
									if (item.metadata.credit !== '') {
										newsObject.author = 'By ' + item.metadata.credit;
									} else {
										newsObject.author = '';
									}
									newsObject.url = item.url;
									newsObject.onClick = e => {
										this.newsTileClicked(e, index);
									};

									return <NewsTile attributes={newsObject} key={item.contentId} />;
								} else if (item.type == 'photo') {
									let mediaObject = {};
									const imagePrefix = this.props.domain;

									mediaObject['col-style'] = 'three-col';
									mediaObject.contentId = item.contentId;
									mediaObject.date = item.displayDate;
									mediaObject.images = item.images[0];
									mediaObject.shortTitle = item.description;
									mediaObject.title = item.title;
									mediaObject.prefix = imagePrefix;
									mediaObject.type = item.type;
									mediaObject.subType = item.subType;
									mediaObject.author = item?.images[0]?.credit ? item?.images[0]?.credit : null;
									mediaObject.url = item.url;
									mediaObject.metadata = item.metadata;
									mediaObject.onClick = e => {
										this.photosTileClicked(e, index);
									};
									// mediaObject.onClick = e => {
									// 	item.type == 'photo' ? this.photosTileClicked(e) : this.videoTileClicked(e);
									// };

									return (
										<NewsTile
											id={`${item.type}Tile-${index}`}
											attributes={mediaObject}
											key={`${item.contentId}-${index}`}
											//onClick={() => this.openGallery(item.index, item.title)}
											mouseOverCaptions={true}
										/>
									);
								} else if (item.type == 'video') {
									let mediaObject = {};
									const imagePrefix = this.props.domain;
									mediaObject['col-style'] = 'three-col';
									mediaObject.contentId = item.contentId;
									mediaObject.date = item.displayDate;
									mediaObject.images = item.images[0];
									mediaObject.shortTitle = item.description;
									mediaObject.title = item.title;
									mediaObject.prefix = imagePrefix;
									mediaObject.type = item.type;
									mediaObject.subType = item.subType;
									mediaObject.metadata = item.metadata;
									mediaObject.author = item?.images[0]?.credit ? item?.images[0]?.credit : null;
									{
										/* mediaObject.onClick = e => {
										this.videoTileClicked(e);
									}; */
									}

									return (
										<NewsTile
											data={this.mapVideoData(item)}
											id={`${item.type}Tile-${item.index}`}
											attributes={mediaObject}
											key={`${item.contentId}-${index}`}
											//onClick={() => this.openGallery(item.index, item.title)}
											mouseOverCaptions={true}
										/>
									);
								} else {
									return (
										<div key={index} id={`ad${index}`} className="news-ad">
											<AdTag
												adConfig={this.props.adConfig.landingpage}
												dfpNetworkId={this.props.adConfig.dfpNetworkId}
											/>
										</div>
									);
								}
							} else {
								return null;
							}
						})}
					</div>
					{this.renderLoadMore()}
				</div>
			</div>
		);
	}

	renderNoNews() {
		return (
			<div className="news-tile">
				<div className="no-news">
					<p>No news was found for the current selection.</p>
				</div>
			</div>
		);
	}

	renderLoadMore() {
		if (this.state.relatedContent.content.length > this.state.showingCells) {
			return (
				<button
					onClick={e => {
						this.loadMorePressed(e);
					}}
					className="load-more-button">
					Load More
				</button>
			);
		} else {
			return null;
		}
	}

	loadMorePressed(e) {
		MeasurementUtils.dispatchMeasurementCall('LoadMore', {
			count: this.loadMoreCount,
		});
		this.loadMoreCount++;
		this.setState({
			showingCells: this.state.showingCells + 12,
		});
	}

	renderHero() {
		if (this.state.hero) {
			const imagePath = this.props.newsImagePath.replace('<url>', () => {
				return this.state.hero.photo.desktop;
			});

			const header_attributes = {
				headerType: 'generic',
				title: !this.state.hero.title ? '---' : this.state.hero.title,
				shortTitle: !this.state.hero.type ? '---' : this.state.hero.type,
				titleElem: '.landing--header',
				metaTitle: !this.state.hero.type ? '' : this.state.hero.type,
				metaDescription: '',
				imageSrc: imagePath,
				metaDate: '',
				metaPlayers: '',
				link: this.state.hero.url,
				canonicalLink: document.location.href.split('?')[0],
			};

			return <PageHeader attributes={header_attributes} />;
		} else {
			return null;
		}
	}

	convertNewsTile(item) {
		let newsObject = {};
		newsObject['col-style'] = 'three-col';
		newsObject.contentId = item.contentId;
		newsObject.date = item.displayDate;
		newsObject.images = item.images;
		newsObject.shortTitle = item.shortTitle;
		newsObject.title = item.title;
		newsObject.type = item.type;
		newsObject.url = item.url;
		newsObject.onClick = this.newsTileClicked;

		return <NewsTile attributes={item} />;
	}

	filter() {
		const showAll = this.state.showAll;
		return (
			<section className="filters">
				<div className="selection">
					{this?.props?.landingPages?.latest?.hideTournFilter ? null : (
						<div
							className={`photos tourny ${!showAll ? 'active' : ''}`}
							onClick={e => {
								this.radioButtonClick('Tourn');
							}}>
							<div className="radio select" />
							<span>{this.props.tournamentYear}</span> Tournament
						</div>
					)}
					{this?.props?.landingPages?.latest?.hideYearAndMonths ? (
						<section className="filters">
							<div className="selection">&nbsp;</div>
						</section>
					) : (
						<div
							className={`photos all ${showAll ? 'active' : ''}`}
							onClick={e => {
								this.radioButtonClick('All');
							}}>
							<div className="radio" />
							<span>By Year and</span> Month
						</div>
					)}
				</div>
				{this?.props?.landingPages?.latest?.hideYearAndMonths ? null : (
					<section className="filters third">{this.renderSubFilter(showAll)}</section>
				)}
			</section>
		);
	}

	minutely() {
		return <div className="minute-tv" data-embed-name="news"></div>;
	}

	renderSubFilter(isShowingAll) {
		if (isShowingAll) {
			return (
				<div>
					{this.renderYears()}
					<div className="filter-dropdown">
						<select
							name="months"
							id="filterMonths"
							key="filterMonths"
							onChange={e => {
								this.getSelectMonth(e);
							}}
							value={this.state.selectedMonth}>
							<option key="01" value="01">
								January
							</option>
							<option key="02" value="02">
								February
							</option>
							<option key="03" value="03">
								March
							</option>
							<option key="04" value="04">
								April
							</option>
							<option key="05" value="05">
								May
							</option>
							<option key="06" value="06">
								June
							</option>
							<option key="07" value="07">
								July
							</option>
							<option key="08" value="08">
								August
							</option>
							<option key="09" value="09">
								September
							</option>
							<option key="10" value="10">
								October
							</option>
							<option key="11" value="11">
								November
							</option>
							<option key="12" value="12">
								December
							</option>
						</select>
					</div>
				</div>
			);
		} else {
			return this.renderTournDates();
		}
	}

	renderTournDates() {
		const content = this.state.tournDatesAvailable;
		return (
			<div className="filter-dropdown">
				<select
					name="tournDates"
					id="filtertournDates"
					key="filtertournDates"
					onChange={e => {
						this.getSelectedTournDay(e);
					}}
					value={this.state.selectedDay}>
					{content.map((item, index) => {
						return (
							<option key={`${item.display}-${index}`} value={item.display}>
								{item.display}
							</option>
						);
					})}
				</select>
			</div>
		);
	}

	renderYears() {
		const content = this.state.yearsAvailable;

		return (
			<div className="filter-dropdown">
				<select
					name="years"
					id="yearListXs"
					key="filterYears"
					onChange={e => {
						this.getSelectYear(e);
					}}
					value={this.state.selectedYear}>
					{content.map((item, index) => {
						return (
							<option key={`${item}-${index}`} value={item}>
								{item}
							</option>
						);
					})}
				</select>
			</div>
		);
	}

	getSelectMonth = e => {
		// logger.log('[MediaLanding] - getSelectMonth: ');
		MeasurementUtils.dispatchMeasurementCall('SelectMonth', {
			selection: e.target.value,
		});
		this.setState({
			selectedMonth: e.target.value,
			relatedContent: null,
		});
	};

	getSelectYear = e => {
		// logger.log('[MediaLanding] - getSelectYear: ');
		MeasurementUtils.dispatchMeasurementCall('SelectYear', {
			selection: e.target.value,
		});
		this.setState({
			selectedYear: e.target.value,
			relatedContent: null,
		});
	};

	getSelectedTournDay = e => {
		const value = e.target.value;
		const key = this.state.tournIndex[value].key;
		// logger.log('[MediaLanding] - getSelectedTournDay value: %o ', value);
		MeasurementUtils.dispatchMeasurementCall('SelectDay', { selection: value });
		this.setState({
			selectedDay: e.target.value,
			selectedDayId: key,
			relatedContent: null,
		});
	};

	radioButtonClick(selection) {
		let checked = selection === 'All' ? true : false;
		MeasurementUtils.dispatchMeasurementCall('SelectCategory', {
			selection: selection,
		});
		this.setState({
			showAll: checked,
			relatedContent: null,
		});
		logger.log(checked);
		// console.dir(e);
		// logger.log('[MediaLanding] - radio button: ');
	}

	newsTileClicked(url, index) {
		logger.log('[MediaLanding] newsTileClicked - state:%o', this.state);
		MeasurementUtils.dispatchMeasurementCall('TileClicked', {
			index,
			url,
		});
		this.setState({
			nav: url,
		});
	}

	videoTileClicked() {
		logger.log('[MediaLanding] videoTileClicked - state:%o', this.state);
	}

	photosTileClicked(url, index) {
		logger.log('[Photos] photosTileClicked - state:%o', this.state);

		MeasurementUtils.dispatchMeasurementCall('TileClicked', {
			index,
			url,
		});

		this.setState({
			nav: url,
		});
	}

	render() {
		// logger.log('[MediaLanding] render - this:%o', this);

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: this.props?.sponsor,

			title: 'News',
			page_header: 'News',
		};
		// let promo = this.state.promo;
		let header_attributes = {};

		// logger.log('[MediaLanding] render - header:%o', header_attributes);

		if (this.state.nav && this.state.nav != this.props.location.pathname) {
			return <EventsRedirect push to={this.state.nav} />;
		} else if (this.state.relatedContent) {
			this.count = 0;
			return (
				<Template
					header={header_attributes}
					subHeader={subheader_attributes}
					adType="landingHero"
					adTypeRefresh="landingHero">
					<section className="wrapper latest">
						<PageSubMenu mode="News" selected="Latest" />
						{this.filter()}
						{this.minutely()}
						{this.renderContent()}
					</section>
				</Template>
			);
		} else {
			return (
				<Template header={header_attributes} subHeader={subheader_attributes}>
					<section className="wrapper latest">
						{/* {this.renderHero()} */}
						{/* <ScrollHandler hasData={(false)} filter={this.state.filters.type} /> */}
						{this.filter()}
						{this.minutely()}
						<LoadingIndicator />
					</section>
				</Template>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaLanding);
