/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { fetchAll } from 'appdir/components/general/Util';
import PlayerBox from 'appdir/components/common-ui/MatchInsightsModal/elements/PlayerBox';
import InTheMedia from 'appdir/components/common-ui/MatchInsightsModal/elements/InTheMedia';

import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsights
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		configScoringData: state['Config'].scoringData,
		configOtherData: state['Config'].otherData,
		windowSize: state['Controller'].miWindowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadOverlayModal: (type, data) => dispatch(deps.actions.ModalOverlay.loadOverlayModal(type, data)),
});

class MatchInsights extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			matchInsightsData: null,
			inTheMedia: null,
		};

		this.dataLoaded = false;
		this.openPlayerBio = this.openPlayerBio.bind(this);

		// logger.log('[MatchInsights] constructor - state:%o', this.state);
		// logger.log('[MatchInsights] constructor - props:%o', this.props);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[MatchInsights] getDerivedStateFromProps - nextProps:%o', nextProps);

		let newState = {
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	componentDidMount() {
		// logger.log('[MatchInsights] componentDidMount - state:%o', this.state);

		if (this.state.matchId && !this.dataLoaded) {
			// logger.log('[MatchInsights] componentDidUpdate -loading data files');
			this.dataLoaded = true;
			let { configScoringData } = this.state;
			let { configOtherData } = this.state;

			// let miPathObj = {
			// 	matchInsightsData: configScoringData.matchInsights.replace('<matchId>', this.state.matchId),
			// 	matchInsightsFacts: configOtherData.innovations.matchInsightsFacts.replace('<matchId>', this.state.matchId),
			// };

			let matchInsightsData = configScoringData.matchInsights.replace('<matchId>', this.state.matchId);
			let matchInsightsFacts = configOtherData.innovations?.matchInsightsFacts.replace('<matchId>', this.state.matchId);
			let miPathObj = [matchInsightsData, matchInsightsFacts];

			fetchAll(miPathObj)
				.then(
					axios.spread((mid_result, mif_result) => {
						this.setState({
							matchInsightsData: mid_result.matches[0],
							inTheMedia: [mif_result.p1_insights, mif_result.p2_insights],
						});
					})
				)
				.catch(error => {
					logger.log('[MatchInsights] componentDidUpdate error loading match insights data:%o', error);
					this.dataLoaded = false;
					this.setState({
						matchInsightsData: null,
						inTheMedia: null,
					});
				});
		}
	}

	openMatchInsights(match) {
		// logger.log('[MatchInsights] openMatchInsights - match:%o', match);

		MeasurementUtils.dispatchMeasurementCall('openMatchInsights', {
			match: match,
		});

		this.props.loadOverlayModal('MATCH_INSIGHTS', { matchId: match });
	}

	openPlayerBio(pid) {
		// location.href = `/en_US/players/overview/${pid}.html`;
	}

	render() {
		logger.log('[MatchInsights] render - state:%o', this.state);

		if (
			op.get(this.state, 'configOtherData.playerProfileImagePath', false) &&
			op.get(this.state, 'configOtherData.flagImagePathSmall', false) &&
			op.get(this.state, 'matchInsightsData', false) &&
			op.get(this.state, 'inTheMedia', false) &&
			op.get(this.state, 'windowSize', false)
		) {
			let teams = [this.state.matchInsightsData.team1, this.state.matchInsightsData.team2];

			return (
				<>
					<div className="mi-live-content">
						<div className="mi-header">
							<div className="mi-header-left">
								<div className="mi-header-logo">
									<img
										src="/assets/images/matchInsights/Watson_product_logo_color_RGB.png"
										alt="Match Insights with Watson"></img>
								</div>
								<div className="mi-header-text">
									Match Insights<div className="with-watson-text">with Watson</div>
								</div>
							</div>
						</div>

						<div className="mi-content-container">
							<div className="mi-match-info-players">
								{teams.map((team, i) => {
									return (
										<div className="player-content" key={`player${i + 1}-content`}>
											<PlayerBox
												data={team}
												teamnum={i + 1}
												imgPath={this.state.configOtherData.playerProfileImagePath}
												flagPath={this.state.configOtherData.flagImagePathSmall}
												windowSize={this.state.windowSize}
												onPlayerBioClick={this.openPlayerBio}
											/>
											{this.state.inTheMedia[i].length > 0 ? (
												<div className="mi-media-content">
													<InTheMedia
														data={this.state.inTheMedia[i][0]}
														teamnum={i + 1}
														count={1}
													/>
												</div>
											) : (
												<div className="mi-media-content">
													There is no information for {team.firstNameA} {team.lastNameA} in
													the media.
												</div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className="mi-cta usopen-live-cta">
						<div
							className="mi-cta-button"
							onClick={() => {
								this.openMatchInsights(this.state.matchId);
							}}>
							Match Insights <span className="bolder">With Watson</span>
						</div>
					</div>
				</>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsights);
