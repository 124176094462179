import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';
import FavoriteStar from 'appdir/components/common-ui/FavoriteStar';
import { determinePlayerHighlightClass } from 'appdir/components/pages/SchedulePage/ScheduleUtils';

const mapStateToProps = (state, props) => {
	return { ...props, favorites: state['Controller'].favorites };
};

const mapDispatchToProps = (dispatch, props) => ({
	update: data => dispatch(deps.actions.Controller.update(data)),
});

class PlayerList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		// logger.log('[PlayersIndex] render - state:%o', this.props);

		return (
			<ul>
				{this.props.players
					.filter(player => {
						return (
							player?.last_name?.charAt(0)?.toUpperCase() === this.props.letter &&
							player.gender == this.props.gender
						);
					})
					.map((player, index) => {
						return (
							<li
								key={player.id + index}
								className={`${determinePlayerHighlightClass(
									player.id,
									this.props?.favorites,
									this.props?.searchedPlayer
								)} ${this.props?.favorites?.players?.includes(player.id) ? 'highlightClass' : ''}`}>
								<span className="" data-id={player.id} />
								<div className="favorite-star">
									<FavoriteStar
										id={player.id}
										playerName={player['tv_name']}
										type="players"
										unFavColor="gray"
										favColor="yellow"
										alwaysShow={true}
										style="outline"
									/>
								</div>
								<Link to={`/en_US/players/overview/${player.id}.html`}>
									<div className="flag">
										<img
											src={`//www.usopen.org/images/flags/${player.country}_h.gif`}
											alt={`${player.country_long} flag`}
										/>
									</div>
									<span className="playerName">
										{player.last_name}, {player.first_name}
									</span>
								</Link>
							</li>
						);
					})}
			</ul>
		);
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayerList);
