import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import ReactHtmlParser from 'html-react-parser';
import EventsLink from 'components/general/EventsLink';
import { getAppVideoLink } from 'appdir/components/general/Util';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['BasicDisplay'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadOverlayModal: (type, data) => dispatch(deps.actions.ModalOverlay.loadOverlayModal(type, data)),
});

class BasicDisplay extends Component {
	constructor(props){
		super(props);
		this.state = {
			showModal: false
		};
	}
	
	renderVideoItem(){
		let strId = this.props.data.id;
		strId = strId.substring(7, strId.length-3);

		const videoData = { 
			video: {
				id: strId,
				title: this.props.data.title,
				shareUrl: this.props.data.shareLink,
				poster: this.props.data.photo
			},
			autoplay:'true'
		};
		// logger.log('[BasicDisplay] - renderVideoItem: data: %o', videoData);

		if(this.props.measureData.actionType === 'homePageClick'){
			MeasurementUtils.dispatchMeasurementCall(this.props.measureData.actionType, { args: this.props.measureData });
		}

		if(!window.webview) {
			this.props.loadOverlayModal('VIDEO_MODAL', videoData)
		} else {
			let videoLink = getAppVideoLink(strId);
            videoLink?.onclick();
		}
	}

	render() {
		// logger.log('[BasicDisplay] render -- this: %o', this);
		if (this.props.data) {
			var minute = {};
			minute['share-link'] = this.props.data.link;
			let captionClass = this.props.data.style == 'lead' ? 'leadTitle' : '';

			return (
				<div className={`displayWrapper ${this.props.data.type === 'video' ? 'acv': ''}`}>
					<div className="photoContainer">
						{this.props.data.type === 'video' ? (
							<div className="photoImg image-wrapper" {...minute} onClick={()=> {this.renderVideoItem()}}>
								<img src={this.props.data.photo} title={this.props.data.title} />
								<div className="playBtn">&nbsp;</div> 
							</div>
						) : (
							<div className="photoImg image-wrapper" {...minute}>
								<EventsLink to={this.props.data.link} measureData={this.props.measureData}>
									<img src={this.props.data.photo} title={this.props.data.title} />
								</EventsLink>
							</div>
						)}
					</div>
					{this.state.showModal && this.props?.data ? (
						this.renderVideoItem()
					) : null}
					<div className="captionContainer">
					{this.props.data.type === 'video' ? (
						<div className={`caption video ${captionClass}`} onClick={()=> {this.renderVideoItem()}}>
								{ReactHtmlParser(this.props.data.title)}
						</div>
						):(
						<div className={`caption ${captionClass}`}>
							<EventsLink to={this.props.data.link} measureData={this.props.measureData}>
								{ReactHtmlParser(this.props.data.title)}
							</EventsLink>
						</div>
					)}
						{this.props.data.style == 'lead' || this.props.display == 'story' ? (
							<div className="description">
								{this.props.data.type === 'video' ? (
									<div className="video-description" onClick={()=> {this.renderVideoItem()}}>
										{ReactHtmlParser(this.props.data.description)}
									</div>
								):(
								<EventsLink to={this.props.data.link} measureData={this.props.measureData}>
									{ReactHtmlParser(this.props.data.description)}
								</EventsLink>
								)}
							</div>
						) : null}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicDisplay);
