/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

import LoadingIndicator from 'components/common-ui/LoadingIndicator';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { fetch, fetchAll, getAppLink, getQuerystringValues } from 'appdir/components/general/Util';

/* page elements */
// import Header from 'appdir/components/pages/Slamtracker/elements/Header';
import Tabs from 'appdir/components/pages/Slamtracker/elements/Tabs';
import STHeader from 'appdir/components/pages/Slamtracker/elements/header/STHeader';

/* preview tab */
import LikelihoodToWin from 'appdir/components/pages/Slamtracker/elements/preview/LikelihoodToWin';
import PreviewHeadToHead from 'appdir/components/pages/Slamtracker/elements/preview/PreviewHeadToHead';
import MatchStats from 'appdir/components/pages/Slamtracker/elements/MatchStats';
import ViewMoreMatches from 'appdir/components/pages/Slamtracker/elements/ViewMoreMatches';

/* summary tab */
import STRelatedContent from 'appdir/components/pages/Slamtracker/elements/STRelatedContent';

/* live & replay tab */
import ImmersivCTA from 'appdir/components/pages/Slamtracker/elements/ImmersivCTA';
import Visualisation from 'appdir/components/pages/Slamtracker/elements/STVisualisation';
import Stage from 'appdir/components/pages/Slamtracker/elements/stage';
import MatchHighlights from 'appdir/components/pages/Slamtracker/elements/MatchHighlights';

/* preview & summary tab */
import AICatchUpBox from 'appdir/components/pages/Slamtracker/elements/AICatchUpBox';

import StubBox from 'components/common-ui/StubBox';
import ErrorBoundary from 'components/general/ErrorBoundary';
import {
	isNumeric,
	arrayToObject,
	hasCurrentMatchEnded,
	isNewMatch,
	isDoublesMatch,
	isTiebreakGame,
	getMatchStatus,
	getStatusConfig,
	useMatchExtras,
	useAIContent
} from 'appdir/components/pages/Slamtracker/Utilities';
import { loadInnovationsData } from 'appdir/components/pages/Slamtracker/Data';
import { hasInsights } from 'components/common-ui/PowerRanking/Utils';
import { updatePointHistory } from 'appdir/components/ScoreManager/PointHistory';

import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';

import op from 'object-path';
import cn from 'classnames';

/**
 * -----------------------------------------------------------------------------
 * React Component: SlamtrackerContent
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Slamtracker'],
		scoring: state?.['Controller']?.scoring,
		statMatch: state?.['ScoreManager']?.statMatch ?? {},
		// liveMatchStatus: state?.['ScoreManager']?.status ?? null,
		liveMatches: state?.['ScoreManager']?.liveMatches ?? [],
		liveMatchIds: state?.['ScoreManager']?.liveMatchIds ?? [],
		controllerLoaded: state?.['Controller']?.loaded,
		stWindowSize: state?.['Controller']?.stWindowSize,
		windowSize: state?.['Controller']?.windowSize,
		stubs: state?.['Config']?.stubPages,
		related: state?.['Config']?.relatedContent,
		scoringConfig: state?.['Config']?.scoring ?? {},
		slamtrackerConfig: state?.['Config']?.slamtracker ?? {},
		scoringData: state?.['Config']?.scoringData ?? {},
		eventNames: state?.['Config']?.scoringConfig?.eventNames,
		adConfig: state?.['Config']?.adConfig,
		sharedDataConfig: state?.['Config']?.sharedDataConfig ?? {},
		commonData: state?.['CommonData'],
		configOtherData: state?.['Config']?.otherData,
		// panelToST: state?.['Controller']?.stpanel.panelToST ?? false),
		currentDay:state?.['ActiveData']?.currentDay ?? false,
		Router: state?.['Router'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.Slamtracker.mount()),
	unmount: () => dispatch(deps.actions.Slamtracker.unmount()),
	setScoringStatus: data => dispatch(deps.actions.Controller.setScoringStatus(data)),
	updateMatchHistory: data => dispatch(deps.actions.Slamtracker.updateMatchHistory(data)),
	updateData: (data) => dispatch(deps.actions.Slamtracker.updateData(data)),
	setCompletedStatMatch: matchData => dispatch(deps.actions.ScoreManager.setCompletedStatMatch(matchData)),
	setLiveStatMatch: matchData => dispatch(deps.actions.ScoreManager.setLiveStatMatch(matchData)),
	setStatMatchHistory: historyData => dispatch(deps.actions.ScoreManager.setStatMatchHistory(historyData)),
	clearStatMatch: () => dispatch(deps.actions.ScoreManager.clearStatMatch()),
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
	reset: () => dispatch(deps.actions.Slamtracker.reset()),
	setSTPanelStatus: data => dispatch(deps.actions.Controller.setSTPanelStatus(data)),
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	getMatchInsightsAvailable: () => dispatch(deps.actions.Tournament.getMatchInsightsAvailable()),
	getPlayerDetail: (ids) => dispatch(deps.actions.Tournament.getPlayerDetail({ playerIds: ids }))
});

const UPCOMING_MATCH = 'upcoming';
const LIVE_MATCH = 'live';
const COMPLETED_MATCH = 'complete';

class SlamtrackerContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
		};

		this.t1_loading = false;
		this.t2_loading = false;
		this.stLoaded = false;
		this.liveLoaded = false;
		this.unsubscribed = true;
		this.scoresInit = false;
		this.pageMeasured = false;
		this.currentMatch = null;
		this.innovationMatch;
		this.firstLoad = true;
		this.matchStatus = null;
		this.loadMore = true;
		this.statsLoaded = false;
		this.historyLoaded = false;
		this.innovationsLoading = false;

		this.myScrollArea = React.createRef();

		logger.log('[SlamtrackerContent] constructor - this:%o', this);
	}

	componentDidMount() {
		// logger.log('[SlamtrackerContent] componentDidMount - this:%o', this);
		/**
		 * this variable is used to determine whether slamtracker
		 * is loaded into the panel or a slamtraker page.  A prop is
		 * passed in from the panel
		 */
		this.isPanel = this.props?.location == 'panel' ? true : false;
		if (this.props?.matchStatus) {
			this.setMatchStatus(this.props.matchStatus);
			// logger.log('[SlamtrackerContent] componentDidMount - this.matchStatus:%o', this.matchStatus);
		}

		/**
		 * If slamtracker is loaded into the panel there is no guarantee
		 * that the slamtracker mount action has been called, so do a check
		 * and call it here
		 */
		if (!this.props?.configStatus) {
			// logger.log('[SlamtrackerContent] mounting Slamtracker');
			this.props.mount(this.isPanel);
		}

		// logger.log('[SlamtrackerContent] componentDidMount - this:%o', this);
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		// logger.log('[SlamtrackerContent] componentDidMount this.parsedQs:%o', this.parsedQs);
		this.isTablet = this.parsedQs?.isTablet === 'true' ? true : false;
		this.showImmersive = this.parsedQs.showImmersive === 'true' ? true : false;

		this.setState({
			mounted: true,
		});
	}

	componentWillUnmount() {
		// logger.log('[SlamtrackerContent] componentWillUnmount');

		this.setState({
			mounted: false,
		});
		// i don't want to do this if we are staying on slamtracker page
		this.props.setScoringStatus({ slamtracker: false });
		//this.props.unmount();
		this.unsubscribeMatch();
		this.props?.reset();
		this.props.clearStatMatch();
		this.clearMatchEndTimer();
	}

	navigate = (path) => {
		if (this.props?.windowSize == 'mobile') {
			this.props?.hide();
		}
		this.props?.navigate(path);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[SlamtrackerContent] componentDidUpdate - prevProps:%o', prevProps);
		// logger.log('[SlamtrackerContent] componentDidUpdate - prevState:%o', prevState);
		logger.log('[SlamtrackerContent] componentDidUpdate - this:%o', this);

		/**
		 * scores has not started, slamtracker status is false, controller is loaded
		 * so set status of slamtracker in controller
		 */
		if (
			!this.scoresInit &&
			(!this.props?.scoring?.slamtracker ||
			this.props?.scoring?.slamtracker === false) &&
			this.props?.controllerLoaded
		) {
			// logger.log('[SlamtrackerContent] componentDidUpdate set status of slamtracker in controller - this:%o', this);
			this.scoresInit = true;
			this.props.setScoringStatus({ slamtracker: true });
		}

		/**
			 * RLL 8-19-21 MI Updates
			 *
			 * load the available MI matches to check if the current matchId exists
			 * in the list and the current match has match insights
			 */
		if (
			prevProps?.configStatus !== this.props?.configStatus &&
			this.props?.configStatus == 'loaded' &&
			this.props?.slamtrackerConfig?.usePowerIndex === true
		) {
			// logger.log('[SlamtrackerContent] renderPreviewContent loading available match insights');
			/** get the available match insights from TournamentData */
			this.props.getMatchInsightsAvailable();
		}

		// logger.log('[SlamtrackerContent] componentDidUpdate - this.props?.scoring?.slamtracker:%o, this.scoresInit:%o', this.props?.scoring?.slamtracker, this.scoresInit);

		if(this.props?.scoring?.slamtracker === true && this.scoresInit && ((this.props?.slamtrackerConfig?.usePowerIndex === true && this.props?.commonData?.matchInsightsMatches?.result?.matches) || !this.props?.slamtrackerConfig?.usePowerIndex)) {
			/**
			 * the currently loaded slamtracker match has ended as user sits on page:
			 *
			 * a previous statMatch exists and the previous statMatch
			 * status is not completed and the match id param from the route
			 * matches the match id for our current statMatch, but the current
			 * statMatch status is complete.
			 * unsubscribe from the match in the scoring module, but do not
			 * clear the statMatch or set stLoaded to false because we are
			 * still sitting on the page
			 */
			if (hasCurrentMatchEnded(prevProps, this.props) && this.stLoaded && !this.unsubscribed) {
				// logger.log('[SlamtrackerContent] componentDidUpdate: match has ended, but still on the page');

				this.matchEndTimer = setTimeout(() => {
					this.unsubscribeMatch();
					this.unsubscribed = true;
				}, 30 * 1000); //give some time for any last point history to come in, then unsubscribe	
			}

			/**
			 * this is a new slamtracker match:
			 *
			 * the match id param from the route does not match the match id
			 * for our current statMatch. Reset slamtracker data
			 */
			if (
				this.stLoaded && isNewMatch(prevProps, this.props)
			) {
				// logger.log(
				// 	'[SlamtrackerContent] componentDidUpdate: about to reset match - this.stLoaded:%o',
				// 	this.stLoaded
				// );

				/* call action to clear out stat match data */
				this.resetMatch();
			}

			/**
			 * we do not have a current match id so we are going to search for one in the
			 * live matches based on the match id param from props.
			 */
			if (!this.currentMatch?.statsLevel && this.props?.matchId && this.props?.liveMatches) {
				// logger.log(
				// 	'[SlamtrackerContent] componentDidUpdate: trying to get a current live match - this.props.matchId:%o, this.props.liveMatches:%o',
				// 	this.props?.matchId,
				// 	this.props?.liveMatches
				// );

				let _this = this;
				this.currentMatch = this.props?.liveMatches?.find(match => {
					return match.match_id == _this.props?.matchId;
				});

				/**
				 * we didn't find a live match, so setting current match to the string "undefined".
				 * we are working with a completed match or an upcoming match
				 */
				if (typeof this.currentMatch === 'undefined') {
					// logger.log(
					// 	'[SlamtrackerContent] componentDidUpdate: unable to get a current live match, must be a completed match or upcoming - this.currentMatch:%o', this.currentMatch
					// );
					this.currentMatch = null;
				}
			}

			/**
			 * we have a matchId, slamtracker is not loaded for this match,
			 * ScoreManager.liveMatches are available and we have a match status. Load a match.
			 */
			if (
				this.props?.matchId &&
				this.props?.liveMatches
			) {
				logger.log(
					'[SlamtrackerContent] componentDidUpdate about to load a match: this.currentMatch:%o',
					this.currentMatch
				);

				if(!this.currentMatch ||
					(this.currentMatch && this.currentMatch?.status === 'Completed') &&
					!this.state?.dataError // dataError means there was an issue loading the cmatch data
				) {
					/** 
					 * we were unable to retrieve a match from props.liveMatches
					 * so we need to load either the completed match or the match preview
					 **/
						
					this.getNotLiveMatch();
				} else if (
					this.currentMatch && 
					(
						!this.stLoaded || 
						(this.stLoaded && !this.liveLoaded)
					) &&
					this.currentMatch.statsLevel
				) { 
					/** 
					 * we were able to retrieve a match from props.liveMatches
					 * so we need to load the live match 
					 **/
					if (this.currentMatch?.status?.toLowerCase() !== 'completed') {
						this.setMatchStatus('live');
					} else {
						this.setMatchStatus('complete');
					}

					if (this.currentMatch?.statsLevel) {
						this.getLiveMatch();
					}
				}	
			}

			// NOTE:  i don't think i should be checking the length but rather if the list of match ids have changed
			if (
				prevProps?.statMatch &&
				this.props?.statMatch?.match_id == this.props?.matchId &&
				this.props?.statMatch?.pointHistory?.length !== prevProps?.statMatch?.pointHistory?.length &&
				this.matchStatus != UPCOMING_MATCH
			) {
				//   logger.log(
				//     "[SlamtrackerContent] componentDidUpdate pointHistory length changed - props:%o",
				//     this.props
				//   );
				this.updateMatchHistory();
			}

			/**
			 * awt 6/2/2021
			 *
			 * if statMatch.match_id changes, or match insights matches change or we are on the panel or coming from
			 * the panel, then call setPageViews passing nothing (unless selectedTab prop is passed to component)
			 * so that default tabs can be chosen and call function to load innovations data
			 */

			if (
				this.props?.statMatch?.match_id !== prevProps?.statMatch?.match_id &&
					this.props?.statMatch?.match_id
			) {
				// logger.log(
				// 	'[SlamtrackerContent] componentDidUpdate webview metrics: statMatch.match_id changed. call setPageViews as default - this:%o',
				// 	this
				// );

				if(!this.matchStatus) {
					let matchStateText = getStatusConfig(this.props?.statMatch.statusCode)['text'];
					this.matchStatus = this.setMatchStatus(matchStateText.toLowerCase());
				}

				this.setPageViews();

				if(this.props?.slamtrackerConfig?.usePowerIndex === true) {
					this.getInnovationsData();
				}
			}

			/**
			 * we have a statMatch object, the statMatch match id is the same as the match id
			 * from props, the statMatch has a player, data is not currently loading so
			 * load player overview data for the match
			 */
			if (
				this.props?.statMatch &&
				this.props?.statMatch?.team1?.idA &&
				this.props?.statMatch?.match_id == this.props?.matchId &&
				!this.t1_loading &&
				!this.t2_loading
			) {
				// logger.log('[SlamtrackerContent] componentDidUpdate calling getMatchData this:%o', this);
				this.getMatchData();
			}
		}
	}

	setMatchStatus(status) {
		switch (status) {
			case 'live':
				this.matchStatus = LIVE_MATCH;
				break;
			case 'complete':
				this.matchStatus = COMPLETED_MATCH;
				break;
			case 'prematch':
				this.matchStatus = UPCOMING_MATCH;
				break;
			case 'upcoming':
				this.matchStatus = UPCOMING_MATCH;
				break;
			case 'summary':
				this.matchStatus = COMPLETED_MATCH;
				break;
				
			default:
				break;
		}
	}

	/** Load a live match */
	getLiveMatch() {
		// logger.log(
		// 	'[SlamtrackerContent] getLiveMatch - matchId:%o, currentMatch:%o, statMatch:%o',
		// 	this.props?.matchId,
		// 	this?.currentMatch,
		// 	this.props?.statMatch
		// );
		let upcoming = false;

		if(this.stLoaded) {
			upcoming = true;
			this.liveLoaded = true;
			/** 
			 * we do need to unsubscribe from the match data 
			 * tennis-scoring-data is set up not to re-subscribe to the data
			 * for any of the currentStatMatches
			 **/
			this.unsubscribeMatch();
		}

		this.stLoaded = true;
		this.unsubscribed = false;

		/* create event to call stats and point history */
		let addEvent = new CustomEvent('ixEventsScoring', {
			detail: {
				type: 'addMatchData',
				statsLevel: 'H', //statLevelToUse,
				// statsLevel: this.currentMatch.statsLevel,
				matchId: this.props?.matchId,
			},
		});

		window.dispatchEvent(addEvent);

		// logger.log("[SlamtrackerContent] componentDidUpdate set current overview data to null");
		if(!upcoming) {
			this.t1_loading = false;
			this.t2_loading = false;
		}

		this.props?.setLiveStatMatch(this.currentMatch);
	}

	/** Load an upcoming or completed match */
	getNotLiveMatch = () => {
		// logger.log(
		// 	'[SlamtrackerContent] getNotLiveMatch - matchId:%o, currentMatch:%o, statMatch:%o',
		// 	this.props?.matchId,
		// 	this.currentMatch,
		// 	this.props?.statMatch
		// );
		this.unsubscribed = true;

		let path = this.props?.scoringData?.matchScore?.path.replace('<matchId>', this.props?.matchId);

		if (!this.statsLoaded && path) {
			this.statsLoaded = true;

			/* fetch the completed match data */
			fetch(path)
				.then(result => {
					// logger.log('[SlamtrackerContent] completed match data for completed or upcoming match:%o', result);

					// this.currentMatch = result?.matches[0];
					let currentMatchStatus = getStatusConfig(result?.matches[0].statusCode);

					/** if upcoming match */
					if(currentMatchStatus.status == 'pre') {
						this.setMatchStatus('prematch');
					} 
					/** if completed match */
					else if(currentMatchStatus.status == 'post') {
						this.setMatchStatus('complete');
					}

					logger.log('[SlamtrackerContent] getNotLiveMatch match data for completed or upcoming match:%o', result);

					this.props?.setCompletedStatMatch(result?.matches[0]);
				})
				.catch(error => {
					logger.log('[SlamtrackerContent] getNotLiveMatch error:%o, status:%o', error, error.status);
					// this.statsLoaded = false;

					this.setState({
						hasError: true,
						dataError: error.status == '404' ? true : false,
						errorType: error,
					});
				});
		}

		let history_path = this.props?.scoringData?.matchHistory?.path.replace('<matchId>', this.props.matchId);

		/* fetch the completed point history data but not if upcoming */
		if (
			!this.historyLoaded &&
			history_path &&
			this.props?.statMatch?.match_id == this.props?.matchId &&
			this.props?.statMatch?.statusCode &&
			this.props?.statMatch?.statusCode != 'B' && //future
			this.props?.statMatch?.statusCode != 'F' //walkover
		) {
			this.historyLoaded = true;

			fetch(history_path)
				.then(historyResult => {
					logger.log('[SlamtrackerContent] getNotLiveMatch completed match historyResult:%o', historyResult);
					let updatedResult = updatePointHistory(
						this.props?.statMatch?.pointHistory,
						historyResult
					);
					this.props.setStatMatchHistory(updatedResult);
				})
				.catch(error => {
					this.historyLoaded = false;
					this.setState({
						hasError: true,
						errorType: error,
					});
				});
		}

		if (!this.stLoaded) {
			this.stLoaded = true;
			this.t1_loading = false;
			this.t2_loading = false;
		}
	}

	/**
	 * actions to take when switching matches in slamtracker:
	 * Unsubscribe from the match in the scoring module, clear
	 * the statMatch data, set stLoaded to false, and
	 * set pageMeasured to false - new page, new measurement call
	 */
	resetMatch() {
		logger.log('[SlamtrackerContent] resetMatch');
		this.props.reset(); //Slamtracker

		this.unsubscribeMatch();

		/* call action to clear out stat match data */
		this.props.clearStatMatch(); //ScoreManager

		this.stLoaded = false;
		this.statsLoaded = false;
		this.historyLoaded = false;
		this.currentMatch = null;
		this.clearMatchEndTimer();
		this.t1_loading = false;
		this.t2_loading = false;

		/* set to false so new match will get a page view measurement call */
		this.pageMeasured = false;

		// clear the innovationsMatch
		this.setState({
			innovationsData: null,
			powerrankError: false,
			stRelatedData: null,
			catchMeUpRecap: null,
			catchMeUpPreview: null,
			hasError: false,
			dataError: false
		});
	}

	/**
	 * unsubscribe match from the scoring module
	 */
	unsubscribeMatch() {
		logger.log('[SlamtrackerContent] unsubscribeMatch');
		let removeEvent = new CustomEvent('ixEventsScoring', {
			detail: {
				type: 'removeMatchData',
				statsLevel: this.props.statMatch.statsLevel,
				matchId: this.props.statMatch.match_id,
			},
		});

		window.dispatchEvent(removeEvent);
	}

	clearMatchEndTimer = () => {
		if(this.matchEndTimer){
			clearTimeout(this.matchEndTimer);
		}
	}

	/**
	 * RLL 8-19-21 MI Updates:
	 *
	 * load innovations data for the match
	 */
	getInnovationsData() {
		// logger.log('[SlamtrackerContent] getInnovationsData this:%o', this);

		if(this.props?.commonData?.matchInsightsMatches?.result?.matches) {
			this.innovationsLoading = true;
		}

		// fetch nlg match insights data file
		let miList = this.props?.commonData?.matchInsightsMatches?.result?.matches;
		let match = this.props?.statMatch;
		let { configOtherData } = this.props;

		/* check if match id and innovationMatch are the same and if the match has insights */
		if (hasInsights(miList, match.match_id)) {

			loadInnovationsData(match.match_id, configOtherData)
				.then(data => {
					// logger.log('[SlamtrackerContent] getInnovationsData innovations data:%o', data);
					this.innovationsLoading = false;

					this.setState({
						innovationsData: {
							...data.innovationsData,
						}
					});
				})
				.catch(error => {
					logger.error('[SlamtrackerContent] getInnovationsData innovations data error:%o', error);
					this.innovationsLoading = false;

					this.setState({
						innovationsData: error.innovationsData,
					});
				});
		}
	}

	/**
	 * this is the callback function from the STOverview panel
	 */
	onPanelLinkClick = path => {
		// logger.log('[SlamtrackerContent] - onPanelLinkClick - path:%o', path);

		if (path) {
			if (window.webview) {
				let link = getAppLink(path, '');
				location.href = link.url;
			} else {
				this.props.navigate({ path: path, location: 'stcontent' });
			}
		}
	};

	calculateHeight(which) {
		let knownHeights;
		let breakingNewsHeight = 0;
		let containerHeight = 0;
		let stContentHeight = 0;
		let featuredHeight = 0;
		let panelHeight = 0;

		if (document.querySelector('.breakingNews')) {
			// breakingNewsHeight = document.querySelector('.breakingNews').getBoundingClientRect().height;
			// logger.log('[SlamtrackerContent] calculateHeight breakingNewsHeight:%o', breakingNewsHeight);
		}
		if (document.querySelector('.stpanel-container')) {
			containerHeight = document.querySelector('.stpanel-container').getBoundingClientRect().height;
			// logger.log('[SlamtrackerContent] calculateHeight containerHeight:%o', containerHeight);
		}
		if (document.querySelector('.slamtracker-content')) {
			stContentHeight = document.querySelector('.slamtracker-content').getBoundingClientRect().height;
			// logger.log('[SlamtrackerContent] calculateHeight stContentHeight:%o', stContentHeight);
		}
		if (document.querySelector('.featured-match')) {
			featuredHeight = document.querySelector('.featured-match').getBoundingClientRect().height;
			// logger.log('[SlamtrackerContent] calculateHeight featuredHeight:%o', featuredHeight);
		}

		if (which == 'panel') {
			/**
			 * close bar - 29px
			 * title bar (width borders) - 59px
			 * ad bar - 70px
			 * top and bottom borders - 2px
			 * featured match - ?
			 * tabs (width border) - 38px
			 * content panel will be height of total
			 * slamtracker panel minus all of the listed items
			 */

			knownHeights = 29 + 59 + 70 + 38 + 2;

			if (containerHeight > 0) {
				panelHeight = containerHeight - (knownHeights + breakingNewsHeight + featuredHeight);
				// logger.log('[SlamtrackerContent] calculateHeight panelHeight:%o', panelHeight);
			}
		} else {
			/**
			 * tabs - 37px
			 * padding for content-area - 8px
			 * padding for slamtracker content-main - 20px;
			 * content panel will be height of total
			 * slamtracker-content div minus all of the listed items
			 */

			knownHeights = 47 + 8 + 20;

			if (stContentHeight > 0) {
				panelHeight = stContentHeight - (featuredHeight + knownHeights);
			}
		}

		return panelHeight;
	}

	/***
	 * function updateMatchHistory
	 *
	 * This function gets called everytime a new point comes in.  What it does is loops through the point history
	 * and separates it by set and game adding in point objects for point server changes, tiebreaks and other status
	 * types that need to be displayed (players arrive on court, players warming up etc.)
	 *
	 * TODO:  To improve efficiency, might want to rewrite this to only need to handle the next point and not process all
	 * 		points every time a point comes in.  I don't know .... just been kinda bothering me.  No time to do that now!
	 */
	updateMatchHistory() {
		let { pointHistory } = this.props.statMatch;
		// logger.log('[SlamtrackerContent] updateMatchHistory - pointHistory:%o', pointHistory);

		let pointsBySet = {};

		if (pointHistory && pointHistory.length > 0) {
			let pointsById = arrayToObject(pointHistory, 'PointNumber');

			for (let i = 1; i <= 5; i++) {
				let setNo = i.toString();

				if (!pointsBySet[setNo]) {
					pointsBySet[setNo] = {};
				}

				let setHistory = {};

				pointHistory
					.filter(point => point.SetNo == setNo)
					// .filter(pointNumber => pointHistory.byId[pointNumber].SetNo == setNo)
					.slice(0)
					.reverse()
					.map((point, i) => {
						//logger.log('[SlamtrackerContent] updateMatchHistory pointNumber:%o, setHistory:%o, point, setHistory);
						// let pointObj = pointHistory.byId[point];
						let pointObj = point;

						// if there's no game obj, add an empty game obj to set

						let isTbGame = isTiebreakGame(
							this.props.statMatch,
							pointObj,
							this.props.eventNames,
							this.props.scoringConfig.messagePath,
							this.props.slamtrackerConfig.superTiebreakEvents
						);
						let gameKey = isTbGame ? 'tiebreak' : pointObj.GameNo;

						if (!setHistory[gameKey]) {
							setHistory[gameKey] = {};
						}

						// add the point obj to game
						setHistory[gameKey][point.PointNumber] = pointObj;

						// do the tiebreak next server stuff
						// set the initial point of the game to 0Z.  it will be used for next server
						if (isNumeric(point.PointNumber) && !setHistory[gameKey]['0Z']) {
							//logger.log('[SlamtrackerContent] updateMatchHistory add 0Z setHistory point:%o, gameKey:%o', point, gameKey);
							//if (!setHistory[pointObj.GameNo]["0Z"]){
							setHistory[gameKey]['0Z'] = pointObj;
							//}
						} else if (
							isNumeric(point.PointNumber) &&
							setHistory[gameKey]['0Z'] &&
							parseInt(setHistory[gameKey]['0Z'].PointNumber, 10) > parseInt(pointObj.PointNumber, 10)
						) {
							//logger.log('[SlamtrackerContent] updateMatchHistory add 0Z setHistory 2 point:%o, gameKey:%o, pointObj.PointNumber:%o', point, gameKey, pointObj.PointNumber);
							setHistory[gameKey]['0Z'] = pointObj;
							//logger.log('[SlamtrackerContent] updateMatchHistory add 0Z setHistory 2 setHistory:%o', setHistory);
						}

						//logger.log('[SlamtrackerContent] updateMatchHistory isTbGame:%o, pointObj.SetNo:%o, pointObj.MatchWinner:%o, pointObj.SetWinner:%o, pointObj.GameWinner:%o', isTbGame, pointObj.SetNo, pointObj.MatchWinner, pointObj.SetWinner, pointObj.GameWinner);

						if (
							isTbGame &&
							pointObj.MatchWinner == '0' &&
							pointObj.SetWinner == '0' &&
							pointObj.GameWinner == '0'
						) {
							let tempServer = pointObj.PointServer == '1' || pointObj.PointServer == '3' ? '1' : '2';
							let tempServeIndicator =
								pointObj.ServeIndicator == '1' || pointObj.ServeIndicator == '3' ? '1' : '2';
							//logger.log('[SlamtrackerContent] updateMatchHistory server -- serveIndicator:%o, tempServer:%o', tempServeIndicator, tempServer);

							if (tempServer !== tempServeIndicator) {
								// logger.log('[SlamtrackerContent] updateMatchHistory server change - gameKey:%o, pointKey:%o',gameKey, `${point.PointNumber}Z`);
								// logger.log('[SlamtrackerContent] updateMatchHistory server change - isAdded: %o',setHistory[gameKey][`${point.PointNumber}Z`]);

								if (!setHistory[gameKey][`${point.PointNumber}Z`]) {
									//logger.log('[SlamtrackerContent] updateMatchHistory server change - adding!');
									setHistory[gameKey][`${point.PointNumber}Z`] = pointObj;
									//logger.log('[SlamtrackerContent] updateMatchHistory server change - setHistory:%o',setHistory);
								}
							}
						}
					});

				// logger.log('[SlamtrackerContent] updateMatchHistory server change - setHistory2:%o', setHistory);
				// create set history
				if (Object.keys(setHistory).length > 0) {
					// add set obj to match
					pointsBySet[setNo] = setHistory;
				} else {
					// delete sets that are not needed from matchHistory
					delete pointsBySet[setNo];
				}
			}

			// set state with new match history
			//   logger.log(
			//     "[SlamtrackerContent] updateMatchHistory pointsBySet:%o",
			//     pointsBySet
			//   );

			let currentPoint = pointHistory[pointHistory.length - 1];
			let previousPoint = pointHistory[pointHistory.length - 2];

			// logger.log(
			// 	'[SlamtrackerContent] updateMatchHistory - currentPointEpochTimeEnd:%o, previousPointEpochTimeEnd:%o',
			// 	parseFloat(currentPoint['EpochTimeEnd']),
			// 	parseFloat(previousPoint['EpochTimeEnd'])
			// );

			let currentPointNum = isString(currentPoint['EpochTimeEnd'])
				? parseFloat(currentPoint['EpochTimeEnd'])
				: currentPoint['EpochTimeEnd'];
			let currentPointTime = moment.unix(currentPointNum);
			// .tz('America/New_York')
			// .format('h:mm:ss');

			let previousPointNum = isString(previousPoint['EpochTimeEnd'])
				? parseFloat(previousPoint['EpochTimeEnd'])
				: previousPoint['EpochTimeEnd'];
			let previousPointTime = moment.unix(previousPointNum);

			// logger.log(
			// 	'[SlamtrackerContent] updateMatchHistory - differenceTime:%o',
			// 	differenceTime
			// );

			// logger.log(
			// 	'[SlamtrackerContent] updateMatchHistory - currentPointTime:%o, previousPointTime:%o',
			// 	currentPointTime,
			// 	previousPointTime
			// );

			let timeSinceLastPoint = currentPointTime.diff(previousPointTime); //.format('h:mm:ss');
			
			// logger.log('[SlamtrackerContent] updateMatchHistory - timeSinceLastPoint:%o', timeSinceLastPoint);

			this.props.updateMatchHistory({
				pointsBySet,
				pointsById,
			});
		}
	}

	setPageViews = (tab) => {
		// logger.log(
		// 	'[SlamtrackerContent] setPageViews - tab:%o, this:%o',
		// 	tab,
		// 	this
		// );

		/** set tab based on data passed to function **/ 
		if (tab) {
			this.setState(prevState => {
				return {
					...prevState,
					tab: tab,
					prevTab: prevState.tab
				}
			}, () => {
				if(window.webview) {
					window.scrollTo(0,0);
				}
			});
		} 
		/** set tab based on current match status **/ 
		else {
			let { statMatch } = this.props;

			let matchStatusConfig = getStatusConfig(statMatch.statusCode);

			// logger.log('[SlamtrackerContent] setPageViews default - matchStatusConfig:%o', matchStatusConfig['data']);

			// this.measureIndex('Default Tab', '', [
			// 	{ slamtrackerTab: matchStatusConfig['data'] },
			// 	{ st_cta_label: `${values.slamtrackerButtonText}${matchStatusConfig['text']}` },
			// 	],
			//  "state");

			 if (!window.webview) {
				if (!this.pageMeasured) {
					this.pageMeasured = true;

					MeasurementUtils.dispatchMeasurementCall('open', {
						pageTitle: 'Slamtracker Panel',
						tab: matchStatusConfig['text'],
						status: this.matchStatus,
						match: this.props.matchId,
					});
				}
			} else {
				let btnText = '';

				// logger.log('[SlamtrackerContent] setPageViews webview metrics: this.matchStatus:%o', this.matchStatus);

				switch (this.matchStatus) {
					case LIVE_MATCH:
						btnText = 'Follow Live';
						break;
					case COMPLETED_MATCH:
						btnText = 'Match Recap';
						break;
					case UPCOMING_MATCH:
						btnText = 'Match Preview';
						break;
					default:
						break;
				}

				let contextData = [
					{ slamtracker_button: btnText },
					{ slamtracker_tab: matchStatusConfig['text'] },
					{ slamtracker_matchid: this.props?.matchId },
				];
				let metricsVal = ['Metrics', 'Slamtracker'];

				logger.log(
					'[SlamtrackerContent] componentDidUpdate webview metrics: metricsVal:%o, contextData:%o',
					metricsVal,
					contextData
				);
				measureInApp(metricsVal, contextData);
			}

			this.setState({
				tab: matchStatusConfig['data']
			});
		}
	};

	isLiveScoresPage = () => {
		return this.props.Router.pathname == '/en_US/scores/index.html' ? true : false;
	}

	renderPreviewContent() {
		// logger.log('[SlamtrackerContent] renderPreviewContent - this:%o', this);
		let doubles = isDoublesMatch(this.props?.statMatch);
		let extras = useMatchExtras(this.props?.statMatch);
		let hasAIContent = useAIContent(this.props?.statMatch);
		let miList = this.props?.commonData?.matchInsightsMatches?.result?.matches;
		let showPowerIndex = hasInsights(miList, this.props?.statMatch?.match_id) && this.props?.slamtrackerConfig?.usePowerIndex === true && this.state?.innovationsData ? true : false;

		// logger.log('[SlamtrackerContent] renderPreviewContent - showPowerIndex:%o, this:%o', showPowerIndex, this);

		return (
			<div className="preview-content panel-content">
				<ErrorBoundary message="Error loading Match Preview">
					{(hasAIContent === true && this.props?.slamtrackerConfig?.useAIBox === true) && this.renderAIBox('preview')}
					{/* {this.renderAIBox('preview')} */}
				</ErrorBoundary>
				{showPowerIndex === true && this.renderLikelihoodToWin()}
				<PreviewHeadToHead doubles={doubles} playersHaveStats={extras} />
				{!this.isLiveScoresPage() && <ViewMoreMatches matchId={this.props?.statMatch?.match_id} currentTab='Preview' measureIndex={this.measureIndex} windowSize={this.props?.windowSize} />}
			</div>
		)
	}

	renderLiveContent(status) {
		let displayImmersiv = this.props?.slamtrackerConfig?.useImmersivCTA && 
			useMatchExtras(this.props?.statMatch) && 
			window.webview && 
			this.props?.statMatch?.statusCode === 'A' && 
			!this.isTablet 
		
		return (
			<div className="live-content panel-content">
				{displayImmersiv === true && (
					<ErrorBoundary message="Error loading Immersiv button">
						<ImmersivCTA matchId={this.props?.statMatch?.match_id} currentTab='Live' measureIndex={this.measureIndex} />
					</ErrorBoundary>
				)}
				{this.props?.slamtrackerConfig?.showVisualisation && status && status == 'live' && <ErrorBoundary message="Error loading Match Visualisation"><Visualisation /></ErrorBoundary>}				
				{this.props?.slamtrackerConfig?.usePointByPoint === true && this.props?.pointsBySet &&
					<ErrorBoundary message="Error loading Point by Point Data">
						<Stage 
							// statMatch={this.props?.statMatch}
							measureIndex={this.measureIndex}
							currentTab={this.state?.tab}
							// pointsBySet = {this.props?.pointsBySet}
							// pointsById = {this.props?.pointsById}
						/>
					</ErrorBoundary>
				}
				{this.props?.slamtrackerConfig?.showMatchHighlights && 
					<ErrorBoundary message="Error loading Match Highlights">
						<MatchHighlights tab={this.state?.tab} />
					</ErrorBoundary>
				}
				{status && status == 'live' && <ErrorBoundary message="Error loading Match Stats"><MatchStats /></ErrorBoundary>}
				{!this.isLiveScoresPage() && <ViewMoreMatches matchId={this.props?.statMatch?.match_id} currentTab='Live' measureIndex={this.measureIndex} windowSize={this.props?.windowSize} />}
			</div>
		)
	}

	renderRecapContent() {
		let hasAIContent = useAIContent(this.props?.statMatch);
		/** status is completed, retired or default */
		let displayImmersiv = this.props?.slamtrackerConfig?.useImmersivCTA && 
			useMatchExtras(this.props?.statMatch) && 
			window.webview && 
			(this.props?.statMatch?.statusCode === 'D' || 
				this.props?.statMatch?.statusCode === 'E' || 
				this.props?.statMatch?.statusCode === 'G'
			) && 
			!this.isTablet

		return (
			<div className="recap-content panel-content">
				{displayImmersiv === true && (
					<ErrorBoundary message="Error loading Immersiv button">
						<ImmersivCTA matchId={this.props?.statMatch?.match_id} currentTab='Replay' measureIndex={this.measureIndex} />
					</ErrorBoundary>
				)}
				{/* <ErrorBoundary message="Error loading Match Recap">
					{(hasAIContent && this.props?.slamtrackerConfig?.useAIBox === true) && this.renderAIBox('recap')}
				</ErrorBoundary> */}
				
				{this.props?.slamtrackerConfig?.showVisualisation && <ErrorBoundary message="Error loading Match Visualisation"><Visualisation /></ErrorBoundary>}
				{/* this.props?.slamtrackerConfig?.usePointByPoint === true &&
					<ErrorBoundary message="Error loading Point by Point Data">
						<Stage statMatch={this.props?.statMatch} 
							measureIndex={this.measureIndex}
							currentTab={this.state?.tab}
						/>
					</ErrorBoundary>
				} */}
				{this.props?.slamtrackerConfig?.showMatchHighlights && 
					<ErrorBoundary message="Error loading Match Highlights">
						<MatchHighlights tab={this.state?.tab} />
					</ErrorBoundary>
				}
        		{this.props?.pointsBySet &&
					<ErrorBoundary message="Error loading Point by Point Data">
						<Stage 
							// statMatch={this.props?.statMatch}
							measureIndex={this.measureIndex}
							currentTab={this.state?.tab}
							// pointsBySet = {this.props?.pointsBySet}
							// pointsById = {this.props?.pointsById}
						/>
					</ErrorBoundary>
				}
				{!this.isLiveScoresPage() && <ViewMoreMatches matchId={this.props?.statMatch?.match_id} currentTab='Replay' measureIndex={this.measureIndex} windowSize={this.props?.windowSize} />}
			</div>
		)
	}

	renderSummaryContent(status) {
		let hasAIContent = useAIContent(this.props?.statMatch);
		
		return (
			<div className="summary-content panel-content">
				<ErrorBoundary message="Error loading Match Recap">
					{(hasAIContent && this.props?.slamtrackerConfig?.useAIBox === true) && this.renderAIBox('summary') }
				</ErrorBoundary>
				{this.props.slamtrackerConfig.showRelatedContent ? (
					<ErrorBoundary message="Error loading Related Content">
						<STRelatedContent
							filterData={this?.state?.stRelatedData}
							relatedData={this.props?.related}
							statMatch={this.props?.statMatch}
							windowSize={this.props?.windowSize}
							location="slamtracker"
							isPanel={this.isPanel}
						/>
					</ErrorBoundary>
				) : null}
				{status && status == 'post' ? (
					<ErrorBoundary message="Error loading Match Stats">
						<MatchStats />
					</ErrorBoundary>
					) : null
				}
				{!this.isLiveScoresPage() && <ViewMoreMatches matchId={this.props?.statMatch?.match_id} currentTab='Summary' measureIndex={this.measureIndex} windowSize={this.props?.windowSize} />}
			</div>
		);
	}

	measureIndex = (measureAction, measure_args, contextData) => {
		if (window.location.href.indexOf('suite') == -1) {
			let measureArray;

			if (!window.webview) {
				measureArray = ['Slamtracker', this.matchStatus].concat(measure_args);

				// logger.log('[SlamtrackerContent] measureIndex - measureArray:%o', measureArray);

				MeasurementUtils.dispatchMeasurementCall(measureAction, {
					data: measureArray,
				});
			} else {
				/** New in 2022, metrics requires context data for apps
				 *  The selected tab is set as slamtracker_tab:<tab Selected>
				 *  context data is set to [{slamtracker_tab:<tab Selected>}]
				 *
				 *  commenting out the previous years measureArray call below
				 *  as it included .concat(measure_args)
				 */
				// measureArray = ['Metrics', 'Slamtracker', matchStatus].concat(measureAction).concat(measure_args);
				if(measureAction == 'Tab Click') {
					measureArray = ['Metrics', 'Slamtracker'].concat(measureAction);
				} else {
					measureArray = ['Metrics', 'Slamtracker', this.matchStatus].concat(measureAction);
				}

				// logger.log(
				// 	'[SlamtrackerContent] measureIndex webview metrics: measureArray:%o, contextData:%o',
				// 	measureArray,
				// 	contextData
				// );

				measureInApp(measureArray, contextData);
			}
		}
	}

	renderAIBox(which) {
		// logger.log('[SlamtrackerContent] renderAIBox which:%o, this:%o', which, this);
		let { statMatch } = this.props;
		let status = which == 'preview' ? 'pre' : 'post';
		let aiCatchUpPreviewPath, aiCatchUpRecapPath, data;

		// logger.log('[SlamtrackerContent] renderAIBox status:%o', status);
		if(this.props?.slamtrackerConfig?.useAIBox === true) {
			if(this.props?.slamtrackerConfig?.useAITestData === true) {
				// if(status == 'pre') {
					data = [  
						{"sentence":"Preseason rank: Zverev (63.18%) > Sinner (61.82%)","status":"approved"},
						{"sentence":"Sinner won 3 sets while Zverev won 2 sets in the US Open Men Singles 2023.","status":"automated"},
						{"sentence":"Zverev had more grass match wins than Sinner.","status":"automated"}
					]
				// } 
			} else {
				if(which == "preview") {
					aiCatchUpPreviewPath = this?.props?.configOtherData?.innovations?.aiCatchUpPath.replace('<status>', status).replace('<matchId>', statMatch?.match_id);
				} else {
					aiCatchUpRecapPath = this?.props?.configOtherData?.innovations?.aiCatchUpPath.replace('<status>', status).replace('<matchId>', statMatch?.match_id);
				}
			}

			if(aiCatchUpPreviewPath && !this.state?.catchMeUpPreview && this.state.catchMeUpPreview !== false) {
				fetch(aiCatchUpPreviewPath)
					.then(result => {
						this.setState({catchMeUpPreview: result?.content.length > 0 ? result?.content : false});
					})
					.catch(error => {
						logger.error('[SlamtrackerContent] error loading AI Preview Data:%o', error);
						this.setState({catchMeUpPreview: false});
					})
			}
	
			if(aiCatchUpRecapPath && !this.state?.catchMeUpRecap && this.state.catchMeUpRecap !== false) {
				fetch(aiCatchUpRecapPath)
					.then(result => {
						this.setState({catchMeUpRecap: result?.content.length > 0 ? result?.content : false});
					})
					.catch(error => {
						logger.error('[SlamtrackerContent] error loading AI Recap Data:%o', error);
						this.setState({catchMeUpRecap: false});
					})
			}
		} 

		if(this.props?.slamtrackerConfig?.showRelatedContent) {
			const stResultRelatedPath = this.props?.related?.stResultRelated?.replace('<matchId>', statMatch?.match_id);
			// const stResultRelatedPath = this.props?.related?.stResultRelated?.replace('<matchId>', '1201'); //for testing 
			// logger.log('[SlamtrackerContent] stResultRelatedPath:%o', stResultRelatedPath);
			if(stResultRelatedPath && !this.state.stRelatedData && this.state.stRelatedData !== false){
				fetch(stResultRelatedPath)
					.then(result => {
						this.setState({stRelatedData: result?.content || false});
					})
					.catch(error => {
						logger.error('[SlamtrackerContent] error loading related content:%o', error);
					})
			}
		}

		if(!aiCatchUpPreviewPath && !aiCatchUpRecapPath) {
			return <AICatchUpBox data={data} stRelatedData={this.state?.stRelatedData} type={which} />
		} else {
			if(aiCatchUpPreviewPath) {
				return <AICatchUpBox data={this.state?.catchMeUpPreview} stRelatedData={this.state?.stRelatedData} type={which} />
			} else if(aiCatchUpRecapPath) {
				return <AICatchUpBox data={this.state?.catchMeUpRecap} stRelatedData={this.state?.stRelatedData} type={which} />
			}
		}
	}

	/***
	 * function getMatchData
	 *
	 * This function will fetch the player overview data, 
	 * player stats data, and head to head data. The data 
	 * retreived will be saved in Slamtracker redux state
	 */
	getMatchData = () => {
		let { statMatch } = this.props;
		// logger.log('[SlamtrackerContent] getMatchData - this:%o', this);
		if (!this.t1_loading && !this.t2_loading) {
			this.t1_loading = true;
			this.t2_loading = true;

			let idObj = [
				statMatch.team1.idA,
				statMatch.team2.idA,
			];

			if (statMatch.team1.idB != null && statMatch.team2.idB != null) {
				idObj.push(statMatch.team1.idB),
				idObj.push(statMatch.team2.idB)
			}

			this.props?.getPlayerDetail(idObj);

			if(useMatchExtras(statMatch) === true){
				
				if(this.props?.slamtrackerConfig?.useHeadtoHeadStats === true) {
					/**
					 * HeadtoHeadTournamentStats
					 * 
					 * Head to head tournament stats are 
					 * only available for round 2 and later
					 */
					if(statMatch?.roundCode !== "1") {
						
						let tournStatsObj;
						
						tournStatsObj = [
							this.props?.scoringData?.playerTournStats.replace('<id>', statMatch.team1.idA),
							this.props?.scoringData?.playerTournStats.replace('<id>', statMatch.team2.idA),
						]

						fetchAll(tournStatsObj)
							.then(data => {
								let tourndata= {
									tourn_stats: {
										team1: {name: statMatch.team1.displayNameA, player_stats: {...data[0]}},
										team2: {name: statMatch.team2.displayNameA, player_stats: {...data[1]}}
									}
								}
								this.props?.updateData(tourndata);
							})
							.catch(error => {
								logger.error('[SlamtrackerContent] error loading tourn stats data:%o', error);
							})
					}
				

					/** 
					 * PastMatchUps 
					 **/
					let head2headPath;

					/** use file we know exists if using test data */
					if(this.props?.slamtrackerConfig?.useH2HTestData === true) {
						head2headPath = "https://ashe.usopen.org/en_US/scores/feeds/2024/stats/head2head/1701.json";
					} else {
						head2headPath = this?.props?.scoringData?.headToHead.replace('<matchId>', statMatch?.match_id);
					}

					fetch(head2headPath)
						.then(data => {
							if(data?.player) {
								/** sort results from most recent to oldest */
								let sorted_results = data?.player[0]?.results;
								
								if(sorted_results) {
									/* sort by year/month/day most recent to least recent  */
									sorted_results.sort((a,b) => {
										return parseInt(b.year) - parseInt(a.year) || parseInt(b.month) - parseInt(a.month) || parseInt(b.day) - parseInt(a.day);
									});
								}

								data.player[0].results = sorted_results;

								let h2hdata = {
									headtohead: data.player[0]
								}

								/** use names from statMatch if using test data */
								if(this.props?.slamtrackerConfig?.useH2HTestData === true && statMatch?.match_id !== '1701') {
									h2hdata.headtohead.player1Name = `${statMatch.team1.firstNameA} ${statMatch.team1.lastNameA}`;
									h2hdata.headtohead.player2Name = `${statMatch.team2.firstNameA} ${statMatch.team2.lastNameA}`;
								}
								
								this.props?.updateData(h2hdata);
							}
						})
						.catch(error => {
							logger.error('[SlamtrackerContent] error loading head to head data:%o', error);
						})
				
				
					/** 
					 * HeadtoHeadYTDStats 
					 **/
					let ytdStatsObj;

					/** use file we know exists (for both players) if using test data */
					if(this.props?.slamtrackerConfig?.useYTDTestData === true) {
						ytdStatsObj = [
							"https://ashe.usopen.org/en_US/scores/feeds/2024/players/stats_ytd/atppf39_ytd.json",
							"https://ashe.usopen.org/en_US/scores/feeds/2024/players/stats_ytd/atppf39_ytd.json",
						]
					} else {
						ytdStatsObj = [
							this.props?.scoringData?.playerYTDStats.replace('<id>', statMatch.team1.idA),
							this.props?.scoringData?.playerYTDStats.replace('<id>', statMatch.team2.idA),
						]
					}

					fetchAll(ytdStatsObj)
						.then(data => {
							let ytddata= {
								ytd_stats: {
									team1: {name: statMatch.team1.displayNameA, player_stats: {...data[0]}},
									team2: {name: statMatch.team2.displayNameA, player_stats: {...data[1]}}
								}
							}
							this.props?.updateData(ytddata);
						})
						.catch(error => {
							logger.error('[SlamtrackerContent] error loading ytd stats data:%o', error);
						})	
			
				}
			}
		}
	}

	renderContentArea = () => {
		// logger.log('[SlamtrackerContent] renderContentArea - this:%o', this);
		// logger.log('[SlamtrackerContent] renderContentArea - overviewProps:%o', overviewProps);
		
		if(!this.state?.innovationsData && this.props?.slamtrackerConfig?.usePowerIndex === true && !this.innovationsLoading) {
			this.getInnovationsData();
		}

		let miList = this.props?.commonData?.matchInsightsMatches?.result?.matches;
		let { statMatch } = this.props;
		let { tab } = this.state;
		let status = getStatusConfig(statMatch.statusCode)['status'];

		const overviewClassnames = () => {
			//logger.log('[SlamtrackerContent] renderContentArea - overviewClassnames - this:%o', this);

			return cn({
				panel: true,
				'overview-panel ': true,
				pr: hasInsights(miList, statMatch.match_id),
				selected:
					this.props?.windowSize == 'mobile' || this.isPanel
						? this.state.tab == 'preview'
						: this.state.tab == 'preview',
			});
		};

		return (
			<div id="st-content-area" className="content-area" ref={this.myScrollArea}>
				{tab == 'preview' ? (
					<div className={overviewClassnames()}>
						{this.renderPreviewContent()}
					</div>
				) : null}
						
				{tab == 'live' && status == 'live' ? (
					<div
						className={`panel live-panel ${
							tab == 'live' ? 'selected' : ''
						}`}>
						{this.renderLiveContent(status)}
					</div>
				) : null}
						
				{tab == 'live' && status == 'post' ? (
					<div
						className={`panel live-panel ${
							tab == 'live' ? 'selected' : ''
						}`}>
						{this.renderRecapContent()}
					</div>
				) : null}
						
				{tab == 'summary' ? (
					<div className={`panel stats-panel ${tab == 'summary' ? 'selected' : ''}`}>
						{this.renderSummaryContent(status)}
					</div>
				) : null}
			</div>
		);
	}

	likelihoodToWinErrorCallback = (error, info) => {
		// logger.log(
		// 	'[SlamtrackerContent] likelihoodToWinErrorCallback - this:%o, error:%o, info:%o',
		// 	this,
		// 	error,
		// 	info
		// );

		this.setState(
			{
				powerrankError: true,
			},
			() => {
				logger.log('[SlamtrackerContent] likelihoodToWinErrorCallback - state:%o', this.state);
			}
		);
	}

	renderLikelihoodToWin() {
		let { statMatch } = this.props;
		
		return (
			<ErrorBoundary
				message={'Likelihood To Win is not currently available for this match'}
				klass="mi-section"
				callback={() => this.likelihoodToWinErrorCallback()}>
					{/* Likelihood To Win */}
				<LikelihoodToWin
					callback={() => this.likelihoodToWinErrorCallback()}
					nav={this.props.navigate}
					measureIndex={this.measureIndex}
					data={{
						...this.state?.innovationsData,
						imgPath: this.props?.configOtherData?.playerProfileImagePath || '',
						flagPath: this.props?.configOtherData?.flagImagePathSmall || '',
						windowSize: this.props?.windowSize,
						infoIcon: false,
						statMatch,
					}}
				/>
			</ErrorBoundary>
		);
	}

	render() {
		// logger.log('[SlamtrackerContent] render - this:%o', this);

		let { statMatch } = this.props;

		if (this.props?.stubs && this.props?.stubs?.slamtracker?.stub === 'stub') {
			return (
				<section className="wrapper scorespage">
					<h1 className="header">IBM SlamTracker</h1>
					<div className={`content-main${this.isPanel ? ' st-content-main' : ''}`}>
						<StubBox
							attributes={{
								id: this.isPanel ? 'panel-stub-page' : null,
								title: this.props.stubs.slamtracker.title,
								message: this.props.stubs.slamtracker.text,
							}}
						/>
					</div>
				</section>
			);
		} else if (
			/**
			 * should hit this condition for matches that are not live matches and
			 * are missing completed match data or point history data
			 **/
			this.props?.stubs &&
			this.props?.stubs?.slamtracker.stub !== 'stub' &&
			!this.currentMatch &&
			this.state.hasError
		) {
			return (
				<div className="content-main">
					<StubBox attributes={{ message: this.props?.stubs?.slamtracker?.nodata }} />
				</div>
			);
		} else if (
			statMatch &&
			statMatch?.match_id &&
			!isUndefined(this.props.stWindowSize)
		) {
			// let foundMatch;

			// if (this.matchStatus == UPCOMING_MATCH) {
			// 	foundMatch = this.props?.commonData?.upcomingMatches?.result?.matches.find(match => {
			// 		return match.match_id == this.props.matchId;
			// 	});
			// } else {
			// 	foundMatch = this.props?.liveMatches?.find(match => {
			// 		return match.match_id == this.props.matchId;
			// 	});
			// }

			return (
				<section
					className={`wrapper scorespage slamtracker ${this.props.scoring['uom']} ${
						this.props?.location ? this.props.location : ''
					} ${this.props.stWindowSize == 'mobile' ? 'st-mobile-view' : ''}`}>
					<h1 className="header" style={{ marginBottom: '0px' }}>
						IBM SlamTracker
					</h1>
					<div className="content-main">
						<div className="slamtracker-content">
							{this.props?.stWindowSize == 'mobile' && !this.isPanel ? (
								<div className="content-filters-scroll" />
							) : null}
							{this.isPanel ? <div className="st-content-scroll" /> : null}
							<div className="featured-match">
								{/* {logger.log(
									'[SlamtrackerContent] render - props:%o, foundMatch:%o, this.matchStatus:%o, this.isPanel:%o',
									this.props,
									foundMatch,
									this.matchStatus,
									this.isPanel
								)} */}
								<ErrorBoundary message="Scores are not available for this match">
									<STHeader
										statMatch={this.props.statMatch}
										liveMatchIds={this.props.liveMatchIds}
										match={statMatch}
										matchid={this.props.matchId}
										matchlist={this.props?.liveMatches}
										panel={this.isPanel}
										matchtype={this.matchStatus}
										useToggle={
											this.props?.slamtrackerConfig?.useToggle
												? this.props?.slamtrackerConfig?.useToggle
												: false
										}
										showChallenges={
											this.props?.slamtrackerConfig?.showChallenges
												? this.props?.slamtrackerConfig?.showChallenges
												: false
										}
                    playerImageURL={this.props?.configOtherData?.playerProfileImagePath}
                    // isHeaderFixed={this.props?.isHeaderFixed}
                    isMobileView={this.props.stWindowSize == 'mobile'}
									/>
								</ErrorBoundary>
							</div>
							{this.props.stWindowSize == 'mobile' && !this.isPanel ? (
								<div className="content-filters-spacer" />
							) : null}
							{this.isPanel ? <div className="st-content-spacer" /> : null}

							<ErrorBoundary message="Error in Tabs">
								<Tabs 
									tab={this.state?.tab}
									setPageViews={this.setPageViews}
									measureIndex={this.measureIndex}
								/>
							</ErrorBoundary>

							{statMatch?.match_id == this.props?.matchId ? this.renderContentArea() : <LoadingIndicator />}
						</div>
						{this.props.scoringMode ? (
							<div className="connection-type">{this.props.scoringMode.polling ? '..' : '.'}</div>
						) : null}
					</div>
					{/* {!window.webview ? (
						<div className="st-ad-container">
							<ErrorBoundary message="">
								<AdTag
									adConfig={this.props.adConfig.slamtracker}
									dfpNetworkId={this.props.adConfig.dfpNetworkId}
								/>
							</ErrorBoundary>
						</div>
					) : null} */}
				</section>
			);
		} else {
			return (
				<section className="wrapper scorespage slamtracker">
					<h1 className="header">IBM SlamTracker</h1>
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlamtrackerContent);
