/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import SlamtrackerLookup from './Lookup';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Translate
 * -----------------------------------------------------------------------------
 */
const Translate = props => {
	//let textfile = rosetta;

	// logger.log('[Translate] props: %o', props);

	const getMessage = props => {
		let message = SlamtrackerLookup.lookup[props.data.text];
		if (props.data.replace) {
			for (var i = 0; i < props.data.replace.length; i++) {
				message = message.replace('{' + i + '}', props.data.replace[i]);
			}
		}
		return message;
	};

	const getTranslation = props => {
		if (props.originalText != undefined && props.originalText != '') {
			let trans = SlamtrackerLookup.lookup[props.originalText];
			if (trans != undefined && trans != '') {
				return trans;
			}
		}
		if (props.englishDefault != undefined && props.englishDefault != '') {
			return props.englishDefault;
		}
		return props.originalText;
	};

	if (props.data) {
		return <span>{getMessage(props)}</span>;
	} else {
		return <span>{getTranslation(props)}</span>;
	}
};

export default Translate;
