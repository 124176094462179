/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import EventsLink from 'appdir/components/general/EventsLink';
// components
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import axios from 'axios';
import { fetchPromiseAll } from 'appdir/components/general/Util';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import TemplateGeneric from 'components/TemplateGeneric';
import { getQSParams } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import AdUnitLazy from 'appdir/components/general/AdUnitLazy';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import op from 'object-path';
import {
	chunkArray,
	matchHasInsights,
	renderTeamNames,
	matchIsFutureState,
	matchIsCompleteState,
} from 'appdir/components/pages/SchedulePage/ScheduleUtils';
import LazyMatchBox from 'appdir/components/_scoring/MatchBox/LazyMatchBox';
import MatchBox from 'appdir/components/_scoring/MatchBox';
import ScheduleBuyTickets from './ScheduleBuyTickets';
import ReactHtmlParser from 'html-react-parser';

const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: ScheduleWebview
 * this is the order of play page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ScheduleWebview'],
		adConfig: state['Config']['adConfig'],
		miMatches: state['Controller'].miMatches,
		appFavs: window.location.search ? getQSParams(window.location.search, 'filterPlayers').split(',') : [],
		appSearchedPlayer: window.location.search ? getQSParams(window.location.search, 'searchedPlayer') : null,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ScheduleWebview.mount()),
});

class ScheduleWebview extends Component {
	constructor(props) {
		super(props);

		// logger.log('[ScheduleWebview] constructor - props:%o', props);
		let filters = props.filters;

		// now add all the props and filters to state
		this.state = {
			favorite_filter: false,
			user_tz_display: false,
			filters: filters,
		};
		this.hasError = false;
		this.loadedJson = ''; // local class var to keep track of last loaded json (used to detect if redirect is needed)
		this.appShowFavs = window.location.search ? getQSParams(window.location.search, 'showFavs') == 'true' : false;

		//format team names for empty display
		this.emptyName = {
			firstNameA: '',
			lastNameA: '',
			displayNameA: 'TBD',
			idA: null,
		};
	}

	componentDidMount() {
		// logger.log('[ScheduleWebview] componentDidMount - this:%o', this);
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[ScheduleWebview] componentDidUpdate - this:%o', this);

		if (this.props.location.search) {
			/* timezone offset */
			let timezoneOffset = this.getQSParams(this.props.location.search, 'tzOffset');
			if (timezoneOffset != this.state.timezone_offset) {
				this.setState(prevState => ({
					...prevState,
					timezone_offset: timezoneOffset,
					user_tz_display: true,
				}));
			}

			/* favorites */
			// let favPlayers = this.getQSParams(this.props.location.search, 'filterPlayers');
			let favPlayers = this.props.appFavs;

			if (favPlayers != this.state.favorite_players) {
				this.setState(prevState => ({
					...prevState,
					favorite_players: favPlayers,
					favorite_filter: true,
				}));
			}
		}

		// calculate new data path from path in state and current filters
		if (this.props.path) {
			let dataPath = this.props.path.replace('<day>', this.state.filters.day);

			// if the data path is not what was last loaded,
			//   and not an update caused by filter status change, load now
			if (dataPath != this.loadedJson && this.props.status != 'change' && this.state.filters.day != '') {
				this.loadedJson = dataPath;

				let schedulePath = dataPath;
				let daysPath = this.props.daysPath;
				let highlightsPath = this.props.highlightsPath;
				let liveScoresPath = this.props.liveScores;
				let futureSchedulePath = this.props.futureSchedule;
				let pathObj = [schedulePath, highlightsPath, liveScoresPath];
				let futurePathObj = [daysPath, futureSchedulePath];

				// call the service fetchAll and pass in the object containing multiple request urls
				if (this.loadedJson != '' && !this.hasError) {
					fetchPromiseAll(futurePathObj)
						.then(
							axios.spread((daysResults, futureResults) => {
								this.hasError = false;
								this.setState({
									tourn_days: daysResults,
									future_schedule: futureResults,
								});
							})
						)
						.catch(error => {
							logger.log('[ScheduleWebview] componentDidUpdate error:%o', error);
							this.loadedJson = '';
							this.hasError = true;
						});

					fetchPromiseAll(pathObj)
						// take the returned results and save them to state.
						.then(
							axios.spread((scheduleResults, highlightResults, liveResults) => {
								this.hasError = false;
								// this.setState(prevState => ({
								// 	...prevState,
								// 	schedule_matches: scheduleResults,
								// 	match_highlights: highlightResults,
								// 	live_matches: liveResults,
								// }));
								this.setState({
									schedule_matches:
										scheduleResults?.status === 'error' ? (this.hasError = true) : scheduleResults,
									match_highlights: highlightResults,
									live_matches: liveResults,
								});
							})
						)
						.catch(error => {
							logger.log('[ScheduleWebview] componentDidUpdate error:%o', error);
							this.loadedJson = '';
							this.hasError = true;
						});
				}
			}
		}
	}

	getQSParams(search, which) {
		search = search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);
		let value = '';

		switch (which) {
			case 'filterPlayers':
				if (parsedQs.filterPlayers) {
					value = parsedQs.filterPlayers;
				}
				break;
			case 'tzOffset':
				if (parsedQs.tzOffset) {
					value = parsedQs.tzOffset;
				}
				break;
			default:
				break;
		}

		return value;
	}

	convertCourtTime(courtEpoch) {
		courtEpoch = courtEpoch * 1000; //have to make it milliseconds

		let wimTime = moment(courtEpoch).tz('Europe/London');
		let wimOffset = wimTime.utcOffset();

		let ustaTime = moment.tz(courtEpoch, 'America/New_York');
		let ustaOffset = ustaTime.utcOffset();

		let localTime = moment(courtEpoch);
		//get timezone name string
		let d = new Date();
		let ltz = Intl.DateTimeFormat(undefined, {timeZoneName: 'short'});
		let localTZ = ltz.formatToParts(d).find((part) => part.type == 'timeZoneName').value;
		
		return (
			<span className="wimTime time-display">
				<span className="time">{ustaTime.format('h:mm')}</span>
				<span className="suffix">
					<span className="meridiem">{ustaTime.format('a')} </span>
					<span className="timezone">EDT</span>
				</span>
				{ localTZ !== 'EDT' ? (
					<>
					<span className="time"> / {localTime.format('h:mm')}</span>
					<span className="suffix">
						<span className="meridiem">{localTime.format('a')} </span>
						<span className="timezone">{localTZ}</span>
					</span>
					</>
				): null }
			</span>
		);
	}

	getMode(day) {
		let mode = '';
		this.state.tourn_days.eventDays.forEach(item => {
			if (item.tournDay == day) {
				mode = item.quals ? 'quali' : 'tourn';
			}
		});
		return mode;
	}

	showVideo(id) {
		//logger.log('[ScheduleWebview] showVideo - id:%o', id);
		if (window.webviewPlatform == 'ios') {
			window.webkit.messageHandlers.video.postMessage(id);
		} else {
			location.href = 'http://usopen/video?id=' + id;
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		logger.log('[ScheduleWebview] getDerivedStateFromProps nextProps:%o, prevState:%o', nextProps, prevState);

		let url = '';
		let schedule_matches = prevState.schedule_matches;
		let tourn_days = prevState.tourn_days;
		let match_highlights = prevState.match_highlights;
		let live_matches = prevState.live_matches;

		let newState = {
			...prevState,
			...nextProps,
			nav: url,
			schedule_matches: schedule_matches,
			tourn_days: tourn_days,
			match_highlights: match_highlights,
			live_matches: live_matches,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[ScheduleWebview] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	renderMatch(match) {
		let useMIButton = matchHasInsights(this.props.miMatches, match.match_id) && !matchIsCompleteState(match);
		// Buy Tickets button only shows when event is in tournament state, matches have not begun, matches are not being played on Cary Leeds courts
		let useBuyTicketsButton =
			this.getMode(this.props.match.params.tournDay) == 'tourn' &&
			!match.courtName.includes('Cary') &&
			matchIsFutureState(match);
		let watchHighlightUrl = (this.state.match_highlights[match.match_id] || {}).contentId;
		let ticketURL = 'https://www.ticketmaster.com/usopentennis?cid=TMSchedule_07122021_phos_chluso_ctusot_endcid';

		return (
			<div
				key={match.match_id}
				className="match"
				data-players={`${match.team1[0].idA},${match.team1[0].idB},${match.team2[0].idA},${match.team2[0].idB}`}
				data-match={match.id}>
				<div className="row">
					<div className="match-info header" colSpan="3">
						{match.notBefore ? (
							<div className="match-info-notBefore">NOT BEFORE: {match.notBefore}</div>
						) : null}
						<span className="event">{match.eventName}</span> -{' '}
						<span className="round">{match.roundName}</span>
					</div>
					<div className="status content mobile">
						<EventsLink to={`/en_US/scores/stats/${match.match_id}.html`}>{match.status}</EventsLink>
					</div>
					<div className="scores header">{match.shortScore}</div>
				</div>
				<div className="row teams">
					<div className="schedule-team content">
						{renderTeamNames(
							match.team1,
							this.state.favorite_players,
							this.props?.appSearchedPlayer,
							this.appShowFavs
						)}
					</div>
					<div className="versus content">{match.conjunction}</div>
					<div className="schedule-team content">
						{renderTeamNames(
							match.team2,
							this.state.favorite_players,
							this.props?.appSearchedPlayer,
							this.appShowFavs
						)}
					</div>
					<div className="status content">
						<EventsLink to={`/en_US/scores/stats/${match.match_id}.html`}>{match.status}</EventsLink>
					</div>
				</div>
				<div className="button-row">
					{useMIButton ? (
						<div key={'insights_wv_' + match.match_id} className="mi-cta-button">
							<EventsLink to={`/en_US/matchinsights/${match.match_id}.html`}>Match Insights</EventsLink>
						</div>
					) : null}
					{useBuyTicketsButton ? (
						<div key={'buy_tickets_wv_' + match.match_id} className="tickets-cta-button">
							<EventsLink to={ticketURL}>Buy Tickets</EventsLink>
						</div>
					) : null}
					{watchHighlightUrl ? (
						<div key={'view_insights_wv_' + match.match_id} className="highlights-cta-button">
							<div
								onClick={() => {
									this.showVideo(watchHighlightUrl);
								}}>
								View Match
								<br />
								Highlights
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
	renderEmptyMatch(match) {
		// logger.log('[ScheduleContent] renderEmptyMatch:%o', match);
		return (
			<div key={match.match_id} className="one-col">
				<MatchBox
					attributes={{
						mode: 'schedule',
						data: { ...match, team1: this.emptyName, team2: this.emptyName },
						style: 'empty',
						event: match.shortEventName,
						tableHeader: match.courtName,
						showLinks: false,
						suppressProfileLink: true,
						comments: match.notBefore,
					}}
				/>
			</div>
		);
	}

	renderMatchScoreboard(match) {
		// logger.log('[ScheduleContent] renderMatchScoreboard:%o', match);
		//undecided winner
		if (match.team1.length > 1) {
			match['team1'] = [];
		} else if (match.team2.length > 1) {
			match['team2'] = [];
		}

		return (
			<div key={match.match_id} className="one-col">
				<LazyMatchBox
					attributes={{
						mode: 'schedule',
						data: {
							...match,
							team1: match.team1[0] ? match.team1[0] : this.emptyName,
							team2: match.team2[0] ? match.team2[0] : this.emptyName,
						},
						style: '',
						event: match.shortEventName,
						tableHeader: match.courtName,
						showLinks: true,
						suppressProfileLink: false,
						searchedPlayer: this.props?.attributes?.searchedPlayer,
						comments: match.notBefore,
					}}
				/>
			</div>
		);
	}

	renderMIP(match) {
		// logger.log('[ScheduleContent] renderMIP:%o', match);
		let liveList = this.props.liveMatches.matches;
		let currentScores = {};
		liveList.find(m => {
			if (m.match_id == match.match_id) {
				currentScores = m.scores;
			}
		});

		return (
			<div key={match.match_id} className="one-col">
				<MatchBox
					attributes={{
						data: {
							...match,
							team1: match.team1[0],
							team2: match.team2[0],
							scores: currentScores,
						},
						style: 'live',
						event: match.shortEventName,
						tableHeader: match.courtName,
						showLinks: true,
						searchedPlayer: this.state?.searchedPlayer,
					}}
				/>
			</div>
		);
	}

	render() {
		logger.log('[ScheduleWebview] render - this:%o', this);

		//get selected day; find if day has been released yet; show schedule or buy tickets
		let selectedDay = find(this.state?.tourn_days?.eventDays, d => {
			return d.tournDay == parseInt(this.props.filters.day);
		});

		// skip the pre-weekend to show day 1 of tournament
		let futureDayInfo;
		if (parseInt(this.props.filters.day) >= 5 || parseInt(this.props.filters.day) <= 7) {
			futureDayInfo = find(this.state?.future_schedule?.futureSchedule, f => {
				return f.day === 7;
			});
		} else {
			//get selected future schedule info
			futureDayInfo = find(this.state?.future_schedule?.futureSchedule, f => {
				return f.day === parseInt(this.props.filters.day);
			});
		}
		logger.log(
			'[ScheduleWebview] render - selectedDay:%o, futureDayInfo:%o, hasError:%o',
			selectedDay,
			futureDayInfo,
			this.hasError
		);

		// if theres no tournDay, then redirect to current day that is in the activeData.
		if (this.hasError) {
			if (!selectedDay?.released && futureDayInfo) {
				return (
					<TemplateGeneric className="webview">
						<section id="schedule" className="wrapper scorespage webview">
							<div className="content-main">
								<div className="column-layout scorespage">
									<div className="schedule-info">
										<div className="day">{futureDayInfo.displayDate}</div>
										<div className="revised">{ReactHtmlParser(futureDayInfo.comments)}</div>
									</div>
								</div>
								{/* //show buy tickets for future days */}
								<ScheduleBuyTickets
									attributes={{
										data: futureDayInfo,
									}}
								/>
							</div>
						</section>
					</TemplateGeneric>
				);
			} else {
				return (
					<section className="wrapper scorespage">
						<div className="content-main">
							<GenericError message="No schedule found" />
						</div>
					</section>
				);
			}
		} else if (
			this.state.schedule_matches &&
			this.state.tourn_days &&
			this.state.match_highlights &&
			op.get(this.props, 'miMatches', false)
		) {
			logger.log(
				'[ScheduleWebview] render - schedule_matches:%o, tourn_days:%o, match_highlights:%o, miMatches:%o',
				this.state.schedule_matches,
				this.state.tourn_days,
				this.state.match_highlights,
				op.get(this.props, 'miMatches', false)
			);

			let chunkedCourts = chunkArray(this.state.schedule_matches.courts, 3);
			let hasFooterComments = false;
			let fcData = [];

			if (this.state.schedule_matches.footerComment !== null) {
				hasFooterComments = true;
				fcData = this.state.schedule_matches.footerComment.split('|');
			}
			return (
				<TemplateGeneric className="webview">
					<section id="schedule" className="wrapper scorespage webview">
						<div className="content-main">
							{!selectedDay?.released ? (
								<ScheduleBuyTickets
									attributes={{
										data: futureDayInfo,
									}}
								/>
							) : (
								<div className="column-layout scorespage">
									{/* <div id="schedule-ad-1" className="schedule-ad-container">
									<AdTag
										adConfig={this.props.adConfig.schedule_1}
										dfpNetworkId={this.props.adConfig.dfpNetworkId}
									/>
								</div> */}
									<div className="schedule-info">
										<div className="day">{this.state.schedule_matches.displayDate}</div>
										{this.state.schedule_matches.comments != null ? (
											<div className="revised">{this.state.schedule_matches.comments}</div>
										) : (
											''
										)}
									</div>
									{chunkedCourts.map((courts, index) => {
										return (
											<React.Fragment key={`frag${index}`}>
												<div className="column-layout">
													{courts.map((court, i) => {
														return (
															<div
																key={court.courtName + court.session}
																className="schedule-court">
																<div className="courtName">
																	{court.courtName}
																	<br />
																	{this.convertCourtTime(court.startEpoch)}
																</div>
																<div className="schedule-content">
																	{court.matches.map(match => {
																		//check for empty/exhibition matches
																		if (
																			match.team1.length == 0 &&
																			match.team2.length == 0
																		) {
																			return this.renderEmptyMatch(match);
																		} else {
																			return this.renderMatchScoreboard(match);
																		}

																		//render for mip
																		// if (matchIsCompleteState(match) || match.statusCode == 'B') {
																		// return this.renderMatchScoreboard(match);
																		// } else {
																		// 	return this.renderMIP(match);
																		// }
																	})}
																</div>
																{i % 3 == 2 &&
																i !== this?.state?.blogData?.content?.length ? (
																	<div className="topAd">
																		<AdUnitLazy
																			key={`adunit-${i}`}
																			id={`adunit-${i}`}
																			data={{ adType: `schedule_1` }}
																		/>
																	</div>
																) : null}
															</div>
														);
													})}
												</div>
												{/*
											{index == 0 ? (
												<div id="schedule-ad-2" className="schedule-ad-container">
													<AdTag
														adConfig={this.props.adConfig.schedule_2}
														dfpNetworkId={this.props.adConfig.dfpNetworkId}
													/>
												</div>
											) : null}
											{index == 1 ? (
												<div id="schedule-ad-3" className="schedule-ad-container">
													<AdTag
														adConfig={this.props.adConfig.schedule_3}
														dfpNetworkId={this.props.adConfig.dfpNetworkId}
													/>
												</div>
											) : null} */}
											</React.Fragment>
										);
									})}
									{hasFooterComments === true ? (
										// <div className="column-layout">
										<div className="footer-comments">
											{fcData.map((comment, i) => {
												return <div className="footer-comment-item">{comment}</div>;
											})}
										</div>
									) : // </div>
									null}
								</div>
							)}
						</div>
					</section>
				</TemplateGeneric>
			);
		} else {
			return (
				<section className="wrapper scorespage">
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleWebview);
