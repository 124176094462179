import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.VIDEOPLAYERLANDING_MOUNT:
			newState = {
				...state,
				config: action.data.config,
				closeFromVideo: action.data.closeFromVideo
			};
			return newState;

		case deps.actionTypes.VIDEOPLAYERLANDING_CLOSE_VIDEO_MODAL:
			newState = {
				...state,
				closeFromVideo: true
			};
			return newState;
	
			
		default:
			return state;
	}
};
