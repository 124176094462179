import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.BREAKINGALERT_MOUNT:
			newState = {
				...state,
				...action.data,
			};
			// logger.log("[BreakingAlert] BREAKINGALERT_MOUNT - state:%o", newState);
			return newState;

		case deps.actionTypes.BREAKINGALERT_STATUS:
			newState = {
				...state,
				status: action.data,
			};
			//   logger.log("[BreakingAlert] BREAKINGALERT_STATUS - state:%o", newState);
			return newState;

		default:
			return state;
	}
};
