/**
 MainNav Initial State
*/

export default {
	key: 'value',
	sharePopup: 'hide',
	newPath: null,
	status: null,
	hideAd: false,
};
