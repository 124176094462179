import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import { relatedContentVideo } from 'appdir/components/data/Schema';

const v = new Validator();

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: hdr }).then(({ data }) => data);
};

const fetchRelated = path => {
	let hdr = restHeaders();

	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				logger.log('[VideoIndex] fetchRelated - 200 valid response');
				let validateResult = v.validate(response.data, relatedContentVideo);
				if (validateResult.valid) {
					return response.data;
				} else {
					// throw error and go to catch block
					throw new Error('failed schema validation');
				}
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			logger.warn('[VideoPlayer] fetchRelated - %o', error.message);
			throw new Error(error.message);
		});
};

export default {
	fetch,
	fetchRelated,
};
