/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Button
 * -----------------------------------------------------------------------------
 *
 * @param state
 * @param state.name {String} The icon name.
 * @param state.style {Object} CSS styles to apply to the component.
 * @param state.size Valid CSS font-size value. The font size of the icon. Default: 22. Note: if you set fontSize in state.style, it will be overwritten with this value.
 * @param state.color Valid CSS color value. Default: #FFFFFF. Note: if you set color in state.style, it will be overwritten with this value.
 *
 * @return {*}
 * @constructor
 */
const Button = ({ to, onClick, external, target, className, style, children, disabled, bypassAppLink }) => {
	let classes = ['generic-button', className];

	let keyPress = e => {
		if (e.key === 'Enter') {
			onClick();
		}
	};

	let center = className.indexOf('center') >= 0 ? 'center' : '';

	if (to) {
		return (
			<div
				className={
					disabled
						? `generic-button_wrapper ${center} disabled_generic-button_wrapper`
						: `${center} generic-button_wrapper`
				}
				disabled={disabled}>
				<EventsLink to={to} external={external} target={target} bypassAppLink={bypassAppLink}>
					<div className={classes.join(' ')} style={style}>
						<div className="content">{children}</div>
						<div className="btn-border"></div>
					</div>
				</EventsLink>
			</div>
		);
	} else if (onClick) {
		return (
			<div
				className={
					disabled ? 'generic-button_wrapper disabled_generic-button_wrapper' : 'generic-button_wrapper'
				}
				disabled={disabled}>
				<div
					className={classes.join(' ')}
					style={style}
					onClick={() => onClick()}
					onKeyPress={e => keyPress(e)}
					tabIndex="0"
					role="button">
					<div className="content">{children}</div>
					<div className="btn-border"></div>
				</div>
			</div>
		);
	} else {
		return (
			<div
				className={
					disabled ? 'generic-button_wrapper disabled_generic-button_wrapper' : 'generic-button_wrapper'
				}
				disabled={disabled}>
				<div className={classes.join(' ')} style={style}>
					<div className="content">{children}</div>
					<div className="btn-border"></div>
				</div>
			</div>
		);
	}
};

/**
 * -----------------------------------------------------------------------------
 * Exports
 * -----------------------------------------------------------------------------
 */
export default Button;
