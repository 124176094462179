import deps from "dependencies";

export default {
  mount: data => (dispatch, getState, store) => {
    return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
      Config => {
        logger.log(
          "[HomePreview] actions.mount: Config.cmsData",
          Config.cmsData
        );

        data.homeData = Config.cmsData.homeContent;
        data.basePicPath = Config.otherData.basePicPath;
        data.jsonServer = Config.cmsData.home.jsonServer;
        data.relatedChips = Config.relatedContent.homeRelated;
        data.adMode = Config.landingPages.adMode;
        data.status = "loaded";
        data.start = 0;
        data.count = 11;
        data.end = 11;

        if (data.prefix === "preview" && data.category) {
          switch (data.category) {
            case "home_modules":
              data.homeData = "/preview" + Config.cmsData.homeContent;
              break;
            default:
              break;
          }
        }
        logger.log("[HomePreview] action.mount - data:%o", data);
        dispatch({ type: deps.actionTypes.HOMEPREVIEW_MOUNT, data: data });
      }
    );
  }
  // more: (start, count) => (dispatch, getState, store) => {
  // 	logger.log('[HomePreview] actions.more: start:%o, count:%o', start, count);
  // 	let data = {
  // 		start,
  // 		count,
  // 		end: start + count,
  // 	};
  // 	return dispatch({ type: deps.actionTypes.HOME_MORE, data: data });
  // },
};
