import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';
import { Validator } from 'jsonschema';
import { searchResults } from 'appdir/components/data/Schema';

const v = new Validator();

const fetch = path => {
	let hdr = restHeaders();
	return axios.get(path, { headers: { ...hdr, 'Content-type': 'application/json' } }).then(({ data }) => data);
};

/**
 *
 * @param {String} path
 */
const fetchSearchResults = path => {
	let hdr = restHeaders();
	return axios
		.get(path, {
			headers: { contentType: 'application/json', Accept: 'application/json' },
		})
		.then(response => {
			logger.log('[SearchPage] fetchRelated - response:%o', response);
			if (response.status === 200) {
				logger.log(response, 'The response headers');
				//logger.log('[SearchPage] fetchRelated - 200 valid response');
				let validateResult = v.validate(response.data, searchResults);
				// if (validateResult.valid) {
				if (true) {
					return response;
				} else {
					// throw error and go to catch block
					throw new Error('failed schema validation');
				}
			} else {
				// throw error and go to catch block
				throw new Error('invalid response code');
			}
		})
		.catch(error => {
			//when throw "Error" is executed it runs the catch block code
			logger.warn('[SearchPage] fetchRelated - %o', error.message);
			throw new Error(error.message);
		});
};

export default {
	fetch,
	fetchSearchResults,
};
