/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { values } from 'appdir/main';
import deps from 'dependencies';
import Translate from 'appdir/components/pages/Slamtracker/Translate';
/**
 * -----------------------------------------------------------------------------
 * React Component: Slamtracker Unit Toggle
 * -----------------------------------------------------------------------------
 */

const SlamtrackerToggle = props => {

	const statMatch = useSelector((state) => state?.['ScoreManager']?.statMatch ?? {});
	// const uom = useSelector((state) => state?.['Controller']?.userPreferences['speed']);
    const uom = "I";
	const dispatch = useDispatch();

	const changeMeasurement = (which) => {
		logger.log('[SlamtrackerToggle] changeMeasurement - which:%o ', which);
		dispatch(deps.actions.Controller.updatePreference({ userPreferences: { speed: which } }));
		measureToggle('toggle', which == 'M' ? 'metric' : 'imp');
	}

	const measureToggle = (measureAction, measure_args, context = []) => {
		if (window.location.href.indexOf('suite') == -1) {
			let matchStatus;

			if (statMatch?.status == 'Complete') {
				matchStatus = 'Complete';
			} else {
				matchStatus = 'Live';
			}

			if (!window.webview) {
				context.push({ pageTitle: 'Slamtracker' });

				// measureInWeb({
				// 	action: measureAction,
				// 	context: context,
				// });
			} else {
				// measureInAppContext({
				// 	pageTitle: 'Slamtracker',
				// 	action: measureAction,
				// 	args: measure_args,
				// 	context: context,
				// });
			}
		}
	}

	return uom ? (
		<div className="unit-toggle">
			<div className="radioBtn">
				<input
					type="radio"
					name="unit-toggle"
					defaultChecked={uom == 'M' ? true : false}
					onClick={() => changeMeasurement('M')}
				/>
				<span className="checkmark" />
				<label htmlFor="metric">
					<Translate originalText="metric" />
				</label>
			</div>

			<div className="radioBtn">
				<input
					type="radio"
					name="unit-toggle"
					defaultChecked={uom == 'I' ? true : false}
					onClick={() => changeMeasurement('I')}
				/>
				<span className="checkmark" />
				<label htmlFor="imperial">
					<Translate originalText="imperial" />
				</label>
			</div>
		</div>
	) : null
}

export default SlamtrackerToggle;
