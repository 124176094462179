export default {
  SCORE_MANAGER_MOUNT: "SCORE_MANAGER_MOUNT",
  SCORE_MANAGER_UPDATE_SCORES_DATA: "SCORE_MANAGER_UPDATE_SCORES_DATA",
  SCORE_MANAGER_SET_CURRENT_MATCH: "SCORE_MANAGER_SET_CURRENT_MATCH",
  SCORE_MANAGER_SET_LIVE_STATMATCH: "SCORE_MANAGER_SET_LIVE_STATMATCH",
  SCORE_MANAGER_SET_COMPLETED_STATMATCH:
    "SCORE_MANAGER_SET_COMPLETED_STATMATCH",
  SCORE_MANAGER_SET_STATMATCH_STATS: "SCORE_MANAGER_SET_STATMATCH_STATS",
  SCORE_MANAGER_SET_STATMATCH_HISTORY: "SCORE_MANAGER_SET_STATMATCH_HISTORY",
  SCORE_MANAGER_CLEAR_STATMATCH: "SCORE_MANAGER_CLEAR_STATMATCH"
};
