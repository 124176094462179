/**
 DrawAnalysis Initial State
*/
/** show ---------- set toggle switch
 *  contentShow --- show/hide the draw analysis description text
 */
export default {
	show: false,
	contentShow: true,
	// 2592000000 = 30 days in milliseconds
	persist: {
		show: 2592000000,
	},
};
