/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';
import ModalOverlay from 'appdir/components/general/ModalOverlay';
import Video from 'appdir/components/content/Video';

/**
 * -----------------------------------------------------------------------------
 * RLL - some of this may change based on how this works for everyone and once
 * 	measurement gets sorted out
 * React Component: Share
 *
 * props:
 * url - specific url to share if not the current page
 * section - section for measurement
 * title - title for measurement
 * -----------------------------------------------------------------------------
 */

const VideoModal = props => {
	let { modalParams } = useSelector(state => state['ModalOverlay']);
	let { video } = modalParams;
	
	// logger.log('[VideoModal] props - modalParams:%o', modalParams);
	// logger.log('[VideoModal] props - props:%o', props);
	// logger.log('[VideoModal] props - video:%o', video);

	const dispatch = useDispatch();

	const onClose = () => {
		document.body.classList.remove('modal-on-noscroll-nofixed');
		dispatch(deps.actions.ModalOverlay.hideOverlayModal());
	};

	/* on load (componenDidMount) */
	useEffect(() => {
		if (!document.body.classList.contains('modal-on-noscroll-nofixed')) {
			document.body.classList.add('modal-on-noscroll-nofixed');
		} else {
			document.body.classList.remove('modal-on-noscroll-nofixed');
		}
	}, []);

	return (
		<ModalOverlay onClose={onClose} containerClass="video-overlay-modal" modalClass="video-modal">
			{video && (
				<div className="modal-content-wrapper">
					<Video key={`video_` + video?.id} data={video} />
				</div>
			)}
		</ModalOverlay>
	);
};
export default VideoModal;
