import deps from 'dependencies';
import MeasurementUtils from 'appdir/lib/analytics';

export default {
	mount: data => (dispatch, getState, store) => {
		logger.log('[LiveVideo] action.mount - params: %o', data);

		if (data.channel != 'live') {
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'US Open Live - ' + data.channel,
			});
		}

		//logger.log('[VideoIndex] action.mount - ensureConfig');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let values = {
				selected: data.channel,
				basePicPath: Config.otherData.basePicPath,
				espnUrl: Config.otherData.espnIframeUrl,
			};
			logger.log('[VideoIndex] action.mount - data%o', values);
			dispatch({ type: deps.actionTypes.LIVEVIDEO_MOUNT, data: values });
		});
	},

	openPip: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEVIDEO_OPENPIP, data });
	},

	closePip: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEVIDEO_CLOSEPIP });
	},

	unmount: () => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEVIDEO_UNMOUNT });
	},

	select: selected => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEVIDEO_SELECT, data: selected });
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.LIVEVIDEO_FILTER, data: data });
	},
};
