/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';
import { fetchAll } from 'appdir/components/general/Util';
import { fetch } from 'appdir/components/general/Util';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import cookie from 'react-cookies';
import { windowSizeVals } from 'appdir/components/general/Util';
import throttle from 'lodash/throttle';

/**
 * -----------------------------------------------------------------------------
 * React Component: Header
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return Object.assign(
		{},
		{ Controller: state['Controller'] },
		{ Stubs: state['Config']['stubs'] },
		{ OtherData: state['Config']['otherData'] },
		{ ScoringData: state['Config']['scoringData'] },
		{ Router: state['Router'] },
		props
	);
	// return {
	// 	Controller: state['Controller'],
	// 	Stubs: state['Config']['stubs'],
	// 	OtherData: state['Config']['otherData'],
	// 	Router: state['Router'],
	// 	...props
	// };
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Controller.mount()),
	update: data => dispatch(deps.actions.Controller.update(data)),
	setPlatform: data => dispatch(deps.actions.Controller.setPlatform(data)),
	updateDate: data => dispatch(deps.actions.Controller.updateDate(data)),
	setWindowSize: windowsize => dispatch(deps.actions.Controller.setWindowSize(windowsize)),
	setSTWindowSize: stwindowsize => dispatch(deps.actions.Controller.setSTWindowSize(stwindowsize)),
	setMIWindowSize: miwindowsize => dispatch(deps.actions.Controller.setMIWindowSize(miwindowsize)),
	updateGeoBlock: data => dispatch(deps.actions.Controller.updateGeoBlock(data)),
	updateVideoChannels: data => dispatch(deps.actions.Controller.updateVideoChannels(data)),
	setMenuItems: data => dispatch(deps.actions.Controller.setMenuItems(data)),
	setGlobalLink: data => dispatch(deps.actions.Controller.setGlobalLink(data)),
	setScoringStatus: data => dispatch(deps.actions.Controller.setScoringStatus(data)),
});

const mobileMatch = window.matchMedia('(max-width: 639px)');
const landscapeMatch = window.matchMedia('(max-width: 767px)');
const smtabletMatch = window.matchMedia('(max-width: 959px)');
const tabletMatch = window.matchMedia('(max-width: 1023px)');
const smdesktopMatch = window.matchMedia('(max-width: 1279px)');
const desktopMatch = window.matchMedia('(max-width: 1599px)');
//const midDesktopMatch = window.matchMedia('(min-width: 1365px) and (max-width: 1600px)');
const largeDesktopMatch = window.matchMedia('(min-width: 1600px)');

// these screen sizes ar for slamtracker
const stMobileMatch = window.matchMedia('(max-width: 767px)');
const stTabletMatch = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
const stDesktopMatch = window.matchMedia('(min-width: 1024px)');

// these screen sizes are for match insights
const miMobileMatch = window.matchMedia('(max-width: 767px)');
const miTabletMatch = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
const miDesktopMatch = window.matchMedia('(min-width: 1024px) and (max-width: 1279px)');
const miLargeDesktopMatch = window.matchMedia('(min-width: 1280px)');

class Controller extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// ...props,
		};
		// this.startScores = false; // are we in the process of starting scores?
		logger.log('[Controller] constructor props:%o', props);
		logger.log('[Controller] constructor props:%o', this.props);
		this.checkWindowSizes = this.checkWindowSizes.bind(this);
	}

	componentDidMount() {
		//current datetime
		let currTime = Date.now();

		logger.log('[Controller] componentdidMount this:%o', this);

		//detect device
		let platform = '';
		if (isMobile && !isTablet) {
			platform = 'mobile';
		} else if (isBrowser) {
			platform = 'browser';
		} else if (isTablet) {
			platform = 'tablet';
		}
		this.props.mount();
		this.props.setPlatform(platform);
		this.props.updateDate(currTime);
		window.addEventListener('resize', throttle(this.onResize.bind(this), 100, { trailing: true }), false);
		window.addEventListener('orientationchange', this.onResize.bind(this), false);
		this.onResize();
		this.geoChecked = false;
		this.videoJsonLoaded = false;
		this.checkVideoTimer = '';
		this.fetchedMenu = false;
		this.countriesLoading = false;
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize.bind(this), false);
		clearTimeout(this.checkVideoTimer);
	}

	componentDidUpdate(prevState) {
		// logger.log('[Controller] componentDidUpdate - this:%o', this);
		this.handleScores();

		if (!this.geoChecked && this.props.OtherData) {
			let liveBlock;

			fetch(this.props.OtherData['liveAtGeoCheck'])
				.then(result => {
					liveBlock = result.block;
					cookie.save('geo_cookie', liveBlock, { path: '/' });

					logger.log('[GeoBlock] checkGeoBlock - geo set :%o', {
						block: liveBlock,
					});

					this.props.updateGeoBlock(liveBlock);
				})
				.catch(e => {
					liveBlock = true;
					cookie.save('geo_cookie', liveBlock, { path: '/' });

					logger.log('[GeoBlock] checkGeoBlock error - geo set :%o', {
						block: liveBlock,
					});

					this.props.updateGeoBlock(liveBlock);
				});

			this.geoChecked = true;
		}

		if (this.props.OtherData && !this.videoJsonLoaded) {
			this.checkVideoJson();
		}
		this.fetchMenuItems();

		if (!this.props.Controller?.countriesLookUp && !this.countriesLoading) {
			this.fetchCountriesLookUp();
		}

		if (this.props.Controller.globalLink && this.props.Controller.globalLink.link == window.location.pathname) {
			// logger.log('[Controller] componentDidUpdate - clear globalLink:%o');
			this.props.setGlobalLink({ globalLink: undefined });
		}
	}

	fetchMenuItems() {
		// logger.log('[Controller] fetchMenuItems:%o', this.props.Controller);
		if (this.props?.Controller?.menus && !this.fetchedMenu) {
			this.fetchedMenu = true;
			let pathsObj = [this.props.Controller.menus, this.props.Controller.siteBG];

			fetchAll(pathsObj)
				.then(
					axios.spread((result, siteResults) => {
						this.props.setMenuItems({
							menuItems: result,
							bgImage: siteResults.backgroundImage,
							mobile_bgImage: siteResults.mobileBackgroundImage,
						});
					})
				)
				.catch(error => {
					logger.log('[Controller] fetchAll error loading data: %o', error);
				});
		}
	}

	fetchCountriesLookUp = () => {
		if (this.props.ScoringData) {
			this.countriesLoading = true;
			fetch(this.props.ScoringData['countriesLookUp'])
				.then(result => {
					// logger.log('[Controller] fetchCountriesLookUp countriesList :%o', result);

					this.props.update({ countriesLookUp: result.countries });
				})
				.catch(e => {
					logger.error('[Controller] fetchCountriesLookUp error - geo set :%o', e);
					this.countriesLoading = false;
					this.props.update({ countriesLookUp: {} });
				});
		}
	};

	/* 
        Notes, so i don't forget!
        This function handles turning on or turning off scores.   The idea is to look at current state and 
        determine if scores should start or stop.

        rules:  if tournstarted && mip -> start scores
                if tournstarted && sidepanel && not mip -> start scores
                if !tournstarted -> stop scores
                if tournstarted && slamtracker -> start stats

        notes:  currently, i'm only handling "if mip" scenario.  no check for tournstarted yet.  there is more to be done
                because i'm resetting the scoredata in livescores component, because current scoring module is not doing that
                when you call stop scoring action.

    */
	handleScores() {
		// logger.log('[Controller] - handleScores scoring:%o', this.props.Controller.scoring);
		let { scoring } = this.props.Controller;
		if (
			scoring &&
			scoring.loaded &&
			(scoring.mip || scoring.sidepanel || scoring.slamtracker || scoring.schedule || scoring.draws)
		) {
			if (!scoring.started && window.currentConnection) {
				logger.log('[Controller] handleScores - dispatch start scores');
				// this.startScores = true; // true if we are in the process of starting scores
				this.props.setScoringStatus({ started: true });
				let startEvent = new CustomEvent('ixEventsScoring', {
					detail: { type: 'start' },
				});
				window.dispatchEvent(startEvent);
			}
		} else if (
			scoring &&
			!scoring.mip &&
			!scoring.sidepanel &&
			!scoring.slamtracker &&
			!scoring.schedule &&
			!scoring.draws
		) {
			if (scoring.started) {
				logger.log('[Controller] handleScores - dispatch stop scores');
				// this.startScores = false;
				this.props.setScoringStatus({ started: false });
				let stopEvent = new CustomEvent('ixEventsScoring', {
					detail: { type: 'stop' },
				});
				window.dispatchEvent(stopEvent);
			}
		}
	}

	onResize(event) {
		this.checkWindowSizes();
	}

	checkWindowSizes() {
		let size = '';
		let numericSize = 0;

		if (mobileMatch.matches) {
			size = 'mobile';
			numericSize = windowSizeVals.MOBILE_WINSIZE;
		} else if (landscapeMatch.matches) {
			size = 'landscape';
			numericSize = windowSizeVals.LANDSCAPE_WINSIZE;
		} else if (smtabletMatch.matches) {
			size = 'smtablet';
			numericSize = windowSizeVals.SMTABLET_WINSIZE;
		} else if (tabletMatch.matches) {
			size = 'tablet';
			numericSize = windowSizeVals.TABLET_WINSIZE;
		} else if (smdesktopMatch.matches) {
			size = 'smdesktop';
			numericSize = windowSizeVals.SMDESKTOP_WINSIZE;
		} else if (desktopMatch.matches || largeDesktopMatch.matches) {
			size = 'desktop';
			numericSize = windowSizeVals.DESKTOP_WINSIZE;
		}

		if (
			size !== this.lastWindowSize ||
			window.innerWidth !== this.lastWindowWidth ||
			window.innerHeight !== this.lastWindowHeight
		) {
			this.lastWindowSize = size;
			this.props.setWindowSize({
				windowSize: size,
				windowSizeVal: numericSize,
				windowWidth: window.innerWidth,
				windowHeight: window.innerHeight,
			});
			logger.log('[Controller] checkWindowSizes - windowWidth: ', window.innerWidth);
		}

		//slamtracker window sizes
		let stSize = '';

		// const stMobileMatch = window.matchMedia('(max-width: 767px)');
		// const stTabletMatch = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
		// const stDesktopMatch = window.matchMedia('(min-width: 1024px)');

		if (stMobileMatch.matches) {
			stSize = 'mobile';
		} else if (stTabletMatch.matches) {
			stSize = 'tablet';
		} else if (stDesktopMatch) {
			stSize = 'desktop';
		}

		if (stSize !== this.lastSTWindowSize) {
			this.lastSTWindowSize = stSize;
			this.props.setSTWindowSize({
				stWindowSize: stSize,
			});
			logger.log('[Controller] checkWindowSizes - stSize: ', stSize);
		}

		//match insights window sizes
		let miSize = '';

		if (miMobileMatch.matches) {
			miSize = 'mobile';
		} else if (miTabletMatch.matches) {
			miSize = 'tablet';
		} else if (miDesktopMatch.matches) {
			miSize = 'desktop';
		} else if (miLargeDesktopMatch.matches) {
			miSize = 'lgdesktop';
		}

		if (miSize !== this.lastMIWindowSize) {
			this.lastMIWindowSize = miSize;
			this.props.setMIWindowSize({
				miWindowSize: miSize,
			});
			// logger.log('[Controller] checkWindowSizes - size: ', size);
		}
	}

	/*
	 * This method loads the live video json and updates the state
	 * with the data so it's available in other places
	 */
	checkVideoJson() {
		// logger.log('[Controller] checkVideoJson - this:%o', this);
		this.videoJsonLoaded = true;
		fetch(this.props.OtherData['liveVideo'])
			.then(json => {
				this.props.updateVideoChannels(json.channels);

				// Update again in 30 seconds
				//setTimeout("this.checkVideoJson()", 30000);

				this.checkVideoTimer = setTimeout(() => this.checkVideoJson(), 30000);
			})
			.catch(error => {
				logger.log('[LiveVideo] checkVideoJson error:%o', error);
				this.videoJsonLoaded = false;
			});
	}

	render() {
		// logger.log('[Controller] render this: ', this);
		return <div />;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
