import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		// logger.log("[Slamtracker] action.mount - ensureConfig");
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// logger.log("[Slamtracker] action.mount - params:%o", params);

			let data = {
				configStatus: 'loaded',
				debug: getState().Router.hash == '#ibm' ? true : false,
			};
			// logger.log('[Slamtracker] action.mount - data: %o', data);
			dispatch({ type: deps.actionTypes.SLAMTRACKER_MOUNT, data: data });
		});
	},
	unmount: () => dispatch => {
		let data = {};
		// logger.log("[Slamtracker] action.unmount - data: %o", data);

		dispatch({ type: deps.actionTypes.SLAMTRACKER_RESET, data: data });
	},
	reset: () => dispatch => {
		let data = {};
		// logger.log("[Slamtracker] action.reset - data: %o", data);

		dispatch({ type: deps.actionTypes.SLAMTRACKER_RESET, data: data });
	},
	changeView: data => dispatch => {
		dispatch({ type: deps.actionTypes.SLAMTRACKER_CHANGEVIEW, data: data });
	},
	liveMount: () => dispatch => {
		let data = {
			liveUpdated: moment().toISOString(),
		};
		dispatch({ type: deps.actionTypes.SLAMTRACKERLIVE_MOUNT, data });
	},
	liveToggleGame: data => dispatch => {
		dispatch({ type: deps.actionTypes.SLAMTRACKERLIVE_TOGGLEGAME, data });
	},
	updateMatchHistory: data => dispatch => {
		dispatch({ type: deps.actionTypes.SLAMTRACKER_UPDATE_MATCHHISTORY, data });
	},
	// loadSituationalData: data => dispatch => {
	//     dispatch({
	//         type: deps.actionTypes.SLAMTRACKER_LOAD_SITUATIONAL_DATA,
	//         data
	//     });
	// },
	updateMomentumPreference: data => dispatch => {
		// logger.log("[Slamtracker] action updateMomentumPreference - data:%o", data);
		if (data.zoomLevel) {
			dispatch({
				type: deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_ZOOM,
				data,
			});
		}
		if (data.settings) {
			dispatch({
				type: deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_SETTINGS,
				data,
			});
		}
		if (data.set) {
			dispatch({
				type: deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_SET,
				data,
			});
		}
		if (data.game) {
			dispatch({
				type: deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_GAME,
				data,
			});
		}
		if (data.showSettings || data.showSettings == false) {
			dispatch({
				type: deps.actionTypes.SLAMTRACKER_UPDATE_MOMENTUM_SHOW_SETTINGS,
				data,
			});
		}
	},
	enableMomentumScroll: () => dispatch => {
		dispatch({ type: deps.actionTypes.SLAMTRACKER_ENABLE_MOMENTUM_SCROLL });
	},
	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.SLAMTRACKER_FILTER, data });
	},
	mountMomentum: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.SLAMTRACKER_MOMENTUM_MOUNT, data });
	},
	togglePoint: data => dispatch => {
		dispatch({ type: deps.actionTypes.SLAMTRACKER_MOMENTUM_TOGGLEPOINT, data });
	},
	updateHighlights: data => dispatch => {
		dispatch({ type: deps.actionTypes.SLAMTRACKER_UPDATE_HIGHLIGHTS, data });
	},
	updateData: (data) => dispatch => {
		// logger.log("[Slamtracker] action.updateData - data: %o", data);
		dispatch({ type: deps.actionTypes.SLAMTRACKER_UPDATE_DATA, data });
	}
};
