const lookup = new Object();

/* 2018 lookups */
// RLL is moving lookups still used into this block to see what we can delete

/* stats */
lookup['aces'] = 'Aces';
lookup['double_faults'] = 'Double Faults';
lookup['first_srv_pct_in'] = 'First Serve % In';
lookup['win_pct_first_srv'] = 'Win % on 1st Serve';
lookup['win_pct_second_srv'] = 'Win % on 2nd Serve';
lookup['second_srv_pct_in'] = 'Second Serve % In';
lookup['winners'] = 'Winners';
lookup['net_points_won'] = 'Net Points Won';
lookup['break_points_won'] = 'Break Points Won';
lookup['rec_pts_won'] = 'Receiving Points Won';
lookup['unforced_errors'] = 'Unforced Errors';
lookup['total_points_won'] = 'Total Points Won';
lookup['distance_run'] = 'Distance Covered';
lookup['distance_per_pt'] = 'Distance Covered/Pt.';
lookup['first_serve_num'] = '1st Serve';
lookup['second_serve_num'] = '2nd Serve';
lookup['total'] = 'Total';
lookup['service_winners'] = 'Service Winners';
lookup['service_games'] = 'Service Games';
lookup['total_points'] = 'Total Points';
lookup['average_serve_speed'] = 'Average Serve Speed';
lookup['fastest_serve_speed'] = 'Fastest Serve Speed';
lookup['approach_shots'] = 'Approach Shots';
lookup['drop_shots'] = 'Drop Shots';
lookup['ground_strokes'] = 'Ground Strokes';
lookup['lobs'] = 'Lobs';
lookup['overhead_strokes'] = 'Overhead Strokes';
lookup['volleys'] = 'Volleys';
lookup['passing_shots'] = 'Passing Shots';
lookup['backhand'] = 'Backhand';
lookup['forehand'] = 'Forehand';
lookup['backhand_short'] = 'BH';
lookup['forehand_short'] = 'FH';
lookup['forced_errors'] = 'Forced Errors';

/* overview */
lookup['name'] = 'Name';
lookup['country'] = 'Country';
lookup['birthdate'] = 'Birth Date';
lookup['birthplace'] = 'Birth Place';
lookup['residence'] = 'Residence';
lookup['height'] = 'Height';
lookup['weight'] = 'Weight';
lookup['plays'] = 'Plays';
lookup['singles_ranking'] = 'Singles Ranking';
lookup['doubles_ranking'] = 'Doubles Ranking';
lookup['full_profile'] = 'Full Profile';
lookup['imperial'] = 'Imperial';
lookup['metric'] = 'Metric';

/* stage items */
lookup['performance_return_depth'] = 'A Look at Performance by Return Depth';
lookup['pct_returns'] = '% of Returns';
lookup['pct_winning'] = 'Winning %';
lookup['shallow'] = 'Shallow';
lookup['deep'] = 'Deep';

lookup['player_movement'] = 'Player Movement';
lookup['total_number_feet'] = 'Total number of feet';
lookup['feet_per_point'] = 'Feet per Point';
lookup['total_number_meters'] = 'Total number of meters';
lookup['meters_per_point'] = 'Meters per Point';
lookup['feet_point'] = 'Feet/ Point';
lookup['meters_point'] = 'Meters/ Point';
lookup['the_field'] = 'The Field';
lookup['seeds'] = 'Seeds';
lookup['total_feet'] = 'Total Feet';
lookup['total_meters'] = 'Total Meters';
lookup['all_others'] = 'All Others';
lookup['total_pts_stmt'] = 'total points have been played.';

lookup['pressure_situations'] = 'Pressure Situations';
lookup['downInGame_subheading'] = 'Down in the Game';
lookup['downXSets_subheading'] = 'Down in the Match';
lookup['upXBreaks_subheading'] = 'Break point Opportunity';
lookup['downXBreaks_subheading'] = 'Threat of a Break';
lookup['miniBreaks_subheading'] = 'Break point Opportunity';
lookup['field_text'] = 'The historical performance of the field when in this situation:';
lookup['field_top_5'] = 'Highly Seeded';
lookup['field_top_10'] = 'Lower Seeded';
lookup['field_all_others'] = 'Unseeded';

lookup['comparison_by_player'] = 'A Comparison By Player';
lookup['comparison_by_team'] = 'A Comparison By Team';
lookup['total_serves'] = 'Total Serves';
lookup['total_first_serves'] = 'Total First Serves';
lookup['total_second_serves'] = 'Total Second Serves';
lookup['total_rec_pts'] = 'Total Receiving Points';
lookup['net_points'] = 'Net Points';

lookup['total_aces'] = 'Total Aces';
lookup['pts_won_first_serve'] = 'Points Won on First Serve';
lookup['pct_winners_aces'] = '% of Aces as Winners';

lookup['fastest_serve'] = 'Fastest Serve';
lookup['last_serve'] = 'Last Serve';
lookup['mph'] = 'MPH';
lookup['kmh'] = 'KM/H';

lookup['kick_height'] = 'Kick Height';
lookup['target'] = 'Target';
lookup['current'] = 'Current';

/* messages */
lookup['depth_title'] = '{0} Depth of Return Through Set {1}';
lookup['serve_points_title'] = '{0} 1st Serve Points Won Through Set {1}';
lookup['downInGame_text'] = "{0}'s historical % of winning the game when losing {1}.";
lookup['downXSets_text'] = "{0}'s historical % of winning the match when losing {1}.";
lookup['upXBreaks_text'] = "{0}'s historical % of winning the game during a {1}break point opportunity.";
lookup['downXBreaks_text'] = "{0}'s historical % of winning the game when facing a {1}break point.";
lookup['miniBreaks_text'] = "{0}'s historical % of winning the game during a break point opportunity.";
lookup['first_pct_through_set'] = 'First Serve % In Through Set {0}';
lookup['through_set'] = 'Through Set {0}';

/* generic */
lookup['challenges'] = 'Challenges';

/* end 2018 lookups */

/* generic */
lookup['match'] = 'Match';
lookup['match_duration'] = 'Match Duration';
lookup['set'] = 'Set';
lookup['sets'] = 'Sets';
lookup['points'] = 'Points';

lookup['set1'] = 'Set 1';
lookup['set2'] = 'Set 2';
lookup['set3'] = 'Set 3';
lookup['set4'] = 'Set 4';
lookup['set5'] = 'Set 5';
lookup['view_matches_in_progress'] = 'Matches in Progress';
lookup['view_completed_matches'] = 'Completed Matches';
lookup['completed_matches'] = 'Completed Matches';
lookup['select_match_text'] = 'SELECT A MATCH TO VIEW DETAILED INFORMATION';
lookup['has_won'] = 'has won';
lookup['pct_of_sets'] = '% of sets when this key is met';
lookup['currently_leads'] = 'currently leads';
lookup['is_tied_with'] = 'is tied with';
lookup['by'] = 'by';
lookup['at'] = 'at';
lookup['vs'] = 'vs.';
lookup['defeated'] = 'defeated';
lookup['sets_all'] = 'sets all';
lookup['set_all'] = 'set all';
lookup['sets_to'] = 'sets to';
lookup['set_to'] = 'set to';
lookup['sets_to_love'] = 'sets to love';
lookup['set_to_love'] = 'set to love';
lookup['love_all'] = 'love all';
lookup['loading_slamtracker'] = 'Loading Slamtracker Data...';

/* tabs */
lookup['overview'] = 'Overview';
lookup['live'] = 'Live';
lookup['keys'] = 'Keys';
lookup['stats'] = 'Stats';
lookup['related'] = 'Related';

/* stats & stage */
lookup['head_2_head'] = 'Head 2 Head';
lookup['match_stats'] = 'Match Stats';
lookup['match_up'] = 'Match Up';
lookup['age'] = 'Age';

lookup['turned_pro'] = 'Turned Pro';
lookup['best_finish'] = 'Best Finish';
lookup['imperial'] = 'Imperial';
lookup['metric'] = 'Metric';
lookup['first_srv_pct'] = '1st Serve %';
lookup['break_pt_conv'] = 'Break Point Conversions';
lookup['net_approaches'] = 'Net Approaches';
lookup['serve_speeds'] = 'Serve Speeds';
lookup['average_first_srv_speed'] = 'Avg First Serve Speed';
lookup['average_second_srv_speed'] = 'Avg Second Serve Speed';
lookup['last_serve_speed'] = 'Last Serve Speed';
lookup['total_net_points'] = 'Total Net Points';
lookup['break_points'] = 'Break Points';
lookup['break_advantage'] = 'Break Advantage';
lookup['break_opportunities'] = 'Break Opportunities';
lookup['break_pt_opportunities'] = 'Break Point Opportunities';
lookup['conversion_perc'] = 'Conversion %';
lookup['pts_won_second_serve'] = 'Points Won on Second Serve';
lookup['pct_ad_court_pts_won'] = '% Advantage Court Points Won';
lookup['pct_deuce_court_pts_won'] = '% Deuce Court Points Won';
lookup['ad_court'] = 'Advantage Court';
lookup['deuce_court'] = 'Deuce Court';
lookup['deuce_crt_pts_won'] = 'Deuce Court Points Won';
lookup['ad_crt_pts_won'] = 'Advantage Court Points Won';
lookup['total_net_approaches'] = 'Total Net Approaches';
lookup['ball_tracking'] = 'Ball Tracking';
lookup['backhand_winner'] = 'Backhand Winners';
lookup['forehand_winner'] = 'Forehand Winners';
lookup['progress_against_keys'] = 'Progress Against Keys';
lookup['kttm_summary'] = 'Keys to the Match Summary';
lookup['match_stats_summary'] = 'Match Stats Summary';
lookup['key_summary_text'] =
	"IBM Keys to the Match identifies key performance indicators—what players need to do to succeed in a match. Each player's performance is measured against their keys and updated in real time. For more information, visit the Keys to the Match section.";
lookup['keys_to_the_match'] = 'Keys to the Match';
lookup['now_on_court'] = 'Now on Court';
lookup['singles_rank'] = 'Singles<br />Rank';
lookup['doubles_rank'] = 'Doubles<br />Rank';
lookup['seed'] = 'Seed';
lookup['depth_of_return'] = 'Depth of Return';
lookup['depth_of_return_desc'] = 'A Look at Performance by Return Depth';
lookup['balltracking_title'] = 'Total Points Won in Each Zone';
lookup['tiebreak'] = 'Tiebreak';
lookup['distance_ran'] = 'Distance Ran';
lookup['match_summary'] = 'Match Summary';
lookup['first_serve'] = 'First Serve';
lookup['second_serve'] = 'Second Serve';
lookup['overall_stats'] = 'Overall Stats';
lookup['serve_stats'] = 'Serve Stats';
lookup['return_stats'] = 'Return Stats';
lookup['rally_stats'] = 'Rally Stats';
lookup['dir_serve_stats'] = 'Dir. Serve Stats';
lookup['choose_statistics'] = 'Choose Statistics';
lookup['deuce'] = 'Deuce';
lookup['advantage'] = 'Advantage';
lookup['service_points_won'] = 'Total Service Points Won';
lookup['total_serves_in'] = 'Total Serves In';
lookup['return_winners'] = 'Return Winners';
lookup['return_unforced_errors'] = 'Return Unforced Errors';
lookup['return_points'] = 'Return Points';
lookup['return_points_won'] = 'Return Points Won';
lookup['rally_count_return_depth'] = 'Average Rally Count by Return Depth';
lookup['avg_rally_count'] = 'Average Rally Count';
lookup['shallow_returns'] = 'Shallow Returns';
lookup['deep_returns'] = 'Deep Returns';
lookup['strokes_per_rally'] = 'Strokes per Rally';
lookup['total_service_points_won'] = 'Total Service Points Won';
lookup['chance_of_winning'] = 'Chance of Winning the Set When Key is Met';
lookup['needs_to'] = 'needs to';
lookup['back_to_top'] = 'Back to Top';
lookup['brk_pts_opps'] = 'Break Point Opps';
lookup['brk_pts_faced'] = 'Break Points Faced';
lookup['brk_pts_lost'] = 'Break Points Lost';
lookup['srv_games_played'] = 'Service Games Played';

/* social sentiment */
lookup['ibm_social_sentiment'] = 'IBM Social Sentiment';
lookup['social_sentiment_desc'] = 'The percentage of positive fan sentiment on Twitter.';
lookup['positive_sentiment'] = 'Positive Sentiment';
lookup['tweets'] = 'Tweets';
lookup['total_tweets'] = 'Total Tweets';
lookup['match_tweets'] = 'Match Tweets';
lookup['hashtags'] = 'Hashtags';
lookup['total_tweets_hashtags'] = 'Total Tweets &amp; Hashtags';

/* point history timeline */
lookup['tl_summary_playersWarmingUp'] = 'Players warming up.';
lookup['tl_summary_wins'] = 'wins';
lookup['tl_summary_loses'] = 'loses';
lookup['tl_summary_theBreak'] = 'the break';
lookup['tl_summary_thePoint'] = 'the point';
lookup['tl_summary_theGame'] = 'the game';
lookup['tl_summary_theSet'] = 'the set';
lookup['tl_summary_theMatch'] = 'the match';
lookup['tl_summary_withAForehand'] = 'with a forehand';
lookup['tl_summary_withABackhand'] = 'with a backhand';
lookup['tl_summary_withAnAce'] = 'with an ace';
lookup['tl_summary_withAServiceWinner'] = 'with a service winner';
lookup['tl_summary_withADoubleFault'] = 'with a double fault';
lookup['tl_summary_winner'] = 'winner';
lookup['tl_summary_unforcedError'] = 'unforced error';
lookup['tl_summary_forcedError'] = 'forced error';
lookup['tl_summary_withAPointPenalty'] = 'with a penalty point';

lookup['tl_ptype_forehand'] = 'Forehand';
lookup['tl_ptype_backhand'] = 'Backhand';
lookup['tl_ptype_ace'] = 'Ace';
lookup['tl_ptype_serviceWinner'] = 'Service Winner';
lookup['tl_ptype_doubleFault'] = 'Double Fault';
lookup['tl_ptype_volley'] = 'Volley';
lookup['tl_ptype_smash'] = 'Smash';
lookup['tl_ptype_winner'] = 'Winner';
lookup['tl_ptype_unforcedError'] = 'Unforced Error';
lookup['tl_ptype_forcedError'] = 'Forced Error';
lookup['tl_ptype_pointPenalty'] = 'Point Penalty';
lookup['tl_ptype_breakPointWon'] = 'Break Point Won';
lookup['tl_ptype_turningPoint'] = 'Turning Point';
lookup['tl_ptype_breakPoint'] = 'Break Point';
lookup['tl_ptype_netPoint'] = 'Net Point';
lookup['tl_summary_finish'] = 'FINISH';

lookup['tl_label_server'] = 'SERVER';
lookup['tl_label_serveSpeed'] = 'SERVE SPEED';
lookup['tl_label_set'] = 'SET';
lookup['tl_label_game'] = 'GAME';
lookup['tl_label_point'] = 'POINT';
lookup['tl_label_match'] = 'MATCH';
lookup['tl_label_matchSummary'] = 'Match Summary';
lookup['tl_label_setSummary'] = 'Set Summary';
lookup['tl_label_gameSummary'] = 'Game Summary';
lookup['tl_label_keysSummary'] = 'Keys Summary';

lookup['tl_resume_live_feed'] = 'Resume Live Feed';

// match_stats
lookup['match_stats'] = 'Match Statistics';

/* messages */
lookup['slamtracker_load'] = 'Please wait while we load slamtracker data';
lookup['keys_unavailable'] = 'Keys to the Match not available for this match';
lookup['live_unavailable'] = 'Live Commentary not available for this match';
lookup['stats_unavailable'] = 'Match Statistics not available for this match';
lookup['related_unavailable'] = 'Related Content not available for this match';
lookup['data_unavailable'] = 'This data is unavailable for this match';
lookup['social_update'] = 'Social Sentiment Will Update Every 10 Minutes';
lookup['bt_summary'] = "{0}'s First Serve Placement through Set {1}";
lookup['psLoadingSet'] = 'LOADING POINT SUMMARY DATA FOR THIS SET';
lookup['psUnavailableSet'] = 'POINT SUMMARY DATA IS UNAVAILABLE FOR THIS SET';
lookup['tl_game_transition'] = '{0} is serving <span class="winning">Game {1}.</span>';
lookup['tl_tiebreak_transition'] = '{0} is serving.';
lookup['distance_total_points'] = 'A total of {0} points have been played.';

lookup['stage_data_unavailable'] = 'Enhanced data is unavailable.';

var month_lookup = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export default {
	lookup,
};
