/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: HeroTriptych
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const HeroTriptych = props => {
	logger.log('[HeroTriptych] - props:%o', props);

	// let attributes = props['attributes'][props.id];
	let attributes = props.data;
	//logger.log('[HeroTriptych] - attr:%o', attributes);

	if (attributes && attributes.items) {
		let items = attributes['items'];

		return (
			<section className={`hero_container`}>
				<div className={`hero_content`}>
					<div className={`title`}>
						<h1>{attributes['title']}</h1>
					</div>

					{attributes['items'].item.map((item, i) => (
						<div className={`hero_item`} key={i}>
							<EventsLink to={`${attributes['items'].item[i].link}`}>
								<div className="hero_link">
									<div className="text">{attributes['items'].item[i].title}</div>
									<input
										type="button"
										value={attributes['items'].item[i].button}
										className="uso-button transFill"
									/>
								</div>
								<div className="hero_image">
									<img
										alt={attributes['items'].item[i].title}
										src={attributes['items'].item[i].images.image.url._}
									/>
								</div>
							</EventsLink>
						</div>
					))}
				</div>
			</section>
		);
	} else if (attributes) {
		return <h1>{attributes['title']}</h1>;
	} else {
		return null;
	}
};

export default HeroTriptych;
