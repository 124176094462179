/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { values } from 'appdir/main';
import axios from 'axios';
import { fetch, fetchAll, scrollIntoViewWithOffset } from 'components/general/Util';
import EventsLink from 'components/general/EventsLink';

import deps from 'dependencies';

import MeasurementUtils from 'appdir/lib/analytics';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';

// components
import DrawsLists from 'appdir/components/_scoring/DrawsLists';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from '../../Template';
import TemplateGeneric from '../../TemplateGeneric';

import PageSubMenu from 'appdir/components/common-ui/PageSubMenu';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';

const queryString = require('querystring-browser');
import op from 'object-path';
import cn from 'classnames';

import { getEventName, getSponsorConfig, getPlayersData, getCurrentRound, getRoute } from './DrawsUtils';

/**
 * -----------------------------------------------------------------------------
 * React Component: DrawsPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['DrawsPage'],
		configWeb_status: state['Config']?.status,
		configWeb_sponsors: state['Config']?.sponsors?.draws,
		configWeb_stubs: state['Config'].stubPages,
		configWeb_otherData: state['Config'].otherData,
		scoringConfig: state['Config']?.scoringConfig,
		scoringData: state['Config']?.scoringData,
		tournamentYear: state['Config']?.scoring?.tournamentYear,
		enabled: state['Config']?.enabled,
		drawData: op.get(state['Tournament'], 'data.draw', null),
		favorites: state['Controller']?.favorites,
		Router: state['Router'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.DrawsPage.mount()),
	unmount: data => dispatch(deps.actions.DrawsPage.unmount(data)),
	filter: data => dispatch(deps.actions.DrawsPage.filter(data)),
	setRound: data => dispatch(deps.actions.DrawsPage.setRound(data)),
	update: data => dispatch(deps.actions.DrawsPage.update(data)),
	getDraws: event => dispatch(deps.actions.Tournament.getDraws({ event: event })),
	clearDraws: () => dispatch(deps.actions.Tournament.clearDraws()),
});
class DrawsPage extends Component {
	constructor(props) {
		super(props);

		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);

		this.state = {
			playersPlaying: [],
			playersSet: {},
			drawsEventData: null, // data loaded from draws event json file
			drawsListData: null, // data that should be displayed
			eventLoading: false,
			width: '1260px',
			height: '5830px',
			x: 0,
			y: 0,
		};

		this.drawsPageRef = React.createRef();

		this.scrollingRef = React.createRef();
		this.init = true;

		this.firstLoad = true;
		this.eventMeasured = false;
		this.drawsDataLoaded = false;
		this.eventDataLoaded = false;
		this.playerDataLoaded = false;
		this.roundsRetrieved = false;
		this.columnsDataRetrieved = false;
		this.displayRounds = [];
		this.displayLabels = [];
		this.totalRounds = 0;
		this.matchID = '';
		this.eventWon = false;
		this.year = values.tournamentYear;
		// this.onScoll = this.onScroll.bind(this);
		// this.wheel = this.onWheel.bind(this);
		// this.scrollCounter = 0;

		// logger.log('[DrawsPage] constructor this:%o', this);
	}

	componentDidMount() {
		// logger.log('[DrawsPage] componentDidMount this:%o', this);
		// commententing out ... don't think it is needed anymore
		// window.addEventListener('scroll', throttle(this.onScroll.bind(this), 100, { trailing: true }), false);
		// window.addEventListener('wheel', throttle(this.onWheel.bind(this), 100, { trailing: true }), false);
		// window.addEventListener('touchmove', throttle(this.onWheel.bind(this), 100, { trailing: true }), false);
		//window.scrollTo(0,0);
	}

	componentWillUnmount() {
		// logger.log('[DrawsPage] componentWillUnmount this:%o', this);

		this.eventMeasured = false;
		this.eventDataLoaded = false;
		this.playerDataLoaded = false;

		this.props.filter({
			event: '',
			open: '',
		});

		this.props.unmount({
			drawsPath: '',
			mountStatus: null,
		});

		this.props.clearDraws();

		//removing player highlight when unmounting component
		// this.props.search({
		// 	searchedPlayer: '',
		// });
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[DrawsPage] componentDidUpdate 1 this:%o', this);

		/** if draws page Ref is available, set an eventListner to detect
		 *  bracket view's container is horizontally scrolled
		 */
		if (this.init && this.scrollingRef?.current) {
			this.init = false;

			this.scrollingRef?.current.addEventListener(
				'scroll',
				throttle(this.positionChange.bind(this), 500, { trailing: true }),
				false
			);
		}

		/** load draws data which includes urls for the draws feeds **/
		if (this.props.mountStatus == 'ready' && !this.state.drawsData && !this.drawsDataLoaded) {
			this.drawsDataLoaded = true;

			fetch(this?.props?.scoringData?.draws)
				.then(result => {
					// logger.log('[DrawsPage] componentDidUpdate result:%o', result);

					this.setState({
						drawsData: result?.draws?.length > 0 ? result : 'notfound',
					});
				})
				.catch(error => {
					logger.error('[DrawsPage] componentDidUpdate error loading draws data:%o', error);
					this.setState({
						drawsData: 'notfound',
					});
				});
		}

		/**
		 * selectedIndex or selectedRound has changed, set
		 * variables to reload page
		 **/
		if (
			this.props.eventRound.selectedRound !== op.get(prevProps, 'eventRound.selectedRound', 0) ||
			this.props.view !== prevProps.view
		) {
			this.roundsRetrieved = false;
			this.columnsDataRetrieved = false;
			// logger.log('[DrawsPage] componentDidUpdate selectedIndex OR selectedRound changed:%o', this);
			if (this.state.searchedPlayer) {
				this.setState(
					{
						searchedPlayer: null,
					},
					() => {
						this.setState(
							{
								searchedPlayer: prevState.searchedPlayer,
							},
							() => {
								this.onFavoriteClicked(this.state.searchedPlayer);
							}
						);
					}
				);
			}
		}

		if (
			prevProps.filters.event !== this.props.filters.event &&
			prevProps.filters.event !== '' /* !== this.props.filters.event*/
		) {
			logger.log(
				'[DrawsPage] componentDidUpdate event has changed from %o to %o',
				prevProps.filters.event,
				this.props.filters.event
			);
			this.onEventChange();
			this.setState({
				eventLoading: true,
				drawsEventData: null,
				playersPlaying: [],
			});
		}

		/** if new event and not yet measured, make pageview measurement call **/
		if (prevProps?.filters.event !== this.props.filters.event && !this.eventMeasured) {
			this.eventMeasured = true;
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: getEventName(this.props.filters.event, this?.props?.scoringConfig?.eventNames),
				webview: window.webview ? true : false,
			});
		}

		/**
		 * draws data has been loaded, there is no selected event or event
		 * has changed - load the draws data for the new event
		 **/
		if (
			this.state.drawsData &&
			this.state.drawsData !== 'notfound' &&
			//((this.props.filters.event !== this.props.filters.event && !this.state.drawsEventData) || (prevProps.filters.event !== this.props.filters.event && prevProps.filters.event !== '' && this.state.drawsEventData)) &&
			(!this.state.drawsEventData ||
				(prevProps.filters.event !== this.props.filters.event &&
					prevProps.filters.event !== '' &&
					this.state.drawsEventData)) &&
			!this.eventDataLoaded
		) {
			// logger.log(
			// 	'[DrawsPage] componentDidUpdate 3 loading event data: this.props.filters.event:%o',
			// 	this.props.filters.event
			// );
			this.eventDataLoaded = true;
			let drawId = this.props.filters.event;
			let { draws } = this.state.drawsData;
			let drawToLoad = draws.filter(draw => {
				return draw.id == this.props.filters.event;
			})[0];
			let showStub = true;
			this.eventWon = false;
			let roundVal = this.props.eventRound.pageReturn === true ? this.props.eventRound.selectedRound : 0;

			logger.log(
				'[DrawsPage] componentDidUpdate 3 new event: this.props.filters.event:%o, draws:%o, drawToLoad:%o',
				this.props.filters.event,
				draws,
				drawToLoad
			);

			let dataToUpdate = {
				eventRound: {
					selectedRound: roundVal,
					selectedIndex: '',
					pageReturn: false,
				},
			};

			if (drawToLoad) {
				showStub = false;

				/** load draws from Tournament Data for MS or WS and bracket view */
				if ((drawToLoad?.id == 'MS' || drawToLoad?.id == 'WS') && this?.props?.enabled?.drawAnalysis) {
					this.props.getDraws(drawToLoad.id);
				}

				fetchAll([drawToLoad.feed_url, this?.props?.scoringData?.liveScore?.path])
					.then(
						axios.spread((draws_result, scores_result) => {
							// logger.log('[DrawsPage] componentDidUpdate draws_result:%o, scores_result:%o', draws_result, scores_result);

							/**
							 * If there are live scores merge the data from the live
							 * matches with the data from the draws match.  The draws matches
							 * need the status and scores from the live matches
							 **/
							let merged_data = Object.assign([]);
							let dataToCheck = draws_result.matches;
							if (dataToCheck.length > 0 && scores_result.matches.length > 0) {
								dataToCheck.map(dmatch => {
									const found = scores_result.matches.find(
										match => match.match_id === dmatch.match_id
									);

									if (found) {
										let merged = Object.assign({}, dmatch);
										merged.status = found.status;
										merged.scores = found.scores;
										merged.statusCode = found.statusCode;
										merged_data.push(merged);
									} else {
										merged_data.push(dmatch);
									}
								});
							} else {
								merged_data = dataToCheck;
							}

							/** set merged data results into draws_result.matches */
							draws_result.matches = merged_data;

							// logger.log('[DrawsPage] componentDidUpdate draws_result:%o', draws_result);

							this.setState(
								{
									showStub: showStub,
									drawsEventData: draws_result,
									eventLoading: false,
									currentEvent: drawId,
								},
								() => {
									// set the current round for this new event
									dataToUpdate.eventRound.selectedRound = getCurrentRound(draws_result.matches);
									this.updateInfo(dataToUpdate);
								}
							);
						})
					)
					.catch(error => {
						// logger.error('[DrawsPage] componentDidUpdate error fetching draws data:%o', error);
						this.setState(
							{
								showStub: showStub,
								drawsEventData: 'notfound',
								eventLoading: false,
							},
							() => {
								this.updateInfo(dataToUpdate);
							}
						);
					});
			} else {
				this.setState(
					{
						showStub: showStub,
						drawsEventData: 'notfound',
						eventLoading: false,
					},
					() => {
						// logger.log(
						// 	'[DrawsPage] componentDidUpdate 3 new event: this.props.filters.event:%o, state:%o',
						// 	this.props.filters.event,
						// 	this.state
						// );
						this.updateInfo(dataToUpdate);
					}
				);
			}
		}

		/**
		 * if we have draws event data and have not gotten player data,
		 * call function to get player data
		 */
		if (
			this.state.drawsEventData &&
			this.props.filters.event == this.state.currentEvent &&
			this.state.playersPlaying.length == 0 &&
			!this.playerDataLoaded
		) {
			// logger.log('[DrawsPage] componentDidUpdate loading player data');
			this.playerDataLoaded = true;
			let playerData = {};
			let { drawsEventData } = this.state;

			playerData = getPlayersData(drawsEventData, this.props.filters.event, drawsEventData.totalRounds);
			// logger.log('playerdata', playerData);
			this.setState({
				playersPlaying: playerData.playersPlaying,
				playersSet: playerData.playersSet,
			});
		}

		/**
		 * only do these actions if we have already loaded
		 * playersPlaying and PlayersSet into state
		 */
		if (
			this.state.drawsData &&
			this.state.drawsData !== 'notfound' &&
			this.state.drawsEventData &&
			this.state.playersPlaying.length > 0
		) {
			// logger.log('[DrawsPage] componentDidUpdate - data and players are in state - this:%o', this);
			// logger.log(
			// 	'[DrawsPage] componentDidUpdate - data and players are in state - prevDrawsEventData:%o',
			// 	op.get(prevState, 'drawsEventData', null)
			// );
			// logger.log(
			// 	'[DrawsPage] componentDidUpdate - data and players are in state - prevSelectedRound:%o',
			// 	op.get(prevProps, 'eventRound.selectedRound', 0)
			// );
			// logger.log(
			// 	'[DrawsPage] componentDidUpdate - data and players are in state - prevSelectedIndex:%o',
			// 	op.get(prevProps, 'eventRound.selectedIndex', '')
			// );

			/**
			 * Get current three rounds for display
			 *
			 * - drawsEventData has changed in state
			 * or
			 * - drawsEventData exists
			 * and
			 * - selectedRound is 0 and this is the first load
			 * or
			 * - the selectedRound is not 0 and has changed in state
			 * or
			 * - selectedIndex has changed in state and is not an empty string
			 * **/
			if (
				(!this.roundsRetrieved && !isEqual(this.state.drawsEventData, prevState.drawsEventData)) ||
				(this.state.drawsEventData &&
					((this.props.eventRound.selectedRound == 0 && this.firstLoad) ||
					!isEqual(this?.props?.eventRound?.selectedRound, prevProps?.eventRound?.selectedRound) || //&&
						//op.get(prevProps, 'eventRound.selectedRound', 0) !== '0'
						!isEqual(this.props.eventRound.selectedIndex, prevProps?.eventRound?.selectedIndex))) //&&
				//op.get(prevProps, 'eventRound.selectedIndex', '') !== ''
			) {
				// logger.log(
				// 	'[DrawsPage] componentDidUpdate - calling roundsToDisplay prevState:%o, this:%o',
				// 	prevState,
				// 	this
				// );
				this.roundsRetrieved = true;
				this.firstLoad = false;
				this.roundsToDisplay(this.state.drawsEventData);
			}

			/**
			 * Get the rounds column data for display
			 *
			 * - drawsEventData exists in state
			 * and
			 * - display rounds data exists
			 * and
			 * - selectedRound is not 0
			 * and
			 * - selectedRound has changed in state
			 * or
			 * - selectedIndex in state and is not an empty string
			 */
			if (
				!this.columnsDataRetrieved &&
				this.state.drawsEventData && //this happens in componentDidUpdate
				this.displayRounds.length > 0 && //this happens in roundsToDisplay
				this.props.eventRound.selectedRound !== 0 && //this happens in roundsToDisplay
				(this.props.eventRound.selectedRound !== op.get(prevProps, 'eventRound.selectedRound', 0) ||
					this.props.eventRound.selectedIndex !== op.get(prevProps, 'eventRound.selectedIndex', '')) //&&
				//op.get(prevProps, 'eventRound.selectedIndex', '') !== ''
			) {
				// logger.log(
				// 	'[DrawsPage] componentDidUpdate - calling getRoundColumnsData prevState:%o, this:%o',
				// 	prevState,
				// 	this
				// );
				this.columnsDataRetrieved = true;
				this.getRoundColumnsData(this.state.drawsEventData);
			}
		}
	}

	/**
	 * when event changes set local vars to false
	 * so data will be loaded for new event
	 **/
	onEventChange() {
		// logger.log('[DrawsPage] onEventChange: this:%o', this);
		this.eventMeasured = false;
		this.eventDataLoaded = false;
		this.playerDataLoaded = false;
		this.roundsRetrieved = false;
		this.columnsDataRetrieved = false;
	}

	updateInfo(data) {
		this.props.update(data);
	}

	/** just extract necessary information for search within the selected event */
	getAvailablePlayers = data => {
		let playerArry = [];
		let playersPlaying;

		data.matches.forEach(d1 => {
			let team1_player1a = null,
				team1_player1b = null;
			team1_player1a = {
				firstName: d1.team1.firstNameA,
				lastName: d1.team1.lastNameA,
				id: d1.team1.idA,
			};
			team1_player1b = {
				firstName: d1.team1.firstNameB,
				lastName: d1.team1.lastNameB,
				id: d1.team1.idB,
			};
			playerArry.push(team1_player1a, team1_player1b);

			let team2_player1a = null,
				team2_player1b = null;
			team2_player1a = {
				firstName: d1.team2.firstNameA,
				lastName: d1.team2.lastNameA,
				id: d1.team2.idA,
			};
			team2_player1b = {
				firstName: d1.team2.firstNameB,
				lastName: d1.team2.lastNameB,
				id: d1.team2.idB,
			};
			playerArry.push(team2_player1a, team2_player1b);
		});

		playersPlaying = playerArry.filter(d => d.id !== null);

		return playersPlaying;
	};

	getStubMessage(event) {
		let drawsStub = this.props?.configWeb_stubs?.draws;

		// get cms to add "default" to stubs_web.json
		let message = this.props?.configWeb_stubs?.draws?.default;
		//"<p>The 2021 US Open Qualifying Tournament draws will be available on Monday, Aug. 23.</p><p>The 2021 US Open men's and women's singles draws will be revealed on Thursday, Aug. 26.</p>";

		//logger.log('[DrawsPage] getStubMessage draws_stubs:%o', drawsStub);
		forEach(drawsStub, function(value, key) {
			//logger.log('[DrawsPage] getStubMessage key:%o, value:%o', key, value);
			if (key == event) {
				message = value;
			}
		});

		return message;
	}

	roundsToDisplay(data) {
		var startRound = 0;
		var endRound = 0;
		let displayRounds = [];
		let displayLabels = [];
		let currentRound = this.props.view !== 'bracket' ? this?.props?.eventRound?.selectedRound : 1;
		let matches = data.matches;
		let matchID = '';
		this.totalRounds = data.totalRounds;

		// First time displaying an event, get most current round for default display
		if (currentRound == 0) {
			for (var i = 0; i < matches.length; i++) {
				// Get the round number from the match id
				let winnerStatus = matches[i].winner;
				if (winnerStatus == null) {
					currentRound = this.props.view == 'round' ? Number(matches[i].match_id.slice(-3, -2)) : 1;
					matchID = matches[i].match_id.slice(-3);
					break;
				} else if (matches[i].roundCode == 'F' && winnerStatus !== null) {
					currentRound = this.props.view == 'round' ? Number(matches[i].match_id.slice(-3, -2)) : 1;
					matchID = matches[i].match_id.slice(-3);
					this.eventWon = true;
					break;
				} else if (i == matches.length - 1 && winnerStatus !== null) {
					currentRound = this.props.view == 'round' ? Number(matches[i].match_id.slice(-3, -2)) : 1;
					matchID = matches[i].match_id.slice(-3);
					break;
				}
			}
		}

		// Set the three rounds to be displayed based on currentRound
		// Set the start and end indexes for loop based on currentRound and totalRounds
		if (this.totalRounds <= 3 || this.props.view == 'bracket') {
			startRound = currentRound;
			endRound = this.totalRounds;
		} else {
			startRound = currentRound;
			endRound = currentRound + 2;
			if (endRound > this.totalRounds) {
				endRound = this.totalRounds;
			}
		}

		var counter = 0;
		for (var i = startRound; i < endRound + 1; i++) {
			displayRounds.push((startRound + counter).toString());
			counter++;
		}

		// Get round labels for the display round
		var counter = 0;
		for (var i = 0; i < matches.length; i++) {
			if (matches[i].match_id.slice(-3, -2) == displayRounds[counter]) {
				displayLabels.push(matches[i].roundName);
				counter++;
			}
		}

		this.props.setRound({
			selectedRound: currentRound,
		});

		// Check if event has been won and it is not a qualification event
		// If so shift the displayRounds array to add a duplicate final round to show a winner column
		if (
			this.props.filters.event !== 'MQ' &&
			this.props.filters.event !== 'WQ' &&
			this.props.filters.event !== 'BQ' &&
			this.props.filters.event !== 'GQ'
		) {
			if (this.eventWon && currentRound == this.totalRounds) {
				let lastRound = displayRounds[0];
				displayRounds.push(lastRound);
			}
		}

		this.displayRounds = displayRounds;
		this.displayLabels = displayLabels;
		this.matchID = matchID;

		//return this.displayRounds;
	}

	completeColumnData(columnData) {
		let selectedRound = this.props.eventRound.selectedRound;
		let selectedEvent = this.props.filters.event;
		let lastColumnKey = Object.keys(columnData)[Object.keys(columnData).length - 1];
		let prevColumnKey = Object.keys(columnData)[Object.keys(columnData).length - 2];

		// logger.log(
		// 	'[DrawsPage] completeColumnData columnData:%o, selectedRound:%o, selectedEvent:%o, this.eventWon:%o, this.totalRounds:%o',
		// 	columnData,
		// 	selectedRound,
		// 	selectedEvent,
		// 	this.eventWon,
		// 	this.totalRounds
		// );

		if (this.eventWon && selectedRound == this.totalRounds - 1) {
			let columnWinData = columnData;

			// if (selectedEvent === 'SQ') {
			// 	columnWinData['columnC'] = JSON.parse(JSON.stringify(columnData['columnB']));
			// 	columnWinData['columnC'].matches.pop();
			// } else {
			columnWinData[lastColumnKey] = JSON.parse(JSON.stringify(columnData[prevColumnKey]));
			// }
			columnWinData[lastColumnKey].title = 'Winner';
			columnWinData[lastColumnKey].matches[0].roundCode = 'W';

			// logger.log('[DrawsPage] completeColumnData adding winner header 1 columnWinData:%o', columnWinData);

			this.setState({
				drawsListData: columnWinData,
			});
		} else if (this.eventWon && selectedRound == this.totalRounds) {
			let columnWinData = JSON.parse(JSON.stringify(columnData));

			// if (selectedEvent === 'SQ') {
			// 	columnWinData['columnB'].matches.pop();
			// }

			// logger.log('[DrawsPage] completeColumnData adding winner header 2 columnWinData:%o', columnWinData);

			columnWinData[prevColumnKey].title = 'Winner';

			columnWinData[prevColumnKey].matches[0].roundCode = 'W';

			// logger.log('[DrawsPage] completeColumnData adding winner header 2 columnWinData:%o', columnWinData);

			this.setState({
				drawsListData: columnWinData,
			});
		} else {
			// logger.log('[DrawsPage] completeColumnData no winner header 3 columnData:%o', columnData);
			this.setState({
				drawsListData: columnData,
			});
		}
	}

	getRoundColumnsData(data) {
		// logger.log('[DrawsPage] getRoundColumnsData this:%o,', this);

		let columnList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
		let columnData = {};

		if (this.props.view == 'bracket') {
			for (let i = 0; i < data.totalRounds; i++) {
				columnData[`column${columnList[i]}`] = { title: '', matches: [] };
			}
		} else {
			columnData = {
				columnA: { title: '', matches: [] },
				columnB: { title: '', matches: [] },
				columnC: { title: '', matches: [] },
			};
		}
		let selectedRound = this.props.eventRound.selectedRound;
		let displayRounds = this.displayRounds;
		let displayLabels = this.displayLabels;
		let eventMatches = data.matches;
		let drawSize = data.drawSize;
		let matchNotPlayed = this.matchID;
		let bracketPosition = '';
		let selectedIndex = op.get(this.props, 'eventRound.selectedIndex', '');
		let roundTemp = 0;

		// logger.log('[DrawsPage] getRoundColumnsData displayRounds:%o', displayRounds);
		// logger.log('[DrawsPage] getRoundColumnsData matchNotPlayed:%o', matchNotPlayed);

		Object.keys(columnData).forEach(function(key) {
			let displayMatches = [];

			/* create only */

			/** If there are only one or two rounds in display matches, break out of loop **/
			if ((displayRounds.length == 2 && roundTemp == 2) || (displayRounds.length == 1 && roundTemp == 1)) {
				return;
			}

			/** Set column title **/
			columnData[key].title = displayLabels[roundTemp];

			/** Get all events with matching round id **/
			if (displayRounds.length > 0) {
				displayMatches = eventMatches.filter(match => {
					if (match.match_id.slice(-3, -2) == displayRounds[roundTemp].toString()) {
						// logger.log('[DrawsPage] getRoundColumnsData match:%o,', match.match_id);
						return match.match_id.slice(-3, -2) == displayRounds[roundTemp].toString();
					}
				});
			}

			// logger.log(
			// 	'[DrawsPage] getRoundColumnsData key:%o displayMatches:%o, displayMatches.length:%o',
			// 	key,
			// 	displayMatches,
			// 	displayMatches.length
			// );

			let drawIndex = 0;
			let matchesSize = displayMatches.length;
			let breakPoint = matchesSize / 2;

			if (selectedIndex === '') {
				/** Get index of match not played to set bracket position **/
				drawIndex = findIndex(displayMatches, function(match) {
					return match.match_id.slice(-3) === matchNotPlayed;
				});

				if (drawIndex < 0) {
					drawIndex = 0;
				}

				// if (displayMatches.length >= 32) {
				// 	if (!bracketPosition) {
				// 		if (drawIndex < breakPoint) {
				// 			bracketPosition = 'top';
				// 		} else {
				// 			bracketPosition = 'bottom';
				// 		}
				// 	}
				// }

				// logger.log(
				// 	'[DrawsPage] getRoundColumnsData displayMatches.length:%o, drawIndex:%o breakPoint:%o, bracketPosition:%o',
				// 	displayMatches.length,
				// 	drawIndex,
				// 	breakPoint,
				// 	bracketPosition
				// );
			}

			// if (drawSize == '128' || drawSize == '96' || drawSize == '64') {
			// 	if (selectedIndex.indexOf('top') > -1 && matchesSize > 7) {
			// 		displayMatches.splice(breakPoint, matchesSize);
			// 	}
			// 	if (selectedIndex.indexOf('bottom') > -1 && matchesSize > 7) {
			// 		displayMatches.splice(0, breakPoint);
			// 	}
			// }

			columnData[key].matches = displayMatches;
			roundTemp++;
		});

		// logger.log('[DrawsPage] getRoundColumnsData columnData:%o', columnData);

		if (selectedIndex === '') {
			selectedIndex = selectedRound.toString() + bracketPosition;

			// logger.log('[DrawsPage] getRoundColumnsData selectedIndex:%o', selectedIndex);

			this.props.update({
				eventRound: {
					selectedIndex: selectedIndex,
				},
			});
		}

		this.completeColumnData(columnData);
	}

	onPdfSelect() {
		let path = '/en_US/scores/draws/' + this?.props?.tournamentYear + '_' + this.props.filters.event + '_draw.pdf';
		//measureApp('Draws', 'Print', path );
		window.open(path, '_blank');
	}

	/**
	 *
	 * @param {*} value
	 */
	onFavoriteClicked = (value) => {
		let searchedPlayer = value;

		const sectionA = document.querySelector(`.columnA .${searchedPlayer?.id}`);
		const sectionB = document.querySelector(`.columnB .${searchedPlayer?.id}`);
		const sectionC = document.querySelector(`.columnC .${searchedPlayer?.id}`);
		const sectionD = document.querySelector(`.columnD .${searchedPlayer?.id}`);
		const sectionE = document.querySelector(`.columnE .${searchedPlayer?.id}`);
		const sectionF = document.querySelector(`.columnF .${searchedPlayer?.id}`);
		const sectionG = document.querySelector(`.columnG .${searchedPlayer?.id}`);

		const stickyMenu = document.querySelector(`#uso-header.sticky`);
		const stickyFavorites = document.querySelector(`.favorites-details.sticky`);
		let offset = stickyFavorites ? 110 : stickyMenu ? 175 : 210;

		if (this.props.view == 'bracket') {
			offset += 15;
		}

		// logger.log('[DrawsPage] onFavoriteClicked - value:%o, aiDrawAutoInitScroll:%o, offset:%o', value, aiDrawAutoInitScroll, offset);

		//section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
		if (!searchedPlayer) {
			this.setState({ searchedPlayer: null });
		} else if (sectionG) {
			this.setState({ searchedPlayer, invalidPlayers: { [searchedPlayer.id]: false } }, () => {
				scrollIntoViewWithOffset(`.columnG .${searchedPlayer?.id}`, offset);
			});
		} else if (sectionF) {
			this.setState({ searchedPlayer, invalidPlayers: { [searchedPlayer.id]: false } }, () => {
				scrollIntoViewWithOffset(`.columnF .${searchedPlayer?.id}`, offset);
			});
		} else if (sectionE) {
			this.setState({ searchedPlayer, invalidPlayers: { [searchedPlayer.id]: false } }, () => {
				scrollIntoViewWithOffset(`.columnE .${searchedPlayer?.id}`, offset);
			});
		} else if (sectionD) {
			this.setState({ searchedPlayer, invalidPlayers: { [searchedPlayer.id]: false } }, () => {
				scrollIntoViewWithOffset(`.columnD .${searchedPlayer?.id}`, offset);
			});
		} else if (sectionC) {
			this.setState({ searchedPlayer, invalidPlayers: { [searchedPlayer.id]: false } }, () => {
				scrollIntoViewWithOffset(`.columnC .${searchedPlayer?.id}`, offset);
			});
		} else if (sectionB) {
			this.setState({ searchedPlayer, invalidPlayers: { [searchedPlayer.id]: false } }, () => {
				scrollIntoViewWithOffset(`.columnB .${searchedPlayer?.id}`, offset);
			});
		} else if (sectionA) {
			this.setState({ searchedPlayer, invalidPlayers: { [searchedPlayer.id]: false } }, () => {
				scrollIntoViewWithOffset(`.columnA .${searchedPlayer?.id}`, offset);
			});
		} else {
			this.setState({ searchedPlayer, invalidPlayers: { [searchedPlayer.id]: true } });
		}
	};

	onOpenDropdown(which) {
		// logger.log('[DrawsPage] onOpenDropdown which:%o', which);

		this.props.filter({
			open: which,
		});

		//measureApp('Draws', 'Menu', 'Open');
	}

	onCloseDropdown(which) {
		//measureApp('Draws', 'Menu', 'Close');
	}

	setEvent(event) {
		// logger.log('[DrawsPage] setEvent event:%o', event);

		MeasurementUtils.dispatchMeasurementCall('eventSelect', {
			event,
		});

		// if (pdfOnlyEvents.indexOf(event) > -1) {
		// 	window.open(`/en_US/scores/draws/${this.year}_${event}_draw.pdf`);
		// } else {
		this.props.filter({
			event,
			open: '',
		});

		// window.history.pushState(
		// 	null,
		// 	'',
		// 	`${getRoute(event, this?.props?.scoringConfig?.eventNames)}`
		// );
		//}
	}

	positionChange(event) {
		if (!event) {
			this.fixedBeforeRender = true;
			logger.log('[DrawsPage] positionChange - no event');
			return;
		}

		// logger.log('[DrawsPage] positionChange - side scroll left:%o', event.target.scrollLeft);
	}

	renderPageContents() {
		let stubMessage = this?.getStubMessage(this.props.filters.event);
		let flagImagePathSmall = this?.props?.configWeb_otherData?.flagImagePathSmall;
		let playerImgPath = this?.props?.configWeb_otherData?.playerProfileImagePath;
		let imgPath = this?.props?.configWeb_otherData?.basePicPath;
		let prizeMoney = this?.state?.drawsEventData?.prizeMoney;

		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);

		logger.log('[DrawsPage] renderPageContents drawsEventData:%o', this.state.drawsEventData);

		const pageClassnames = () => {
			return cn({
				wrapper: true,
				scorespage: true,
				draws: true,
				bracket: this.props.view == 'bracket',
				[`rounds_${this.state?.drawsEventData?.totalRounds}`]: this.props.view == 'bracket',
			});
		};
		return (
			<>
				{this.state?.drawsEventData == 'notfound' && this.state.showSub === false ? (
					<section id="draws-page" className={pageClassnames()}>
						<PageSubMenu
							mode="Draws"
							data={{ drawsEventData: this.state.drawsEventData }}
							selected={this.props.filters.event}
							selectedRound={this.props.eventRound.selectedRound}
							filters={this?.props?.filters}
							showFavorites={true}
							playersData={this.state?.playersPlaying}
							searchedPlayer={this?.state?.searchedPlayer?.id}
							invalidPlayers={this.state.invalidPlayers}
							drawPdf={this.props.scoringData.drawPdf.replace('<eventId>', this.props.filters.event)}
							onClick={this.onFavoriteClicked}
							onSelected={which => {
								this.setEvent(which);
							}}
							onOpen={which => {
								this.onOpenDropdown(which);
							}}
							onClose={which => {
								this.onCloseDropdown(which);
							}}
						/>
						<GenericError message="Draws data for this event is not available." />
					</section>
				) : this.props.configWeb_stubs &&
				  ((this.state.showStub === true) || this.state.drawsData == 'notfound') ? (
					<section id="draws-page" className={pageClassnames()}>
						<StubBox
							attributes={{
								title: 'Draws',
								message: stubMessage,
								basePicPath: imgPath,
							}}
						/>
					</section>
				) : this.state.drawsListData && this.state.playersPlaying.length > 0 ? (
					<section ref={this.drawsPageRef} id="draws-page" className={pageClassnames()}>
						<PageSubMenu
							mode="Draws"
							data={{ drawsEventData: this.state.drawsEventData }}
							selected={this.props.filters.event}
							selectedRound={this.props.eventRound.selectedRound}
							filters={this?.props?.filters}
							showFavorites={window.webview ? false : true}
							playersData={this.state?.playersPlaying}
							searchedPlayer={this?.state?.searchedPlayer?.id}
							invalidPlayers={this.state.invalidPlayers}
							drawPdf={this.props.scoringData.drawPdf.replace('<eventId>', this.props.filters.event)}
							onPdfClick={() => {
								this.onPdfSelect();
							}}
							showPdfIcon={true}
							onClick={this.onFavoriteClicked}
							onSelected={which => {
								this.setEvent(which);
							}}
							onOpen={which => {
								this.onOpenDropdown(which);
							}}
							onClose={which => {
								this.onCloseDropdown(which);
							}}
						/>
						<div className="column-layout" ref={this.scrollingRef}>
							{!this.state.eventLoading ? (
								<>
									<DrawsLists
										attributes={{
											data: this.state.drawsListData,
											matchBoxStyle: '',
											showSlamTrackerLinks: true,
											profileLinks: 'main',
											archiveYear: '',
											flagImagePathSmall,
											playerImgPath,
											prizeMoney,
											searchedPlayer: this.state.searchedPlayer?.id,
											selecedEvent: this.props.filters.event,
											parentRef: this?.drawsPageRef?.current,
										}}
									/>
								</>
							) : (
								<LoadingIndicator />
							)}
						</div>
					</section>
				) : this.state.drawsData && this.props.filters.event && this.totalRounds ? (
					<section id="draws-page" className={pageClassnames()}>
						<PageSubMenu
							mode="Draws"
							data={{ drawsEventData: this.state.drawsEventData }}
							selected={this.props.filters.event}
							selectedRound={this.props.eventRound.selectedRound}
							filters={this?.props?.filters}
							showFavorites={true}
							playersData={this.state?.playersPlaying}
							searchedPlayer={this?.state?.searchedPlayer?.id}
							invalidPlayers={this.state.invalidPlayers}
							drawPdf={this.props.scoringData.drawPdf.replace('<eventId>', this.props.filters.event)}
							onPdfClick={() => {
								this.onPdfSelect();
							}}
							showPdfIcon={true}
							onClick={this.onFavoriteClicked}
							onSelected={which => {
								this.setEvent(which);
							}}
							onOpen={which => {
								this.onOpenDropdown(which);
							}}
							onClose={which => {
								this.onCloseDropdown(which);
							}}
						/>
						<LoadingIndicator />
					</section>
				) : (
					<section id="draws-page" className={pageClassnames()}>
						{/* <h1 className="header">Draws</h1> */}
						<LoadingIndicator />
					</section>
				)}
			</>
		);
	}

	render() {
		// logger.log('[DrawsPage] render this:%o', this);
		let search = this.props.location.search.replace(/^\?/, '');
		let parsedQs = queryString.parse(search);
		let titleName = getEventName(this.props.filters.event, this?.props?.scoringConfig?.eventNames); // this is for spelling out the event name
		let eventRoute = getRoute(this.props.filters.event, this?.props?.scoringConfig?.eventNames);

		let header_attributes = {
			headerType: 'scores',
			title: `US Open Draws | Official Site of the ${this.props.tournamentYear} US Open Tennis Championships - A USTA Event`,
			metaTitle: 'US Open ' + titleName + ' Draw',
			metaDescription: `View the latest ${this.props.tournamentYear} US Open Draws. The ${this.props.tournamentYear} US Open men's and women's singles draws will be revealed on Thursday, Aug. 26.`,
			metaDate: '',
			metaPlayers: '',
			canonicalLink: `https://www.usopen.org${eventRoute}`
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '#',
					title: 'Draws',
				},
			],
			sponsor: getSponsorConfig(this?.props?.filters?.event, this?.props?.configWeb_sponsors), //this?.props?.configWeb_draws?.sponsors.default,
			title: titleName,
			page_header: titleName,
		};

		// logger.log('[DrawsPage] render header_attributes:%o', header_attributes);

		let hideAd = this.props.configWeb_stubs && (this.state.showStub == true || this.state.drawsData == 'notfound');
		let sponsorConfig = getSponsorConfig(this?.props?.filters?.event, this?.props?.configWeb_sponsors);
		// logger.log('[DrawsPage] render sponsorConfig:%o', sponsorConfig);
		//logger.log('[DrawsPage] render hideAd:%o', hideAd);

		return !window.webview ? (
			<Template header={header_attributes} subHeader={subheader_attributes} hideAd={hideAd} key={this.props?.Router?.pathname}>
				{this.renderPageContents()}
			</Template>
		) : (
			<TemplateGeneric>
				{sponsorConfig.webview ? (
					<div id="sub_header" className="draws">
						<div id="sponsor">
							<div className="presented_by">
								<span>{sponsorConfig.tagline}</span>
								<EventsLink to={sponsorConfig.link} title={sponsorConfig.title}>
									<img
										className={sponsorConfig.imgClass}
										src={sponsorConfig.imgSrc}
										border="0"
										alt={sponsorConfig.name}
									/>
								</EventsLink>
							</div>
						</div>
					</div>
				) : null}
				{this.renderPageContents()}
			</TemplateGeneric>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsPage);
