/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: Match Insights Modal
 * @props - content: header, body
 *          closeModal: callback for X button
 * -----------------------------------------------------------------------------
 */
const MIModal = props => {
	return (
		<div className="mi-modal">
			<div className="modal-container">
				<div className="modal-close" onClick={() => props.closeModal()}>
					<i className="icon-close" />
				</div>
				<div className="modal-header">{props.header}</div>
				<hr className="spacerLine" />
				<div className="modal-body">{props.body}</div>
			</div>
		</div>
	);
};

export default MIModal;
