import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		// loggger('[PlayersIndex] mount - players');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let flagImagePathLarge = Config.otherData.flagImagePathLarge;
			let playerProfileImagePath = Config.otherData.playerProfileImagePath;
			let profilePath = Config.scoringData.players;
			let seedsList = Config.scoringData.seedsList;
			let updatedSeedsList = Config.scoringData.updatedSeedsList;
			dispatch({
				type: deps.actionTypes.PLAYERINDEX_LOADED,
				data: {
					profilePath,
					seedsList,
					flagImagePathLarge,
					playerProfileImagePath,
					status: 'loaded',
					tourn_events: Config.scoringConfig.eventNames,
					updatedSeedsList,
				},
			});
		});
	},
	unmount: data => (dispatch, getState, store) => {
		// logger.log('[PlayersIndex] actions.unmount data:%o',data);
		dispatch({ type: deps.actionTypes.PLAYERINDEX_UNMOUNT, data: data });
	},
};
