import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	switch (action.type) {
		case deps.actionTypes.COUNTRYPLAYERSPAGE_MOUNT:
			// logger.log(
			//   "[COUNTRYPLAYERSPAGE] COUNTRYPLAYERSPAGE_MOUNT - action.data:%o",
			//   action.data
			// );
			newState = { ...state, paths: action.data };

			return newState;

		case deps.actionTypes.COUNTRYPLAYERSPAGE_FILTER:
			filters = {
				...state.filters,
				...action.data,
			};

			newState = {
				...state,
				filters: filters,
			};
			return newState;

		case deps.actionTypes.COUNTRYPLAYERSPAGE_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.COUNTRYPLAYERSPAGE_LOAD_COMPLETE:
			// logger.log(
			//   "[COUNTRYPLAYERSPAGE] COUNTRYPLAYERSPAGE_LOAD_COMPLETE - result:%o",
			//   action.data
			// );
			newState = Object.assign({}, state, {
				data: action.data,
				status: 'loaded',
			});
			return newState;

		case deps.actionTypes.COUNTRYPLAYERSPAGE_LOAD_ERROR:
			newState = Object.assign({}, state, {
				error: action.err,
				status: 'error',
			});
			return newState;

		default:
			return state;
	}
};
