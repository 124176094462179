/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import isEmpty from 'lodash/isEmpty';
import MatchBox from 'appdir/components/_scoring/MatchBox';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import op from 'object-path';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: ScoresBox
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ScoresBox'],
		configScoringData: state['Config'].scoringData,
		configOtherData: state['Config'].otherData,
		windowSize: state['Controller'].miWindowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadOverlayModal: (type, data) => dispatch(deps.actions.ModalOverlay.loadOverlayModal(type, data)),
});

class ScoresBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			matchesData: null,
		};

		this.dataLoaded = false;

		logger.log('[ScoresBox] constructor - state:%o', this.state);
		logger.log('[ScoresBox] constructor - props:%o', this.props);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[ScoresBox] getDerivedStateFromProps - nextProps:%o', nextProps);

		let newState = {
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		return newState;
	}

	componentDidMount() {
		logger.log('[ScoresBox] componentDidMount - state:%o', this.state);
		if (this.state.matchId && this.state.matchData) {
			let decodedData = '';
			try {
				decodedData = atob(this.state.matchData);
				let parsedData = JSON.parse(decodedData);
				logger.log('[ScoresBox] mmatch data: %o', parsedData);
				this.setState({
					matchesData: parsedData,
				});
			} catch (e) {
				// decoding failed, let's try to load from the server
				logger.error(e.message);
			}
		} else if (this.state.matchId && !this.dataLoaded) {
			// logger.log('[ScoresBox] componentDidUpdate -loading data files');
			this.dataLoaded = true;
			let { configScoringData } = this.state;
			let { configOtherData } = this.state;
			logger.log('[ScoresBox] tennis-api url: %o', configScoringData.tennisAPIMatchByGame);
			// let matchDataPath = configScoringData.tennisAPIMatchByGame.replace('<matchId>', this.state.matchId);
			let matchDataPath = configScoringData.matchScore.path.replace('<matchId>', this.state.matchId);
			// matchDataPath =
			// 	this.state.game && this.state.game.length > 0
			// 		? `${matchDataPath}/game/${this.state.game}`
			// 		: matchDataPath;
			logger.log('[ScoresBox] tennis-api url: %o', matchDataPath);

			fetch(matchDataPath)
				.then(data => {
					this.setState({
						matchesData: data,
					});
				})
				.catch(error => {
					logger.log('[ScoresBox] componentDidUpdate error loading match data: %o', error);
				});
		}
	}

	openScoresBox(match) {
		// logger.log('[ScoresBox] openScoresBox - match:%o', match);

		MeasurementUtils.dispatchMeasurementCall('openScoresBox', {
			match: match,
		});

		this.props.loadOverlayModal('SCORES_BOX', { matchId: match });
	}

	openPlayerBio(pid) {
		// location.href = `/en_US/players/overview/${pid}.html`;
	}

	render() {
		logger.log('[ScoresBox] render - state:%o', this.state);

		if (op.get(this.state, 'matchesData', false)) {
			logger.log('Match: %o', this.state.matchesData);
			let match = this.state.matchesData.matches[0];
			return (
				<div className="scores-box-container">
					<ErrorBoundary message="Match data is not available at this time.">
						{match.scores ? (
							<MatchBox
								attributes={{
									data: this.state.matchesData.matches[0],
									style: 'live',
									event: this.state.matchesData.matches[0].shortEventName,
									tableHeader: this.state.matchesData.matches[0].courtName,
									showLinks: false,
									usePlayerImages: false,
									isFeaturedMatch: false,
									liveVideo: false,
									useRadio: false,
									suppressProfileLink: false,
								}}
								key={`liveBlogScoreboard-${this.state.matchesData.matches[0].match_id}`}
							/>
						) : (
							<LoadingIndicator />
						)}
					</ErrorBoundary>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoresBox);
