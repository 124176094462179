/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getComponents, validateData } from 'appdir/components/general/Util';
import React, { Component } from 'react';
import JSXParser from 'react-jsx-parser';
import { parseString } from 'xml2js';
import { fetch } from 'appdir/components/general/Util';
import uniq from 'lodash/uniq';
import endsWith from 'lodash/endsWith';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Helmet from 'react-helmet';
import TemplateGeneric from 'components/TemplateGeneric';
import Time from 'appdir/components/common-ui/Time';
import ShareMenu from 'appdir/components/common-ui/ShareMenu';
import AgeGate from 'appdir/components/general/AgeGate';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { getAttributesArray } from 'appdir/components/pages/ContentPage/Utils';
/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['GeneralContentPageWebview'],
		AgeGate: state['AgeGate'],
		Router: state['Router'],
		stubs: state['Config'].stubPages,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.GeneralContentPageWebview.mount()),
	unmount: () => dispatch(deps.actions.GeneralContentPageWebview.unmount()),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
});
class GeneralContentPageWebview extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.props);
		this.state.pageDate = {};
		this.onShareClick = this.onShareClick.bind(this);
		this.lastDataPath = '';

		logger.log('[GeneralContentPageWebview] constructor');
	}

	componentDidMount() {
		logger.log('[GeneralContentPageWebview] componentDidMount');
	}

	componentWillUnmount() {
		logger.log('[GeneralContentPageWebview] componentWillUnmount');
		this.props.unmount();
	}

	componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});

		logger.log('[GeneralContentPageWebview] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
	}

	setViewedContent(timestamp, contentId) {
		//logger.log('[GeneralContentPageWebview] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.updateViewedContent(timestamp, contentId);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.dataUrl != undefined && endsWith(this.state.dataUrl, '.xml')) {
			//logger.log('[GeneralContentPageWebview] componentDidUpdate - fetch:%o', this.state.dataUrl);

			//if path samem but key diff (new nav to existing location?)
			//  reload the data
			if (
				this.state.location.pathname == prevState.location.pathname &&
				this.state.location.key != prevState.location.key
			) {
				this.lastDataPath = '';
			}

			// if (
			//   this.props.Router.pathname == prevProps.Router.pathname &&
			//   this.props.Router.key != prevProps.Router.key
			// ) {
			//   logger.log(
			//     "[GeneralContentPageWebview] componentDidUpdate - clear this.lastDataPath"
			//   );
			//   this.lastDataPath = "";
			// }

			if (this.state.dataUrl != this.lastDataPath) {
				this.lastDataPath = this.state.dataUrl;
				fetch(this.state.dataUrl)
					.then(result => {
						// logger.log("[GeneralContentPageWebview] componentDidUpdate result:%o", result);

						//set the xml parse options
						let options = {
							explicitArray: false,
							normalize: true,
							trim: true,
							mergeAttrs: true,
							valueProcessors: [],
						};

						//parse the xml
						parseString(result, options, function(err, json) {
							result = json.contentItem;
						});

						//clean the jsx block
						result.jsx = result.jsx
							.replace(/data="{(.*?)}"/gi, 'data={$1}')
							.replace(/(<img("[^"]*"|[^\/">])*)>/gi, '$1/>')
							.replace(/\/p\/>/, '/p>');
						result.jsx = this.replaceLinks(result.jsx);
						// clean the accordion blocks if present
						if (result.type === 'accordion') {
							result.section.map((item, i) => {
								result.section[i].jsx = item.jsx
									.replace(/data="{(.*?)}"/gi, 'data={$1}')
									.replace(/(<img("[^"]*"|[^\/">])*)>/gi, '$1/>');
							});
						}

						logger.log('[GeneralContentPageWebview] - result:%o', result);

						//update viewed content if has a date
						if (result.date) {
							this.setViewedContent(parseInt(result.date), result.contentId);
						}

						this.setState({
							data: result,
							pageData: result,
						});

						// logger.log('[measureInApp] result:%o', result);

						/** RLL added the condition for content != atoz to prevent double android call */
						if (result.type != 'landing' && result.contentId !== 'atoz') {
							measureInApp(['metricsState', this.props.match.params.category, result.title]);
						}
					})
					.catch(error => {
						logger.error('[GeneralContentPageWebview] componentDidUpdate error:%o', error);
						if (error.status == 404) {
							this.setState({
								data: 'notfound',
							});
						}
					});

				if (this.state.prevNextUrl != '' && this.state.prevNextUrl !== undefined) {
					fetch(this.state.prevNextUrl)
						.then(result => {
							logger.log('[GeneralContentPageWebview] componentDidUpdate preNextUrl result:%o', result);

							this.setState({
								prevNextData: result,
							});
						})
						.catch(error => {
							logger.error('[GeneralContentPageWebview] componentDidUpdate error:%o', error);
							if (error.status == 404) {
								this.setState({
									prevNextData: 'notfound',
								});
							}
						});
				}
			}
		} else {
			logger.warn('[GeneralContentPageWebview] componentDidUpdate - improper data url: %o', this.state.dataUrl);
		}

		//this.handleColumns();
	}

	replaceLinks(jsx) {
		//<a href="/en_US/players/overview/wta324166.html">Elena Rybakina</a>
		// logger.log('[GeneralContentPageWebview] replaceLinks - jsx:%o', jsx);
		let result = jsx.match(/<a href=".*?a>/g);
		logger.log('[GeneralContentPageWebview] replaceLinks - result:%o', result);
		result
			? result.forEach(link => {
					let m = link.match(/href="(.*)">(.*)<\/a>/);
					jsx = jsx.replace(link, `<EventsLink to="${m[1]}">${m[2]}</EventsLink>`);
			  })
			: null;

		logger.log('[GeneralContentPageWebview] replaceLinks - jsx:%o', jsx);
		return jsx;
	}

	onShareClick(url) {
		//logger.log('[GeneralContentPageWebview] onShareClick');
		MeasurementUtils.dispatchMeasurementCall('toggleShare', {
			display: url,
		});
		this.setState({
			sharePopup: 'hide',
		});
	}

	toggleShare() {
		//logger.log('[GeneralContentPageWebview] toggleShare - sharePopup:%o', this.state.sharePopup);
		MeasurementUtils.dispatchMeasurementCall('toggleShare', {
			display: !this.state.sharePopup,
		});
		this.setState({
			sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
		});
	}

	renderYearsMenu() {
		logger.log('[GeneralContentPageWebview] renderYearsMenu - pageData:%o', this.state.pageData.dependencies);
		let yearOptions = [];
		const years = [].concat(this.state.pageData.dependencies.data).map((item, i) => {
			if ((item.type = 'YearByYear')) {
				yearOptions.push(item.title);
			}
		});
		return (
			<select
				name="dropdown"
				size="1"
				id="select-anchor"
				className="champs-select-menu-dropdown"
				onChange={this.handleYearChange}>
				{yearOptions
					.sort((a, b) => b - a)
					.map((item, i) => (
						<option key={i} value={item}>
							{item}
						</option>
					))}
			</select>
		);
		// return years;
	}

	handleYearChange = e => {
		// logger.log("[GeneralContentPageWebview] handleYearChange - event:%o", e.target.value);
		let yrElement = document.querySelector(`#_${e.target.value}`).getBoundingClientRect();
		// logger.log("[GeneralContentPageWebview] handleYearChange - element:%o", yrElement);
		window.scrollTo(0, yrElement.top - 90);
	};

	getByline(name, url) {
		return (
			<div className={`four-col byline ${this.state.data.margin == 'true' ? 'left' : ''}`}>
				<div className="byline--attribution">{name ? 'By ' + name : ''}</div>
				<div className="byline--share">
					<div
						className="byline--share-button"
						onClick={() => {
							this.toggleShare();
						}}>
						<i className="icon-share" />
						SOCIAL
					</div>
					<div className={`byline--share-wrapper ${url ? 'threecol' : ''}`}>
						<ShareMenu
							type="popup"
							view={this.state.sharePopup}
							orient="top"
							onClick={this.onShareClick(url)}
							share={url}
						/>
					</div>
				</div>
			</div>
		);
	}

	getBreadcrumbs() {
		// logger.log("[GeneralContentPageWebview] getBreadcrumbs - state:%o", this.state);
		let breadCrumbs = [
			{
				link: '/index.html',
				title: 'Home',
			},
		];
		if (this.state.pageData && this.state.pageData.breadcrumbs) {
			let breadCrumb = this.state.pageData.breadcrumbs;
			if (typeof breadCrumb !== 'undefined') {
				Object.keys(breadCrumb).map(function(k, i) {
					// logger.log("[GeneralContentPageWebview] getBreadcrumbs - breadcrumb:%o", breadCrumb[k]);
					if (breadCrumb[k]?.title) {
						breadCrumbs.push(breadCrumb[k]);
					} else {
						//logger.log('[GeneralContentPageWebview] getBreadcrumbs - removing: %o:', breadCrumb[k]);
					}
				});
			}
		}
		return breadCrumbs;
	}

	getSponsor() {
		// logger.log("[GeneralContentPageWebview] getSponsor - state:%o", this.state);
		let sponsor = null;
		if (this.state.pageData && this.state.pageData.sponsor) {
			if (this.state.pageData.sponsor.sponsor.name != 'nosponsor') {
				sponsor = {
					link: this.state.pageData.sponsor.sponsor.link,
					name: this.state.pageData.sponsor.sponsor.name,
					title: this.state.pageData.sponsor.sponsor.tagline,
					imgClass: this.state.pageData.sponsor.sponsor.imgClass,
					imgSrc: this.state.pageData.sponsor.sponsor.imgSrc,
					tagline: this.state.pageData.sponsor.sponsor.tagline,
				};
			}
		} else if (this.state.category != '' && this.state.sponsors[this.state.category]) {
			sponsor = this.state.sponsors[this.state.category];
		} else if (this.state.landing != '' && this.state.sponsors[this.state.landing]) {
			sponsor = this.state.sponsors[this.state.landing];
		}
		// logger.log("[GeneralContentPageWebview] getSponsor - sponsor:%o", sponsor);
		return sponsor;
	}

	getHeader() {
		// logger.log("[GeneralContentPageWebview] getHeader - state:%o", this.state);

		let videoId =
			this.state.data.header && this.state.data.header.videoId && this.state.data.header.video
				? this.state.data.header.videoId
				: null;

		let video_attributes = {
			playerId: 'main',
			contentId: videoId,
			videoUrl: null,
			title: null,
			thumb: null,
			autoplay: true,
			fullscreenMode: '',
			style: `video-wrapper one-col modal header`,
			aspect: 'wide',
			url: null,
		};

		//content page, (category)
		if (this.state.category != 'articles' && !this.state.landing) {
			let videoclass = videoId ? 'video' : '';

			return (
				<div className="news--header-wrapper">
					<div className={`news--header ${videoclass}`}>
						<div className="news--header-title">
							<h2>{this.state.data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{this.state.data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
						</div>
					</div>
					{videoId ? (
						<div className="news--header-videoLink">
							<VideoWrapper attributes={video_attributes} type="header" />
						</div>
					) : null}
				</div>
			);
		} else if (this.state.landing && videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video" />
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (this.state.landing) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header" />
				</div>
			);
		}

		//article pages
		else if (videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video">
						<div className="news--header-icon">
							<i className="icon-news" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time
									epoch_ms={this.state.data.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title">
							<h2>{this.state.data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{this.state.data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (this.state.data.header.image && this.state.data.header.image.length > 25) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header">
						<div className="news--header-icon">
							<i className="icon-news" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time
									epoch_ms={this.state.data.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title">
							<h2>{this.state.data.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{this.state.data.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{this.state.data.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	playFullVideo(id) {
		logger.log('[GeneralContentPageWebview] playFullVideo - id:%o', id);
	}

	renderAccordionJSX(jsx) {
		let attributes = getAttributesArray(this.state.pageData.dependencies.data);
		let components = getComponents(getComponentListArray(this.state.pageData.dependencies.data));
		logger.log(
			'[GeneralContentPageWebview] renderAccordionJSX - attributes:%o components:%o:',
			attributes,
			components
		);
		// logger.log('[GeneralContentPageWebview] renderAccordionJSX - data:%o:', this.state.pageData);

		<JSXParser
			bindings={attributes}
			components={{ ...components }}
			jsx={jsx}
			renderInWrapper={false}
			showWarnings={true}
		/>;
	}

	getComponentListArray = list => {
		let comps = [];

		if (validateData(list, 'array')) {
			list.forEach(item => {
				if (item?.type) {
					comps.push(item['type']);
				} else {
					//logger.log('[ContentPage] getComponentListArray - removing: %o:', item['reference']);
				}
			});
		} else if (validateData(list, 'json')) {
			comps.push(list['type']);
		}

		comps = uniq(comps);
		comps.push('EventsLink');
		//logger.log('[ContentPage] getComponentListArray - %o:', comps);
		return comps;
	};

	goBack() {
		this.props.history.goBack();
	}

	render() {
		logger.log('[GeneralContentPageWebview] render - state:%o', this.state);
		logger.log('[GeneralContentPageWebview] render - props:%o', this.props);

		if (this.state?.data === 'notfound') {
			let header_propsData = {
				headerType: 'generic',
				title: 'Let!',
				metaTitle: 'This page does not exist.',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
				imageSrc: '//www.usopen.org/images/pics/large/f_stub_image_2020.jpg',
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: 'Let',
			};
			logger.log('[GeneralContentPageWebview] render - notfound:%o', this.props);

			return (
				<TemplateGeneric header={header_propsData} subHeader={subheader_attributes}>
					// <PageHeader attributes={header_propsData} />
				</TemplateGeneric>
			);
			//<NotFoundPage attributes={header_propsData} />
		} else if (
			this.state.pageData &&
			this.state.pageData.contentId &&
			this.state.stubs &&
			this.state.stubs[this.state.pageData.contentId] &&
			this.state.stubs[this.state.pageData.contentId].stub === 'stub'
		) {
			let header_propsData = {
				headerType: this.state.category || this.state.landing,
				shortTitle: this.state.pageData.shortTitle,
				scrollElem: '.content-main',
				metaTitle: this.state.pageData.metaTitle,
				metaDescription: this.state.pageData.abstract,
				metaDate: this.state.pageData.date,
				scroll: false,
				shareUrl: this.state.pageData.url.share,
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: this.state.pageData.title,
				sponsor: this.getSponsor(),
			};

			return (
				<TemplateGeneric header={header_propsData} subHeader={subheader_attributes}>
					<StubBox
						attributes={{
							title: header_propsData.metaTitle,
							message: this.state.stubs[this.state.pageData.contentId].text,
							basePicPath: this.state.basePicPath,
						}}
					/>
				</TemplateGeneric>
			);
		} else if (this.state.pageData && this.state.pageData.type === 'around ny' && !this.props.AgeGate.allowed) {
			let header_propsData = {
				headerType: 'generic',
				title: 'Around NY',
				metaTitle: 'Around NY.',
				metaDescription: '',
				metaDate: '',
				metaPlayers: '',
				imageSrc: '',
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: 'Around NY',
				sponsor: this.getSponsor(),
			};
			logger.log('[GeneralContentPageWebview] render - around ny:%o', this.props);

			return (
				<TemplateGeneric header={header_propsData} subHeader={subheader_attributes}>
					<AgeGate />
				</TemplateGeneric>
			);
		} else if (this.state.pageData && this.state.pageData.type === 'accordion') {
			let attributes = getAttributesArray(this.state.pageData.dependencies.data);
			let components = getComponents(this.getComponentListArray(this.state.pageData.dependencies.data));
			logger.log(
				'[GeneralContentPageWebview] render - accordion attributes:%o components:%o:',
				attributes,
				components
			);
			//logger.log('[GeneralContentPageWebview] render - data:%o:', this.state.pageData);

			let flex = this.state.pageData.tiled && this.state.pageData.tiled == 'true' ? 'flex' : '';

			let header_propsData = {
				headerType: this.state.category || this.state.landing,
				shortTitle: this.state.pageData.shortTitle,
				scrollElem: '.content-main',
				metaTitle: this.state.pageData.metaTitle,
				metaDescription: this.state.pageData.abstract,
				metaDate: this.state.pageData.date,
				scroll: false,
				shareUrl: this.state.pageData.url.share,
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: this.state.pageData.title,
				sponsor: this.getSponsor(),
			};

			//initially was checking for seemingly valid url to image or video due to invalid test data
			if (this.state.pageData.header.image && this.state.pageData.header.image.length > 25) {
				header_propsData['imageSrc'] = this.state.pageData.header.image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (this.state.pageData.header.video && this.state.pageData.header.video.length > 25) {
				header_propsData['videoSrc'] = this.state.pageData.header.video;
			}

			let prevNextBackData = {
				prevNextData: this.state.prevNextData,
				category: this.state.category,
				history: this.props.history,
			};

			//logger.log('[GeneralContentPageWebview] render - propsData:%o', header_propsData);

			return (
				<TemplateGeneric header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper article-wrapper">
						<Helmet>
							{this.state.category == 'players_only' ? <meta name="robots" content="noindex" /> : null}
							<meta name="measureTitle" content="Detail" />

							<meta property="og:title" content={this.state.pageData.title} />
							<meta property="og:description" content={this.state.pageData.abstract} />
							<meta property="fb:app_id" content="471867639491882" />
							<meta property="og:image" content={this.state.pageData.header.image} />
							<meta property="og:url" content={this.state.pageData.url.share} />

							<meta name="twitter:card" content="summary_large_image" />
							<meta name="twitter:site" content="@USOpen" />
							<meta name="twitter:creator" content="@USOpen" />
							<meta name="twitter:title" content={this.state.pageData.title} />
							<meta name="twitter:description" content={this.state.pageData.abstract} />
							<meta name="twitter:image" content={this.state.pageData.header.image} />
							<meta name="twitter:url" content={this.state.pageData.url.share} />

							{this.state.pageData.url.amp ? (
								<link rel="amphtml" href={this.state.pageData.url.amp} />
							) : null}
						</Helmet>
						{!this.state.pageData.hero || this.state.pageData.hero === 'false' ? (
							<h1 className="header">{this.state.pageData.title}</h1>
						) : null}
						<div className={`general_content accordion`}>
							<section className="text_section gray">
								<JSXParser
									bindings={attributes}
									components={{ ...components }}
									jsx={this.state.pageData.jsx}
									renderInWrapper={false}
									showWarnings={true}
								/>
							</section>

							<Accordion className="accordion-container">
								{this.state.pageData.section.map((item, i) => (
									<AccordionItem key={item.id}>
										<AccordionItemHeading className="accordion-header">
											<h3>
												<div className="ui-accordion-header-icon" role="presentation" />
												{item.title}
											</h3>
										</AccordionItemHeading>
										<AccordionItemPanel className="accordion-cnt">
											<JSXParser
												bindings={attributes}
												components={{ ...components }}
												jsx={item.jsx}
												renderInWrapper={false}
												showWarnings={true}
											/>
										</AccordionItemPanel>
									</AccordionItem>
								))}
							</Accordion>
						</div>
					</section>
				</TemplateGeneric>
			);
		} else if (this.state.pageData) {
			let attributes = getAttributesArray(this.state.pageData.dependencies.data);
			let components = getComponents(this.getComponentListArray(this.state.pageData.dependencies.data));
			// components.push('EventsLink');
			logger.log('[GeneralContentPageWebview] render - attributes:%o components:%o:', attributes, components);
			logger.log('[GeneralContentPageWebview] render - pageData:%o:', this.state.pageData);

			let flex = this.state.pageData.tiled && this.state.pageData.tiled == 'true' ? 'flex' : '';

			let header_propsData = {
				headerType: this.state.category || this.state.landing,
				shortTitle: this.state.pageData.shortTitle,
				scrollElem: '.content-main',
				metaTitle: this.state.pageData.metaTitle,
				metaDescription: this.state.pageData.abstract,
				metaDate: this.state.pageData.date,
				scroll: false,
				shareUrl: this.state.pageData.url.share,
			};

			let subheader_attributes = {
				breadcrumbs: this.getBreadcrumbs(),
				title: this.state.pageData.title,
				sponsor: this.getSponsor(),
			};

			//initially was checking for seemingly valid url to image or video due to invalid test data
			if (this.state.pageData.header.image && this.state.pageData.header.image.length > 25) {
				header_propsData['imageSrc'] = this.state.pageData.header.image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (this.state.pageData.header.video && this.state.pageData.header.video.length > 25) {
				header_propsData['videoSrc'] = this.state.pageData.header.video;
			}

			let prevNextBackData = {
				prevNextData: this.state.prevNextData,
				category: this.state.category,
				history: this.props.history,
			};

			let headerTitle = this.state.pageData.title;
			if (this.state.landing == 'sponsors') {
				headerTitle = 'Sponsors';
			}
			
			logger.log('[GeneralContentPageWebview] render - propsData:%o', header_propsData);

			return (
				<TemplateGeneric header={header_propsData} subHeader={subheader_attributes}>
					<section className="wrapper article-wrapper">
						<Helmet>
							{this.state.category == 'players_only' ? <meta name="robots" content="noindex" /> : null}
							<meta name="measureTitle" content="Detail" />

							<meta property="og:title" content={this.state.pageData.title} />
							<meta property="og:description" content={this.state.pageData.abstract} />
							<meta property="fb:app_id" content="471867639491882" />
							<meta property="og:image" content={this.state.pageData.header.image} />
							<meta property="og:url" content={this.state.pageData.url.share} />

							<meta name="twitter:card" content="summary_large_image" />
							<meta name="twitter:site" content="@USOpen" />
							<meta name="twitter:creator" content="@USOpen" />
							<meta name="twitter:title" content={this.state.pageData.title} />
							<meta name="twitter:description" content={this.state.pageData.abstract} />
							<meta name="twitter:image" content={this.state.pageData.header.image} />
							<meta name="twitter:url" content={this.state.pageData.url.share} />

							{this.state.pageData.url.amp ? (
								<link rel="amphtml" href={this.state.pageData.url.amp} />
							) : null}
						</Helmet>
						{/* {this.getHeader()} */}
						{/* {this.getByline(this.state.pageData.credit, this.state.pageData.url.share)} */}
						{this.state.category && this.state.category == 'us-open-at-home' ? (
							<div className="webview-back-link">
								<a href="/webview/en_US/us-open-at-home/index.html">
									<i className="icon-arrow-large-left"></i> Back to At-Home Guide
								</a>
							</div>
						) : this.state.category &&
						  this.state.category == 'visit' &&
						  (this.state.id == 'transportation_directions' ||
							this.state.id == 'american_express_on_site_benefits') ? (
	  
							this.props.history.length > 2 ? (
								<div
									className="webview-back-link"
									onClick={() => {
										this.goBack();
									}}>
									<i className="icon-arrow-large-left"></i> Back
								</div>
							) : null
						) : this.state.category &&
						  this.state.category == 'visit' &&
						  this.state.id != 'a_z' &&
						  this.state.id != 'on_site' &&
						  this.state.id != 'plan_your_visit' ? (
							<div
								className="webview-back-link"
								onClick={() => {
									this.goBack();
								}}>
								<i className="icon-arrow-large-left"></i> Back
							</div>
						) : null}
						{(!this.state.pageData.hero || this.state.pageData.hero === 'false') &&
						this.state.id !== 'american_express_on_site_benefits' ? (
							<h1 className="header">{headerTitle}</h1>
						) : null}
						<div className={`general_content ${this.state.pageData.type}`}>
							{this.state.pageData.contentId == 'year_by_year' ? (
								<section className="champs-select-menu">{this.renderYearsMenu()}</section>
							) : null}
							<JSXParser
								bindings={attributes}
								components={{ ...components }}
								jsx={this.state.pageData.jsx}
								renderInWrapper={false}
								showWarnings={true}
							/>
						</div>
					</section>
				</TemplateGeneric>
			);
		} else {
			logger.warn('[GeneralContentPageWebview] render - data not loaded, state:%o', this.state);

			let header_propsData = {
				headerType: 'generic',
				titleElem: '.news--header',
				scroll: false,
			};

			return (
				<TemplateGeneric header={header_propsData}>
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</TemplateGeneric>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralContentPageWebview);
