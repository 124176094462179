import WinFactorsFinder from './index';
import deps from 'dependencies';

export default [
	{
		path: '/:lang(en_US)/test/winfactorsfinder/index.html',
		exact: true,
		component: WinFactorsFinder,
		load: params => deps.actions.WinFactorsFinder.mount(params),
	},
];
