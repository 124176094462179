import React from 'react';

const PlayerImage = props => {
	let attributes = props.attributes;

	if (props.attributes['player-image']) {
		return (
			<div className={'player-img-container' + (attributes.colClass ? ' ' + attributes.colClass : '')}>
				<img
					className={'player-image ' + (attributes['style'] ? attributes['style'] : '')}
					src={attributes['player-image']}
				/>
			</div>
		);
	} else {
		return null;
	}
};

export default PlayerImage;
