/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Guest Info
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['GuestInfo'],
		componentVars: state['Config'].components,
		scoring: state['Config'].scoring,
		...props,
	};
};

class GuestInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};

		this.openConcierge = this.openConcierge.bind(this);
	}

	openConcierge() {
		let open = this.state.open ? false : true;
		this.setState({ open });
	}

	render() {
		// logger.log("[GuestInfo] render -state:%o", this.state);

		if (this.props.componentVars && this.props.componentVars.useGuestInfo == 'true') {
			return (
				<section className="conciergeSection">
					<div className="conciergeButton" onClick={this.openConcierge} />
					<div className={`conciergeWrapper ${this.state.open ? `open` : ``}`}>
						<div className={`navigationArea ${this.state.open ? `open` : ``}`}>
							<div className="icon close" onClick={this.openConcierge}>
								<i className="icon-close" />
							</div>
						</div>
						{this.state.open && (
							<iframe
								src={`https://chat.satis.fi/?pageID=4509`}
								allowFullScreen={false}
								className={`conciergeIframe ${this.state.open ? `open` : ``}`}
							/>
						)}
					</div>
				</section>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps)(GuestInfo);
