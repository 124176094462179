import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import EventsRedirect from 'appdir/components/general/EventsRedirect';

/**
 * -----------------------------------------------------------------------------
 * React Component: MatchInsightsWebview
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchInsights'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MatchInsights.mount()),
});
class MatchInsightsWebview extends Component {
	constructor(props) {
		super(props);

		logger.log('[MatchInsightsWebview] props:%o', this.props);
	}

	componentDidMount() {
		this.props.mount();
	}

	render() {
		return <EventsRedirect to={'/en_US/matchinsights/index.html'} />;
	}
}

MatchInsightsWebview.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MatchInsightsWebview);
