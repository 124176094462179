import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';

import { fetch, sortFavPlayers, getAvailableFavPlayersNum } from 'appdir/components/general/Util';
import EventsLink from 'appdir/components/general/EventsLink';
import PlayerImage from 'appdir/components/pages/PlayerPage/PlayerImage';
import FavoriteStar from 'appdir/components/common-ui/FavoriteStar';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * Class Component: Favorites Module that's used on the below pages
 *  - Players Index
 *  - Draws
 *  - Scores
 *  - Schedule
 *
 * props: {
 *     @param playersData ---- Obj that contains players data displayName, lastName, nation, etc - used on the searchable favorites, such as Draws, Schedule
 * 	@param searchedPlayer - player id to highlight selected player (a feature on searchable pages, draws and schedule)
 *     @param onClick -------- callback func() to set a selected player to highlight - a feature on searchable pages
 * }
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['FavoritesModule'],
		favorites: state['Controller'].favorites,
		scoringData: state['Config'].scoringData,
		roundNames: state['Config'].scoringConfig?.roundNames,
		sharedDataConfig: state['Config'].sharedDataConfig,
		playerStatusData: state['CommonData']['playerStatus'],
		playerImagePath: state['Config']?.otherData?.playerImagePath,
		flagImagePathSmall: state['Config']?.otherData?.flagImagePathSmall,
		EventsWindow: state['WindowSize'].EventsWindow,
		countriesLookUp: state['Controller'].countriesLookUp,
		...props,
	};
};

class FavoritesModule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// ...props,
			status: null,
			clickedIndex: null,
		};

		this.loadedJson = false;
		this.firstLoad = true;
		this.labelText = '';
		logger.log('[FavoritesModule] constructor - state: %o', this);
	}

	componentDidMount() {
		// this.playerActive = [];
		this.setState(
			{
				status: 'loaded',
			},
			() => {
				this.playersPlayingData = this.props?.playersData?.length > 0 ? this.props?.playersData : [];
			}
		);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.makeSticky.bind(this), false);
	}

	componentDidUpdate(prevProps, prevState) {
		let curFavPlayers = this.props.favorites?.players;
		let prevFavPlayers = prevProps?.favorites?.players;

		/** fetch master players data */
		if (
			(!this.loadedJson && this.props?.scoringData?.players && curFavPlayers?.length > 0) ||
			(prevFavPlayers?.length === 0 && curFavPlayers?.length > 0 && !this.state.sortedPlayers)
		) {
			this.loadedJson = true;
			let sortedPlayers = [];

			// this.playerActive = this.playersPlayingData.filter(p => this.props?.players?.favorites.includes(p.id));

			fetch(this.props.scoringData.players)
				.then(result => {
					/** loop through favorited players and sort the players alphabetically */

					sortedPlayers = sortFavPlayers(result.players, this.favorites);
					this.setState(
						{
							sortedPlayers: sortedPlayers,
							allPlayers: result.players,
						},
						() => {
							// Get the header
							this.headerElem = document.querySelector('.favorites-details');

							// Get the offset position of the navbar
							if (this.headerElem) {
								this.sticky = this.headerElem.offsetTop;

								window.addEventListener(
									'scroll',
									throttle(this.makeSticky.bind(this), 100, { trailing: true }),
									false
								);
							}
						}
					);
				})
				.catch(error => {
					//logger.log('[FavoritesModule] componentDidUpdate error:%o',error);
					this.setState({
						status: 'error',
						errorType: error,
					});
				});
		}

		/** if favorites are changed, update the sortedPlayers list */
		if (!isEqual(curFavPlayers, prevFavPlayers) && this.state.allPlayers) {
			let tmpSortedPlayers = sortFavPlayers(this.state.allPlayers, this.favorites);
			if (!isEqual(tmpSortedPlayers, this.state.sortedPlayers)) {
				this.setState({
					sortedPlayers: tmpSortedPlayers,
				});
			}
		}
		this.addLabelText();
	}

	sortByLastName = arr => {
		arr.sort((a, b) => {
			const lastNameA = a.last_name.toUpperCase();
			const lastNammB = b.last_name.toUpperCase();

			if (lastNameA < lastNammB) {
				return -1;
			}
			if (lastNameA > lastNammB) {
				return 1;
			}
			return 0;
		});
		return arr;
	};

	makeSticky = () => {
		if (window.pageYOffset > this.sticky) {
			this.headerElem.classList.add('sticky');
		} else {
			this.headerElem.classList.remove('sticky');
		}
	};

	measureNextPrev = (oldIndex, newIndex) => {
		if (oldIndex < newIndex) {
			MeasurementUtils.dispatchMeasurementCall('Favorites', {
				action: 'next',
				curSlide: newIndex,
			});
		} else {
			MeasurementUtils.dispatchMeasurementCall('Favorites', {
				action: 'prev',
				curSlide: newIndex,
			});
		}
	};

	lastPlayedRound = (matches, id) => {
		const roundCodes = [];
		let playerName = '';
		for (let match of matches) {
			const team1id = match.team1.idA;
			const team2id = match.team2.idA;

			if (team1id === id || team2id === id) {
				roundCodes.push(match.roundCode);
				playerName = team1id === id ? match.team1.displayNameA : match.team2.displayNameA;
			}
		}
		let displayMessage = playerName + ' last played in Round ' + roundCodes[roundCodes.length - 1];
		return [displayMessage, playerName, roundCodes[roundCodes.length - 1]];
	};

	showUnavailableMessage = (matches, id, index) => {
		this.setState(prevState => ({
			clickedIndex: prevState.clickedIndex === index ? null : index,
		}));
		let [displayMessage, playerName, lastRound] = this.lastPlayedRound(matches, id);
	};

	/** set searchecPlayer on a parent component */
	onClick = (player, isClickable = false, index) => {
		if (isClickable) {
			// for all pages that are not draws page
			this.props?.mode.toLowerCase() !== 'draws' && this.props?.searchedPlayer !== player.id
				? this.props.onClick(player)
				: this.props.onClick(null);

			// if page is draws and the player plays in a later round or current round then go to where the player is on the draws page
			if (this.props?.mode.toLowerCase() === 'draws') {
				if (
					parseInt(
						this.props?.roundNames.findIndex(
							item =>
								item.roundCode ===
								this.lastPlayedRound(this.props?.drawsData.drawsEventData.matches, player.id)[2]
						)
					) +
						1 >=
						parseInt(this.props?.selectedRound) &&
					this.props?.searchedPlayer !== player.id
				) {
					this.props?.onClick(player);
					this.state.clickedIndex = null;
				}
				// allow the selected player from above case to be unselected
				else if (
					parseInt(
						this.props?.roundNames.findIndex(
							item =>
								item.roundCode ===
								this.lastPlayedRound(this.props?.drawsData.drawsEventData.matches, player.id)[2]
						)
					) +
						1 >=
						parseInt(this.props?.selectedRound) &&
					this.props?.searchedPlayer === player.id
				) {
					this.props?.onClick(null);
				}
				// the player plays in the event but was elimated in an earlier round, show the player unavailable message (name and last round played)
				else if (
					parseInt(
						this.props?.roundNames.findIndex(
							item =>
								item.roundCode ===
								this.lastPlayedRound(this.props?.drawsData.drawsEventData.matches, player.id)[2]
						)
					) +
						1 <
					parseInt(this.props?.selectedRound)
				) {
					this.showUnavailableMessage(this.props?.drawsData.drawsEventData.matches, player.id, index);
				} else {
					this.props?.onClick(null);
				}
			}

			MeasurementUtils.dispatchMeasurementCall('Favorites', {
				action: 'search',
				player,
			});
		}
	};

	/** loop through favorited players ids and get the players data then sort them alphabetically */
	sortPlayers = allPlayers => {
		let tmpData = [];
		let sortedPlayers;

		this.favorites.map((player, index) => {
			let tmp = allPlayers.filter(p => p.id === player);
			if (tmp.length > 0) {
				tmpData.push(tmp[0]);
			}
		});

		if (tmpData.length > 0) {
			sortedPlayers = tmpData.sort((a, b) => {
				return a?.last_name < b?.last_name ? -1 : 1;
			});
		}

		return sortedPlayers;
	};

	/** favorite player card on Players Index */
	getPlayersIndexFavCard = (player, index) => {
		// logger.log('[FavoritesModule] getPlayersIndexFavCard - player %o', player);

		let countryIndex = this.props?.countriesLookUp?.findIndex((item, index) => item.code === player.country);
		let countryCode = '';
		let countryName = '';

		if (countryIndex) {
			countryCode = this.props.countriesLookUp[countryIndex].code;
			countryName = this.props.countriesLookUp[countryIndex].display;
		}

		let klass = 'favorite-card ';

		return (
			<div className={klass} key={`player ${player.id} ${index}`}>
				<EventsLink to={`/en_US/players/overview/${player.id}.html`}>
					<PlayerImage
						attributes={{
							'player-image': this.props?.playerImagePath.replace('<id>', player.id),
							style: 'regular',
						}}
					/>
				</EventsLink>
				<div className="player-info">
					<EventsLink to={`/en_US/players/overview/${player.id}.html`}>
						<div className="player-name">
							{player.first_name}
							<span className="last-name">
								{player.last_name}
								<span className="seed">{player.seedValue !== '' ? ` [${player.seedValue}]` : ''}</span>
							</span>
						</div>
						<div className="flag-image">
							<img src={this.props?.flagImagePathSmall.replace('<code>', player.country)} />
							<span className="country">{countryName}</span>
						</div>
					</EventsLink>
					<FavoriteStar
						id={player.id}
						playerName={player['tv_name']}
						type="players"
						unFavColor="gray"
						favColor="yellow"
						alwaysShow={true}
						style="outline"
					/>
				</div>
			</div>
		);
	};

	/** favorite player card on Draws, Schedule and Scores pages */
	getSearchableFavCard = (player, index) => {
		// logger.log('[FavoritesModule] getPlayerFavCard - player %o', player);

		let isClickable = false;
		let isActive = false;
		let tmpIsPlaying = false;
		if (this.playersPlayingData) {
			isActive = player.status === 'active' ? true : false;
			tmpIsPlaying = isActive;
			isClickable = isActive;
		}

		let klass = 'favorite-card ';
		klass += isActive ? '' : 'disabled ';
		klass += isClickable ? 'hoverable ' : '';
		klass += this.props?.searchedPlayer == player?.id && isActive ? 'selected ' : '';

		return player ? (
			<div
				className={`${klass} ${this.state.clickedIndex === index ? 'show-message' : 'hide-message'}`}
				key={`player ${player?.id} ${index}`}
				onClick={() => this.onClick(player, isClickable, index)}>
				{this.props?.mode.toLowerCase() === 'draws' && this.state.clickedIndex !== null && (
					<div className="player-unavailable-message">
						{this.lastPlayedRound(this.props?.drawsData.drawsEventData.matches, player.id)[0]}
					</div>
				)}
				<div className="player-with-flag">
					<PlayerImage
						attributes={{
							'player-image': this.props?.playerImagePath.replace('<id>', player.id),
							style: 'regular',
						}}
					/>
					<div className="flag-image">
						<img src={this.props?.flagImagePathSmall.replace('<code>', player.country)} />
					</div>
				</div>

				<div className="player-name">
					{player.tv_name}
					<span className="seed">{player.seedValue !== '' ? ` [${player.seedValue}]` : ''}</span>
				</div>
				{this.props.invalidPlayers && this.props.invalidPlayers[player.id] ? (
					<div className="unavailable">
						<span className="player-name">{player.tv_name}</span>
						<span className="message">is not available on this page.</span>
					</div>
				) : null}
			</div>
		) : null;
	};

	/** Searchable pages only - over tablet size display on the left side, mobile size, display at the top */
	getInstructions = () => {
		return (
			<div className="instruction">
				<span>
					{this.favorites?.length == 0
						? 'You have no favorited players'
						: 'Select a player to locate a match'}
				</span>
			</div>
		);
	};

	setSlidesToShow = (type = 'searchable') => {
		let viewCount = type == 'playersindex' ? 1.5 : 3.5; // default value in mobile
		let cardSize = type == 'playersindex' ? 220 : 85;

		if (this.props.EventsWindow) {
			let numericWindowSize = this.props.EventsWindow?.numericWindowSize;
			let windowWidth = this.props.EventsWindow?.windowWidth;

			if (type == 'playersindex' && numericWindowSize >= 3 && numericWindowSize <= 4) {
				// max-width: 1279px
				cardSize = 207 + 15; // add 10px as slider shaves some width out
			} else if (numericWindowSize > 4 || type == 'searchable') {
				cardSize =
					type == 'playersindex'
						? 291 + 15
						: windowWidth <= 425
						? 90
						: windowWidth <= 768
						? 105
						: windowWidth >= 860 && windowWidth <= 1024
						? 130
						: windowWidth < 1440
						? 110
						: windowWidth >= 1440 && windowWidth < 1600
						? 130
						: windowWidth >= 1600 && windowWidth < 2000
						? 150
						: 250; // + 15 + 20;

				if (type !== 'playersindex') {
					windowWidth = windowWidth - cardSize; // 130;
				}
			}
			viewCount = windowWidth / cardSize;
		}

		return viewCount;
	};

	addLabelText = () => {
		let notPlayedLabel, playedFavLabel;
		if (this.slider) {
			notPlayedLabel = document.querySelector('.players-inactive');
			playedFavLabel = document.querySelector('.players-active');

			notPlayedLabel?.classList.add('display-label');
			playedFavLabel?.classList.add('display-label');
			if (document.querySelectorAll('div.players-inactive.display-label').length > 1) {
				const el = document.querySelectorAll('div.players-inactive.display-label');
				for (let i = 1; i < el.length; i++) {
					el[i].classList.remove('display-label');
				}
			}
		}
	};

	getFavCards = (type = 'searchable') => {
		let settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: this.setSlidesToShow(type),
			slidesToScroll: 1,
			swipeToSlide: true,
			beforeChange: (oldIndex, newIndex) => {
				this.measureNextPrev(oldIndex, newIndex);
			},
			responsive: [
				{
					breakpoint: 1279,
					settings: {
						slidesToShow: this.setSlidesToShow(type),
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: this.setSlidesToShow(type),
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: this.setSlidesToShow(type),
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 375,
					settings: {
						slidesToShow: this.setSlidesToShow(type),
						slidesToScroll: 1,
					},
				},
			],
		};

		/** fix the width of the slider and match with seeds players table */
		if (type === 'playersindex') {
			settings = {
				dots: false,
				infinite: false,
				speed: 500,
				slidesToShow: 6,
				slidesToScroll: 6,
				swipeToSlide: true,
				beforeChange: (oldIndex, newIndex) => {
					this.measureNextPrev(oldIndex, newIndex);
				},
				responsive: [
					{
						breakpoint: 1279,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 4,
						},
					},
					{
						breakpoint: 1023,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3,
						},
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
						},
					},
					{
						breakpoint: 460,
						settings: {
							slidesToShow: 1.4,
							slidesToScroll: 1,
						},
					},
				],
			};
		}

		let allFavs,
			playingFavs,
			remainingPlayers,
			sortedPlayers = [];

		if (type === 'searchable') {
			const modArray = this.props?.playersData?.map(({ firstName, lastName, id }) => ({
				last_name: lastName,
				first_name: firstName,
				id: id,
			}));

			// logger.log(
			// 	'this.props.playersdata: %o, this.state.allPlayers: %o, this.favorites: %o, ',
			// 	this.props.playersData,
			// 	this.state.allPlayers,
			// 	this.favorites
			// );

			// allFavs - full data format for all favorited players
			allFavs = this.state?.allPlayers.filter(item => this.favorites.includes(item.id));

			// playingFavs - active favorites on the given day
			playingFavs = allFavs?.filter(item => this.props?.playersData?.find(fav => fav.id === item.id));
			// removed duplicates
			playingFavs = playingFavs?.filter(
				(player, index, self) => index === self.findIndex(p => p.id === player.id)
			);
			playingFavs = playingFavs?.map(item => {
				return {
					...item,
					status: 'active',
				};
			});
			// remainingPlayers - inactive favorites on the given day
			remainingPlayers = allFavs?.filter(favPlayer => !playingFavs?.find(fav => favPlayer.id === fav.id));
			// removes duplicates
			remainingPlayers = remainingPlayers.filter(
				(player, index, self) => index === self.findIndex(p => p.id === player.id)
			);
			remainingPlayers = remainingPlayers?.map(item => {
				return {
					...item,
					status: 'inactive',
				};
			});

			// sorted favorites by last name - sorts in 2 categories (active/inactive)
			playingFavs = playingFavs?.length > 0 ? this.sortByLastName(playingFavs) : playingFavs;
			remainingPlayers = remainingPlayers?.length > 0 ? this.sortByLastName(remainingPlayers) : remainingPlayers;
			sortedPlayers = [...playingFavs, ...remainingPlayers];

			this.labelText =
				this.props?.mode?.toLowerCase() == 'schedule' && this.props?.scheduleReleased?.released
					? ['playing this day', 'not playing this day']
					: this.props?.mode?.toLowerCase() == 'schedule' &&
					  this.props?.tab.toLowerCase() === 'schedule of play' &&
					  !this.props?.scheduleReleased?.released
					? ['', 'Schedule not released yet']
					: this.props?.mode?.toLowerCase() === 'schedule' &&
					  this.props?.tab.toLowerCase() === 'practice schedule'
					? ['practicing', 'not practicing']
					: this.props?.mode?.toLowerCase() == 'draws'
					? ['in this event', 'not in this event']
					: this.props?.mode?.toLowerCase() === 'scores' && this.props?.tab?.toLowerCase() === 'live scores'
					? ['playing', 'not playing']
					: ['played', 'did not play'];
		}

		return type === 'playersindex' ? (
			<Slider {...settings}>
				{this.state.sortedPlayers.map((player, index) => {
					/** Favorite Player's card for Player Index */
					return this.getPlayersIndexFavCard(player, index);
				})}
			</Slider>
		) : (
			<Slider ref={slider => (this.slider = slider)} {...settings}>
				{sortedPlayers.map((player, index) => {
					return (
						<>
							<div
								className={`${player.status === 'active' ? 'players-active' : 'players-inactive'}`}
								key={index}>
								{`${player.status === 'active' ? this.labelText[0] : this.labelText[1]}`}
							</div>
							{this.getSearchableFavCard(player, index)}
							{/* {this.addLabelText()} */}
						</>
					);
				})}
			</Slider>
		);
	};

	render() {
		// logger.log('[FavoritesModule] render - this %o', this);

		this.favorites = this.props.favorites?.players;

		this.favNum = 0;

		/** make sure that the favorite players are included in the players.json */
		if (this.state?.allPlayers?.length > 0 && this.favorites?.length > 0) {
			this.favNum = getAvailableFavPlayersNum(this.state?.allPlayers, this.props?.favorites?.players);
		}

		this.searchable =
			this.props?.mode?.toLowerCase() == 'schedule' ||
			this.props?.mode?.toLowerCase() == 'draws' ||
			this.props?.mode?.toLowerCase() == 'scores'
				? true
				: false;

		/**
		 * 	players data array that are seachable on the page
		 *  - Schedule page
		 *  - Draws page
		 *  - Scores page (Live and Completed)
		 */

		if (
			this.favorites?.length > 0 &&
			this.state.sortedPlayers &&
			this.state?.allPlayers &&
			this.playersPlayingData
		) {
			return (
				<div className={`favorites-module ${this.props?.mode ? this.props?.mode?.toLowerCase() : ''}`}>
					{/** for Players Index page */
					this.props.mode?.toLowerCase() == 'playersindex' ? (
						<>
							<div className="title-bar">
								<h3 className="star solid">Favorites ({this.favNum})</h3>
							</div>
							<div className="players-favorites-content">{this.getFavCards('playersindex')}</div>
						</>
					) : this.searchable ? (
						/** for Draws, Schedule, and Scores */
						<>
							{this.getFavCards('searchable')}
							{this.addLabelText()}
						</>
					) : null}
				</div>
			);
		} else {
			return (
				<div
					className={`favorites-module no-favorites ${
						this.props?.mode ? this.props?.mode?.toLowerCase() : ''
					}`}>
					{/** for Players Index page */
					this.props.mode?.toLowerCase() == 'playersindex' ? (
						<>
							<div className="title-bar">
								<h3 className="star solid">Favorites ({this.favNum})</h3>
							</div>
							<div className="players-favorites-content no-player">
								<p>Add your favorite players by selecting the player's &#9734;</p>
							</div>
						</>
					) : this.searchable ? (
						/** for Draws, Schedule, and Scores */
						<div className="fav-no-favs-dropdown">
							{this.getInstructions()}
							<div className="find-player">
								<EventsLink to="/en_US/players/index.html">Find a Player to favorite </EventsLink>
							</div>
						</div>
					) : null}
				</div>
			);
		}
	}
}

export default connect(mapStateToProps)(FavoritesModule);
