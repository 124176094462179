/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import RolexScheduleTime from 'appdir/components/common-ui/RolexScheduleTime';
import AdUnitLazy from 'appdir/components/general/AdUnitLazy';
import deps from 'dependencies';
import EventsLink from 'appdir/components/general/EventsLink';
import op from 'object-path';
import {
	chunkArray,
	matchHasInsights,
	renderTeamNames,
	matchIsFutureState,
	matchIsInProgress,
	matchIsCompleteState,
} from './ScheduleUtils';
import LazyMatchBox from 'appdir/components/_scoring/MatchBox/LazyMatchBox';
import MatchBox from 'appdir/components/_scoring/MatchBox';

/**
 * -----------------------------------------------------------------------------
 * React Component: SchedulePageContent
 * this is the order of page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SchedulePage'],
		scoring: state['Controller'].scoring,
		adConfig: state['Config']['adConfig'],
		miMatches: state['Controller'].miMatches,
		favorites: state['Controller'].favorites,
		liveMatches: state['ScoreManager'].liveMatches,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
class ScheduleContent extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.showRolex = this.showRolex.bind(this);
		this.hideRolex = this.hideRolex.bind(this);

		//format team names for empty display
		this.emptyName = {
			firstNameA: '',
			lastNameA: '',
			displayNameA: 'TBD',
			idA: null,
		};
	}

	hideRolex(evt) {
		// logger.log('[ScheduleContent] hideTime - evt:%o', evt.target.id);
		this.setState({
			showSelected: 0,
		});
	}

	showRolex(evt) {
		// logger.log('[ScheduleContent] showTime - evt:%o', evt.target.id);
		this.setState({
			showSelected: evt.target.id,
		});
	}

	renderRolex(courtTime, activeClass) {
		courtTime = new Date(courtTime);
		//logger.log('[ScheduleContent] courtTime - :%o',courtTime);

		return (
			<RolexScheduleTime
				top={'0px'}
				//left={'85%'}
				left={'0px'}
				visible={activeClass}
				format={'hh:mma'}
				position={'relative'}
				when={courtTime}
				label={{ event: 'New York: ', local: 'Your Time: ', session: 'Session will begin at:' }}
				sponsor={{ logo: '/assets/images/logos/rolex_logo_sm.png' }}
			/>
		);
	}

	renderMatch(match) {
		let useMIButton = matchHasInsights(this.props.miMatches, match.match_id) && !matchIsCompleteState(match);
		// Buy Tickets button only shows when event is in tournament state, matches have not begun, matches are not being played on Cary Leeds courts
		let useBuyTicketsButton =
			op.get(this.props, 'attributes.mode') == 'tourn' &&
			!match.courtName.includes('Cary') &&
			matchIsFutureState(match);
		let watchHighlightUrl;
		if (this.props.attributes?.highlights) {
			watchHighlightUrl = (this.props.attributes?.highlights[match.match_id] || {}).url;
		}
		let ticketURL = 'https://www.ticketmaster.com/usopentennis?cid=TMSchedule_07122021_phos_chluso_ctusot_endcid';

		return (
			<div
				key={match.match_id}
				data-match={match.match_id}
				className="match"
				data-players={`${match.team1[0].idA},${match.team1[0].idB},${match.team2[0].idA},${match.team2[0].idB}`}>
				<div className="row">
					<div className="match-info header" colSpan="3">
						{match.notBefore ? (
							<div className="match-info-notBefore">NOT BEFORE: {match.notBefore}</div>
						) : null}
						<span className="event">{match.eventName}</span> -{' '}
						<span className="round">{match.roundName}</span>
						<span className="scores">{match.shortScore}</span>
					</div>
					<div className="scores header">{match.shortScore}</div>
					<div className="status mobile">
						<EventsLink to={`/en_US/scores/stats/${match.match_id}.html`}>{match.status}</EventsLink>
					</div>
				</div>
				<div className="row teams">
					<div>{}</div>
					<div className="schedule-team content">
						{renderTeamNames(match.team1, this.props?.favorites, this.props?.attributes?.searchedPlayer)}
					</div>
					<div className="versus content">{match.conjunction}</div>
					<div className="schedule-team content">
						{renderTeamNames(match.team2, this.props?.favorites, this.props?.attributes?.searchedPlayer)}
					</div>
					<div className="schedule-buttons-cta lgdesktop">
						{useMIButton ? (
							<div className="cta-button-wrapper lgdesktop">
								<div key={'insights_lg_' + match.match_id} className="mi-cta-button">
									<EventsLink
										to={`/en_US/matchinsights/${match.match_id}.html`}
										measureData={{
											...this.measureData,
											itemTitle: 'Match Insights',
											link: `/en_US/matchinsights/${match.match_id}.html`,
											actionType: 'schedulePageClick',
											which: `matchID_${match.match_id}`,
										}}>
										Match Insights
									</EventsLink>
								</div>
							</div>
						) : null}
						{watchHighlightUrl ? (
							<div className="cta-button-wrapper lgdesktop">
								<div key={'view_highlights_lg_' + match.match_id} className="highlights-cta-button">
									<EventsLink
										to={watchHighlightUrl}
										measureData={{
											...this.measureData,
											itemTitle: 'View Match Highlights',
											link: { watchHighlightUrl },
											actionType: 'schedulePageClick',
											which: `matchID_${match.match_id}`,
										}}>
										View Match
										<br />
										Highlights
									</EventsLink>
								</div>
							</div>
						) : null}
					</div>
					<div className="status content">
						{useBuyTicketsButton ? (
							<div className="cta-button-wrapper lgdesktop">
								<div key={'buy_tickets_lg_' + match.match_id} className="tickets-cta-button">
									<EventsLink to={ticketURL}>Buy Tickets</EventsLink>
								</div>
							</div>
						) : null}
						<EventsLink to={`/en_US/scores/stats/${match.match_id}.html`}>{match.status}</EventsLink>
					</div>
				</div>
				<div className="row mobile">
					<div className="cta-button-wrapper tablet">
						{useMIButton ? (
							<div key={'insights_md_' + match.match_id} className="mi-cta-button">
								<EventsLink
									to={`/en_US/matchinsights/${match.match_id}.html`}
									measureData={{
										...this.measureData,
										itemTitle: 'Match Insights',
										link: `/en_US/matchinsights/${match.match_id}.html`,
										actionType: 'schedulePageClick',
										which: `matchID_${match.match_id}`,
									}}>
									Match Insights
								</EventsLink>
							</div>
						) : null}
						{useBuyTicketsButton ? (
							<div key={'buy_tickets_md_' + match.match_id} className="tickets-cta-button">
								<EventsLink to={ticketURL}>Buy Tickets</EventsLink>
							</div>
						) : null}
						{watchHighlightUrl ? (
							<div key={'view_highlights_md_' + match.match_id} className="highlights-cta-button">
								<EventsLink
									to={watchHighlightUrl}
									measureData={{
										...this.measureData,
										itemTitle: 'View Match Highlights',
										link: { watchHighlightUrl },
										actionType: 'schedulePageClick',
										which: `matchID_${match.match_id}`,
									}}>
									View Match
									<br />
									Highlights
								</EventsLink>
							</div>
						) : null}
						<div className="status">
							<EventsLink to={`/en_US/scores/stats/${match.match_id}.html`}>{match.status}</EventsLink>
						</div>
					</div>
					<div className="scores">{match.shortScore}</div>
				</div>
				<div className="highlights-wrapper mobile">
					{watchHighlightUrl ? (
						<div key={'view_insights_sm_' + match.match_id} className="highlights-cta-button">
							<EventsLink
								to={watchHighlightUrl}
								measureData={{
									...this.measureData,
									itemTitle: 'View Match Highlights',
									link: { watchHighlightUrl },
									actionType: 'schedulePageClick',
									which: `matchID_${match.match_id}`,
								}}>
								View Match
								<br />
								Highlights
							</EventsLink>
						</div>
					) : null}
				</div>
				<div className="row smdesktop">
					<div className="cta-button-wrapper smdesktop">
						{useMIButton ? (
							<div key={'insights_sm_' + match.match_id} className="mi-cta-button">
								<EventsLink
									to={`/en_US/matchinsights/${match.match_id}.html`}
									measureData={{
										...this.measureData,
										itemTitle: 'Match Insights',
										link: `/en_US/matchinsights/${match.match_id}.html`,
										actionType: 'schedulePageClick',
										which: `matchID_${match.match_id}`,
									}}>
									Match Insights
								</EventsLink>
							</div>
						) : null}
						{useBuyTicketsButton ? (
							<div key={'buy_tickets_sm_' + match.match_id} className="tickets-cta-button">
								<EventsLink to={ticketURL}>Buy Tickets</EventsLink>
							</div>
						) : null}
						{watchHighlightUrl ? (
							<div key={'view_highlights_sm_' + match.match_id} className="highlights-cta-button">
								<EventsLink
									to={watchHighlightUrl}
									measureData={{
										...this.measureData,
										itemTitle: 'View Match Highlights',
										link: { watchHighlightUrl },
										actionType: 'schedulePageClick',
										which: `matchID_${match.match_id}`,
									}}>
									View Match
									<br />
									Highlights
								</EventsLink>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}

	renderMIP(match) {
		// logger.log('[ScheduleContent] renderMIP this:%o', this);
		let liveList = this.props.attributes.liveMatches.matches;
		let currentScores = {};
		liveList.find(m => {
			if (m.match_id == match.match_id) {
				currentScores = m.scores;
			}
		});

		return (
			<div key={match.match_id} className="one-col">
				<LazyMatchBox
					attributes={{
						mode: 'schedule',
						data: {
							...match,
							team1: match.team1[0],
							team2: match.team2[0],
							scores: currentScores,
						},
						style: 'live',
						event: match.shortEventName,
						tableHeader: match.courtName,
						showLinks: true,
						searchedPlayer: this.state?.searchedPlayer,
					}}
				/>
			</div>
		);
	}

	renderEmptyMatch(match) {
		// logger.log('[ScheduleContent] renderEmptyMatch:%o', match);
		return (
			<div key={match.match_id} className="one-col">
				<MatchBox
					attributes={{
						mode: 'schedule',
						data: { ...match, team1: this.emptyName, team2: this.emptyName },
						style: 'empty',
						event: match.shortEventName,
						tableHeader: match.courtName,
						showLinks: false,
						suppressProfileLink: true,
					}}
				/>
			</div>
		);
	}

	renderMatchScoreboard(match) {
		// logger.log('[ScheduleContent] renderMatchScoreboard:%o', match);
		//undecided winner
		if (match.team1.length > 1) {
			match['team1'] = [];
		} else if (match.team2.length > 1) {
			match['team2'] = [];
		}

		return (
			<div key={match.match_id} className="one-col">
				<LazyMatchBox
					attributes={{
						mode: 'schedule',
						data: {
							...match,
							team1: match.team1[0] ? match.team1[0] : this.emptyName,
							team2: match.team2[0] ? match.team2[0] : this.emptyName,
						},
						style: '',
						event: match.shortEventName,
						tableHeader: match.courtName,
						showLinks: true,
						suppressProfileLink: false,
						searchedPlayer: this.props?.attributes?.searchedPlayer,
					}}
				/>
			</div>
		);
	}

	render() {
		// logger.log('[ScheduleContent] render this:%o', this);
		let hasFooterComments = false;
		let fcData = [];
		let chunkedCourts = chunkArray(this.props.attributes.data.courts, this.props.attributes.chunkVal);
		logger.log(
			'[ScheduleContent] render chunkedCourts.length:%o, chunkedCourts: %o',
			chunkedCourts.length,
			chunkedCourts
		);

		// logger.log(
		// 	'[ScheduleContent] render chunkedCourts:%o, completedMatches:%o, liveMatches: %o',
		// 	chunkedCourts,
		// 	this.props.attributes.completedMatches,
		// 	this.props.attributes.liveMatches
		// );
		if (this.props.attributes.data.footerComment !== null) {
			hasFooterComments = true;
			fcData = this.props.attributes.data.footerComment.split('|');
		}

		if (op.get(this.props, 'miMatches', false)) {
			return (
				<>
					{chunkedCourts.map((courts, index) => {
						return (
							<React.Fragment key={`frag${index}`}>
								<div className="column-layout">
									{courts.map(court => {
										let courtTime = court.startEpoch * 1000;
										// logger.log('[SchedulePage] render - :%o', courtTime);

										return (
											<div
												key={court.courtId + court.session}
												data-court={court.courtId + court.session}
												data-session={court.session}
												className="schedule-court">
												<div
													className="courtName"
													data="1300"
													id={court.courtId + court.session}
													onMouseEnter={this.showRolex}
													onMouseLeave={this.hideRolex}>
													{court.courtName}
													<br />
													{court.time}
													<div className="rolexContainer">
														{this.renderRolex(
															courtTime,
															this.state.showSelected === court.courtId + court.session
																? true
																: false
														)}
													</div>
												</div>

												<div className="schedule-content">
													{court.matches.map(match => {
														//check for empty/exhibition matches
														if (match.team1.length == 0 && match.team2.length == 0) {
															return this.renderEmptyMatch(match);
														}
														//render match with live data
														else if (matchIsInProgress(match)) {
															return this.renderMIP(match);
														}
														//render match with schedule data
														else {
															return this.renderMatchScoreboard(match, 'schedule');
														}
													})}
												</div>
											</div>
										);
									})}
								</div>
								{index == 0 && this.props?.adConfig?.schedule_2?.showAd ? (
									<div id="schedule-ad-2" className="schedule-ad-container">
										<AdUnitLazy data={{ adType: 'schedule_2' }} />
									</div>
								) : null}
								{index > 0 &&
								index < chunkedCourts.length - 1 &&
								this.props?.adConfig?.[`schedule_${index}`]?.showAd ? (
									<div id={`schedule-ad-${index}`} className="schedule-ad-container">
										<AdUnitLazy data={{ adType: `schedule_${index}` }} />
									</div>
								) : null}
							</React.Fragment>
						);
					})}
					{hasFooterComments === true ? (
						<div className="column-layout">
							<div className="footer-comments">
								{fcData.map((comment, i) => {
									return (
										<div className="footer-comment-item" key={`comment-${i}`}>
											{comment}
										</div>
									);
								})}
							</div>
						</div>
					) : null}
					{this.props?.adConfig?.[`schedule_${chunkedCourts.length}`]?.showAd ? (
						<div id={`schedule-ad-${chunkedCourts.length}`} className="schedule-ad-container">
							<AdUnitLazy data={{ adType: `schedule_${chunkedCourts.length}` }} />
						</div>
					) : null}
				</>
			);
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps)(ScheduleContent);
