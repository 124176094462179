/***
 **  Main Drows and DrawsArchiveYear Draws Lists Component
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import throttle from 'lodash/throttle';
import EventsLink from 'appdir/components/general/EventsLink';
import PlayerImage from 'appdir/components/_scoring/MatchBox/PlayerImage';
import LazyMatchBox from 'appdir/components/_scoring/MatchBox/LazyMatchBox';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import Time from 'appdir/components/common-ui/Time';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';

import cn from 'classnames';
import { Rnd } from 'react-rnd';

import { values } from 'appdir/main';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { isWinnerIsCompleted } from 'appdir/components/pages/DrawsPage/DrawsUtils';
import { doMeasurement } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {
		...state['DrawsLists'],
		view: state['DrawsPage'].view,
		sharedDataConfig: state['Config'].sharedDataConfig,
		playerImagePath: state['Config']?.otherData?.playerImagePath,
		matchInsightsMatches: state['CommonData']['matchInsightsMatches'],
		enabled: state['Config'].enabled,
		selectedRound: state['DrawsPage'].eventRound?.selectedRound,
		favorites: state['Controller']?.favorites,
		windowSize: state['Controller'].windowSize,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	checkExpired: dataConfig => dispatch(deps.actions.CommonData.checkExpired(dataConfig)),
	update: params => dispatch(deps.actions.CommonData.update(params)),
	getDrawPath: playerId => dispatch(deps.actions.Tournament.getDrawPath({ playerId: playerId })),
});

const mobileMatch = window.matchMedia('(max-width: 767px)');
const tabletMatch = window.matchMedia('(max-width: 1023px)');
class DrawsLists extends Component {
	constructor(props) {
		super(props);

		this.state = {
			width: '100vw',
			height: '100vh',
			x: 0,
			y: 0,
			isInitialView: true,
			isZoomedOut: false,
		};
		this.rndRef = React.createRef();

		this.sharedDataLoaded = false;
		this.setOnHover = this.setOnHover.bind(this);
		this.profileBasePath = '/en_US/players/overview/';

		this.firstLoad = true;
		this.init = true;
		this.initCheck = true;

		this.drawsListWrapperRef = React.createRef();
		this.drawsBracketWrapperRef = React.createRef();

		logger.log('[DrawsLists] constructor this:%o', this);
	}
	componentDidMount() {
		logger.log('[DrawsLists] componentDidMount this:%o', this);

		window.scrollTo(0, 0);

		// const div = this.ref?.current;
		// if (!div) {
		// 	return; // This should not happen, but React is not 100% clear about it
		// }
		// div.addEventListener('wheel', onWheel, { capture: true });
		// div.addEventListener('dblclick', onDoubleClick, { capture: true });
		// div.addEventListener('touchstart', onTouchStart, { capture: true });

		window.addEventListener('scroll', throttle(this.positionChange.bind(this), 100, { trailing: true }), false);
	}

	componentWillUnmount() {
		// const div = this.ref?.current;
		// if (!div) {
		// 	return; // This should not happen, but React is not 100% clear about it
		// }
		// div.removeEventListener('wheel', onWheel, { capture: true });
		// div.removeEventListener('dblclick', onDoubleClick, { capture: true });
		// div.removeEventListener('touchstart', onTouchStart, { capture: true });
	}

	componentDidUpdate(prevProps, prevState) {
		/** Pull shared matches json file to get all the available matches ids */

		if (!this.sharedDataLoaded) {
			// load all the available MI matches list to check if the selected MatchId exists
			this.sharedDataLoaded = true;
			this.props
				.checkExpired(this.props.sharedDataConfig['matchInsightsMatches'])
				.then(response => {
					logger.log('[DrawsLists] componentDidUpdate matchInsightsMatches - response:%o', response);
					if (response.status == 'expired') {
						this.props.update(this.props.sharedDataConfig['matchInsightsMatches']);
					}
				})
				.catch(error => {
					this.sharedDataLoaded = false;
					// logger.error('[DrawsLists] componentDidUpdate matchInsightsMatches - :o', error);
				});
		}
	}

	assignElements() {
		this.parentWrapper = document.querySelector('#draws-page'); // used to get width and left position
		this.topAdWrapper = document.querySelector('.topAd'); // get topAd elememnt to get the gheight
		this.drawsListWrapper = document.querySelector('.drawsList-wrapper');

		this.drawsListLabels = document.querySelector('.drawsList.labels'); // meant to check scrolling and fix position (maybe future use?)
		this.drawsTransformWrapper = document.querySelector('.react-transform-wrapper');
		this.drawsTransformComponent = document.querySelector('.react-transform-component');
		this.drawsLabelsContainer = document.querySelector('.labels-container');
		this.drawsBracketWrapper = document.querySelector('.draws-bracket-wrapper');
	}

	positionChange(event) {
		// if (!event) {
		// 	logger.log('[DrawsLists] positionChange - no event');
		// 	return;
		// }

		// logger.log('[DrawsLists] positionChange - event:%o this:%o', event, this);
		if (this.props?.view == 'bracket') {
			this.assignElements();
		}
	}

	matchHasInsights(matchId) {
		if (op.get(this.props.matchInsightsMatches, 'status', '') == 'loaded') {
			let matchesList = op.get(this.props, 'matchInsightsMatches.result.matches', []);
			this.checkMatchExisits = true;

			return matchesList.includes(matchId);
		}
	}

	setOnHover(pid) {
		logger.log('[DrawsLists] setOnHover pid:%o, this:%o', pid, this);

		if (pid !== null) {
			this.setState({
				hoveredPid: pid,
			});
		}
	}

	isNextColumn() {
		let nextColumn = [];
		let data = this.props.attributes.data;

		Object.keys(data).map((column, index) => {
			//let column = 'column' + letter;

			if (data[column].matches.length === 0) {
				nextColumn.push(false);
			} else {
				nextColumn.push(true);
			}
		});

		// logger.log('[DrawsLists] isNextColumn nextColumn:%o', nextColumn);

		return nextColumn;
	}

	renderRoundBrackets() {
		return (
			<>
				<div className={`connector-round`}>
					<div className={`left-side`}></div>
					<div className={`right-side`}></div>
				</div>
			</>
		);
	}

	getPrizeMoney(column) {
		let prizeMoney = null;
		let drawsData = this.props.attributes.data;

		if (this?.props?.attributes?.prizeMoney?.length > 0) {
			this.props.attributes.prizeMoney.map((rnd, index) => {
				if (
					//drawsData[column].matches &&
					//drawsData[column].matches.length > 0 &&
					drawsData[column]?.matches[0]?.roundCode == rnd.roundCode &&
					drawsData[column]?.matches[0]?.roundCode == 'F'
				) {
					prizeMoney =
						this.props.view == 'round'
							? `Runner-Up: $${rnd.money} / Winner: $${this.props.attributes.prizeMoney[index + 1].money}`
							: `Runner-Up: $${rnd.money}`;
				} else if (
					//drawsData[column].matches &&
					drawsData[column]?.matches?.length > 0 &&
					drawsData[column]?.matches[0]?.roundCode == rnd.roundCode
				) {
					prizeMoney = '$' + rnd.money;
				}
			});
		}
		//logger.log('[DrawsLists] getPrizeMoney column:%o, prizeMoney:%o', column, prizeMoney);

		return prizeMoney;
	}
	getColumn(column) {
		let drawsData = this.props.attributes.data;

		let columnKey = column;

		if (this.props.view == 'bracket' && column == 'columnE' && Object.keys(drawsData).length == 6) {
			columnKey = 'columnF';
		}
		if (this.props.view == 'bracket' && column == 'columnF' && Object.keys(drawsData).length == 6) {
			columnKey = 'columnG';
		}
		return columnKey;
	}

	onWheel = event => {
		//let { isInitialView } = this.state;
		if (!this.state.isInitialView) {
			return;
		}
		if (event.deltaY > 0) {
			event.stopPropagation();
			return;
		} else if (event.deltaY < 0) {
			this.setState({ isInitialView: false });
		}
	};

	onDoubleClick = () => {
		if (this.state.isInitialView) {
			this.setState({ isInitialView: false });
		}
	};

	onTouchStart = event => {
		// Rule taken from react-zoom-pan-pinch.
		let isDoubleTap = this.lastTouch && +new Date() - this.lastTouch < 200;
		if (isDoubleTap && event.touches.length === 1) {
			this.onDoubleClick();
		} else {
			this.lastTouch = +new Date();
		}
	};

	handleTransform = e => {
		logger.log(
			'[DrawsLists] handleTransform scale:%o, isInitialView:%o, isZoomedOut:%o, parentRef-w:%o, drawsListWrapperRef-w:%o, ',
			e.instance.transformState.scale,
			this.state.isInitialView,
			this.state.isZoomedOut,
			this?.props?.attributes?.parentRef?.clientWidth,
			this?.drawsBracketWrapperRef?.current?.clientWidth
		);
		// e.setTransform(0, 0, 0.8);
	};

	render() {
		//logger.log('[DrawsLists] render this:%o', this);

		let drawsData = this.props.attributes.data;

		// boolean
		let showSlamTrackerLinks = this.props.attributes.showSlamTrackerLinks;

		// add a class to the match-box <div> to adjust stles if necessary
		let matchBoxStyle = this.props.attributes.matchBoxStyle ? this.props.attributes.matchBoxStyle : '';

		// "main" || "archive" - set the proper link path based on the page
		let profileLinks = this.props.attributes.profileLinks;

		// eventId for Draws Archive. for main draws, leave it as blank
		let archiveYear = this.props.attributes.archiveYear ? this.props.attributes.archiveYear : '';

		this.flagImagePathSmall = this.props.attributes.flagImagePathSmall;

		this.playerProfileImagePath = this.props.attributes.playerImgPath;

		let count = 0;

		this.event = this.props?.attributes?.selecedEvent;

		const columnClassNames = (column, index) => {
			let columnKey = column;

			if (this.props.view == 'bracket' && column == 'columnE' && Object.keys(drawsData).length == 6) {
				columnKey = 'columnF';
			}
			if (this.props.view == 'bracket' && column == 'columnF' && Object.keys(drawsData).length == 6) {
				columnKey = 'columnG';
			}

			return cn({
				roundGroup: true,
				[this.getColumn(column, index)]: true,
				last: this.isNextColumn()[index + 1] ? false : true,
			});
		};

		return (
			// <Rnd
			// 	size={{ width: this.state.width, height: this.state.height }}
			// 	position={{ x: this.state.x, y: this.state.y }}
			// 	onDragStop={(e, d) => {
			// 		this.setState({ x: d.x, y: d.y });
			// 	}}
			// 	className = "draws-rnd"
			// 	style={{ position: 'relative' }}
			// 	lockAspectRatio={true}
			// 	minWidth={250}
			// 	ref={this.rndRef}
			// 	enableResizing={false}
			// 	disableDragging={false}
			// 	bounds={'.column-layout'}
			// 	>
			<div ref={this.drawsListWrapperRef} className="drawsList-wrapper">
				{this.props.view !== 'bracket' ? (
					<>
						<div className="drawsList labels">
							{// loop through ABC array to assign columnA through columnC
							//['A', 'B', 'C'].map((letter, index) => {
							Object.keys(drawsData).map((column, index) => {
								// logger.log(
								// 	'[DrawsLists] render drawsData.length:%o, column:%o, index:%o',
								// 	Object.keys(drawsData).length,
								// 	column,
								// 	index
								// );

								let prizeMoney = null;
								if (this?.props?.attributes?.prizeMoney?.length > 0) {
									this.props.attributes.prizeMoney.map((rnd, index) => {
										if (
											//drawsData[column].matches &&
											//drawsData[column].matches.length > 0 &&
											drawsData[column]?.matches[0]?.roundCode == rnd.roundCode &&
											drawsData[column]?.matches[0]?.roundCode == 'F'
										) {
											prizeMoney =
												this.props.view == 'round' || Object.keys(drawsData).length < 6
													? `Runner-Up: $${rnd.money} / Winner: $${
															this.props.attributes.prizeMoney[index + 1].money
													  }`
													: `Runner-Up: $${rnd.money}`;
										} else if (
											//drawsData[column].matches &&
											drawsData[column]?.matches?.length > 0 &&
											drawsData[column]?.matches[0]?.roundCode == rnd.roundCode
										) {
											prizeMoney = '$' + rnd.money;
										}
									});
								}
								// logger.log("[DrawsLists] prizeMoney %o", prizeMoney);
								// make sure data exists
								if (
									drawsData[column].matches.length !== 0 &&
									op.get(this.props.matchInsightsMatches, 'status', '') == 'loaded'
								) {
									return (
										<div key={column} className={columnClassNames(column, index)}>
											<h3 className={'draws-prize-money'}>
												<span className="round-name">{drawsData[column].title}</span>
												{prizeMoney ? (
													<span className="round-prize-money">{prizeMoney}</span>
												) : null}
												<div className="ticket-container">
													<hr />
													<EventsLink
														to="https://www.ticketmaster.com/usopentennis"
														external={true}>
														<i className="icon-tickets" />
														Tickets
													</EventsLink>
													<hr />
												</div>
											</h3>
										</div>
									);
								}
							})}
						</div>

						<div className="drawsList">
							{// loop through ABC array to assign columnA through columnC
							//['A', 'B', 'C'].map((letter, index) => {
							Object.keys(drawsData).map((column, index) => {
								// logger.log("[DrawsLists] prizeMoney %o", prizeMoney);
								// make sure data exists
								if (
									drawsData[column].matches.length !== 0 &&
									op.get(this.props.matchInsightsMatches, 'status', '') == 'loaded'
								) {
									return (
										<div key={column} className={columnClassNames(column, index)}>
											<div className="draw-container">
												{// loop through drawsData.columnA-C.matches to get each MatchBox
												drawsData[column].matches.map((item, match_index) => {
													// for draws archve, set a special class for Winner and Challenged Match Boxes
													let winnerClass =
														item.roundCode && item.roundCode === 'W' ? 'winner' : '';
													let qualifierClass =
														item.roundCode && item.roundCode === 'WQ' ? 'qualifier' : '';
													let challengeClass =
														item.roundCode && item.roundCode === 'C' ? 'challenge' : '';

													// flag true to odd ordered matchbox to add brakets
													let drawBrakets = (match_index + 1) % 2 == 1 ? true : false;
													let isFinalRd =
														item.roundCode === 'F' || item.title === 'Final' ? true : false;

													// showLinks    - receive true or false
													// profileLinks - receive "main" or "archive" and pass it to draws.js
													// onHovered    - callback function called in onHover(pid) in  /_scoring/MatchBox/draws.js
													// highlightPid - pass the state value to draws.js to check and render based on the state
													return (
														<div
															key={`${column} + ${item.roundCode} + ${
																item.match_id
															} + ${archiveYear} + ${count++}`}
															className={`drawGroup ${winnerClass} ${qualifierClass} ${challengeClass}`}>
															{this.props.view == 'bracket' &&
															this.getColumn(column) == 'columnG' ? (
																<>
																	<div className="round-name">
																		{drawsData[column].title}
																	</div>
																	<LazyMatchBox
																		attributes={{
																			mode: 'draws',
																			data: item,
																			event: item.shortEventName,
																			style: item.statusCode == 'B' ? '' : '',
																			showLinks: showSlamTrackerLinks,
																			profileLinks: profileLinks,
																			flagImagePathSmall: this.flagImagePathSmall,
																			playerProfileImagePath: this
																				.playerProfileImagePath,
																			drawBrackets: drawBrakets,
																			isFinalRd: isFinalRd,
																			hasMatchInsights: this.matchHasInsights(
																				item.match_id
																			),
																			searchedPlayer: this.props.attributes
																				.searchedPlayer,
																		}}
																		onHovered={this.setOnHover}
																		highlightPid={this.state.hoveredPid}
																	/>
																	<div className="winner-container">
																		{item.winner ? (
																			<div className="bio-photos">
																				<PlayerImage
																					attributes={{
																						'player-image': this.playerProfileImagePath.replace(
																							'<playerid>',
																							item.winner === '1'
																								? item.team1.idA
																								: item.team2.idA
																						),
																						style: 'xsmall',
																					}}
																				/>
																				{(item.team1.idB || item.team2.idB) &&
																				(item.team1.idB !== '' ||
																					item.team2.idB !== '') ? (
																					<PlayerImage
																						attributes={{
																							'player-image': this.playerProfileImagePath.replace(
																								'<playerid>',
																								item.winner === '1'
																									? item.team1.idB
																									: item.team2.idB
																							),
																							style: 'xsmall',
																						}}
																					/>
																				) : null}
																			</div>
																		) : null}

																		<div className="winner-details">
																			{this?.props?.attributes?.prizeMoney
																				?.length > 0 ? (
																				<div className="prize-money">{`WINNER - $${
																					this.props.attributes.prizeMoney[
																						this.props.attributes.prizeMoney
																							.length - 1
																					].money
																				}`}</div>
																			) : (
																				<div className="prize-money">
																					WINNER
																				</div>
																			)}
																			{item.winner ? (
																				<div className="team-name">
																					{`${
																						item[`team${item.winner}`]
																							.displayNameA
																					}`}
																					{item[`team${item.winner}`].idB
																						? ` / ${
																								item[
																									`team${item.winner}`
																								].displayNameB
																						  }`
																						: ''}
																				</div>
																			) : null}
																		</div>
																	</div>
																</>
															) : (
																<LazyMatchBox
																	attributes={{
																		mode: 'draws',
																		data: item,
																		event: item.shortEventName,
																		style: item.statusCode == 'B' ? '' : '',
																		showLinks: showSlamTrackerLinks,
																		profileLinks: profileLinks,
																		flagImagePathSmall: this.flagImagePathSmall,
																		playerProfileImagePath: this
																			.playerProfileImagePath,
																		drawBrackets: drawBrakets,
																		isFinalRd: isFinalRd,
																		hasMatchInsights: this.matchHasInsights(
																			item.match_id
																		),
																		searchedPlayer: this.props.attributes
																			.searchedPlayer,
																	}}
																	onHovered={this.setOnHover}
																	highlightPid={this.state.hoveredPid}
																/>
															)}

															{this.renderRoundBrackets()}
														</div>
													);
												})}
											</div>
										</div>
									);
								}
							})}
						</div>
					</>
				) : (
					<TransformWrapper
						ref={this.rndRef}
						initialScale={1}
						doubleClick={{
							mode: 'reset',
						}}
						wheel={{
							wheelDisabled: 'true',
							step: 1,
							smoothStep: 0.005,
						}}
						maxScale={1.5}
						minScale={
							this?.props?.attributes?.parentRef?.clientWidth /
								this?.drawsBracketWrapperRef?.current?.clientWidth -
							0.02
						}
						panning={{ disabled: true, velocityDisabled: true }}
						// onPanningStop={ref =>
						// 	this.setState({
						// 		isInitialView: true,
						// 	})
						// }
						centerZoomedOut={true}
						limitToBounds={true}
						smooth={true}
						alignmentAnimation={{ disabled: 'true' }}
						zoomAnimation={{ animationType: 'linear', animationTime: 1 }} //size: 0.2
						velocityAnimation={{ disabled: 'true' }}
						onZoom={ref =>
							this.setState(
								{
									isInitialView: ref.instance.transformState.scale != 1 ? false : true,
								},
								() => ref?.setTransform(0, 0, ref.instance.transformState.scale)
							)
						}
						onPinchingStop={ref =>
							this.setState(
								{
									isInitialView: ref.instance.transformState.scale != 1 ? false : true,
								},
								() => ref?.setTransform(0, 0, ref.instance.transformState.scale)
							)
						}
						onZoomStop={ref =>
							this.setState(
								{
									isZoomedOut:
										ref.instance.transformState.scale <
										this.drawsBracketWrapperRef?.current.clientWidth
											? true
											: false,
									// isInitialView: false,
								},
								() => ref?.setTransform(0, 0, ref.instance.transformState.scale)
							)
						}
						options={{ wrapperClass: 'disable-dbl-tap-zoom' }}
						// maxPositionY={{0: 'px'}}
						// onTransformed={e => this.handleTransform(e)}
					>
						<TransformComponent>
							<div ref={this.drawsBracketWrapperRef} className="draws-bracket-wrapper">
								<div className="labels-container">
									<div className="drawsList labels">
										{// loop through ABC array to assign columnA through columnC
										//['A', 'B', 'C'].map((letter, index) => {
										Object.keys(drawsData).map((column, index) => {
											// logger.log(
											// 	'[DrawsLists] render drawsData.length:%o, column:%o, index:%o',
											// 	Object.keys(drawsData).length,
											// 	column,
											// 	index
											// );

											let prizeMoney = null;
											if (this?.props?.attributes?.prizeMoney?.length > 0) {
												this.props.attributes.prizeMoney.map((rnd, index) => {
													if (
														//drawsData[column].matches &&
														//drawsData[column].matches.length > 0 &&
														drawsData[column]?.matches[0]?.roundCode == rnd.roundCode &&
														drawsData[column]?.matches[0]?.roundCode == 'F'
													) {
														prizeMoney =
															this.props.view == 'round' ||
															Object.keys(drawsData).length < 6
																? `Runner-Up: $${rnd.money} / Winner: $${
																		this.props.attributes.prizeMoney[index + 1]
																			.money
																  }`
																: `Runner-Up: $${rnd.money}`;
													} else if (
														//drawsData[column].matches &&
														drawsData[column]?.matches?.length > 0 &&
														drawsData[column]?.matches[0]?.roundCode == rnd.roundCode
													) {
														prizeMoney = '$' + rnd.money;
													}
												});
											}
											// logger.log("[DrawsLists] prizeMoney %o", prizeMoney);
											// make sure data exists
											if (
												drawsData[column].matches.length !== 0 &&
												op.get(this.props.matchInsightsMatches, 'status', '') == 'loaded'
											) {
												return (
													<div key={column} className={columnClassNames(column, index)}>
														<h3 className={'draws-prize-money'}>
															<span className="round-name">
																{drawsData[column].title}
															</span>
															{prizeMoney ? (
																<span className="round-prize-money">{prizeMoney}</span>
															) : null}
															<div className="ticket-container">
																<hr />
																<EventsLink
																	to="https://www.ticketmaster.com/usopentennis"
																	external={true}>
																	<i className="icon-tickets" />
																	Tickets
																</EventsLink>
																<hr />
															</div>
														</h3>
													</div>
												);
											}
										})}
									</div>
								</div>
								<div className={`drawsList ${this.state.isZoomedOut ? 'isZoomedOut' : ''}`}>
									{// loop through ABC array to assign columnA through columnC
									//['A', 'B', 'C'].map((letter, index) => {
									Object.keys(drawsData).map((column, index) => {
										// logger.log("[DrawsLists] prizeMoney %o", prizeMoney);
										// make sure data exists
										if (
											drawsData[column].matches.length !== 0 &&
											op.get(this.props.matchInsightsMatches, 'status', '') == 'loaded'
										) {
											return (
												<div key={column} className={columnClassNames(column, index)}>
													<div
														className="draw-container"
														>
														{// loop through drawsData.columnA-C.matches to get each MatchBox
														drawsData[column].matches.map((item, match_index) => {
															// for draws archve, set a special class for Winner and Challenged Match Boxes
															let winnerClass =
																item.roundCode && item.roundCode === 'W'
																	? 'winner'
																	: '';
															let qualifierClass =
																item.roundCode && item.roundCode === 'WQ'
																	? 'qualifier'
																	: '';
															let challengeClass =
																item.roundCode && item.roundCode === 'C'
																	? 'challenge'
																	: '';

															// flag true to odd ordered matchbox to add brakets
															let drawBrakets = (match_index + 1) % 2 == 1 ? true : false;
															let isFinalRd =
																item.roundCode === 'F' || item.title === 'Final'
																	? true
																	: false;

															// showLinks    - receive true or false
															// profileLinks - receive "main" or "archive" and pass it to draws.js
															// onHovered    - callback function called in onHover(pid) in  /_scoring/MatchBox/draws.js
															// highlightPid - pass the state value to draws.js to check and render based on the state
															return (
																<div
																	key={`${column} + ${item.roundCode} + ${
																		item.match_id
																	} + ${archiveYear} + ${count++}`}
																	className={`drawGroup ${winnerClass} ${qualifierClass} ${challengeClass}`}>
																	{this.props.view == 'bracket' &&
																	this.getColumn(column) == 'columnG' ? (
																		<>
																			<div className="round-name">
																				{drawsData[column].title}
																			</div>
																			<LazyMatchBox
																				attributes={{
																					mode: 'draws',
																					data: item,
																					event: item.shortEventName,
																					style:
																						item.statusCode == 'B'
																							? ''
																							: '',
																					showLinks: showSlamTrackerLinks,
																					profileLinks: profileLinks,
																					flagImagePathSmall: this
																						.flagImagePathSmall,
																					playerProfileImagePath: this
																						.playerProfileImagePath,
																					drawBrackets: drawBrakets,
																					isFinalRd: isFinalRd,
																					hasMatchInsights: this.matchHasInsights(
																						item.match_id
																					),
																					searchedPlayer: this.props
																						.attributes.searchedPlayer,
																				}}
																				onHovered={this.setOnHover}
																				highlightPid={this.state.hoveredPid}
																			/>
																			<div className="winner-container">
																				{logger.log(
																					'[DrawsLists] Winning Team:%o',
																					item
																				)}
																				{item.winner ? (
																					<div className="bio-photos">
																						<PlayerImage
																							attributes={{
																								'player-image': this.playerProfileImagePath.replace(
																									'<playerid>',
																									item.winner === '1'
																										? item.team1.idA
																										: item.team2.idA
																								),
																								style: 'xsmall',
																							}}
																						/>
																						{(item.team1.idB ||
																							item.team2.idB) &&
																						(item.team1.idB !== '' ||
																							item.team2.idB !== '') ? (
																							<PlayerImage
																								attributes={{
																									'player-image': this.playerProfileImagePath.replace(
																										'<playerid>',
																										item.winner ===
																											'1'
																											? item.team1
																													.idB
																											: item.team2
																													.idB
																									),
																									style: 'xsmall',
																								}}
																							/>
																						) : null}
																					</div>
																				) : null}

																				<div className="winner-details">
																					{this?.props?.attributes?.prizeMoney
																						?.length > 0 ? (
																						<div className="prize-money">{`WINNER - $${
																							this.props.attributes
																								.prizeMoney[
																								this.props.attributes
																									.prizeMoney.length -
																									1
																							].money
																						}`}</div>
																					) : (
																						<div className="prize-money">
																							WINNER
																						</div>
																					)}
																					{item.winner ? (
																						<div className="team-name">
																							{`${
																								item[
																									`team${item.winner}`
																								].displayNameA
																							}`}
																							{item[`team${item.winner}`]
																								.idB
																								? ` / ${
																										item[
																											`team${item.winner}`
																										].displayNameB
																								  }`
																								: ''}
																						</div>
																					) : null}
																				</div>
																			</div>
																		</>
																	) : (
																		<LazyMatchBox
																			attributes={{
																				mode: 'draws',
																				data: item,
																				event: item.shortEventName,
																				style: item.statusCode == 'B' ? '' : '',
																				showLinks: showSlamTrackerLinks,
																				profileLinks: profileLinks,
																				flagImagePathSmall: this
																					.flagImagePathSmall,
																				playerProfileImagePath: this
																					.playerProfileImagePath,
																				drawBrackets: drawBrakets,
																				isFinalRd: isFinalRd,
																				hasMatchInsights: this.matchHasInsights(
																					item.match_id
																				),
																				searchedPlayer: this.props.attributes
																					.searchedPlayer,
																			}}
																			onHovered={this.setOnHover}
																			highlightPid={this.state.hoveredPid}
																		/>
																	)}

																	{this.renderRoundBrackets()}
																</div>
															);
														})}
													</div>
												</div>
											);
										}
									})}
								</div>
							</div>
						</TransformComponent>
					</TransformWrapper>
				)}
			</div>
			//</Rnd>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawsLists);
