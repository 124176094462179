import deps from 'dependencies';
const queryString = require('querystring-browser');
import { getEventCode, getRoute } from './DrawsUtils';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[DrawsPage] action.mount - params: %o, DrawsPage:%o', params, getState()['DrawsPage']);

			let search = window.location?.search?.replace(/^\?/, '');
			let parsedQs = queryString.parse(search);

			logger.log('[DrawsPage] action.mount parsedQs - :%o', parsedQs);
			let event =
				params?.legacy && parsedQs?.event
					? parsedQs?.event
					: params?.webview && params.event
					? params.event
					: params?.event
					? getEventCode(params?.event, Config?.scoringConfig?.eventNames)
					: 'MS';

			logger.log('[DrawsPage] action.mount event - :%o', event);

			let legacyEvent = params?.legacy && parsedQs?.event ? parsedQs.event : null;

			logger.log('[DrawsPage] action.mount redirect - legacyEvent:%o', legacyEvent);

			if (legacyEvent) {
				logger.log('[DrawsPage] action.mount redirect - legacyEvent:%o', legacyEvent);
				window.history.pushState(null, '', `${getRoute(legacyEvent, Config.scoringConfig?.eventNames)}`);
			} else if (params?.legacy) {
				logger.log('[DrawsPage] action.mount - redirect event:%o', event);
				window.history.pushState(null, '', `${getRoute(event, Config.scoringConfig?.eventNames)}`);
			}

			let view = parsedQs?.view ? parsedQs.view : 'round';

			let data = {
				mountStatus: 'ready',
				filters: {
					event,
					open: '',
				},
				view,
			};
			dispatch({ type: deps.actionTypes.DRAWSPAGE_MOUNT, data: data });
		});
	},

	unmount: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSPAGE_UNMOUNT, data: data });
	},

	filter: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.DRAWSPAGE_FILTER, data: data });
	},

	setRound: data => (dispatch, getState, store) => {
		logger.log('[DrawsPage] action.round - alldata: %o', data);
		dispatch({ type: deps.actionTypes.DRAWS_SET_ROUND, data: data });
	},

	/** RLL added 2/15/2022 to combine round and filter */
	update: data => (dispatch, getState, store) => {
		// logger.log('[DrawsPage] action.update - data: %o', data);
		dispatch({ type: deps.actionTypes.DRAWSPAGE_UPDATE, data: data });
	},
	renderDraws: timestamp => (dispatch, getState, store) => {
		// logger.log('[DrawsPage] action.renderDraws');
		dispatch({ type: deps.actionTypes.DRAWS_RENDER, data: timestamp });
	},
	changeView: data => dispatch => {
		dispatch({ type: deps.actionTypes.DRAWS_CHANGE_VIEW, data });
	},
};
