import deps from "dependencies";

export default (state = {}, action) => {
  let newState;

  switch (action.type) {
    case deps.actionTypes.GROUNDS_MAP_MOUNT:
      newState = {
        ...state,
        status: "loaded",
        ...action.data
      };
      logger.log(
        "[GroundsMap] reducer.GROUNDS_MAP_MOUNT - newState:%o",
        newState
      );
      return newState;

    case deps.actionTypes.GROUNDS_MAP_UNMOUNT:
      newState = {
        ...state,
        status: "unloaded",
        ...action.data
      };
      logger.log(
        "[GroundsMap] reducer.GROUNDS_MAP_UNMOUNT - newState:%o",
        newState
      );
      return newState;

    case deps.actionTypes.GROUNDS_MAP_ERROR:
      logger.log("[GroundsMap] reducer.GROUNDS_MAP_ERROR - action:%o", action);
      newState = Object.assign({}, state, {
        status: "error",
        error: action.error
      });
      return newState;

    case deps.actionTypes.GROUNDS_MAP_LOAD:
      logger.log("[GroundsMap] reducer.GROUNDS_MAP_LOAD");

      newState = {
        ...state,
        status: "loaded",
        ...action.data
      };
      //logger.log('[GroundsMap] reducer.GROUNDS_MAP_LOAD - newState:%o', newState);
      return newState;

    default:
      return state;
  }
};
