/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

/* page elements */
import SlamtrackerContent from 'appdir/components/pages/Slamtracker/SlamtrackerContent';
import Template from 'components/Template';

import SlamtrackerPanel from 'appdir/components/_scoring/SlamtrackerPanel';

/**
 * -----------------------------------------------------------------------------
 * React Component: SlamtrackerPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Slamtracker'],
		windowSize: state['Controller'].stWindowSize,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	unmount: () => dispatch(deps.actions.Slamtracker.unmount()),
	setScoringStatus: data => dispatch(deps.actions.Controller.setScoringStatus(data)),
	clearStatMatch: () => dispatch(deps.actions.ScoreManager.clearStatMatch()),
});

class Slamtracker extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		logger.log('[Slamtracker] componentDidMount with panel - this:%o', this);
	}

	componentWillUnmount() {
		// logger.log('[Slamtracker] componentWillUnmount');
		// i don't want to do this if we are staying on slamtracker page
		this.props.setScoringStatus({ slamtracker: false });
		//this.state.unmount();
		this.props.clearStatMatch();
	}

	componentDidUpdate() {
		// logger.log('[Slamtracker] componentDidUpdate - this:%o', this);
	}

	render() {
		// logger.log("[Slamtracker] render - this:%o", this);

		let header_propsData = {
			headerType: 'scores',
			title: 'IBM SlamTracker',
			metaTitle: 'IBM SlamTracker',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};
		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
				{
					link: '/en_US/scores/index.html',
					title: 'Scores',
				},
			],
			sponsor: {
				link: 'http://www.ibm.com/sports/usopen',
				title: 'Presented by',
				name: 'IBM',
				imgClass: '',
				imgSrc: '/assets/images/logos/ibm_logo_black.png',
				tagline: 'Presented by',
			},
			page_header: 'IBM SlamTracker',
			title: 'IBM SlamTracker',
		};

		return (
			<Template
				header={header_propsData}
				subHeader={this.props.windowSize !== 'mobile' ? subheader_attributes : null}>
        <SlamtrackerContent
          matchId={this.props.match.params.matchId}
          location="st-page"
          selectedTab={this.props?.location?.state?.selectedTab || null}
        />
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Slamtracker);
