import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import queryString from 'querystring-browser';

//initialize logger as not enabled
global.logEnabled = false;
global.startTime = moment();

let search = document.location.search.replace(/^\?/, '');
let logging = search.indexOf('logs=true') > -1;
let parsedQs = queryString.parse(search);

if (process.env.NODE_ENV == 'development' || logging || parsedQs.logs) { //|| window.webview 
	global.logEnabled = true;
}

export const Logger = {
	log: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			//let time = moment().diff(startTime, 'seconds', true);
			args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
			console.log(...args);
		}
	},

	info: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			//let time = moment().diff(startTime, 'seconds', true);
			args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
			console.info(...args);
		}
	},

	warn: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
			console.warn(...args);
		}
	},

	error: (...args) => {
		if (logEnabled === true || logEnabled === 'true') {
			args[0] = moment().format('HH:mm:ss') + ' ' + args[0];
			console.error(...args);
		}
	},
};
