import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.HOME_MOUNT: {
			newState = {
				...state,
				...action.data,
				end: action.data.start + action.data.end,
			};
			logger.log('[Home] reducer - HOME_MOUNT: %o', newState);

			return newState;
		}
		case deps.actionTypes.HOME_UNMOUNT: {
			newState = {
				...state,
				...action.data,
			};
			logger.log('[Home] reducer - HOME_UNMOUNT: %o', newState);

			return newState;
		}
		default:
			return state;
	}
};
