import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	let filters;

	switch (action.type) {
		case deps.actionTypes.PASTCHAMPS_MOUNT:
			newState = {
				...state,
				...action.data,
			};

			return newState;

		case deps.actionTypes.PASTCHAMPS_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};

			return newState;

		case deps.actionTypes.PASTCHAMPS_LOAD:
			newState = {
				...state,
				...action.data,
			};
			return newState;

		case deps.actionTypes.PASTCHAMPS_FILTER:
			filters = {
				...state.filters,
				...action.data,
			};

			logger.log('[PastChamps] PASTCHAMPS_FILTER - state:%o', state);

			newState = {
				...state,
				filters: filters,
			};

			logger.log('[PastChamps] PASTCHAMPS_FILTER - newState:%o', newState);
			return newState;

		default:
			return state;
	}
};
