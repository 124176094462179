import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

const IndexList = ({ data }) => {
	return (
		<div className="content">
			<h1>IndexList</h1>
			<strong>
				<ul>
					<li>
						<EventsLink to="/test/videotest">Video Test</EventsLink>
					</li>
				</ul>
			</strong>
		</div>
	);
};

export default IndexList;

/*


*/
