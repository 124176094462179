import React, { PureComponent } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * -----------------------------------------------------------------------------
 * Component: EventInfo
 * @params
 *  - event
 *  - isFeaturedMatch
 *  - round
 *  - roundName
 *  - roundNameShort
 *  - comments
 *  - style
 *  - eventCode
 *  - mode
 * -----------------------------------------------------------------------------
 */

export default class EventInfo extends PureComponent {
	render() {
		let event = this.props.event ? this.props.event : '';
		let isFeaturedMatch = this.props.isFeaturedMatch ? this.props.isFeaturedMatch : false;
		let round = this.props.round
			? this.props.round
			: isFeaturedMatch
			? this.props.roundName
			: this.props.roundNameShort;

		// logger.log('[MatchBox EventInfo] props:%o', this.props);

		if (this.props.mode == 'schedule' || this.props.mode == 'draws') {
			if (this.props.style === 'empty' && this.props.eventCode == null && this.props.comment !== null) {
				event = this.props.comment;
			}
		}

		return (
			<div>
				{event ? <span className="event-type">{event}</span> : null}
				{round ? <span className="event-round">{round}</span> : null}
			</div>
		);
	}
}
