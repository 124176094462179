/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { useSelector } from 'react-redux';

import IBMwatsonx from 'appdir/components/common-ui/IBMwatsonx/index.js';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: AI Catch Up - PRE/POST match content
 * -----------------------------------------------------------------------------
 */

const AICatchUp = props => {
	logger.log('[AICatchUp] - props:%o', props);
	const enabled = useSelector(state => state['Config']?.enabled);
	
	let data = props.data;
	let bullets = data?.bullets?.bullet ?? [];

	if (data && enabled?.aicatchup) {
		return (
			<div className="ai-catch-up-box top-border">
				{
					data?.style == "pre" ?  (
						<h4 className="rich-text">Match Preview</h4>
					) : (
						<h4 className="rich-text">Match Report</h4>
					)
				}
					{
						data?.title && (
							<h3 className="rich-text">{data.title}</h3>
						)
					}
				<div className="ai-catch-up-content rich-text">
					{
						data?.text && (
							ReactHtmlParser(data['text'])
						)
					}
					
					<ul>
						{bullets?.length > 0 && 
							bullets.map((c, i) => {
							
								return (
									<li key={`sentence-${i}`}>{c}</li>
								)
						})}
					</ul>
				</div>
				<IBMwatsonx onclickClass={true} />
			</div>
		);
	} else {
		return null;
	}
};

export default AICatchUp;
