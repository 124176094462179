import React from "react";
import TableComponent from "appdir/components/common-ui/TableComponent";

const tableContent = (data = []) =>
  data.map(({ year_value, AO, RG, WIM, USO }) => (
    <tr key={year_value}>
      <td>{year_value}</td>
      <td>{AO}</td>
      <td>{RG}</td>
      <td>{WIM}</td>
      <td>{USO}</td>
    </tr>
  ));

const createTableHeaders = () => [
  "",
  "Australian Open",
  "Roland Garros",
  "Wimbledon",
  "US Open"
];

const createMobileTableHeaders = () => ["", "AO", "RG", "WIM", "USO"];

const formatTableData = data => {
  let tableData = data;
  return tableData.map((d, i) => {
    return {
      year: d.year_value,
      ao: d.AO,
      rg: d.RG,
      wim: d.WIM,
      uso: d.USO
    };
  });
};

const PlayerHistory = ({ singles, doubles }) => {
  let tableHeader = createTableHeaders();
  let mobileTableHeader = createMobileTableHeaders();
  let propsSingleData,
    propsDoubleData,
    tableSinglesRowData,
    tableDoublesRowData;
  if (singles) {
    tableSinglesRowData = formatTableData(singles);
    propsSingleData = {
      headers: tableHeader,
      mobileHeaders: mobileTableHeader,
      data: tableSinglesRowData,
      tableContainer: "match-results",
      tableTitle: "Grand Slam Singles Results"
    };
  } else {
    propsSingleData = {
      headers: tableHeader,
      mobileHeaders: mobileTableHeader,
      data: null,
      tableContainer: "match-results",
      tableTitle: "Grand Slam Singles Results"
    };
  }
  if (doubles) {
    tableDoublesRowData = formatTableData(doubles);
    propsDoubleData = {
      headers: tableHeader,
      mobileHeaders: mobileTableHeader,
      data: tableDoublesRowData,
      tableContainer: "match-results",
      tableTitle: "Grand Slam Doubles Results"
    };
  } else {
    propsDoubleData = {
      headers: tableHeader,
      mobileHeaders: mobileTableHeader,
      data: null,
      tableContainer: "match-results",
      tableTitle: "Grand Slam Doubles Results"
    };
  }

  return (
    <span>
      <div className="col-2 inline" style={{ marginBottom: "20px" }}>
        <TableComponent attributes={propsSingleData} />
      </div>
      <div className="col-2 inline">
        <TableComponent attributes={propsDoubleData} />
      </div>
    </span>
  );
};

export default PlayerHistory;
