import React, { Component } from 'react';
import { connect } from 'react-redux';
import BasicDisplay from './BasicDisplay';
import Slider from 'react-slick';
import ButtonGroup from './ButtonGroup';

/**
 * ---------------------------------------
 * FilmStrip - module with a title. the film strip contains a lead content piece (news, photo, video) with a title and a short
 * description. scrolling content pieces beneath, and then a third row of content. Each row of content has a different style treatment, the second and
 * third rows of content will show image, title and short description as well.
 * ---------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['FilmStrip'],
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class FilmStrip extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: true,
		};

		this.measureData = {
			...props.attributes.data,
			type: props.attributes.data.type,
			itemId: props.attributes.data.id,
			itemType: props.attributes.data.type,
			actionType: 'homePageClick',
		};
		logger.log(
			'[FilmStrip] constructor - state:%o, props: %o, measureData:%o',
			this.state,
			this.props,
			this.measureData
		);
	}

	render() {
		let dataItems = this.props.attributes.data.content.items;
		let buttonData = this.props.attributes.data.content.buttons;
		let leadData = [];
		let galleryData = [];
		let subGalleryData = [];
		let windowWidth = this.props.EventsWindow.windowWidth;
		let galleryView, subGalleryView;

		if (windowWidth <= 640) {
			//get size for gallery view
			var moduleSize = windowWidth - 30;
			var imageSize = 157 + 10; /*padding*/
			galleryView = moduleSize / imageSize;

			//get size for sub gallery view
			var componentSize = 240 + 15; /*padding*/
			subGalleryView = moduleSize / componentSize;
		} else {
			//get size for gallery view
			var moduleSize = 659;
			var imageSize = 263 + 20; /*padding*/
			galleryView = moduleSize / imageSize;

			//get size for sub gallery view
			var componentSize = 276 + 10; /*padding*/
			subGalleryView = moduleSize / componentSize;
		}

		var gallerySettings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: galleryView,
			slidesToScroll: 1,
		};

		var subGallerySettings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: subGalleryView,
			slidesToScroll: 1,
		};

		// logger.log('[FilmStrip] render windowWidth:%o, galleryViewCount: %o, subGalleryView: %o', windowWidth, galleryView, subGalleryView);

		// logger.log(
		// 	'[FilmStrip] render props:%o, leadData: %o, galleryData: %o, subGalleryData: %o',
		// 	this.props.attributes,
		// 	leadData,
		// 	galleryData,
		// 	subGalleryData
		// );

		dataItems.map((item, i) => {
			switch (item.style) {
				case 'lead':
					leadData.push(item);
					break;
				case 'galleryItem':
					galleryData.push(item);
					break;
				case 'subGallery':
					subGalleryData.push(item);
					break;
				default:
					break;
			}
		});

		return (
			<div className="filmstrip">
				{leadData.length > 0 ? (
					<div className="lead">
						<BasicDisplay
							data={leadData[0]}
							measureData={{ ...this.measureData, which: 'lead', itemTitle: leadData[0].title }}
						/>
					</div>
				) : null}

				{galleryData.length > 0 ? (
					<div className="gallery">
						<Slider {...gallerySettings}>
							{galleryData.map((item, i) => {
								return (
									<div className="galleryItem" key={`slider${i}`}>
										<BasicDisplay
											data={item}
											measureData={{
												...this.measureData,
												which: `story${i}`,
												itemTitle: item.title,
											}}
										/>
									</div>
								);
							})}
						</Slider>
					</div>
				) : null}

				{subGalleryData.length > 0 ? (
					<div className="subGallery">
						<Slider {...subGallerySettings}>
							{subGalleryData.map((item, i) => {
								return (
									<div className="galleryItem" key={`sub${i}`}>
										<BasicDisplay
											data={item}
											measureData={{
												...this.measureData,
												which: `sub_story${i}`,
												itemTitle: item.title,
											}}
										/>
									</div>
								);
							})}
						</Slider>
					</div>
				) : null}
				{buttonData.length > 0 ? (
					<ButtonGroup
						data={buttonData}
						measureData={{
							...this.measureData,
							which: 'button',
							actionType: 'homepage_button',
							moduleType: this.props.attributes.data.type,
							title: this.props.attributes.data.title,
						}}
					/>
				) : null}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilmStrip);
