import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		//logger.log('[MobileNav] actions.mount data:%o',data);
		logger.log('[MobileNav] actions.mount state:%o', getState());
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			//let menus = Config.cmsData.mainMenu;
			let flagImagePathSmall = Config.otherData.flagImagePathSmall;
			dispatch({
				type: deps.actionTypes.MOBILENAV_MOUNT,
				data: { flagImagePathSmall },
			});
			//logger.log('[MobileNav] mount - data:%o', menus);
		});
	},
	unmount: data => (dispatch, getState, store) => {
		logger.log('[MobileNav] actions.unmount data:%o', data);
		dispatch({ type: deps.actionTypes.MOBILENAV_UNMOUNT, data: data });
	},
};
