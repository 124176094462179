import deps from "dependencies";

import MeasurementUtils from "appdir/lib/analytics";

export default {
  mount: data => (dispatch, getState, store) => {
    dispatch({ type: deps.actionTypes.LIVESHOW_MOUNT });

    logger.log("[LiveShow] action.mount - params: %o", data);

    switch (data.channel) {
      case "us_open_now_live_show":
        MeasurementUtils.dispatchMeasurementCall(
          MeasurementUtils.ACTION_TYPES.pageView,
          {
            pageTitle: "US Open Now Live Show"
          }
        );
        break;
      default:
        break;
    }

    //logger.log('[LiveShow] action.mount - ensureConfig');
    return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
      Config => {
        let values = {
          selected: data.channel,
          basePicPath: Config.otherData.basePicPath,
          usonowApiPath: Config.relatedContent.usoNowRelatedContent
        };
        logger.log("[LiveShow] action.mount - data%o", values);
        dispatch({ type: deps.actionTypes.LIVESHOW_MOUNT, data: values });
      }
    );
  },

  unmount: () => (dispatch, getState, store) => {
    dispatch({ type: deps.actionTypes.LIVESHOW_UNMOUNT });
  },

  select: selected => (dispatch, getState, store) => {
    dispatch({ type: deps.actionTypes.LIVESHOW_SELECT, data: selected });
  },

  filter: data => (dispatch, getState, store) => {
    dispatch({ type: deps.actionTypes.LIVESHOW_FILTER, data: data });
  }
};
