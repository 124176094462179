/**
 LiveShow Initial State
*/

export default {
  selected: "index", //values: 'wimchan', 'espn, 'bbc'
  filters: {
    selected: "",
    title: "SELECT A DAY",
    open: ""
  }
};
