import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.ONCOURT_SCHEDULE_MOUNT:
			newState = {
				...state,
				...action.data,
			};

			// logger.log(
			//   "[SchedulePage] reducers ONCOURT_SCHEDULE_MOUNT - newState:%o",
			//   newState
			// );
			return newState;

		case deps.actionTypes.ONCOURT_SCHEDULE_UNMOUNT:
			newState = {
				...state,
				...action.data,
			};

			// logger.log(
			//   "[SchedulePage] reducers ONCOURT_SCHEDULE_MOUNT - newState:%o",
			//   newState
			// );
			return newState;

		default:
			return state;
	}
};
