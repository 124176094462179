/**
 ResultsPage Initial State
 I think the only initial state we need to set is "all" for all events.   Filter is for events only.  not day.
*/

export default {
	//eventfilter: 'all',
	mode: '',
	isDataLoading: false,
	filters: {
		event: '',
		day: '',
		open: '',
	},
};
