import deps from "dependencies";

export default {
  mount: params => (dispatch, getState, store) => {
    logger.log("[FanWeek] actions.mount - ");

    return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(
      Config => {
        let data = {
          status: "load",
          fanweekPath: Config.cmsData.fanweek
        };

        logger.log("[FanWeek] action.mount - alldata: %o", data);

        dispatch({ type: deps.actionTypes.FANWEEK_LOAD, data: data });
      }
    );
  }
};
