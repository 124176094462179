import React, { Component } from 'react';
import { connect } from 'react-redux';

// import ChartistGraph from 'react-chartist';
import ctPointLabels from 'chartist-plugin-pointlabels';

import op from 'object-path';

// ChartistGraph.plugins = ChartistGraph.plugins || {};
// ChartistGraph.plugins.ctHtmlLabels = function(options) {
// 	return function(chart) {
// 		chart.on('draw', function(context) {
// 			if (context.type === 'label') {
// 				// Best to combine with something like sanitize-html
// 				context.element.empty()._node.innerHTML = context.text;
// 			}
// 		});
// 	};
// };

const mapStateToProps = (state, props) => {
	return {
		...state['RankOverTime'],
		windowSize: state['PageHeader'].windowSize,
		sidepanelExpanded: state['PageHeader'].sidepanelExpanded,
		sidepanel: state['Controller'].sidepanel,
		...props,
	};
};

class RankOverTime extends Component {
	constructor(props) {
		super(props);

		this.chartistRef = React.createRef();
		this.chartistRef2 = React.createRef();

		this.type = 'Line';
		this.data = op.get(this.props, 'data', {});

		if (this.data.graphData) {
			this.data.graphData.series[2] = this.buildPrevMatchSeries();
			this.data.graphData.series[3] = this.buildLastMatchSeries();
			this.high = this.data.graphData
				? Math.min(
						...this.data.graphData.series[0].data,
						...this.data.graphData.series[1].data,
						...this.data.graphData.series[2].data
				  ) - 1
				: 0;
		}

		this.responsiveOptions2 = [
			[
				'screen and (min-width: 991px)',
				{
					axisX: {
						labelInterpolationFnc: (value, index) => {
							'';
						},
					},
					axisY: {},
				},
			],
			[
				'screen and (min-width: 768px) and (max-width: 990px)',
				{
					axisX: {
						labelInterpolationFnc: (value, index) => {
							return '';
						},
					},
					axisY: {},
				},
			],
			[
				'screen and (min-width: 320px) and (max-width: 767px)',
				{
					axisX: {
						labelInterpolationFnc: (value, index) => {
							return '';
						},
					},
					axisY: {},
				},
			],
		];

		this.responsiveOptions1 = [
			[
				'screen and (min-width: 991px)',
				{
					axisX: {
						labelInterpolationFnc: (value, index) => {
							//return value for every other day (is index and even number)
							return index % 2 == 0 ? value : '';
						},
					},
					axisY: {},
				},
			],
			[
				'screen and (min-width: 768px) and (max-width: 990px)',
				{
					axisX: {
						labelInterpolationFnc: (value, index) => {
							//return value for every other day (is index and even number)
							return index % 2 == 0 ? value : '';
						},
					},
					axisY: {},
				},
			],
			[
				'screen and (min-width: 320px) and (max-width: 767px)',
				{
					axisX: {
						labelInterpolationFnc: (value, index) => {
							//return value for every other day (is index and even number)
							return index % 2 == 0 ? value : '';
						},
					},
					axisY: {},
				},
			],
		];

		logger.log('[RankOverTime] - constructor props:%o', props);
	}

	getOptions1(graphData) {
		return {
			fullWidth: true,
			lineSmooth: false,
			showLine: true,
			showPoint: true,
			axisX: {
				labelOffset: {
					x: -8,
					y: 0,
				},
			},
			axisY: {
				offset: 24,
				high: 0,
				low: this.high,
				onlyInteger: true,
				labelInterpolationFnc: (value, index) => {
					return -value;
				},
			},
			plugins: [
				ctPointLabels({
					textAnchor: 'middle',
					labelOffset: {
						x: 0,
						y: 4,
					},
					labelInterpolationFnc: () => {
						//return 'W'; // change to "L" if lost match
						//return this.data.winIndicator;
						return '';
					},
				}),
				//ChartistGraph.plugins.ctHtmlLabels()
			],
		};
	}

	getOptions2(graphData) {
		return {
			fullWidth: true,
			lineSmooth: false,
			showLine: false,
			showPoint: false,
			axisX: {
				labelOffset: {
					x: -8,
					y: 0,
				},
			},
			axisY: {
				offset: 24,
				high: 0,
				low: this.high,
				onlyInteger: true,
				labelInterpolationFnc: (value, index) => {
					return -value;
				},
			},
			plugins: [
				ctPointLabels({
					textAnchor: 'middle',
					labelOffset: {
						x: 0,
						y: 4,
					},
					labelInterpolationFnc: () => {
						//return 'W'; // change to "L" if lost match
						//return this.data.winIndicator;
						return '';
					},
				}),
				//ChartistGraph.plugins.ctHtmlLabels()
			],
		};
	}

	buildPrevMatchSeries() {
		const matchesPlayed = this.data.matchesPlayed;
		const labels = this.data.graphData.labels;
		let data = [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null];

		matchesPlayed.map((match, i) => {
			const index = labels.indexOf(match.matchDate);
			data[index] = this.data.graphData.series[1]['data'][index];
		});

		return {
			name: 'prevmatch-series',
			className: 'ct-series-c prevmatch-series',
			data,
		};
	}
	buildLastMatchSeries() {
		const lastMatchPlayed = this.data.lastMatchPlayed;
		const labels = this.data.graphData.labels;
		let data = [null, null, null, null, null, null, null, null, null, null, null, null, null, null];
		const index = labels.indexOf(lastMatchPlayed);

		//logger.log('[RankOverTime] - buildLastMatchSeries this.data.graphData.series[1][index]', this.data.graphData.series[1].data[index]);

		data[index] = this.data.graphData.series[1]['data'][index];

		// logger.log('[RankOverTime] - buildLastMatchSeries this.data:%o', this.data);
		// logger.log('[RankOverTime] - buildLastMatchSeries lastMatchPlayed:%o', lastMatchPlayed);
		// logger.log('[RankOverTime] - buildLastMatchSeries labels:%o', labels);
		// logger.log('[RankOverTime] - buildLastMatchSeries index:%o', index);

		// logger.log('[RankOverTime] - buildLastMatchSeries data:%o', data);

		return {
			name: 'lastmatch-series',
			className: `ct-series-c lastmatch-series ${this.data.winIndicator == 'W' ? 'won' : 'loss'}`,
			data,
		};
	}

	componentDidUpdate(prevProps, prevState) {}

	render() {
		// if (this.data.graphData) {
		//     this.data.graphData.series[2] = this.buildLastMatchSeries();
		// }
		return (
			<>
				<div className="rank-over-time-container">
					<div className="graph-title">Index Over Time</div>
					{this.data.graphData ? (
						<>
							<div className="line-graph-container">
								{/* <ChartistGraph
									className="chart-1"
									ref={this.chartistRef}
									data={this.data.graphData}
									options={this.getOptions1(this.data.graphData)}
									responsiveOptions={this.responsiveOptions1}
									type={this.type}
								/>
								<ChartistGraph
									className="chart-2"
									ref={this.chartistRef2}
									data={this.data.graphData}
									options={this.getOptions2(this.data.graphData)}
									//responsiveOptions={this.responsiveOptions2}
									type={this.type}
								/> */}
							</div>
							<div className="legend-container">
								<div className="legend pr">
									<div className="bullet line"></div>
									IBM Power Index
								</div>
								<div className="legend won">
									<div className="bullet dot"></div>
									Match Won
								</div>
								<div className="legend tour">
									<div className="bullet dashed"></div>
									{this.data.event.toUpperCase()} Tour Ranking
								</div>
								<div className="legend loss">
									<div className="bullet dot"></div>
									Match Lost
								</div>
							</div>
						</>
					) : null}
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps)(RankOverTime);
