import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import deps from 'dependencies';
// import get from "lodash/get";
// import isEmpty from "lodash/isEmpty";
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['OpenQuestions'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.OpenQuestions.mount()),
});

class OpenQuestions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			showModal: true,
		};
		this.listener;

		this.genChip = this.genChip.bind(this);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Open Questions',
		});
		logger.log('[OpenQuestions] constructor - props:%o', props);
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('mount')) {
			this.props.mount();
		}
		this.listener = window.addEventListener('keydown', e => this.toggleModal(e));
	}

	componentWillUnmount() {
		window.removeEventListener(this.listener);
	}

	genChip(link, img, desc) {
		return (
			<div className="debate_chip_wrapper">
				<Link to="">
					<div className="debate_chip">
						<div className="video_img">
							<div className="playBtn" />
						</div>
						<div className="caption">{desc}</div>
					</div>
				</Link>
			</div>
		);
	}

	toggleModal(e) {
		let showModal = this.state.showModal == true ? false : true;
		if (e.key == 'Escape') {
			showModal = false;
		} else if (e.key) {
			return;
		}
		this.setState({ showModal });
	}

	render() {
		logger.log('[OpenQuestions] render - this.state: %o', this.state);
		logger.log('[OpenQuestions] render - this.props: %o', this.props);

		let header_attributes = {
			headerType: 'visit',
			title: 'Open Questions',
			metaTitle: 'Open Questions ',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
		};

		// if (!isEmpty(get(this.props, "mapMarkers", []))) {
		if (1 == 1) {
			//replace this with check for data
			return (
				<Fragment>
					{this.state.showModal && (
						<div className="openquestions_modal">
							<div className="openquestions_modal_content">
								<div className="watson_animated" />
								<div className="modal_header">Open Questions with Watson</div>
								<div className="modal_text">
									We’re using the AI of IBM Watson to analyze millions of articles, blogs and expert
									opinions on hot tennis topics. Watson uses natural language processing to identify
									themes, claims and arguments about a particular statement. It summarizes those
									arguments into agree or disagree categories, then generates a cogent narrative. Add
									your voice to the discussion!
								</div>
								<button className="open_question_submit" onClick={e => this.toggleModal(e)}>
									Got It
								</button>
							</div>
						</div>
					)}
					<div className="openquestions general_content">
						<div className="open_questions_header">
							<span>Open Questions with Watson</span>
						</div>
						<div className="question_chips">{this.genChip('/', '', 'Serena Williams Plays Tennis')}</div>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<LoadingIndicator />
				</Fragment>
			);
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(OpenQuestions);
