import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import throttle from 'lodash/throttle';
import replace from 'lodash/replace';
import parse from 'html-react-parser';
import { getCleanLink } from 'appdir/components/general/Util';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['SmartSearch'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SmartSearch.mount()),
	unmount: () => dispatch(deps.actions.SmartSearch.unmount()),
	showResults: flag => dispatch(deps.actions.SearchPage.showSearch(flag)),
});

class SmartSearch extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.props.data, highlightedResult: -1, showSmartSearch: false, nav: false };
		this.setBlur = true;
		this.lastTerm = '';
		this.onFocus = this.onFocus.bind(this);
	}

	componentDidMount() {
		this.props.mount();
		window.addEventListener('keydown', throttle(this.keyDown.bind(this), 0), false);
		window.addEventListener('click', throttle(this.clickHandler.bind(this), 0), false);
	}

	onFocus(e) {
		if (this.lastTerm != this.state.data.value) {
			this.lastTerm = this.state.data.value;
			//logger.log('[SmartSearch] onFocus - term:%o', this.state.data.value);
			MeasurementUtils.dispatchMeasurementCall('smartsearch_term', this.lastTerm);
		}
	}

	clickHandler(e) {
		let activeEl = document.activeElement;
		let bodyEl = document.getElementsByTagName('body')[0];
		let searchResultEl = document.getElementById('wim-search-results-id');
		if (activeEl != bodyEl && activeEl != searchResultEl) {
			this.props.showResults(false);
			this.setState({
				showSmartSearch: false,
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return { ...prevState, ...nextProps };
		});
	}

	keyDown(e) {
		let keyPressValue = e.which;
		let searchResultEl = document.getElementById('wim-search-results-id');
		let searchInputEl = document.getElementById('wimbledon-search-text');
		let activeEl = document.activeElement;
		switch (keyPressValue) {
			case 38:
				if (activeEl == searchResultEl || activeEl == searchInputEl) {
					e.preventDefault();
					this.updateHighlightedResult(-1);
				}
				break;
			case 40:
				if (activeEl == searchResultEl || activeEl == searchInputEl) {
					e.preventDefault();
					this.updateHighlightedResult(1);
				}
				break;
			case 13:
				if (activeEl == searchResultEl) {
					e.preventDefault();
					this.setState({ nav: true });
				} else {
					this.props.showResults(false);
					this.setState({ showSmartSearch: false });
				}
				break;
			default:
				return false;
		}
	}

	updateHighlightedResult(update) {
		if (this.state.searchResults) {
			if (this.state.searchResults.response.docs.length > 0) {
				if (this.setBlur) {
					document.getElementById('wimbledon-search-text').blur();
					document.getElementById('wim-search-results-id').focus();
					this.setBlur = false;
				}
				let resultLength = this.state.searchResults.response.docs.length - 1;
				let updateIndex = this.state.highlightedResult + update;
				if (updateIndex <= resultLength && updateIndex >= 0) {
					this.setState(prevState => {
						return { ...prevState, highlightedResult: updateIndex };
					});
				}
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.data.queryPath && this.state.data.value && prevState.data.value !== this.state.data.value) {
			this.setBlur = true;
			this.setState(prevState => {
				return { ...prevState, highlightedResult: -1 };
			});

			let query = this.state.data.queryPath;
			let term = this.state.data.value;
			if (term.includes(' ')) {
				term = `"${term}"`;
			} else {
				term = `${term}`;
			}

			//logger.log('[SmartSearch] getResults - state:%o', this.state);
			logger.log('[SmartSearch] getResults - term:%o', term);

			query = replace(query, new RegExp('<SEARCH_TEXT>', 'g'), term);
			//logger.log('[SmartSearch] getResults - query:%o', query);

			//service call
			deps.services.SearchPage.fetchSearchResults(query).then(result => {
				//logger.log('[SmartSearch] getResults - data:%o', result);
				result.response.docs = result.response.docs.filter(d => d['metatag.resulttitle_str']);
				this.props.showResults(true);
				this.setState({
					searchResults: result,
					showSmartSearch: true,
					nav: false,
				});
			});
		}
	}

	highlightText(searchTerm, text) {
		if (text) {
			return `<span>${text.replace(searchTerm, `<span class='bold-text'>${searchTerm}</span>`)}</span>`;
		}
		return null;
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	getResults() {
		this.props.showResults(false);
		this.setState({ showSmartSearch: false });
		this.state.submitFn(this.state.data.value);
	}

	render() {
		let url;
		let cleanURL;
		if (this.state.highlightedResult !== -1) {
			url = this.state.searchResults.response.docs[this.state.highlightedResult].url;
			cleanURL = getCleanLink(url);
			//logger.log('[SmartSearch] render - cleanUrl:%o', cleanURL);
		}

		return this.state.searchResults && this.state.showSmartSearch ? (
			this.state.searchResults.response.docs.length > 0 ? (
				!this.state.nav ? (
					<div tabIndex="1" id="wim-search-results-id" className="wim-search-results" onFocus={this.onFocus}>
						<div className="wim-search-results-header">POPULAR RESULTS</div>
						{this.state.searchResults.response.docs.map((d, i) => {
							logger.log('[SmartSearch] render - doc:%o', d);
							return (
								<WimLink key={i} to={d.url_str} style="white">
									<div
										className={`result-article-title ${
											this.state.highlightedResult === i ? 'highlight' : ''
										}`}>
										{/*ReactHtmlParser*/ parse(
											this.highlightText(this.state.data.value, d['metatag.resulttitle_str'])
										)}
									</div>
								</WimLink>
							);
						})}
						<div
							className="wim-results-more"
							onClick={() => {
								this.getResults();
							}}>
							<i className="wim-icon-search predictive" />
							<div className="more-results-text">FOR MORE RESULTS ></div>
						</div>
					</div>
				) : cleanURL.external ? (
					<EventsRedirect to={url} />
				) : (
					<EventsRedirect to={cleanURL.url} />
				)
			) : null
		) : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartSearch);
