/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import AdTag from 'appdir/components/general/AdTag';

/**
 * -----------------------------------------------------------------------------
 * React Component: AdChip 
 * 
 * To use this component, you need to create a config in config_web.json
 * 
 * Config looks like this (just add a new one under mip):  Description of everything is in the constructor
 * 
 * "adConfig" : {
        "mip": {
            "dfpNetworkId":"5681",
            "sizeMapping":[ 
                {"viewport": [1024, 0], "sizes":[[970, 90]]},
                {"viewport": [768, 0], "sizes":[[728, 90]]},
                {"viewport": [0, 0], "sizes":[[320, 50]] }
            ],
            "shouldRefresh" : true,
            "sizes" : [[970,90],[728,90],[320,50]],
            "isResponsive" : true,
            "adUnit" : "2016_Desktop_ROS/leaderboard",
            "refreshRate" : 30000
        }
        "yourConfig": {
            ...
        }
    }
 * 
 * -----------------------------------------------------------------------------
 */
const AdChip = (props) => {
	const adConfig = useSelector(state => state['Config']?.adConfig);
	const windowSize = useSelector(state => state['Controller']?.windowSize);

	return props?.data?.adType && adConfig ? (
		<AdTag
			key={props.id}
			adConfig={adConfig[props.data.adType]}
			dfpNetworkId={adConfig.dfpNetworkId}
		/>
	) : null;
}

export default AdChip;
