import React, { Component } from 'react';
import ReactHtmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
import EventsLink from 'appdir/components/general/EventsLink';

class DiningCard extends Component {
	constructor(props) {
		super();
	}

	render() {
		let attributes = this.props.data;
		if (!attributes) {
			return null;
		}
		let imagesData = attributes?.images[0];
		// console.log('attributes', attributes);

		return (
			<div className="dining-card-wrapper">
				<div className="dining-image-wrapper">
					{/* check for image url and display */}
					{!isEmpty(imagesData) && imagesData?.url ? (
						<img src={imagesData?.url} alt={imagesData?.caption} />
					) : (
						<img src="/assets/images/misc/noimage.png" />
					)}
				</div>

				<div className="dining-card-body">
					{/* if a title exists, write it */}
					{attributes?.title ? <h4 className="dining-card-title">{attributes['title']}</h4> : <h4></h4>}
					{attributes?.map ? (
						<div className="find-on-map">
							<EventsLink to={attributes?.map?.link}>
								<i className="icon-grounds-map"></i>
								{attributes?.map?.text}
							</EventsLink>
						</div>
					) : null}

					{attributes?.description && attributes?.description !== '' ? (
						<div className="dining-card-description">{ReactHtmlParser(attributes['description'])}</div>
					) : null}
					{/* test icon for grounds map */}

					{/* if link and button text exist, add a link here */}
					{attributes?.link &&
					attributes?.link !== '' &&
					attributes?.button_text &&
					attributes?.button_text !== '' ? (
						<div className="reserve">
							<EventsLink to={attributes['link']}>{attributes['button_text']}</EventsLink>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default DiningCard;
