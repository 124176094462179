import React, { Component } from 'react';
import { isMobileSafari, isBrowser } from 'react-device-detect';
import SmartSearch from 'appdir/components/common-ui/SmartSearch';
import { fetch } from 'appdir/components/general/Util';

class SearchBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState(prevState => {
			return Object.assign({}, prevState, nextProps);
		});
	}

	getResults() {
		let dataPath = this.props.solrPath.replace(this.state.value);

		fetch(dataPath).then(result => {
			logger.log('[SearchPage] getResults - data:%o', result);
			this.setState({
				data: result,
			});
		});
	}

	handleChange(evt) {
		logger.log('[SearchBox] handleChange - value:%o', this.state.value);
		this.setState({
			value: evt.target.value,
		});
	}

	getFormValue(evt) {
		if (evt) {
			evt.preventDefault();
		}
		this.props.onEnter(this.state.value);
		logger.log('[SearchBox] handleChange - value:%o', this.state.value);
	}

	handleKeyPress(evt) {
		if (evt.key == 'Enter') {
			logger.log('[SearchBox] handleKeyPress - value:%o', this.state.value);
			this.props.onEnter(this.state.value);
		}
	}
	handleBlur(evt) {
		if (isMobileSafari) {
			this.props.onEnter(this.state.value);
		}
	}
	render() {
		//let matchData = this.props.attributes;
		return (
			<div className="search-box">
				<div className="search-wrapper">
					<input
						id="wimbledon-search-text"
						type="text"
						value={this.state.value}
						onChange={this.handleChange}
						onKeyPress={this.handleKeyPress}
						onBlur={this.handleBlur}
						placeholder="Search USOpen.org"
					/>
					{/* <input id="wimbledon-search-text" type="text" value={this.state.value} onKeyPress={this.handleKeyPress} onBlur={this.handleBlur}  placeholder="Search Wimbledon.com"/> */}
					{isBrowser ? (
						<SmartSearch
							data={{
								value: this.state.value,
								submitFn: this.props.onEnter.bind(this),
							}}
						/>
					) : null}

					<i className="icon-search" />
				</div>
			</div>
		);
	}
}
export default SearchBox;
