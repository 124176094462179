import React from 'react';

const DataTabs = props => {
	logger.log('[DataTabs] props:%o', props);

	const { data = {} } = props;
	const { tabsList = [], className, callback } = data;

	return (
		<div className={`data-tabs ${className ? className : ''}`}>
			{tabsList.map((tab, index) => {
				return (
					<div key={index} onClick={e => callback(tab.id)} className={tab.selected ? 'tab show' : 'tab'}>
						<span>{tab.label}</span>
					</div>
				);
			})}
		</div>
	);
};

export default DataTabs;
