import React, { useState, useEffect } from 'react';
import EventsLink from 'appdir/components/general/EventsLink';
import LightBlueBar from '../LightBlueBar/LightBlueBar';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

function BlueBarMenu(props) {
	const router = useSelector(state => state['Router']);
	const pathname = `${router?.pathname}${router?.hash}`;
	const EventsWindow = useSelector(state => state['WindowSize']?.EventsWindow);

	logger.log('[BlueBarMenu] EventsWindow:%o, props:%o', EventsWindow, props);

	const onSelect = path => {
		const dataObj =
			// loop through the data to see if we're clicking on the top level links or submenu links
			props.menuData.find(item => item?.link?.url.toLowerCase() === path.toLowerCase()) ||
			props.menuData.find(item => item?.link?.url.toLowerCase() === props?.pathName.toLowerCase()) ||
			props.menuData.find(item => item?.menu && item.menu.find(subitem => subitem?.url.toLowerCase() === path.toLowerCase())) ||
			props.menuData.find(
				item => item?.third_menu && item.third_menu.find(subitem => subitem?.link?.url.toLowerCase() === path.toLowerCase())
			) ||
			(props.mode === 'watch' &&
				(props.menuData.find(item => item.title === 'Live Streaming') ||
					props.menuData.find(item => item.title === 'Live Practice Courts') ||
					props.menuData.find(item => item.title === 'Stars of the Open') ||
					props.menuData.find(item => item.title === 'Legends of the Open') ||
					props.menuData.find(item => item.title === 'Equality Panel')));

		setSelectedOption(dataObj);
	};

	const [selectedOption, setSelectedOption] = useState(pathname);

	useEffect(() => {
		onSelect(pathname);
	}, [pathname, props.menuData]);

	const handleChange = e => {
		props.history.push(e.target.value);
		const dataObj = props.menuData.find(item => item.link.url === e.target.value);
		setSelectedOption(dataObj);
		// console.log('handlechange bb', e.target.value);
	};

	const dropdownDefault =
		props.mode == 'watch' ? 'Filter by Category' : props.mode == 'fan-week' ? 'View More' : null;

	if (props.mode === 'visit' || props.mode === 'watch' || props.mode == 'fan-week') {
		return (
			<div>
				{(EventsWindow?.numericWindowSize < 2 && props.mode !== 'visit') ||
				(EventsWindow?.numericWindowSize < 3 && props.mode === 'fan-week') ||
				(EventsWindow?.numericWindowSize < 3 && props.mode === 'watch') ? (
					<div className="watch-blue-bar-wrapper">
						<EventsLink
							className="blue-bar-menu-event"
							to={selectedOption?.link?.url}
							key={selectedOption?.title}
							onClick={() => onSelect(selectedOption?.link?.url)}>
							<div data-text={selectedOption?.title} className={`blue-bar-menu-event active`}>
								{selectedOption?.title}
							</div>
						</EventsLink>
						<select onChange={handleChange} className="watch-blue-bar-dropdown-selected" value={2}>
							<option value={dropdownDefault} default className="watch-blue-bar-dropdown-item default">
								{dropdownDefault}
							</option>
							{props.menuData.map(option => (
								<option
									key={option.title}
									value={option.link.url}
									className="watch-blue-bar-dropdown-item">
									{option.title}
								</option>
							))}
						</select>
					</div>
				) : (
					<div className="blue-bar-menu-wrapper">
						{props.menuData
							? props.menuData.map(option => {
									return (
										<div key={`div-${option.title}`}>
											<EventsLink
												className="blue-bar-menu-event"
												to={option.link.url}
												key={option.title}
												onClick={() => onSelect(option.link.url)}>
												<div
													data-text={option.title}
													className={`blue-bar-menu-event ${selectedOption?.title ===
														option.title && 'active'}`}>
													{option.title}
												</div>
											</EventsLink>
										</div>
									);
							  })
							: null}
					</div>
				)}
				{(selectedOption && props.mode == 'visit' && selectedOption?.title !== 'A-Z Guide') ||
				(props.mode === 'watch' &&
					selectedOption?.title !== 'Live Streaming' &&
					selectedOption?.title !== 'Live Practice Courts') ? (
					<LightBlueBar menuData={selectedOption?.menu || selectedOption?.third_menu} isUrlRedirect={true} />
				) : null}
			</div>
		);
	} else {
		return null;
	}
}

export default withRouter(BlueBarMenu);
