/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsLink from 'appdir/components/general/EventsLink';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: ScheduleBuyTickets
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SchedulePage'],
		favorites: state['Controller'].favorites,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({});

class ScheduleBuyTickets extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let data = this.props.attributes;

		// let scheduleContentData = data.tournData.tournament_schedule.draws.maindraw.dates;
		let scheduleContentData =
			this.props.filters.day < 7
				? data.tournData.tournament_schedule.draws.qualifying.dates
				: data.tournData.tournament_schedule.draws.maindraw.dates;

		let futureSessions = [];
		scheduleContentData.map(draw => {
			if (String(draw.tournDay) === String(this.props.filters.day)) {
				futureSessions.push(draw);
			}
		});
		let hasFooterComments = false;
		let fcData = [];
		// logger.log(
		// 	'[ScheduleBuyTickets] render this:%o, hasFooterComments:%o, fcData:%o, data: %o, scheduleContentData: %o',
		// 	this,
		// 	hasFooterComments,
		// 	fcData,
		// 	data,
		// 	scheduleContentData
		// );
		if (data?.scheduleMatches && data?.scheduleMatches?.footerComment !== null) {
			hasFooterComments = true;
			fcData = data.scheduleMatches?.footerComment?.split('|');
		}

		if (data) {
			//round info
			return (
				<>
					<React.Fragment>
						<div className="column-layout future-tickets-schedule">
							{/* add background-color: #fff and a border */}
							<div className="schedule-comments">
								The match schedules are released the day prior to play, but you can check for available
								tickets to the events below.
								<EventsLink
									to="https://www.ticketmaster.com/usopentennis"
									external={true}
									className="future-events-tickets">
									<div className="see-tickets">
										<i className="icon-tickets" />
										<span className="see-tickets-btn">See tickets</span>
									</div>
								</EventsLink>
							</div>
						</div>
						<div className="column-layout future-events">
							<div className="future-events-header">
								<span className="time">TIME</span>
								<span className="event">EVENT</span>
							</div>
							<div className="future-events-wrapper">
								{futureSessions?.map((sessions, i) => {
									return (
										<div>
											{sessions.session.map(session => {
												{
													return session.times.map(time => {
														{
															return time.events.map(event => {
																return (
																	<div className="future-event">
																		<span className="start-time">{time.start}</span>
																		<span>{event}</span>
																	</div>
																);
															});
														}
													});
												}
											})}
										</div>
									);
								})}
							</div>
						</div>

						{hasFooterComments === true ? (
							<div className="column-layout">
								<div className="footer-comments">
									{fcData.map((comment, i) => {
										return (
											<div className="footer-comment-item" key={`comment-${i}`}>
												{comment}
											</div>
										);
									})}
								</div>
							</div>
						) : null}
					</React.Fragment>
				</>
			);
		} else {
			return (
				<section className="wrapper scorespage">
					<div className="content-main">
						<LoadingIndicator />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleBuyTickets);
