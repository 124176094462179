/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'html-react-parser';
import { fetch, getQuerystringHash } from 'appdir/components/general/Util';
import op from 'object-path';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import ButtonGeneral from 'components/cms/ButtonGeneral';
import MeasurementUtils from 'appdir/lib/analytics';
import remove from 'lodash/remove';

/**
 * -----------------------------------------------------------------------------
 * React Component: IntlTVSched
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['IntlTVSched'],
		domain: op.get(state['Config'], 'relatedContent.domain'),
		...props,
	};
};

class IntlTVSched extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: { open: '' },
			selected: '',
		};

		this.parsedCountry = '';
		this.loaded = false;
		this.onOpen = this.onOpen.bind(this);
		this.setCountry = this.setCountry.bind(this);
		// logger.log('[IntlTVSched] - props:%o', props);
	}

	componentDidMount() {
		// logger.log('[IntlTVSched] componentDidMount - state:%o, props:%o', this.state, this.props);
		//get query string values
		this.parsedCountry = getQuerystringHash();

		if (!this.loaded) {
			this.loaded = true;

			let dataPath = this.props.domain + this.props.data?.feed;
			// logger.log('[IntlTVSched] - componentDidMount dataPath%o', dataPath);

			fetch(dataPath)
				.then(results => {
					this.setState({
						tvData: results.broadcasters,
						countries: results.countries,
					});
				})
				.catch(error => {
					logger.log('[IntlTVSched] componentDidMount error loading data: %o', error);
					this.setState({ hasError: true });
				});
		}
	}

	componentDidUpdate() {
		// logger.log('[IntlTVSched] - componentDidUpdate:%o', props);
	}

	onOpen(which) {
		if (this?.props?.onOpen) {
			this?.props?.onOpen(which);
		}
	}

	setCountry(e) {
		// logger.log('[IntlTVSched] render - setCountry:%o', e);
		MeasurementUtils.dispatchMeasurementCall('CountryFilter', {
			e,
		});
		this.parsedCountry = '';

		this.setState({
			selectedCountry: e.target.value,
		});
	}

	render() {
		// logger.log('[IntlTVSched] render - this:%o', this);
		let filteredContent = [];

		if (this.state?.tvData) {
			let countryDropdownVals;

			let selectCountryTitle = [
				{ name: 'Filter by Country', value: 'Filter by Country' },
				{ name: 'All Countries', value: 'All' },
			];

			//get country default - usa
			let defaultCountry = { name: 'United States' };
			let defaultContent = [];
			this.state.tvData.map(j => {
				if (j.countryList.includes('United States')) {
					defaultContent.push(j);
				}
			});
			// logger.log('[IntlTVSched] render - defaultContent:%o', defaultContent);

			if (this.state.countries.length > 0) {
				countryDropdownVals = this.state.countries.map((item, index) => {
					return { name: item, value: item };
				});
			}

			//remove default country from country list
			remove(countryDropdownVals, country => {
				return country.name.includes(defaultCountry.name);
			});

			let countriesDataArr = selectCountryTitle.concat(countryDropdownVals);
			// logger.log('[IntlTVSched] render - countriesDataArr:%o, defaultCountry:%o, countryDropdownVals:%o', countriesDataArr, defaultCountry, countryDropdownVals);

			this.countriesData = {
				options: countriesDataArr,
			};

			if (this.state.tvData.length > 0) {
				//remove default country from display list
				let otherCountries = [];
				this.state.tvData.map(c => {
					if (c.countryList[0] !== defaultCountry.name) {
						if (!c.countryList[0].includes(defaultCountry.name)) {
							otherCountries.push(c);
						}
					}
				});

				if ((this.parsedCountry !== '' && this.parsedCountry.length > 2) || null) {
					otherCountries.map(c => {
						if (c.countryList.includes(this.parsedCountry)) {
							filteredContent.push(c);
						}
					});
				} else if (
					this.state.selectedCountry &&
					this.state.selectedCountry !== 'All' &&
					this.state.selectedCountry !== this.countriesData.options[0].name
				) {
					//filter by country
					otherCountries.map(c => {
						if (c.countryList.includes(this.state.selectedCountry)) {
							filteredContent.push(c);
						}
					});
				} else {
					filteredContent = otherCountries;
				}
			}

			//display for select box
			let displayCountry = this.countriesData.options[0].name;

			if (this.parsedCountry !== '') {
				if (filteredContent.length > 0) {
					displayCountry = this.parsedCountry;
				}
			} else if (this.state?.selectedCountry) {
				displayCountry = this.state?.selectedCountry;
			}
			// logger.log('[IntlTVSched] render - filteredContent:%o, displayCountry:%o', filteredContent, displayCountry);

			return (
				<div className="tv_intl">
					<div className="default-wrapper">
						<table className="table">
							<thead className="headerTitle">
								<tr>
									<td className="header" colSpan={5}>
										{defaultCountry.name}
									</td>
								</tr>
							</thead>
							<tbody className="title">
								<tr>
									<td className="row-spacer" />
									<td className="region">Region</td>
									<td className="country">Country</td>
									<td className="channel">Network</td>
									<td className="row-spacer" />
								</tr>
							</tbody>
							<tbody className="networks">
								<tr key={'network usa'}>
									<td className="row-spacer" />
									<td>{ReactHtmlParser(defaultContent[0].region)}</td>
									<td>
										<div className="td-spacer">{ReactHtmlParser(defaultContent[0].countries)}</div>
									</td>
									<td className="channel">
										<div className="title">{defaultContent[0].title}</div>
										<div className="info">
											<div className="img">
												{defaultContent[0].images && defaultContent[0].images.length > 0 ? (
													<img
														src={defaultContent[0].images[0].url}
														alt={defaultContent[0].images[0].caption}
													/>
												) : null}
											</div>
											<div className="watch">
												{defaultContent[0].link ? (
													<ButtonGeneral
														data={{
															style: 'uso-button uppercase blue round',
															title: 'Watch',
															link: defaultContent[0].link,
														}}
													/>
												) : null}
											</div>
										</div>
									</td>
									<td className="row-spacer" />
								</tr>
							</tbody>
							<tfoot>
								<tr className="empty">
									<td colSpan="6">&nbsp;</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<div className="countries-wrapper">
						<table>
							<thead className="headerTitle">
								<tr>
									<td className="header" colSpan={5}>
										All other Countries
									</td>
								</tr>
							</thead>
						</table>
						<div className="intl-broadcasters-select-wrapper">
							<select onChange={this.setCountry} className="intl-broadcasters-select-menu">
								{this.countriesData.options.map(option => (
									<option key={option.name} value={option.value}>
										{option.name}
									</option>
								))}
							</select>
						</div>
					</div>

					<table className="table">
						<tbody className="title">
							<tr>
								<td className="row-spacer" />
								<td className="region">Region</td>
								<td className="country">Country</td>
								<td className="channel">Network</td>
								<td className="row-spacer" />
							</tr>
						</tbody>
						{filteredContent.length > 0 ? (
							<tbody className="networks">
								{filteredContent.map((network, i) => {
									return (
										<tr key={'network' + i}>
											<td className="row-spacer" />
											<td>{ReactHtmlParser(network.region)}</td>
											<td>
												<div className="td-spacer">{ReactHtmlParser(network.countries)}</div>
											</td>
											<td className="channel">
												<div className="title">{network.title}</div>
												<div className="info">
													<div className="img">
														{network.images && network.images.length > 0 ? (
															<img
																src={network.images[0].url}
																alt={network.images[0].caption}
															/>
														) : null}
													</div>
													<div className="watch">
														{network.link ? (
															<ButtonGeneral
																data={{
																	style: 'uso-button uppercase blue round',
																	title: 'Watch',
																	link: network.link,
																}}
															/>
														) : null}
													</div>
												</div>
											</td>
											<td className="row-spacer" />
										</tr>
									);
								})}
							</tbody>
						) : (
							<tbody>
								<tr>
									<td colSpan="5">That country is not available.</td>
								</tr>
							</tbody>
						)}
						<tfoot>
							<tr className="empty">
								<td colSpan="6">&nbsp;</td>
							</tr>
						</tfoot>
					</table>
				</div>
			);
		} else if (!this.state.tvData && !this.state.hasError) {
			return null;
		} else {
			return (
				<section className="wrapper tv_intl">
					<div className="error-text">
						<GenericError message="The current list is unavailable." />
					</div>
				</section>
			);
		}
	}
}

export default connect(mapStateToProps)(IntlTVSched);
