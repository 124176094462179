import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import { values } from 'appdir/main';
import SelectMenu from 'appdir/components/common-ui/SelectMenu';
import MeasurementUtils from 'appdir/lib/analytics';
import { getEventName, getRoute } from './DrawsUtils';
import filter from 'lodash/filter';

const mapStateToProps = (state, props) => {
	return {
		...state['DrawsPage'],
		scoringConfig: state['Config']?.scoringConfig,
		drawsConfig: state['Config']?.otherData?.draws,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.DrawsPage.filter(settings)),
	mount: params => dispatch(deps.actions.DrawsPage.mount(params)),
	resultsFilter: settings => dispatch(deps.actions.ResultsPage.filter(settings)),
});

//const pdfOnlyEvents = [];

class EventSelect extends Component {
	constructor(props) {
		super(props);

		logger.log('[EventSelect] constructor:%o', this.props);

		//let excludeList = 'ED';

		/* set a flag to indicate which event to exluded from MenuSelect to set nav
		 * so it doesn't change URL when selected from dropdown and open PDF */
		// let eventsDropdownVals = props.events.map(item => {
		// 	let baseDropdownVals = { name: item.long, value: item.code };

		// 	if (pdfOnlyEvents.indexOf(item.code) > -1) {
		// 		let excludes = { exclude: true };
		// 		baseDropdownVals = { ...baseDropdownVals, ...excludes };
		// 	}

		// 	return baseDropdownVals;
		// });

		// remove ED from dropdown if there is in eventNames.ssi
		// eventsDropdownVals = eventsDropdownVals.filter(event => {
		// 	if (excludeList.indexOf(event.value) < 0) {
		// 		return true;
		// 	}
		// });

		this.state = {
			eventsDropdownVals: [],
		};

		this.year = values.tournamentYear;

		this.setEvent = this.setEvent.bind(this);
		this.onOpen = this.onOpen.bind(this);

		//logger.log('[EventSelect] constructor props:%o state:%o', props, this.state);
	}

	componentDidMount() {
		if (this.props.drawsConfig) {
			this.setDropdownVals();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.drawsConfig !== this.props.drawsConfig ||
			(this.props.drawsConfig && prevProps.events !== this.props.events)
		) {
			this.setDropdownVals();
		}
	}

	setDropdownVals() {
		//logger.log('[EventSelect] setDropdownVals');
		let eventsDropdownVals = this.props.events.map(item => {
			let baseDropdownVals = { name: item.long, value: item.code };
			if (this.props.sb_code) {
				baseDropdownVals = { name: item.long, value: item.sb_code };
			}

			if (this?.props?.drawsConfig?.pdfOnlyEvents?.indexOf(item.code) > -1) {
				let excludes = { exclude: true };
				baseDropdownVals = { ...baseDropdownVals, ...excludes };
			}

			return baseDropdownVals;
		});

		// remove ED from dropdown if there is in eventNames.ssi
		eventsDropdownVals = eventsDropdownVals.filter(event => {
			if (this.props.drawsConfig.dropdownExcludeEvents.indexOf(event.value) < 0) {
				return true;
			}
		});

		this.setState({
			eventsDropdownVals: eventsDropdownVals,
		});
	}

	setEvent(event) {
		//logger.log('[EventSelect] basePath:%o event:%o', this.state.basePath, event);

		MeasurementUtils.dispatchMeasurementCall('eventSelect', {
			event,
			eventName: getEventName(event, this?.props?.scoringConfig?.eventNames),
			webview: window.webview ? true : false,
		});

		if (this?.props?.drawsConfig?.pdfOnlyEvents?.indexOf(event) > -1) {
			window.open(`/en_US/scores/draws/${this.year}_${event}_draw.pdf`);
		} else if (this.props.scoresSubnav) {
			this.props.resultsFilter({ event });
		} else {
			if (this.props.mode == 'draws') {
				this.setState({
					nav: `${getRoute(event, this?.props?.scoringConfig?.eventNames)}`,
				});
			} else {
				this.setState({
					nav: this.state.basePath + '?event=' + event,
				});
			}
		}
	}

	onOpen(which) {
		this.props.filter({
			open: which,
		});

		MeasurementUtils.dispatchMeasurementCall('eventSelect', {
			open: which,
			webview: window.webview ? true : false,
		});
	}

	onClose(which) {}

	render() {
		//logger.log('[EventSelect] render - this:%o', this);
		let selectTitle = [{ name: 'Filter by Event', value: '' }];
		let eventsDataArr = selectTitle.concat(this.state.eventsDropdownVals);
		let eventsData = {
			options: eventsDataArr,
		};

		let isInFilter = filter(eventsDataArr, o => {
			return o.value == this.props.filters.event;
		});

		let selected = '';
		if (isInFilter?.length > 0) {
			selected = this.props.filters.event;
		}

		return (
			<div className="scores-select-menu">
				<SelectMenu
					name="events"
					attributes={eventsData}
					selected={selected}
					show={this.props.filters.open == 'events'}
					showFilterSelect={true}
					onSelected={this.setEvent}
					onOpen={this.onOpen}
					onClose={this.onClose}
				/>
				{this.state.nav ? <EventsRedirect to={this.state.nav} /> : null}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSelect);
