import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// this will call the reducer in controller which will set the redux state
			//logger.log('[LiveScores] mount state.status:%o',getState().LiveScores.status);
			dispatch({
				type: deps.actionTypes.LIVESCORES_MOUNT,
				data: { status: 'load', basePicPath: Config.otherData.basePicPath },
			});
		});
	},
	unmount: () => (dispatch, getState, store) => {
		// when unloading the mip page, reset Scoring data in the redux state
		// also reset the status of the page to null
		// I think this will change later.  May not need to reset scoring data in all cases.
		// Cannot reset Scoring data in Controller, because Controller puts everything in localstorage and
		// we don't want to do that.
		status = null;
		dispatch({
			type: deps.actionTypes.LIVESCORES_UNMOUNT,
			data: { status: status },
		});
	},
};
