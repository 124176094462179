/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
// import { measureApp } from 'appdir/components/general/Analytics';
import MeasurementUtils from 'appdir/lib/analytics';
import TournamentDaySelector from 'appdir/components/common-ui/TournamentDaySelector';
/**
 * -----------------------------------------------------------------------------
 * React Component: ResultsPage
 * this is the completed matches page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['ResultsPage'],
		currentDay: state['ActiveData']?.currentDay,
	};
	//return Object.assign({}, state['ResultsPage'], props);
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({
	filter: settings => dispatch(deps.actions.ResultsPage.filter(settings)),
});

class DaySelect extends Component {
	constructor(props) {
		super(props);

		this.dayNavOptions = props.days.map(item => ({
			value: item.tournDay.toString(),
			type: item.quals ? 'quali' : 'tourn',
			textName: item.displayDay,
			dropdownName: item.messageShort,
			title: item.messageShort,
			sort: item.tournDay,
			tournEvents: item.events,
			disabled: item.events.length == 0 ? true : false,
		}));

		this.setDay = this.setDay.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.setMode = this.setMode.bind(this);

		logger.log('[DaySelect] constructor this:%o', this);
	}

	setDay(day) {
		logger.log('[DaySelect] setDay:%o', day);

		this.props.filter({
			day: day,
			open: '',
		});
	}
	onOpen(which) {
		this.props.filter({
			open: which,
		});
	}

	setMode(mode) {
		this.setState({
			mode: mode,
		});
	}

	render() {
		logger.log('[DaySelect] render - this:%o', this);

		let daysData = {
			options: [
				{
					dropdownName: 'Select Day',
					textName: '',
					value: '',
					type: '',
					title: '',
					disabled: true,
				},
			],
		};

		// add available days to base archive menu options
		this.dayNavOptions.forEach(item => {
			daysData.options.push({
				textName: item.textName,
				dropdownName: item.dropdownName,
				value: item.value,
				type: item.type,
				title: item.title,
				sort: item.sort,
				disabled: item.disabled,
			});
		});

		return (
			<div className="daynav scores-select-menu">
				<TournamentDaySelector handleOptionSelected={this.setDay} selectedDay={this.props.filters.day} />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DaySelect);
