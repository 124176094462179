/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import EventsLink from 'appdir/components/general/EventsLink';
import Time from 'appdir/components/common-ui/Time';
import deps from 'dependencies';
import { fetch } from 'appdir/components/general/Util';
import AdTag from 'appdir/components/general/AdTag';
import MeasurementUtils from 'appdir/lib/analytics';
import isEmpty from 'lodash/isEmpty';

/**
 * -----------------------------------------------------------------------------
 * Component: RelatedContentChips
 * -----------------------------------------------------------------------------
 */
const externalLinkRegex = /^(http|https)/;

const mapStateToProps = (state, props) => {
	return {
		...state['RelatedContentChips'],
		adConfigData: state['Config'].adConfig,
		...props,
	};
};

class RelatedContentChips extends Component {
	constructor(props) {
		super(props);
		if (this.props) {
			this.state = {
				apiPath: this.props.apiPath,
				chipsId: this.props.chipsId,
				showAds: this.props.showAds,
			};
		}
		this.formatChipData = this.formatChipData.bind(this);
		this.fetchRelated = this.fetchRelated.bind(this);
		this.relatedContentFetchSkip = 13;

		this.formatOmitIds = this.formatOmitIds.bind(this);
	}

	componentDidMount() {
		logger.log('[RelatedContentChips] componentDidMount - state:%o', this.state);

		if (this.state.apiPath) {
			let path = this.state.apiPath;
			logger.log('[RelatedContentChips] componentDidMount - url:%o', deps.services);

			//path = 'http://ix-events-related-content.mybluemix.net/rest/v2/wim_2018/en/tag?tags=atpf324&count=4';

			fetch(path).then(data => {
				// logger.log("[RelatedContentChips] data.totalRows:%o, skip:%o", data.totalRows, this.relatedContentFetchSkip);
				this.setState({
					chipsData: data.content.map(this.formatChipData),
					remaining: data.totalRows - this.relatedContentFetchSkip,
					status: 'loaded',
				});
			});
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// logger.log('[RelatedContentChips] getDerivedStateFromProps nextProps:%o, prevState:%o',nextProps, prevState);

		let newState = {
			...prevState,
			...nextProps,
		};

		if (isEmpty(newState)) {
			newState = null;
		}

		// logger.log('[RelatedContentChips] getDerivedStateFromProps newState:%o',newState);

		return newState;
	}

	componentDidUpdate(prevState) {
		logger.log('[RelatedContentChips] - componentDidUpdate state:%o', this.state);
	}

	formatChipData(chipData) {
		return {
			photo: chipData.images[0].medium,
			title: chipData.title,
			type: chipData.type,
			link: chipData.url,
			date: <Time epoch_ms={chipData.displayDate} format="dddd, MMMM D" />,
		};
	}

	fetchRelated() {
		if (this.state.apiPath) {
			let apiPath = this.state.apiPath + '&skip=' + this.relatedContentFetchSkip;
			fetch(apiPath)
				.then(data => {
					let formatedContentArry = data.content.map(this.formatChipData);
					let currentContentArry = this.state.chipsData;
					currentContentArry = currentContentArry.concat(formatedContentArry);
					MeasurementUtils.dispatchMeasurementCall('loadMore', {});

					this.setState(prevState => {
						this.relatedContentFetchSkip += 13;
						return {
							...prevState,
							chipsData: currentContentArry,
							remaining: data.totalRows - this.relatedContentFetchSkip,
						};
					});
				})
				.catch(error => {
					logger.error('[RelatedContentChips] error fetching related content data', error);
				});
		}
	}

	formatOmitIds() {
		logger.log('[RelatedContentChips] - formatOmitIds state:%o', this.state);
		let omissions = '';
		if (this.state.chipsData && this.state.chipsData.length) {
			this.state.chipsData.map((item, i) => {
				logger.log('[RelatedContentChips] - formatOmitIds map:%o', item);
				omissions += `&omitId=${item.id}`;
			});
		}
		logger.log('[RelatedContentChips] - formatOmitIds omissions:%o', omissions);
		return omissions;
	}

	renderAd(num) {
		return this.state.showAds ? (
			<div key={`ad${num}`} id={`ad${num}`} className="chip ad">
				<AdTag
					key={`key-ad${num}`}
					adConfig={this.props.adConfig['landingpage']}
					dfpNetworkId={this.props.adConfig.dfpNetworkId}
				/>
				
			</div>
		) : null;
	}

	render() {
		logger.log('[RelatedContentChips] - render this:%o', this);

		if (this.state.chipsData && !isEmpty(this.state.chipsData)) {
			// logger.log("[RelatedContentChips] - render props:%o", this.props);
			logger.log('[RelatedContentChips] - render state:%o', this.state);
			let skip = 0;

			return (
				<div key="rc_chips">
					<div className="chip_content" key={`hp_chips_${this.state.chipsId}`}>
						{this.state.chipsData.map((item, i) =>
							i != skip && this.state.showAds ? (
								<>
									{i == 2 || i == 7 || (i > 7 && (i - 2) % 5 == 0) ? (
										this.renderAd(i)
									) : (
										<div
											key={this.state.chipsId + i}
											id={this.state.chipsId + i}
											className={`chip`}>
											<EventsLink
												to={`${item['link']}?promo=${this.state.chipsId}_${i}&usopennow=true`}>
												<div className={`photoContainer`}>
													<div className={`photoThumbs`} />
													<div className={`photoImg`}>
														<img src={item['photo']} title={item['title']} />
													</div>
												</div>
												<div className={`caption`}>{item['title']}</div>
												<div className={`date`}>{item['date']}</div>
											</EventsLink>
										</div>
									)}
								</>
							) : (
								<React.Fragment>
									<div key={this.state.chipsId + i} id={this.state.chipsId + i} className={`chip`}>
										<EventsLink to={`${item['link']}?usopennow=true`}>
											<div className={`photoContainer`}>
												<div className={`photoThumbs`} />
												<div className={`photoImg`}>
													<img src={item['photo']} title={item['title']} />
												</div>
											</div>
											<div className={`caption`}>{item['title']}</div>
											<div className={`date`}>{item['date']}</div>
										</EventsLink>
									</div>
								</React.Fragment>
							)
						)}
					</div>
					{this.state.remaining > 0 && (
						<div id="hpLoad">
							<a onClick={this.fetchRelated}>
								<div className="label">Load More</div>
							</a>
						</div>
					)}
				</div>
			);
		} else {
			return null;
		}
	}
}
export default connect(mapStateToProps)(RelatedContentChips);

