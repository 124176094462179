import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let adConfig = Config.adConfig;
			dispatch({
				type: deps.actionTypes.ADUNITLAZY_MOUNT,
				data: { adConfig },
			});
		});
	},
};
