/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import FanWeekHighlights from './FanWeekHighlights';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: Visit Dining
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['FanWeekHighlights'],
		otherData: state['Config'].otherData,
		sponsor: state['Config'].sponsors?.visit,
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FanWeekHighlights);
