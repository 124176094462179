/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import { fetch } from 'components/general/Util';
import { Link } from 'react-router-dom';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import StubBox from 'appdir/components/common-ui/StubBox';
import Template from 'components/Template';
import Sponsor from './Sponsor';

/**
 * -----------------------------------------------------------------------------
 * React Component: SponsorLanding
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['SponsorLanding'],
		stubs: state['Config'].stubPages,
		scoringVars: state['Config'].scoring,
		seedGender: state['PlayerFilters'].seedGender,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SponsorLanding.mount()),
	unmount: data => dispatch(deps.actions.SponsorLanding.unmount(data)),
	filterGender: gender => dispatch(deps.actions.PlayerFilters.filterGender(gender)),
});

class SponsorLanding extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedIndex: 0,
			sponsorList: null,
			loadedJson: false,
		};

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Partners',
		});
		// logger.log('[SponsorLanding] constructor - state: %o', this.state);
	}

	componentDidMount() {
		// logger.log('[SponsorLanding] componentDidMount');
		if (
			!this.state.sponsorList &&
			!this.state.loadedJson &&
			this.props.sponsorListPath &&
			this.props.scoringVars !== undefined
		) {
			this.fetchData();
		}
	}

	componentWillUnmount() {
		// logger.log('[SponsorLanding] unmounted');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[SponsorLanding] componentDidUpdate - state:%o', this.state);

		// calculate new data path from path in state and current filters
		if (
			!this.state.loadedJson &&
			!this.isStubbed() &&
			this.props.sponsorListPath &&
			this.props.scoringVars !== undefined
		) {
			this.fetchData();
		}
	}

	isStubbed() {
		return this.props.stubs && this.props.stubs.sponsors
			? this.props.stubs.sponsors.stub == 'stub'
				? true
				: false
			: false;
	}

	fetchData() {
		logger.log('[SponsorLanding] fetchData - this:%o', this);

		if (this.props.scoringVars.seedsAvailable == 'true') {
			fetch(this.props.sponsorListPath)
				.then(data => {
					logger.log('[SponsorLanding] fetchData data: %o', data);
					this.setState(prevState => {
						return {
							...prevState,
							sponsorList: data,
							loadedJson: true,
						};
					});
				})
				.catch(error => {
					logger.log('[SponsorLanding] fetchData error loading data: %o', error);
				});
		}
	}

	createTableHeaders() {
		if (this.props.filters.listView == 'seeds') {
			return ['Seed', 'Name', '', 'Opponent', 'Round'];
		} else {
			return ['Rank', 'Name'];
		}
	}

	formatTableData(data) {
		if (this.props.filters.listView == 'seeds') {
			let seedData = data.seeds;
			return seedData.map((d, i) => {
				return {
					seed: d['rank'],
					name:
						d['tour-id'] != null ? (
							<Link to={`/en_US/players/overview/${d['tour-id']}.html`}>{d['long-name']}</Link>
						) : (
							d['long-name']
						),
					text: d['result'],
					opponent:
						d['opp-tour-id'] != null ? (
							<Link to={`/en_US/players/overview/${d['opp-tour-id']}.html`}>{d['opp-long-name']}</Link>
						) : (
							d['opp-long-name']
						),
					round: d['round'],
				};
			});
		}
	}

	getPageData() {
		let tableTitle;
		let headerData;
		let tableRowData;
		let pageClass;

		tableTitle = "Men's Seeds";
		headerData = this.createTableHeaders();
		tableRowData = this.formatTableData(this.state.sponsorList);

		return {
			tableTitle: tableTitle,
			headers: headerData,
			data: tableRowData,
			tableContainer: 'competitorList',
			pageClass: pageClass,
		};
	}

	setSelected(index) {
		this.setState(prevState => {
			return { ...prevState, selectedIndex: index };
		});
	}

	render() {
		logger.log('[SponsorLanding] render - this:%o', this);

		let header_propsData = {
			headerType: 'Partners',
			metaTitle: 'Partners',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scroll: false,
			canonicalLink: 'https://www.usopen.org/en_US/about/partners.html',
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			title: 'Partners',
		};

		if (this.isStubbed()) {
			return (
				<Template header={header_propsData} subHeader={subheader_attributes} hideAd={true}>
					<section className="wrapper">
						<div className="scores-background" />

						<StubBox
							attributes={{
								title: header_propsData.title,
								message: this.props.stubs.sponsors.text,
								basePicPath: this.props.basePicPath,
							}}
						/>
					</section>
				</Template>
			);
		} else {
			if (this.state.loadedJson && this.state.sponsorList) {
				//let propsData = this.getPageData();

				return (
					<Template header={header_propsData} subHeader={subheader_attributes}>
						<section className="wrapper">
							<h1 className="header">Partners</h1>
							<div className="sponsorsContainer">
								{this.state.sponsorList.sponsors.map((section, index) => {
									return (
										<Fragment>
											{index > 0 ? <div className="blue_divide" /> : null}
											<h3>{section.title}</h3>
											<div className="sponsorsCol">
												<div className="sponsorsTable">
													{section[`section-sponsors`].map((sponsor, index) => {
														return (
															<Sponsor
																attributes={{
																	imagePath: `${sponsor.image}`,
																	alt: sponsor.title,
																	title: sponsor.title,
																	link: sponsor.link,
																}}
															/>
														);
													})}
												</div>
											</div>
										</Fragment>
									);
								})}
							</div>
						</section>
					</Template>
				);
			} else {
				return (
					<Template header={header_propsData} subHeader={subheader_attributes}>
						<section className="wrapper playerSeeds">
							<h1 className="header">Partners</h1>
							<LoadingIndicator />
						</section>
					</Template>
				);
			}
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorLanding);
