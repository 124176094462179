import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;
	switch (action.type) {
		case deps.actionTypes.PLAYERFILTERS_MOUNT:
			newState = { ...state, ...action.data };
			return newState;

		case deps.actionTypes.PLAYERFILTERS_COUNTRY:
			newState = { ...state, ...action.data };
			return newState;

		case deps.actionTypes.PLAYERFILTERS_GENDER:
			newState = { ...state, ...action.data };
			return newState;

		default:
			return state;
	}
};
