/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import { getComponents, getAppLink } from 'appdir/components/general/Util';
import React, { Component } from 'react';
import JSXParser from 'react-jsx-parser';
import { parseString } from 'xml2js';
import endsWith from 'lodash/endsWith';
import MeasurementUtils from 'appdir/lib/analytics';
import { measureInApp } from 'appdir/components/general/Analytics';
import { fetch } from 'appdir/components/general/Util';
import WindowSize from 'appdir/components/general/WindowSize';

import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import Helmet from 'react-helmet';
import PageHeader from 'appdir/components/general/PageHeader';
import Byline from 'appdir/components/general/Byline';
import Time from 'appdir/components/common-ui/Time';
import VideoWrapper from 'appdir/components/general/VideoWrapper';
import InfiniteScroll from 'react-infinite-scroller';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import {
	getAttributesArray,
	getBreadcrumbs,
	getComponentListArray,
	getSponsor,
	getAiCatchUpContent
} from 'appdir/components/pages/ContentPage/Utils';

import { DFPSlotsProvider, DFPManager } from 'react-dfp';


/**
 * -----------------------------------------------------------------------------
 * React Component: Content Page
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['ContentPageWebview'],
		enabled: state['Config'].enabled,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.ContentPageWebview.mount()),
	unmount: () => dispatch(deps.actions.ContentPageWebview.unmount()),
	updateViewedContent: (time, id) => dispatch(deps.actions.Controller.updateViewedContent(time, id)),
});

class ContentPageWebview extends Component {
	constructor(props) {
		super(props);
		// this.state = Object.assign({}, this.props);
		this.state = {
			currArticleIndex: 0,
			contentPageArray: [],
		};

		this.lastDataPath = '';
		// this.state.currArticleIndex = 0;
		// this.state.contentPageArray = [];
		this.hasMore = true;
		this.pageYOffset = 0;
		this.firstTime = true;
		this.headerImgArray = [];

		logger.log('[ContentPageWebview] constructor:%o', this.props);
		if ('scrollRestoration' in history) {
			// Back off, browser, I got this...
			// logger.log('[ContentPageWebview] scrollRestoration');
			history.scrollRestoration = 'manual';
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		// logger.log('[ContentPageWebview] componentDidMount');
	}

	componentWillUnmount() {
		// logger.log('[ContentPageWebview] componentWillUnmount');
		this.props.unmount();
	}

	// componentWillReceiveProps(nextProps) {
	// 	this.setState(prevState => {
	// 		return Object.assign({}, prevState, nextProps);
	// 	});

	// 	logger.log('[ContentPageWebview] componentWillReceiveProps - state:%o next:%o', this.state, nextProps);
	// }

	setViewedContent(timestamp, contentId) {
		//logger.log('[ContentPageWebview] setViewedContent - timestamp:%o contentId:%o', timestamp, contentId);
		this.props.updateViewedContent(timestamp, contentId);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props?.dataUrl != undefined && endsWith(this.props?.dataUrl, '.xml')) {
			//logger.log('[ContentPageWebview] componentDidUpdate - fetch:%o', this.props?.dataUrl);

			//if path samem but key diff (new nav to existing location?)
			//  reload the data
			if (
				this.props?.location?.pathname == prevState?.location?.pathname &&
				this.props?.location?.key != prevState?.location?.key
			) {
				this.lastDataPath = '';
			}

			if (this.props?.dataUrl != this.lastDataPath && this.firstTime) {
				this.firstTime = false;
				this.lastDataPath = this.props?.dataUrl;
				this.fetchArticle().then(data => {
					if (data) {
						let contentArray = this.state.contentPageArray;
						contentArray.push(data);
						// logger.warn(
						// 	'[ContentPageWebview] componentDidUpdate - setting state in didupdate',
						// 	this.props?.dataUrl
						// );
						this.setState(prevState => {
							return {
								...prevState,
								contentPageArray: contentArray,
							};
						});
					}
				});
				if (this.props?.prevNextUrl != '' && this.props?.prevNextUrl !== undefined && this.firstTime) {
					this.fecthNext();
				}
			}
		} else {
			logger.warn('[ContentPageWebview] componentDidUpdate - improper data url: %o', this.props?.dataUrl);
		}
	}

	generateArticleURL() {
		let strArray = this.state.nextURL.split('/');
		let contentId = strArray.pop().split('.')[0];
		let dt = strArray.pop();
		let combinedStr = `${dt}/${contentId}`;
		let prevNextPath = this.props?.relatedContent?.nextPrevious
			.replace('<contentId>', contentId)
			.replace('<count>', 1)
			.replace('<true|false>', false)
			.replace('<type>', 'news');

		let path = this.props?.cmsData?.news.replace('<contentId>', combinedStr);
		return { path, prevNextPath };
	}

	onShareClick = () => {
		//logger.log('[ContentPageWebview] onShareClick');
		this.setState({
			sharePopup: 'hide',
		});
	};

	fetchArticle(url) {
		return fetch(url ? url : this.props?.dataUrl)
			.then(result => {
				//logger.log('[ContentPageWebview] componentDidUpdate result:%o', result);
				//set the xml parse options
				let options = {
					explicitArray: false,
					normalize: true,
					trim: true,
					mergeAttrs: true,
					valueProcessors: [],
				};

				//parse the xml
				parseString(result, options, function(err, json) {
					result = json.contentItem;
				});

				//clean the jsx block
				result.jsx = result.jsx.replace(/data="{(.*?)}"/gi, 'data={$1}');
				//logger.log('[ContentPageWebview] - result:%o', result);

				//update viewed content if has a date
				if (result.date) {
					this.setViewedContent(parseInt(result.date), result.contentId);
				}

				/** Find AI Catch Up Pre/Post content for metrics data */
				let aicontent = this.props?.enabled?.aicatchup ? getAiCatchUpContent(result?.dependencies?.data) : [];
				let aicontentType = aicontent?.[0]?.style;
				let eventType = aicontent?.[0]?.eventcode ?? "";
				let matchId = aicontent?.[0]?.matchid ?? "";
				let articleType = result?.category ? result.category : "News";

				let contextData = [];
				let metricsVal = [];

				if (result?.type?.toLowerCase() == "photo list") {
					contextData = [
						{ content_photolist: result?.title },
						{ articleType: articleType }
					];
					metricsVal = ['metricsState', 'Photo List'];

				} else if (aicontent?.length > 0) {
					/** Pre/Post|MS/WS|1101 */
					contextData = [
						{ content_article: result?.title },
						{ genai: aicontentType?.charAt(0)?.toUpperCase() + aicontentType.slice(1) + "|" + eventType + "|" + matchId},
						{ articleType: articleType }
					];
					metricsVal = ['metricsState', 'Article', 'Article Detail'];

				} else {
					/** 
					 *  iOS can't suppress the basic page view call and it creates double calls 
					 *  when webview sends a special context data message, thus all the page view calls
					 *  will be done by webview sending messages to apps
					 */
					contextData = [
						{ content_article: result?.title },
						{ articleType: articleType }
					];
					metricsVal = ['metricsState', 'Article', 'Article Detail'];
				}
	
				logger.log(
					'[ContentPageWebview] fetchArticle webview metrics: metricsVal:%o, contextData:%o',
					metricsVal,
					contextData
				);

				measureInApp(metricsVal, contextData);

				return result;
			})
			.catch(error => {
				logger.error('[ContentPageWebview] componentDidUpdate error:%o', error);
				if (error.status == 404) {
					this.setState({
						data: 'notfound',
					});
					return false;
				}
			});
	}

	fecthNext(url) {
		// logger.log('[ContentPageWebview] fetchNext url:%o', url);
		return fetch(url ? url : this.props?.prevNextUrl)
			.then(result => {
				// logger.log('[ContentPageWebview] componentDidUpdate preNextUrl result:%o', result);

				this.setState({
					nextURL: result.next.length > 0 ? `/webview${result.next[0].url}` : null,
				});
			})
			.catch(error => {
				logger.error('[ContentPageWebview] componentDidUpdate error:%o', error);
				if (error.status == 404) {
					this.setState({
						prevNextData: 'notfound',
					});
				}
			});
	}

	toggleShare() {
		//logger.log('[ContentPageWebview] toggleShare - sharePopup:%o', this.state.sharePopup);
		this.setState({
			sharePopup: this.state.sharePopup == 'hide' ? 'show' : 'hide',
		});
	}

	getHeader() {
		logger.log('[ContentPageWebview] getHeader - this:%o', this);

		let { data } = this.state;

		let videoId =
			data.header && data.header.videoId && data.header.video
				? data.header.videoId
				: null;

		let video_attributes = {
			playerId: 'main',
			contentId: videoId,
			videoUrl: null,
			title: null,
			thumb: null,
			autoplay: true,
			fullscreenMode: '',
			style: `video-wrapper one-col modal header`,
			aspect: 'wide',
			url: null,
		};

		//content page, (category)
		if (this.props?.category != 'articles' && !this.props?.landing) {
			let videoclass = videoId ? 'video' : '';

			return (
				<div className="news--header-wrapper">
					<div className={`news--header ${videoclass}`}>
						<div className="news--header-title">
							<h2>{data?.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data?.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data?.abstract}</h4>
						</div>
					</div>
					{videoId ? (
						<div className="news--header-videoLink">
							<VideoWrapper attributes={video_attributes} type="header" />
						</div>
					) : null}
				</div>
			);
		} else if (this.props?.landing && videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video" />
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (this.props?.landing) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header" />
				</div>
			);
		}

		//article pages
		else if (videoId) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header video">
						<div className="news--header-icon">
							<i className="icon-news" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time
									epoch_ms={data?.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title">
							<h2>{data?.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data?.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data?.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
					<div className="news--header-videoLink">
						<VideoWrapper attributes={video_attributes} type="header" />
					</div>
				</div>
			);
		} else if (data?.header?.image && data?.header?.image.length > 25) {
			return (
				<div className="news--header-wrapper">
					<div className="news--header">
						<div className="news--header-icon">
							<i className="icon-news" />
						</div>
						<div className="news--header-date">
							<h3>
								<Time
									epoch_ms={data?.date}
									format="ddd DD MMM YYYY kk:mm z"
									options="upper"
								/>
							</h3>
						</div>
						<div className="news--header-title">
							<h2>{data?.title}</h2>
						</div>
						<div className="news--header-shorttitle">
							<h2>{data?.shortTitle}</h2>
						</div>
						<div className="news--header-abstract">
							<h4>{data?.abstract}</h4>
							<h3> READ MORE</h3>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	playFullVideo(id) {
		logger.log('[ContentPageWebview] playFullVideo - id:%o', id);
	}

	fetchMoreArticle() {
		let prevURL = this.state.nextURL;
		let articleData = this.generateArticleURL();
		this.fetchArticle(articleData.path).then(data => {
			if (data) {
				let contentArray = this.state.contentPageArray;
				contentArray.push(data);
				this.headerImgArray.push(data.header);
				this.setState(
					prevState => {
						return { ...prevState, contentPageArray: contentArray };
					},
					() => {
						this.props?.history.push(prevURL);
					}
				);
			} else {
				this.setState(prevState => {
					return {
						...prevState,
						data: 'notfound',
					};
				});
			}
		});

		this.fecthNext(articleData.prevNextPath);
	}

	renderArticles() {
		let { contentPageArray } = this.state;
		let contentPageArrayMap = [];

		for (let i = 0; i < contentPageArray.length; i++) {
			let componentsAttributes = {
				attributes: getAttributesArray(contentPageArray[i].dependencies.data),
				components: getComponents(getComponentListArray(contentPageArray[i].dependencies.data)),
				jsx: contentPageArray[i].jsx,
			};
			contentPageArrayMap.push(componentsAttributes);
		}

		return contentPageArrayMap.map((d, i) => {
			let currContent = contentPageArrayMap[i];
			logger.log('[ContentPageWebview] currContent:%o', contentPageArrayMap[i]);
			let header_propsData = {
				headerType: this.props.category || this.props.landing,
				shortTitle: contentPageArray[i].shortTitle,
				scrollElem: '.content-main',
				metaTitle: contentPageArray[i].title,
				metaDescription: contentPageArray[i].abstract,
				metaDate: contentPageArray[i].date,
				metaPlayers: contentPageArray[i].players,
				scroll: false,
				credit: contentPageArray[i].credit,
				shareUrl: contentPageArray[i].url.share,
				watsonxBranding: this.props?.enabled?.aicatchup ? getAiCatchUpContent(contentPageArray[i]?.dependencies?.data)?.length > 0 : false
			};

			let subheader_attributes = {
				breadcrumbs: getBreadcrumbs(contentPageArray),
				title: contentPageArray[i].shortTitle,
				sponsor: getSponsor(contentPageArray, this.props),
			};

			//initially was checking for seemingly valid url to image or video due to invalid test data
			if (
				contentPageArray[i].header.image &&
				contentPageArray[i].header.image.length > 25
			) {
				header_propsData['imageSrc'] = contentPageArray[i].header.image;
				header_propsData['titleElem'] = '.news--header';
				header_propsData['scroll'] = true;
			}

			if (contentPageArray[i].header.video) {
				header_propsData['videoSrc'] = contentPageArray[i].header.video;
			}

			{
				Object.keys(currContent.attributes).map((att, i) => {
					if (currContent.attributes[att].type == 'SocialEmbed') {
						currContent.attributes[att].visible = true;
					}
					if(currContent.attributes[att].type == 'Video') {
						currContent.attributes[att].style = 'inline';
					}
				});
			}
			
			return i == 0 ? (
				<span key={`${i}-${d.contentId}`}>
					{/* <Header attributes={header_propsData} />
					<MobileNav />
					<SubHeader attributes={subheader_attributes} />*/}
					<PageHeader attributes={header_propsData} />
					<Helmet>
						<meta name="measureTitle" content="Detail" />

						<meta property="og:title" content={contentPageArray[i].title} />
						<meta property="og:description" content={contentPageArray[i].abstract} />
						<meta property="fb:app_id" content="471867639491882" />
						<meta property="og:image" content={contentPageArray[i].header.image} />
						<meta property="og:url" content={contentPageArray[i].url.share} />

						<meta name="twitter:card" content="summary_large_image" />
						<meta name="twitter:site" content="@USOpen" />
						<meta name="twitter:creator" content="@USOpen" />
						<meta name="twitter:title" content={contentPageArray[i].title} />
						<meta name="twitter:description" content={contentPageArray[i].abstract} />
						<meta name="twitter:image" content={contentPageArray[i].header.image} />
						<meta name="twitter:url" content={contentPageArray[i].url.share} />

						{contentPageArray[i].url.amp ? (
							<link rel="amphtml" href={contentPageArray[i].url.amp} />
						) : null}
					</Helmet>
					<Byline
						attributes={header_propsData}
						webview={true}
						share={{
							url: contentPageArray[i].url.share,
							title: contentPageArray[i].metaTitle,
						}}
					/>
					<article id={`article${i}`} key={`article${i}`} className="newsArticle">
						<ErrorBoundary message={'Unable to render content component.'}>
							<JSXParser
								key={`articles${i}`}
								bindings={currContent.attributes}
								components={currContent.components}
								jsx={currContent.jsx}
								renderInWrapper={false}
								showWarnings={true}
							/>
						</ErrorBoundary>
					</article>
				</span>
			) : (
				<span key={`${i}-${d.contentId}`}>
					<div className="next-article">
						<div className="centerMargins">
							<div className="breadcrumbs">
								<span>Next Story :&nbsp;</span>
								<span className="pagetitle">{contentPageArray[i].title}</span>
							</div>
						</div>
					</div>
					<PageHeader attributes={header_propsData} />
					{contentPageArray[i].url.amp ? (
						<link rel="amphtml" href={contentPageArray[i].url.amp} />
					) : null}
					<Byline
						attributes={header_propsData}
						share={{
							url: contentPageArray[i].url.share,
							title: contentPageArray[i].metaTitle,
						}}
					/>
					<article id={`article${i}`} key={`article${i}`} className="newsArticle">
						<ErrorBoundary message={'Unable to render content component.'}>
							<JSXParser
								key={`articles${i}`}
								bindings={currContent.attributes}
								components={currContent.components}
								jsx={currContent.jsx}
								renderInWrapper={false}
								showWarnings={true}
							/>
						</ErrorBoundary>
					</article>
				</span>
			);
		});
	}

	onLinkClick(e) {
		logger.info('[onLinkClick] event: %o', e.target);
		if (
			e.target.tagName == 'A' &&
			(e.target.href.includes('players/overview') ||
				e.target.href.includes('news/articles/2021') ||
				e.target.href.includes('news/articles/2022') ||
				e.target.href.includes('news/articles/2023') ||
				e.target.href.includes('visit/') ||
				(!e.target.href.includes('external=true') &&
					!e.target.href.includes('javascript') &&
					!e.target.href.includes('/usopen/') &&
					!e.target.href.includes('ustaApp') &&
					!e.target.href.includes('ustaopen')))
		) {
			e.preventDefault();
			logger.info('[onLinkClick] event: %o', e.target);
			let link = {
				url: '',
				external: false,
			};
			link = getAppLink(e.target.href, '');

			logger.info('[onLinkClick] link: %o', link);

			if (link.url.length > 0) {
				if (link.url.includes('/webview/')) {
					let internalLink = link.url.replace('https://www.usopen.org', '');
					this.props?.history.push(internalLink);
				} else if (link.url.indexOf('usta') == 0) {
					document.location.href = link.url;
				} else {
					document.location.href = link.url;
				}
			}
		}
	}

	render() {
		logger.log('[ContentPageWebview] render - this:%o', this);

		let flex = '';
		if (this.state.data) {
			if (this.state.data.tiled) {
				if (this.state.data.tiled == 'true') {
					flex = 'flex';
				}
			}
		} else {
			flex = '';
		}

		if (this.state?.data == 'notfound') {
			return (
				<section className="wrapper article-wrapper webview">
					<PageHeader attributes={header_propsData} />
				</section>
			);
		} else if (this.state?.contentPageArray?.length > 0) {
			if (this.state.category == 'interviews') {
				this.hasMore = false;
			}
			return (
				<section className="wrapper article-wrapper" onClickCapture={this.onLinkClick.bind(this)}>
					<WindowSize />
					<InfiniteScroll
						loadMore={this.fetchMoreArticle.bind(this)}
						hasMore={false}
						loader={
							<div key="infinite-loader" style={{ position: 'relative' }}>
								<LoadingIndicator />
							</div>
						}
						initialLoad={false}
						useWindow={true}
						threshold={100}>
						<DFPSlotsProvider
							dfpNetworkId={"5681"}
							collapseEmptyDivs={true}
							singleRequest={true}
							lazyLoad={{
								fetchMarginPercent: 100,
								renderMarginPercent: 25,
								mobileScaling: 0
						}}>
						{this.renderArticles()}
						</DFPSlotsProvider>
					</InfiniteScroll>
				</section>
			);
		} else {
			// logger.warn('[ContentPageWebview] render - data not loaded, state:%o', this.state);

			return (
				<section className="wrapper article-wrapper">
					<LoadingIndicator key="opening-loader" />
				</section>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPageWebview);
