import deps from 'dependencies';

export default {
	mount: params => (dispatch, getState, store) => {
		//logger.log('[SiteMap] action.mount - ensureConfig');

		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[SiteMap] action.mount - dispatch - param:%o', params);
			logger.log('Config: ', Config);

			let data = {
				sponsorListPath: Config.cmsData.mainMenu,
				basePicPath: Config.otherData.basePicPath,
			};

			dispatch({
				type: deps.actionTypes.SITEMAP_LOAD,
				data: data,
			});
		});
	},
	unmount: data => (dispatch, getState, store) => {
		logger.log('[MainNav] actions.unmount data:%o', data);
		dispatch({ type: deps.actionTypes.SITEMAP_UNMOUNT, data: data });
	},
};
