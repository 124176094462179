/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import EventsLink from 'appdir/components/general/EventsLink';
import PlayerImage from 'appdir/components/content/PlayerImage';
import EventsButton from 'appdir/components/common-ui/EventsButton';
import LikelihoodWinBanner from 'appdir/components/pages/MatchInsights/elements/LikelihoodWinBanner';
import PowerIndexChart from 'appdir/components/pages/MatchInsights/elements/PowerIndexChart';
import { getTeamsLikelihoodWinData } from 'appdir/components/pages/MatchInsights/MatchInsightsUtils';
import { fetch, getAppLink, getQuerystringValues } from 'appdir/components/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component:  MatchInsight Landing MatchCard
 *
 *  props = {
 *             @param Object match ------------- required,
 *			 @param Integer total ------------ total of Match Cards data
 *             @param Object searchedPlayer ----
 *           }
 *
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['MatchCard'],
		otherData: state['Config'].otherData,
		windowSize: state['Controller'].windowSize,
		innovationTest: state['Config'].innovationTest,
		//  favorites: state['Controller'].favorites,
		...props,
	};
};

// map all the dispatch functions to props so it can be called whenever we wish
const mapDispatchToProps = (dispatch, props) => ({});

class MatchCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			powerRankingData: null,
			status: null,
		};

		this.matchId = null;

		logger.log('[MatchCard] constructor - this: %o', this);
	}

	componentDidMount() {
		/** ?override=true ignores the match status and always set vote to open in the MI details page
		 *  add the same param to the link to the details page
		 */
		this.parsedQs = getQuerystringValues(document.location.search.replace(/^\?/, ''));
		this.isInnovationTest = this.parsedQs?.test;

		this.setState({
			status: 'loaded',
		});
	}

	componentDidUpdate(prevProps, prevState) {
		this.matchId = this.props?.match?.match_id;

		if (
			!this.state.powerRankingData &&
			this.matchId &&
			this.matchId !== undefined &&
			this.state.status == 'loaded'
		) {
			let powerIndexMatchupPath = this.props?.otherData.innovations?.powerIndexMatchup?.replace(
				'<matchId>',
				this.matchId
			);

			/** set testing URLs  */
			if (this.isInnovationTest) {
				powerIndexMatchupPath = this.props.innovationTest.matchInsightscloudData.powerIndexMatchup.replace(
					'<matchId>',
					this.matchId
				);
			}

			fetch(powerIndexMatchupPath)
				.then(result => {
					// logger.log('[MatchCard] result:%o', result);
					this.setState({
						powerRankingData: result,
					});
				})
				.catch(error => {
					logger.error('[MatchCard] componentDidUpdate error loading powerIndexMatchup data:%o', error);
					this.setState({
						powerRankingData: 'error',
					});
				});
		}
	}

	/** iOS didn't get postMessage through the a href link
	 *  force it to get the message
	 */
	sendiOSMetrics = match_id => {
		// window.location.href = window.webkit.messageHandlers.matchInsights.postMessage(match_id);
		let link = getAppLink(`/en_US/scores/stats/${match_id}.html`, '');
		location.href = link.url;
	};

	render() {
		let playerImageSrc = this.props.otherData.playerProfileImagePath;
		let imageStyle = 'regular';
		let flagImagePath = this.props.otherData.flagImagePathSmall;

		let { match, total } = this.props;
		let teams = [match.team1, match.team2];

		/** support both passing the powerRankings data as props and pass matchID and fetch powerRankings data */
		if (this.state.powerRankingData && this.state.powerRankingData !== 'error') {
			let likelyhoodWinData = getTeamsLikelihoodWinData(this.state.powerRankingData, teams);

			/** make sure players have the probablility number  */
			if (likelyhoodWinData.filter(d => d['likelihoodWinNum']).length > 0) {
				let team1Data = likelyhoodWinData.filter(d => d['player_id'] === teams[0]['idA']);
				let direction = team1Data[0]['likelihoodWin'] ? 'left' : 'right';

				return (
					<EventsLink
						to={`/en_US/scores/stats/${match.match_id}.html${
							this.parsedQs?.override ? '?override=true' : ''
						}${this.parsedQs?.test ? '?test=true' : ''}`}
						key={`match-card-${match.match_id}`}
						bypassAppLink={false}
						stateData={{ selectedTab: 'insights' }}
						webviewCare={true}
						style={'insights-index-btn'}>
						<div className={`match-card ${total < 2 ? `no-margin` : ``}`}>
							<div className="card">
								<div className="card-head">
									<div className="eventName">{`${match.eventName} - ${match.roundName}`}</div>
									<div className="courtName">{match.shortCourtName}</div>
								</div>

								<LikelihoodWinBanner direction={direction} />

								<div className="teams">
									<div className="team-wrapper team1" id={match.team1.idA}>
										<PlayerImage
											attributes={{
												'player-image': `${playerImageSrc.replace(
													'<playerid>',
													match.team1.idA
												)}`,
												style: `${imageStyle}`,
											}}
										/>
										<div className="team-name">
											<div
												className={`team1 
                                        ${get(this.props, 'searchedPlayer.id', '') == match.team1.idA ? `yellow` : ``} 
                                        ${
											get(this.props, 'favourites.show', false) &&
											get(this.props, 'favourites.players', []).includes(match.team1.idA)
												? `purple`
												: ``
										} 
                                        `}>
												<div className="playerName">
													<div className="name">
														{match.team1.displayNameA}
														{/* <span className="seed">{match.team1.seed}</span> */}
													</div>
													<div className="player-flag">
														<img
															alt={match.team1.nationA}
															src={flagImagePath.replace('<code>', match.team1.nationA)}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="v">
										<PowerIndexChart
											teams={teams}
											matchId={match.match_id}
											powerRankingData={this.state.powerRankingData}
											type="matchcard"
										/>
									</div>

									<div className="team-wrapper team2" id={match.team2.idA}>
										<PlayerImage
											attributes={{
												'player-image': `${playerImageSrc.replace(
													'<playerid>',
													match.team2.idA
												)}`,
												style: `${imageStyle}`,
											}}
										/>
										<div className="team-name">
											<div
												className={`team2 
                                            ${
												get(this.props, 'searchedPlayer.id', '') == match.team2.idA
													? `yellow`
													: ``
											}
                                            ${
												get(this.props, 'favourites.show', false) &&
												get(this.props, 'favourites.players', []).includes(match.team2.idA)
													? `purple`
													: ``
											} 
                                            `}>
												<div className="playerName">
													<div className="name">
														{match.team2.displayNameA}
														{/* <span className="seed">{match.team2.seed}</span> */}
													</div>
													<div className="player-flag">
														<img
															alt={match.team2.nationA}
															src={flagImagePath.replace('<code>', match.team2.nationA)}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<EventsButton className="card-cta" onClick={null}>
									{match
										? matchIsFutureState(match)
											? 'Match Preview'
											: matchIsLiveState(match)
											? 'Match Details by IBM SlamTracker'
											: matchIsCompleteState(match)
											? 'Match Recap'
											: null
										: null}
								</EventsButton>
							</div>
						</div>
					</EventsLink>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchCard);
