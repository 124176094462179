import deps from 'dependencies';

export default {
	mount: data => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let data = {
				loaded: true,
			};

			dispatch({ type: deps.actionTypes.BREAKINGALERT_MOUNT, data: data });
		});
	},
	setOpenStatus: data => (dispatch, getState, store) => {
		dispatch({ type: deps.actionTypes.BREAKINGALERT_STATUS, data: data });
	},
};
