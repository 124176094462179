/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: BackToAllMI
 *
 *  props = {
 *             parsedQs --- override=true to keep HaveYourSay voting open in the MI details & ST
 *           }
 *  if coming from SlamTracker, don't use bypassAppLink() so it can open in a new webview
 * -----------------------------------------------------------------------------
 */
const BackToAllMI = props => {
	// logger.log('[MatchInsights] BackToAllMI - props:%o', props);
	return (
		<div className="mi-section no-border-bottom center back-to-all-mi">
			<EventsLink
				to={`${
					window.webviewPlatform && props?.type != 'slamtracker' ? '/webview' : ''
				}/en_US/matchinsights/index.html${props?.parsedQs?.override ? '?override=true' : ''}${
					props?.parsedQs?.test ? '?test=true' : ''
				}`}
				bypassAppLink={props?.type === 'slamtracker' ? false : true}>
				Back to All Matches
			</EventsLink>
		</div>
	);
};

export default BackToAllMI;
