/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Template from 'components/Template';
import deps from 'dependencies';
import replace from 'lodash/replace';
import SearchResults from './SearchResults';
import EventsLink from 'appdir/components/general/EventsLink';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';

const queryString = require('querystring-browser');

/**
 * -----------------------------------------------------------------------------
 * React Component: SearchPage
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['SearchPage'],
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.SearchPage.mount()),
	unmount: () => dispatch(deps.actions.SearchPage.unmount()),
});

class SearchPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// ...this.props,
			noResults: false,
			searchResults: null,
			initialPageLoadNoQuery: false,
		};

		this.fetchURLQuery = true;

		this.onEnter = this.onEnter.bind(this);

		logger.log('[SearchPage] constructor - props:%o', this.props);
	}

	componentDidMount() {
		//don't call if has a route
		//this.props.mount();
	}

	componentWillUnmount() {
		logger.log('[SearchPage] componentWillMount - this:%o', this);
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[SearchPage] componentDidUpdate - path:%o', this.props.atozPath);

		if (this.props.location.search && this.fetchURLQuery && this.props.solrSearch) {
			this.fetchURLQuery = false;
			let search = this.props.location.search.replace(/^\?/, '');
			let parsedQs = queryString.parse(search);
			if (!parsedQs.query) {
				this.setState(prevState => {
					return {
						...prevState,
						initialPageLoadNoQuery: true,
						noResults: true,
					};
				});
			} else {
				this.getSearchUrl(parsedQs.query, 0);
			}

			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'Search',
			});

			logger.log(parsedQs, 'The parsed qs');
		}
	}

	getSearchUrl(term, start, cnt) {
		let url = this.props.solrSearch.service;
		let query = this.props.solrSearch['q.op'];
		let rows = cnt ? cnt : this.props.solrSearch.rows;

		term = term.trim();

		query = replace(query, new RegExp('<SEARCH TEXT>', 'g'), term);

		url = `${url}&q.op=${query}`;
		url = `${url}&df=${this.props.solrSearch.df}`;
		url = `${url}&fl=${this.props.solrSearch.fl}`;

		url = `${url}&indent=${this.props.solrSearch.indent}&hl=${this.props.solrSearch.hl}&hl.fl=${this.props.solrSearch['hl.fl']}&hl.fragsize=${this.props.solrSearch['hl.fragsize']}`;

		if (start) {
			url = `${url}&start=${start}`;
		}
		if (rows) {
			url = `${url}&rows=${rows}`;
		}

		logger.log('[SearchPage] getSearchUrl - url:%o', url);
		this.getResults(url, term);
	}

	getResults(dataPath, term) {
		deps.services.SearchPage.fetchSearchResults(dataPath).then(result => {
			logger.log('[SearchPage] getResults - data:%o', result);
			this.setState({
				searchResults: result.data,
			});

			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'Search Results',
			});
		});
	}

	getContent() {
		logger.log('[SearchPage] getContent - this:%o', this);
		if (this.state.searchResults && this.state.searchResults.response.numFound > 0) {
			return <SearchResults data={this.state.searchResults} />;
		} else if (this.state.searchResults) {
			return (
				<div className="column-layout">
					<div className="no-results">No Results Found</div>
				</div>
			);
		}
	}

	// getFaqs() {
	// 	if (this.state.faqData) {
	// 		let cnt = 0;

	// 		return (
	// 			<div className="category-question-list">
	// 				<div className="category-list">
	// 					<div className="category-list-title">{this.state.faqData.categoryTitle}</div>
	// 					{this.state.faqData.content.map((val, key) => {
	// 						return (
	// 							<div className="category-list-question" key={cnt++}>
	// 								<EventsLink to={val.url} style="white">
	// 									{val.title}
	// 								</EventsLink>
	// 							</div>
	// 						);
	// 					})}
	// 				</div>
	// 			</div>
	// 		);
	// 	} else {
	// 		return null;
	// 	}
	// }

	onEnter(value) {
		logger.log('[SearchPage] onEnter - value:%o', value);
	}

	getNavSettings() {
		let navSettings;
		//pass in the top fixed position of the nav when the page scrolls
		navSettings = { navTop: 190 };

		return navSettings;
	}

	render() {
		logger.log('[SearchPage] render - this:%o', this);

		let header_propsData = {
			headerType: 'search',
			//"titleElem": ".search--header",
			minHeight: 190,
			metaTitle: 'Search',
			metaDescription: '',
			metaDate: '',
			metaPlayers: '',
			scroll: true,
			hideElems: ['.category-question-list'],
		};

		let subheader_attributes = {
			breadcrumbs: [
				{
					link: '/index.html',
					title: 'home',
				},
			],
			sponsor: [],
			title: 'Search',
			page_header: 'Search',
		};

		return (
			<Template header={header_propsData} subHeader={subheader_attributes}>
				<h1 className="header">Search</h1>
				<section className="wrapper article-wrapper">
					<div className="general_content search">
						<div className="siteDetailContainer">
							<div id="searchContainer" className="siteDetail">
								<div className="searchFormContainer">
									<form
										name="ksearch"
										id="ksearch"
										method="get"
										action="/en_US/search/results.html"
										style={{ margin: '0px', padding: '0px' }}>
										<input
											style={{ float: 'left' }}
											type="text"
											aria-label="Search"
											name="query"
											id="query"
										/>
										<div className="pageSearchButton">
											<input type="submit" className="uso-button blue" value="SEARCH" />
										</div>
									</form>
								</div>
								<div className="helperText">
									Use the search form above to enter keywords for your search
								</div>
								{!this.state.searchResults && !this.state.noResults ? (
									<LoadingIndicator />
								) : this.state.searchResults && !this.state.noResults ? (
									this.getContent()
								) : this.state.initialPageLoadNoQuery && this.state.noResults ? null : (
									<div className="searchResultsContainer">
										<div id="resultsMessage">
											There were no search results for '{this.state.searchTerm}'. Please try your search
											again.
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
