import SearchPage from './index';
import deps from 'dependencies';

export default [
	{
		path: ['/:lang(en_US)/search/index.html', '/:lang(en_US)/search/results.html'],
		exact: true,
		component: SearchPage,
		load: params => deps.actions.SearchPage.mount(params),
	},
];
