import React from 'react';
import BasicDisplay from './BasicDisplay';
import ButtonGroup from './ButtonGroup';

/**
 * ---------------------------------------
 * StoryGroup - module with a title which contains one large lead content piece (news, photo, video) with a title and a short description and
 * then the ability to add multiple stories underneath it. these stories underneath will contain an image, title, and short description.
 * ---------------------------------------
 */

const StoryGroup = props => {
	// logger.log('[StoryGroup] render props:%o, ', props);
	let leadData = [];
	let otherData = [];
	let buttonData = props.data.content.buttons;

	props.data.content.items.map(item => {
		if (item.style == 'lead') {
			leadData.push(item);
		} else {
			otherData.push(item);
		}
	});

	let measureData = {
		...props.data,
		type: props.data.type,
		itemId: props.data.id,
		itemType: props.data.type,
	};
	delete measureData.content;

	return (
		<div className="storygroup">
			<div className="lead">
				{leadData.length > 0 ? (
					<BasicDisplay
						data={leadData[0]}
						measureData={{
							...measureData,
							which: 'lead',
							actionType: 'homePageClick',
							itemTitle: leadData[0].title,
						}}
					/>
				) : null}
			</div>

			{otherData.length > 0 ? (
				<div className="more-stories">
					{otherData.map((item, i) => {
						return (
							<div className="story" key={`story${i}`}>
								<BasicDisplay
									data={item}
									display="story"
									measureData={{
										...measureData,
										which: `story${i}`,
										actionType: 'homePageClick',
										itemTitle: item.title,
									}}
								/>
							</div>
						);
					})}
				</div>
			) : null}

			{buttonData.length > 0 ? (
				<ButtonGroup
					data={buttonData}
					measureData={{
						...measureData,
						which: 'button',
						actionType: 'homepage_button',
						moduleType: props.data.type,
						title: props.data.title,
					}}
				/>
			) : null}
		</div>
	);
};

export default StoryGroup;
