import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import { values } from 'appdir/main';
import { getAppLink } from 'appdir/components/general/Util';
import { doMeasurement } from 'appdir/components/general/Util';

import Button from 'appdir/components/common-ui/Button';
import EventsLink from 'appdir/components/general/EventsLink';

/**
 *
 * @param {*} props.bracketViewLink ----- for deep link to the Bracket view
 * @param {*} props.playerId ------------ selected player to auto scroll to on the Bracket view
 * @returns ----------------------------- A button
 */
const BraketViewButton = props => {
	const bracketViewLink = props?.attributes?.bracketViewLink;
	const playerId = props?.attributes?.playerId;

	let drawAnalysisHelpRedux = useSelector(state => state['DrawAnalysisHelp']);

	const dispatch = useDispatch();

	/** send metrics call for apps on the Draws Bracket view deep link tap on Power Index to Bracket view
	 *  and deep link to the bracket view
	 */
	const onLinkClick = (e, path) => {
		logger.log('[BraketViewButton] e:%o, path:%o', e.target, path);
		e.preventDefault();

		let measureArgs = [{ pi_draws: playerId }];
		let contextData = [];

		if (window.webview) {
			measureArgs = [];
			contextData = [{ pi_draws: playerId }];
		}
		doMeasurement(values.powerIndexTitle, 'Draws Bracket View', measureArgs, contextData);

		if (typeof webview !== 'undefined') {
			let link = getAppLink(path, '');

			/** try delaying the link open for iOS to pick up the doMeasurenet metrics call above */
			setTimeout(() => {
				location.href = link.url;
			}, 500);
		}
	};

	/** send metrics call - used for desktop only
	 *  for apps, this is added to onLinkClick()
	 */
	const handleOnClick = () => {
		/** if the button to the Bracket view is clicked on the help overlay on Power Index page,
		 *  turn off the overlay so it doesn't auto pop-up on the bracket view
		 *
		 *  if drawAnalysisHelpRedux.viewed = false, the user hasn't seen the first time
		 *  onboarding screen, don't update redux
		 */
		if (drawAnalysisHelpRedux.viewed && drawAnalysisHelpRedux.help) {
			let data = {
				help: false,
			};
			dispatch(deps.actions.DrawAnalysisHelp.update(data));
		}

		let measureArgs = [{ pi_draws: playerId }];
		let contextData = [];

		if (window.webview) {
			measureArgs = [];
			contextData = [{ pi_draws: playerId }];
		}
		doMeasurement(values.powerIndexTitle, 'Draws Bracket View', measureArgs, contextData);
	};

	if (bracketViewLink) {
		if (window.webview) {
			return (
				<Button
					className={`blue full compact`}
					onClick={e => {
						onLinkClick(e, bracketViewLink);
					}}>
					Draws Bracket View
				</Button>
			);
		} else {
			return (
				<Button className={`blue full compact`}>
					<EventsLink to={bracketViewLink} onClick={() => handleOnClick()}>
						Draws Bracket View
					</EventsLink>
				</Button>
			);
		}
	}
};
export default BraketViewButton;
