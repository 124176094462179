/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';

/* page elements */
import SlamtrackerContent from 'components/pages/Slamtracker/SlamtrackerContent';
import TemplateGeneric from 'components/TemplateGeneric';

/**
 * -----------------------------------------------------------------------------
 * React Component: SlamtrackerWebview
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['Slamtracker'],
		...props,
	};
};

const mapDispatchToProps = dispatch => ({
	unmount: () => dispatch(deps.actions.Slamtracker.unmount()),
	setScoringStatus: data => dispatch(deps.actions.Controller.setScoringStatus(data)),
	clearStatMatch: () => dispatch(deps.actions.ScoreManager.clearStatMatch()),
});

class SlamtrackerDemo extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		logger.log('[SlamtrackerDemo] componentDidMount - this:%o', this);
	}

	componentWillUnmount() {
		// i don't want to do this if we are staying on slamtracker page
		this.props.setScoringStatus({ slamtracker: false });
		//this.props.unmount();
		this.props.clearStatMatch();
	}

	componentDidUpdate() {
		logger.log('[SlamtrackerDemo] componentDidUpdate - this:%o', this);
	}

	render() {
		logger.log('[SlamtrackerDemo] render - this:%o', this);

		return (
			<TemplateGeneric className="webview">
				<SlamtrackerContent matchId={this.props.match.params.matchId} />
			</TemplateGeneric>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SlamtrackerDemo);
